import * as React from 'react';
import { CSSTransitionGroup } from 'react-transition-group-v1';
import { Tooltip, TooltipContent } from '../../../components/tooltip/tooltip';
import { BrandingSettingsDraftState } from '../../editor-form/branding-settings-form-view-model';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { SvgWrapper } from '../../../components/svg-wrapper';

import * as styles from './loading-spinner.less';

export interface ILoadingSpinnerProps {
	draftState: BrandingSettingsDraftState;
	savePackage?(): void;
}

@observer
export class LoadingSpinner extends React.Component<ILoadingSpinnerProps> {
	render() {
		const { draftState, savePackage } = this.props;
		let loadingText: string;
		let spinnerStyle: string;
		let retry: boolean = false;
		switch (draftState) {
			case BrandingSettingsDraftState.Changed:
			case BrandingSettingsDraftState.Saved:
				loadingText = 'Saved';
				spinnerStyle = styles.saved;
				break;
			case BrandingSettingsDraftState.Saving:
				loadingText = 'Saving';
				spinnerStyle = styles.saving;
				break;
			case BrandingSettingsDraftState.Unsaved:
				loadingText = 'Unsaved';
				spinnerStyle = styles.unsaved;
				retry = true;
				break;
			default:
				const unsupported: never = draftState;
				throw new Error(`Value ${unsupported} is not supported`);
		}

		return (
			<RetryTooltip enabled={retry}>
				<div className={styles.loadingSpinnerContainer} onClick={retry ? savePackage : undefined}>
					<SvgWrapper svg="spinner" className={spinnerStyle} data-pp-at-target="saving-spinner" />
					<CSSTransitionGroup
						component="div"
						className={styles.spinnerTextWrapper}
						transitionName="loading-spinner-text"
						transitionEnterTimeout={200}
						transitionLeaveTimeout={200}>
						<div key={loadingText} className={styles.spinnerText} data-pp-at-target="saving-text">{loadingText}</div>
					</CSSTransitionGroup>
				</div>
			</RetryTooltip>
		);
	}
}

@observer
class RetryTooltip extends React.Component<{enabled:boolean}> {
	tooltip = 'Click to save changes';
	@observable visible = false;

	handleVisibleChange = (visible: boolean) => {
		this.visible = this.props.enabled ? visible : false;
	}

	render() {
		return (
			<Tooltip
				visible={this.visible}
				onVisibleChange={this.handleVisibleChange}
				label={this.tooltip}
				placement="bottom"
				tooltipClassName={styles.retryTooltip}
				tooltipContentClassName={styles.retryTooltipContent}
			>
				{this.props.children}
				<TooltipContent>
					<div>{this.tooltip}</div>
				</TooltipContent>
			</Tooltip>
		);
	}
}
