import * as React from 'react';
import { inject, observer } from 'mobx-react';
import {
	BatchTitleInput,
	ListingDisplay,
	EventTimeInput,
	GiftsReceivedOnInput,
	GiftTotalInputs,
} from './batch-entry-batch-details';
import { PaymentTotal } from '../../batch-entry-payments/batch-entry-payments-store';
import { BatchEntryBatchDetailsViewModel } from './batch-entry-batch-details-view-model';
import { BatchEntryUserActionCreator } from '../../../pages/batch-entry/batch-entry-user-actions';
import { BatchEntryDiscrepency } from '../../batch-entry-payments/batch-entry-discrepency';
import { ValidatableForm } from '../../../../components/form-controls/validatable-form';
import { RenderResponsive } from '../../../../components/hoc-behavior/render-responsive';
import { ResponsiveVisibility } from '../../../../helpers/responsive-helper';
import { ResponsiveBreakpoint } from '../../../../helpers/responsive-helper';
import { ModelMetadata } from '../../..//virtual-terminal-generated';

import * as styles from './batch-entry-batch-details.less';

@inject('userActionCreator')
@observer
export class BatchEntryBatchDetailsEdit extends React.Component<{
	vm: BatchEntryBatchDetailsViewModel,
	batchTotal: PaymentTotal,
	batchCount: PaymentTotal,
	userActionCreator?: BatchEntryUserActionCreator }> {
	render() {
		const { vm, batchTotal, batchCount } = this.props;
		const { batch, updateBatchName, listingStore, giftsReceivedOn, updateGiftsReceivedOn, validationErrors } = vm;
		const { selectedListing: { ListingName }, paymentLabel: { NounPluralLowerCase, NounPluralSentenceCase, VerbPresentTenseLowerCase, NounLowerCase } } = listingStore;
		const { ExpectedTotalAmount, ExpectedTotalCount } = batch;
		return (
			<ValidatableForm onSubmit={this.handleSubmit} validationErrors={validationErrors} acceptanceTestTargetId="Edit batch details">
				<div className="panel panel-highlight">
					<header className="panel-heading">
						<div className="display-flex">
							<div className="flex-auto">
								<BatchTitleInput
									label="Batch title"
									requestModelMetadata={ModelMetadata.EditBatchRequestModel}
									batchName={batch.Name}
									updateBatchName={updateBatchName}
								/>
							</div>
							<RenderResponsive visibility={ResponsiveVisibility.Hidden} breakpoints={ResponsiveBreakpoint.Xs}>
								<div className="flex-none">
									<BatchEntryDiscrepency batchTotal={batchTotal}
										expectedTotal={ExpectedTotalAmount}
										batchCount={batchCount}
										expectedCount={ExpectedTotalCount}
										paymentNoun={NounLowerCase}
										paymentNounPlural={NounPluralLowerCase} />
								</div>
							</RenderResponsive>
						</div>
					</header>
					<div className={`panel-body ${styles.panelBody}`}>
						<ListingDisplay listingName={ListingName} />
						<EventTimeInput vm={vm} verbPresentTenseLowerCase={VerbPresentTenseLowerCase} />
						<GiftsReceivedOnInput nounPluralSentenceCase={NounPluralSentenceCase} giftsReceivedOn={giftsReceivedOn} updateGiftsReceivedOn={updateGiftsReceivedOn} />
						<GiftTotalInputs vm={vm} nounPluralLowerCase={NounPluralLowerCase} />
					</div>
					<footer className="panel-footer panel-footer-btn-group">
						<button type="button" onClick={this.handleCancelEdit} className="btn btn-ghost">Cancel</button>
						<button type="submit" className="btn btn-primary">Update</button>
					</footer>
				</div>
			</ValidatableForm>
		);
	}

	private handleCancelEdit = () => {
		const { userActionCreator } = this.props;
		userActionCreator.CancelEditBatch();
	}

	private handleSubmit = () => {
		const { userActionCreator, vm: { batch } } = this.props;
		userActionCreator.UpdateBatch(batch.ListingId, { ...batch });
	}
}
