import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {ModalDialogCommander} from '../../components/modal-dialog-commander';
import {ModalDialog, IModalDialogButton} from '../../components/modal-dialog';

export class CampaignFundErrorModalDialog extends React.Component {

	open() {
		return ModalDialogCommander.open(ReactDOM.findDOMNode(this));
	}

	close() {
		ModalDialogCommander.close(ReactDOM.findDOMNode(this));
	}

	render() {
		const buttons: (IModalDialogButton | JSX.Element)[] = [
			{
				label: 'Close',
				dismiss: true,
				isPrimary: true
			}];
		return <ModalDialog title="" buttons={buttons}>Our system encountered an unexpcted error and we're currently looking into it. Please try again soon</ModalDialog>;
	}
}
