import * as Models from './transaction-import-generated';
import { AddNewFundViewModel } from './components/add-new-fund-dialog';
import { observable, action } from 'mobx';
import { AutocompleteStore, IAutocompleteItem } from '../components/autocomplete';
import { AlertProps, AlertType } from './components/alert-control';
import { getTransactionImportDataService, TransactionImportDataServiceAction, TransactionImportApiConfigType, } from './transaction-import-data-service';
import { IDataServiceActionSubscriber } from '../utils/data-service';

export class TransactionImportFundMatchingViewModel {
	transactionImportId: number;
	merchantId: number;
	cancelUrl: string;

	importedFunds: Models.ImportedFundViewModel[];
	quickBooksManageFundInfo: Models.QuickBooksManageFundInfo;

	pushpayFundStores: AutocompleteStore[];

	@observable
	autoCompleteItems: IAutocompleteItem[];

	@observable
	navigationInProgress: boolean = false;

	@observable
	addNewFundViewModel: AddNewFundViewModel;

	@observable
	alertProps: AlertProps;

	newFundList: number[] = [];

	matchFundsSubscriber: IDataServiceActionSubscriber<TransactionImportApiConfigType, 'matchFunds'>;
	addNewFundSubscriber: IDataServiceActionSubscriber<TransactionImportApiConfigType, 'addNewFund'>;
	cancelImportSubscriber: IDataServiceActionSubscriber<TransactionImportApiConfigType, 'cancelImport'>;

	constructor(model: Models.TransactionImportFundsViewModel) {
		this.transactionImportId = model.TransactionImportId;
		this.merchantId = model.MerchantId;
		this.cancelUrl = model.CancelUrl;

		this.importedFunds = model.ImportedFunds;
		this.quickBooksManageFundInfo = model.QuickBooksManageFundInfo;

		this.autoCompleteItems = model.PushpayFunds.map(o => ({ Text: o.PushpayFundDisplayName, Value: `${o.PushpayFundId}` }));
		this.pushpayFundStores = model.ImportedFunds.map(o => (new AutocompleteStore({Items: this.autoCompleteItems, SelectedValue: `${o.PushpayFundId}`})));

		this.addNewFundViewModel = new AddNewFundViewModel(
			model.FundCodePlaceholderText,
			model.PayerLabel,
			model.IntegrationFundsInfo,
			model.QuickBooksManageFundInfo,
			Models.ModelMetadata.ImportNewFundModel);

		this.matchFundsSubscriber = getTransactionImportDataService().getActionSubscriberFactory('matchFunds')((action) => this.subscribeToMatchFunds(action));
		this.addNewFundSubscriber = getTransactionImportDataService().getActionSubscriberFactory('addNewFund')((action) => this.subscribeToAddNewFund(action));
		this.cancelImportSubscriber = getTransactionImportDataService().getActionSubscriberFactory('cancelImport')((action) => this.subscribeToCancelImport(action));
	}

	@action.bound
	addNewPushayFund(newFund: Models.PushpayFundModel) {
		if (newFund) {
			this.newFundList.push(newFund.PushpayFundId);
			this.autoCompleteItems.push({Text: newFund.PushpayFundDisplayName, Value: `${newFund.PushpayFundId}`});
		}
	}

	@action.bound
	showAlert(type: AlertType, content: string | string[]) {
		this.alertProps = {
			alertType: type,
			alertContent: content,
			showCloseButton: true,
			onClose: () => {
				this.alertProps = null;
			}
		};
	}

	refreshFundMappings() {
		this.importedFunds.forEach((f, index) => {
			const selectedFundId = Number(this.pushpayFundStores[index].selectedValue);

			if (f.PushpayFundId !== selectedFundId) {
				f.PushpayFundId = selectedFundId;
				if (this.newFundList.indexOf(selectedFundId) >= 0) {
					f.FundMatchStatus = Models.TransactionImportFundMatchStatus.ManuallyMatchedToNewFund;
				} else {
					f.FundMatchStatus = Models.TransactionImportFundMatchStatus.ManuallyMatchedToExistingFund;
				}
			}
		});
	}

	@action.bound
	initMatchFundsRequest() {
		this.navigationInProgress = true;
		this.matchFundsSubscriber.initRequest({
			model: {
				TransactionImportId: this.transactionImportId,
				ImportedFunds: this.importedFunds
			},
		});
	}

	@action.bound
	initAddNewFundRequest() {
		this.addNewFundViewModel.addFundInProgress = true;
		this.addNewFundSubscriber.initRequest({
			model: {
				FundName: this.addNewFundViewModel.fundName,
				Code: this.addNewFundViewModel.code,
				Notes: this.addNewFundViewModel.notes,
				TaxDeductible: this.addNewFundViewModel.taxDeductible,
				IntegrationFundMatches: this.addNewFundViewModel.matchedIntegrationFunds,
				QuickBooksIntegrationMapping: this.addNewFundViewModel.matchedQuickBooksConfiguration,
			}
		});
	}

	@action.bound
	initCancelImportRequest() {
		this.navigationInProgress = true;
		this.cancelImportSubscriber.initRequest({
			transactionImportId: this.transactionImportId
		});
	}

	private subscribeToMatchFunds(action: TransactionImportDataServiceAction) {
		switch (action.type) {
			case 'request_success':
				const result =  action.response as Models.TransactionImportOperationResultModel;
				if (result.Result === Models.OperationResult.Success) {
					window.location.href = result.SuccessUrl;
					return;
				} else {
					this.showAlert(AlertType.Danger, result.ErrorMessage);
				}
				break;
			case 'request_error':
				const validationErrors = action.error.validationErrors;
				if (validationErrors) {
					this.showAlert(AlertType.Danger, Object.keys(validationErrors).map(x => validationErrors[x]));
				}
				break;
		}
		this.navigationInProgress = false;
	}

	private subscribeToAddNewFund(action: TransactionImportDataServiceAction) {
		switch (action.type) {
			case 'request_success':
				if (action.response.Result === Models.OperationResult.Success) {
					this.addNewPushayFund(action.response.NewFund);
					this.addNewFundViewModel.closeDialog();
					this.showAlert(AlertType.Success, 'Your new fund has been successfully created.');
				} else {
					this.addNewFundViewModel.showAlert(AlertType.Danger, action.response.ErrorMessage);
				}
				break;
			case 'request_error':
				const validationErrors = action.error.validationErrors;
				if (validationErrors) {
					this.addNewFundViewModel.showAlert(AlertType.Danger, Object.keys(validationErrors).map(x => validationErrors[x]));
				}
				break;
		}

		this.addNewFundViewModel.addFundInProgress = false;
	}

	private subscribeToCancelImport(action: TransactionImportDataServiceAction) {
		switch (action.type) {
			case 'request_success':
				if (action.response.Result === Models.OperationResult.Success) {
					window.location.href = this.cancelUrl;
					return;
				} else {
					this.showAlert(AlertType.Danger, action.response.ErrorMessage);
				}
			break;
		}
		this.navigationInProgress = false;
	}
}
