import * as React from 'react';
import { AccessibilityOption } from '../hoc-accessibility/accessibility-option';
import { SvgWrapper } from '../svg-wrapper';
import { IPayerViewModel, IPayerSearchResult } from './payer-search-models';
import { IHaveOmniboxContext, OmniboxContextKey, LoadMoreId, EmptyId } from './payer-search-omnibox-context';
import { inject, observer } from 'mobx-react';
import { ScrollableContainerItem } from '../hoc-behavior/scrollable-container';
import { Country } from '../../loggedinweb-generated';

@inject(OmniboxContextKey)
@observer
export class PayerSearchDropDownMenuItem extends React.Component<IHaveOmniboxContext & {
	selectedPayer: IPayerViewModel;
	payer: IPayerViewModel;
	enableFeatureOrganizationalGiving: boolean;
}, {}> {
	render() {
		const {payer, selectedPayer, omniboxContext: {highlightedItemId, ensureVisibleId, omniboxFocused}} = this.props;
		const isSelected = selectedPayer === payer;
		const isHighlighted = omniboxFocused && highlightedItemId === payer.id;

		let fullAddress = null;
		fullAddress = payer.displayAddress;
		if (payer.postCode) {
			fullAddress = fullAddress ? `${fullAddress} ${payer.postCode}` : `${this.getZipCode(payer)}`;
		}

		let iconName = null;
		if (payer.isRegistered) {
			iconName = 'member-fill';
		} else {
			iconName = 'member-outline';
		}

		if(this.props.enableFeatureOrganizationalGiving && payer.isOrganization) {
			iconName = 'member-organization';
		}

		return (
			<ScrollableContainerItem tryScrollIntoView={ensureVisibleId === payer.id}>
				<AccessibilityOption id={this.props.omniboxContext.getListItemId(payer.id)}>
					<li className={isHighlighted ? 'hover' : ''}
						onClick={this.handleClick}
						onMouseMove={this.handleMouseMove}>
						<button tabIndex={-1} className={`btn btn-link${isSelected ? ' active' : ''} payer-search-dropdown-item`} type="button">
							<div className="payer-search-dropdown-item-icon">
								<SvgWrapper className="svg" svg={iconName} />
							</div>
							<div>
								<strong className="payer-search-dropdown-item-info">{payer.name}</strong>
								{payer.emailAddress ?
									<small className="payer-search-dropdown-item-info">{payer.emailAddress}</small>
									: null}
								{this.renderMobileNumberAndZipCode(payer)}
								{fullAddress &&
								<small className="payer-search-dropdown-item-info">
									<span>{fullAddress}</span>
								</small>
								}
								{NewFeatures.NewGiftEntryCommunityMemberSearchResultLabels && payer.campusName &&
									<small className="payer-search-dropdown-item-info">
										<span>{payer.campusName}</span>
									</small>
								}
								{NewFeatures.NewGiftEntryCommunityMemberSearchResultLabels && payer.primaryExternalId &&
									<small className="payer-search-dropdown-item-info">
										<span>{payer.primaryExternalId}</span>
									</small>
								}
							</div>
						</button>
					</li>
				</AccessibilityOption>
			</ScrollableContainerItem>
		);
	}

	private renderMobileNumberAndZipCode(payer: IPayerViewModel) {
		return <small className="payer-search-dropdown-item-info">
			{this.renderMobileNumber(payer.mobileNumber)}
		</small>;
	}

	private renderMobileNumber(mobileNumber: string) {
		if (!mobileNumber) {
			return null;
		}
		return <span>{mobileNumber}</span>;
	}

	private getZipCode(payer: IPayerViewModel) {
		if(!payer.postCode) {
			return null;
		}
		const zipCodeDisplayName = payer.country === Country.US ? 'Zip code' : 'Post code';
		return `${zipCodeDisplayName}: ${payer.postCode}`;
	}

	private handleMouseMove = () => {
		const {omniboxContext, payer} = this.props;
		omniboxContext.handleItemHighlighted(payer.id);
	}

	private handleClick = () => {
		const {omniboxContext, payer} = this.props;
		omniboxContext.handleItemSelected(payer.id);
	}
}

@inject(OmniboxContextKey)
@observer
export class PayerSearchLoadMoreItem extends React.Component<IHaveOmniboxContext & {
	inProgress: boolean;
}, {}> {
	render() {
		const {omniboxContext: {highlightedItemId, ensureVisibleId, omniboxFocused}, inProgress} = this.props;
		const isHighlighted = omniboxFocused && highlightedItemId === LoadMoreId;

		return (
			<ScrollableContainerItem tryScrollIntoView={ensureVisibleId === LoadMoreId}>
				<AccessibilityOption id={this.props.omniboxContext.getListItemId(LoadMoreId)}>
					<li onClick={this.handleClick}
						onMouseMove={this.handleMouseMove}
						className={(isHighlighted ? ' hover' : '')}>
						<button className="btn btn-xs btn-link" tabIndex={-1} type="button">
							{`Load more${inProgress ? '...' : ''}`}
						</button>
					</li>
				</AccessibilityOption>
			</ScrollableContainerItem>
		);
	}

	private handleMouseMove = () => {
		const {omniboxContext} = this.props;
		omniboxContext.handleItemHighlighted(LoadMoreId);
	}

	private handleClick = () => {
		const {omniboxContext} = this.props;
		omniboxContext.handleItemSelected(LoadMoreId);
	}
}

@inject(OmniboxContextKey)
@observer
export class PayerSearchDownDownMenuEmptyItem extends React.Component<IHaveOmniboxContext & {
	searchResult: IPayerSearchResult;
	searchValue: string;
}, {}> {
	render() {
		const {omniboxContext, searchResult, searchValue} = this.props;

		if (searchResult.error) {
			return (
				<EmptyScrollableAccessibilityOption omniboxContext={omniboxContext}>
					<span className="field-validation-error">
						Sorry, something went wrong searching for ‘<strong>{searchValue}</strong>’. We have been notified and will look into what happened.
					</span>
				</EmptyScrollableAccessibilityOption>
			);
		}

		if (searchValue) {
			return (
				<EmptyScrollableAccessibilityOption omniboxContext={omniboxContext}>
					There is no ‘<strong>{searchValue}</strong>’ found.
				</EmptyScrollableAccessibilityOption>
			);
		}

		return (
			<EmptyScrollableAccessibilityOption omniboxContext={omniboxContext}>
				No matching Community Members were found.
			</EmptyScrollableAccessibilityOption>
		);
	}
}

export const EmptyScrollableAccessibilityOption = props =>
	<ScrollableContainerItem tryScrollIntoView={props.omniboxContext.ensureVisibleId === EmptyId}>
		<AccessibilityOption id={props.omniboxContext.getListItemId(EmptyId)}>
			<li className="dropdown-header">{props.children}</li>
		</AccessibilityOption>
	</ScrollableContainerItem>;
