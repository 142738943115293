import * as React from 'react';
import * as styles from './styles.less';

export interface IProgressCircleProps {
	progressPercentage: number;
	borderWidth: number;
	size: number;
}

export class ProgressCircle extends React.Component<IProgressCircleProps> {

	private progressIndicator:  SVGPathElement | null;

	componentDidMount() {
		this.calculateStroke();
	}

	componentDidUpdate() {
		this.calculateStroke();
	}

	render() {
		const { size, borderWidth } = this.props;
		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 160 160" width={size} height={size} className={styles.progressCircle}>
				<path className={styles.progressCircleBorder}
					d="m3,80c0,-42.54144 34.45856,-77 77,-77c42.54144,0 77,34.45856 77,77c0,42.54144 -34.45856,77 -77,77c-42.54144,0 -77,-34.45856 -77,-77z"
					strokeWidth={borderWidth} fill="none"/>
				<path ref={el => this.progressIndicator = el}
					d="m3,80c0,-42.54144 34.45856,-77 77,-77c42.54144,0 77,34.45856 77,77c0,42.54144 -34.45856,77 -77,77c-42.54144,0 -77,-34.45856 -77,-77z"
					strokeWidth={borderWidth} strokeLinecap="butt" fill="none"/>
			</svg>
		);
	}

	private calculateStroke() {
		if(this.progressIndicator) {
			const { progressPercentage } = this.props;
			const circumference = this.progressIndicator.getTotalLength();
			const percentage = Math.min(progressPercentage, 1); // max to 100%
			this.progressIndicator.style.strokeDasharray = `${circumference}`;
			this.progressIndicator.style.strokeDashoffset = `${circumference - (circumference * percentage)}`;
		}
	}
}
