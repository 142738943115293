import * as React from 'react';
import { isFunction } from '../../utils/is-function';
import { inputPropsToShowNumericKeyboard } from '../../utils/input-utils';

export interface ITextboxCommonProps {
	acceptanceTestTargetId: string;
	formControlId?: string;
	placeholder?: string;
	additionalClassNames?: string;
	type?: string;
	labelledBy?: string;
	maxLength?: number;
	numericKeyboard?: boolean;
	name?: string;
	disabled?: boolean;
	autoComplete?: string;
	pattern?: string;
	ariaDescribedBy?: string;
	ariaRequired?: boolean;
}

export interface ITextboxPrimitiveProps {
	value: string;
	ariaInvalid?: boolean;
	onChange?: (value: string) => void;
	onBlur?: () => void;
}

export type ITextboxProps = ITextboxCommonProps & ITextboxPrimitiveProps;

export class Textbox extends React.Component<ITextboxProps, {}> {
	input: HTMLInputElement;

	render() {
		const {
			formControlId,
			acceptanceTestTargetId = null,
			type = 'text',
			additionalClassNames,
			labelledBy,
			ariaDescribedBy,
			ariaRequired,
			ariaInvalid,
			numericKeyboard,
			value,
			...rest } = this.props;

		const valueOrEmptyStringToEnsureThisIsAControlledComponent = value || '';

		return (
			<input
				className={additionalClassNames}
				{...rest}
				type={type}
				spellCheck={false}
				id={formControlId}
				value={valueOrEmptyStringToEnsureThisIsAControlledComponent}
				onChange={this.handleChange}
				onBlur={this.handleBlur}
				data-pp-at-target={acceptanceTestTargetId}
				aria-labelledby={labelledBy}
				aria-describedby={ariaDescribedBy}
				aria-required={ariaRequired}
				aria-invalid={ariaInvalid || undefined}
				name={name}
				{...(numericKeyboard ? inputPropsToShowNumericKeyboard() : undefined)}
			/>
		);
	}

	private handleChange = (event: React.FormEvent<HTMLInputElement>) => {
		const { onChange } = this.props;

		if (isFunction(onChange)) {
			onChange(event.currentTarget.value);
		}
	}

	private handleBlur = () => {
		const { onBlur } = this.props;

		if (isFunction(onBlur)) {
			onBlur();
		}
	}
}

