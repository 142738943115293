import * as React from 'react';

/* tslint:disable:max-line-length */
export const authorizeIcon: JSX.Element = <svg viewBox="0 0 160 160" xmlns="http://www.w3.org/2000/svg">
	<title>Authorize</title>
	<defs>
		<linearGradient x1="50%" y1="1.59239477%" x2="72.9370117%" y2="94.5955301%" id="lg1">
			<stop stopColor="#000000" offset="0%" />
			<stop stopColor="#000000" stopOpacity="0" offset="100%" />
		</linearGradient>
		<linearGradient x1="75.3491211%" y1="98.2873535%" x2="19.2053223%" y2="1.85180664%" id="lg2">
			<stop stopColor="#E3DFC8" offset="0%" />
			<stop stopColor="#FFFFFF" offset="100%" />
		</linearGradient>
	</defs>
	<mask id="sm1" fill="white">
		<circle cx="80" cy="80" r="80" />
	</mask>
	<circle fill="#4DA5BB" stroke="none" cx="80" cy="80" r="80" mask="sm1" />
	<polygon fillOpacity="0.25" fill="url(#lg1)" stroke="none" mask="url(#sm1)" points="51 46 130 46 171 114 51 115" />
	<rect fill="url(#lg2)" fillRule="evenodd" stroke="none" x="30" y="45" width="100" height="73" rx="2" />
	<rect strokeOpacity="0.1" stroke="#000000" strokeWidth="2" fill="none" x="29" y="44" width="102" height="75" rx="2" />
	<path fill="#FFFFFF" stroke="none" d="M30,46.9904333 C30,45.8911474 30.8950515,45 31.9975379,45 L128.002462,45 C129.105672,45 130,45.896653 130,46.9904333 L130,116.009567 C130,117.108853 129.104949,118 128.002462,118 L31.9975379,118 C30.8943282,118 30,117.103347 30,116.009567 L30,46.9904333 Z" />
	<path fillOpacity="0.7" fill="url(#lg2)" stroke="none" d="M30,46.9904333 C30,45.8911474 30.8950515,45 31.9975379,45 L128.002462,45 C129.105672,45 130,45.896653 130,46.9904333 L130,116.009567 C130,117.108853 129.104949,118 128.002462,118 L31.9975379,118 C30.8943282,118 30,117.103347 30,116.009567 L30,46.9904333 Z" />
	<rect fill="#4D4D4D" stroke="none" x="60" y="97" width="40" height="12" rx="2" />
	<path fill="#757575" fillRule="nonzero" stroke="none" d="M122,52.9890478 L124.912497,50 L125.795117,50.9058183 L122.88262,53.8948661 L126,57.0941817 L125.11738,58 L122,54.8006844 L118.88262,58 L118,57.0941817 L121.11738,53.8948661 L118.204883,50.9058183 L119.087503,50 L122,52.9890478 Z" />  <circle fill="#2CA01C" fillRule="nonzero" stroke="none" id="circle4032" r="8.5" cy="63.5" cx="60.5" />
	<path fill="#FFFFFF" fillRule="nonzero" stroke="none" d="m 55,64 c 0,1.638133 1.325926,2.966102 2.961539,2.966102 h 0.423076 V 65.864407 H 57.961539 C 56.933439,65.864407 56.1,65.029683 56.1,64 c 0,-1.029683 0.833439,-1.864407 1.861539,-1.864407 h 1.015384 v 5.762712 c 0,0.608449 0.492487,1.101695 1.1,1.101695 V 61.033898 H 57.961539 C 56.325926,61.033898 55,62.361867 55,64 Z m 8.038462,-2.966102 h -0.423077 v 1.101695 h 0.423077 C 64.066561,62.135593 64.9,62.970317 64.9,64 c 0,1.029683 -0.833439,1.864407 -1.861538,1.864407 H 62.023077 V 60.101695 C 62.023077,59.493246 61.53059,59 60.923077,59 v 7.966102 h 2.115385 C 64.674074,66.966102 66,65.638133 66,64 66,62.361867 64.674074,61.033898 63.038462,61.033898 Z" />
	<path fill="#757575" stroke="none" d="M76,66 C75.4477153,66 75,65.5522847 75,65 C75,64.4477153 75.4477153,64 76,64 C76.5522847,64 77,64.4477153 77,65 C77,65.5522847 76.5522847,66 76,66 Z M80,66 C79.4477153,66 79,65.5522847 79,65 C79,64.4477153 79.4477153,64 80,64 C80.5522847,64 81,64.4477153 81,65 C81,65.5522847 80.5522847,66 80,66 Z M84,66 C83.4477153,66 83,65.5522847 83,65 C83,64.4477153 83.4477153,64 84,64 C84.5522847,64 85,64.4477153 85,65 C85,65.5522847 84.5522847,66 84,66 Z" />
	<path fill="#EC1C24" stroke="none" d="M 108,63.500104 C 108,68.194392 104.19437,72 99.500156,72 94.805631,72 91,68.194392 91,63.500104 91,58.805815 94.805631,55 99.500156,55 104.19437,55 108,58.805815 108,63.500104 Z" />
	<path fill="#FFFFFF" stroke="none" d="M 95.482804,59 94,68 h 0.482751 L 96,59 Z m 6.501576,3.008965 c -0.044,0.289499 -0.17319,0.526808 -0.38761,0.712613 C 101.38226,62.907155 101.04533,63 100.5849,63 H 100 l 0.30377,-2 h 0.67976 c 0.44616,0 0.73556,0.09776 0.86732,0.293382 0.13156,0.19574 0.17614,0.43442 0.13353,0.715583 z m 2.60199,-2.290529 C 104.20704,59.239593 103.5599,59 102.64484,59 H 98.442993 L 97,68 h 2.53928 l 0.53533,-3.339519 h 1.38411 c 1.01917,0 1.81846,-0.255758 2.39703,-0.767617 0.57847,-0.51117 0.9407,-1.221353 1.08649,-2.130088 0.14163,-0.884203 0.0227,-1.565611 -0.35587,-2.04434 z M 96.482723,59 95,68 h 0.482931 L 97,59 Z" />
	<rect fill="#B2B2B2" stroke="none" x="56" y="86" width="48" height="3" />
	<rect fill="#B2B2B2" stroke="none" x="41" y="80" width="78" height="3" />
</svg>;

export const matchIcon: JSX.Element = <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 160 160">
	<title>Match Accounts</title>
	<defs>
		<linearGradient id="lg3" y2="55.2428653%" x2="57.9747036%" y1="6.05464802%" x1="45.9564856%">
			<stop offset="0%" stopColor="#000000"/>
			<stop offset="100%" stopOpacity="0" stopColor="#000000"/>
		</linearGradient>
		<linearGradient id="lg4" y2="1.85180664%" x2="19.2053223%" y1="98.2873535%" x1="75.3491211%">
			<stop offset="0%" stopOpacity="0.636209239" stopColor="#E3DFC8"/>
			<stop offset="100%" stopColor="#FFFFFF"/>
		</linearGradient>
	</defs>
	<mask id="sm2" fill="white">
		<circle cx="80" cy="80" r="80" />
	</mask>
	<circle fill="#FFBA40" stroke="none" cx="80" cy="80" r="80" mask="sm2" />
	<path fill="url(#lg3)" fillOpacity="0.150000006" stroke="none" mask="url(#sm2)" d="M28,68 L134.013672,68 L154.839844,123.629883 C154.839844,123.629883 144.513288,160.510299 127.5625,168.359375 C110.611712,176.208451 105.703125,228.09375 85.2480469,197.001953 C64.7929688,165.910156 28,92.1289063 28,92.1289063 L28,68 Z"/>
	<rect fillRule="evenodd" fill="#FFFFFF" stroke="none" rx="2" height="25.6914894" width="105" y="67" x="28"/>
	<rect fill="none" rx="2" height="27.6914894" width="107" y="66" x="27" strokeWidth="2" stroke="#262626" strokeOpacity="0.183395607"/>
	<rect fill="#FFFFFF" stroke="none" rx="2" height="25.6914894" width="105" y="67" x="28"/>
	<rect fill="url(#lg4)" fillOpacity="0.699999988" rx="2" height="25.6914894" width="105" y="67" x="28"/>
	<rect height="3.35106383" width="45" y="78" x="57" fill="#757575" stroke="none" />
	<path fill="#979797" stroke="#979797" d="M119.594229,80.7160733 L123.920252,77.0869237 C124.052698,76.9710254 124.185941,76.9710254 124.318387,77.0869237 L125.064391,77.7383274 C125.196836,77.8688874 125.204815,77.9847856 125.089124,78.086022 L119.792898,82.5201755 C119.74343,82.5634627 119.677207,82.5851064 119.594229,82.5851064 C119.511251,82.5851064 119.445029,82.5634627 119.395561,82.5201755 L114.099334,78.086022 C113.966889,77.9701238 113.966889,77.8542256 114.099334,77.7383274 L114.870072,77.0869237 C115.002517,76.9710254 115.135761,76.9710254 115.268206,77.0869237 L119.594229,80.7160733 Z" />
	<path fill="#27a86c" fillRule="nonzero" stroke="none" d="M 50,80 C 50,75.581734 46.418214,72 41.999876,72 37.581591,72 34,75.581681 34,80 c 0,4.418319 3.581591,8 7.999876,8 C 46.418214,88 50,84.418266 50,80 Z"/>
	<path d="M 40.741932,82.347816 44.944676,77.413046 45.296451,77 46,77.826092 45.648226,78.239138 41.093706,83.586954 40.741932,84 40.599988,83.833333 40.248214,83.420287 38.351774,81.19353 38,80.780484 l 0.703549,-0.826092 0.351775,0.413046 z" fill="#ffffff" fillRule="nonzero" stroke="none" />
</svg>;

export const setupIcon: JSX.Element =
	<svg viewBox="0 0 160 160" version="1.1" xmlns="http://www.w3.org/2000/svg">
		<title>Setup</title>
		<defs>
			<linearGradient x1="50%" y1="1.59239477%" x2="72.9370117%" y2="94.5955301%" id="lg5">
				<stop stopColor="#000000" offset="0%" />
				<stop stopColor="#000000" stopOpacity="0" offset="100%" />
			</linearGradient>
			<linearGradient x1="75.3491211%" y1="98.2873535%" x2="19.2053223%" y2="1.85180664%" id="lg6">
				<stop stopColor="#E3DFC8" offset="0%" />
				<stop stopColor="#FFFFFF" offset="100%" />
			</linearGradient>
		</defs>
		<mask id="sm3" fill="white">
			<circle cx="80" cy="80" r="80" />
		</mask>
		<circle fill="#CB697E" stroke="none" cx="80" cy="80" r="80" mask="sm3" />
		<polygon fillOpacity="0.25" fill="url(#lg5)" mask="url(sm3)" points="52 48 129 48 172 117 52 117" />
		<rect strokeOpacity="0.1" stroke="#000000" fill="none" strokeWidth="2" x="26" y="46" width="107" height="72" rx="2" />
		<path d="m 27,50 h 105 v 65 c 0,1.10457 -0.89543,2 -2,2 H 29 c -1.10457,0 -2,-0.89543 -2,-2 z" fill="#ffffff" stroke="none" />
		<path d="m 27,50 h 105 v 65 c 0,1.10457 -0.89543,2 -2,2 H 29 c -1.10457,0 -2,-0.89543 -2,-2 z" fill="url(#lg6)" fillOpacity="0.7" stroke="none" />
		<path d="m 29,47 h 101 c 1.10457,0 2,0.895431 2,2 v 1 H 27 v -1 c 0,-1.104569 0.89543,-2 2,-2 z" fill="#757575" stroke="none" />
		<polygon fill="#757575" stroke="none" points="58 60 116.513672 60 116.513672 62.3859649 58 62.3859649"/>
		<polygon fill="#757575" stroke="none" points="36 84 56.5175781 84 56.5175781 86.3859649 36 86.3859649"/>
		<polygon fill="#757575" stroke="none" points="36 104 56.5175781 104 56.5175781 106.385965 36 106.385965"/>
		<polygon fill="#757575" stroke="none" points="58 66 98.6658723 66 98.6658723 68.3859649 58 68.3859649"/>
		<polygon fill="#757575" stroke="none" points="36 94 50.2593207 94 50.2593207 96.3859649 36 96.3859649"/>
		<polygon points="63 82 92 82 92 89 63 89" fill="#FFFFFF" fillRule="evenodd"/>
		<path stroke="#CCCCCC" strokeWidth="1" d="M63.5,82.5 L63.5,88.5 L91.5,88.5 L91.5,82.5 L63.5,82.5 Z" fill="none"/>
		<polygon points="63 92 92 92 92 99 63 99" fill="#FFFFFF" fillRule="evenodd" stroke="none"/>
		<path stroke="#CCCCCC" strokeWidth="1" d="M63.5,92.5 L63.5,98.5 L91.5,98.5 L91.5,92.5 L63.5,92.5 Z" fill="none"/>
		<polygon points="63 102 92 102 92 109 63 109" fill="#FFFFFF" fillRule="evenodd" stroke="none"/>
		<path stroke="#CCCCCC" strokeWidth="1" d="M63.5,102.5 L63.5,108.5 L91.5,108.5 L91.5,102.5 L63.5,102.5 Z" fill="none"/>
		<polygon points="95 82 124 82 124 89 95 89" fill="#FFFFFF" fillRule="evenodd" stroke="none"/>
		<path stroke="#CCCCCC" strokeWidth="1" d="M95.5,82.5 L95.5,88.5 L123.5,88.5 L123.5,82.5 L95.5,82.5 Z" fill="none"/>
		<polygon points="95 92 124 92 124 99 95 99" fill="#FFFFFF" fillRule="evenodd" stroke="none"/>
		<path stroke="#CCCCCC" strokeWidth="1" d="M95.5,92.5 L95.5,98.5 L123.5,98.5 L123.5,92.5 L95.5,92.5 Z" fill="none"/>
		<polygon points="95 102 124 102 124 109 95 109" fill="#FFFFFF" fillRule="evenodd" stroke="none"/>
		<path stroke="#CCCCCC" strokeWidth="1" d="M95.5,102.5 L95.5,108.5 L123.5,108.5 L123.5,102.5 L95.5,102.5 Z" fill="none"/>
		<rect stroke="#B2B2B2" strokeWidth="2" fill="none" x="37" y="59" width="11.122807" height="11.122807" />
		<path d="M43.6072462,65.3155073 L51.1287072,57.4875422 L51.5911113,57 L52.5159194,57.9750844 L52.0535153,58.4626266 L44.0666458,66.7749659 L43.6042417,67.2625081 L43.4176582,67.0657812 L42.9552541,66.578239 L40.4624041,63.9498679 L40,63.4623257 L40.9248082,62.4872413 L41.3872122,62.9747835 L43.6072462,65.3155073 Z" stroke="#27A86C" fill="#27A86C" fillRule="nonzero"/>
	</svg>;
/* tslint:enable:max-line-length */
