import * as React from 'react';
import {TipBox} from '../../components/tipbox';
import {Carousel} from '../../components/carousel';
import {CarouselSlide} from '../../components/carousel-slide';
import {TutorialSlide} from '../../components/tutorial-slide';
import {Models} from '../check-deposit-generated';
import PaymentLabel = Models.PaymentLabel;

export class BatchListHelp extends React.Component<{payButtonLabel: PaymentLabel}> {
	render() {
		const {payButtonLabel} = this.props;
		return (
		<TipBox storageKey="check-deposit-batch-list-help" extraClassNames="carousel-container">
			<Carousel slideDelay={20000}>
				<CarouselSlide key="open-new-check-deposit">
					<TutorialSlide imageSource="/Content/LoggedInWeb/assets/images/check-deposit/create-check-deposit.gif" imgWidth={430} imgHeight={242}>
						<h2>Creating a new check deposit</h2>
						<p>Clicking the ‘Open check scanning portal’ link will take you to our check scanning portal in a new window.
							Once there, click on transactions and select the option ‘Remote Deposit Complete’ to create
							a new deposit.</p>
					</TutorialSlide>
				</CarouselSlide>
				<CarouselSlide key="scanning-checks">
					<TutorialSlide imageSource="/Content/LoggedInWeb/assets/images/check-deposit/scan-your-checks.gif" imgWidth={430} imgHeight={242}>
						<h2>Scanning your checks</h2>
						<p>Before scanning checks, please make sure to count and enter the number of checks and the
							total amount. Scan checks one by one or in bulk depending on your scanner.</p>
					</TutorialSlide>
				</CarouselSlide>
				<CarouselSlide key="complete-and-close">
					<TutorialSlide imageSource="/Content/LoggedInWeb/assets/images/check-deposit/complete-and-close-deposit.gif" imgWidth={430} imgHeight={242}>
						<h2>Complete and close your deposit</h2>
						<p>To submit the batch for processing, first click the ‘Complete’ button and then select the
							deposit from the list and click ‘Close’. It may take up to an hour for your deposit to be
							ready for allocation in the Pushpay admin portal.</p>
					</TutorialSlide>
				</CarouselSlide>
				<CarouselSlide key="allocate-checks">
					<TutorialSlide imageSource="/Content/LoggedInWeb/assets/images/check-deposit/allocate.gif" imgWidth={430} imgHeight={242}>
						<h2>Allocating your checks</h2>
						<p>In the Pushpay portal, find deposits marked ‘Ready for allocation’. Click on the deposit and
							begin your allocation by entering the {payButtonLabel.PayerLowerCase}’s name and assigning the check to the
							appropriate fund. You can use the Batch Settings to set ‘{payButtonLabel.NounLowerCase} date’ and ‘fund’ for all
							checks in the deposit.</p>
					</TutorialSlide>
				</CarouselSlide>
				<CarouselSlide key="complete-allocation">
					<TutorialSlide imageSource="/Content/LoggedInWeb/assets/images/check-deposit/allocation-complete.gif" imgWidth={430} imgHeight={242}>
						<h2>Complete your allocation</h2>
						<p>When you’ve allocated all the checks in a deposit, click ‘Complete allocation’. The deposit
							will also be moved from ‘In progress’ to the ‘Completed’ section and checks will be
							available for review in the transactions tab.</p>
					</TutorialSlide>
				</CarouselSlide>
			</Carousel>
		</TipBox>
		);
	}
}

