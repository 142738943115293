import * as React from 'react';
import { ResponseTypes } from '../../funds/funds-generated';
import IntegrationStatusLabel = ResponseTypes.IntegrationStatusLabel;

export class IntegrationLabels extends React.Component<{labels: IntegrationStatusLabel[]}, {}> {
	get labels() {
		return this.props.labels;
	}

	renderLabel = (label: ResponseTypes.IntegrationStatusLabel) => {
		return <span className={label.CssClass} key={label.Label}>{label.Label}</span>;
	}

	render() {
		return <span className="integration-labels">{this.labels.map(this.renderLabel)}</span>;
	}
}
