import * as React from 'react';
import { ModalDialog } from '../modal-dialog';
import { StandardErrorTitle } from '../modal-dialog-commander';

export class EventTimeErrorDialog extends React.Component<{
	message: string,
}> {
	render() {
		const { message } = this.props;
		return (
			<ModalDialog title={StandardErrorTitle}>
				<p>{message}</p>
			</ModalDialog>
		);
	}
}
