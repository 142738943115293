export type FileUploadStateEmpty = {
	viewState: FileUploadViewState.Empty;
};

export type FileUploadStateFileSelected = {
	viewState: FileUploadViewState.FileSelected;
	previewUrl: string;
	value: string;
};

export type FileUploadStateError = {
	viewState: FileUploadViewState.Error;
	uploadError: string;
};

export type FileUploadState =
	FileUploadStateEmpty |
	FileUploadStateFileSelected |
	FileUploadStateError;

export enum FileUploadViewState {
	Unknown = 0,
	Empty = 1,
	FileSelected = 2,
	Processing = 3,
	Uploading = 4,
	Error = 5,
}
