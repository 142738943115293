import * as React from 'react';
import { MultiSelectItem } from '../../loggedinweb-generated';
import { SvgWrapper } from '../../../Shared/components/svg-wrapper';
import { observer } from 'mobx-react';

import * as styles from './styles.less';

export interface IMultiSelectedItemsProps {
	options: MultiSelectItem[];
	selectedValues: string[];
	valuesChanged: (values: string[]) => void;
	title?: string;
	className?: string;
}

@observer
export class MultiSelectedItems extends React.Component<IMultiSelectedItemsProps> {

	private readonly mappedSelectedItems: {[value: string]: MultiSelectedItem};

	constructor(props) {
		super(props);
		this.mappedSelectedItems = this.props.options.reduce((mapped, option) => {
			mapped[option.Value] = option;
			return mapped;
		}, {});
	}

	render() {
		const { title, className } = this.props;
		return (
			<div className={`${styles.listWrapper} ${className && className}`}>
				{title && <h2 className={styles.itemListHeading}>{title}:</h2>}
				<ul className={styles.itemList}>
					{
						this.getListOfItems().map(option => <MultiSelectedItem item={option} key={option.Value} removeItem={this.removeItem}/>)
					}
				</ul>
			</div>
		);
	}

	private getListOfItems(): MultiSelectItem[] {
		const { options, selectedValues } = this.props;
		if(!selectedValues || selectedValues.length === 0) {
			return options;
		}

		return selectedValues.reduce((mapped, value) => {
			mapped.push(this.mappedSelectedItems[value]);
			return mapped;
		}, []);
	}

	private removeItem = (itemValue: string) => {
		const { selectedValues, options, valuesChanged } = this.props;
		// get list of all options if none are selected
		const normalizedOptions = selectedValues.length ? selectedValues : options.map(option => option.Value);
		const newValues = normalizedOptions.filter(value => value !== itemValue);
		valuesChanged(newValues);
	}
}

export interface IMultiSelectedItemProps {
	item: MultiSelectItem;
	removeItem: (value: string) => void;
}

export class MultiSelectedItem extends React.Component<IMultiSelectedItemProps> {
	render() {
		const { item } = this.props;
		return (
			<li className={styles.item} onClick={this.itemClicked}>
				<span className={styles.itemContent}>{item.SelectedText || item.Text}</span>
				<SvgWrapper svg="close-cross-small" className={styles.closeIcon} />
			</li>
		);
	}

	private itemClicked = () => {
		this.props.removeItem(this.props.item.Value);
	}
}
