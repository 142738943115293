import { observable } from 'mobx';
import { Models } from '../../community-member/community-member-generated';
import { getPayerName } from '../../utils/getPayerName';
import { IVirtualTerminalPayerViewModel } from '../components/payer-search/payer-view-model';
import { IMemberViewModel } from '../components/member/member-view-model';

export function communityMemberModelToViewModel(model: IMemberViewModel): IVirtualTerminalPayerViewModel {
	//display address does not include country and zip code
	const addressParts = model.Address && [model.Address.Line1, model.Address.Line2, model.Address.City, model.Address.State];

	const country = model.Address && model.Address.Country;

	if (NewFeatures.NewGiftEntryCommunityMemberSearchResultLabels) {
		return {
			displayAddress: model.Address && addressParts.filter((x) => x).join(', '),
			emailAddress: model.EmailAddress,
			id: `${model.CommunityMemberId || ''}`,
			isAnonymous: model.IsAnonymous,
			mobileNumber: model.MobileNumber,
			name: getPayerName(model),
			postCode: model.Address && model.Address.Postcode,
			existingPaymentMethods: observable.box({ PaymentMethods: [], HasMorePages: false }),
			country: country,
			isOrganization: model.CommunityMemberType === Models.CommunityMemberType.Organization,
			campusName: model.CampusName,
			primaryExternalId: model.PrimaryExternalId,
		};
	}

	return {
		displayAddress: model.Address && addressParts.filter(x => x).join(', '),
		emailAddress: model.EmailAddress,
		id: `${model.CommunityMemberId || ''}`,
		isAnonymous: model.IsAnonymous,
		mobileNumber: model.MobileNumber,
		name: getPayerName(model),
		postCode: model.Address && model.Address.Postcode,
		existingPaymentMethods: observable.box({ PaymentMethods: [], HasMorePages: false }),
		country: country,
		isOrganization: model.CommunityMemberType === Models.CommunityMemberType.Organization,
	};
}

export const anonymousMember = <IMemberViewModel>{
	CommunityMemberId: null,
	FirstName: 'Anonymous',
	LastName: 'Payer',
	IsAnonymous: true
};
