import { init as initInternationalMobileInput } from './internationalMobileInput';
import { MessageBox } from './message-box';
import { LoginTabs } from './login-tabs';
import { EmailPasswordForm } from './form-email-password';
import { MobileForm } from './form-mobile';
import { MobileCodeForm } from './form-mobile-code';
import { Models } from './login.generated';

export class LoginPage {
	private emailAndPasswordForm: EmailPasswordForm;
	private adminForm: EmailPasswordForm;
	private mobileForm: MobileForm;
	private mobileCodeForm: MobileCodeForm;
	private loginTabs: LoginTabs;

	constructor(clientSideTestData: Models.LoginPageClientSideTestData) {
		const messageBox = new MessageBox(clientSideTestData.InitialErrorMessage);
		this.loginTabs = new LoginTabs(messageBox);
		this.adminForm = new EmailPasswordForm(Models.LoginView.AdminEmailAndPassword, this.loginTabs, messageBox);
		this.mobileCodeForm = new MobileCodeForm(messageBox, this.loginTabs, clientSideTestData.ShowTxtSentBanner, this.adminForm);
		this.mobileForm = new MobileForm(messageBox, this.loginTabs, this.mobileCodeForm);
		this.emailAndPasswordForm = new EmailPasswordForm(Models.LoginView.PayerEmailAndPassword, this.loginTabs, messageBox);

	}
}

export function init(clientSideTestData: Models.LoginPageClientSideTestData) : void {
	initInternationalMobileInput();
	// tslint:disable-next-line:no-unused-expression
	new LoginPage(clientSideTestData);
}
