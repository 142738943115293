import { Models } from '../check-deposit-generated';

export class RegisteredScannerViewModel {

	Id: number;
	Model: string;
	SerialNumber: string;

	constructor(model: Models.RegisteredScannerViewModel) {
		this.Id = model.Id;
		this.Model = model.Model;
		this.SerialNumber = model.SerialNumber;
	}
}
