/// <reference path="../typings/globals/webpack-env/index.d.ts"/>

import { importModuleFromRequireContext } from '../Shared/importModuleFromRequireContext';

// imports function required for pendo (in product information)
// The reason why this is top level domain is because pendo is included as a 3rd party script
export * from '../Shared/hashjs';

//exports of this module will be available in global scope:
//window.pushpay.loggedinweb[exportName]

//this is a replacement for System.import
//it's using one of the ways webpack provides for importing modules
// /ALL_TS/ is a placeholder replaced during webpack build
const allModules = require.context('./', true, /ALL_TS/);

export function importModule(fileName) {
	return importModuleFromRequireContext(allModules, fileName);
}

//adding overload of require.ensure function with error callback
declare var require: {
	/**
	 * Download additional dependencies on demand. The paths array lists modules that should be available. When they are, callback is called.
	 * If the callback is a function expression, dependencies in that source part are extracted and also loaded on demand.
	 * A single request is fired to the server, except if all modules are already available.
	 * This creates a chunk. The chunk can be named. If a chunk with this name already exists, the dependencies are merged into that chunk and that chunk is used.
	 */
	ensure: (
		paths: string[],
		callback: (require: <T>(path: string) => T) => void,
		errorCallback: (error) => void,
		chunkName?: string
	) => void;
} & __WebpackModuleApi.RequireFunction;

//mockviews are loaded async when needed
export function importMockModule(filename): Promise<any> {
	return new Promise((resolve, reject) => {
		try {
			require.ensure(
				[],
				function (require) {
					const mockViewsWebpackExport: any = require('../MockViews/LoggedInWeb/webpackExport');
					resolve(mockViewsWebpackExport.importModule(filename));
				},
				function (error) {
					reject(error);
				},
				'mockviews'
			);
		} catch (e) {
			reject(e);
		}
	});
}

//insights sub-area is loaded async when needed
export function importInsightsModule(filename): Promise<any> {
	return new Promise((resolve, reject) => {
		try {
			require.ensure(
				[],
				function (require) {
					const insightsWebpackExport: any = require('../Insights/webpackExport');
					resolve(insightsWebpackExport.importModule(filename));
				},
				function (error) {
					reject(error);
				},
				'insights'
			);
		} catch (e) {
			reject(e);
		}
	});
}
