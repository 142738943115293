import * as React from 'react';
import { observer } from 'mobx-react';
import { ModelMetadata, ScheduleEditRequest } from '../../schedule-import-generated';
import { FormControlLabelled, FormControlType } from '../../../components/form-controls/form-control-labelled';
import { Fragment } from '../../../../Shared/components/fragment';
import { FormControlLabel } from '../../../components/form-controls/form-control-label';
import { ModalDialogForm } from '../../../components/modal-dialog-form';
import { Button } from '../../../components/button';
import { EditScheduleDetailsViewModel } from './view-model';

export interface IEditScheduleDetailsProps {
	vm: EditScheduleDetailsViewModel;
	onSubmitHandler: (details: ScheduleEditRequest) => void;
}

@observer
export class EditScheduleDetails extends React.Component<IEditScheduleDetailsProps> {
	render() {
		const { isPayerDetailsEditable, availableStatusItems, status, validationErrors } = this.props.vm;
		const modelMetadata = ModelMetadata.ScheduleEditRequest;

		const buttons = [
			<Button key="cancel"
				className="btn btn-cancel"
				type="button"
				data-dismiss="modal"
				acceptanceTestTargetId="Edit Schedule Details Cancel">Cancel</Button>,
			<Button key="submit"
				className="btn btn-default"
				type="submit"
				acceptanceTestTargetId="Edit Schedule Details Save">Save</Button>		];
		return (
			<ModalDialogForm heading="Edit draft schedule details"
				buttons={buttons}
				onSubmitHandler={this.handleSubmit}
				validationErrors={validationErrors}>
				{ isPayerDetailsEditable
					? this.renderFields()
					: this.renderReadonlyFields()}
				<div className="row">
					<FormControlLabelled label={modelMetadata.Status.displayName}
						cssClassNames="col-sm-4"
						formControlProps={{
							name: modelMetadata.Status.propertyName,
							formControlType: FormControlType.DropDown,
							value: status ? status.toString() : '',
							Options: availableStatusItems,
							onChangeHandler: this.handleStatusChange,
							validationRules: modelMetadata.Status.validationRules,
						}} />
				</div>
			</ModalDialogForm>
		);
	}

	handleSubmit = () => {
		const data = this.props.vm.requestObject;
		this.props.onSubmitHandler(data);
	}

	private renderFields() {
		const {
			firstName,
			lastName,
			email,
			mobile,
		} = this.props.vm;
		const modelMetadata = ModelMetadata.ScheduleEditRequest;
		return (
			<Fragment>
				<div className="row">
					<FormControlLabelled label={modelMetadata.FirstName.displayName}
						cssClassNames="col-sm-4"
						formControlProps={{
							name: modelMetadata.FirstName.propertyName,
							formControlType: FormControlType.Text,
							type: 'text',
							value: firstName,
							onChangeHandler: this.updateFirstName,
							validationRules: modelMetadata.FirstName.validationRules,
						}} />
					<FormControlLabelled label={modelMetadata.LastName.displayName}
						cssClassNames="col-sm-4"
						formControlProps={{
							name: modelMetadata.LastName.propertyName,
							formControlType: FormControlType.Text,
							type: 'text',
							value: lastName,
							onChangeHandler: this.updateLastName,
							validationRules: modelMetadata.LastName.validationRules,
						}} />
				</div>
				<div className="row">
					<FormControlLabelled label={modelMetadata.Email.displayName}
						cssClassNames="col-sm-4"
						formControlProps={{
							name: modelMetadata.Email.propertyName,
							formControlType: FormControlType.Text,
							type: 'text',
							value: email,
							onChangeHandler: this.updateEmail,
							validationRules: modelMetadata.Email.validationRules,
						}} />
					<FormControlLabelled label={modelMetadata.Mobile.displayName}
						cssClassNames="col-sm-4"
						formControlProps={{
							name: modelMetadata.Mobile.propertyName,
							formControlType: FormControlType.Text,
							type: 'text',
							value: mobile,
							onChangeHandler: this.updateMobile,
							validationRules: modelMetadata.Mobile.validationRules,
						}} />
				</div>
			</Fragment>
		);
	}

	private renderReadonlyFields() {
		const {
			firstName,
			lastName,
			email,
			mobile,
		} = this.props.vm;
		const modelMetadata = ModelMetadata.ScheduleEditRequest;
		return (
			<Fragment>
				<div className="row">
					<div className="col-sm-4 form-group">
						<FormControlLabel label={modelMetadata.FirstName.displayName} elementName={modelMetadata.FirstName.propertyName} />
						<div>{firstName}</div>
					</div>
					<div className="col-sm-4 form-group">
						<FormControlLabel label={modelMetadata.LastName.displayName} elementName={modelMetadata.LastName.propertyName} />
						<div>{lastName}</div>
					</div>
				</div>
				<div className="row">
					<div className="col-sm-4 form-group">
						<FormControlLabel label={modelMetadata.Email.displayName} elementName={modelMetadata.Email.propertyName} />
						<div>{email}</div>
					</div>
					<div className="col-sm-4 form-group">
						<FormControlLabel label={modelMetadata.Mobile.displayName} elementName={modelMetadata.Mobile.propertyName} />
						<div>{mobile}</div>
					</div>
				</div>
			</Fragment>
		);
	}

	private updateFirstName = (ev: React.FormEvent<HTMLInputElement>) => {
		this.props.vm.updateFirstName(ev.currentTarget.value);
	}

	private updateLastName = (ev: React.FormEvent<HTMLInputElement>) => {
		this.props.vm.updateLastName(ev.currentTarget.value);
	}

	private updateEmail = (ev: React.FormEvent<HTMLInputElement>) => {
		this.props.vm.updateEmail(ev.currentTarget.value);
	}

	private updateMobile = (ev: React.FormEvent<HTMLInputElement>) => {
		this.props.vm.updateMobile(ev.currentTarget.value);
	}

	private handleStatusChange = (event: React.FormEvent<HTMLSelectElement>) => {
		const value = event.currentTarget.value;
		this.props.vm.updateStatus(value);
	}
}
