import * as React from 'react';
import { observer } from 'mobx-react';

import { PaymentLabel } from '../../../schedule-import-generated';
import { Fragment } from '../../../../../Shared/components/fragment';
import { MigrationContactDetails } from '../../../components/migration-contact-details/migration-contact-details';
import { MigrationContactDetailsViewModel } from '../../../components/migration-contact-details/migration-contact-details-view-model';

@observer
export class PanelContactDetails extends React.Component<{vm: MigrationContactDetailsViewModel, paymentLabel: PaymentLabel}> {
	render() {
		const {
			paymentLabel,
			vm,
		} = this.props;

		return (
			<Fragment>
				<div className="panel-heading">Enter contact details for emails</div>
				<div className="panel-body">
					<p>Enter your contact details for {paymentLabel.PayerPluralLowerCase} to contact you about any inquiries.</p>
					<MigrationContactDetails vm={vm} paymentLabel={paymentLabel}/>
				</div>
			</Fragment>
		);
	}
}
