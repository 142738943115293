import { ModalDialogCommander } from '../../components/modal-dialog-commander';
import { StandardErrorTitle } from '../../components/modal-dialog-commander';
import { reportError } from '../../utils/error-utils';
import { isScheduleImportError, ScheduleImportError } from './schedule-import-error';

export function reportScheduleImportError(error: any, customData?: any) {
	if (isScheduleImportError(error)) {
		ModalDialogCommander.error(error.userError, StandardErrorTitle);
		return;
	}

	reportError(error, customData);
}

export function handleScheduleImportError(errorMessage: string) {
	if (!errorMessage) {
		return;
	}
	throw new ScheduleImportError(errorMessage);
}
