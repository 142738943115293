import { States, Events, Actions } from './pledge-entry-states-events-and-actions';

export const pledgeEntryMachineConfig = () => ({
	key: 'Pledge entry page',
	initial: States.Init,
	states: {
		[States.Init]: {
			onEntry: Actions.Start,
			on: {
				[Events.ShowLiveCampaignRequired]: States.LiveCampaignRequired,
				[Events.StartAddingPledges]: States.AddingPledges,
			}
		},
		[States.LiveCampaignRequired]: {},
		...addingPledgesMachineConfig(),
	},
});

const addingPledgesMachineConfig = () => ({
	[States.AddingPledges]: {
		parallel: true,
		states: {
			[States.AddPledge]: {
				initial: States.NoMember,
				states: {
					...noMember(),
					...memberSelected(),
				}
			},
			[States.RecentPledges]: {
				initial: States.Init,
				states: {
					[States.Init]: {
						onEntry: Actions.LoadPledges,
						on: {
							[Events.LoadPledgesComplete]: States.Idle,
						},
					},
					[States.Idle]: {
						on: {
							[Events.DeletePledge]: States.Deleting,
						},
					},
					[States.Deleting]: {
						on: {
							[Events.ConfirmDeletePledge]: { [States.ProcessingDelete]: { actions: [Actions.DeletePledgeConfirmed] } },
							[Events.CancelDeletePledge]: States.Idle,
						},
					},
					[States.ProcessingDelete]: {
						on: {
							[Events.DeletePledgeSuccess]: States.Idle,
							[Events.DeletePledgeError]: States.Error,
						},
					},
					[States.Error]: {
						on: {
							[Events.Close]: States.Idle,
						},
					},
				},
			},
		},
	},
});

const noMember = () => ({
	[States.NoMember]: {
		id: States.NoMember,
		parallel: true,
		states: {
			[States.SelectingAMember]: {
				initial: States.SearchingForMember,
				states: {
					[States.SearchingForMember] : {
						initial: States.Idle,
						states: {
							[States.Idle]: {
								on: {
									[Events.Search]: States.Searching,
								},
							},
							[States.Searching]: {
								onEntry: Actions.SearchForMember,
								on: {
									[Events.Search]: { [States.Searching]: { actions: [Actions.CancelSearching] } },
									[Events.SearchComplete]: States.Idle,
								},
							},
							[States.SearchingForMemberHistory]: {
								id: States.SearchingForMemberHistory,
								history: true,
							},
						},
						on: {
							[Events.AddMember]: { [States.AddingMember]: { actions: [Actions.CancelSearching] } },
						}
					},
					[States.AddingMember]: {
						initial: States.Idle,
						states: {
							[States.Idle]: {
								on: {
									[Events.SaveMember]: { [States.SavingMember]: { actions: [Actions.SaveAddMember] } },
									[Events.Cancel]: States.id.SearchingForMemberHistory,
								},
							},
							[States.SavingMember]: {
								on: {
									[Events.Success]: States.id.MemberSelected,
									[Events.ValidationError]: States.Idle,
								},
							},
						},
						on: {
							[Events.Error]: States.Error,
						},
					},
					[States.Error]: {
						on: {
							[Events.Close]: States.SearchingForMember,
						},
					},
				},
			},
			...displayingResults(),
		},
	},
});

const displayingResults = () => ({
	[States.DisplayResults]: {
		initial: States.HidingResults,
		states: {
			[States.HidingResults]: {
				on: {
					[Events.ShowResults]: States.ShowingResults,
					[Events.SearchComplete]: States.ShowingResults,
				},
			},
			[States.ShowingResults]: {
				initial: States.Idle,
				states: {
					[States.Idle]: {
						on: {
							[Events.LoadMore]: { [States.LoadingMore]: { actions: [Actions.LoadMore] } },
						}
					},
					[States.LoadingMore]: {
						on: {
							[Events.SearchComplete]: States.Idle,
							[Events.Search]: { [States.Idle]: { actions: [Actions.CancelSearching] } },
						},
					},
				},
				on: {
					[Events.HideResults]: States.HidingResults,
					[Events.SelectMember]: { [States.id.MemberSelected]: { actions: [Actions.CancelSearching, Actions.SelectMember] } },
				}
			},
		}
	},
});

const memberSelected = () => ({
	[States.MemberSelected]: {
		id: States.MemberSelected,
		initial: States.MemberSelectedIdle,
		states: {
			[States.MemberSelectedIdle]: {
				id: States.MemberSelectedIdle,
				on: {
					[Events.ClearMember]: { [States.id.NoMember] : { actions: [Actions.ClearMember] } },
					[Events.AddMember]: States.AddingMember,
					[Events.EditMember]: States.EditingMember,
					[Events.AddPledge]: { [States.SavingPledge]: { actions: [Actions.AddPledge] } },
				},
			},
			[States.SavingPledge]: {
				on: {
					[Events.Success]: { [States.id.NoMember]: { actions: [Actions.ClearMember] } },
					[Events.Error]: States.Error,
					[Events.PledgeAlreadyAdded]: States.PledgeAlreadyAdded,
				},
			},
			...addingMember(),
			...editingMember(),
			...pledgeAlreadyAdded(),
			[States.Error]: {
				on: {
					[Events.Close]: States.MemberSelectedIdle,
				},
			},
		}
	}
});

const addingMember = () => ({
	[States.AddingMember]: {
		initial: States.Idle,
		states: {
			[States.Idle]: {
				on: {
					[Events.SaveMember]: { [States.SavingMember]: { actions: [Actions.SaveAddMember] } },
					[Events.Cancel]: States.id.MemberSelectedIdle,
				},
			},
			[States.SavingMember]: {
				on: {
					[Events.Success]: States.id.MemberSelectedIdle,
					[Events.ValidationError]: States.Idle,
				},
			},
		},
		on: {
			[Events.Error]: States.Error,
		},
	},
});

const editingMember = () => ({
	[States.EditingMember]: {
		initial: States.Idle,
		states: {
			[States.Idle]: {
				on: {
					[Events.SaveMember]: { [States.SavingMember]: { actions: [Actions.SaveEditMember] } },
					[Events.Cancel]: States.id.MemberSelectedIdle,
				},
			},
			[States.SavingMember]: {
				on: {
					[Events.Success]: States.id.MemberSelectedIdle,
					[Events.ValidationError]: States.Idle,
				},
			},
		},
		on: {
			[Events.Error]: States.Error,
		},
	},
});

const pledgeAlreadyAdded = () => ({
	[States.PledgeAlreadyAdded]: {
		initial: States.Idle,
		states: {
			[States.Idle]: {
				on: {
					[Events.UpdatePledge]: { [States.SavingPledge]: { actions: [Actions.UpdatePledge] } },
					[Events.DeletePledgeFromModal]: States.ConfirmDelete,
					[Events.Cancel]: States.id.MemberSelectedIdle,
				}
			},
			[States.SavingPledge]: {
				on: {
					[Events.Success]: States.PledgeUpdated,
				},
			},
			[States.PledgeUpdated]: {
				on: {
					[Events.Close]: { [States.id.NoMember]: { actions: [Actions.ClearMember] } },
				},
			},
			[States.ConfirmDelete]: {
				on: {
					[Events.ConfirmDeletePledge]: { [States.DeletingPledge]: { actions: [Actions.DeletePledgeConfirmed] } },
					[Events.Cancel]: States.id.MemberSelectedIdle,
				}
			},
			[States.DeletingPledge]: {
				on: {
					[Events.DeletePledgeSuccess]: States.PledgeDeleted,
				}
			},
			[States.PledgeDeleted]: {
				on: {
					[Events.Close]: States.id.MemberSelectedIdle,
				},
			},
		},
		on: {
			[Events.Error]: States.Error,
		}
	},
});
