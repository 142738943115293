export class ClipboardCopyManager {

	constructor() {
		let elems:NodeList = document.querySelectorAll('[data-copy-clipboard]');
		for(let i = 0; i < elems.length; i++) {
			elems[i].addEventListener('click', this.copyToClipboard, false);
		}
	}

	private copyToClipboard(e:Event) {
		e.preventDefault();
		let target:HTMLElement = e.currentTarget as HTMLElement;
		let copyTargetSelector:string = target.getAttribute('data-copy-clipboard');

		if(!copyTargetSelector) {
			return;
		}

		let copyTarget: HTMLInputElement = document.querySelector(copyTargetSelector) as HTMLInputElement;

		if(copyTarget && copyTarget.select) {
			copyTarget.focus();
			copyTarget.select();
			let succeeded:boolean = false;
			try {
				succeeded = document.execCommand('copy');
			} catch (err) {
				succeeded = false;
			}


			if(succeeded) {
				copyTarget.blur();
				alert('Content copied to clipboard');
			} else {
				alert('Press Ctrl/Cmd + C to copy');
			}
		}
	}
}

export let clipboardCopyManager  = () => new ClipboardCopyManager();
