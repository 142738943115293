import * as React from 'react';
import { ResponseTypes, ModelMetadata } from '../../funds/funds-generated';
import { observer } from 'mobx-react';
import { computed } from 'mobx';

import { IListingModel } from '../components/listing-model';
import { IntegrationConfigurationStore } from '../components/integration-configuration-store';
import { AuthenticationSection } from '../components/authentication-section';
import { IntegrationContainer } from '../components/integration-container';
import { ModalDialogCommander } from '../../components/modal-dialog-commander';
import { EnableIntegrationModalDialog } from '../../integrations/components/enable-integration-dialog';
import getFundsForDisplay from '../helpers/get-integration-funds-for-display';
import { PlanningCenterGivingConfigurationSection, PlanningCenterListingModel } from '../components/planningcenter-configuration-section/planningcenter-giving-config'
import { PlanningCenterPeopleConfigurationSection } from '../components/planningcenter-configuration-section/planningcenter-people-config'

import ConfigurationViewModel = ResponseTypes.PlanningCenterConfigurationViewModel;
import ListingConfigurationModel = ResponseTypes.PlanningCenterListingConfigurationModel;
import IntegrationListingConfigurationViewModel = ResponseTypes.IntegrationListingConfigurationViewModel;

export class PlanningCenterIntegrationConfigurationStore
extends IntegrationConfigurationStore<ListingConfigurationModel, ConfigurationViewModel> {
	@computed
	get funds() {
		return this.configuration.IntegrationFunds
			? getFundsForDisplay(this.configuration.IntegrationFunds)
			: [];
	}

	get campuses() {
		return this.configuration.IntegrationCampuses;
	}

	get paymentSources() {
		return this.configuration.PaymentSources;
	}

	get givingStatus() {
		return this.configuration.GivingStatus;
	}

	get isGivingModuleEnabled() {
		return this.configuration.IsGivingModuleEnabled;
	}

	createListing(listing: IntegrationListingConfigurationViewModel<ListingConfigurationModel>):
		IListingModel<ListingConfigurationModel> {
		const messageWhenMissingFallbackFunds = this.isListingMissingFallbackFund(listing, this.configuration.IntegrationFunds)
			? ModelMetadata.PlanningCenterListingConfigurationModel.FallbackFundKey.validationRules.required.errorMessage
			: null;
		return new PlanningCenterListingModel(listing, this.funds, this.campuses, messageWhenMissingFallbackFunds);
	}

	get EnableIntegrationUri() {
		return this.configuration.EnableIntegrationUri;
	}

	get ShouldPromptToEnable() {
		return this.configuration.ShouldPromptToEnable;
	}

	get CommunityMembersUrl() {
		return this.configuration.CommunityMembersUrl;
	}

	get InstanceName() {
		return this.configuration.InstanceName;
	}

	get DefaultPaymentSource() {
		return this.configuration.PaymentSourceId;
	}
}

@observer
export class PlanningCenterIntegration extends React.Component<{
		controller: PlanningCenterIntegrationConfigurationStore
	},
	{}> {
	componentDidMount() {
		if (this.props.controller.ShouldPromptToEnable) {
			window.setTimeout(() => ModalDialogCommander.showReactDialog(<EnableIntegrationModalDialog actionUrl={this.props.controller.EnableIntegrationUri} integrationIsThirdParty={false} />), 0);
		}
	}

	renderAuthenticationSection = () => {
		return <PlanningCenterAuthenticationSection controller={this.props.controller}/>;
	}

	renderConfigurationSection = () => {
		const statusMessage = this.props.controller.systemStatus === ResponseTypes.ExternalSystemStatus.Enabled
			? `Your community members are now being synchronized with ${this.props.controller.viewModel.Metadata.ProductName}.`
			: `Your community members are ready to be synchronized with ${this.props.controller.viewModel.Metadata.ProductName}, to start the process enable the integration.`;
		return <PlanningCenterConfigurationSection store={this.props.controller} statusMessage={statusMessage}/>;
	}

	render() {
		return <IntegrationContainer
			isAuthenticationSectionEditable={this.props.controller.isAuthenticationSectionEditable}
			authenticationSectionFactory={this.renderAuthenticationSection}
			configurationSectionFactory={this.renderConfigurationSection} />;
	}
}

@observer
class PlanningCenterAuthenticationSection extends React.Component<{
		controller: PlanningCenterIntegrationConfigurationStore
	},
	{}> {
	render() {
		return (
			<AuthenticationSection store={this.props.controller}/>
		);
	}

	private get metadata() {
		return ModelMetadata.PlanningCenterAuthenticationEditModel;
	}
}

@observer
class PlanningCenterConfigurationSection extends React.Component<{
		store: PlanningCenterIntegrationConfigurationStore,
		statusMessage: string
	},
	{}> {
	render() {
		return (
			<>
				<PlanningCenterPeopleConfigurationSection
					store={this.props.store}
					statusMessage={this.props.statusMessage}
					communityMembersUrl={this.props.store.CommunityMembersUrl}
				/>
				<PlanningCenterGivingConfigurationSection
					store={this.props.store}
				/>
			</>
		);
	}
}
