import { action, computed, observable } from 'mobx';
import { CommonPaymentFieldsModel } from '../../../loggedinweb-generated';
import { AchAccountType, ExistingPaymentMethodViewModel, PaymentMethodType, ScheduleFrequency, AssetType } from '../../virtual-terminal-generated';
import { CreditCardHelper } from '../../../helpers/creditcardhelper';

export interface IPaymentEntryModel {
	amount: number;
	yourId?: string;
	paymentMethod: PaymentMethod;
	paymentMethodIndex: number;
	CommonPaymentFields: CommonPaymentFieldsModel;
	isRecurring: boolean;
	recurringSchedule?: ScheduleFrequency;
	sendEmailNotifications: boolean;
	descriptionForMerchant?: string;
	descriptionForDonor?: string;
	assetType?: AssetType;
}

export interface ICheckNumber {
	routingNumber: string;
	accountNumber: string;
	checkNumber: string;
	cleanedCheckReaderOutput: string;
}

export type PaymentMethod =
	CardPaymentMethod |
	ExistingCardPaymentMethod |
	AchBankAccountPaymentMethod |
	ExistingAchBankAccountPaymentMethod |
	CashPaymentMethod |
	RecordedAchBankAccountPaymentMethod |
	RecordedCheckPaymentMethod |
	RecordedCardPaymentMethod |
	NonCashPaymentMethod;

export class CardPaymentMethod {
	readonly type = PaymentMethodUiType.CreditCard;
	readonly key = 'card';
	readonly isProcessed = true;
	@observable cardNumberInput: string;
	@observable expiryDateInput: string;
	@observable cvv: string;
	@action updateCardNumberInput = (cardNumberInput: string) => { this.cardNumberInput = cardNumberInput; };
	@action updateExpiryDateInput = (expiryDateInput: string) => { this.expiryDateInput = expiryDateInput; };
	@action updateCvv = (cvv: string) => { this.cvv = cvv; };

	@computed
	get cardNumber() {
		return CreditCardHelper.getCardNumberParsed(this.cardNumberInput);
	}

	@computed
	get expiryMonth() {
		return CreditCardHelper.getExpiryMonthParsed(this.expiryDateInput);
	}

	@computed
	get expiryYear() {
		return CreditCardHelper.getExpiryYearParsed(this.expiryDateInput);
	}

	@computed
	get cardBrand() {
		return CreditCardHelper.getCardBrandFromInput(this.cardNumberInput);
	}
}

export class ExistingCardPaymentMethod {
	readonly type = PaymentMethodUiType.ExistingCreditCard;
	readonly key: string;
	readonly isProcessed = true;
	readonly lastTwoDigits: string;
	readonly cardBrandName: string;

	@observable
	confirmedCardNumberInput: string;

	@action
	updateConfirmedCardNumberInput = (confirmedCardNumberInput: string) => { this.confirmedCardNumberInput = confirmedCardNumberInput; }

	@computed
	get confirmedCardNumber() {
		return getLastFourDigits(this.confirmedCardNumberInput);
	}

	constructor(existingPaymentMethod: ExistingPaymentMethodViewModel) {
		this.key = `${existingPaymentMethod.PaymentMethodIndex}`;
		this.lastTwoDigits = existingPaymentMethod.LastTwoDigits;
		this.cardBrandName = existingPaymentMethod.CardBrandName;
	}
}

export class AchBankAccountPaymentMethod {
	readonly type = PaymentMethodUiType.ACH;
	readonly key = 'ach';
	readonly isProcessed = true;
	@observable routingNumber: string;
	@observable accountNumber: string;
	@observable accountType: AchAccountType = AchAccountType.Checking;
	@action updateRoutingNumber = (routingNumber: string) => { this.routingNumber = routingNumber; };
	@action updateAccountNumber = (accountNumber: string) => { this.accountNumber = accountNumber; };
	@action updateAccountType = (accountType: AchAccountType) => { this.accountType = accountType; };
}

export class ExistingAchBankAccountPaymentMethod {
	readonly type = PaymentMethodUiType.ExistingACH;
	readonly key: string;
	readonly isProcessed = true;
	readonly bankName: string;
	readonly lastTwoDigits: string;
	readonly accountNumberLength: number;

	@observable
	confirmedAccountNumberInput: string;

	@action
	updateConfirmedAccountNumberInput = (confirmedAccountNumberInput: string) => { this.confirmedAccountNumberInput = confirmedAccountNumberInput; }

	@computed
	get confirmedAccountNumber() {
		return getLastFourDigits(this.confirmedAccountNumberInput);
	}

	constructor(existingPaymentMethod: ExistingPaymentMethodViewModel) {
		this.key = `${existingPaymentMethod.PaymentMethodIndex}`;
		this.lastTwoDigits = existingPaymentMethod.LastTwoDigits;
		this.bankName = existingPaymentMethod.BankName;
		this.accountNumberLength = existingPaymentMethod.AccountNumberLength;
	}
}

export class CashPaymentMethod {
	readonly type = PaymentMethodUiType.Cash;
	readonly key = 'cash';
	readonly isProcessed = false;
}

export class RecordedAchBankAccountPaymentMethod {
	readonly type = PaymentMethodUiType.RecordedACH;
	readonly key = 'recorded_ach';
	readonly isProcessed = false;
	@observable paymentReference: string;
	@action updatePaymentReference = (paymentReference: string) => { this.paymentReference = paymentReference; };
}

export class RecordedCheckPaymentMethod implements ICheckNumber {
	readonly type = PaymentMethodUiType.RecordedCheck;
	readonly key = 'recorded_check';
	readonly isProcessed = false;
	@observable routingNumber: string;
	@observable accountNumber: string;
	@observable checkNumber: string;
	@observable cleanedCheckReaderOutput: string;
	@observable partiallyMisread?: boolean;
	@action updateRoutingNumber = (routingNumber: string) => { this.routingNumber = routingNumber; };
	@action updateAccountNumber = (accountNumber: string) => { this.accountNumber = accountNumber; };
	@action updateCheckNumber = (checkNumber: string) => { this.checkNumber = checkNumber; };
	@action updatePartiallyMisread = (partiallyMisread: boolean) => { this.partiallyMisread = partiallyMisread; };
	@action applyCheckNumber = (checkNumber: ICheckNumber) => {
		this.routingNumber = checkNumber.routingNumber;
		this.accountNumber = checkNumber.accountNumber;
		this.checkNumber = checkNumber.checkNumber;
		this.cleanedCheckReaderOutput = checkNumber.cleanedCheckReaderOutput;
	}
}

export class RecordedCardPaymentMethod {
	readonly type = PaymentMethodUiType.RecordedCreditCard;
	readonly key = 'recorded_card';
	readonly isProcessed = false;
	@observable paymentReference: string;
	@action updatePaymentReference = (paymentReference: string) => { this.paymentReference = paymentReference; };
}

export class NonCashPaymentMethod {
	readonly type = PaymentMethodUiType.NonCash;
	readonly key = 'non_cash';
	readonly isProcessed = false;
	@observable descriptionForDonor: string;
	@observable descriptionForMerchant: string;
	@observable assetType: AssetType;
	@action setDescriptionForMerchant = (descriptionForMerchant: string) => { this.descriptionForMerchant = descriptionForMerchant; };
	@action setDescriptionForDonor = (descriptionForDonor: string) => { this.descriptionForDonor = descriptionForDonor; };
	@action setAssetType = (assetType: AssetType) => { this.assetType = assetType; };

	constructor() {
		this.assetType = AssetType.StocksAndBonds;
	}
}

export enum CardType {
	Unknown = 0,
	Visa = 1,
	Mastercard = 2,
	Amex = 3,
	Discover = 4
}

export namespace CardType {
	export function getName(cardType: CardType) {
		if (cardType === null) {
			return '';
		}
		return CardType[cardType];
	}
}

export enum PaymentScheduleStatus {
	Pending = 0, Active = 1, Paused = 2, Completed = 3, Cancelled = 9
}

export enum PaymentMethodUiType {
	CreditCard,
	ACH,
	Cash,
	RecordedCheck,
	RecordedCreditCard,
	RecordedACH,
	ExistingCreditCard,
	ExistingACH,
	NonCash
}

export function existingPaymentMethodFactory(existingPaymentMethod: ExistingPaymentMethodViewModel) {
	switch(existingPaymentMethod.PaymentMethodType) {
		case PaymentMethodType.ACH:
			return new ExistingAchBankAccountPaymentMethod(existingPaymentMethod);
		case PaymentMethodType.CreditCard:
			return new ExistingCardPaymentMethod(existingPaymentMethod);
		default:
			throw new Error('Unhandled existing payment method type');
	}
}

function getLastFourDigits(input: string) {
	const result = /.*([0-9]{4})$/.exec(input);
	return result ? result[1] : null;
}
