import * as React from 'react';
import { TipBox } from '../../../../components/tipbox';
import { SvgWrapper } from '../../../../../Shared/components/svg-wrapper';

import { tip, icon, iconSvg, text } from './batch-entry-tipbox.less';

export const BatchEntryTipBox = (props: { paymentNoun: string, helpUrl: string, serviceTimeUrl: string }) => (
	<TipBox dismissable storageKey="batch-entry-create-batch-help">
		<h2>Getting started creating a batch</h2>
		<div className="row">
			<div className={`col-sm-6 ${tip}`}>
				<div className={icon}>
					<SvgWrapper svg="icon-tips" className={iconSvg} />
				</div>
				<div className={text}>
					To help you get all your cash and check {props.paymentNoun} into Pushpay, we have prepared <a
						className="arrow-hollow-right-after"
						href={props.helpUrl}
						target="_blank" rel="noopener noreferrer"
					>some useful tips</a>
				</div>
			</div>
			<div className={`col-sm-6 ${tip}`}>
				<div className={icon}>
					<SvgWrapper svg="icon-time" className={iconSvg} />
				</div>
				<div className={text}>
					To group your transactions, you can choose to <a href={props.serviceTimeUrl}>set up a service time</a> for your listings from Settings.
				</div>
			</div>
		</div>
	</TipBox>
);
