import * as React from 'react';
import { TransitionGroup } from 'react-transition-group-v1';
import { CustomFieldViewModel } from '../../utils/customFieldsUtils';
import { CommonControlProps, getCustomFieldProperties } from '../../utils/customFieldsUtils';
import { getCustomFieldPropertyMetadata } from '../../../Shared/utils/customFieldsValidation';
import { isFunction } from '../../utils/is-function';
import { observer } from 'mobx-react';
import {Models} from '../check-deposit-generated';
import CustomFieldEditModel = Models.CustomFieldEditModel;
import { IFormControlSelectProps } from '../../components/form-controls/form-control-select';
import { FormControlSelect } from '../../components/form-controls/form-control-select';
import { FormControlAmount } from '../../components/form-controls/form-control-amount';
import { SvgWrapper } from '../../components/svg-wrapper';
import {ValidationMessage} from '../../components/form-controls/validation-message';
import { velocity } from '../../helpers/velocity';
import {enterTransitionDuration, leaveTransitionDuration} from '../../components/data-grid/data-grid';
import {IValidationRules} from '../../validation/validation-rules';

export interface IPaymentFundProps {
	viewModel: CustomFieldViewModel;
	fund: CustomFieldEditModel;
	fundErrorMessage: string;
	amount: number;
	amountErrorMessage: string;
	index: number;
	isMultiSplit: boolean;
	allAmountsAreValid: boolean;
	onFundChange: (value: string, index: number) => void;
	onFundBlur: () => void;
	onAmountChange: (value: number, index:number) => void;
	onAmountBlur: () => void;
	onDelete: (index: number) => void;
}

@observer
export class FundSelector extends React.Component<IPaymentFundProps, {}> {
	container: HTMLElement;

	render() {
		const { index, viewModel, fund, fundErrorMessage, amount, amountErrorMessage, isMultiSplit, allAmountsAreValid,
			onAmountChange, onAmountBlur, onDelete } = this.props;

		const metadata = getCustomFieldPropertyMetadata(viewModel);

		const { propertyName, placeholder } = metadata;

		const uniqueSuffix = index.toString(10);

		const duplicateFundValidationRule = fundErrorMessage ? {nevervalid: {errorMessage: fundErrorMessage}} : null;

		const fundValidationRules = {
			...metadata.validationRules,
			...duplicateFundValidationRule
		};

		const commonControlProps = {
			name: `${propertyName}_${uniqueSuffix}`,
			placeholder: placeholder ? placeholder : `Select ${viewModel.Label}`,
			validationRules: fundValidationRules,
			value: typeof fund.Value === 'string' ? fund.Value : '',
			onChangeHandler: this.handleFundChange,
		} as CommonControlProps;

		const controlProps = getCustomFieldProperties(viewModel, commonControlProps) as IFormControlSelectProps;

		controlProps.uniqueSuffix = uniqueSuffix;
		controlProps.onBlurHandler = this.handleFundBlur;
		controlProps.revalidateOnBlur = true;

		const emptyValidationRule = amountErrorMessage ?  {nevervalid: {errorMessage: amountErrorMessage}} : null;
		const amountValidationRules = {
			...emptyValidationRule,
			required: {
				errorMessage: `Please enter an amount.`
			}
		};

		return (
			<div className={(isMultiSplit ? 'cd-check-split-row' : null) } ref={(container) => this.container = container}>
				<TransitionGroup component="div" className="cd-check-split-form-fields">
					<div className="cd-fund-selector form-group col-md-4">
						<FormControlSelect {...controlProps} />
						<ValidationMessage elementName={controlProps.name} />
					</div>
					{isMultiSplit &&
						<FundAmountControl
							key={`amount-${index}`}
							uniqueSuffix={uniqueSuffix}
							amount={amount}
							validationRules={amountValidationRules}
							allAmountsAreValid={allAmountsAreValid}
							index={index}
							onAmountChange={onAmountChange}
							onAmountBlur={onAmountBlur}
						/>
					}
					{isMultiSplit &&
						<FundDeleteButton index={index} onDelete={onDelete} key={`delete-${index}`}/>
					}
				</TransitionGroup>
		</div>
		);
	}
	componentWillEnter(callback) {
		velocity(this.container, 'slideDown', {
			duration: enterTransitionDuration,
			easing: 'ease-in',
			complete: callback,
		});
	}

	componentWillLeave(callback) {
		velocity(this.container, 'slideUp', {
			duration: leaveTransitionDuration,
			easing: 'ease-out',
			complete: callback,
		});
	}

	private handleFundChange = (event: React.FormEvent<any>) => {
		const { index, onFundChange } = this.props;
		if (isFunction(onFundChange)) {
			onFundChange((event.target as HTMLInputElement).value, index);
		}
	}

	private handleFundBlur = (event: React.FormEvent<any>) => {
		this.props.onFundBlur();
	}
}
export interface IFundAmountControlProps {
	uniqueSuffix: string;
	amount: number;
	validationRules: IValidationRules;
	allAmountsAreValid: boolean;
	index: number;
	onAmountChange: (value: number, index:number) => void;
	onAmountBlur: () => void;
}

export class FundAmountControl extends React.Component<IFundAmountControlProps, null> {
	container: HTMLElement;

	render() {
		const {uniqueSuffix, amount, validationRules, allAmountsAreValid} = this.props;
		return (
			<div className="cd-fund-amount-field col-md-2" ref={(container) => this.container = container}>
				<span className="cd-fund-amount">
					<FormControlAmount
						uniqueSuffix={uniqueSuffix}
						value={amount}
						name={`Amount_${uniqueSuffix}`}
						validationRules={validationRules}
						isInvalid={!allAmountsAreValid}
						onChangeHandler={this.handleAmountChange}
						onBlur={this.handleAmountBlur}
						placeholder={"0.00"}
					/>
				</span>
				<ValidationMessage elementName={`Amount_${uniqueSuffix}`}/>
			</div>
		);
	}

	componentWillEnter(callback) {
		velocity(this.container, 'fadeIn', {
			duration: enterTransitionDuration,
			complete: callback,
			easing: 'ease-in-out',
		});
	}

	componentWillLeave(callback) {
		velocity(this.container, 'fadeOut', {
			duration: leaveTransitionDuration,
			complete: callback,
			easing: 'ease-in-out',
		});
	}

	private handleAmountChange = (value: number) => {
		const { onAmountChange, index } = this.props;
		if (isFunction(onAmountChange)) {
			onAmountChange(value, index);
		}
	}

	private handleAmountBlur = () => {
		const { onAmountBlur } = this.props;
		if(isFunction(onAmountBlur)) {
			onAmountBlur();
		}
	}
}

export class FundDeleteButton extends React.Component<{index: number, onDelete: (index: number) => void}, null> {
	container: HTMLElement;

	render() {
		return (
			<div className="cd-remove-fund" ref={(container) => this.container = container}>
				<button type="button" className="btn btn-link" onClick={this.handleDelete}>
					<SvgWrapper svg="icon-delete" className="icon" />
					Delete
				</button>
			</div>
		);
	}

	componentWillEnter(callback) {
		velocity(this.container, 'fadeIn', {
			duration: enterTransitionDuration,
			complete: callback,
			easing: 'ease-in-out',
		});
	}

	componentWillLeave(callback) {
		velocity(this.container, 'fadeOut', {
			duration: leaveTransitionDuration,
			complete: callback,
			easing: 'ease-in-out',
		});
	}

	private handleDelete = (event: React.FormEvent<HTMLButtonElement>) => {
		const { onDelete, index } = this.props;
		if (isFunction(onDelete)) {
			onDelete(index);
		}
	}
}
