import * as React from 'react';
import { observer } from 'mobx-react';
import { Formatter } from '../../../helpers/formatter';
import * as styles from './styles.less';
import { GivingStatementRunStatus, PaymentLabel } from '../../giving-statements-generated';

export interface IDownloadTabContentProps {
	numberOfStatements: number;
	numberWithNoEmail: number;
	supportsNoEmailDownload: boolean;
	status: GivingStatementRunStatus;
	paymentLabel: PaymentLabel;
	onClickDownloadNoEmail: () => void;
	onClickDownloadAll: () => void;
	onClickPublish: () => void;
	givingUnitType: string;
}

@observer
export default class DownloadTabContent extends React.Component<IDownloadTabContentProps> {
	render() {
		const { supportsNoEmailDownload } = this.props;

		return (
			<div className={styles.splitPanel}>
				{supportsNoEmailDownload && this.renderNoEmailSection()}
				{this.renderDownloadAllSection()}
			</div>
		);
	}

	private renderNoEmailSection = () => {
		const { numberWithNoEmail, onClickDownloadNoEmail, givingUnitType } = this.props;
		const formattedNumber = Formatter.formatNumberForDisplay(numberWithNoEmail, 0);
		const givingUnit = givingUnitType.slice(0,-1);
		const givingUnits = givingUnitType;
		const copy = (numberWithNoEmail === 1)
			? <div>There is <strong>{formattedNumber}</strong> {givingUnit} without an email address.</div>
			: <div>There are <strong>{formattedNumber}</strong> {givingUnits} without email addresses.</div>;

		return (
			<div className={styles.subPanel}>
				<div>
					<div className={`responsive-btn-group ${styles.buttonGroup}`}>
						<button className="btn btn-default" disabled={!numberWithNoEmail} onClick={onClickDownloadNoEmail}>
							Download {formattedNumber} statement{numberWithNoEmail === 1 ? '' : 's'}
						</button>
					</div>
					{copy}
				</div>
			</div>
		);
	}

	private renderDownloadAllSection = () => {
		const { numberOfStatements, numberWithNoEmail, onClickDownloadAll } = this.props;
		const formattedNumber = Formatter.formatNumberForDisplay(numberOfStatements, 0);

		return (
			<div className={styles.subPanel}>
				<div className={`responsive-btn-group ${styles.buttonGroup}`}>
					<button className={`btn ${!numberWithNoEmail ? 'btn-default' : 'btn-ghost'}`} onClick={onClickDownloadAll}>
						Download all
					</button>
				</div>
				<div>
					Download <strong>{formattedNumber}</strong> statement{numberOfStatements === 1 ? '' : 's'}.
				</div>
			</div>
		);
	}
}
