import * as React from 'react';
import { observer } from 'mobx-react';
import { classNames } from '../../../../Shared/utils/classnames';
import { SvgWrapper } from '../../../../Shared/components/svg-wrapper';

import * as styles from './campaign-card-actions.less';


interface CampaignActionProps extends React.Props<CampaignAction> {
	className?: string;
	url?: string;
	onClick?: () => void;
	acceptanceTestTargetId?: string;
}

@observer
export class CampaignAction extends React.Component<CampaignActionProps> {
	render() {
		const { url, className, children, acceptanceTestTargetId } = this.props;
		return (
			<a href={url}
				className={classNames(className, styles.action)}
				onClick={this.onClick}
				data-pp-at-target={acceptanceTestTargetId}>
				{children}
			</a>
		);
	}

	private onClick = () => {
		const { url, onClick } = this.props;

		if (!url && onClick) {
			onClick();
		}
	}
}

export const EditAction = observer(({ editUrl }) => (
	<CampaignAction url={editUrl} acceptanceTestTargetId="edit action">
		Edit
		<SvgWrapper className={styles.icon} svg="pen" />
	</CampaignAction>
));


export const DuplicateAction = observer(({ duplicateUrl }) => (
	<CampaignAction url={duplicateUrl} acceptanceTestTargetId="duplicate action">
		Duplicate
		<SvgWrapper className={styles.icon} svg="duplicate" />
	</CampaignAction>
));


export const ActivateAction = observer(({ onClick }: { onClick: () => void }) => (
	<CampaignAction className={styles.majorAction} onClick={onClick} acceptanceTestTargetId="publish action">
		Publish
	</CampaignAction>
));

export const ReactivateAction = observer(({ reactivateUrl }) => (
	<CampaignAction className={styles.majorAction} url={reactivateUrl} acceptanceTestTargetId="reactivate action">
		Reactivate
		<SvgWrapper className={styles.icon} svg="reactivate" />
	</CampaignAction>
));

export const DeleteAction = observer(({ onClick, children }) => (
	<CampaignAction onClick={onClick} acceptanceTestTargetId="delete action">
		Delete
		<SvgWrapper className={styles.icon} svg={'bin'} />
		{children}
	</CampaignAction >
));

export const CloseAction = observer(({ onClick, children }) => (
	<CampaignAction onClick={onClick} acceptanceTestTargetId="close action">
		Close
		<SvgWrapper className={styles.icon} svg="close-card" />
		{children}
	</CampaignAction>
));

export const AddPledgeActionOld = observer(({ addPledgeUrl }) => (
	<CampaignAction className={classNames(styles.majorAction, styles.addPledgeLink)} url={addPledgeUrl} acceptanceTestTargetId="add a pledge action">
		Add a pledge
	</CampaignAction>
));

export const AddPledgeAction = observer(({ addPledgeUrl, pledgeLabelValue }) => (
	<CampaignAction className={classNames(styles.majorAction, styles.addPledgeLink)} url={addPledgeUrl} acceptanceTestTargetId="add a pledge action">
		Add a {pledgeLabelValue}
	</CampaignAction>
));

export const ShareAction = observer(({ onClick, children }) => (
	<CampaignAction className={styles.shareLink} onClick={onClick} acceptanceTestTargetId="share action">
		Share
		<SvgWrapper className={styles.icon} svg="share" />
		{children}
	</CampaignAction>
));

export const MatchFundAction = observer(({ editFundUrl }) => (
	<CampaignAction className={styles.majorAction} url={editFundUrl} acceptanceTestTargetId="match fund action">
		Match Fund
	</CampaignAction>
));

