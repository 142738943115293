import { MachineConfig } from 'xstate/lib/types';
import State from './campaign-card-states';
import { CampaignCardAction as Action } from './campaign-card-event-actions';
import { RxMachineContext } from '../../../../../Shared/state-machine/rx-state-machine';
import { createEvent, transitionSymbol } from '../../../../../Shared/state-machine/rx-state-machine-factory';
import { campaignsLandingSubject } from '../../../../campaigns/pages/campaigns-landing/campaigns-landing-intent';

const Eventz = {
	activateCampaign: createEvent('ACTIVATE_CAMPAIGN', State.Processing, Action.activateCampaign),
	closeCampaign: createEvent('CLOSE_CAMPAIGN', State.Confirming),
	confirmCloseCampaign: createEvent('CONFIRM_CLOSE_CAMPAIGN', State.Processing, Action.closeCampaign),
	cancelConfirmation: createEvent('CANCEL_CONFIRMATION', State.Idle),
	deleteCampaign: createEvent('DELETE_CAMPAIGN', State.Confirming),
	confirmDeleteCampaign: createEvent('CONFIRM_DELETE_CAMPAIGN', State.Processing, Action.deleteCampaign),
	shareCampaign: createEvent('SHARE_CAMPAIGN', State.Sharing),
	shareCampaignComplete: createEvent('SHARE_CAMPAIGN_COMPLETE', State.Idle),
	processCampaignComplete: createEvent('PROCESS_CAMPAIGN_COMPLETE', State.Idle),
	processCampaignFail: createEvent('PROCESS_CAMPAIGN_FAIL', State.ShowingError, Action.handleFailure),
	dismissCampaignError: createEvent('DISMISS_CAMPAIGN_ERROR', State.Idle, Action.dismissRetry),
};

export type ICampaignCardEventCreator = { [P in keyof typeof Eventz]: ReturnType<(typeof Eventz)[P]> };

const campaignCardMachineContextConfig: MachineConfig = {
	key: 'Campaign Card State Machine',
	initial: State.Idle,
	states: {
		[State.Idle]: {
			on: {
				...Eventz.activateCampaign[transitionSymbol],
				...Eventz.deleteCampaign[transitionSymbol],
				...Eventz.closeCampaign[transitionSymbol],
				...Eventz.shareCampaign[transitionSymbol],
			}
		},
		[State.Processing]: {
			onEntry: Action.startProcessing,
			on: {
				...Eventz.processCampaignComplete[transitionSymbol],
				...Eventz.processCampaignFail[transitionSymbol],
			},
			onExit: Action.finishProcessing,
		},
		[State.Confirming]: {
			on: {
				...Eventz.confirmDeleteCampaign[transitionSymbol],
				...Eventz.confirmCloseCampaign[transitionSymbol],
				...Eventz.cancelConfirmation[transitionSymbol],
				...Eventz.shareCampaign[transitionSymbol],
			}
		},
		[State.Sharing]: {
			on: {
				...Eventz.shareCampaignComplete[transitionSymbol],
				...Eventz.closeCampaign[transitionSymbol],
			}
		},
		[State.ShowingError]: {
			on: {
				...Eventz.activateCampaign[transitionSymbol],
				...Eventz.confirmDeleteCampaign[transitionSymbol],
				...Eventz.confirmCloseCampaign[transitionSymbol],
				...Eventz.dismissCampaignError[transitionSymbol],
			}
		},
	}
};

export const createCampaignCardMachineContext = () =>
	RxMachineContext.connect<typeof Eventz>(campaignCardMachineContextConfig, campaignsLandingSubject)(Eventz);
