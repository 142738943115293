import * as React from 'react';
import { TipBox } from '../../../components/tipbox';
import {SvgWrapper} from '../../../../Shared/components/svg-wrapper';


import * as styles from './styles.less';

export class QboHelpPanel extends React.Component<{ helpPageUri: string }> {
	render() {
		return (
			<TipBox storageKey="qbo-integration-help">
				<div className={styles.container}>
					<div className={styles.logo}>
						<SvgWrapper svg="quickbooks-logo" width="192" height="37"/>
					</div>
					<div className={styles.text}>
						<p>
							This integration will send your Pushpay batches through to QuickBooks Online as journal entries.
							These journal entries will be automatically itemized into QuickBooks Accounts according to the
							fund mappings you set.
						</p>
						<p>
							Before you get started, make sure you've read our overview of the&nbsp;
							<a href={this.props.helpPageUri} target="_blank" rel="noopener noreferrer">QuickBooks Online integration</a>.
						</p>
					</div>
				</div>
			</TipBox>
		);
	}
}
