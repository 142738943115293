import * as React from 'react';
import { CommonPaymentFieldsModel, CustomFieldEditModel, CustomFieldType, ModelMetadata } from '../loggedinweb-generated';
import { ModelMetadata as VTModelMetadata } from '../virtual-terminal/virtual-terminal-generated';
import { observer } from 'mobx-react';
import { isFunction } from '../utils/is-function';
import { CustomFieldViewModel } from '../utils/customFieldsUtils';
import { CustomField } from './custom-field';
import { FormControlLabelled, FormControlType, IFormControlLabelledTooltipProps } from './form-controls/form-control-labelled';
import { FormControlCheckbox } from './form-controls/form-control-checkbox/form-control-checkbox';
import { recordEvent, WithAnalytics, injectAnalytics } from '../analytics';

export interface IPaymentCommonFieldsProps {
	customFields?: CustomFieldViewModel[];
	model: CommonPaymentFieldsModel;
	paymentNounLowerCase: string;
	paymentNounSentenceCase: string;
	onCustomFieldChange?: (field: CustomFieldEditModel) => void;
	onGivenOnChange?: (date: Date) => void;
	onNotesChange?: (value: string) => void;
	yourId: string;
	onYourIdChange: (event: React.FormEvent<HTMLInputElement>) => void;
	disableSendEmail?: boolean;
	sendEmailNotification?: boolean;
	onEmailNotificationChange: (checked: boolean) => void;
	showNotes: boolean;
	showEmailNotification: boolean;
}

const metadata = ModelMetadata.CommonPaymentFieldsModel;
const formControlWidthClassNames: string = 'col-md-4';
const notesTooltipOptions: IFormControlLabelledTooltipProps = {
	message: `Notes will only be visible to you.`,
	placement: 'top',
};

const yourIdMetadata = VTModelMetadata.VirtualTerminalPaymentRequestModel.YourId;

const yourIdTooltipOptions: IFormControlLabelledTooltipProps = {
	message: `Your own ID for the person from your records E.g. Tithe number`,
	placement: 'top',
};

@observer
export class PaymentCommonFields extends React.Component<IPaymentCommonFieldsProps, {}> {
	render() {
		const {
			customFields,
			onCustomFieldChange,
			onGivenOnChange,
			onNotesChange,
			model: { Notes, GivenOn, ReferenceFieldValues },
			paymentNounSentenceCase,
			paymentNounLowerCase,
			yourId,
			onYourIdChange,
			onEmailNotificationChange,
			disableSendEmail,
			sendEmailNotification,
			showNotes,
			showEmailNotification
		} = this.props;

		return (
			<div className="payment-common-fields">
				<div className="row">
					<PaymentReferenceFields customFields={customFields}
						referenceFieldValues={ReferenceFieldValues}
						onReferenceFieldValueChange={onCustomFieldChange}
					/>
				</div>
				<div className="row">
					<GivenOnField paymentNounSentenceCase={paymentNounSentenceCase} value={GivenOn} onChangeHandler={onGivenOnChange} />
					{showNotes &&
						<NotesField value={Notes} onNotesChange={onNotesChange} />
					}
					{ // if not showing notes we display YourId next to GivenOn
						!showNotes &&
						<YourIdField
							yourId={yourId}
							onYourIdChange={onYourIdChange} />
					}
				</div>
				{ showEmailNotification &&
					<FormControlCheckbox
						containerClassNames="vt-form-payment-details-send-email" disabled={disableSendEmail}
						value={sendEmailNotification} onChangeHandler={onEmailNotificationChange}
						text={`Send email notifications to this person about this ${paymentNounLowerCase}`}
						acceptanceTestTargetId="SendEmail" />}
				{ showNotes &&
					<div className="row">
						<YourIdField
							yourId={yourId}
							onYourIdChange={onYourIdChange} />
					</div>}
			</div>
		);
	}
}

@injectAnalytics
@observer
export class YourIdField extends React.Component<WithAnalytics & {
	yourId: string,
	onYourIdChange: (event: React.FormEvent<HTMLInputElement>) => void
}> {
	render() {
		const {
			yourId,
			onYourIdChange
		} = this.props;

		return <FormControlLabelled cssClassNames="col-md-4"
			label="Your ID (optional)"
			tooltipOptions={yourIdTooltipOptions}
			formControlProps={{
				formControlType: FormControlType.Text,
				type: 'text',
				name: yourIdMetadata.propertyName,
				placeholder: 'e.g. 123456',
				maxLength: yourIdMetadata.validationRules.length.parameters.max,
				validationRules: yourIdMetadata.validationRules,
				value: yourId,
				ignorePanLikeValidation: true,
				onChangeHandler: onYourIdChange,
				...(NewFeatures.SetupPinpointAnalytics && { onBlurHandler: this.onBlurHandler }),
				acceptanceTestTargetId: 'your id'
			}} />;
	}

	private onBlurHandler = () => {
		if (this.props.analytics && this.props.yourId) {
			const { feature, subFeature } = this.props.analytics;
			recordEvent({ feature, subFeature, eventTypeLabel: 'completeYourId' });
		}
	}
}

@injectAnalytics
@observer
export class PaymentReferenceFields extends React.Component<WithAnalytics & {
	customFields: CustomFieldViewModel[],
	referenceFieldValues: CustomFieldEditModel[],
	onReferenceFieldValueChange: (field: CustomFieldEditModel) => void,
}> {
	render(): any {
		const { customFields } = this.props;
		return (
			customFields.map(customField => this.renderCustomField(customField))
		);
	}

	private renderCustomField = (customField: CustomFieldViewModel) => {
		const { onReferenceFieldValueChange, referenceFieldValues, analytics } = this.props;
		const value = referenceFieldValues.filter(x => x.Key === customField.Key).map(x => x.Value)[0];

		const onChange = (model: CustomFieldEditModel) => {
			if (NewFeatures.SetupPinpointAnalytics && analytics && customField.Type === CustomFieldType.Fund) {
				const { feature, subFeature } = analytics;
				recordEvent({ feature, subFeature, eventTypeLabel: 'selectFund' })
			}
			onReferenceFieldValueChange(model);
		}

		return <CustomField
			key={customField.Key}
			customField={customField}
			className={formControlWidthClassNames}
			onChange={onChange}
			value={value} />;
	}
}

@injectAnalytics
@observer
export class GivenOnField extends React.Component<WithAnalytics & {
	paymentNounSentenceCase: string,
	value: Date,
	onChangeHandler: (date: Date) => void,
}> {
	render() {
		const { paymentNounSentenceCase, value, onChangeHandler } = this.props;
		return (
			<FormControlLabelled cssClassNames={formControlWidthClassNames}
				label={`${paymentNounSentenceCase} received on (optional)`}
				formControlProps={{
					formControlType: FormControlType.DatePicker,
					name: metadata.GivenOn.propertyName,
					placeholder: 'Choose a date',
					value: value,
					onChangeHandler: onChangeHandler,
					...(NewFeatures.SetupPinpointAnalytics && { onBlurHandler: this.onBlurHandler }),
				}} />
		);
	}

	private onBlurHandler = () => {
		if (this.props.analytics && this.props.value) {
			const { feature, subFeature } = this.props.analytics;
			recordEvent({ feature, subFeature, eventTypeLabel: 'completeGiftReceivedOn' });
		}
	}
}

@injectAnalytics
@observer
export class NotesField extends React.Component<WithAnalytics & {
	value: string,
	onNotesChange: (notes: string) => void,
}> {
	render() {
		const { value } = this.props;
		return (
			<FormControlLabelled cssClassNames={formControlWidthClassNames}
				label={`Notes (optional)`}
				tooltipOptions={notesTooltipOptions}
				formControlProps={{
					formControlType: FormControlType.Textarea,
					name: metadata.Notes.propertyName,
					placeholder: 'Notes',
					maxLength: metadata.Notes.validationRules.length.parameters.max,
					validationRules: metadata.Notes.validationRules,
					rows: 1,
					value: value || '',
					onChangeHandler: this.handleNotesChange,
					...(NewFeatures.SetupPinpointAnalytics && { onBlurHandler: this.onBlurHandler }),
				}}
			/>
		);
	}

	private handleNotesChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
		const { onNotesChange } = this.props;
		if (isFunction(onNotesChange)) {
			onNotesChange(event.currentTarget.value);
		}
	}

	private onBlurHandler = () => {
		if (this.props.analytics && this.props.value) {
			const { feature, subFeature } = this.props.analytics;
			recordEvent({ feature, subFeature, eventTypeLabel: 'completeNotes' });
		}
	}
}
