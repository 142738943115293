
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Button } from '../../../components/button';
import { ModalDialog, IModalDialogButton } from '../../../components/modal-dialog';

export interface IDepositBatchModalDialogProps {
	batchCount:number;
	confirm: () => void;
}

export class DepositBatchModalDialog extends React.Component<IDepositBatchModalDialogProps, { isProcessingRequest: boolean }> {
	constructor(props) {
		super(props);
		this.state = { isProcessingRequest: false };
	}

	submitBatches = () => {
		this.setState({
			isProcessingRequest: true
		});

		this.props.confirm();
	}

	render() {
		const confirmButton = <Button key="Confirm"
			className="btn btn-primary"
			onClick={this.submitBatches}
			isProcessingRequest={this.state.isProcessingRequest}>Confirm</Button>;
		const buttons: (IModalDialogButton | JSX.Element)[] = [
			{
				label: 'Cancel',
				dismiss: true,
				isPrimary: false,
				hideOnProgress: true
			},
			confirmButton];
		const batchCountDisplay = this.props.batchCount > 1 ? <strong>{this.props.batchCount} batches</strong> : 'batch';
		const pronoun = this.props.batchCount > 1 ? 'these' : 'this';
		return (<ModalDialog title=""
			buttons={buttons}
			inProgress={this.state.isProcessingRequest}>
			Are you sure you want to mark {pronoun} {batchCountDisplay} as deposited? This action cannot be undone.
		</ModalDialog>);
	}
}
