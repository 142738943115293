import * as React from 'react';
import { TransitionGroup } from 'react-transition-group-v1';
import { createNamespace } from '../../../../Shared/helpers/namespace';
import { observer } from 'mobx-react';
import { RecentGiftsGridViewModel } from './recent-gifts-grid-view-model';
import { RecentGiftEntryViewModel, Currency, PaymentLabel } from '../../virtual-terminal-generated';
import { getRecentGiftsGridColumnConfig } from './recent-gifts-grid';
import { DataGridRowData } from '../../../components/data-grid/data-grid-row-data';
import { IDataGridColumn, DataGridCell } from '../../../components/data-grid/data-grid';
import { SvgWrapper } from '../../../components/svg-wrapper';
import { ResponsiveBreakpoint, ResponsiveVisibility } from '../../../helpers/responsive-helper';
import { RenderResponsive } from '../../../components/hoc-behavior/render-responsive';
import { Formatter } from '../../../helpers/formatter';
import { LoadingBox } from '../../../components/loading-box';
import { velocity } from '../../../helpers/velocity';
import { testFrequencyCodeReadable, frequencyWithAnnualCodeReadable, frequencyWithQuarterlyCodeReadable, frequencyWithSemiAnnualCodeReadable, frequencyCodeReadable } from '../../constants';
import { ModalDialogCommander } from '../../../components/modal-dialog-commander';
import { DeletePaymentConfirmationDialog, DeletePaymentConfirmationDialogOld } from '../delete-payment-confirmation-dialog';

const ns = createNamespace('vt-payments-payment-details');

const RecentGiftsTitle = ({ paymentLabel, isRecurring, isNonCash }) => {
	return (<h3 className={ns('title')}>
		{GetPaymentLabelText(paymentLabel, isRecurring, isNonCash)} details
	</h3>);
};

const GetPaymentLabelText = (paymentLabel, isRecurring, isNonCash) => {
	if (isRecurring) {
		return `Recurring ${paymentLabel.NounLowerCase}`;
	} else if(isNonCash) {
		return paymentLabel.NonCashNounSentenceCase;
	} else {
		return paymentLabel.NounSentenceCase;
	}
};

@observer
export class RecentGiftsPaymentDetails extends React.Component<{
	gift: RecentGiftEntryViewModel,
	vm: RecentGiftsGridViewModel,
	updateExpandedRow: (expandedRowKey: string) => void,
}, {}> {
	private columns: { [key: string]: IDataGridColumn };

	constructor(props) {
		super(props);

		this.columns = getRecentGiftsGridColumnConfig();

		Object.assign(this.columns, { CloseGift: { displayName: '', formatter: this.formatClose } });
		Object.assign(this.columns.PaymentMethod, { responsiveBreakpoints: ResponsiveBreakpoint.Xs | ResponsiveBreakpoint.Sm });
		Object.assign(this.columns.FundDisplayValue, { responsiveBreakpoints: ResponsiveBreakpoint.Xs | ResponsiveBreakpoint.Sm });
	}

	render() {
		const { gift, vm: { paymentLabel, expandedGiftDetails, acceptableLoadingTimeExceeded } } = this.props;
		const { EditUrl, IsDeletable, IsRecurring, IsNonCash } = gift;

		return (
			<div className={ns()}>
				<header className="panel-heading">
					<RenderResponsive visibility={ResponsiveVisibility.Visible} breakpoints={ResponsiveBreakpoint.Xs | ResponsiveBreakpoint.Sm}>
						<RecentGiftsTitle paymentLabel={paymentLabel} isRecurring={IsRecurring} isNonCash={IsNonCash} />
					</RenderResponsive>
					<DataGridRowData data={gift} columns={this.columns} onSelectRow={null} />
				</header>
				<div className={`panel-body ${!expandedGiftDetails && acceptableLoadingTimeExceeded ? ns('details-loading') : ''}`}>
					<RenderResponsive visibility={ResponsiveVisibility.Hidden} breakpoints={ResponsiveBreakpoint.Xs | ResponsiveBreakpoint.Sm}>
						<RecentGiftsTitle paymentLabel={paymentLabel} isRecurring={IsRecurring} isNonCash={IsNonCash} />
					</RenderResponsive>
					<TransitionGroup>
						{expandedGiftDetails
							? this.renderExpandedGiftDetails(expandedGiftDetails, paymentLabel)
							: acceptableLoadingTimeExceeded && <LoadingBox key="full-details-loading" text={`Loading ${paymentLabel.NounLowerCase} details...`} />
						}
					</TransitionGroup>
				</div>
				{(EditUrl || IsDeletable) && (
					<footer className="panel-footer panel-footer-btn-group">
						{IsDeletable &&
							<button type="button"
								className={`btn btn-ghost ${ns('delete-btn')}`}
								data-pp-at-target="Delete payment details"
								onClick={this.onDeleteGift}>
								Delete
							</button>}
						{EditUrl && <a href={EditUrl} className="btn btn-default">Edit</a>}
					</footer>
				)}
			</div>
		);
	}

	componentDidMount() {
		this.props.vm.initTypedGiftDetailsRequest(this.props.gift);
	}

	componentWillUnmount() {
		this.props.vm.updateExpandedGiftDetails(null);
	}

	renderExpandedGiftDetails = (expandedGiftDetails: RecentGiftEntryViewModel, paymentLabel: PaymentLabel) => {
		return expandedGiftDetails.IsNonCash
			? <RecentGiftGridNonCashGiftDetailsFull key="full-details" expandedGiftDetails={expandedGiftDetails} paymentLabel={paymentLabel} />
			: <RecentGiftGridPaymentDetailsFull key="full-details" expandedGiftDetails={expandedGiftDetails} paymentLabel={paymentLabel} />;
	}

	onDeleteGift = () => {
		const {
			vm: {
				paymentLabel,
				initPaymentOrNonCashGiftDeleteRequest,
				modalDialogProcessingViewModel
			},
			gift: {
				IsNonCash,
				EncodedToken,
				NonCashGiftId,
				IsRecurring
			}
		} = this.props;
		const giftDetails = { IsNonCash, EncodedToken, NonCashGiftId, IsRecurring };
		ModalDialogCommander.showReactDialog(
			<DeletePaymentConfirmationDialog
				paymentLabel={paymentLabel}
				initGiftDeleteRequest={initPaymentOrNonCashGiftDeleteRequest}
				giftDetails={giftDetails}
				modalDialogProcessingViewModel={modalDialogProcessingViewModel}
			/>
		);
	}

	private closeGiftDetails = () => {
		this.props.vm.updateExpandedGiftDetails(null);
		this.props.updateExpandedRow(null);
	}

	private formatClose = (gift: RecentGiftEntryViewModel, classNames: string) => (
		<DataGridCell key="CloseGift" classNames={classNames}>
			<button type="button" className={`btn btn-link ${ns('close-btn')}`} onClick={this.closeGiftDetails}>
				<SvgWrapper svg="close-cross" className={ns('close-icon')} title={`Close ${this.props.vm.paymentLabel.NounSentenceCase}`} />
			</button>
		</DataGridCell>
	)
}

@observer
class RecentGiftGridPaymentDetailsFull extends React.Component<{ expandedGiftDetails: RecentGiftEntryViewModel, paymentLabel: PaymentLabel }, {}> {
	private content: HTMLElement;

	render() {
		const { expandedGiftDetails, paymentLabel } = this.props;
		const givenOnDateFormat = 'dddd, D MMMM YYYY';
		const itemClassname = ns('item');
		const {
			AmountAsMoney: { Currency: amountCurrency, Amount },
			PaymentMethod,
			PayerDisplayName,
			PayerDisplayEmail,
			PayerDisplayPhone,
			PayerAddress,
			FundDisplayValue,
			AdditionalInfo: { ReferenceFields, Notes, GivenOn },
			FrequencyCode: frequencyCode,
			WhenNextPaymentDue
		} = expandedGiftDetails;

		const frequencyText = frequencyCodeReadable[frequencyCode]
	 		|| frequencyWithAnnualCodeReadable[frequencyCode]
			|| frequencyWithSemiAnnualCodeReadable[frequencyCode]
			|| frequencyWithQuarterlyCodeReadable[frequencyCode]
			|| (NewFeatures.TesterCommandsEnabled && testFrequencyCodeReadable[frequencyCode]);

		return (
			<div className={`row ${ns('body-content')}`} ref={(content) => this.content = content}>
				<div className={`${ns('column-payment')} col-lg-3 col-md-4 col-sm-12`}>
					<div className={itemClassname}>
						<label id={ns('label-amount')}>Amount</label>
						<div className={ns('amount')} aria-labelledby={ns('label-amount')}>
							{Currency[amountCurrency]} <span className={ns('amount-number')}>${Formatter.formatNumberForDisplay(Amount)}</span>
						</div>
					</div>
					<div className={itemClassname}>
						<label id={ns('label-paymentmethod')}>Payment Method</label>
						<div aria-labelledby={ns('label-paymentmethod')}>{PaymentMethod}</div>
					</div>
					{GivenOn && (
						<div className={itemClassname}>
							<label id={ns('label-givenon')}>Given on</label>
							<div aria-labelledby={ns('label-givenon')}>{Formatter.formatDate(GivenOn, givenOnDateFormat)}</div>
						</div>
					)}
					{frequencyCode && (
						<div className={itemClassname}>
							<label id={ns('label-frequency')}>Frequency</label>
							<div aria-labelledby={ns('label-frequency')}>{frequencyText}</div>
						</div>
					)}
					{WhenNextPaymentDue && (
						<div className={itemClassname}>
							<label id={ns('label-whennextpaymentdue')}>Next {paymentLabel.NounLowerCase} due</label>
							<div aria-labelledby={ns('label-whennextpaymentdue')}>{Formatter.formatDate(WhenNextPaymentDue)}</div>
						</div>
					)}
				</div>
				<div className={`${ns('column-payer')} col-lg-3 col-md-4 col-sm-6`}>
					<div className={itemClassname}>
						<label id={ns('label-name')}>Name</label>
						<div aria-labelledby={ns('label-name')}>{PayerDisplayName}</div>
					</div>
					{PayerDisplayEmail && (
						<div className={itemClassname}>
							<label id={ns('label-email')}>Email</label>
							<div aria-labelledby={ns('label-email')}>{PayerDisplayEmail}</div>
						</div>)}
					{PayerDisplayPhone && (
						<div className={itemClassname}>
							<label id={ns('label-phone')}>Phone</label>
							<div aria-labelledby={ns('label-phone')}>{PayerDisplayPhone}</div>
						</div>)}
					{PayerAddress && (
						<div className={itemClassname}>
							<label id={ns('label-address')}>Address</label>
							<div aria-labelledby={ns('label-address')}>{PayerAddress}</div>
						</div>)}
				</div>
				<div className="col-lg-3 col-md-4 col-sm-6">
					<div className={itemClassname}>
						<label id={ns('label-fund')}>Fund</label>
						<div aria-labelledby={ns('label-fund')}>{FundDisplayValue}</div>
					</div>
					{ReferenceFields && ReferenceFields.length > 0 && ReferenceFields.map((field) => {
						const { Key, Label, Value } = field;

						return Value && (
							<div key={Key} className={itemClassname}>
								<label id={Key}>{Label}</label>
								<div aria-labelledby={Key}>{Value}</div>
							</div>
						);
					})}
					{Notes && (
						<div className={itemClassname}>
							<label id={ns('label-notes')}>Notes</label>
							<div aria-labelledby={ns('label-notes')}>{Notes}</div>
						</div>)}
				</div>
			</div>
		);
	}

	componentWillEnter(callback) {
		this.content.style.opacity = '0';
		velocity(this.content, 'fadeIn', {
			duration: 250,
			easing: 'ease-in',
			complete: callback
		});
	}
}


@observer
class RecentGiftGridNonCashGiftDetailsFull extends React.Component<{ expandedGiftDetails: RecentGiftEntryViewModel, paymentLabel: PaymentLabel }, {}> {
	private content: HTMLElement;

	render() {
		const { expandedGiftDetails, paymentLabel } = this.props;
		const givenOnDateFormat = 'dddd, D MMMM YYYY';
		const itemClassname = ns('item');
		const {
			AmountAsMoney: { Currency: amountCurrency, Amount },
			PaymentMethod: assetType,
			PayerDisplayName,
			PayerDisplayEmail,
			PayerDisplayPhone,
			PayerAddress,
			FundDisplayValue,
			AdditionalInfo: { ReferenceFields, Notes, GivenOn },
			DescriptionForDonor,
			DescriptionForMerchant
		} = expandedGiftDetails;

		return (
			<div className={`row ${ns('body-content')}`} ref={(content) => this.content = content}>
				<div className={`${ns('column-payment')} col-lg-3 col-md-4 col-sm-12`}>
					<div className={itemClassname}>
						<label id={ns('label-asset-type')}>Asset Type</label>
						<div className={ns('asset-type')} aria-labelledby={ns('label-asset-type')}>
							{assetType}
						</div>
					</div>
					<div className={itemClassname}>
						<label id={ns('label-paymentmethod')}>Amount</label>
						<div aria-labelledby={ns('label-amount')}>{Currency[amountCurrency]} <span className={ns('amount-number')}>${Formatter.formatNumberForDisplay(Amount)}</span></div>
					</div>
					{GivenOn && (
						<div className={itemClassname}>
							<label id={ns('label-givenon')}>Given on</label>
							<div aria-labelledby={ns('label-givenon')}>{Formatter.formatDate(GivenOn, givenOnDateFormat)}</div>
						</div>
					)}
				</div>
				<div className={`${ns('column-payer')} col-lg-3 col-md-4 col-sm-6`}>
					<div className={itemClassname}>
						<label id={ns('label-name')}>Name</label>
						<div aria-labelledby={ns('label-name')}>{PayerDisplayName}</div>
					</div>
					{PayerDisplayEmail && (
						<div className={itemClassname}>
							<label id={ns('label-email')}>Email</label>
							<div aria-labelledby={ns('label-email')}>{PayerDisplayEmail}</div>
						</div>)}
					{PayerDisplayPhone && (
						<div className={itemClassname}>
							<label id={ns('label-phone')}>Phone</label>
							<div aria-labelledby={ns('label-phone')}>{PayerDisplayPhone}</div>
						</div>)}
					{PayerAddress && (
						<div className={itemClassname}>
							<label id={ns('label-address')}>Address</label>
							<div aria-labelledby={ns('label-address')}>{PayerAddress}</div>
						</div>)}
				</div>
				<div className="col-lg-3 col-md-4 col-sm-6">
					<div className={itemClassname}>
						<label id={ns('label-fund')}>Fund</label>
						<div aria-labelledby={ns('label-fund')}>{FundDisplayValue}</div>
					</div>
					{ReferenceFields && ReferenceFields.length > 0 && ReferenceFields.map((field) => {
						const { Key, Label, Value } = field;

						return Value && (
							<div key={Key} className={itemClassname}>
								<label id={Key}>{Label}</label>
								<div aria-labelledby={Key}>{Value}</div>
							</div>
						);
					})}
					<div className={itemClassname}>
						<label id={ns('label-description-for-donor')}>{paymentLabel.NounSentenceCase} description</label>
						<div aria-labelledby={ns('label-fund')}>{DescriptionForDonor}</div>
					</div>
					{DescriptionForMerchant && (
						<div className={itemClassname}>
							<label id={ns('label-description-for-merchant')}>Detailed description</label>
							<div className={ns('description-for-merchant')} aria-labelledby={ns('label-description-for-merchant')}>{DescriptionForMerchant}</div>
						</div>)}
				</div>
			</div>
		);
	}

	componentWillEnter(callback) {
		this.content.style.opacity = '0';
		velocity(this.content, 'fadeIn', {
			duration: 250,
			easing: 'ease-in',
			complete: callback
		});
	}
}
