import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { CustomSelect, CustomSelectItem, getPrefixedCustomSelectId } from './custom-select';
import { getUniqueElementId } from './form-controls/form-control-utils';
import { isFunction } from '../utils/is-function';
import { classNames } from '../../Shared/utils/classnames';
import { Fragment } from '../../Shared/components/fragment';
import { LabelItem } from './label/labels';

export interface IListingSelectorWithLabelProps {
	selectedListingId: string;
	availableListings: { id: string; listingName: string }[];
	onChange: (listingId: string) => void;
	acceptanceTestTargetId?: string;
	isUnavailable?: boolean;
	className?: string;
	componentId?: string;
	singleLine?: boolean;
	childrenClassName?: string;
	labelForListingSelection?: string;
	isDisabled?: boolean;
}

export class ListingSelectorWithLabel extends React.Component<IListingSelectorWithLabelProps, { open: boolean }> {
	private componentId = this.props.componentId || getUniqueElementId();
	private listingSelector: ListingSelector;
	constructor(props) {
		super(props);
		this.state = { open: false };
	}
	render() {
		const {
			className,
			children,
			availableListings,
			singleLine = true,
			childrenClassName = 'col-sm-4',
			labelForListingSelection = 'Select a listing',
			...rest
		} = this.props;
		return (
			<div
				className={classNames(
					'row',
					singleLine ? 'listing-selector-container' : 'listing-selector-multiline-container',
					className
				)}
			>
				{availableListings.length > 1 ? (
					<Fragment>
						<label
							onClick={this.handleLabelClick}
							htmlFor={getPrefixedCustomSelectId(this.componentId)}
							className={classNames(
								childrenClassName,
								'listing-selector-label',
								!singleLine && 'listing-selector-multiline-child'
							)}
						>
							{labelForListingSelection}
						</label>
						<div
							className={classNames(
								childrenClassName,
								'listing-selector',
								!singleLine && 'listing-selector-multiline-child'
							)}
						>
							<ListingSelector
								componentId={this.componentId}
								availableListings={availableListings}
								{...rest}
								ref={(listingSelector) => {
									this.listingSelector = listingSelector;
								}}
							/>
						</div>
					</Fragment>
				) : (
					<Fragment>
						<label
							className={classNames(
								childrenClassName,
								'listing-selector-label',
								!singleLine && 'listing-selector-multiline-child'
							)}
						>
							Listing
						</label>
						<div
							className={classNames(
								childrenClassName,
								'listing-name',
								!singleLine && 'listing-selector-multiline-child'
							)}
						>
							{availableListings[0].listingName}
						</div>
					</Fragment>
				)}
			</div>
		);
	}

	private handleLabelClick = (event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault();
		(ReactDOM.findDOMNode(this.listingSelector).firstChild as HTMLElement).focus();
	};
}

interface IListingSelectorProps {
	selectedListingId: string;
	availableListings: { id: string; listingName: string, labels?: LabelItem[] }[];
	onChange: (listingId: string) => void;
	acceptanceTestTargetId?: string;
	componentId: string;
	isUnavailable?: boolean;
	isDisabled?: boolean;
}

class ListingSelector extends React.Component<IListingSelectorProps, { open: boolean }> {
	state = { open: false };

	render() {
		const { selectedListingId, componentId, isUnavailable, isDisabled } = this.props;
		return (
			<CustomSelect
				componentId={componentId}
				open={this.state.open}
				selectedItem={selectedListingId}
				onItemSelected={this.handleItemSelected}
				onRequestOpen={this.handleRequestOpen}
				onRequestClose={this.handleRequestClose}
				acceptanceTestTargetId={this.props.acceptanceTestTargetId}
				isUnavailable={isUnavailable}
				isDisabled={isDisabled}
			>
				{this.props.availableListings.map((x) => (
					<CustomSelectItem key={x.id} value={x.id} displayLabel={x.listingName} labels={x.labels} />
				))}
			</CustomSelect>
		);
	}

	handleItemSelected = (id: string) => {
		const { onChange } = this.props;
		this.setState({ open: false });

		if (isFunction(onChange)) {
			onChange(id);
		}
	};

	handleRequestOpen = () => {
		this.setState({ open: true });
	};

	handleRequestClose = () => {
		this.setState({ open: false });
	};
}
