import { PaymentLabel } from '../loggedinweb-generated';
import { NodeListHelper } from '../helpers/nodelisthelper';

export class OrganizationStepListingChooser {
	private merchantSelector: HTMLInputElement;
	private recurringRadio: HTMLInputElement;
	private onetimeRadio: HTMLInputElement;
	private givingLinkInput: HTMLInputElement;
	private giveButtonTemplate: HTMLTextAreaElement;
	private emailTextArea: HTMLTextAreaElement;
	private givingLinkTitle: HTMLElement;
	private relevantEmailTabs: HTMLElement[];
	private selectedTab: HTMLElement;
	private givingLink: string;

	constructor(private merchantData: Object, private organizationName: string, private paymentLabel: PaymentLabel) { }

	public initialize() {
		var merchantPicker = document.querySelector('.merchant-picker') as HTMLElement;
		this.giveButtonTemplate = document.querySelector('#give-button-template') as HTMLTextAreaElement;
		this.emailTextArea = document.querySelector('textarea.migrate-users-email-text') as HTMLTextAreaElement;

		this.initializeRelevantEmailTabs();

		if(merchantPicker) {
			this.initializeMerchantPicker(merchantPicker);
		} else {
			this.initializeWithoutMerchantPicker();
		}
	}

	private initializeRelevantEmailTabs() {
		this.relevantEmailTabs = NodeListHelper.toArray(document.querySelectorAll('ul.relevant-email-nav>li'));
		this.relevantEmailTabs.forEach(tab => tab.addEventListener('click', this.onSelectRelevantEmailTab));
		this.selectedTab = this.relevantEmailTabs[0];
	}

	private initializeWithoutMerchantPicker() {
		this.givingLink = 'https://pushpay.com/pay/_________';
		if(this.giveButtonTemplate) {
			this.updateGiveButtonTemplate('Give');
		}

		if(this.emailTextArea) {
			this.updateRelevantEmail();
		}
	}

	private initializeMerchantPicker(merchantPicker: HTMLElement) {
		this.merchantSelector = merchantPicker.querySelector('[data-merchant-listing-id]') as HTMLInputElement;
		this.onetimeRadio = merchantPicker.querySelector('#giving-type_oneoff') as HTMLInputElement;
		this.recurringRadio = merchantPicker.querySelector('#giving-type_recurring') as HTMLInputElement;
		this.givingLinkInput = merchantPicker.querySelector('#give-link') as HTMLInputElement;
		this.givingLinkTitle = document.querySelector('label[data-giving-link-title]') as HTMLElement;

		this.merchantSelector.addEventListener('change', this.generateGivingLink);
		if(this.onetimeRadio) {
			this.onetimeRadio.addEventListener('change', this.generateGivingLink);
		}
		if(this.recurringRadio) {
			this.recurringRadio.addEventListener('change', this.generateGivingLink);
		}

		this.generateGivingLink();
	}


	private generateGivingLink = () => {
		const merchant = this.merchantData[this.merchantSelector.value];
		this.givingLink = merchant.HppUrl;
		if (this.recurringRadio && this.recurringRadio.checked) {
			this.givingLink += '?r=monthly';
		}

		if(this.givingLinkInput) {
			this.givingLinkInput.value = this.givingLink;
		}

		if(this.giveButtonTemplate) {
			this.updateGiveButtonTemplate(merchant.PayButtonLabel);
		}

		if(this.givingLinkTitle) {
			const label: string = this.paymentLabel ? this.paymentLabel.ActionLowerCase : 'giving';
			this.givingLinkTitle.innerHTML = `${merchant.Name} ${label} link`;
		}

		if(this.emailTextArea) {
			this.updateRelevantEmail();
		}
	}

	private updateGiveButtonTemplate = (payButtonLabel: string) => {
		const buttonHtml: string = `<div>
	<center>
		<a href="${this.givingLink}">
			<input type="button" value="${payButtonLabel}"
			formtarget="_blank" rel="noopener noreferrer" style="
			align-content: center;
			background: rgb(207,21,10);
			font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
			font-size: 1.60em;
			border-bottom-left-radius: 5px;
			border-top-left-radius: 5px;
			border-bottom-right-radius: 5px;
			border-top-right-radius: 5px;
			height: 50px;
			width: 150px;
			border: none;
			color: white;
			font-weight: 500;
			letter-spacing: 1px;
			cursor: pointer;
			-webkit-appearance: none" />
		</a>
	</center>
</div>`;
		this.giveButtonTemplate.value = buttonHtml;
	}

	private updateTransitionEmailText = () => {
		/* tslint:disable max-line-length */
		const emailHtml: string = `Hello!

We have an important update regarding online giving at ${this.organizationName}, and we need your help! Your generosity and financial partnership with ${this.organizationName} is immensely appreciated. You currently have scheduled giving set up in our old online giving system through [Old Giving System]. We are now in the process of transitioning our online system to a new giving experience called Pushpay, which allows for simple giving from a variety of platforms, including mobile giving. This has replaced online giving in [Old Giving System].

It is our goal to get all recurring giving switched over in the next [Enter Campaign Time Remaining], so we need to get your scheduled gifts transitioned to the new system.

Here’s how:

Part One: Cancel scheduled gifts in [Old Giving System]
[Insert Cancellation Steps Here]

Part Two: Setting up a new recurring gift using Pushpay
Visit ${this.givingLink}/recurring and set up your automatic gift using Pushpay. The recurring gift option should already be selected and you can simply set up your automatic gift. After you set up your recurring gift, you will be prompted to complete the setup of your new account!

As always, if you have any questions, please call [phone number] or email us at [email address]. We are so blessed that you have made the commitment to give in this way, and are honored that you have decided to help us continue to expand God’s family.

Blessings,
The Team at ${this.organizationName}.
`;
		/* tslint:enable max-line-length */
		this.emailTextArea.value = emailHtml;
	}

	private onSelectRelevantEmailTab = (e: MouseEvent) => {
		e.preventDefault();
		this.selectedTab = e.currentTarget as HTMLElement;
		this.updateRelevantEmail();
	}

	private updateRelevantEmail = () => {
		this.setActiveEmailTab(this.selectedTab);
		this.emailTextArea.value = this.getRelevantEmail(this.selectedTab.classList);
	}



	private setActiveEmailTab = (selectedTab: HTMLElement) => {
		this.relevantEmailTabs.forEach(({ classList }) => classList.remove('active'));
		selectedTab.classList.add('active');
	}

	private getRelevantEmail = (classList: DOMTokenList) => {
		if(classList.contains('tab-version-1-with-video')) {
			return this.getEmailVersionOneWithVideo();
		}

		if(classList.contains('tab-version-1-without-video')) {
			return this.getEmailVersionOneWithoutVideo();
		}

		if(classList.contains('tab-version-2')) {
			return this.getEmailVersionTwo();
		}

		return '';
	}


	private getEmailVersionOneWithVideo = () =>
		/* tslint:disable max-line-length */
		`Hi [Name],

You are receiving this email because you are a ${this.paymentLabel.ActionLowerCase} leader at ${this.organizationName} and I want everyone to follow your example! This two minute video message from me explains why.

Thanks for watching!

[Insert Video]

As you heard in the video, we are transitioning to a new online ${this.paymentLabel.ActionLowerCase} platform called Pushpay. It is our goal to complete this process within the month, so we need your help to move your current online ${this.paymentLabel.ActionLowerCase} to the new platform. It’s a simple, two-step process that will take no more than ten minutes.

Here’s how to transition from our current platform to Pushpay

Cancel your current scheduled gifts →
Set up a new recurring ${this.paymentLabel.NounLowerCase} using Pushpay →

After you set up your recurring ${this.paymentLabel.NounLowerCase}, you will be prompted to complete the set-up of your new account.

If you have any questions, please reply to this email.

Blessings,
The Team at ${this.organizationName}`
	/* tslint:enable max-line-length */

	private getEmailVersionOneWithoutVideo = () =>
		/* tslint:disable max-line-length */
		`Hi [Name],

You are receiving this email because you are a ${this.paymentLabel.ActionLowerCase} leader at ${this.organizationName} and I want everyone to follow your example! Not only do you have a generous heart but you also have generous habits which is why you have taken the time not only to ${this.paymentLabel.VerbLowerCase}, but to ${this.paymentLabel.VerbLowerCase} consistently with a recurring contribution to ${this.organizationName}. This intentionality does not go unnoticed and it has had a powerful impact.

I wanted to take this time to thank you but also to update you! In an effort to make your consistent ${this.paymentLabel.ActionLowerCase} easier, faster and more secure we are transitioning to a new online ${this.paymentLabel.ActionLowerCase} platform called Pushpay. It is our goal to complete this process by [Insert Target Date], so we need your help to move your current online ${this.paymentLabel.ActionLowerCase} to the new platform. It’s a simple, two-step process that will take no more than ten minutes.

Part One: Setting up a new recurring ${this.paymentLabel.NounLowerCase} using Pushpay.
Visit ${this.givingLink}/recurring and set up your automatic ${this.paymentLabel.NounLowerCase} using Pushpay. The recurring ${this.paymentLabel.NounLowerCase} option should already be selected and you can simply set up your automatic ${this.paymentLabel.NounLowerCase}. After you set up your recurring ${this.paymentLabel.NounLowerCase}, you will be prompted to complete the setup of your new account!

Part Two: Cancel scheduled ${this.paymentLabel.NounPluralLowerCase} in [Old ${this.paymentLabel.ActionSentenceCase} platform]
[Insert Cancellation Steps Here] or if you would like us to cancel your recurring ${this.paymentLabel.NounLowerCase} on our old platform please click this link and complete the form

As always, if you have any questions, please call [phone number] or email us at [email address]. We are so blessed that you have made the commitment to ${this.paymentLabel.VerbLowerCase} in this way, and are honored that you have decided to help us continue to expand God’s family.

Blessings,
The Team at ${this.organizationName}`
	/* tslint:disable max-line-length */

	private getEmailVersionTwo = () =>
		/* tslint:disable max-line-length */
		`Hello [Name],

As you may know we are now in the process of transitioning our online platform to a new ${this.paymentLabel.ActionLowerCase} experience called Pushpay, which allows for simple ${this.paymentLabel.ActionLowerCase} from a variety of platforms, including mobile ${this.paymentLabel.ActionLowerCase}. This has replaced online ${this.paymentLabel.ActionLowerCase} in [Old ${this.paymentLabel.ActionSentenceCase} platform].

It is our goal to get all recurring ${this.paymentLabel.ActionLowerCase} switched over in the next [Insert Target], so we need to get your scheduled ${this.paymentLabel.NounPluralLowerCase} transitioned to the new platform.

Here’s how:

Part One: Setting up a new recurring ${this.paymentLabel.NounLowerCase} using Pushpay
Visit ${this.givingLink}/recurring and set up your automatic ${this.paymentLabel.NounLowerCase} using Pushpay. The recurring ${this.paymentLabel.NounLowerCase} option should already be selected and you can simply set up your automatic ${this.paymentLabel.NounLowerCase}. After you set up your recurring ${this.paymentLabel.NounLowerCase}, you will be prompted to complete the setup of your new account!

Part Two: Cancel scheduled ${this.paymentLabel.NounPluralLowerCase} in [Old ${this.paymentLabel.ActionSentenceCase} platform]
[Insert Cancellation Steps Here] or if you would like us to cancel your recurring ${this.paymentLabel.NounLowerCase} on our old platform please click this link and complete the form.

As always, if you have any questions, please call [phone number] or email us at [email address]. We are so blessed that you have made the commitment to ${this.paymentLabel.VerbLowerCase} in this way, and are honored that you have decided to help us continue to expand God’s family.

Blessings,
The Team at ${this.organizationName}`
	/* tslint:disable max-line-length */

}

export function init(data) {
	const organizationStepListingChooser = new OrganizationStepListingChooser(data.MerchantData, data.OrganizationName, data.PaymentLabel);
	organizationStepListingChooser.initialize();
}
