
//scrolling starts when mouse pointer gets scrollingThreshold to the edge of the screen
const scrollingThreshold = 25;

const defaultDuration = 800;

export interface IScrollingBounds {
	minY: number;
	maxY: number;
	viewportHeight: number;
}

export interface IScrollingProvider {
	getCurrentScrollY: () => number;
	scrollTo: (y: number) => void;
	requestAnimationFrame: (callback: () => void) => number;
	cancelAnimationFrame: (request: number) => void;
	getMinMaxScrollingPosition: () => {min:number, max:number};
}

//its only purpose is to let tests inject MockScrollingProvider
//so it's possible to simulate scrolling
class WindowScrollingProvider implements IScrollingProvider {
	getCurrentScrollY(): number {
		return window.pageYOffset;
	}

	scrollTo(y: number): void {
		window.scrollTo(window.pageXOffset, y);
	}

	requestAnimationFrame(callback: () => void): number {
		return window.requestAnimationFrame(callback);
	}

	cancelAnimationFrame(request: number): void {
		window.cancelAnimationFrame(request);
	}

	getMinMaxScrollingPosition() {
		return {
			min:0,
			max: document.body.scrollHeight - window.innerHeight
		};
	}
}

export class ScrollingTransition {
	velocity: number;

	private from: number;
	private to: number;
	private maxDelta: number;
	private isStarted = false;
	private frameRequest: number;
	private update: (currentPosition: number) => void;
	private lastTime: number;
	private progress: number;
	private currentScrollOffset: number;
	private clientY: number;
	private scrollingProvider: IScrollingProvider;

	constructor(update?: (pageY: number) => void, scrollingProvider?: IScrollingProvider) {
		this.update = update;
		this.scrollingProvider = scrollingProvider || new WindowScrollingProvider();
	}

	startScrollingTo(to: number) {
		const scrollY = this.scrollingProvider.getCurrentScrollY();

		if (this.isStarted) {
			this.stop();
		}

		this.startScrolling(scrollY, to);
	}

	startScrollingIfNeeded(clientY: number, scrollingBounds: IScrollingBounds) {
		const scrollY = this.scrollingProvider.getCurrentScrollY();
		let to: number;
		let velocity: number;

		if (scrollY > scrollingBounds.minY && clientY < scrollingThreshold) {
			to = scrollingBounds.minY;
			velocity = (clientY - scrollingThreshold) / scrollingThreshold;
		} else if (scrollY < scrollingBounds.maxY && clientY > scrollingBounds.viewportHeight - scrollingThreshold) {
			to = scrollingBounds.maxY;
			velocity = (scrollingThreshold - (scrollingBounds.viewportHeight - clientY)) / scrollingThreshold;
		} else {
			//if the current scrolling positions is already beyond the desired position - stop current transition and don't start a new one
			this.stop();
			return;
		}

		this.clientY = clientY;

		//if already scrolling to the same position - just update velocity and clientY
		//otherwise restart
		if (this.isStarted && this.to !== to) {
			this.stop();
		}

		this.startScrolling(scrollY, to, velocity);
	}

	stop() {
		this.isStarted = false;
		this.cancelUpdateLoop();
	}

	private startScrolling(from: number, to: number, velocity?: number) {
		if (!this.isStarted) {
			this.from = from;
			this.to = this.getValidScrollToValue(to);
			this.maxDelta = this.to - this.from;

			if (velocity) {
				this.velocity = velocity;
			} else {
				//calculate velocity if not set
				this.velocity = this.maxDelta / defaultDuration;
			}

			if (this.maxDelta === 0) {
				return;
			}

			this.start();
		}
	}

	private getValidScrollToValue(to:number):number {
		const minMaxScrollingPosition = this.scrollingProvider.getMinMaxScrollingPosition();

		return Math.min(Math.max(to, minMaxScrollingPosition.min), minMaxScrollingPosition.max);
	}

	private start() {
		this.isStarted = true;
		this.lastTime = Date.now();

		this.progress = 0;
		this.currentScrollOffset = 0;

		const scrollingLoop = () => {
			var now = Date.now();
			var dT = now - this.lastTime;
			var newScrollOffset = this.scrollingProvider.getCurrentScrollY() - this.from;
			var minMaxScrollingPosition = this.scrollingProvider.getMinMaxScrollingPosition();

			if (Math.abs(this.currentScrollOffset - newScrollOffset) >= 1) {
				this.progress += newScrollOffset - this.currentScrollOffset;
			}

			this.progress += dT * this.velocity;

			this.lastTime = now;

			if (this.progress / this.maxDelta >= 1) {
				this.currentScrollOffset = this.maxDelta;
				this.stop();
			} else {
				this.currentScrollOffset = easeInOutQuad(this.progress / this.maxDelta) * this.maxDelta;

				if (this.from + this.currentScrollOffset <= minMaxScrollingPosition.min
					|| this.from + this.currentScrollOffset >= minMaxScrollingPosition.max) {
					this.stop();
					return;
				}
				this.frameRequest = this.scrollingProvider.requestAnimationFrame(scrollingLoop);
			}

			if (this.update) {
				this.update(this.from + this.currentScrollOffset + this.clientY);
			}
			this.scrollingProvider.scrollTo(this.from + this.currentScrollOffset);
		};
		this.frameRequest = this.scrollingProvider.requestAnimationFrame(scrollingLoop);
	}

	private cancelUpdateLoop() {
		if (this.frameRequest) {
			this.scrollingProvider.cancelAnimationFrame(this.frameRequest);
			this.frameRequest = null;
		}
	}
}

function easeInOutQuad(time: number) {
	return time * time / (time * time + (1 - time) * (1 - time));
}
