import {
	ScheduleDetailsSortableColumn,
	ScheduleDetailViewModel,
	SchedulesListViewModel,
	PaymentLabel,
	SchedulesListFilteredResponse,
} from '../../schedule-import-generated';
import { MultiSelectItem, TransactionExportButton } from '../../../loggedinweb-generated';
import { observable, action, toJS } from 'mobx';
import { SortDirection } from '../../../components/data-grid/data-grid';
import { ScheduleDetailsProps } from '../../components/schedule-details-expanded-row';
import { LocationHistoryHelper, QueryParamConverter } from '../../../helpers/location-history-helper';

export class SchedulesDetailsViewModel {

	migrationId: number;
	currentMigrationUrl: string;
	statusOptions: MultiSelectItem[];
	@observable statusSelectedValues: string[];
	listingOptions: MultiSelectItem[];
	@observable listingSelectedValues: string[];
	fundsOptions: MultiSelectItem[];
	@observable fundsSelectedValues: string[];
	@observable isLoadingData: boolean = true;
	@observable gridData: ScheduleDetailViewModel[];
	@observable pageCount: number;
	@observable currentPage: number;
	@observable sortedBy: ScheduleDetailsSortableColumn = ScheduleDetailsSortableColumn.Unknown;
	@observable sortDirection: SortDirection = SortDirection.ASCENDING;
	paymentLabel: PaymentLabel;
	@observable scheduleCount: number;
	@observable monthlyTotal: number;
	@observable expandedRowData: ScheduleDetailsProps = null;
	exportButtons: TransactionExportButton[];

	private readonly locationHelper: LocationHistoryHelper<ScheduleDetailsFilterValues>;
	private readonly stateConverter: StateConverter = new StateConverter();

	constructor(vm: SchedulesListViewModel) {
		this.locationHelper = new LocationHistoryHelper<ScheduleDetailsFilterValues>(this.stateConverter, this.handleLocationStateChange);
		const revivedState = this.locationHelper.getState();

		this.migrationId = vm.MigrationId;
		this.statusOptions = vm.AvailableStatusItems;
		this.statusSelectedValues = revivedState ? revivedState.SelectedStatus : [];
		this.listingOptions = vm.AvailableListingItems;
		this.listingSelectedValues = revivedState ? revivedState.SelectedListings : [];
		this.fundsOptions = vm.AvailableFundItems;
		this.fundsSelectedValues = revivedState ? revivedState.SelectedFunds : [];
		this.currentPage = revivedState ? revivedState.CurrentPage : 1;
		this.paymentLabel = vm.PaymentLabel;
		this.pageCount = vm.PageCount;
		this.scheduleCount = vm.ScheduleCount;
		this.monthlyTotal = vm.ScheduleImportTotalMonthlyValue;
		this.currentMigrationUrl = vm.CurrentMigrationUrl;
		this.exportButtons = vm.ExportButtons;
	}

	@action.bound
	filtersChanged(selectedStatus: string[], selectedListings: string[], selectedFunds: string[], shouldUpdateLocationState: boolean): void {
		if (this.statusSelectedValues !== selectedStatus ||
			this.listingSelectedValues !== selectedListings ||
			this.fundsSelectedValues !== selectedFunds ) {

			this.currentPage = 1;
			this.statusSelectedValues = selectedStatus;
			this.listingSelectedValues = selectedListings;
			this.fundsSelectedValues = selectedFunds;
		}

		if(shouldUpdateLocationState) {
			this.updateLocationState();
		}
	}

	@action.bound
	currentPageChanged(newPage: number): void {
		this.currentPage = newPage;
		this.updateLocationState();
	}

	@action.bound
	changeGridSortedBy(newSortedBy: ScheduleDetailsSortableColumn, sortDirection: SortDirection): void {
		this.sortedBy = newSortedBy;
		this.sortDirection = sortDirection;

		if (this.currentPage !== 1) {
			this.currentPage = 1;
			this.updateLocationState();
		}
	}

	@action.bound
	setGridData(data: SchedulesListFilteredResponse) {
		this.gridData = data.ScheduleDetails;
		this.isLoadingData = false;
		this.scheduleCount = data.TotalCount;
		this.pageCount = data.PageCount;
		this.monthlyTotal = data.TotalEstimatedMonthlyTotal;
	}

	@action.bound
	deleteSchedule(scheduleId: number) {
		const scheduleDetail = this.gridData.find((item) => item.ScheduleId === scheduleId);
		if (!scheduleDetail) {
			return;
		}
		this.scheduleCount -= 1;
		this.monthlyTotal -= scheduleDetail.MonthlyTotal;
		this.gridData = this.gridData.filter(x => x !== scheduleDetail);
	}

	@action.bound
	setIsLoadingData(isLoading: boolean) {
		this.isLoadingData = isLoading;
	}

	@action.bound
	setExpandedRowData(data: ScheduleDetailsProps | null) {
		this.expandedRowData = data;
	}

	private updateLocationState = () => {
		const newState = this.getCurrentlyAppliedFilters();
		this.locationHelper.pushState(newState);
	}

	@action
	private handleLocationStateChange = (newState: ScheduleDetailsFilterValues) => {
		if(newState) {
			this.statusSelectedValues = newState.SelectedStatus || [];
			this.listingSelectedValues = newState.SelectedListings || [];
			this.fundsSelectedValues = newState.SelectedFunds || [];
			this.currentPage = newState.CurrentPage;
		} else {
			this.statusSelectedValues = [];
			this.listingSelectedValues = [];
			this.fundsSelectedValues = [];
			this.currentPage = 1;
		}
	}

	private getCurrentlyAppliedFilters(): ScheduleDetailsFilterValues {
		// We need to call toJS so we get the applied filters as they are now.
		// Otherwise, the values will be a reference to the observable arrays which will always contain the current filters
		// when the values are checked, not as they were when getCurrentlyAppliedFilters was called
		return {
			SelectedStatus: toJS(this.statusSelectedValues),
			SelectedListings: toJS(this.listingSelectedValues),
			SelectedFunds: toJS(this.fundsSelectedValues),
			CurrentPage: this.currentPage,
		};
	}
}

export class ScheduleDetailsFilterValues {
	SelectedStatus: string[];
	SelectedListings: string[];
	SelectedFunds: string[];
	CurrentPage: number;
}


class StateConverter implements QueryParamConverter<ScheduleDetailsFilterValues> {
	public queryToState(queryString: string): ScheduleDetailsFilterValues {
		const ret = {
			SelectedStatus: [],
			SelectedListings: [],
			SelectedFunds: [],
			CurrentPage: 1,
		};

		if(queryString !== '') {
			queryString.split('&').reduce((acc, cur) => {
				const parts = cur.split('=');

				if (acc[parts[0]] instanceof Array) {
					acc[parts[0]].push(parts[1]);
				} else {
					acc[parts[0]] = Number(parts[1]);
				}

				return acc;
			}, ret);
		}

		return ret;
	}

	public stateToQuery(state: ScheduleDetailsFilterValues): string {
		const ret = [];
		if(state && state.SelectedStatus.length > 0) {
			ret.push(state.SelectedStatus.map(item => `SelectedStatus=${item}`).join('&'));
		}
		if(state && state.SelectedListings.length > 0) {
			ret.push(state.SelectedListings.map(item => `SelectedListings=${item}`).join('&'));
		}
		if(state && state.SelectedFunds.length > 0) {
			ret.push(state.SelectedFunds.map(item => `SelectedFunds=${item}`).join('&'));
		}

		if(state && state.CurrentPage > 0) {
			ret.push(`CurrentPage=${state.CurrentPage}`);
		}

		return  ret.join('&');
	}
}
