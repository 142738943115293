import * as React from 'react';
import HTML5Backend from 'react-dnd-html5-backend';
import * as ReactDnD from 'react-dnd';
import { observer } from 'mobx-react';

import * as ViewModels from '../view-models/index';
import { MembersGrid } from './members-grid';
import CustomDragLayer from './custom-drag-layer';
import { SvgWrapper } from '../../components/svg-wrapper';
import { FormControlCheckbox } from '../../components/form-controls/form-control-checkbox/form-control-checkbox';
import { ConvertCommunityMemberToOrgLink } from '../components/convert-community-member-to-org-link';
import { classNames } from '../../../Shared/utils/classnames';

@observer
export class Summary extends React.Component<{ vm: ViewModels.Main }, any> {
	render() {
		const accountTooltipContent =
			"A Pushpay account holds the giver's details and payment records. A person may " +
			'have multiple Pushpay accounts, and they can be registered or unregistered.';
		const { vm } = this.props;
		return (
			<div className="summary">
				{this.filterMessageText(vm.filterMessage, vm.enableFeatureOrganizationalGiving)}
				<span className="legend">
					{this.organizationLegend(vm)}
					<span
						className="legend-item"
						title="A community member consists of one or more Pushpay accounts that belong to the same person."
					>
						<SvgWrapper svg="member-fill" className="key" />
						Community Member
					</span>
					<span className="legend-item" title={accountTooltipContent}>
						<span className="circle verified key"></span>Pushpay accounts
					</span>
				</span>
			</div>
		);
	}

	private organizationLegend(vm: ViewModels.Main) {
		if (vm.enableFeatureOrganizationalGiving) {
			return (
				<span
					className="legend-item"
					title={
						'This represents an organization in your community. Organization community members are currently only available in ' +
						vm.settings.PaymentLabel.GiftEntryFeatureName +
						' and Check Deposit.'
					}
				>
					<SvgWrapper svg="member-organization" className="key" />
					Organization
				</span>
			);
		}
	}

	private filterMessageText(filterMessage: string, enableFeatureOrganizationalGiving: boolean) {
		if (!enableFeatureOrganizationalGiving) {
			return <h3>{filterMessage}</h3>;
		}
		if (enableFeatureOrganizationalGiving) {
			return <ConvertCommunityMemberToOrgLink className="conversion-link" />;
		}
	}
}

@observer
class FilterSection extends React.Component<{ vm: ViewModels.Main }, {}> {
	render() {
		const { vm } = this.props;
		return (
			<form
				onSubmit={(e) => {
					e.preventDefault();
					vm.newSearch();
					vm.updateMessage();
				}}
			>
				<div className={classNames('panel', 'panel-plain', 'filter-form')}>
					<div className="form-group">
						<h4>Filter by</h4>
					</div>
					<div>
						<label>Name, email or mobile number</label>
					</div>
					<div className="row">
						<div className="col-sm-8">
							<input
								type="text"
								className="form-control"
								data-pp-at-target="SearchInput"
								value={vm.searchTerms}
								onChange={(e) => (vm.searchTerms = (e.target as HTMLInputElement).value)}
							/>
						</div>
						<div className="col-sm-2">
							<FormControlCheckbox
								value={vm.excludeResultsWithEmail}
								onChangeHandler={vm.updateExcludeResultsWithEmail}
								text="Members with no email address"
							/>
						</div>
						<div className="col-sm-2">
							<FormControlCheckbox
								value={vm.excludeResultsWithAddress}
								onChangeHandler={vm.updateExcludeResultsWithAddress}
								text="Members with no physical address"
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-sm-3 responsive-btn-group">
							<button className="btn btn-primary" data-pp-at-target="FilterButton">
								Filter
							</button>
						</div>
					</div>
					{this.filterMessageText(vm.filterMessage, vm.enableFeatureOrganizationalGiving)}
				</div>
			</form>
		);
	}

	private filterMessageText(filterMessage: string, enableFeatureOrganizationalGiving: boolean) {
		if (enableFeatureOrganizationalGiving && filterMessage) {
			return (
				<div>
					<span>{filterMessage}</span>
				</div>
			);
		}
	}
}

@ReactDnD.DragDropContext(HTML5Backend)
@observer
export default class Consolidator extends React.Component<{ vm: ViewModels.Main }, any> {
	render(): any {
		const { vm } = this.props;
		return (
			<div>
				<FilterSection vm={vm} />
				<Summary key="summary" vm={vm} />
				<MembersGrid key="members" vm={vm}></MembersGrid>
				<CustomDragLayer />
				<h3>{vm.morePagesAvailable || vm.hasLongList ? vm.filterMessage : null}</h3>
				{vm.morePagesAvailable ? (
					<div className="load-more-container">
						<button
							className="btn btn-ghost"
							onClick={() => vm.loadMore()}
							disabled={vm.busyTracker.isBusy}
						>
							Load More
						</button>
					</div>
				) : null}
			</div>
		);
	}
}
