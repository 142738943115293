import * as React from 'react';
import { observer } from 'mobx-react';
import { TransitionGroup } from 'react-transition-group-v1';
import { BatchListMainViewModel } from '../../pages/batch-list/batch-list-main-view-model';
import { BatchListUserAction } from '../../pages/batch-list/batch-list-user-actions';
import { BatchListItem } from './batch-list-item/batch-list-item';
import { BatchStatus } from '../../virtual-terminal-generated';
import { BatchListHeader } from './batch-list-header/batch-list-header';
import { classNames } from '../../../../Shared/utils/classnames';

import * as styles from './batch-list.less';

@observer
export class BatchList extends React.Component<{ vm: BatchListMainViewModel }, {}> {
	render() {
		const { vm } = this.props;
		const {
			batches,
			showingBatchStatus,
			canLoadMore,
			requestInProgress,
		} = vm;
		const completed = showingBatchStatus === BatchStatus.Completed;
		const inProgress = showingBatchStatus === BatchStatus.Open;
		const deposited = showingBatchStatus === BatchStatus.Deposited;
		return (
			<div className="list-group-wrapper">
				<ul className={`nav nav-tabs ${styles.batchListTabs}`}>
					<li className={classNames(styles.tab, inProgress ? `active`: null)}>
						<a href=""
							className={styles.tabContent}
							tabIndex={inProgress ? -1 : null}
							onClick={this.onClickShowOpen}
							data-pp-at-target="show in progress">
							In progress
						</a>
					</li>
					<li className={classNames(styles.tab, completed ? `active` : null)}>
						<a href=""
							className={styles.tabContent}
							tabIndex={completed ? -1 : null}
							onClick={this.onClickShowCompleted}
							data-pp-at-target="show completed">
							Completed
						</a>
					</li>
					<li className={classNames(styles.tab, deposited ? `active` : null)}>
						<a href=""
							className={styles.tabContent}
							tabIndex={deposited ? -1 : null}
							onClick={this.onClickShowDeposited}
							data-pp-at-target="show deposited">
							Deposited
						</a>
					</li>
				</ul>
				<BatchListHeader vm={vm} />
				<TransitionGroup component="ul" className="list-group">
					{batches.map(batch => <BatchListItem key={batch.vm.BatchId} batch={batch} />)}
				</TransitionGroup>
				{canLoadMore && (
					<footer className="list-group-load-more">
						<button onClick={this.loadMore} disabled={requestInProgress} className="btn btn-ghost" data-pp-at-target="load more">Load More</button>
					</footer>
				)}
			</div>
		);
	}

	onClickShowOpen = (event: React.MouseEvent<HTMLAnchorElement>) => {
		event.preventDefault();

		if (this.props.vm.showingBatchStatus !== BatchStatus.Open) {
			this.switchStatus(BatchStatus.Open);
		}
	}

	onClickShowCompleted = (event: React.MouseEvent<HTMLAnchorElement>) => {
		event.preventDefault();

		if (this.props.vm.showingBatchStatus !== BatchStatus.Completed) {
			this.switchStatus(BatchStatus.Completed);
		}
	}

	onClickShowDeposited = (event: React.MouseEvent<HTMLAnchorElement>) => {
		event.preventDefault();

		if (this.props.vm.showingBatchStatus !== BatchStatus.Deposited) {
			this.switchStatus(BatchStatus.Deposited);
		}
	}

	switchStatus = (batchStatus: BatchStatus) => {
		const { raiseAction, changeTabAndResetFilters } = this.props.vm;

		changeTabAndResetFilters(batchStatus);
		raiseAction(new BatchListUserAction.QueryBatches());
	}

	loadMore = () => {
		const { raiseAction, nextPage } = this.props.vm;

		nextPage();
		raiseAction(new BatchListUserAction.QueryBatches({ appendResults: true }));
	}
}
