import { observable, action, computed, reaction } from 'mobx';
import { ScheduleImportStepViewModel, ImportStep} from '../schedule-import-step-view-model';
import {
	SetupAlertViewModel,
	OperationResponse,
	OperationResult,
	ScheduleImportEmailType,
	ScheduleImportMigrationStatus,
	ScheduleImportMigrationEmailContentType,
	ScheduleImportDefaultStartPoint,
} from '../../schedule-import-generated';
import { getScheduleImportDataService, ScheduleImportDataServiceAction, ScheduleImportApiConfigType } from '../../schedule-import-data-service';
import { IDataServiceActionSubscriber } from '../../../utils/data-service';
import { MigrationDateRangeViewModel } from '../../components/migration-date-range/migration-date-range-view-model';
import { MigrationContactDetailsViewModel } from '../../components/migration-contact-details/migration-contact-details-view-model';
import { MigrationEmailsViewModel } from '../../components/migration-emails/migration-emails-view-model';
import { InitialEmailTemplate } from './default-templates/initial-email-template';
import { WeeklyEmailTemplate } from './default-templates/weekly-email-template';
import { FinalEmailTemplate } from './default-templates/final-email-template';

export class ScheduleImportSetupAlertViewModel extends ScheduleImportStepViewModel {
	@observable dateRangeViewModel: MigrationDateRangeViewModel;
	@observable contactDetailsViewModel: MigrationContactDetailsViewModel;
	@observable emailsViewModel: MigrationEmailsViewModel;

	@observable emailContentType: ScheduleImportMigrationEmailContentType;
	@observable defaultStartPoint: ScheduleImportDefaultStartPoint;

	originalStartDate:Date;
	originalEndDate: Date;

	reviewCampaignHelpUrl: string;

	updateMigrationSubscriber: IDataServiceActionSubscriber<ScheduleImportApiConfigType, 'updateMigration'>;

	constructor(public viewData: SetupAlertViewModel) {
		// tslint:disable:max-line-length
		super(
			viewData.PaymentLabel,
			ImportStep.Alert,
			'Set up alerts',
			`You\'re almost ready to start your migration. Tailor your campaign communications to your ${viewData.PaymentLabel.PayerPluralLowerCase}. Once you\'ve completed this step and activated your migration we\'ll start the process of emailing your ${viewData.PaymentLabel.PayerPluralLowerCase} and inviting them to move to Pushpay.`
		);
		// tslint:enable:max-line-length

		this.dateRangeViewModel = new MigrationDateRangeViewModel(
			viewData.Migration.StartDate,
			viewData.Migration.EndDate,
			viewData.MinMigrationPeriodInDays,
			viewData.MaxMigrationPeriodInDays,
			ScheduleImportMigrationStatus.Draft,
		);

		this.originalStartDate = viewData.Migration.StartDate;
		this.originalEndDate = viewData.Migration.EndDate;

		this.contactDetailsViewModel = new MigrationContactDetailsViewModel(
			viewData.Migration.ContactName,
			viewData.Migration.ContactEmail,
			viewData.Migration.ContactPhone
		);

		this.emailsViewModel = new MigrationEmailsViewModel(
			viewData.Migration.InitialEmail,
			viewData.Migration.WeeklyEmail,
			viewData.Migration.FinalEmail,
		);

		this.emailContentType = viewData.Migration.EmailContentType;
		this.populateDefaultEmailTemplateIfNeeded();

		this.defaultStartPoint = viewData.Migration.DefaultStartPoint;

		this.reviewCampaignHelpUrl = viewData.ReviewCampaignHelpUrl;

		this.updateMigrationSubscriber = getScheduleImportDataService().getActionSubscriberFactory('updateMigration')((action) => this.subscribeToConfirmMigration(action));

		reaction(() => this.contactDetailsViewModel.isModified || this.dateRangeViewModel.isModidfied, (isModified: boolean) => this.showUpdateEmailAlert(isModified));
	}

	@action.bound
	updateEmailContent(contentType: ScheduleImportMigrationEmailContentType) {
		this.emailContentType = contentType;

		this.emailsViewModel.initialEmail.Message = this.getEmailDefaultText(ScheduleImportEmailType.InitialInvite);
		this.emailsViewModel.initialEmail.VideoMessage = this.initialEmailVideoMessage;

		this.emailsViewModel.weeklyEmail.Message = this.getEmailDefaultText(ScheduleImportEmailType.WeeklyReminder);
		this.emailsViewModel.finalEmail.Message = this.getEmailDefaultText(ScheduleImportEmailType.FinalReminder);
	}

	@action.bound
	updateDefaultStartPoint(defaultStartPoint: ScheduleImportDefaultStartPoint) {
		this.defaultStartPoint = defaultStartPoint;
	}

	@action.bound
	confirmMigration() {
		this.initUpdateMigrationRequest(true);
	}

	@action.bound
	saveMigration() {
		this.initUpdateMigrationRequest(false);
	}

	private showUpdateEmailAlert(isModified: boolean) {
		if (isModified && this.isDefaultEmailTemplatesModified) {
			this.showInfoAlert('It looks like you\'ve changed some of your migration details. Please review your emails to make sure these still include the correct details.');
		} else {
			this.clearAlert();
		}
	}

	@action.bound
	private initUpdateMigrationRequest(isConfirmed: boolean) {
		this.actionInProgress = true;
		this.updateMigrationSubscriber.initRequest({
			model: {
				MigrationId: this.viewData.Migration.MigrationId,
				StartDate: this.dateRangeViewModel.startDate,
				OriginalStartDate: this.originalStartDate,
				EndDate: this.dateRangeViewModel.endDate,
				OriginalEndDate: this.originalEndDate,
				ContactName: this.contactDetailsViewModel.contactName,
				ContactEmail: this.contactDetailsViewModel.contactEmail,
				ContactPhone: this.contactDetailsViewModel.contactPhone,
				InitialEmail: this.emailsViewModel.initialEmail,
				WeeklyEmail: this.emailsViewModel.weeklyEmail,
				FinalEmail: this.emailsViewModel.finalEmail,
				IsConfirmed: isConfirmed,
				NeedToAdjustActiveSchedules: false,
				EmailContentType: this.emailContentType,
				DefaultStartPoint: this.defaultStartPoint,
			}
		});
	}

	@computed
	get isDefaultEmailTemplatesModified() : boolean {
		return this.emailsViewModel.initialEmail.Message !== this.getEmailDefaultText(ScheduleImportEmailType.InitialInvite)
			|| this.emailsViewModel.initialEmail.VideoMessage !== this.initialEmailVideoMessage
			|| this.emailsViewModel.weeklyEmail.Message !== this.getEmailDefaultText(ScheduleImportEmailType.WeeklyReminder)
			|| this.emailsViewModel.finalEmail.Message !== this.getEmailDefaultText(ScheduleImportEmailType.FinalReminder);
	}

	private subscribeToConfirmMigration(action: ScheduleImportDataServiceAction) {
		switch (action.type) {
			case 'request_success':
				const response: OperationResponse = action.response;
				if (response.Result === OperationResult.Success) {
					window.location.href = response.SuccessUrl;
					return;
				} else {
					this.showErrorAlert(response.ErrorMessage);
				}
				break;
			case 'request_error':
				const validationErrors = action.error.validationErrors;
				if (validationErrors) {
					this.showValidationErrorsAlert(validationErrors);
				}
				break;
		}
		this.actionInProgress = false;
	}

	private populateDefaultEmailTemplateIfNeeded() {
		if (!this.emailsViewModel.initialEmail.Message) {
			this.emailsViewModel.initialEmail.Message = this.getEmailDefaultText(ScheduleImportEmailType.InitialInvite);
			this.emailsViewModel.initialEmail.VideoMessage = InitialEmailTemplate.getDefaultVideoTextTemplate(this.viewData.EmailBranding.OrganizationName, this.viewData.PaymentLabel);
		}
		if (!this.emailsViewModel.weeklyEmail.Message) {
			this.emailsViewModel.weeklyEmail.Message = this.getEmailDefaultText(ScheduleImportEmailType.WeeklyReminder);
		}
		if (!this.emailsViewModel.finalEmail.Message) {
			this.emailsViewModel.finalEmail.Message = this.getEmailDefaultText(ScheduleImportEmailType.FinalReminder);
		}
	}

	private get initialEmailVideoMessage(): string {
		return InitialEmailTemplate.getDefaultVideoTextTemplate(this.viewData.EmailBranding.OrganizationName, this.viewData.PaymentLabel);
	}

	private getEmailDefaultText(type: ScheduleImportEmailType): string {
		const orgName = this.viewData.EmailBranding.OrganizationName;
		const endDate = this.dateRangeViewModel.endDate || new Date();
		const paymentLabel = this.viewData.PaymentLabel;
		const contentType = this.emailContentType;
		const contactName = this.contactDetailsViewModel.contactName || '[Contact Name]';
		const contactPhone = this.contactDetailsViewModel.contactPhone || '[Contact Phone]';

		switch(type) {
			case ScheduleImportEmailType.InitialInvite:
				return InitialEmailTemplate.getDefaultTextTemplate(orgName, endDate, paymentLabel, contentType, contactName, contactPhone);
			case ScheduleImportEmailType.WeeklyReminder:
				return WeeklyEmailTemplate.getDefaultTextTemplate(orgName, endDate, paymentLabel, contentType, contactName, contactPhone);
			case ScheduleImportEmailType.FinalReminder:
				return FinalEmailTemplate.getDefaultTextTemplate(orgName, endDate, paymentLabel, contentType, contactName, contactPhone);
		}
	}
}
