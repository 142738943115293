import * as React from 'react';
import { IFormControlCommonProps } from '../form-control-common-props';
import { FormControlInput } from '../form-control-input';
import { SvgWrapper } from '../../../../Shared/components/svg-wrapper';
import * as styles from './form-control-clipboard.less';
import { copyToClipboard } from '../../../utils/copy-to-clipboard-utils';
import { Tooltip, TooltipContent } from '../../tooltip/tooltip';
import { IIconInputGroupProps, IconInputGroupPosition } from '../../icon-input-group';
import { classNames } from '../../../../Shared/utils/classnames';

type ClipboardCommonProps = Omit<
	IFormControlCommonProps,
	'disabled' | 'validateOnBlur' | 'validateOnChange' | 'revalidateOnBlur' | 'revalidateOnChange' | 'type'
>;

export type IFormControlClipboardProps = ClipboardCommonProps & {
	value: string;
	cssClassNames?: string;
	ariaLabel?: string;
};

export class FormControlClipboard extends React.Component<IFormControlClipboardProps> {
	state = {
		copied: false,
	};
	private tooltipTimeout: number;
	render() {
		const { name, placeholder, value, cssClassNames, ...rest } = this.props;
		const clipboard: IIconInputGroupProps = {
			position: IconInputGroupPosition.Right,
			render: (
				<Tooltip
					tooltipClassName={classNames('input-group-addon')}
					placement="top"
					label="Copy to clipboard"
					visible={this.state.copied}
					onClick={() => this.copyValueAndSetTooltipVisibility(value)}
				>
					<SvgWrapper svg="copy" title="Copy to clipboard" width="18" height="18" />
					<TooltipContent>
						<div>Copied to clipboard!</div>
					</TooltipContent>
				</Tooltip>
			),
		};

		return (
			<FormControlInput
				{...rest}
				cssClassNames={classNames(styles.input, cssClassNames)}
				disabled={!value}
				inputGroupOptions={clipboard}
				name={name}
				placeholder={placeholder}
				readonly={!!value}
				type="text"
				value={value}
			/>
		);
	}

	private copyValueAndSetTooltipVisibility = (value: string) => {
		if (!value) return;
		copyToClipboard(value);
		this.setState({ copied: true });
		if (this.tooltipTimeout) {
			window.clearTimeout(this.tooltipTimeout);
		}
		this.tooltipTimeout = window.setTimeout(() => this.setState({ copied: false }), 1500);
	};
}
