import { MachineConfig } from 'xstate/lib/types';

export enum FormStates {
	IDLE = 'Initial Form State',
	SUBMITTING_FORM_STATE = 'Submitting Form State',
	INVALID_FORM_STATE = 'Invalid Form State',
	VALID_FORM_STATE = 'Valid Form State',
}

export enum FormEvents {
	START_SUBMISSION = 'Submit form',
	SUBMISSION_FAILURE = 'Submit Form Failed',
	SUBMISSION_SUCCESS = 'Submit Form Succeeded'
}

export const GenerateFormStateMachine = (submitFormActionKey: string, submissionSuccessfulKey: string, submissionFailedKey: string): MachineConfig => ({
	key: 'Form state',
	initial: FormStates.IDLE,
	states: {
		[FormStates.IDLE]: {
			on: {
				[FormEvents.START_SUBMISSION]: {
					[FormStates.SUBMITTING_FORM_STATE]: {
						actions: [submitFormActionKey]
					}
				}
			}
		},
		[FormStates.SUBMITTING_FORM_STATE]: {
			on: {
				[FormEvents.SUBMISSION_FAILURE]: {
					[FormStates.INVALID_FORM_STATE]: {
						actions: [submissionFailedKey]
					}
				},
				[FormEvents.SUBMISSION_SUCCESS]: {
					[FormStates.VALID_FORM_STATE]: {
						actions: [submissionSuccessfulKey]
					}
				}
			}
		},
		[FormStates.INVALID_FORM_STATE]: {
			on: {
				[FormEvents.START_SUBMISSION]: {
					[FormStates.SUBMITTING_FORM_STATE]: {
						actions: [submitFormActionKey]
					}
				}
			}
		},
		[FormStates.VALID_FORM_STATE]: {},
	}
});
