import { action } from 'mobx';
import { FieldState } from 'formstate/lib/core/fieldState';
import { Validator } from 'formstate/lib/core/types';
import { ModelMetadata } from '../../../LoggedInWeb/loggedinweb-generated';
import {
	stringLength,
	minLength,
	maxLength,
	required,
	email,
	date,
	number,
	range,
	regex,
	noCreditCardDetails,
} from '../validation/validators';

const noCreditCardDetailsValidationMessage = 'For your security, please only enter your credit card details into credit card fields.';

export class BaseFieldState<T> extends FieldState<T> {
	readonly fieldName: string;

	constructor(initialValue: T, metadata?: ModelMetadata.IPropertyMetadata, skipSanitization = true) {
		super(initialValue);

		if (metadata) {
			this.fieldName = metadata.propertyName;

			const validationRules = metadata.validationRules;
			if (validationRules) {
				const validators = Object.keys(validationRules).map(key => {
					const rule = validationRules[key];
					switch(key) {
						case 'length':
							return stringLength(rule.parameters.min, rule.parameters.max, rule.errorMessage);
						case 'maxlength':
							return maxLength(rule.parameters.max, rule.errorMessage);
						case 'minlength':
							return minLength(rule.parameters.min, rule.errorMessage);
						case 'required':
							return required(rule.errorMessage);
						case 'email':
							return email(rule.errorMessage);
						case 'date':
							return date(rule.errorMessage);
						case 'number':
							return number(rule.errorMessage);
						case 'range':
							return range(rule.parameters.min, rule.parameters.max, rule.errorMessage);
						case 'regex':
							return regex(new RegExp(rule.parameters.pattern), rule.errorMessage);
						case 'skipsanitization':
							skipSanitization = true;
							return null;
						default:
							throw new Error(`Unimplemented validator ${key}`);
					}
				});

				this.validators(...validators.filter((validator) => validator !== null));
			}
		}

		if (!skipSanitization) {
			this.validators(noCreditCardDetails(noCreditCardDetailsValidationMessage));
		}
	}

	@action
	validators = (...validators: Validator<T>[]) => {
		this._validators.push(...validators);
		return this;
	}
}
