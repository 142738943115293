import { observable, action } from 'mobx';
import { IPayerViewModel, IPayerSearchResult } from '../../components/payer-search-omnibox/payer-search-omnibox';
import { Debouncer } from '../../utils/debouncer';

export class MemberSearchOmniboxViewModel {
	@observable
	omniboxValue = '';

	@observable
	searchValue = '';

	@observable
	searchResult: IPayerSearchResult;

	@observable
	loadMoreInProgress = false;

	@observable
	openRequested = false;

	@observable
	selectedPayer: IPayerViewModel;

	private memberSearchDebouncer: Debouncer<string>;

	private pendingSearchValue: string;

	searchMembersCallback: (searchValue: string, skip: number) => void = () => { };

	cancelSearchCallback: () => void = () => { };

	selectedPayerChangeCallback: (payer: IPayerViewModel) => void = () => { };

	selectedPayerClearedCallback: () => void = () => { };

	constructor() {
		this.memberSearchDebouncer = new Debouncer<string>(500, this.searchMembers);
	}


	@action
	handleInputValueChange(value: string) {
		if (this.selectedPayer) {
			return;
		}

		this.omniboxValue = value;
		value = value && value.trim();
		if (!value || value.length < 3) {
			this.memberSearchDebouncer.cancel();
			this.cancelSearchCallback();
			this.searchResult = null;
			this.searchValue = '';
		} else {
			this.memberSearchDebouncer.exec(value);
		}
	}

	@action.bound
	updateSearchResult(result: IPayerSearchResult) {
		this.searchResult = result;
		this.searchValue = this.pendingSearchValue;
		this.loadMoreInProgress = false;
	}

	@action.bound
	handleMenuStateChange(open: boolean) {
		this.openRequested = open;
	}

	@action.bound
	handleSelectedPayerChange(payer: IPayerViewModel) {
		this.selectedPayer = payer;
		this.selectedPayerChangeCallback(payer);
	}

	@action.bound
	handleClearSelectedPayer() {
		this.selectedPayer = null;
		this.searchResult = null;
		this.searchValue = '';
		this.omniboxValue = '';
		this.selectedPayerClearedCallback();
	}

	@action.bound
	handleLoadMore() {
		if (this.loadMoreInProgress) {
			return;
		}
		this.loadMoreInProgress = true;
		this.searchMembers(this.searchValue, this.searchResult.payers.length);
	}

	private searchMembers = (value: string, skip: number = 0) => {
		this.pendingSearchValue = value;
		this.searchMembersCallback(this.pendingSearchValue, skip);
	}
}
