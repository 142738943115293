import * as React from 'react';
import { observer } from 'mobx-react';
import { SvgWrapper } from './svg-wrapper';
import { KeyCodes } from '../../Shared/helpers/keycodes';
const savedTimeoutDuration = 3000;

enum CurrentState {
	Default = 0,
	Editing,
	Saved
}

export interface EditableLabelProps {
	value: string;
	onChange: (value: string) => JQueryPromise<any>;
}

export interface EditableLabelState {
	currentState: CurrentState;
	currentValue: string;
	hasSavedTimer: number;
	isSaving: boolean;
}

@observer
export class EditableLabel extends React.Component<EditableLabelProps, EditableLabelState> {

	inputElem: HTMLInputElement = null;

	constructor(props) {
		super(props);
		this.state = {
			currentState: CurrentState.Default,
			currentValue: this.props.value,
			hasSavedTimer: null,
			isSaving: false
		};
	}

	render() {
		const {currentState, currentValue, isSaving} = this.state;
		return (
			<div className="editable-label">
				{
					currentState === CurrentState.Default || currentState === CurrentState.Saved
						? <span className="value-display"><span title={currentValue}>{currentValue}</span></span>
						: <input ref={e => this.inputElem = e} type="text" value={currentValue} disabled={isSaving}
							onChange={this.updateCurrentValue} className="value-edit" onKeyDown={this.handleKeyDown} />
				}
				{
					this.renderButton()
				}
			</div>
		);
	}

	UNSAFE_componentWillReceiveProps(nextProps: EditableLabelProps) {
		this.setState(prevState => {
			return { ...prevState, currentValue: nextProps.value };
		});
	}

	componentDidUpdate() {
		if(this.state.currentState === CurrentState.Editing) {
			this.inputElem.focus();
		}
	}

	renderButton() {
		const svgWidth = 15;
		const svgHeight = 15;
		switch(this.state.currentState) {
			case CurrentState.Default:
				return <button onClick={this.startEditing}><SvgWrapper svg="icon-edit" className="svg" width={svgWidth} height={svgHeight} /> Edit</button>;
			case CurrentState.Editing:
				return <button onClick={this.finishEditing} disabled={this.state.isSaving}><SvgWrapper svg="icon-edit" className="svg" width={svgWidth} height={svgHeight}  /> Save</button>;
			case CurrentState.Saved:
				return <button className="saved" onClick={this.startEditing}><SvgWrapper svg="tick" className="svg" width={svgWidth} height={svgHeight} /> Saved</button>;
		}
	}

	private startEditing = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		clearTimeout(this.state.hasSavedTimer);
		this.setState(prevState => {
			return {...prevState, currentState: CurrentState.Editing, hasSavedTimer: null, isSaving: false};
		});
	}

	private finishEditing = (e: React.MouseEvent<HTMLButtonElement> = null) => {
		if(e) {
			e.preventDefault();
		}
		this.setState(prevState => {
			return {...prevState, isSaving: true};
		});
		this.props.onChange(this.state.currentValue).then(() => {
			const timer = setTimeout(() => this.clearSavedState(), savedTimeoutDuration);
			this.setState(prevState => {
				return {...prevState, hasSavedTimer: timer, currentState: CurrentState.Saved, isSaving: false};
			});
		}).fail(() => {
			this.setState(prevState => {
				return {...prevState, isSaving: false};
			});
		});
	}

	private clearSavedState = () => {
		this.setState(prevState => {
			return {...prevState, currentState: CurrentState.Default, isSaving: false};
		});
	}

	private updateCurrentValue = (e: React.FormEvent<HTMLInputElement>) => {
		e.preventDefault();
		const val = (e.target as HTMLInputElement).value;
		this.setState(prevState => {
			return {...prevState, currentValue: val};
		});
	}

	private handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		switch (e.keyCode) {
			case KeyCodes.Enter:
				e.preventDefault();
				this.finishEditing();
				break;
			case KeyCodes.Escape:
				e.preventDefault();
				this.setState(prevState => {
					return {
						currentState: CurrentState.Default,
						isSaving: false,
						currentValue: this.props.value,
						hasSavedTimer: null,
					};
				});
				break;

		}
	}
}
