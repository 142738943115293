import { action, observable } from 'mobx';
import {
	MappingsResult,
	MappingsSetupProgress,
	QuickBooksFundMappingViewModel,
	QuickBooksSaveMappingsViewModel,
	QuickBooksMappingViewModel,
	QuickBooksMerchantMappingViewModel,
	QuickBooksPaymentGatewayMappingViewModel,
} from '../../qbo-integration-generated';
import {
	BatchEntryMapping,
	CollapsedAccountMapping,
	ProfitStarsMapping,
	QboBankAccountMappingInfoViewModel
} from './qbo-bank-account-mapping-info-view-model';
import { QboLocationMappingInfoViewModel } from './qbo-location-mapping-info-view-model';
import { QboFundsMappingInfoViewModel } from './qbo-funds-mapping-info-view-model';
import { StepStatusStep } from '../../../components/step-status';

export type StringToStringMap = {[key: string]: string};

export type NumberToMappingMap = {[key: number]: QuickBooksMerchantMappingViewModel};

export class QboMappingClientSideViewModel {

	readonly step1Title: string = 'Match bank accounts';
	readonly step2Title: string = 'Match listings and locations';
	readonly step3Title: string = 'Match funds';

	@observable
	mappingsResult: MappingsResult;

	@observable
	bankAccountMappingInfoViewModel: QboBankAccountMappingInfoViewModel;

	@observable
	listingLocationInfoViewModel: QboLocationMappingInfoViewModel;

	@observable
	fundsMappingInfoViewModel: QboFundsMappingInfoViewModel;

	readonly configurationSteps: StepStatusStep<MappingsSetupProgress>[];

	@observable
	activeStep: MappingsSetupProgress;

	@observable
	isProcessingRequest: boolean;

	constructor(mappingInfo: QuickBooksMappingViewModel) {
		this.mappingsResult = mappingInfo.Result;
		this.configurationSteps = [
			{ key: MappingsSetupProgress.Deposits, label: this.step1Title },
			{ key: MappingsSetupProgress.MerchantLocations, label: this.step2Title },
			{ key: MappingsSetupProgress.Funds, label: this.step3Title }
		];
		this.activeStep = mappingInfo.SetupProgress;
		this.isProcessingRequest = false;

		if(mappingInfo.Result === MappingsResult.Success) {
			const listingLocationMappingEnabled = mappingInfo.LocationsForFundsEnabled && mappingInfo.MerchantLocationsEnabled;
			this.bankAccountMappingInfoViewModel = new QboBankAccountMappingInfoViewModel(mappingInfo, `1. ${this.step1Title}`, this.setActiveStep);
			this.listingLocationInfoViewModel = new QboLocationMappingInfoViewModel(mappingInfo,
																					`2. ${this.step2Title}`,
																					listingLocationMappingEnabled,
																					this.setListingLocationMappingEnabled,
																					this.setActiveStep);
			this.fundsMappingInfoViewModel = new QboFundsMappingInfoViewModel(mappingInfo, `3. ${this.step3Title}`, listingLocationMappingEnabled, this.setActiveStep);
		}
	}

	getSaveMappingModel = (): QuickBooksSaveMappingsViewModel  => {
		const { batchEntryMappings, profitStarsMappings, creditCardMappings, achMappings, allProfitStarsMappingsUseSameAccount } = this.bankAccountMappingInfoViewModel;

		// get the payment gateway mappings from the 3 mapping collections
		let paymentGatewayMappings: QuickBooksPaymentGatewayMappingViewModel[] = [
			...this.getCollapsedGatewayMappings(achMappings),
			...this.getCollapsedGatewayMappings(creditCardMappings),
			...this.getProfitStarsMappings(profitStarsMappings),
			...this.getBatchEntryMappings(batchEntryMappings),
		];

		// get the listing location mappings
		const { listingMappings, enableMappings } = this.listingLocationInfoViewModel;
		const merchantMappings: QuickBooksMerchantMappingViewModel[] = listingMappings.map(mapping => ({
			MerchantId: mapping.merchantId,
			ClassId: null, // not used at this time - may be set in the future
			LocationId: mapping.locationId,
		}));

		// get the funds mappings
		const fundsMappings: QuickBooksFundMappingViewModel[] = this.fundsMappingInfoViewModel.fundMappings.map(mapping => {
			return {
				ClassId: mapping.classId,
				LocationId: mapping.locationId,
				AccountId: mapping.accountId,
				FundId: mapping.fundId,
			};
		});
		return {
			AllMerchantsUseTheSameBankAccountForProfitStars: allProfitStarsMappingsUseSameAccount,
			PaymentGateways: paymentGatewayMappings,
			MerchantLocationsEnabled: enableMappings,
			Merchants: merchantMappings,
			Funds: fundsMappings,
			SetupProgress: this.activeStep,
		} as QuickBooksSaveMappingsViewModel;
	}

	@action.bound
	setListingLocationMappingEnabled(value: boolean) {
		this.fundsMappingInfoViewModel.setIsListingsMappedToLocations(value);
	}

	@action.bound
	setActiveStep(step: MappingsSetupProgress) {
		this.activeStep = step;
		this.bankAccountMappingInfoViewModel.setLocalActiveStep(step);
		this.listingLocationInfoViewModel.setLocalActiveStep(step);
		this.fundsMappingInfoViewModel.setLocalActiveStep(step);
	}

	@action.bound
	setIsProcessingRequest(value: boolean) {
		this.isProcessingRequest = value;
		this.bankAccountMappingInfoViewModel.setIsProcessingRequest(value);
		this.listingLocationInfoViewModel.setIsProcessingRequest(value);
		this.fundsMappingInfoViewModel.setIsProcessingRequest(value);
	}

	private getCollapsedGatewayMappings(collapsedGatewayMappings: CollapsedAccountMapping[]): QuickBooksPaymentGatewayMappingViewModel[] {
		return collapsedGatewayMappings.reduce((gatewayMappings, currentMapping) => {
			const mappings = currentMapping.gatewayIds.map((gatewayId) => {
				return {
					PaymentGatewayId: gatewayId,
					AccountId: currentMapping.selectedAccount,
					IsEnabled: currentMapping.isEnabled,
				};
			});
			return [
				...gatewayMappings,
				...mappings,
			];
		}, []);
	}

	private getProfitStarsMappings(profitStarsMappings: ProfitStarsMapping[]): QuickBooksPaymentGatewayMappingViewModel[] {
		return profitStarsMappings.map((mapping) => {
			return {
				PaymentGatewayId: mapping.gatewayId,
					AccountId: mapping.selectedAccount,
					IsEnabled: mapping.isEnabled,
				};
			});
	}

	private getBatchEntryMappings(batchEntryMappings: BatchEntryMapping[]): QuickBooksPaymentGatewayMappingViewModel[] {
		return batchEntryMappings.map((mapping) => {
			return {
				PaymentGatewayId: mapping.gatewayId,
					AccountId: mapping.selectedAccount,
					IsEnabled: mapping.isEnabled,
				};
			});
	}
}
