import * as React from 'react';
import { Button } from '../../../../Shared/components/button/button';
import { Fragment } from '../../../../Shared/components/fragment';

export interface IDeleteStatementsConfirmModalProps {
	onDismiss: () => void;
	onConfirm: () => void;
}

export const DeleteStatementsConfirmModal: React.FunctionComponent<IDeleteStatementsConfirmModalProps> = (props) => {
	const {
		onDismiss,
		onConfirm
	} = props;

	return (
		<Fragment>
			<div className="modal-content" data-pp-at-target="Modal">
				<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onDismiss} />
				<div className="modal-body left-align">
					<div className="modal-text">
                        Are you sure you want to delete the statements?
					</div>
				</div>
				<div className="modal-footer">
					<Button type="button" className="btn btn-cancel" onClick={onDismiss}>Cancel</Button>
					<Button type="button" className="btn btn-primary" onClick={onConfirm}>Confirm</Button>
				</div>
			</div>
		</Fragment>
	);
};
