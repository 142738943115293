import * as React from 'react';
import { ResponseTypes } from '../../funds/funds-generated';
import { observable, computed } from 'mobx';
import { makeAjaxCall } from '../../funds/utils/ajax-helper';
import { ErrorDialog } from './error-dialog';
import { ModalDialogCommander } from '../../components/modal-dialog-commander';
import { DisconnectIntegrationModalDialog } from './disconnect-integration-dialog';
import { RemoveIntegrationModalDialog } from './delete-integration-dialog';
import { EnableIntegrationAjaxModalDialog } from './enable-integration-ajax-dialog';

export interface IActionUrls {
	enableUrl ?: string;
	disableUrl ?: string;
	deleteUrl ?: string;
	configureUrl ?: string;
	setupUrl ?: string;
	cancelUrl ?: string;
	saveFundsFileUrl ?: string;
	configureIntegrationMappingsUrl?: string;
	importFundsUrl ?: string;
	enablePcoGivingUrl ?: string;
	disablePcoGivingUrl ?: string;
}

export class IntegrationServiceClient {
	@observable
	isSettingUp: boolean;

	@observable
	isEnabling: boolean;

	@observable
	isDisabling: boolean;

	@observable
	isDeleting: boolean;

	@computed
	get isProcessingRequest() {
		return this.isSettingUp ||
			this.isEnabling ||
			this.isDisabling ||
			this.isDeleting;
	}

	integrationName: string;

	integrationIsThirdParty: boolean;

	integrationHasFunds: boolean;

	actionUrls: ResponseTypes.ActionUrls;

	constructor(integrationName: string, integrationIsThirdParty: boolean, integrationHasFunds: boolean, actionUrls?: IActionUrls) {
		this.integrationName = integrationName;
		this.integrationIsThirdParty = integrationIsThirdParty;
		this.integrationHasFunds = integrationHasFunds;
		if (actionUrls) {
			this.actionUrls = {
				EnableIntegrationUrl: actionUrls.enableUrl,
				DisableIntegrationUrl: actionUrls.disableUrl,
				DeleteIntegrationUrl: actionUrls.deleteUrl,
				ConfigureIntegrationUrl: actionUrls.configureUrl,
				SetupAuthenticationUrl: actionUrls.setupUrl,
				CancelActionUrl: actionUrls.cancelUrl,
				SaveFundsFileUrl: actionUrls.saveFundsFileUrl,
				ConfigureIntegrationMappingsUrl: actionUrls.configureIntegrationMappingsUrl,
				ImportFundsUrl: actionUrls.importFundsUrl,
				EnablePcoGivingUrl: actionUrls.enablePcoGivingUrl,
				DisablePcoGivingUrl: actionUrls.disablePcoGivingUrl
			};
		}
	}

	saveFundsFile = (data) => {
		this.makeRequest(this.actionUrls.SaveFundsFileUrl, data, (response) => {
			throw new Error(`Invalid state: save funds file should return RedirectRequest instead but returned ${response}`);
		}, (isProcessing) => this.isSettingUp = isProcessing);
	}

	setupAuthentication = (data) => {
		this.makeRequest(this.actionUrls.SetupAuthenticationUrl, data, (response) => {
			throw new Error(`Invalid state: setup authentication should return RedirectRequest instead but returned ${response}`);
		}, (isProcessing) => this.isSettingUp = isProcessing);
	}

	connectPcoGiving = () => {
		this.makeRequest(this.actionUrls.EnablePcoGivingUrl, null, (response) => {
			throw new Error(`Invalid state: enable pco giving should return RedirectRequest instead but returned ${response}`);
		}, (isProcessing) => this.isSettingUp = isProcessing);
	}

	enablePcoGiving = () => {
		ModalDialogCommander.showReactDialog(React.createElement(EnableIntegrationAjaxModalDialog, { store: this, proceedEnablingIntegration: this.proceedEnablePcoGiving }));
	}

	proceedEnablePcoGiving = () => {
		this.makeRequest(this.actionUrls.EnablePcoGivingUrl, null, (response) => {
			throw new Error(`Invalid state: enable pco giving should return RedirectRequest instead but returned ${response}`);
		}, (isProcessing) => this.isSettingUp = isProcessing);
	}

	disablePcoGiving = () => {
		ModalDialogCommander.showReactDialog(React.createElement(DisconnectIntegrationModalDialog, { store: this, proceedDisablingIntegration: this.proceedDisablePcoGiving }));
	}

	proceedDisablePcoGiving = () => {
		this.makeRequest(this.actionUrls.DisablePcoGivingUrl, null, (response) => {
			throw new Error(`Invalid state: disable pco giving should return RedirectRequest instead but returned ${response}`);
		}, (isProcessing) => this.isSettingUp = isProcessing);
	}

	setupConfiguration = (data) => {
		this.makeRequest(this.actionUrls.ConfigureIntegrationUrl, data, (response) => {
			throw new Error(`Invalid state: setup configuration should return RedirectRequest instead but returned ${response}`);
		}, (isProcessing) => this.isSettingUp = isProcessing);
	}

	enableIntegration = () => {
		ModalDialogCommander.showReactDialog(React.createElement(EnableIntegrationAjaxModalDialog, { store: this, proceedEnablingIntegration: this.proceedEnablingIntegration }));
	}

	proceedEnablingIntegration = () => {
		this.makeRequest(this.actionUrls.EnableIntegrationUrl, null, (response) => {
			throw new Error(`Invalid state: enable integration should return RedirectRequest instead but returned ${response}`);
		}, (isProcessing) => this.isEnabling = isProcessing);
	}

	disableIntegration = () => {
		ModalDialogCommander.showReactDialog(React.createElement(DisconnectIntegrationModalDialog, { store: this, proceedDisablingIntegration: this.proceedDisablingIntegration }));
	}

	proceedDisablingIntegration = () => {
		this.makeRequest(this.actionUrls.DisableIntegrationUrl, null, (response) => {
			throw new Error(`Invalid state: disable integration should return RedirectRequest instead but returned ${response}`);
		}, (isProcessing) => this.isDisabling = isProcessing);
	}

	deleteIntegration = () => {
		ModalDialogCommander.showReactDialog(React.createElement(RemoveIntegrationModalDialog, { store: this }));
	}

	proceedDeletingIntegration = () => {
		this.makeRequest(this.actionUrls.DeleteIntegrationUrl, null, (response) => {
			throw new Error(`Invalid state: delete integration should return RedirectRequest instead but returned ${response}`);
		}, (isProcessing) => this.isDeleting = isProcessing);
	}

	private makeRequest(url, data, action, processingSetter: (value: boolean) => void) {
		processingSetter(true);
		makeAjaxCall(url, data)
			.fail(error => {
				return ModalDialogCommander.showReactDialog(ErrorDialog(error));
			})
			.then(response => {
				//Only FlashMessages of type FlashMessageType.Error are supported
				if (isFlashMessage(response) && response.MessageType === ResponseTypes.FlashMessageType.Error) {
					const t = $.Deferred();

					ModalDialogCommander.showReactDialog(ErrorDialog({ ErrorMessage: response.Message }, response.Title))
						.then(() => {
							t.reject();
						});

					return t.promise();
				}

				return response;
			})
			.then(action)
			.always(() => {
				processingSetter(false);
			});
	}
}


function isFlashMessage(response: any): response is ResponseTypes.FlashMessage {
	if (response && response instanceof Object) {
		const flashMessage = response as Object;
		return flashMessage.hasOwnProperty('Message') &&
			flashMessage.hasOwnProperty('Title') &&
			flashMessage.hasOwnProperty('MessageType');
	}

	return false;
}
