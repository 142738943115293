import * as React from 'react';
import { IMaskedInputProps } from 'react-text-mask';
import { FormControlLabelled, FormControlType } from '../../../components/form-controls/form-control-labelled';
import { RecordedCheckPaymentMethod } from './payment-entry-model';
import { observer } from 'mobx-react';
import { ModelMetadata } from '../../virtual-terminal-generated';
import { injectAnalytics, recordEvent, WithAnalytics } from '../../../analytics';

@injectAnalytics
@observer
export class PaymentMethodRecordedCheck extends React.Component<WithAnalytics & {
	check: RecordedCheckPaymentMethod,
	showRoutingAndAccountNumbers: boolean
	checkNoun: string
}, {}> {
	render() {
		const { showRoutingAndAccountNumbers, checkNoun, check: { accountNumber, checkNumber, routingNumber, partiallyMisread }} = this.props;
		const recordedCheckClassName = `vt-payment-method vt-payment-method-recorded-check ${showRoutingAndAccountNumbers ? `col-lg-8` : `col-xs-12`}`;
		const checkNumberClassName = showRoutingAndAccountNumbers ? `vt-payment-method-check-number` : `vt-payment-method-check-number-only`;

		return (
			<div className="row">
				{partiallyMisread && <span className="label label-warning">Partially misread</span>}
				<div className={recordedCheckClassName}>
					{showRoutingAndAccountNumbers &&
						<FormControlLabelled cssClassNames="vt-payment-method-routing-number"
							label="Routing number (optional)"
							formControlProps={{
								formControlType: FormControlType.Text,
								name: this.metadata.RoutingNumber.propertyName,
								placeholder: '●●●●●●●●●',
								maskOptions: this.createMaskOptions(this.metadata.RoutingNumber.validationRules.length.parameters.max),
								type: 'text',
								value: routingNumber,
								onChangeHandler: this.handleRoutingNumberChange,
								validationRules: this.metadata.RoutingNumber.validationRules,
								acceptanceTestTargetId: 'routing number - check - recorded'
							}} />
					}
					{showRoutingAndAccountNumbers &&
						<FormControlLabelled cssClassNames="vt-payment-method-account-number"
							label="Account number (optional)"
							formControlProps={{
								formControlType: FormControlType.Text,
								name: this.metadata.AccountNumber.propertyName,
								placeholder: '●●●●●●●●●',
								maskOptions: this.createMaskOptions(this.metadata.AccountNumber.validationRules.length.parameters.max),
								type: 'text',
								value: accountNumber,
								ignorePanLikeValidation: true,
								onChangeHandler: this.handleAccountNumberChange,
								validationRules: this.metadata.AccountNumber.validationRules,
								acceptanceTestTargetId: 'account number - check - recorded'
							}} />
					}
					<FormControlLabelled cssClassNames={checkNumberClassName}
						label={`${checkNoun} number (optional)`}
						formControlProps={{
							formControlType: FormControlType.Text,
							name: this.metadata.CheckNumber.propertyName,
							placeholder: '●●●●',
							maskOptions: this.createMaskOptions(this.metadata.CheckNumber.validationRules.length.parameters.max),
							type: 'text',
							value: checkNumber,
							ignorePanLikeValidation: true,
							onChangeHandler: this.handleCheckNumberChange,
							...(NewFeatures.SetupPinpointAnalytics && { onBlurHandler: this.handleCheckNumberBlur }),
							validationRules: this.metadata.CheckNumber.validationRules,
							acceptanceTestTargetId: 'check number - check - recorded'
						}} />
				</div>
			</div>
		);
	}

	private handleCheckNumberBlur = () => {
		const { analytics, check: { checkNumber } } = this.props;
		if (analytics && checkNumber) {
			const { feature, subFeature } = analytics;
			recordEvent({
				feature,
				subFeature,
				eventTypeLabel: 'completePaymentMethod',
				attributes: { paymentMethodType: 'recordedCheck' }
			});
		}
	}

	private createMaskOptions(length: number): IMaskedInputProps {
		const mask = new Array<RegExp>(length).fill(/[0-9]/);
		return { mask, guide: false };
	}

	private handleRoutingNumberChange = (event: React.FormEvent<HTMLInputElement>) => {
		this.props.check.updateRoutingNumber(event.currentTarget.value);
	}

	private handleAccountNumberChange = (event: React.FormEvent<HTMLInputElement>) => {
		this.props.check.updateAccountNumber(event.currentTarget.value);
	}

	private handleCheckNumberChange = (event: React.FormEvent<HTMLInputElement>) => {
		this.props.check.updateCheckNumber(event.currentTarget.value);
	}

	private get metadata() {
		return ModelMetadata.RecordedCheckModel;
	}
}
