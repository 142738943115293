import { MainEvents } from '../state/main';
import { getCancellableEffect } from '../../../Shared/helpers/saga-side-effects';
import { givingStatementsDataService } from '../giving-statements-data-service';
import { GivingStatementsViewModel } from '../giving-statements-view-model';
import { LandingPageEvents } from '../state/landing-page';
import { LandingPageViewModel } from '../pages/landing-page/landing-page-view-model';
import { MachineContext } from '../../../Shared/state-machine/saga-state-machine';

export const statementHistoryCancellableEffect = getCancellableEffect();

export const LandingPageActionMap = {
	GetNewCommunityMemberCount: function* (context: MachineContext) {
		try {
			const viewModel = context.viewModel as GivingStatementsViewModel;
			const landingPageViewModel = viewModel.landingPageViewModel as LandingPageViewModel;
			const newCount = landingPageViewModel.allCommunityMembersAreReviewed ? 0 : yield givingStatementsDataService.newCommunityMemberCount(null);

			landingPageViewModel.setNewMemberCount(newCount);

			// If there are new members, show the member dialog. Otherwise take them straight to configure.
			context.transition(LandingPageEvents[newCount ? 'SHOW_COMMUNITY_MEMBER_DIALOG' : 'CREATE_NEW_CONFIGURATION']);
		} catch (error) {
			// @TODO: Handle the error.
			console.error(error);
		}
	},
	CreateConfiguration: function* (context: MachineContext) {
		try {
			const id = yield givingStatementsDataService.createRun(null);

			statementHistoryCancellableEffect.cancel();

			context.transition(MainEvents.LOAD_CONFIGURATION_IN_PROGRESS, id);
		} catch (error) {
			// @TODO: Handle the error.
			console.error(error);
		}
	},
	FetchExistingConfigurationList: function* (context: MachineContext) {
		try {
			const viewModel = context.viewModel as GivingStatementsViewModel;
			const configList = yield givingStatementsDataService.historicSummaryList(null);

			viewModel.landingPageViewModel.updateStatementHistory(configList);

			context.transition(LandingPageEvents.SHOW_EXISTING_CONFIG_LIST);
		} catch (error) {
			// @TODO Handle the error.
			console.error(error);
		}
	},
	ScrollToList: (context: MachineContext) => {
		const viewModel = context.viewModel as GivingStatementsViewModel;
		viewModel.landingPageViewModel.setScrollToRunsList(true);
	},
};
