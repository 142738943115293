import * as React from 'react';
import { DropDownMenuController } from '../hoc-behavior/dropdown-menu-controller';
import { OmniboxContext, IHaveOmniboxContext, OmniboxContextKey } from './payer-search-omnibox-context';
import { observer, Provider } from 'mobx-react';

@observer
export class PayerSearchContainer extends React.Component<{ className?: string }, IHaveOmniboxContext> {
	constructor(props) {
		super(props);

		let state = {};
		state[OmniboxContextKey] = new OmniboxContext();
		this.state = state;
	}

	render() {
		const { handleRequestClose, handleRequestOpen, open, omniboxFocused} = this.state.omniboxContext;
		return (
			<Provider omniboxContext={this.state.omniboxContext}>
				<DropDownMenuController onRequestClose={handleRequestClose} onRequestOpen={handleRequestOpen} open={open} openOnFocus={true}>
					<div className={`payer-search-omnibox${omniboxFocused ? ' payer-search-omnibox-focused' : ''} ${this.props.className}`} data-pp-at-target="payer-search-omnibox">
						{this.props.children}
					</div>
				</DropDownMenuController>
			</Provider>
		);
	}
}
