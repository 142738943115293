import {ModalDialogCommander} from '../components/modal-dialog-commander';

export class PillarStep {

	private form: HTMLFormElement;
	private stepsRemaining: Number;
	private confirmDialog: Element;
	private stepCheckbox: HTMLInputElement;
	private checkboxEventListener: EventListenerOrEventListenerObject;

	constructor() {
		if(document.querySelector('.step-status')) {
			this.form = document.querySelector('.complete-step-form') as HTMLFormElement;
			let stepsCompletedInput: HTMLInputElement = this.form.querySelector('input[name=StepsRemaining]') as HTMLInputElement;
			this.stepsRemaining = parseInt(stepsCompletedInput.value);
			this.stepCheckbox = this.form.querySelector('input[name=IsCompleted]') as HTMLInputElement;
			if(this.stepsRemaining > 0) {
				// Unfinished steps - enable the checkbox and add event listeners
				this.stepCheckbox.disabled = false;
				this.confirmDialog = document.querySelector('div[data-confirm-dialog]');
				let confirmButton: Element = this.confirmDialog.querySelector('[data-confirm-button]');
				this.checkboxEventListener = event => this.checkBoxChangeListener(event);
				confirmButton.addEventListener('click', event => this.confirmFinalStep(event));
				this.stepCheckbox.addEventListener('change', this.checkboxEventListener);
			}
		}
	}

	checkBoxChangeListener(e:Event) {
		if (this.stepsRemaining === 1 && this.stepCheckbox.checked) {
			// Last step being completed - show dialog
			this.stepCheckbox.checked = false; // uncheck the box in case dialog dismissed
			ModalDialogCommander.open(this.confirmDialog);
		} else {
			// otherwise, submit the form
			this.removeCheckboxListener();
			this.form.submit();
		}
	}

	confirmFinalStep(e:Event) {
		ModalDialogCommander.close(this.confirmDialog);
		this.stepCheckbox.checked = true; // re-check the checkbox before submitting
		this.removeCheckboxListener();
		this.form.submit();
	}

	removeCheckboxListener() {
		this.stepCheckbox.removeEventListener('change', this.checkboxEventListener);
	}
}

export let pillarStep = () => new PillarStep();
