import { observable, action, reaction } from 'mobx';

import {
	EditMigrationViewModel,
	OperationResponse,
	OperationResult,
	ScheduleImportMigrationStatus,
} from '../../schedule-import-generated';
import { getScheduleImportDataService, ScheduleImportDataServiceAction, ScheduleImportApiConfigType, } from '../../schedule-import-data-service';
import { AlertProps, AlertType } from '../../../transaction-import/components/alert-control';
import { IDataServiceActionSubscriber } from '../../../utils/data-service';
import { MigrationDateRangeViewModel } from '../../components/migration-date-range/migration-date-range-view-model';
import { MigrationContactDetailsViewModel } from '../../components/migration-contact-details/migration-contact-details-view-model';
import { MigrationEmailsViewModel } from '../../components/migration-emails/migration-emails-view-model';

export class ScheduleMigrationEditViewModel {
	readonly redirectUrl: string;
	readonly migrationStatusText: string;
	readonly migrationStatusClassName: string;
	@observable alertProps: AlertProps;
	@observable dateRangeViewModel: MigrationDateRangeViewModel;
	@observable contactDetailsViewModel: MigrationContactDetailsViewModel;
	@observable emailsViewModel: MigrationEmailsViewModel;
	@observable actionInProgress: boolean;
	originalStartDate:Date;
	originalEndDate:Date;

	editMigrationSubscriber: IDataServiceActionSubscriber<ScheduleImportApiConfigType, 'updateMigration'>;

	constructor(public viewData: EditMigrationViewModel) {
		this.dateRangeViewModel = new MigrationDateRangeViewModel(
			viewData.Migration.StartDate,
			viewData.Migration.EndDate,
			viewData.MinMigrationPeriodInDays,
			viewData.MaxMigrationPeriodInDays,
			viewData.MigrationStatus,
			viewData.MinDaysRemainingForUpdate,
		);

		this.originalStartDate = viewData.Migration.StartDate;
		this.originalEndDate = viewData.Migration.EndDate;

		this.contactDetailsViewModel = new MigrationContactDetailsViewModel(
			viewData.Migration.ContactName,
			viewData.Migration.ContactEmail,
			viewData.Migration.ContactPhone
		);

		this.emailsViewModel = new MigrationEmailsViewModel(
			viewData.Migration.InitialEmail,
			viewData.Migration.WeeklyEmail,
			viewData.Migration.FinalEmail,
		);

		this.redirectUrl = viewData.RedirectUrl;

		this.migrationStatusText = this.viewData.Migration.MigrationStatusText;
		this.migrationStatusClassName = this.viewData.Migration.MigrationStatusClassName;

		this.editMigrationSubscriber = getScheduleImportDataService().getActionSubscriberFactory('updateMigration')((action) => this.subscribeToEditMigration(action));

		reaction(() => this.contactDetailsViewModel.isModified || this.dateRangeViewModel.isModidfied, (isModified: boolean) => this.showUpdateEmailAlert(isModified));
	}

	@action.bound
	handleCancel() {
		this.actionInProgress = true;
		window.location.href = this.redirectUrl;
	}

	@action.bound
	showAlert(type: AlertType, content: string | string[], showCloseButton: boolean = true) {
		this.alertProps = {
			alertType: type,
			alertContent: content,
			showCloseButton: showCloseButton,
			onClose: () => {
				this.alertProps = null;
			}
		};
	}

	@action.bound
	initUpdateMigrationRequest() {
		this.actionInProgress = true;
		const isMigrationActive = this.viewData.MigrationStatus === ScheduleImportMigrationStatus.Active
								|| this.viewData.MigrationStatus === ScheduleImportMigrationStatus.Paused;

		this.editMigrationSubscriber.initRequest({
			model: {
				MigrationId: this.viewData.Migration.MigrationId,
				StartDate: this.dateRangeViewModel.startDate,
				OriginalStartDate: this.originalStartDate,
				EndDate: this.dateRangeViewModel.endDate,
				OriginalEndDate: this.originalEndDate,
				ContactName: this.contactDetailsViewModel.contactName,
				ContactEmail: this.contactDetailsViewModel.contactEmail,
				ContactPhone: this.contactDetailsViewModel.contactPhone,
				InitialEmail: this.emailsViewModel.initialEmail,
				WeeklyEmail: this.emailsViewModel.weeklyEmail,
				FinalEmail: this.emailsViewModel.finalEmail,
				IsConfirmed: false,
				NeedToAdjustActiveSchedules: isMigrationActive && this.dateRangeViewModel.isEndDateModified,
				EmailContentType: this.viewData.Migration.EmailContentType,
				DefaultStartPoint: this.viewData.Migration.DefaultStartPoint,
			}
		});
	}

	private showUpdateEmailAlert(isModified: boolean) {
		if (isModified) {
			this.showAlert(
				AlertType.Info,
				'It looks like you\'ve changed some of your migration details. Please review your emails to make sure these still include the correct details.',
				false);
		} else {
			this.alertProps.alertContent = null;
		}
	}

	private subscribeToEditMigration(action: ScheduleImportDataServiceAction) {
		switch (action.type) {
			case 'request_success':
				const response: OperationResponse  =  action.response;
				if (response.Result === OperationResult.Success) {
					window.location.href = this.redirectUrl;
					return;
				} else {
					this.showAlert(AlertType.Danger, response.ErrorMessage);
				}
				break;
			case 'request_error':
				const validationErrors = action.error.validationErrors;
				if (validationErrors) {
					this.showAlert(AlertType.Danger, Object.keys(validationErrors).map(x => validationErrors[x]));
				}
				break;
		}
		this.actionInProgress = false;
	}
}
