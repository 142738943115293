import * as React from 'react';
import { observer, inject, Provider } from 'mobx-react';
import { computed } from 'mobx';
import { CampaignsLandingMainViewModel, ICampaignsLandingMachineContext } from './campaigns-landing-main-view-model';
import { CampaignsHeader } from '../../components/campaigns-header/campaigns-header';
import { WelcomeToCampaigns } from '../../components/campaigns-call-to-actions/welcome-to-campaigns';
import { CampaignCard } from '../../components/campaign-card/campaign-card';
import { CampaignStatus } from '../../campaigns-generated';
import State from './state-machine/campaigns-landing-states';
import { Button } from '../../../../Shared/components/button/button';
import { Match, MatchAny, MatchNone } from '../../../../Shared/state-machine/match';
import { classNames } from '../../../../Shared/utils/classnames';
import { Spinner } from '../../../../Shared/components/spinner/spinner';
import { CSSTransitionGroup } from 'react-transition-group-v1';
import { machineContextKey } from '../../../../Shared/state-machine/saga-state-machine';
import { createCampaignsLandingMachineContext } from './state-machine/campaigns-landing-machine-context';
import { CampaignsErrorModal } from '../../components/campaigns-modals/campaigns-error-modal';
import { CampaignCardSection, CampaignCardViewModel } from '../../campaign-types';
import { Tab, TabContainer } from "../../../components/tab/tab";

import * as styles from "./campaigns-landing-main.less";
import * as sharedStyles from "../../shared-styles.less";
import { isFunction } from "../../../../Shared/utils/is-function";

const campaignCardTransitionTime = parseInt(styles.campaignCardTransitionTime);

interface CampaignsLandingMainProps {
	store: CampaignsLandingMainViewModel;
	machineContext?: ReturnType<typeof createCampaignsLandingMachineContext>;
}

@inject(machineContextKey)
@observer
export class CampaignsLandingMain extends React.Component<
	CampaignsLandingMainProps,
	{ opened: {} }
> {
	constructor(props) {
		super(props);
		this.state = { opened: {} };
	}

	render() {
		const { store, machineContext: { eventCreator } } = this.props;
		const {
			campaigns: {
				[CampaignCardSection.Drafts]: draftsSection,
				[CampaignCardSection.Published]: publishedSection,
				[CampaignCardSection.Closed]: closedSection
			},
			vm: {
				CreateCampaignUrl,
			},
		} = store;

		const initialStates = [State.Draft, State.ActiveOrScheduled, State.Closed].map(state => [state, State.Managing, State.Init]);

		const DraftTitle = CampaignCardSection[CampaignCardSection.Drafts];
		const PublishedTitle = CampaignCardSection[CampaignCardSection.Published];
		const ClosedTitle = CampaignCardSection[CampaignCardSection.Closed];

		return (
			<div>
				<Match state={State.Welcome}>
					<WelcomeToCampaigns createCampaignUrl={CreateCampaignUrl} />
				</Match>

				<Match state={State.CampaignsManagement}>
					<CampaignsHeader createCampaignUrl={CreateCampaignUrl} />

					<MatchAny states={initialStates}>
						<div className={sharedStyles.spinnerContainer}>
							<Spinner className={sharedStyles.loadSpinner} />
						</div>
					</MatchAny>

					<MatchNone states={initialStates}>
						<TabContainer
							currentTabId={CampaignCardSection.Published}
							navContainerClassName={styles.navContainer}
						>
							<Tab
								tabId={CampaignCardSection.Published}
								title={PublishedTitle}
								acceptanceTestTargetId="Published tab">
								<CampaignCards
									title={PublishedTitle}
									disableAnimation={this.state.opened[PublishedTitle]}
									onReveal={this.onCampaignView}
									state={State.ActiveOrScheduled}
									items={publishedSection.items}
									hasNextPage={publishedSection.hasNextPage}
									loadMore={this.loadActiveOrScheduledCampaigns}
									shouldDisableLoadMore={() => store.shouldDisableLoadMore} />
							</Tab>
							<Tab
								tabId={CampaignCardSection.Drafts}
								title={DraftTitle}
								acceptanceTestTargetId={"Draft tab"}
							>
								<CampaignCards
									title={DraftTitle}
									disableAnimation={this.state.opened[DraftTitle]}
									onReveal={this.onCampaignView}
									state={State.Draft}
									items={draftsSection.items}
									hasNextPage={draftsSection.hasNextPage}
									loadMore={this.loadDraftCampaigns}
									shouldDisableLoadMore={() =>
										store.shouldDisableLoadMore
									}
								/>
							</Tab>
							<Tab
								tabId={CampaignCardSection.Closed}
								title={ClosedTitle}
								acceptanceTestTargetId="Closed tab"
							>
								<CampaignCards
									title={ClosedTitle}
									disableAnimation={this.state.opened[ClosedTitle]}
									onReveal={this.onCampaignView}
									state={State.Closed}
									items={closedSection.items}
									hasNextPage={closedSection.hasNextPage}
									loadMore={this.loadClosedCampaigns}
									shouldDisableLoadMore={() => store.shouldDisableLoadMore} />
							</Tab>
						</TabContainer>
					</MatchNone>

					<CampaignsErrorModal state={[State.ShowingError, State.On]} onClose={eventCreator.processFailureComplete} />
				</Match>
			</div>
		);
	}
	private onCampaignView = (title: string) =>
		this.setState({ opened: { ...this.state.opened, [title]: true } });

	private loadDraftCampaigns = () => this.props.machineContext.eventCreator.loadDraftCampaigns(
		{ position: this.props.store.campaigns[CampaignStatus.Draft].items.length - 1 }
	)

	private loadActiveOrScheduledCampaigns = () => this.props.machineContext.eventCreator.loadActiveOrScheduledCampaigns(
		{ position: this.props.store.campaigns[CampaignStatus.Published].items.length - 1 }
	)

	private loadClosedCampaigns = () => this.props.machineContext.eventCreator.loadClosedCampaigns(
		{ position: this.props.store.campaigns[CampaignStatus.Closed].items.length - 1 }
	)
}


interface CampaignCardsProps {
	title: string;
	state: string;
	items: CampaignCardViewModel[];
	hasNextPage: boolean;
	loadMore: () => void;
	shouldDisableLoadMore: () => boolean;
	onReveal?: (title: string) => void;
	disableAnimation?: boolean;
}

@inject(machineContextKey)
@observer
class CampaignCards extends React.Component<
	CampaignCardsProps & ICampaignsLandingMachineContext
> {
	private transitionDelay = 300;

	componentDidUpdate(prevProps: CampaignCardsProps) {
		const { items, hasNextPage, loadMore } = this.props;
		const allCampaignsAreRemoved = prevProps.items.length !== 0 && items.length === 0;
		if (allCampaignsAreRemoved && hasNextPage) {
			loadMore();
		}
	}

	render() {
		const { title, state, items, hasNextPage, loadMore, shouldDisableLoadMore, disableAnimation} = this.props;

		const hasAnimation = disableAnimation ? false : true;

		return (
			<section className={styles.container}>
				<div
					className={classNames(
						styles.cards,
						this.isCollapsed && styles.collapsed
					)}
				>
					{
						<CSSTransitionGroup
							component="div"
							transitionName={{
								enter: styles.cardAppear,
								enterActive: styles.cardAppearActive,
								appear: styles.cardAppear,
								appearActive: styles.cardAppearActive,
								leave: styles.cardLeave,
								leaveActive: styles.cardLeaveActive,
							}}
							transitionEnter={hasAnimation}
							transitionEnterTimeout={campaignCardTransitionTime}
							transitionAppear={hasAnimation}
							transitionAppearTimeout={campaignCardTransitionTime}
							transitionLeave={hasAnimation}
							transitionLeaveTimeout={this.transitionDelay}
						>
							{items.map((item, index) => (
								<CampaignCard
									key={item.Id}
									cardViewModel={item}
									disableAnimation={!hasAnimation}
									animationDelay={campaignCardTransitionTime}
									acceptanceTestTargetId={`campaign card ${state.toLowerCase()} ${index}`}
								/>
							))}
						</CSSTransitionGroup>
					}
					{
						!hasNextPage && items.length === 0 &&
						<i className={styles.emptyCampaignMessage}>{this.getEmptyCampaignMessage(state)}</i>
					}
					{
						hasNextPage && <LoadMoreButton loadMore={loadMore} shouldDisableLoadMore={shouldDisableLoadMore} state={state} />
					}
				</div>
			</section>
		);
	}

	componentWillUnmount(){
		const { onReveal, title, disableAnimation } = this.props;
		if (isFunction(onReveal) && !disableAnimation) {
			onReveal(title);
		}
	}

	@computed
	private get isCollapsed() {
		const { state, machineContext: { matchesState } } = this.props;
		return matchesState([state, State.Collapsible, State.Collapsed]);
	}

	private toggle = (e) => {
		e.preventDefault();

		const { state } = this.props;
		const { eventCreator } = this.props.machineContext;

		switch (state) {
			case State.Draft:
				this.isCollapsed ? eventCreator.expandDraftCampaigns() : eventCreator.collapseDraftCampaigns();
				return;
			case State.ActiveOrScheduled:
				this.isCollapsed ? eventCreator.expandActiveOrScheduledCampaigns() : eventCreator.collapseActiveOrScheduledCampaigns();
				return;
			case State.Closed:
				this.isCollapsed ? eventCreator.expandClosedCampaigns() : eventCreator.collapseClosedCampaigns();
				return;
		}
	}

	private getEmptyCampaignMessage(state: string) {
		switch (state) {
			case State.Draft:
				return 'No Campaigns in drafts';
			case State.ActiveOrScheduled:
				return 'No published Campaigns';
			case State.Closed:
				return 'No closed Campaigns';
			default:
				throw new Error(`Campaign state '${state} is not supported'`);
		}
	}
}

interface LoadMoreButtonProps {
	loadMore: () => void;
	shouldDisableLoadMore: () => boolean;
	state: string;
	machineContext?: ReturnType<typeof createCampaignsLandingMachineContext>;
}

@inject(machineContextKey)
@observer
class LoadMoreButton extends React.Component<LoadMoreButtonProps> {
	render() {
		const { loadMore, shouldDisableLoadMore, state } = this.props;

		return <Button className={styles.loadMoreBtn}
			loadingClassName={styles.loading}
			spinnerClassName={sharedStyles.loadMoreSpinner}
			onClick={loadMore}
			isLoading={this.isLoading} acceptanceTestTargetId={`load more ${state.toLowerCase()}`}
			disabled={shouldDisableLoadMore()}>
			{this.isLoading ? 'Loading More' : 'Load More'}
		</Button>;
	}

	@computed
	private get isLoading() {
		const { state, machineContext: { matchesState } } = this.props;
		return matchesState([state, State.Managing, State.LoadingMore]);
	}
}
