import { MachineConfig } from 'xstate/lib/types';
import { MachineContext } from '../../../Shared/state-machine/saga-state-machine';
import { GivingStatementActions, GivingStatementsActionMap } from '../actions/main-actions';
import { LandingPageStateMachine } from './landing-page';
import { ConfigurePageStateMachine } from './configure-page';
import { PreviewPageStateMachine } from './preview-page';
import { SendPageStateMachine } from './send-page';
import { GivingStatementsViewModel } from '../giving-statements-view-model';

export enum GivingStatementsStates {
	PAGE_LOAD = 'Page load',
	LANDING_VIEW = 'Landing page',
	CONTINUE_CONFIGURATION = 'Continue configuration in progress',
	CONFIGURE_VIEW = 'Configure page',
	PREVIEW_VIEW = 'Preview page',
	SEND_VIEW = 'Send statements page',
}

export enum MainEvents {
	LOAD_LANDING_VIEW = 'Load landing view',
	LOAD_HISTORICAL_RUNS = 'Load landing view and scroll to runs list',
	LOAD_CONFIGURATION_IN_PROGRESS = 'Load an already started configuration',
	LOAD_CONFIGURE_VIEW = 'Load configure view',
	LOAD_PREVIEW_VIEW = 'Load preview view',
	LOAD_SEND_VIEW = 'Load send view',
	LOAD_PREVIOUS_VIEW = 'Load previous view',
}

export const GivingStatementsStateMachine = (): MachineConfig => ({
	key: 'GivingStatements',
	// @NOTE: We need a higher level initial state to set the state of the app.

	initial: GivingStatementsStates.PAGE_LOAD,
	states: {
		[GivingStatementsStates.PAGE_LOAD]: {
			onEntry: GivingStatementActions.Init,
			on: {
				[MainEvents.LOAD_LANDING_VIEW]: GivingStatementsStates.LANDING_VIEW,
				[MainEvents.LOAD_CONFIGURATION_IN_PROGRESS]: GivingStatementsStates.CONTINUE_CONFIGURATION,
			}
		},
		[GivingStatementsStates.LANDING_VIEW]: {
			onEntry: GivingStatementActions.ClearConfigurationId,
			on: {
				[MainEvents.LOAD_CONFIGURATION_IN_PROGRESS]: GivingStatementsStates.CONTINUE_CONFIGURATION,
			},
			...LandingPageStateMachine(),
		},
		[GivingStatementsStates.CONTINUE_CONFIGURATION]: {
			onEntry: GivingStatementActions.ContinueConfiguration,
			on: {
				[MainEvents.LOAD_CONFIGURE_VIEW]: {
					[GivingStatementsStates.CONFIGURE_VIEW]: {
						actions: [GivingStatementActions.LoadConfigureView],
					},
				},
				[MainEvents.LOAD_PREVIEW_VIEW]: GivingStatementsStates.PREVIEW_VIEW,
				[MainEvents.LOAD_SEND_VIEW]: GivingStatementsStates.SEND_VIEW,
			},
		},
		[GivingStatementsStates.CONFIGURE_VIEW]: {
			on: {
				[MainEvents.LOAD_PREVIEW_VIEW]: GivingStatementsStates.PREVIEW_VIEW,
				[MainEvents.LOAD_PREVIOUS_VIEW]: {
					[GivingStatementsStates.CONFIGURE_VIEW]: {
						actions: [GivingStatementActions.LoadPreviousView],
					},
				},
				[MainEvents.LOAD_LANDING_VIEW]: GivingStatementsStates.LANDING_VIEW,
				[MainEvents.LOAD_SEND_VIEW]: GivingStatementsStates.SEND_VIEW,
				[MainEvents.LOAD_HISTORICAL_RUNS]: {
					[GivingStatementsStates.LANDING_VIEW]: {
						actions: [GivingStatementActions.ScrollToList],
					}
				},
			},
			...ConfigurePageStateMachine(),
		},
		[GivingStatementsStates.PREVIEW_VIEW]: {
			onEntry: GivingStatementActions.LoadPreviewView,
			on: {
				[MainEvents.LOAD_SEND_VIEW]: GivingStatementsStates.SEND_VIEW,
				[MainEvents.LOAD_PREVIOUS_VIEW]: {
					[GivingStatementsStates.CONFIGURE_VIEW]: {
						actions: [GivingStatementActions.LoadConfigureView],
					},
				},
				[MainEvents.LOAD_LANDING_VIEW]: GivingStatementsStates.LANDING_VIEW,
			},
			...PreviewPageStateMachine(),
		},
		[GivingStatementsStates.SEND_VIEW]: {
			on: {
				[MainEvents.LOAD_LANDING_VIEW]: GivingStatementsStates.LANDING_VIEW,
				[MainEvents.LOAD_PREVIOUS_VIEW]: GivingStatementsStates.LANDING_VIEW,
				[MainEvents.LOAD_CONFIGURE_VIEW]: {
					[GivingStatementsStates.CONFIGURE_VIEW]: {
						actions: [GivingStatementActions.LoadConfigureView],
					},
				},
			},
			...SendPageStateMachine(),
		},
	},
});

let GivingStatementsMachineContext: MachineContext = null;

export const attachGivingStatementsMachineContext = (vm: GivingStatementsViewModel) => {
	if (GivingStatementsMachineContext === null) {
		GivingStatementsMachineContext = new MachineContext(GivingStatementsStateMachine(), GivingStatementsActionMap, vm);
	} else {
		GivingStatementsMachineContext.viewModel = vm;
	}

	vm.setMachineContext(GivingStatementsMachineContext);

	return GivingStatementsMachineContext;
};
