import * as React from 'react';
import { observer } from 'mobx-react';
import { ListingMapping, QboLocationMappingInfoViewModel } from '../../mapping-form/qbo-location-mapping-info-view-model';
import {
	FormControlSelect,
	IFormControlSelectOptionProps
} from '../../../../components/form-controls/form-control-select';
import { FormControlCheckbox } from '../../../../components/form-controls/form-control-checkbox/form-control-checkbox';

import * as styles from './styles.less';
import { QboPanelContainer } from '../panel-container';
import { MappingsSetupProgress } from '../../../qbo-integration-generated';

export interface ListingLocationMappingProps {
	vm: QboLocationMappingInfoViewModel;
	isProcessingRequest: boolean;
	cancelAction: () => void;
}

@observer
export class ListingLocationMapping extends React.Component<ListingLocationMappingProps> {
	render() {
		const {
			vm: {
				enableMappings,
				toggleEnableMapping,
				panelEnabled,
				listingMappings,
				qbLocations,
				paymentLabel,
				activeStep,
				setStepAsActive,
			},
			isProcessingRequest,
			cancelAction,
		} = this.props;
		return (
			<QboPanelContainer
				title="2. Match listings and locations"
				currentStep={activeStep}
				activeOnStep={MappingsSetupProgress.MerchantLocations}
				editButtonAcceptanceTestTargetId="listing-location-mapping-edit"
				isProcessingRequest={isProcessingRequest}
				setStepAsActive={setStepAsActive}
				cancelAction={cancelAction}>

					<div>
						<FormControlCheckbox
							value={enableMappings}
							onChangeHandler={toggleEnableMapping}
							disabled={!panelEnabled}
							text="Match Pushpay listings to QuickBooks locations."
							labelClassNames={styles.checkboxLabel}
							containerClassNames={styles.checkboxContainer}
						/>
					</div>

					<div className={styles.explanation}>
						{panelEnabled ?
						`If your Pushpay Listings match your QuickBooks Locations, check this box. You'll then be able to match them,
						which ensures your ${paymentLabel.NounLowerCase} is allocated correctly in QuickBooks Online.` :
						`We've noticed you don't have Locations enabled in QuickBooks Online, so you can skip this step! If you ever
						decide to enable Locations in QuickBooks Online, you can come back here later and match them to your Pushpay Listings.`}
					</div>
					{
						panelEnabled && listingMappings.map(mapping => <ListingMappingFormRow key={mapping.merchantId} mapping={mapping} locationOptions={qbLocations} enabled={enableMappings}/>)
					}
			</QboPanelContainer>
		);
	}
}


@observer
export class ListingMappingFormRow extends React.Component<{mapping: ListingMapping, locationOptions: IFormControlSelectOptionProps[], enabled: boolean}> {
	render() {
		const { mapping: {merchantId, merchantName, locationId}, locationOptions, enabled} = this.props;
		return (
			<div className={styles.mappingRow}>
				<div className={styles.mappingMerchant}>{merchantName}</div>
				<div className={styles.mappingLocation}>
					<label className={styles.locationLabel} htmlFor={`location-${merchantId}`}>
						Match to QuickBooks location <span className={styles.optional}>(Optional)</span>
					</label>
					<div className={styles.locationSelect}>
						<FormControlSelect Options={locationOptions}
							onChangeHandler={this.locationChanged}
							value={locationId}
							name={`account-${merchantId}`}
							placeholder="Select a location"
							disabled={!enabled}/>

					</div>
				</div>
			</div>
		);
	}

	locationChanged = (e: React.FormEvent<HTMLSelectElement>) => {
		this.props.mapping.setLocation(e.currentTarget.value);
	}
}
