import {BrowserSupportHelper} from './browsersupporthelper';

export module SelectorHelper {
	export function closest(selector: string, element: Element): Element {
		while (element !== document.body) {
			element = element.parentElement;
			if (BrowserSupportHelper.matchesSelector(element, selector)) {
				return element;
			}
		}
		return null;
	}
}
