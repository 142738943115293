import * as React from 'react';
import * as styles from './styles.less';
import { classNames } from '../../../../Shared/utils/classnames';

export const AnimatedSuccessTick: React.StatelessComponent<{ wrapperClass?: string }> = ({ wrapperClass }) => {
	const tickWrapperClass = classNames(styles.tickWrapper, wrapperClass);
	return (
		<svg className={tickWrapperClass} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
			<polyline className={styles.tick} points="29.75 55.98 45.63 68.31 72.29 30.02"/>
			<circle className={styles.tickBorder} cx="49.5" cy="49.5" r="48"/>
		</svg>
	);
};
