import * as React from 'react';
import { observer, Provider } from 'mobx-react';
import { Button } from '../../../Shared/components/button/button';
import { ValidatableForm } from '../../components/form-controls/validatable-form';
import { MemberFormViewModel } from '../view-models/member-form';

interface IMemberModalWrapperProps {
	memberFormViewModel: MemberFormViewModel;
	submitHandler: () => void;
}

@observer
export class MemberModalWrapper extends React.Component<IMemberModalWrapperProps> {
	render() {
		const { submitHandler, memberFormViewModel } = this.props;

		return (
			<Provider memberFormViewModel={memberFormViewModel}>
				<div className="modal modal-form fade" tabIndex={-1} role="dialog" data-modal-prevent-close={memberFormViewModel.isProcessingRequest}>
					<ValidatableForm className="panel panel-default modal-dialog modal-dialog-labelled"
						onSubmit={submitHandler}
						validationErrors={memberFormViewModel.validationErrors}>
						<Button type="button" className="modal-form-close" data-dismiss="modal" aria-label="Close"></Button>
						{this.props.children}
					</ValidatableForm>
				</div>
			</Provider>
		);
	}
}
