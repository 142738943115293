import { action, observable, computed } from 'mobx';
import { DayOfWeek, EventTimeViewModel } from '../../loggedinweb-generated';

export class EventTimeAddEditDialogViewModel {
	@observable
	startTime: string;

	@observable
	endTime: string;

	@observable
	name: string;

	@observable
	dayOfWeek: DayOfWeek;

	@observable
	validationSummaryTitle: string;

	@observable
	validationErrors: { [s: string]: string };

	id: number;

	token: string;

	merchantNames: string[];

	@computed
	get hasMerchantNames(): boolean {
		return this.merchantNames && this.merchantNames.length > 0;
	}

	@action.bound
	updateStartTime(startTime: string) {
		this.startTime = startTime;
	}

	@action.bound
	updateEndTime(endTime: string) {
		this.endTime = endTime;
	}

	@action.bound
	updateName(name: string) {
		this.name = name;
	}

	@action.bound
	updateDayOfWeek(dayOfWeek: string) {
		this.dayOfWeek = parseInt(dayOfWeek);
	}

	@action.bound
	updateValidationSummaryTitle(validationSummaryTitle: string) {
		this.validationSummaryTitle = validationSummaryTitle;
	}

	@action.bound
	updateValidationErrors(validationErrors: {[s: string]: string}) {
		this.validationErrors = validationErrors;
	}

	constructor(public eventTime?: EventTimeViewModel) {
		if (eventTime) {
			this.id = eventTime.Id;
			this.token = eventTime.Token;
			this.dayOfWeek = eventTime.DayOfWeek;
			this.startTime = eventTime.StartTime;
			this.endTime = eventTime.EndTime;
			this.name = eventTime.Name;
			this.merchantNames = eventTime.MerchantNames;
		} else {
			this.dayOfWeek = DayOfWeek.Sunday;
			this.startTime = '09:00';
			this.endTime = '10:00';
		}
	}
}
