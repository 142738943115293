import * as React from 'react';
import { ModelMetadata } from '../funds-generated';
import { validation } from '../../validation/validation';
import { getGeneratedAttributes } from '../../utils/form-controls';
import { Provider, observer, inject } from 'mobx-react';
import { observable, computed, action, IObservableArray, toJS } from 'mobx';
import { FloatingLabelComponent, FloatingLabelComponentStore } from './floating-label';

export interface IFormProps {
	className?: string;
	onSubmit?: (form: HTMLFormElement) => void;
}

@observer
export class Form extends React.Component<IFormProps, {}> {
	private form: HTMLFormElement;
	private formContext: FormContext;

	constructor(props) {
		super(props);
		this.formContext = new FormContext();
	}

	handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
	}

	handleJQuerySubmit = (event: JQueryEventObject) => {
		if (event.result && this.props.onSubmit) {//form is valid
			this.props.onSubmit(this.form);
		}
	}

	componentDidMount() {
		validation.parseForm(this.form);
		validation.bindForm(this.form);

		$(this.form).on('submit', this.handleJQuerySubmit);
	}

	componentWillUnmount() {
		$(this.form).off('submit', this.handleJQuerySubmit);
	}

	render() {
		return (
			<Provider formContext={this.formContext}>
				<ModelContextFor modelName="">
					<form action="" autoComplete="off" noValidate={true} method="post" onSubmit={this.handleSubmit} ref={(ref) => this.form = ref}
						className={this.props.className}>
						<HiddenFieldsForArrayItems formContext={this.formContext} />
						{this.props.children}
					</form>
				</ModelContextFor>
			</Provider>
		);
	}
}

@inject('modelName')
@observer
export class ValidationMessage extends React.Component<{ for: string, modelName?: string, additionalCssClass?: string }, {}> {
	render() {
		return (
			<span className={`field-validation-valid ${this.props.additionalCssClass || ''}`}
				data-valmsg-for={getFullModelName(this.props.modelName, this.props.for)}
				data-valmsg-replace="true" />
		);
	}
}

export class ModelFor extends React.Component<{ propertyName: string }, {}> {
	render() {
		return <ModelContextFor propertyName={this.props.propertyName}>{React.Children.only(this.props.children)}</ModelContextFor>;
	}
}

export class ModelForArrayItem extends React.Component<{ propertyName?: string, index: number }, {}> {
	render() {
		return (
			<AddHiddenFieldToContext fieldName={this.props.propertyName} index={this.props.index}>
				<ModelFor propertyName={getFullModelName(this.props.propertyName, `[${this.props.index}]`)}>
					{React.Children.only(this.props.children)}
				</ModelFor>
			</AddHiddenFieldToContext>
		);
	}
}

export interface IHaveFloatingLabelProps {
	floatingLabel?: string;
}

export interface IHaveMetadataProps {
	propertyMetadata?: ModelMetadata.IPropertyMetadata;
	ignorePanLikeValidation?: boolean;
}

function createFloatingLabelStore(data: IHaveFloatingLabelProps & FieldElementProps, showLabelOnFocus) {
	const store = new FloatingLabelComponentStore();
	store.elementHasFocus = false;
	store.elementHasValue = !!(data.defaultValue || data.value);
	store.showLabelOnFocus = showLabelOnFocus;
	return store;
}

export class InputField extends React.Component<IHaveMetadataProps & IHaveFloatingLabelProps & React.HTMLProps<HTMLInputElement>, {}> {
	store: FloatingLabelComponentStore;
	constructor(props) {
		super(props);
		this.store = createFloatingLabelStore(this.props, true);
	}

	render() {
		const { floatingLabel, propertyMetadata, ignorePanLikeValidation, ...restOfProps } = this.props;
		return <FormFieldWrapper
			fieldType="input"
			floatingLabel={floatingLabel}
			propertyMetadata={propertyMetadata}
			ignorePanLikeValidation={ignorePanLikeValidation}
			floatingLabelStore={this.store}
			elementProps={restOfProps} />;
	}
}

export class SelectField extends React.Component<IHaveMetadataProps & IHaveFloatingLabelProps & React.HTMLProps<HTMLSelectElement>, {}> {
	store: FloatingLabelComponentStore;
	constructor(props) {
		super(props);
		this.store = createFloatingLabelStore(this.props, false);
	}

	render() {
		const { floatingLabel, propertyMetadata, ignorePanLikeValidation, ...restOfProps } = this.props;
		return (
			<FormFieldWrapper
				fieldType="select"
				floatingLabel={floatingLabel}
				propertyMetadata={propertyMetadata}
				floatingLabelStore={this.store}
				elementProps={restOfProps}>
				{this.props.children}
			</FormFieldWrapper>
		);
	}
}

export class TextareaField extends React.Component<IHaveMetadataProps & IHaveFloatingLabelProps
	& React.HTMLProps<HTMLTextAreaElement>, {}> {
	store: FloatingLabelComponentStore;
	constructor(props) {
		super(props);
		this.store = createFloatingLabelStore(this.props, true);
	}

	render() {
		const { floatingLabel, propertyMetadata, ignorePanLikeValidation, ...restOfProps } = this.props;
		return (
			<FormFieldWrapper
				fieldType="textarea"
				floatingLabel={floatingLabel}
				propertyMetadata={propertyMetadata}
				ignorePanLikeValidation={ignorePanLikeValidation}
				floatingLabelStore={this.store}
				elementProps={restOfProps} />
		);
	}
}

export function getFullModelName(modelName: string, propertyName: string) {
	modelName = modelName || '';
	if (!propertyName) {
		return modelName;
	}

	let prefix = '';

	if (modelName && propertyName.indexOf('[') === -1) {
		//if not an array accessor then a property accessor
		prefix = '.';
	}
	return `${modelName}${prefix}${propertyName}`;
}

@inject('modelName')
@observer
class ModelContextFor extends React.Component<{ modelName?: string, propertyName?: string }, {}> {
	render() {
		return (
			<Provider modelName={getFullModelName(this.props.modelName, this.props.propertyName)} >
				{React.Children.only(this.props.children)}
			</Provider >
		);
	}
}

@observer
class HiddenFieldsForArrayItems extends React.Component<{ formContext: FormContext }, {}> {
	renderHiddenField(value: { name: string, index: number }) {
		return <input type="hidden" key={`${value.name}_${value.index}`} name={value.name} value={`${value.index}`} />;
	}

	render() {
		this.props.formContext.hiddenFieldsAsArray.map(this.renderHiddenField);

		return <div>{this.props.formContext.hiddenFieldsAsArray.map(this.renderHiddenField)}</div>;
	}
}

type FieldElementProps = React.HTMLProps<HTMLSelectElement> | React.HTMLProps<HTMLInputElement> | React.
	HTMLProps<HTMLTextAreaElement>;

type FieldElementType = HTMLSelectElement | HTMLTextAreaElement | HTMLInputElement;

interface IFormFieldProps {
	fieldType: 'input' | 'textarea' | 'select';
	propertyMetadata?: ModelMetadata.IPropertyMetadata;
	elementProps: FieldElementProps;
	modelName?: string;
	fieldRef?: (element: FieldElementType) => void;
	ignorePanLikeValidation?: boolean;
}

@inject((stores: any, props: { modelName?: string }) => {
	props.modelName = stores.modelName;
})
@observer
class FormField extends React.Component<IFormFieldProps, {}> {
	element: FieldElementType;

	private get propertyMetadata() {
		return this.props.propertyMetadata || {} as ModelMetadata.IPropertyMetadata;
	}

	private get fieldName() {
		return getFullModelName(this.props.modelName, this.propertyMetadata.propertyName);
	}

	private get generatedAttributes() {
		return getGeneratedAttributes(this.propertyMetadata);
	}

	private get renderIgnorePanLikeAttribute() {
		if (!this.props.ignorePanLikeValidation) {
			return null;
		}

		return {'data-ignore-pan-like': true};
	}

	private get elementClassName() {
		const classes = [];
		if (this.props.elementProps.type !== 'radio' &&
			this.props.elementProps.type !== 'checkbox' &&
			this.props.elementProps.type !== 'hidden') {
			classes.push('form-control');
		}

		classes.push(this.props.elementProps.className);
		return classes.join(' ');
	}

	componentDidMount() {
		validation.attachElementValidation(this.element);
	}

	componentWillUnmount() {
		validation.detachElementValidation(this.element);
	}

	render() {
		switch (this.props.fieldType) {
			case 'input':
				return this.renderInput();
			case 'select':
				return this.renderSelect();
			case 'textarea':
				return this.renderTextArea();
			default:
				return null;
		}
	}

	private refCallback = (ref: FieldElementType) => {
		this.element = ref;
		if (this.props.fieldRef) {
			this.props.fieldRef(ref);
		}
	}

	private renderInput() {
		return <input name={this.fieldName}
			{...this.generatedAttributes}
			{...this.renderIgnorePanLikeAttribute}
			{...this.props.elementProps as any}
			ref={this.refCallback}
			className={this.elementClassName} />;
	}

	private renderSelect() {
		return (
			<div className="select-wrapper">
				<select name={this.fieldName}
					{...this.generatedAttributes}
					{...this.props.elementProps as any}
					ref={this.refCallback}
					className={this.elementClassName}>
					{this.props.children}
				</select>
			</div>
		);
	}

	private renderTextArea() {
		return <textarea name={this.fieldName}
			{...this.generatedAttributes}
			{...this.renderIgnorePanLikeAttribute}
			{...this.props.elementProps as any}
			ref={this.refCallback}
			className={this.elementClassName} />;
	}
}

interface IFormFieldWrapperProps {
	floatingLabelStore: FloatingLabelComponentStore;
}

@observer
class FormFieldWrapper extends React.Component<IFormFieldWrapperProps & IHaveFloatingLabelProps & IFormFieldProps, {}> {
	element: FieldElementType;

	private get store() {
		return this.props.floatingLabelStore;
	}

	get shouldRenderLabel() {
		return !!this.props.floatingLabel;
	}

	get elementPlaceholder() {
		return this.shouldRenderLabel ? '' : this.props.elementProps.placeholder;
	}

	renderLabel() {
		return <FloatingLabelComponent
			store={this.store}
			placeholder={this.props.elementProps.placeholder}
			onElementShouldGainFocus={this.toggleElementFocus}
			label={this.props.floatingLabel} />;
	}

	render() {
		const extendedElementProps: FieldElementProps = Object.assign({}, this.props.elementProps, {
			onChange: this.handleChange,
			onBlur: this.handleBlur,
			onFocus: this.handleFocus
		});

		if (this.elementPlaceholder) {
			extendedElementProps.placeholder = this.elementPlaceholder;
		}

		if (!this.shouldRenderLabel) {
			return <FormField {...this.props} fieldRef={(ref) => this.element = ref} elementProps={extendedElementProps} />;
		}

		return (
			<div>
				{this.renderLabel()}
				<FormField {...this.props} fieldRef={(ref) => this.element = ref} elementProps={extendedElementProps} />
			</div>
		);
	}

	private toggleElementFocus = () => {
		this.element.focus();
	}

	@action
	private handleChange = (event: React.FormEvent<any>) => {
		this.store.elementHasValue = !!this.element.value;
		if (this.props.elementProps.onChange) {
			(this.props.elementProps as any).onChange(event);
		}
	}

	@action
	private handleBlur = (event: React.FocusEvent<any>) => {
		this.store.elementHasFocus = false;
		if (this.props.elementProps.onBlur) {
			(this.props.elementProps as any).onBlur(event);
		}
	}

	@action
	private handleFocus = (event: React.FocusEvent<any>) => {
		this.store.elementHasFocus = true;
		if (this.props.elementProps.onFocus) {
			(this.props.elementProps as any).onFocus(event);
		}
	}
}

interface IAddHiddenFieldToContextProps {
	fieldName: string;
	index: number;
	modelName?: string;
	formContext?: FormContext;
}

/**
 * This component updates hidden fields om form context so form can render hidden inputs for Array indices
 * Hidden fields with indices Are needed to get MVC ModelBinder working correctly.
 * In order to send a collection of models to MVC, we need to declare the indices as a part of the form, eg:
 * <input name="Models.Index" type="hidden" value="5"/>
 * <input name="Models.Index" type="hidden" value="7"/>
 * <input name="Models[5].Name" />
 * <input name="Models[7].Name" />
 * Otherwise ModelBinder is not able to process form data with gaps in indices
 * Please note that position of the hidden input is not relevant as long as it exists on the form
 */
@inject('modelName', 'formContext')
@observer
class AddHiddenFieldToContext extends React.Component<IAddHiddenFieldToContextProps, {}> {
	get fieldName() {
		return getFullModelName(getFullModelName(this.props.modelName, this.props.fieldName), 'Index');
	}

	componentDidMount() {
		if (this.props.formContext) {
			this.props.formContext.addHiddenFieldForArrayItem(this.fieldName, this.props.index);
		}
	}

	componentWillUnmount() {
		if (this.props.formContext) {
			this.props.formContext.removeHiddenFieldForArrayItem(this.fieldName, this.props.index);
		}
	}

	render() {
		return React.Children.only(this.props.children);
	}
}

class FormContext {

	@observable
	hiddenFields = observable.map<string, IObservableArray<number>>();

	@action addHiddenFieldForArrayItem = (name: string, index: number) => {
		var entry = this.getOrCreateEntry(name);
		entry.push(index);
	}

	@action removeHiddenFieldForArrayItem = (name: string, index: number) => {
		var entry = this.getOrCreateEntry(name);
		entry.remove(index);
	}

	@computed
	get hiddenFieldsAsArray(): { name: string, index: number }[] {
		const result = new Array<{ name: string, index: number }>();
		this.hiddenFields.forEach((value, key) => {
			const values: number[] = toJS(value);

			values.sort().forEach((item) => {
				result.push({ name: key, index: item });
			});
		});

		return result;
	}

	private getOrCreateEntry(name: string) {
		if (!this.hiddenFields.has(name)) {
			this.hiddenFields.set(name, observable(new Array<number>()));
		}

		return this.hiddenFields.get(name);
	}
}
