import * as React from 'react';
import { observer } from 'mobx-react';
import { responsiveHelper } from '../../helpers/responsive-helper';
import { DataGridCell, leaveTransitionDuration, IDataGridColumn, enterTransitionDuration } from './data-grid';
import { RenderResponsive } from '../hoc-behavior/render-responsive';
import { KeyCodes } from '../../../Shared/helpers/keycodes';
import { isFunction } from '../../utils/is-function';
import { classNames as combineClassNames } from '../../../Shared/utils/classnames';

@observer
export class DataGridRowData extends React.Component<{
	expandedRowKey?: string;
	data: Object;
	columns: { [key: string]: IDataGridColumn };
	onSelectRow: (params: any) => void;
	extraClassNames?: string;
}, { isEntering: boolean }> {
	private row: HTMLElement;

	constructor(props) {
		super(props);
		this.state = { isEntering: false };
	}

	render() {
		const { data, columns, onSelectRow, extraClassNames } = this.props;

		return !this.state.isEntering && (
			<div
				className={combineClassNames(`data-grid-row-data${onSelectRow ? ' data-grid-row-data-clickable' : ''}`, extraClassNames)}
				onClick={this.onSelectRow}
				tabIndex={onSelectRow ? 0 : null}
				onKeyDown={this.onKeyDown}
				ref={this.updateRowNode}
			>
				{Object.keys(columns).map((key) => {
					const { formatter, responsiveVisibility, responsiveBreakpoints, extraClassNames } = columns[key];
					const classNames = combineClassNames('data-grid-cell', `data-grid-cell-${key.toLowerCase()}`, extraClassNames);
					const value = data[key];
					const content = (typeof value === 'string' || typeof value === 'number') ? value : '';

					return (
						<RenderResponsive key={key} visibility={responsiveVisibility} breakpoints={responsiveBreakpoints}>
							{formatter ? formatter(data, classNames) : <DataGridCell classNames={classNames}>{content}</DataGridCell>}
						</RenderResponsive>
					);
				})}
			</div>
		);
	}

	componentWillEnter(callback) {
		this.setState({ isEntering: true });

		if (this.props.expandedRowKey || responsiveHelper.isXs || responsiveHelper.isSm) {
			callback();
		} else {
			// wait for panel to transition out
			setTimeout(callback, leaveTransitionDuration);
		}
	}

	componentDidEnter() {
		this.setState({ isEntering: false });

		if (!this.props.expandedRowKey) {
			// wait for scroll disabling classes to be removed
			setTimeout(() => this.row.focus(), 0);
		}
	}

	componentWillLeave(callback) {
		if (responsiveHelper.isXs || responsiveHelper.isSm) {
			// wait for panel to transition in
			setTimeout(callback, enterTransitionDuration);
		} else {
			callback();
		}
	}

	private updateRowNode = (row) => this.row = row;

	private onSelectRow = () => {
		const { onSelectRow, data } = this.props;
		if (isFunction(onSelectRow)) {
			onSelectRow(data);
		}
	}

	private onKeyDown = (event: React.KeyboardEvent<any>) => {
		switch (event.keyCode) {
			case KeyCodes.Space:
			case KeyCodes.Enter:
				event.preventDefault();
				this.onSelectRow();
				break;
			default:
				break;
		}
	}
}
