import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { IManageFundsListItemProps, ManageFundsListItem } from './manage-funds-listitem';
import { SortableList } from './manage-funds-draganddrop';
import { shallowEqual } from '../../helpers/shallowequal';
import { ScrollingTransition } from '../utils/scrolling-transition';
import { getBoundingRect } from '../../helpers/getboundingrect';
import { isAnimationEnabled } from '../../helpers/isanimationenabled';
import { TooltipComponent } from '../../components/tooltip-component';

export interface IManageFundsListProps {
	listingId: number;
	listingName: string;
	items: IManageFundsListItemProps[];
	sortingOrderChange?: (sortingOrder: Array<string>, listingId: number) => void;
	dragStateChange?: (isDragging: boolean, listingId: number) => void;
	toggleDefaultFundPerListing?: (listingId: number) => void;
	showDefaultFundColumn: boolean;
	isBeingReordered: boolean;
	newlyAddedFundKey?: string;
	defaultFundToggleTooltip: string;
	canLoadMore: boolean;
}

export interface IManageFundsListState {
	tooltipDismissed?:boolean;
}

export class ManageFundsList extends React.Component<IManageFundsListProps, IManageFundsListState> {

	private listItems:{ [key: string]: Element } = {};
	private tooltip:HTMLElement = null;

	constructor(props) {
		super(props);

		this.state = {
			tooltipDismissed: false
		};
	}

	renderItem(item: IManageFundsListItemProps) {
		return <ManageFundsListItem key={item.itemKey} model={item} showToggleDefaultButton={this.props.showDefaultFundColumn}
			ref={(ref)=> this.listItems[item.itemKey] = ReactDOM.findDOMNode(ref) as Element}/>;
	}

	shouldComponentUpdate(nextProps: IManageFundsListProps, nextState: IManageFundsListState) {

		return !this.props.items.every((x, idx) => shallowEqual(x, nextProps.items[idx]))
			|| this.props.isBeingReordered !== nextProps.isBeingReordered
			|| this.state.tooltipDismissed !== nextState.tooltipDismissed
			|| this.props.showDefaultFundColumn !== nextProps.showDefaultFundColumn;
	}

	toggleDefaultFundPerListing() {
		if (this.props.toggleDefaultFundPerListing) {
			this.props.toggleDefaultFundPerListing(this.props.listingId);
		}
	}

	renderDefaultFundHeader() {
		if (this.props.showDefaultFundColumn) {
			return <div className="fund-listing-item-action">Default</div>;
		}
		return null;
	}

	componentDidUpdate(prevProps: IManageFundsListProps) {
		if (this.props.newlyAddedFundKey && !this.state.tooltipDismissed) {
			const item = this.props.items.filter(x => x.itemKey === this.props.newlyAddedFundKey)[0];
			if (item && item.isVisible) {
				this.dismissTooltip();
			} else {
				this.updateTooltipPosition();
			}
		}
	}

	componentDidMount() {
		if (this.props.newlyAddedFundKey && isAnimationEnabled()) {
			const itemToScrollTo = this.listItems[this.props.newlyAddedFundKey];
			if (itemToScrollTo instanceof HTMLElement) {
				const transition = new ScrollingTransition();
				const viewportHeight = window.innerHeight;
				const elementBounds = getBoundingRect(itemToScrollTo);
				const positionInCenterOfViewport = elementBounds.top - viewportHeight / 2;
				transition.startScrollingTo(positionInCenterOfViewport);
			}
		}

		if (this.props.newlyAddedFundKey && !this.state.tooltipDismissed) {
			this.updateTooltipPosition();
		}
	}

	dismissTooltip() {
		this.setState({ tooltipDismissed: true });
	}

	renderTooltip() {
		if (NewFeatures.ManageFundsPageOptimizations && this.props.canLoadMore) {
			return;
		}

		if (this.props.newlyAddedFundKey && !this.state.tooltipDismissed) {
			return <div className="tooltip bottom in"
						onClick={() => this.dismissTooltip()}
						ref={(ref) => this.tooltip = ref as HTMLElement}>
						<div className="tooltip-arrow"></div>
						<div className="tooltip-inner">
				Your new fund has been added. Change the status to visible on the listings you want it available on.</div>
					</div>;
		}
		return null;
	}

	renderInfo() {
		return (
			<TooltipComponent className="info-tooltip" message={this.props.defaultFundToggleTooltip} />
		);
	}

	render() {
		return (
			<div className={`fund-listing${this.props.isBeingReordered ? ' is-being-reordered':''}`}>
				<h3>{this.props.listingName}</h3>
				<h3 className="toggle-default-fund">
					<label className="slider-toggle">
						<input type="checkbox" onChange={() => this.toggleDefaultFundPerListing() } checked={this.props.showDefaultFundColumn} />
						<span>Use a default fund</span>
					</label>
					{this.renderInfo()}
				</h3>
				<div className="fund-listing-headers">
					<div className="fund-listing-item-info">Drag fund to change order</div>
					{ this.props.items.some(x => !!x.linkedCampaignUrl) &&
						<div className="fund-listing-item-action fund-listing-item-action-campaign">Campaign</div> }
					{ this.renderDefaultFundHeader() }
					<div className="fund-listing-item-action">Edit</div>
					<div className="fund-listing-item-action">Visible</div>
				</div>

				<SortableList
					className="fund-listing-item-list"
					uniqueKey={this.props.listingId}
					sortingOrder={this.props.items.map(x => x.itemKey) }
					sortingOrderChange={(data) => this.props.sortingOrderChange(data, this.props.listingId) }
					draggableItems={this.props.items.filter(x => x.isVisible).map(x => x.itemKey) }
					dragStateChange = {(state) => this.props.dragStateChange(state, this.props.listingId)}>
					{this.props.items.map(item => this.renderItem(item)) }
				</SortableList>
				{this.renderTooltip()}
			</div>
		);
	}

	private updateTooltipPosition() {
		if (this.tooltip) {
			const newlyAddedFund = this.listItems[this.props.newlyAddedFundKey];

			if (newlyAddedFund instanceof HTMLElement) {
				let offsetTop = newlyAddedFund.offsetTop;
				const parent = newlyAddedFund.parentNode as HTMLElement;
				if (parent && parent.offsetTop > offsetTop) {
					offsetTop += parent.offsetTop;
				}

				this.tooltip.style.top = `${offsetTop}px`;
			}
		}
	}
}
