import * as React from 'react';

export interface ICarouselProps extends React.Props<CarouselSlide> {
	id?: string;
}

export class CarouselSlide extends React.Component<ICarouselProps, {}> {

	render() {
		return (
			<div className="carousel-slide" ref={this.props.id}>
				{this.props.children}
			</div>
		);
	}
}
