import { CancellablePromise, ICancellablePromise } from '../../utils/cancellable-promise';
import * as Util from '../util';
import { AjaxUtils } from '../../utils/ajax-utils';

export class PostError {
	userError: string;
	shouldReloadPage: boolean;
	validationErrors: { [s: string]: string };
	timedout: boolean;
	conflict: boolean;
	response: string;
	isPostError: true = true;
	//A JS Error that represents this
	asJsError: Error;

	constructor(public client: XMLHttpRequest, url: string) {
		this.asJsError = new Error(`PostError:${client.status} Url:${url || 'unknown'}
	${client.response}`);

		this.response = client.response;

		switch (client.status) {
			case 205: //Reset Content = reload page
				this.shouldReloadPage = true;
				this.userError = client.getResponseHeader('X-Reset-Because');
				break;

			case 403: //verboten
			case 401: //Unauthorised = likely CSRF = reload page
				this.shouldReloadPage = true;
				this.userError = 'Session has expired, please try again';
				break;

			case 409: // conflict: someone else edited it
				//todo:
				this.conflict = true;
				this.userError = 'Your changes could not be saved as another user was editing this community member at the same time. Please try again.';
				break;

			case 422: //Unprocessable entity = validation errors
				this.validationErrors = JSON.parse(client.response || null);
				break;



			case 0: //timeout
				this.timedout = true;
			//fallthrough to default:
			default:
				// unhandled
				this.userError = 'There was a problem communicating with Pushpay. We\'re currently looking into it. Please try again soon';
				break;
		}
	}
}

export function post<T>(url: string, data: any, timeout = 20000): ICancellablePromise<T> {
	return new CancellablePromise<T>((resolve, reject, onCancel) => {
		var client = new XMLHttpRequest();
		client.onload = () => {
			if (client.status === 200) {
				resolve(JSON.parse(client.response || null, AjaxUtils.reviveDate));
			} else {
				reject(new PostError(client, url));
			}
		};

		var requestUrl = Util.combineWithLocationPathname(url);

		client.open('POST', requestUrl);

		client.onerror = () => reject(new PostError(client, requestUrl));
		client.ontimeout = () => reject(new PostError(client, requestUrl));

		onCancel(() => client.abort());

		client.timeout = timeout; //must be set after open in some browsers
		client.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
		client.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

		client.send(AjaxUtils.dictionaryToUrlEncodedFormString(data));
	});
}
