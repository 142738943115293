import * as React from 'react';
import { observable, reaction } from 'mobx';
import { observer } from 'mobx-react';
import { EditorSlidingPanel } from './components/editor-sliding-panel/editor-sliding-panel';

// ...
import { BrandingSettingsMainViewModel, BrandingPackageState, PublishState } from './branding-settings-main-view-model';
import { BrandingSettingsUserAction } from './saga-actions/actions';
import { BrandingSettingsForm } from './editor-form/branding-settings-form';
import { BrandingSettingPublishButton } from './components/publish-button/publish-button';
import { DiscardDraftButton } from './components/discard-button/discard-button';
import { ListingSelectorWithLabel } from '../components/listing-selector';
import { CampusSelectorWithLabel } from '../components/campus-selector';
import { BreakpointMaxWidth, makeResponsiveHelper, ResponsiveHelper, responsiveHelper } from '../helpers/responsive-helper';
import { LoadingSpinner } from './components/loading-spinner/loading-spinner';
import { RealTimePreview } from './components/real-time-preview/real-time-preview';
import { classNames } from '../../Shared/utils/classnames';

import * as styles from './branding-settings-main.less';
import { PreviewToggle } from './components/preview-toggle/preview-toggle';
import { BrandingSettingPreviewButton } from './components/preview-button/preview-button';
import { BrandingSettingHeader } from './components/header/header';
import { LabelType, LabelItem } from '../components/label/labels';

interface IBrandingSettingsMainProps {
	vm: BrandingSettingsMainViewModel;
	raiseAction(action: BrandingSettingsUserAction): void;
}

@observer
export class BrandingSettingsMain extends React.Component<IBrandingSettingsMainProps> {
	previewContainerRef: HTMLDivElement;

	iframeResponsiveHelper: ResponsiveHelper;

	iframeResponsiveReactionDisposer: () => void;

	@observable
	private isEditorPanelOpened: boolean = true;

	render() {
		return (responsiveHelper.isSm || responsiveHelper.isXs ? this.renderMobileEditor() : this.renderDesktopEditor());
	}

	UNSAFE_componentWillMount() {
		window.addEventListener('beforeunload', this.confirmNavigationIfUnsavedChanges);
	}

	componentWillUnmount() {
		window.removeEventListener('beforeunload', this.confirmNavigationIfUnsavedChanges);
	}

	renderListingSelector() {
		const {
			formViewModel: {
				hasReliableOrgAndCampusBranding
			}
		} = this.props.vm

		let options = this.props.vm.model.Listings.map(x => ({
			id: `${x.ListingId}`,
			listingName: x.ListingName
		}));

		if (hasReliableOrgAndCampusBranding) {
			options = this.props.vm.model.Listings.map(x => ({
				id: `${x.ListingId}`,
				listingName: x.ListingName,
				labels: this.getLabelsForListingOption(x.ListingId)
			}));
		}

		// NOTE: classname ReliableOrgAndCampusBranding_IsEnabled has been added on this element temporarily to support style changes on the custom-select.tsx component in a FF way

		return <ListingSelectorWithLabel
			componentId="merchant-listing-selector"
			acceptanceTestTargetId="merchant-listing-selector"
			className={classNames(styles.merchantListingSelector, hasReliableOrgAndCampusBranding ? "ReliableOrgAndCampusBranding_IsEnabled" : "")}
			onChange={this.handleListingChange}
			selectedListingId={`${this.props.vm.selectedListingId}`}
			availableListings={options}
			isUnavailable={this.props.vm.isProcessing} />;
	}

	renderCampusSelector() {
		const {
			model: { Campuses },
			selectedCampusKey,
			isProcessing
		} = this.props.vm

		const options = Campuses.map(x => ({ key: `${x.CampusKey}`, campusName: x.CampusName }));
		return <CampusSelectorWithLabel
			componentId="merchant-campus-selector"
			acceptanceTestTargetId="merchant-campus-selector"
			className={styles.merchantCampusSelector}
			onChange={this.handleCampusChange}
			selectedCampusKey={`${selectedCampusKey}`}
			availableCampus={options}
			isUnavailable={isProcessing} />;
	}

	private getLabelsForListingOption = (listingId: number): LabelItem[] => {
		let labels: LabelItem[] = [];

		if (listingId == this.props.vm.defaultOrgBrandingListingId) {
			labels.push({type: LabelType.Primary, text: "Org default"});
		}

		if (listingId == this.props.vm.defaultCampusBrandingListingId) {
			labels.push({type: LabelType.DefaultDark, text: "Campus default"});
		}

		return labels;
	}

	private confirmNavigationIfUnsavedChanges = (e) => {
		if (this.props.vm.isDirty && !this.props.vm.sessionExpired) {
			const dialogText = 'You have unsaved changes, are you sure you want to leave this page?';
			e.returnValue = dialogText;
			return dialogText;
		}
	}

	private savePackage = () => {
		this.props.raiseAction(new BrandingSettingsUserAction.SavePackage());
	}

	private publishDraft = () => {
		this.props.raiseAction(new BrandingSettingsUserAction.PublishDraft());
	}

	private displayPreview = () => {
		this.props.raiseAction(new BrandingSettingsUserAction.DisplayPreview());
	}

	private discardDraft = () => {
		this.props.raiseAction(new BrandingSettingsUserAction.DiscardDraft());
	}

	private handleListingChange = (listingId: string) => {
		this.props.raiseAction(new BrandingSettingsUserAction.ChangeListing(listingId));
	}

	private handleCampusChange = (campusKey: string) => {
		this.props.raiseAction(new BrandingSettingsUserAction.ChangeCampus(campusKey));
	}

	private renderDesktopEditor = () => {
		const {
			raiseAction,
			vm: {
				formViewModel,
				packageState,
				selectedListingId,
				model,
				showMobilePreview,
				publishState,
				organizationName,
				selectedCampusName,
				hasReliableOrgAndCampusBranding
			},
		} = this.props;
		const selectedListing = model.Listings.find(listing => `${listing.ListingId}` === selectedListingId);
		const selectedListingName = selectedListing && selectedListing.ListingName;

		return (
			<div>
				<BrandingSettingHeader givingPageUrl={formViewModel.givingPageUrl} setupGuideUrl={formViewModel.setupGuideUrl} />
				{
					hasReliableOrgAndCampusBranding && <div>{this.renderCampusSelector()}</div>
				}
				<div className={classNames('panel', 'panel-default', styles.mainContainer)}>
					<div className={classNames('panel-heading', styles.panelHeading)}>
						{this.renderListingSelector()}
						<div className={classNames('panel-body', styles.panelBodyContent)}>
							<div className={classNames(styles.previewContainer, !this.isEditorPanelOpened && styles.expanded)} ref={ref => this.previewContainerRef = ref}>
								{model.PreviewUrl &&
									<RealTimePreview
										vm={this.props.vm}
										responsiveHelperCallback={this.responsiveHelperCallback}
										responsiveReactionDisposer={this.iframeResponsiveReactionDisposer}
									/>}
							</div>
							<EditorSlidingPanel opened={this.isEditorPanelOpened} togglePanel={this.toggleEditorPanel} acceptanceTestTargetId="editor sliding panel">
								<div className={styles.editorContainer}>
									<div className={styles.editorHeading}>
										<span className={styles.editorHeadingContent}>Editor</span>
										<LoadingSpinner draftState={formViewModel.draftState} savePackage={this.savePackage} />
									</div>
									<div>
										<BrandingSettingsForm vm={formViewModel} selectedListingName={selectedListingName} selectedCampusName={selectedCampusName} currentOrgName={organizationName} raiseAction={raiseAction} />
									</div>
								</div>
							</EditorSlidingPanel>
						</div>
						<div className={styles.previewToggleContainer}>
							<PreviewToggle
								name="previewMode"
								showMobilePreview={showMobilePreview}
								onChangeHandler={this.handleLivePreviewToggleChange}
							/>
						</div>
						<div className={styles.btnContainer}>
							<DiscardDraftButton
								canDiscard={packageState === BrandingPackageState.ChangesAwaitingPublish}
								className={styles.discardDraft}
								discardDraft={this.discardDraft} />
							<BrandingSettingPublishButton
								publishState={publishState}
								onClick={this.publishDraft} />
						</div>
					</div>
				</div>
			</div>
		);
	}

	private renderMobileEditor = () => {
		const {
			vm: {
				formViewModel,
				packageState,
				selectedListingId,
				model,
				publishState,
				organizationName,
				selectedCampusName,
				hasReliableOrgAndCampusBranding
			},
			raiseAction } = this.props;
		const selectedListing = model.Listings.find(listing => `${listing.ListingId}` === selectedListingId);
		const selectedListingName = selectedListing && selectedListing.ListingName;

		return (
			<div className={styles.mainContainer}>
				<BrandingSettingHeader givingPageUrl={formViewModel.givingPageUrl} setupGuideUrl={formViewModel.setupGuideUrl} />
				{hasReliableOrgAndCampusBranding && this.renderCampusSelector()}
				<h2>Editor</h2>
				{this.renderListingSelector()}
				<BrandingSettingsForm vm={formViewModel} selectedListingName={selectedListingName} selectedCampusName={selectedCampusName} currentOrgName={organizationName} raiseAction={raiseAction} />
				<BrandingSettingPreviewButton
					onClick={this.displayPreview} />
				<BrandingSettingPublishButton
					publishState={publishState}
					onClick={this.publishDraft} />
				<DiscardDraftButton
					canDiscard={packageState === BrandingPackageState.ChangesAwaitingPublish}
					className={styles.discardDraft}
					discardDraft={this.discardDraft} />
			</div>
		);
	}

	private toggleEditorPanel = () => this.isEditorPanelOpened = !this.isEditorPanelOpened;

	private handleLivePreviewToggleChange = (event: React.FormEvent<HTMLInputElement>) => {
		const showMobilePreview = event.currentTarget.value === 'mobile';
		if (!showMobilePreview && this.previewContainerRef.clientWidth <= BreakpointMaxWidth.Xs && this.isEditorPanelOpened) {
			this.toggleEditorPanel();
		}
		this.savePackage();
		this.props.vm.handleMobilePreviewChange(showMobilePreview);
	}

	private responsiveHelperCallback = (contentWindow: Window) => {
		this.savePackage();
		this.iframeResponsiveHelper = makeResponsiveHelper(contentWindow);
		const { vm } = this.props;
		this.iframeResponsiveReactionDisposer = reaction(
			() => this.iframeResponsiveHelper.isXs,
			(isXs: boolean) =>  {
				if (isXs) {
					vm.showMobilePreview = true;
				}
			}
		);
	}
}
