import * as React from 'react';
import { observer } from 'mobx-react';
import { Button } from '../../../Shared/components/button/button';

interface IMemberFormFooterProps {
	onCancel: Function;
}

@observer
export class MemberFormFooter extends React.Component<IMemberFormFooterProps> {
	onCancelClick = () => {
		this.props.onCancel();
	}

	render() {
		return (
			<div className="panel-footer panel-footer-btn-group">
				<Button type="button" className="btn btn-cancel" onClick={this.onCancelClick}>Cancel</Button>
				<Button type="submit" className="btn btn-default">Save</Button>
			</div>
		);
	}
}
