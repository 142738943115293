import * as React from 'react';
import { observer } from 'mobx-react';
import { SimpleProgressBar } from './simple-progress-bar';

export interface IProgressIndicatorProps {
	totalStatements: number;
	percentComplete: number;
}

const MIN_DISPLAY_PERCENTAGE = 5;
export const ProgressIndicator: React.StatelessComponent<IProgressIndicatorProps> = observer((props) => {
	const { totalStatements, percentComplete } = props;
	const processedStatements = Math.round(totalStatements * percentComplete / 100);
	const shouldInitialProgressTextBeGreen = NewFeatures.InitialGenerateProgressTextIsGreen;
	let className = `text-center ${percentComplete ? 'text-success' : 'text-muted'}`;
	let message = 'We are gathering data to generate your giving statements.';

	if (shouldInitialProgressTextBeGreen) {
		className = `text-center text-success`;
	}

	if (processedStatements) {
		message = `Generated ${processedStatements} of ${totalStatements}.`;
	}

	// On render, setting the percent complete to start at 5% so it doesn't sit on 0 for too long.
	const displayPercentComplete =  Math.max(percentComplete, MIN_DISPLAY_PERCENTAGE);

	if (displayPercentComplete >= 100) {
		message = 'We are finalising your giving statements.';
	}

	return (
		<div>
			<SimpleProgressBar percentComplete={displayPercentComplete}/>
			<div className={className}>
				<em>
				<strong>{message}</strong>
				<br/>
				You can close this window and come back later if you wish.
				</em>
			</div>
		</div>
	);
});
