import moment from 'moment';
import { isObservable, toJS } from 'mobx';
import { post } from '../utils/ajax-client';
import { AjaxUtils } from '../utils/ajax-utils';
import { BrandingSettingsApiConfig, BrandingStatsRequest } from './branding-settings-generated';
import { ICancellablePromise, CancellablePromise } from '../utils/cancellable-promise';
import { HttpTimeoutError } from './saga-actions/errors/http-timeout-error';
import { getCancellablePromiseEffect } from '../utils/saga-utils';

export interface IApiAction<TRequest, TResponse = any> {
	readonly url: (model: TRequest) => string;
	request: TRequest;
	response: TResponse;
}

export type BrandingSettingsActionRequest<TActionKey extends keyof BrandingSettingsApiConfigActions> = BrandingSettingsApiConfigActions[TActionKey]['request'];
export type BrandingSettingsActionResponse<TActionKey extends keyof BrandingSettingsApiConfigActions> = BrandingSettingsApiConfigActions[TActionKey]['response'];

export type BrandingSettingsApiConfigActions = typeof BrandingSettingsApiConfig['actions'];

export type BrandingSettingsDataService = {
	[x in keyof BrandingSettingsApiConfigActions]: (request: BrandingSettingsActionRequest<x>) => IterableIterator<ICancellablePromise<BrandingSettingsActionResponse<x>>>;
} & { uploadImage: (request: { url: string, file: File }) => IterableIterator<ICancellablePromise<any>> };

const options = {
	requestTimeoutInMilliseconds: 20 * 1000,
	uploadTimeoutInMilliseconds: 4 * 60 * 1000
};

let instance: BrandingSettingsDataService | null = null;

function createActionHandler<TKey extends keyof BrandingSettingsApiConfigActions>(actionKey: TKey) {
	const actionConfig = BrandingSettingsApiConfig.actions[actionKey] as any;

	return (requestData: any) => {
		requestData = isObservable(requestData) ? toJS(requestData) : { ...requestData };
		const request = post(actionConfig.url(requestData), requestData, {
			timeout: options.requestTimeoutInMilliseconds,
			baseUrl: AjaxUtils.resolveBaseUrl(BrandingSettingsApiConfig.defaultBaseUrl)
		});

		return getCancellablePromiseEffect(request);
	};
}

function createBrandingSettingsDataService(): BrandingSettingsDataService {
	const dataService = (Object.keys(BrandingSettingsApiConfig.actions) as Array<keyof BrandingSettingsApiConfigActions>)
		.reduce<BrandingSettingsDataService>((acc: any, actionKey: keyof BrandingSettingsApiConfigActions) => {
			acc[actionKey] = createActionHandler(actionKey);
			return acc;
		}, {} as BrandingSettingsDataService);

	// simple image uploading to push files to s3
	dataService.uploadImage = ({ url, file }) => {
		const request = new CancellablePromise((resolve, reject, onCancel) => {
			const ajaxStateReady = 4;
			const httpOK = 200;
			var client = new XMLHttpRequest();
			client.onload = (ev) => {
				if (client.status === httpOK) {
					resolve();
				} else {
					reject(new Error(`HttpError: ${client.status}`));
				}
			};

			client.open('PUT', url);

			client.onerror = () => reject(new Error(client.responseText));
			client.ontimeout = () => reject(new HttpTimeoutError('Image upload failed. Please try using a smaller sized image.'));
			var startTime = moment();
			client.onreadystatechange = () => {
				if (client.readyState === ajaxStateReady && client.status === httpOK) {
					var duration = moment.duration(moment().diff(startTime));
					recordStats({
						'message': 'An image was uploaded.',
						'uploadUrl': ` ${url}`,
						'fileSize': `${file.size}`,
						'durationMilliseconds': `${duration.asMilliseconds()}`
					});
				}
			};
			onCancel(() => client.abort());

			client.timeout = options.uploadTimeoutInMilliseconds; //must be set after open in some browsers

			client.setRequestHeader('Content-Type', file.type);

			client.send(file);
		});

		return getCancellablePromiseEffect(request);
	};

	return dataService;
}

function recordStats(Data: { [key: string]: string }) {
	var service = getBrandingSettingsDataService();
	var timestamp = moment();
	let stats: BrandingStatsRequest = {
		Timestamp: timestamp.subtract(timestamp.utcOffset(), 'minutes').toDate(),
		Data: Data,
	};

	service.recordStats({ model: stats });
}

export function getBrandingSettingsDataService() {
	if (instance === null) {
		instance = createBrandingSettingsDataService();
	}

	return instance;
}

export function mockBrandingSettingsDataService(mockDataService: BrandingSettingsDataService) {
	instance = mockDataService;
}
