import { MachineConfig } from 'xstate/lib/types';
import { GivingStatementActions } from '../actions/main-actions';
import { MainEvents } from '../state/main';
import { DeleteStatementsStateMachine } from './delete-statements-states';

export enum SendPageStates {
	MAIN = 'Sending page main',
	POLL_FOR_SUMMARY_DATA = 'Poll for summary data',
	SEND_OR_SUMMARY_STATUS = 'Send or summary status',
	DELETE = 'Sending page delete',
	// Main
	LOADING = 'Loading send page data',
	LOAD_FAIL = 'Loading send page data failed',
	LOADED = 'Send page data loaded',
	IDLE = 'Idle send page state',
	EMAIL_MODAL_ACTIVE = 'Email modal ative',
	DOWNLOAD_ALL_MODAL_ACTIVE = 'Download all modal active',
	DOWNLOAD_NO_EMAIL_MODAL_ACTIVE = 'Download no email modal active',
	SAVING_AS_DRAFT = 'Saving as draft',
	NO_STATEMENTS = 'No statements',
	// Poll for summary data
	POLL_WAITING = 'Waiting for next send summary poll',
	POLL_ERROR = 'More than 3 consecutive errors polling for summary data',
	POLLING = 'Loading send summary data',
	// Send or summary status
	SEND_MODE = 'Send page in send mode',
	SUMMARY_MODE = 'Send page in summary mode',
	PUBLISHING_STATEMENTS = 'Publishing giving statements',
	SENDING_PREVIEW_EMAIL = 'Sending preview email',
}

export const LOADED_STATES: SendPageStates[] = [
	SendPageStates.IDLE,
	SendPageStates.EMAIL_MODAL_ACTIVE,
	SendPageStates.DOWNLOAD_ALL_MODAL_ACTIVE,
	SendPageStates.DOWNLOAD_NO_EMAIL_MODAL_ACTIVE,
	SendPageStates.SAVING_AS_DRAFT,
	SendPageStates.SENDING_PREVIEW_EMAIL,
];

export enum SendPageEvents {
	// Main
	LOAD_SUCCESS = 'Send page load success',
	LOAD_FAIL = 'Send page load failed',
	LOAD_NO_STATEMENTS = 'load no statements',
	SHOW_EMAIL_MODAL = 'Show email modal',
	DISMISS_EMAIL_MODAL = 'Dismiss email modal',
	SHOW_DOWNLOAD_ALL_MODAL = 'Show download all modal',
	SHOW_DOWNLOAD_NO_EMAIL_MODAL = 'Show download no email modal',
	DISMISS_DOWNLOAD_MODAL = 'Dismiss download modal',
	SAVE_AS_DRAFT = 'Save as draft',
	SAVE_AS_DRAFT_COMPLETE = 'Save as draft complete',
	// Poll for summary data
	START_LOADING_SUMMARY_DATA = 'Start loading summary data',
	FINISH_LOADING_SUMMARY_DATA = 'Finished loading summary data',
	// Send or summary status
	SWITCH_TO_SUMMARY_MODE = 'Switch send page to summary mode',
	SHOW_ERROR_MODAL = 'Show the polling error modal',
	DISMISS_ERROR_MODAL = 'Switch to list page',
	PUBLISH_STATEMENTS = 'Publish statements',
	PUBLISHING_STATEMENTS_COMPLETE = 'Statement publish complete',
	SEND_PREVIEW_EMAIL = 'Send preview email',
	SEND_PREVIEW_EMAIL_COMPLETE = 'Send preview email complete'
}

export const SendPageStateMachine = (): MachineConfig => ({
	initial: SendPageStates.LOADING,
	states: {
		[SendPageStates.LOADING]: {
			on: {
				[SendPageEvents.LOAD_SUCCESS]: SendPageStates.LOADED,
				[SendPageEvents.LOAD_FAIL]: SendPageStates.LOAD_FAIL,
				[SendPageEvents.LOAD_NO_STATEMENTS]: SendPageStates.NO_STATEMENTS,
			},
			onEntry: GivingStatementActions.LoadSendPageData,
		},
		[SendPageStates.NO_STATEMENTS]: {
			...DeleteStatementsStateMachine(),
		},
		[SendPageStates.LOAD_FAIL]: {},
		[SendPageStates.LOADED]: {
			parallel: true,
			states: {
				[SendPageStates.MAIN]: {
					initial: SendPageStates.IDLE,
					states: {
						[SendPageStates.IDLE]: {
							on: {
								[SendPageEvents.SHOW_EMAIL_MODAL]: SendPageStates.EMAIL_MODAL_ACTIVE,
								[SendPageEvents.SHOW_DOWNLOAD_ALL_MODAL]: {
									[SendPageStates.DOWNLOAD_ALL_MODAL_ACTIVE]: {
										actions: [GivingStatementActions.ShowDownloadModal]
									}
								},
								[SendPageEvents.SHOW_DOWNLOAD_NO_EMAIL_MODAL]: {
									[SendPageStates.DOWNLOAD_NO_EMAIL_MODAL_ACTIVE]: {
										actions: [GivingStatementActions.ShowDownloadModal]
									}
								},
								[SendPageEvents.SAVE_AS_DRAFT]: {
									[SendPageStates.SAVING_AS_DRAFT]: {
										actions: [GivingStatementActions.SaveAsDraft]
									}
								},
								[SendPageEvents.PUBLISH_STATEMENTS]: {
									[SendPageStates.PUBLISHING_STATEMENTS]: {
										actions: [GivingStatementActions.PublishStatements]
									}
								},
								[SendPageEvents.SEND_PREVIEW_EMAIL]: {
									[SendPageStates.SENDING_PREVIEW_EMAIL]: {
										actions: [GivingStatementActions.SendPreviewEmail]
									}
								},
							},
						},
						[SendPageStates.SAVING_AS_DRAFT]: {
							on: {
								[SendPageEvents.SAVE_AS_DRAFT_COMPLETE]: SendPageStates.IDLE,
							},
						},
						[SendPageStates.SENDING_PREVIEW_EMAIL]: {
							on: {
								[SendPageEvents.SEND_PREVIEW_EMAIL_COMPLETE]: SendPageStates.IDLE,
							},
						},
						[SendPageStates.PUBLISHING_STATEMENTS]: {
							on: {
								[SendPageEvents.PUBLISHING_STATEMENTS_COMPLETE]: SendPageStates.IDLE,
							},
						},
						[SendPageStates.EMAIL_MODAL_ACTIVE]: {
							on: {
								[SendPageEvents.DISMISS_EMAIL_MODAL]: SendPageStates.IDLE,
							},
							...SendEmailModalStateMachine(),
						},

						[SendPageStates.DOWNLOAD_ALL_MODAL_ACTIVE]: {
							on: {
								[SendPageEvents.DISMISS_DOWNLOAD_MODAL]: SendPageStates.IDLE,
							},
							...DownloadModalStateMachine(GivingStatementActions.LoadDownloadAllData),
						},
						[SendPageStates.DOWNLOAD_NO_EMAIL_MODAL_ACTIVE]: {
							on: {
								[SendPageEvents.DISMISS_DOWNLOAD_MODAL]: SendPageStates.IDLE,
							},
							...DownloadModalStateMachine(GivingStatementActions.LoadDownloadNoEmailData),
						}
					},
				},
				[SendPageStates.SEND_OR_SUMMARY_STATUS]: {
					initial: SendPageStates.SEND_MODE,
					states: {
						[SendPageStates.SEND_MODE]: {
							onEntry: GivingStatementActions.TriggerMoveToSummaryIfRequested,
							on: {
								[SendPageEvents.SWITCH_TO_SUMMARY_MODE]: SendPageStates.SUMMARY_MODE,
							},
						},
						[SendPageStates.SUMMARY_MODE]: {},
					},
				},
				[SendPageStates.POLL_FOR_SUMMARY_DATA]: {
					initial: SendPageStates.POLLING,
					onExit: GivingStatementActions.CancelSummaryDataPolling,
					states: {
						[SendPageStates.POLLING]: {
							onEntry: [GivingStatementActions.LoadSentSummaryData],
							on: {
								[SendPageEvents.FINISH_LOADING_SUMMARY_DATA]: SendPageStates.POLL_WAITING,
								[SendPageEvents.SHOW_ERROR_MODAL]: SendPageStates.POLL_ERROR,
							},
						},
						[SendPageStates.POLL_WAITING]: {
							onEntry: [GivingStatementActions.WaitForNextLoad],
							on: {
								[SendPageEvents.START_LOADING_SUMMARY_DATA]: SendPageStates.POLLING,
								[SendPageEvents.SHOW_ERROR_MODAL]: SendPageStates.POLL_ERROR,
							},
						},
						[SendPageStates.POLL_ERROR]: {
							onEntry: [GivingStatementActions.ShowErrorModal],
							...ErrorModalStateMachine(),
						},
					},
				},
				[SendPageStates.DELETE]: DeleteStatementsStateMachine(),
			},
		},
	},
});

export enum SendEmailModalStates {
	IDLE = 'Initial email modal state',
	SENDING = 'Sending emails',
	SHOW_EMAIL_SEND_SUCCESS = 'Show email send success',
	SHOW_EMAIL_SEND_FAILURE = 'Show email send failure',
}

export enum SendEmailModalEvents {
	CONFIRM_EMAIL_SEND = 'Confirm email send',
	EMAIL_SEND_SUCCESSFUL = 'Successfully sent emails',
	EMAIL_SEND_FAILED = 'Failed to send emails',
}

const SendEmailModalStateMachine = (): MachineConfig => ({
	initial: SendEmailModalStates.IDLE,
	states: {
		[SendEmailModalStates.IDLE]: {
			on: {
				[SendEmailModalEvents.CONFIRM_EMAIL_SEND]: {
					[SendEmailModalStates.SENDING]: {
						actions: [GivingStatementActions.SendEmails]
					}
				},
			},
		},
		[SendEmailModalStates.SENDING]: {
			on: {
				[SendEmailModalEvents.EMAIL_SEND_SUCCESSFUL]: SendEmailModalStates.SHOW_EMAIL_SEND_SUCCESS,
				[SendEmailModalEvents.EMAIL_SEND_FAILED]: {
					[SendEmailModalStates.SHOW_EMAIL_SEND_FAILURE]: {
						actions: [GivingStatementActions.SendEmailsFailed]
					}
				},
			}
		},
		[SendEmailModalStates.SHOW_EMAIL_SEND_SUCCESS]: {},
		[SendEmailModalStates.SHOW_EMAIL_SEND_FAILURE]: {},
	},
});

export enum DownloadModalStates {
	INITIAL = 'Initial download modal state',
	LOADING = 'Loading download modal data',
	SUCCESS = 'Successfully Loaded download modal data',
	FAIL = 'Failed to Load download modal data',
}

export enum DownloadModalEvents {
	LOAD = 'Load download data',
	LOAD_SUCCESS = 'Load download data success',
	LOAD_FAIL = 'Load download data failed',
}

const DownloadModalStateMachine = (loadActionKey: string): MachineConfig => ({
	key: 'Download Modal',
	initial: DownloadModalStates.INITIAL,
	states: {
		[DownloadModalStates.INITIAL]: {
			on: {
				[DownloadModalEvents.LOAD]: {
					[DownloadModalStates.LOADING]: {
						actions: [loadActionKey]
					}
				}
			},
		},
		[DownloadModalStates.LOADING]: {
			on: {
				[DownloadModalEvents.LOAD_SUCCESS]: DownloadModalStates.SUCCESS,
				[DownloadModalEvents.LOAD_FAIL]: DownloadModalStates.FAIL,
			}
		},
		[DownloadModalStates.SUCCESS]: {},
		[DownloadModalStates.FAIL]: {},
	},
});

export enum ErrorModalStates {
	VISIBLE = 'Error modal is visible',
	HIDDEN = 'Initial error modal data',
	DISMISSED = 'Error modal gone',
}

export enum ErrorModalEvents {
	SHOW = 'Show error modal',
	DISMISS = 'Dismiss error modal',
}

const ErrorModalStateMachine = (): MachineConfig => ({
	key: 'Error Modal',
	initial: ErrorModalStates.HIDDEN,
	states: {
		[ErrorModalStates.HIDDEN]: {
			on: {
				[ErrorModalEvents.SHOW]: ErrorModalStates.VISIBLE,
			},
		},
		[ErrorModalStates.VISIBLE]: {
			on: {
				[ErrorModalEvents.DISMISS]: MainEvents.LOAD_LANDING_VIEW,
			}
		},
	},
});
