import * as React from 'react';
import { observable, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import { machineContextKey } from '../../../../Shared/state-machine/saga-state-machine';
import { createCampaignCardMachineContext } from '../campaign-card/state-machine/campaign-card-machine-context';
import { retryCountdown$ } from '../../pages/campaigns-landing/campaigns-landing-intent';
import { SvgWrapper } from '../../../../Shared/components/svg-wrapper';
import { subscriber, subscribe, unsubscribeAll } from '../../../../Shared/decorators/subscriber';

import * as styles from './campaign-card-error-notification.less';
import { classNames } from '../../../../Shared/utils/classnames';

@inject(machineContextKey)
@subscriber
@observer
export class CampaignCardErrorNotification extends React.Component<{
	retry: () => void, pauseCountdown?: boolean, machineContext?: ReturnType<typeof createCampaignCardMachineContext>
}> {

	@observable
	private retryRemainingSeconds: number = 0;

	@subscribe(retryCountdown$)
	@action.bound
	onRetryCountdown(remaining: number) {
		if (remaining > 0) {
			this.retryRemainingSeconds = remaining;
		} else {
			this.props.retry();
		}
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.onClickAway);
		this.unsubscribe();
	}

	componentDidMount() {
		document.addEventListener('click', this.onClickAway);

		if (this.props.pauseCountdown) {
			this.unsubscribe();
		}
	}


	render() {
		return (
			<div className={styles.container} data-pp-at-target="campaign card error notification">
				<SvgWrapper svg="alert-exclamation-icon" className={styles.icon} />
				<div className={styles.content}>
					<h6 className={styles.title}>
						{
							`Our system encountered an unexpected error.
Will try again in ${this.retryRemainingSeconds} secs`
						}
					</h6>
					<button className={classNames('btn btn-link', styles.button)} onClick={this.onClickRetry} data-pp-at-target="campaign card error notification retry">Retry now</button>
					<button className={classNames('btn btn-link', styles.button)} onClick={this.onClose} data-pp-at-target="campaign card error notification close">Close</button>
				</div>
			</div>
		);
	}

	private onClickRetry = (e) => {
		this.props.retry();
	}

	private onClose = (e) => {
		this.props.machineContext.eventCreator.dismissCampaignError({});
	}

	private onClickAway = (e) => {
		if (!(e.target as HTMLElement).closest(`.${styles.container}`)) {
			this.props.machineContext.eventCreator.dismissCampaignError({});
		}
	}

	@unsubscribeAll
	private unsubscribe() { }
}
