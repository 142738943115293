import { ModalDialogCommander } from '../components/modal-dialog-commander';
import {
	ActionKey,
	combineMiddleware,
	DataService,
	DataServiceAction,
	DataServiceOptions,
	IDataService,
	isActionForService
} from '../utils/data-service';
import { ScheduleImportApiConfig } from './schedule-import-generated';

export type ScheduleImportApiConfigType = typeof ScheduleImportApiConfig;
export type ScheduleImportDataService = IDataService<ScheduleImportApiConfigType>;
export type ScheduleImportDataServiceAction = DataServiceAction<ScheduleImportApiConfigType, any>;

export function isScheduleImportAction<TActionKey extends ActionKey<ScheduleImportApiConfigType>>(action: ScheduleImportDataServiceAction, key: TActionKey):
	action is DataServiceAction<ScheduleImportApiConfigType, TActionKey> {
	return isActionForService(action, key);
}

function reportUnexpectedError(error, action: ScheduleImportDataServiceAction) {
	window.reportUnhandledRejection(error, { action });
}

let scheduleImportDataService: ScheduleImportDataService = null;

function createScheduleImportDataService(options?: DataServiceOptions<ScheduleImportApiConfigType>): ScheduleImportDataService {
	options = options || {};

	options.middleware = combineMiddleware(next => action => {
		switch (action.type) {
			case 'request_init':
				console.log(`Schedule import request initiated`, action);
				break;
			case 'request_success':
				console.log(`Schedule import request completed`, action);
				break;
			case 'request_error':
				const { error } = action;

				if (error.shouldReloadPage || error.timedout) {
					ModalDialogCommander.error(error.userError, '').done(() => {
						document.location.reload(true);
					});
					return;
				}

				console.log(`Schedule import request failed`, action);
				break;
			case 'request_cancel':
				console.log(`Schedule import request cancelled`, action);
				break;
			case 'unexpected_error':
				reportUnexpectedError(action.error, action);
				ModalDialogCommander.showStandardErrorMessage();
				break;
		}

		return next(action);
	}, options.middleware);

	return new DataService(ScheduleImportApiConfig, options);
}

export function getScheduleImportDataService(): ScheduleImportDataService {
	if (!scheduleImportDataService) {
		scheduleImportDataService = createScheduleImportDataService();
	}

	return scheduleImportDataService;
}

export function mockScheduleImportDataService(mockDataService: ScheduleImportDataService) {
	scheduleImportDataService = mockDataService;
}
