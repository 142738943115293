import * as React from 'react';
import { observer } from 'mobx-react';
import * as styles from './styles.less';
import { ModelMetadata } from '../../../funds/funds-generated';
import { ValidationMessage, SelectField } from '../../../funds/components/form-controls';
import { CcbMembershipTypeConfigurationModel, CcbMembershipTypeModel } from 'integrations/components/ccb-membership-type-configuration-model';
import { classNames } from '../../../../Shared/utils/classnames';

export interface IDefaultOrganizationTypeConfigurationProps {
	model: CcbMembershipTypeConfigurationModel;
}

@observer
export class DefaultOrganizationTypeConfiguration extends React.Component<IDefaultOrganizationTypeConfigurationProps, {}> {
	render() {
		const { model: {membershipTypes, defaultCcbOrganizationalTypeId} } = this.props;
		const { DefaultCcbOrganizationalTypeId } = ModelMetadata.CcbMembershipTypeConfigurationEditModel;

		return <div className={classNames('panel-body', styles.defaultTypeConfigContainer)}>
			<div className={classNames('control-label', 'col-md-3', styles.defaultTypeConfigLabelWrapper)}>
				<label>Select default organization</label>
			</div>
			<div className={classNames('col-md-4', styles.communityMemberTypeSelectWrapper)}>
				<SelectField
					className={classNames({[styles.communityMemberTypeSelectUnselected]: defaultCcbOrganizationalTypeId === null})}
					propertyMetadata={DefaultCcbOrganizationalTypeId}
					value={defaultCcbOrganizationalTypeId !== null ? defaultCcbOrganizationalTypeId : ''}
					onChange={this.defaultCcbMemberTypeChanged}>
					<option value="">- Select CCB organization -</option>
					{membershipTypes.map(this.renderDefaultOrganizationalTypeOption)}
				</SelectField>
				<ValidationMessage for={DefaultCcbOrganizationalTypeId.propertyName} />
			</div>
		</div>;
	}

	renderDefaultOrganizationalTypeOption = (memberType: CcbMembershipTypeModel) => {
		return <option disabled={!memberType.isOrganizationalType} key={memberType.membershipTypeId} value={memberType.membershipTypeId}>{memberType.membershipTypeName}</option>;
	}

	defaultCcbMemberTypeChanged = (event :React.FormEvent<HTMLSelectElement>) => {
		const value = event.currentTarget.value;
		this.props.model.updateDefaultCcbOrganizationalTypeId(value);
	}
}
