export function rewriteUrlAppendBatchIdAndUpdateBrowserHistory(batchId: number) {
	const url = ensureTrailingSlash(window.location.pathname)
		+ batchId
		+ window.location.search;
	updateHistory(url);
}

export function rewriteUrlRemoveBatchIdAndUpdateBrowserHistory() {
	const url = window.location.pathname.replace(/^(.*batch)([\/\d]*)$/, '$1')
		+ window.location.search;
		updateHistory(url);
}

function ensureTrailingSlash(url: string) {
	return url.replace(/\/?$/, '/');
}

function updateHistory(url: string) {
	window.history.pushState(window.history.state, '', url);
}
