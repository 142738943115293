import * as React from 'react';
import { FormState } from 'formstate/lib/core/formState';
import { isFunction } from '../../utils/is-function';

export interface IFormProps {
	formState: FormState<any>;
	onSubmit?: (validationResult: FormValidationResult) => void;
	className?: string;
	disabled?: boolean;
}

export type FormValidationResult = { hasError: true } | { hasError: false, value: any };

export class Form extends React.Component<IFormProps> {
	render() {
		const { className, children, disabled } = this.props;
		return (
			<form data-nojqueryvalidate noValidate className={className} onSubmit={this.onSubmit}>
				<fieldset disabled={disabled}>
					{children}
				</fieldset>
			</form>
		);
	}

	onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		const { onSubmit, formState } = this.props;

		event.preventDefault();

		const result = await formState.validate();
		if (result.hasError) {
			formState.enableAutoValidation();
		}

		if (isFunction(onSubmit)) {
			onSubmit(result);
		}
	}
}
