import { ModalDialogCommander } from '../components/modal-dialog-commander';
import {
	ActionKey,
	combineMiddleware,
	DataService,
	DataServiceAction,
	DataServiceOptions,
	IDataService,
	isActionForService
} from '../utils/data-service';
import { Models } from './check-deposit-generated';

export type CheckDepositApiConfig = typeof Models.CheckDepositApiConfig;
export type CheckDepositDataService = IDataService<CheckDepositApiConfig>;
export type CheckDepositDataServiceAction = DataServiceAction<CheckDepositApiConfig, any>;

export function isCheckDepositAction<TActionKey extends ActionKey<CheckDepositApiConfig>>(action: CheckDepositDataServiceAction, key: TActionKey):
	action is DataServiceAction<CheckDepositApiConfig, TActionKey> {
	return isActionForService(action, key);
}

function reportUnexpectedError(error, action: CheckDepositDataServiceAction) {
	window.reportUnhandledRejection(error, { action });
}

let checkDepositDataService: CheckDepositDataService = null;

function createCheckDepositDataService(options?: DataServiceOptions<CheckDepositApiConfig>): CheckDepositDataService {
	options = options || {};

	options.middleware = combineMiddleware(next => action => {
		switch (action.type) {
			case 'request_init':
				console.log(`Check deposit request initiated`, action);
				break;
			case 'request_success':
				console.log(`Check deposit request completed`, action);
				break;
			case 'request_error':
				const { error } = action;

				if (error.shouldReloadPage || error.timedout) {
					ModalDialogCommander.error(error.userError, '').done(() => {
						document.location.reload(true);
					});
					return;
				}

				console.log(`Check deposit request failed`, action);
				break;
			case 'request_cancel':
				console.log(`Check deposit request cancelled`, action);
				break;
			case 'unexpected_error':
				reportUnexpectedError(action.error, action);
				ModalDialogCommander.showStandardErrorMessage();
				break;
		}

		return next(action);
	}, options.middleware);

	return new DataService(Models.CheckDepositApiConfig, options);
}

export function getCheckDepositDataService(): CheckDepositDataService {
	if (!checkDepositDataService) {
		checkDepositDataService = createCheckDepositDataService({timeout: 120_000});
	}

	return checkDepositDataService;
}

export function mockCheckDepositDataService(mockDataService: CheckDepositDataService) {
	checkDepositDataService = mockDataService;
}
