

// ReSharper disable InconsistentNaming
// ReSharper disable StringLiteralWrongQuotes
/* tslint:disable:no-use-before-declare quotemark max-line-length */
import { IApiAction } from '../utils/data-service';
export interface ImageDetailsViewModel {
	PublicUrl: string;
	ImageKey: string;
	ImageKind: ImageKind;
	ImageStatus: ImageStatus;
	ValidationFailures: ValidationFailure[];
}
export interface ValidationFailure {
	FailureCode: string;
	FailureParameters: { [key: string]: string };
}
export enum ImageStatus {
	None = 0, NotFound = 1, Processing = 2, Processed = 3, Invalid = 4,
}
export enum ImageKind {
	None = 0, HeroImage = 1, EnhancedLogo = 2, Favicon = 3, BackdropImage = 4, BackdropMobileImage = 5,
}
export interface UploadImageViewModel {
	ImageKind: ImageKind;
	UploadUrl: string;
	ImageKey: string;
}
export interface BrandingPackageEditModel {
	EnhancedLogoImageKey: string;
	FaviconImageKey: string;
	BackdropImageKey: string;
	BackdropMobileImageKey: string;
	GivingTitle: string;
	HeroImageKey: string;
	PrimaryColor: string;
	SecondaryColor: string;
	hasOrganizationBrandingMerchantChanged: boolean;
	IsOrgBrandingMerchant: boolean;
	hasCampusBrandingMerchantChanged: boolean;
	IsCampusBrandingMerchant: boolean;
}
export interface BrandingPackageViewModel {
	EnhancedLogoImageKey: string;
	EnhancedLogoImageUrl: string;
	FaviconImageKey: string;
	FaviconImageUrl: string;
	GivingTitle: string;
	HeroImageKey: string;
	HeroImageUrl: string;
	BackdropImageKey: string;
	BackdropImageUrl: string;
	BackdropMobileImageKey: string;
	BackdropMobileImageUrl: string;
	PrimaryColor: string;
	SecondaryColor: string;
	DraftKey: string;
	ETag: string;
	GivingPageUrl: string;
	SetupGuideUrl: string;
	IsCampusBrandingMerchant: boolean;
	IsOrgBrandingMerchant: boolean;
}
export interface ListingDetailsViewModel {
	ListingId: number;
	BrandingPackage: BrandingPackageViewModel;
	MerchantInfo: WebGivingMerchant;
	IsOrgBrandingMerchant: boolean;
	IsCampusBrandingMerchant: boolean;
}
export interface CampusDetailsViewModel {
	SelectedListing: ListingDetailsViewModel;
	Listings: ListingViewModel[];
	CampusKey: string;
	CampusName: string;
	DefaultCampusBrandingListingId?: number;
	DefaultOrgBrandingListingId?: number;
	CanConfigureOrgDefaultBranding: boolean;
}
export interface WebGivingMerchant {
	Handle: string;
	TradingName: string;
	LegalName: string;
	MerchantId: number;
	MerchantVersion: number;
	IsHppEnabled: boolean;
	TermsAndConditions: string;
	PrivacyPolicy: string;
	Address: string;
	Country: Country;
	SupportsAch: boolean;
	SupportsPayerCoversFees: boolean;
	PayerCoversFeesDefault: boolean;
	TransactionFeeRate: number;
	PercentageOfPaymentAddedToCoverFees: number;
	SupportedCardBrandKeys: string[];
	CardPreference: MerchantCardPreference;
	CustomFields: ICustomFieldViewModel[];
	PaymentLabel: PaymentLabel;
	PaymentMin: number;
	PaymentMax: number;
	AchMax: number;
	LogoUrl: string;
	PaymentMethodPreference: PaymentMethodType;
	PayersMustBeVerified: boolean;
	PayerSpecifiedRecurringEndDateEnabled: boolean;
	WebsiteUrl: string;
	Currency: Currency;
	MobileAppName: string;
	GetAppUrl: string;
	HasCustomApp: boolean;
	WebGivingUrl: string;
	HasCustomLogo: boolean;
	BrandingEnabled: boolean;
	RecurringByDefault: boolean;
}
export enum Currency {
	NZD = 0, AUD = 1, USD = 2, CAD = 3, Unspecified = -1,
}
export enum PaymentMethodType {
	Unknown = 0, CreditCard = 1, NzBankAccount = 2, ACH = 3, Cash = 4, RecordedCheck = 5, AchCheck = 6, ProfitStarsScannedCheck = 7, RecordedCreditCard = 8, RecordedACH = 9, ImportedUnspecified = 10, ImportedUnsupported = 11,
}
export interface PaymentLabel {
	NounSentenceCase: string;
	NounPluralSentenceCase: string;
	VerbSentenceCase: string;
	VerbAppliedSentenceCase: string;
	NounLowerCase: string;
	NounPluralLowerCase: string;
	VerbLowerCase: string;
	VerbAppliedLowerCase: string;
	VerbPresentTenseSentenceCase: string;
	VerbPresentTenseLowerCase: string;
	VerbPastTenseSentenceCase: string;
	VerbPastTenseLowerCase: string;
	PayerSentenceCase: string;
	PayerLowerCase: string;
	PayerPluralSentenceCase: string;
	PayerPluralLowerCase: string;
	ActionSentenceCase: string;
	ActionLowerCase: string;
	ActionPluralSentenceCase: string;
	ActionPluralLowerCase: string;
	TextGivingFeatureName: string;
	GiftEntryFeatureName: string;
}
export enum MerchantCardPreference {
	AnyCard = 0, DebitPreferred = 1, DebitOnly = 2,
}
export enum Country {
	NZ = 0, AU = 1, US = 2, AF = 4, AL = 8, AQ = 10, DZ = 12, AS = 16, AD = 20, AO = 24, AG = 28, AZ = 31, AR = 32, AT = 40, BS = 44, BH = 48, BD = 50, AM = 51, BB = 52, BE = 56, BM = 60, BT = 64, BO = 68,
	BA = 70, BW = 72, BV = 74, BR = 76, BZ = 84, IO = 86, SB = 90, VG = 92, BN = 96, BG = 100, MM = 104, BI = 108, BY = 112, KH = 116, CM = 120, CA = 124, CV = 132, KY = 136, CF = 140, LK = 144, TD = 148, CL = 152,
	CN = 156, TW = 158, CX = 162, CC = 166, CO = 170, KM = 174, YT = 175, CG = 178, CD = 180, CK = 184, CR = 188, HR = 191, CU = 192, CY = 196, CZ = 203, BJ = 204, DK = 208, DM = 212, DO = 214, EC = 218, SV = 222,
	GQ = 226, ET = 231, ER = 232, EE = 233, FO = 234, FK = 238, GS = 239, FJ = 242, FI = 246, AX = 248, FR = 250, GF = 254, PF = 258, TF = 260, DJ = 262, GA = 266, GE = 268, GM = 270, PS = 275, DE = 276, GH = 288,
	GI = 292, KI = 296, GR = 300, GL = 304, GD = 308, GP = 312, GU = 316, GT = 320, GN = 324, GY = 328, HT = 332, HM = 334, VA = 336, HN = 340, HK = 344, HU = 348, IS = 352, IN = 356, ID = 360, IR = 364, IQ = 368,
	IE = 372, IL = 376, IT = 380, CI = 384, JM = 388, JP = 392, KZ = 398, JO = 400, KE = 404, KP = 408, KR = 410, KW = 414, KG = 417, LA = 418, LB = 422, LS = 426, LV = 428, LR = 430, LY = 434, LI = 438, LT = 440,
	LU = 442, MO = 446, MG = 450, MW = 454, MY = 458, MV = 462, ML = 466, MT = 470, MQ = 474, MR = 478, MU = 480, MX = 484, MC = 492, MN = 496, MD = 498, ME = 499, MS = 500, MA = 504, MZ = 508, OM = 512, NA = 516,
	NR = 520, NP = 524, NL = 528, CW = 531, AW = 533, SX = 534, BQ = 535, NC = 540, VU = 548, NI = 558, NE = 562, NG = 566, NU = 570, NF = 574, NO = 578, MP = 580, UM = 581, FM = 583, MH = 584, PW = 585, PK = 586,
	PA = 591, PG = 598, PY = 600, PE = 604, PH = 608, PN = 612, PL = 616, PT = 620, GW = 624, TL = 626, PR = 630, QA = 634, RE = 638, RO = 642, RU = 643, RW = 646, BL = 652, SH = 654, KN = 659, AI = 660, LC = 662,
	MF = 663, PM = 666, VC = 670, SM = 674, ST = 678, SA = 682, SN = 686, RS = 688, SC = 690, SL = 694, SG = 702, SK = 703, VN = 704, SI = 705, SO = 706, ZA = 710, ZW = 716, ES = 724, SS = 728, SD = 729, EH = 732,
	SR = 740, SJ = 744, SZ = 748, SE = 752, CH = 756, SY = 760, TJ = 762, TH = 764, TG = 768, TK = 772, TO = 776, TT = 780, AE = 784, TN = 788, TR = 792, TM = 795, TC = 796, TV = 798, UG = 800, UA = 804, MK = 807,
	EG = 818, GB = 826, GG = 831, JE = 832, IM = 833, TZ = 834, VI = 850, BF = 854, UY = 858, UZ = 860, VE = 862, WF = 876, WS = 882, YE = 887, ZM = 894, XK = 999, __ = -2, Unsupported = -1,
}
export interface BrandingStatsRequest {
	Data: { [key: string]: string };
	Timestamp: Date;
}
export interface BrandingSettingsViewModel {
	SelectedListing: ListingDetailsViewModel;
	Listings: ListingViewModel[];
	SelectedCampus: CampusDetailsViewModel;
	Campuses: CampusViewModel[];
	PreviewUrl: string;
	OrganizationName: string;
	HasReliableOrgAndCampusBranding: boolean;
	SupportsBackdropImages: boolean;
}
export interface CampusViewModel {
	CampusKey: number;
	CampusName: string;
}
export interface ListingViewModel {
	ListingId: number;
	ListingName: string;
}
export interface FundCustomFieldViewModel {
	Key: string;
	Label: string;
	Placeholder: string;
	IsRequired: boolean;
	Type: CustomFieldType.Fund;
	DefaultOptionValue: string;
	Options: FundCustomFieldOptionViewModel[];
	Order: number;
}
export interface FundCustomFieldOptionViewModel {
	Label: string;
	Value: string;
	ForListing: boolean;
}
export enum CustomFieldType {
	Unknown = 0, Text = 1, Number = 2, DropDown = 3, Fund = 4,
}
export interface DropDownCustomFieldViewModel {
	Key: string;
	Label: string;
	Placeholder: string;
	IsRequired: boolean;
	Type: CustomFieldType.DropDown;
	DefaultOptionValue: string;
	Options: DropDownCustomFieldOptionViewModel[];
	Order: number;
}
export interface DropDownCustomFieldOptionViewModel {
	Label: string;
	Value: string;
}
export interface NumberCustomFieldViewModel {
	Key: string;
	Label: string;
	Placeholder: string;
	IsRequired: boolean;
	Type: CustomFieldType.Number;
	MinLength: number;
	MaxLength: number;
	Order: number;
}
export interface TextCustomFieldViewModel {
	Key: string;
	Label: string;
	Placeholder: string;
	IsRequired: boolean;
	Type: CustomFieldType.Text;
	MinLength: number;
	MaxLength: number;
	Validation: TextFieldValidation;
	Order: number;
}
export enum TextFieldValidation {
	NoValidation = 0, LettersOnly = 1,
}
export type ICustomFieldViewModel = TextCustomFieldViewModel | NumberCustomFieldViewModel | DropDownCustomFieldViewModel | FundCustomFieldViewModel;
export const BrandingSettingsApiConfig = {
	defaultBaseUrl: () => location.pathname.match(new RegExp('/pushpay/portal/\\d+', 'i'))[0],
	actions: {
		recordStats: <IApiAction<{model: BrandingStatsRequest}, string>>{
			url: (data) => `/settings/branding/ajax/RecordStats`,
		},
		getListingDetails: <IApiAction<{merchantId: number}, ListingDetailsViewModel>>{
			url: (data) => `/settings/branding/ajax/${data.merchantId}/listingDetails`,
		},
		getCampusDetails: <IApiAction<{ campusKey: string }, CampusDetailsViewModel>>{
			url: (data) => `/settings/branding/ajax/${data.campusKey}/campusDetails`,
		},
		savePackage: <IApiAction<{merchantId: number, model: BrandingPackageEditModel, eTag: string}, BrandingPackageViewModel>>{
			url: (data) => `/settings/branding/ajax/${data.merchantId}/save`,
		},
		discardDraftPackage: <IApiAction<{merchantId: number}, BrandingPackageViewModel>>{
			url: (data) => `/settings/branding/ajax/${data.merchantId}/discard`,
		},
		createAssetUploadUrl: <IApiAction<{merchantId: number, imageKind: ImageKind}, UploadImageViewModel>>{
			url: (data) => `/settings/branding/ajax/${data.merchantId}/createUploadUrl/${data.imageKind}`,
		},
		getImageDetails: <IApiAction<{merchantId: number, imageKey: string}, ImageDetailsViewModel>>{
			url: (data) => `/settings/branding/ajax/${data.merchantId}/imageDetails/${data.imageKey}`,
		},
		publishPackage: <IApiAction<{merchantId: number, draftKey: string, eTag: string}, BrandingPackageViewModel>>{
			url: (data) => `/settings/branding/ajax/${data.merchantId}/publish/${data.draftKey}`,
		},
	},
	urls: {
	},
};
/* tslint:enable:no-use-before-declare quotemark max-line-length */
// ReSharper restore InconsistentNaming
// ReSharper restore StringLiteralWrongQuotes
// ReSharper disable InconsistentNaming
// ReSharper disable StringLiteralWrongQuotes
/* tslint:disable:no-use-before-declare quotemark max-line-length */
export namespace ModelMetadata {
	export class MetadataArrayOf<T> {
		metadata: T;

		constructor(metadata: T) {
			this.metadata = metadata;
		}

		at(index: number): T {
			return this.metadata;
		}
	}

	export interface IPropertyMetadata {
		propertyName: string;
		displayName?: string;
		placeholder?: string;
		validationRules?: { [rule: string]: any };
		modelMetadata?: { [name: string] :IPropertyMetadata};
	}

	export let BrandingPackageEditModel = {
		EnhancedLogoImageKey: {
			propertyName: "EnhancedLogoImageKey",
			validationRules: {
				maxlength: {
					errorMessage: "The field EnhancedLogoImageKey must be a string or array type with a maximum length of '200'.",
					parameters: {
						max: 200,
					},
				},
			},
		},
		FaviconImageKey: {
			propertyName: "FaviconImageKey",
			validationRules: {
				maxlength: {
					errorMessage: "The field FaviconImageKey must be a string or array type with a maximum length of '200'.",
					parameters: {
						max: 200,
					},
				},
			},
		},
		BackdropImageKey: {
			propertyName: "BackdropImageKey",
			validationRules: {
				maxlength: {
					errorMessage: "The field BackdropImageKey must be a string or array type with a maximum length of '200'.",
					parameters: {
						max: 200,
					},
				},
			},
		},
		BackdropMobileImageKey: {
			propertyName: "BackdropMobileImageKey",
			validationRules: {
				maxlength: {
					errorMessage: "The field BackdropMobileImageKey must be a string or array type with a maximum length of '200'.",
					parameters: {
						max: 200,
					},
				},
			},
		},
		GivingTitle: {
			propertyName: "GivingTitle",
			validationRules: {
				maxlength: {
					errorMessage: "The field GivingTitle must be a string or array type with a maximum length of '200'.",
					parameters: {
						max: 200,
					},
				},
			},
		},
		HeroImageKey: {
			propertyName: "HeroImageKey",
			validationRules: {
				maxlength: {
					errorMessage: "The field HeroImageKey must be a string or array type with a maximum length of '200'.",
					parameters: {
						max: 200,
					},
				},
			},
		},
		PrimaryColor: {
			propertyName: "PrimaryColor",
			validationRules: {
				maxlength: {
					errorMessage: "The field PrimaryColor must be a string or array type with a maximum length of '200'.",
					parameters: {
						max: 200,
					},
				},
			},
		},
		SecondaryColor: {
			propertyName: "SecondaryColor",
			validationRules: {
				maxlength: {
					errorMessage: "The field SecondaryColor must be a string or array type with a maximum length of '200'.",
					parameters: {
						max: 200,
					},
				},
			},
		},
		hasOrganizationBrandingMerchantChanged: {
			propertyName: "hasOrganizationBrandingMerchantChanged",
			validationRules: {
				required: {
					errorMessage: "The hasOrganizationBrandingMerchantChanged field is required.",
				},
			},
		},
		IsOrgBrandingMerchant: {
			propertyName: "IsOrgBrandingMerchant",
			validationRules: {
				required: {
					errorMessage: "The IsOrgBrandingMerchant field is required.",
				},
			},
		},
		hasCampusBrandingMerchantChanged: {
			propertyName: "hasCampusBrandingMerchantChanged",
			validationRules: {
				required: {
					errorMessage: "The hasCampusBrandingMerchantChanged field is required.",
				},
			},
		},
		IsCampusBrandingMerchant: {
			propertyName: "IsCampusBrandingMerchant",
			validationRules: {
				required: {
					errorMessage: "The IsCampusBrandingMerchant field is required.",
				},
			},
		},
	};
}
/* tslint:enable:no-use-before-declare quotemark max-line-length */
// ReSharper restore InconsistentNaming
// ReSharper restore StringLiteralWrongQuotes
