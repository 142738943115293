import * as React from 'react';
import * as styles from './styles.less';

export interface IProgressCountProps {
	displayCount: number;
	units: string;
}

export class ProgressCount extends React.Component<IProgressCountProps> {
	render() {
		const { displayCount, units } = this.props;
		return (
			<div className={styles.progressCount}>
				<div className={styles.progressCountNumber}>{displayCount}</div>
				<div className={styles.progressCountUnits}>{units}</div>
			</div>
		);
	}
}
