import * as React from 'react';
import { EntryDashboardMainViewModel } from './entry-dashboard-main-view-model';
import { Tile, ITileProps } from '../../../components/tile/tile';
import { checkNounPluralLowerCase } from '../../utils/check-translator';
import { Fragment } from '../../../../Shared/components/fragment';
import { Formatter } from '../../../helpers/formatter';

import * as styles from './entry-dashboard-main.less';

export const TileCount = (props: { count: number, text: string }) => {
	const countFormatted = Formatter.formatNumberForDisplay(props.count, 0);

	return (
		<div className={styles.tileCount}>
			<div className={styles.tileCountTotal} title={countFormatted}>{countFormatted}</div>
			<div className="text-muted">{props.text}</div>
		</div>
	);
};

export class EntryDashboardMain extends React.Component<{ vm: EntryDashboardMainViewModel }> {
	render() {
		const {
			HomeCountry,
			PaymentLabel,
			SingleEntryUrl,
			BatchEntryUrl,
			BatchListUrl,
			CheckDepositUrl,
			HelpCenterUrl,
			RecentSingleEntriesCount,
			BatchesCount,
			ShowCheckDepositTile,
			HasQuickBooks,
			SupportsNonCashGifts
		} = this.props.vm.model;
		const orgHasRecentSingleEntries: boolean = RecentSingleEntriesCount > 0;
		const orgHasBatches: boolean = BatchesCount > 0;

		const showQuickbooksTiles = HasQuickBooks;
		const showNonCashGiftsAnnouncement = SupportsNonCashGifts;
		const wrapperClassName = `col-sm-${ShowCheckDepositTile ? '4' : '6'} ${styles.tile}`;

		const singleEntryTile: IEntryDashboardTileViewModel = {
			wrapperClassName,
			buttonText: `Enter ${PaymentLabel.NounPluralLowerCase}`,
			content: `Create one time or recurring ${PaymentLabel.NounPluralLowerCase} on behalf of your ${PaymentLabel.PayerPluralLowerCase}
				through the all new ${PaymentLabel.GiftEntryFeatureName}.`,
			url: SingleEntryUrl,
			graphic: orgHasRecentSingleEntries
				? <TileCount
					count={RecentSingleEntriesCount}
					text={`${RecentSingleEntriesCount === 1 ? PaymentLabel.NounSentenceCase : PaymentLabel.NounPluralSentenceCase} in the last 30 days`} />
				: 'tile-single-entry',
			title: 'Single Entry',
			ribbonText: showNonCashGiftsAnnouncement && `Now includes ${PaymentLabel.NonCashNounLowerCase} entry`
		};
		const batchEntryTile: IEntryDashboardTileViewModel = {
			wrapperClassName,
			url: orgHasBatches ? BatchListUrl : BatchEntryUrl,
			graphic: orgHasBatches
				? <TileCount count={BatchesCount} text={`${BatchesCount === 1 ? 'Batch' : 'Batches'} in total`} />
				: 'tile-batch-entry',
			title: 'Batch Entry',
			content: `Create batches to enter and group multiple ${PaymentLabel.NounPluralLowerCase} together. Track and manage the batches in this section.`,
			buttonText: orgHasBatches ? 'View all batches' : 'Create batch',
			ribbonText: showQuickbooksTiles ? 'Works with QuickBooks\xAE Online' : null
		};
		const checkDepositTile: IEntryDashboardTileViewModel = {
			wrapperClassName,
			url: CheckDepositUrl,
			graphic: 'tile-check-deposit',
			title: 'Check Deposit',
			content: `Get all your ${PaymentLabel.VerbPresentTenseLowerCase} in one place. Scan and process ${checkNounPluralLowerCase(HomeCountry)} with Pushpay with 5 simple steps.`,
			buttonText: 'Get started',
			ribbonText: showQuickbooksTiles ? 'Works with QuickBooks Online' : null
		};

		return (
			<Fragment>
				<h1>{PaymentLabel.GiftEntryFeatureName}</h1>
				<p className="lead">
					{PaymentLabel.GiftEntryFeatureName} is the new way to record and process all {PaymentLabel.NounPluralLowerCase} in one place.
				</p>
				{showQuickbooksTiles
					? <div className={`row equal-height-columns ${styles.tiles}`}>
						<EntryDashboardTile vm={batchEntryTile} />
						{ShowCheckDepositTile && <EntryDashboardTile vm={checkDepositTile} />}
						<EntryDashboardTile vm={singleEntryTile} />
					</div>
					: <div className={`row equal-height-columns ${styles.tiles}`}>
						<EntryDashboardTile vm={singleEntryTile} />
						<EntryDashboardTile vm={batchEntryTile} />
						{ShowCheckDepositTile && <EntryDashboardTile vm={checkDepositTile} />}
					</div>
				}
				<footer className={styles.helpCenter}>
					{/*tslint:disable max-line-length*/}
					Learn more about {PaymentLabel.GiftEntryFeatureName} in Pushpay <a className="arrow-hollow-right-after" href={HelpCenterUrl} target="_blank" rel="noopener noreferrer">Help Center</a>
					{/*tslint:enable max-line-length*/}
				</footer>
			</Fragment>
		);
	}
}

class EntryDashboardTile extends React.Component<{ vm: IEntryDashboardTileViewModel }> {
	render() {
		const { wrapperClassName, ...tileProps } = this.props.vm;
		return (<div className={wrapperClassName}>
			<Tile {...tileProps} />
		</div>);
	}
}

interface IEntryDashboardTileViewModel extends ITileProps {
	wrapperClassName: string;
}
