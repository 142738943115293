import * as React from 'react';
import { ResourceCenterStep } from '../../loggedinweb-generated';
import { SvgWrapper } from '../../components/svg-wrapper';
import * as styles from '../resource-center-dashboard.less';

interface StepPillarProps {
	step: ResourceCenterStep;
}

export const StepPillar = ({ step: { StepNumber, Title, Url, IsComplete, ImageFileName } }: StepPillarProps) => (
	<a className={styles.stepPillar} href={Url}>
		<div className={styles.stepIconContainer}>
			<img
				className={styles.stepIcon}
				src={`/Content/LoggedInWeb/assets/images/pillars/${ImageFileName}.png`}
				alt=""
			/>
			{IsComplete && (
				<SvgWrapper title="Step completed" svg="step-complete" className={`svg ${styles.stepComplete}`} />
			)}
		</div>
		<div className={styles.stepContent}>
			<p className={styles.heading}>{'Step ' + StepNumber}</p>
			<p className={styles.title}>
				{Title}
				<span className="arrow-hollow-right-after" />
			</p>
		</div>
	</a>
);
