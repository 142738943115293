import * as React from 'react';
import { createNamespace } from '../../../../../Shared/helpers/namespace';
import { GivingFrequencySelectorWithLabel } from '../../../../components/giving-frequency-selector';
import {
	frequencyCodeReadable,
	testFrequencyCodeReadable,
	frequencyWithAnnualCodeReadable,
	frequencyWithSemiAnnualCodeReadable,
	frequencyWithQuarterlyCodeReadable,
} from '../../../constants';
import * as styles from './payment-entry-recurring-edit.less';

const ns = createNamespace('vt-form-payment-details-recurring-edit');

function getScheduleFrequencyOptions(frequencies: { [f: number]: string }) {
	return Object.keys(frequencies).map((k) => {
		return {
			Label: frequencies[k],
			Value: k,
		};
	});
}

interface PaymentEntryRecurringEditState {
	selectedFrequencyValue: string;
}

export class PaymentEntryRecurringEdit extends React.Component<{ vm: any }, {}> {
	state: PaymentEntryRecurringEditState = {
		selectedFrequencyValue: `${this.props.vm.FrequencyCode}`,
	};

	handleRecurringFrequencyChange = (frequencyCode: string) => {
		this.setState({ ...this.state, selectedFrequencyValue: frequencyCode });
	};

	render() {
		const {
			HasAnnualRecurringFrequencyCodeOption,
			HasQuarterlyRecurringFrequencyCodeOption,
			HasSemiAnnualRecurringFrequencyCodeOption,
		} = this.props.vm;

		let scheduleFrequencies = getScheduleFrequencyOptions(frequencyCodeReadable);

		if (HasQuarterlyRecurringFrequencyCodeOption) {
			scheduleFrequencies = scheduleFrequencies.concat(
				getScheduleFrequencyOptions(frequencyWithQuarterlyCodeReadable)
			);
		}

		if (HasSemiAnnualRecurringFrequencyCodeOption) {
			scheduleFrequencies = scheduleFrequencies.concat(
				getScheduleFrequencyOptions(frequencyWithSemiAnnualCodeReadable)
			);
		}

		if (HasAnnualRecurringFrequencyCodeOption) {
			scheduleFrequencies = scheduleFrequencies.concat(
				getScheduleFrequencyOptions(frequencyWithAnnualCodeReadable)
			);
		}

		if (NewFeatures.TesterCommandsEnabled) {
			scheduleFrequencies = getScheduleFrequencyOptions(testFrequencyCodeReadable).concat(scheduleFrequencies);
		}

		return (
			<div className={`${ns()}`}>
				<div className={styles.frequencySelector}>
					<GivingFrequencySelectorWithLabel
						singleLine
						acceptanceTestTargetId={'RecurringFrequencySelector'}
						labelForGivingFrequencySelection={'How often?'}
						selectedFrequencyValue={this.state.selectedFrequencyValue}
						availableFrequencies={scheduleFrequencies}
						onChange={this.handleRecurringFrequencyChange}
					/>
					{/* hidden input allows capture of selectedFrequencyValue by Razor form */}
					<input
						name="ScheduleFrequency.FrequencyCode"
						type="hidden"
						value={this.state.selectedFrequencyValue}
					/>
				</div>
			</div>
		);
	}
}
