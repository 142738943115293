import * as React from 'react';
import { Button } from '../../../../Shared/components/button/button';
import * as styles from './styles.less';
import { inject, observer } from 'mobx-react';
import { MachineContext, machineContextKey } from '../../../../Shared/state-machine/saga-state-machine';
import { SendEmailModalEvents, SendEmailModalStates, SendPageEvents, SendPageStates } from '../../state/send-page';
import { MatchModal } from '../../../components/match-modal';
import { Match, MatchAny } from '../../../../Shared/state-machine/match';
import { SvgWrapper } from '../../../../Shared/components/svg-wrapper';
import { AnimatedSuccessTick } from '../../../components/animated-svg/success-tick';

export interface ISendEmailConfirmationModalProps {
	communityMemberCount: number;
	statementTitle: string;
	canDownload: boolean;
	machineContext?: MachineContext;
}

@inject(machineContextKey)
@observer
export class SendEmailConfirmationModal extends React.Component<ISendEmailConfirmationModalProps> {
	render() {
		const { communityMemberCount, statementTitle, canDownload } = this.props;

		return (
			<MatchModal state={SendPageStates.EMAIL_MODAL_ACTIVE}>
				<div className="modal-content">
					<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.onDismissClick} />
					<div className="modal-body">
						<MatchAny states={[SendEmailModalStates.IDLE, SendEmailModalStates.SENDING]}>
							<div className={`modal-text ${styles.modalText}`}>
								You are about to email <strong>{communityMemberCount}</strong> community members their statements. Are you sure
								you want to do this?
							</div>
						</MatchAny>
						<Match state={SendEmailModalStates.SHOW_EMAIL_SEND_FAILURE}>
							<div className="modal-text">
								<SvgWrapper svg="alert-exclamation-icon" width={68} height={68} />
								<h2>Error</h2>
								<p>Something has gone wrong. Please reload the page and try again.</p>
							</div>

						</Match>
						<Match state={SendEmailModalStates.SHOW_EMAIL_SEND_SUCCESS}>
							<div className="modal-text">
								<AnimatedSuccessTick />
								<h2>Success!</h2>
								<p>You have emailed <strong>{communityMemberCount}</strong> community members their {statementTitle}.</p>
								{canDownload &&
									<p>
										You may also download your remaining statements to send to community members without
										email addresses.
									</p>
								}
							</div>
						</Match>
					</div>
					<div className="modal-footer">
						<Match state={SendEmailModalStates.IDLE}>
							<Button type="button" className="btn btn-ghost" onClick={this.onDismissClick}>Cancel</Button>
							<Button type="submit" className="btn btn-default" onClick={this.onConfirmClick}>Confirm</Button>
						</Match>
						<Match state={SendEmailModalStates.SENDING}>
							<Button type="button" className="btn btn-ghost" onClick={this.onDismissClick}>Cancel</Button>
							<Button type="submit" className="btn btn-default" isLoading={true}>Confirm</Button>
						</Match>
						<Match state={SendEmailModalStates.SHOW_EMAIL_SEND_FAILURE}>
							<Button type="button" className="btn btn-default" onClick={this.onDismissClick}>Close</Button>
						</Match>
						<Match state={SendEmailModalStates.SHOW_EMAIL_SEND_SUCCESS}>
							<Button type="submit" className="btn btn-default" onClick={this.onDismissClick}>Continue</Button>
						</Match>
					</div>
				</div>
			</MatchModal>
		);
	}

	onDismissClick = () => {
		this.props.machineContext.transition(SendPageEvents.DISMISS_EMAIL_MODAL);
		this.props.machineContext.transition(SendPageEvents.START_LOADING_SUMMARY_DATA);
		this.props.machineContext.transition(SendPageEvents.SWITCH_TO_SUMMARY_MODE);
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}

	onConfirmClick = () => {
		this.props.machineContext.transition(SendEmailModalEvents.CONFIRM_EMAIL_SEND);
	}
}
