import { Country } from '../../loggedinweb-generated';

export function checkNounLowerCase(country: Country) {
	return countryUsesEnglishEnglish(country) ? 'cheque' : 'check';
}

export function checkNounTitleCase(country: Country) {
	return countryUsesEnglishEnglish(country) ? 'Cheque' : 'Check';
}

export function checkNounPluralLowerCase(country: Country) {
	return checkNounLowerCase(country) + 's';
}

export function checkNounPluralTitleCase(country: Country) {
	return checkNounTitleCase(country) + 's';
}

function countryUsesEnglishEnglish(country: Country) {
	return country === Country.AU || country === Country.CA || country === Country.NZ;
}
