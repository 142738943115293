import * as React from 'react';
import { ModelMetadata } from '../../funds/funds-generated';
import { when } from 'mobx';
import { observer } from 'mobx-react';
import { Autocomplete, AutocompleteStore } from '../../components/autocomplete';
import { ListingModel } from './listing-model';
import { ValidationMessage } from '../../funds/components/form-controls';

export interface IFallbackFundSelector {
	listing: ListingModel<any>;
	propertyMetadata: ModelMetadata.IPropertyMetadata;
	defaultValue: string;
	floatingLabelText?: string;
}

@observer
export class FallbackFundSelector extends React.Component<IFallbackFundSelector, {}> {
	private store: AutocompleteStore;

	constructor(props) {
		super(props);

		this.store = new AutocompleteStore({
			Items: this.props.listing.funds,
			SelectedValue: this.props.defaultValue
		});

		when(() => this.store.selectedItem !== null && this.store.selectedItem !== undefined, this.props.listing.resetValidationError);
	}

	render() {
		const showCustomValidationError = !!this.props.listing.validationError;
		const floatingLabelText = this.props.floatingLabelText || "Select fallback fund";
		const emptyText = `- ${floatingLabelText} -`;

		return (
			<div className={`form-group ${showCustomValidationError ? 'has-error' : ''}`}>
				<Autocomplete autocompleteStore={this.store}
					emptyText={emptyText}
					floatingLabelText={floatingLabelText}
					fieldName={this.props.propertyMetadata.propertyName}
					validationRules={this.props.propertyMetadata.validationRules}
					useVirtualized={true} />
				<span className="field-validation-error custom-validation-error">{this.props.listing.validationError}</span>
				<ValidationMessage for={this.props.propertyMetadata.propertyName} />
			</div>
		);
	}
}
