import { action, observable, runInAction } from 'mobx';
import { Models } from '../check-deposit-generated';
import * as CheckDepositClient from './registered-scanners-ajax-client';


export class DeleteRegisteredScannerDialogViewModel {
	@observable isOpen: boolean;
	@observable request: { scannerId: number };
	@observable isProcessingRequest: boolean;
	@observable failedToDelete: boolean;

	onScannerDeleted: (response: Models.RegisteredScannerViewModel[]) => void;

	private deleteScannerEndpoint: string;

	constructor(store: Models.DeleteRegisteredScannerDialogViewModel, onScannerDeleted: (scanners: Models.RegisteredScannerViewModel[]) => void) {
		this.isOpen = false;
		this.request = {
			scannerId: 0
		};
		this.isProcessingRequest = false;
		this.onScannerDeleted = onScannerDeleted;
		this.deleteScannerEndpoint = store.DeleteRegisteredScannerEndpoint;
	}

	@action
	openDialog = (scannerId: number) => {
		this.request.scannerId = scannerId;
		this.isOpen = true;
	}

	@action
	closeDialog = () => {
		if (!this.isProcessingRequest) {
			this.isOpen = false;
		}
	}

	@action
	onSubmitted = () => {
		this.isProcessingRequest = true;
		this.failedToDelete = false;

		CheckDepositClient.deleteScanner(this.deleteScannerEndpoint, this.request.scannerId)
			.then(response => {
				runInAction('DeleteRegisteredScanner.handleSubmit[onFulfilled]', () => {
					this.isProcessingRequest = false;
					this.onScannerDeleted(response.RegisteredScanners);
					this.closeDialog();
				});
			})
			.catch(error => {
				this.isProcessingRequest = false;
				this.failedToDelete = true;
			});
	}
}
