import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { AjaxUtils } from '../utils/ajax-utils';
import { NodeListHelper } from './node-list-helper';

type ComponentType<TProps> = React.ComponentClass<TProps> | React.SFC<TProps>;

interface  IComponent<TProps> {
	name: string;
	component: ComponentType<TProps>;
	modelDataToPropsConverter?: (data: any) => TProps;
}

const dataAttr = 'data-pushpay-component';

export class ComponentLoader {
	private componentsToLoad: IComponent<any>[] = [];

	registerComponent<TProps>(name: string,
									component: ComponentType<TProps>,
									modelDataToPropsConverter?: (data: any) => TProps) {
		this.componentsToLoad.push({
			name,
			component,
			modelDataToPropsConverter
		});
	}

	init() {
		NodeListHelper.toArray<HTMLElement>(document.querySelectorAll(`[${dataAttr}]`))
			.forEach(element => {
				const componentName = element.getAttribute(dataAttr);
				const data = this.componentsToLoad.find(x => x.name === componentName);

				if(data) {
					let props: any;
					const script = element.querySelector('script');
					props = script && script.textContent;

					if(props) {
						props = JSON.parse(props, AjaxUtils.reviveDate);
						if(data.modelDataToPropsConverter) {
							props = data.modelDataToPropsConverter(props);
						}
					}

					const component = React.createElement(data.component, props);
					ReactDOM.render(component, element);
				}
			});

	}
}
