import * as React from 'react';
import { RegisteredScannersViewModel } from './registered-scanners-viewmodel';
import { RegisteredScannerViewModel } from './registered-scanner-viewmodel';
import { RegisterScannerComponent } from './register-scanner-dialog';
import { DeleteRegisteredScannerDialog } from './delete-registered-scanner-dialog';
import { ModalDialogController } from '../../components/modal-dialog-controller';
import { AddNewRegisteredScannerLink } from './add-new-registered-scanner-link';
import { SvgWrapper } from '../../components/svg-wrapper';
import { TipBox } from '../../components/tipbox';
import { observer } from 'mobx-react';

const noRegisteredScannersComponent = (onRegisterScannerClick: (event: React.MouseEvent<HTMLAnchorElement>) => void) => {
	return (
		<div>
			<h3>You don't have any check scanners yet.</h3>

			<TipBox icon="cd-check" storageKey="whyRegister" dismissable={false}>
				<div className="tipbox-header">
					Why register check scanners?
				</div>
				<p className="cd-tip-box-content">
					You need to have a check scanner registered to be able to scan and process checks. Registering ensures all checks are coming from a known source.
				</p>
				<p className="cd-tip-box-content">
					To <a href="" onClick={onRegisterScannerClick}>add a new check scanner</a>,
					you just need the manufacturer and model name, and the device serial number.
				</p>
			</TipBox>
		</div>
	);
};

const scannerListComponent = (scanners: RegisteredScannerViewModel[], onDeleteScannerClick: (event: React.MouseEvent<HTMLAnchorElement>, scannerId: number) => void) => {
	const scannerComponents = scanners.map(scanner => (
		<div className="list-group-item" key={scanner.Id}>
			<div className="list-group-left">
				<div>
					<span className="list-group-item-large">{scanner.Model}</span>
				</div>
				<div>
					<span className="list-group-item-light"><strong>Serial Number: </strong> {scanner.SerialNumber}</span>
				</div>
			</div>
			<div className="list-group-right color-gray">
				<a className="btn btn-link btn-sm btn-vertically-centered" onClick={e => onDeleteScannerClick(e, scanner.Id)}>
					<SvgWrapper className="svg icon" svg="icon-delete" />
					Delete
				</a>
			</div>
		</div>)
	);

	return (
		<div className="list-group">
			{scannerComponents}
		</div>
	);
};

@observer
export class RegisteredScannersView extends React.Component<{ store: RegisteredScannersViewModel }> {

	render() {
		const {
			scanners,
			registerScannerDialogViewModel,
			deleteRegisteredScannerDialogViewModel,
			shouldEagerOpenRegisterScannerDialog
		} = this.props.store;

		const body = scanners.length === 0
			? noRegisteredScannersComponent(e => this.registerScannerClicked(e))
			: scannerListComponent(scanners, (e, id) => this.deleteScannerClicked(e, id));

		const isRegisterScannerDialogVisible = registerScannerDialogViewModel.isOpen || shouldEagerOpenRegisterScannerDialog;

		return (
			<div>
				<div className="page-head">
					<header>
						<h1>
							Check scanners

							<span className="header-buttons">
								<AddNewRegisteredScannerLink onClick={this.registerScannerClicked} />
							</span>
						</h1>
					</header>
				</div>
				{body}

				<ModalDialogController isVisible={isRegisterScannerDialogVisible}>
					<RegisterScannerComponent store={registerScannerDialogViewModel} />
				</ModalDialogController>

				<ModalDialogController isVisible={deleteRegisteredScannerDialogViewModel.isOpen}>
					<DeleteRegisteredScannerDialog store={deleteRegisteredScannerDialogViewModel} />
				</ModalDialogController>
			</div>
		);
	}

	registerScannerClicked = (event: React.MouseEvent<HTMLAnchorElement>) => {
		event.preventDefault();

		this.props.store.registerScannerDialogViewModel.openDialog();
	}

	deleteScannerClicked = (event: React.MouseEvent<HTMLAnchorElement>, scannerId: number) => {
		event.preventDefault();

		this.props.store.deleteRegisteredScannerDialogViewModel.openDialog(scannerId);
	}
}
