import { TooltipOld } from '../components/tooltip-old';
import { BrowserSupportHelper } from '../helpers/browsersupporthelper';
import { querySelector } from './login-utils';

export class LoginTooltip {
	private tooltip: TooltipOld;

	private mobilePopup: HTMLElement;
	private isPopupEnabled: boolean;

	constructor(element: HTMLElement) {
		if (BrowserSupportHelper.isTouchSupported()) {
			this.mobilePopup = document.querySelector(element.getAttribute('data-mobile-popup')) as HTMLElement;
			this.isPopupEnabled = true;

			element.addEventListener('click', (e) => this.showMobilePopup());

			const dismissElements = querySelector<HTMLElement>('.close, .mobile-popup-background', this.mobilePopup);
			dismissElements.forEach(x => x.addEventListener('click', (e) => this.hideMobilePopup()));
		} else {
			this.tooltip = new TooltipOld(element);
		}
	}

	showMobilePopup() {
		if (this.isPopupEnabled) {
			this.mobilePopup.style.display = 'block';
		}
	}

	hideMobilePopup() {
		this.mobilePopup.style.display = 'none';
	}

	disable() {
		if (BrowserSupportHelper.isTouchSupported()) {
			this.isPopupEnabled = false;
		} else {
			this.tooltip.disable();
		}
	}

	enable() {
		if (BrowserSupportHelper.isTouchSupported()) {
			this.isPopupEnabled = true;
		} else {
			this.tooltip.enable();
		}
	}
}
