import * as React from 'react';
import * as styles from './styles.less';
import { Fragment } from '../../../Shared/components/fragment';

export class StepStatusStep<T> {
	key: T;
	label?: string;
}

export interface IStepStatusIndicatorProps<T> {
	steps: StepStatusStep<T>[];
	currentStep: T;
	/**
	 * Will hide steps if passed in and currentStep === completedStep
	 */
	completedStep?: T;
}

export class StepStatusIndicator extends React.Component<IStepStatusIndicatorProps<any>> {
	render() {
		const { steps, currentStep, completedStep } = this.props;

		// Optionally don't render if steps are completed
		return completedStep && completedStep === currentStep ? null : (
			<div className={styles.container}>
				<div className={styles.steps}>
					{steps.map((step, index) => this.renderStep(step, index))}
				</div>
			</div>
		);
	}

	renderStep(step: StepStatusStep<any>, index: number) {
		const { currentStep } = this.props;
		const circleClasses = [styles.stepCircle];
		const labelClasses = [styles.stepLabel];

		if(step.key === currentStep) {
			circleClasses.push(styles.stepCircleCurrent);
			labelClasses.push(styles.stepLabelCurrent);
		}
		return (
			<Fragment key={step.key}>
				{index > 0 ? <div className={styles.stepSeparator} /> : null }
				<div className={styles.step}>
					<div className={circleClasses.join(' ')}>{step.key}</div>
					{step.label ? (
						<div className={labelClasses.join(' ')}>
							<div className={styles.stepLabelText}>
								{step.label}
							</div>
						</div>
						) : null }
				</div>
			</Fragment>
		);
	}
}
