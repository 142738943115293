import * as React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { Checkbox } from '../../../../Shared/forms/primitives/checkbox';
import { Button } from '../../../../Shared/components/button/button';
import { classNames } from '../../../../Shared/utils/classnames';

import * as styles from './campaign-card-popup.less';

@observer
export class DeleteActionPopupContent extends React.Component<{ onCancel: () => void, onConfirm: () => void }> {

	@observable
	private isConfirmed: boolean = false;

	render() {
		return (
			<div className={styles.deleteActionContent}>
				<h5>Are you sure you want to delete this draft?</h5>
				<Checkbox
					label={'Yes, delete this draft'}
					checked={this.isConfirmed}
					onChange={this.onChange}
					acceptanceTestTargetId={'delete checkbox'}
					className={styles.actionCheckbox}
				/>
				<div className={styles.warning}>
					Checking this option and clicking 'Delete' will completely remove this draft from your account. Please note:
					<strong> this process cannot be undone.</strong>
				</div>

				<Button type={'button'} className={classNames('btn', 'btn-primary', styles.button)} onClick={this.onConfirm} disabled={!this.isConfirmed}
					acceptanceTestTargetId="confirm delete button">
					Delete the draft
				</Button>
				<span className={styles.text}>{' or'}</span>
				<Button type={'button'} className={classNames('btn-link', styles.button)} onClick={this.onCancel}>cancel</Button>
			</div>
		);
	}

	@action.bound
	private onChange(val: boolean) {
		this.isConfirmed = val;
	}

	@action.bound
	private onConfirm(e) {
		e.stopPropagation();
		if (this.isConfirmed) {
			this.props.onConfirm();
		}
	}

	private onCancel = e => {
		e.stopPropagation();
		this.props.onCancel();
	}
}
