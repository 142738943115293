export const hex2rgb = function (hex) {
	var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result ? {
		r: parseInt(result[1], 16),
		g: parseInt(result[2], 16),
		b: parseInt(result[3], 16)
	} : null;
};

export const calculateLuminance = function (hexColor) {
	var rgbColor = hex2rgb(hexColor);
	if (rgbColor === null) {
		return -1;
	}
	var r = rgbColor.r, g = rgbColor.g, b = rgbColor.b;
	var a = [r, g, b].map(function (v) {
		v /= 255;
		return (v <= 0.03928) ?
			v / 12.92 :
			Math.pow(((v + 0.055) / 1.055), 2.4);
	});
	return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
};

export const calculateContrast = function (a, b) {
	const aL = calculateLuminance(a) + 0.05;
	const bL = calculateLuminance(b) + 0.05;
	return Math.max(aL / bL, bL / aL);
};

