import * as React from 'react';
import { classNames } from '../../../Shared/utils/classnames';
import { FormControlLabelled, FormControlType } from '../../components/form-controls/form-control-labelled';
import { injectValidationFormContext, IHaveValidatableFormContext } from '../../components/form-controls/validatable-form-context';
import { observer } from 'mobx-react';
import {SvgWrapper} from '../../components/svg-wrapper';
import { isFunction } from '../../utils/is-function';
import * as styles from './import-file-upload.less';

export interface IImportFileUploadProps extends IHaveValidatableFormContext {
	label: string;
	name: string;
	required?: boolean;
	tooltip?: string;
	onFileChange: (file: File) => void;
}

enum FileDropResult {
	Ok,
	TooManyFiles,
	NoFileSelected,
}

interface IImportFileUploadState {
	fileName?: string;
	dropResult: FileDropResult;
	dragOver: boolean;
}

@injectValidationFormContext
@observer
export class ImportFileUpload extends React.Component<IImportFileUploadProps, IImportFileUploadState> {
	files: FileList;
	dragCount: number = 0;
	fileInputHidden: HTMLInputElement;

	constructor(props: IImportFileUploadProps) {
		super(props);
		this.state = {
			fileName: null,
			dropResult: this.props.required ? FileDropResult.NoFileSelected : FileDropResult.Ok,
			dragOver: false,
		};
	}

	render() {
		const { label, name, tooltip } = this.props;
		const { fileName, dropResult, dragOver } = this.state;
		const validationRules = this.validate(dropResult);

		const tooltipOptions = tooltip !== null ? {message: tooltip} : null;

		const dragOverClassName = dragOver ? 'form-control-dragover' : null;

		return (
			<div className="row import-file-upload"
				onDrop={this.handleDrop}
				onDragOver={this.handleDragOver}
				onDragEnter={this.handleDrag}
				onDragLeave={this.handleDrag}>
				<div className="col-md-4">
					<div className="form-group">
						<FormControlLabelled
							cssClassNames={dragOverClassName}
							label={label}
							tooltipOptions={tooltipOptions}
							formControlProps={{
								formControlType: FormControlType.Text,
								type: 'text',
								name: name,
								placeholder: 'Drop or select a file',
								value: fileName,
								validationRules: validationRules,
								onMouseUpHandler: this.handleInputMouseUp,
								readonly: true
							}} />
					</div>
				</div>
				<div className={classNames('col-md-8', 'form-inline', styles.formControlWithoutLabel)}>
					<div className="form-group">
						<label className="btn btn-ghost">
							Select file
							<input key={fileName /* For some browsers (IE <= 10), the only way to clear a file input
													is to remove/add it from the dom. So we are abusing React's key
													attribute to force a fresh input element when the fileName changes. */}
								type="file"
								multiple={false}
								className="hidden"
								onChange={this.handleFileChange}
								ref={input => this.fileInputHidden = input}/>
						</label>
						{fileName && <button type="button" className="btn btn-link btn-xs" aria-lable="delete" onClick={this.handleDelete}>
							<SvgWrapper className="icon" svg="icon-delete" />
							Delete
						</button>}
					</div>
				</div>
			</div>
		);
	}

	componentDidUpdate(prevProps: IImportFileUploadProps, prevState: IImportFileUploadState) {
		if (prevState.dropResult === this.state.dropResult) {
			return;
		}

		this.props.validatableFormContext.validateElement(this.props.name);
	}

	validate(invalidDrop: FileDropResult) {
		switch (invalidDrop) {
			case (FileDropResult.TooManyFiles):
				return {nevervalid:{errorMessage:'Only drop one file at a time'}};
			case (FileDropResult.NoFileSelected):
				return {required: {errorMessage: 'Please select a file'}};
			case (FileDropResult.Ok):
				return null;
		}
	}

	setFiles = (files: FileList) => {
		var dropResult = FileDropResult.Ok;
		if (!files) {
			dropResult = this.props.required ? FileDropResult.NoFileSelected : FileDropResult.Ok;
			this.files = null;
		} else if (files.length !== 1) {
			dropResult = FileDropResult.TooManyFiles;
			this.files = null;
		} else {
			this.files = files;
		}

		this.dragCount = 0;
		const fileName = this.files && this.files[0] && this.files[0].name;
		this.setState(ps => ({...ps, fileName, dropResult, dragOver: false}));

		const { onFileChange } = this.props;
		if (fileName && isFunction(onFileChange)) {
			onFileChange(this.files[0]);
		}
	}

	handleInputMouseUp = (ev: React.MouseEvent<HTMLInputElement>) => {
		this.fileInputHidden.click();
	}

	handleFileChange = (ev: React.FormEvent<any>) => {
		const input = ev.target as HTMLFormElement;
		this.setFiles(input.files);
	}

	handleDrop = (ev: React.DragEvent<any>) => {
		ev.preventDefault();
		this.setFiles(ev.dataTransfer.files);
	}

	handleDragOver = (ev: React.DragEvent<any>) => {
		ev.preventDefault();
	}

	handleDrag = (ev: React.DragEvent<any>) => {
		const isEnter = ev.type === 'dragenter';
		this.dragCount += isEnter ? 1 : -1;
		if (this.dragCount === 1) {
			this.setState(ps => ({...ps, dragOver: true}));
		} else if (this.dragCount === 0) {
			this.setState(ps => ({...ps, dragOver: false}));
		}
	}

	handleDelete = () => {
		this.setFiles(null);
	}
}
