import * as React from 'react';
import { ValidatableForm } from '../../components/form-controls/validatable-form';
import { observer } from 'mobx-react';
import { FormControlLabelled } from '../../components/form-controls/form-control-labelled';
import { Button } from '../../components/button';
import { IFormControlLabelledProps } from '../../components/form-controls/form-control-labelled';


export interface ICreateImportFormProps {
	handleSubmit: () => void;
	handleCancel: () => void;
	formatSelectProps: IFormControlLabelledProps;
	listingSelectProps: IFormControlLabelledProps;
	navigationInProgress: boolean;
}

@observer
export class CreateImportForm extends React.Component<ICreateImportFormProps> {
	render() {
		const {
			handleSubmit,
			handleCancel,
			formatSelectProps,
			listingSelectProps,
			navigationInProgress,
		} = this.props;

		return (
			<ValidatableForm onSubmit={handleSubmit} className="panel panel-default">
				<div className="panel-heading">
					Select data format and listing
				</div>
				<div className="panel-body">

					<div className="row">
						<div className="col-sm-4">
							<FormControlLabelled {...formatSelectProps} />
						</div>
						<div className="col-sm-4">
							<FormControlLabelled {...listingSelectProps} />
						</div>
					</div>

				</div>
				<div className="panel-footer panel-footer-btn-group">
					<Button className="btn btn-ghost" disabled={navigationInProgress} onClick={handleCancel}>Cancel</Button>
					<Button submit={true} className="btn btn-default" disabled={navigationInProgress}>Next</Button>
				</div>
			</ValidatableForm>
		);
	}
}
