import * as React from 'react';
import moment from 'moment';
import { ObservableMap } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Button } from '../../../../Shared/components/button/button';
import { classNames } from '../../../../Shared/utils/classnames';
import { dateService } from '../../services/date';
import { Formatter } from '../../../helpers/formatter';
import { FormControlLabelled, FormControlType } from '../../../components/form-controls/form-control-labelled';
import { FormControlTextarea } from '../../../components/form-controls/form-control-textarea';
import { Fragment } from '../../../../Shared/components/fragment';
import { IHaveValidatableFormContext, injectValidationFormContext } from '../../../components/form-controls/validatable-form-context';
import { IOriginalEmailData } from '../../pages/send-statements/send-statements-page-view-model';
import { MachineContext, machineContextKey } from '../../../../Shared/state-machine/saga-state-machine';
import { ModelMetadata, EmailBranding } from '../../giving-statements-generated';
import { ScrollToElement } from '../scroll-to-element';
import { SendPageStates, SendEmailModalStates, } from '../../state/send-page';
import { ValidatableForm } from '../../../components/form-controls/validatable-form';
import { ValidationMessage } from '../../../components/form-controls/validation-message';
import { ValidationSummary } from '../../../components/form-controls/validation-summary';
import { PaymentLabel } from '../../giving-statements-generated';
import * as styles from './styles.less';

export interface IEditEmailTemplateProps extends IHaveValidatableFormContext {
	machineContext?: MachineContext;
	originalEmailData: IOriginalEmailData;
	replyTo: string;
	subject: string;
	heading: string;
	emailBranding: EmailBranding;
	message: string;
	videoUrl: string;
	contactEmail: string;
	contactPhone: string;
	lastUpdateTime: Date;
	endDate: Date;
	startDate: Date;
	lastUpdatedBy: string;
	emailCountToSend: number;
	statementName: string;
	onReplyToChange?: (name: string) => void;
	onSubjectChange?: (name: string) => void;
	onHeadingChange?: (name: string) => void;
	onVideoUrlChange?: (name: string) => void;
	onMessageChange?: (name: string) => void;
	onSendClick?: () => void;
	onSaveAsDraftClick?: () => void;
	onSendPreviewEmail?: () => void;
	errorMap: ObservableMap<string, string>;
	errorMessages: string[] | null;
	defaultEmailTextKey: string;
	paymentLabel: PaymentLabel;
	hideTotalTaxDeductible: boolean;
	onHideTotalTaxDeductibleChange?: (checked: boolean) => void;
}

@inject(machineContextKey)
@injectValidationFormContext
@observer
export class EmailEditor extends React.Component<IEditEmailTemplateProps> {
	render() {
		const {
			machineContext,
			replyTo,
			emailCountToSend,
			onSendClick,
			errorMap,
			errorMessages,
		} = this.props;
		const metadata = ModelMetadata.UpdateStatementRequest;

		return (
			<ValidatableForm errorMap={errorMap} onSubmit={onSendClick}>
				{
					errorMessages && errorMessages.length > 0 &&
					<ScrollToElement>
						<ValidationSummary validationErrors={errorMessages} />
					</ScrollToElement>
				}
				<FormControlLabelled
					label={metadata.ReplyTo.displayName}
					formControlProps={{
						formControlType: FormControlType.Text,
						type: 'text',
						placeholder: 'Enter a reply to address',
						name: metadata.ReplyTo.propertyName,
						value: replyTo,
						onChangeHandler: this.onReplyToChangeEvent,
						validationRules: metadata.ReplyTo.validationRules,
						acceptanceTestTargetId: 'email-reply-to',
					}}
				/>
				<FormControlLabelled
					label={metadata.EmailSubject.displayName}
					formControlProps={{
						formControlType: FormControlType.Text,
						type: 'text',
						placeholder: 'Enter an email subject',
						name: metadata.EmailSubject.propertyName,
						value: this.props.subject,
						onChangeHandler: this.onSubjectChangeEvent,
						validationRules: metadata.EmailSubject.validationRules,
						acceptanceTestTargetId: 'email-subjectemail',
					}}
				/>
				<div className={styles.container}>
					{this.renderEmailTemplate()}
					<div className={styles.btnGroup}>
						<Button
							type="submit"
							className="btn btn-default"
							isLoading={machineContext.matchesState(SendEmailModalStates.SENDING)}>
							{machineContext.matchesState(SendEmailModalStates.SENDING) ? 'Sending' : 'Send'} {emailCountToSend} email{emailCountToSend === 1 ? '' : 's'}
						</Button>
					</div>
				</div>
			</ValidatableForm>
		);
	}

	private renderEmailTemplate() {
		const {
			onSaveAsDraftClick,
			machineContext,
			emailBranding,
			lastUpdateTime,
			contactPhone,
			contactEmail,
			endDate,
			startDate,
			statementName
		} = this.props;

		const metadata = ModelMetadata.UpdateStatementRequest;

		const buttonStyle: React.CSSProperties = {
			borderColor: emailBranding.PrimaryColor,
			backgroundColor: emailBranding.PrimaryColor
		};
		const borderStyle: React.CSSProperties = {
			borderTopColor: emailBranding.PrimaryColor
		};

		return (
			<div className={styles.emailTemplate} style={borderStyle}>
				<div className={styles.overlay} />
				<div className={styles.header} >
					<div className={styles.organization}>
						<img className={styles.logo} src={emailBranding.MerchantLogoUrl} />
						<div className={styles.name}>{emailBranding.OrganizationName}</div>
					</div>
					<FormControlLabelled
						label={metadata.EmailHeading.displayName}
						formControlProps={{
							formControlType: FormControlType.Text,
							type: 'text',
							placeholder: 'Enter an email heading',
							name: metadata.EmailHeading.propertyName,
							value: this.props.heading,
							onChangeHandler: this.onHeadingChangeEvent,
							validationRules: metadata.EmailHeading.validationRules,
							acceptanceTestTargetId: 'email-subjectemail',
						}}
						cssClassNames={classNames(styles.bustOverlay, styles.emailHeadingInput)}
					/>
					{startDate && endDate && <Fragment>
						<hr className={styles.headerDivider} />
						<div className={styles.date}>{dateService.getStatementDateRange(startDate, endDate)}</div>
					</Fragment>}
				</div>
				<div className={styles.body}>
					<div className={styles.content}>
						<div className={styles.greeting}>Hi Sam,</div>
						<div>Thanks for your donations! You can now <a>download your {statementName}</a></div>
						<div className={styles.actionContainer}>
							{this.emailIsModified()
								? <Button
									type="button"
									className={classNames(styles.bustOverlay, styles.saveAsDraftButton, 'btn btn-ghost')}
									isLoading={machineContext.matchesState(SendPageStates.SAVING_AS_DRAFT)}
									disabled={machineContext.matchesState(SendEmailModalStates.SENDING)}
									onClick={onSaveAsDraftClick}>
									Save
								</Button>
								: lastUpdateTime && <div className={styles.lastUpdate}>Last updated {this.calculateAgo(lastUpdateTime)}</div>
							}
						</div>
						{this.renderTextTemplate()}
						<FormControlLabelled
							label={metadata.EmailVideoUrl.displayName + ` (optional)`}
							cssClassNames={classNames(styles.bustOverlay, styles.videoLink)}
							formControlProps={{
								name: metadata.EmailVideoUrl.propertyName,
								formControlType: FormControlType.Text,
								type: 'text',
								placeholder: 'Enter a video URL',
								value: this.props.videoUrl,
								onChangeHandler: this.onUpdateVideoUrlEvent,
								validationRules: metadata.EmailVideoUrl.validationRules,
								acceptanceTestTargetId: 'email-videoUrl',
							}} />
						<div className={styles.bottomPanel} style={borderStyle}>
							<div className={styles.amountPanel}>Total tax-deductible contributions <span className={styles.dollarValue}>$7,800.00</span></div>
							<div className={styles.buttonPanel}>
								<Button className={classNames(styles.downloadStatementBtn, 'btn btn-default')} style={buttonStyle}>
									Download statement
								</Button>
							</div>
						</div>
						<div className={styles.enquiry}><span>If you have any queries about this or trouble accessing your Giving Statement, please email </span>
							<strong>{contactEmail}</strong><span> or phone </span><strong>{contactPhone}</strong>.</div>
					</div>
				</div>
				<div className={styles.footer}>
					<img className={styles.pushpayLogo} src={emailBranding.PushpayLogoUrl} />
					<hr className={styles.footerDivider} />
					<div className={styles.copyright}>Copyright &copy; {Formatter.formatDate(new Date(), 'YYYY')} Pushpay. All rights reserved.</div>
				</div>
			</div>
		);
	}

	private renderTextTemplate() {
		const { message } = this.props;
		const metadata = ModelMetadata.UpdateStatementRequest;
		const charUsed = message ? message.length : 0;
		const charAllowed = metadata.EmailText.validationRules.length.parameters.max;

		return (
			<div className={classNames(styles.bustOverlay, styles.emailContentWrapper)}>
				<FormControlTextarea
					cssClassNames={styles.message}
					name={metadata.EmailText.propertyName}
					value={this.props.message}
					rows={10}
					validationRules={{
						...metadata.EmailText.validationRules,
						containsdefaulttext: {
							errorMessage: 'Please modify the default email message',
							parameters: {
								defaulttext: this.props.defaultEmailTextKey,
							},
						},
					}}
					onChangeHandler={this.onUpdateMessageEvent}
					maxLength={metadata.EmailText.validationRules.length.parameters.max}
				/>
				<div className={classNames(styles.charLimit, charUsed > charAllowed ? styles.limitExceeded : null)}>
					<div><ValidationMessage elementName={metadata.EmailText.propertyName} /></div>
					<div>{message ? message.length : 0} / {charAllowed} characters used</div>
				</div>
			</div>
		);
	}

	private calculateAgo(whenHappened: Date): string {
		return moment(whenHappened).fromNow();
	}

	private emailIsModified = () => {
		const { originalEmailData } = this.props;

		return Object.keys(originalEmailData).some((key) => originalEmailData[key] !== this.props[key]);
	}

	private onReplyToChangeEvent = (nameEvent: React.FormEvent<HTMLInputElement>) => {
		this.props.onReplyToChange(nameEvent.currentTarget.value);
	}

	private onSubjectChangeEvent = (nameEvent: React.FormEvent<HTMLInputElement>) => {
		this.props.onSubjectChange(nameEvent.currentTarget.value);
	}

	private onHeadingChangeEvent = (nameEvent: React.FormEvent<HTMLInputElement>) => {
		this.props.onHeadingChange(nameEvent.currentTarget.value);
	}

	private onUpdateVideoUrlEvent = (nameEvent: React.FormEvent<HTMLInputElement>) => {
		this.props.onVideoUrlChange(nameEvent.currentTarget.value);
	}

	private onUpdateMessageEvent = (nameEvent: React.FormEvent<HTMLTextAreaElement>) => {
		this.props.onMessageChange(nameEvent.currentTarget.value);
	}
}
