import * as React from 'react';
import { TransitionGroup } from 'react-transition-group-v1';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import { ResponsiveVisibility, ResponsiveBreakpoint } from '../../helpers/responsive-helper';
import { RenderResponsive } from '../hoc-behavior/render-responsive';
import { SvgWrapper } from '../svg-wrapper';
import { isFunction } from '../../utils/is-function';
import { KeyCodes } from '../../../Shared/helpers/keycodes';
import { DataGridContext } from './data-grid-context';
import { DataGridRowPanel } from './data-grid-row-panel';
import { DataGridRowData } from './data-grid-row-data';
import { NodeListHelper } from '../../helpers/nodelisthelper';
import { velocity } from '../../helpers/velocity';
import { classNames as combineClassNames } from '../../../Shared/utils/classnames';

export const enterTransitionDuration: number = 250;
export const leaveTransitionDuration: number = 150;
export const dataGridRowIsNewDuration: number = 5000;

export enum SortDirection {
	ASCENDING,
	DESCENDING
}

export const DataGridCell = (props) => <div className={props.classNames}>{props.children}</div>;

export const SortingIcon = (props) => {
	const { columnKey, sortedBy, sortDirection } = props;
	let iconName: string = 'icon-sorting';
	let title: string = 'Column sortable';

	if (sortedBy === columnKey) {
		iconName = sortDirection === SortDirection.ASCENDING ? 'icon-sorting-down' : 'icon-sorting-up';
		title = `Sorted in ${SortDirection[sortDirection].toLowerCase()} order`;
	}

	return <SvgWrapper key={iconName} svg={iconName} className={`icon ${iconName}`} title={title} />;
};

export interface IDataGridColumn {
	displayName: string;
	sortable?: boolean;
	formatter?: (data: any, classNames: string, ...rest: any[]) => React.ReactNode;
	responsiveVisibility?: ResponsiveVisibility;
	responsiveBreakpoints?: ResponsiveBreakpoint;
	extraClassNames?: string;
}

export interface IDataGridProps {
	columns: { [key: string]: IDataGridColumn };
	data: {}[];
	getRowKey: (rowData: any) => string; // return value must be unique
	sortedBy?: string;
	sortDirection?: SortDirection;
	noDataMessage?: string;
	onSelectRow?: ((data: any) => void) | boolean;
	classNames?: string[];
	onSortByColumn?: (columnKey: string) => void;
	renderExpandedRow?: (
		data?: any,
		updateExpandedRow?: (key: string) => void
	) => React.ReactNode;
	highlightNewData?: boolean;
	rowClassNames?: string;
}

@observer
export class DataGrid extends React.Component<IDataGridProps, {}> {
	dataGridContext: DataGridContext;
	private documentContainerNodes: HTMLElement[];

	constructor(props) {
		super(props);
		this.dataGridContext = new DataGridContext(props.getRowKey);
	}

	UNSAFE_componentWillMount() {
		this.documentContainerNodes = NodeListHelper.toArray(document.querySelectorAll('html, body')) as HTMLElement[];
	}

	render() {
		const {
			columns, data, sortedBy, getRowKey, classNames, onSortByColumn, sortDirection,
			renderExpandedRow, onSelectRow, noDataMessage, highlightNewData, rowClassNames
		} = this.props;
		const gridClassNames = ['data-grid'].concat(classNames).join(' ');
		const { expandedRowKey, updateExpandedRow, initialDataHasLoaded } = this.dataGridContext;
		let onSelectRowHandler = updateExpandedRow;

		if (typeof(onSelectRow) === 'function') {
			onSelectRowHandler = onSelectRow;
		} else if (onSelectRow === false) {
			onSelectRowHandler = null;
		}

		return (
			<div className={gridClassNames}>
				<RenderResponsive visibility={ResponsiveVisibility.Hidden} breakpoints={ResponsiveBreakpoint.Xs|ResponsiveBreakpoint.Sm}>
					<div className={combineClassNames('data-grid-row', 'data-grid-row-header', rowClassNames)}>
						{this.showHeader && Object.keys(columns).map((key) => {
							const { displayName, sortable, responsiveBreakpoints, responsiveVisibility, extraClassNames } = columns[key];
							const cellClassNames = combineClassNames('data-grid-cell', `data-grid-cell-${key.toLowerCase()}`, extraClassNames);
							const sortableHeaderClassNames = `${cellClassNames} data-grid-cell-sortable${sortedBy === key ? ' data-grid-cell-sortable-sorted' : ''}`;
							const cellIsResponsive = responsiveBreakpoints && responsiveVisibility;

							const headerCell = <GridHeaderCell key={key} columnKey={key} displayName={displayName} sortable={sortable} onSortByColumn={onSortByColumn}
													sortedBy={sortedBy} sortDirection={sortDirection} sortableHeaderClassNames={sortableHeaderClassNames} cellClassNames={cellClassNames}/>;
							if (cellIsResponsive) {
								return(
									<RenderResponsive key={`responsive_${key}`} visibility={responsiveVisibility} breakpoints={responsiveBreakpoints}>
										{headerCell}
									</RenderResponsive>
								);
							}
							return headerCell;
						})}
					</div>
				</RenderResponsive>
				<TransitionGroup component="div">
					{this.hasData
						? data.map((rowData: any, index: number) => {
							const key = getRowKey(rowData);

							return (
								<DataGridRow key={key} highlightNewData={highlightNewData} shouldAnimate={initialDataHasLoaded} rowIndex={index + 1}>
									<TransitionGroup component="div">
										{onSelectRowHandler === updateExpandedRow && expandedRowKey && expandedRowKey === key
											? <DataGridRowPanel key="panel" dataGridContext={this.dataGridContext}>{renderExpandedRow(rowData, updateExpandedRow)}</DataGridRowPanel>
											: <DataGridRowData key="data" data={rowData} columns={columns} onSelectRow={onSelectRowHandler} expandedRowKey={expandedRowKey} extraClassNames={rowClassNames} />
										}
									</TransitionGroup>
								</DataGridRow>
							);
						})
						: noDataMessage && (
							<DataGridRow key="empty-message" highlightNewData={false} shouldAnimate={initialDataHasLoaded}>
								<div className="data-grid-empty-message">{noDataMessage}</div>
							</DataGridRow>
						)
					}
				</TransitionGroup>
			</div>
		);
	}

	componentDidMount() {
		this.toggleDocumentScroll();

		if (this.hasData) {
			this.dataGridContext.setInitialDataHasLoaded();
		}
	}

	componentDidUpdate() {
		const { data, getRowKey } = this.props;
		const { expandedRowKey } = this.dataGridContext;

		if (this.hasData) {
			this.dataGridContext.setInitialDataHasLoaded();
		}

		// close the expanded item if it no longer exists in the data
		if (
			(!this.hasData && expandedRowKey)
			|| (this.hasData && !data.filter((row) => getRowKey(row) === expandedRowKey).length)
		) {
			this.dataGridContext.updateExpandedRow(null);
		}

		this.toggleDocumentScroll();
	}

	private toggleDocumentScroll = () => {
		const gridExpandedClassname = 'data-grid-is-expanded';

		if (this.dataGridContext.expandedRowKey) {
			// wait for panel to transition in, to prevent the rest of the document jumping in the background
			setTimeout(() => {
				this.documentContainerNodes.forEach((node) => node.classList.add(gridExpandedClassname));
			}, enterTransitionDuration);
		} else {
			this.documentContainerNodes.forEach((node) => node.classList.remove(gridExpandedClassname));
		}
	}

	@computed
	private get hasData() {
		return this.props.data && this.props.data.length > 0;
	}

	@computed
	private get showHeader() {
		return this.props.noDataMessage || this.hasData;
	}
}

interface IGridHeaderCellProps {
	columnKey: string;
	displayName: string;
	sortable: boolean;
	onSortByColumn: (columnKey: string) => void;
	sortedBy: string;
	sortDirection: SortDirection;
	sortableHeaderClassNames: string;
	cellClassNames: string;
}

class GridHeaderCell extends React.Component<IGridHeaderCellProps> {
	render() {
		const { columnKey, displayName, sortable, onSortByColumn, sortedBy, sortDirection, sortableHeaderClassNames, cellClassNames} = this.props;
		if (sortable && isFunction(onSortByColumn)) {
			return(
				<div className={sortableHeaderClassNames} onClick={this.onClick} tabIndex={0} onKeyDown={this.onKeyDown}>
					{displayName}<SortingIcon columnKey={columnKey} sortedBy={sortedBy} sortDirection={sortDirection} />
				</div>
			);
		}
		return <div className={cellClassNames}>{displayName}</div>;
	}

	private onClick = () => {
		const { columnKey, onSortByColumn } = this.props;
		onSortByColumn(columnKey);
	}

	private onKeyDown = (event: React.KeyboardEvent<any>) => {
		const { columnKey, onSortByColumn } = this.props;
		switch (event.keyCode) {
			case KeyCodes.Space:
			case KeyCodes.Enter:
				event.preventDefault();
				onSortByColumn(columnKey);
				break;
			default:
				break;
		}
	}
}

@observer
export class DataGridRow extends React.Component<{ highlightNewData: boolean, shouldAnimate: boolean, rowIndex?: number }, { isNew: boolean }> {
	private enterStateTimeout: number;
	private row: HTMLElement;

	constructor(props) {
		super(props);
		this.state = { isNew: false };
	}

	render() {
		return (
			<div className={`data-grid-row${this.state.isNew ? ' data-grid-row-new' : ''}`} data-pp-at-target={`data-grid-row${this.props.rowIndex || ``}`} ref={(row) => this.row = row}>
				{this.props.children}
			</div>
		);
	}

	componentWillEnter(callback) {
		if (!this.props.shouldAnimate) {
			callback();
			return;
		}

		velocity(this.row, 'slideDown', {
			duration: enterTransitionDuration,
			easing: 'ease-in-out',
			complete: this.highlightRowIfNeeded(callback),
		});
	}

	componentWillLeave(callback) {
		if (!this.props.shouldAnimate) {
			callback();
			return;
		}

		velocity(this.row, 'slideUp', {
			duration: leaveTransitionDuration,
			easing: 'ease-in-out',
			complete: callback,
		});
	}

	componentWillUnmount() {
		if (this.enterStateTimeout) {
			clearTimeout(this.enterStateTimeout);
		}
	}

	private highlightRowIfNeeded = (callback) => {
		if (this.props.highlightNewData !== false) {
			this.setState({ isNew: true });
			this.enterStateTimeout = window.setTimeout(() => {
				this.setState({ isNew: false });
			}, dataGridRowIsNewDuration);
		}
		callback();
	}
}
