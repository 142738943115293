import * as React from 'react';
import { SettingsEventTimeViewModel, ListingViewModel } from '../../settings-generated';
import { Fragment } from '../../../../Shared/components/fragment';
import { ServiceTimePickerItem } from './service-time-picker-item';

import * as styles from './service-time-picker.less';


export interface IServiceTimePickerProps {
	listing: ListingViewModel;
	eventTimes: SettingsEventTimeViewModel[];
	onChangeServiceTimeActivated: (eventTime: SettingsEventTimeViewModel, listingId: number, isActivated: boolean) => void;
	onEditServiceTime: (eventTime: SettingsEventTimeViewModel) => void;
}

export class ServiceTimePicker extends React.Component<IServiceTimePickerProps> {
	render() {
		const { listing, eventTimes, onEditServiceTime } = this.props;

		return (
			<Fragment>
				<h3 className={styles.title}>{listing.ListingName}</h3>
				<ul className={styles.list}>
					{eventTimes.map((eventTime: SettingsEventTimeViewModel) => <ServiceTimePickerItem
						key={eventTime.Id}
						eventTime={eventTime}
						onChangeActivated={this.onChangeServiceTimeActivated}
						onEdit={onEditServiceTime}
						isActivated={this.isEventTimeActive(eventTime)}
					/>)}
				</ul>
			</Fragment>
		);
	}

	onChangeServiceTimeActivated = (eventTime: SettingsEventTimeViewModel, checked: boolean) => {
		const { listing: { ListingId }, onChangeServiceTimeActivated } = this.props;

		onChangeServiceTimeActivated(eventTime, ListingId, checked);
	}

	isEventTimeActive = (eventTime: SettingsEventTimeViewModel) => {
		const { ActiveEventTimes } = this.props.listing;

		if (!ActiveEventTimes) {
			return false;
		}

		return ActiveEventTimes.some((eventTimeId: number) => eventTimeId === eventTime.Id);
	}
}
