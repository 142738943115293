import { KeyCodes } from '../../Shared/helpers/keycodes';

/**
 * returns KeyCodes.Unknown when ctrl, alt, shift or meta keys used
 *
 * @export
 * @param {React.KeyboardEvent} event
 * @returns {KeyCodes}
 */
export function eventToKeyCode(event: React.KeyboardEvent<any>): KeyCodes {
	if (event.ctrlKey || event.altKey || event.shiftKey || event.metaKey) {
		return KeyCodes.Unknown;
	}

	if (KeyCodes.hasOwnProperty(`${event.keyCode}`)) {
		return event.keyCode as KeyCodes;
	}

	return KeyCodes.Unknown;
}
