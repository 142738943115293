import { BrowserSupportHelper } from './helpers/browsersupporthelper';
import { layout } from './layout/layout';
import { validation } from './validation/validation';
import { pillarStep } from './pages/pillar-step';
import './pages/organization-step-listing-chooser';
import './pages/notification-settings';
import './check-deposit/index';
import './funds/funds';
import { clipboardCopyManager } from './components/copy-to-clipboard';
import { contentToggleManager } from './components/content-toggler';
import './components/payment-common-fields';
import './helpers/arrayextensions';
import './helpers/react-component-loader';
import './utils/page-loading-stats';
import './components/custom-select';
import './components/modal-dialog-controller';
import './components/tooltip/tooltip';
import './login/page-login';
import './login/page-admin-login';
import {requireSvgResource} from './svg-sprite';

if (typeof window !== 'undefined') {
	layout();
	validation.init();
	pillarStep();
	clipboardCopyManager();
	contentToggleManager();
	BrowserSupportHelper.addNoCSSGridClassIfNeeded();
	requireSvgResource();
}

