import * as React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { IFormControlCommonProps } from '../form-control-common-props';
import { ColorPickerButton } from '../../../branding-settings/components/color-picker-button/color-picker-button';
import { FormControlInput } from '../form-control-input';
import { classNames } from '../../../../Shared/utils/classnames';
import * as styles from './styles.less';


export interface IFormControlColorProps extends IFormControlCommonProps {
	value: string;
	name: string;
	uniqueSuffix?: string;
	cssClassNames?: string;
	readonly?: boolean;
	ariaLabel?: string;
	onChangeHandler: (event: React.FormEvent<HTMLInputElement>) => void;
	onFocusHandler?: () => void;
}

@observer
export class FormControlColor extends React.Component<IFormControlColorProps> {
	@observable
	private inputRef?: HTMLInputElement;

	render() {
		const cssClassNames = classNames('input-group', this.props.cssClassNames);
		const cssTextFieldClassNames = classNames(styles.textField, 'form-control');

		return (
				<div className={cssClassNames}>
					<FormControlInput
						{...this.props}
						cssClassNames={`${cssTextFieldClassNames} ${styles.colorPickerText}`}
						type="text"
						spellCheck={false}
						refCallback={this.createRef}
					/>
					<div className={`${styles.colorPickerButton} input-group-prepend`}>
						<ColorPickerButton {...this.props} inputRef={this.inputRef} />
					</div>
				</div>
		);
	}

	@action.bound
	private createRef = (ref) => {
		this.inputRef = ref;
	}
}

