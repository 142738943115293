import * as React from 'react';
import { KeyCodes } from '../../../Shared/helpers/keycodes';
import { eventToKeyCode } from '../../helpers/keycodehelper';
import { wrapReactChildEventHandlers } from '../../utils/wrap-reactchild-eventhandlers';

/**
 * Defines consistent behavior for components that need to open a dropdown menu
 * handles blur, focus, click and key down (UpArrow, DownArrow, Esc, Enter) events
 * @export
 * @class DropDownMenuController
 * @extends {React.Component<{
 * 	open: boolean;
 * 	onRequestOpen: () => void;
 * 	onRequestClose: () => void;
 * }, {}>}
 */
export class DropDownMenuController extends React.Component<{
	open: boolean;
	onRequestOpen: () => void;
	onRequestClose: () => void;
	openOnFocus: boolean;
}, {}> {
	private eventHandlers = wrapReactChildEventHandlers(() => (React.Children.only(this.props.children) as React.ReactElement).props, {
		onFocus: () => {
			const { open, onRequestOpen, openOnFocus } = this.props;
			if (openOnFocus && !open) {
				onRequestOpen();
			}
		},
		onBlur: (event: React.FocusEvent<Node>) => {
			const { open, onRequestClose } = this.props;
			if (document.activeElement !== event.currentTarget && !(event.currentTarget).contains(document.activeElement) && open) {
				onRequestClose();
			}
		},
		onClick: () => {
			const { open, onRequestOpen } = this.props;
			if (!open) {
				onRequestOpen();
			}
		},
		onKeyDown: (event: React.KeyboardEvent<any>) => {
			const { open, onRequestClose, onRequestOpen } = this.props;
			const keyCode = eventToKeyCode(event);

			switch (keyCode) {
				case KeyCodes.UpArrow:
				case KeyCodes.DownArrow:
				case KeyCodes.Enter:
					if (!open) {
						onRequestOpen();
						event.preventDefault();
						event.stopPropagation();
					}
					break;
				case KeyCodes.Escape:
					if (open) {
						onRequestClose();
						event.preventDefault();
						event.stopPropagation();
					}
					break;
				default:
					break;
			}
		}
	});

	render() {
		return React.cloneElement(React.Children.only(this.props.children) as React.ReactElement, this.eventHandlers);
	}
}
