import * as React from 'react';
import { ValidatableControl } from '../form-controls/validatable-control';
import { IPayerViewModel } from './payer-search-models';
import { OmniboxFieldName } from './payer-search-omnibox-context';
import { ValidationMessage } from '../form-controls/validation-message';

export class PayerSearchValidationMessage extends React.Component<{
	payerNoun?: string;
	selectedPayer: IPayerViewModel;
}, {}> {
	render() {
		const { selectedPayer, payerNoun } = this.props;
		if (selectedPayer) {
			return null;
		}

		return (
			<span>
				<ValidatableControl elementName={OmniboxFieldName}
					validationRules={{
						nevervalid: {
							errorMessage: `Please select a ${payerNoun || 'giver'}`
						}
					}}>
					<input
						data-validate="true"
						type="hidden"
						name={OmniboxFieldName}
						value={selectedPayer ? 'payerSelected' : ''} />
				</ValidatableControl>
				<ValidationMessage elementName={OmniboxFieldName} additionalCssClass="payer-search-info payer-search-info-error" />
			</span >
		);
	}
}
