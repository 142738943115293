import * as React from 'react';

export interface IAccessibilityComboboxProps {
	id?: string;
	expanded?: boolean;
	owns?: string;
	autocomplete?: 'list'|'inline'|'both';
	activedescendant?: string;
}

/**
 * https://www.w3.org/TR/wai-aria/roles#combobox
 * @param {(IAccessibilityComboboxProps & { children?: React.ReactNode })} props
 * @returns
 */
export const AccessibilityCombobox: React.StatelessComponent<IAccessibilityComboboxProps> = (props: IAccessibilityComboboxProps & { children?: React.ReactNode }) => {
	const { id, expanded, owns, activedescendant, children, autocomplete, ...other} = props;

	return React.cloneElement((React.Children.only(children) as React.ReactElement), {
		id,
		role: 'combobox',
		'aria-autocomplete': autocomplete,
		'aria-expanded': !!expanded,
		'aria-owns': owns,
		'aria-activedescendant': activedescendant,
		...other
	});
};
