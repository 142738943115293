/*
'element'	<span>
			<span>
'flag'			<span></span>
				<span>
'select'			<select>
						1+ <option></option>
					</select>
				</span>
'input'			<input></input>
			</span>
			</span>
*/

(($: any) => {
	'use strict';

	$.InternationalMobileInput = function inputInit (el) {


		var _plugin = this;

		_plugin.wrapper = null;
		_plugin.element = null;
		_plugin.select = null;
		_plugin.selectedPlaceholder = null;
		_plugin.selectedOption = null;
		_plugin.input = null;
		var _internal = <any>{};
		_internal.isActive = false;
		let _isClick = false;//this is used to
		var init = () => {
			_plugin.element = $(el);

			_internal.findElements();
			_internal.wireEvents();
			_internal.changeTriggers(); // set default state

			//Trigger default icon & placeholders
			_internal.selectOnChange();
		};

		_internal.findElements = () => {
			_plugin.wrapper = _plugin.element.find('div.email-phone-input-control');
			_plugin.select = _plugin.element.find('select');
			_plugin.input = _plugin.element.find('input');
		};

		_internal.wireEvents = () => {
			_plugin.select.change((e)=> {
				_internal.selectOnChange();
				if (_isClick) {_plugin.input.focus();}
			});
			_plugin.select.click((e) => {
				_isClick = true;
			});


			_plugin.select.keyup( (e) => {
				_isClick = false;
				if (e.which === 9 && !e.shiftKey) {
					_plugin.input.focus();
				}
			});

			_plugin.input.keyup(() => {
				var text = _plugin.input.val();
				if (text.indexOf('+') === 0) {
					var opt = _plugin.select.find('option[data-pp-country-code="'+ text + '"]');
					if (opt.length === 1) {
						_plugin.select.val(opt.val());
						_internal.selectOnChange();
					}

				}
			});
		};

		_internal.selectOnChange = () => {

			_plugin.selectedOption = _plugin.select.find('option:selected');


			_plugin.selectedPlaceholder = _plugin.selectedOption.attr('data-pp-country-placeholder');

			_internal.changeTriggers();
		};

		_internal.changeTriggers = () => {
			_plugin.input.attr('placeholder', _plugin.selectedPlaceholder);

		};

		init();
	};
})(jQuery);

export function init() {
	$('[data-pp-international-mobile-input]').each(function () {
		// tslint:disable-next-line:no-unused-expression
		new (<any>$).InternationalMobileInput(this);
	});
}
