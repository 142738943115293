import * as React from 'react';
import {observer} from 'mobx-react';
import moment from 'moment';
import * as ReactDnD from 'react-dnd';
import * as ViewModels from '../view-models/index';
import {
	DragDropTypes,
	IDragSourcePropsBase,
	dragSourceCollector,
	hideDragPreview,
	classNames
} from './_common';
import {SvgWrapper} from '../../components/svg-wrapper';
import * as Util from '../util';
import { Models } from '../community-member-generated';

export interface IAccountTableRowProps extends IDragSourcePropsBase {
	vm: ViewModels.Account;
	index: number;
	isDraggingCallback?: (isDragging: boolean) => void;
	disableCommunityMemberDragAndDrop: boolean;
}

@ReactDnD.DragSource<IAccountTableRowProps>(DragDropTypes.account, {
	beginDrag(props) {
		return {vm: props.vm};
	},
	canDrag(props, monitor) {
		return true;
	}
}, dragSourceCollector)
@observer
@hideDragPreview
export default class AccountRow extends React.Component<IAccountTableRowProps, {}> {

	render(): any {
		let {
				vm, index, connectDragSource, isDragging, isDraggingCallback,
				shouldHideElementOnDragBecauseIEIsStillTheWorst, disableCommunityMemberDragAndDrop
			} = this.props;

		if (disableCommunityMemberDragAndDrop) {
			isDragging = false;
		} else {
			isDraggingCallback(isDragging);
		}

		const verifiedTooltipContent = 'This Pushpay account has been registered by the giver.';
		const unverifiedTooltipContent = 'This Pushpay account has not been registered by the giver.';
		let tooltipContent = vm.verified ? verifiedTooltipContent : unverifiedTooltipContent;

		const unlinkTooltip = 'Ungroup this Pushpay account from this community member. A new community member will be created for this Pushpay account.';
		const idTagTooltip = 'View the personal details and transaction history of this Pushpay account.';
		const accountTitle = disableCommunityMemberDragAndDrop ? ''
			: 'Drag a row onto another community member to group accounts';

		const element = <div className={classNames('account', vm.droppedAnimation.currentClassName, {
								disableCommunityMemberDragAndDrop,
								'is-dragging': isDragging
							})} title={accountTitle}
							data-pp-at-target={`AccountDetailTableRow${index + 1}`}>
			<div className="grid-row">
				<div className="row-icon"><span className={`circle ${vm.verified ? ' verified' : ''}`} title={tooltipContent}></span></div>
				<div className="name">{vm.details.FirstName}</div>
				<div className="name">{vm.details.LastName}</div>
				<div className="email-address">{vm.details.EmailAddress}</div>
				<div className="mobile-number">{vm.details.MobileNumber}</div>
				<div className="address"><span title={vm.details.Address.DisplayAddress}>{vm.details.Address.DisplayAddress}</span></div>
				<div className="last-activity">{moment(vm.details.LastPaymentOnLocal).format('D MMM\' YY')}</div>
				<div className="commands">
					<a className="icon" data-pp-at-target={`EditAccountIcon${index + 1}`} href={`${Util.combineWithLocationPathname(`profile/${vm.id}`)}`}
						title={idTagTooltip}>
						<SvgWrapper svg="id-tag" className="svg" />
					</a>
					{
						vm.parent.accounts.length > 1
							? (
								<button className="icon" onClick={() => vm.parent.parent.ungroup(vm)} title={unlinkTooltip}>
									<SvgWrapper svg="unlink" className="svg" />
								</button>
								)
							: null
					}

				</div>
			</div>
		</div>;

		return disableCommunityMemberDragAndDrop
			? element
			: connectDragSource(element);
	}
}
