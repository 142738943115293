import { DraftCampaignCardViewModel, ClosedCampaignCardViewModel, ActiveCampaignCardViewModel, ScheduledCampaignCardViewModel } from '../../../campaigns-generated';
import { createAction } from '../../../../../Shared/state-machine/rx-state-machine-factory';

export const CampaignCardAction = {
	activateCampaign: createAction<{
		draftCard: DraftCampaignCardViewModel
	}>(),

	reactivateCampaign: createAction<{
		closedCard: ClosedCampaignCardViewModel
	}>(),

	closeCampaign: createAction<{
		ActiveOrScheduledCard: ActiveCampaignCardViewModel | ScheduledCampaignCardViewModel
	}>(),

	deleteCampaign: createAction<{
		draftCard: DraftCampaignCardViewModel
	}>(),

	handleFailure: createAction<{
		id: number,
		onRetry: () => void
	}>(),

	dismissRetry: createAction(),

	startProcessing: createAction(),

	finishProcessing: createAction(),
};
