export module EnumHelper {
	export function MapRadioGridOptions(e: Enum): { Label: string, Value: string }[] {
		return MapKeys(e).map(key => {
			return {
				Label: key,
				Value: `${e[key]}`,
			};
		});
	}

	export function MapKeyValues(e: Enum): {key: string, value: string}[] {
		return MapKeys(e).map(key => {
			return {
				key: key,
				value: e[key]
			};
		});
	}

	export function MapKeys(e: Enum): string[] {
		return Object.keys(e).filter(key => isNaN(Number(key)));
	}

	export function MapValues(e: Enum) {
		return Object.keys(e).filter(value => !isNaN(Number(value)));
	}
}

export interface Enum {
	[id: number]: string;
}
