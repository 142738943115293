import { alertController } from '../../components/alert-controller';
import { delay } from 'redux-saga';
import { all } from 'redux-saga/effects';
import { DownloadModalEvents, SendPageEvents, SendEmailModalEvents, ErrorModalEvents } from '../state/send-page';
import { ConfigureRunViewModel, DownloadViewModel, SendStatementsViewModel, SendSummaryViewModel } from '../giving-statements-generated';
import { givingStatementsDataService } from '../giving-statements-data-service';
import { GivingStatementsViewModel } from '../giving-statements-view-model';
import { MachineContext } from '../../../Shared/state-machine/saga-state-machine';
import { PostError } from '../../utils/ajax-client';
import { getCancellableEffect } from '../../../Shared/helpers/saga-side-effects';
import { SendStatementsPageViewModel, IZipInformation } from '../pages/send-statements/send-statements-page-view-model';

export const summaryDataCancellableEffect = getCancellableEffect();

export const SendPageActionMap = {
	LoadSendPageData: function* (context: MachineContext, data: { immediatelyMoveToSummary: boolean }) {
		try {
			const viewModel: GivingStatementsViewModel = context.viewModel;
			const sendData: SendStatementsViewModel = yield givingStatementsDataService.statementsLoad({
				id: viewModel.currentConfigurationId,
			});
			// update viewModel.currentConfiguration.status from Generating to Generated/Issued.
			viewModel.currentConfiguration.status = sendData.Status;
			viewModel.updateSendStatementsViewModels(sendData);

			context.transition(SendPageEvents[sendData.StatementsGenerated ? 'LOAD_SUCCESS' : 'LOAD_NO_STATEMENTS'], data);
		} catch (e) {
			context.transition(SendPageEvents.LOAD_FAIL, e);
		}
	},
	SaveAsDraft: function* (context: MachineContext) {
		const viewModel = context.viewModel as GivingStatementsViewModel;
		const sendPageViewModel = viewModel.sendPageViewModel as SendStatementsPageViewModel;

		try {
			const id = viewModel.currentConfigurationId;
			const success = yield saveAsDraft(id, sendPageViewModel);

			if (success) {
				alertController.showSuccess('Saved as draft');
			} else {
				alertController.showDanger('Failed to saved as draft. Please try again.');
			}

			context.transition(SendPageEvents.SAVE_AS_DRAFT_COMPLETE);
		} catch (error) {
			if (error.isPostError) {
				sendPageViewModel.emailData.setValidationErrors(error.validationErrors);
			}

			context.transition(SendPageEvents.SAVE_AS_DRAFT_COMPLETE);
		}
	},
	SendEmails: function* (context: MachineContext) {
		try {
			const viewModel = context.viewModel as GivingStatementsViewModel;
			const id = viewModel.currentConfigurationId;
			const updateSendStatementsSuccess = yield saveAsDraft(id, viewModel.sendPageViewModel);

			if (!updateSendStatementsSuccess) {
				context.transition(SendEmailModalEvents.EMAIL_SEND_FAILED, 'Update email data call failed');
				return;
			}

			const triggerEmailSendSuccess = yield givingStatementsDataService.triggerEmailSend({ id });

			if (!triggerEmailSendSuccess) {
				context.transition(SendEmailModalEvents.EMAIL_SEND_FAILED, 'Trigger send email failed');
				return;
			}

			context.transition(SendEmailModalEvents.EMAIL_SEND_SUCCESSFUL);
		} catch (error) {
			context.transition(SendEmailModalEvents.EMAIL_SEND_FAILED, error);
		}
	},
	SendEmailsFailed: function (context: MachineContext, error: PostError) {
		const viewModel: GivingStatementsViewModel = context.viewModel;

		if (error.isPostError) {
			viewModel.sendPageViewModel.emailData.setValidationErrors(error.validationErrors);
		} else {
			viewModel.sendPageViewModel.emailData.setValidationErrors({ Unknown: 'Something went wrong, please try again' });
		}
	},
	ShowDownloadModal: function (context: MachineContext) {
		context.transition(DownloadModalEvents.LOAD);
	},
	LoadDownloadAllData: function* (context: MachineContext) {
		try {
			const viewModel: GivingStatementsViewModel = context.viewModel;
			const convertedZipInfo: IZipInformation[] = yield GetZipInfo(viewModel.currentConfigurationId, false);
			viewModel.sendPageViewModel.updateDownloadAllData(convertedZipInfo);
			context.transition(DownloadModalEvents.LOAD_SUCCESS);
		} catch (e) {
			context.transition(DownloadModalEvents.LOAD_FAIL, e);
		}
	},
	LoadDownloadNoEmailData: function* (context: MachineContext) {
		try {
			const viewModel: GivingStatementsViewModel = context.viewModel;
			const convertedZipInfo: IZipInformation[] = yield GetZipInfo(viewModel.currentConfigurationId, true);
			viewModel.sendPageViewModel.updateDownloadNoEmailData(convertedZipInfo);
			context.transition(DownloadModalEvents.LOAD_SUCCESS);
		} catch (e) {
			context.transition(DownloadModalEvents.LOAD_FAIL, e);
		}
	},
	TriggerMoveToSummaryIfRequested: function (context: MachineContext, data: { immediatelyMoveToSummary: boolean }) {
		if (data && data.immediatelyMoveToSummary) {
			context.transition(SendPageEvents.SWITCH_TO_SUMMARY_MODE);
		}
	},
	LoadSentSummaryData: function* (context: MachineContext) {
		const viewModel: GivingStatementsViewModel = context.viewModel;
		try {
			const allData: { data: SendSummaryViewModel, updatedConfig: ConfigureRunViewModel } = yield all({
				data: yield givingStatementsDataService.sendSummary({
					id: viewModel.currentConfigurationId,
				}),
				updatedConfig: yield givingStatementsDataService.configure({
					id: viewModel.currentConfigurationId,
				}),
			});
			// update viewModel.currentConfiguration.status from Generating to Generated/Issued.
			viewModel.currentConfiguration.status = allData.updatedConfig.Status;
			viewModel.currentConfiguration.hasBeenPublished = allData.updatedConfig.HasBeenPublished;
			viewModel.sendPageViewModel.updateStatus(allData.updatedConfig.Status);
			viewModel.sendPageViewModel.summaryData.updateSentSummaryData(allData.data, allData.updatedConfig.Status);
		} catch (e) {
			viewModel.sentSummaryRetrievalErrorCount += 1;
			if (viewModel.sentSummaryRetrievalErrorCount > 3) {
				context.transition(SendPageEvents.SHOW_ERROR_MODAL);
				return;
			}
		}
		context.transition(SendPageEvents.FINISH_LOADING_SUMMARY_DATA);
	},
	WaitForNextLoad: function* (context: MachineContext) {
		//cancel any waiting task if exists.
		yield summaryDataCancellableEffect.cancel();
		yield summaryDataCancellableEffect.run(function* () {
			yield delay(30000);
			context.transition(SendPageEvents.START_LOADING_SUMMARY_DATA);
		});
	},
	CancelSummaryDataPolling: function* (context: MachineContext) {
		yield summaryDataCancellableEffect.cancel();
	},
	ShowErrorModal: function (context: MachineContext) {
		context.transition(ErrorModalEvents.SHOW);
	},
	PublishStatements: function* (context: MachineContext) {
		const viewModel = context.viewModel as GivingStatementsViewModel;
		const id = viewModel.currentConfigurationId;
		try {
			yield givingStatementsDataService.setAsPublished({ id });
			context.transition(SendPageEvents.PUBLISHING_STATEMENTS_COMPLETE);
			context.transition(SendPageEvents.START_LOADING_SUMMARY_DATA);
			context.transition(SendPageEvents.SWITCH_TO_SUMMARY_MODE);
		} catch (e) {
			context.transition(SendPageEvents.SHOW_ERROR_MODAL);
		}
	},
	SendPreviewEmail: function* (context: MachineContext) {
		const viewModel = context.viewModel as GivingStatementsViewModel;

		try {
			const id = viewModel.currentConfigurationId;
			const updateSendStatementsSuccess = yield saveAsDraft(id, viewModel.sendPageViewModel);

			if (!updateSendStatementsSuccess) {
				throw new Error('Failed to save the email changes. Please try again.');
			}

			const success = yield givingStatementsDataService.sendPreviewEmail({ id });
			if (success) {
				alertController.showSuccess('Test email has been sent.');
			} else {
				throw new Error('Failed to send the test email. Please try again.');
			}
		} catch (error) {
			if (error.validationErrors) {
				const sendPageViewModel = viewModel.sendPageViewModel as SendStatementsPageViewModel;
				sendPageViewModel.emailData.setValidationErrors(error.validationErrors);
			} else {
				alertController.showDanger(error.message);
			}
		} finally {
			context.transition(SendPageEvents.SEND_PREVIEW_EMAIL_COMPLETE);
		}
	}
};

function* saveAsDraft(id: number, sendPageViewModel: SendStatementsPageViewModel) {
	const { emailData, excludeLessThanAmount } = sendPageViewModel;
	const updateModel = {
		ReplyTo: emailData.replyTo,
		EmailSubject: emailData.subject,
		EmailHeading: emailData.heading,
		EmailText: emailData.message,
		EmailVideoUrl: emailData.videoUrl,
		HideTotalTaxDeductible: emailData.hideTotalTaxDeductible,
		ExcludeStatementsLessThan: excludeLessThanAmount,
	};

	// Clear the validation errors on save to clear any errors being displayed.
	emailData.setValidationErrors(null);

	const result = yield givingStatementsDataService.updateSendStatements({ id, updateModel });

	// If save as draft was successful, reset the original email so the `Save` button hides until further modifications
	// are made.
	if (result) {
		emailData.resetOriginalEmailData();

		// Fetch and set the last updated value on the email view model.
		const sendStatementsViewModel: SendStatementsViewModel = yield givingStatementsDataService.statementsLoad({ id });

		emailData.updateLastUpdateTime(sendStatementsViewModel.LastUpdated);
	}

	return result;
}

function* GetZipInfo(id: number, onlyWithNoEmailAddress: boolean) {
	const zipInfo: DownloadViewModel = yield givingStatementsDataService.getSendAndDownloadData({
		id,
		onlyWithNoEmailAddress,
	});
	const convertedZipInfo = zipInfo.Zips.map((zip) => ({
		fromName: zip.FromName,
		toName: zip.ToName,
		url: zip.DownloadUrl,
		doesNotSupportFromAndToName: zip.DoesNotSupportFromAndToName,
	}));
	return convertedZipInfo;
}
