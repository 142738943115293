import { ModalDialogCommander } from '../components/modal-dialog-commander';
import {
	ActionKey,
	combineMiddleware,
	DataService,
	DataServiceAction,
	DataServiceOptions,
	IDataService,
	isActionForService
} from '../utils/data-service';
import { Models } from './community-member-generated';
import { dataService } from '../../Shared/utils/data-service';

const ds = dataService(Models.CommunityMemberApiConfig);
export const communityMemberPromiseDataService = () => ds.getPromiseService();

export function mockCommunityMemberDataServiceForPromiseService(mockDataService) {
	ds.mock(mockDataService);
}

export type CommunityMemberApiConfig = typeof Models.CommunityMemberApiConfig;
export type CommunityMemberDataService = IDataService<CommunityMemberApiConfig>;
export type CommunityMemberDataServiceAction = DataServiceAction<CommunityMemberApiConfig, any>;

export function isCommunityMemberAction<TActionKey extends ActionKey<CommunityMemberApiConfig>>(action: CommunityMemberDataServiceAction, key: TActionKey):
	action is DataServiceAction<CommunityMemberApiConfig, TActionKey> {
	return isActionForService(action, key);
}

function reportUnexpectedError(error, action: CommunityMemberDataServiceAction) {
	window.reportUnhandledRejection(error, { action });
}

let communityMemberDataService: CommunityMemberDataService = null;

function createCommunityMemberDataService(options?: DataServiceOptions<CommunityMemberApiConfig>): CommunityMemberDataService {
	options = options || {};

	options.middleware = combineMiddleware(next => action => {
		switch (action.type) {
			case 'request_init':
				console.log(`Community member request initiated`, action);
				break;
			case 'request_success':
				console.log(`Community member request completed`, action);
				break;
			case 'request_error':
				const { error } = action;

				if (error.shouldReloadPage || error.timedout) {
					ModalDialogCommander.error(error.userError, '').done(() => {
						document.location.reload(true);
					});
					return;
				}

				console.log(`Community mmeber request failed`, action);
				break;
			case 'request_cancel':
				console.log(`Community member request cancelled`, action);
				break;
			case 'unexpected_error':
				reportUnexpectedError(action.error, action);
				ModalDialogCommander.showStandardErrorMessage();
				break;
		}

		return next(action);
	}, options.middleware);

	return new DataService(Models.CommunityMemberApiConfig, options);
}

export function getCommunityMemberDataService(): CommunityMemberDataService {
	if (!communityMemberDataService) {
		communityMemberDataService = createCommunityMemberDataService();
	}

	return communityMemberDataService;
}

export function mockCommunityMemberDataService(mockDataService: CommunityMemberDataService) {
	communityMemberDataService = mockDataService;
}
