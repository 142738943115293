
import { observable, action } from 'mobx';
import { AlertProps, AlertType } from '../../transaction-import/components/alert-control';
import { StepStatusStep } from '../../components/step-status';
import { PaymentLabel } from '../schedule-import-generated';

export enum ImportStep {
	Select = 1,
	Upload = 2,
	Match = 3,
	Review = 4,
	Alert = 5,
}

export class ScheduleImportStepViewModel {
	readonly currentStep: ImportStep;
	readonly stepTitle: string;
	readonly stepDescription: string;
	readonly scheduleImportSteps: StepStatusStep<ImportStep>[];
	readonly paymentLabel: PaymentLabel;

	@observable alertProps: AlertProps;
	@observable actionInProgress: boolean = false;

	constructor(paymentLabel: PaymentLabel, step: ImportStep, title?: string, description?: string) {
		this.currentStep = step;
		this.stepTitle = title;
		this.stepDescription = description;
		this.paymentLabel = paymentLabel;

		this.scheduleImportSteps = [
			{ key: ImportStep.Select, label: 'Select source' },
			{ key: ImportStep.Upload, label: 'Upload file' },
			{ key: ImportStep.Match, label: 'Match funds' },
			{ key: ImportStep.Review, label: 'Review and confirm' },
			{ key: ImportStep.Alert, label: `Alert your ${paymentLabel.PayerPluralLowerCase}` },
		];
		this.alertProps = {
			alertContent: null,
			showCloseButton: true,
			onClose: () => {
				this.alertProps.alertContent = null;
			}
		};
	}

	@action.bound
	clearAlert() {
		this.alertProps.alertContent = null;
	}

	@action.bound
	showSuccessAlert(message: string) {
		this.showAlert(AlertType.Success, message, true);
	}

	@action.bound
	showErrorAlert(message: string) {
		this.showAlert(AlertType.Danger, message, true);
	}

	@action.bound
	showWarningAlert(message: string) {
		this.showAlert(AlertType.Warning, message, true);
	}

	@action.bound
	showInfoAlert(message: string) {
		this.showAlert(AlertType.Info, message, false);
	}

	@action.bound
	showValidationErrorsAlert(validationErrors: { [s: string]: string }) {
		this.showAlert(AlertType.Danger, Object.keys(validationErrors).map(x => validationErrors[x]), true);
	}

	private showAlert(type: AlertType, content: string | string[], showCloseButton: boolean) {
		this.alertProps.alertType = type;
		this.alertProps.alertContent = content;
		this.alertProps.showCloseButton = showCloseButton;
	}
}
