import { call } from 'redux-saga/effects';
import { IBrandingSettingsSagaContext } from '../branding-settings-saga';
import { BrandingSettingsUserAction } from './actions';
import { ListingDetailsViewModel } from '../branding-settings-generated';

export function* listingChangeHandler(context: IBrandingSettingsSagaContext, action: BrandingSettingsUserAction.ChangeListing): IterableIterator<any> {
	const { mainViewModel, dataService } = context;

	if (action.listingId === mainViewModel.selectedListingId) {
		return;
	}

	if (mainViewModel.isDirty) {
		if (!confirm('You have unsaved changes, are you sure you want to switch to another listing?')) {
			return;
		}
	}

	mainViewModel.isLoadingListingDetails = true;

	try {
		const listingDetails: ListingDetailsViewModel = yield call(dataService.getListingDetails, { merchantId: Number(action.listingId) });
		mainViewModel.onSelectListing(listingDetails);
	} catch (error) {
		context.reportError(error);
	} finally {
		mainViewModel.isLoadingListingDetails = false;
	}
}
