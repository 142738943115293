import * as React from 'react';

export interface IAccessibilityOptionProps {
	id?: string;
	optionIndex?: number;
	optionsCount?: number;
	selected?: boolean;
}

/**
 * https://www.w3.org/TR/wai-aria/roles#option
 *
 * @param {(IAccessibilityOptionProps & { children?: React.ReactNode })} props
 * @returns
 */
export const AccessibilityOption: React.StatelessComponent<IAccessibilityOptionProps> = (props: IAccessibilityOptionProps & { children?: React.ReactNode }) => {
	const { id, optionIndex, optionsCount, selected, children, ...other} = props;

	return React.cloneElement((React.Children.only(children) as React.ReactElement), {
		role: 'option',
		id: props.id,
		'aria-setsize': props.optionsCount,
		'aria-posinset': props.optionIndex,
		'aria-selected': props.selected,
		...other
	});
};
