import moment from 'moment';
import { action, autorun, computed, observable, reaction } from 'mobx';
import {
	BatchViewModel,
	VirtualTerminalEventTimeViewModel,
	BatchStatus,
	EventTimeStatus,
} from '../../../virtual-terminal-generated';
import { IFormControlSelectOptionProps, IFormControlSelectOptionGroupProps } from '../../../../components/form-controls/form-control-select';
import { VirtualTerminalListingStore } from '../../../virtual-terminal-listing-store';
import { eventTimeForDisplay } from '../../../../components/event-time/event-time-display-formats';
import { DefaultSettingsViewModel } from '../../default-settings/default-settings-view-model';
import { StorageHelper } from '../../../../helpers/storagehelper';

export class BatchEntryBatchDetailsViewModel {
	@observable
	batch: BatchViewModel;

	@observable
	listingStore: VirtualTerminalListingStore;

	@observable
	defaultSettingsViewModel: DefaultSettingsViewModel;

	@observable
	giftsReceivedOn: Date;

	@observable
	showingDefaultSettings: boolean = true;

	@observable
	state: BatchDetailsState;

	@observable
	isProcessing: boolean = false;

	@observable
	validationErrors: { [key: string]: string };

	@observable
	eventTimes: VirtualTerminalEventTimeViewModel[];

	private batchWhenEditStart: BatchViewModel = null;

	private giftReceivedOnWhenEditStart: Date = null;

	constructor(listingStore: VirtualTerminalListingStore,
		defaultSettingsViewModel: DefaultSettingsViewModel,
		eventTimes: VirtualTerminalEventTimeViewModel[],
		batch: BatchViewModel | null,
		public readonly referrerUrl: string,
	) {
		this.listingStore = listingStore;
		this.defaultSettingsViewModel = defaultSettingsViewModel;
		this.eventTimes = eventTimes;

		if (batch === null) {
			this.batch = getEmptyBatchViewModel();
			this.state = BatchDetailsState.Creating;
		} else {
			this.batch = batch;
			this.state = BatchDetailsState.Viewing;

			if (batch.Status !== BatchStatus.Open) {
				this.hideDefaultSettings();
			}
		}

		//When updates to the default settings are made we need to synch it with the batch details model
		//It is a one way synch however as we would only update default settings givenOn on batch details save
		reaction(() => this.defaultSettingsViewModel.givenOn, this.synchDefaultSettingsGivenOn, { fireImmediately: true });
		//Debounce saving default settings changes to local storage
		autorun(this.storeDefaultSettingsIfBatchIsCreated, { delay: 300 });
	}

	@computed
	get batchExists() {
		return !!this.batch.BatchId;
	}

	@computed
	get listings() {
		return this.listingStore.listings;
	}

	@computed
	get selectedEventTime() {
		if (!this.batch.EventTimeId) {
			return null;
		}
		const [matchingEvent] = this.eventTimes.filter(x => x.Id === this.batch.EventTimeId);

		if (!matchingEvent) {
			window.reportUnhandledRejection(new Error('Batch has invalid event time'), {
				batchId: this.batch.BatchId,
				eventTimeId: this.batch.EventTimeId
			});
			return null;
		}

		return matchingEvent;
	}

	@computed
	get eventTimesOptions(): (IFormControlSelectOptionProps | IFormControlSelectOptionGroupProps)[] {
		if (!this.listingStore.selectedListing) {
			return this.eventTimesSorted.map(e => toEventTimeOption(e));
		}

		const eventTimesGrouped = this.eventTimesSorted.reduce((accumulator, eventTime) => {
			if (eventTime.Status === EventTimeStatus.Archived) {
				accumulator.eventTimesForListing.push(toEventTimeOptionForArchived(eventTime));
			} else if (isActiveEventTime(this.listingStore, eventTime)) {
				accumulator.eventTimesForListing.push(toEventTimeOption(eventTime));
			} else {
				accumulator.otherEventTimes.push(toEventTimeOption(eventTime));
			}

			return accumulator;
		}, { eventTimesForListing: [], otherEventTimes: [] });

		const { eventTimesForListing, otherEventTimes } = eventTimesGrouped;

		if (eventTimesForListing.length > 0 && otherEventTimes.length === 0) {
			return eventTimesForListing;
		}

		if (otherEventTimes.length > 0 && eventTimesForListing.length === 0) {
			return otherEventTimes;
		}

		return [{
			Label: `Service times active for ${this.listingStore.selectedListing.ListingName}`,
			Options: eventTimesForListing,
		}, {
			Label: 'Other service times',
			Options: otherEventTimes,
		}];

		function isActiveEventTime(listingStore: VirtualTerminalListingStore, eventTime: VirtualTerminalEventTimeViewModel): boolean {
			return listingStore.selectedListing.ActiveEventTimes.some(x => x === eventTime.Id);

		}
	}

	@action.bound
	storeCurrentBatchDetailsState() {
		this.batchWhenEditStart = { ...this.batch };
		this.giftReceivedOnWhenEditStart = this.giftsReceivedOn;
	}

	@action.bound
	revertBatchDetailsState() {
		this.batch = { ...this.batchWhenEditStart };
		this.giftsReceivedOn = this.giftReceivedOnWhenEditStart;
	}

	@action.bound
	setBatch(batch: BatchViewModel) {
		this.batch = batch;
	}

	@action.bound
	updateBatchName(name: string) {
		this.batch.Name = name;
	}

	@action.bound
	updateSelectedListing(listingId: number) {
		this.batch.ListingId = listingId;
	}

	@action.bound
	updateExpectedNumberOfGifts(expectedNumberOfGifts: number) {
		this.batch.ExpectedTotalCount = expectedNumberOfGifts;
	}

	@action.bound
	updateEventTimeId(eventTimeId: number) {
		this.batch.EventTimeId = eventTimeId;
	}

	@action.bound
	updateExpectedTotalAmount(expectedAmount: number) {
		this.batch.ExpectedTotalAmount = expectedAmount;
	}

	@action.bound
	updateGiftsReceivedOn(giftsReceivedOn: Date) {
		this.giftsReceivedOn = giftsReceivedOn;
	}

	@action.bound
	updateState(state: BatchDetailsState) {
		this.state = state;
	}

	@action.bound
	setEventTimes(eventTimes: VirtualTerminalEventTimeViewModel[]) {
		this.eventTimes = eventTimes;
	}

	@action.bound
	addEventTime(eventTime: VirtualTerminalEventTimeViewModel) {
		this.eventTimes.push(eventTime);
	}

	@action.bound
	addEventTimeToSelectedListing(eventTimeId: number) {
		this.listingStore.selectedListing.ActiveEventTimes.push(eventTimeId);
	}

	@action.bound
	updateEventTimes(newBatchEventTimeId: number) {
		// You cannot reselect an archived event time once it is deselected, so we
		// check and remove it if it is no longer selected.
		const [archivedEventTime] = this.eventTimes.filter(e => e.Status === EventTimeStatus.Archived);
		if (archivedEventTime && archivedEventTime.Id !== newBatchEventTimeId) {
			const index = this.eventTimes.indexOf(archivedEventTime);
			this.eventTimes.splice(index, 1);
		}
	}

	@action.bound
	updateValidationErrors(validationErrors: { [key: string]: string }) {
		this.validationErrors = validationErrors;
	}

	@action.bound
	showDefaultSettings() {
		this.showingDefaultSettings = true;
	}

	@action.bound
	hideDefaultSettings() {
		this.showingDefaultSettings = false;
	}

	@computed
	private get eventTimesSorted() {
		return this.eventTimes
			.orderBy(e => e.DayOfWeek)
			.thenBy(e => moment(e.StartTime, 'h:mm a'))
			.toArray();
	}

	@action.bound
	private synchDefaultSettingsGivenOn() {
		this.giftsReceivedOn = this.defaultSettingsViewModel.givenOn;
	}

	private storeDefaultSettingsIfBatchIsCreated = () => {
		if (!this.batchExists) {
			return;
		}
		StorageHelper.setDefaultSettingsForBatch(`${this.batch.BatchId}`, this.defaultSettingsViewModel.defaultSettingsModel);
	}
}

export enum BatchDetailsState {
	Creating,
	Viewing,
	Editing,
}

export function getEmptyBatchViewModel(): BatchViewModel {
	return {
		BatchId: null,
		Name: null,
		ListingId: null,
		ExpectedTotalAmount: null,
		ExpectedTotalCount: null,
		EventTimeId: null,
		Status: BatchStatus.Open,
		CreatedBy: null,
		CreatedOn: null,
		LastUpdatedBy: null,
		LastUpdatedOn: null,
		ShowExportLabel: null,
		ExportLabel: null,
		ExportButtons: null,
	};
}

function toEventTimeOption(eventTime: VirtualTerminalEventTimeViewModel): IFormControlSelectOptionProps {
	const mapped = {
		MerchantNames: null,
		Id: eventTime.Id,
		Token: eventTime.Token,
		Name: eventTime.Name,
		DayOfWeek: eventTime.DayOfWeek,
		StartTime: eventTime.StartTime,
		EndTime: eventTime.EndTime,
	};
	return { Label: eventTimeForDisplay(mapped), Value: eventTime.Id.toString() };
}

function toEventTimeOptionForArchived(eventTime: VirtualTerminalEventTimeViewModel): IFormControlSelectOptionProps {
	const option = toEventTimeOption(eventTime);
	option.Label = option.Label + ' (Archived)';
	return option;
}
