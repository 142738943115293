import { IPayerSearchResult } from './payer-search-models';
import { ArrayHelper } from '../../helpers/arrayhelper';
import {
	getNewHighlightedIndex,
	SelectableListKeyboardNavigation
} from '../hoc-behavior/selectable-list-keyboard-controller';
import { observable, action, computed } from 'mobx';

export interface IHaveOmniboxContext {
	omniboxContext?: OmniboxContext;
}

export const OmniboxContextKey = 'omniboxContext';
export const LoadMoreId = 'loadMoreItemId';
export const EmptyId = 'emptyItemId';
export const OmniboxFieldName = 'CommunityMemberId';

export class OmniboxContext {
	@observable
	omniboxFocused: boolean;

	@observable
	openRequested = false;

	@observable
	highlightedItemId: string = null;

	@observable
	ensureVisibleId: string = null;

	@observable
	searchResult: string[] = [];

	@observable
	componentId: string;

	@observable
	onPayerSelected: (payerId: string) => void;

	@observable
	onMenuStateChange: (open: boolean) => void;

	@observable
	hasLoadMore: boolean;

	@observable
	dropDownCanBeExpanded: boolean;

	@observable
	organizationalGivingEnabled: boolean;

	@computed
	get open(): boolean {
		return this.dropDownCanBeExpanded && this.openRequested;
	}

	@computed
	get listId(): string {
		return `${this.componentId}--list`;
	}

	@computed
	get activeItemId(): string {
		return this.open && typeof this.highlightedItemId === 'string' ? this.getListItemId(this.highlightedItemId) : null;
	}

	getListItemId(id: string) {
		return `${this.listId}--item-${id}`;
	}

	@action
	setFocused(focused: boolean) {
		this.omniboxFocused = focused;
	}

	@action
	handleRequestOpen = () => {
		this.onMenuStateChange(true);
	}

	@action
	handleRequestClose = () => {
		this.onMenuStateChange(false);
	}

	@action
	handleItemHighlighted = (id: string) => {
		this.ensureVisibleId = null;
		this.highlightedItemId = id;
	}

	@action
	handleItemHighlightedByKeyboard = (navigationType: SelectableListKeyboardNavigation) => {
		const results = this.hasLoadMore ? this.searchResult.concat([LoadMoreId]) : this.searchResult;
		const highlightedItemIndex = results.indexOf(this.highlightedItemId);
		const newIndex = getNewHighlightedIndex(highlightedItemIndex, results.length, navigationType);
		const id = results[newIndex];

		this.ensureVisibleId = id;
		this.highlightedItemId = id;
	}

	@action
	handleCurrentItemSelected = () => {
		this.onPayerSelected(this.highlightedItemId);
	}

	@action
	handleItemSelected = (payerId: string) => {
		this.onPayerSelected(payerId);
	}

	@action
	updateFromProps(
		searchResult: IPayerSearchResult,
		payerSelected: boolean,
		componentId: string,
		openRequested: boolean,
		onPayerSelected: (payerId: string) => void,
		onMenuStateChange: (open: boolean) => void) {

		this.componentId = componentId;
		this.onPayerSelected = onPayerSelected;
		this.dropDownCanBeExpanded = searchResult && !payerSelected;
		this.openRequested = openRequested;
		this.onMenuStateChange = onMenuStateChange;

		this.hasLoadMore = searchResult && searchResult.hasMorePages;
		const newSearchResult = searchResult ? searchResult.payers.map(x => x.id) : [];

		if (!ArrayHelper.equals(newSearchResult, this.searchResult)) {
			if (searchResult && searchResult.appendedResults) {
				//keep the highlighted item index when loaded more
				this.highlightedItemId = newSearchResult[this.searchResult.length];
			} else {
				//highlight the first item and scroll it into view
				this.highlightedItemId = newSearchResult.length > 0 ? newSearchResult[0] : null;
			}
			this.ensureVisibleId = this.highlightedItemId;
		}

		this.searchResult = newSearchResult;
	}
}
