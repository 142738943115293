import * as React from 'react';
import { take } from 'redux-saga/effects';
import { QboConfigurationSagaContext } from '../../pages/configuration/qbo-configuration-main-saga';
import { EnableIntegrationDialog, EnableIntegrationUserAction } from '../enable-integration-dialog';
import { ModalDialogCommander } from '../../../components/modal-dialog-commander';
import { QuickBooksIntegrationActionResult } from '../../qbo-integration-generated';
import { Channel, userActionChannel } from '../../../../Shared/utils/user-action-channel';

export function* enableIntegration(context: QboConfigurationSagaContext) {
	const {mainViewModel: { setServerMessage }, dataService} = context;

	try {
		const response: QuickBooksIntegrationActionResult = yield dataService.enable(null);

		const {Message, RedirectTo} = response;

		setServerMessage(Message);

		// successfully enabled - set location to RedirectTo
		document.location.href = RedirectTo;
		// never ending promise since we're doing a redirect and don't want to resolve the promise early
		yield new Promise(()=> {});
	} catch (e) {
		setServerMessage(e.response);
	}
}


export function* showEnableIntegrationDialog(context: QboConfigurationSagaContext) {
	const confirmationChannel: Channel<EnableIntegrationUserAction> = userActionChannel();

	const enableDialog = React.createElement(EnableIntegrationDialog, {userActionChannel: confirmationChannel});

	ModalDialogCommander.showReactForm(enableDialog);

	const result = yield take(confirmationChannel);

	if(result instanceof  EnableIntegrationUserAction.Cancel) {
		ModalDialogCommander.forceCloseCurrent();
		return;
	}
	yield enableIntegration(context);
}
