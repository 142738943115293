import * as React from 'react';
import { delay } from 'redux-saga';
import { call } from 'redux-saga/effects';
import { BatchListUserAction, IQueryBatchesOptions } from './batch-list-user-actions';
import { BatchListMainViewModel } from '../batch-list/batch-list-main-view-model';
import { Channel, userActionChannel } from '../../../../Shared/utils/user-action-channel';
import { sagaDataService, VirtualTerminalSagaDataService, IVirtualTerminalSagaContext } from '../../saga/virtual-terminal-saga';
import { ActionHandlers } from '../../../../Shared/utils/saga-utils';
import { alertController } from '../../../components/alert-controller';
import { BatchViewModel, BatchStatus } from '../../virtual-terminal-generated';
import { ModalDialogCommander } from '../../../components/modal-dialog-commander';
import { BatchEntryDepositFailuresDialog } from '../../components/batch-entry/batch-entry-deposit-failures-dialog';

export class BatchListSagaContext implements IVirtualTerminalSagaContext {
	userActionChannel: Channel<BatchListUserAction> = userActionChannel();
	dataService: VirtualTerminalSagaDataService = sagaDataService();
	executingBlockingAction?: boolean = false;
	constructor(public mainViewModel: BatchListMainViewModel) { }
}

export function getActionHandlers() {
	const actionHandlers = new ActionHandlers<IVirtualTerminalSagaContext, typeof BatchListUserAction[keyof typeof BatchListUserAction]>();

	actionHandlers.add(
		BatchListUserAction.QueryBatches,
		{
			async: true,
			debounceable: true,
			handler: (context, action) => queryBatches(context, action.options),
		},
	);
	actionHandlers.add(
		BatchListUserAction.DepositBatches,
		{
			async: true,
			handler: (context, action) => depositBatches(context, action.batchIds),
		}
	);

	return actionHandlers;
}

function* queryBatches(context: BatchListSagaContext, options: IQueryBatchesOptions = {}) {
	const {
		mainViewModel: {
			queryBatchesAllDebouncer,
			setRequestInProgress,
		}
	} = context;
	const { debounceDelay } = options;

	if (debounceDelay) {
		// debounce request
		yield call(delay, debounceDelay);
	}

	queryBatchesAllDebouncer.cancel();
	setRequestInProgress(true);

	yield queryBatchesInner(context, options);

	setRequestInProgress(false);
	queryBatchesAllDebouncer.exec();
}

function* queryBatchesInner(context: BatchListSagaContext, options: IQueryBatchesOptions = {}) {
	const {
		mainViewModel: {
			queryModel,
			updateBatches,
			updateBatchesOld,
			updateCanLoadMore,
		},
		dataService,
	} = context;
	const { takeAllUpToCurrentPage = false, appendResults = false } = options;
	const model = { ...queryModel, TakeAllUpToCurrentPage: takeAllUpToCurrentPage };

	const { Batches, CanLoadMore, TotalBatchCount } = yield dataService.queryBatches({ model });

	updateBatches(Batches, TotalBatchCount, appendResults);

	updateCanLoadMore(CanLoadMore);
}

function* depositBatches(context: BatchListSagaContext, batchIds: number[]) {
	const {
		mainViewModel: {
			queryBatchesAllDebouncer,
			setRequestInProgress,
		},
		dataService,
	} = context;

	queryBatchesAllDebouncer.cancel();
	setRequestInProgress(true);

	const { Batches }: { Batches: BatchViewModel[] } = yield dataService.depositBatches({ model: { BatchIds: batchIds } });
	yield queryBatchesInner(context);

	setRequestInProgress(false);
	queryBatchesAllDebouncer.exec();

	ModalDialogCommander.forceCloseCurrent();

	const failedBatches = Batches.filter(x => x.Status !== BatchStatus.Deposited);
	if (failedBatches.length === 0) {
		const countText = Batches.length > 1 ? 'selected batches have' : 'batch has';
		alertController.showSuccess(`Your ${countText} been deposited.`);
	} else {
		const errorDialog = React.createElement(BatchEntryDepositFailuresDialog, {
			batches: failedBatches.map(x => {
				return {
					batchId: x.BatchId,
					name: x.Name,
					reason: `Batch was ${getActionedName(x.Status)} by ${x.LastUpdatedBy}`
				};
			})
		});
		ModalDialogCommander.showReactDialog(errorDialog);
	}
}

function getActionedName(status: BatchStatus) {
	if (status === BatchStatus.Open) {
		return 'reopened';
	} else {
		return 'completed';
	}
}
