import { runInAction } from 'mobx';
import { BrandingPackageEditModel, ImageKind } from '../branding-settings-generated';
import { BrandingSettingsFormViewModel } from '../editor-form/branding-settings-form-view-model';
import { FormControlImageState, stateNoSelection, isUploading, isProcessing } from '../../components/form-controls/form-control-image-input/form-control-image-input';
import { IBrandingSettingsImageUploadChannels } from '../branding-settings-saga';

export function convertFormViewModelToEditModel(formViewModel: BrandingSettingsFormViewModel): BrandingPackageEditModel {
	return {
		EnhancedLogoImageKey: getImageKey(formViewModel.enhancedLogo),
		FaviconImageKey: getImageKey(formViewModel.favicon),
		BackdropImageKey: formViewModel.supportsBackdropImages ? getImageKey(formViewModel.backdrop) : null,
		BackdropMobileImageKey: formViewModel.supportsBackdropImages ? getImageKey(formViewModel.backdropMobile) : null,
		GivingTitle: formViewModel.givingTitle,
		HeroImageKey: getImageKey(formViewModel.heroImage),
		PrimaryColor: formViewModel.primaryColor,
		SecondaryColor: formViewModel.secondaryColor,
		hasOrganizationBrandingMerchantChanged: formViewModel.hasOrganizationBrandingMerchantChanged,
		IsCampusBrandingMerchant: formViewModel.isCampusBrandingMerchant,
		hasCampusBrandingMerchantChanged: formViewModel.hasCampusBrandingMerchantChanged,
		IsOrgBrandingMerchant: formViewModel.isOrganizationBrandingMerchant,
	};
}

function getImageKey(imageState: FormControlImageState) {
	if (imageState.uploadState === 'success') {
		return imageState.value;
	}

	return null;
}

export function getImageUploadChannel(channels: IBrandingSettingsImageUploadChannels, imageKey: ImageKind) {
	switch (imageKey) {
		case ImageKind.EnhancedLogo:
			return channels.enhancedLogo;
		case ImageKind.Favicon:
			return channels.favicon;
		case ImageKind.HeroImage:
			return channels.heroImage;
		case ImageKind.BackdropImage:
			return channels.backdrop;
		case ImageKind.BackdropMobileImage:
			return channels.backdropMobile;
		default:
			const unsupported: never | ImageKind.None = imageKey;
			throw new Error(`ImageKind ${unsupported} is not supported`);
	}
}

export function getImageState(viewModel: BrandingSettingsFormViewModel, imageKey: ImageKind) {
	switch (imageKey) {
		case ImageKind.EnhancedLogo:
			return viewModel.enhancedLogo;
		case ImageKind.Favicon:
			return viewModel.favicon;
		case ImageKind.HeroImage:
			return viewModel.heroImage;
		case ImageKind.BackdropImage:
			return viewModel.backdrop;
		case ImageKind.BackdropMobileImage:
			return viewModel.backdropMobile;
		default:
			const unsupported: never | ImageKind.None = imageKey;
			throw new Error(`ImageKind ${unsupported} is not supported`);
	}
}

export const setImageState = (viewModel: BrandingSettingsFormViewModel, imageKey: ImageKind) => {
	const prevState = getImageState(viewModel, imageKey);
	return (state: FormControlImageState) => {
		runInAction(() => {

			if (state.uploadState === 'uploading' || state.uploadState === 'processing') {
				if (prevState.uploadState === 'success') {
					state.previousValue = prevState.value;
					state.previousPreviewUrl = prevState.previewUrl;
				} else if (prevState.uploadState === 'uploading' || prevState.uploadState === 'processing') {
					state.previousValue = prevState.previousValue;
					state.previousPreviewUrl = prevState.previousPreviewUrl;
				}
			} else if (state === stateNoSelection) {
				if ((isUploading(prevState) || isProcessing(prevState)) && prevState.previousValue && prevState.previousPreviewUrl) {
					state = {
						uploadState: 'success',
						value: prevState.previousValue,
						previewUrl: prevState.previousPreviewUrl
					};
				}
			}

			switch (imageKey) {
				case ImageKind.EnhancedLogo:
					viewModel.enhancedLogo = state;
					break;
				case ImageKind.Favicon:
					viewModel.favicon = state;
					break;
				case ImageKind.HeroImage:
					viewModel.heroImage = state;
					break;
				case ImageKind.BackdropImage:
					viewModel.backdrop = state;
					break;
				case ImageKind.BackdropMobileImage:
					viewModel.backdropMobile = state;
					break;
				default:
					const unsupported: never | ImageKind.None = imageKey;
					throw new Error(`ImageKind ${unsupported} is not supported`);
			}
		});
	};
};
