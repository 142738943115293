import * as React from 'react';
import { ModalDialog } from '../../../components/modal-dialog';
import { Channel } from '../../../../Shared/utils/user-action-channel';
import { inject, observer } from 'mobx-react';
import { PausedContext } from '../../qbo-integration-generated';

export namespace ProcessPurgeQueueUserAction {
	export class PurgeQueue { }

	export class ProcessQueue { }
}

export type ProcessPurgeQueueUserAction = typeof ProcessPurgeQueueUserAction[keyof typeof ProcessPurgeQueueUserAction]['prototype'];

@inject('userActionChannel')
@observer
export class ProcessPurgeQueueDialog extends React.Component<{userActionChannel: Channel<ProcessPurgeQueueUserAction>, pausedContext: PausedContext}, {isProcessing: boolean}> {
	constructor(props) {
		super(props);
		this.state = { isProcessing: false };
	}

	render() {
		const { isProcessing } = this.state;
		const { pausedContext } = this.props;

		const buttons:JSX.Element[] = [
			<button type="button" key="purge" className="btn btn-ghost" onClick={this.handlePurgeClick} disabled={isProcessing}>Discard queued settlements</button>,
			<button type="button" key="process" className="btn btn-default" onClick={this.handelProcessClick} disabled={isProcessing}>Send queued settlements</button>
		];

		return(
			<ModalDialog title="Do you want to send queued settlements to QuickBooks Online?" buttons={buttons} hideCloseButton={true} showAlert={false} >
				<p>
					Your integration has been paused since {pausedContext.PausedOnDisplayDate}.
					We have queued {pausedContext.NumberOfQueuedSettlements} settlement(s) over this period.
					If you have manually entered Pushpay settlements into QuickBooks Online over this period, we recommend discarding to avoid duplication.
					Your integration will automatically be re-enabled upon dismissing this dialog box.
				</p>
			</ModalDialog>
		);
	}

	handlePurgeClick = () => {
		const { userActionChannel } = this.props;
		this.setState({isProcessing: true});
		userActionChannel.put(new ProcessPurgeQueueUserAction.PurgeQueue());
	}

	handelProcessClick = () => {
		const { userActionChannel } = this.props;
		this.setState({isProcessing: true});
		userActionChannel.put(new ProcessPurgeQueueUserAction.ProcessQueue());
	}
}
