import * as React from 'react';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import { classNames } from '../../utils/classnames';

export type SelectOptionValue = string | number;

export interface SelectOption {
	label: string;
	value: SelectOptionValue;
}

export type ISelectCommonProps = {
	formControlId?: string;
	acceptanceTestTargetId: string;
	options: SelectOption[];
	additionalClassNames?: string;
	placeholder?: string;
};

export interface ISelectPrimitiveProps {
	value: SelectOptionValue;
	onChange: (value: SelectOptionValue) => void;
	ariaInvalid?: boolean;
}

export type ISelectProps = ISelectCommonProps & ISelectPrimitiveProps;

@observer
export class Select extends React.Component<ISelectProps> {
	render() {
		const {
			formControlId,
			options,
			value,
			onChange,
			additionalClassNames,
			acceptanceTestTargetId,
			ariaInvalid,
			placeholder,
			...rest } = this.props;
		const className = classNames('form-control', additionalClassNames);
		//value could be a number
		const valueOrEmptyStringToEnsureThisIsAControlledComponent = (value === null || value === undefined) ? '' : value;
		return (
			<div className="select-wrapper">
				<select
					id={formControlId}
					value={valueOrEmptyStringToEnsureThisIsAControlledComponent}
					onChange={this.onChange}
					className={className}
					data-pp-at-target={acceptanceTestTargetId}
					aria-invalid={ariaInvalid || null}
					{...rest}>
					{placeholder && <option value="">{placeholder}</option>}
					{options.map(x => (
						<option key={x.value} value={x.value}>{x.label}</option>
					))}
				</select>
			</div>
		);
	}

	private onChange = (ev: React.FormEvent<HTMLSelectElement>) => {
		const newValue = this.optionMap[ev.currentTarget.value];
		this.props.onChange(newValue);
	}

	//Values can be numbers or strings but event values are always strings
	//We need to map the event value back to the original value
	@computed
	private get optionMap(): Record<string, SelectOptionValue> {
		return this.props.options.reduce((acc: Record<string, SelectOptionValue>, option) => {
			const { value } = option;
			acc[value.toString()] = value;
			return acc;
		}, {});
	}
}
