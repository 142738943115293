import { responsiveHelper } from '../helpers/responsive-helper';

/*
Dropdowns can run in two modes
	Submit buttons / Link buttons

Submit: Would submit it parent form with the value in specified in the button
Link button: would navigate to the value of the link

Supporting mobile:
-----------------
Add the [mobile-enabled] class
Generate the corresponding select in Razor and set the data-action on the option to either submit/link

Sample HTML
-----------------
<div class="dropdown [mobile-enabled]">
	<button type="button" class="btn btn-ghost dropdown-toggle down-arrow" data-toggle="dropdown">Export</button>
	<ul class="dropdown-menu">
		<li>
			<button name="button" type="submit" class="btn btn-link" value="1" title="Opt 1"><span>Opt 1</span></button>
		</li>
		<li>
			<button name="button" type="submit" class="btn btn-link" value="2" title="Opt 2"><span>Opt 2</span></button>
		</li>
	</ul>
	<!-- This is optional if you're using [mobile-enabled]
	<div class="select-wrapper">
		<select class="form-control">
			<option>Select a format</option>
			<option value="1" data-action="[submit/link]">Opt 1</option>
			<option value="1" data-action="[submit/link]">Opt 1</option>
		</select>
	</div>
</div>
*/
export class Dropdown {
	static OpenClass = 'open';
	static MobileEnabledClass = 'mobile-enabled';

	private dropdown: JQuery;
	private dropdownToggle: JQuery;
	private select: JQuery;

	constructor(element: Element) {
		this.dropdown = $(element);
		this.dropdownToggle = this.dropdown.find('[data-toggle=dropdown]');
		this.select = this.dropdown.find('select');
		this.bindClickHandler();
		this.bindSelectHandler();
	}

	private bindClickHandler() {
		$(document).click(e => {

			if ((responsiveHelper.isXs || responsiveHelper.isSm) && this.dropdown.hasClass(Dropdown.MobileEnabledClass)) {
				return;
			}

			if (this.dropdown.is(e.target) || this.dropdown.has(e.target).length > 0) {
				this.dropdown.toggleClass(Dropdown.OpenClass);
				this.repositionMenuIfOutsideWindowBounds();

				// if click target was the link/button preventDefault so we don't get # appended to the url
				if (this.dropdownToggle.is(e.target)) {
					e.preventDefault();
				}
			} else {
				this.dropdown.removeClass(Dropdown.OpenClass);
				this.repositionMenuIfOutsideWindowBounds();
			}
		});
	}

	private bindSelectHandler() {
		if (this.select === null) {
			return;
		}

		this.select.change(e => {
			var option = this.select.find('option:selected');

			switch (option.data('action')) {
				case 'submit':
					this.generateSubmitRequestFromOption(option);
					break;

				case 'link':
				case 'navigate':
					window.location.href = option.attr('value');
					break;
				default:
					break;
			}
		});
	}

	private generateSubmitRequestFromOption(option: JQuery) {
		let form = this.select.closest('form');

		let inputVal = form.find('input[name="button"]');

		if (inputVal.length === 0) {
			inputVal = $('<input type="hidden" name="button"/>')
				.attr('type', 'hidden');
		}

		// reset for future
		this.select.find('option:eq(0)').prop('selected', true);

		inputVal.val(option.attr('value'));

		form.append(inputVal);
		form.submit();
	}

	private repositionMenuIfOutsideWindowBounds() {
		let dropdownMenu = this.dropdown.find('.dropdown-menu');

		if (dropdownMenu && dropdownMenu.hasClass('.dropdown-menu-right')) {
			return;
		}

		var rect = dropdownMenu[0].getBoundingClientRect();

		if (rect.right > $(window).width()) {
			dropdownMenu.addClass('dropdown-menu-right');
		}
	}
}
