import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { BatchEntryBatchDetailsViewModel, BatchDetailsState } from './batch-entry-batch-details-view-model';
import { injectAnalytics, recordEvent, WithAnalytics } from '../../../../analytics';
import { FormControlLabelled, FormControlType } from '../../../../components/form-controls/form-control-labelled';
import { ModelMetadata, MerchantAdminRole } from '../../../virtual-terminal-generated';
import { SvgWrapper } from '../../../../components/svg-wrapper';
import { nullableNumber } from '../../../../utils/nullable-number';
import { TooltipComponent } from '../../../../components/tooltip-component';
import { BatchEntryUserActionCreator } from '../../../pages/batch-entry/batch-entry-user-actions';
import { PaymentTotal } from '../../batch-entry-payments/batch-entry-payments-store';
import { BatchEntryBatchDetailsCreate } from './batch-entry-batch-details-create';
import { BatchEntryBatchDetailsEdit } from './batch-entry-batch-details-edit';
import { BatchEntryBatchDetailsView } from './batch-entry-batch-details-view';

import * as styles from './batch-entry-batch-details.less';

const injectUserActionCreator = 'userActionCreator';
const metadata = ModelMetadata.CreateBatchRequestModel;

@observer
export class BatchEntryBatchDetails extends React.Component<{
	vm: BatchEntryBatchDetailsViewModel,
	batchTotal: PaymentTotal,
	batchCount: PaymentTotal,
}> {
	render() {
		const { vm : { state } } = this.props;

		switch(state) {
			case BatchDetailsState.Creating:
				return <BatchEntryBatchDetailsCreate {...this.props} />;
			case BatchDetailsState.Editing:
				return <BatchEntryBatchDetailsEdit {...this.props} />;
			case BatchDetailsState.Viewing:
				return <BatchEntryBatchDetailsView {...this.props} />;
			default:
				const unsupported: never = state;
				throw new Error(`Unsupported BatchDetailsState ${unsupported}`);
		}
	}
}

@injectAnalytics
@observer
export class BatchTitleInput extends React.Component<WithAnalytics & {
	label: string,
	requestModelMetadata: typeof ModelMetadata.CreateBatchRequestModel | typeof ModelMetadata.EditBatchRequestModel,
	batchName: string,
	updateBatchName: (name: string) => void }
> {
	render() {
		const { batchName, label, requestModelMetadata } = this.props;
		return (
			<div className={styles.titleContainer}>
				<FormControlLabelled cssClassNames={styles.titleInputWrapper}
					label={label}
					formControlProps={{
						formControlType: FormControlType.Text,
						name: requestModelMetadata.Name.propertyName,
						type: 'text',
						value: batchName,
						placeholder: 'Type batch name',
						maxLength: requestModelMetadata.Name.validationRules.length.parameters.max,
						validationRules: requestModelMetadata.Name.validationRules,
						onChangeHandler: this.handleBatchNameChange,
						...(NewFeatures.SetupPinpointAnalytics && { onBlurHandler: this.handleBlur }),
						acceptanceTestTargetId: 'Batch title',
						cssClassNames: styles.titleInput,
					}}
				/>
				{
					batchName &&
					<div className={styles.clearContainer}>
						<span className={styles.clearSpacer}>{batchName}</span>
						<button type="button" className={`btn btn-gray-link btn-xs ${styles.clearBtn}`} onClick={this.clearBatchName}>
							<SvgWrapper svg="icon-xs-cross" className={styles.clearIcon} />
						</button>
					</div>
				}
			</div>
		);
	}

	private handleBatchNameChange = (ev: React.FormEvent<HTMLInputElement>) => {
		this.props.updateBatchName(ev.currentTarget.value);
	}

	private handleBlur = () => {
		if (NewFeatures.SetupPinpointAnalytics && this.props.analytics && this.props.batchName) {
			const { feature, subFeature } = this.props.analytics;
			recordEvent({ feature, subFeature, eventTypeLabel: 'enterBatchTitle' });
		}
	}

	private clearBatchName = () => {
		this.props.updateBatchName('');
	}
}

export const ListingDisplay = ({ listingName, cssClassNames }: { listingName: string, cssClassNames?: string }) => {
	return(
		<div className={`form-group ${cssClassNames}`}>
			<span className={styles.fieldTitle}>Your listing</span>
			<div className={styles.listingName}>{listingName}</div>
		</div>
	);
};

@injectAnalytics
@inject(injectUserActionCreator)
@observer
export class EventTimeInput extends React.Component<WithAnalytics & { vm: BatchEntryBatchDetailsViewModel, verbPresentTenseLowerCase: string, userActionCreator?: BatchEntryUserActionCreator  }> {
	render() {
		const { vm, verbPresentTenseLowerCase } = this.props;
		const { eventTimesOptions, batch, listingStore } = vm;
		const showAddServiceTime = listingStore.selectedListing && listingStore.selectedListing.Role === MerchantAdminRole.CanDoEverything;
		const hasNoEventTimes = eventTimesOptions.length === 0;
		const tooltipOptions = {
			message: `Adding a service time helps you track your ${verbPresentTenseLowerCase} more accurately. You can manage your service times in Settings.`
		};

		if (hasNoEventTimes) {
			return (
				<div className="form-group">
					<span className={styles.fieldTitle}>Service time (optional)<TooltipComponent className="info-tooltip" {...tooltipOptions} /></span>
					<div>
						{/*tslint:disable-next-line:max-line-length*/}
						There are no service times for this listing. <button type="button" onClick={this.handleLaunchAddEventTimeDialog} className="btn btn-link btn-sm btn-link-inline">Add service time</button> for this batch.
					</div>
				</div>
			);
		}

		return(
			<div className="row">
				<FormControlLabelled cssClassNames="col-md-6 col-lg-4"
					label="Service time (optional)"
					tooltipOptions={{ ...tooltipOptions }}
					formControlProps={{
						formControlType: FormControlType.DropDown,
						name: metadata.EventTimeId.propertyName,
						placeholder: 'Select service time',
						value: (batch.EventTimeId && batch.EventTimeId.toString()) || '',
						onChangeHandler: this.updateEventTimeId,
						...(NewFeatures.SetupPinpointAnalytics && { onBlurHandler: this.handleBlur }),
						Options: eventTimesOptions,
						acceptanceTestTargetId: 'Service time',
					}} />
					{
						showAddServiceTime &&
						<button type="button" onClick={this.handleLaunchAddEventTimeDialog} className={`btn btn-sm btn-link ${styles.addServiceTime}`}>
							<SvgWrapper svg="icon-add" className="icon" />
							Add service time
						</button>
					}
			</div>
		);
	}

	private updateEventTimeId = (ev: React.FormEvent<HTMLSelectElement>) => {
		let newValue = Number(ev.currentTarget.value);
		// FormControlLabelled is returning '0' instead of 'null' when you select
		// a value, then reselect the default/unselected option.
		if (newValue === 0) {
			newValue = null;
		}
		this.props.vm.updateEventTimeId(newValue);


	}

	private handleBlur = () => {
		const { analytics, vm: { batch: { EventTimeId } } } = this.props;
		if (analytics && EventTimeId) {
			const { feature, subFeature } = this.props.analytics;
			recordEvent({ feature, subFeature, eventTypeLabel: 'selectEventTime' });
		}
	}

	private handleLaunchAddEventTimeDialog = () => {
		this.props.userActionCreator.LaunchAddServiceTimeDialog();
	}
}

@injectAnalytics
@observer
export class GiftsReceivedOnInput extends React.Component<WithAnalytics & { nounPluralSentenceCase: string, giftsReceivedOn: Date, updateGiftsReceivedOn: (giftsReceivedOn: Date) => void }> {
	render() {
		const { nounPluralSentenceCase, giftsReceivedOn, updateGiftsReceivedOn } = this.props;
		return (
			<div className="row">
				<FormControlLabelled cssClassNames="col-md-6 col-lg-4"
					label={`${nounPluralSentenceCase} received on (optional)`}
					formControlProps={{
						formControlType: FormControlType.DatePicker,
						name: 'giftsReceivedOn',
						placeholder: 'Choose a date',
						value: giftsReceivedOn,
						onChangeHandler: updateGiftsReceivedOn,
						...(NewFeatures.SetupPinpointAnalytics && { onBlurHandler: this.handleBlur }),
						acceptanceTestTargetId: 'Gift received on',
					}} />
			</div>
		);
	}

	handleBlur = () => {
		if (this.props.analytics && this.props.giftsReceivedOn) {
			const { feature, subFeature } = this.props.analytics;
			recordEvent({ feature, subFeature, eventTypeLabel: 'enterGiftsReceivedOn' });
		}
	}
}

@injectAnalytics
@observer
export class GiftTotalInputs extends React.Component<WithAnalytics & { vm: BatchEntryBatchDetailsViewModel, nounPluralLowerCase: string}> {
	render() {
		const {
			vm: {
				batch,
				updateExpectedTotalAmount,
			},
			nounPluralLowerCase,
		} = this.props;
		return(
			<div className="row">
				<div className="col-md-6 col-lg-4">
					<div className="row">
						<FormControlLabelled cssClassNames="col-sm-6"
							label={`Number of ${nounPluralLowerCase} (optional)`}
							formControlProps={{
								formControlType: FormControlType.Tel,
								type: 'tel',
								name: metadata.ExpectedTotalCount.propertyName,
								placeholder: '0',
								value: `${batch.ExpectedTotalCount || ''}`,
								onChangeHandler: this.updateExpectedNumberOfGifts,
								...(NewFeatures.SetupPinpointAnalytics && { onBlurHandler: this.handleNumberOfGiftsBlur }),
								acceptanceTestTargetId: 'Number of gifts',
							}} />
						<div className={`col-sm-6 ${styles.amountWrapper}`}>
							<FormControlLabelled label="Total amount (optional)"
								formControlProps={{
									formControlType: FormControlType.Amount,
									name: metadata.ExpectedTotalAmount.propertyName,
									placeholder: '0.00',
									value: batch.ExpectedTotalAmount,
									onChangeHandler: updateExpectedTotalAmount,
									...(NewFeatures.SetupPinpointAnalytics && { onBlurHandler: this.handleBatchTotalBlur }),
									acceptanceTestTargetId: 'Batch total',
									inputClassName: styles.amountInput,
								}} />
						</div>
					</div>
				</div>
				<div className={`col-sm-6 col-md-4 ${styles.totalsMessage}`}>
					Number of {nounPluralLowerCase} and total amount will be calculated automatically based on the {nounPluralLowerCase} you are entering.
				</div>
			</div>
		);
	}

	private handleNumberOfGiftsBlur = () => {
		if (this.props.analytics && this.props.vm.batch.ExpectedTotalCount) {
			const { feature, subFeature } = this.props.analytics;
			recordEvent({ feature, subFeature, eventTypeLabel: 'enterNumberOfGifts' });
		}
	}

	private handleBatchTotalBlur = () => {
		if (this.props.analytics && this.props.vm.batch.ExpectedTotalAmount) {
			const { feature, subFeature } = this.props.analytics;
			recordEvent({ feature, subFeature, eventTypeLabel: 'enterBatchTotal' });
		}
	}

	private updateExpectedNumberOfGifts = (ev: React.FormEvent<HTMLInputElement>) => {
		this.props.vm.updateExpectedNumberOfGifts(ev.currentTarget.value === '' ? null : nullableNumber(ev.currentTarget.value));
	}
}
