import { observable, computed, action, ObservableMap } from 'mobx';
import { BrandingPackageViewModel, ImageKind, ModelMetadata } from '../branding-settings-generated';
import { FormControlImageState, stateNoSelection, isUploading, isProcessing } from '../../components/form-controls/form-control-image-input/form-control-image-input';

export class BrandingSettingsFormViewModel {

	@observable
	givingTitle: string;

	@observable
	primaryColor: string;

	@observable
	boxedSecondaryColor: { value: string };

	@observable
	hasOrganizationBrandingMerchantChanged: boolean = false;

	@observable
	isOrganizationBrandingMerchant: boolean;

	@observable
	hasCampusBrandingMerchantChanged: boolean = false;

	@observable
	isCampusBrandingMerchant: boolean;

	@observable
	hasReliableOrgAndCampusBranding: boolean;

	@observable
	canConfigureOrgDefaultBranding: boolean;

	@computed
	get secondaryColor() {
		return this.boxedSecondaryColor.value;
	}

	set secondaryColor(value: string) {
		this.boxedSecondaryColor = { value };
	}

	@observable
	heroImage: FormControlImageState = stateNoSelection;

	@observable
	enhancedLogo: FormControlImageState = stateNoSelection;

	@observable
	favicon: FormControlImageState = stateNoSelection;

	@observable
	backdrop: FormControlImageState = stateNoSelection;

	@observable
	backdropMobile: FormControlImageState = stateNoSelection;

	@observable
	draftState: BrandingSettingsDraftState = BrandingSettingsDraftState.Saved;

	@observable
	draftKey: string;

	@observable
	errorMap: ObservableMap<string, string> = observable.map<string, string>();

	@observable
	eTag: string;

	@observable
	givingPageUrl: string;

	setupGuideUrl: string;

	organizationName: string;

	@computed
	get isFormValid() {
		return !Array.from(this.errorMap.values()).some(x => !!x);
	}

	isImageValid = (imageKind: ImageKind): boolean => {
		switch (imageKind) {
			case ImageKind.HeroImage:
				return !this.errorMap.get(ModelMetadata.BrandingPackageEditModel.HeroImageKey.propertyName);
			case ImageKind.EnhancedLogo:
				return !this.errorMap.get(ModelMetadata.BrandingPackageEditModel.EnhancedLogoImageKey.propertyName);
			case ImageKind.Favicon:
				return !this.errorMap.get(ModelMetadata.BrandingPackageEditModel.FaviconImageKey.propertyName);
			case ImageKind.BackdropImage:
				return !this.errorMap.get(ModelMetadata.BrandingPackageEditModel.BackdropImageKey.propertyName);
			default:
				return false;
		}
	}

	getImageError = (imageKind: ImageKind): string => {
		switch (imageKind) {
			case ImageKind.HeroImage:
				return this.errorMap.get(ModelMetadata.BrandingPackageEditModel.HeroImageKey.propertyName);
			case ImageKind.EnhancedLogo:
				return this.errorMap.get(ModelMetadata.BrandingPackageEditModel.EnhancedLogoImageKey.propertyName);
			case ImageKind.Favicon:
				return this.errorMap.get(ModelMetadata.BrandingPackageEditModel.FaviconImageKey.propertyName);
			case ImageKind.BackdropImage:
				return this.errorMap.get(ModelMetadata.BrandingPackageEditModel.BackdropImageKey.propertyName);
			default:
				return undefined;
		}
	}

	@action.bound
	failedToSave(validationErrors?: { [key: string]: string }) {
		this.draftState = BrandingSettingsDraftState.Unsaved;
		if (validationErrors) {
			this.errorMap.merge(validationErrors);
		}
	}

	@action.bound
	onFocusSecondaryColor() {
		if (this.heroImage.uploadState === 'success') {
			// This is to trigger the preview refresh.
			this.boxedSecondaryColor = { value: this.secondaryColor };
		}
	}

	@action.bound
	clearValidationErrors() {
		this.errorMap.clear();
	}

	@computed
	get isSavingOrUploadingOrProcessing() {
		return this.draftState === BrandingSettingsDraftState.Changed
			|| this.draftState === BrandingSettingsDraftState.Saving
			|| isUploading(this.heroImage)
			|| isUploading(this.enhancedLogo)
			|| isUploading(this.favicon)
			|| isUploading(this.backdrop)
			|| isProcessing(this.heroImage)
			|| isProcessing(this.enhancedLogo)
			|| isProcessing(this.favicon)
			|| isProcessing(this.backdrop);
	}

	@action.bound
	applyBrandingPackage(brandingPackage?: BrandingPackageViewModel) {
		if (brandingPackage) {
			this.isCampusBrandingMerchant = brandingPackage.IsCampusBrandingMerchant;
			this.isOrganizationBrandingMerchant = brandingPackage.IsOrgBrandingMerchant;
			this.givingTitle = brandingPackage.GivingTitle;
			this.primaryColor = brandingPackage.PrimaryColor;
			this.secondaryColor = brandingPackage.SecondaryColor;
			this.draftKey = brandingPackage.DraftKey;
			this.eTag = brandingPackage.ETag;
			this.givingPageUrl = brandingPackage.GivingPageUrl;
			this.setupGuideUrl = brandingPackage.SetupGuideUrl;

			if (brandingPackage.EnhancedLogoImageKey) {
				this.enhancedLogo = {
					previewUrl: brandingPackage.EnhancedLogoImageUrl,
					uploadState: 'success',
					value: brandingPackage.EnhancedLogoImageKey,
				};
			} else {
				this.enhancedLogo = stateNoSelection;
			}

			if (brandingPackage.FaviconImageKey) {
				this.favicon = {
					previewUrl: brandingPackage.FaviconImageUrl,
					uploadState: 'success',
					value: brandingPackage.FaviconImageKey,
				};
			} else {
				this.favicon = stateNoSelection;
			}

			if (brandingPackage.HeroImageKey) {
				this.heroImage = {
					previewUrl: brandingPackage.HeroImageUrl,
					uploadState: 'success',
					value: brandingPackage.HeroImageKey,
				};
			} else {
				this.heroImage = stateNoSelection;
			}

			if (this.supportsBackdropImages) {
				if (brandingPackage.BackdropImageKey) {
					this.backdrop = {
						previewUrl: brandingPackage.BackdropImageUrl,
						uploadState: 'success',
						value: brandingPackage.BackdropImageKey,
					};
				} else {
					this.backdrop = stateNoSelection;
				}

				if (brandingPackage.BackdropMobileImageKey) {
					this.backdropMobile = {
						previewUrl: brandingPackage.BackdropMobileImageUrl,
						uploadState: 'success',
						value: brandingPackage.BackdropMobileImageKey,
					};
				} else {
					this.backdropMobile = stateNoSelection;
				}
			}
		}
	}

	@action.bound
	setCanConfigureOrgDefaultBranding(canConfigure: boolean) {
		this.canConfigureOrgDefaultBranding = canConfigure
	}

	constructor(public supportsBackdropImages: boolean, data?: BrandingPackageViewModel) {
		this.applyBrandingPackage(data);
	}

	getPackageChanges = (snapshot: BrandingPackageSnapshot): Partial<BrandingPackageViewModel> => {
		const changes: Partial<BrandingPackageViewModel> = {};
		if (snapshot.givingTitle !== this.givingTitle) {
			changes.GivingTitle = this.givingTitle;
		}

		if (snapshot.primaryColor !== this.primaryColor) {
			changes.PrimaryColor = this.primaryColor;
		}

		if (snapshot.boxedSecondaryColor !== this.boxedSecondaryColor) {
			changes.SecondaryColor = this.secondaryColor;
		}

		if (snapshot.isOrganizationBrandingMerchant !== this.isOrganizationBrandingMerchant) {
			changes.IsOrgBrandingMerchant = this.isOrganizationBrandingMerchant;
		}

		if (snapshot.isCampusBrandingMerchant !== this.isCampusBrandingMerchant) {
			changes.IsCampusBrandingMerchant = this.isCampusBrandingMerchant;
		}

		if (this.enhancedLogo.uploadState === 'success' && snapshot.enhancedLogoImageKey !== this.enhancedLogo.value) {
			changes.EnhancedLogoImageKey = this.enhancedLogo.value;
			changes.EnhancedLogoImageUrl = this.enhancedLogo.previewUrl;
		} else if (this.enhancedLogo.uploadState === 'no-selection' && snapshot.enhancedLogoImageKey) {
			changes.EnhancedLogoImageKey = '';
			changes.EnhancedLogoImageUrl = '';
		}

		if (this.favicon.uploadState === 'success' && snapshot.faviconImageKey !== this.favicon.value) {
			changes.FaviconImageKey = this.favicon.value;
			changes.FaviconImageUrl = this.favicon.previewUrl;
		} else if (this.favicon.uploadState === 'no-selection' && snapshot.faviconImageKey) {
			changes.FaviconImageKey = '';
			changes.FaviconImageUrl = '';
		}

		if (this.heroImage.uploadState === 'success' && snapshot.heroImageKey !== this.heroImage.value) {
			changes.HeroImageKey = this.heroImage.value;
			changes.HeroImageUrl = this.heroImage.previewUrl;
		} else if (this.heroImage.uploadState === 'no-selection' && snapshot.heroImageKey) {
			changes.HeroImageKey = '';
			changes.HeroImageUrl = '';
		}

		if (this.supportsBackdropImages) { 
			if (this.backdrop.uploadState === 'success' && snapshot.backdropImageKey !== this.backdrop.value) {
				changes.BackdropImageKey = this.backdrop.value;
				changes.BackdropImageUrl = this.backdrop.previewUrl;
			} else if (this.backdrop.uploadState === 'no-selection' && snapshot.backdropImageKey) {
				changes.BackdropImageKey = '';
				changes.BackdropImageUrl = '';
			}
		}

		return changes;
	}

	getInitialPackage = (): Partial<BrandingPackageViewModel> => {
		let brandingPackage: Partial<BrandingPackageViewModel> = this.supportsBackdropImages ? {
			GivingTitle: this.givingTitle,
			PrimaryColor: this.primaryColor,
			SecondaryColor: this.secondaryColor,
			EnhancedLogoImageKey: '',
			EnhancedLogoImageUrl: '',
			FaviconImageKey: '',
			FaviconImageUrl: '',
			HeroImageKey: '',
			HeroImageUrl: '',
			BackdropImageKey: '',
			BackdropImageUrl: '',
		} : {
			GivingTitle: this.givingTitle,
			PrimaryColor: this.primaryColor,
			SecondaryColor: this.secondaryColor,
			EnhancedLogoImageKey: '',
			EnhancedLogoImageUrl: '',
			FaviconImageKey: '',
			FaviconImageUrl: '',
			HeroImageKey: '',
			HeroImageUrl: ''
		};

		if (this.enhancedLogo.uploadState === 'success') {
			brandingPackage.EnhancedLogoImageKey = this.enhancedLogo.value;
			brandingPackage.EnhancedLogoImageUrl = this.enhancedLogo.previewUrl;
		}

		if (this.favicon.uploadState === 'success') {
			brandingPackage.FaviconImageKey = this.favicon.value;
			brandingPackage.FaviconImageUrl = this.favicon.previewUrl;
		}

		if (this.heroImage.uploadState === 'success') {
			brandingPackage.HeroImageKey = this.heroImage.value;
			brandingPackage.HeroImageUrl = this.heroImage.previewUrl;
		}

		if (this.supportsBackdropImages) {
			if (this.backdrop.uploadState === 'success') {
				brandingPackage.BackdropImageKey = this.backdrop.value;
				brandingPackage.BackdropImageUrl = this.backdrop.previewUrl;
			}
		}

		return brandingPackage;
	}
}

export enum BrandingSettingsFormField {
	GivingTitle,
	PrimaryColor,
	SecondaryColor,
	OrganizationBranding,
	CampusBranding,
}

export enum BrandingSettingsDraftState {
	Changed = 0,
	Unsaved = 1,
	Saving = 2,
	Saved = 3,
}

export interface BrandingPackageSnapshot {
	givingTitle: string;
	primaryColor: string;
	boxedSecondaryColor: { value: string };
	enhancedLogoImageKey: string;
	faviconImageKey: string;
	heroImageKey: string;
	backdropImageKey: string;
	isOrganizationBrandingMerchant: boolean;
	isCampusBrandingMerchant: boolean;
}
