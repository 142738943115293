import * as React from 'react';
import { ResponseTypes, ModelMetadata } from '../../funds/funds-generated';
import { observer } from 'mobx-react';
import { computed } from 'mobx';

import TooltipComponent from '../components/configuration-section-tooltip';

import { InputField, ValidationMessage, SelectField } from '../../funds/components/form-controls';

import { ListingModel } from '../components/listing-model';
import { IntegrationConfigurationStore } from '../components/integration-configuration-store';
import { AuthenticationSection } from '../components/authentication-section';
import { ConfigurationSectionHeader } from '../components/configuration-section-header';
import { IntegrationContainer } from '../components/integration-container';
import { ConfigurationSection } from '../components/configuration-section';
import { ListingSettingsEditor } from '../components/listing-configuration';
import { FallbackFundSelector } from '../components/fallback-fund-selector';
import getFundsForDisplay from '../helpers/get-integration-funds-for-display';

import FellowshipOneConfigurationViewModel = ResponseTypes.FellowshipOneConfigurationViewModel;
import FellowshipOneListingConfigurationModel = ResponseTypes.FellowshipOneListingConfigurationModel;
import IntegrationListingConfigurationViewModel = ResponseTypes.IntegrationListingConfigurationViewModel;

export class FellowshipOneIntegrationConfigurationStore
	extends IntegrationConfigurationStore<FellowshipOneListingConfigurationModel, FellowshipOneConfigurationViewModel> {

	@computed
	get defaultStatusForPeople() {
		return this.configuration.DefaultStatusForPeople;
	}

	@computed
	get peopleStatuses() {
		return this.configuration.PeopleStatuses.orderBy(x => x.Text.toLowerCase()).toArray();
	}

	@computed
	get funds() {
		return getFundsForDisplay(this.configuration.IntegrationFunds);
	}

	createListing(listing: IntegrationListingConfigurationViewModel<FellowshipOneListingConfigurationModel>) {
		const messageWhenMissingFallbackFunds = this.isListingMissingFallbackFund(listing, this.configuration.IntegrationFunds)
			? ModelMetadata.FellowshipOneListingConfigurationModel.FallbackFundKey.validationRules.required.errorMessage
			: null;

		return new ListingModel(listing, this.funds, messageWhenMissingFallbackFunds);
	}
}

@observer
export class FellowshipOneIntegration extends React.Component<{ controller: FellowshipOneIntegrationConfigurationStore }, {}> {

	renderAuthenticationSection = () => {
		return <FellowshipOneAuthenticationSection controller={this.props.controller} />;
	}

	renderConfigurationSection = () => {
		return <FellowshipOneConfigurationSection controller={this.props.controller} />;
	}

	render() {
		return <IntegrationContainer
			isAuthenticationSectionEditable={this.props.controller.isAuthenticationSectionEditable}
			authenticationSectionFactory={this.renderAuthenticationSection}
			configurationSectionFactory={this.renderConfigurationSection} />;
	}
}

@observer
class FellowshipOneAuthenticationSection extends React.Component<{ controller: FellowshipOneIntegrationConfigurationStore }, {}> {
	render() {
		return (
			<AuthenticationSection store={this.props.controller}>
				<div className="form-group">
					<label className="col-md-3 control-label">{this.metadata.ChurchCode.displayName}</label>
					<div className="col-md-5">
						<InputField type="text" propertyMetadata={this.metadata.ChurchCode} ignorePanLikeValidation={true} />
					</div>
					<div className="col-md-3">
						<ValidationMessage for={this.metadata.ChurchCode.propertyName} />
					</div>
				</div>
			</AuthenticationSection>
		);
	}

	private get metadata() {
		return ModelMetadata.FellowshipOneAuthenticationEditModel;
	}
}

const message = `If we run into any trouble matching transactions to Fellowship One
we'll use the fallback information you've selected here.`;

// ReSharper disable once InconsistentNaming
const FellowshipOneListingRowHeader = React.createFactory(() => (
	<span><span>Select fallback funds in Fellowship One </span><TooltipComponent message={message} /></span>
));

@observer
class FellowshipOneConfigurationSection extends React.Component<{ controller: FellowshipOneIntegrationConfigurationStore }, {}> {
	renderOrganizationSettings = () => {
		return (
			<div className="organization-settings">
				<div className="form-group">
					<label className="control-label">
						{this.metadata.DefaultStatusForPeople.displayName} <TooltipComponent message={'If we are unable to match with an '
						+'existing person, a new person is created with the status selected here. You can also link a custom status you '
						+'added in F1, such as “New from Pushpay”.'} />
					</label>
					<div className="control">
						<SelectField
							floatingLabel="Select a status"
							propertyMetadata={this.metadata.DefaultStatusForPeople}
							defaultValue={this.props.controller.defaultStatusForPeople}>
							<option value="">- Select a status-</option>
							${this.props.controller.peopleStatuses.map(x => <option key={x.Value} value={x.Value}>{x.Text}</option>)}
						</SelectField>
						<ValidationMessage for={this.metadata.DefaultStatusForPeople.propertyName} />
					</div>
				</div>
			</div>
		);
	}

	render() {
		return (
			<div>
				<ConfigurationSectionHeader store={this.props.controller} />

				<ConfigurationSection
					store={this.props.controller}
					headerComponentFactory={FellowshipOneListingRowHeader}
					organizationSettings={this.renderOrganizationSettings()}
					listingSettingsEditor={FellowshipOneListingSettingsEditor} />
			</div>
		);
	}

	private get metadata() {
		return ModelMetadata.FellowshipOneConfigurationEditModel;
	}
}

class FellowshipOneListingSettingsEditor extends React.Component<{ listing: ListingModel<FellowshipOneListingConfigurationModel> }, {}> {
	render() {
		return (
			<ListingSettingsEditor>
				<FallbackFundSelector
					propertyMetadata={this.metadata.FallbackFundKey}
					listing={this.props.listing}
					defaultValue={this.props.listing.model.FallbackFundKey} />
			</ListingSettingsEditor>
		);
	}

	private get metadata() {
		return ModelMetadata.FellowshipOneListingConfigurationModel;
	}
}
