import * as React from 'react';
import { BatchListViewModel } from './batch-list-view-model';
import { BatchList } from './batch-list';
import { BatchAddNewDepositLink } from './batch-add-new-deposit-link';
import { TipBox } from '../../components/tipbox';
import {BatchListHelp} from './batch-help-screens';
import { BatchEmptyView } from './batch-empty-view';
import { observer } from 'mobx-react';

@observer
export class BatchListView extends React.Component<{ store: BatchListViewModel }> {

	noRegisteredScannersTipBox = () => {
		return (
			<TipBox icon="cd-check" storageKey="no-scanners" dismissable={false}>
				<div className="tipbox-header">
					You need to register a check scanner
				</div>
				<p className="cd-tip-box-content">
					You need to have a supported check scanner registered to be able to scan and process checks.
					<br />You can add a new check scanner on the <a href={this.props.store.registeredScannersRedirectUrl}>Settings page</a>
				</p>
			</TipBox>
		);
	}

	render() {
		const { numberOfRegisteredScanners, hasBatches, batchListStore, batchDialogStore, payButtonLabel, isPaymentEntryOnlyRole } = this.props.store;
		return (
			<div>
				<div className="page-head">
					<header>
						<h1>
							Check Deposit
							<span className="header-buttons">
								<BatchAddNewDepositLink store={this.props.store.batchDialogStore} />
							</span>
						</h1>
					</header>
				</div>
				{!isPaymentEntryOnlyRole && numberOfRegisteredScanners === 0 ? this.noRegisteredScannersTipBox() : null}
				<BatchListHelp payButtonLabel={payButtonLabel} />
				{hasBatches ? <BatchList store={batchListStore}/> : <BatchEmptyView store={batchDialogStore} />}
			</div>
		);
	}
}
