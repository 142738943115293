import {
	Fund, ScheduleImportDraftDisplayStatus
} from '../../schedule-import-generated';
import * as React from 'react';
import { SvgWrapper } from '../../../components/svg-wrapper';

import * as styles from './styles.less';
import { Formatter } from 'helpers/formatter';

export module ScheduleHelper {
	export const statusFormatter = (status: ScheduleImportDraftDisplayStatus): React.ReactNode => {
		switch (status) {
			case ScheduleImportDraftDisplayStatus.Migrated:
				return (
					<span className={`${styles.statusActive} ${styles.status}`}>
						<SvgWrapper svg="ind-tick" className={styles.statusIcon}/><span className="hidden-xs"> Migrated</span>
					</span>
				);
			case ScheduleImportDraftDisplayStatus.MigratedElsewhere:
				return (
					<span className={`${styles.statusActive} ${styles.status}`}>
						<SvgWrapper svg="ind-tick" className={styles.statusIcon}/><span className="hidden-xs"> Migrated elsewhere</span>
					</span>
				);
			case ScheduleImportDraftDisplayStatus.Declined:
				return (
					<span className={`${styles.statusDeclined} ${styles.status}`}>
						<SvgWrapper svg="ind-cross" className={styles.statusIcon}/><span className="hidden-xs"> Declined</span>
					</span>
				);
			case ScheduleImportDraftDisplayStatus.Awaiting:
				return (
					<span className={`${styles.statusAwaiting} ${styles.status}`}>
						<SvgWrapper svg="ind-clock" className={styles.statusIcon}/><span className="hidden-xs"> Awaiting</span>
					</span>
				);
			case ScheduleImportDraftDisplayStatus.NotReached:
				return (
					<span className={`${styles.statusDeclined} ${styles.status}`}>
						<SvgWrapper svg="ind-cross" className={styles.statusIcon}/><span className="hidden-xs"> Not reached</span>
					</span>
				);
		}
		return <span className={`${styles.statusDeclined} ${styles.status}`}><SvgWrapper svg="ind-question" className={styles.statusIcon} /> Unknown</span>;
	};

	export const seenEmailFormatter = (hasSeenEmail: boolean): string => hasSeenEmail ? 'Opened' : null;

	export const fundFormatter = (fund: Fund): string => fund.Name;

	export const amountFormatter = (amount: number): React.ReactNode => <strong>${Formatter.formatNumberForDisplay(amount)}</strong>;
}
