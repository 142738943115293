import React, { useMemo } from 'react';

import { WarningIcon, LinkIcon, UnlinkIcon, PencilWriteIcon } from '@pushpay/iconography';
import { Link } from '@pushpay/link';
import { NavigationItem } from '@pushpay/shell/lib/types/components/navigation/types';

import { AdministeredOrganization, NavItem, NavMenuItemType } from '../../loggedinweb-generated';
import { navigationIcons } from './navigation-icons';
import { useSideBarStyles } from './side-bar-styles';

type UseNavigationItemsArgs = {
	currentOrganization: AdministeredOrganization;
	navItems: NavItem[];
	feedbackUrl: string;
};

export function useNavigationItems({ currentOrganization, feedbackUrl, navItems }: UseNavigationItemsArgs) {
	const classes = useSideBarStyles(undefined, undefined);

	return useMemo(() => {
		const getWarning = ({ HasWarningIcon, WarningText, WarningUrl, Items: childItems }: NavItem) => {
			if (HasWarningIcon) {
				return { WarningText, WarningUrl };
			}
			const childWithWarning = childItems.find((c) => c.HasWarningIcon);
			return (
				childWithWarning && {
					WarningText: childWithWarning.WarningText,
					WarningUrl: childWithWarning.WarningUrl,
				}
			);
		};

		const getAtTarget = ({ DisplayName, KeyAsString }: NavItem) => {
			const itemKey = KeyAsString.toLowerCase();
			switch (itemKey) {
				case 'giftentry':
					return 'Gift Entry';
				case 'givingstatements':
					return 'Giving Statement';
				case 'recurring':
					return 'Recurring';
				default:
					return DisplayName;
			}
		};

		const getIsActive = ({ KeyAsString }: NavItem) => {
			return KeyAsString.toLowerCase() === 'campaigns' ? /pushpay\/portal\/\d+\/campaigns/ : undefined;
		}

		const formatWarningUrl = (url: string) => (url.indexOf('https://') === 0 ? url : `/${url}`);

		const getIndicatorIcon = (item: NavItem) => {
			const warning = getWarning(item);
			if (warning) {
				return {
					indicatorIcon: (
						<Link
							className={classes.indicatorWarningLink}
							displayStyle="unstyled"
							href={formatWarningUrl(warning.WarningUrl)}
							onClick={(ev) => ev.stopPropagation()}
						>
							<WarningIcon className={classes.indicatorWarningIcon} />
						</Link>
					),
					indicatorIconTooltip: warning.WarningText,
				};
			}
			if (item.Key !== NavMenuItemType.Community || !currentOrganization.IsCcbActive) {
				return;
			}
			if (currentOrganization.CcbPeopleLinkingEnabled) {
				return {
					indicatorIcon: <LinkIcon data-pendo-link-and-sync-enabled={true} />,
					indicatorIconTooltip: 'Link & Sync enabled',
				};
			}
			return {
				indicatorIcon: <UnlinkIcon data-pendo-link-and-sync-enabled={false} />,
				indicatorIconTooltip: 'Link & Sync disabled',
			};
		};

		const mapChildNavigationItem = (item: NavItem) => {
			return {
				label: item.DisplayName,
				href: item.Url,
				'data-pp-at-target': getAtTarget(item),
			};
		};

		const mapTopLevelNavigationItem = (item: NavItem) => {
			return {
				label: item.DisplayName,
				href: item.Url,
				icon: navigationIcons[item.KeyAsString],
				children: item.Items && item.Items.length ? item.Items.map(mapChildNavigationItem) : undefined,
				isActive: getIsActive(item),
				'data-pp-at-target': getAtTarget(item),
				...getIndicatorIcon(item),
			};
		};

		return [
			...navItems.map(mapTopLevelNavigationItem),
			{
				icon: PencilWriteIcon,
				label: 'Feedback',
				href: feedbackUrl,
				target: '_blank',
				'data-pp-at-target': 'Feedback',
			},
		] as NavigationItem[];
	}, [currentOrganization, feedbackUrl, navItems]);
}
