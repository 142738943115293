import * as React from 'react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { DataGrid, DataGridCell, IDataGridColumn } from '../../../components/data-grid/data-grid';
import { ResponsiveVisibility, ResponsiveBreakpoint, responsiveHelper } from '../../../helpers/responsive-helper';
import { RecentPledgeDetailWithTotals } from 'campaigns/components/pledges-grid/pledge-details-with-totals';
import { SvgWrapper } from '../../../components/svg-wrapper';
import { Formatter } from '../../../helpers/formatter';
import { Bubble } from '../../../components/bubble/bubble';
import { classNames as combineClassNames } from '../../../../Shared/utils/classnames';
import { Button } from '../../../../Shared/components/button/button';
import { PledgeRowViewModel, PledgeLabel, PaymentLabel, PledgeRowWithTotalsViewModel } from '../../../campaigns/campaigns-generated';
import { deletePledgeContextKey, DeletePledgeContext } from './with-delete-pledge';
import { SmoothHeightTransition } from '../../../components/hoc-behavior/transitions';

import * as styles from './pledges-grid.less';

interface PledgesGridProps {
	pledges: Array<PledgeRowViewModel>;
	hasCampaignNameColumn?: boolean;
	pledgeLabel: PledgeLabel;
	paymentLabel: PaymentLabel;
}

@inject(deletePledgeContextKey)
@observer
export class PledgeGridDeleteConfirmation extends React.Component<{ pledge: PledgeRowViewModel, deletePledgeContext?: DeletePledgeContext, pledgeLabel: PledgeLabel }> {
	render() {
		const { deletePledgeContext: { handleCancel }, pledgeLabel } = this.props;
		return (
			<Bubble onCancel={this.onCancel} placement="left">
				<div className={styles.deletePopupContent}>
					<h5>Are you sure you want to delete this {NewFeatures.DonorPledgeEntry_ConfigurablePledgeGrammar ? pledgeLabel.NounLowerCase : 'pledge'}?</h5>
					<div>
						<Button type="button"
							isLoading={this.processingDelete}
							className={combineClassNames('btn', 'btn-primary', styles.deletePopupButton)}
							spinnerClassName={styles.deletePopupSpinner}
							loadingClassName={styles.deletePopupLoading}
							onClick={this.onConfirmDelete}
							acceptanceTestTargetId={'Delete pledge confirmation'}>
							{this.processingDelete ? `Deleting`: `Delete ${NewFeatures.DonorPledgeEntry_ConfigurablePledgeGrammar ? pledgeLabel.NounLowerCase : 'pledge'}`}
						</Button>
						<span className={styles.deletePopupText}>{' or'}</span>
						<Button type="button" disabled={this.processingDelete} className={combineClassNames('btn-link', styles.deletePopupButton)} onClick={handleCancel}>cancel</Button>
					</div>
				</div>
			</Bubble>
		);
	}

	onConfirmDelete = () => {
		this.props.deletePledgeContext.handleConfirmDelete(this.props.pledge.Id);
	}

	onCancel = () => {
		const { inProcessingDeleteState, handleCancel } = this.props.deletePledgeContext;
		if (inProcessingDeleteState === false) {
			handleCancel();
		}
	}

	@computed
	private get processingDelete() {
		const { pledge, deletePledgeContext } = this.props;
		return deletePledgeContext.isProcessingDelete(pledge.Id);
	}
}

function formatAmountColumn(item: PledgeRowViewModel, classNames: string): JSX.Element {
	const amount: string = `$${Formatter.formatNumberForDisplay(item.TotalAmount)}`;
	return (
		<DataGridCell key="Amount" classNames={classNames}>
			{amount}
		</DataGridCell>
	);
}

@inject(deletePledgeContextKey)
@observer
class DeleteColumn extends React.Component<{ pledge: PledgeRowViewModel, classNames: string, deletePledgeContext?: DeletePledgeContext, pledgeLabel: PledgeLabel }> {
	render() {
		const { pledge, classNames, deletePledgeContext: { inProcessingDeleteState }, pledgeLabel } = this.props;
		return (
			<DataGridCell key="Actions" classNames={classNames}>
				<div className={styles.deleteContainer}>
					<button type="button" title={NewFeatures.DonorPledgeEntry_ConfigurablePledgeGrammar ? `Delete ${pledgeLabel.NounLowerCase}` : 'Delete pledge'} disabled={inProcessingDeleteState} className={combineClassNames('btn', 'btn-link', styles.deleteBtn)} onClick={this.onDelete}>
						<SvgWrapper svg="bin" className={styles.deleteIcon} title={NewFeatures.DonorPledgeEntry_ConfigurablePledgeGrammar ? `Delete ${pledgeLabel.NounLowerCase}` : 'Delete pledge'} />
					</button>
					{this.deleting && <PledgeGridDeleteConfirmation pledge={pledge} pledgeLabel={pledgeLabel} />}
				</div>
			</DataGridCell>
		);
	}

	onDelete = () => {
		this.props.deletePledgeContext.handleDelete(this.props.pledge.Id);
	}

	@computed
	private get deleting() {
		const { pledge, deletePledgeContext } = this.props;
		return deletePledgeContext.isDeletingOrProcessingDelete(pledge.Id);
	}
}

function formatPayerColumn(pledge: PledgeRowViewModel, classNames: string): JSX.Element {
	const { PledgerName, PushpayerAccountUrl } = pledge;
	return (
		<DataGridCell key="PledgerName" classNames={classNames}>
			{ responsiveHelper.isXs ? PledgerName : <a href={PushpayerAccountUrl}>{PledgerName}</a> }
		</DataGridCell>
	);
}

function formatDatePledgedColumn(pledge: PledgeRowViewModel, classNames: string): JSX.Element {
	const { CreatedOn } = pledge;
	return (
		<DataGridCell key="DatePledged" classNames={classNames}>
			{ CreatedOn }
		</DataGridCell>
	);
}

function formatCampaignNameColumn(pledge: PledgeRowViewModel, classNames: string): JSX.Element {
	const { CampaignName, CampaignUrl } = pledge;
	return (
		<DataGridCell key="CampaignName" classNames={classNames}>
			<a href={CampaignUrl}>{CampaignName}</a>
		</DataGridCell>
	);
}

function formatEmailAddressColumn(pledge: PledgeRowViewModel, classNames: string): JSX.Element {
	const { EmailAddress } = pledge;
	return (
		<DataGridCell key="EmailAddress" classNames={classNames}>
			<a href={`mailto:${EmailAddress}`}>{EmailAddress}</a>
		</DataGridCell>
	);
}

function formatTotalGivenColumn(pledge: PledgeRowWithTotalsViewModel, classNames: string): JSX.Element {
	const total = `$${Formatter.formatNumberForDisplay(pledge.TotalGiven)}`;
	return (
		<DataGridCell key="TotalGiven" classNames={classNames}>
			<strong>{total}</strong>
		</DataGridCell>
	);
}

function formatProgressColumn(pledge: PledgeRowWithTotalsViewModel, classNames: string): JSX.Element {
	const progress = `${Formatter.formatNumberForDisplay(pledge.Progress)}%`;
	return (
		<DataGridCell key="Progress" classNames={classNames}>
			<strong>{progress}</strong>
		</DataGridCell>
	);
}

function formatRemainingColumn(pledge: PledgeRowWithTotalsViewModel, classNames: string): JSX.Element {
	return (
		<DataGridCell key="Remaining" classNames={classNames}>
			{`$${Formatter.formatNumberForDisplay(pledge.Remaining)}`}
		</DataGridCell>
	);
}

@observer
export class PledgesGridWithTotals extends React.Component<PledgesGridProps> {
	private columns: { [key: string]: IDataGridColumn };

	constructor(props) {
		super(props);

		const {
			pledgeLabel,
			paymentLabel,
		} = this.props;

		this.columns = {
			PayerDisplayName: { displayName: 'Name', formatter: formatPayerColumn, extraClassNames: styles.columnPayerdisplayname },
			EmailAddress: {
				displayName: 'Email address',
				formatter: formatEmailAddressColumn,
				responsiveVisibility: ResponsiveVisibility.Hidden,
				responsiveBreakpoints: ResponsiveBreakpoint.Xs|ResponsiveBreakpoint.Sm,
				extraClassNames: styles.columnPayerdisplayemail
			},
			DatePledged: {
				displayName: NewFeatures.DonorPledgeEntry_ConfigurablePledgeGrammar ? `Date ${pledgeLabel.VerbPastTenseLowerCase}` : 'Date pledged',
				formatter: formatDatePledgedColumn,
				responsiveVisibility: ResponsiveVisibility.Hidden,
				responsiveBreakpoints: ResponsiveBreakpoint.Xs|ResponsiveBreakpoint.Sm,
				extraClassNames: styles.columnPayerdisplaydatepledged
			},
			...(props.hasCampaignNameColumn && {CampaignName: {
				displayName: 'Campaign name',
				formatter: formatCampaignNameColumn,
				responsiveVisibility: ResponsiveVisibility.Hidden,
				responsiveBreakpoints: ResponsiveBreakpoint.Xs,
				extraClassNames: styles.columnCampaignname
			}}),
			Amount: { 
				displayName: NewFeatures.DonorPledgeEntry_ConfigurablePledgeGrammar ? `Total ${pledgeLabel.VerbPastTenseLowerCase}` : 'Total pledged',
				formatter: formatAmountColumn,
				extraClassNames: styles.columnAmount
			},
			Actions: {
				displayName: '',
				formatter: (pledge, classNames) => <DeleteColumn pledge={pledge} classNames={classNames} pledgeLabel={pledgeLabel} />,
				responsiveVisibility: ResponsiveVisibility.Hidden,
				responsiveBreakpoints: ResponsiveBreakpoint.Xs,
				extraClassNames: styles.columnActions
			},
			TotalGiven: {
				displayName: `Total ${paymentLabel.VerbPastTenseLowerCase}`,
				formatter: formatTotalGivenColumn,
				responsiveVisibility: ResponsiveVisibility.Hidden,
				responsiveBreakpoints: ResponsiveBreakpoint.Xs|ResponsiveBreakpoint.Sm,
				extraClassNames: styles.columnCampaigntotalgiven,
			},
			CampaignProgress: {
				displayName: "Progress",
				formatter: formatProgressColumn,
				responsiveVisibility: ResponsiveVisibility.Hidden,
				responsiveBreakpoints: ResponsiveBreakpoint.Xs|ResponsiveBreakpoint.Sm,
				extraClassNames: styles.columnCampaignprogress,
			},
			CampaignRemaining: {
				displayName: "Remaining",
				formatter: formatRemainingColumn,
				responsiveVisibility: ResponsiveVisibility.Hidden,
				responsiveBreakpoints: ResponsiveBreakpoint.Xs|ResponsiveBreakpoint.Sm,
				extraClassNames: styles.columnCampaignremaining,
			},
		};
	}

	render() {
		const { pledges, hasCampaignNameColumn, pledgeLabel } = this.props;
		return (
			<SmoothHeightTransition>
				<DataGrid
					columns={this.columns}
					data={pledges}
					getRowKey={this.getRowKey}
					onSelectRow={responsiveHelper.isXs}
					renderExpandedRow={this.renderPledgeDetails}
					noDataMessage={NewFeatures.DonorPledgeEntry_ConfigurablePledgeGrammar ? `No ${pledgeLabel.NounPluralLowerCase}` : 'No pledges'}
					rowClassNames={combineClassNames(styles.gridRowWithTotals, {[styles.gridRowWithCampaignName]: hasCampaignNameColumn})} />
			</SmoothHeightTransition>
		);
	}

	private renderPledgeDetails = (pledgeDetail: PledgeRowWithTotalsViewModel, updateExpandedRow: (key: string) => void, ) => (
		<RecentPledgeDetailWithTotals 
			pledgeDetail={pledgeDetail}
			showCampaignName={this.props.hasCampaignNameColumn}
			updateExpandedRow={updateExpandedRow}
			pledgeLabel={this.props.pledgeLabel} 
			paymentLabel={this.props.paymentLabel}
		/>
	)

	private getRowKey = (pledge: PledgeRowWithTotalsViewModel) => pledge.Id.toString();
}
