import * as React from 'react';
import { take } from 'redux-saga/effects';
import { QboConfigurationSagaContext } from '../../pages/configuration/qbo-configuration-main-saga';
import { ProcessPurgeQueueDialog, ProcessPurgeQueueUserAction } from '../process-purge-queue-dialog';
import { ModalDialogCommander } from '../../../components/modal-dialog-commander';
import { QuickBooksIntegrationActionResult, JournalEntryCreationHint, PausedContext } from '../../qbo-integration-generated';
import { Channel, userActionChannel } from '../../../../Shared/utils/user-action-channel';

export function* processPurgeQueue(context: QboConfigurationSagaContext, hint: JournalEntryCreationHint) {
	const {mainViewModel: { setServerMessage }, dataService} = context;

	try {
		const response: QuickBooksIntegrationActionResult = yield dataService.enable({journalEntryCreationHint:hint});

		const {Message, RedirectTo} = response;

		setServerMessage(Message);

		// successfully enabled - set location to RedirectTo
		document.location.href = RedirectTo;
		// never ending promise since we're doing a redirect and don't want to resolve the promise early
		yield new Promise(()=> {});
	} catch (e) {
		setServerMessage(e.response);
	}
}


export function* showProcessPurgeQueueDialog(context: QboConfigurationSagaContext, pausedContext: PausedContext) {
	const processPurgeChannel: Channel<ProcessPurgeQueueUserAction> = userActionChannel();

	const processPurgeDialog = React.createElement(ProcessPurgeQueueDialog, {userActionChannel: processPurgeChannel, pausedContext});

	ModalDialogCommander.showReactForm(processPurgeDialog);

	const result = yield take(processPurgeChannel);

	if(result instanceof  ProcessPurgeQueueUserAction.PurgeQueue) {
		yield processPurgeQueue(context, JournalEntryCreationHint.DoNotCreateForPausedSettlements);
		return;
	}

	if (result instanceof ProcessPurgeQueueUserAction.ProcessQueue)	{
		yield processPurgeQueue(context, JournalEntryCreationHint.CreateForPausedSettlements);
		return;
	}

	ModalDialogCommander.forceCloseCurrent();
}
