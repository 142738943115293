import {ArrayHelper} from '../../helpers/arrayhelper';
import {inject} from 'mobx-react';

export enum StoreType {
	ImportFundsStore
}

const discriminatorPropertyName = '__discriminator__';

export interface IProviderContext {
	stores: Array<any>;
}

export function store(storeType: StoreType) {
	return <TFunction extends Function>(target: TFunction) => {
		target.prototype[discriminatorPropertyName] = storeType;
		return target;
	};
}

export function connect<TProps>(storeType: StoreType, storeToProps: (store: any, props: TProps) => void)
	: <TFunction extends React.ComponentClass<TProps>>(target: TFunction) => TFunction {
	return inject((context: IProviderContext, nextProps: TProps) => {
		const store = context.stores.find(x => x[discriminatorPropertyName] === storeType);
		storeToProps(store, nextProps);
	});
}
