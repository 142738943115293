import * as React from 'react';
import { TransitionGroup } from 'react-transition-group-v1';
import { ResponsiveVisibility, ResponsiveBreakpoint } from '../../helpers/responsive-helper';
import { RenderResponsive } from '../../components/hoc-behavior/render-responsive';
import { CustomFieldEditModel } from '../../loggedinweb-generated';
import { observer } from 'mobx-react';
import { AllocationBatchDetailsModel } from './allocation-batch-details-model';
import { Tooltip, ITooltipProps, TooltipContent } from '../../components/tooltip/tooltip';
import { Formatter } from '../../helpers/formatter';
import { FormControlLabelled, FormControlType } from '../../components/form-controls/form-control-labelled';
import { CustomField } from '../../components/custom-field';
import { SvgWrapper } from '../../components/svg-wrapper';
import { Models } from '../check-deposit-generated';
import UiBatchStatus = Models.UiBatchStatus;
import { velocity } from '../../helpers/velocity';
import { Button } from '../../components/button';
import { isFunction } from '../../utils/is-function';

export interface IAllocationOverviewPanelProps {
	store: AllocationBatchDetailsModel;
	applySpeedSettings: () => void;
	isCheckDetailsOpen?: boolean;
}

@observer
export class AllocationOverviewPanel extends React.Component<IAllocationOverviewPanelProps> {

	render() {
		const { store, applySpeedSettings, isCheckDetailsOpen } = this.props;
		const {
			BatchDetails: { MerchantName, NumberOfProcessibleChecks, TotalAmount, CreatedBy, UpdatedBy, BatchStatus },
			StatusLastChangedOnFormatted,
			CreatedOnFormatted,
			showBatchSettings,
			toggleShowBatchSettings
		} = store;
		const batchEditable = BatchStatus !== UiBatchStatus.Closed;

		const createdByText = `Batch created${CreatedBy ? ' by ' +  CreatedBy: ''} on`;

		return (
			<div className="cd-allocation-overview">
				<div className="panel panel-color panel-default gray">
					<div className="panel-heading top-border">
						<div className="cd-allocation-overview-listing">
							<label id="cd-allocation-overview-listing-label">Listing</label>
							<h2 aria-labelledby="cd-allocation-overview-listing-label">{MerchantName}</h2>
						</div>
						<div className="cd-allocation-overview-totals">
							<div className="cd-allocation-overview-check-count">
								<label id="cd-allocation-overview-check-count-label"><span className="hidden-xs">Number of </span>checks</label>
								<div className="h2" aria-labelledby="cd-allocation-overview-check-count-label">{NumberOfProcessibleChecks}</div>
							</div>
							<div className="cd-allocation-overview-total-amount">
								<label id="cd-allocation-overview-total-amount-label"><span className="hidden-xs">Total </span>Amount</label>
								<div className="h2" aria-labelledby="cd-allocation-overview-total-amount-label"><sup>$</sup>{Formatter.formatNumberForDisplay(TotalAmount, 2)}</div>
							</div>
						</div>
					</div>
					<div className="panel-body">
						<div className="cd-allocation-overview-body">
							<div className="cd-allocation-overview-dates">
								<div className="cd-allocation-overview-date-created">
									<label id="cd-allocation-overview-date-created">
										<span className="visible-xs">Created</span>
										<span className="hidden-xs">{createdByText}&nbsp;</span>
									</label>
									<strong aria-labelledby="cd-allocation-overview-date-created">{CreatedOnFormatted}</strong>
								</div>
								{UpdatedBy && (
									<div className="cd-allocation-overview-date-updated">
										{BatchStatus === UiBatchStatus.Closed ?
											<label id="cd-allocation-overview-date-updated">
												<span className="visible-xs">Allocation completed</span>
												<span className="hidden-xs">Allocation completed by {UpdatedBy} on&nbsp;</span>
											</label>
											: <label id="cd-allocation-overview-date-updated">
												<span className="visible-xs">Updated</span>
												<span className="hidden-xs">Last updated by {UpdatedBy} on&nbsp;</span>
											</label>}
										<strong aria-labelledby="cd-allocation-overview-date-updated">{StatusLastChangedOnFormatted}</strong>
									</div>
								)}
							</div>
							{batchEditable && (<button className="btn btn-link" onClick={toggleShowBatchSettings}>
								<SvgWrapper svg="icon-xs-gear" className="icon icon-xs" title="Settings" />Settings
							</button>)}
						</div>
						<TransitionGroup component="div" className="cd-allocation-overview-speed-settings-container">
							{showBatchSettings && batchEditable &&
								<AllocationSpeedSettings store={store} applySpeedSettings={applySpeedSettings} isCheckDetailsOpen={isCheckDetailsOpen} />}
						</TransitionGroup>
					</div>
				</div>
			</div>
		);
	}
}

export interface IAllocationSpeedSettingsProps {
	store: AllocationBatchDetailsModel;
	applySpeedSettings: () => void;
	isCheckDetailsOpen: boolean;
}

@observer
class AllocationSpeedSettings extends React.Component<IAllocationSpeedSettingsProps> {
	form: HTMLElement;

	render() {
		const {
			GivenOn,
			onChangeGivenOn,
			FundKey,
			fundCustomField,
			BatchDetails: { PayButtonLabel },
			isProcessingSpeedSettingsRequest: isRequestInProgress,
			speedSettingsButtonDisabled } = this.props.store;
		const { isCheckDetailsOpen }= this.props;
		const speedSettingsGridClasses = 'col-md-4 col-sm-4';
		const tooltipMessage = isCheckDetailsOpen ?
			'Settings cannot be applied while allocating a check' :
			'Settings will be applied across all checks in this deposit except for split checks';
		return (
			<form className="cd-allocation-overview-speed-settings panel-expander" ref={(form) => this.form = form}>
				<RenderResponsive breakpoints={ResponsiveBreakpoint.Xs | ResponsiveBreakpoint.Sm} visibility={ResponsiveVisibility.Visible}>
					<div className="cd-allocation-overview-speed-settings-help">
						<SvgWrapper svg="icon-info" className="icon cd-allocation-overview-speed-settings-help-icon" title="Info" />
						{tooltipMessage}
					</div>
				</RenderResponsive>
				<div className="row">
					<FormControlLabelled cssClassNames={speedSettingsGridClasses}
						label={`${PayButtonLabel.NounPluralSentenceCase} received on (optional)`}
						formControlProps={{
							formControlType: FormControlType.DatePicker,
							name: 'batchGivenOn',
							value: GivenOn,
							onChangeHandler: onChangeGivenOn,
							placeholder: 'Choose a date',
						}} />
					<CustomField
						className={speedSettingsGridClasses}
						customField={fundCustomField}
						value={FundKey}
						onChange={this.onChangeFund} />
					<div className={`${speedSettingsGridClasses} form-group`}>
						<Button className="btn btn-ghost" submit={true} onClick={this.onUpdateChecks}
							disabled={speedSettingsButtonDisabled || isCheckDetailsOpen} isProcessingRequest={isRequestInProgress} >
							Update checks
						</Button>
							<span className="cd-allocation-overview-tooltip">
								<TooltipComponent placement="top" visible={isCheckDetailsOpen}>
									{tooltipMessage}
								</TooltipComponent>
							</span>
					</div>
				</div>
			</form>
		);
	}

	componentWillEnter(callback) {
		velocity(this.form, 'fadeIn', {
			duration: 250,
			complete: callback,
			easing: 'ease-in-out',
		});
	}

	componentWillLeave(callback) {
		velocity(this.form, 'fadeOut', {
			duration: 150,
			complete: callback,
			easing: 'ease-in-out',
		});
	}

	private onUpdateChecks = (event: React.FormEvent<HTMLButtonElement>) => {
		event.preventDefault();
		this.props.applySpeedSettings();
	}

	private onChangeFund = (field: CustomFieldEditModel) => {
		this.props.store.onChangeFund(field.Value);
	}
}

@observer
class TooltipComponent extends React.Component<Partial<ITooltipProps>, {visible: boolean}> {

	state = {visible: false};

	render() {
		return  <Tooltip
					visible={this.state.visible || this.props.visible}
					onVisibleChange={this.handleVisibleChange}
					placement={this.props.placement || 'top'}
					label="more information">
					<SvgWrapper svg="icon-info" className="icon tooltip-icon" />
					<RenderResponsive breakpoints={ResponsiveBreakpoint.Xs | ResponsiveBreakpoint.Sm} visibility={ResponsiveVisibility.Hidden}>
						<TooltipContent>
							<div>{this.props.children}</div>
						</TooltipContent>
					</RenderResponsive>
				</Tooltip>;
	}

	private handleVisibleChange = (visible: boolean) => {
		this.setState({ visible });
		if (isFunction(this.props.onVisibleChange)) {
			this.props.onVisibleChange(visible);
		}
	}
}
