import { CampaignsApiConfig } from './campaigns-generated';
import { dataService } from '../../Shared/utils/data-service';
import { b64EncodeUnicode } from '../../Shared/utils/encode-utils';

const campaignsDataService = dataService(CampaignsApiConfig);

export const getCampaignsSagaDataService = () => campaignsDataService.getSagaService();
export const getCampaignsPromiseDataService = () => campaignsDataService.getPromiseService();

export function mockCampaignDataService(mockCampaignDataService) {
	campaignsDataService.mock(mockCampaignDataService);
}

const cursorPrefix = 'arrayconnection:';

export function generateCursor(position: number) {
	if (position === null || position === undefined) {
		return '';
	}

	return b64EncodeUnicode(cursorPrefix + position);
}
