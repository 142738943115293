import { Channel } from './user-action-channel';

export function userActionCreator<TActions extends { [key: string]: { prototype: any, new(...args: any[]): any }, }>
	(userActions: TActions, userActionChannel: Channel<TActions[keyof TActions]['prototype']>) {
	return Object.keys(userActions).reduce((userActionMap, userActionName) => {

		userActionMap[userActionName as keyof TActions] = (...params: any[]) => {
			const userAction = new userActions[userActionName](...params);
			userActionChannel.put(userAction);
		};
		return userActionMap;
	}, {} as ActionCreator<TActions>);
}

export type ActionCreator<T> = {[x in keyof T]: (...params: any[]) => void };
