import * as EXIF from 'exif-js';
import { IValidationRules } from './validation-rules';
import { Features } from '../helpers/features';
import { ValidationHelper } from '../helpers/validationhelper';
import { calculateContrast } from '../../Shared/utils/color-math';

enum ExifOrientation {
	ORIGINAL = 1,
	FLIP = 2,
	ROTATE_180 = 3,
	FLIP_AND_ROTATE_180 = 4,
	FLIP_AND_ROTATE_270 = 5,
	ROTATE_90 = 6,
	FLIP_AND_ROTATE_90 = 7,
	ROTATE_270 = 8,
}

export class Validation {
	init() {
		this.addCustomClientValidationRules();
		this.bindForms();
	}

	bindForm(form) {
		var $form = $(form);

		if (Features.SanitizeJavascript) {
			const originalJqueryRules = $.fn.rules;
			$.fn.extend({
				rules() {
					const originalResult = originalJqueryRules.apply(this, arguments);
					if (this.is('input[type="text"]:not([data-ignore-pan-like]), textarea:not([data-ignore-pan-like])')) {
						originalResult.panHandling = true;
						return originalResult;
					}

					return originalResult;
				}
			});
		}
		var validator = $form.validate();
		if (!validator) {
			return;
		}

		var settings = validator.settings;
		settings.highlight = element => { //also passed, not used - errorClass, validClass
			$(element).attr('data-error-highlight', '');
			$(element).closest('li').addClass('field-error');
		};

		settings.unhighlight = element => { //also passed, not used - errorClass, validClass
			$(element).removeAttr('data-error-highlight');
			$(element).closest('li').not(':has([data-error-highlight])').removeClass('field-error');
		};

		// jquery.validate.unobtrusive default is :hidden
		// this allows hidden fields to opt in to validation with the data-validate attribute
		settings.ignore = ':hidden:not([data-validate])';

		// Override standard keyup/focus events to prevent annoying behaviour on mobiles
		settings.onkeyup = false;
		settings.onfocusout = false;
		settings.onclick = false;

		// Trim email before running the validation on it
		if (settings.rules) {
			$.each(settings.rules, (index, element) => {
				if (element && element.email) {
					element.email = Object.assign({}, typeof element.email === 'object' ? element.email : {}, {
						depends() {
							$(this).val($.trim($(this).val()));
							return true;
						}
					});
				}
			});
		}

		// Hide custom error message when form validates - as two error messages looks clumsy
		$form.on('submit', event => {
			//event.result is true when the form is valid
			if (event.result) {
				return;
			}

			$('.custom-error-message').hide();
		});

		return validator;
	}

	bindForms() {
		$('form').each((i, form) => {
			this.bindForm(form);
		});
	}

	parseForm(form: HTMLFormElement) {
		const unobtrusive = ($.validator as any).unobtrusive;
		unobtrusive.parse(form);
	}

	attachElementValidation(element: Element) {
		//that is a replicata of $.validator.unobtrusive.parseElement
		//https://github.com/aspnet/jquery-validation-unobtrusive/blob/master/jquery.validate.unobtrusive.js#L163

		//parseElement does not add the element to the validator if the validator has been created
		//here we're using the same logic to update validator settings

		const $element = $(element);
		const form = $element.closest('form')[0];
		const validator = $(form).data('validator') as JQueryValidation.Validator;

		if (validator) {
			// ReSharper disable once InconsistentNaming
			const rules = { '__dummy__': true };
			const messages = {};
			const unobtrusive = ($.validator as any).unobtrusive;

			validator.settings.rules[element.getAttribute('name')] = rules;
			validator.settings.messages[element.getAttribute('name')] = messages;

			unobtrusive.adapters.forEach((adapter) => {
				var prefix = `data-val-${adapter.name}`;
				var message = $element.attr(prefix);
				var paramValues = {};

				if (message !== undefined) {
					// Compare against undefined, because an empty message is legal (and falsy)
					prefix += '-';
					adapter.params.forEach(parameter => {
						paramValues[parameter] = $element.attr(prefix + parameter);
					});
					adapter.adapt({
						element: element,
						form: form,
						message: message,
						params: paramValues,
						rules: rules,
						messages: messages
					});
				}
			});
		}
	}

	detachElementValidation(element: Element) {
		const $element = $(element);
		const form = $element.closest('form')[0];
		const validator = $(form).data('validator') as JQueryValidation.Validator;

		if (validator) {
			delete validator.settings.rules[element.getAttribute('name')];
			delete validator.settings.messages[element.getAttribute('name')];
		}
	}

	/**
	 * converts validationRules to a collection of validation attributes used by unobtrusive validator
	 * @param validationRules
	 */
	validationAttributesForProperty(validationRules: IValidationRules) {
		const validationAttributes: { [rule: string]: any } = {};

		if (validationRules) {
			validationAttributes['data-val'] = true;

			for (let ruleName in validationRules) {
				if (validationRules.hasOwnProperty(ruleName)) {
					const rule = validationRules[ruleName];
					validationAttributes[`data-val-${ruleName}`] = rule ? rule.errorMessage : '';
					if (rule && rule.parameters) {
						for (let parameterName in rule.parameters) {
							if (rule.parameters.hasOwnProperty(parameterName)) {
								validationAttributes[`data-val-${ruleName}-${parameterName}`] = rule.parameters[parameterName];
							}
						}
					}
				}
			}
		}

		return validationAttributes;
	}

	validateField(fieldName: string) {
		const $element = $(`[name="${fieldName}"]`);
		const form = $element.closest('form')[0];
		const validator = $(form).data('validator') as JQueryValidation.Validator;
		validator.element($element);
	}

	private addCustomClientValidationRules() {
		//keep the following regex in sync with HttpAndHttpsOnlyUrlAttribute.cs
		// tslint:disable-next-line:max-line-length
		const regex = /^https?:\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;
		const unobtrusive = ($.validator as any).unobtrusive;

		$.validator.addMethod('httpurl', function (value, element) {
			return this.optional(element) || regex.test(value);
		});

		unobtrusive.adapters.add('httpurl', (options) => {
			options.rules['httpurl'] = true;
			if (options.message) {
				options.messages['httpurl'] = options.message;
			}
		});

		//even though it looks weird, it becomes handy when a jquery validation is used from a react component
		$.validator.addMethod('nevervalid', function (value, element) {
			return false;
		});
		unobtrusive.adapters.add('nevervalid', (options) => {
			options.rules['nevervalid'] = true;
			if (options.message) {
				options.messages['nevervalid'] = options.message;
			}
		});

		if (Features.SanitizeJavascript) {
			this.addPanHandlingValidationRule();
		}

		this.addColorContrastValidationRule();
		this.addFileTypeValidationRule();
		this.addFileSizeValidationRule();
		this.addImageDimensionValidationRule();
		this.addDefaultTextValidationRule();
		this.addContainsDefaultTextValidationRule();
	}

	private addPanHandlingValidationRule() {
		$.validator.addMethod('panHandling', (value, element) => {
			if (!ValidationHelper.looksLikeCreditCard(value)) {
				return true;
			}

			return false;
		}, 'For your security, please only enter your credit card details into credit card fields.');

		const unobtrusive = ($.validator as any).unobtrusive;
		unobtrusive.adapters.add('panHandling', (options) => {
			options.rules['panHandling'] = true;
			if (options.message) {
				options.messages['panHandling'] = options.message;
			}
		});
	}

	private addColorContrastValidationRule() {
		$.validator.addMethod('colorcontrast', (value, element, params) => {
			const contrastRatio = calculateContrast(value, params.against);
			return (contrastRatio > params.ratio);
		}, 'Please make sure your color creates a readable text-and-background color contrast.');

		const unobtrusive = ($.validator as any).unobtrusive;

		unobtrusive.adapters.add('colorcontrast', ['ratio', 'against'], (options) => {
			options.rules['colorcontrast'] = {
				ratio: options.params.ratio,
				against: options.params.against,
			};
			if (options.message) {
				options.messages['colorcontrast'] = options.message;
			}
		});
	}

	private addImageDimensionValidationRule() {
		const unobtrusive = ($.validator as any).unobtrusive;
		const paramList = [
			'height',
			'minheight',
			'maxheight',
			'width',
			'minwidth',
			'maxwidth',
		];

		$.validator.addMethod('imagedimension', (value, element: HTMLInputElement, params) => {
			const form = $(element).closest('form');
			const validator = form.data('validator') as JQueryValidation.Validator;
			const previous = validator.previousValue(element, 'imagedimension');
			const theImage = new Image();

			const markImageAsValid = () => {
				const submitted = validator.formSubmitted;
				$(element).removeClass('pending');
				validator.toHide = validator.errorsFor(element);
				validator.formSubmitted = submitted;
				validator.successList.push(element);
				validator.invalid[element.name] = false;
				validator.showErrors();
			};

			const markImageAsInvalid = (response) => {
				const errors = {};
				const message = response || validator.defaultMessage(element, 'imagedimension');
				$(element).removeClass('pending');
				errors[element.name] = previous.message = message;
				validator.invalid[element.name] = true;
				validator.showErrors(errors);
			};

			const validateDimension = (width: number, height: number) => {
				if ((params.height && height !== params.height)
					|| (params.width && width !== params.width)
					|| (params.minheight && height < params.minheight)
					|| (params.minwidth && width < params.minwidth)
					|| (params.maxheight && height > params.maxheight)
					|| (params.maxwidth && width > params.maxwidth)) {
					markImageAsInvalid(null);
				}
				markImageAsValid();
			};

			const theFile = element.files[0];

			if (!theFile) {
				return true;
			}

			$(element).addClass('pending');
			const imageUrl = URL.createObjectURL(theFile);

			theImage.addEventListener('load', (evt) => {
				URL.revokeObjectURL(imageUrl);

				let width = theImage.naturalWidth;
				let height = theImage.naturalHeight;

				if (['image/jpeg', 'image/tiff'].indexOf(theFile.type) > - 1) {
					EXIF.getData(theImage as any, function () {
						// Check orientation in EXIF metadata
						const exifOrientation = EXIF.getTag(this, 'Orientation');
						// Set proper dimensions according to exif orientation
						if ([ExifOrientation.FLIP_AND_ROTATE_270,
						ExifOrientation.ROTATE_90,
						ExifOrientation.FLIP_AND_ROTATE_90,
						ExifOrientation.ROTATE_270].indexOf(exifOrientation) > -1) {
							[width, height] = [height, width];
						}

						validateDimension(width, height);
					});
				} else {
					validateDimension(width, height);
				}
			});
			theImage.addEventListener('error', (evt) => {
				markImageAsInvalid(evt.error);
			});

			theImage.src = URL.createObjectURL(theFile);

			return 'pending';
		}, 'Please ensure the image meets the requirements.');

		unobtrusive.adapters.add('imagedimension', paramList, (options) => {
			const opts = {};
			paramList.forEach((param) => {
				opts[param] = options.params[param];
			});
			options.rules['imagedimension'] = opts;
			if (options.message) {
				options.messages['imagedimension'] = options.message;
			}
		});
	}

	private addFileTypeValidationRule() {
		$.validator.addMethod('filetype', (value, element: HTMLInputElement, params) => {
			const theFile = element.files[0];
			if (theFile && params.types.split(',').indexOf(theFile.type) === -1) {
				return false;
			}
			return true;
		});

		const unobtrusive = ($.validator as any).unobtrusive;
		unobtrusive.adapters.add('filetype', ['types'], (options) => {
			options.rules['filetype'] = {
				types: options.params.types,
			};
			if (options.message) {
				options.messages['filetype'] = options.message;
			}
		});
	}

	private addFileSizeValidationRule() {
		$.validator.addMethod('filesize', (value, element: HTMLInputElement, params) => {
			const theFile = element.files[0];
			if (theFile && theFile.size > params.maxsize * 1024 * 1024) {
				return false;
			}
			return true;
		});

		const unobtrusive = ($.validator as any).unobtrusive;
		unobtrusive.adapters.add('filesize', ['maxsize'], (options) => {
			options.rules['filesize'] = {
				maxsize: options.params.maxsize,
			};
			if (options.message) {
				options.messages['filesize'] = options.message;
			}
		});
	}

	private addDefaultTextValidationRule() {
		$.validator.addMethod('defaulttext', (value, element: HTMLInputElement, params) => {
			if (value === params.defaulttext) {
				return false;
			}
			return true;
		});

		const unobtrusive = ($.validator as any).unobtrusive;
		unobtrusive.adapters.add('defaulttext', ['defaulttext'], (options) => {
			options.rules['defaulttext'] = {
				defaulttext: options.params.defaulttext,
			};
			if (options.message) {
				options.messages['defaulttext'] = options.message;
			}
		});
	}

	private addContainsDefaultTextValidationRule() {
		$.validator.addMethod('containsdefaulttext', (value, element: HTMLInputElement, params) => {
			if (value.indexOf(params.defaulttext) !== -1) {
				return false;
			}
			return true;
		});

		const unobtrusive = ($.validator as any).unobtrusive;
		unobtrusive.adapters.add('containsdefaulttext', ['defaulttext'], (options) => {
			options.rules['containsdefaulttext'] = {
				defaulttext: options.params.defaulttext,
			};
			if (options.message) {
				options.messages['containsdefaulttext'] = options.message;
			}
		});
	}
}

export let validation = new Validation();
