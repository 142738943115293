import { action, observable, computed } from 'mobx';
import { ModalDialogCommander } from '../../components/modal-dialog-commander';
import { CheckDepositDataService, CheckDepositDataServiceAction, getCheckDepositDataService, isCheckDepositAction } from '../check-deposit-data-service';
import { Models } from '../check-deposit-generated';
import BatchItemViewModel = Models.BatchItemViewModel;
import UiBatchStatus = Models.UiBatchStatus;

const removeBatchAction = 'dismissRejectedBatch';

export class BatchDismissRejectedDialogViewModel {
	@observable currentlyProcessingRequest: string = null;

	private dataService: CheckDepositDataService;
	private batch: BatchItemViewModel;
	private unsubscribe: Function;
	private onBatchDismissed: (batch: BatchItemViewModel) => void;

	constructor() {
		this.dataService = getCheckDepositDataService();
		this.unsubscribe = this.dataService.subscribe((action) => {
			this.subscribeToRemoveBatch(action);
		});
	}
	@action
	setViewModel = (batch: BatchItemViewModel, onBatchDismissed: (batch: BatchItemViewModel) => void) => {
		this.batch = batch;
		this.onBatchDismissed = onBatchDismissed;
	}

	@action
	removeBatch = () => {
		//we are currently in the middle of dismissing a batch we shouldn't be trying to dismiss another one yet
		if (this.isProcessingRequest) {
			window.reportUnhandledRejection(new Error(`Attempted to dismiss a batch while currently dismissing another batch`), { batchId: this.batch.BatchId });
			return;
		}

		if(this.batch.Status !== UiBatchStatus.Rejected) {
			throw new Error(`User attempted to dismiss a batch which is not rejected`);
		}
		this.currentlyProcessingRequest = this.dataService.initRequest(removeBatchAction, { batchId: this.batch.BatchId });
	}

	@computed
	get isProcessingRequest() {
		return this.currentlyProcessingRequest !== null;
	}

	@action
	destroy = () => {
		this.cancelCurrentRequest();
		this.unsubscribe();
	}

	@action
	private cancelCurrentRequest = () => {
		if (this.currentlyProcessingRequest) {
			this.dataService.cancelRequest(this.currentlyProcessingRequest);
			this.currentlyProcessingRequest = null;
		}
	}

	@action
	private subscribeToRemoveBatch = (action: CheckDepositDataServiceAction) => {
		if (!isCheckDepositAction(action, removeBatchAction)) {
			return;
		}

		if (action.requestId !== this.currentlyProcessingRequest) {
			return;
		}

		switch(action.type) {
			case 'request_success':
				this.currentlyProcessingRequest = null;
				if (typeof this.onBatchDismissed === 'function') {
					this.onBatchDismissed(this.batch);
				}
				ModalDialogCommander.forceCloseCurrent();
				return;
			case 'request_cancel':
				this.currentlyProcessingRequest = null;
				return;
			case 'request_error':
				this.currentlyProcessingRequest = null;
				if(action.error.conflict) {
					//conflict batch was not in rejected state
					ModalDialogCommander.error(action.error.response, 'Sorry, something went wrong');
					return;
				}
				//batch not found
				ModalDialogCommander.error(action.error.userError, 'Sorry, something went wrong');
				return;
		}
	}
}
