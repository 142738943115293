import * as React from 'react';
import { IFormControlSelectOptionProps, IFormControlSelectOptionGroupProps, renderOption } from '../form-controls/form-control-select';
import { classNames } from '../../../Shared/utils/classnames';
import { SvgWrapper } from '../svg-wrapper';

import * as styles from './sort-by.less';

interface ISortByProps {
	selectedValue: string | string[] | number;
	options: IFormControlSelectOptionProps[];
	onChangeHandler: (event: React.FormEvent<HTMLSelectElement>) => void;
}

export class SortBy extends React.Component<ISortByProps, { isFocused: boolean }> {
	constructor(props) {
		super(props);

		this.state = {
			isFocused: false,
		};
	}

	render() {
		const {
			selectedValue,
			options,
			onChangeHandler,
		} = this.props;
		const { isFocused } = this.state;

		return (
			<div className={styles.sortBy}>
				<label htmlFor={styles.sortBy} className={classNames(styles.sortByLabel, isFocused ? styles.sortByLabelFocus : null)}>
					<SvgWrapper svg="icon-sorting" className={`icon icon-xs hidden-desktop ${styles.iconSort}`} />
					Sort <span className="hidden-sm hidden-xs">by</span>
				</label>
				<div className={`select-wrapper ${styles.sortBySelectWrapper}`}>
					<select
						className={`form-control ${styles.sortBySelect}`}
						id={styles.sortBy}
						name="sort"
						value={selectedValue}
						data-pp-at-target="Sort by"
						onChange={onChangeHandler}
						onFocus={this.onFocusHandler}
						onBlur={this.onBlurHandler}
					>
						{options.map(renderOption)}
					</select>
				</div>
			</div>
		);
	}

	onFocusHandler = () => {
		this.setState({ isFocused: true });
	}

	onBlurHandler = () => {
		this.setState({ isFocused: false });
	}
}
