import { action, computed, observable } from 'mobx';
import { Models } from '../check-deposit-generated';
import TransactionSummaryAndFundTotalsViewModel = Models.TransactionSummaryAndFundTotalsViewModel;
import TransactionSummaryViewModel = Models.TransactionSummaryViewModel;
import FundTotals = Models.BatchEntryFundTotal;
import PaymentStatus = Models.PaymentStatus;
import { SortDirection } from '../../components/data-grid/data-grid';

export enum SortableColumns {
	PaymentStatus,
	PayerDisplayName,
	PayerDisplayEmail,
	FundDisplayValue,
	AmountAsMoney
}

export class AllocationGridCompleteViewModel {
	@observable sortedBy: SortableColumns = SortableColumns.PayerDisplayName;
	@observable sortDirection: SortDirection = SortDirection.ASCENDING;
	@observable transactions: TransactionSummaryViewModel[] = null;
	@observable fundTotals: FundTotals[] = null;

	@computed
	get sortedTransactions(): TransactionSummaryViewModel[] {
		if (this.transactions) {
			const orderingFunc = this.sortDirection === SortDirection.ASCENDING ? 'orderBy' : 'orderByDescending';
			return this.transactions[orderingFunc](transaction => this.getSortProperty(transaction)).toArray();
		}
		return null;
	}

	@computed
	get showFundTotals() {
		return this.fundTotals && !!this.fundTotals.length;
	}

	@action
	update = (newResponse: TransactionSummaryAndFundTotalsViewModel) => {
		this.fundTotals = newResponse.FundTotals
		this.transactions = newResponse.Transactions
	}

	@action
	sortColumns = (columnKey: string) => {
		if (columnKey === SortableColumns[this.sortedBy]) {
			// change sort direction
			this.sortDirection = (this.sortDirection === SortDirection.ASCENDING) ? SortDirection.DESCENDING : SortDirection.ASCENDING;
		} else {
			this.sortDirection = SortDirection.ASCENDING;
			this.sortedBy = SortableColumns[columnKey];
		}
	}

	private getSortProperty(transaction: TransactionSummaryViewModel): string | number {
		const currentColumn = SortableColumns[this.sortedBy];

		switch (this.sortedBy) {
			case SortableColumns.AmountAsMoney:
				return transaction.AmountAsMoney.Amount;
			case SortableColumns.PaymentStatus:
				return PaymentStatus[transaction.PaymentStatus];
			default:
				return transaction[currentColumn];
		}
	}
}
