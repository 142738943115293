import * as React from 'react';
import { observer } from 'mobx-react';
import { createNamespace } from '../../helpers/namespace';
import { classNames } from '../../utils/classnames';

const ns = createNamespace('form-control-radio');

export interface IRadioOptionProps {
	label: string;
	value: string;
}

export interface IRadioButtonCommonProps {
	formControlId?: string;
	acceptanceTestTargetId: string;
	options: IRadioOptionProps[];
	gridStyle?: boolean;
	additionalClassNames?: string;
	disabled?: boolean;
	radioItemClassName?: string;
	'aria-label'?: string;
	name?: string;
}

export interface IRadioButtonPrimitiveProps {
	ariaInvalid?: boolean;
	selectedValue: string;
	onChange: (value: string) => void;
}

export type IRadioButtonProps = IRadioButtonCommonProps & IRadioButtonPrimitiveProps;

@observer
export class RadioButton extends React.Component<IRadioButtonProps> {
	render() {
		const { options,
			formControlId,
			gridStyle,
			additionalClassNames,
			'aria-label': ariaLabel,
			acceptanceTestTargetId,
			radioItemClassName,
			selectedValue,
			disabled,
			ariaInvalid,
			name,
		} = this.props;
		const classNamesWithGridStyle = classNames(additionalClassNames, gridStyle ? 'radio-grid' : null);
		return (
			<div id={formControlId} role="radiogroup" aria-label={ariaLabel} className={classNamesWithGridStyle}>
				{options.map((option) => {
					const { label, value } = option;
					return (
						<RadioButtonOption
							key={value}
							name={name}
							label={label}
							value={value}
							selectedValue={selectedValue}
							acceptanceTestTargetId={acceptanceTestTargetId}
							onChange={this.onChange}
							ariaInvalid={ariaInvalid || undefined}
							disabled={disabled}
							radioItemClassName={radioItemClassName}
						/>
					);
				})}
			</div>
		);
	}

	private onChange = (ev: React.FormEvent<HTMLInputElement>) => {
		this.props.onChange(ev.currentTarget.value);
	}
}


const RadioButtonOption = observer(({ value, label, radioItemClassName, acceptanceTestTargetId, selectedValue, disabled, ariaInvalid, onChange, name  }:
{
	value: string,
	label: string,
	radioItemClassName?: string,
	acceptanceTestTargetId: string,
	selectedValue: string,
	disabled?: boolean,
	ariaInvalid?: boolean,
	onChange: (ev: React.FormEvent<HTMLInputElement>) => void,
	name: string,
}) => {
	return (
		<label key={value} className={`radio-inline ${radioItemClassName || ``}`} >
			<input className={ns('input')} type="radio" name={name} value={value} checked={selectedValue === value} onChange={onChange}
				data-pp-at-target={`${acceptanceTestTargetId} ${value}`} aria-invalid={ariaInvalid} disabled={disabled} />
			<span className={classNames(ns('button'), 'radio-inner')}>
				<span className={ns('button-content')}>{label}</span>
			</span>
		</label>
	);
});
