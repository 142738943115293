import * as React from 'react';
import { observer } from 'mobx-react';
import { BatchDialogStore } from './batch-dialog-store';
import { SvgWrapper } from '../../components/svg-wrapper';
import { ResponsiveImage } from '../../components/responsive-image';

export const BatchEmptyView = observer((props: { store: BatchDialogStore }) => {
	const { openDialogOrCheckScanningPortal, isCheckScanningReadOnly, helpCenterUrl } = props.store;
	const checkDepositImageRoot = `/Content/LoggedInWeb/assets/images/check-deposit/`;
	
	return(
		<div>
			<div className="cd-batch-empty-view-description">
				Get all your giving in one place. Scan and process checks with Pushpay with these 5 simple steps:
			</div>
			<div className="cd-batch-empty-view-list row">
				<div className="cd-batch-empty-view-list-item col-sm-5ths">
					<div className="row">
						<div className="cd-batch-empty-view-list-item-image-wrapper col-xs-4 col-sm-12">
							<div className="cd-batch-empty-view-list-item-image">
								<ResponsiveImage className="img-responsive" image={`${checkDepositImageRoot}step-1.png`} alt="Create a check deposit" />
							</div>
							<SvgWrapper svg="arrow-short" className="cd-batch-empty-view-list-item-arrow hidden-xs" />
						</div>
						<div className="cd-batch-empty-view-list-item-content col-xs-8 col-sm-12">
							<h4 className="cd-batch-empty-view-list-item-name">Step 1</h4>
							<p className="cd-batch-empty-view-list-item-text">
								Create a check deposit in the scanning tool
							</p>
						</div>
					</div>
				</div>
				<div className="cd-batch-empty-view-list-item-separator visible-xs"></div>
				<div className="cd-batch-empty-view-list-item col-sm-5ths">
					<div className="row">
						<div className="cd-batch-empty-view-list-item-image-wrapper col-xs-4 col-sm-12">
							<div className="cd-batch-empty-view-list-item-image">
								<ResponsiveImage className="img-responsive" image={`${checkDepositImageRoot}step-2.png`} alt="Scan your checks" />
							</div>
							<SvgWrapper svg="arrow-short" className="cd-batch-empty-view-list-item-arrow hidden-xs" />
						</div>
						<div className="cd-batch-empty-view-list-item-content col-xs-8 col-sm-12">
							<h4 className="cd-batch-empty-view-list-item-name">Step 2</h4>
							<p className="cd-batch-empty-view-list-item-text">
								Scan your checks
							</p>
						</div>
					</div>
				</div>
				<div className="cd-batch-empty-view-list-item-separator visible-xs"></div>
				<div className="cd-batch-empty-view-list-item col-sm-5ths">
					<div className="row">
						<div className="cd-batch-empty-view-list-item-image-wrapper col-xs-4 col-sm-12">
							<div className="cd-batch-empty-view-list-item-image">
								<ResponsiveImage className="img-responsive" image={`${checkDepositImageRoot}step-3.png`} alt="Complete and close check deposit" />
							</div>
							<SvgWrapper svg="arrow-short" className="cd-batch-empty-view-list-item-arrow hidden-xs" />
						</div>
						<div className="cd-batch-empty-view-list-item-content col-xs-8 col-sm-12">
							<h4 className="cd-batch-empty-view-list-item-name">Step 3</h4>
							<p className="cd-batch-empty-view-list-item-text">
								Complete and close check deposit
							</p>
						</div>
					</div>
				</div>
				<div className="cd-batch-empty-view-list-item-separator visible-xs"></div>
				<div className="cd-batch-empty-view-list-item col-sm-5ths">
					<div className="row">
						<div className="cd-batch-empty-view-list-item-image-wrapper col-xs-4 col-sm-12">
							<div className="cd-batch-empty-view-list-item-image">
								<ResponsiveImage className="img-responsive" image={`${checkDepositImageRoot}step-4.png`} alt="Allocate givers and funds to checks" />
							</div>
							<SvgWrapper svg="arrow-short" className="cd-batch-empty-view-list-item-arrow hidden-xs" />
						</div>
						<div className="cd-batch-empty-view-list-item-content col-xs-8 col-sm-12">
							<h4 className="cd-batch-empty-view-list-item-name">Step 4</h4>
							<p className="cd-batch-empty-view-list-item-text">
								Allocate givers and funds to checks
							</p>
						</div>
					</div>
				</div>
				<div className="cd-batch-empty-view-list-item-separator visible-xs"></div>
				<div className="cd-batch-empty-view-list-item col-sm-5ths">
					<div className="row">
						<div className="cd-batch-empty-view-list-item-image-wrapper col-xs-4 col-sm-12">
							<div className="cd-batch-empty-view-list-item-image">
								<ResponsiveImage className="img-responsive" image={`${checkDepositImageRoot}step-5.png`} alt="Mark your batch as completed" />
							</div>
						</div>
						<div className="cd-batch-empty-view-list-item-content col-xs-8 col-sm-12">
							<h4 className="cd-batch-empty-view-list-item-name">Step 5</h4>
							<p className="cd-batch-empty-view-list-item-text">
								Mark your batch as completed
							</p>
						</div>
					</div>
				</div>
				<div className="cd-batch-empty-view-list-item-separator visible-xs"></div>
			</div>
			<div className="cd-batch-empty-view-cta">
				<button type="button" className="btn btn-default" disabled={isCheckScanningReadOnly} onClick={openDialogOrCheckScanningPortal}>
					<SvgWrapper svg="icon-follow-link" className="icon" />
					Get started
				</button>
			</div>
			<div className="cd-batch-empty-view-help">
				Learn how to scan your checks the right way
				in <a href={`${helpCenterUrl}`} className="arrow-hollow-right-after" target="_blank" rel="noopener noreferrer">Resource Center</a>
			</div>
		</div>
	);
});
