import * as React from 'react';
import { observer } from 'mobx-react';
import { ScheduleImportCreateViewModel } from './schedule-import-create-view-model';
import { ScheduleImportStep } from '../schedule-import-step';
import { ModelMetadata, PaymentLabel } from '../../schedule-import-generated';
import { FormControlType, IFormControlLabelledProps } from '../../../components/form-controls/form-control-labelled';
import { CreateImportForm } from '../../../transaction-import/forms/create-import-form';
import { Alert, AlertStatus } from '../../../components/alert-controller';

@observer
export class ScheduleImportCreate extends React.Component<{ vm: ScheduleImportCreateViewModel }> {
	render() {
		const {
			importType,
			allImportTypes,
			merchantId,
			allVisibleListings,
			actionInProgress,
			prepareDataUrl,
			unavailableRecurringFrequencies,
			supportUrl,
			paymentLabel,
		} = this.props.vm;

		const helpLink = <a className="arrow-hollow-right-after info-link" target="_blank" rel="noopener noreferrer" href={prepareDataUrl}> here</ a>;

		const modelMetadata = ModelMetadata.CreateImportRequest;

		const formatSelectProps: IFormControlLabelledProps = {
			label: modelMetadata.ImportType.displayName,
			formControlProps: {
				formControlType: FormControlType.DropDown,
				name: modelMetadata.ImportType.propertyName,
				placeholder: `Select ${modelMetadata.ImportType.displayName.charAt(0).toLowerCase() +
					modelMetadata.ImportType.displayName.slice(1)
					}`,
				value: importType ? importType.toString() : null,
				onChangeHandler: this.handleImportTypeChange,
				Options: allImportTypes,
				validationRules: modelMetadata.ImportType.validationRules,
			},
		};

		const listingSelectProps: IFormControlLabelledProps = {
			label: modelMetadata.MerchantId.displayName,
			formControlProps: {
				formControlType: FormControlType.DropDown,
				name: modelMetadata.MerchantId.propertyName,
				placeholder: `Select ${modelMetadata.MerchantId.displayName.charAt(0).toLowerCase() +
					modelMetadata.MerchantId.displayName.slice(1)
					}`,
				value: merchantId || allVisibleListings.length === 1 ? merchantId.toString() : null,
				onChangeHandler: this.handleListingChange,
				Options: allVisibleListings,
				validationRules: modelMetadata.MerchantId.validationRules,
				hideEmptyOption: allVisibleListings.length === 1 ? true : false,
			},
		};

		const contactLink = supportUrl

		const warningBanner = unavailableRecurringFrequencies &&
			<Alert status={AlertStatus.Warning}>
				<p>{paymentLabel.NounSentenceCase} frequency schedules that have not been enabled will not be migrated for your organization:</p>
				<ul>
					{unavailableRecurringFrequencies.map((freq) => <li>{freq}</li>)}
				</ul>
				<p>If you would like to enable these frequencies, please <a target="_blank" rel="noopener noreferrer" href={contactLink}>contact us</a>.</p>
				<p>In the meantime, {paymentLabel.PayerPluralLowerCase} will be asked to choose a new schedule frequency upon migration. </p>
			</Alert>
		
		return (
			<ScheduleImportStep vm={this.props.vm} helpLink={helpLink}>
				{warningBanner}
				<CreateImportForm
					handleSubmit={this.handleSubmit}
					handleCancel={this.handleCancel}
					formatSelectProps={formatSelectProps}
					listingSelectProps={listingSelectProps}
					navigationInProgress={actionInProgress} />
			</ScheduleImportStep>
		);
	}

	private handleImportTypeChange = (event: React.FormEvent<HTMLSelectElement>) => {
		const value = event.currentTarget.value;
		this.props.vm.updateImportType(value);
	};

	private handleListingChange = (event: React.FormEvent<HTMLSelectElement>) => {
		const value = event.currentTarget.value;
		this.props.vm.updateSelectedListing(value);
	};

	private handleSubmit = () => {
		this.props.vm.initCreateImportRequest();
	};

	private handleCancel = () => {
		this.props.vm.cancelImport();
	};
}
