let features: { [key: string]: boolean };

declare const window: any;

function getFeatures() {
	if (!features) {
		window.pushpay = window.pushpay || {};
		features = window.pushpay.features = window.pushpay.features || {};

		freezeFeaturesToAvoidMutationAtRuntime(features);
	}

	return features;
}

function freezeFeaturesToAvoidMutationAtRuntime(features: object) {
	Object.freeze(features);
}

export const Features = {
	get SanitizeJavascript(): boolean {
		return getFeatures()['SanitizeJavascript'];
	},
	// You should not be adding any new features to this file
	// Any new features should be marked as <!-- frontend --> in Features.config and generated using JavaScript/Shared/features-generated.tt
};
