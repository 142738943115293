import { ConfigurePageEvents, FundsModalEvents } from '../state/configure-page';
import { FormEvents } from '../state/form-states';
import { GivingStatementRunStatus, GivingUnitType } from '../giving-statements-generated';
import { givingStatementsDataService } from '../giving-statements-data-service';
import { GivingStatementsViewModel } from '../giving-statements-view-model';
import { MachineContext } from '../../../Shared/state-machine/saga-state-machine';
import { MainEvents } from '../state/main';
import { PostError } from '../../utils/ajax-client';
import { urlService } from '../services/url';
import { UpdateRunResponse } from '../giving-statements-generated';

export const ConfigurePageActionMap = {
	LoadConfigureView: (context: MachineContext, isRegenerating: boolean) => {
		const vm: GivingStatementsViewModel = context.viewModel;
		const { configurePageViewModel, currentConfiguration } = vm;

		// We don't want the user to be able to change the configuration values when the status is in any of the following
		// states.
		switch (currentConfiguration.status) {
			case GivingStatementRunStatus.Generating:
			case GivingStatementRunStatus.Issuing:
				configurePageViewModel.setPeriodControlsDisabled(true);
				configurePageViewModel.setStatementControlsDisabled(true);
				configurePageViewModel.setNonChangeableControlsDisabled(true);
				break;
			case GivingStatementRunStatus.Generated:
			case GivingStatementRunStatus.Issued:
				configurePageViewModel.setPeriodControlsDisabled(true);
				configurePageViewModel.setStatementControlsDisabled(false);
				configurePageViewModel.setNonChangeableControlsDisabled(true);
				break;
			case GivingStatementRunStatus.Configured:
				if (currentConfiguration.hasBeenGenerated) {
					configurePageViewModel.setPeriodControlsDisabled(true);
					configurePageViewModel.setStatementControlsDisabled(false);
					configurePageViewModel.setNonChangeableControlsDisabled(true);
				} else {
					configurePageViewModel.setPeriodControlsDisabled(false);
					configurePageViewModel.setStatementControlsDisabled(false);
					configurePageViewModel.setNonChangeableControlsDisabled(false);
				}
				break;
			default:
				configurePageViewModel.setPeriodControlsDisabled(false);
				configurePageViewModel.setStatementControlsDisabled(false);
				configurePageViewModel.setNonChangeableControlsDisabled(false);
				break;
		}

		configurePageViewModel.setIsRegenerating(isRegenerating);

		// On configure page load, we fetch the member stats.
		context.transition(ConfigurePageEvents.LOAD_COMMUNITY_MEMBER_STATS);
	},
	LoadPreviousView: (context: MachineContext) => {
		const vm: GivingStatementsViewModel = context.viewModel;

		if (vm.configurePageViewModel.isRegenerating) {
			context.transition(MainEvents.LOAD_SEND_VIEW, { immediatelyMoveToSummary: true });
		} else {
			context.transition(MainEvents.LOAD_LANDING_VIEW);
		}
	},
	SubmitConfigurationForm: function* (context: MachineContext) {
		const viewModel: GivingStatementsViewModel = context.viewModel;

		if (!viewModel.configurePageViewModel.isFormValid) {
			return;
		}

		const {
			currentConfigurationId,
			configurePageViewModel: {
				isIntended
			},
			currentConfiguration: {
				phoneNumber,
				emailAddress,
				statementName,
				statementToDate,
				statementFromDate,
				selectedType,
				nonCashIncluded,
				einIncluded,
				customerAddressIncluded,
				publishType,
				contributionsThreshold,
			}
		} = viewModel as GivingStatementsViewModel;

		try {
				const response: UpdateRunResponse = 
					yield givingStatementsDataService.updateRunForNewModal({
						id: currentConfigurationId,
						isIntended: isIntended,
						updateModel: {
							ContactPhoneNumber: phoneNumber,
							From: statementFromDate,
							To: statementToDate,
							PeriodType: selectedType,
							StatementName: statementName,
							ContactEmail: emailAddress,
							NonCashIncluded: nonCashIncluded,
							EinIncluded: einIncluded,
							CustomerAddressIncluded: customerAddressIncluded,
							GivingUnitType: GivingUnitType[publishType],
							ContributionsThreshold: contributionsThreshold,
						}
					});

			if (response.IsDuplicatePeriod) {
				context.transition(ConfigurePageEvents.SHOW_DUPLICATE_PERIOD_MODAL);
			} else {
				context.transition(FormEvents.SUBMISSION_SUCCESS);
			}
		} catch (e) {
			context.transition(FormEvents.SUBMISSION_FAILURE, e);
		}
	},
	ConfigurationValidationFailed: function* (context: MachineContext, data: PostError) {
		const viewModel: GivingStatementsViewModel = context.viewModel;

		if (data.isPostError) {
			viewModel.configurePageViewModel.setValidationErrors(data.validationErrors);
		} else {
			viewModel.configurePageViewModel.setValidationErrors({ Unknown: 'Something went wrong, please try again' });
		}
	},
	ConfigurationValidationSucceeded: function (context: MachineContext) {
		const viewModel: GivingStatementsViewModel = context.viewModel;
		viewModel.configurePageViewModel.clearErrorMessages();
		// This is the point the user moves onto the next page, so pushing onto the history stack so when the user hits the
		// browser back button, we can transition to the previous state.
		urlService.pushPreviousViewState();

		const vm: GivingStatementsViewModel = context.viewModel;
		if (vm.configurePageViewModel.backToHomeAfterSubmit) {
			context.transition(MainEvents.LOAD_PREVIOUS_VIEW);
		} else {
			context.transition(MainEvents.LOAD_PREVIEW_VIEW);
		}
	},
	ShowFundsModal: function (context: MachineContext) {
		context.transition(FundsModalEvents.LOAD);
	},
	LoadFunds: function* (context: MachineContext) {
		try {
			const { currentConfigurationId } = context.viewModel;

			const funds = yield givingStatementsDataService.taxDeductibleFunds({ id: currentConfigurationId });
			const vm: GivingStatementsViewModel = context.viewModel;

			vm.configurePageViewModel.setTaxDeductibleFunds(funds);
			context.transition(FundsModalEvents.LOAD_SUCCESS);
		} catch (e) {
			context.transition(FundsModalEvents.LOAD_FAILURE, e);
		}
	},
	LoadCommunityMemberFundStats: function* (context: MachineContext) {
		try {
			const vm = context.viewModel as GivingStatementsViewModel;
			const { statementFromDate, statementToDate, nonCashIncluded } = vm.currentConfiguration;

			if (!statementFromDate || !statementToDate) {
				return;
			}

			const communityMemberFundStats = yield givingStatementsDataService.communityMemberFundStats({
				start: statementFromDate,
				end: statementToDate,
				includeNonCash: nonCashIncluded,
			});

			vm.setCommunityMemberFundStats(communityMemberFundStats);
		} catch (e) {
			context.transition(ConfigurePageEvents.LOAD_COMMUNITY_MEMBER_STATS_FAILURE, e);
		}
	},
};
