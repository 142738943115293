import * as React from 'react';
import { observable, runInAction, action } from 'mobx';
import { observer } from 'mobx-react';

import { classNames } from '../../../Shared/utils/classnames';
import * as styles from './file-upload-styles.less';

@observer
export class FileUploadAreaBorder extends React.Component<{ active: boolean }> {

	private borderContainer: SVGElement;

	@observable
	private borderSettings = {
		rx: 5,
		ry: 5,
		height: 0,
		width: 0
	};

	changeBorderSettings = () => {
		runInAction(() => {
			const { clientHeight, clientWidth } = this.borderContainer.parentNode as HTMLElement;
			this.borderSettings.width = clientWidth;
			this.borderSettings.height = clientHeight;
		});
	}

	componentDidMount() {
		this.changeBorderSettings();
		window.addEventListener('resize', this.changeBorderSettings);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.changeBorderSettings);
	}

	render() {
		const { width, height } = this.borderSettings;
		return (
			<svg
				className={classNames('upload-area-border', styles.border)}
				style={{ transform: `scale(${this.getScale(width)}, ${this.getScale(height)})` }}
				ref={element => this.borderContainer = element}
			>
				<rect {...this.borderSettings} />
			</svg>
		);
	}

	private getScale(size): number {
		if (!this.props.active || !size) {
			return 1;
		}
		const activeMargin = 10;
		return (size - activeMargin) / size;
	}
}
