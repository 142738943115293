import { MessageBox } from './message-box';
import { Models } from './login.generated';
import { FormSubmitCallbackResult, LoginForm } from './login-form';
import { LoginTabs } from './login-tabs';
import { MobileCodeForm } from './form-mobile-code';
import { LoginTooltip } from './login-tooltip';

export class MobileForm {
	private form: LoginForm<Models.PasswordlessMobileLoginResultModel>;
	private passwordlessTooltip: LoginTooltip;

	constructor(private messageBox: MessageBox, private loginTabs: LoginTabs, private mobileCodeForm: MobileCodeForm) {
		this.form = new LoginForm<Models.PasswordlessMobileLoginResultModel>(Models.LoginView.PayerMobile, loginTabs, messageBox, (data) => this.loginResult(data));
		const tooltipIcon = this.form.querySelector<HTMLElement>('[data-pp-login-passwordless-tooltip]');
		this.passwordlessTooltip = new LoginTooltip(tooltipIcon);
	}

	loginResult(data: Models.PasswordlessMobileLoginResultModel): FormSubmitCallbackResult {
		if (data.Result === Models.PasswordlessMobileLoginResult.ConfirmationCodeSent) {
			this.mobileCodeForm.setUpFormData(data.Token, data.Mobile);
			this.loginTabs.showMobileCodeForm();
		} else if (data.Result === Models.PasswordlessMobileLoginResult.UnverifiedPhoneNumber) {
			this.loginTabs.showEmailAndPassword();
			this.messageBox.displayError(data.ErrorMessage);
		} else {
			this.messageBox.displayError(data.ErrorMessage);
		}

		return FormSubmitCallbackResult.ReenableForm;
	}
}
