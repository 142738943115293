import * as React from 'react';
import { Button } from '../../../../Shared/components/button/button';
import { Fragment } from '../../../../Shared/components/fragment';

export interface IDeleteStatementsFailureModalProps {
    onClose: () => void;
}

export const DeleteStatementsFailureModal: React.FunctionComponent<IDeleteStatementsFailureModalProps> = (props) => {
    const { onClose } = props;

    return (
        <Fragment>
            <div className="modal-content" data-pp-at-target="Modal">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onClose} />
                <div className="modal-body left-align">
                    <h2>Error</h2>
                    <p>Something has gone wrong. Please reload the page and try again.</p>

                </div>
                <div className="modal-footer">
                    <Button type="button" className="btn btn-cancel" onClick={onClose}>Close</Button>
                </div>
            </div>
        </Fragment>
    );
};
