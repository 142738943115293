import { Machine, State } from 'xstate';
import { toStatePaths } from 'xstate/lib/utils';
import { observable, action } from 'mobx';
import { Subject } from 'rxjs';
import { PayloadAction } from './rx-state-machine-factory';
import { MachineConfig, StandardMachine, ParallelMachine } from 'xstate/lib/types';

export const VisualizeMachineSettings = Symbol('this is for Visualize machine only');

export interface IVisualizeMachineSettings {
	config: MachineConfig;
	getMachineState: () => State;
	transition: (eventName: string, payload?: {}) => void;
	reset: () => void;
	disableActions: (disable: boolean) => void;
}

export class RxMachineContext {

	private machine: StandardMachine | ParallelMachine;
	private eventCreator: Record<string, Function>;
	private _disableActionsForTesting = false;

	@observable
	private machineState: State;

	static connect = <TEventz extends { [P in string]: (machineContext: RxMachineContext) => Function }>(config: MachineConfig, subject: Subject<PayloadAction<any>[]>) => {
		const machineContext = new RxMachineContext(config, subject);
		return (eventz: TEventz, initialPayload?: {}) => {
			const eventCreator = getEventCreator(machineContext, eventz);

			machineContext.eventCreator = eventCreator;
			machineContext.machine = Machine(machineContext.config);
			machineContext.initialize(initialPayload);

			return {
				eventCreator,
				matchesState: machineContext.matchesState,
				matchesAnyState: machineContext.matchesAnyState,
				[VisualizeMachineSettings]: {
					config,
					getMachineState: () => machineContext.machineState,
					transition: machineContext.transition,
					reset: () => machineContext.initialize(initialPayload),
					disableActions: (disable: boolean) => machineContext._disableActionsForTesting = disable,
				} as IVisualizeMachineSettings
			};
		};
	}

	@action
	transition = (eventName: string, payload?: {}) => {
		console.log(eventName);

		const nextState = this.machine.transition(this.machineState, eventName);
		this.machineState = nextState;
		const actions = nextState.actions.map(action => ({ type: action, payload, eventCreator: this.eventCreator }));
		this.runActions([...actions]);
	}

	private constructor(private config: MachineConfig, private subject: Subject<PayloadAction<any>[]>) { }

	private matchesState = (state: string | string[]) => {
		const statePathToMatch = ([] as string[]).concat(state).join('.');
		const match = new RegExp(`(^|\\.)${statePathToMatch}($|\\.)`);
		const statePaths = toStatePaths(this.machineState.value).map(s => s.join('.'));

		return statePaths.some(statePath => match.test(statePath));
	}

	private matchesAnyState = (states: (string | string[])[]) => {
		return states.some(state => this.matchesState(state));
	}

	private initialize = (initialPayload?: {}) => {
		this.machineState = this.machine.initialState;
		this.runActions(this.machineState.actions.map(action => ({
			type: action,
			payload: initialPayload,
			eventCreator: this.eventCreator
		})));
	}

	private runActions = (actions: PayloadAction<any>[]) => {
		if (this._disableActionsForTesting) {
			return;
		}

		this.subject.next(actions);
	}
}

// eventHandlerSymbol implementation
// export const connect = <T extends { [P in string]: Record<string, object> & { [eventHandlerSymbol]: Function } }>(machineContext: RxMachineContext, events: T) => {
// 	return Object.keys(events).reduce((result, name) => {
// 		result[name] = events[name][eventHandlerSymbol];
// 		return result;
// 	}, {} as { [P in keyof T]: Function });
// };



const getEventCreator = <T extends { [P in keyof T]: (machineContext: RxMachineContext) => Function }>(machineContext: RxMachineContext, events: T) => {
	return Object.keys(events).reduce((result, name) => {
		result[name] = events[name](machineContext) as any;
		return result;
	}, {} as { [P in keyof T]: ReturnType<T[P]> });
};
