import * as React from 'react';
import { classNames } from '../../../Shared/utils/classnames';
import { Fragment } from '../../../Shared/components/fragment';
import { observer } from 'mobx-react';

import * as styles from './tab.less';

export interface ITabContainerProps {
	currentTabId: string | number;
	children: React.ReactNode;
	containerClassName?: string;
	navContainerClassName?: string;
}

export interface ITabContainerState {
	currentTabId: string | number;
}

@observer
export class TabContainer extends React.Component<ITabContainerProps, ITabContainerState> {

	private static isTabItem(item: any | Tab): item is Tab {
		return (item as React.ReactElement<any>).type === Tab;
	}

	constructor(props) {
		super(props);
		this.state = { currentTabId: this.props.currentTabId };
	}

	UNSAFE_componentWillReceiveProps(nextProps: ITabContainerProps) {
		if (nextProps.currentTabId !== this.props.currentTabId) {
			this.setState({ currentTabId: nextProps.currentTabId });
		}
	}

	render() {
		const { containerClassName, navContainerClassName } = this.props;
		const items = this.normalizeChildren();

		return (
			<div className={classNames(containerClassName)}>
				<ul className={classNames(styles.tabList, navContainerClassName)} role="presentation">
					{items.map((item) => {
						const { title, tabId, acceptanceTestTargetId } =
							item.props;
						return (
							<NavItem
								title={title}
								tabId={tabId}
								currentTabId={this.state.currentTabId}
								key={tabId}
								setActiveTab={this.setActiveTab}
								acceptanceTestTargetId={acceptanceTestTargetId}
							/>
						);
					})}
				</ul>
				<div>
					{items.filter( item => item.props.tabId === this.state.currentTabId)[0]}
				</div>
			</div>
		);
	}

	setActiveTab = (newTab: string | number) => {
		this.setState({ currentTabId: newTab });
	}

	/***
	 * Ensures all direct children are of type <Tab> (removing all others) and is an array (even if only one child)
	 */
	private normalizeChildren = (): React.ReactElement<ITabProps>[] => {
		const { children } = this.props;
		const arrChildren = React.Children.toArray(children) as React.ReactElement<any>[];
		return arrChildren.filter(child => TabContainer.isTabItem(child));
	}
}

export interface ITabProps {
	tabId: string | number;
	title: string;
	children: React.ReactNode;
	acceptanceTestTargetId: string;
}

@observer
export class Tab extends React.Component<ITabProps> {
	render() {
		return <Fragment>{this.props.children}</Fragment>;
	}
}

interface INavItemProps {
	setActiveTab: (tabId: number | string) => void;
	tabId: number | string;
	currentTabId: number | string;
	title: string;
	acceptanceTestTargetId: string;
}

@observer
class NavItem extends React.Component<INavItemProps> {
	render() {
		const { tabId, currentTabId, title, acceptanceTestTargetId } = this.props;

		return (
			<li className={styles.tab} role="presentation">
				<button className={classNames(styles.tabButton, tabId === currentTabId && styles.active)}
						onClick={this.buttonClickListener} type="button" data-pp-at-target={acceptanceTestTargetId}>
					{title}
				</button>
			</li>
		);
	}

	private buttonClickListener = () => {
		const { setActiveTab, tabId } = this.props;
		setActiveTab(tabId);
	}
}
