import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { Button } from '../../../../Shared/components/button/button';
import { IZipInformation } from '../../pages/send-statements/send-statements-page-view-model';
import { PanelBody, PanelFooter, PanelHeader } from '../../../components/panel';
import { MatchModal } from '../../../components/match-modal';
import { DownloadModalStates, SendPageEvents, SendPageStates } from '../../state/send-page';
import { Match } from '../../../../Shared/state-machine/match';
import { MachineContext, machineContextKey } from '../../../../Shared/state-machine/saga-state-machine';
import { LoadingBox } from '../../../components/loading-box';
import { SvgWrapper } from '../../../../Shared/components/svg-wrapper';
import * as styles from './styles.less';

export interface IDownloadStatementsModalProps {
	zips: IZipInformation[];
	showWhenInState: string | string[];
	machineContext?: MachineContext;
}

@inject(machineContextKey)
@observer
export class DownloadStatementsModal extends React.Component<IDownloadStatementsModalProps> {
	render() {
		const { zips, machineContext, showWhenInState } = this.props;

		const wrapperClassNames = ['modal-content'];

		if (machineContext.matchesState(DownloadModalStates.LOADING)) {
			wrapperClassNames.push('panel', 'panel-default');
		}

		const atTarget = showWhenInState === SendPageStates.DOWNLOAD_NO_EMAIL_MODAL_ACTIVE ? 'Download statements no email' : 'Download all statements';

		return (
			<MatchModal state={showWhenInState}>
				<div className={wrapperClassNames.join(' ')} data-pp-at-target={atTarget}>
					<Button className="close" aria-label="Close" onClick={this.onClose} />
					<Match state={DownloadModalStates.LOADING}>
						<div className="modal-body" style={{minHeight: '200px'}}>
							<LoadingBox text="" />
						</div>
					</Match>
					<Match state={DownloadModalStates.SUCCESS}>
						<PanelHeader>
							Download statements by batch
						</PanelHeader>
						<PanelBody>
							<div className={styles.listContainer}>
								<div className={styles.listItem}>
									<strong>Batch range</strong>
									<strong>Download</strong>
								</div>
								<ul className={styles.listItemContainer}>
									{zips.map((zip, index) => (
										<li key={index} className={styles.listItem}>
											<div>{zip.fromName} {(!zip.doesNotSupportFromAndToName) && '-'} {zip.toName}</div>
											<a href={zip.url}>Download</a>
										</li>
									))}
								</ul>
							</div>
						</PanelBody>
						<PanelFooter extraClassNames="panel-footer-btn-group">
							<Button className="btn btn-cancel" aria-label="Close" onClick={this.onClose}>
								Close
							</Button>
						</PanelFooter>
					</Match>
					<Match state={DownloadModalStates.FAIL}>
						<div className="modal-body">
							<SvgWrapper svg="alert-exclamation-icon" width={68} height={68}/>
							<h2>Error</h2>
							<p>Oops, something went wrong</p>
						</div>
						<div className="modal-footer">
							<Button className="btn btn-cancel" aria-label="Close" onClick={this.onClose}>
								Close
							</Button>
						</div>
					</Match>
				</div>
			</MatchModal>
		);
	}

	onClose = () => {
		this.props.machineContext.transition(SendPageEvents.DISMISS_DOWNLOAD_MODAL);
	}
}
