import * as React from 'react';
import { observer } from 'mobx-react';
import { IntegrationServiceClient } from './integration-service-client';
import { Button } from '../../components/button';

@observer
export class EnableIntegrationButton extends React.Component<{ serviceClient: IntegrationServiceClient }, {}> {
	render() {
		return (
			<Button isProcessingRequest={this.props.serviceClient.isEnabling}
				disabled={this.props.serviceClient.isProcessingRequest}
				className="btn btn-link btn-sm"
				onClick={this.props.serviceClient.enableIntegration}>
				Enable integration
			</Button>
		);
	}
}

