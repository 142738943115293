import * as React from 'react';
import { when } from 'mobx';
import { cps, race, call, Effect } from 'redux-saga/effects';
import { BrandingPackageViewModel } from '../branding-settings-generated';
import { IBrandingSettingsSagaContext } from '../branding-settings-saga';
import { BrandingSettingConfirmPublishDialog } from '../branding-settings-confirm-publish-dialog';
import { ModalDialogCommander } from '../../components/modal-dialog-commander';
import { BrandingPackageState, PublishState } from '../branding-settings-main-view-model';
import { alertController } from '../../components/alert-controller';
import { PostError } from '../../utils/ajax-client';
import { handleConflict } from './conflict-handler';

export function* requestPublishConfirmation(context: IBrandingSettingsSagaContext) {
	let publishAction: (error: any, result: any) => void;
	const publishEffect = cps((callback) => publishAction = callback); // convert callback `publishClicked` to generator `publishEffect`

	const dialog = React.createElement(BrandingSettingConfirmPublishDialog, {
		publishClicked: () => publishAction(null, true),
		vm: context.mainViewModel
	});

	var r = yield race({
		cancel: call(() => ModalDialogCommander.showReactDialog(dialog)),
		publish: publishEffect
	});

	return !!r.publish;
}

export function* publishDraftHandler(context: IBrandingSettingsSagaContext): IterableIterator<Effect> {
	const { mainViewModel } = context;
	const { formViewModel } = mainViewModel;

	const prevPackageState = mainViewModel.packageState;
	mainViewModel.packageState = BrandingPackageState.PublishAwaitingConfirmation;

	if (formViewModel.isSavingOrUploadingOrProcessing) {
		let readyAction: (error: any, result: any) => void;
		const waitingEffect = cps((callback) => readyAction = callback);

		when(() => !formViewModel.isSavingOrUploadingOrProcessing, () => {
			readyAction(null, mainViewModel.publishState === PublishState.Ready);
		});

		const ready = yield waitingEffect;
		if (!ready) {
			mainViewModel.packageState = prevPackageState;
			return;
		}
	}

	const merchantId = mainViewModel.model.SelectedListing.ListingId;
	var publishConfirmed = yield call(requestPublishConfirmation, context);
	if (publishConfirmed) {
		try {
			context.mainViewModel.packageState = BrandingPackageState.PublishingInProgress;
			const response: BrandingPackageViewModel = yield call(context.dataService.publishPackage, {
				merchantId: Number(merchantId),
				draftKey: mainViewModel.formViewModel.draftKey,
				eTag: formViewModel.eTag,
			});
			formViewModel.eTag = response.ETag;
			mainViewModel.packageState = BrandingPackageState.NoDraft;
			alertController.showSuccess('Your brand update has successfully been published, this can take up to 5 mins to take effect.');
			ModalDialogCommander.forceCloseCurrent();
		} catch (error) {
			if (error instanceof PostError && error.conflict) {
				const latestPackage = error.updatedRecordForConflict as BrandingPackageViewModel;
				yield call(handleConflict, context, latestPackage);
				ModalDialogCommander.forceCloseCurrent();
				return;
			}

			mainViewModel.packageState = BrandingPackageState.ChangesAwaitingPublish;
			// We don't want to close the dialog in a finally block, as reportError() potentially opens a new dialog (finally would close this)
			ModalDialogCommander.forceCloseCurrent();
			context.reportError(error);
		}
	} else {
		mainViewModel.packageState = BrandingPackageState.ChangesAwaitingPublish;
	}
}
