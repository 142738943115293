import * as React from 'react';

export interface IValidationMessageProps {
	errorMessage?: string;
	id?: string;
}

export class ValidationMessage extends React.Component<IValidationMessageProps, {}> {
	render() {
		const { errorMessage, id } = this.props;
		const ariaLabel = errorMessage? `There is a problem with a field: ${errorMessage}` : null;
		return <span id={id} role="alert" aria-atomic="true" className="field-validation-error" aria-label={ariaLabel}>{errorMessage}</span>;
	}
}
