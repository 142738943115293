import * as React from 'react';
import { observer } from 'mobx-react';
import { PaymentCommonFields } from '../../../components/payment-common-fields';
import { PaymentMethodSelector } from './payment-method-selector';
import { SmoothHeightTransition } from '../../../components/hoc-behavior/transitions';
import { PaymentMethod, PaymentMethodUiType, NonCashPaymentMethod } from './payment-entry-model';
import { PaymentMethodCard } from './payment-method-card';
import { PaymentMethodRecordedCard } from './payment-method-recorded-card';
import { PaymentMethodRecordedAch } from './payment-method-recorded-ach';
import { PaymentMethodRecordedCheck } from './payment-method-recorded-check';
import { PaymentMethodExistingAch } from './payment-method-existing-ach';
import { PaymentMethodExistingCard } from './payment-method-existing-card';
import { PaymentMethodAch } from './payment-method-ach';
import { AchAccountType, ModelMetadata, VirtualTerminalListingConfiguration } from '../../virtual-terminal-generated';
import { VirtualTerminalFormViewModel } from '../form/virtual-terminal-form-view-model';
import { CustomFieldEditModel } from '../../../loggedinweb-generated';
import { PaymentEntryAmount } from './payment-entry-amount';
import { LoadingBox } from '../../../components/loading-box';
import { FormControlLabelled, FormControlType, IFormControlLabelledTooltipProps } from '../../../components/form-controls/form-control-labelled';
import { EnumHelper } from '../../../helpers/enumhelper';
import { PaymentEntryRecurring } from './payment-entry-recurring';
import { createNamespace } from '../../../../Shared/helpers/namespace';
import { Country } from '../../../loggedinweb-generated';
import { checkNounLowerCase, checkNounTitleCase } from '../../utils/check-translator';
import { PaymentMethodNonCash, PaymentMethodNonCashAdditionalFields } from './payment-method-non-cash';
import { PaymentLabel } from '../../../loggedinweb-generated';
import { FundAllocation } from '../../../check-deposit/allocation/fund-allocation';
import { Models } from '../../../check-deposit/check-deposit-generated';
import CheckSplitEditModel = Models.CheckSplitEditModel;

const yourIdMetadata = ModelMetadata.VirtualTerminalPaymentRequestModel.YourId;

const yourIdTooltipOptions: IFormControlLabelledTooltipProps = {
	message: `Your own ID for the person from your records E.g. Tithe number`,
	placement: 'top',
};

const ns = createNamespace('vt-form-payment-details');

@observer
export class PaymentEntryDetails extends React.Component<{ vm: VirtualTerminalFormViewModel }, {}> {
	render() {
		const {
			paymentEntry,
			paymentEntryBusy,
			paymentEntryVisible,
			existingPaymentMethods,
			newPaymentMethods,
			checkReadingMode,
			listingStore,
			hasMoreExistingPaymentMethods,
			selectedMemberIsAnonymous,
			updateSendEmailNotifications,
			allowRecurring,
			allowNonCash,
			isSplitGivingEnabled,
			handleAddFund,
			handleRemoveFund,
			handleFundChanged,
			handleAmountChanged,
			handleAmountBlurred,
			splits,
			isSplit,
			fundField,
			referenceFields,
			formattedTotalAllocated,
			allAmountsAreValid,
			splitAmountErrorMessage,
		} = this.props.vm;

		if (paymentEntryBusy) {
			return (
				<div className="panel-body vt-form-payment-details vt-form-payment-details-loading disabled">
					<LoadingBox text="Processing" />
				</div>
			);
		}

		if (!paymentEntryVisible) {
			return null;
		}

		const { selectedListing: {
			PaymentLabel: paymentLabel,
			HomeCountry: country
		}, selectedListingConfiguration } = listingStore;
		const cardTokenizationAvailable = selectedListingConfiguration.CardTokenizationAvailable;
		const paymentMethodAllowsRecurring = paymentEntry.paymentMethod.isProcessed;
		const showPaymentMethodSelector = this.getPaymentMethodSelectorVisible(checkReadingMode, selectedMemberIsAnonymous,
			allowNonCash && listingStore.selectedListingConfiguration.SupportsNonCash);
		const paymentMethodEditor = getPaymentMethodEditor(paymentEntry.paymentMethod, listingStore.selectedListing.HomeCountry, listingStore.paymentLabel);
		const showCardsNotAvailableText = !cardTokenizationAvailable;

		// Anonymous payers can only do Cash or NonCash
		const availableNewPaymentMethods = (selectedMemberIsAnonymous
			? newPaymentMethods.filter(x => (x.type === PaymentMethodUiType.NonCash || x.type === PaymentMethodUiType.Cash))
			: newPaymentMethods);

		return (
			<div className={`${ns()}${paymentEntryBusy ? ' disabled' : ''}`}>
				<PaymentEntryAmount vm={this.props.vm} />
				<div className={`panel-body ${ns('general')}`}>
					<SmoothHeightTransition>
						{/* a user cannot change the payment method in check mode or when the selected payer is anonymous */}
						{showPaymentMethodSelector &&
							<div className="row">
								<div className="form-group col-md-4">
									<PaymentMethodSelector
											existingMethods={existingPaymentMethods}
											newMethods={availableNewPaymentMethods}
											hasMoreExistingMethods={hasMoreExistingPaymentMethods}
											selectedPaymentMethodKey={paymentEntry.paymentMethod.key}
											onPaymentMethodChange={this.handlePaymentMethodChange}
											checkNoun={checkNounLowerCase(country)}
											nonCashNoun={paymentLabel.NonCashNounSentenceCase}
											cardTokenizationAvailable={cardTokenizationAvailable} />
									<SmoothHeightTransition>
										{
											showCardsNotAvailableText &&
												this.cardsNotAvailableText(selectedListingConfiguration)
										}
									</SmoothHeightTransition>
								</div>
								<SmoothHeightTransition>
									<div className="col-md-4">
										{
											paymentMethodEditor.editor && !paymentMethodEditor.useFullRow && paymentMethodEditor.editor
										}
									</div>
								</SmoothHeightTransition>
							</div>
						}
					</SmoothHeightTransition>
					<SmoothHeightTransition>
						{paymentMethodEditor.editor && paymentMethodEditor.useFullRow ? paymentMethodEditor.editor : null}
					</SmoothHeightTransition>
					<SmoothHeightTransition>
						{allowRecurring && paymentMethodAllowsRecurring && (
							<div className={`${ns('recurring-toggle')} row`}>
								<FormControlLabelled cssClassNames="col-md-4"
													 label={`${paymentLabel.NounSentenceCase} type`}
													 formControlProps={{
														 name: 'recurring',
														 formControlType: FormControlType.Radio,
														 value: `${paymentEntry.isRecurring}`,
														 onChangeHandler: this.handleRecurringChange,
														 Options: [{
															 Label: 'One time',
															 Value: 'false'
														 }, {
															 Label: 'Recurring',
															 Value: 'true'
														 }],
														 acceptanceTestTargetId: 'is recurring',
													 }} />
							</div>
						)}
					</SmoothHeightTransition>
					<SmoothHeightTransition>
						{allowRecurring && paymentEntry.isRecurring && paymentMethodAllowsRecurring && <PaymentEntryRecurring vm={this.props.vm} inlinePanel />}
					</SmoothHeightTransition>
					<FundAllocation
						splitInfo={splits}
						viewModel={fundField}
						allAmountsAreValid={allAmountsAreValid}
						amountErrorMessage={splitAmountErrorMessage}
						formattedTotalAllocated={formattedTotalAllocated}
						onFundChange={handleFundChanged}
						onAmountChange={handleAmountChanged}
						onAmountBlur={handleAmountBlurred}
						onAdd={handleAddFund}
						onDelete={handleRemoveFund}
						maximumFundsLimit={5}
						singleFundOnly={!isSplitGivingEnabled}
					/>
					<PaymentCommonFields
						model={paymentEntry.CommonPaymentFields}
						customFields={referenceFields}
						paymentNounLowerCase={paymentLabel.NounLowerCase}
						paymentNounSentenceCase={paymentLabel.NounSentenceCase}
						onCustomFieldChange={this.handleReferenceFieldValueChange}
						onGivenOnChange={this.handleGivenOnChange}
						onNotesChange={this.handleNotesChange}
						yourId={paymentEntry.yourId}
						onYourIdChange={this.handleYourIdChange}
						sendEmailNotification={paymentEntry.sendEmailNotifications}
						disableSendEmail={paymentMethodEditor.disableEmailNotification}
						onEmailNotificationChange={updateSendEmailNotifications}
						showNotes={paymentMethodEditor.showNotes}
						showEmailNotification={paymentMethodEditor.showEmailNotification}
					/>
					{paymentMethodEditor.editorAfterCommonFields}
				</div>
				{paymentEntryBusy && <LoadingBox text="Processing" />}
			</div>
		);
	}

	private getPaymentMethodSelectorVisible(checkReadingMode: boolean, selectedMemberIsAnonymous: boolean, nonCashEnabledForMerchant: boolean) {
		if (checkReadingMode) {
			return false;
		}
		if (!selectedMemberIsAnonymous) {
			return true;
		}
		return nonCashEnabledForMerchant;
	}

	private cardsNotAvailableText(listing: VirtualTerminalListingConfiguration) {
		return (
			<span className="field-validation-error">
				<span>Entering card {listing.PaymentNounPluralLowerCase} is temporarily unavailable. <a href={listing.CardTokenizationHelpLink} target="_blank" rel="noopener noreferrer">Click here for more information</a></span>
			</span>
		);
	}

	private get disabled() {
		return this.props.vm.paymentEntryDisabled;
	}

	private handleGivenOnChange = (value: Date) => {
		if (this.disabled) {
			return;
		}
		this.props.vm.updateGivenOn(value);
	}

	private handleNotesChange = (value: string) => {
		if (this.disabled) {
			return;
		}
		this.props.vm.updateNotes(value);
	}

	private handleReferenceFieldValueChange = (field: CustomFieldEditModel) => {
		if (this.disabled) {
			return;
		}
		this.props.vm.updateReferenceFieldValue(field);
	}

	private handlePaymentMethodChange = (paymentMethodKey: string) => {
		if (this.disabled) {
			return;
		}

		this.props.vm.updatePaymentMethod(paymentMethodKey);
	}

	private handleYourIdChange = (event: React.FormEvent<HTMLInputElement>) => {
		if (this.disabled) {
			return;
		}
		this.props.vm.updateYourId(event.currentTarget.value);
	}

	private handleRecurringChange = (value: string) => {
		if (this.disabled) {
			return;
		}
		const isRecurring = value === 'true';
		this.props.vm.updateIsRecurring(isRecurring);
	}
}

function getPaymentMethodEditor(paymentMethod: PaymentMethod, country: Country, paymentLabel: PaymentLabel): {
	editor: React.ReactChild, useFullRow: boolean, showNotes: boolean, showEmailNotification: boolean, disableEmailNotification: boolean, editorAfterCommonFields: React.ReactChild} {
	if (!paymentMethod) {
		return { editor: null, useFullRow: false, showNotes: true, showEmailNotification: true, disableEmailNotification: false, editorAfterCommonFields: null };
	}

	let editor: React.ReactChild;
	let editorAfterCommonFields = null;
	let useFullRow = false;
	let showNotes = true;
	let showEmailNotification = true;
	let disableEmailNotification = false;

	switch (paymentMethod.type) {
		case PaymentMethodUiType.CreditCard:
			useFullRow = true;
			editor = <PaymentMethodCard card={paymentMethod} />;
			disableEmailNotification = true;
			break;
		case PaymentMethodUiType.RecordedCreditCard:
			editor = <PaymentMethodRecordedCard card={paymentMethod} />;
			break;
		case PaymentMethodUiType.RecordedCheck:
			const showRoutingAndAccountNumbers = country === Country.US;
			const checkNoun = checkNounTitleCase(country);
			useFullRow = showRoutingAndAccountNumbers;
			editor = <PaymentMethodRecordedCheck showRoutingAndAccountNumbers={showRoutingAndAccountNumbers} check={paymentMethod} checkNoun={checkNoun} />;
			break;
		case PaymentMethodUiType.Cash:
			editor = null;
			break;
		case PaymentMethodUiType.ACH:
			useFullRow = true;
			editor = <PaymentMethodAch accounts={getAchAccountTypes()} achBankAccount={paymentMethod} />;
			break;
		case PaymentMethodUiType.RecordedACH:
			editor = <PaymentMethodRecordedAch achBankAccount={paymentMethod} />;
			break;
		case PaymentMethodUiType.ExistingACH:
			editor = <PaymentMethodExistingAch existingAchBankAccount={paymentMethod} />;
			break;
		case PaymentMethodUiType.ExistingCreditCard:
			editor = <PaymentMethodExistingCard existingCard={paymentMethod} />;
			disableEmailNotification = true;
			break;
		case PaymentMethodUiType.NonCash:
			editor = <PaymentMethodNonCash noncash={paymentMethod} /> ;
			showNotes = false;
			showEmailNotification = false;
			editorAfterCommonFields = <PaymentMethodNonCashAdditionalFields noncash={paymentMethod} paymentLabel={paymentLabel} />;
			break;
	}

	return { editor, useFullRow, showNotes, showEmailNotification, disableEmailNotification, editorAfterCommonFields };
}

function getAchAccountTypes(): { Key: string, Label: string, Value: string }[] {
	return EnumHelper.MapKeyValues(AchAccountType).map(x => {
		return { Key: x.key, Label: x.key, Value: x.value };
	});
}
