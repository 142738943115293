import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Button } from '../../../../Shared/components/button/button';
import { classNames } from '../../../../Shared/utils/classnames';
import { Fragment } from '../../../../Shared/components/fragment';
import { FundsModalStates } from '../../state/configure-page';
import { LoadingBox } from '../../../components/loading-box';
import { MachineContext, machineContextKey } from '../../../../Shared/state-machine/saga-state-machine';
import { Match } from '../../../../Shared/state-machine/match';
import { PanelBody, PanelFooter, PanelHeader } from '../../../components/panel';
import { SvgWrapper } from '../../../../Shared/components/svg-wrapper';
import * as styles from './tax-deductible-funds-modal.less';
import * as rootStyles from '../shared-styles/styles.less';

interface ITaxDeductibleFundsProps {
	fundsSummaryUrl: string;
	items: string[];
}

@observer
class TaxDeductibleFunds extends React.Component<ITaxDeductibleFundsProps> {
	render() {
		const { fundsSummaryUrl, items } = this.props;

		return (
			<Fragment>
				<div className={styles.description}>
					<p>These are your funds that have been set as tax deductible.</p>
					<p>You can edit your funds by going to the <a href={fundsSummaryUrl}>Funds summary page</a>.</p>
				</div>
				<div className={styles.listContainer}>
					<div className={styles.listName}>Fund name</div>
					<ul className={styles.listItemContainer}>
					{
						items.map((item, index) => (
							<li key={index} className={styles.listItem}>{item}</li>
						))
					}
					</ul>
				</div>
			</Fragment>
		);
	}
}

export interface ITaxDeductibleFundsModalProps extends ITaxDeductibleFundsProps {
	onClose?: () => void;
	machineContext?: MachineContext;
}

@inject(machineContextKey)
@observer
export class TaxDeductibleFundsModal extends React.Component<ITaxDeductibleFundsModalProps> {
	render() {
		const { onClose, machineContext, ...others } = this.props;
		const modalClassNames = ['modal-content'];

		if (machineContext.matchesState(FundsModalStates.SUCCEEDED)) {
			modalClassNames.push('panel', 'panel-default');
		}

		return (
			<div className={modalClassNames.join(' ')}>
				<Button className="close" aria-label="Close" onClick={onClose} />
				<Match state={FundsModalStates.LOADING}>
					<div className={classNames('modal-body', rootStyles.loadingSpinnerWrapper)}>
						<LoadingBox text="" />
					</div>
				</Match>
				<Match state={FundsModalStates.SUCCEEDED}>
					<PanelHeader>
						<div>Tax deductible funds</div>
					</PanelHeader>
					<PanelBody>
						<TaxDeductibleFunds {...others} />
					</PanelBody>
					<PanelFooter extraClassNames="panel-footer-btn-group">
						<Button className="btn btn-cancel" aria-label="Close" onClick={onClose}>
							Close
						</Button>
					</PanelFooter>
				</Match>
				<Match state={FundsModalStates.FAILED}>
					<div className="modal-body">
						<SvgWrapper svg="alert-exclamation-icon" width={68} height={68}/>
						<h2>Error</h2>
						<p>Something has gone wrong. Please reload the page and try again.</p>
					</div>
				</Match>
			</div>
		);
	}
}
