export class Debouncer<T> {
	private timeout: number | null = null;
	constructor(private wait: number, private callback: (value: T) => void) { }

	exec(value?: T): void {
		this.cancel();
		this.timeout = window.setTimeout(this.callback, this.wait, value);
	}

	cancel() {
		if (this.timeout) {
			window.clearTimeout(this.timeout);
		}
	}
}
