import * as React from 'react';
import { observer } from 'mobx-react';
import { Fragment } from '../../../../Shared/components/fragment';
import { PanelHeader, PanelBody } from '../../../components/panel';
import * as styles from './styles.less';
import * as sharedStyles from '../shared-styles/styles.less';

export interface ICommunityMemberStatsDisplay {
	memberCount: number;
	memberWithoutEmailCount: number;
	memberWithoutEmailOrPhysicalCount: number;
}

export const CommunityMemberStatsDisplay: React.StatelessComponent<ICommunityMemberStatsDisplay> = observer((props) => {
	const {
		memberCount,
		memberWithoutEmailCount,
		memberWithoutEmailOrPhysicalCount
	} = props;

	return (
		<Fragment>
			<PanelHeader>
				Community members included
				<p className={styles.panelSubHeader}>
					Community members who will receive a statement based on the above selections.
				</p>
			</PanelHeader>
			<PanelBody>
				<div className={sharedStyles.panelRowGroup}>
					<div className={`row ${sharedStyles.panelRow}`}>
						<span className={`col-xs-6 ${sharedStyles.panelRowLabel}`}>Community members </span>
						<span className={`col-xs-6 ${sharedStyles.panelRowValue}`}>{memberCount}</span>
					</div>
					<div className={`row ${sharedStyles.panelRow}`}>
						<span className={`col-xs-6 ${sharedStyles.panelRowLabel}`}>Members without email addresses </span>
						<span className={`col-xs-6 ${sharedStyles.panelRowValue}`}>{memberWithoutEmailCount}</span>
					</div>
					<div className={`row ${sharedStyles.panelRow}`}>
						<span className={`col-xs-6 ${sharedStyles.panelRowLabel}`}>Members without email addresses and physical addresses</span>
						<span className={`col-xs-6 ${sharedStyles.panelRowValue}`}>{memberWithoutEmailOrPhysicalCount}</span>
					</div>
				</div>
			</PanelBody>
		</Fragment >
	);
});
