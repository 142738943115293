import * as React from 'react';
import { createNamespace } from '../../../../Shared/helpers/namespace';
import { observer, inject } from 'mobx-react';
import { RecentGiftEntryViewModel, Currency, BatchEntryPaymentViewModel, PaymentLabel } from '../../virtual-terminal-generated';
import { DataGridRowData } from '../../../components/data-grid/data-grid-row-data';
import { IDataGridColumn, DataGridCell } from '../../../components/data-grid/data-grid';
import { SvgWrapper } from '../../../components/svg-wrapper';
import { ResponsiveBreakpoint, ResponsiveVisibility } from '../../../helpers/responsive-helper';
import { RenderResponsive } from '../../../components/hoc-behavior/render-responsive';
import { Formatter } from '../../../helpers/formatter';
import { velocity } from '../../../helpers/velocity';
import { getBatchGiftsGridColumnConfig } from './batch-entry-payments-grid';
import { BatchEntryUserActionCreator } from '../../pages/batch-entry/batch-entry-user-actions';
import { LoadingBox } from '../../../components/loading-box';
import { injectAnalytics, recordEvent, WithAnalytics } from '../../../analytics';

const ns = createNamespace('vt-payments-payment-details');

const BatchEntryTitle = ({ paymentLabel }) => <h3 className={ns('title')}>{paymentLabel.NounSentenceCase} details</h3>;

@injectAnalytics
@inject('userActionCreator')
@observer
export class BatchEntryPaymentDetails extends React.Component<WithAnalytics & {
	payment: BatchEntryPaymentViewModel,
	expandedPayment: BatchEntryPaymentViewModel,
	paymentLabel: PaymentLabel,
	updateExpandedRow: (expandedRowKey: string) => void,
	acceptableLoadingTimeExceeded: boolean,
	userActionCreator?: BatchEntryUserActionCreator,
	setExpandedPayment: (payment: BatchEntryPaymentViewModel) => void,
}, {}> {
	private columns: { [key: string]: IDataGridColumn };

	constructor(props) {
		super(props);

		this.columns = getBatchGiftsGridColumnConfig(props.paymentLabel);

		Object.assign(this.columns, { CloseGift: { displayName: '', formatter: this.formatClose } });
		Object.assign(this.columns.PaymentMethod, { responsiveBreakpoints: ResponsiveBreakpoint.Xs | ResponsiveBreakpoint.Sm });
		Object.assign(this.columns.FundDisplayValue, { responsiveBreakpoints: ResponsiveBreakpoint.Xs | ResponsiveBreakpoint.Sm });
	}

	render() {
		const { payment, paymentLabel, expandedPayment, acceptableLoadingTimeExceeded, analytics } = this.props;
		const { EditUrl, IsDeletable } = payment;

		return (
			<div className={ns()}>
				<header className="panel-heading">
					<RenderResponsive visibility={ResponsiveVisibility.Visible} breakpoints={ResponsiveBreakpoint.Xs | ResponsiveBreakpoint.Sm}>
						<BatchEntryTitle paymentLabel={paymentLabel} />
					</RenderResponsive>
					<DataGridRowData data={payment} columns={this.columns} onSelectRow={null} />
				</header>
				<div className={`panel-body hidden-print ${!expandedPayment && acceptableLoadingTimeExceeded ? ns('details-loading') : ''}`}>
					<RenderResponsive visibility={ResponsiveVisibility.Hidden} breakpoints={ResponsiveBreakpoint.Xs | ResponsiveBreakpoint.Sm}>
						<BatchEntryTitle paymentLabel={paymentLabel} />
					</RenderResponsive>
					{ expandedPayment
						? <BatchEntryGridPaymentDetailsFull key="full-details" payment={expandedPayment} />
						: acceptableLoadingTimeExceeded && <LoadingBox key="full-details-loading" text={`Loading ${paymentLabel.NounLowerCase} details...`} />
					}
				</div>
				{(EditUrl || IsDeletable) && (
					<footer className="panel-footer panel-footer-btn-group hidden-print">
						{IsDeletable &&
							<button type="button"
								className={`btn btn-ghost ${ns('delete-btn')}`}
								data-pp-at-target="Delete payment details"
								onClick={this.onDeleteGift}>
								Delete
							</button>}
						{EditUrl && (
							<a
								href={EditUrl}
								onClick={() => {
									if (NewFeatures.SetupPinpointAnalytics && analytics) {
										const { feature, subFeature } = analytics;
										recordEvent({ feature, subFeature, eventTypeLabel: 'editGift', immediate: true });
									}
								}}
								className="btn btn-default">
								Edit
							</a>
						)}
					</footer>
				)}
			</div>
		);
	}

	componentDidMount() {
		const { payment, userActionCreator } = this.props;
		userActionCreator.LoadBatchPayment(payment.EncodedToken);
	}

	componentWillUnmount() {
		this.props.setExpandedPayment(null);
	}

	onDeleteGift = () => {
		const { payment: { EncodedToken }, userActionCreator } = this.props;
		userActionCreator.LaunchDeleteBatchPaymentDialog(EncodedToken);
	}

	private closeGiftDetails = () => {
		this.props.updateExpandedRow(null);
	}

	private formatClose = (gift: RecentGiftEntryViewModel, classNames: string) => (
		<DataGridCell key="CloseGift" classNames={classNames}>
			<button type="button" className={`btn btn-link ${ns('close-btn')}`} onClick={this.closeGiftDetails}>
				<SvgWrapper svg="close-cross" className={ns('close-icon')} title={`Close ${this.props.paymentLabel.NounSentenceCase}`} />
			</button>
		</DataGridCell>
	)
}

@observer
class BatchEntryGridPaymentDetailsFull extends React.Component<{ payment: BatchEntryPaymentViewModel }, {}> {
	private content: HTMLElement;

	render() {
		const givenOnDateFormat = 'dddd, D MMMM YYYY';
		const itemClassname = ns('item');
		const {
			AmountAsMoney: { Currency: amountCurrency, Amount },
			PaymentMethod,
			PayerDisplayName,
			PayerDisplayEmail,
			PayerDisplayPhone,
			PayerAddress,
			FundDisplayValue,
			AdditionalInfo: { ReferenceFields, Notes, GivenOn },
		} = this.props.payment;

		return (
			<div className={`row ${ns('body-content')}`} data-pp-at-target="Payment details content" ref={(content) => this.content = content}>
				<div className={`${ns('column-payment')} col-lg-3 col-md-4 col-sm-12`}>
					<div className={itemClassname}>
						<label id={ns('label-amount')}>Amount</label>
						<div className={ns('amount')} aria-labelledby={ns('label-amount')}>
							{Currency[amountCurrency]} <span className={ns('amount-number')}>${Formatter.formatNumberForDisplay(Amount)}</span>
						</div>
					</div>
					<div className={itemClassname}>
						<label id={ns('label-paymentmethod')}>Payment Method</label>
						<div aria-labelledby={ns('label-paymentmethod')}>{PaymentMethod}</div>
					</div>
					{GivenOn && (
						<div className={itemClassname}>
							<label id={ns('label-givenon')}>Given on</label>
							<div aria-labelledby={ns('label-givenon')}>{Formatter.formatDate(GivenOn, givenOnDateFormat)}</div>
						</div>
					)}
				</div>
				<div className={`${ns('column-payer')} col-lg-3 col-md-4 col-sm-6`}>
					<div className={itemClassname}>
						<label id={ns('label-name')}>Name</label>
						<div aria-labelledby={ns('label-name')}>{PayerDisplayName}</div>
					</div>
					{PayerDisplayEmail && (
						<div className={itemClassname}>
							<label id={ns('label-email')}>Email</label>
							<div aria-labelledby={ns('label-email')}>{PayerDisplayEmail}</div>
						</div>)}
					{PayerDisplayPhone && (
						<div className={itemClassname}>
							<label id={ns('label-phone')}>Phone</label>
							<div aria-labelledby={ns('label-phone')}>{PayerDisplayPhone}</div>
						</div>)}
					{PayerAddress && (
						<div className={itemClassname}>
							<label id={ns('label-address')}>Address</label>
							<div aria-labelledby={ns('label-address')}>{PayerAddress}</div>
						</div>)}
				</div>
				<div className="col-lg-3 col-md-4 col-sm-6">
					<div className={itemClassname}>
						<label id={ns('label-fund')}>Fund</label>
						<div aria-labelledby={ns('label-fund')}>{FundDisplayValue}</div>
					</div>
					{ReferenceFields && ReferenceFields.length > 0 && ReferenceFields.map((field) => {
						const { Key, Label, Value } = field;

						return Value && (
							<div key={Key} className={itemClassname}>
								<label id={Key}>{Label}</label>
								<div aria-labelledby={Key}>{Value}</div>
							</div>
						);
					})}
					{Notes && (
						<div className={itemClassname}>
							<label id={ns('label-notes')}>Notes</label>
							<div aria-labelledby={ns('label-notes')}>{Notes}</div>
						</div>)}
				</div>
			</div>
		);
	}

	componentWillEnter(callback) {
		this.content.style.opacity = '0';
		velocity(this.content, 'fadeIn', {
			duration: 250,
			easing: 'ease-in',
			complete: callback
		});
	}
}
