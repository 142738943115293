import { observable, action } from 'mobx';
import { ScheduleImportStepViewModel, ImportStep } from '../schedule-import-step-view-model';
import {
	BundleViewModel,
	ReviewImportViewModel,
	OperationResponse,
	OperationResult,
	BundlesSummary,
	DeleteBundleResponse,
} from '../../schedule-import-generated';
import { getScheduleImportDataService, ScheduleImportDataServiceAction, ScheduleImportApiConfigType, } from '../../schedule-import-data-service';
import { IDataServiceActionSubscriber } from '../../../utils/data-service';

export class ScheduleImportReviewViewModel extends ScheduleImportStepViewModel {
	@observable bundles: BundleViewModel[];
	@observable bundlesSummary: BundlesSummary;
	readonly createImportUrl: string;
	readonly hasConfirmedOrActiveMigration: boolean;

	bundleToDelete: BundleViewModel;
	deleteImportSubscriber: IDataServiceActionSubscriber<ScheduleImportApiConfigType, 'deleteImport'>;
	confirmImportSubscriber: IDataServiceActionSubscriber<ScheduleImportApiConfigType, 'confirmImport'>;

	constructor(model: ReviewImportViewModel) {
		super(
			model.PaymentLabel,
			ImportStep.Review,
			'Review and confirm',
			'Please review and confirm the details below or upload more files.'
		);
		this.bundles = model.Bundles;
		this.createImportUrl = model.CreateImportUrl;
		this.hasConfirmedOrActiveMigration = model.HasConfirmedOrActiveMigration;
		this.bundlesSummary = model.BundlesSummary;

		this.deleteImportSubscriber = getScheduleImportDataService().getActionSubscriberFactory('deleteImport')((action) => this.subscribeToDeleteImport(action));
		this.confirmImportSubscriber = getScheduleImportDataService().getActionSubscriberFactory('confirmImport')((action) => this.subscribeToConfirmImport(action));
	}

	@action.bound
	handleConfirmImport() {
		this.actionInProgress = true;
		this.confirmImportSubscriber.initRequest({
			model: {
				BundleIds: this.bundles.map(b => b.BundleId),
				ClientDate: new Date(),
			}
		});
	}

	@action.bound
	handleNewUpload() {
		window.location.href = this.createImportUrl;
		this.actionInProgress = true;
	}

	@action.bound
	handleDeleteImport(bundleId: number) {
		this.actionInProgress = true;
		[this.bundleToDelete] = this.bundles.filter( b => b.BundleId === bundleId);
		this.deleteImportSubscriber.initRequest({
			scheduleImportBundleId: bundleId
		});
	}

	@action.bound
	subscribeToDeleteImport(action: ScheduleImportDataServiceAction) {
		switch (action.type) {
			case 'request_success':
				const response: DeleteBundleResponse = action.response;
				if (response.Result === OperationResult.Success) {
					this.showSuccessAlert(`Your upload for ${this.bundleToDelete.ListingName} has been deleted.`);

					const index = this.bundles.indexOf(this.bundleToDelete);
					this.bundles.splice(index, 1);
					this.bundleToDelete = null;

					this.bundlesSummary = response.BundlesSummary;

				} else {
					this.showErrorAlert(response.ErrorMessage);
				}
				break;
			case 'request_error':
				const validationErrors = action.error.validationErrors;
				if (validationErrors) {
					this.showValidationErrorsAlert(validationErrors);
				}
				break;
		}
		this.actionInProgress = false;
	}

	@action.bound
	subscribeToConfirmImport(action: ScheduleImportDataServiceAction) {
		switch (action.type) {
			case 'request_success':
				const response: OperationResponse = action.response;
				if (response.Result === OperationResult.Success) {
					window.location.href = response.SuccessUrl;
					return;
				} else {
					this.showErrorAlert(response.ErrorMessage);
				}
				break;
			case 'request_error':
				const validationErrors = action.error.validationErrors;
				if (validationErrors) {
					this.showValidationErrorsAlert(validationErrors);
				}
				break;
		}
		this.actionInProgress = false;
	}
}
