import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { NodeListHelper } from '../helpers/nodelisthelper';
import { FloatingLabelComponent, FloatingLabelComponentStore } from '../funds/components/floating-label';
import { action } from 'mobx';

type ElementType = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;

export class FloatingLabel {
	private isHtmlSelectElement: boolean;
	private element: ElementType;
	private wrapper: HTMLElement;
	private labelRoot: HTMLElement;
	private labelText: string;
	private placeholder: string;
	private store: FloatingLabelComponentStore;

	static bindAll() {
		NodeListHelper.toArray<HTMLElement>(document.querySelectorAll('[data-has-floating-label]'))
			.forEach((element) => new FloatingLabel(element, element.getAttribute('data-has-floating-label')));
	}

	constructor(element: HTMLElement, labelText: string) {
		const typedElement = element;

		if (this.isElementAlreadyInitialized(element)) {
			return;
		}

		if (!this.isSupportedElementType(typedElement)) {
			throw new Error('element must be either input, textarea or select');
		} else {
			this.element = typedElement;
		}

		this.labelText = labelText;
		this.isHtmlSelectElement = typedElement instanceof HTMLSelectElement;

		this.initWrapper();
		this.configureStore(labelText);

		this.placeholder = this.element.getAttribute('placeholder');
		this.element.setAttribute('placeholder', '');

		this.renderLabel();
		this.bind();
	}

	destroy() {
		this.unbind();
	}

	private toggleElementFocus = () => {
		this.element.focus();
	}

	@action
	private configureStore(labelText: string) {
		this.store = new FloatingLabelComponentStore();
		this.store.elementHasValue = !!this.element.value;
		this.store.showLabelOnFocus = !this.isHtmlSelectElement;
	}

	private handleChange = () => {
		this.store.elementHasValue = !!this.element.value;
	}

	private handleFocus = () => {
		this.store.elementHasFocus = true;
	}

	private handleBlur = () => {
		this.store.elementHasFocus = false;
	}

	private isSupportedElementType(element: HTMLElement): element is ElementType {
		return element instanceof HTMLInputElement ||
			element instanceof HTMLTextAreaElement ||
			element instanceof HTMLSelectElement;
	}

	private initWrapper() {
		//select has select-wrapper element while other elements do not have wrappers
		this.wrapper = this.isHtmlSelectElement ? this.element.parentElement : this.element;
		this.wrapper.classList.add('has-floating-label');
	}

	private renderLabel = () => {
		const label = React.createElement(FloatingLabelComponent, {
			store: this.store,
			label: this.labelText,
			placeholder: this.placeholder,
			onElementShouldGainFocus: this.toggleElementFocus
		});
		if (!this.labelRoot) {
			this.labelRoot = document.createElement('div');
			this.wrapper.parentNode.insertBefore(this.labelRoot, this.wrapper);
		}

		ReactDOM.render(label, this.labelRoot);
		this.element.setAttribute('data-floating-label-ready', '');
	}

	private isElementAlreadyInitialized(element: HTMLElement) {
		return element.hasAttribute('data-floating-label-ready');
	}

	private bind() {
		this.element.addEventListener('change', this.handleChange);
		this.element.addEventListener('focus', this.handleFocus);
		this.element.addEventListener('blur', this.handleBlur);
	}

	private unbind() {
		this.element.removeEventListener('change', this.handleChange);
		this.element.removeEventListener('focus', this.handleFocus);
		this.element.removeEventListener('blur', this.handleBlur);
	}
}
