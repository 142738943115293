import { MessageBox } from './message-box';
import { Models } from './login.generated';
import { FormSubmitCallbackResult, LoginForm } from './login-form';
import { LoginTabs } from './login-tabs';

export class EmailPasswordForm {
	private form: LoginForm<Models.LoginResultModel>;
	private passwordField: HTMLInputElement;
	private userNameField: HTMLInputElement;

	constructor(tab: Models.LoginView, tabs: LoginTabs, private messageBox: MessageBox) {
		this.form = new LoginForm<Models.LoginResultModel>(tab, tabs, messageBox, (data) => this.loginResult(data));
		this.userNameField = this.form.querySelector<HTMLInputElement>('input[name=UserName]');
		this.passwordField = this.form.querySelector<HTMLInputElement>('input[name=Password]');
	}

	setUserName(userName: string): void {
		if (userName && userName.length > 0) {
			this.userNameField.value = userName;
		}
	}

	private loginResult(data: Models.LoginResultModel) : FormSubmitCallbackResult {
		if (data.Success) {
			window.location.href = data.RedirectTo;
			return FormSubmitCallbackResult.AboutToNavigate;
		}

		this.messageBox.displayError(data.ErrorMessage);
		this.passwordField.value = '';
		this.passwordField.focus();
		return FormSubmitCallbackResult.ReenableForm;
	}
}
