import * as React from 'react';

export interface ILinkProps extends React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
	acceptanceTargetId: string;
}

export class Link extends React.Component<ILinkProps> {
	render() {
		const { acceptanceTargetId, children, ...rest} = this.props;
		return (
			<a data-pp-at-target={acceptanceTargetId} {...rest}>{children}</a>
		);
	}
}
