import * as React from 'react';
import {observable, action, computed, reaction} from 'mobx';
import {observer} from 'mobx-react';
import { Virtualize, Virtualizer, VirtualItem } from '../../Shared/components/virtualize';
import {KeyCodes} from '../../Shared/helpers/keycodes';
import {Utils} from '../helpers/utils';
import {responsiveHelper} from '../helpers/responsive-helper';
import {InputField} from '../funds/components/form-controls';
import KeyboardEvent = React.KeyboardEvent;
import {FloatingLabelComponent, FloatingLabelComponentStore} from '../funds/components/floating-label';
import { validation } from '../validation/validation';
import { classNames } from '../../Shared/utils/classnames';

// replicates AutocompleteItem but with optional TextPrefix
// ReSharper disable InconsistentNaming
export interface IAutocompleteItem {
	Text: string;
	TextPrefix?: string;
	Value: string;
}
// ReSharper restore InconsistentNaming

export class AutocompleteStore {
	@observable items: IAutocompleteItem[];
	@observable selectedItem: IAutocompleteItem;
	@observable currentIndex: number;
	@observable searchTerm: string;
	@observable isActive: boolean;
	@observable dropListActive: boolean;

// replicates ResponseTypes.AutocompleteViewModel
// ReSharper disable InconsistentNaming
	constructor(autocompleteViewModel: { Items: IAutocompleteItem[], SelectedValue: string}) {
// ReSharper restore InconsistentNaming
		this.items = autocompleteViewModel.Items;
		this.selectedItem = this.items.find(x => x.Value === autocompleteViewModel.SelectedValue) || null;
		this.searchTerm = this.selectedText;
	}

	@action
	activate = () => {
		this.isActive = true;
		this.showDropList(true);
		this.currentIndex = this.displayItems.indexOf(this.selectedItem);
	}

	@action
	deactivate = () => {
		if (!this.isActive) {
			return;
		}

		this.isActive = false;
		this.showDropList(false);
		this.resetCurrentItem();

		const matchingItems = this.displayItems.filter(x => this.exactMatchExpression.test(x.Text));

		if (matchingItems.length === 0) {
			this.selectedItem = null;
			this.searchTerm = '';
		} else if (matchingItems.length === 1) {
			this.selectedItem = matchingItems[0];
			this.searchTerm = this.selectedItem.Text;
		} else if (!this.selectedItem) {
			this.searchTerm = '';
		}
	}

	@computed
	get selectedValue() {
		return this.selectedItem ? this.selectedItem.Value : '';
	}

	@computed
	get selectedText() {
		return this.selectedItem && this.selectedItem.Text;
	}

	@action
	showDropList = (showDropList: boolean) => {
		this.dropListActive = showDropList;
	}

	@action
	selectItem = (item: IAutocompleteItem) => {
		this.selectedItem = item;
		this.searchTerm = this.selectedText;
		this.showDropList(false);
		this.resetCurrentItem();
	}

	@action
	selectItemByValue = (selectedValue: string) => {
		const selectedItem = this.items.find(x => x.Value === selectedValue) || null;
		this.selectItem(selectedItem);
	}

	@action
	search = (searchTerm: string) => {
		this.searchTerm = searchTerm;
		this.selectedItem = null;
		this.currentIndex = 0;
		this.showDropList(true);
	}

	@action
	reset = () => {
		this.searchTerm = '';
		this.selectedItem = null;
	}

	@computed
	get exactMatchExpression() {
		return new RegExp(`^(${Utils.escapeRegex(this.searchTerm)})$`, 'i');
	}

	@computed
	get searchExpression() {
		return new RegExp(`(${Utils.escapeRegex(this.searchTerm)})`, 'i');
	}

	@computed
	get displayItems() {
		if (this.selectedItem) {
			return this.items;
		}

		return this.searchTerm
			? this.items.filter(x => this.searchExpression.test(x.Text))
			: this.items;
	}

	@action
	setCurrentItem = (item: IAutocompleteItem) => { this.currentIndex = this.displayItems.indexOf(item); }

	@action
	resetCurrentItem = () => { this.currentIndex = -1; }

	@computed
	get currentItem() {
		return this.currentIndex < 0
			? null
			: this.displayItems[this.currentIndex];
	}

	@action
	moveCurrentItemUp = () => {
		if (this.dropListActive) {
			this.currentIndex = Math.max(this.currentIndex - 1, 0);
		} else {
			this.activate();
		}
	}

	@action
	moveCurrentItemDown = () => {
		if (this.dropListActive) {
			this.currentIndex = Math.max(Math.min(this.currentIndex + 1, this.displayItems.length - 1), 0);
		} else {
			this.activate();
		}
	}

	@action
	selectCurrentItem = (): boolean => {
		if (this.displayItems.length === 1) {
			this.selectItem(this.displayItems[0]);
			return true;
		}
		if (this.currentIndex < 0) {
			return false;
		}
		this.selectItem(this.displayItems[this.currentIndex]);
		return true;
	}
}

export interface IAutocompleteProps {
	autocompleteStore: AutocompleteStore;
	emptyText?: string;
	floatingLabelText?: string;
	fieldName?: string;
	validationRules?: any;
	disabled?: boolean;
	validateOnInit?: boolean;
	useVirtualized?: boolean; // FF UseVirtualizedDropdownComponentInLoggedInWeb
}

@observer
export class Autocomplete extends React.Component<IAutocompleteProps, {}> {
	private container: HTMLElement;
	private input: HTMLInputElement;
	private itemsContainer: HTMLElement;
	private currentItem: HTMLElement;
	private useVirtualized: boolean; // FF UseVirtualizedDropdownComponentInLoggedInWeb

	private floatingLabelStore = new FloatingLabelComponentStore();

	constructor(props) {
		super(props);
		this.floatingLabelStore.showLabelOnFocus = true;
		this.useVirtualized = this.props.useVirtualized; // FF UseVirtualizedDropdownComponentInLoggedInWeb
		this.updateStateOfFloatingLabelStore();
		reaction(() => this.store.isActive || this.store.selectedItem, this.updateStateOfFloatingLabelStore);
	}

	@action
	updateStateOfFloatingLabelStore = () => {
		this.floatingLabelStore.elementHasFocus = this.store.isActive;
		this.floatingLabelStore.elementHasValue = this.store.selectedItem !== null && this.store.selectedItem !== undefined;
	}

	render() {
		// FF UseVirtualizedDropdownComponentInLoggedInWeb
		if (this.useVirtualized) {
			return (
				<div className="autocomplete open" ref={(ref) => this.container = ref}
					onKeyDown={this.handleKeyPress}>
					<div onClick={this.handleComponentFocused} onFocus={this.handleComponentFocused} onBlur={this.handleComponentBlur}>
						{this.renderHiddenInput()}
						{this.renderFloatingLabel()}
						{this.renderInput()}
					</div>
					{this.store.dropListActive && (
						<Virtualize
							selectedIndex={this.store.currentIndex}
							count={this.store.displayItems.length}
							overscan={2}
							estimateSize={() => 44}
							getScrollElement={() => this.itemsContainer}
						>
							{(virtualizer) => (
								<div className="display-items dropdown-menu" ref={(ref) => (this.itemsContainer = ref)}>
									<ul className="list" style={{ height: virtualizer.getTotalSize() }}>
										{this.renderVirtualizedItems(virtualizer)}
									</ul>
								</div>
							)}
						</Virtualize>
					)}
				</div>
			);
		} else {
			const containerClass = `autocomplete ${this.store.isActive ? ' open' : ''}`;
			const itemsContainerClass = `display-items dropdown-menu
			${this.store.dropListActive ? '' : ' hidden'}`;

			return (
				<div className={containerClass} ref={(ref) => this.container = ref}
					onKeyDown={this.handleKeyPress}>
					<div onClick={this.handleComponentFocused} onFocus={this.handleComponentFocused} onBlur={this.handleComponentBlur}>
						{this.renderHiddenInput()}
						{this.renderFloatingLabel()}
						{this.renderInput()}
					</div>
					<ul className={itemsContainerClass} ref={(ref) => this.itemsContainer = ref}>
						{this.renderItems()}
					</ul>
				</div>
			);
		}
	}

	renderFloatingLabel() {
		if (this.props.emptyText && this.props.floatingLabelText) {
			return (
				<FloatingLabelComponent
					store={this.floatingLabelStore}
					label={this.props.floatingLabelText}
					placeholder={this.props.emptyText} />
			);
		}

		return null;
	}

	renderInput() {
		const placeholder = this.props.emptyText;
		return (
			<div className="select-wrapper">
				<input type="text" className={`form-control${this.props.disabled ? ' disabled' : ''}`}
					placeholder={placeholder}
					disabled={this.props.disabled}
					ref={(ref) => this.input = ref}
					onChange={this.handleSearch}
					value={this.store.searchTerm}
					data-ignore-pan-like={true} />
				{
					// FF UseVirtualizedDropdownComponentInLoggedInWeb
					this.useVirtualized 
						? responsiveHelper.isXs && this.renderMobileInput() 
						: this.renderMobileInput()
				}
			</div>
		);
	}

	renderMobileInput() {
		return (
			<select className={`autocomplete-select-mobile form-control${this.props.disabled ? ' disabled' : ''}`} onChange={this.handleMobileItemSelected} disabled={this.props.disabled}>
				{this.props.emptyText ?
					<option value="">{this.props.emptyText}</option> : null}
				{this.store.displayItems.map(item =>
					<option key={item.Value} value={item.Value} selected={item.Value === this.store.selectedValue}>{this.getItemTextPrefix(item)}{item.Text}</option>)}
			</select>
		);
	}

	renderHiddenInput() {
		if (!this.props.fieldName) {
			return null;
		}
		const metadata = {
			propertyName: this.props.fieldName,
			validationRules: this.props.validationRules
		};
		return (
			<InputField data-validate type="hidden" propertyMetadata={metadata} value={this.store.selectedValue} />
		);
	}

	renderItems(): JSX.Element | JSX.Element[] {
		if (this.store.displayItems.length > 0) {
			return this.store.displayItems.map(item => this.renderItem(item));
		}

		return <li className="no-items">No items {this.store.searchTerm?'found':'to display'}</li>;
	}

	renderItem(item: IAutocompleteItem) {
		const isCurrentItem = item === this.store.currentItem;
		const itemClass = isCurrentItem ? 'selected' : '';

		return (
			<li className={itemClass} key={item.Value}
				ref={ref => ref && isCurrentItem ? this.currentItem = ref : null}>
				<button className="btn btn-link"
					onClick={() => this.store.selectItem(item) }
					onMouseOver={() => this.store.setCurrentItem(item) }
					type="button" tabIndex={-1}>
					{ this.renderItemText(item) }
				</button>
			</li>
		);
	}

	renderVirtualizedItems(virtualizer: Virtualizer): JSX.Element | JSX.Element[] {
		if (this.store.displayItems.length > 0) {
			return virtualizer.getVirtualItems().map((virtualItem) =>
				this.renderVirtualizedItem(this.store.displayItems[virtualItem.index], virtualItem)
			);
		}

		return <li className="no-items">No items {this.store.searchTerm?'found':'to display'}</li>;
	}

	renderVirtualizedItem(item: IAutocompleteItem, virtualItem: VirtualItem) {
		const isCurrentItem = item === this.store.currentItem;
		const itemClass = classNames('item', isCurrentItem && 'selected');

		return (
			<li
				className={itemClass}
				key={virtualItem.key}
				ref={(ref) => (ref && isCurrentItem ? (this.currentItem = ref) : null)}
				style={{
					height: `${virtualItem.size}px`,
					transform: `translateY(${virtualItem.start}px)`,
				}}
			>
				<button
					className="btn btn-link"
					onClick={() => this.store.selectItem(item)}
					onMouseOver={() => this.store.setCurrentItem(item)}
					type="button"
					tabIndex={-1}
				>
					{this.renderItemText(item)}
				</button>
			</li>
		);
	}

	componentDidUpdate() {
		// FF UseVirtualizedDropdownComponentInLoggedInWeb
		if (!this.useVirtualized) {
			this.scrollCurrentItemIntoView();
		}		
	}

	scrollCurrentItemIntoView = () => {
		if (this.currentItem) {
			const itemOffsetTop = this.currentItem.offsetTop;
			const itemOffsetHeight = this.currentItem.offsetHeight;
			const itemContainerScrollTop = this.itemsContainer.scrollTop;
			const itemContainerHeight = this.itemsContainer.clientHeight;

			if (itemOffsetTop < itemContainerScrollTop) {
				this.itemsContainer.scrollTop = itemOffsetTop;
			} else if (itemOffsetTop + itemOffsetHeight > itemContainerScrollTop + itemContainerHeight) {
				this.itemsContainer.scrollTop = itemOffsetTop + itemOffsetHeight - itemContainerHeight;
			}
		}
	}

	renderItemText(item: IAutocompleteItem) {
		if (this.store.searchTerm) {
			const matches = this.store.searchExpression.exec(item.Text);
			if (matches) {
				return (
					<span>
						{this.getItemTextPrefix(item)}{item.Text.substr(0, matches.index)}<strong>{matches[1]}</strong>{item.Text.substr(matches.index + matches[1].length)}
					</span >
				);
			}
		}
		return (<span>{this.getItemTextPrefix(item)}{item.Text}</span>);
	}

	getItemTextPrefix(item: IAutocompleteItem) {
		return item.TextPrefix ? `${item.TextPrefix} ` : '';
	}

	handleKeyPress = (ev: KeyboardEvent<any>) => {
		if (responsiveHelper.isXs) {
			return;
		}
		switch (ev.keyCode) {
			case KeyCodes.Tab:
			case KeyCodes.Escape: {
				this.store.showDropList(false);
				return;
			}
			case KeyCodes.Enter: {
				ev.preventDefault();

				if (this.store.dropListActive) {
					this.store.showDropList(false);
				}

				this.store.selectCurrentItem();
				return;
			}
			case KeyCodes.DownArrow: {
				ev.preventDefault();
				this.store.moveCurrentItemDown();
				break;
			}
			case KeyCodes.UpArrow: {
				ev.preventDefault();
				this.store.moveCurrentItemUp();
				break;
			}
		}
	}

	handleSearch = (ev: React.FormEvent<HTMLInputElement>) => {
		this.store.search(ev.currentTarget.value);
	}
	handleComponentFocused = () => {
		if (this.props.disabled) {
			return;
		}
		if (responsiveHelper.isXs) {
			return;
		}
		this.store.activate();
		this.input.focus();
	}
	handleComponentBlur = () => {
		if (!this.store.dropListActive) {
			this.store.deactivate();
		}
	}
	handleSelectedItemsClick = () => {
		this.store.showDropList(true);
	}
	handleMobileItemSelected = (ev: React.FormEvent<HTMLSelectElement>) => {
		this.store.selectItemByValue(ev.currentTarget.value);
	}

	handleWindowClick = (ev: Event) => {
		if (!this.container) {
			return;
		}
		const targetElement = ev.target as Element;
		if (targetElement === this.container || this.container.contains(targetElement)) {
			return;
		}
		this.input.blur();
		this.store.deactivate();
	}

	componentDidMount() {
		const { validateOnInit, fieldName } = this.props;
		if (validateOnInit) {
			validation.validateField(fieldName);
		}

		window.addEventListener('click', this.handleWindowClick);
	}

	componentWillUnmount() {
		window.removeEventListener('click', this.handleWindowClick);
	}

	private get store() {
		return this.props.autocompleteStore;
	}
}
