import { MachineConfig } from 'xstate/lib/types';
import { GivingStatementActions } from '../actions/main-actions';
import { DeleteStatementsStateMachine } from './delete-statements-states';

export enum PreviewPageStates {
	MAIN = 'Preview Page Main',
	DELETE = 'Preview Page Delete',
	DEFAULT_STATE = 'Preview Page default state',
	CONFIRMATION_DIALOG_ACTIVE = 'Confirmation dialog shown',
	STATEMENTS_GENERATING = 'Generating Statements',
}

export enum PreviewPageEvents {
	SHOW_CONFIRMATION_DIALOG = 'Show confirmation dialog',
	HIDE_CONFIRMATION_DIALOG = 'Hide confirmation dialog',
	BEGIN_STATEMENT_GENERATION = 'Begin generating statements',
	POLL_GENERATION_PROGRESS = 'Poll generation progress',
}

export const PreviewPageStateMachine = (): MachineConfig => ({
	parallel: true,
	states: {
		[PreviewPageStates.MAIN]: {
			initial: PreviewPageStates.DEFAULT_STATE,
			states: {
				[PreviewPageStates.DEFAULT_STATE]: {
					on: {
						[PreviewPageEvents.SHOW_CONFIRMATION_DIALOG]: PreviewPageStates.CONFIRMATION_DIALOG_ACTIVE,
						[PreviewPageEvents.POLL_GENERATION_PROGRESS]: {
							[PreviewPageStates.STATEMENTS_GENERATING]: {
								actions: [GivingStatementActions.PollGenerationProgress]
							}
						}
					},
				},
				[PreviewPageStates.CONFIRMATION_DIALOG_ACTIVE]: {
					on: {
						[PreviewPageEvents.BEGIN_STATEMENT_GENERATION]: {
							[PreviewPageStates.STATEMENTS_GENERATING]: {
								actions: [GivingStatementActions.BeginGenerationProgressPoll]
							}
						},
						[PreviewPageEvents.HIDE_CONFIRMATION_DIALOG]: PreviewPageStates.DEFAULT_STATE,
					},
				},
				[PreviewPageStates.STATEMENTS_GENERATING]: {
					on: {
						[PreviewPageEvents.POLL_GENERATION_PROGRESS]: {
							[PreviewPageStates.STATEMENTS_GENERATING]: {
								actions: [GivingStatementActions.PollGenerationProgress]
							}
						}
					}
				}
			},
		},
		[PreviewPageStates.DELETE]: DeleteStatementsStateMachine(),
	}
});
