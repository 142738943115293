import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Button } from '../../../Shared/components/button/button';
import { MemberFormViewModel } from '../view-models/member-form';

interface IMemberFormFooterProps {
	memberFormViewModel?: MemberFormViewModel;
}

@inject('memberFormViewModel')
@observer
export class MemberFormFooter extends React.Component<IMemberFormFooterProps> {
	render() {
		const { isProcessingRequest } = this.props.memberFormViewModel;

		return (
			<div className="panel-footer panel-footer-btn-group">
				<Button type="button" className="btn btn-cancel" data-dismiss="modal" disabled={isProcessingRequest}>Cancel</Button>
				<Button type="submit" className="btn btn-default" isLoading={isProcessingRequest}>Save</Button>
			</div>
		);
	}
}
