import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming/lib/types/theme/types';

export const useSideBarStyles = createUseStyles((theme: Theme) => ({
	root: {
		font: theme.typography["text-4"],

		'& [data-pp-at-target="Settings"]': {
			borderTop: `1px solid ${theme.colors['border-surface']}`,
		},
	},
	mobileButton: {
		position: 'fixed',
		top: 0,
		left: 0,
		zIndex: 1052,
	},
	nav: {},
	item: {
		'&:hover': {
			textDecoration: 'none',
			color: theme.colors['text-default'],
		},
		'&:focus': {
			color: theme.colors['text-default'],
			textDecoration: 'none',
		},
	},
	itemActive: {
		'&:hover, &:focus': {
			color: theme.colors['productBrand-500'],
		},
	},
	list: {
		margin: 0,
	},
	indicatorWarningLink: {
		display: 'flex',
	},
	indicatorWarningIcon: {
		color: theme.colors['text-error'],
	},
}));
