import * as React from 'react';
import { observable, runInAction, action } from 'mobx';
import { observer } from 'mobx-react';

import { classNames } from '../../../Shared/utils/classnames';
import { isFunction } from '../../utils/is-function';

import { SvgWrapper } from '../svg-wrapper';
import * as styles from './file-upload-styles.less';
import { FileUploadState, FileUploadStateFileSelected, FileUploadViewState } from './file-upload-state';
import { IFormControlCommonProps } from '../form-controls/form-control-common-props';

export interface IFileUploadContainer {
	fileCurrent: FileUploadState;
	containerClassNames?: string;
	renderContent: (props: IFileUploadDragOverProps) => JSX.Element;
	acceptanceTestTargetId: string;
	name: string;
}

export interface IFileUploadDragOverProps {
	isDragOver: boolean;
	updateIsDragOver: (isDragOver: boolean) => void;
}

@observer
export class FileUploadContainer extends React.Component<IFileUploadContainer> {

	@observable private isDragOver = false;

	private inputRef: HTMLInputElement;

	render() {
		const {
			containerClassNames,
			acceptanceTestTargetId = '',
			renderContent,
			name,
			fileCurrent,
		} = this.props;

		return (
			<div
				className={classNames(containerClassNames)}
				data-pp-at-target={`${acceptanceTestTargetId} Container`}
				onDrag={this.onDrag}
				onDragOver={this.onDragOver}
				onDragLeave={this.onDragLeave}>
				{renderContent({
					isDragOver: this.isDragOver,
					updateIsDragOver: this.updateIsDragOver,
				})}
			</div>
		);
	}

	private onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();

		const { fileCurrent } = this.props;
		if (fileCurrent.viewState === FileUploadViewState.Empty || fileCurrent.viewState === FileUploadViewState.Error) {
			this.updateIsDragOver(true);
		}
	}

	private onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();

		const { fileCurrent } = this.props;
		if (fileCurrent.viewState === FileUploadViewState.Empty || fileCurrent.viewState === FileUploadViewState.Error) {
			this.updateIsDragOver(false);
		}
	}

	private onDrag = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
	}

	@action
	private updateIsDragOver = (isDragOver: boolean) => this.isDragOver = isDragOver
}
