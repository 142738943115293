import * as React from 'react';
import { CSSTransitionGroup } from 'react-transition-group-v1';
import { observable, action, computed } from 'mobx';
import { observer } from 'mobx-react';
import { MultiSelectStore } from '../multi-select/multi-select-store';
import {ResponseTypes} from '../../funds/funds-generated';
import MultiSelectItem = ResponseTypes.MultiSelectItem;
import { TooltipComponent } from './multi-selected-items-tooltip';
import { SvgWrapper } from '../svg-wrapper';

export interface IMultiSelectItemAndStore {
	item: MultiSelectItem;
	store: MultiSelectStore;
}

export class MultiSelectedItemsStore {
	@observable stores: MultiSelectStore[] = [];

	@computed
	get selectedItems(): IMultiSelectItemAndStore[] {
		return this.stores.reduce((agg, store) => {
			const items = store.selectedItems.map(item => ({item, store} as IMultiSelectItemAndStore));
			return agg.concat(items);
		}, []);
	}

	@action
	addStore = (store: MultiSelectStore) => {
		this.stores.push(store);
	}

	@action
	deselectItem = ({item, store}: IMultiSelectItemAndStore) => {
		store.deselectItem(item);
	}

	@action
	reset = () => {
		this.stores.forEach(store => {
			store.reset();
		});
	}
}

export interface IMultiSelectedItemsProps {
	multiSelectPageStore: MultiSelectedItemsStore;
}

@observer
export class MultiSelectedItemsOld extends React.Component<IMultiSelectedItemsProps, {}> {
	private store = this.props.multiSelectPageStore;

	render() {
		return (
			<div className="multi-selected-items">
				<CSSTransitionGroup
					component="ul"
					className="selected-item-container"
					transitionName="spillow-fade"
					transitionEnterTimeout={200}
					transitionLeaveTimeout={200}>
					{this.store.selectedItems.map(selectedItem => this.renderSelectedItem(selectedItem))}
					{this.store.selectedItems.map(selectedItem => this.renderSelectedItemMobile(selectedItem))}
				</CSSTransitionGroup>
			</div>
		);
	}

	renderSelectedItem(itemAndStore: IMultiSelectItemAndStore) {
		const {item, store} = itemAndStore;
		return (
			<TooltipComponent text={store.displayName} key={`${item.Value}_selected`}>
				<a className="spillow hidden-sm hidden-xs" href="#" onClick={(ev) => this.handleDeselect(ev, {store, item}) }>
					<span className="spillow-content">{item.SelectedText || item.Text}</span>
					<SvgWrapper className="spillow-close" svg="close-cross" />
				</a>
			</TooltipComponent>
		);
	}

	renderSelectedItemMobile(itemAndStore: IMultiSelectItemAndStore) {
		const {item, store} = itemAndStore;
		return (
			<a className="spillow visible-sm-inline-block visible-xs-inline-block" href="#" onClick={(ev) => this.handleDeselect(ev, {store, item}) } key={`${item.Value}_selected`}>
				<span className="spillow-content">{item.SelectedText || item.Text}</span>
				<SvgWrapper className="spillow-close" svg="close-cross" />
			</a>
		);
	}

	handleDeselect = (ev: React.MouseEvent<HTMLAnchorElement>, item: IMultiSelectItemAndStore) => {
		ev.preventDefault();
		ev.stopPropagation();
		this.store.deselectItem(item);
	}
}
