import { MachineConfig } from 'xstate/lib/types';
import { GivingStatementActions } from '../actions/main-actions';

export enum DeleteStatementsStates {
    INITIAL = 'Delete Statements Initial State',
    CONFIRMING = 'Delete Statements Confirming State',
    DELETING = 'Delete Statements Deleting State',
    SUCCEEDED = 'Delete Statements Succeeded State',
    FAILED = 'Delete Statements Failed State',
}

export enum DeleteStatementsEvents {
    DELETE_START = 'Delete Start',
    DELETE_CONFIRMATION = 'Delete Confirmation',
    DELETE_CANCELLATION = 'Delete Cancellation',
    DELETE_FAILURE = 'Delete Failure',
    DELETE_SUCCESS = 'Delete Success',
    DISMISS_DELETE_FAILURE_MODAL = 'Dismiss Delete Failure Modal'
}

export const DeleteStatementsStateMachine = (): MachineConfig => ({
    initial: DeleteStatementsStates.INITIAL,
    states: {
        [DeleteStatementsStates.INITIAL]: {
            on: {
                [DeleteStatementsEvents.DELETE_START]: DeleteStatementsStates.CONFIRMING,
            },
        },
        [DeleteStatementsStates.CONFIRMING]: {
            on: {
                [DeleteStatementsEvents.DELETE_CONFIRMATION]: {
                    [DeleteStatementsStates.DELETING]: {
                        actions: [GivingStatementActions.DeleteStarted],
                    },
                },
                [DeleteStatementsEvents.DELETE_CANCELLATION]: DeleteStatementsStates.INITIAL,
            },
        },
        [DeleteStatementsStates.DELETING]: {
            on: {
                [DeleteStatementsEvents.DELETE_SUCCESS]: DeleteStatementsStates.SUCCEEDED,
                [DeleteStatementsEvents.DELETE_FAILURE]: DeleteStatementsStates.FAILED,
            },
        },
        [DeleteStatementsStates.FAILED]: {
            on: {
                [DeleteStatementsEvents.DISMISS_DELETE_FAILURE_MODAL]: DeleteStatementsStates.INITIAL,
            }
        },
        [DeleteStatementsStates.SUCCEEDED]: {}
    },
});