import * as React from 'react';
import { observer } from 'mobx-react';
import { GivenOnField, NotesField, PaymentReferenceFields } from '../../../components/payment-common-fields';
import { CustomField } from '../../../components/custom-field';
import { DefaultSettingsViewModel } from './default-settings-view-model';
import { FormControlLabelled, FormControlType } from '../../../components/form-controls/form-control-labelled';

import * as styles from './default-settings.less';

const formControlWidthClassName = 'col-md-4';

@observer
export class DefaultSettings extends React.Component<{ vm: DefaultSettingsViewModel, heading?: string }> {
	render() {
		const { vm, heading } = this.props;
		const {
			listingStore,
			givenOn,
			notes,
			referenceFieldValues,
			paymentMethodType,
			updateGivenOn,
			updateNotes,
			updateReferenceFieldValue,
			paymentMethodTypeOptions,
			notFundReferenceFieldViewModels,
			fundFieldViewModel,
			fundFieldValue,
		} = vm;
		const paymentLabel = listingStore.paymentLabel;
		return (
			<div>
				{heading && <div className={styles.heading}>{heading}</div>}
				<div className="row">
					<GivenOnField paymentNounSentenceCase={paymentLabel.NounSentenceCase} value={givenOn} onChangeHandler={updateGivenOn} />
					<CustomField className={formControlWidthClassName}
						customField={fundFieldViewModel}
						value={fundFieldValue}
						onChange={updateReferenceFieldValue} />
					<FormControlLabelled cssClassNames={formControlWidthClassName}
						label={`${paymentLabel.NounSentenceCase} type`}
						formControlProps={{
							formControlType: FormControlType.DropDown,
							name: 'PaymentMethod',
							placeholder: `Select ${paymentLabel.NounLowerCase} type`,
							value: `${paymentMethodType}`,
							onChangeHandler: this.handlePaymentMethodTypeChanged,
							Options: paymentMethodTypeOptions,
						}} />
				</div>
				<div className="row">
					<PaymentReferenceFields customFields={notFundReferenceFieldViewModels}
						referenceFieldValues={referenceFieldValues}
						onReferenceFieldValueChange={updateReferenceFieldValue}
					/>
					<NotesField value={notes} onNotesChange={updateNotes} />
				</div>
			</div>
		);
	}

	private handlePaymentMethodTypeChanged = (ev: React.FormEvent<HTMLSelectElement>) => {
		this.props.vm.updatePaymentMethodType(Number(ev.currentTarget.value));
	}
}
