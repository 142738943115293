import * as React from 'react';
import { action, observable, autorun, IReactionDisposer } from 'mobx';
import { inject, observer, Provider } from 'mobx-react';
import { machineContextKey, MachineContext } from '../../../../Shared/state-machine/saga-state-machine';

export const deletePledgeContextKey = 'deletePledgeContext';

export interface IWithDeletePledgeProps {
	deletingState: string | string[];
	processingDeleteState: string | string[];
	onDelete: (pledgeId: number) => void;
	onConfirmDelete: (pledgeId: number) => void;
	onCancelDelete: () => void;
	_selectedPledgeIdForTesting?: number;
}

@inject(machineContextKey)
@observer
export class WithDeletePledge extends React.Component<IWithDeletePledgeProps & { machineContext?: MachineContext }> {

	private deletePledgeContext = new DeletePledgeContext();

	private disposeUpdateContext: IReactionDisposer;

	componentDidMount() {
		this.disposeUpdateContext = autorun(this.updateContext);
	}

	componentWillUnmount() {
		this.disposeUpdateContext();
	}

	updateContext = () => {
		const { machineContext, deletingState, processingDeleteState, onDelete, onConfirmDelete, onCancelDelete, _selectedPledgeIdForTesting } = this.props;
		const inDeletingState = machineContext.matchesState(deletingState);
		const inProcessingDeleteState = machineContext.matchesState(processingDeleteState);
		this.deletePledgeContext.updateFromProps(inDeletingState, inProcessingDeleteState, onDelete, onConfirmDelete, onCancelDelete, _selectedPledgeIdForTesting);
	}

	render () {
		return (
			<Provider deletePledgeContext={this.deletePledgeContext}>
				{this.props.children}
			</Provider>
		);
	}
}

export class DeletePledgeContext {
	@observable inProcessingDeleteState: boolean = false;
	@observable private selectedPledgeId: number = null;
	@observable private inDeletingState: boolean = false;
	@observable private onDelete: (pledgeId: number) => void;
	@observable private onConfirmDelete: (pledgeId: number) => void;
	@observable private onCancel: () => void;

	isDeletingOrProcessingDelete = (pledgeId: number) => {
		return (this.inDeletingState || this.inProcessingDeleteState)
			&& this.selectedPledgeId === pledgeId;
	}

	isProcessingDelete = (pledgeId: number) => {
		return this.inProcessingDeleteState && this.selectedPledgeId === pledgeId;
	}

	@action.bound
	updateFromProps(inDeletingState: boolean,
		inProcessingDeleteState: boolean,
		onDelete: (pledgeId: number) => void,
		onConfirmDelete: (pledgeId: number) => void,
		onCancel: () => void,
		_selectedPledgeIdForTesting: number,
	) {
		this.inDeletingState = inDeletingState;
		this.inProcessingDeleteState = inProcessingDeleteState;
		this.onDelete = onDelete;
		this.onConfirmDelete = onConfirmDelete;
		this.onCancel = onCancel;
		if (_selectedPledgeIdForTesting) {
			this.selectedPledgeId = _selectedPledgeIdForTesting;
		}
	}

	@action.bound
	handleDelete(pledgeId: number) {
		this.selectedPledgeId = pledgeId;
		this.onDelete(pledgeId);
	}

	@action.bound
	handleConfirmDelete(pledgeId: number) {
		this.selectedPledgeId = pledgeId;
		this.onConfirmDelete(pledgeId);
	}

	@action.bound
	handleCancel() {
		this.selectedPledgeId = null;
		this.onCancel();
	}
}
