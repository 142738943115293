import { action, observable } from 'mobx';
import {
	CollapsedPaymentGatewayMapping,
	MappingsSetupProgress,
	PaymentLabel,
	QuickBooksAccount,
	QuickBooksMappingViewModel,
	QuickBooksPaymentGateway,
	QuickBooksPaymentGatewayKind,
	QuickBooksPaymentGatewayMappingViewModel,
} from '../../qbo-integration-generated';
import { StringToStringMap } from './qbo-mapping-client-side-view-model';

export class QboBankAccountMappingInfoViewModel {
	readonly panelTitle: string;

	@observable
	bankAccounts: QuickBooksAccount[] = [];

	@observable
	achMappings: CollapsedAccountMapping[] = [];

	@observable
	creditCardMappings: CollapsedAccountMapping[] = [];

	@observable
	profitStarsMappings: ProfitStarsMapping[] = [];

	@observable
	batchEntryMappings: BatchEntryMapping[] = [];

	@observable
	allProfitStarsMappingsUseSameAccount: boolean;

	@observable
	paymentLabel: PaymentLabel;

	@observable
	activeStep: MappingsSetupProgress;

	@observable
	isProcessingRequest: boolean;

	private readonly gatewayMerchantNames: StringToStringMap;

	constructor(model: QuickBooksMappingViewModel, panelTitle: string, public setStepAsActive: (step: MappingsSetupProgress) => void) {
		this.panelTitle = panelTitle;

		this.bankAccounts = model.Accounts.filter(account => account.IsBankAccount);

		this.activeStep = model.SetupProgress;

		this.isProcessingRequest = false;

		// create dictionary for MerchantId -> MerchantName to make lookups faster
		const merchNameDict: { [key: number]: string } = model.Merchants.reduce(
			(map, merchant) => {
				map[merchant.MerchantId] = merchant.MerchantName;
				return map;
			}, {});

		// create dictionary for PaymentGatewayId => MerchantName to make lookups faster
		this.gatewayMerchantNames = model.PaymentGateways.reduce(
			(map, gateway) => {
				map[gateway.PaymentGatewayId] = merchNameDict[gateway.MerchantId];
				return map;
			}, {});

		if (model.CollapsedAchPaymentGatewayMappings) {
			this.achMappings = model.CollapsedAchPaymentGatewayMappings.map(item =>
				new CollapsedAccountMapping('Bank Deposits', item, this.gatewayMerchantNames, model.PaymentLabel)
			);
		}

		if (model.CollapsedCreditCardPaymentGatewayMappings) {
			this.creditCardMappings = model.CollapsedCreditCardPaymentGatewayMappings.map(item =>
				new CollapsedAccountMapping('Card Deposits', item, this.gatewayMerchantNames, model.PaymentLabel)
			);
		}

		// get the ProfitStars gateways from gateway list
		const profitStarsGateways: QuickBooksPaymentGateway[] = model.PaymentGateways.filter(gateway => gateway.GatewayKind === QuickBooksPaymentGatewayKind.ProfitStars);

		if (profitStarsGateways.length > 0) {
			this.profitStarsMappings = profitStarsGateways.map(gateway => {
				const mapping = model.ProfitStarsPaymentGatewayMappings.find(mapping => mapping.PaymentGatewayId === gateway.PaymentGatewayId);
				return new ProfitStarsMapping(gateway, mapping, this.gatewayMerchantNames, model.PaymentLabel);
			});
		}

		// get the BatchEntry gateways from gateway list
		const batchEntryGateways: QuickBooksPaymentGateway[] = model.PaymentGateways.filter(gateway => gateway.GatewayKind === QuickBooksPaymentGatewayKind.BatchEntry);

		if(batchEntryGateways.length > 0) {
			this.batchEntryMappings = batchEntryGateways.map(gateway => {
				const mapping = model.BatchEntryPaymentGatewayMappings.find(mapping => mapping.PaymentGatewayId === gateway.PaymentGatewayId);
				return new BatchEntryMapping(gateway, mapping, this.gatewayMerchantNames, model.PaymentLabel);
			});
		}

		this.paymentLabel = model.PaymentLabel;
	}

	@action.bound
	setLocalActiveStep(step: MappingsSetupProgress) {
		this.activeStep = step;
	}

	@action.bound
	setIsProcessingRequest(value: boolean) {
		this.isProcessingRequest = value;
	}
}

export class CollapsedAccountMapping {
	associatedMerchants: string;
	gatewayIds: string[];

	@observable
	isEnabled: boolean;

	@observable
	selectedAccount: string;

	constructor(public gatewayKind: string,
				collapsedPaymentGatewayMapping: CollapsedPaymentGatewayMapping,
				public gatewayMerchantNames: StringToStringMap,
				public paymentLabel: PaymentLabel) {
		this.gatewayIds = collapsedPaymentGatewayMapping.PaymentGatewayIds;
		this.associatedMerchants = collapsedPaymentGatewayMapping.PaymentGatewayIds.map(id => this.gatewayMerchantNames[id]).join(', ');
		this.isEnabled = collapsedPaymentGatewayMapping.IsEnabled;
		this.selectedAccount = collapsedPaymentGatewayMapping.AccountId || '';
	}

	@action.bound
	toggleIsEnabled() {
		this.isEnabled = !this.isEnabled;
	}

	@action.bound
	selectedAccountChanged(newAccount: string) {
		this.selectedAccount = newAccount;
	}
}

export class ProfitStarsMapping {
	associatedMerchants: string;

	readonly gatewayKind: string = 'Check Deposits';

	readonly gatewayId: string;

	@observable
	isEnabled: boolean;

	@observable
	selectedAccount: string;

	constructor(gateway: QuickBooksPaymentGateway,
				gatewayMapping: QuickBooksPaymentGatewayMappingViewModel,
				gatewayMerchantNames: StringToStringMap,
				public paymentLabel: PaymentLabel) {
		this.associatedMerchants = gatewayMerchantNames[gateway.PaymentGatewayId];
		this.isEnabled = gatewayMapping ? gatewayMapping.IsEnabled : false;
		this.selectedAccount = gatewayMapping ? gatewayMapping.AccountId : '';
		this.gatewayId = gateway.PaymentGatewayId;
	}

	@action.bound
	toggleIsEnabled() {
		this.isEnabled = !this.isEnabled;
	}

	@action.bound
	selectedAccountChanged(newAccount: string) {
		this.selectedAccount = newAccount;
	}
}

export class BatchEntryMapping {
	associatedMerchants: string;

	readonly gatewayKind: string = 'Batch Entry Deposits';

	readonly gatewayId: string;

	@observable
	isEnabled: boolean;

	@observable
	selectedAccount: string;

	constructor(gateway: QuickBooksPaymentGateway,
			gatewayMapping: QuickBooksPaymentGatewayMappingViewModel,
			gatewayMerchantNames: StringToStringMap,
			public paymentLabel: PaymentLabel) {
		this.associatedMerchants = gatewayMerchantNames[gateway.PaymentGatewayId];
		this.isEnabled = gatewayMapping ? gatewayMapping.IsEnabled : false;
		this.selectedAccount = gatewayMapping ? gatewayMapping.AccountId : '';
		this.gatewayId = gateway.PaymentGatewayId;
	}

	@action.bound
	toggleIsEnabled() {
		this.isEnabled = !this.isEnabled;
	}

	@action.bound
	selectedAccountChanged(newAccount: string) {
		this.selectedAccount = newAccount;
	}
}
