import * as React from 'react';
import { observer } from 'mobx-react';
import { IMaskedInputProps } from 'react-text-mask';
import { ExistingCardPaymentMethod } from './payment-entry-model';
import { FormControlLabelled, FormControlType } from '../../../components/form-controls/form-control-labelled';
import { IIconInputGroupProps, IconInputGroupPosition } from '../../../components/icon-input-group';
import { MaskedInputHelper } from '../../../helpers/masked-input-helper';
import { injectAnalytics, recordEvent, WithAnalytics } from '../../../analytics';

@injectAnalytics
@observer
export class PaymentMethodExistingCard extends React.Component<WithAnalytics & {
	existingCard: ExistingCardPaymentMethod;
}, {}> {
	static readonly cardNumberPlaceholders: any[] = [/[0-9]/, /[0-9]/,/[0-9]/, /[0-9]/];
	static readonly maskedCardNumbers = ['\u25CF', '\u25CF', '\u25CF', '\u25CF', ' ', '\u25CF', '\u25CF', '\u25CF', '\u25CF', ' ', '\u25CF', '\u25CF', '\u25CF', '\u25CF', ' '];

	render() {
		const { existingCard: { confirmedCardNumberInput, cardBrandName } } = this.props;
		const inputGroupOptions: IIconInputGroupProps = {
			svg: cardBrandName.toLowerCase(),
			title: cardBrandName,
			position: IconInputGroupPosition.Left,
			width: 32,
			height: 24,
			transparent: true
		};

		return(
			<div className="vt-payment-method vt-payment-method-existing-input-group">
				<FormControlLabelled label="Confirm card numbers last 4 digits"
					formControlProps={{
						formControlType: FormControlType.Text,
						name: 'confirmCardNumber',
						inputGroupOptions: inputGroupOptions,
						maskOptions: this.maskOptions,
						type: 'text',
						value: confirmedCardNumberInput,
						onFocusHandler: MaskedInputHelper.setCursorToFirstPlaceholder,
						onMouseUpHandler: MaskedInputHelper.setCursorToFirstPlaceholder,
						onChangeHandler: this.handleConfirmCardNumberChange,
						...(NewFeatures.SetupPinpointAnalytics && { onBlurHandler: this.handleConfirmCardNumberBlur }),
						validationRules: this.confirmedCardNumberValidationRules,
						acceptanceTestTargetId: 'confirm card number',
					}}>
				</FormControlLabelled>
			</div>
		);
	}

	private handleConfirmCardNumberBlur = () => {
		const { analytics, existingCard: { confirmedCardNumberInput } } = this.props;
		if (analytics && confirmedCardNumberInput) {
			const { feature, subFeature } = analytics;
			recordEvent({
				feature,
				subFeature,
				eventTypeLabel: 'completePaymentMethod',
				attributes: { paymentMethodType: 'existingCard' }
			});
		}
	}

	private handleConfirmCardNumberChange = (event: React.FormEvent<HTMLInputElement>) => {
		this.props.existingCard.updateConfirmedCardNumberInput(event.currentTarget.value);
	}

	private get maskOptions(): IMaskedInputProps {
		return {
			mask: PaymentMethodExistingCard.maskedCardNumbers.concat(PaymentMethodExistingCard.cardNumberPlaceholders),
			guide: true,
			showMask: true
		};
	}

	private get confirmedCardNumberValidationRules() {
		const confirmedCardNumberValidationRegex = `^${PaymentMethodExistingCard.maskedCardNumbers
			.concat(PaymentMethodExistingCard.cardNumberPlaceholders
			.map(x => x.source))
			.join(``)}$`;

		return {
			regex: {
				errorMessage: `Please enter the card's last 4 digits`,
				parameters: {
					pattern: confirmedCardNumberValidationRegex
				}
			}
		};
	}
}
