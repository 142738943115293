import { observable, action } from 'mobx';
import { ResponseTypes } from '../../funds/funds-generated';
import { IAutocompleteItem } from '../../components/autocomplete';

import IntegrationListingConfigurationViewModel = ResponseTypes.IntegrationListingConfigurationViewModel;

export interface IListingModel<T> {
	model: T;
	funds: IAutocompleteItem[];
	toggleActive: () => void;
}

export class ListingModel<T> implements IListingModel<T> {
	protected listing: ResponseTypes.IntegrationListingConfigurationViewModel<T>;

	@observable model: T;

	@observable enabled;

	@observable validationError: string;

	get listingName() {
		return this.listing.ListingName;
	}

	get listingId() {
		return this.listing.ListingId;
	}

	funds: IAutocompleteItem[];

	constructor(listing: IntegrationListingConfigurationViewModel<T>, funds: IAutocompleteItem[], validationError: string = null) {
		this.listing = listing;
		this.funds = funds;
		this.model = listing.Model;
		this.enabled = listing.Model !== null;
		this.validationError = validationError;
	}

	@action toggleActive = () => {
		this.enabled = !this.enabled;

		if (this.enabled) {
			this.model = {} as T;
		} else {
			this.resetValidationError();
		}
	}

	@action resetValidationError = () => {
		this.validationError = null;
	}
}
