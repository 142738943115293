import * as React from 'react';
import { TransactionImportFundMatchingViewModel } from './transaction-import-fund-matching-view-model';
import { ImportStepHeader, ImportStep } from './components/import-step-header';
import { ImportStepDescription } from './components/import-step-description';
import { AddNewFundDialog } from './components/add-new-fund-dialog';
import { ModelMetadata, ImportedFundViewModel } from './transaction-import-generated';
import { AlertControl } from './components/alert-control';
import { ValidationMessage } from '../components/form-controls/validation-message';
import { ValidatableForm } from '../components/form-controls/validatable-form';
import { SvgWrapper } from '../components/svg-wrapper';
import { Button } from '../components/button';
import { Autocomplete, AutocompleteStore } from '../components/autocomplete';
import { observer } from 'mobx-react';

@observer
export class TransactionImportFundMatching extends React.Component<{
	vm: TransactionImportFundMatchingViewModel}, {}> {

	render() {
		const {
			navigationInProgress,
			importedFunds,
			pushpayFundStores,
			alertProps,
		} = this.props.vm;

		return (
			<div>
				<AlertControl {...alertProps} />

				<ImportStepHeader currentStep={ImportStep.Match}/>

				<ImportStepDescription title="Match your funds" description="Match the funds from your file with the funds in Pushpay.">
					<div className="visible-xs header-buttons">
						<Button className="btn btn-link btn-sm" onClick={this.handleNewFund}>
							<SvgWrapper svg="icon-add" className="svg icon" />
							Add new fund
						</Button>
					</div>
				</ImportStepDescription>

				<ValidatableForm onSubmit={this.handleSubmit} className="panel panel-default">
					<div className="panel-heading">
						<div className="header">
							<div className="col-sm-4">
								Funds from your file
							</div>
							<div className="col-sm-5 hidden-xs">
								Pushpay funds
							</div>
							<div className="col-sm-3 hidden-xs text-right">
								<Button className="btn btn-link btn-sm" onClick={this.handleNewFund}>
									<SvgWrapper svg="icon-add" className="svg icon" />
									Add new fund
								</Button>
							</div>
						</div>
					</div>
					<div>
						{
							importedFunds.map( (fund, index) => (
								<FundMappingRow  key={fund.ImportFundId} importedFund={fund} pushpayFundStore={pushpayFundStores[index]} />
							))
						}
					</div>
					<div className="panel-footer panel-footer-btn-group">
						<Button className="btn btn-ghost" disabled={navigationInProgress} onClick={this.handleCancel}>Cancel</Button>
						<Button submit={true} className="btn btn-default" disabled={navigationInProgress}>Next</Button>
					</div>
				</ValidatableForm>
			</div>

		);
	}

	private handleSubmit = () => {
		this.props.vm.refreshFundMappings();
		this.props.vm.initMatchFundsRequest();
	}

	private handleNewFund = (ev: React.MouseEvent<HTMLButtonElement>) => {
		ev.preventDefault();
		this.props.vm.addNewFundViewModel.openDialog(<AddNewFundDialog vm={this.props.vm.addNewFundViewModel} handleAddNewFund={this.handleNewFundSubmit}/>);
	}

	private handleNewFundSubmit = () => {
		this.props.vm.initAddNewFundRequest();
	}

	private handleCancel = () => {
		this.props.vm.initCancelImportRequest();
	}
}

export interface FundMappingRowProps {
	importedFund: ImportedFundViewModel;
	pushpayFundStore: AutocompleteStore;
}

@observer
export class FundMappingRow extends React.Component<FundMappingRowProps, {}> {
	render() {

		const {
			importedFund,
			pushpayFundStore,
		} = this.props;

		const elementName = `FundMappingRow_${importedFund.ImportFundId}`;

		return (
			<div className={`transaction-import-fund-row ${pushpayFundStore.selectedItem ? 'matched' : ''}`}>
				<div className="col-sm-4">
					<label className="import-fund-label">{importedFund.ImportFundName}</label>
				</div>
				<div className="col-sm-5">
						<Autocomplete emptyText="- Select Pushpay fund -" floatingLabelText="Select Pushpay fund"
							autocompleteStore={pushpayFundStore}
							fieldName={elementName}
							validationRules={ModelMetadata.ImportedFundViewModel.PushpayFundId.validationRules}/>
				</div>
				<div className="col-sm-3 import-fund-validation-container">
					{ this.renderValidationMessage(elementName) }
				</div>
			</div>
		);
	}

	renderValidationMessage(elementName: string) {
		if (this.props.pushpayFundStore.selectedItem) {
			return (
				<div className="match-success">
					<SvgWrapper svg="alert-icon-success" className="svg icon match-success-icon" />
					<span className="match-success-message">You have matched your funds</span>
				</div>
			);
		} else {
			return (<ValidationMessage elementName={elementName} />);
		}
	}
}
