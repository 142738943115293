import { delay } from 'redux-saga';
import { call } from 'redux-saga/effects';
import { states, events, eventType, actions, actionType } from '../../../../Shared/state-machine/states-events-and-actions';
import { MachineContext } from '../../../../Shared/state-machine/saga-state-machine';
import { getCancellableEffect } from '../../../../Shared/helpers/saga-side-effects';
import {
	SearchMembersResponse,
	SearchMemberResult,
	AddPledgeRequest,
	UpdatePledgeRequest,
	AddPledgeSuccessResponse,
	PledgeAlreadyAddedResponse,
	AddPledgeResponseType,
	AddMemberResponse,
	EditMemberResponse,
	EditCommunityMemberRequestModel,
	DeleteRecentPledgeRequest,
	CommunityMemberType,
} from '../../campaigns-generated';
import { getCampaignsSagaDataService } from '../../campaigns-data-service';
import { IPayerViewModel } from '../../../components/payer-search-omnibox/payer-search-models';
import { PledgeEntryMainViewModel, IMemberSearchResult } from './pledge-entry-main-view-model';

enum PledgeEntryStates {
	LiveCampaignRequired,
	AddPledge,
	AddingPledges,
	Idle,
	MemberSelectedIdle,
	NoMember,
	SelectingAMember,
	SearchingForMember,
	SearchingForMemberHistory,
	DisplayResults,
	Searching,
	LoadingMore,
	MemberSelected,
	AddingMember,
	EditingMember,
	SavingMember,
	ShowingResults,
	HidingResults,
	SavingPledge,
	PledgeAlreadyAdded,
	DeletingPledge,
	PledgeUpdated,
	ConfirmDelete,
	PledgeDeleted,
	Error,
	Init,
	RecentPledges,
	Deleting,
	ProcessingDelete,
}

export const States = states(PledgeEntryStates);

export const Events = events({
	ShowLiveCampaignRequired: eventType<void>(),
	StartAddingPledges: eventType<void>(),
	Search: eventType<typeof Actions.dataTypes.SearchForMember>(),
	SearchComplete: eventType<void>(),
	Success: eventType<void>(),
	Error: eventType<void>(),
	ValidationError: eventType<void>(),
	LoadMore: eventType<void>(),
	SelectMember: eventType<typeof Actions.dataTypes.SelectMember>(),
	AddMember: eventType<void>(),
	SaveMember: eventType<typeof Actions.dataTypes.SaveAddMember | typeof Actions.dataTypes.SaveEditMember>(),
	Cancel: eventType<void>(),
	ClearMember: eventType<void>(),
	EditMember: eventType<void>(),
	ShowResults: eventType<void>(),
	HideResults: eventType<void>(),
	AddPledge: eventType<typeof Actions.dataTypes.AddPledge>(),
	LoadPledgesComplete: eventType<void>(),
	ConfirmDeletePledge: eventType<typeof Actions.dataTypes.DeletePledgeConfirmed>(),
	CancelDeletePledge: eventType<void>(),
	DeletePledgeSuccess: eventType<void>(),
	DeletePledgeError: eventType<void>(),
	PledgeAlreadyAdded: eventType<void>(),
	UpdatePledge: eventType<typeof Actions.dataTypes.UpdatePledge>(),
	DeletePledge: eventType<void>(),
	Close: eventType<void>(),
	DeletePledgeFromModal: eventType<void>(),
});

export const Actions = actions({
	Start: actionType<void>((machineContext: MachineContext<PledgeEntryMainViewModel>) => {
		const { viewModel } = machineContext;
		if (viewModel.campaignRequiredModel.IsCampaignRequired) {
			Events.raise.ShowLiveCampaignRequired(machineContext);
		} else {
			Events.raise.StartAddingPledges(machineContext);
		}
	}),
	SearchForMember: actionType<{ query: string }>(function* (machineContext: MachineContext<PledgeEntryMainViewModel>, { query }) {
		yield searchMembersCancellableEffect.run(function* () {
			const { viewModel: { updateMemberSearchValue, resetSearchSkip, updateMemberSearchResult } } = machineContext;
			try {
				const searchQuery = updateMemberSearchValue(query);
				const searchSkip = resetSearchSkip();
				if (searchQuery === '') {
					updateMemberSearchResult(null);
					Events.raise.HideResults(machineContext);
					return;
				}
				if (searchQuery.trim().length < 2) {
					return;
				}
				yield call(delay, 500);
				const result: SearchMembersResponse = yield getCampaignsSagaDataService().searchMembers({ model: { Query: searchQuery, Skip: searchSkip } });
				updateMemberSearchResult({
					members: result.Results,
					hasMorePages: result.HasMorePages,
				});
			} catch {
				updateMemberSearchResult(getErrorSearchResult());
			}
			Events.raise.SearchComplete(machineContext);
		});
	}),
	LoadMore: actionType<void>(function* (machineContext: MachineContext<PledgeEntryMainViewModel>) {
		yield searchMembersCancellableEffect.run(function* () {
			const { viewModel: { getNextSearchSkip, memberSearchValue, memberSearchResult, updateMemberSearchResult } } = machineContext;
			try {
				const searchSkip = getNextSearchSkip();
				const result: SearchMembersResponse = yield getCampaignsSagaDataService().searchMembers({ model: { Query: memberSearchValue, Skip: searchSkip } });
				updateMemberSearchResult({
					members: memberSearchResult.members.concat(result.Results),
					hasMorePages: result.HasMorePages,
				});
			} catch {
				updateMemberSearchResult(getErrorSearchResult());
			}
			Events.raise.SearchComplete(machineContext);
		});
	}),
	CancelSearching: actionType<void>(function* () {
		yield searchMembersCancellableEffect.cancel();
	}),
	SelectMember: actionType<{ payer: IPayerViewModel }>((machineContext: MachineContext<PledgeEntryMainViewModel>, { payer }) => {
		const { viewModel: { form, memberSearchResult: { members } } } = machineContext;
		const [ selectedMember ] = members.filter(member => member.CommunityMemberId === Number(payer.id));
		form.$.member.onChange(selectedMember);
	}),
	ClearMember: actionType<void>((machineContext: MachineContext<PledgeEntryMainViewModel>) => {
		const { viewModel } = machineContext;
		viewModel.clearMember();
	}),
	SaveAddMember: actionType<{ merchantId: number, model: EditCommunityMemberRequestModel }>
		(function* (machineContext: MachineContext<PledgeEntryMainViewModel>, request) {
		try {
			const result: AddMemberResponse = yield getCampaignsSagaDataService().addMember(request);
			const { viewModel: { form } } = machineContext;
			form.$.member.onChange(result.MemberInfo);
			Events.raise.Success(machineContext);
		} catch (err) {
			handleMemberError(machineContext, err);
		}
	}),
	SaveEditMember: actionType<{ model: EditCommunityMemberRequestModel }>(function* (machineContext: MachineContext<PledgeEntryMainViewModel>, request) {
		try {
			const result: EditMemberResponse = yield getCampaignsSagaDataService().editMember(request);
			const { viewModel: { form } } = machineContext;
			form.$.member.onChange(result.UpdatedCommunityMember);
			Events.raise.Success(machineContext);
		} catch (err) {
			handleMemberError(machineContext, err);
		}
	}),
	AddPledge: actionType<{ request: AddPledgeRequest }>(function* (machineContext: MachineContext<PledgeEntryMainViewModel>, { request }) {
		try {
			const { viewModel } = machineContext;
			const result: AddPledgeSuccessResponse | PledgeAlreadyAddedResponse = yield getCampaignsSagaDataService().addPledge({ request });
			if (result.Type === AddPledgeResponseType.Success) {
				const { pledges } = viewModel;
				pledges.unshift(result.Pledge);
				machineContext.viewModel.updatePledges(pledges);
				Events.raise.Success(machineContext);
			} else if (result.Type === AddPledgeResponseType.PledgeAlreadyAdded) {
				viewModel.setAlreadyAddedPledge(result);
				Events.raise.PledgeAlreadyAdded(machineContext);
			}
		} catch {
			Events.raise.Error(machineContext);
		}
	}),
	UpdatePledge: actionType<{ request: UpdatePledgeRequest }>(function* (machineContext: MachineContext<PledgeEntryMainViewModel>, { request }) {
		try {
			const result: AddPledgeSuccessResponse = yield getCampaignsSagaDataService().updatePledge({ request });
			let { viewModel: { pledges } } = machineContext;
			pledges = pledges.filter(pledge => pledge.Id !== result.Pledge.Id);
			pledges.unshift(result.Pledge);
			machineContext.viewModel.updatePledges(pledges);
			Events.raise.Success(machineContext);
		} catch {
			Events.raise.Error(machineContext);
		}
	}),
	LoadPledges: actionType<void>(function* (machineContext: MachineContext<PledgeEntryMainViewModel>) {
		try {
			const result = yield getCampaignsSagaDataService().getRecentPledges({request: {}});
			machineContext.viewModel.updatePledges(result.Pledges);
			Events.raise.LoadPledgesComplete(machineContext);
		} catch {
			Events.raise.Error(machineContext);
		}
	}),
	DeletePledgeConfirmed: actionType<{ request: DeleteRecentPledgeRequest }>(function* (machineContext: MachineContext<PledgeEntryMainViewModel>, { request }) {
		try {
			yield getCampaignsSagaDataService().deleteRecentPledge({ request });
			const { pledges, updatePledges } = machineContext.viewModel;
			updatePledges(pledges.filter(pledge => pledge.Id !== request.Id));
			Events.raise.DeletePledgeSuccess(machineContext);
		} catch {
			Events.raise.Error(machineContext);
		}
	}),
});

const searchMembersCancellableEffect = getCancellableEffect();

function handleMemberError(machineContext: MachineContext<PledgeEntryMainViewModel>, error: any) {
	if (error.validationErrors) {
		machineContext.viewModel.setMemberFormValidationErrors(error.validationErrors);
		Events.raise.ValidationError(machineContext);
		return;
	}

	Events.raise.Error(machineContext);
}

export function mapSearchMemberResultToIPayer(member: SearchMemberResult): IPayerViewModel {

	if (NewFeatures.NewGiftEntryCommunityMemberSearchResultLabels) {
		return {
			country: member.Address && member.Address.Country,
			displayAddress: member.Address && member.Address.DisplayAddress,
			emailAddress: member.EmailAddress,
			id: String(member.CommunityMemberId),
			mobileNumber: member.MobileNumber,
			name: member.FirstName + (member.LastName ? ' ' + member.LastName : ''),
			postCode: member.Address && member.Address.Postcode,
			isOrganization: member.CommunityMemberType === CommunityMemberType.Organization,
			campusName: member.CampusName,
			primaryExternalId: member.PrimaryExternalId,
		};
	};

	return {
		country: member.Address && member.Address.Country,
		displayAddress: member.Address && member.Address.DisplayAddress,
		emailAddress: member.EmailAddress,
		id: String(member.CommunityMemberId),
		mobileNumber: member.MobileNumber,
		name: member.FirstName + (member.LastName ? ' ' + member.LastName : ''),
		postCode: member.Address && member.Address.Postcode,
		isOrganization: member.CommunityMemberType === CommunityMemberType.Organization
	};
}

const getErrorSearchResult = (): IMemberSearchResult => ({ members: [], error: true });
