import * as React from 'react';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import { Formatter } from '../../../../helpers/formatter';
import { SvgWrapper } from '../../../../components/svg-wrapper';
import { BatchStatus, BatchListItemViewModel } from '../../../virtual-terminal-generated';
import { BatchInfoLabel } from '../../../../components/batch-info-label';
import { FormControlCheckbox } from '../../../../components/form-controls/form-control-checkbox/form-control-checkbox';
import { BatchListItemProps } from './batch-list-item-props';
import { classNames } from '../../../../../Shared/utils/classnames';

import * as styles from './batch-list-item.less';

@observer
export class BatchListItem extends React.Component<{ batch: BatchListItemProps }, {}> {
	render() {
		const { vm, isSelected, hasQuickBooks } = this.props.batch;
		return (
			<li className={`list-group-item ${styles.container}`}>
				<BatchListItemTitle vm={vm} />
				<BatchListItemPaymentTotal vm={vm} />
				<BatchListItemDetail vm={vm} />
				<BatchListItemPaymentNumber vm={vm} />
				<BatchListItemDepositCheckbox vm={vm} isSelected={isSelected} handleDepositBatchChange={this.handleDepositBatchChange} />
				<BatchListItemRecordedPayment vm={vm} hasQuickBooks={hasQuickBooks} />
			</li>
		);
	}

	@action
	private handleDepositBatchChange = (checked: boolean) => {
		this.props.batch.setIsSelected(checked);
	}
}

@observer
class BatchListItemLabel extends React.Component<BatchListItemViewModel> {
	render() {
		const { LastUpdatedBy, StatusLastChangedOn, CreatedBy, CreatedOn, BatchStatus: status, IsOnClosedListing } = this.props;

		if (status === BatchStatus.Open) {
			return <BatchInfoLabel prefix={LastUpdatedBy ? 'Last updated by' : 'Created by'} name={LastUpdatedBy || CreatedBy} date={StatusLastChangedOn || CreatedOn} isOnClosedListing={IsOnClosedListing} />;
		} else if (status === BatchStatus.Completed) {
			return <BatchInfoLabel prefix="Completed by" name={LastUpdatedBy} date={StatusLastChangedOn} isOnClosedListing={IsOnClosedListing} />;
		} else {
			return <BatchInfoLabel prefix="Deposited by" name={LastUpdatedBy} date={StatusLastChangedOn} isOnClosedListing={IsOnClosedListing} />;
		}
	}
}

const BatchListItemTitle = observer(({ vm: { BatchName, Url, BatchStatus: Status } }) => {
	const batchStatus = BatchStatus[Status];
	const open = Status === BatchStatus.Open;
	return (
		<div className={`list-group-item-large ${styles.title}`}>
			<a href={Url} title={BatchName} className={`list-group-item-title`} data-pp-at-target={BatchName}>{BatchName}</a>
			{open
				? <span className="label label-default list-group-item-label">{batchStatus}</span>
				: <SvgWrapper svg="ind-tick" className={styles.iconCompleted} />}
		</div>
	);
});

const BatchListItemDetail = observer(({ vm, vm: { BatchId, ListingName } }) => {
	return (
		<div className={`list-group-item-light hidden-sm hidden-xs ${styles.details}`}>
			<span>Batch ID #{BatchId}</span>
			<span className="list-group-item-bullet"></span>
			<strong>{ListingName}</strong>
			<span className="list-group-item-bullet"></span>
			<BatchListItemLabel {...vm}/>
		</div>
	);
});

const BatchListItemPaymentTotal = observer(({ vm: { PaymentsTotal } }) => (
	<div className={`list-group-item-large list-group-right ${styles.total}`}>
		<sup className={styles.totalPrefix}>$</sup>
		<span data-pp-at-target="payments total">{Formatter.formatNumberForDisplay(PaymentsTotal)}</span>
	</div>
));

const BatchListItemPaymentNumber = observer(({ vm: { PaymentLabel: { NounPluralLowerCase }, PaymentsNumber } }) => (
	<div className={`list-group-right ${styles.count}`}>
		Number of {NounPluralLowerCase}: <strong data-pp-at-target="payments number">{PaymentsNumber}</strong>
	</div>
));

const BatchListItemDepositCheckbox = observer(({ vm: { BatchId, BatchStatus: Status }, isSelected, handleDepositBatchChange }) => {
	const canDepositBatch = Status === BatchStatus.Completed;

	if (!canDepositBatch) {
		return null;
	}

	return (
		<div className={styles.deposit}>
			<FormControlCheckbox
				containerClassNames={classNames('checkbox-green', styles.checkboxGreen)}
				acceptanceTestTargetId={`deposit batch ${BatchId}`}
				onChangeHandler={handleDepositBatchChange}
				value={isSelected}
				text="Select batch to be deposited" />
		</div>
	);
});

const BatchListItemRecordedPayment = observer(({ vm, hasQuickBooks }
	: { vm: BatchListItemViewModel, hasQuickBooks: boolean }) => {

	const { BatchStatus: Status, RecordedPaymentsTotal, HasBatchBeenSentToQuickBooks } = vm;

	switch(Status) {
		case BatchStatus.Open:
			return null;
		case BatchStatus.Completed:
			if (!hasQuickBooks) {
				return null;
			}
			if (RecordedPaymentsTotal <= 0) {
				return null;
			}
			return <BatchListItemRecordedItem prefix="To be sent" total={RecordedPaymentsTotal} />;
		case BatchStatus.Deposited:
			if (!HasBatchBeenSentToQuickBooks || RecordedPaymentsTotal <= 0) {
				return <div className={classNames('list-group-row', styles.emptyRow)}></div>;
			}
			return <BatchListItemRecordedItem prefix="Sent" total={RecordedPaymentsTotal} />;
		default:
			const unsupported: BatchStatus = Status;
			throw new Error(`Batch status ${unsupported} is not handled`);
	}
});

const BatchListItemRecordedItem = ({ prefix, total }: { prefix: string, total: number }) => {
	return (
		<div className={styles.qboRow}>
			<div className={styles.sentText}>
				<div>{prefix} to QuickBooks Online:&nbsp;</div>
				<div className={styles.qboTotal}>
					<strong data-pp-at-target="recorded qbo total">
						<sup className={styles.qboTotalPrefix}>$</sup>{Formatter.formatNumberForDisplay(total)}
					</strong>
				</div>
			</div>
		</div>
	);
};
