import { PaymentMethodUiType } from '../components/payment-entry/payment-entry-model';
import { PaymentMethodType } from '../virtual-terminal-generated';

export function toPaymentMethodType(paymentMethodUiType: PaymentMethodUiType) {
	switch(paymentMethodUiType) {
		case PaymentMethodUiType.ACH:
		case PaymentMethodUiType.ExistingACH:
			return PaymentMethodType.ACH;

		case PaymentMethodUiType.RecordedACH:
			return PaymentMethodType.RecordedACH;

		case PaymentMethodUiType.CreditCard:
		case PaymentMethodUiType.ExistingCreditCard:
			return PaymentMethodType.CreditCard;

		case PaymentMethodUiType.RecordedCreditCard:
			return PaymentMethodType.RecordedCreditCard;

		case PaymentMethodUiType.Cash:
			return PaymentMethodType.Cash;

		case PaymentMethodUiType.RecordedCheck:
			return PaymentMethodType.RecordedCheck;

		case PaymentMethodUiType.NonCash:
			return PaymentMethodType.Unknown; // It's not a normal payment and should be handled differently on the backend

		default:
			const unhandledPaymentMethodUiType: never = paymentMethodUiType;
			return unhandledPaymentMethodUiType;
	}
}

export function getPaymentMethodDisplayText(type: PaymentMethodUiType, checkNoun: string, nonCashNoun: string): string {
	switch (type) {
		case PaymentMethodUiType.ACH:
			return 'ACH';
		case PaymentMethodUiType.CreditCard:
			return 'Card';
		case PaymentMethodUiType.RecordedACH:
			return 'Recorded bank transfer';
		case PaymentMethodUiType.RecordedCreditCard:
			return 'Recorded card';
		case PaymentMethodUiType.RecordedCheck:
			return `Recorded ${checkNoun}`;
		case PaymentMethodUiType.NonCash:
			return nonCashNoun;
		default:
			return PaymentMethodUiType[type];
	}
}
