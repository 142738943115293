import * as React from 'react';
import { TransitionGroup } from 'react-transition-group-v1';
import { RenderResponsive } from '../../components/hoc-behavior/render-responsive';
import { responsiveHelper, ResponsiveBreakpoint, ResponsiveVisibility } from '../../helpers/responsive-helper';
import { isFunction } from '../../utils/is-function';
import { observer } from 'mobx-react';
import { AllocationItemForm } from './allocation-item-form';
import { Models } from '../check-deposit-generated';
import PaymentLabel = Models.PaymentLabel;
import { SvgWrapper } from '../../components/svg-wrapper';
import { KeyCodes } from '../../../Shared/helpers/keycodes';
import { AllocationCheckDetailsListModel, SortableColumns } from './allocation-check-details-list-model';
import { SortingIcon, leaveTransitionDuration } from '../../components/data-grid/data-grid';
import { ClientFlowHelper } from '../helpers/client-flow-helper';
import { getPayerName } from '../../utils/getPayerName';


@observer
export class AllocationGrid extends React.Component<{
	store: AllocationCheckDetailsListModel;
	getPreviousPaymentDetailsInProgress: boolean;
	payButtonLabel: PaymentLabel;
}> {
	private documentContainerNodes: NodeList;
	private rowNodes: { [token: string]: HTMLElement };

	constructor(props) {
		super(props);

		this.documentContainerNodes = document.querySelectorAll('html, body');
		this.rowNodes = {};
	}

	render() {
		const { store, getPreviousPaymentDetailsInProgress, payButtonLabel } = this.props;
		const { SortedCheckDetails, IncompleteCount, SortedBy, SortDirection, disabled, showOverlay, ExpandedCheckStore, AddMemberFormProps, isProcessingSaveRequest } = store;
		const sortingIconProps = { sortDirection: SortDirection, sortedBy: SortedBy };
		const incomleteCountDesc = IncompleteCount === 1
			? <span>is <strong>{IncompleteCount} incomplete check</strong></span>
			: <span>are <strong>{IncompleteCount} incomplete checks</strong></span>;

		return SortedCheckDetails && (
			<div className="cd-allocation-grid">
				<div className="cd-allocation-grid-info">
					<p>There {incomleteCountDesc} in this batch. Click on any row to start allocating each check.</p>
					<p className="cd-note"><span className="cd-missing-data-indicator">{'\u2022'}</span> Checks with red dots have missing data</p>
					<AllocationGridPartialDepositInfoPanel store={store} />
				</div>
				<div className={`data-grid${showOverlay ? ' disabled' : ''}`}>
					<RenderResponsive visibility={ResponsiveVisibility.Hidden} breakpoints={ResponsiveBreakpoint.Xs | ResponsiveBreakpoint.Sm}>
						<div className="data-grid-row data-grid-row-header">
							<div className="data-grid-cell cd-cell-missing-data-indicator" />
							<div className={`data-grid-cell cd-cell-row-number ${this.getSortableClassNames(SortableColumns.ROW_NO)}`} onClick={this.sortByRowNumber}>
								#<SortingIcon columnKey={SortableColumns.ROW_NO} {...sortingIconProps} />
							</div>
							<div className={`data-grid-cell cd-cell-giver-name ${this.getSortableClassNames(SortableColumns.GIVER)}`} onClick={this.sortByGiver}>
								{payButtonLabel.PayerSentenceCase}<SortingIcon columnKey={SortableColumns.GIVER} {...sortingIconProps} />
							</div>
							<div className="data-grid-cell cd-cell-giver-email">Email Address</div>
							<div className="data-grid-cell cd-cell-giver-mobile">Contact</div>
							<div className="data-grid-cell cd-cell-funds">Funds</div>
							<div className={`data-grid-cell cd-cell-check-info ${this.getSortableClassNames(SortableColumns.ROUTING_ACCOUNT_NO)}`} onClick={this.sortByAccount}>
								Routing &amp; account no.<SortingIcon columnKey={SortableColumns.ROUTING_ACCOUNT_NO} {...sortingIconProps} />
							</div>
							<div className={`data-grid-cell cd-cell-amount ${this.getSortableClassNames(SortableColumns.AMOUNT)}`} onClick={this.sortByAmount}>
								Amount<SortingIcon columnKey={SortableColumns.AMOUNT} {...sortingIconProps} />
							</div>
							<div className="data-grid-cell cd-cell-actions" />
						</div>
					</RenderResponsive>
					<TransitionGroup component="div" className="data-grid-body">
						{SortedCheckDetails.map((data) => {
							return (ExpandedCheckStore && ExpandedCheckStore.check.EncodedToken === data.EncodedToken)
								? (<AllocationItemForm
									key={`${data.EncodedToken}-form`}
									store={ExpandedCheckStore}
									disabled={getPreviousPaymentDetailsInProgress || disabled}
									showLoadingOverlay={getPreviousPaymentDetailsInProgress}
									isProcessingSaveRequest={isProcessingSaveRequest}
									addMemberFormProps={AddMemberFormProps}
									listViewModel={store}
									focusRow={this.focusRow}
									toggleDocumentScroll={this.toggleDocumentScroll}
								/>)
								: (<AllocationGridRow
									key={data.EncodedToken}
									data={data}
									listViewModel={store}
									payButtonLabel={payButtonLabel}
									addRowNode={this.addRowNode}
								/>);
						})}
					</TransitionGroup>
				</div>
			</div>
		);
	}

	addRowNode = (element: HTMLElement, token: string) => this.rowNodes[token] = element;

	focusRow = (token: string) => {
		const row = this.rowNodes[token];
		if (row) {
			row.focus();
		}
	}

	toggleDocumentScroll = (enableScroll: boolean) => {
		Array.prototype.forEach.call(this.documentContainerNodes, (element) => element.classList[enableScroll ? 'remove' : 'add']('data-grid-is-expanded'));
	}

	private getSortableClassNames(column: SortableColumns): string {
		const sortedColumnClassName = (column === this.props.store.SortedBy) ? 'data-grid-cell-sortable-sorted' : '';
		return `data-grid-cell-sortable ${sortedColumnClassName}`;
	}

	private sortByRowNumber = () => this.props.store.sortColumns(SortableColumns.ROW_NO);

	private sortByGiver = () => this.props.store.sortColumns(SortableColumns.GIVER);

	private sortByAccount = () => this.props.store.sortColumns(SortableColumns.ROUTING_ACCOUNT_NO);

	private sortByAmount = () => this.props.store.sortColumns(SortableColumns.AMOUNT);
}

@observer
export class AllocationGridRow extends React.Component<{
	data: Models.CheckAllocationViewModel,
	listViewModel: AllocationCheckDetailsListModel,
	payButtonLabel: PaymentLabel,
	addRowNode: (row: HTMLElement, token: string) => void,
}, {
	isEntering: boolean
}> {
	constructor(props) {
		super(props);
		this.state = { isEntering: false };
	}

	render() {
		const { data, payButtonLabel, addRowNode } = this.props;
		const { CommunityMember, FundName, Amount, HasMissingData, RowNumber, IsRead, RoutingNumber, AccountNumber } = data;
		const EmailAddress = CommunityMember && CommunityMember.EmailAddress;
		const MobileNumber = CommunityMember && CommunityMember.MobileNumber;
		const rowDataClassNames = `data-grid-row-data data-grid-row-data-clickable${IsRead ? ' cd-seen' : ''}`;
		const giverCellClassName = `data-grid-cell cd-cell-giver-name${CommunityMember ? '' : ' cd-unallocated'}`;
		const hiddenMobileBreakpoints = ResponsiveBreakpoint.Xs | ResponsiveBreakpoint.Sm;
		const giverNameContents = CommunityMember ? <strong>{getPayerName(CommunityMember)}</strong> : <em>Select {payButtonLabel.PayerLowerCase}</em>;
		const checkInfo = `${RoutingNumber} ${AccountNumber}`;

		return !this.state.isEntering && (
			<div className="data-grid-row">
				<div className={rowDataClassNames} onClick={this.beginEditing} tabIndex={0} onKeyDown={this.checkKeyPressed} ref={(row) => addRowNode(row, data.EncodedToken)}>
					<div className="data-grid-cell cd-cell-missing-data-indicator">{HasMissingData ? <span className="cd-missing-data-indicator">{`\u2022`}</span> : null}</div>
					<RenderResponsive visibility={ResponsiveVisibility.Hidden} breakpoints={hiddenMobileBreakpoints}>
						<div className="data-grid-cell cd-cell-row-number">{RowNumber}</div>
					</RenderResponsive>
					<div className={giverCellClassName}>{giverNameContents}</div>
					<div className={`data-grid-cell cd-cell-giver-email${EmailAddress ? '' : ' cd-cell-empty'}`}>{EmailAddress}</div>
					<div className={`data-grid-cell cd-cell-giver-mobile${MobileNumber ? '' : ' cd-cell-empty'}`}>{MobileNumber}</div>
					<RenderResponsive visibility={ResponsiveVisibility.Hidden} breakpoints={hiddenMobileBreakpoints}>
						<div className="data-grid-cell cd-cell-funds">{FundName}</div>
					</RenderResponsive>
					<div className="data-grid-cell cd-cell-check-info">{checkInfo}</div>
					<div className="data-grid-cell cd-cell-amount"><strong>${Amount.toFixed(2)}</strong></div>
					<div className="data-grid-cell cd-cell-actions">
						<RenderResponsive visibility={ResponsiveVisibility.Hidden} breakpoints={hiddenMobileBreakpoints}>
							<SvgWrapper svg="pen" className="icon" title="Edit check" />
						</RenderResponsive> Edit
					</div>
				</div>
			</div>
		);
	}

	componentWillEnter(callback) {
		this.setState({ isEntering: true });

		if (this.props.listViewModel.ExpandedCheckStore || responsiveHelper.isXs || responsiveHelper.isSm) {
			callback();
		} else {
			// wait for form to transition out
			setTimeout(callback, leaveTransitionDuration);
		}
	}

	componentDidEnter() {
		this.setState({ isEntering: false });
	}

	componentWillLeave(callback) {
		if (responsiveHelper.isXs || responsiveHelper.isSm) {
			// wait for form to transition in
			setTimeout(callback, leaveTransitionDuration);
		} else {
			callback();
		}
	}

	private checkKeyPressed = (e: React.KeyboardEvent<any>) => {
		switch (e.keyCode) {
			case KeyCodes.Space:
			case KeyCodes.Enter:
				e.preventDefault();
				this.beginEditing();
				break;
			default:
				break;
		}
	}

	private beginEditing = () => {
		const { handleStartEditing, disabled } = this.props.listViewModel;

		if (disabled) {
			return;
		}

		if (isFunction(handleStartEditing)) {
			handleStartEditing(this.props.data.EncodedToken);
		}
	}
}

@observer
export class AllocationGridPartialDepositInfoPanel extends React.Component<{ store: AllocationCheckDetailsListModel }> {
	render() {
		if (!this.props.store.isPartialBatch) {
			return null;
		}

		return (
			<div className="alert alert-sm alert-info">
				<SvgWrapper svg="icon-info" className="icon alert-icon" />
				<span>
					Your batch was adjusted because some of the checks encountered issues during verification. For more information,{'\u00A0'}
					<a href="#" onClick={this.openCheckScanningPortal} className="arrow-hollow-right-after">open check scanning portal</a>
				</span>
			</div>
		);
	}

	private openCheckScanningPortal = (event: React.MouseEvent<HTMLAnchorElement>) => {
		event.preventDefault();
		ClientFlowHelper.openCheckScanningPortalWindow();
	}
}
