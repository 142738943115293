import * as React from 'react';
import { injectValidationFormContext, IHaveValidatableFormContext, getFieldError } from './validatable-form-context';
import { observer } from 'mobx-react';

export interface IValidationMessageProps extends IHaveValidatableFormContext {
	elementName: string;
	additionalCssClass?: string;
	hideErrors?: boolean;
}

export const ValidationMessage = injectValidationFormContext(observer((props: IValidationMessageProps) => {
	const { additionalCssClass, elementName, validatableFormContext, hideErrors } = props;

	if (!validatableFormContext) {
		//fallback to jQuery validation when validatableFormContext is unavailable
		return <span className={`field-validation-valid ${additionalCssClass || ''}`}
			data-valmsg-for={elementName}
			data-valmsg-replace="true" />;
	}

	const error = getFieldError(props, elementName);
	if (!error || hideErrors) {
		return null;
	}

	return (
		<span className={`field-validation-error ${additionalCssClass || ''}`}>
			<span>{error}</span>
		</span>
	);
}));
