import * as React from 'react';
import update from 'immutability-helper';
import {ResponseTypes} from './funds-generated';
import {ManageFundsListings} from './components/manage-funds-listings';
import {IManageFundsListProps} from './components/manage-funds-list';
import {IManageFundsListItemProps} from './components/manage-funds-listitem';
import {ModalDialogCommander} from '../components/modal-dialog-commander';
import {ModalDialog, IModalDialogButton} from '../components/modal-dialog';
import {stateService} from './utils/manage-funds-state-service';
import {makeAjaxCall} from './utils/ajax-helper';
import {stateHelper as s} from './utils/state-helper';

const MaxNumberOfElementsToDefaultToAllListings = 500;
const RecommendedNumberOfVisibleFunds = 7;

export interface IListingState {
	fundKeys?: string[];
	defaultFundKey?: string;
	hash?: string;
	showDefaultFundColumn?: boolean;
}

export interface IListingsState {
	[listingId: number]: IListingState;
}

export interface IManageFundsViewProps {
	model: ResponseTypes.ManageFundsViewModel;
	persistChangesForMilliseconds: number;
}

export interface IManageFundsViewState {
	listings?: IListingsState;
	listingIdBeingReordered?: number;
	isProcessingRequest?: boolean;
	selectedListing?: number;
	isLoadingMore?: boolean;
	canLoadMore?: boolean;
	pagingCursor?: string;
	fundItems?: ResponseTypes.FundViewModel[];
}

export interface IConfirmTooManyFundsModalDialogProps {
	onConfirm: () => void;
	listingName: string;
	fundBeingSelected: string;
	totalVisibleFundsPerListing: number;
}

export interface IConfirmMaximumFundsReachedModalDialogProps {
	listingName: string;
	fundBeingSelected: string;
	maxVisibleFundsPerListing: number;
}

export class ConfirmTooManyFundsModalDialog extends React.Component<IConfirmTooManyFundsModalDialogProps, any> {
	render() {
		const confirmButton = <button key="confirm_button"
			className="btn btn-primary"
			onClick={this.props.onConfirm}
			data-dismiss="modal">Activate anyway</button>;
		const buttons: (IModalDialogButton | JSX.Element)[] = [
			{
				label: 'Cancel',
				dismiss: true,
				isPrimary: false
			},
			confirmButton
		];

		return (
			<ModalDialog title="" buttons={buttons}>
				Pushpay recommends {RecommendedNumberOfVisibleFunds} funds (or fewer) per listing.<br />
				If you activate ‘{this.props.fundBeingSelected}’ for {this.props.listingName} you
				will have {this.props.totalVisibleFundsPerListing} funds for this listing.
			</ModalDialog>);
	}
}

export class MaximumFundsReachedModalDialog extends React.Component<IConfirmMaximumFundsReachedModalDialogProps, any> {
	render() {
		const buttons: (IModalDialogButton | JSX.Element)[] = [{label: 'OK', dismiss: true, isPrimary: false}];
		return (
			<ModalDialog title="" buttons={buttons}>
				Listings cannot have more than {this.props.maxVisibleFundsPerListing} funds visible to givers.<br />
				To make the ‘{this.props.fundBeingSelected}’ fund visible for {this.props.listingName}, please hide one of the visible funds first.
			</ModalDialog>);
	}
}

export let ManageFundsErrorDialog = (message?: string) => (
	<ModalDialog title="Whoops, something went wrong">
		{
			message || 'There was a problem saving your changes.\u00a0Please try again.'
		}
	</ModalDialog>);

export class ManageFundsView extends React.Component<IManageFundsViewProps, IManageFundsViewState> {
	private tooManyFundsDialogWasConfirmed = false;

	constructor(props) {
		super(props);
		const persistedChanges = stateService.loadPersistedChanges(
									this.props.model.OrganizationId,
									this.ignoreChangesMadeBefore());
		const currentState = stateService.convertToState(this.props.model);
		const mergedState = stateService.getMergedState(this.props.model, persistedChanges);

		const numberOfElements = this.props.model.Funds.length * this.props.model.Listings.length;

		this.state = {
			listingIdBeingReordered: null,
			selectedListing: numberOfElements > MaxNumberOfElementsToDefaultToAllListings ? this.props.model.Listings[0].ListingId : null,
			listings: mergedState || currentState,
			canLoadMore: this.props.model.CanLoadMore,
			pagingCursor: this.props.model.PagingCursor,
			fundItems: this.props.model.Funds
		};

		this.setupShowDefaultColumnPerListing(this.state.listings);

		this.discardChanges = this.discardChanges.bind(this);
		this.publish = this.publish.bind(this);
		this.toggleDefault = this.toggleDefault.bind(this);
		this.toggleVisible = this.toggleVisible.bind(this);
		this.sortingOrderChange = this.sortingOrderChange.bind(this);
		this.dragStateChange = this.dragStateChange.bind(this);
		this.toggleDefaultFundPerListing = this.toggleDefaultFundPerListing.bind(this);
		this.listingSelectionChange = this.listingSelectionChange.bind(this);
		this.loadMore = this.loadMore.bind(this);
	}

	toggleVisible(key: string, listingId: number) {
		let checkTooManyVisibleFunds = false;

		const updatedState = this.getUpdateListingStateSpec(listingId, (listingState, updateSpec) => {
			const idx = listingState.fundKeys.indexOf(key);
			if (idx === -1) {
				if (listingState.showDefaultFundColumn && listingState.fundKeys.length === 0) {
					updateSpec.defaultFundKey = s.set(key);
				}

				updateSpec.fundKeys = s.push(key);
				checkTooManyVisibleFunds = true;
			} else {
				if (!this.validateVisibleFundsRemaining(listingState)) {
					return;
				}

				updateSpec.fundKeys = s.splice<string>({ start: idx, deleteCount: 1 });

				if (listingState.fundKeys.length === 1) {
					updateSpec.defaultFundKey = s.set(null);
				} else if (listingState.showDefaultFundColumn && listingState.defaultFundKey === key) {
					updateSpec.defaultFundKey = s.set(listingState.fundKeys[0] || null);
				}
			}
		});

		if (checkTooManyVisibleFunds) {
			this.showTooManyFundsVisibleDialogIfNeededAndSetState(key, listingId, updatedState);
		} else {
			this.updateState(updatedState);
		}
	}

	validateVisibleFundsRemaining(listingState: IListingState): boolean {
		const visibleFundsRemaining = listingState.fundKeys.length - 1;
		if (visibleFundsRemaining > 0) {
			return true;
		}
		ModalDialogCommander.showReactDialog(<ModalDialog title="" >Every listing must have at least one visible fund.</ModalDialog>);
		return false;
	}

	toggleDefaultFundPerListing(listingId: number) {
		this.updateListingState(listingId, (listingState, updateSpec) => {
			if (listingState.showDefaultFundColumn) {
				updateSpec.defaultFundKey = s.set(null);
				updateSpec.showDefaultFundColumn = s.set(false);
			} else {
				updateSpec.defaultFundKey = s.set(listingState.fundKeys[0] || null);
				updateSpec.showDefaultFundColumn = s.set(true);
			}
		});
	}

	toggleDefault(key: string, listingId: number) {
		let checkTooManyVisibleFunds = false;

		const updatedState = this.getUpdateListingStateSpec(listingId, (listingState, updateSpec) => {
			if (listingState.defaultFundKey !== key) {
				updateSpec.defaultFundKey = s.set(key);

				if (listingState.fundKeys.indexOf(key) === -1) {
					updateSpec.fundKeys = s.push(key);
					checkTooManyVisibleFunds = true;
				}
			}
		});

		if (checkTooManyVisibleFunds) {
			this.showTooManyFundsVisibleDialogIfNeededAndSetState(key, listingId, updatedState);
		} else {
			this.updateState(updatedState);
		}
	}

	sortingOrderChange(sortingOrder: Array<string>, listingId: number) {
		this.updateListingState(listingId, (listingState, updateSpec) => {
			var reordered: string[] = [];
			sortingOrder.forEach(x => {
				if (listingState.fundKeys.indexOf(x) !== -1) {
					reordered.push(x);
				}
			});

			updateSpec.fundKeys = s.set(reordered);
		});
	}

	dragStateChange(isDragging: boolean, listingId: number) {
		if (isDragging) {
			this.setState({ listingIdBeingReordered: listingId });
		} else {
			this.setState({ listingIdBeingReordered: null });
		}
	}

	listingSelectionChange(listingId: number) {
		const updateSpec = {
			'selectedListing': {
				$set: listingId
			}
		};

		this.setState(update(this.state, updateSpec));
	}

	publish() {
		const model: ResponseTypes.ManageFundsEditModel = {
			Listings: this.props.model.Listings.map((x): ResponseTypes.ManageFundsEditModelListing => {
				var listingState = this.state.listings[x.ListingId];
				return {
					ListingId: x.ListingId,
					DefaultFundKey: listingState.defaultFundKey,
					FundKeys: listingState.fundKeys
				};
			})
		};

		this.setState({
			isProcessingRequest: true
		});

		makeAjaxCall(this.props.model.PublishUrl, model)
			.fail((response) => {
				this.onPublishError(response.ErrorMessage);
				this.setState({
					isProcessingRequest: false
				});
			})
			.done(() => this.onPublishSuccess());
	}

	onPublishSuccess() {
		stateService.removeState(this.props.model);
		window.location.href = this.props.model.RedirectUrl;
	}

	onPublishError(message?: string) {
		ModalDialogCommander.showReactDialog(ManageFundsErrorDialog(message));
	}

	loadMore() {
		this.setState({ isLoadingMore: true });
		makeAjaxCall(this.props.model.LoadMoreUrl, { PagingCursor: this.state.pagingCursor })
			.fail((response) => this.onLoadMoreError(response.ErrorMessage))
			.done((response: ResponseTypes.ManageFundsViewModel) => this.onLoadMoreSuccess(response))
			.always(() => this.setState({ isLoadingMore: false }));
	}

	onLoadMoreSuccess(data: ResponseTypes.ManageFundsViewModel) {
		this.setState({ fundItems: this.state.fundItems.concat(data.Funds) });
		this.setState({ canLoadMore: data.CanLoadMore })
		this.setState({ pagingCursor: data.PagingCursor })
	}

	onLoadMoreError(message?: string) {
		ModalDialogCommander.showReactDialog(ManageFundsErrorDialog(message));
	}

	hasChanges() {
		for (let i = 0; i < this.props.model.Listings.length; i++) {
			const originalListing = this.props.model.Listings[i];
			const listingState = this.state.listings[originalListing.ListingId];
			if (originalListing.DefaultFundKey !== listingState.defaultFundKey) {
				return true;
			}

			if (originalListing.FundKeys.length !== listingState.fundKeys.length
				|| !originalListing.FundKeys.every((x, idx) => x === listingState.fundKeys[idx])) {
				return true;
			}
		}

		return false;
	}

	discardChanges(e: React.MouseEvent<any>) {
		e.preventDefault();
		stateService.removeState(this.props.model);

		const listings = stateService.convertToState(this.props.model);
		this.setupShowDefaultColumnPerListing(listings);

		this.setState({
			listings: listings
		});
	}

	getSortedFunds(listing: IListingState ) {
		let fundItems = NewFeatures.ManageFundsPageOptimizations
			? this.state.fundItems
			: this.props.model.Funds;

		return fundItems.sort((a, b) => {
			var indexA = listing.fundKeys.indexOf(a.Key);
			var indexB = listing.fundKeys.indexOf(b.Key);

			//if items is not in the list - it should appear at the end
			if (indexA < 0 && indexB >= 0) {
				return 1;
			} else if (indexA >= 0 && indexB < 0) {
				return -1;
			}

			if (indexA > indexB) {
				return 1;
			} else if (indexA < indexB) {
				return -1;
			}

			var aName = a.Name.toLowerCase();
			var bName = b.Name.toLowerCase();

			//indexes are the same, most likely both of -1 - sort by name
			if (aName > bName) {
				return 1;
			} else if (aName < bName) {
				return -1;
			}

			//names are the same - sort by code
			var aCode = a.Code.toLowerCase();
			var bCode = b.Code.toLowerCase();

			if (aCode > bCode) {
				return 1;
			} else if (aCode < bCode) {
				return -1;
			}

			return 0;
		});
	}

	createListing(listing: ResponseTypes.FundMerchantListingViewModel, idx: number): IManageFundsListProps {
		const listingState = this.state.listings[listing.ListingId];
		const listingId = listing.ListingId;

		const items = this.getSortedFunds(listingState).map(x => {
			let data: IManageFundsListItemProps;

			data = {
				name: x.Name,
				code: x.Code,
				listingId: listingId,
				itemKey: x.Key,
				itemUrl: x.EditUrl,
				isDefault: listingState.defaultFundKey === x.Key,
				isVisible: listingState.fundKeys.indexOf(x.Key) !== -1,
				toggleVisible: this.toggleVisible,
				makeDefault: this.toggleDefault,
				isNew: x.IsNew,
				type: x.FundType,
				linkedCampaignUrl: x.LinkedCampaignUrl,
			};

			return data;
		});

		return {
			items: items,
			listingId: listingId,
			listingName: listing.ListingName,
			sortingOrderChange: this.sortingOrderChange,
			dragStateChange: this.dragStateChange,
			isBeingReordered: this.state.listingIdBeingReordered === listingId,
			toggleDefaultFundPerListing: this.toggleDefaultFundPerListing,
			showDefaultFundColumn: listingState.showDefaultFundColumn,
			newlyAddedFundKey: idx === 0 ? this.props.model.NewlyAddedFundKey : null,
			defaultFundToggleTooltip: this.props.model.DefaultFundToggleTooltip,
			canLoadMore: this.state.canLoadMore
		};
	}

	render() {
		return <ManageFundsListings
			listings={this.props.model.Listings.map(this.createListing, this) }
			selectedListing = {this.state.selectedListing}
			listingSelectionChange={this.listingSelectionChange}
			hasChanges={this.hasChanges() }
			publish={this.publish}
			redirectUrl={this.props.model.RedirectUrl}
			defaultFundToggleTooltip={this.props.model.DefaultFundToggleTooltip}
			isBeingReordered={this.state.listingIdBeingReordered !== null}
			discardChanges={this.discardChanges}
			isProcessingRequest={this.state.isProcessingRequest}
			disableAllListingsDropdownOption={this.props.model.DisableAllListingsDropdownOption}
			canLoadMore={this.state.canLoadMore}
			loadMoreUrl={this.props.model.LoadMoreUrl}
			isLoadingMore={this.state.isLoadingMore}
			loadMore={this.loadMore}
			/>;
	}

	private getFundByKey(key: string): ResponseTypes.FundViewModel {
		return NewFeatures.ManageFundsPageOptimizations
			? this.state.fundItems.find(x => x.Key === key)
			: this.props.model.Funds.find(x => x.Key === key);
	}

	private getListingById(listingId: number): ResponseTypes.FundMerchantListingViewModel {
		return this.props.model.Listings.find(x => x.ListingId === listingId);
	}

	private getTotalVisibleNonCampaignFundsPerListing(listingId: number): number {
		return this.state.listings[listingId].fundKeys.filter(key =>
			NewFeatures.ManageFundsPageOptimizations
				? this.state.fundItems.some(f => f.Key === key && f.FundType !== ResponseTypes.FundType.Campaign)
				: this.props.model.Funds.some(f => f.Key === key && f.FundType !== ResponseTypes.FundType.Campaign)
			).length;
	}

	private updateState(updatedState: IManageFundsViewState) {
		this.setState(updatedState);
		stateService.saveState(this.props.model, updatedState.listings);
	}

	private showTooManyFundsVisibleDialogIfNeededAndSetState(key: string, listingId: number, updatedState: IManageFundsViewState) {
		const totalVisibleFundsPerListing = this.getTotalVisibleNonCampaignFundsPerListing(listingId);
		if (totalVisibleFundsPerListing < RecommendedNumberOfVisibleFunds) {
			this.updateState(updatedState);
			return;
		}

		const listing = this.getListingById(listingId);
		const selectedFund = this.getFundByKey(key);

		if (totalVisibleFundsPerListing >= this.props.model.MaximumVisibleFundsPerListing) {
			// totalVisibleFundsPerListing is the number of currently visible funds before we make one more listing visible.
			// if it's greater than or equal to maximumVisibleFundsPerListing we need to prevent the action and show the error dialog
			ModalDialogCommander
				.showReactDialog((<MaximumFundsReachedModalDialog
					fundBeingSelected={selectedFund.Name}
					listingName={listing.ListingName}
					maxVisibleFundsPerListing={this.props.model.MaximumVisibleFundsPerListing} />));
		} else {
			// if we have shown the warning message already, don't show it again (until the page is reloaded).
			if (this.tooManyFundsDialogWasConfirmed) {
				this.updateState(updatedState);
				return;
			}

			// show warning dialog and supress it being shown again if the dialog is confirmed
			ModalDialogCommander
				.showReactDialog((<ConfirmTooManyFundsModalDialog
					fundBeingSelected={selectedFund.Name}
					listingName={listing.ListingName}
					totalVisibleFundsPerListing ={totalVisibleFundsPerListing + 1}
					onConfirm={() => {
						this.tooManyFundsDialogWasConfirmed = true;
						this.updateState(updatedState);
					} } />));
		}
	}

	private ignoreChangesMadeBefore() {
		return new Date((new Date().valueOf()) - this.props.model.UnsavedChangesExpiry);
	}

	private setupShowDefaultColumnPerListing(listings:IListingsState) {
		for (let listing of this.props.model.Listings) {
			const listingState = listings[listing.ListingId];
			listingState.showDefaultFundColumn = listingState.defaultFundKey !== null || listingState.fundKeys.length === 0;
		}
	}

	private updateListingState(listingId: number, updater: (listingState: IListingState, updateSpec: IListingState) => void) {
		const updatedState = this.getUpdateListingStateSpec(listingId, updater);
		this.updateState(updatedState);
	}

	private getUpdateListingStateSpec(listingId: number, updater: (listingState: IListingState, updateSpec: IListingState) => void) {
		const updateStateSpec: IManageFundsViewState = {
			listings: {}
		};

		const spec: IListingState = updateStateSpec.listings[listingId] = {};
		updater(this.state.listings[listingId], spec);
		return s.update(this.state, updateStateSpec);
	}
}
