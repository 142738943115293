import * as React from 'react';
import * as Models from './transaction-import-generated';
import { TransactionImportCreateViewModel } from './transaction-import-create-view-model';
import { ImportStepHeader, ImportStep } from './components/import-step-header';
import { ImportStepDescription } from './components/import-step-description';
import { AlertControl } from './components/alert-control';
import { FormControlType, IFormControlLabelledProps } from '../components/form-controls/form-control-labelled';
import { observer } from 'mobx-react';
import { CreateImportForm } from './forms/create-import-form';

@observer
export class TransactionImportCreate extends React.Component<{vm: TransactionImportCreateViewModel}, {}> {

	render() {
		const {
			alertProps,
			allImportTypes,
			allVisibleListings,
			importType,
			merchantId,
			navigationInProgress,
		} = this.props.vm;

		const modelMetadata = Models.ModelMetadata.TransactionImportCreateRequestModel;

		const formatSelectProps: IFormControlLabelledProps = {
			label: modelMetadata.ImportType.displayName,
			formControlProps: {
				formControlType: FormControlType.DropDown,
				name: modelMetadata.ImportType.propertyName,
				value: importType ? importType.toString() : null,
				onChangeHandler : this.handleImportTypeChange,
				Options: allImportTypes,
				validationRules: modelMetadata.ImportType.validationRules,
			}
		};

		const listingSelectProps: IFormControlLabelledProps = {
			label: modelMetadata.MerchantId.displayName,
			formControlProps: {
				formControlType: FormControlType.DropDown,
				name: modelMetadata.MerchantId.propertyName,
				value: (merchantId || allVisibleListings.length === 1) ? merchantId.toString() : null,
				onChangeHandler : this.handleListingChange,
				Options: allVisibleListings,
				validationRules: modelMetadata.MerchantId.validationRules,
				hideEmptyOption: allVisibleListings.length === 1 ? true : false,
			}
		};
		return (
			<div>
				<AlertControl {...alertProps} />

				<ImportStepHeader currentStep={ImportStep.Select}/>

				<ImportStepDescription title="Select source" description="Select your file's data format and campus listing." />

				<CreateImportForm
					handleSubmit={this.handleSubmit}
					handleCancel={this.handleCancel}
					formatSelectProps={formatSelectProps}
					listingSelectProps={listingSelectProps}
					navigationInProgress={navigationInProgress}
				/>
			</div>
		);
	}

	private handleImportTypeChange = (event: React.FormEvent<HTMLSelectElement>) => {
		const value = event.currentTarget.value;
		this.props.vm.updateImportType(value);
	}

	private handleListingChange = (event: React.FormEvent<HTMLSelectElement>) => {
		const value = event.currentTarget.value;
		this.props.vm.updateSelectedListing(value);
	}

	private handleSubmit = () => {
		this.props.vm.initCreateImportRequest();
	}

	private handleCancel = () => {
		this.props.vm.cancelImport();
	}
}

