import * as React from 'react';
import * as ReactDOM from 'react-dom';
import MaskedInput, { IMaskedInputProps } from 'react-text-mask';
import { IconInputGroup, IIconInputGroupProps } from '../icon-input-group';
import { IFormControlCommonProps } from './form-control-common-props';
import { ValidatableControl } from './validatable-control';
import { getElementId } from './form-control-utils';
import { Fragment } from '../../../Shared/components/fragment';
import { classNames } from '../../../Shared/utils/classnames';

export type IFormControlInputProps = IFormControlCommonProps & {
	inputGroupOptions?: IIconInputGroupProps;
	maskOptions?: IMaskedInputProps;
	type: string;
	readonly?: boolean;
	onChangeHandler?: (event: React.FormEvent<HTMLInputElement>) => void;
	onBlurHandler?: (event: React.FormEvent<HTMLInputElement>) => void;
	value: string;
	ignorePanLikeValidation?: boolean;
	onFocusHandler?: (event: React.FormEvent<HTMLInputElement>) => void;
	onMouseUpHandler?: (event: React.FormEvent<HTMLInputElement>) => void;
	cssClassNames?: string;
	ariaLabel?: string;
	spellCheck?: boolean;
} & ({ maxLength?: number, showInputCounts?: false } | { showInputCounts: true, maxLength: number });

export class FormControlInput extends React.Component<IFormControlInputProps> {
	render(): any {
		const {
			type, name, uniqueSuffix, placeholder, onChangeHandler, value, inputGroupOptions, maxLength, onBlurHandler,
			onFocusHandler, onMouseUpHandler, showInputCounts, maskOptions, validationRules, refCallback, readonly,
			disabled, tabIndex, ignorePanLikeValidation, acceptanceTestTargetId, cssClassNames, ariaLabel, spellCheck,
			validateOnChange = false,
			revalidateOnChange = false,
			validateOnBlur = false,
			revalidateOnBlur = true,
		} = this.props;
		const id = getElementId(name, uniqueSuffix);
		const controlledValue = typeof value === 'string' ? value : '';
		const className = classNames('form-control', cssClassNames);

		const input = maskOptions && maskOptions.mask
			?
				<MaskedInput {...maskOptions}
					id={id}
					name={name}
					type={type}
					className={className}
					placeholder={placeholder}
					onChange={onChangeHandler}
					onMouseUp={onMouseUpHandler}
					value={controlledValue}
					onFocus={onFocusHandler}
					onBlur={onBlurHandler}
					ref={this.maskRefCallback}
					tabIndex={tabIndex}
					maxLength={maxLength}
					spellCheck={spellCheck}
					data-ignore-pan-like={ignorePanLikeValidation}
					data-pp-at-target={acceptanceTestTargetId}
					aria-label={ariaLabel}
				/>
			:
				<input
					id={id}
					name={name}
					className={className}
					type={type}
					placeholder={placeholder}
					onChange={onChangeHandler}
					onMouseUp={onMouseUpHandler}
					value={controlledValue}
					onFocus={onFocusHandler}
					onBlur={onBlurHandler}
					ref={refCallback}
					readOnly={readonly}
					disabled={disabled}
					tabIndex={tabIndex}
					maxLength={maxLength}
					spellCheck={spellCheck}
					data-ignore-pan-like={ignorePanLikeValidation}
					data-pp-at-target={acceptanceTestTargetId}
					aria-label={ariaLabel}
				/>;

		const inputContent =
			<Fragment>
				<ValidatableControl
					validationRules={validationRules}
					elementName={name}
					validateOnChange={validateOnChange}
					revalidateOnChange={revalidateOnChange}
					revalidateOnBlur={revalidateOnBlur}
					validateOnBlur={validateOnBlur}
				>
					{input}
				</ValidatableControl>
				{showInputCounts && <InputCount charCounts={controlledValue.length} maxLength={maxLength} />}
			</Fragment>;

		return inputGroupOptions ? <IconInputGroup options={inputGroupOptions}>{inputContent}</IconInputGroup> : inputContent;
	}

	private maskRefCallback = (element) => {
		if (this.props.refCallback) {
			this.props.refCallback(ReactDOM.findDOMNode(element) as HTMLElement);
		}
	}
}

const InputCount = ({ charCounts, maxLength }) => (
	<div className={classNames('form-control-input-counts', charCounts === maxLength && 'maxed')}>
		{`${charCounts}/${maxLength} characters`}
	</div>
);
