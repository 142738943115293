import * as React from 'react';
import { ListingSelectorWithLabel } from '../../components/listing-selector';
import { ManageFundsList, IManageFundsListProps } from './manage-funds-list';
import { Button } from '../../components/button';
import { Affix, AffixPosition } from './affix';
import { ResponseTypes } from '../funds-generated';

export interface IManageFundsListingsProps {
	listings: IManageFundsListProps[];
	publish: () => void;
	selectedListing: number;
	listingSelectionChange?: (listingId: number) => void;
	discardChanges: (evt: React.MouseEvent<HTMLButtonElement>) => void;
	hasChanges?: boolean;
	redirectUrl?: string;
	isBeingReordered: boolean;
	isProcessingRequest: boolean;
	defaultFundToggleTooltip: string;
	disableAllListingsDropdownOption: boolean;
	canLoadMore: boolean;
	loadMoreUrl: string;
	isLoadingMore: boolean;
	loadMore: () => void;
}

export class ManageFundsListings extends React.Component<IManageFundsListingsProps, any> {

	private selectedListingId: string = null;

	renderListing(listing: IManageFundsListProps) {
		return <ManageFundsList  {...listing} key={listing.listingId} />;
	}

	renderFloatingStatusPanel() {
		if (this.props.hasChanges) {
			return (
				<Affix affixPosition={AffixPosition.Top}>
					<div className="status-panel">
						<label>You currently have unsaved listing changes</label>
						<div className="responsive-btn-group">
							<button type="button" className="btn btn-link" onClick={this.props.discardChanges}>Discard changes</button>
							<Button isProcessingRequest={this.props.isProcessingRequest}
								className="btn btn-ghost-white"
								onClick={this.props.publish}>Publish now</Button>
						</div>
					</div>
				</Affix>
			);
		}
		return null;
	}

	renderListingSelector() {
		const { disableAllListingsDropdownOption } = this.props;

		const options = this.props.listings.map(x => ({ id: `${x.listingId}`, listingName: x.listingName }));

		if (NewFeatures.ManageFundsPageOptimizations) {
			if (!disableAllListingsDropdownOption) {
				options.unshift({
					id: '',
					listingName: 'All listings'
				});
			}

			this.selectedListingId = `${this.props.selectedListing === null && options.length > 0
				? options[0].id
				: this.props.selectedListing}`;

			return <ListingSelectorWithLabel
				onChange={this.handleListingChange}
				selectedListingId={this.selectedListingId}
				availableListings={options} />;
		} else {
			options.unshift({
				id: '',
				listingName: 'All listings'
			});

			return <ListingSelectorWithLabel
				onChange={this.handleListingChange}
				selectedListingId={`${this.props.selectedListing === null ? '' : this.props.selectedListing}`}
				availableListings={options} />;
		}
	}

	render() {
		return (
			<div className="manage-funds-listings-control">
				{this.renderListingSelector()}
				<div className="row">
					<div className="col-md-12">
						<div className="panel panel-default">
							<div className="panel-heading">
								Fund listings
							</div>
							<div className="panel-body">
								{this.renderFloatingStatusPanel()}
								<div className={`fund-listing-container row ${this.props.isBeingReordered ? ' is-being-reordered' : ''}`}>
									{this.props.listings.filter(this.filterListings, this).map(this.renderListing, this)}
								</div>
								{
									NewFeatures.ManageFundsPageOptimizations && this.props.canLoadMore &&
									<div className="row">
										<div className="responsive-btn-group-centered">
											<Button isProcessingRequest={this.props.isLoadingMore}
												onClick={this.props.loadMore}
												className="btn btn-ghost">Load More</Button>
										</div>
									</div>
								}
							</div>
							<div className="panel-footer text-center">
								<div className="actions">
									<Button isProcessingRequest={this.props.isProcessingRequest}
										disabled={!this.props.hasChanges}
										onClick={this.props.publish}
										className="btn btn-default">Publish</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	private handleListingChange = (value: string) => {
		if (typeof this.props.listingSelectionChange === 'function') {
			const listingId = parseInt(value);
			this.props.listingSelectionChange(isNaN(listingId) ? null : listingId);
		}
	}

	private filterListings(listing: IManageFundsListProps): boolean {
		if (NewFeatures.ManageFundsPageOptimizations) {
			return this.selectedListingId === '' || this.selectedListingId === listing.listingId.toString();
		} else {
			return this.props.selectedListing === null || this.props.selectedListing === listing.listingId;
		}
	}
}
