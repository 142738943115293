import * as React from 'react';
import { observer } from 'mobx-react';
import { FormControlLabelled, FormControlType } from './form-control-labelled';

export interface IDateRange {
	from: Date;
	to: Date;
}

export interface IFormControlDateRangeLabelledProps {
	cssClassNames?: string;
	fromLabel?: string;
	fromInputName?: string;
	toLabel?: string;
	toInputName?: string;
	value: IDateRange;
	onChangeHandler: (dateRange: IDateRange) => void;
	options?: Pikaday.PikadayOptions;
}

@observer
export class FormControlDateRangeLabelled extends React.Component<IFormControlDateRangeLabelledProps, {}> {
	render() {
		const classNames = this.props.cssClassNames ? this.props.cssClassNames : ``;
		return (
			<div className={classNames}>
				<div className="date-group arrow-point-right">
					<FormControlLabelled label={this.props.fromLabel || 'From'}
						formControlProps={{
							name: this.props.fromInputName,
							formControlType: FormControlType.DatePicker,
							value: this.props.value.from,
							onChangeHandler: this.fromOnChangeHandler,
							options: this.props.options
						}} />

					<FormControlLabelled label={this.props.toLabel || 'To'}
						formControlProps={{
							name: this.props.toInputName,
							formControlType: FormControlType.DatePicker,
							value: this.props.value.to,
							onChangeHandler: this.toOnChangeHandler,
							options: {
								...this.props.options,
								minDate: this.props.value.from
							}
						}} />
				</div>
			</div>
		);
	}

	private fromOnChangeHandler = (dateFrom: Date) => {
		const dateRange = { from: dateFrom, to: this.getDateToValue(dateFrom) };
		this.props.onChangeHandler(dateRange);
	}

	private toOnChangeHandler = (dateTo: Date) => {
		const dateRange = { from: this.props.value.from, to: dateTo };
		this.props.onChangeHandler(dateRange);
	}

	private getDateToValue = (dateFrom: Date) => {
		return dateFrom && dateFrom > this.props.value.to
			? null
			: this.props.value.to;
	}
}
