import { DraftCampaignCardViewModel, ActiveCampaignCardViewModel, ClosedCampaignCardViewModel, ScheduledCampaignCardViewModel } from './campaigns-generated';

export type CampaignCardViewModel = DraftCampaignCardViewModel | ActiveCampaignCardViewModel | ClosedCampaignCardViewModel | ScheduledCampaignCardViewModel;

export type CampaignLabel = 'Draft' | 'Scheduled' | 'Live' | 'Closed';


export enum CampaignCardSection {
	Unsupported,
	Drafts,
	Published,
	Closed,
}
