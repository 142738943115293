import { querySelector } from './login-utils';
import { Models } from './login.generated';

export class MessageBox {
	messageBox: HTMLElement[];
	messageBoxText: HTMLElement[];

	constructor(initialMessage: Models.UserMessage) {
		this.messageBox = querySelector<HTMLElement>('[data-pp-login-alert]');
		this.messageBoxText = querySelector<HTMLElement>('[data-pp-login-alert-message]');

		if (initialMessage) {
			this.displayError(initialMessage);
		} else {
			this.clear();
		}
	}

	clear(): void {
		this.hide();
	}

	error(text: string) {
		this.messageBoxText.forEach(x => x.innerText = text);
		this.show();
	}

	htmlError(html: string) {
		this.messageBoxText.forEach(x => x.innerHTML = html);

		const messageBoxLinks = querySelector<HTMLElement>('[data-pp-login-alert-message] a');
		messageBoxLinks.forEach(x => x.classList.add('alert-link'));

		this.show();
	}

	displayError(message: Models.UserMessage) {
		if (message.IsSafeHtml) {
			this.htmlError(message.Message);
		} else {
			this.error(message.Message);
		}
	}

	private show() {
		this.messageBox.forEach(x => {
			x.classList.remove('hidden');
			x.scrollIntoView();
		});
	}

	private hide() {
		this.messageBox.forEach(x => x.classList.add('hidden'));
	}
}
