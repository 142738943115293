import * as React from 'react';
import { observer } from 'mobx-react';
import { TooltipComponent } from '../../components/tooltip-component';
import { responsiveHelper } from '../../helpers/responsive-helper';

@observer
export default class ConfigurationSectionTooltip extends React.Component<{ message: string }, {}> {
	render() {
		return <TooltipComponent className="info-tooltip" message={this.props.message} placement={(responsiveHelper.isXs || responsiveHelper.isSm) ? 'left' : 'right'} />;
	}
}
