import { observable, action, computed } from 'mobx';

import { ScheduleImportStepViewModel, ImportStep } from '../schedule-import-step-view-model';
import {
	ScheduleImportFormat,
	FileUploadViewModel,
	ScheduleImportUploadStatus,
	PaymentLabel,
	FileUploadRequest,
	OperationResult,
	FileParserMessage,
	FileUploadStatusResponse,
} from '../../schedule-import-generated';
import { ICancellablePromise, CancellablePromise } from '../../../utils/cancellable-promise';
import { getScheduleImportDataService, isScheduleImportAction, } from '../../schedule-import-data-service';
import { IImportFileUploader, UploadError, ImportFileUploader } from '../../../transaction-import/utils/file-uploader';

const completedUploadStatus = [
	ScheduleImportUploadStatus.Completed,
	ScheduleImportUploadStatus.ValidationError,
	ScheduleImportUploadStatus.ValidationWarning,
	ScheduleImportUploadStatus.Failed,
];

export class ScheduleImportFileUploadViewModel extends ScheduleImportStepViewModel {
	readonly cancelUrl: string;
	readonly backUrl: string;
	readonly paymentLabel: PaymentLabel;
	readonly showDonorsFile: boolean;
	readonly importId: number;
	readonly pollForUpdateTimeInMs: number = 1000;

	uploadStartTime: number;

	@observable transactionsFile: File;
	@observable donorsFile?: File;
	@observable uploadStatus: ScheduleImportUploadStatus;
	@observable parserMessages: FileParserMessage[];

	uploader: IImportFileUploader = new ImportFileUploader();

	uploadChain: ICancellablePromise<any>;

	constructor(model: FileUploadViewModel) {
		super(
			model.PaymentLabel,
			ImportStep.Upload,
			'Upload file',
			'Select your file to upload.'
		);

		this.importId = model.ImportId;
		this.cancelUrl = model.CancelUrl;
		this.backUrl = model.BackUrl;
		this.paymentLabel = model.PaymentLabel;
		this.showDonorsFile = model.ImportType === ScheduleImportFormat.PushpayAndDonors;
		this.uploadStatus = model.UploadStatus;
		this.parserMessages = model.ParserMessages;

		if (this.uploadStatus === ScheduleImportUploadStatus.Uploading) {
			this.uploadChain = this.startWaitForVerification()
				.then(this.handleVerificationResult)
				.catch(this.handleUploadError);
		}
	}

	@action.bound
	uploadFile() {
		this.clearAlert();
		this.uploadStatus = ScheduleImportUploadStatus.Uploading;
		this.startUploadTimer();

		let startFileUpload = null;
		if (this.donorsFile) {
			startFileUpload = this.startFileUpload(this.donorsFile, 'donors')
							.chainCancel(() => this.startFileUpload(this.transactionsFile, null));
		} else {
			startFileUpload = this.startFileUpload(this.transactionsFile, null);
		}

		this.uploadChain = startFileUpload
		.chainCancel(() => {
			this.reportSuccessfulUpload(this.transactionsFile);
			return this.startWaitForVerification();
		})
		.then(this.handleVerificationResult)
		.catch(this.handleUploadError);
	}

	@action.bound
	cancelImport() {
		if (this.uploadChain) {
			this.uploadChain.cancel();
		}
		this.startCancelImport();
	}

	@action.bound
	tryAgain() {
		this.actionInProgress = true;
		window.location.href = this.backUrl;
	}

	@action.bound
	goToNextStep() {
		this.actionInProgress = true;
		window.location.reload();
	}

	@action.bound
	updateTransactionsFile(file: File) {
		this.transactionsFile = file;
	}

	@action.bound
	updateDonorsFile(file: File) {
		this.donorsFile = file;
	}

	@action
	handleUploadError = (error) => {
		var message: string;
		if (error instanceof UploadError) {
			message = error.message;
		} else if (error instanceof Error) {
			message = error.message;
		} else {
			message = 'Unknown error';
		}
		this.uploadStatus = ScheduleImportUploadStatus.WaitingForUpload;
		this.showErrorAlert('Your file upload was unsuccessful. Please try again.');
		this.reportFailedUpload(this.transactionsFile, message);
	}

	private startUploadTimer() {
		this.uploadStartTime = window.performance.now();
	}

	private reportSuccessfulUpload(file: File) {
		this.reportUploadStatus(file, true);
	}

	private reportFailedUpload(file: File, message: string) {
		this.reportUploadStatus(file, false, message);
	}

	private reportUploadStatus(file: File, successful: boolean, message: string = null) {
		const uploadTimeInMs = window.performance.now() - this.uploadStartTime;

		getScheduleImportDataService().initRequest('reportUploadStatistics', {
			model: {
				ImportId: this.importId,
				FileName: file.name,
				FileSize: file.size,
				Successful: successful,
				Message: message,
				UploadTimeInMs: uploadTimeInMs,
			}
		});
	}

	private startCancelImport() {
		const unsubscribe = getScheduleImportDataService().subscribe(action => {
			if (!isScheduleImportAction(action, 'cancelImport')) {
				return;
			}

			if (action.type === 'request_init') {
				return;
			}

			if (action.type === 'request_success') {
				if (action.response.Result === OperationResult.Success) {
					window.location.href = this.cancelUrl;
				} else {
					this.showErrorAlert(action.response.ErrorMessage);
				}
			} else {
				this.showErrorAlert('Failed to cancel import');
			}
			unsubscribe();
		});
		getScheduleImportDataService().initRequest('cancelImport', {scheduleImportBundleId: this.importId});
	}

	private handleVerificationResult = (uploadResponse: FileUploadStatusResponse) => {
		this.uploadStatus = uploadResponse.UploadStatus;
		this.parserMessages = uploadResponse.ParserMessages;
		switch (this.uploadStatus) {
			case ScheduleImportUploadStatus.Failed:
				this.showErrorAlert('Your file upload was unsuccessful. Please try again.');
				break;
			case ScheduleImportUploadStatus.ValidationError:
				this.showErrorAlert('Please review rejected row details and try to upload the file again.');
				break;
			case ScheduleImportUploadStatus.ValidationWarning:
				this.showWarningAlert('Your file upload has completed, but returned with warnings.');
				break;
		}
	}

	private startFileUpload(file: File, tag: string) {
		return this.getFileUploadLink(file,tag)
				.chainCancel(link => this.uploader.uploadFile(file, link));
	}

	private getFileUploadLink(file: File, tag: string) {
		var promise = new CancellablePromise<string>((resolve, reject, onCancel) => {
			const unsubscribe = getScheduleImportDataService().subscribe(action => {
				if (!isScheduleImportAction(action, 'getUploadUrl')) {
					return;
				}

				if (action.type === 'request_init') {
					return;
				}

				if (action.type === 'request_success') {
					resolve(action.response.UploadLink);
				} else {
					reject('Failed to get upload link');
				}
				unsubscribe();
			});

			onCancel(() => {
				unsubscribe();
			});

			getScheduleImportDataService().initRequest(
				'getUploadUrl',
				{model: {
					ImportId: this.importId,
					FileName: file.name,
					FileSize: file.size,
					Tags: [tag],
				}});
		});

		return promise;
	}

	private startWaitForVerification() {
		return new CancellablePromise<FileUploadStatusResponse>((resolve, reject, onCancel) => {
			const unsubscribe = getScheduleImportDataService().subscribe(action => {
				if (!isScheduleImportAction(action, 'getImportUploadStatus')) {
					return;
				}

				if (action.type === 'request_init') {
					return;
				}

				if (action.type === 'request_success') {
					if (completedUploadStatus.indexOf(action.response.UploadStatus) !== -1) {
						unsubscribe();
						resolve(action.response);
					} else {
						setTimeout(
							() => getScheduleImportDataService().initRequest('getImportUploadStatus', {scheduleImportBundleId: this.importId}),
							this.pollForUpdateTimeInMs);
					}
				} else {
					unsubscribe();
					reject('Failed to get upload status');
				}
			});

			onCancel(() => {
				unsubscribe();
			});

			getScheduleImportDataService().initRequest('getImportUploadStatus', {scheduleImportBundleId: this.importId});
		});
	}
}
