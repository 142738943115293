import { runInAction } from 'mobx';
import { Task } from 'redux-saga';
import { take, fork, cancelled } from 'redux-saga/effects';
import { Channel } from '../../../Shared/utils/user-action-channel';
import { callInAction, ActionHandlers, AsyncActionHandler, ActionConcurrencyInstruction, Action } from '../../../Shared/utils/saga-utils';
import {
	getQboIntegrationDataService,
	QboIntegrationActionRequest,
	QboIntegrationActionResponse,
	QboIntegrationApiConfigActions,
} from '../qbo-saga-data-service';
import { reportError } from '../utils/error-utils';
import { QboIntegrationApiConfig } from '../qbo-integration-generated';

import { runSaga } from '../../utils/saga-utils';
import { isFunction } from '../../utils/is-function';

export interface IQboIntegrationSagaContext {
	readonly userActionChannel: Channel<any>;
	readonly mainViewModel: any;
	readonly dataService: QboSagaDataService;
	executingBlockingAction?: boolean;
}

function getUserActionName<TActionType extends Action>(userAction: TActionType): string | undefined {
	return (userAction as any).constructor.name;
}

export function runQboIntegrationSaga<TActionType extends Action>(
	sagaContext: IQboIntegrationSagaContext,
	actionHandlers: ActionHandlers<IQboIntegrationSagaContext, TActionType>,
	onSagaInit?: (context: IQboIntegrationSagaContext) => void) {
	runSaga({}, qboIntegrationSaga, sagaContext, actionHandlers, onSagaInit);
}

function* qboIntegrationSaga<TActionType extends Action>(context: IQboIntegrationSagaContext,
				actionHandlers: ActionHandlers<IQboIntegrationSagaContext, TActionType>, onSagaInit?: (context:IQboIntegrationSagaContext) => void): IterableIterator<any> {

	const { userActionChannel } = context;

	let currentAsyncHandler: AsyncActionHandler<IQboIntegrationSagaContext> | undefined;
	let currentAsyncTask: Task | undefined;

	if (isFunction(onSagaInit)) {
		yield onSagaInit(context);
	}

	while (true) {
		const userAction: TActionType = yield take(userActionChannel);
		try {
			const incomingActionHandler = actionHandlers.get(userAction);
			console.log('userAction', getUserActionName(userAction));

			if (currentAsyncHandler && currentAsyncTask && currentAsyncTask.isRunning()) {
				const instruction = actionHandlers.getInstructionForIncomingAction(currentAsyncHandler, incomingActionHandler);

				switch (instruction) {
					case ActionConcurrencyInstruction.AllowIncoming:
						console.log('Allowing incoming action', { running: currentAsyncHandler.actionType.name, incoming: incomingActionHandler.actionType.name });
						break;
					case ActionConcurrencyInstruction.CancelRunning:
						console.log('Cancelling running action', { running: currentAsyncHandler.actionType.name, incoming: incomingActionHandler.actionType.name });
						if (currentAsyncHandler.blocking) {
							context.executingBlockingAction = false;
						}
						currentAsyncTask.cancel();
						currentAsyncHandler = undefined;
						break;
					case ActionConcurrencyInstruction.BlockIncoming:
						console.log('Blocking incoming action', { running: currentAsyncHandler.actionType.name, incoming: incomingActionHandler.actionType.name });
						continue;
					default:
						throw new Error(`ActionConcurrencyInstruction[${instruction}] is not supported`);
				}
			}

			console.log('Starting action', { incoming: incomingActionHandler.actionType.name });
			if (incomingActionHandler.async) {
				currentAsyncHandler = incomingActionHandler;

				if (currentAsyncHandler.blocking) {
					context.executingBlockingAction = true;
				}

				currentAsyncTask = yield fork(function* () {
					try {
						yield callInAction(incomingActionHandler.handler, context, userAction);
					} catch (ex) {
						reportError(ex, {
							sagaRunningFork: true,
							sagaUserAction: getUserActionName(userAction),
						});
					} finally {
						if (!(yield cancelled())) {
							context.executingBlockingAction = false;
						}
					}
				});
			} else {
				runInAction(() => incomingActionHandler.handler(context, userAction));
			}
		} catch (ex) {
			reportError(ex, {
				sagaUserAction: getUserActionName(userAction),
			});
		}
	}
}

export type QboSagaDataService = {
	[x in keyof QboIntegrationApiConfigActions]: (request: QboIntegrationActionRequest<x>) => Iterator<QboIntegrationActionResponse<x>>
};

export function sagaDataService(): QboSagaDataService {
	return (Object.keys(QboIntegrationApiConfig.actions) as Array<keyof QboIntegrationApiConfigActions>).reduce((acc: any, actionKey: keyof QboIntegrationApiConfigActions) => {
		acc[actionKey] = function* (request: any) {
			const action: (...args: any[]) => PromiseLike<any> = getQboIntegrationDataService()[actionKey];
			return yield action(request);
		};

		return acc;
	}, {});
}
