import * as React from 'react';
import { EditMemberFormContainer, IEditMemberFormProps } from './edit-member-form-container';
import { getCommunityMemberDataService } from '../community-member-data-service';
import { MemberFormBody } from '../components/member-form-body';
import { MemberFormFields } from '../components/member-form-fields';
import { MemberFormFooter } from '../components/member-form-footer';
import { MemberFormHeading } from '../components/member-form-heading';

export class EditMemberDialog extends React.Component<IEditMemberFormProps, {}> {
	render() {
		const editMemberActionSubscriberFactory = getCommunityMemberDataService().getActionSubscriberFactory('updateCommunityMember');
		const organizationalGivingFeatureEnabled = this.props.enableFeatureOrganizationalGiving;
		return (
			<EditMemberFormContainer {...this.props} editMemberActionSubscriberFactory={editMemberActionSubscriberFactory}>
				<MemberFormHeading>Community member details</MemberFormHeading>
				<MemberFormBody>
					<MemberFormFields editing={true} organizationalGivingFeatureEnabled={organizationalGivingFeatureEnabled} />
				</MemberFormBody>
				<MemberFormFooter />
			</EditMemberFormContainer>
		);
	}
}
