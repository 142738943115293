import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { Footer, MobileMenuButton } from '@pushpay/shell';
import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming/lib/types/theme/types';
import { memo } from '@pushpay/types';
import { OnClickOutsideProvider, useForceUpdate } from '@pushpay/utils';

import { Header } from '../cap-layout/cap-header';
import { useMyOrganizations } from '../cap-layout/my-organizations';
import { LoggedInWebThemeProvider } from '../components/theming';
import { NavViewModel } from '../loggedinweb-generated';
import { SideBar } from './side-bar';
import { useMobile } from './use-mobile';


type ProductLogo = 'pushpay' | 'churchStaq' | 'parishStaq';

const useStyles = createUseStyles((theme: Theme) => ({
	'@global': {
		':focus, a:focus, :focus-visible': {
			outline: 'none',
		},
	},
	root: {},
	mobileButton: {
		position: 'fixed',
		top: theme.SPACING.XSMALL,
		left: theme.SPACING.XSMALL,
		zIndex: 1052,
	},
}));

type ShellProps = {
	vm: NavViewModel;
};

const ShellCore = memo(function Shell({ vm }: ShellProps) {
	const classes = useStyles(undefined, undefined);
	const headerContainer = useRef<HTMLDivElement | null>(null);
	const navigationContainer = useRef<HTMLDivElement | null>(null);
	const footerContainer = useRef<HTMLDivElement | null>(null);
	const forceUpdate = useForceUpdate();

	useEffect(function placeComponents() {
		headerContainer.current = document.querySelector('#shell-header');
		navigationContainer.current = document.querySelector('#shell-navigation');
		footerContainer.current = document.querySelector('#shell-footer');
		forceUpdate();
	}, []);

	useEffect(function showBodyContent() {
		document.querySelector('.body-content').classList.remove('hidden');
	}, []);

	const {
		NavInfo: navInfo,
		CurrentActiveOrganization: currentOrganization,
		AdministeredOrganizationInfo: { Items: organizationItems },
		ProductLogo,
	} = vm;

	const {
		Items: navItems,
		Footer: { Url: feedbackUrl },
	} = navInfo;

	const myOrganizations = useMyOrganizations(organizationItems, currentOrganization);
	const { isMobile, isOpenMobile, closeMobile, toggleMobile } = useMobile();
	const mobileButtonRef = useRef<HTMLButtonElement>(null);

	return (
		<>
			{headerContainer.current &&
				createPortal(
					<>
						<Header vm={vm} {...myOrganizations} />
						{isMobile && (
							<MobileMenuButton
								ref={mobileButtonRef}
								className={classes.mobileButton}
								label={isOpenMobile ? 'Close Menu' : 'Open Menu'}
								isMenuOpen={isOpenMobile}
								onClick={toggleMobile}
							/>
						)}
					</>,
					headerContainer.current
				)}
			{navigationContainer.current &&
				createPortal(
					<SideBar
						currentOrganization={currentOrganization}
						navItems={navItems}
						feedbackUrl={feedbackUrl}
						mobileButtonRef={mobileButtonRef}
						isOpenMobile={isOpenMobile}
						closeMobile={closeMobile}
					/>,
					navigationContainer.current
				)}
			{footerContainer.current && createPortal(
				<Footer
					logo={NewFeatures.DisplayTheCorrectPushpayOrStaqLogoInTheMerchantPortal ? ProductLogo as ProductLogo: undefined}
				/>,
			footerContainer.current)}
		</>
	);
});

export const Shell = (props: ShellProps) => {
	return (
		<BrowserRouter>
			<LoggedInWebThemeProvider>
				<OnClickOutsideProvider>
					<ShellCore {...props} />
				</OnClickOutsideProvider>
			</LoggedInWebThemeProvider>
		</BrowserRouter>
	);
};
