import { AjaxUtils } from './ajax-utils';

// For use in the rare case where we need to submit a POST request for a non-AJAX controller action
export function createNonAjaxPostService<TConfig extends { actions, defaultBaseUrl }>(apiConfig: TConfig): GenericExportService<TConfig> {
	const baseUrl = AjaxUtils.resolveBaseUrl(apiConfig.defaultBaseUrl);

	return Object.keys(apiConfig.actions)
		.reduce((acc, actionKey) => {
			const url = AjaxUtils.combineWithBasePath(apiConfig.actions[actionKey].url(), baseUrl);
			acc[actionKey as keyof GenericExportService<TConfig>] = (params: any) => {
				post(url, params);
			};
			return acc;
		}, {} as GenericExportService<TConfig>);
}

type GenericPostActions<TConfig extends { actions }> = TConfig['actions'];
type GenericPostActionRequest<TConfig extends { actions: { [key: string]: { request: any } } }, TActionKey extends keyof GenericPostActions<TConfig>>
	= GenericPostActions<TConfig>[TActionKey]['request'];
export type GenericExportService<TConfig extends { actions }> = {
	[x in keyof GenericPostActions<TConfig>]: (postData: GenericPostActionRequest<TConfig, x>) => void;
};

function post(path: string, params: any) {
	const antiForgeryTokenElement = document.querySelector('[name="__RequestVerificationToken"]') as HTMLInputElement;
	if (antiForgeryTokenElement) {
		params = { '__RequestVerificationToken': antiForgeryTokenElement.value, ...params };
	}

	const form = document.createElement('form');
	form.setAttribute('method', 'post');
	form.setAttribute('action', path);

	for (const key in params) {
		if (params.hasOwnProperty(key)) {
			const hiddenField = document.createElement('input');
			hiddenField.setAttribute('type', 'hidden');
			hiddenField.setAttribute('name', key);
			hiddenField.setAttribute('value', params[key]);

			form.appendChild(hiddenField);
		}
	}

	form.style.display = 'none';
	document.body.appendChild(form);
	form.submit();

	document.body.removeChild(form);
}
