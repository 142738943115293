import * as React from 'react';
import { IImportFileUploadStore } from './import-file-upload-store';
import { ImportFileUploadForm } from './import-file-upload-form';
import { AlertControl } from './components/alert-control';
import { observer } from 'mobx-react';
import { ImportStepHeader, ImportStep } from './components/import-step-header';
import { ImportStepDescription } from './components/import-step-description';

export interface ITransactionImportUploadProps {
	store: IImportFileUploadStore;
}

@observer
export class TransactionImportUpload extends React.Component<ITransactionImportUploadProps, {}> {
	render() {
		const {store}= this.props;
		const {isWaitingForUpload, isUploading, isValidating, hasUploaded, hasFailed, isInvalid, rejectedRows, navigationInProgress, percentComplete} = store.viewModel;
		const uploadHasError = hasFailed || isInvalid;
		const hasMessages = rejectedRows && rejectedRows.length > 0;
		const showUploadForm = isWaitingForUpload || hasFailed || isInvalid || (hasUploaded && hasMessages);

		return (
			<div>
				<AlertControl {...store.alertProps} />

				<ImportStepHeader currentStep={ImportStep.Upload} />

				<ImportStepDescription title="Upload CSV file" description="Select your transaction file to upload." />

				{showUploadForm && <ImportFileUploadForm viewModel={store.viewModel} />}

				{isUploading &&
					<div className="panel panel-plain text-center">
						<div className="panel-heading"><h2>Your file is uploading</h2></div>
						<div className="panel-body">
							<p>
								You will need to stay on this page to allow the upload to finish successfully. This may take several minutes.
								Leaving this page will cancel uploading this file.
							</p>
							{this.renderProgressBar(percentComplete)}
						</div>
						<div className="panel-footer">
							<button className="btn btn-cancel" id="cancel" onClick={store.viewModel.handleCancelUpload}>Cancel</button>
						</div>
					</div>
				}

				{isValidating &&
					<div className="panel panel-plain text-center">
						<div className="panel-heading">
							<img className="loading-box-img" src="/Content/LoggedInWeb/assets/images/loader.png" alt={'Loading'} />
						</div>
						<div className="panel-body">
							Your file has finished uploading. Please wait while we verify your file.
						</div>
						<div className="panel-footer">
							<button className="btn btn-cancel" id="cancel" onClick={store.viewModel.handleCancelImport} disabled={navigationInProgress}>Cancel</button>
						</div>
					</div>
				}

				{hasUploaded && !uploadHasError && !hasMessages &&
					<div className="panel panel-plain text-center">
						<div className="panel-heading"><h2>Your file has finished uploading</h2></div>
						<div className="panel-footer">
							<button className="btn btn-default" id="next" onClick={store.viewModel.handleNext} disabled={navigationInProgress}>Next</button>
						</div>
					</div>
				}
			</div>
		);
	}

	renderProgressBar(percentComplete: number) {
		const progressBarStyle: React.CSSProperties = {
			width: `${percentComplete}%`
		};

		return (
			<div className="progress">
				<div className="progress-bar active" role="progressbar"
					aria-valuenow={percentComplete} aria-valuemin={0} aria-valuemax={100} style={progressBarStyle}>
				</div>
			</div>
		);
	}
}
