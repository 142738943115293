import * as React from 'react';
import { IFormControlCommonProps } from './form-control-common-props';
import { MultiSelectItem } from '../../loggedinweb-generated';
import { MultiSelect } from '../multi-select/multi-select';
import { MultiSelectStore } from '../multi-select/multi-select-store';
import { getElementId } from '../form-controls/form-control-utils';
import { ArrayHelper } from '../../helpers/arrayhelper';

export interface IFormControlMultiSelectProps extends IFormControlCommonProps {
	items: MultiSelectItem[];
	onChangeHandler: (selectedValues: string[]) => void;
	selectedValues?: string[];
	acceptanceTestTargetId?: string;
}

export class FormControlMultiSelect extends React.Component<IFormControlMultiSelectProps> {
	private multiSelectStore: MultiSelectStore;

	constructor(props) {
		super(props);

		const { items, onChangeHandler, selectedValues } = props;

		this.multiSelectStore = new MultiSelectStore({ Items: items, SelectedValues: selectedValues }, null, onChangeHandler);
	}

	render() {
		const { uniqueSuffix, name, acceptanceTestTargetId } = this.props;

		return <MultiSelect
			multiSelectStore={this.multiSelectStore}
			fieldName={name}
			id={getElementId(name, uniqueSuffix)}
			acceptanceTestTargetId={acceptanceTestTargetId}
		/>;
	}

	UNSAFE_componentWillReceiveProps(nextProps: IFormControlMultiSelectProps) {
		const { selectedValues: currentValues } = this.props;
		const { selectedValues } = nextProps;

		if (currentValues && currentValues.length && selectedValues && !selectedValues.length) {
			this.multiSelectStore.reset();
		}

		if(!ArrayHelper.equals(currentValues, selectedValues)) {
			this.multiSelectStore.setSelectedItems(selectedValues);
		}
	}
}
