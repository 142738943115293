import * as React from 'react';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import { IMaskedInputProps } from 'react-text-mask';
import { ExistingAchBankAccountPaymentMethod } from './payment-entry-model';
import { FormControlLabelled, FormControlType } from '../../../components/form-controls/form-control-labelled';
import { IIconInputGroupProps, IconInputGroupPosition } from '../../../components/icon-input-group';
import { MaskedInputHelper } from '../../../helpers/masked-input-helper';
import { injectAnalytics, recordEvent, WithAnalytics } from '../../../analytics';

@injectAnalytics
@observer
export class PaymentMethodExistingAch extends React.Component<WithAnalytics & {
	existingAchBankAccount: ExistingAchBankAccountPaymentMethod;
}, {}> {
	static readonly accountNumberPlaceholders: any[] = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

	render() {
		const { existingAchBankAccount: { confirmedAccountNumberInput } } = this.props;
		const inputGroupOptions: IIconInputGroupProps = {
			svg: 'ach',
			title: 'Ach',
			position: IconInputGroupPosition.Left,
			width: 32,
			height: 24,
			transparent: true
		};

		return (
			<div className="vt-payment-method">
				<FormControlLabelled label="Confirm account numbers last 4 digits"
					formControlProps={{
						formControlType: FormControlType.Text,
						name: 'confirmAccountNumber',
						inputGroupOptions: inputGroupOptions,
						maskOptions: this.maskOptions,
						type: 'text',
						value: confirmedAccountNumberInput,
						onMouseUpHandler: MaskedInputHelper.setCursorToFirstPlaceholder,
						onFocusHandler: MaskedInputHelper.setCursorToFirstPlaceholder,
						onChangeHandler: this.handleConfirmAccountNumberChange,
						...(NewFeatures.SetupPinpointAnalytics && { onBlurHandler: this.handleConfirmAccountNumberBlur }),
						validationRules: this.confirmedAccountNumberValidationRules,
						acceptanceTestTargetId: 'confirm account number',
					}} />
			</div>
		);
	}

	private handleConfirmAccountNumberBlur = () => {
		const { analytics, existingAchBankAccount: { confirmedAccountNumber } } = this.props;
		if (analytics && confirmedAccountNumber) {
			const { feature, subFeature } = analytics;
			recordEvent({
				feature,
				subFeature,
				eventTypeLabel: 'completePaymentMethod',
				attributes: { paymentMethodType: 'existingACH' }
			});
		}
	}

	private handleConfirmAccountNumberChange = (event: React.FormEvent<HTMLInputElement>) => {
		this.props.existingAchBankAccount.updateConfirmedAccountNumberInput(event.currentTarget.value);
	}

	@computed
	private get maskedAccountNumbers() {
		const maskedAccountNumbersLength = this.props.existingAchBankAccount.accountNumberLength - 4;
		return new Array<string>(maskedAccountNumbersLength).fill(`\u25CF`);
	}

	@computed
	private get maskOptions(): IMaskedInputProps {
		return {
			mask: this.maskedAccountNumbers.concat(PaymentMethodExistingAch.accountNumberPlaceholders),
			guide: true,
			showMask: true
		};
	}

	@computed
	private get confirmedAccountNumberValidationRules() {
		const confirmedAccountNumberValidationRegex = `^${this.maskedAccountNumbers.concat(PaymentMethodExistingAch.accountNumberPlaceholders.map(x => x.source)).join(``)}$`;

		return {
			regex: {
				errorMessage: `Please enter the account's last 4 digits`,
				parameters: {
					pattern: confirmedAccountNumberValidationRegex
				}
			}
		};
	}
}

