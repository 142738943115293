import * as React from 'react';
import { observer } from 'mobx-react';
import { FundMapping } from '../../mapping-form/qbo-funds-mapping-info-view-model';
import {
	FormControlSelect,
	IFormControlSelectOptionProps
} from '../../../../components/form-controls/form-control-select';
import { IValidationRules } from '../../../../validation/validation-rules';
import { ValidationMessage } from '../../../../components/form-controls/validation-message';
import { QboFundsMappingInfoViewModel } from '../../mapping-form/qbo-funds-mapping-info-view-model';

import * as styles from './styles.less';
import { QboPanelContainer } from '../panel-container';
import { MappingsSetupProgress } from '../../../qbo-integration-generated';

export interface FundsMappingProps {
	vm: QboFundsMappingInfoViewModel;
	isProcessingRequest: boolean;
	cancelAction: () => void;
}

@observer
export class FundsMapping extends React.Component<FundsMappingProps> {
	render() {
		const {
			vm: {
				fundMappings,
				isLocationsShown,
				isClassesEnabled,
				incomeAccounts,
				classes,
				locations,
				paymentLabel,
				activeStep,
				setStepAsActive,
			},
			isProcessingRequest,
			cancelAction,
		} = this.props;
		return(
			<QboPanelContainer
				title="3. Match funds"
				currentStep={activeStep}
				activeOnStep={MappingsSetupProgress.Funds}
				editButtonAcceptanceTestTargetId="funds-mapping-edit"
				isProcessingRequest={isProcessingRequest}
				setStepAsActive={setStepAsActive}
				cancelAction={cancelAction}>
					<p className={styles.introParagraph}>In order to reduce manual effort importing {paymentLabel.NounLowerCase} totals into QuickBooks Online,
						please match Pushpay funds to accounts, classes and locations. If you'd like to edit or create new funds, you can do
						that in the manage funds page.</p>
					<div className={styles.mappingForm}>
						<div className={styles.mappingRow}>
							<div className={`${styles.fundName} ${styles.columnHeader}`}>Pushpay funds</div>
							<div className={`${styles.selectField} ${styles.columnHeader}`}>Match to QuickBooks Accounts</div>
							{
								isClassesEnabled &&
								<div className={`${styles.selectField} ${styles.columnHeader}`}>Match to QuickBooks Classes <span className={styles.optional}>(Optional)</span></div>
							}
							{
								isLocationsShown &&
								<div className={`${styles.selectField} ${styles.columnHeader}`}>Match to QuickBooks Locations <span className={styles.optional}>(Optional)</span></div>
							}
						</div>
						{
							fundMappings.map(mapping =>
								<MappingRow
									key={mapping.fundId}
									mapping={mapping}
									accountOptions={incomeAccounts}
									classOptions={classes}
									locationOptions={locations}
									isLocationsEnabled={isLocationsShown}
									isClassesEnabled={isClassesEnabled} />
							)
						}
				</div>
			</QboPanelContainer>
		);
	}
}

export interface MappingRowProps {
	mapping: FundMapping;
	isLocationsEnabled: boolean;
	isClassesEnabled: boolean;
	accountOptions: IFormControlSelectOptionProps[];
	classOptions: IFormControlSelectOptionProps[];
	locationOptions: IFormControlSelectOptionProps[];
}

@observer
class MappingRow extends React.Component<MappingRowProps> {
	render() {
		const { mapping: { fundName, fundId, fundCode, accountId, classId, locationId },
			accountOptions, classOptions, isClassesEnabled, locationOptions, isLocationsEnabled } = this.props;

		const accountValidationRules: IValidationRules = {
			required: { errorMessage: 'Please select a matching Account' },
		};

		const accountDropdownName = `Fund_Account_${fundId}`;
		return(
			<div className={styles.mappingRow}>
				<div className={styles.fundName}>{fundName} { fundCode ? <span className={styles.fundCode}>({fundCode})</span> : null }</div>
				<div className={styles.selectField}>
					<label htmlFor={`account-${fundId}`} className={styles.hiddenLabel}>QuickBooks account for {fundName}</label>
					<FormControlSelect
						name={accountDropdownName}
						value={accountId}
						Options={accountOptions}
						onChangeHandler={this.accountChanged}
						validationRules={accountValidationRules}
						placeholder="Select an account"/>
					<ValidationMessage elementName={accountDropdownName}/>
				</div>
				{ isClassesEnabled && <div className={styles.selectField}>
					<label htmlFor={`class-${fundId}`} className={styles.hiddenLabel}>QuickBooks class for {fundName}</label>
					<FormControlSelect
						name={`class-${fundId}`}
						value={classId}
						Options={classOptions}
						onChangeHandler={this.classChanged}
						placeholder="Select a class"/>
				</div> }
				{ isLocationsEnabled && <div className={styles.selectField}>
					<label htmlFor={`location-${fundId}`} className={styles.hiddenLabel}>QuickBooks location for {fundName}</label>
					<FormControlSelect
						name={`location-${fundId}`}
						value={locationId}
						Options={locationOptions}
						onChangeHandler={this.locationChanged}
						placeholder="Select a location"/>
				</div> }
			</div>
		);
	}

	accountChanged = (e: React.FormEvent<HTMLSelectElement>) => {
		this.props.mapping.setAccountId(e.currentTarget.value);
	}

	classChanged = (e: React.FormEvent<HTMLSelectElement>) => {
		this.props.mapping.setClassId(e.currentTarget.value);
	}

	locationChanged = (e: React.FormEvent<HTMLSelectElement>) => {
		this.props.mapping.setLocationId(e.currentTarget.value);
	}
}
