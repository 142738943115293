import { MessageBox } from './message-box';
import { LoginTabs } from './login-tabs';
import { EmailPasswordForm } from './form-email-password';
import { Models } from './login.generated';

export class LoginPage {
	private adminForm: EmailPasswordForm;
	private loginTabs: LoginTabs;

	constructor(clientSideTestData: Models.LoginPageClientSideTestData) {
		const messageBox = new MessageBox(clientSideTestData.InitialErrorMessage);
		this.loginTabs = new LoginTabs(messageBox);
		this.adminForm = new EmailPasswordForm(Models.LoginView.AdminEmailAndPassword, this.loginTabs, messageBox);
	}
}

export function init(clientSideTestData: Models.LoginPageClientSideTestData) : void {
	// tslint:disable-next-line:no-unused-expression
	new LoginPage(clientSideTestData);
}
