import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { injectAnalytics, recordEvent, WithAnalytics } from '../../../analytics';
import { MainEvents } from '../../state/main';
import { Button } from '../../../../Shared/components/button/button';
import { ConfigurationStep, configurationSteps } from '../../giving-statements-view-model';
import { GenerateStatementsModal } from '../../components/generate-statements-modal';
import { MachineContext, machineContextKey } from '../../../../Shared/state-machine/saga-state-machine';
import { Match } from '../../../../Shared/state-machine/match';
import { Panel, PanelBody, PanelFooter, PanelType } from '../../../components/panel';
import { PreviewPageEvents, PreviewPageStates } from '../../state/preview-page';
import { PreviewPageViewModel } from './preview-page-view-model';
import { ProgressIndicator } from '../../components/progress-indicator/progress-indicator';
import { StepStatusIndicator } from '../../../components/step-status';
import { MatchModal } from '../../../components/match-modal';
import { DeleteStatementsButton } from '../../components/delete-statements-button';
import { DeleteStatementsEvents } from '../../state/delete-statements-states';
import * as rootStyles from '../../app.less';
import * as previewStyles from './preview-page.less';

export interface IPreviewPageProps {
	machineContext?: MachineContext;
	vm: PreviewPageViewModel;
}

@injectAnalytics
@inject(machineContextKey)
@observer
export class PreviewPage extends React.Component<WithAnalytics & IPreviewPageProps> {
	private iframeRef: HTMLIFrameElement;

	render() {
		const {
			machineContext,
			vm: {
				mainViewModel,
				percentComplete,
			}
		} = this.props;

		const { CommunityMemberTotal: totalStatements, DisabledForCampusOrg: disabledForCampusOrg } = mainViewModel.communityMemberFundStats;

		const shouldButtonBeDisabled = NewFeatures.TemporarilyDisableDeleteStatementsButtonOnGeneratePage;
		let disableButton = shouldButtonBeDisabled ? percentComplete < 1 : false;

		return (
			<div className={rootStyles.pageContainer}>
				<StepStatusIndicator
					currentStep={ConfigurationStep.Generate}
					steps={configurationSteps}
				/>

				<Match state={PreviewPageStates.STATEMENTS_GENERATING}>
					<ProgressIndicator
						totalStatements={totalStatements}
						percentComplete={percentComplete}
					/>
				</Match>

				<h1 className={rootStyles.pageTitle}>
					Generate your statements
					{
						!mainViewModel.currentConfiguration.hasBeenPublished &&
						<DeleteStatementsButton className={previewStyles.deleteButton} onDelete={this.deleteStatements} disableButton={disableButton} />
					}
				</h1>

				<p className={rootStyles.pageDescription}>
					{`This giving statement contains example information in order to give you an idea of how your statements will look. They have been designed to fit the US Window Envelope #10. When you've finished reviewing this example, click the Next button to proceed.`}
				</p>

				<Panel panelType={PanelType.DEFAULT}>
					<PanelBody>
						<iframe className={previewStyles.preview} src={mainViewModel.previewUri} ref={this.setIframeRef} />
					</PanelBody>
					<PanelFooter extraClassNames="panel-footer-btn-group">
						<Button className="btn btn-cancel" onClick={this.onBackClick}>Back</Button>

						<Button
							className="btn btn-default"
							onClick={this.onGenerateClick}
							isLoading={machineContext.matchesState(PreviewPageStates.STATEMENTS_GENERATING)}>
							{machineContext.matchesState(PreviewPageStates.STATEMENTS_GENERATING) ? 'Generating' : 'Next'}
						</Button>
					</PanelFooter>
				</Panel>

				<MatchModal state={PreviewPageStates.CONFIRMATION_DIALOG_ACTIVE}>
					<GenerateStatementsModal
						totalStatements={totalStatements}
						onCancelClick={this.onCancelClick}
						onGenerateClick={this.onConfirmClick}
						disabledForCampusOrg={disabledForCampusOrg}
					/>
				</MatchModal>
			</div>
		);
	}

	componentDidMount() {
		const { vm } = this.props;

		vm.setIframeRef(this.iframeRef);
	}

	private setIframeRef = (element: HTMLIFrameElement) => {
		this.iframeRef = element;
	}

	private onBackClick = () => {
		this.props.machineContext.transition(MainEvents.LOAD_PREVIOUS_VIEW);
	}

	private onGenerateClick = () => {
		if (NewFeatures.SetupPinpointAnalytics && this.props.analytics) {
			const { feature, subFeature } = this.props.analytics;
			recordEvent({ feature, subFeature, eventTypeLabel: 'previewNext' });
		}

		this.props.machineContext.transition(PreviewPageEvents.SHOW_CONFIRMATION_DIALOG);
	}

	private onCancelClick = () => {
		this.props.machineContext.transition(PreviewPageEvents.HIDE_CONFIRMATION_DIALOG);
	}

	private onConfirmClick = () => {
		if (NewFeatures.SetupPinpointAnalytics && this.props.analytics) {
			const { feature, subFeature } = this.props.analytics;
			recordEvent({ feature, subFeature, eventTypeLabel: 'generate' });
		}

		this.props.machineContext.transition(PreviewPageEvents.BEGIN_STATEMENT_GENERATION);
	}

	private deleteStatements = () => {
		this.props.machineContext.transition(DeleteStatementsEvents.DELETE_START);
	}
}
