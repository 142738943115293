import * as React from 'react';
import { SvgWrapper } from '../svg-wrapper';

import * as styles from './tile.less';
export interface ITileProps {
	url: string;
	// string for an svg | JSX.Element for custom content
	graphic: string | JSX.Element;
	title: string;
	content: string;
	buttonText: string;
	ribbonText?: string;
}

export class Tile extends React.Component<ITileProps> {
	render() {
		const { url, graphic, title, content, buttonText, ribbonText } = this.props;

		return (
			<div className={styles.tile}>
				<a href={url} className={styles.tileLink}>
					{typeof graphic === 'string'
						? (
							<div className={styles.tileIcon}>
								<SvgWrapper svg={graphic} />
							</div>
						) : graphic
					}
					<h2>{title}</h2>
					<p className={styles.tileContent}>
						{content}
					</p>
					<span className={`btn btn-ghost ${styles.tileButton}`}>{buttonText}</span>
				</a>
				{ribbonText && <div className={styles.ribbon}><div className={styles.ribbonContent}>{ribbonText}</div></div>}
			</div>
		);
	}
}

