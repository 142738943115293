import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { CSSTransitionGroup } from 'react-transition-group-v1';
import { PaymentLabel } from '../../virtual-terminal/virtual-terminal-generated';
import { AddressField } from './address-field';
import { CountryData } from '../../loggedinweb-generated';
import { CountryField } from './country-field';
import { CountryStateField } from './country-state-field';
import { Fragment } from '../../../Shared/components/fragment';
import { MemberFormField } from './member-form-field';
import { MemberFormViewModel } from '../view-models/member-form';
import { Models, ModelMetadata } from '../community-member-generated';
import { TypeField } from './type-field';
import { ValidationSummary } from '../../components/form-controls/validation-summary';
import * as styles from './member-form-fields.less';

const { FirstName, LastName, EmailAddress, MobileNumber, Address } =  ModelMetadata.EditCommunityMemberRequestModel;

// Since OrganizationName and FirstName will be saved to the same field in the back end, it is hard to define both fields in the generated model,
// so define OrganizationName here separately as a workaround.
const OrganizationName = {
	propertyName: 'OrganizationName',
	displayName: 'Organization name',
	validationRules: {
		length: {
			errorMessage: 'The field Organization name must be a string with a maximum length of 100.',
			parameters: {
				max: 100,
			},
		},
		required: {
			errorMessage: 'The Organization name field is required.',
		},
	},
};
const { CommunityMemberType } = Models;

interface IMemberFormFieldsProps {
	memberFormViewModel?: MemberFormViewModel;
	editing?: boolean;
	paymentLabel?: PaymentLabel;
	organizationalGivingFeatureEnabled: boolean;
}

@inject('memberFormViewModel')
@observer
export class MemberFormFields extends React.Component<IMemberFormFieldsProps> {
	render () {
		const { request, errorMessages, errorSummaryTitle } = this.props.memberFormViewModel;
		const [ country ] = CountryData.filter(x => x.countryCode === request.Address.Country);
		const countryStates = country && country.subRegions;

		return (
			<CSSTransitionGroup
				component="div"
				className={styles.communityMemberTypeTransitionWrapper}
				transitionName="community-member-type-transition"
				transitionEnterTimeout={200}
				transitionLeaveTimeout={200}>
				<div key={request.CommunityMemberType}>
					<ValidationSummary validationErrors={errorMessages} customTitle={errorSummaryTitle} />
					{this.renderOrgTypeRowOrFirstNameLast(this.props.organizationalGivingFeatureEnabled)}
					<div className="row">
						<MemberFormField
							metadata={EmailAddress}
							label={EmailAddress.displayName}
							cssClassNames="col-sm-6"
							acceptanceTestTargetId="email address"
						/>
						<MemberFormField
							metadata={MobileNumber}
							label={MobileNumber.displayName}
							ignorePanLikeValidation={true}
							cssClassNames="col-sm-6"
							acceptanceTestTargetId="mobile number"
						/>
					</div>
					<div className="row">
						<AddressField
							metadata={Address.modelMetadata.Line1}
							label={Address.displayName}
							acceptanceTestTargetId="address line 1"
						/>
						<AddressField
							metadata={Address.modelMetadata.Line2}
							acceptanceTestTargetId="address line 2"
						/>
						<AddressField
							metadata={Address.modelMetadata.City}
							acceptanceTestTargetId="city"
						/>
						{countryStates
							? <CountryStateField
								selectedState={request.Address.State}
								countryStates={countryStates}
								handleCountryStateChange={this.handleCountryStateChange}
								acceptanceTestTargetId="state"
							/>
							: <AddressField
								metadata={Address.modelMetadata.State}
								cssClassNames="col-sm-9"
								acceptanceTestTargetId="state"
							/>
						}
						<AddressField
							metadata={Address.modelMetadata.Postcode}
							cssClassNames="col-xs-6 col-sm-3"
							acceptanceTestTargetId="post code"
						/>
						<CountryField
							selectedCountry={request.Address.Country}
							handleCountryChange={this.handleCountryChange}
							acceptanceTestTargetId="country"
						/>
					</div>
				</div>
			</CSSTransitionGroup>
		);
	}

	private renderOrgTypeRowOrFirstNameLast(organizationalGivingFeatureEnabled: boolean) {
		if (organizationalGivingFeatureEnabled) {
			return this.renderOrganizationTypeRow();
		}
		return this.renderFirstNameLastNameRow();
	}

	private renderOrganizationTypeRow = () => {
		const { editing, memberFormViewModel, paymentLabel } = this.props;
		const { request } = memberFormViewModel;

		return (
			<Fragment>
				<div className="row">
					<TypeField
						selectedType={request.CommunityMemberType}
						handleTypeChange={this.handleTypeChange}
						acceptanceTestTargetId="type"
						disabled={editing}
						paymentLabel={paymentLabel}
					/>
				</div>
				{request.CommunityMemberType === CommunityMemberType.Individual
					? this.renderFirstNameLastNameRow()
					: <div className="row">
						<MemberFormField
							metadata={OrganizationName}
							label={OrganizationName.displayName}
							cssClassNames="col-sm-12"
							acceptanceTestTargetId="first name"
							currentValue={this.currentOrganizationNameValue}
							handleChange={this.handleOrganizationNameChange}
						/>
					</div>
				}
			</Fragment>
		);
	}

	private get currentOrganizationNameValue() {
		return this.props.memberFormViewModel.request[FirstName.propertyName];
	}

	private handleOrganizationNameChange = (event: React.FormEvent<HTMLInputElement>) => {
		const value = event.currentTarget.value;
		this.props.memberFormViewModel.updateRequest(FirstName.propertyName, value);
	}

	private renderFirstNameLastNameRow = () => (
		<div className="row">
			<MemberFormField
				metadata={FirstName}
				label={FirstName.displayName}
				cssClassNames="col-sm-6 member-form-input-first-name"
				acceptanceTestTargetId="first name"
			/>
			<MemberFormField
				metadata={LastName}
				label={LastName.displayName}
				cssClassNames="col-sm-6"
				acceptanceTestTargetId="last name"
			/>
		</div>
	)

	private handleTypeChange = (typeAsString: string) => {
		const { memberFormViewModel } = this.props;
		const { request } = memberFormViewModel;
		const type = +(typeAsString);

		if (type === CommunityMemberType.Organization) {
			// If the user switches to `Organization`, concatenate first and last name together, and set that as the first name.
			memberFormViewModel.updateRequest(FirstName.propertyName, [request.FirstName, request.LastName].join(' '));
			memberFormViewModel.updateRequest(LastName.propertyName, '');
		} else if (type === CommunityMemberType.Individual) {
			// If the user switches to `Individual`, split the name of the organization, and set the last string as the last
			// name, and the rest as the first.
			const names = request.FirstName.split(' ');

			memberFormViewModel.updateRequest(LastName.propertyName, names.pop());
			memberFormViewModel.updateRequest(FirstName.propertyName, names.join(' '));
		}

		memberFormViewModel.updateTypeState(type);
	}

	private handleCountryStateChange = (ev: React.FormEvent<HTMLSelectElement>) => {
		this.props.memberFormViewModel.updateCountryState(ev.currentTarget.value);
	}

	private handleCountryChange = (ev: React.FormEvent<HTMLSelectElement>) => {
		this.props.memberFormViewModel.updateCountryState('');
		this.props.memberFormViewModel.updateCountry(+(ev.currentTarget.value));
	}
}
