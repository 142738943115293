import * as React from 'react';
import * as styles from './convert-community-member-to-org-link.less';
import { observer } from 'mobx-react';
import { Tooltip, TooltipContent } from '../../components/tooltip/tooltip';
import { SvgWrapper } from '../../components/svg-wrapper';
import { Button } from '../../../Shared/components/button/button';
import { ModalDialogCommander } from '../../components/modal-dialog-commander';
import { ConvertCommunityMemberToOrgModal } from './convert-community-member-to-org-modal';
import { ConvertCommunityMemberToOrgModalViewModel } from '../view-models/convert-community-member-to-org-modal-viewmodel';

export interface IConvertCommunityMemberToOrgLink {
	className?: string;
}

@observer
export class ConvertCommunityMemberToOrgLink extends React.Component<IConvertCommunityMemberToOrgLink, { visible: boolean }> {
	constructor(props) {
		super(props);
		this.state = { visible: false };
	}

	render() {
		const { className } = this.props;
		return (
			<div className={className}>
				<Button className={`btn-link ${styles.conversionLink}`} onClick={this.showConvertToOrganizationModal}>Change member type</Button>
				<Tooltip
					visible={this.state.visible}
					label="more information"
					onVisibleChange={this.handleVisibleChange}
				>
					<SvgWrapper svg="icon-info" className={`icon tooltip-icon ${styles.conversionLinkTooltipIcon}`} />
					<TooltipContent>
						<div>Change your existing community members into organizations.</div>
					</TooltipContent>
				</Tooltip>
			</div>
		);
	}

	private handleVisibleChange = (visible: boolean) => {
		this.setState({ visible: visible });
	}

	private showConvertToOrganizationModal() {
		ModalDialogCommander.showReactDialog(React.createElement(ConvertCommunityMemberToOrgModal, { vm: new ConvertCommunityMemberToOrgModalViewModel() }));
	}
}
