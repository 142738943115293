import * as React from 'react';
import {ResponseTypes} from './funds-generated';
import {SortOrder} from './components/sort-order';
import FundListViewModel = ResponseTypes.FundListViewModel;
import FundListItemViewModel = ResponseTypes.FundListItemViewModel;
import FundTotal = ResponseTypes.FundTotal;
import {ModalDialogCommander} from '../components/modal-dialog-commander';
import {DeleteFundModalDialog} from './components/common-delete-fund-dialog';
import {AjaxPoller} from './utils/ajax-helper';
import {FundList} from './components/fund-list';
import {LocalStorageHelper} from '../helpers/localstoragehelper';

export interface IFundListViewProps {
	model: FundListViewModel;
}

export interface IFundListViewState {
	fundItems: FundListItemViewModel[];
	isOpenTabVisible: boolean;
	sortOrder: SortOrder;
	fundTotalsMessage: string;
	fundTotalsUpdated: boolean;
}

export class FundListView extends React.Component<IFundListViewProps, IFundListViewState> {
	private pageFundListSortOrderKey = 'pageFundListSortOrder';

	constructor(props) {
		super(props);

		const initialSortOrder = this.getStoredSortOrder() || SortOrder.AToZ;

		this.state = {
			fundItems: this.props.model.InitialFunds,
			isOpenTabVisible: true,
			sortOrder: initialSortOrder,
			fundTotalsMessage: this.props.model.FundTotalsMessage,
			fundTotalsUpdated: false
		};
		this.tabVisibilityChange = this.tabVisibilityChange.bind(this);
		this.sortOrderChange = this.sortOrderChange.bind(this);
		this.deleteFund = this.deleteFund.bind(this);
		this.updateFundTotals = this.updateFundTotals.bind(this);

		this.pollForFundTotals();
	}

	pollForFundTotals() {
		if (!this.props.model.PollForUpdatedFundTotals) {
			return;
		}
		const url = this.props.model.FundTotalsUrl;
		const ajaxPoller = new AjaxPoller(url, this.updateFundTotals, null, 60, 500);
		ajaxPoller.poll();
	}

	updateFundTotals(fundTotals: FundTotal[]) {
		const fundItems = this.state.fundItems.map(fundItem => {
			const fundTotal = fundTotals.find(x => x.Key === fundItem.Key);
			if (fundTotal && fundItem.Total !== fundTotal.Total) {
				return { ...fundItem, Total: fundTotal.Total };
			}

			return fundItem;
		});

		this.setState({
			fundItems,
			fundTotalsMessage: 'Fund totals are up to date',
			fundTotalsUpdated: true,
		});
	}

	tabVisibilityChange(isOpenTabVisible: boolean) {
		this.setState({ isOpenTabVisible });
	}

	sortOrderChange(newOrder: SortOrder) {
		this.saveSortOrderAsync(newOrder);
		this.setState({ sortOrder: newOrder });
	}

	deleteFund(key: string, deleteUrl: string) {
		return ModalDialogCommander.showReactDialog(<DeleteFundModalDialog urlDelete={deleteUrl} fundDelete={() => {
			this.setState({ fundItems: this.state.fundItems.filter(x => x.Key !== key) });
			return jQuery.Deferred().resolve().promise();
		} } />);
	}

	sortAndFilterItems() {
		return this.state.fundItems
			.filter(x => x.IsOpen === this.state.isOpenTabVisible)
			.sort((a, b) => SortOrder.sortFund(a, b, this.state.sortOrder));
	}

	render() {
		return (
			<FundList
				tabVisibilityChange={this.tabVisibilityChange}
				isOpenTabVisible={this.state.isOpenTabVisible}
				showFundTotalsMessage={this.props.model.PollForUpdatedFundTotals}
				sortOrder ={this.state.sortOrder}
				sortOrderChange={this.sortOrderChange}
				fundItems={this.sortAndFilterItems()}
				totalNumberOfListings={this.props.model.TotalNumberOfListings}
				deleteFund={this.deleteFund}
				fundTotalsMessage={this.state.fundTotalsMessage}
				fundTotalsUpdated={this.state.fundTotalsUpdated}/>
			);
	}

	private getStoredSortOrder() {
		const storedSortOrder = LocalStorageHelper.getLocalStorage().getItem(this.pageFundListSortOrderKey);
		return JSON.parse(storedSortOrder) as SortOrder;
	}

	private saveSortOrderAsync(newOrder: SortOrder) {
		setTimeout(() => {
			LocalStorageHelper.getLocalStorage().setItem(this.pageFundListSortOrderKey, JSON.stringify(newOrder));
		}, 0);
	}
}
