import * as React from 'react';
import { SvgWrapper } from '../../components/svg-wrapper';

export class AddNewRegisteredScannerLink extends React.Component<{ onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void, redirectUrl?: string }> {
	render() {
		return (
			<a className="btn btn-link btn-sm btn-vertically-centered list-group-action-link" href={this.props.redirectUrl} onClick={this.props.onClick}>
				<SvgWrapper className="svg icon" svg="icon-add" />
				Add Scanner
			</a>
		);
	}
}
