import * as React from 'react';
import { classNames } from '../../utils/classnames';
import { getUniqueElementId } from '../../utils/form-control-utils';
import { FormControlLabel } from '../primitives/label';
import { ValidationMessage } from '../validation/validation-message';
import { IFormFieldTooltipProps, FormFieldTooltip } from './form-field-tooltip';

export interface IFormFieldProps {
	className?: string;
	label?: string;
	tooltip?: IFormFieldTooltipProps;
	getDescription?: () => JSX.Element;
}

type AdditionalFormFieldProps = {
	isFormFieldGroupItem?: never;
	error?: string;
} | {
	isFormFieldGroupItem: true;
	error?: never;
};

export class FormField extends React.Component<IFormFieldProps & AdditionalFormFieldProps> {
	private readonly formControlId = getUniqueElementId();
	render() {
		const { className, label, tooltip, getDescription, isFormFieldGroupItem, error, children } = this.props;
		const child = React.Children.only(children) as React.ReactElement;
		const field = React.cloneElement(child, {
			formControlId: this.formControlId,
			name: this.formControlId,
		});
		const containerClass = classNames(isFormFieldGroupItem ? 'form-group-item' : 'form-group', className, label ? `form-control-labelled` : ``);

		return (
			<div className={containerClass} data-field-invalid={(!isFormFieldGroupItem && !!error) || null}>
				{
					label && (<FormControlLabel className="form-control-label" formControlId={this.formControlId}>
						{label}
						{tooltip && <FormFieldTooltip {...tooltip} />}
					</FormControlLabel>)}
				{field}
				{error && <ValidationMessage id={this.formControlId} errorMessage={error} />}
				{getDescription && getDescription()}
			</div>
		);
	}
}
