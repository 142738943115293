

// ReSharper disable InconsistentNaming
// ReSharper disable StringLiteralWrongQuotes
/* tslint:disable:no-use-before-declare quotemark max-line-length */
import { IApiAction } from '../utils/data-service';
export interface EventTimeForMerchantSuccessResponse {
	Type: EventTimeResponse.Success;
	EventTimeForMerchant: EventTimeForMerchant;
}
export interface EventTimeForMerchant {
	MerchantId: number;
	IsActive: boolean;
	Id: number;
	Name: string;
	DayOfWeek: DayOfWeek;
	StartTime: string;
	EndTime: string;
	Token: string;
}
export enum DayOfWeek {
	Sunday = 0, Monday = 1, Tuesday = 2, Wednesday = 3, Thursday = 4, Friday = 5, Saturday = 6,
}
export enum EventTimeResponse {
	Unknown = 0, Success = 1, ConfirmationRequired = 2, Invalid = 3,
}
export interface PaymentBatchSuccessResponse {
	Type: EventTimeResponse.Success;
	Batches: PaymentBatchViewModel[];
}
export interface PaymentBatchViewModel {
	Name: string;
	BatchDetailsUrl: string;
}
export interface EventTimeEditRequest {
	Name: string;
	Id: number;
	Token: string;
	DayOfWeek: DayOfWeek;
	StartTime: string;
	EndTime: string;
}
export interface InvalidEventTimeIdErrorResponse {
	Type: EventTimeResponse.Invalid;
	Reason: string;
}
export interface EventTimeConfirmationRequiredResponse {
	Type: EventTimeResponse.ConfirmationRequired;
	InProgressBatches: PaymentBatchViewModel[];
}
export interface EventTimeSuccessResponse {
	Type: EventTimeResponse.Success;
	EventTime: EventTimeViewModel;
}
export interface EventTimeCreateRequest {
	Name: string;
	DayOfWeek: DayOfWeek;
	StartTime: string;
	EndTime: string;
}
export interface EventTimeViewModel {
	MerchantNames: string[];
	Id: number;
	Token: string;
	Name: string;
	DayOfWeek: DayOfWeek;
	StartTime: string;
	EndTime: string;
}
export interface SettingsServiceTimeConfigViewModel {
	Listings: ListingViewModel[];
	EventTimes: SettingsEventTimeViewModel[];
	PaymentLabel: PaymentLabel;
}
export interface PaymentLabel {
	NounSentenceCase: string;
	NounPluralSentenceCase: string;
	VerbSentenceCase: string;
	VerbAppliedSentenceCase: string;
	NounLowerCase: string;
	NounPluralLowerCase: string;
	VerbLowerCase: string;
	VerbAppliedLowerCase: string;
	VerbPresentTenseSentenceCase: string;
	VerbPresentTenseLowerCase: string;
	VerbPastTenseSentenceCase: string;
	VerbPastTenseLowerCase: string;
	PayerSentenceCase: string;
	PayerLowerCase: string;
	PayerPluralSentenceCase: string;
	PayerPluralLowerCase: string;
	ActionSentenceCase: string;
	ActionLowerCase: string;
	ActionPluralSentenceCase: string;
	ActionPluralLowerCase: string;
	TextGivingFeatureName: string;
	GiftEntryFeatureName: string;
}
export interface SettingsEventTimeViewModel {
	Name: string;
	DayOfWeek: DayOfWeek;
	StartTime: string;
	EndTime: string;
	Id: number;
	Token: string;
}
export interface ListingViewModel {
	ListingId: number;
	ListingName: string;
	PaymentLabel: PaymentLabel;
	HomeCountry: Country;
	TransactionsUrl: string;
	ActiveEventTimes: number[];
	Role: MerchantAdminRole;
}
export enum MerchantAdminRole {
	CanDoEverything = 0, PaymentEntryOnly = 1,
}
export enum Country {
	NZ = 0, AU = 1, US = 2, AF = 4, AL = 8, AQ = 10, DZ = 12, AS = 16, AD = 20, AO = 24, AG = 28, AZ = 31, AR = 32, AT = 40, BS = 44, BH = 48, BD = 50, AM = 51, BB = 52, BE = 56, BM = 60, BT = 64, BO = 68,
	BA = 70, BW = 72, BV = 74, BR = 76, BZ = 84, IO = 86, SB = 90, VG = 92, BN = 96, BG = 100, MM = 104, BI = 108, BY = 112, KH = 116, CM = 120, CA = 124, CV = 132, KY = 136, CF = 140, LK = 144, TD = 148, CL = 152,
	CN = 156, TW = 158, CX = 162, CC = 166, CO = 170, KM = 174, YT = 175, CG = 178, CD = 180, CK = 184, CR = 188, HR = 191, CU = 192, CY = 196, CZ = 203, BJ = 204, DK = 208, DM = 212, DO = 214, EC = 218, SV = 222,
	GQ = 226, ET = 231, ER = 232, EE = 233, FO = 234, FK = 238, GS = 239, FJ = 242, FI = 246, AX = 248, FR = 250, GF = 254, PF = 258, TF = 260, DJ = 262, GA = 266, GE = 268, GM = 270, PS = 275, DE = 276, GH = 288,
	GI = 292, KI = 296, GR = 300, GL = 304, GD = 308, GP = 312, GU = 316, GT = 320, GN = 324, GY = 328, HT = 332, HM = 334, VA = 336, HN = 340, HK = 344, HU = 348, IS = 352, IN = 356, ID = 360, IR = 364, IQ = 368,
	IE = 372, IL = 376, IT = 380, CI = 384, JM = 388, JP = 392, KZ = 398, JO = 400, KE = 404, KP = 408, KR = 410, KW = 414, KG = 417, LA = 418, LB = 422, LS = 426, LV = 428, LR = 430, LY = 434, LI = 438, LT = 440,
	LU = 442, MO = 446, MG = 450, MW = 454, MY = 458, MV = 462, ML = 466, MT = 470, MQ = 474, MR = 478, MU = 480, MX = 484, MC = 492, MN = 496, MD = 498, ME = 499, MS = 500, MA = 504, MZ = 508, OM = 512, NA = 516,
	NR = 520, NP = 524, NL = 528, CW = 531, AW = 533, SX = 534, BQ = 535, NC = 540, VU = 548, NI = 558, NE = 562, NG = 566, NU = 570, NF = 574, NO = 578, MP = 580, UM = 581, FM = 583, MH = 584, PW = 585, PK = 586,
	PA = 591, PG = 598, PY = 600, PE = 604, PH = 608, PN = 612, PL = 616, PT = 620, GW = 624, TL = 626, PR = 630, QA = 634, RE = 638, RO = 642, RU = 643, RW = 646, BL = 652, SH = 654, KN = 659, AI = 660, LC = 662,
	MF = 663, PM = 666, VC = 670, SM = 674, ST = 678, SA = 682, SN = 686, RS = 688, SC = 690, SL = 694, SG = 702, SK = 703, VN = 704, SI = 705, SO = 706, ZA = 710, ZW = 716, ES = 724, SS = 728, SD = 729, EH = 732,
	SR = 740, SJ = 744, SZ = 748, SE = 752, CH = 756, SY = 760, TJ = 762, TH = 764, TG = 768, TK = 772, TO = 776, TT = 780, AE = 784, TN = 788, TR = 792, TM = 795, TC = 796, TV = 798, UG = 800, UA = 804, MK = 807,
	EG = 818, GB = 826, GG = 831, JE = 832, IM = 833, TZ = 834, VI = 850, BF = 854, UY = 858, UZ = 860, VE = 862, WF = 876, WS = 882, YE = 887, ZM = 894, XK = 999, __ = -2, Unsupported = -1,
}
export const EventTimeApiConfig = {
	defaultBaseUrl: () => (<RegExpMatchArray>location.pathname.match(new RegExp('/pushpay/portal/\\d+', 'i')))[0],
	actions: {
		getEventTimes: <IApiAction<{}, EventTimeViewModel[]>>{
			url: (data) => `/eventtime/get-eventtimes`,
		},
		add: <IApiAction<{createInfo: EventTimeCreateRequest}, EventTimeViewModel>>{
			url: (data) => `/eventtime/add`,
		},
		edit: <IApiAction<{editInfo: EventTimeEditRequest}, EventTimeSuccessResponse | EventTimeConfirmationRequiredResponse | InvalidEventTimeIdErrorResponse>>{
			url: (data) => `/eventtime/edit`,
		},
		editConfirmed: <IApiAction<{editInfo: EventTimeEditRequest}, EventTimeSuccessResponse | InvalidEventTimeIdErrorResponse>>{
			url: (data) => `/eventtime/edit-confirmed`,
		},
		getInProgressPaymentBatchesForEventTime: <IApiAction<{id: number}, PaymentBatchSuccessResponse | InvalidEventTimeIdErrorResponse>>{
			url: (data) => `/eventtime/get-batches-inprogress`,
		},
		getEventTime: <IApiAction<{id: number}, EventTimeSuccessResponse | InvalidEventTimeIdErrorResponse>>{
			url: (data) => `/eventtime/get-eventtime/${data.id}`,
		},
		delete: <IApiAction<{id: number}, EventTimeSuccessResponse | InvalidEventTimeIdErrorResponse>>{
			url: (data) => `/eventtime/delete/${data.id}`,
		},
		addAndActivateForMerchant: <IApiAction<{createInfo: EventTimeCreateRequest}, EventTimeViewModel>>{
			url: (data) => `/eventtime/{merchantId}/add`,
		},
		activateEventTimeForMerchant: <IApiAction<{id: number, merchantId: number}, EventTimeForMerchantSuccessResponse | InvalidEventTimeIdErrorResponse>>{
			url: (data) => `/eventtime/${data.merchantId}/activate/${data.id}`,
		},
		deactivateEventTimeForMerchant: <IApiAction<{id: number, merchantId: number}, EventTimeForMerchantSuccessResponse | InvalidEventTimeIdErrorResponse>>{
			url: (data) => `/eventtime/${data.merchantId}/deactivate/${data.id}`,
		},
	},
	urls: {
	},
};
/* tslint:enable:no-use-before-declare quotemark max-line-length */
// ReSharper restore InconsistentNaming
// ReSharper restore StringLiteralWrongQuotes
// ReSharper disable InconsistentNaming
// ReSharper disable StringLiteralWrongQuotes
/* tslint:disable:no-use-before-declare quotemark max-line-length */
export namespace ModelMetadata {
	export class MetadataArrayOf<T> {
		metadata: T;

		constructor(metadata: T) {
			this.metadata = metadata;
		}

		at(index: number): T {
			return this.metadata;
		}
	}

	export interface IPropertyMetadata {
		propertyName: string;
		displayName?: string;
		placeholder?: string;
		validationRules?: { [rule: string]: any };
		modelMetadata?: { [name: string] :IPropertyMetadata};
	}

	export let EventTimeCreateRequest = {
		Name: {
			propertyName: "Name",
			validationRules: {
				length: {
					errorMessage: "The field Name must be a string with a maximum length of 100.",
					parameters: {
						max: 100,
					},
				},
			},
		},
		DayOfWeek: {
			propertyName: "DayOfWeek",
			validationRules: {
				required: {
					errorMessage: "The DayOfWeek field is required.",
				},
			},
		},
		StartTime: {
			propertyName: "StartTime",
			validationRules: {
				required: {
					errorMessage: "The StartTime field is required.",
				},
			},
		},
		EndTime: {
			propertyName: "EndTime",
			validationRules: {
				required: {
					errorMessage: "The EndTime field is required.",
				},
			},
		},
	};
	export let EventTimeEditRequest = {
		Name: {
			propertyName: "Name",
			displayName: "Service name",
			validationRules: {
				length: {
					errorMessage: "The field Service name must be a string with a maximum length of 100.",
					parameters: {
						max: 100,
					},
				},
				required: {
					errorMessage: "The Service name field is required.",
				},
			},
		},
		Id: {
			propertyName: "Id",
			validationRules: {
				number: {
					errorMessage: "The field Id must be a number.",
				},
				required: {
					errorMessage: "The Id field is required.",
				},
			},
		},
		Token: {
			propertyName: "Token",
			validationRules: {
				required: {
					errorMessage: "The Token field is required.",
				},
			},
		},
		DayOfWeek: {
			propertyName: "DayOfWeek",
			validationRules: {
				required: {
					errorMessage: "The DayOfWeek field is required.",
				},
			},
		},
		StartTime: {
			propertyName: "StartTime",
			validationRules: {
				required: {
					errorMessage: "The StartTime field is required.",
				},
			},
		},
		EndTime: {
			propertyName: "EndTime",
			validationRules: {
				required: {
					errorMessage: "The EndTime field is required.",
				},
			},
		},
	};
}
/* tslint:enable:no-use-before-declare quotemark max-line-length */
// ReSharper restore InconsistentNaming
// ReSharper restore StringLiteralWrongQuotes
