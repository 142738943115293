import * as React from 'react';
import {CSSTransitionGroup} from 'react-transition-group-v1';
import {ResponseTypes} from '../funds-generated';
import {FundListTabStrip} from './fund-list-tab-strip';
import {FundListDropdown} from './fund-list-dropdown';
import {FundListItem, IFundListItemProps} from './fund-list-item';
import {SortOrder} from './sort-order';
import FundListItemViewModel = ResponseTypes.FundListItemViewModel;

export interface IFundListProps {
	tabVisibilityChange: (visibility) => void;
	sortOrderChange: (sortOrder) => void;
	isOpenTabVisible: boolean;
	showFundTotalsMessage: boolean;
	sortOrder: SortOrder;
	fundItems: FundListItemViewModel[];
	totalNumberOfListings: number;
	fundTotalsMessage: string;
	fundTotalsUpdated: boolean;
	deleteFund: (key: string, deleteUrl: string) => JQueryPromise<any>;
}

export class FundList extends React.Component<IFundListProps, any> {

	renderFundTotalsMessage() {
		if (!this.props.showFundTotalsMessage) {
			return null;
		}
		const messageClassName = `fund-total-message-inner ${this.props.fundTotalsUpdated ? 'fund-total-updated' : null}`;

		return (
			<CSSTransitionGroup
				component="div"
				className="fund-total-message"
				transitionName="fund-total-message"
				transitionEnterTimeout={2500}
				transitionLeaveTimeout={2000}>
				<div key={this.props.fundTotalsMessage}>
					<div className={messageClassName}>{this.props.fundTotalsMessage}</div>
				</div>
			</CSSTransitionGroup>
		);
	}

	createFundItems() {
		return this.props.fundItems.map((x): IFundListItemProps => {
			return {
				total: x.Total,
				code: x.Code,
				name: x.Name,
				isOpen: x.IsOpen,
				notes: x.Notes,
				editUrl: x.EditUrl,
				canDeleteUrl: x.CanDeleteUrl,
				deleteUrl: x.DeleteUrl,
				key: x.Key,
				isUnknown: x.IsUnknown,
				defaultOnNumberOfListings: x.DefaultOnNumberOfListings,
				shownOnNumberOfListings: x.ShownOnNumberOfListings,
				totalNumberOfListings: this.props.totalNumberOfListings,
				deleteFund: this.props.deleteFund,
				FundType: x.FundType,
				CampaignName: x.CampaignName,
				CampaignUrl: x.CampaignUrl
			};
		});
	}

	renderFundItem(item: IFundListItemProps) {
		return (<FundListItem item={item} key={item.key} />);
	}

	render() {
		return (
			<div className="fund-list">
				<FundListTabStrip tabVisibilityChange={this.props.tabVisibilityChange} isOpenTabVisible={this.props.isOpenTabVisible} />
				<div className="sort-bar form-inline filter-form col-md-12">
					<div className="form-group">
						<label>Sort by</label>
						<FundListDropdown sortOrder={this.props.sortOrder} sortOrderChanged={x => this.props.sortOrderChange(x) }/>
					</div>
					{this.renderFundTotalsMessage() }
				</div>
				<div className="list-group">
					{this.createFundItems().map((x) => this.renderFundItem(x)) }
				</div>
			</div>
		);
	}
}
