import { action } from 'mobx';

export const arrayRemove = action(<T>(array: T[], item: T) => {
	var index = array.indexOf(item);
	if (index < 0) {
		return false;
	}
	array.splice(index, 1);
	return true;
});

export function timerPromise(ms: number) {
	return new Promise<number>((resolve, reject) => {
		setTimeout(resolve, ms);
	});
}

export function applyPrimitiveProperties(src, target) {
	Object.keys(src)
		.filter(x => typeof src[x] !== 'object')
		.filter(x => typeof src[x] !== 'undefined')
		.forEach(x => target[x] = src[x]);
	return target;
}

export function noPropagate(handler: Function) {
	return e => {
		handler(e);
		e.stopPropagation();
	};
}

export function combineWithLocationPathname(url: string) {
	return location.pathname.replace(/\/?$/, '/') + url;
}
