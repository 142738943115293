import * as React from 'react';
import {ModalDialogCommander} from '../../components/modal-dialog-commander';
import {CloseFundModalDialog} from './common-close-fund-dialog';
import {Button} from '../../components/button';
import {SvgWrapper} from '../../components/svg-wrapper';

export interface ICloseFundButtonProps {
	url: string;
	className: string;
	fundClose: () => JQueryPromise<any>;
}

export class CloseFundButton extends React.Component<ICloseFundButtonProps, {}> {
	handleButtonClick = () => {
		ModalDialogCommander.showReactDialog(<CloseFundModalDialog urlClose={this.props.url} fundClose={this.props.fundClose} />);
	}

	render() {
		return (
			<Button className={this.props.className} onClick={this.handleButtonClick}>
				<SvgWrapper svg="icon-close-fund" className="icon" />Close fund
			</Button>);
	}
}
