import * as React from 'react';
import { observer } from 'mobx-react';
import { isFunction } from '../../../utils/is-function';
import * as styles from './styles.less';
import { ValidatableControl } from '../../../components/form-controls/validatable-control';
import { ValidationMessage } from '../../../components/form-controls/validation-message';
import { IValidationRules } from '../../../validation/validation-rules';

type Quarter = number|undefined;

export interface IDateQuarterSelectorProps {
	start?: Quarter;
	end?: Quarter;
	max: Quarter;

	name: string;
	validationRules?: IValidationRules;

	onChange?: (start: Quarter, end: Quarter) => void;

	acceptanceTestTargetId: string;
	ariaLabel?: string;
	disabled?: boolean;
}

@observer
export class DateQuarterSelector extends React.Component<IDateQuarterSelectorProps, {}> {

	render() {
		const {
			start,
			end,
			max,
			name,
			ariaLabel,
			acceptanceTestTargetId,
			validationRules,
			disabled,
		} = this.props;

		const quarters: Quarter[] = [1,2,3,4];

		return (
			<div>
				<span className={styles.dateQuarterSelector}
					aria-label={ariaLabel}
					data-pp-at-target={acceptanceTestTargetId}
					role="group"
				>
					{quarters.map((quarter, index) => {
						const isChecked = quarter >= start && quarter <= end && quarter <= max;
						const isDisabled = disabled || quarter > max;
						const htmlFor = `${name}_${index}`;
						const labelClasses = `${styles.dateQuarterSelectorLabel} ${isChecked ? styles.isActive : ''} ${isDisabled ? styles.isDisabled : ''}`;

						return ([
							<ValidatableControl key={`input_${index}`} validationRules={validationRules} elementName={name} revalidateOnChange={true}>
								<input type="checkbox" className={styles.dateQuarterSelectorCheckbox}
									value={quarter}
									checked={isChecked}
									disabled={isDisabled}
									id={htmlFor}
									name={name}
									onChange={this.handleChange}
									data-pp-at-target={`${acceptanceTestTargetId}_q${quarter}`}
								/>
							</ValidatableControl>,
							<label
								className={labelClasses}
								key={`label_${index}`}
								htmlFor={htmlFor}
								data-pp-at-target={`${acceptanceTestTargetId}_label_q${quarter}`}
							>
								<span>{`Quarter ${quarter}`}</span>
							</label>
						]);
					})}
				</span>
			<ValidationMessage elementName={name} hideErrors={!validationRules}/>
			</div>
		);
	}

	private handleChange = (event: React.FormEvent<HTMLInputElement>) => {
		const { onChange } = this.props;
		const quarter = parseInt(event.currentTarget.value);
		var start = this.props.start;
		var end = this.props.end;

		// click with no range selects the range from Q1 to that quarter
		// (this is because it will be common to select year-to-date ranges)
		if (start === undefined || end === undefined) { start = 1; end = quarter; } else
		// click < than then start extends start to that quarter
		if (quarter < start) { start = quarter; } else
		// click > than the end extends end to that quarter
		if (quarter > end) { end = quarter; } else
		// click inside start/end moves the end to that quarter
		if (quarter > start && quarter < end) { end = quarter; } else
		// click on start of range unselects that quarter
		if (quarter === start && start < end) { start = start + 1; } else
		// click on end of range unselects that quarter
		if (quarter === end && end > start) { end = end - 1; } else
		// click on single selected quarter deselects all
		if (quarter === start && quarter === end) { start = end = undefined; }

		if (isFunction(onChange)) {
			onChange(start, end);
		}
	}

}
