import * as React from 'react';
import { Fragment } from '../../../Shared/components/fragment';
import { PaymentBatchViewModel } from '../../settings/settings-generated';

import * as styles from './event-time-dialog.less';

export class EventTimeOpenBatchesList extends React.Component<{
	openPaymentBatches: PaymentBatchViewModel[],
}> {
	render() {
		const { openPaymentBatches } = this.props;

		return (
			<Fragment>
				<h5 className={`${styles.paymentBatchesTitle} text-left`}>Open batches</h5>
				<ul className={`${styles.batchListingText} text-left`}>
					{openPaymentBatches.map((batch, i) =>
						<li key={`batch-${i}`}>
							<a href={batch.BatchDetailsUrl} target="_blank" rel="noopener noreferrer">{batch.Name}</a>
						</li>)}
				</ul>
			</Fragment>
		);
	}
}
