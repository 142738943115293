import * as React from 'react';
import { observable, runInAction, action } from 'mobx';
import { observer } from 'mobx-react';

import { IFormControlCommonProps } from '../form-control-common-props';
import { Fragment } from '../../../../Shared/components/fragment';

import * as styles from './form-control-file-input-styles.less';
import { FileUploadContainer, IFileUploadDragOverProps } from '../../file-upload/file-upload-container';
import { FileUploadPanel } from '../../file-upload/file-upload-panel';
import { PreviewFilePanel } from '../../file-upload/file-preview-panel';
import { FileUploadState, FileUploadStateFileSelected, FileUploadViewState } from '../../file-upload/file-upload-state';


export interface IFormControlFileInputProps extends IFormControlCommonProps {
	fileCurrent: FileUploadState;
	fileAccept?: string;
	ariaLabel?: string;
	onChangeHandler?: (file: File | null) => void;
}

@observer
export class FormControlFileInput extends React.Component<IFormControlFileInputProps> {

	render() {
		const {
			acceptanceTestTargetId = '',
			fileAccept,
			ariaLabel,
			fileCurrent,
			name,
		} = this.props;

		return (
			<FileUploadContainer
				acceptanceTestTargetId={`${acceptanceTestTargetId} FileUploadContainer`}
				renderContent={this.renderContent}
				containerClassNames={styles.background}
				fileCurrent={fileCurrent}
				name={name}
			/>
		);
	}

	private renderContent = ({isDragOver, updateIsDragOver}: IFileUploadDragOverProps) => {
		const {
			acceptanceTestTargetId = '',
			onChangeHandler,
			fileAccept,
			ariaLabel,
			fileCurrent,
			name,
		} = this.props;

		switch (fileCurrent.viewState) {
			case FileUploadViewState.Empty:
			case FileUploadViewState.Error:
			return (
					<FileUploadPanel
						name={name}
						uniqueSuffix={'FileUploadPanel'}
						fileAccept={fileAccept}
						ariaLabel={ariaLabel}
						isDragOver={isDragOver}
						updateIsDragOver={updateIsDragOver}
						onChangeHandler={onChangeHandler}
						fileUploadState={fileCurrent}
					/>
			);
			case FileUploadViewState.FileSelected:
				return (
					<Fragment>
						<FileUploadPanel
							name={name}
							uniqueSuffix={'FileUploadPanel'}
							fileAccept={fileAccept}
							ariaLabel={ariaLabel}
							isDragOver={isDragOver}
							updateIsDragOver={updateIsDragOver}
							onChangeHandler={onChangeHandler}
							fileUploadState={fileCurrent}
						/>
						<PreviewFilePanel
							acceptanceTestTargetId={acceptanceTestTargetId}
							fileCurrent={fileCurrent}
							onChangeHandler={onChangeHandler}
						/>
					</Fragment>
				);
			default:
				const unsupported: never = fileCurrent;
				throw new Error(`Unsupported upload state: ${(unsupported as any).viewState}`);
		}
	}
}
