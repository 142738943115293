import './allocation/allocation-view';
import './batch/batch-list';
import './batch/batch-list-item';
import './batch/batch-list-store';
import './scanners/registered-scanners-view';
import './scanners/register-scanner-dialog';
import './scanners/register-scanner-dialog-viewmodel';
import './scanners/delete-registered-scanner-dialog';
import './scanners/delete-registered-scanner-dialog-viewmodel';
import './scanners/add-new-registered-scanner-link';
