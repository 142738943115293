import * as React from 'react';
import { computed } from 'mobx';
import { observer } from 'mobx-react';

import { ConfigurationSection } from '../configuration-section';
import { IntegrationLabels } from '../integration-labels';
import { ModelMetadata, ResponseTypes } from '../../../funds/funds-generated';
import { ListingModel } from '../listing-model';
import { ListingSettingsEditor } from '../listing-configuration';
import { FallbackFundSelector } from '../fallback-fund-selector';
import TooltipComponent from '../../components/configuration-section-tooltip';
import { SelectField, ValidationMessage } from '../../../funds/components/form-controls';
import { IntegrationFundDisplayItem } from '../../helpers/get-integration-funds-for-display';
import { PlanningCenterIntegrationConfigurationStore } from "../../pages/configure-planningcenter";
import { GivingIntegrationActions } from './giving-integration-actions';
import { SvgWrapper} from "../../../components/svg-wrapper";
import { Button} from "../../../components/button";
import ListingConfigurationModel = ResponseTypes.PlanningCenterListingConfigurationModel;
import IntegrationListingConfigurationViewModel = ResponseTypes.IntegrationListingConfigurationViewModel;
import PlanningCenterCampusViewModel = ResponseTypes.PlanningCenterCampusViewModel;
import GivingStatus = ResponseTypes.GivingStatus;
import ExternalSystemStatus = ResponseTypes.ExternalSystemStatus;

export class PlanningCenterListingModel extends ListingModel<ListingConfigurationModel> {
	campuses: ResponseTypes.PlanningCenterCampusViewModel[];

	constructor(listing: IntegrationListingConfigurationViewModel<ListingConfigurationModel>,
		funds: IntegrationFundDisplayItem[],
		campuses: PlanningCenterCampusViewModel[],
		validationError: string = null) {
		super(listing, funds, validationError);
		this.campuses = campuses;
	}

	@computed
	get campus(): string {
		return `${this.model.CampusId || ''}`;
	}
}

export class PlanningCenterGivingConfigurationSection extends React.Component<{
	store: PlanningCenterIntegrationConfigurationStore
}, {
	givingStatus: GivingStatus,
	isGivingModuleEnabled: boolean
}> {
	constructor(props) {
		super(props);
		this.state = {
			givingStatus: props.store.givingStatus,
			isGivingModuleEnabled: props.store.isGivingModuleEnabled
		};

		this.handleConnectGiving = this.handleConnectGiving.bind(this);
		this.handleEnableGiving = this.handleEnableGiving.bind(this);
		this.handleDisableGiving = this.handleDisableGiving.bind(this);
	}

	renderGivingBlurb() {
		const showNotConnectedBlurb = this.state.givingStatus === GivingStatus.NotConnected;
		const showInSetupBlurb = this.state.givingStatus === GivingStatus.InSetup;

		return (
			<div className="panel panel-default">
				<div className="panel-body">
					{
						showNotConnectedBlurb &&
						<p>
							When the Giving integration is enabled, transactions in Pushpay will automatically be generated in Planning Center Giving.
							<a href={this.props.store.viewModel.Metadata.HelpUrl}> Click here to learn more.</a>
						</p>
					}
					{
						showInSetupBlurb &&
						<p>
							Please complete the setup process by configuring the Settings below.
							Questions about the Settings? Click <a href={this.props.store.viewModel.Metadata.HelpUrl}>here</a>
						</p>
					}
				</div>
			</div>
		);
	}

	renderGivingModuleNotEnabledBlurb() {
		let content = (
			<>
				<p>
					Looks like your instance of Planning Center does not have the Giving module enabled or you are not authorized to access it. Please visit this
					<a href="https://giving.planningcenteronline.com/setup"> link</a> to help setup your Planning Center to support the syncing
					of payments.

					Once you have finished setting up Planning Center, please try re-connecting Giving. (<em>Please ensure you are
					using a Planning Center account with the appropriate access level</em>).
				</p>
			</>
		);

		if (this.state.givingStatus === GivingStatus.NotConnected) {
			return (
				<div className="panel panel-default">
					<div className="panel-body">
						{ content }
					</div>
				</div>
			);
		}

		return (
			<div className="alert alert-danger alert-integration-warning">
				<div className="alert-body">
					<span className="alert-title">Planning Center Giving Module Not Enabled</span>
					{ content }
				</div>
			</div>
		);
	}

	renderPaymentSources() {
		return this.props.store.paymentSources.map(x => <option key={x.Id} value={`${x.Id}`}>{x.Name}</option>);
	}

	renderOrganizationSettings() {
		const paymentSourceTooltip = "The payment source tells you where the donation came from (Pushpay, Planning Center, Paypal, Square, etc)";
		const hasPaymentSources = this.props.store.paymentSources !== null && this.props.store.paymentSources.length > 0;

		return (
			<div className="organization-settings">
				<div className="form-group">
					<label className="col-md-3 control-label">{this.metadata.PaymentSourceId.displayName}
						<TooltipComponent message={paymentSourceTooltip} />
					</label>
					<div className="col-md-5">
						<div className="select-wrapper">
							<SelectField propertyMetadata={this.metadata.PaymentSourceId}
										 defaultValue={this.props.store.DefaultPaymentSource}>
								<option value="">- Select payment source -</option>
								{ hasPaymentSources && this.renderPaymentSources() }
							</SelectField>
						</div>
					</div>
					<div className="col-md-3">
						<ValidationMessage for={this.metadata.PaymentSourceId.propertyName} />
					</div>
				</div>
			</div>
		);
	}

	renderGivingConfiguration() {
		return (
			<div>
				<ConfigurationSection
					store={this.props.store}
					headerComponentFactory={PCOGivingListingRowHeader}
					organizationSettings={this.renderOrganizationSettings()}
					listingSettingsEditor={PCOGivingListingSettingsEditor} />
			</div>
		);
	}

	render() {
		const showGivingModuleNotEnabledBlurb = this.state.isGivingModuleEnabled === false
			&& this.props.store.viewModel.SystemStatus === ExternalSystemStatus.Enabled;
		const showGivingConfiguration =
			this.state.isGivingModuleEnabled === true
			&& (this.state.givingStatus === GivingStatus.InSetup
			|| this.state.givingStatus === GivingStatus.SetupComplete
			|| this.state.givingStatus === GivingStatus.Enabled
			|| this.state.givingStatus === GivingStatus.Disabled);
		const showGivingBlurb = showGivingModuleNotEnabledBlurb == false
			&& (this.state.givingStatus === GivingStatus.NotConnected || this.state.givingStatus === GivingStatus.InSetup);

		return (
			<div className="connection-section">
				<h2>
					<div className="integration-section-headers">
						<span>Configure Giving</span>
						<IntegrationLabels labels={this.props.store.viewModel.Content.SecondaryLabels} />

						<GivingIntegrationActions
							status={this.props.store.viewModel.SystemStatus}
							givingStatus={this.state.givingStatus}
							isGivingModuleEnabled={this.state.isGivingModuleEnabled}
						  	store={this.props.store}
							importFundsUrl={this.props.store.viewModel.ActionUrls.ImportFundsUrl}
							handleConnectionClick={this.handleConnectGiving}
							handleEnableGivingClick={this.handleEnableGiving}
							handleDisableGivingClick={this.handleDisableGiving} />
					</div>
				</h2>
				{ showGivingBlurb && this.renderGivingBlurb() }
				{ showGivingModuleNotEnabledBlurb && this.renderGivingModuleNotEnabledBlurb() }
				{ showGivingConfiguration && this.renderGivingConfiguration() }
			</div>
		);
	}

	handleConnectGiving() {
		this.props.store.serviceClient.connectPcoGiving();
	}

	handleEnableGiving() {
		this.props.store.serviceClient.enablePcoGiving();
	}

	handleDisableGiving() {
		this.props.store.serviceClient.disablePcoGiving();
	}

	get metadata() {
		return ModelMetadata.PlanningCenterConfigurationEditModel;
	}
}

const PCOGivingListingRowHeader = React.createFactory(() => (
	<span>
		<span>Set default funds and campuses:</span>
		<TooltipComponent
			message={`If we run into any trouble matching transactions to Planning Center we'll use the fallback information you've selected here.`}
		/>
	</span>
));

@observer
class PCOGivingListingSettingsEditor extends React.Component<{ listing: PlanningCenterListingModel}, {}> {
	renderCampuses() {
		return this.props.listing.campuses.map(x => <option key={x.ExternalId} value={`${x.ExternalId}`}>{x.Name}</option>);
	}

	render() {
		const hasCampuses = this.props.listing !== null && this.props.listing.campuses.length > 0;

		return (
			<ListingSettingsEditor>
				<FallbackFundSelector
					propertyMetadata={this.metadata.FallbackFundKey}
					listing={this.props.listing}
					defaultValue={this.props.listing.model.FallbackFundKey}
					floatingLabelText="Select default fund" />
				<div className="form-group">
					<SelectField propertyMetadata={this.metadata.CampusId}
						floatingLabel="Select campus (Optional)"
						defaultValue={this.props.listing.campus}>
						<option value="">- Select campus (Optional) -</option>
						{ hasCampuses && this.renderCampuses() }
					</SelectField>
					<ValidationMessage for={this.metadata.CampusId.propertyName} />
				</div>
			</ListingSettingsEditor>
		);
	}

	private get metadata() {
		return ModelMetadata.PlanningCenterListingConfigurationModel;
	}
}
