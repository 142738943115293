import {action} from 'mobx';
import {AnimationTrigger} from './_common';
import PersonalDetails from './personal-details';
import Member from './member';

import * as Models from '../models/index';


export default class Account {
	id: number;
	verified: boolean;
	details = new PersonalDetails();

	droppedAnimation = new AnimationTrigger('dropped', 300);

	constructor(public parent: Member) {
	}

	@action
	applyModel(model: Models.PersonModel) {
		this.id = model.PersonId;
		this.verified = model.EmailAddressVerified || model.MobileNumberVerified;
		this.details.importValues(model);

		return this;
	}
}


