import { observable, action, computed } from 'mobx';
import {
	CampaignsLandingViewModel, GetCollectedAmountsSuccessResponse, GetCampaignsSuccessResponse, ScheduledCampaignCardViewModel, PledgeLabel
} from '../../campaigns-generated';
import { DraftCampaignCardViewModel, ActiveCampaignCardViewModel, ClosedCampaignCardViewModel } from '../../campaigns-generated';
import { createCampaignsLandingMachineContext } from './state-machine/campaigns-landing-machine-context';
import { subscribe, subscriber } from '../../../../Shared/decorators/subscriber';
import {
	loadCampaigns$, activateCampaign$, closeCampaign$,
	deleteCampaign$, getCollectedAmounts$, startProcessingCard$, finishProcessingCard$
} from './campaigns-landing-intent';
import { CampaignCardSection, CampaignCardViewModel } from '../../campaign-types';

export type ICampaignsLandingMachineContext = {
	machineContext?: ReturnType<typeof createCampaignsLandingMachineContext>;
};

@subscriber
export class CampaignsLandingMainViewModel {

	@observable
	campaigns = {
		[CampaignCardSection.Drafts]: {
			items: [] as DraftCampaignCardViewModel[],
			hasNextPage: false
		},
		[CampaignCardSection.Published]: {
			items: [] as (ActiveCampaignCardViewModel | ScheduledCampaignCardViewModel)[],
			hasNextPage: false
		},
		[CampaignCardSection.Closed]: {
			items: [] as ClosedCampaignCardViewModel[],
			hasNextPage: false
		},
	};

	@observable
	private numberOfProcessingCards = 0;

	@computed
	get shouldDisableLoadMore() {
		return this.numberOfProcessingCards !== 0;
	}

	constructor(public vm: CampaignsLandingViewModel) { }

	@subscribe(loadCampaigns$)
	@action.bound
	onLoadCampaigns([{ Campaigns, HasNextPage }, campaignCardSection]: [GetCampaignsSuccessResponse, CampaignCardSection]) {
		const targetCampaigns = this.campaigns[campaignCardSection];
		(targetCampaigns.items as CampaignCardViewModel[])
			.push(...Campaigns as CampaignCardViewModel[]);

		targetCampaigns.hasNextPage = HasNextPage;
	}

	@subscribe(activateCampaign$)
	@action.bound
	onActivateCampaign([draftItem, activeItem]) {
		this.campaigns[CampaignCardSection.Drafts].items = this.campaigns[CampaignCardSection.Drafts].items.filter(item => item !== draftItem);
		this.campaigns[CampaignCardSection.Published].items.unshift(activeItem);
	}

	@subscribe(closeCampaign$)
	@action.bound
	onCloseCampaign([activeItem, closedItem]) {
		this.campaigns[CampaignCardSection.Published].items = this.campaigns[CampaignCardSection.Published].items.filter(item => item !== activeItem);
		this.campaigns[CampaignCardSection.Closed].items.unshift(closedItem);
	}

	@subscribe(deleteCampaign$)
	@action.bound
	onDeleteCampaign(draftItem) {
		this.campaigns[CampaignCardSection.Drafts].items = this.campaigns[CampaignCardSection.Drafts].items.filter(item => item !== draftItem);
	}

	@subscribe(getCollectedAmounts$)
	@action.bound
	onGetCollectedAmounts({ CollectedAmounts }: GetCollectedAmountsSuccessResponse) {
		const collectedAmounts = CollectedAmounts.reduce((result, { FundKey, Amount }) => {
			result[FundKey] = Amount;
			return result;
		}, {});

		[...this.campaigns[CampaignCardSection.Published].items, ...this.campaigns[CampaignCardSection.Closed].items].forEach(item => {
			item.CollectedAmount = collectedAmounts[item.FundKey] || 0;
		});
	}

	@subscribe(startProcessingCard$)
	@action.bound
	onStartProcessingCard() {
		this.numberOfProcessingCards++;
	}

	@subscribe(finishProcessingCard$)
	@action.bound
	onFinishProcessingCard() {
		this.numberOfProcessingCards--;
	}

	@action
	updatePledgeLabelForStorybook = (pledgeLabel: PledgeLabel) => {
		this.campaigns[CampaignCardSection.Drafts].items.map(x => Object.assign(x, {PledgeLabel: pledgeLabel}));
		this.campaigns[CampaignCardSection.Published].items.map(x => Object.assign(x, {PledgeLabel: pledgeLabel}));
		this.campaigns[CampaignCardSection.Closed].items.map(x => Object.assign(x, {PledgeLabel: pledgeLabel}));
	}
}
