import * as React from 'react';
import { observer } from 'mobx-react';
import { SvgWrapper } from '../../svg-wrapper';
import { classNames } from '../../../../Shared/utils/classnames';
import * as styles from './form-control-checkbox.less';
import { IFormControlLabelledTooltipProps } from '../form-control-labelled';
import { Tooltip, TooltipContent } from '../../../components/tooltip/tooltip';

export interface IFormControlCheckboxProps {
	value: boolean;
	onChangeHandler: (checked: boolean) => void;
	text?: string | JSX.Element;
	containerClassNames?: string;
	disabled?: boolean;
	acceptanceTestTargetId?: string;
	labelClassNames?: string;
	tickClassNames?: string;
	tooltipOptions?: IFormControlLabelledTooltipProps;
}

@observer
export class FormControlCheckbox extends React.Component<IFormControlCheckboxProps, {}> {
	render() {
		const {
			value,
			text,
			containerClassNames,
			disabled,
			acceptanceTestTargetId,
			labelClassNames,
			tickClassNames,
			tooltipOptions,
		} = this.props;

		return (
			<div className={classNames(
				styles.wrapper,
				containerClassNames ? containerClassNames : null,
				{ disabled },
				value ? 'checked' : null,
			)}>
				<label
					className={classNames(
						styles.label,
						disabled ? styles.disabledLabel : null,
						labelClassNames,
					)}
					data-pp-at-target={acceptanceTestTargetId ? `${acceptanceTestTargetId}_label` : undefined}
				>
					<input className={styles.checkboxInput} type="checkbox" checked={value} disabled={disabled} onChange={this.onChangeHandler} data-pp-at-target={acceptanceTestTargetId} />
					<span className={classNames('fake-checkbox', styles.fakeCheckbox)}>
						{value && <SvgWrapper
							svg="checkbox-ticked"
							className={classNames('svg', styles.tickSvg, tickClassNames ? tickClassNames : null)}
						/>}
					</span>
					{text && <span className={styles.labelText}>{text}</span>}
					{tooltipOptions && <FormControlCheckboxToolip {...tooltipOptions} />}
				</label>
			</div>
		);
	}

	private onChangeHandler = (ev: React.FormEvent<HTMLInputElement>) => {
		this.props.onChangeHandler(ev.currentTarget.checked);
	}
}

@observer
export class FormControlCheckboxToolip extends React.Component<IFormControlLabelledTooltipProps, { visible: boolean }> {
	constructor(props) {
		super(props);
		this.state = { visible: false };
	}

	render() {
		const { tooltipContentClassName, className, placement, message } = this.props;
		return (
			<span className={`info-tooltip ${styles.tooltipIcon}`}>
				<Tooltip
					label="more information"
					visible={this.state.visible}
					onVisibleChange={this.handleVisibleChange}
					tooltipClassName={className}
					placement={placement}>
					<SvgWrapper svg="icon-info" className="icon tooltip-icon" />
					<TooltipContent>
						<div className={tooltipContentClassName}>{message}</div>
					</TooltipContent>
				</Tooltip>
			</span>
		);
	}

	private handleVisibleChange = (visible: boolean) => {
		this.setState({ visible: visible });
	}
}
