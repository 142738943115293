import * as React from 'react';
export interface IIntegrationContainerProps {
	isAuthenticationSectionEditable: boolean;
	authenticationSectionFactory: () => React.ReactChild;
	configurationSectionFactory: () => React.ReactChild;
}

// ReSharper disable once InconsistentNaming
export const IntegrationContainer = (props: IIntegrationContainerProps) => (
	<div>
		{props.isAuthenticationSectionEditable ? props.authenticationSectionFactory() : props.configurationSectionFactory()}
	</div>
);
