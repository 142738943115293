import { observable, action, computed } from 'mobx';
import { EmailTemplateViewModel, ScheduleImportEmailType } from '../../schedule-import-generated';
import moment from 'moment';
import { isFunction } from '../../../utils/is-function';

export class MigrationEmailsViewModel {
	@observable initialEmail: EmailTemplateViewModel;
	@observable weeklyEmail: EmailTemplateViewModel;
	@observable finalEmail: EmailTemplateViewModel;

	@observable activeEmailTemplateType: ScheduleImportEmailType;

	constructor(
		initialEmail: EmailTemplateViewModel,
		weeklyEmail: EmailTemplateViewModel,
		finalEmail: EmailTemplateViewModel,
	) {
		this.initialEmail = initialEmail;
		this.weeklyEmail = weeklyEmail;
		this.finalEmail = finalEmail;

		this.activeEmailTemplateType = ScheduleImportEmailType.InitialInvite;
	}

	@action.bound
	updateEmailVideoLink(videoLink: string) {
		this.activeEmailTemplate.VideoLink = videoLink;
	}

	@action.bound
	updateActiveEmailTemplateType(emailType: ScheduleImportEmailType) {
		this.activeEmailTemplateType = emailType;
	}

	@action.bound
	updateEmailMessage(message: string) {
		this.activeEmailTemplate.Message = message;
	}

	@action.bound
	updateEmailVideoMessage(message: string) {
		this.activeEmailTemplate.VideoMessage = message;
	}

	@computed
	get activeEmailTemplate() : EmailTemplateViewModel {
		switch (this.activeEmailTemplateType) {
			case ScheduleImportEmailType.InitialInvite:
				return this.initialEmail;
			case ScheduleImportEmailType.WeeklyReminder:
				return this.weeklyEmail;
			case ScheduleImportEmailType.FinalReminder:
				return this.finalEmail;
		}
	}
}
