import * as React from 'react';
import { observer, Provider, inject } from 'mobx-react';
import { CampaignsLandingMainViewModel } from './campaigns-landing-main-view-model';
import { createCampaignsLandingMachineContext } from './state-machine/campaigns-landing-machine-context';
import { CampaignsLandingMain } from './campaigns-landing-main';

interface CampaignsLandingAppProps {
	store: CampaignsLandingMainViewModel;
}


@observer
export class CampaignsLandingApp extends React.Component<CampaignsLandingAppProps> {
	private campaignsLandingMachineContext: ReturnType<typeof createCampaignsLandingMachineContext>;

	constructor(props) {
		super(props);
		const { HasCampaigns, PollForCollectedAmounts, CampaignSavedNotification, CollectedAmountsUpdatedSince } = this.props.store.vm;
		this.campaignsLandingMachineContext = createCampaignsLandingMachineContext({
			hasCampaigns: HasCampaigns,
			pollForCollectedAmounts: PollForCollectedAmounts,
			collectedAmountsUpdatedSince: CollectedAmountsUpdatedSince,
			savedNotification: CampaignSavedNotification,
		});
	}


	render() {
		return (
			<Provider machineContext={this.campaignsLandingMachineContext}>
				<CampaignsLandingMain store={this.props.store} />
			</Provider>
		);
	}
}
