import * as React from 'react';
import { observer } from 'mobx-react';
import { RecordedCardPaymentMethod } from './payment-entry-model';
import { FormControlLabelled, FormControlType } from '../../../components/form-controls/form-control-labelled';
import { ModelMetadata } from '../../virtual-terminal-generated';
import { injectAnalytics, recordEvent, WithAnalytics } from '../../../analytics';

@injectAnalytics
@observer
export class PaymentMethodRecordedCard extends React.Component<WithAnalytics & { card: RecordedCardPaymentMethod }, {}> {
	render() {
		const { card: { paymentReference } } = this.props;

		return (
			<div className="vt-payment-method">
				<FormControlLabelled label="Payment reference (optional)"
					formControlProps={{
						formControlType: FormControlType.Text,
						name: ModelMetadata.RecordedCreditCardModel.Reference.propertyName,
						placeholder: 'Enter a payment reference',
						type: 'text',
						value: paymentReference,
						onChangeHandler: this.handlePaymentReferenceChange,
						...(NewFeatures.SetupPinpointAnalytics && { onBlurHandler: this.handlePaymentReferenceBlur }),
						maxLength: this.referenceValidationRules.length.parameters.max,
						validationRules: this.referenceValidationRules,
						acceptanceTestTargetId: 'payment reference - card - recorded'
					}} />
			</div>
		);
	}

	private handlePaymentReferenceBlur = () => {
		const { analytics, card: { paymentReference } } = this.props;
		if (analytics && paymentReference) {
			const { feature, subFeature } = analytics;
			recordEvent({
				feature,
				subFeature,
				eventTypeLabel: 'completePaymentMethod',
				attributes: { paymentMethodType: 'recordedCard' }
			});
		}
	}

	private handlePaymentReferenceChange = (event: React.FormEvent<HTMLInputElement>) => {
		this.props.card.updatePaymentReference(event.currentTarget.value);
	}

	private get referenceValidationRules() {
		return ModelMetadata.RecordedCreditCardModel.Reference.validationRules;
	}
}
