import { runSaga as originalRunSaga, RunSagaOptions } from 'redux-saga';
import { CpsCallback, cancelled } from 'redux-saga/effects';
import { ICancellablePromise } from './cancellable-promise';

// redux saga supports cancellation of CpsCallbacks but typings are out of date
// This is temporary type until the submitted PR is accepted https://github.com/redux-saga/redux-saga/pull/1035
export type ReduxSagaCpsCallback = CpsCallback & { cancel?: () => void };

export { dataServiceCall } from './saga-effects/dataServiceCall';

let defaultSagaMonitor: any;

function getDefaultSagaMonitor() {
	if (!defaultSagaMonitor) {
		if (window['pushpay'] && window['pushpay']['devTools']) {
			defaultSagaMonitor = window['pushpay']['devTools'].sagaMonitor;
		}
	}

	return defaultSagaMonitor;
}

export const runSaga = ((options: RunSagaOptions<any, any>, ...args: any[]) => {
	options = {
		onError: error => window.reportUnhandledRejection(error),
		...options,
	};

	return (originalRunSaga as any)({ sagaMonitor: getDefaultSagaMonitor(), ...options }, ...args);
}) as typeof originalRunSaga;

export function* getCancellablePromiseEffect<T>(promise: ICancellablePromise<T>) {
	try {
		return yield promise;
	} finally {
		if (yield cancelled()) {
			if (promise) {
				promise.cancel();
			}
		}
	}
}


import { userActionChannel, Channel } from './user-action-channel';

export { userActionChannel };
export type Channel<T> = Channel<T>;
