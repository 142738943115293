import * as React from 'react';

export class WarningMessage extends React.Component<{
	warningText: string,
	anchorTarget: string,
	anchorText : string
}, {}> {
	render() {
		const warningText = this.props.warningText;
		const anchorText = this.props.anchorText;
		const anchorTarget = this.props.anchorTarget;
		return (
			<div> {warningText} <a href={anchorTarget} target="_blank" rel="noopener noreferrer" onClick={this.handleClick} >{anchorText}</a></div>
		);
	}

	handleClick  = (ev: React.MouseEvent<HTMLAnchorElement>) => {
		ev.stopPropagation();
	}
}
