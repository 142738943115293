import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { ResponseTypes } from './funds-generated';
import { Table } from '../components/tables';
import { ReallocationControl, ISelectListItem, ISelectListItemGroup } from './components/reallocation-control';
import TransactionsQueryModel = ResponseTypes.TransactionsQueryModel;
import RecurringSchedulesQueryModel = ResponseTypes.RecurringSchedulesQueryModel;

export function init() {
	let isTransactionReallocation = true;
	let grid = document.querySelector('#portal-reallocate-transactions-grid');
	let query: TransactionsQueryModel | RecurringSchedulesQueryModel;
	if (!grid) {
		grid = document.querySelector('#portal-reallocate-recurring-schedules-grid');
		isTransactionReallocation = false;
		query = JSON.parse(document.getElementById('query').innerText) as RecurringSchedulesQueryModel;
	} else {
		query = JSON.parse(document.getElementById('query').innerText) as TransactionsQueryModel;
	}
	const container = document.querySelector('[data-reallocation-control]');
	const totalTransactionCount = parseInt(container.getAttribute('data-total-transaction-count'));
	const shownTransactionCount = Math.min(parseInt(container.getAttribute('data-shown-transaction-count')), totalTransactionCount);
	const urlReallocate = container.getAttribute('data-action-url');
	const filters = container.getAttribute('data-filters');
	const availableFunds = JSON.parse(document.getElementById('availableFunds').innerText) as ISelectListItem[];
	const availableFundsGrouped = this.getAvailableFundsGrouped(availableFunds) as ISelectListItemGroup[];

	let reallocationControl: ReallocationControl | null;
	ReactDOM.render(<ReallocationControl
		ref={ref => reallocationControl = ref}
		totalTransactionCount={totalTransactionCount}
		availableFunds={availableFunds}
		availableFundsGrouped={availableFundsGrouped}
		filters={filters}
		query={query}
		urlReallocate={urlReallocate}
		shownTransactionCount={shownTransactionCount}
		itemToReallocateName={isTransactionReallocation ? 'transaction' : 'recurring schedule'}
		isTransactionReallocation={isTransactionReallocation} />, container);

	// ReSharper disable once Html.EventNotResolved
	grid.addEventListener('gridselectionchange', (event: CustomEvent) => {
		const table = event.detail.table as Table;
		const selectedPaymentKeys = table.getSelectedRows().map(x => parseInt(x.getAttribute('data-payment-key')));
		const reallocateAll = table.getReallocateAll();

		if (reallocationControl) {
			reallocationControl.setState({
				selectedTransactionKeys: selectedPaymentKeys,
				reallocateAll: reallocateAll,
			});
		}
	});

	var filterBtn = document.getElementById('filter-btn');
	if (filterBtn) {
		filterBtn.addEventListener('click', (event: CustomEvent) => {
			event.preventDefault();
			const filters = document.getElementsByClassName('advanced-filters-section-toogle')[0] as HTMLElement;
			if (filters.style.display === 'block') {
				filters.style.display = 'none';
				if (filterBtn.classList.contains('dropup')) {
					filterBtn.classList.remove('dropup');
				}
			} else {
				filters.style.display = 'block';
				filterBtn.classList.add('dropup');
			}
		});
	}

	var payerSearch = document.getElementById('PayerSearch');
	var payerSearchAddon = document.getElementById('SearchAddon');
	if (payerSearch && payerSearchAddon) {
		payerSearch.addEventListener('focus', () => {
			payerSearchAddon.classList.add('active');
		});
		payerSearch.addEventListener('blur', () => {
			if (payerSearchAddon.classList.contains('active')) {
				payerSearchAddon.classList.remove('active');
			}
		});
	}
}

export function getAvailableFundsGrouped(items: ISelectListItem[]): ISelectListItemGroup[] {
	const forListing: ISelectListItemGroup = { Label: 'For listing', Options: [] };
	const allFunds: ISelectListItemGroup = { Label: 'All funds', Options: [] };

	items.map(obj => {
		if(obj.Group === 'For listing') {
			forListing.Options.push(obj);
		} else {
			allFunds.Options.push(obj);
		}
	});

	return [forListing, allFunds];
}
