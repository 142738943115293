import { ICancellablePromise } from '../../utils/cancellable-promise';
import * as Models from './index';
import { PostError, post as postCore } from './ajax-client';




namespace Csrf {
	export function appendToken(data: any): any {
		return Object.assign({}, getCsrfToken(), data);
	}

	function arrayFrom<T>(a: any) {
		return [].slice.call(a) as T[];
	}

	function getCsrfToken() {
		let r = {};
		for (let i of arrayFrom<HTMLInputElement>(document.querySelectorAll('[data-csrf-token-holder] input'))) {
			r[i.name] = i.value;
		}
		return r;
	}
}

export default class AjaxDataService implements Models.IDataService {

	showUserMessage = x => alert(x);

	searchMembers(request: Models.SearchRequestModel): ICancellablePromise<Models.CommunityMemberSearchResult> {
		return this.post('ajax/Search', request);
	}
	//todo: also return the count of remaining items

	// Move an Account into a (new) Member. When the service returns, its data can replace the current Member
	moveAccount(request: Models.GroupPersonWithCommunityMemberRequestModel): ICancellablePromise<Models.GroupPersonWithCommunityMemberResponseModel> {
		return this.post('ajax/GroupPersonWithCommunityMember', request);
	}


	moveMember(request: Models.MergeCommunityMembersRequestModel): ICancellablePromise<Models.MergeCommunityMembersResponseModel> {
		return this.post('ajax/MergeCommunityMembers', request);
	}

	//save a member's new details. Can possibly Reject with a Ajax.ValidationErrors
	//todo: handle the server side validation error
	editMember(request: Models.EditCommunityMemberRequestModel): ICancellablePromise<Models.EditMemberResponse> {
		return this.post<Models.EditMemberResponse>('ajax/UpdateCommunityMember', request, e => {
			if (e.conflict) {
				return Promise.resolve(new Models.ConflictedCommunityMemberModel(JSON.parse(e.response || null)));
			}
			if (e.validationErrors) {
				return Promise.resolve(new Models.ValidationErrors(e.validationErrors));
			}
			return null;
		});
	}

	ungroup(request: Models.UngroupPersonRequestModel): ICancellablePromise<Models.UngroupPersonResponseModel> {
		return this.post('ajax/UngroupPerson', request);
	}

	memberCustomStatements(request: Models.GivingStatementsRequestModel): ICancellablePromise<Models.GivingStatementsByNameAndPreviewabilityResponseModel[]> {
		return this.post('ajax/CustomGivingStatements', request);
	}

	regenerateCustomGivingStatement(request: Models.RegenerateCustomStatementRequestModel): ICancellablePromise<any> {
		return this.post('ajax/RegenerateCustomGivingStatement', request);
	}

	private post<T>(url: string, data: any, interceptPostError?: (e: PostError) => Promise<T>) {
		return postCore<T>(url, Csrf.appendToken(data)).catch(e => {
			if (e instanceof PostError) {
				let interceptionResult = interceptPostError && interceptPostError(e);
				if (interceptionResult) {
					return interceptionResult;
				}

				if (e.shouldReloadPage || e.timedout) {
					this.showUserMessage(e.userError);
					document.location.reload(true);
					return null;
				}
			}

			return Promise.reject(e);
		});
	}
}
