import * as React from 'react';
import * as styles from './color-picker-button.less';
import { observer } from 'mobx-react';
import ChromePicker from 'react-color/lib/Chrome';
import { getFieldError, injectValidationFormContext, IHaveValidatableFormContext } from '../../../components/form-controls/validatable-form-context';

export interface IColorPickerButtonProps extends IHaveValidatableFormContext {
	name: string;
	uniqueSuffix?: string;
	value: string;
	onChangeHandler: (event: React.FormEvent<HTMLInputElement>) => void;
	onFocusHandler?: () => void;
	inputRef?: HTMLInputElement;
}

export interface IColorPickerButtonState {
	displayColorPicker: boolean;
}

@injectValidationFormContext
@observer
export class ColorPickerButton extends React.Component<IColorPickerButtonProps, IColorPickerButtonState> {

	constructor(props) {
		super(props);
		this.state = {
			displayColorPicker: false,
		};
	}

	render() {
		const { value, name } = this.props;
		const error = getFieldError(this.props, name);
		return (
			<div>
				<div className={styles.button} onClick={this.handlePickerToggle}>
					<div className={styles.colorBox} style={{ backgroundColor: value }} />
				</div>
				{this.state.displayColorPicker &&
					<div className={styles.popover}>
						<div className={styles.cover} onClick={this.handlePickerClose} />
						<ChromePicker disableAlpha={true} color={value} onChangeComplete={this.onPickChange} />
						{error && this.getErrorMessage(error)}
					</div>
				}
			</div>
		);
	}

	private getErrorMessage(error :string) {
		return (
			<div className={styles.errorMessage}>{error}</div>
		);
	}

	private setNativeValue = (element, value) => {
		// from https://stackoverflow.com/a/51220004/275754
		const prototype = Object.getPrototypeOf(element);
		const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value').set;

		prototypeValueSetter.call(element, value);
	}

	private workAround_SetInputOfColorFieldAndRaiseInputEvent_BecauseReactColorSwallowsEventObject = (value) => {
		const { inputRef } = this.props;

		// because this component doesn't know anything about jQuery.Validation,
		// this is a work around to:
		// 1. populate the hidden color input with the new value. Doing so does not trigger an event.
		this.setNativeValue(inputRef, value.hex);
		// 2. trigger the color input's onInput event.
		const inputEvent = document.createEvent('Event');
		inputEvent.initEvent('input', true, true);
		inputRef.dispatchEvent(inputEvent);
		// ... which will then cascade into triggering jQuery.validation and this.onChange.
	}

	private onPickChange = (value) => {
		this.workAround_SetInputOfColorFieldAndRaiseInputEvent_BecauseReactColorSwallowsEventObject(value);
	}

	private handlePickerToggle = () => {
		this.setState({ displayColorPicker: !this.state.displayColorPicker });
	}

	private handlePickerClose = () => {
		this.setState({ displayColorPicker: false });
	}
}
