import {
	CampaignOverviewViewModel,
	PledgeRowViewModel,
	GetPledgesResponse,
	CampaignOverviewResponse,
	GetPledgesRequest,
	DeletePledgeRequest,
	PaymentLabel,
	DeletePledgeResponse, PledgeTableViewModel, CampaignStatisticsViewModel, PledgeStatisticsViewModel, PledgeLabel, PledgeRowWithTotalsViewModel,
} from '../../campaigns-generated';
import { connectMachine, IHaveMachine } from '../../../../Shared/state-machine/connect-machine';
import { Actions, TABLE_PAGE_SIZE } from './campaign-details-states-events-and-actions';
import { getCampaignDetailsConfig } from './campaign-details-machine';
import { MachineContext } from '../../../../Shared/state-machine/saga-state-machine';
import { action, computed, observable } from 'mobx';
import { CampaignDetailsTabIdentifiers } from '../../components/campaign-details-tabs/campaign-details-tabs';
import { generateCursor } from '../../campaigns-data-service';

@connectMachine(getCampaignDetailsConfig, Actions.handlers)
export class CampaignDetailsMainViewModel implements IHaveMachine {
	readonly machineContext: MachineContext;

	@observable
	overviewTabViewModel: CampaignOverviewTabViewModel;

	currentTabId: CampaignDetailsTabIdentifiers = CampaignDetailsTabIdentifiers.Overview;

	constructor(public mainDetailsViewModel: CampaignOverviewViewModel) {
		this.overviewTabViewModel = new CampaignOverviewTabViewModel(
			this.mainDetailsViewModel.GoalAmount,
			this.mainDetailsViewModel.PledgeLabel,
		)
	}
}

export class CampaignOverviewTabViewModel {
	@observable pledgeTableData: PledgeTableViewModel<PledgeRowViewModel | PledgeRowWithTotalsViewModel> = null;
	@observable campaignStatistics: CampaignStatisticsViewModel = null;
	@observable pledgeStatistics: PledgeStatisticsViewModel = null;
	@observable cursorOffset: number = 0;
	@observable searchQuery: string = '';
	@observable pledgeLabel: PledgeLabel = null;
	@observable paymentLabel: PaymentLabel = null;
	@observable pledgeExportUrl: string = '';
	@observable isExporting: boolean = false;

	constructor(public goalAmount: number, pledgeLabel: PledgeLabel) {
		this.pledgeLabel = pledgeLabel;
	}

	@computed
	get pageNumber(): number {
		return this.cursorOffset / TABLE_PAGE_SIZE + 1;
	}

	@computed
	get pageCount(): number {
		return this.pledgeTableData && Math.ceil(this.pledgeTableData.TotalNumberOfPledges / TABLE_PAGE_SIZE);
	}

	@computed
	get canExportPledges(): boolean {
		return !!(this.pledgeTableData && this.pledgeTableData.TotalNumberOfPledges) && !!this.pledgeExportUrl;
	}

	@action.bound
	setInitialData(response: CampaignOverviewResponse) {
		this.pledgeStatistics = response.PledgeStatistics;
		this.pledgeTableData = response.PledgeTableData;
		this.campaignStatistics = response.CampaignStatistics;
		this.paymentLabel = response.PaymentLabel;
		this.pledgeExportUrl = response.PledgeExportUrl;
	}

	@action.bound
	setData(response: GetPledgesResponse) {
		this.pledgeTableData = response.PledgeTableData;
	}

	@action.bound
	setDataAfterDelete(response: DeletePledgeResponse) {
		this.pledgeTableData = response.PledgeTableData;
		this.pledgeStatistics = response.PledgeStatistics;
	}

	@action.bound
	getTableDataRequest(pageNumber: number, campaignId: number): GetPledgesRequest {
		this.cursorOffset = (pageNumber - 1) * TABLE_PAGE_SIZE;
		return {
			CampaignId: campaignId,
			Cursor: generateCursor(this.cursorOffset),
			NumberOfRecords: TABLE_PAGE_SIZE,
			Query: this.searchQuery,
		} as GetPledgesRequest;
	}

	@action.bound
	getDeletePledgeRequest(pageNumber: number, campaignId: number, pledgeId: number): DeletePledgeRequest {
		// If NOT on page 1 AND only 1 record in current result set, set page number to previous page
		// Otherwise, you will have an empty table when no pledges are returned for the current page.
		if (pageNumber !== 1 && this.pledgeTableData.Results.length === 1) {
			pageNumber = pageNumber - 1;
		}

		this.cursorOffset = (pageNumber - 1) * TABLE_PAGE_SIZE;
		return {
			CampaignId: campaignId,
			Cursor: generateCursor(this.cursorOffset),
			NumberOfRecords: TABLE_PAGE_SIZE,
			Query: this.searchQuery,
			PledgeId: pledgeId,
		} as DeletePledgeRequest;
	}

	@action
	updatePledgeLabelForStorybook = (pledgeLabel: PledgeLabel) => {
		this.pledgeLabel = pledgeLabel;
	}
}