import * as React from 'react';
import * as ReactDnD from 'react-dnd';
import {getEmptyImage} from 'react-dnd-html5-backend';
import {observer} from 'mobx-react';
import * as ViewModels from '../view-models/index';

export namespace DragDropTypes {
	export const member = 'member';
	export const account = 'account';

	export function dispatchDropTarget<R>(monitor: ReactDnD.DropTargetMonitor, handlers: {
		member?: (o: ViewModels.Member) => R,
		account?: (o: ViewModels.Account) => R,
	}): R {
		let h = handlers[monitor.getItemType()];
		return h ? h((monitor.getItem() as any).vm) : undefined;
	}
}

export interface IDragSourcePropsBase {
	connectDragSource?: ReactDnD.ConnectDragSource;
	connectDragPreview?: ReactDnD.ConnectDragPreview;
	isDragging?: boolean;
	shouldHideElementOnDragBecauseIEIsStillTheWorst?: boolean;
	canDrag?: boolean;
}

export interface IDropTargetPropsBase {
	connectDropTarget?: ReactDnD.ConnectDropTarget;
	isOver?: boolean;
	canDrop?: boolean;
}

export interface VmProps<TVM> {
	vm: TVM;
}

/// define a stateless react component with this generic-typed helper
export function defineView<TVM>(renderFunction: (vm: TVM) => JSX.Element): React.ClassicComponentClass<VmProps<TVM>> {
	return observer(props => renderFunction(props.vm)) as any;
}

export function hideDragPreview<T extends React.Component<IDragSourcePropsBase, {}>>(constructor: new (props) => T): new (props) => T {
	let oldFunction = constructor.prototype['componentDidMount'];
	constructor.prototype['componentDidMount'] = function () {
		(this as T).props.connectDragPreview(getEmptyImage(), {

			/* tslint:disable max-line-length */
			//todo: IE support (see comments on https://github.com/gaearon/react-dnd/blob/master/examples/02%20Drag%20Around/Custom%20Drag%20Layer/DraggableBox.js)
			// IE fallback: specify that we'd rather screenshot the node
			// when it already knows it's being dragged so we can hide it with CSS.
			/* tslint:enable max-line-length */
			captureDraggingState: true
		});

		if(oldFunction) {
			oldFunction.apply(this, arguments);
		}
	};
	return constructor;
}

//like https://github.com/JedWatson/classnames/blob/master/index.js but shorter
export function classNames(...classes: (string | { [s: string]: boolean })[]) {
	let aa = classes.map(c => {
		return typeof c === 'string' ? [c] : Object.getOwnPropertyNames(c || {}).filter(k => c[k]);
	});

	return new Array<string>().concat(...aa).join(' ');
}

export const dropTargetCollector: ReactDnD.DropTargetCollector = (connect, monitor) => ({
	connectDropTarget: connect.dropTarget(),
	isOver: monitor.isOver(),
	canDrop: monitor.canDrop()
});

var ie = window.navigator.userAgent.indexOf('MSIE') >= 0 ||
	window.navigator.userAgent.indexOf('Trident') >= 0 ||
	window.navigator.userAgent.indexOf('Edge') >= 0; //ffs

export const dragSourceCollector: ReactDnD.DragSourceCollector = (connect, monitor) => ({
	connectDragSource: connect.dragSource(),
	connectDragPreview: connect.dragPreview(),
	isDragging: monitor.isDragging(),
	shouldHideElementOnDragBecauseIEIsStillTheWorst: monitor.isDragging() && ie,
	canDrag: monitor.canDrag(),
});
