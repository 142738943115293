export class Utils {
	static forceReflow(element: HTMLElement) {
		//Forces browser to trigger reflow
		//http://stackoverflow.com/questions/21664940/force-browser-to-trigger-reflow-while-changing-css
		//wrapping it inside a function to stop TSLint complaining
		((offset: number) => { })(element.offsetWidth);
	}

	static escapeRegex(str: string) : string {
		const reRegExpChar = /[\\^$.*+?()[\]{}|]/g;
		const reHasRegExpChar = RegExp(reRegExpChar.source);
		return (str && reHasRegExpChar.test(str))
			? str.replace(reRegExpChar, '\\$&')
			: str;
	}
}
