import { IStoreLoader, StoreLoaderBase } from '../../Shared/helpers/store-loader-base';
import { CcbIntegrationConfigurationStore } from '../integrations/pages/configure-ccb';
import { ElvantoIntegrationConfigurationStore } from '../integrations/pages/configure-elvanto';
import { FellowshipOneIntegrationConfigurationStore } from '../integrations/pages/configure-fellowship-one';
import { ThinkMinistryIntegrationConfigurationStore } from '../integrations/pages/configure-thinkministry';
import { PlanningCenterIntegrationConfigurationStore } from '../integrations/pages/configure-planningcenter';
import { ShelbyArenaIntegrationConfigurationStore } from '../integrations/pages/configure-shelbyarena';
import { ImportFundsStore } from '../funds/components/import-funds-panel';
import DataService from '../community-member/models/ajax-data-service';
import Main from '../community-member/view-models/main';
import { BatchListViewModel } from '../check-deposit/batch/batch-list-view-model';
import { getCheckDepositDataService } from '../check-deposit/check-deposit-data-service';
import { AllocationViewModel } from '../check-deposit/allocation/allocation-view-model';
import { RegisteredScannersViewModel } from '../check-deposit/scanners/registered-scanners-viewmodel';
import { VirtualTerminalMainViewModel } from '../virtual-terminal/pages/single/single-entry-main-view-model';
import { BatchListMainViewModel } from '../virtual-terminal/pages/batch-list/batch-list-main-view-model';
import { BatchEntryMainViewModel } from '../virtual-terminal/pages/batch-entry/batch-entry-main-view-model';
import { EntryDashboardMainViewModel } from '../virtual-terminal/pages/entry-dashboard/entry-dashboard-main-view-model';
import { MultiSelectedItemsStore } from '../components/multi-selected-items-old/multi-selected-items-old';
import { TransactionImportFundMatchingViewModel } from '../transaction-import/transaction-import-fund-matching-view-model';
import { TransactionImportSummaryViewModel } from '../transaction-import/transaction-import-summary-view-model';
import { TransactionImportCreateViewModel } from '../transaction-import/transaction-import-create-view-model';
import { ScheduleImportCreateViewModel } from '../schedule-import/pages/create-import/schedule-import-create-view-model';
import { ScheduleImportFileUploadViewModel } from '../schedule-import/pages/file-upload/schedule-import-file-upload-view-model';
import { ScheduleImportFundMatchingViewModel } from '../schedule-import/pages/fund-matching/schedule-import-fund-matching-view-model';
import { ScheduleImportProgressViewModel } from '../schedule-import/pages/import-progress/schedule-import-progress-view-model';
import { ScheduleImportReviewViewModel } from '../schedule-import/pages/review-import/schedule-import-review-view-model';
import { ScheduleImportSetupAlertViewModel } from '../schedule-import/pages/setup-alert/schedule-import-setup-alert-view-model';
import { ScheduleMigrationEditViewModel } from '../schedule-import/pages/edit-migration/schedule-migration-edit-view-model';
import { ImportFileUploadStore } from '../transaction-import/import-file-upload-store';
import { QboConfigurationMainViewModel } from '../qbo-integration/pages/configuration/qbo-configuration-main-view-model';
import { getTransactionImportDataService } from '../transaction-import/transaction-import-data-service';
import { ServiceTimeMainViewModel } from '../settings/pages/service-time/service-time-main-view-model';
import { brandingSettingsSaga, createContext } from '../branding-settings/branding-settings-saga';
import { runSaga } from '../utils/saga-utils';
import { ResourceCenterDashboardViewModel } from '../resource-center/resource-center-dashboard-view-model';
import { MigrationSummaryDashboardViewModel } from '../schedule-import/pages/dashboard/migration-summary-dashboard-view-model';
import { SchedulesDetailsViewModel } from '../schedule-import/pages/schedules-details/schedules-details-view-model';
import { GivingStatementsViewModel } from '../giving-statements/giving-statements-view-model';
import { CreateCampaignMainViewModel } from '../campaigns/pages/create-campaign/create-campaign-main-view-model';
import { CampaignsLandingMainViewModel } from '../campaigns/pages/campaigns-landing/campaigns-landing-main-view-model';
import { CampaignsOutageMainViewModel } from '../campaigns/pages/campaigns-outage/campaigns-outage-main-view-model';
import { CampaignDetailsMainViewModel } from '../campaigns/pages/campaign-details/campaign-details-main-view-model';
import { PledgeEntryMainViewModel } from '../campaigns/pages/pledge-entry/pledge-entry-main-view-model';
import { PreconfiguredGivingLinkViewModel } from '../preconfigured-giving-links/preconfigured-giving-links-view-model';

const loaders: IStoreLoader[] = [
	{
		name: 'CcbIntegrationConfigurationStore',
		storeInit: (data) => {
			return new CcbIntegrationConfigurationStore(data);
		}
	},
	{
		name: 'ElvantoIntegrationConfigurationStore',
		storeInit: (data) => {
			return new ElvantoIntegrationConfigurationStore(data);
		}
	},
	{
		name: 'FellowshipOneIntegrationConfigurationStore',
		storeInit: (data) => {
			return new FellowshipOneIntegrationConfigurationStore(data);
		}
	},
	{
		name: 'ThinkMinistryIntegrationConfigurationStore',
		storeInit: (data) => {
			return new ThinkMinistryIntegrationConfigurationStore(data);
		}
	},
	{
		name: 'PlanningCenterIntegrationConfigurationStore',
		storeInit: (data) => {
			return new PlanningCenterIntegrationConfigurationStore(data);
		}
	},
	{
		name: 'ShelbyArenaIntegrationConfigurationStore',
		storeInit: (data) => {
			return new ShelbyArenaIntegrationConfigurationStore(data);
		}
	},
	{
		name: 'ImportFundsStore',
		storeInit: (data) => {
			return new ImportFundsStore(data);
		}
	},
	{
		name: 'CommunityMembersConsolidatorStore',
		storeInit: (data) => {
			return new Main(new DataService(), data);
		}
	},
	{
		name: 'VirtualTerminalMainViewModel',
		storeInit: (data) => {
			return new VirtualTerminalMainViewModel(data);
		}
	},
	{
		name: 'BatchListMainViewModel',
		storeInit: (data) => {
			return new BatchListMainViewModel(data);
		}
	},
	{
		name: 'BatchEntryMainViewModel',
		storeInit: (data) => {
			return new BatchEntryMainViewModel(data);
		}
	},
	{
		name: 'EntryDashboardMainViewModel',
		storeInit: (data) => {
			return new EntryDashboardMainViewModel(data);
		}
	},
	{
		name: 'AllocationViewModel',
		storeInit: (data) => {
			return new AllocationViewModel(getCheckDepositDataService(), data);
		}
	},
	{
		name: 'CheckDepositBatchListViewModel',
		storeInit: (data) => {
			return new BatchListViewModel(getCheckDepositDataService(), data);
		}
	},
	{
		name: 'RegisteredScannersViewModel',
		storeInit: (data) => {
			return new RegisteredScannersViewModel(data);
		}
	},
	{
		name: 'MultiSelectedItemsStore',
		storeInit: (data) => {
			return new MultiSelectedItemsStore();
		}
	},
	{
		name: 'TransactionImportFundMatchingViewModel',
		storeInit: (data) => {
			return new TransactionImportFundMatchingViewModel(data);
		}
	},
	{
		name: 'TransactionImportUploadStore',
		storeInit: (data) => {
			return new ImportFileUploadStore(data, getTransactionImportDataService());
		}
	},
	{
		name: 'TransactionImportSummaryViewModel',
		storeInit: (data) => {
			return new TransactionImportSummaryViewModel(data);
		}
	},
	{
		name: 'TransactionImportCreateViewModel',
		storeInit: (data) => {
			return new TransactionImportCreateViewModel(data);
		}
	},
	{
		name: 'ScheduleImportCreateViewModel',
		storeInit: (data) => {
			return new ScheduleImportCreateViewModel(data);
		}
	},
	{
		name: 'ScheduleImportFileUploadViewModel',
		storeInit: (data) => {
			return new ScheduleImportFileUploadViewModel(data);
		}
	},
	{
		name: 'ScheduleImportFundMatchingViewModel',
		storeInit: (data) => {
			return new ScheduleImportFundMatchingViewModel(data);
		}
	},
	{
		name: 'ScheduleImportProgressViewModel',
		storeInit: (data) => {
			return new ScheduleImportProgressViewModel(data);
		}
	},
	{
		name: 'ScheduleImportReviewViewModel',
		storeInit: (data) => {
			return new ScheduleImportReviewViewModel(data);
		}
	},
	{
		name: 'ScheduleImportSetupAlertViewModel',
		storeInit: (data) => {
			return new ScheduleImportSetupAlertViewModel(data);
		}
	},
	{
		name: 'ScheduleMigrationEditViewModel',
		storeInit: (data) => {
			return new ScheduleMigrationEditViewModel(data);
		}
	},
	{
		name: 'ServiceTimeMainViewModel',
		storeInit: (data) => {
			return new ServiceTimeMainViewModel(data);
		}
	},
	{
		name: 'QuickBooksConfigurationViewModel',
		storeInit: (data) => {
			return new QboConfigurationMainViewModel(data);
		}
	},
	{
		name: 'BrandingSettingsMainViewModel',
		storeInit: (data) => {
			const context = createContext(data);
			runSaga({}, () => brandingSettingsSaga(context ));
			return context;
		},
	},
	{
		name: 'ResourceCenterDashboardViewModel',
		storeInit: (data) => {
			return new ResourceCenterDashboardViewModel(data);
		}
	},
	{
		name: 'MigrationSummaryDashboardViewModel',
		storeInit: (data) => {
			return new MigrationSummaryDashboardViewModel(data);
		},
	},
	{
		name: 'SchedulesDetailsViewModel',
		storeInit: (data) => {
			return new SchedulesDetailsViewModel(data);
		},
	},
	{
		name: 'GivingStatementsViewModel',
		storeInit: (data) => {
			return new GivingStatementsViewModel(data);
		},
	},
	{
		name: 'CreateCampaignMainViewModel',
		storeInit: (data) => {
			return new CreateCampaignMainViewModel(data);
		},
	},
	{
		name: 'CampaignsLandingMainViewModel',
		storeInit: (data) => {
			return new CampaignsLandingMainViewModel(data);
		},
	},
	{
		name: 'CampaignsOutageMainViewModel',
		storeInit: (data) => {
			return new CampaignsOutageMainViewModel(data);
		},
	},
	{
		name: 'CampaignDetailsMainViewModel',
		storeInit: (data) => {
			return new CampaignDetailsMainViewModel(data);
		},
	},
	{
		name: 'PledgeEntryMainViewModel',
		storeInit: (data) => {
			return new PledgeEntryMainViewModel(data);
		},
	},
	{
		name: 'PreconfiguredGivingLinkViewModel',
		storeInit: (data) => {
			return new PreconfiguredGivingLinkViewModel(data);
		},
	},
];

const singleton = new StoreLoaderBase(loaders);
export default singleton;
