import * as React from 'react';
import { AddMemberFormContainer, IAddMemberFormProps } from '../../../community-member/views/add-member-form-container';
import { getVirtualTerminalDataService } from '../../single-entry-data-service';
import { MemberFormBody } from '../../../community-member/components/member-form-body';
import { MemberFormFields } from '../../../community-member/components/member-form-fields';
import { MemberFormFooter } from '../../../community-member/components/member-form-footer';
import { MemberFormHeading } from '../../../community-member/components/member-form-heading';
import { PaymentLabel } from '../../virtual-terminal-generated';

export class AddMemberDialog extends React.Component<IAddMemberFormProps & { paymentLabel?: PaymentLabel }, {}> {
	render() {
		const { paymentLabel } = this.props;
		const enableFeatureOrganizationalGiving = this.props.enableFeatureOrganizationalGiving;
		const addMemberSubscriberFactory = getVirtualTerminalDataService().getActionSubscriberFactory('addCommunityMember');
		return(
			<AddMemberFormContainer {...this.props} addMemberActionSubscriberFactory={addMemberSubscriberFactory}>
				<MemberFormHeading>
					Add new member
				</MemberFormHeading>
				<MemberFormBody>
					<MemberFormFields paymentLabel={paymentLabel} organizationalGivingFeatureEnabled={enableFeatureOrganizationalGiving} />
				</MemberFormBody>
				<MemberFormFooter />
			</AddMemberFormContainer>
		);
	}
}
