import * as React from 'react';
import { observer } from 'mobx-react';
import { SvgWrapper } from '../../../LoggedInWeb/components/svg-wrapper';
import { classNames } from '../../utils/classnames';

export interface ICheckboxPrimitiveProps {
	checked: boolean;
	onChange: (value: boolean) => void;
	ariaInvalid?: boolean;
}
export interface ICheckboxCommonProps {
	label: string;
	acceptanceTestTargetId: string;
	className?: string;
	disabled?: boolean;
}

export type ICheckboxProps = ICheckboxCommonProps & ICheckboxPrimitiveProps;


@observer
export class Checkbox extends React.Component<ICheckboxProps> {
	render() {
		const {
			label,
			checked,
			ariaInvalid,
			acceptanceTestTargetId,
			className,
			disabled
		} = this.props;
		return (
			<div className={classNames('checkbox', className, disabled!! ? 'disabled' : null)}>
				<label>
					<input type="checkbox"
						checked={checked}
						name={label}
						onChange={this.onChange}
						aria-invalid={ariaInvalid || undefined}
						data-pp-at-target={acceptanceTestTargetId}
						disabled={disabled}
					/>
					{checked && <SvgWrapper svg="checkbox-ticked" className="svg svg-checkbox-ticked" />}
					<span>{label}</span>
				</label>
			</div>
		);
	}

	private onChange = (ev: React.FormEvent<HTMLInputElement>) => {
		this.props.onChange(ev.currentTarget.checked);
	}
}
