import * as React from 'react';
import { observer } from 'mobx-react';
import { Button } from '../../../components/button';
import { ModalDialog } from '../../../components/modal-dialog';
import { ModalDialogProcessingViewModel } from '../../../components/modal-dialog/modal-dialog-processing-view-model';

@observer
export class CampaignCloseConfirmationModal  extends React.Component<{
	onSubmitHandler: () => void,
	onCancelHandler: () => void,
	viewModel: ModalDialogProcessingViewModel,
	}>
	{
	render() {
		const {
			onCancelHandler,
			viewModel: { isProcessing },
		} = this.props;
		const buttons = [
			<Button
				key="cancel"
				className="btn btn-cancel"
				type="button"
				data-dismiss="modal"
				disabled={isProcessing}
				onClick={onCancelHandler}
				acceptanceTestTargetId = "cancel confirm close campaign button"
			>
				Cancel
			</Button>,
			<Button
				key="submit"
				className="btn btn-default"
				type="submit"
				onClick={this.onSubmitHandler}
				isProcessingRequest={isProcessing}
				acceptanceTestTargetId = "confirm close campaign button"
			>
				{isProcessing ? `Closing` : `Close Campaign`}
			</Button>,
		];

		return (
			<ModalDialog
				title="Are you sure you would like to close this campaign?"
				buttons={buttons}
				showAlert={false}
			>
				{/* tslint:disable:max-line-length */}
				<p className="modal-text">
				Once closed, you can republish it again by navigating to the <strong>Closed</strong> tab and clicking the <strong>Reactivate</strong> button.
				</p>
				{/* tslint:enable:max-line-length */}
			</ModalDialog>
		);
	}

	private onSubmitHandler = () => {
		const { onSubmitHandler } = this.props

		onSubmitHandler();
	}
}
