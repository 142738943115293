import * as React from 'react';
import { ResponseTypes, ModelMetadata } from '../../funds/funds-generated';
import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';

import TooltipComponent from '../components/configuration-section-tooltip';

import { InputField, ValidationMessage, SelectField } from '../../funds/components/form-controls';

import { ListingModel } from '../components/listing-model';
import { IntegrationConfigurationStore } from '../components/integration-configuration-store';
import { AuthenticationSection } from '../components/authentication-section';
import { ConfigurationSectionHeader } from '../components/configuration-section-header';
import { IntegrationContainer } from '../components/integration-container';
import { ListingSettingsEditor } from '../components/listing-configuration';
import { FallbackFundSelector } from '../components/fallback-fund-selector';
import { ConfigurationSection } from 'integrations/components/configuration-section';
import { CcbConfigurationSection } from 'integrations/components/ccb-configuration-section/ccb-configuration-section';
import { CcbMembershipTypeConfigurationModel } from 'integrations/components/ccb-membership-type-configuration-model';
import getFundsForDisplay, { IntegrationFundDisplayItem } from '../helpers/get-integration-funds-for-display';

import CcbConfigurationViewModel = ResponseTypes.CcbConfigurationViewModel;
import CcbListingConfigurationModel = ResponseTypes.CcbListingConfigurationModel;
import IntegrationListingConfigurationViewModel = ResponseTypes.IntegrationListingConfigurationViewModel;
import CcbCampusViewModel = ResponseTypes.CcbCampusViewModel;

const integrationAbbreviation =  "ChMS";
const integrationName =  "Pushpay ChMS";

export class CcbListingModel extends ListingModel<CcbListingConfigurationModel> {
	campuses: ResponseTypes.CcbCampusViewModel[];

	constructor(listing: IntegrationListingConfigurationViewModel<CcbListingConfigurationModel>,
		funds: IntegrationFundDisplayItem[],
		campuses: CcbCampusViewModel[],
		validationError: string = null) {
		super(listing, funds, validationError);
		this.campuses = campuses;
	}

	@computed
	get campus(): string {
		return `${this.model.CampusId || ''}`;
	}
}

export class CcbIntegrationConfigurationStore
	extends IntegrationConfigurationStore<CcbListingConfigurationModel, CcbConfigurationViewModel> {

	@observable
	AnonymousIndividualId: string = null;

	IsMembershipTypeMappingEnabled: boolean = false;

	MembershipTypeConfiguration: CcbMembershipTypeConfigurationModel = null;

	constructor(configuration: CcbConfigurationViewModel) {
		super(configuration);
		this.AnonymousIndividualId = `${configuration.AnonymousIndividualId || ''}`;
		this.IsMembershipTypeMappingEnabled = configuration.IsMembershipTypeMappingEnabled;

		if (this.IsMembershipTypeMappingEnabled) {
			this.MembershipTypeConfiguration = new CcbMembershipTypeConfigurationModel(
				configuration.DefaultCcbOrganizationalTypeId,
				configuration.MembershipTypes,
				configuration.CommunityMemberTypes
			);
		}
	}

	@computed
	get funds() {
		return getFundsForDisplay(this.configuration.IntegrationFunds);
	}

	@computed
	get IsAnonymousIdFieldEnabled() {
		return this.configuration.IsAnonymousFieldEnabled;
	}

	@action.bound
	updateAnonymousIndividualId(anonymousIndividualId: string) {
		this.AnonymousIndividualId = anonymousIndividualId;
	}

	createListing(listing: IntegrationListingConfigurationViewModel<CcbListingConfigurationModel>) {
		const messageWhenMissingFallbackFunds = this.isListingMissingFallbackFund(listing, this.configuration.IntegrationFunds)
			? ModelMetadata.CcbListingConfigurationModel.FallbackFundKey.validationRules.required.errorMessage
			: null;

		return new CcbListingModel(listing,
			this.funds,
			this.configuration.Campuses.orderBy(x => x.CampusName.toLowerCase()).toArray(),
			messageWhenMissingFallbackFunds);
	}
}

@observer
export class CcbIntegration extends React.Component<{ controller: CcbIntegrationConfigurationStore }, {}> {

	renderAuthenticationSection = () => {
		return <CcbAuthenticationSectionContainer controller={this.props.controller} />;
	}

	renderConfigurationSection = () => {
		return <CcbConfigurationSectionContainer controller={this.props.controller} />;
	}

	render() {
		return <IntegrationContainer
			isAuthenticationSectionEditable={this.props.controller.isAuthenticationSectionEditable}
			authenticationSectionFactory={this.renderAuthenticationSection}
			configurationSectionFactory={this.renderConfigurationSection} />;
	}
}

@observer
class CcbAuthenticationSectionContainer extends React.Component<{ controller: CcbIntegrationConfigurationStore }, {}> {
	render() {
		return (
			<AuthenticationSection store={this.props.controller}>
				<div className="form-group">
					<label className="col-md-3 control-label">{this.metadata.TenancyUrl.displayName}</label>
					<div className="col-md-5">
						<InputField type="text" propertyMetadata={this.metadata.TenancyUrl} ignorePanLikeValidation={true}/>
					</div>
					<div className="col-md-3">
						<ValidationMessage for={this.metadata.TenancyUrl.propertyName} />
					</div>
				</div>
				<div className="form-group">
					<label className="col-md-3 control-label">{this.metadata.ApiUsername.displayName}</label>
					<div className="col-md-5">
						<InputField type="text" propertyMetadata={this.metadata.ApiUsername} ignorePanLikeValidation={true} />
					</div>
					<div className="col-md-3">
						<ValidationMessage for={this.metadata.ApiUsername.propertyName} />
					</div>
				</div>
				<div className="form-group">
					<label className="col-md-3 control-label">{this.metadata.ApiPassword.displayName}</label>
					<div className="col-md-5">
						<InputField type="password" propertyMetadata={this.metadata.ApiPassword} />
					</div>
					<div className="col-md-3">
						<ValidationMessage for={this.metadata.ApiPassword.propertyName} />
					</div>
				</div>
			</AuthenticationSection>
		);
	}

	private get metadata() {
		return ModelMetadata.CcbAuthenticationEditModel;
	}
}

const message = `If we run into any trouble matching transactions to ${integrationName}
we'll use the fallback information you've selected here.`;

const anonymousTooltipMessage = `For use when recording anonymous cash gifts.
Just enter the ${integrationName} Profile ID for the person you wish to assign all anonymous gifts to, you may call this profile "Loose Cash" or similar.
If you don't enter an ID all anonymous payments will be recorded in ${integrationAbbreviation} as unassigned.`;

// ReSharper disable once InconsistentNaming
const CcbListingRowHeader: React.Factory<null> = React.createFactory(() => (
	<span><span>Select fallback funds and matching campuses </span><TooltipComponent message={message} /></span>
));

@observer
class CcbConfigurationSectionContainer extends React.Component<{ controller: CcbIntegrationConfigurationStore }, {}> {
	renderOrganizationSettings = () => {
		const { AnonymousIndividualId } = this.props.controller;
		const anonymousIndividualId = typeof AnonymousIndividualId === 'string' ? AnonymousIndividualId : '';
		return (
			<div className="organization-settings">
				<div className="form-group">
					<label className="col-md-3 control-label">{this.metadata.AnonymousIndividualId.displayName}
						<TooltipComponent message={anonymousTooltipMessage} />
					</label>
					<div className="col-md-5">
						<InputField type="text"
							propertyMetadata={this.metadata.AnonymousIndividualId}
							ignorePanLikeValidation={true}
							onChange={this.handleAnonymousIndividualIdChange}
							value={anonymousIndividualId} />
					</div>
					<div className="col-md-3">
						<ValidationMessage for={this.metadata.AnonymousIndividualId.propertyName} />
					</div>
				</div>
			</div>
		);
	}

	render() {
		let ccbConfigurationSection = null;

		if (this.props.controller.IsMembershipTypeMappingEnabled) {
			ccbConfigurationSection = <CcbConfigurationSection
				store={this.props.controller}
				headerComponentFactory={CcbListingRowHeader}
				organizationSettings={this.props.controller.IsAnonymousIdFieldEnabled && this.renderOrganizationSettings()}
				listingSettingsEditor={CcbListingSettingsEditor} />;
		} else {
			ccbConfigurationSection = <ConfigurationSection
				store={this.props.controller}
				headerComponentFactory={CcbListingRowHeader}
				organizationSettings={this.props.controller.IsAnonymousIdFieldEnabled ? this.renderOrganizationSettings() : null}
				listingSettingsEditor={CcbListingSettingsEditor} />;
		}

		return (
			<div>
				<ConfigurationSectionHeader store={this.props.controller} />
				{ccbConfigurationSection}
			</div>
		);
	}

	handleAnonymousIndividualIdChange = (ev: React.FormEvent<HTMLInputElement>) => {
		this.props.controller.updateAnonymousIndividualId(ev.currentTarget.value);
	}

	get metadata() {
		return ModelMetadata.CcbConfigurationEditModel;
	}
}

@observer
class CcbListingSettingsEditor extends React.Component<{ listing: CcbListingModel }, {}> {
	render() {
		return (
			<ListingSettingsEditor>
				<FallbackFundSelector
					propertyMetadata={this.metadata.FallbackFundKey}
					listing={this.props.listing}
					defaultValue={this.props.listing.model.FallbackFundKey} />
				<div className="form-group">
					<SelectField propertyMetadata={this.metadata.CampusId}
						floatingLabel="Select campus (Optional)"
						defaultValue={this.props.listing.campus}>
						<option value="">- Select campus (Optional) -</option>
						${this.props.listing.campuses.map(x => <option key={x.CampusId} value={`${x.CampusId}`}>{x.CampusName}</option>)}
					</SelectField>
					<ValidationMessage for={this.metadata.CampusId.propertyName} />
				</div>
			</ListingSettingsEditor>
		);
	}

	get metadata() {
		return ModelMetadata.CcbListingConfigurationModel;
	}
}
