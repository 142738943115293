import * as React from 'react';
import * as styles from './simple-progress-bar.less';

export interface IProgressBarProps {
	percentComplete: number;
}

export const SimpleProgressBar: React.StatelessComponent<IProgressBarProps> = (props) => {
	const { percentComplete } = props;
	const progressBarStyle: React.CSSProperties = {
		width: `${percentComplete}%`
	};

	return (
		<div className={styles.progress}>
			<div className={styles.progressBar} role="progressbar"
				aria-valuenow={percentComplete} aria-valuemin={0} aria-valuemax={100} style={progressBarStyle} data-tooltip-text={`${percentComplete}%`}>
			</div>
		</div>
	);
};
