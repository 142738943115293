import * as React from 'react';
import { observer } from 'mobx-react';
import { CampaignsCallToAction } from './campaigns-call-to-action';
import { Link } from '../../../components/link/link';
import { classNames } from '../../../../Shared/utils/classnames';

import * as styles from './campaigns-call-to-action.less';

export interface LiveCampaignRequiredProps {
	createCampaignUrl: string;
	campaignLandingUrl: string;
}

export const LiveCampaignRequired = observer(({ createCampaignUrl, campaignLandingUrl }: LiveCampaignRequiredProps) => (
	<CampaignsCallToAction
		getHeader={() => `To 'Add a pledge' you need to have a live Campaign`}
		getLinks={() =>
			<div className={styles.liveCampaignRequiredButtons}>
				<Link href={campaignLandingUrl}
					title="Go to Campaigns"
					className={classNames('btn', 'btn-ghost', styles.liveCampaignRequiredGoto)}
					acceptanceTargetId="Go to campaigns"
				>
					Go to Campaigns
				</Link>
				{!NewFeatures.EnableNewCampaignsExperience && (
					<Link
						href={createCampaignUrl}
						title="Create a Campaign"
						className="btn btn-ghost"
						acceptanceTargetId="Create a new campaign"
					>
						Create a Campaign
					</Link>
				)}
			</div>
		}
	/>
));
