import { isObservable, toJS } from 'mobx';
import { post } from '../utils/ajax-client';
import { AjaxUtils } from '../utils/ajax-utils';
import { EventTimeApiConfig } from './settings-generated';
import { ICancellablePromise } from '../utils/cancellable-promise';

export interface IApiAction<TRequest, TResponse = any> {
	readonly url: (model: TRequest) => string;
	request: TRequest;
	response: TResponse;
}

export type SettingsActionRequest<TActionKey extends keyof SettingsApiConfigActions> = SettingsApiConfigActions[TActionKey]['request'];
export type SettingsActionResponse<TActionKey extends keyof SettingsApiConfigActions> = SettingsApiConfigActions[TActionKey]['response'];

export type SettingsApiConfigActions = typeof EventTimeApiConfig['actions'];

export type SettingsDataService = {
	[x in keyof SettingsApiConfigActions]: (request: SettingsActionRequest<x>) => ICancellablePromise<SettingsActionResponse<x>>;
};

const options = {
	timeout: 20000,
	newCSRFToken: '',
};

let instance: SettingsDataService | null = null;

function createActionHandler<TKey extends keyof SettingsApiConfigActions>(actionKey: TKey) {
	const actionConfig = EventTimeApiConfig.actions[actionKey] as any;

	return (request: any) => {
		request = isObservable(request) ? toJS(request) : { ...request };

		return post(actionConfig.url(request), request, {
			timeout: options.timeout,
			baseUrl: AjaxUtils.resolveBaseUrl(EventTimeApiConfig.defaultBaseUrl),
			antiForgeryToken: options.newCSRFToken
		}) as ICancellablePromise<any>;
	};
}

function createSettingsDataService(): SettingsDataService {
	return (Object.keys(EventTimeApiConfig.actions) as Array<keyof SettingsApiConfigActions>)
		.reduce<SettingsDataService>((acc: SettingsDataService, actionKey: keyof SettingsApiConfigActions) => {
			acc[actionKey] = createActionHandler(actionKey);
			return acc;
		}, {} as SettingsDataService);
}

export function getSettingsDataService() {
	if (instance === null) {
		instance = createSettingsDataService();
	}

	return instance;
}

export function setNewCRSFoken(token: string) {
	options.newCSRFToken = token;
}

export function mockSettingsDataService(mockDataService: SettingsDataService) {
	instance = mockDataService;
}
