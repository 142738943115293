import * as React from 'react';
import { createNamespace } from '../../../../Shared/helpers/namespace';
import { ExistingAchBankAccountPaymentMethod, ExistingCardPaymentMethod } from './payment-entry-model';
import { SvgWrapper } from '../../../components/svg-wrapper';

const ns = createNamespace('vt-payment-method-existing');

export const PaymentMethodSelectorExistingCard = (props: { existingCard: ExistingCardPaymentMethod }) => {
	const { cardBrandName, lastTwoDigits } = props.existingCard;

	return (
		<div className={ns('option')}>
			<SvgWrapper className={ns('item-icon')} svg={cardBrandName.toLowerCase()} title={cardBrandName} width="32" height="24" />
			<div className={ns('content') + ' text-left'}>
				{`\u25CF\u25CF\u25CF\u25CF \u25CF\u25CF\u25CF\u25CF \u25CF\u25CF\u25CF\u25CF \u25CF\u25CF${lastTwoDigits}`}
			</div>
		</div>
	);
};

export const PaymentMethodSelectorExistingAch = (props: { existingAch: ExistingAchBankAccountPaymentMethod }) => {
	const { bankName, lastTwoDigits, accountNumberLength } = props.existingAch;
	const mask = new Array<string>(accountNumberLength - 2).fill('\u25CF').join('');

	return (
		<div className={ns('option')}>
			<SvgWrapper className={ns('item-icon')} svg="ach" title={bankName} width="32" height="24" />
			<div className={ns('content') + ' text-left'}>{`${mask}${lastTwoDigits}`}</div>
		</div>
	);
};
