import * as React from 'react';
import { Link } from '../link/link';
import * as styles from './styles.less';
import { FormControlLabel } from '../../components/form-controls/form-control-label';
import { SvgWrapper } from '../../components/svg-wrapper';
import { classNames } from '../../../Shared/utils/classnames';
import { Spinner } from '../../../Shared/components/spinner/spinner';
import { TooltipPlacement } from '../tooltip/tooltip';

export interface IQrCodeViewerProps {
	qrCodeUrl: string;
	downloadFileName: string;
	viewerOptions: React.ReactElement<any>;
	isGeneratingQrCode: boolean;
	onQrCodeGenerationComplete: () => void;
	tooltipOptions?: IFormControlLabelledTooltipProps;
}

export interface IFormControlLabelledTooltipProps {
	className?: string;
	tooltipContentClassName?: string;
	message: React.ReactNode;
	placement?: TooltipPlacement;
}

export const QrCodeViewer: React.FC<IQrCodeViewerProps> = (props: IQrCodeViewerProps) => {
	const {
		qrCodeUrl,
		downloadFileName,
		viewerOptions,
		isGeneratingQrCode,
		onQrCodeGenerationComplete,
		tooltipOptions,
	} = props;
	return (
		<div
			className={classNames(
				'form-control-labelled',
				'form-group',
				styles.qrCodeViewerWrapper,
				styles.qrCodePaymentGivingLinkTextCaptionOn
			)}
		>
			<FormControlLabel label="QR Code" elementName="QRCodeViewer" tooltipOptions={tooltipOptions} />
			{viewerOptions && <div>{viewerOptions}</div>}
			<div
				id="QRCodeViewer"
				className={classNames('panel', 'panel-color', 'midgray', styles.qrCodeViewerPanel)}
			>
				<div className={classNames('panel-heading', 'fill', styles.qrCodeViewerPanelHeading)}>
					Preview
				</div>
				<div className={classNames('panel-body', styles.qrCodeViewerPanelBody)}>
					<div className={classNames(styles.qrCodeImageWrapper, qrCodeUrl && styles.qrCodeGenerated)}>
						{isGeneratingQrCode && (
							<div className={styles.qrCodeSpinnerOverlay}>
								<div className={styles.qrCodeSpinnerContainer}>
									<Spinner className={styles.qrCodeSpinner} />
								</div>
							</div>
						)}
						{qrCodeUrl ? (
							<div className={styles.qrCodeImageContainer}>
								<img
									onLoad={onQrCodeGenerationComplete}
									className={styles.qrCodeImage}
									src={qrCodeUrl}
									alt="Generated QR code image"
								/>
							</div>
						) : (
							<figure className={styles.qrCodePlaceholderImageContainer}>
								<SvgWrapper
									className={styles.qrCodePlaceholderImage}
									width="256"
									height="256"
									svg="pushpay-website-qr-code"
									title="QR code preview"
								/>
								<figcaption className={styles.qrCodePlaceholderCaption}>
									QR Code Preview
								</figcaption>
							</figure>
						)}
					</div>
				</div>
				<div
					className={classNames(
						'panel-footer',
						'panel-footer-btn-group',
						styles.qrCodeViewerPanelFooter
					)}
				>
					<Link
						href={qrCodeUrl}
						title="Download QR code"
						download={downloadFileName}
						className={classNames(
							'btn',
							'btn-ghost',
							styles.qrCodeDownloadBtn,
							(qrCodeUrl && !isGeneratingQrCode ? '' : 'disabled')
					)}
						acceptanceTargetId="Download qrcode"
					>
						Download
					</Link>
				</div>
			</div>
		</div>
	);
};
