import * as React from 'react';
import { observer } from 'mobx-react';
import { Fragment } from '../../Shared/components/fragment';
import { StepPillar } from './components/step-pillar';
import { VideoContainer } from './components/video-container';
import { classNames } from '../../Shared/utils/classnames';
import { ResourceCenterStep } from '../loggedinweb-generated';
import { ResourceCenterDashboardViewModel } from './resource-center-dashboard-view-model';
import * as styles from './resource-center-dashboard.less';

export const ResourceCenterDashboard = observer(({ vm }: { vm: ResourceCenterDashboardViewModel }) => (
	<Fragment>
		<h1>Resource center</h1>
		<p className="lead">
			<strong>Welcome!</strong> We're glad you've chosen to partner with Pushpay and can't wait to serve you and
			your church!
		</p>
		<p className="lead">
			Below is some great information that has helped churches across the nation be successful with Pushpay.
		</p>
		<StepContainer steps={vm.model.FiveSteps} />
		<div className={styles.container}>
			<LetterPanel userName={vm.model.UserName} />
			<Videos />
		</div>
	</Fragment>
));

const StepContainer = observer(({ steps }: { steps: ResourceCenterStep[] }) => (
	<div className={styles.stepContainer}>
		{steps.map((step) => (
			<StepPillar key={step.Title} step={step} />
		))}
	</div>
));

const LetterPanel = observer(({ userName }) => (
	<div className={classNames('panel', 'panel-default', styles.letterContainer)}>
		<h2 className={classNames('panel-heading', styles.panelHeading)}>A word from the Chief Customer Officer</h2>
		<div className="panel-body">
			{/* tslint:disable max-line-length */}
			<p>
				<strong>Dear {userName},</strong>
			</p>
			<p>
				Welcome to Pushpay! We’re so excited to be a part of your ongoing efforts to engage and strengthen your
				community. I know that our robust giving and engagement solutions will equip you to lead and serve your
				community with excellence.
			</p>
			<p>
				Here at Pushpay, we’re constantly innovating to make giving and engagement easier, faster, and more
				secure. Pushpay is a one-stop shop for community management and engagement with our combined ChMS,
				digital giving, and custom app solutions. I hope you will have an opportunity to explore all of our
				offerings to find the most seamless solution for you. As a leading partner with an expanding catalog of
				integrations, we’re committed to providing you with industry-leading tools to grow your ministry.
			</p>
			<p>
				Our award-winning coaching and customer service team is dedicated to your success every step of the way.
				Through serving thousands of churches of all sizes, we’ve learned a lot. The churches who experience the
				greatest benefit from our solutions apply the 5 Steps of Success found in our Resource Center. We
				encourage you to lean into these best practices and to reach out to our team if you have any questions.
			</p>
			<p>Best regards,</p>
			<img alt="Signed Molly Matthews" className={styles.signature} src={signatureUrl} />
			<p>Molly Matthews</p>
			<p>Chief Customer Officer, Pushpay</p>
			{/* tslint:disable max-line-length */}
		</div>
		<h2 className={classNames('panel-heading', styles.panelHeading)}>{legalTitle}</h2>
		<div className={classNames('panel-body', styles.panelGuarantee)}>
			<a href={legalUrl} target="_blank" rel="noopener noreferrer">
				<strong>{legalText}</strong>
			</a>
		</div>
	</div>
));

const Videos = observer(() => (
	<div className={styles.videoContainer}>
		<h3 className={styles.videoHeader}>Related videos</h3>
		{videos.map((video) => (
			<VideoContainer key={video.title} {...video} />
		))}
	</div>
));

const videos = [
	{
		title: 'Give Like God Gives - Mother Teresa',
		url: 'https://player.vimeo.com/video/183889546?color=1269a6&title=0&byline=0&portrait=0',
	},
	{
		title: 'Give Like God Gives - Bono on Generosity',
		url: 'https://player.vimeo.com/video/143070110?color=1269a6&title=0&byline=0&portrait=0',
	},
	{
		title: 'Give Like God Gives - Sarah Thebarge How Generosity Heals Us',
		url: 'https://player.vimeo.com/video/149342349?color=1269a6&title=0&byline=0&portrait=0',
	},
];

const [legalTitle, legalText, legalUrl, signatureUrl] = [
	'Legal Center',
	"Here's where you'll find all the legal information for our products, services and websites.",
	'https://pushpay.com/legal-center/portal',
	'https://downloads.pushpay.com/pushpay-app/merchant-resources/church-resources/ResourceCenter/MollyM_Signature.png',
];
