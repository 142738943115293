import * as React from 'react';
import { ModalDialogForm } from '../../../components/modal-dialog-form';
import { DataGrid, IDataGridColumn, DataGridCell } from '../../../components/data-grid/data-grid';
import { BatchHistoryItemViewModel } from '../../virtual-terminal-generated';
import { Formatter } from '../../../helpers/formatter';

export interface IBatchEntryBatchHistoryDialogProps {
	batchId: number;
	batchHistoryItems: BatchHistoryItemViewModel[];
}

export class BatchEntryHistoryDialog extends React.Component<IBatchEntryBatchHistoryDialogProps> {
	render() {
		const { batchId, batchHistoryItems } = this.props;
		return (
			<ModalDialogForm heading={`Batch history #${batchId}`}>
				<HistoryGrid batchHistoryItems={batchHistoryItems} />
			</ModalDialogForm>
		);
	}
}

class HistoryGrid extends React.Component<{ batchHistoryItems: BatchHistoryItemViewModel[] }> {
	private columns: { [key: string]: IDataGridColumn } = getHistoryGridColumnConfig();

	render() {
		const { batchHistoryItems } = this.props;
		return (
			<div className="batch-entry-history-dialog-grid">
				<DataGrid columns={this.columns}
					data={batchHistoryItems}
					getRowKey={getHistoryGridRowKey}
					onSelectRow={false}
				/>
			</div>
		);
	}
}

const getHistoryGridRowKey = (item: BatchHistoryItemViewModel) => `${item.BatchHistoryId}`;

function getHistoryGridColumnConfig() {
	return {
		DateAndTime: { displayName: 'Date & time', formatter: formatDateAndTime },
		OwnerName: { displayName: 'By' },
		Description: { displayName: 'Description' },
	};
}

const formatDateAndTime = (item: BatchHistoryItemViewModel, classNames: string) => {
	const dateAndTimeFormatted = Formatter.formatDate(item.DateAndTime, 'MMM D, YYYY, hh:mm a');
	return (
		<DataGridCell key="DateAndTime" classNames={classNames}>
			{dateAndTimeFormatted}
		</DataGridCell>
	);
};
