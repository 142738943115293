import * as React from 'react';
import { observer } from 'mobx-react';
import { BatchEntryFundTotal } from '../../virtual-terminal-generated';
import { SmoothHeightTransition } from '../../../components/hoc-behavior/transitions';
import { Formatter } from '../../../helpers/formatter';

import * as styles from './batch-entry-fund-totals.less';

@observer
export class BatchEntryFundTotals extends React.Component<{ showFundTotals: boolean, fundTotals: BatchEntryFundTotal[], nounPluralSentenceCase: string }> {
	render() {
		const { showFundTotals, fundTotals, nounPluralSentenceCase } = this.props;
		const fundTotalsCount = fundTotals && fundTotals.length || 0;

		if (!showFundTotals || fundTotalsCount === 0) {
			return <SmoothHeightTransition disableLeaveTransition />;
		}

		return(
			<SmoothHeightTransition disableLeaveTransition>
				<div className={styles.container}>
					<h2>Fund subtotals</h2>
					<FundTotalsText fundTotalsCount={fundTotalsCount} nounPluralSentenceCase={nounPluralSentenceCase} />
					<ul className="list-group">
						{fundTotals.map(fundTotal => <BatchEntryFundTotalItem key={fundTotal.FundKey} fundTotal={fundTotal} />)}
					</ul>
				</div>
			</SmoothHeightTransition>
		);
	}
}

const FundTotalsText = ({ fundTotalsCount, nounPluralSentenceCase }: { fundTotalsCount: number, nounPluralSentenceCase: string }) => {
	if (fundTotalsCount === 1) {
		return <p>{nounPluralSentenceCase} were made to the following fund in this batch.</p>;
	}
	return <p>{nounPluralSentenceCase} were made to the following <strong>{fundTotalsCount} funds</strong> in this batch.</p>;
};

@observer
class BatchEntryFundTotalItem extends React.Component<{ fundTotal: BatchEntryFundTotal }> {
	render() {
		const { FundDisplayValue, FundCode, FundNotes, Total: { Amount } } = this.props.fundTotal;
		return (
			<li className={`list-group-item ${styles.item}`}>
				<div className="list-group-left">
					<div className={styles.name}>
						{FundDisplayValue}
						{
							FundCode &&
							<span className={`text-muted ${styles.code}`}>
								({FundCode})
							</span>
						}
					</div>
					{
						FundNotes && <div className={styles.notes}>{FundNotes}</div>
					}
				</div>
				<div className={`list-group-right list-group-item-large ${styles.total}`}>
					<sup>$</sup>
					{Formatter.formatNumberForDisplay(Amount)}
				</div>
			</li>
		);
	}
}
