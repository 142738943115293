import * as React from 'react';
import { observer } from 'mobx-react';
import * as styles from './campaign-card-popup.less';
import { CampaignShareUrlViewModel, CampaignStatus, PledgeLabel } from '../../campaigns-generated';
import { SvgWrapper } from '../../../../Shared/components/svg-wrapper';
import { classNames } from '../../../../Shared/utils/classnames';

interface ShareActionPopupContentProps {
	listings: CampaignShareUrlViewModel[];
	donorPledgeEntryUrl?: string;
	onSelect: (e: React.MouseEvent<HTMLLIElement>, content: string) => void;
	campaignStatus: CampaignStatus;
	pledgeLabel: PledgeLabel;
}

export const ShareActionPopupContent = observer(({ listings, donorPledgeEntryUrl, onSelect, campaignStatus, pledgeLabel }: ShareActionPopupContentProps) => (
	<ul className={styles.shareActionContent}>
		{donorPledgeEntryUrl && campaignStatus === CampaignStatus.Published &&
			<>
				<li className={classNames(styles.shareItem, styles.shareHeading)}>
					{NewFeatures.DonorPledgeEntry_ConfigurablePledgeGrammar ? pledgeLabel.NounSentenceCase : 'Pledge'} Link
				</li>
				<li key="PledgeEntry" className={styles.shareItem} onClick={(e) => onSelect(e, donorPledgeEntryUrl)}>
					<SvgWrapper className={styles.shareIcon} svg="icon-link" />
					<span>Copy {NewFeatures.DonorPledgeEntry_ConfigurablePledgeGrammar ? pledgeLabel.NounSentenceCase : 'Pledge'} Entry link</span>
				</li>
			</>
		}
		<li className={classNames(styles.shareItem, styles.shareHeading)}>
			Giving Links
		</li>
		{listings.map(listing => (
			<li key={listing.ListingName} className={styles.shareItem} onClick={(e) => onSelect(e, listing.Url)}>
				<SvgWrapper className={styles.shareIcon} svg="icon-link" />
				<span>Copy {listing.ListingName} link</span>
			</li>
		))}
	</ul>
));
