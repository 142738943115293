import { validation } from '../validation/validation';
import { PropertyMetadata } from './customFieldsUtils';

export function getGeneratedAttributes(metadata: PropertyMetadata): React.AllHTMLAttributes<any> {
	const generatedAttributes = validation.validationAttributesForProperty(metadata.validationRules) as React.AllHTMLAttributes<any>;

	if (metadata.placeholder) {
		generatedAttributes.placeholder = metadata.placeholder;
	}

	return generatedAttributes;
}
