import {observable, action} from 'mobx';


export class Field {
	@observable value: string;
	@observable oldValue: string;
	@observable validationError: string;

	validator: (value: string) => string;

	constructor(public parent: FieldSetBase, public name: string, public label: string) {

	}

	@action setValue(event) {
		this.value = event.target.value;
	}

	@action startEditing() {
		this.parent.startEditing(this);
	}
}

export class FieldSetBase {
	fields: Field[];
	@observable isEditing: boolean;

	pleaseFocusMeField: Field;

	startEditing(pleaseFocusMeField: Field = null) {
		this.pleaseFocusMeField = pleaseFocusMeField;
		this.fields.forEach(x => x.oldValue = x.value);
		this.isEditing = true;
	}

	importValues(values: { [key: string]: string } | any) {
		Object.keys(values).forEach(k => {
			var [f] = this.fields.filter(x => x.name === k);
			if (f) {
				f.value = values[k];
			}
		});
	}

	exportValues(): { [key: string]: string } {
		return this.fields.reduce((o, f) => {
			o[f.name] = f.value;
			return o;
		}, {});
	}

	//isValid;
	//save();
	//cancel();

	stopEditing(revert = false) {
		this.isEditing = false;
		if (revert) {
			this.fields.forEach(x => x.value = x.oldValue);
		} else {
			this.fields.forEach(x => x.oldValue = null);
		}
	}
}
