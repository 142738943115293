import * as React from 'react';
import { observer } from 'mobx-react';
import {
	PayerSearchContainer,
	PayerSearchLabel,
	PayerSearchInputField,
	PayerSearchClearButton,
	SelectedPayerInfo,
	PayerSearchDropDownMenu,
} from '../../components/payer-search-omnibox/payer-search-omnibox';
import { MemberSearchOmniboxViewModel } from '../view-models/member-search-omnibox-viewmodel';
import { Fragment } from '../../../Shared/components/fragment';

export interface IMemberSearchOmniboxProps {
	vm: MemberSearchOmniboxViewModel;
	label?: string;
	errorText?: string;
	enableFeatureOrganizationalGiving: boolean;
}

@observer
export class MemberSearchOmnibox extends React.Component<IMemberSearchOmniboxProps> {

	render() {
		const {
			vm,
			label,
			errorText,
			enableFeatureOrganizationalGiving
		} = this.props;
		const value = vm.selectedPayer ? vm.selectedPayer.name : vm.omniboxValue;

		return (
			<div className="form-group">
				<label className="form-control-label">
					{label}
				</label>
				<PayerSearchContainer className={errorText ? 'input-validation-error' : ''}>
					<PayerSearchLabel payerNoun="Member">
						<PayerSearchInputField
							value={value}
							placeholder="Type member's name"
							onChange={this.handleInputChange}
							focusOnMount={true}
						/>
						{
							vm.selectedPayer &&
							<Fragment>
								<PayerSearchClearButton selectedPayer={vm.selectedPayer} onClick={vm.handleClearSelectedPayer} />
								<SelectedPayerInfo selectedPayer={vm.selectedPayer} />
							</Fragment>
						}
					</PayerSearchLabel>
					<PayerSearchDropDownMenu
						componentId="member-convert-omnibox"
						searchResult={vm.searchResult}
						searchValue={vm.searchValue}
						onChange={vm.handleSelectedPayerChange}
						selectedPayer={vm.selectedPayer}
						onLoadMore={vm.handleLoadMore}
						loadMoreInProgress={vm.loadMoreInProgress}
						onMenuStateChange={vm.handleMenuStateChange}
						openRequested={vm.openRequested}
						hideAddNewMemberOption={true}
						enableFeatureOrganizationalGiving={enableFeatureOrganizationalGiving}
					/>
				</PayerSearchContainer>
				{
					errorText &&
					<span className={`field-validation-error`}>
						<span>{errorText}</span>
					</span>
				}
			</div>
		);
	}

	handleInputChange = (event: React.FormEvent<HTMLInputElement>) => {
		this.props.vm.handleInputValueChange(event.currentTarget.value);
	}
}
