import {
	IScheduleImportSagaContext, sagaDataService, ScheduleImportSagaDataService
} from '../../saga/schedule-import-saga';
import { userActionChannel } from '../../../utils/user-action-channel';
import { ScheduleDetailsUserAction } from './schedule-details-user-actions';
import { userActionCreator } from '../../../utils/user-action-creator';
import { SchedulesDetailsViewModel } from './schedules-details-view-model';
import {
	ScheduleDetailsSortableColumn, ScheduleImportDraftDisplayStatus, SchedulesListFilteredRequest, ScheduleDetails,
	OperationResponse, OperationResult, ExportAction, ScheduleEditRequest
} from '../../schedule-import-generated';
import { ActionHandlers } from '../../../../Shared/utils/saga-utils';
import { SortDirection } from '../../../components/data-grid/data-grid';
import { ScheduleDetailsProps } from '../../components/schedule-details-expanded-row';
import moment from 'moment';
import { getScheduleImportExportDataService } from '../../schedule-import-non-ajax-data-service';
import { EditScheduleDetails } from '../../components/edit-schedule-details';
import * as React from 'react';
import { ModalDialogCommander } from '../../../components/modal-dialog-commander';
import { spawn } from 'redux-saga/effects';
import { EditScheduleDetailsViewModel } from '../../components/edit-schedule-details/view-model';

export class ScheduleDetailsContext implements IScheduleImportSagaContext {
	userActionChannel = userActionChannel<ScheduleDetailsUserAction>();
	userActionCreator = userActionCreator<typeof ScheduleDetailsUserAction>(ScheduleDetailsUserAction, this.userActionChannel);
	dataService: ScheduleImportSagaDataService = sagaDataService();
	executingBlockingAction?: boolean = false;
	constructor(public mainViewModel: SchedulesDetailsViewModel) { }
}

export function* scheduleDetailsOnSagaInit(context: ScheduleDetailsContext) {
	yield getScheduleList(context);
}

export function getActionHandlers() {
	const actionHandlers = new ActionHandlers<ScheduleDetailsContext, typeof ScheduleDetailsUserAction[keyof typeof ScheduleDetailsUserAction]>();
	const saveActionPriority = 100;

	actionHandlers.add(
		ScheduleDetailsUserAction.GetScheduleList, {
			async: true,
			handler: context => getScheduleList(context),
		}
	);

	actionHandlers.add(
		ScheduleDetailsUserAction.GetScheduleDetails, {
			async: true,
			handler: (context, action) => getScheduleDetails(context, action.scheduleImportDraftId),
		}
	);

	actionHandlers.add(
		ScheduleDetailsUserAction.DeleteSchedule, {
			async: true,
			priority: saveActionPriority,
			handler: (context, action) => deleteSchedule(context, action.scheduleImportDraftId),
		}
	);

	actionHandlers.add(
		ScheduleDetailsUserAction.UpdateFilters, {
			async: true,
			handler: (context, action) => updateFilters(context, action.selectedStatus, action.selectedListings, action.selectedFunds, action.shouldUpdateLocationState),
		}
	);

	actionHandlers.add(
		ScheduleDetailsUserAction.ExportSchedules, {
			async: true,
			handler: (context, action) => exportSchedules(context, action.exportAction, action.selectedStatus, action.selectedListings, action.selectedFunds),
		}
	);

	actionHandlers.add(
		ScheduleDetailsUserAction.ChangePageNumber, {
			async: true,
			handler: (context, action) => changeGridPageNumber(context, action.pageNumber),
		}
	);

	actionHandlers.add(
		ScheduleDetailsUserAction.ChangeGridSortedBy, {
			async: true,
			handler: (context, action) => changeGridSortedBy(context, action.newSortedBy, action.sortDirection),
		}
	);

	actionHandlers.add(
		ScheduleDetailsUserAction.ShowEditScheduleDetailsDialog, {
			async: true,
			handler: context => showEditScheduleDetailsDialog(context),
		}
	);

	actionHandlers.add(
		ScheduleDetailsUserAction.EditScheduleDetails, {
			async: true,
			handler: (context, action) => saveEditScheduleDetails(context, action.scheduleDetails)
		}
	);

	return actionHandlers;
}

export function* getScheduleList(context: ScheduleDetailsContext) {
	const {
		mainViewModel: {
			migrationId, statusSelectedValues, fundsSelectedValues, listingSelectedValues, sortedBy, sortDirection, currentPage,
			setIsLoadingData, setGridData
		},
		dataService
	} = context;

	setIsLoadingData(true);

	const scheduleRequest: SchedulesListFilteredRequest = {
		MigrationId: migrationId,
		SelectedStatuses: convertStatuses(statusSelectedValues),
		SelectedFunds: convertIdStringsToNumbers(fundsSelectedValues),
		SelectedListings: convertIdStringsToNumbers(listingSelectedValues),
		SortedBy: sortedBy,
		IsAscending: sortDirection === SortDirection.ASCENDING,
		PageNumber: currentPage,
	};

	const response = yield dataService.schedulesListFiltered({details: scheduleRequest});
	setGridData(response);
	setIsLoadingData(false);
}

export function* getScheduleDetails(context: ScheduleDetailsContext, scheduleImportDraftId: number) {
	const { dataService, mainViewModel: { setExpandedRowData } }  = context;
	const result = yield dataService.scheduleDetails({scheduleImportDraftId});

	const resultData: ScheduleDetails = result.ScheduleDetails;
	const viewData: ScheduleDetailsProps = {
		scheduleId: scheduleImportDraftId,
		importedScheduleId: resultData.ImportedScheduleId,
		paymentLabel: resultData.PaymentLabel,
		amount: resultData.Amount,
		paymentMethod: resultData.PaymentMethod,
		paymentReference: resultData.PaymentReference,
		frequency: resultData.Frequency,
		nextScheduledDate: resultData.NextOccurrence !== null ? moment(resultData.NextOccurrence).toDate() : null,
		name: resultData.Name,
		firstName: resultData.FirstName,
		lastName: resultData.LastName,
		linkToPayer: resultData.LinkToPayer,
		email: resultData.EmailAddress,
		mobileNumber: resultData.MobileNumber,
		address: resultData.Address,
		defaultCountry: resultData.DefaultCountry,
		fund: resultData.Fund,
		listing: resultData.MerchantListing,
		displayStatus: resultData.DisplayStatus,
		status: resultData.Status,
		availableStatusItems: resultData.AvailableStatusItems,
		isEditable: resultData.IsEditable,
		isEmailBounced: resultData.IsEmailBounced,
	};

	setExpandedRowData(viewData);
}

export function* deleteSchedule(context: ScheduleDetailsContext, scheduleImportDraftId: number) {
	const { dataService }  = context;
	const response: OperationResponse = yield dataService.scheduleDelete({scheduleImportDraftId});

	if (response.Result === OperationResult.Success) {
		context.mainViewModel.deleteSchedule(scheduleImportDraftId);
	}
}

export function* exportSchedules(context: ScheduleDetailsContext, action: ExportAction, selectedStatus: string[], selectedListings: string[], selectedFunds: string[]) {
	const { migrationId } = context.mainViewModel;

	getScheduleImportExportDataService().exportSchedules({
		migrationId: migrationId,
		action: action,
		statuses: selectedStatus.toString(),
		funds: selectedFunds.toString(),
		listings: selectedListings.toString(),
	});
}

export function* updateFilters(context: ScheduleDetailsContext, selectedStatus: string[], selectedListings: string[], selectedFunds: string[], shouldUpdateLocationState: boolean) {
	context.mainViewModel.filtersChanged(selectedStatus, selectedListings, selectedFunds, shouldUpdateLocationState);

	yield getScheduleList(context);
}

export function* changeGridPageNumber(context: ScheduleDetailsContext, pageNumber: number) {
	context.mainViewModel.currentPageChanged(pageNumber);

	yield getScheduleList(context);
}

export function* changeGridSortedBy(context: ScheduleDetailsContext, newSortedBy: ScheduleDetailsSortableColumn, sortDirection: SortDirection) {
	context.mainViewModel.changeGridSortedBy(newSortedBy, sortDirection);

	yield getScheduleList(context);
}

let editScheduleDetails: EditScheduleDetailsViewModel;

export function* showEditScheduleDetailsDialog(context: ScheduleDetailsContext) {
	const { userActionChannel, mainViewModel: { expandedRowData } } = context;

	const vm = new EditScheduleDetailsViewModel(expandedRowData);
	const popup = React.createElement(EditScheduleDetails, {
		vm,
		onSubmitHandler: (data: ScheduleEditRequest) => userActionChannel.put(new ScheduleDetailsUserAction.EditScheduleDetails(data)),
	});

	editScheduleDetails = vm;

	ModalDialogCommander.showReactForm(popup);
}

export function* saveEditScheduleDetails(context: ScheduleDetailsContext, data: ScheduleEditRequest) {
	const { dataService } = context;
	try {
		const resp: OperationResponse = yield dataService.scheduleEdit({model : data });
		if(resp.Result === OperationResult.Success) {
			ModalDialogCommander.forceCloseCurrent();
			// reload the schedule table to reflect the changes.
			yield spawn(getScheduleList, context);
			editScheduleDetails = null;
		}
	} catch (e) {
		if(e.isPostError) {
			editScheduleDetails.setValidationErrors(e.validationErrors);
		} else {
			throw e;
		}
	}
}

function convertStatuses(statusStrings: string[]): ScheduleImportDraftDisplayStatus[] {
	return statusStrings.map(status => ScheduleImportDraftDisplayStatus[ScheduleImportDraftDisplayStatus[parseInt(status, 10)]]);
}

function convertIdStringsToNumbers(ids: string[]) : number[] {
	return ids.map(id => parseInt(id, 10)).filter(id => !isNaN(id));
}
