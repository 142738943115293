import * as React from 'react';
import { observer } from 'mobx-react';

import { ScheduleImportFileUploadViewModel } from './schedule-import-file-upload-view-model';
import { ScheduleImportStep } from '../schedule-import-step';
import { FormControlType, IFormControlLabelledProps } from '../../../components/form-controls/form-control-labelled';
import { FileUploadForm } from '../../../transaction-import/forms/file-upload-form';
import { UploadProgressForm } from './upload-progress-form';
import * as styles from './styles.less';
import { SvgWrapper } from '../../../components/svg-wrapper';
import { ScheduleImportUploadStatus } from '../../schedule-import-generated';
import { ImportFileErrorGrid } from '../../../transaction-import/components/import-file-error-grid';

@observer
export class ScheduleImportFileUpload extends React.Component<{ vm: ScheduleImportFileUploadViewModel }> {
	render() {
		const {
			actionInProgress,
			paymentLabel,
			showDonorsFile,
			uploadStatus,
			parserMessages,
		} = this.props.vm;

		return (
			<ScheduleImportStep vm={this.props.vm} >
				{uploadStatus === ScheduleImportUploadStatus.WaitingForUpload &&
					<FileUploadForm
						navigationInProgress={actionInProgress}
						showDonorsFile={showDonorsFile}
						handleSubmit={this.handleSubmit}
						handleCancelImport={this.handleCancelImport}
						handleTransactionsFileChange={this.handleTransactionsFileChange}
						handleDonorsFileChange={this.handleDonorsFileChange}
						fileTypeName="schedule"
						paymentLabel={paymentLabel} />
				}
				{uploadStatus === ScheduleImportUploadStatus.Uploading &&
					<UploadProgressForm
						title="Uploading file"
						cancelButtonText="Cancel"
						nextButtonDisabled={true}
						navigationInProgress={actionInProgress}
						handleCancel={this.handleCancelImport}>
						<div className="text-center">
							<img className={`loading-box-img ${styles.statusIcon}`} src="/Content/LoggedInWeb/assets/images/loader.png" alt={'Loading'}/>
							<p className={styles.statusText}>
								Please wait while we upload and validate your file. You will need to stay on this page to allow the process to finish successfully.
							</p>
						</div>
					</UploadProgressForm>
				}
				{uploadStatus === ScheduleImportUploadStatus.Completed &&
					<UploadProgressForm
						title="Upload completed"
						cancelButtonText="Cancel"
						nextButtonDisabled={false}
						cancelButtonDisabled={true}
						navigationInProgress={actionInProgress}
						handleCancel={this.handleCancelImport}
						handleNext={this.handleNext}>
						<div className="text-center">
							<SvgWrapper svg="ind-transparent-tick" title="Upload completed" className={styles.statusSvg} />
							<p className={styles.statusText}>
								Your file has finished uploading and is validated.
							</p>
						</div>
					</UploadProgressForm>
				}
				{uploadStatus === ScheduleImportUploadStatus.ValidationError &&
					<UploadProgressForm
						title="Upload unsuccessful"
						cancelButtonText="Try again"
						nextButtonDisabled={true}
						navigationInProgress={actionInProgress}
						handleCancel={this.handleTryAgain}>
							<ImportFileErrorGrid
							/* tslint:disable:max-line-length */
								description="Some rows in this file were rejected. Warnings can be ignored but all errors must be fixed to proceed. Please correct the errors and try uploading your file again."
							/* tslint:enable:max-line-length */
								messageHeader="Messages"
								rows={parserMessages} />
					</UploadProgressForm>
				}
				{uploadStatus === ScheduleImportUploadStatus.ValidationWarning &&
					<UploadProgressForm
						title="File upload complete"
						cancelButtonText="Cancel"
						nextButtonDisabled={false}
						navigationInProgress={actionInProgress}
						handleCancel={this.handleCancelImport}>
							<ImportFileErrorGrid
								description="Your file upload has completed with warnings. You can ignore warnings and still proceed."
								messageHeader="Messages"
								rows={parserMessages} />
					</UploadProgressForm>
				}
				{uploadStatus === ScheduleImportUploadStatus.Failed &&
					<UploadProgressForm
						title="Upload unsuccessful"
						cancelButtonText="Try again"
						nextButtonDisabled={true}
						navigationInProgress={actionInProgress}
						handleCancel={this.handleTryAgain}>
							Please try uploading your file again.
					</UploadProgressForm>
				}
			</ScheduleImportStep>
		);
	}

	private handleSubmit = () => {
		this.props.vm.uploadFile();
	}

	private handleNext = () => {
		this.props.vm.goToNextStep();
	}

	private handleCancelImport = () => {
		this.props.vm.cancelImport();
	}

	private handleTryAgain = () => {
		this.props.vm.tryAgain();
	}

	private handleTransactionsFileChange = (file: File) => {
		this.props.vm.updateTransactionsFile(file);
	}

	private handleDonorsFileChange = (file: File) => {
		this.props.vm.updateDonorsFile(file);
	}
}
