import { action, computed, observable } from 'mobx';
import {
	CreateCampaignViewModel,
	ListingsViewModel,
	CampaignDetailsViewModel,
	CampaignStatus,
	EditCampaignConfirmationRequiredResponse,
	ModelMetadata,
	PaymentLabel,
	PledgeLabel,
} from '../../campaigns-generated';
import { FormState } from '../../../../Shared/forms/form-state';
import { TextFieldState } from '../../../../Shared/forms/fields/textbox-field';
import { AmountFieldState } from '../../../../Shared/forms/fields/amount-field';
import { RadioButtonFieldState } from '../../../../Shared/forms/fields/radio-button-field';
import { CheckboxListFieldState } from '../../../../Shared/forms/fields/checkboxlist-field';
import { getCreateCampaignConfig } from './create-campaign-machine';
import { Actions } from './create-campaign-states-events-and-actions';
import { MachineContext } from '../../../../Shared/state-machine/saga-state-machine';
import { connectMachine, IHaveMachine } from '../../../../Shared/state-machine/connect-machine';
import { getDateRangeFieldState } from '../../../../Shared/forms/fields/date-range-field';
import { dateAfter } from '../../../../Shared/forms/validation/validators';
import { TextareaFieldState } from '../../../../Shared/forms/fields/textarea-field';
import { ModalDialogProcessingViewModel } from 'components/modal-dialog/modal-dialog-processing-view-model';

@connectMachine(getCreateCampaignConfig, Actions.handlers)
export class CreateCampaignMainViewModel implements IHaveMachine {
	readonly listings: ListingsViewModel[];
	readonly backUrl: string;
	readonly campaignId?: number;
	readonly fundMappingRequired: boolean;
	readonly campaignDetails: CampaignDetailsViewModel;
	readonly paymentLabel: PaymentLabel;
	readonly pledgeLabel: PledgeLabel;

	readonly machineContext: MachineContext;

	readonly form = new FormState({
			campaignName: new TextFieldState('', ModelMetadata.CampaignCreateRequest.CampaignName),
			description: new TextareaFieldState('', ModelMetadata.CampaignCreateRequest.Description),
			financialGoal: new AmountFieldState(null, ModelMetadata.CampaignCreateRequest.FinancialGoal),
			isTaxDeductible: new RadioButtonFieldState(null, ModelMetadata.CampaignCreateRequest.TaxDeductible),
			campaignListings: new CheckboxListFieldState([], ModelMetadata.CampaignCreateRequest.SelectedListings),
			StartFinishDate: getDateRangeFieldState(
				{ metadata: ModelMetadata.CampaignCreateRequest.StartDate },
				{ metadata: ModelMetadata.CampaignCreateRequest.EndDate }
			).validators(dateAfter($ => [$.from.value, $.to.value], 'The start date must be before the finish date')),
			fundThankYouMessage: new TextareaFieldState('', ModelMetadata.CampaignCreateRequest.FundThankYouMessage),
			email: new TextFieldState('', ModelMetadata.CampaignCreateRequest.Email),
			phoneNumber: new TextFieldState('', ModelMetadata.CampaignCreateRequest.PhoneNumber),
		});

	@observable campaignStatus?: CampaignStatus;

	@observable editConfirmationRequired: EditCampaignConfirmationRequiredResponse = null;

	@observable
	modalDialogProcessingViewModel: ModalDialogProcessingViewModel;

	constructor(model: CreateCampaignViewModel) {
		const { Listings, CampaignDetails, BackUrl, FundMappingRequired, PaymentLabel, PledgeLabel } = model;
		const { Id, Status } = CampaignDetails;

		this.campaignStatus = Status;
		this.backUrl = BackUrl;
		this.listings = Listings;
		this.campaignId = Id;
		this.campaignDetails = CampaignDetails;
		this.fundMappingRequired = FundMappingRequired;
		this.paymentLabel = PaymentLabel;
		this.pledgeLabel = PledgeLabel;
		this.prepopulateForm(CampaignDetails);
		this.modalDialogProcessingViewModel =  new ModalDialogProcessingViewModel();
	}

	@computed
	get fieldsForRequest() {
		const {
			campaignName,
			financialGoal,
			isTaxDeductible,
			campaignListings,
			StartFinishDate,
			email,
			phoneNumber,
			fundThankYouMessage } = this.form.$;

		let fields = {
			Id: this.campaignId,
			CampaignName: campaignName.value,
			FinancialGoal: financialGoal.value,
			TaxDeductible: isTaxDeductible.value === 'true',
			SelectedListings: campaignListings.value as number[],
			StartDate: StartFinishDate.$.from.value,
			EndDate: StartFinishDate.$.to.value,
			FundThankYouMessage: fundThankYouMessage.value,
		};

		fields['Email'] = email.value;
		fields['PhoneNumber'] = phoneNumber.value;

		const { description } = this.form.$;
		fields['Description'] = description.value;

		return fields;
	}

	@action.bound
	setEditConfirmationRequired(editConfirmationRequired: EditCampaignConfirmationRequiredResponse) {
		this.editConfirmationRequired = editConfirmationRequired;
	}

	@action.bound
	private prepopulateForm(campaignDetails: CampaignDetailsViewModel) {
		const {
			Name,
			Description,
			FinancialGoal,
			TaxDeductible,
			AssignedListings,
			StartDate,
			EndDate,
			EmailAddress,
			PhoneNumber,
			FundThankYouMessage } = campaignDetails;

		const fields = this.form.$;
		fields.campaignName.value = Name;
		fields.financialGoal.value = FinancialGoal;
		if (TaxDeductible !== null) {
			fields.isTaxDeductible.value = TaxDeductible.toString();
		}
		if (AssignedListings) {
			fields.campaignListings.value = AssignedListings;
		}
		if (this.listings && this.listings.length === 1) {
			fields.campaignListings.value = [this.listings[0].Id];
		}

		const { from, to } = fields.StartFinishDate.$;
		from.value = StartDate;
		to.value = EndDate;

		fields.fundThankYouMessage.value = FundThankYouMessage;
		fields.email.value = EmailAddress;
		fields.phoneNumber.value = PhoneNumber;
		fields.description.value = Description;
	}
}
