import { responsiveHelper } from '../helpers/responsive-helper';

export class Flyout {
	public openLink: string;
	public closeLink: string;
	public panel: string;
}

export class FlyoutHandler {

	static openClass = 'open';


	private _flyouts: Flyout[];

	constructor(flyouts: Flyout[]) {

		this._flyouts = flyouts;

		this.bindClickHandler();
	}

	private bindClickHandler() {
		$(document).click(e => {

			var aFlyoutIsOpen = false;

			this._flyouts.forEach(flyout => {

				// the panel was clicked
				if ($(flyout.panel).is(e.target) || $(flyout.panel).has(e.target).length > 0) {
					aFlyoutIsOpen = true;
					return;
				}

				// open button was toggled
				if ($(flyout.openLink).is(e.target) || $(flyout.openLink).has(e.target).length > 0) {
					this.toggleFlyout(e.originalEvent, flyout);

					if ($(flyout.openLink).hasClass('open')) {
						aFlyoutIsOpen = true;
						if (responsiveHelper.isXs) {
							$('body').addClass('no-scroll');
						}
					}
					return;
				}


				// somewhere else on the document was clicked close the flyout
				$(flyout.panel).removeClass('open');
				$(flyout.openLink).removeClass('open');
				$(flyout.closeLink).removeClass('open');
			});

			// no flyout open let body scroll normally
			if (!aFlyoutIsOpen) {
				$('body').removeClass('no-scroll');
			}
		});
	}

	private toggleFlyout(e: Event, flyout: Flyout) {
		e.preventDefault();
		$(flyout.panel).toggleClass('open');
		$(flyout.openLink).toggleClass('open');
		$(flyout.closeLink).toggleClass('open');
	}
}
