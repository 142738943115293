import * as React from "react";
import {observer} from 'mobx-react';

import {ResponseTypes} from '../../../funds/funds-generated';
import {Button} from "../../../components/button";
import {SvgWrapper} from "../../../components/svg-wrapper";
import {IIntegrationConfigurationStore} from "../integration-configuration-store";
import GivingStatus = ResponseTypes.GivingStatus;
import ExternalSystemStatus = ResponseTypes.ExternalSystemStatus;

@observer
export class GivingIntegrationActions extends React.Component<{
	status: ExternalSystemStatus,
	givingStatus: GivingStatus,
	isGivingModuleEnabled: boolean,
	importFundsUrl: string,
	handleConnectionClick: () => void,
	handleEnableGivingClick: () => void,
	handleDisableGivingClick: () => void,
	store: IIntegrationConfigurationStore<any>
}, {}> {
	constructor(props) {
		super(props);
		this.handleImportFundsClick = this.handleImportFundsClick.bind(this);
	}

	renderConnectIntegrationButton() {
		return (
			<Button	className="btn btn-link btn-sm"
					   disabled={this.props.store.serviceClient.isProcessingRequest}
					   onClick={this.props.handleConnectionClick}>
				Connect integration
			</Button>
		);
	}

	renderImportFundsButton() {
		return (
			<Button	className="btn btn-link btn-sm"
					   disabled={this.props.store.serviceClient.isProcessingRequest}
					   onClick={this.handleImportFundsClick}>
				<SvgWrapper svg="icon-shuffle" className="icon"/>Map funds
			</Button>
		);
	}

	renderEnableGivingButton() {
		return (
			<Button className="btn btn-link btn-sm"
				disabled={this.props.store.serviceClient.isProcessingRequest}
				onClick={this.props.handleEnableGivingClick}>
				<SvgWrapper svg="icon-link" className="icon" />Enable integration
			</Button>
		);
	}

	renderDisableGivingButton() {
		return (
			<Button className="btn btn-link btn-sm"
				disabled={this.props.store.serviceClient.isProcessingRequest}
				onClick={this.props.handleDisableGivingClick}>
				<SvgWrapper svg="icon-unlink" className="icon" />Disable integration
			</Button>
		);
	}

	render() {
		const integrationIsEnabled = this.props.status === ExternalSystemStatus.Enabled;
		const hasConnectIntegrationButton = integrationIsEnabled
			&& (this.props.givingStatus === GivingStatus.NotConnected
				|| (this.props.isGivingModuleEnabled === false
					&& this.props.givingStatus === GivingStatus.InSetup));
		const hasImportFundsButton = this.props.importFundsUrl.length > 0
			&& (this.props.givingStatus === GivingStatus.Enabled || this.props.givingStatus === GivingStatus.Disabled || this.props.givingStatus === GivingStatus.SetupComplete);
		const hasEnableGivingButton = integrationIsEnabled
			&& this.props.isGivingModuleEnabled
			&& (this.props.givingStatus === GivingStatus.Disabled || this.props.givingStatus === GivingStatus.SetupComplete);
		const hasDisableGivingButton = integrationIsEnabled && this.props.givingStatus === GivingStatus.Enabled;

		return (
			<span className="header-buttons">
			{ hasConnectIntegrationButton && this.renderConnectIntegrationButton() }
			{ hasImportFundsButton && this.renderImportFundsButton() }
			{ hasEnableGivingButton && this.renderEnableGivingButton() }
			{ hasDisableGivingButton && this.renderDisableGivingButton() }
			</span>
		);
	}

	handleImportFundsClick() {
		window.location.href = this.props.importFundsUrl;
	}
}
