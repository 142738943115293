import * as React from 'react';
import { SvgWrapper } from './svg-wrapper';
import { observer } from 'mobx-react';

export interface IModalDialogProps {
	title: string;
	buttons?: (IModalDialogButton | JSX.Element)[];
	hideCloseButton?: boolean;
	showAlert?: boolean;
	inProgress?: boolean;
	className?: string;
}

export interface IModalDialogButton {
	label: string;
	dismiss: boolean;
	isPrimary: boolean;
	hideOnProgress?: boolean;
	disabled?: boolean;
}

@observer
export class ModalDialog extends React.Component<IModalDialogProps, {}> {
	static defaultProps: IModalDialogProps = {
		title: '',
		buttons: [{ label: 'OK', dismiss: true, isPrimary: true }],
		showAlert: true
	};

	renderButton(button: IModalDialogButton | JSX.Element, idx: number) {
		if (this.isDialogButton(button)) {
			const { isPrimary, disabled, dismiss, label } = button;
			const style: React.CSSProperties = {};

			if (this.props.inProgress && button.hideOnProgress) {
				style.display = 'none';
			}

			return <button type="button"
				key={idx}
				style={style}
				className={`btn ${isPrimary ? 'btn-primary' : 'btn-cancel'}`}
				data-dismiss={dismiss ? 'modal' : null}
				data-pp-at-target="ModalOkButton"
				disabled={disabled}>{label}</button>;
		} else {
			return button;
		}
	}

	isDialogButton(button: any): button is IModalDialogButton {
		return typeof button.label !== 'undefined';
	}

	render() {
		const { inProgress, hideCloseButton, title, children, buttons, showAlert, className } = this.props;
		const classNames = `modal fade ${className ? className : ''}`;

		return (
			<div className={classNames} tabIndex={-1} role="dialog" data-modal-prevent-close={inProgress}>
				<div className="modal-dialog" role="document">
					<div className="modal-content" data-pp-at-target="Modal">
						{hideCloseButton ? null : <button type="button" className="close" data-dismiss="modal" aria-label="Close"></button>}
						<div className="modal-body">
							{showAlert ? <div className="alert-icon"><SvgWrapper svg="alert-exclamation-icon" /></div> : null}
							{title && <h2>{title}</h2>}
							<div>{children}</div>
						</div>
						<div className="modal-footer">
							{buttons.map(this.renderButton, this)}
						</div>
					</div>
				</div>
			</div>);
	}
}
