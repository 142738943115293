import * as React from 'react';
import { classNames } from '../../../Shared/utils/classnames';

export interface IProgressBarProps {
	percentComplete: number;
	cssClassNames?: string;
}

export const ProgressBar: React.StatelessComponent<IProgressBarProps> = (props) => {
	const {percentComplete, cssClassNames} = props;
	const progressBarStyle: React.CSSProperties = {
		width: `${percentComplete}%`
	};

	return (
		<div className="transaction-import-progress">
			<div className="progress">
				<div className={classNames('progress-bar', 'active', cssClassNames)} role="progressbar"
					aria-valuenow={percentComplete} aria-valuemin={0} aria-valuemax={100} style={progressBarStyle} data-tooltip-text={`${percentComplete}%`}>
				</div>
			</div>
		</div>
	);
};
