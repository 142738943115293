import * as React from 'react';
import { CountryData } from '../../loggedinweb-generated';
import { FormControlSelect } from '../../components/form-controls/form-control-select';
import { getUniqueElementId } from '../../components/form-controls/form-control-utils';
import { ModelMetadata } from '../community-member-generated';

interface ICountryFieldProps {
	selectedCountry: number;
	handleCountryChange: (event: React.FormEvent<HTMLSelectElement>) => void;
	acceptanceTestTargetId?: string;
}

export const CountryField: React.StatelessComponent<ICountryFieldProps> = (props) => {
	const { selectedCountry, handleCountryChange, acceptanceTestTargetId  } = props;
	const countryMetadata = ModelMetadata.EditCommunityMemberRequestModel.Address.modelMetadata.Country;
	const options = CountryData.map((country) => ({
		Label: country.displayName,
		Key: `${country.countryCode}`,
		Value: `${country.countryCode}`
	}));

	return (
		<div className="col-xs-12 form-group">
			<FormControlSelect
				Options={options}
				name={countryMetadata.propertyName}
				uniqueSuffix={getUniqueElementId()}
				hideEmptyOption={true}
				onChangeHandler={handleCountryChange}
				validationRules={countryMetadata.validationRules}
				// We have to `.toString()` the selected country to coerce the value into a string.
				value={selectedCountry.toString()}
				acceptanceTestTargetId={acceptanceTestTargetId}
			/>
		</div>
	);
};
