import * as React from 'react';
import {ResponseTypes} from '../funds-generated';
import {ModalDialogCommander} from '../../components/modal-dialog-commander';
import {ModalDialog} from '../../components/modal-dialog';
import {Button} from '../../components/button';
import {makeAjaxCall} from '../utils/ajax-helper';
import {SvgWrapper} from '../../components/svg-wrapper';
import FundValidationError = ResponseTypes.FundValidationError;

export interface IReopenFundButtonProps {
	url: string;
	className: string;
	fundReopen: () => JQueryPromise<any>;
}

export let ReopenFundErrorDialog = (error = { ErrorMessage: 'There was a problem reopening the fund' } as FundValidationError) =>
	<ModalDialog title="">{error.ErrorMessage}</ModalDialog>;

export class ReopenFundButton extends React.Component<IReopenFundButtonProps, { isProcessingRequest: boolean }> {
	constructor(props) {
		super(props);
		this.state = { isProcessingRequest: false };
	}

	handleButtonClick() {
		this.setState({
			isProcessingRequest: true
		});
		makeAjaxCall(this.props.url)
			.fail(error => {
				ModalDialogCommander.showReactDialog(ReopenFundErrorDialog(error));
			})
			.then(() => this.props.fundReopen())
			.always(() => {
				this.setState({
					isProcessingRequest: false
				});
			});
	}

	render() {
		return <Button className={this.props.className}
					onClick={() => this.handleButtonClick() }
					isProcessingRequest={this.state.isProcessingRequest}>
			<SvgWrapper svg="icon-reopen-fund" className="icon" />Reopen fund
			</Button>;
	}
}
