import * as React from 'react';
import { Button } from '../../components/button';

export interface IFormActionsProps {
	cancelButtonAction: string | (() => void);
	submitButtonText: string;
	cancelButtonText?: string;
	isSettingUp: boolean;
	isProcessingRequest: boolean;
}

export class FormActions extends React.Component<IFormActionsProps, {}> {
	renderCancelButton() {
		if (this.props.isProcessingRequest) {
			return null;
		}

		const cancelButtonText = this.props.cancelButtonText || 'Cancel';
		const cancelButtonAction = this.props.cancelButtonAction;

		if (typeof cancelButtonAction === 'string') {
			return <a className="btn btn-cancel" href={cancelButtonAction}>{cancelButtonText}</a>;
		} else {
			return (
				<button type="button"
					className="btn btn-cancel"
					onClick={cancelButtonAction}>
					Cancel
				</button>
			);
		}
	}

	render() {
		return (
			<div className="panel-footer panel-footer-btn-group">
				{this.renderCancelButton()}
				<Button className="btn btn-default" type="submit"
					isProcessingRequest={this.props.isSettingUp} disabled={this.props.isProcessingRequest}>{this.props.submitButtonText}</Button>
			</div>
		);
	}
}
