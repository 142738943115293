import { CustomFieldType, ICustomFieldViewModel, CustomFieldEditModel } from '../loggedinweb-generated';
import { IFormControlLabelledProps, FormControlType } from '../components/form-controls/form-control-labelled';
import { IFormControlSelectOptionProps, IFormControlSelectOptionGroupProps } from '../components/form-controls/form-control-select';
import { ArrayHelper } from '../helpers/arrayhelper';

export type CustomFieldViewModel = ICustomFieldViewModel;

export type PropertyMetadata = {
	propertyName: string;
	displayName?: string;
	placeholder?: string;
	validationRules?: { [rule: string]: any };
};

export type CommonControlProps = {
	name: string;
	placeholder?: string;
	validationRules?: { [rule: string]: any };
	value: string;
	onChangeHandler: (event: React.FormEvent<any>) => void;
};

export function getCustomFieldProperties(field: CustomFieldViewModel, commonControlProps: CommonControlProps): IFormControlLabelledProps['formControlProps'] {

	let controlProps: IFormControlLabelledProps['formControlProps'] = null;

	switch (field.Type) {
		case CustomFieldType.DropDown: {
			const { Options } = field;

			controlProps = {
				...commonControlProps,
				Options,
				formControlType: FormControlType.DropDown,
			};
			break;
		}
		case CustomFieldType.Text:
			controlProps = {
				...commonControlProps,
				type: 'text',
				formControlType: FormControlType.Text,
			};
			break;
		case CustomFieldType.Number:
			controlProps = {
				...commonControlProps,
				type: 'text',
				formControlType: FormControlType.Number,
			};
			break;
		case CustomFieldType.Fund: {
			const { Options } = field;
			const useGroups = Options.filter(x => !x.ForListing).length > 0;
			let options: (IFormControlSelectOptionProps | IFormControlSelectOptionGroupProps)[] = Options;

			if (useGroups) {
				const forListing: IFormControlSelectOptionGroupProps = { Label: 'For listing', Options: [] };
				const allFunds: IFormControlSelectOptionGroupProps = { Label: 'All funds', Options: [] };

				Options.forEach(x => {
					if (x.ForListing) {
						forListing.Options.push(x);
					} else {
						allFunds.Options.push(x);
					}
				});

				options = [forListing, allFunds];
			}

			controlProps = {
				...commonControlProps,
				Options: options,
				formControlType: FormControlType.DropDown,
			};
			break;
		}
	}
	return controlProps;
}

export function getCustomFieldOptionLength(field: CustomFieldViewModel) {
	switch (field.Type) {
		case CustomFieldType.Fund:
		case CustomFieldType.DropDown: {
			return field.Options.length;
		}
		case CustomFieldType.Text:
		case CustomFieldType.Number:
			return 0;
	}
}

export function getCustomFieldEditModelMap(customFieldEditModels: CustomFieldEditModel[]): { [key: string]: CustomFieldEditModel } {
	return ArrayHelper.arrayToMap(customFieldEditModels, 'Key');
}

export function mergeCustomFieldEditModels(target: {[key: string]: CustomFieldEditModel}, source: {[key: string]: CustomFieldEditModel}): CustomFieldEditModel[] {
	const mergedEditFieldMap = Object.assign(target, source);
	return Object.keys(mergedEditFieldMap).map(x => mergedEditFieldMap[x]);
}
