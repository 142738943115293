import * as React from 'react';
import { IntegrationStatusLabel } from '../../qbo-integration-generated';
import { IntegrationStatusLabels } from '../integration-status-label/IntegrationStatusLabels';

import * as styles from './styles.less';

export interface QBOPageHeadingProperties {
	title: string;
	instanceName?: string;
	labels: IntegrationStatusLabel[];
	className?: string;
}

export class QBOPageHeading extends React.Component<QBOPageHeadingProperties, null> {
	render() {
		const { title, instanceName, labels, className } = this.props;
		let tooltip = instanceName ? `Company Name: ${instanceName}` : '';
		return (
			<div className={`${className ? className : ''} ${styles.container}`}>
				<h1 className={styles.heading}>{title}</h1>
				{labels.length > 0 ?
					<IntegrationStatusLabels labels={labels} tooltip={tooltip} labelGroupClassName={styles.labelGroup} labelClassName={styles.label}/>
					: null
				}
			</div>
		);
	}
}
