import * as React from 'react';
import { observer } from 'mobx-react';

import { ScheduleImportProgressViewModel } from './schedule-import-progress-view-model';
import { ScheduleImportStep } from '../schedule-import-step';
import {
	ModelMetadata
} from '../../schedule-import-generated';
import { FormControlType, IFormControlLabelledProps } from '../../../components/form-controls/form-control-labelled';
import { ProgressBar } from '../../../transaction-import/components/progress-bar';
import { Formatter } from '../../../helpers/formatter';
import { RenderResponsive } from '../../../components/hoc-behavior/render-responsive';
import { ResponsiveVisibility, ResponsiveBreakpoint } from '../../../helpers/responsive-helper';
import { Fragment } from '../../../../Shared/components/fragment';
import { SummaryForm, SummaryRow } from '../../components/summary-form/summary-form';

@observer
export class ScheduleImportProgress extends React.Component<{ vm: ScheduleImportProgressViewModel }> {
	render() {
		const {
			percentageComplete,
			listingName,
			importType,
			numberOfSchedules,
			numberOfFunds,
			numberOfGivers,
			monthlyTotal,
			dateStarted,
			importedBy,
			paymentLabel,
		} = this.props.vm;

		const monthlyTotalElement = <span><span className="dollar">$</span>{Formatter.formatNumberForDisplay(monthlyTotal)}</span>;
		return (
			<ScheduleImportStep vm={this.props.vm} >
				<SummaryHeading
					listingName={listingName}
					numberOfSchedules={numberOfSchedules}
					date={dateStarted}
					importedBy={importedBy}/>
				<ProgressBar percentComplete={percentageComplete} cssClassNames="slow"/>
				<SummaryForm listingName={listingName} dataFormat={importType}>
					<SummaryRow label="Number of schedules" content={numberOfSchedules.toString()}/>
					<SummaryRow label="Estimated average monthly total" content={monthlyTotalElement}/>
					<SummaryRow label="Number of funds" content={numberOfFunds.toString()}/>
					<SummaryRow label={`Number of ${paymentLabel.PayerPluralLowerCase}`} content={numberOfGivers.toString()}/>
				</SummaryForm>
			</ScheduleImportStep>
		);
	}
}

const SummaryHeading: React.StatelessComponent<{listingName: string, numberOfSchedules: number, date: string, importedBy: string}> = (props) => {
	const { listingName, numberOfSchedules, date, importedBy } = props;

	const importStatus = <span className="label label-warning">importing</span>;
	return (
		<Fragment>
			<div className="transaction-import-summary-title">
				<RenderResponsive visibility={ResponsiveVisibility.Visible} breakpoints={ResponsiveBreakpoint.Xs}>
					{importStatus}
				</RenderResponsive>
				<h1>
					{listingName} - {`${numberOfSchedules} ${numberOfSchedules === 1 ? 'schedule ' : 'schedules '}`}
					<RenderResponsive visibility={ResponsiveVisibility.Hidden} breakpoints={ResponsiveBreakpoint.Xs}>
						{importStatus}
					</RenderResponsive>
				</h1>
			</div>
			<ul className="list-unstyled transaction-import-summary-subtitle">
				<li><strong>Date uploaded:</strong> {date}</li>
				<li><strong>Imported by:</strong> {importedBy}</li>
			</ul>
		</Fragment>
	);
};
