import {ResponseTypes} from '../funds-generated';
import { IListingsState } from '../page-manage-funds';
import { ArrayHelper } from '../../helpers/arrayhelper';
import { LocalStorageHelper } from '../../helpers/localstoragehelper';

export interface IPersistedChanges {
	[listingId: number]: { defaultFundKey?: string, fundKeys?: string[], hash: string };
	createdOn: number;
}

var localStorage = LocalStorageHelper.getLocalStorage();

export interface IManageFundsStateService {
	saveState(model: ResponseTypes.ManageFundsViewModel, state: IListingsState): void;

	removeState(model: ResponseTypes.ManageFundsViewModel): void;

	convertToState(currentModel: ResponseTypes.ManageFundsViewModel): IListingsState;

	loadPersistedChanges(organizationId: number, ignoreChangesMadeBefore: Date): IPersistedChanges;

	getMergedState(model: ResponseTypes.ManageFundsViewModel, persistedChanges: IPersistedChanges): IListingsState;
}

class StateService implements IManageFundsStateService {
	private keyPrefix = 'manageFundsState:';

	hasDraft(): boolean {
		return false;
	}

	key(orgId: number) {
		return `${this.keyPrefix}${orgId}`;
	}

	loadPersistedChanges(organizationId: number, ignoreChangesMadeBefore: Date): IPersistedChanges {
		this.removeChangesMadeBefore(ignoreChangesMadeBefore);

		return this.extractChangesFromStorage(this.key(organizationId));
	}

	getChanges(model: ResponseTypes.ManageFundsViewModel, state: IListingsState): IPersistedChanges {
		const changes: IPersistedChanges = {
			createdOn: new Date().valueOf()
		};

		for (let i = 0; i < model.Listings.length; i++) {
			const listing = model.Listings[i];
			const listingState = state[listing.ListingId];

			const listingChange: { defaultFundKey?: string, fundKeys?: string[], hash: string } = {
				hash: listing.Hash
			};

			if (listing.DefaultFundKey !== listingState.defaultFundKey) {
				listingChange.defaultFundKey = listingState.defaultFundKey;
				changes[listing.ListingId] = listingChange;
			}

			if (!ArrayHelper.equals(listing.FundKeys, listingState.fundKeys)) {
				listingChange.fundKeys = listingState.fundKeys;
				changes[listing.ListingId] = listingChange;
			}
		}

		return changes;
	}

	removeState(currentModel: ResponseTypes.ManageFundsViewModel) {
		setTimeout(() => { localStorage.removeItem(this.key(currentModel.OrganizationId)); }, 0);
	}

	saveState(currentModel: ResponseTypes.ManageFundsViewModel, state: IListingsState) {
		const changes = this.getChanges(currentModel, state);

		if (Object.keys(changes).filter(x=>x!=='createdOn').length === 0) {
			setTimeout(() => { localStorage.removeItem(this.key(currentModel.OrganizationId)); }, 0);
			return;
		}

		//saving to localStorage async so it does not block page rendering
		setTimeout(() => { localStorage.setItem(this.key(currentModel.OrganizationId), JSON.stringify(changes)); }, 0);
	}

	getMergedState(model: ResponseTypes.ManageFundsViewModel, persistedChanges: IPersistedChanges): IListingsState {
		const currentState = this.convertToState(model);
		let hasChanges = false;

		if (persistedChanges === null) {
			return null;
		}

		const availableFundKeys = model.Funds.map(x => x.Key);

		for (let i = 0; i < model.Listings.length; i++) {
			const listing = model.Listings[i];
			const listingState = currentState[listing.ListingId];
			const changes = persistedChanges[listing.ListingId];

			if (changes && listing.Hash === changes.hash) {
				if (changes.fundKeys) {
					listingState.fundKeys = changes.fundKeys.filter(x => availableFundKeys.indexOf(x) !== -1);
					hasChanges = true;
				}

				if (typeof changes.defaultFundKey !== 'undefined'
					&& (changes.defaultFundKey === null || listingState.fundKeys.indexOf(changes.defaultFundKey) !== -1)) {
					listingState.defaultFundKey = changes.defaultFundKey;
					hasChanges = true;
				}
			}
		}

		return hasChanges ? currentState : null;
	}

	convertToState(currentModel: ResponseTypes.ManageFundsViewModel) {
		var currentState: IListingsState = {};

		currentModel.Listings.forEach((x) => {
			currentState[x.ListingId] = {
				defaultFundKey: x.DefaultFundKey,
				fundKeys: x.FundKeys.slice(),
				hash: x.Hash
			};
		});

		return currentState;
	}

	private extractChangesFromStorage(key: string) {
		try {
			const storedData = localStorage[key];

			if (storedData) {
				const changes = JSON.parse(storedData) as IPersistedChanges;
				if (changes && typeof changes.createdOn === 'number') {
					return changes;
				}
			}
		} catch (e) {
			Raygun.send(e);
			console.warn('Could not parse persisted changes', e);
		}

		return null;
	}

	private removeChangesMadeBefore(before: Date) {
		const keysToRemove: string[] = [];

		for (let key in localStorage) {
			if (localStorage.hasOwnProperty(key) && key.indexOf(this.keyPrefix) === 0) {
				const changes = this.extractChangesFromStorage(key);
				//let's remove unparsable keys as well
				if (!changes || changes.createdOn < before.valueOf()) {
					keysToRemove.push(key);
				}
			}
		}

		for (let key of keysToRemove) {
			localStorage.removeItem(key);
		}
	}
}

let stateService: IManageFundsStateService = new StateService();

export {stateService};
