import * as React from 'react';
import { observer } from 'mobx-react';
import { ResponseTypes } from '../../funds/funds-generated';
import { Button } from '../../components/button';
import { SvgWrapper } from '../../components/svg-wrapper';
import { IIntegrationConfigurationStore } from './integration-configuration-store';

@observer
export class IntegrationActions extends React.Component<{ store: IIntegrationConfigurationStore<any> }, {}> {
	renderEnableButton() {
		if ((this.viewModel.SystemStatus === ResponseTypes.ExternalSystemStatus.Disabled ||
			this.viewModel.SystemStatus === ResponseTypes.ExternalSystemStatus.InSetup) &&
			!this.viewModel.IsSetupIncomplete &&
			this.viewModel.IsAuthenticated) {
			return (
				<Button isProcessingRequest={this.store.serviceClient.isEnabling}
					disabled={this.store.serviceClient.isProcessingRequest}
					className="btn btn-link btn-sm"
					onClick={this.store.serviceClient.enableIntegration}>
					<SvgWrapper svg="icon-link" className="icon"/>Enable integration
				</Button>
			);
		}

		return null;
	}

	renderDisableButton() {
		if (this.viewModel.SystemStatus === ResponseTypes.ExternalSystemStatus.Enabled ||
			this.viewModel.SystemStatus === ResponseTypes.ExternalSystemStatus.Paused) {
			return (
				<Button disabled={this.store.serviceClient.isProcessingRequest}
					className="btn btn-link btn-sm"
					onClick={this.store.serviceClient.disableIntegration}>
					<SvgWrapper svg="icon-unlink" className="icon"/>Disable integration
				</Button>
			);
		}

		return null;
	}

	renderRemoveButton() {
		if (!this.viewModel.IsNewConnection &&
			(this.viewModel.SystemStatus === ResponseTypes.ExternalSystemStatus.Disabled ||
			this.viewModel.SystemStatus === ResponseTypes.ExternalSystemStatus.InSetup)) {
			return (
				<Button disabled={this.store.serviceClient.isProcessingRequest}
					className="btn btn-link btn-sm"
					onClick={this.store.serviceClient.deleteIntegration}>
					<SvgWrapper svg="icon-delete" className="icon"/>Delete integration
				</Button>
			);
		}

		return null;
	}

	render() {
		return (
			<span>
				{this.renderEnableButton()}
				{this.renderDisableButton()}
				{this.renderRemoveButton()}
			</span>
		);
	}

	private get store() {
		return this.props.store;
	}

	private get viewModel() {
		return this.store.viewModel;
	}
}

