export class VisibilityToggler {
	static init() {
		$(document.body).on('click', '[data-toggle-visibility]', (event) => {
			const selector = $(event.currentTarget).data('toggle-visibility');
			if (selector) {
				$(selector).toggle();
			}
		});
	}
}
