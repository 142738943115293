import * as React from 'react';
import { classNames } from '../../../Shared/utils/classnames';
import { SvgWrapper } from '../svg-wrapper';
import * as styles from './styles.less';

type ScrollToTopButtonProps = Omit<
	React.ButtonHTMLAttributes<HTMLButtonElement>,
	| 'type'
	| 'form'
	| 'formAction'
	| 'formEncType'
	| 'formMethod'
	| 'formNoValidate'
	| 'formTarget'
	| 'name'
	| 'type'
	| 'value'
>;

export type IScrollToTopButtonProps = ScrollToTopButtonProps & {
	cssClassNames?: string;
	label?: string;
};

export const ScrollToTopButton: React.FC<IScrollToTopButtonProps> = (props: IScrollToTopButtonProps) => {
	const { cssClassNames, label, ...rest } = props;
	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};
	return (
		<button
			{...rest}
			aria-label={label || 'Scroll to the top'}
			className={classNames('btn', 'btn-default', styles.scrollToTopButton, cssClassNames)}
			onClick={scrollToTop}
			type="button"
		>
			<SvgWrapper
				aria-hidden="true"
				className={styles.scrollToTopIcon}
				svg="arrow-single"
				width="20"
				height="12"
			/>
		</button>
	);
};
