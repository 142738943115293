import * as React from 'react';
import { IPayerViewModel } from './payer-search-models';
import { SvgWrapper } from '../svg-wrapper';

export const PayerSearchClearButton = ({ selectedPayer, onClick }: { selectedPayer: IPayerViewModel, onClick?: (event: React.FormEvent<any>) => void }) => {
	if (!selectedPayer) {
		return null;
	}

	return (
		<span className="payer-search-clear-container" data-pp-at-target="payer search clear container">
			<span className="input-text-width-spacing" aria-hidden="true">{selectedPayer.name}</span>
			<button className="btn btn-gray-link btn-xs payer-search-clear" onClick={onClick} aria-label="Clear" tabIndex={-1} type="button">
				<SvgWrapper svg="icon-xs-cross" className="icon" />
			</button>
		</span>
	);
};
