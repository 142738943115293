import * as React from 'react';
import { observer } from 'mobx-react';
import { injectAnalytics, recordEvent, WithAnalytics } from '../../../analytics';
import { GivingStatementsPeriodType } from '../../giving-statements-generated';
import { FormControlLabelled, FormControlType } from '../../../components/form-controls/form-control-labelled';
import { IFormControlRadioOptionProps } from '../../../components/form-controls/form-control-radio';

export interface IPerPeriodTypeProps {
	title?: string;
	disabled?: boolean;
}

export interface IPeriodTypeSelectorProps {
	periodType: GivingStatementsPeriodType;
	perTypeProps?: {[key in GivingStatementsPeriodType]?: IPerPeriodTypeProps};
	onChangePeriodType: (periodType: GivingStatementsPeriodType) => void;

	acceptanceTestTargetId: string;
	formControlId?: string;
}

const PERIOD_TYPES = [
	GivingStatementsPeriodType.Annual,
	GivingStatementsPeriodType.Quarterly,
	GivingStatementsPeriodType.Custom
];

@injectAnalytics
@observer
export class PeriodTypeSelector extends React.Component<WithAnalytics & IPeriodTypeSelectorProps, {}> {
	render () {
		const { periodType, perTypeProps, acceptanceTestTargetId, formControlId } = this.props;
		const options = PERIOD_TYPES.map((periodType) => {
			const { disabled = false, title } = perTypeProps && perTypeProps[periodType];
			const option: IFormControlRadioOptionProps = {
				Label: GivingStatementsPeriodType[periodType],
				Value: `${periodType}`,
				disabled,
			};

			if (title) {
				option.tooltipOptions = { message: title };
			}

			return option;
		});

		return (
			<div className="row">
				<FormControlLabelled cssClassNames="col-md-4"
					label="Period Type"
					formControlProps={{
						name: formControlId,
						formControlType: FormControlType.Radio,
						value: `${periodType}`,
						onChangeHandler: this.handlePeriodTypeChange,
						Options: options,
						acceptanceTestTargetId,
					}}
				/>
			</div>
		);
	}

	private handlePeriodTypeChange = (value: string) => {
		const { onChangePeriodType, analytics } = this.props;

		onChangePeriodType(Number(value));

		if (NewFeatures.SetupPinpointAnalytics && analytics) {
			const { feature, subFeature } = analytics;
			recordEvent({ feature, subFeature, eventTypeLabel: 'selectTimePeriod' });
		}
	}
}
