import * as React from 'react';
import { observer } from 'mobx-react';
import { FormField, IFormFieldProps } from './form-field';
import { Amount, IAmountCommonProps } from '../primitives/amount';
import { classNames } from '../../utils/classnames';
import { BaseFieldState } from './base-field-state';
import { ModelMetadata } from '../../../LoggedInWeb/loggedinweb-generated';
import { isFunction } from '../../utils/is-function';

export class AmountFieldState extends BaseFieldState<number> {
	constructor(initialValue: number, metadata?: ModelMetadata.IPropertyMetadata) {
		super(initialValue, metadata);
		this.disableAutoValidation();
	}
}

export type IAmountFieldProps = IAmountCommonProps & IFormFieldProps & { fieldState: AmountFieldState };

@observer
export class AmountField extends React.Component<IAmountFieldProps> {
	render() {
		const { label, className, tooltip, additionalClassNames, fieldState, ...rest } = this.props;
		const { value, onChange, hasError, error } = fieldState;
		const classes = classNames(`form-control`, additionalClassNames, hasError ? `input-validation-error` : null);

		return (
			<FormField label={label} className={className} tooltip={tooltip} error={error}>
				<Amount
					{...rest}
					value={value}
					onChange={onChange as any}
					ariaInvalid={hasError}
					onBlur={this.onBlur}
					additionalClassNames={classes}
				/>
			</FormField>
		);
	}

	onBlur = () => {
		const { fieldState: { enableAutoValidationAndValidate }, onBlur } = this.props;
		enableAutoValidationAndValidate();
		if (isFunction(onBlur)) {
			onBlur();
		}
	}
}
