import CommandBase from './command-base';
import { ICancellablePromise } from '../../../utils/cancellable-promise';
import Member from '../member';
import { GivingStatementsByNameAndPreviewabilityResponseModel } from '../../models';
import Main from '../main';

export default class ViewCustomStatementsCommand extends CommandBase<GivingStatementsByNameAndPreviewabilityResponseModel[]> {

	constructor(public main: Main, public member: Member) {
		super();

		this.trackers.push(member.busyTracker);
	}

	preApply(): void {
	}

	send(): ICancellablePromise<GivingStatementsByNameAndPreviewabilityResponseModel[]> {
		return this.main.dataService.memberCustomStatements({
			CommunityMemberId: this.member.id
		});
	}

	postApply(result: GivingStatementsByNameAndPreviewabilityResponseModel[]) {
		this.member.customStatements = result;
		this.member.canPreview = result[0] && result[0].CanPreview; //TODO: when we have multiple statement types, this needs to be fixed as the result will return more than one type
	}

	undoPreApply(): void {

	}
}
