import { isArrayLike } from 'mobx';
import { FormState as BaseFormState, ValidatableMapOrArray } from 'formstate/lib/core/formState';
import { ComposibleValidatable } from 'formstate/lib/core/types';
import { BaseFieldState } from './fields/base-field-state';

export class FormState<TValue extends ValidatableMapOrArray> extends BaseFormState<TValue> {
	setErrors(errors: { [name: string]: string }) {
		if (isArrayLike(this.$)) {
			this.$.forEach(validatable => {
				this.setValidatableError(validatable, errors);
			});
		} else {
			Object.keys(this.$).forEach(x => {
				const validatable = (this.$ as { [key: string]: ComposibleValidatable<any> })[x];
				this.setValidatableError(validatable, errors);
			});
		}
	}

	private setValidatableError(validatable: ComposibleValidatable<any>, errors: { [name: string]: string }) {
		if (validatable instanceof BaseFieldState && validatable.fieldName) {
			validatable.setError(errors[validatable.fieldName]);
		} else if (validatable instanceof FormState) {
			validatable.setErrors(errors);
		}
	}
}
