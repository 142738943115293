import { call } from 'redux-saga/effects';
import { IBrandingSettingsSagaContext } from '../branding-settings-saga';
import { BrandingSettingsUserAction } from './actions';
import { CampusDetailsViewModel } from '../branding-settings-generated';

export function* campusChangeHandler(context: IBrandingSettingsSagaContext, action: BrandingSettingsUserAction.ChangeCampus): IterableIterator<any> {
	const { mainViewModel, dataService } = context;

	if (action.campusKey === mainViewModel.selectedCampusKey) {
		return;
	}

	if (mainViewModel.isDirty) {
		if (!confirm('You have unsaved changes, are you sure you want to switch to another campus?')) {
			return;
		}
	}

	mainViewModel.isLoadingListingDetails = true;

	try {
		const campusDetails: CampusDetailsViewModel = yield call(dataService.getCampusDetails, { campusKey: String(action.campusKey) });
		mainViewModel.onSelectCampus(campusDetails);
	} catch (error) {
		context.reportError(error);
	} finally {
		mainViewModel.isLoadingListingDetails = false;
	}
}
