import * as React from 'react';
import { PaymentMethod } from './payment-entry-model';
import { PaymentMethodSelectorExistingAch, PaymentMethodSelectorExistingCard } from './payment-method-selector-existing';
import { SvgWrapper } from '../../../components/svg-wrapper';
import {
	CustomSelect,
	CustomSelectItem,
	CustomSelectHeader,
	CustomSelectCustomizableItem,
	CustomSelectSeparator,
	ICustomSelectCustomizableItemState,
	getPrefixedCustomSelectId
} from '../../../components/custom-select';
import { AccessibilityOption } from '../../../components/hoc-accessibility/accessibility-option';
import { observer, inject } from 'mobx-react';
import { isFunction } from '../../../utils/is-function';
import { getUniqueElementId } from '../../../components/form-controls/form-control-utils';
import { PaymentMethodUiType } from './payment-entry-model';
import { IVirtualTerminalFormUserActionCreator } from '../../components/form/virtual-terminal-form-user-action-creator';
import { getPaymentMethodDisplayText } from '../../utils/payment-method-type-helper';

function getPaymentMethodDisplayComponent(method: PaymentMethod): React.ReactChild {
	switch (method.type) {
		case PaymentMethodUiType.ExistingCreditCard:
			return <PaymentMethodSelectorExistingCard existingCard={method} />;
		case PaymentMethodUiType.ExistingACH:
			return <PaymentMethodSelectorExistingAch existingAch={method} />;
		default:
			console.warn(`Missing matching component for ${method.type}`);
			return null;
	}
}

const injectUserActionCreator = 'userActionCreator';

@observer
export class PaymentMethodSelector extends React.Component<{
	existingMethods?: PaymentMethod[];
	newMethods: PaymentMethod[];
	paymentMethod?: PaymentMethod;
	selectedPaymentMethodKey: string;
	hasMoreExistingMethods: boolean;
	onPaymentMethodChange?: (paymentMethodKey: string) => void;
	checkNoun: string;
	nonCashNoun: string;
	cardTokenizationAvailable?: boolean;
}, { open: boolean }> {
	state = { open: false };
	private componentId = getUniqueElementId();

	render() {
		const { existingMethods, newMethods, selectedPaymentMethodKey: paymentMethodKey, hasMoreExistingMethods, checkNoun, nonCashNoun, cardTokenizationAvailable } = this.props;
		const hasExistingMethods = existingMethods && existingMethods.length > 0;

		return (
			<div className="vt-payment-method-selector">
				<label htmlFor={getPrefixedCustomSelectId(this.componentId)}>Payment method</label>
				<CustomSelect
					componentId={this.componentId}
					open={this.state.open}
					selectedItem={paymentMethodKey}
					placeholder="Select payment method"
					onItemSelected={this.handleItemSelected}
					onRequestOpen={this.handleRequestOpen}
					onRequestClose={this.handleRequestClose}
					acceptanceTestTargetId="paymentmethoddropdown">
					{hasExistingMethods && <CustomSelectHeader><h5>Existing payment methods</h5></CustomSelectHeader>}
					{hasExistingMethods && existingMethods.map(method =>
						<CustomSelectCustomizableItem key={method.key}
							value={method.key}
							listItemComponentFactory={this.existingMethodListItem}
							selectedComponentFactory={this.existingMethodSelectedItem} />
					)}
					{hasMoreExistingMethods && <CustomSelectCustomizableItem
						key="view_all_existing"
						value="view_all_existing"
						listItemComponentFactory={this.viewAllExistingMethodsListItem}
						selectedComponentFactory={null}
					/>}
					{hasExistingMethods && <CustomSelectSeparator />}
					<CustomSelectHeader><h5>New payment methods</h5></CustomSelectHeader>
					{newMethods.map(method => (this.mapNewMethodToComponents(method, checkNoun, nonCashNoun, cardTokenizationAvailable)))}
				</CustomSelect>
			</div>
		);
	}

	private handleItemSelected = (key: string) => {
		const { onPaymentMethodChange } = this.props;

		this.setState({ open: false });
		if (isFunction(onPaymentMethodChange)) {
			onPaymentMethodChange(key);
		}
	}

	private handleRequestOpen = () => {
		this.setState({ open: true });
	}

	private handleRequestClose = () => {
		this.setState({ open: false });
	}

	private existingMethodListItem = (state: ICustomSelectCustomizableItemState) => {
		return <ExistingPaymentMethodListItem itemState={state} existingMethods={this.props.existingMethods} />;
	}

	private existingMethodSelectedItem = (key: string) => {
		const [method] = this.props.existingMethods.filter(x => x.key === key);
		return getPaymentMethodDisplayComponent(method);
	}

	private mapNewMethodToComponents = (method: PaymentMethod, checkNoun: string, nonCashNoun: string, cardTokenizationAvailable: boolean) => {
		if (method.type === PaymentMethodUiType.CreditCard && !cardTokenizationAvailable) {
			return <CustomSelectHeader><h5>{getPaymentMethodDisplayText(method.type, checkNoun, nonCashNoun)}</h5></CustomSelectHeader>;
		}

		return <CustomSelectItem key={method.key} displayLabel={getPaymentMethodDisplayText(method.type, checkNoun, nonCashNoun)} value={method.key} />;
	}

	private viewAllExistingMethodsListItem = (state: ICustomSelectCustomizableItemState) => <ExistingPaymentMethodViewAllItem itemState={state} />;
}

@inject(injectUserActionCreator)
@observer
class ExistingPaymentMethodListItem extends React.Component<{
	itemState: ICustomSelectCustomizableItemState,
	existingMethods: PaymentMethod[],
	userActionCreator?: IVirtualTerminalFormUserActionCreator,
}, {}> {
	render() {
		const { id, isSelected, isHighlighted, key } = this.props.itemState;
		const [method] = this.props.existingMethods.filter(x => x.key === key);

		// todo: PP-16871 fed-infrastructure - investigate why CustomSelectListItem is rerendered even when its surrounding JSX condition is false
		if (!method) {
			return null;
		}

		return (
			<AccessibilityOption id={id} selected={isSelected || null}>
				<li tabIndex={-1}
					onMouseMove={this.handleMouseMove}
					className={`vt-payment-method-existing-item${isHighlighted ? ' hover' : ''}`}>
					<div className={`dropdown-menu-item${isSelected ? ' active' : ''}`}>
						<button type="button" tabIndex={-1} className="vt-payment-method-existing-btn-select btn btn-link" onClick={this.handleClick}
							data-pp-at-target={`existing-payment-method-${method.key}`}>
							{getPaymentMethodDisplayComponent(method)}
						</button>

						<button type="button" tabIndex={-1} className="vt-payment-method-existing-btn-delete btn btn-link text-danger" onClick={this.handleDeleteClick}>
							<SvgWrapper className="icon vt-payment-method-icon-delete" svg="icon-delete" title="Delete payment method" />
						</button>
					</div>
				</li>
			</AccessibilityOption>);
	}

	private handleDeleteClick = () => {
		const [paymentMethod] = this.props.existingMethods.filter(x => x.key === this.props.itemState.key);
		this.props.userActionCreator.RemovePaymentMethod(paymentMethod);
	}

	private handleMouseMove = () => {
		const { onItemHighlighted, index } = this.props.itemState;
		onItemHighlighted(index);
	}

	private handleClick = () => {
		const { onItemSelected, index } = this.props.itemState;
		onItemSelected(index);
	}
}

@inject(injectUserActionCreator)
@observer
class ExistingPaymentMethodViewAllItem extends React.Component<{
	userActionCreator?: IVirtualTerminalFormUserActionCreator,
	itemState: ICustomSelectCustomizableItemState
}, {}> {
	render() {
		const { id, isSelected, isHighlighted } = this.props.itemState;

		return (
			<AccessibilityOption id={id} selected={isSelected || null}>
				<li tabIndex={-1}
					onMouseMove={this.handleMouseMove}
					className={`vt-payment-method-existing-item${isHighlighted ? ' hover' : ''}`}>
					<div className={`dropdown-menu-item${isSelected ? ' active' : ''}`}>
						<button type="button" tabIndex={-1} className="vt-payment-method-existing-btn-view-all btn btn-link arrow-hollow-down-after" onClick={this.handleViewAllClick}>
							View all payment methods
						</button>
					</div>
				</li>
			</AccessibilityOption>);
	}

	private handleViewAllClick = () => {
		this.props.userActionCreator.ViewAllExistingPaymentMethods();
	}

	private handleMouseMove = () => {
		const { onItemHighlighted, index } = this.props.itemState;
		onItemHighlighted(index);
	}
}
