import { action, observable } from 'mobx';

export class ModalDialogProcessingViewModel {
	@observable
	isProcessing: boolean = false;

	@action.bound
	setIsProcessing(isProcessing: boolean) {
		this.isProcessing = isProcessing;
	}
}
