import * as React from 'react';
import { observer } from 'mobx-react';

import { Formatter } from '../../../../helpers/formatter';
import { Fragment } from '../../../../../Shared/components/fragment';
import * as styles from './styles.less';
import { PaymentLabel } from '../../../schedule-import-generated';

export interface IConfirmDetailsProps {
	numberOfSchedules: number;
	numberOfGivers: number;
	monthlyTotal: number;
	paymentLabel: PaymentLabel;
}

@observer
export class PanelConfirmDetails extends React.Component<IConfirmDetailsProps> {
	render() {
		const {
			numberOfSchedules,
			numberOfGivers,
			monthlyTotal,
			paymentLabel,
		} = this.props;

		const totalAmount = <span><span className={styles.dollar}>$</span>{Formatter.formatNumberForDisplay(monthlyTotal)}</span>;

		return (
			<Fragment>
				<div className="panel-heading">Confirm migration details</div>
				<div className={`panel-body ${styles.gridPanel}`}>
					<div className={`row ${styles.gridRow}`}>
						<div className={`col-xs-6 ${styles.rowLabel}`}>Number of schedules</div>
						<div className={`col-xs-6 ${styles.rowValue}`}>{numberOfSchedules}</div>
					</div>
					<div className={`row ${styles.gridRow}`}>
						<div className={`col-xs-6 ${styles.rowLabel}`}>Number of {paymentLabel.PayerPluralLowerCase}</div>
						<div className={`col-xs-6 ${styles.rowValue}`}>{numberOfGivers}</div>
					</div>
					<div className={`row ${styles.gridRow}`}>
						<div className={`col-xs-6 ${styles.rowLabel}`}>Estimated average monthly total</div>
						<div className={`col-xs-6 ${styles.rowValue}`}>{totalAmount}</div>
					</div>
				</div>
			</Fragment>
		);
	}
}
