import * as React from 'react';
import { observer } from 'mobx-react';
import { FormControlLabelled, FormControlType } from '../../../../components/form-controls/form-control-labelled';
import { RadioLayoutType } from '../../../../components/form-controls/form-control-radio';
import { PaymentLabel, EmailBranding, ScheduleImportMigrationEmailContentType, ScheduleImportDefaultStartPoint } from '../../../schedule-import-generated';
import { Fragment } from '../../../../../Shared/components/fragment';
import { Button } from '../../../../components/button';
import { ModalDialogCommander } from '../../../../components/modal-dialog-commander';
import { ModalDialog } from '../../../../components/modal-dialog';
import { MigrationEmails } from '../../../components/migration-emails/migration-emails';
import { MigrationEmailsViewModel } from '../../../components/migration-emails/migration-emails-view-model';
import { Formatter } from '../../../../helpers/formatter';

import * as styles from './panel-email-template.less';

export interface IEmailTemplateProps {
	vm: MigrationEmailsViewModel;
	paymentLabel: PaymentLabel;
	emailContentType: ScheduleImportMigrationEmailContentType;
	defaultStartPoint: ScheduleImportDefaultStartPoint;
	reviewCampaignHelpUrl: string;
	contactName: string;
	contactEmail: string;
	contactPhone: string;
	emailBranding: EmailBranding;
	isDefaultEmailTemplatesModified: boolean;
	endDate: Date;

	updateEmailContent: (contentType: ScheduleImportMigrationEmailContentType) => void;
	updateDefaultStartPoint: (defaultStartPoint: ScheduleImportDefaultStartPoint) => void;
}

@observer
export class PanelEmailTemplate extends React.Component<IEmailTemplateProps> {

	render() {
		const {
			paymentLabel,
			emailContentType,
			emailBranding,
			defaultStartPoint,
			endDate,
			reviewCampaignHelpUrl,
		} = this.props;

		let startDatePointOptions = null;
		let selectedDefaultStartOption = defaultStartPoint;
		let tooltipContent = null;
		if (emailContentType === ScheduleImportMigrationEmailContentType.OldScheduleStoppedByChurch ||
			emailContentType === ScheduleImportMigrationEmailContentType.OldScheduleCancelledByUser) {
			startDatePointOptions = [{
				Label: 'The first payment will occur after the migration end date.',
				Value: ScheduleImportDefaultStartPoint.MigrationEnd.toString(),
			}, {
				Label: 'The first payment will occur 10 or more days after the user clicks on the email link and confirms the schedule.',
				Value: ScheduleImportDefaultStartPoint.TenDaysHence.toString(),
			}, {
				Label: 'The first payment will occur 3 or more days after the user clicks on the email link and confirms the schedule.',
				Value: ScheduleImportDefaultStartPoint.ThreeDaysHence.toString(),
			}];

			if (emailContentType === ScheduleImportMigrationEmailContentType.OldScheduleStoppedByChurch) {
				if (!defaultStartPoint || defaultStartPoint === ScheduleImportDefaultStartPoint.NextDay) {
					selectedDefaultStartOption = ScheduleImportDefaultStartPoint.MigrationEnd;
				}
				tooltipContent = <Fragment>
					<p>This option controls how quickly your team will need to act when cancelling previous schedules.</p>
					<p>Leaving more time will help avoid any duplicate payments, but payments may be skipped while your members are between systems.</p>
					<p>Note: This may change the default start date, users are always free to change this date to suit their personal preferences.</p>
				</Fragment>;
			}

			if (emailContentType === ScheduleImportMigrationEmailContentType.OldScheduleCancelledByUser) {
				if (defaultStartPoint === null || defaultStartPoint === undefined) {
					selectedDefaultStartOption = ScheduleImportDefaultStartPoint.TenDaysHence;
				}

				tooltipContent = <Fragment>
					<p>This option controls how quickly your members will need to act when cancelling previous schedules to avoid making payment twice.</p>
					<p>Leaving more time will help avoid any duplicate payments, but payments may be skipped while your members are between systems.</p>
					<p>Note: This may change the default start date, users are always free to change this date to suit their personal preferences.</p>
				</Fragment>;

				startDatePointOptions.push({
					Label: 'The first payment will occur as soon as tomorrow, after the user clicks on the email link and confirms the schedule.',
					Value: ScheduleImportDefaultStartPoint.NextDay.toString(),
				});
			}
		}

		const endDateFormatted = Formatter.formatDate(endDate, 'MMM D, YYYY') || 'the end date';

		const helpUrl = reviewCampaignHelpUrl;

		return (
			<Fragment>
				<div className="panel-heading">Customize emails to send to {paymentLabel.PayerPluralLowerCase}</div>
				<div className="panel-body">
					{/* tslint:disable:max-line-length */}
					<p>Customize your emails that will alert your {paymentLabel.PayerPluralLowerCase} weekly that you are migrating to Pushpay and what action is required from them. You can also upload a video to your email if you wish to. <a className="arrow-hollow-right-after info-link" target="_blank" rel="noopener noreferrer" href={helpUrl}>Learn more</a></p>

					<FormControlLabelled label="To configure your migration settings and email templates, please choose an option below:"
						formControlProps={{
							name: 'emailContent',
							formControlType: FormControlType.Radio,
							layoutType: RadioLayoutType.RadioSection,
							value: emailContentType.toString(),
							onChangeHandler: this.updateContentType,
							Options: [{
								Label: `At the end of the migration, we will shut down our old system. All existing recurring ${paymentLabel.NounPluralLowerCase} will then be stopped.`,
								Value: ScheduleImportMigrationEmailContentType.OldSystemSwitchedOff.toString(),
								tooltipOptions: {
									message: `Emails will state that the old system is being switched off by ${endDateFormatted} and their schedules will be cancelled if they take no action.`,
									placement: 'top',
									tooltipContentClassName: styles.tooltipContent,
								}
							}, {
								Label: 'Our team will cancel previous schedules on behalf of our members.',
								Value: ScheduleImportMigrationEmailContentType.OldScheduleStoppedByChurch.toString(),
								tooltipOptions: {
									message: `Emails will advise members that the ${emailBranding.OrganizationName} team will cancel previous schedules.`,
									placement: 'top',
									tooltipContentClassName: styles.tooltipContent,
								}
							}, {
								Label: 'We need our members to cancel their previous schedules themselves.',
								Value: ScheduleImportMigrationEmailContentType.OldScheduleCancelledByUser.toString(),
								tooltipOptions: {
									message: 'Emails will advise members they must cancel their previous schedules to avoid duplicate payments.',
									placement: 'top',
									tooltipContentClassName: styles.tooltipContent,
								}
							}],
						}} />

					{ startDatePointOptions &&
						<FormControlLabelled label={`Choose the default start date for recurring ${paymentLabel.NounPluralLowerCase} in Pushpay:`}
							tooltipOptions={{
								message: tooltipContent,
								placement: 'top',
								tooltipContentClassName: styles.tooltipContent,
							}}
							formControlProps={{
								name: 'startPoint',
								formControlType: FormControlType.Radio,
								layoutType: RadioLayoutType.RadioSection,
								value: selectedDefaultStartOption.toString(),
								onChangeHandler: this.updateDefaultStartPoint,
								Options: startDatePointOptions,
							}} />
					}

					{/* tslint:enable:max-line-length */}

					<MigrationEmails {...this.props} />
				</div>
			</Fragment>
		);
	}

	private updateDefaultStartPoint = (defaultStartPoint: string) => {
		const startPoint = defaultStartPoint && parseInt(defaultStartPoint, 10);
		this.props.updateDefaultStartPoint(startPoint);
	}

	private updateContentType = (contentType: string) => {
		if (this.props.isDefaultEmailTemplatesModified) {
			const confirmButton = <Button className="btn btn-primary" onClick={() => {
				ModalDialogCommander.forceCloseCurrent();
				this.updateEmailContent(contentType);
			}}>Continue</Button>;
			const buttons = [{ label: 'Cancel', dismiss: true, isPrimary: false }, confirmButton];

			ModalDialogCommander.showReactDialog(
				<ModalDialog
					title={'Changing this setting will reset all email templates to their initial state, you will need to re-apply any customized wording.'}
					showAlert={false}
					hideCloseButton={true}
					buttons={buttons} />);
		} else {
			this.updateEmailContent(contentType);
		}

	}

	private updateEmailContent = (contentType: string) => {
		const type = parseInt(contentType);
		this.props.updateEmailContent(type);

		if (type === ScheduleImportMigrationEmailContentType.OldSystemSwitchedOff) {
			this.props.updateDefaultStartPoint(null);
		}
	}
}
