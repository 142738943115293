import { GenerateFormStateMachine } from './form-states';
import { MachineConfig } from 'xstate/lib/types';
import { GivingStatementActions } from '../actions/main-actions';
import { DeleteStatementsStateMachine } from './delete-statements-states';

export enum ConfigurePageStates {
	MAIN = 'Configure Page Main',
	DELETE = 'Configure Page Delete',
	INITIAL = 'Initial Configure Page State',
	FUNDS_MODAL_ACTIVE = 'Show Funds Modal',
	LOAD_COMMUNITY_MEMBER_STATS_FAILED = 'Load Community Member Stats Failed',
	DUPLICATE_MODAL_ACTIVE = 'Show Duplicate Period Modal',
}

export enum ConfigurePageEvents {
	SHOW_FUNDS_MODAL = 'Show Funds modal',
	DISMISS_FUNDS_MODAL = 'Dismiss Funds modal',
	SHOW_DUPLICATE_PERIOD_MODAL = 'Show Duplicate Period modal',
	DISMISS_DUPLICATE_PERIOD_MODAL = 'Dismiss Duplicate Period modal',
	LOAD_COMMUNITY_MEMBER_STATS = 'Load Community Member Stats',
	LOAD_COMMUNITY_MEMBER_STATS_FAILURE = 'Load Community Member Stats Failure',
}

const ConfigureFormStateMachine = () => GenerateFormStateMachine(
	GivingStatementActions.SubmitConfigurationForm,
	GivingStatementActions.ConfigurationValidationSucceeded,
	GivingStatementActions.ConfigurationValidationFailed
);

export const ConfigurePageStateMachine = (): MachineConfig => ({
	parallel: true,
	states: {
		[ConfigurePageStates.MAIN]: {
			initial: ConfigurePageStates.INITIAL,
			states: {
				[ConfigurePageStates.INITIAL]: {
					on: {
						[ConfigurePageEvents.SHOW_FUNDS_MODAL]: {
							[ConfigurePageStates.FUNDS_MODAL_ACTIVE]: {
								actions: [GivingStatementActions.ShowFundsModal]
							}
						},
						[ConfigurePageEvents.SHOW_DUPLICATE_PERIOD_MODAL]: ConfigurePageStates.DUPLICATE_MODAL_ACTIVE,
						[ConfigurePageEvents.LOAD_COMMUNITY_MEMBER_STATS]: {
							[ConfigurePageStates.INITIAL]: {
								actions: [GivingStatementActions.LoadCommunityMemberFundStats]
							}
						},
						[ConfigurePageEvents.LOAD_COMMUNITY_MEMBER_STATS_FAILURE]: ConfigurePageStates.LOAD_COMMUNITY_MEMBER_STATS_FAILED,
					},
					...ConfigureFormStateMachine(),
				},
				[ConfigurePageStates.FUNDS_MODAL_ACTIVE]: {
					on: {
						[ConfigurePageEvents.DISMISS_FUNDS_MODAL]: ConfigurePageStates.INITIAL,
					},
					...FundsModalStateMachine(),
				},
				[ConfigurePageStates.LOAD_COMMUNITY_MEMBER_STATS_FAILED]: {
					on: {
						[ConfigurePageEvents.LOAD_COMMUNITY_MEMBER_STATS]: {
							[ConfigurePageStates.INITIAL]: {
								actions: [GivingStatementActions.LoadCommunityMemberFundStats]
							}
						},
					}
				},
				[ConfigurePageStates.DUPLICATE_MODAL_ACTIVE]: {
					on: {
						[ConfigurePageEvents.DISMISS_DUPLICATE_PERIOD_MODAL]:
							ConfigurePageStates.INITIAL,
					},
					...ConfigureFormStateMachine(),
				},
			},
		},
		[ConfigurePageStates.DELETE]: DeleteStatementsStateMachine(),
	}
});

export enum FundsModalStates {
	INITIAL = 'Initial Funds Modal State',
	LOADING = 'Loading Funds',
	SUCCEEDED = 'Loading Funds Succeeded',
	FAILED = 'Loading Funds Failed',
}

export enum FundsModalEvents {
	LOAD = 'Load Funds',
	LOAD_SUCCESS = 'Load Funds Success',
	LOAD_FAILURE = 'Load Funds Failure',
}

export const FundsModalStateMachine = (): MachineConfig => ({
	initial: FundsModalStates.INITIAL,
	states: {
		[FundsModalStates.INITIAL]: {
			on: {
				[FundsModalEvents.LOAD]: {
					[FundsModalStates.LOADING]: {
						actions: [GivingStatementActions.LoadFunds]
					}
				},
			},
		},
		[FundsModalStates.LOADING]: {
			on: {
				[FundsModalEvents.LOAD_SUCCESS]: FundsModalStates.SUCCEEDED,
				[FundsModalEvents.LOAD_FAILURE]: FundsModalStates.FAILED,
			},
		},
		[FundsModalStates.SUCCEEDED]: {},
		[FundsModalStates.FAILED]: {},
	},
});
