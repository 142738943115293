import * as React from 'react';
export interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	isProcessingRequest?: boolean;
	submit?: boolean;
	acceptanceTestTargetId?: string;
}

export class Button extends React.Component<IButtonProps, {}> {
	render() {
		const { submit, disabled, isProcessingRequest, acceptanceTestTargetId, ...additionalProps } = this.props;
		return (
			<button type={submit ? 'submit' : 'button'} {...additionalProps}
				disabled={disabled || isProcessingRequest} data-pp-at-target={acceptanceTestTargetId}>
				{this.props.children}{isProcessingRequest ? '...' : ''}
			</button>
		);
	}
}
