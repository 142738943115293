import * as React from 'react';
import { observer } from 'mobx-react';
import { SimpleMatchModal } from '../../../components/match-modal';

interface CampaignsErrorModalProps {
	state: string | string[];
	onClose: () => void;
}

const errorMessage = `Our system encountered an unexpected error and we're currently looking into it. Please try again soon.`;

export const CampaignsErrorModal = observer(({ state, onClose }: CampaignsErrorModalProps) => (
	<SimpleMatchModal
		type="warning"
		state={state}
		getBody={() => <div>{errorMessage}</div>}
		getFooter={() => (<button className="btn btn-cancel" onClick={onClose}>Close</button>)} />
));
