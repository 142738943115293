import { isArrayLike } from 'mobx';
import moment from 'moment';
import { looksLikeCreditCard, looksLikeEmail } from '../../helpers/validationhelper';
import { isFunction } from '../../utils/is-function';

export function required(errorMessage: any) {
	return (value: any) => {
		if (value === null || value === undefined) {
			return errorMessage;
		}
		if (typeof value === 'string' && value.trim() === '') {
			return errorMessage;
		}
		return null;
	};
}

export function isTrue(errorMessage: string) {
	return (value: boolean) => {
		if (!!value) {
			return null;
		}
		return errorMessage;
	};
}

export function noCreditCardDetails(errorMessage: string) {
	return (value: any) => {
		if (typeof value !== 'string' && typeof value !== 'number') {
			return null;
		}
		if (!looksLikeCreditCard(value.toString())) {
			return null;
		}
		return errorMessage;
	};
}

export function stringLength(min: number, max: number, errorMessage: string) {
	if (min > max) {
		throw new Error('Min length must be smaller than max length');
	}

	if (max === 0) {
		throw new Error('Max length must be greater than zero');
	}

	return (value: any) => {
		if (value === '' || value === undefined || value === null) {
			return null; //Use required validator to enforce filling out the field
		}
		if (typeof value !== 'string') {
			throw new Error('length validator requires an argument of type string');
		}

		const trimmedString = value.trim();

		const tooLong = trimmedString.length > max;
		const tooShort = trimmedString.length < min;
		if (tooShort || tooLong) {
			return errorMessage;
		}
		return null;
	};
}

export function maxLength(max: number, errorMessage: string) {
	return (value: any) => {
		if (value === '' || value === undefined || value === null) {
			return null; //Use required validator to enforce filling out the field
		}
		if (typeof value !== 'string') {
			const trimmedString = value.trim();
			return trimmedString.length > max ? errorMessage : null;
		}
		if (isArrayLike(value)) {
			return value.length > max ? errorMessage : null;
		}
		throw new Error('maxlength validator requires an argument or type string or array');
	};
}

export function minLength(min: number, errorMessage: string) {
	return (value: any) => {
		if (value === '' || value === undefined || value === null) {
			return null; //Use required validator to enforce filling out the field
		}
		if (typeof value === 'string') {
			const trimmedString = value.trim();
			return trimmedString.length < min ? errorMessage : null;
		}
		if (isArrayLike(value)) {
			return value.length < min ? errorMessage : null;
		}
		throw new Error('minlength validator requires an argument or type string or array');
	};
}

export function email(errorMessage: string) {
	return (value: any) => {
		if (value === null || value === undefined || value === '') {
			return null; //Use required validator to enforce filling out the field
		}
		if (typeof value !== 'string') {
			throw new Error('email validator requires an argument of type string');
		}
		// Lets ignore leading and trailing white space
		value = value.trim();
		// Some simple checks to cover cases that the regex below can't deal with:
		const [localPart, domain] = value.split('@');
		if (localPart) {
			if (localPart[0] === '.' || localPart[localPart.length - 1] === '.') {
				return errorMessage;
			}
			if (localPart.indexOf('..') > -1) {
				return errorMessage;
			}
		}
		if (domain && domain.indexOf('.') === -1) {
			return errorMessage;
		}

		return looksLikeEmail(value) ? null : errorMessage;
	};
}

export function date(errorMessage: string) {
	return (value: any) => {
		if (value === null || value === undefined) {
			return null; //Use required validator to enforce filling out the field
		}
		if (value instanceof Date && moment(value).isValid()) {
			return null;
		}
		return errorMessage;
	};
}

export function dateAfter<T>(getDateRange: (value: T) => [Date, Date], errorMessage: string) {
	return (value: T) => {
		if (!isFunction(getDateRange)) {
			throw new Error('dateAfter validator requires a function to get from and to date.');
		}

		const [from, to] = getDateRange(value);

		if (!from || !to) {
			return null; //Use required validator to enforce filling out the field
		}

		if (!(from instanceof Date) || !moment(from).isValid()) {
			return errorMessage;
		}

		if (!(to instanceof Date) || !moment(to).isValid()) {
			return errorMessage;
		}

		if (moment(from).isAfter(moment(to))) {
			return errorMessage;
		}

		return null;
	};
}

export function number(errorMessage: string) {
	return (value: any) => {
		if (value === null || value === undefined) {
			return null; //Use required validator to enforce filling out the field
		}
		if (typeof value === 'number' && !isNaN(value)) {
			return null;
		}
		return errorMessage;
	};
}

export function range(min: number, max: number, errorMessage: string) {
	if (min > max) {
		throw new Error('Min must be smaller than max');
	}

	return (value: any) => {
		if (value === null || value === undefined) {
			return null; //Use required validator to enforce filling out the field
		}
		if (typeof value !== 'number') {
			return errorMessage;
		}
		if (isNaN(value)) {
			return errorMessage;
		}
		if (value < min || value > max) {
			return errorMessage;
		}
		return null;
	};
}

export function regex(regex: RegExp, errorMessage: string) {
	return (value: any) => {
		if (!value) {
			return null;
		}
		if (regex.test(value)) {
			return null;
		}
		return errorMessage;
	};
}
