import * as React from 'react';
import { MigrationSummaryDashboardViewModel } from './migration-summary-dashboard-view-model';
import { observer } from 'mobx-react';
import { FormControlSelect } from '../../../components/form-controls/form-control-select';

import * as styles from './styles.less';
import { LoadingBox } from '../../../components/loading-box';
import { Fragment } from '../../../../Shared/components/fragment';
import { MigrationSummary } from '../../components/migration-summary';
import { Timeline } from '../../components/timeline';
import { Givers } from '../../components/givers';
import { ScheduleImportDraftDisplayStatus } from '../../schedule-import-generated';
import { SvgWrapper } from '../../../../Shared/components/svg-wrapper';

export interface IDashboardProps {
	vm: MigrationSummaryDashboardViewModel;
}

@observer
export class MigrationSummaryDashboard extends React.Component<IDashboardProps> {
	render() {
		const {
			vm: {
				selectedListingId,
				merchantListings,
				funds,
				selectedFundId,
				isLoadingData,
				statusLabelClass,
				statusLabelText,
				uploadSchedulesUrl,
				isMigrationEditable,
			}
		} = this.props;

		const listingValue = selectedListingId ? selectedListingId.toString(10) : '';
		const fundValue = selectedFundId ? selectedFundId.toString(10) : '';

		return (
			<div>
				<div className={styles.header}>
					<h1>Migration Summary <span className={`label ${statusLabelClass} list-group-item-label`}>{statusLabelText}</span></h1>
					{isMigrationEditable && <a href={uploadSchedulesUrl} className={`btn btn-link btn-sm ${styles.uploadLink}`}>
						<SvgWrapper svg="icon-add" className={`icon ${styles.iconAdd}`} />
						Upload more schedules
					</a>}
				</div>
				<div className={styles.summaryFilter}>
					<div className={styles.filterWrapper}>
						<label htmlFor="Listing_0" className={styles.filterLabel}>Listing:</label>
						<FormControlSelect Options={merchantListings}
							onChangeHandler={this.listingChangedHandler}
							value={listingValue} name={'Listing'}
							uniqueSuffix="0"
							placeholder="All listings"
							wrapperClassName={styles.filterSelect}/>
					</div>
					<div className={styles.filterWrapper}>
						<label htmlFor="Funds_0" className={styles.filterLabel}>Fund:</label>
						<FormControlSelect Options={funds}
							onChangeHandler={this.fundChangedHandler}
							value={fundValue} name={'Funds'}
							uniqueSuffix="0"
							placeholder="All funds"
							wrapperClassName={styles.filterSelect}/>
					</div>
				</div>
				<div className={styles.details}>
					{isLoadingData ? <LoadingBox text="Loading"/> : this.renderPanels()}

				</div>
			</div>
		);
	}

	listingChangedHandler = (e: React.FormEvent<HTMLSelectElement>): void => {
		const val = Number(e.currentTarget.value);
		const listingId = val === 0 ? null : val;
		this.props.vm.merchantListingChangedHandler(listingId);
	}

	fundChangedHandler = (e: React.FormEvent<HTMLSelectElement>): void => {
		const val = Number(e.currentTarget.value);
		const fundId = val === 0 ? null : val;
		this.props.vm.fundsChangedHandler(fundId);
	}

	private renderPanels() {
		const { summaryViewModel, editMigrationUrl, fundName, listingName, isMigrationEditable } = this.props.vm;
		const { TotalScheduleCount, TotalEstimatedMonthlyAmount, MigratedScheduleCount, MigratedEstimatedMonthlyAmount, PercentageMigrated,
			RemainingScheduleCount, RemainingEstimatedMonthlyAmount, DeclinedScheduleCount, DaysLeft, StartDate, EndDate, UnreachedScheduleCount, MigratedElsewhereScheduleCount,
			TotalGiverCount, MigratedGiverCount, UnreachedGiverCount } = summaryViewModel;

		return (
			<Fragment>
				<div className={styles.panels}>
					<MigrationSummary
						overallScheduleCount={TotalScheduleCount}
						overallScheduleValue={TotalEstimatedMonthlyAmount}
						migratedScheduleCount={MigratedScheduleCount}
						migratedScheduleValue={MigratedEstimatedMonthlyAmount}
						migratedSchedulePercent={PercentageMigrated}
						migratedDetailsUrl={this.getDetailsUrl(ScheduleImportDraftDisplayStatus.Migrated)}
						incompleteScheduleCount={RemainingScheduleCount}
						incompleteScheduleValue={RemainingEstimatedMonthlyAmount}
						incompleteDetailsUrl={this.getDetailsUrl(ScheduleImportDraftDisplayStatus.Awaiting)}
						declinedScheduleCount={DeclinedScheduleCount}
						declinedDetailsUrl={this.getDetailsUrl(ScheduleImportDraftDisplayStatus.Declined)}
						migratedElsewhereCount={MigratedElsewhereScheduleCount}
						migratedElsewhereUrl={this.getDetailsUrl(ScheduleImportDraftDisplayStatus.MigratedElsewhere)}
						unreachedCount={UnreachedScheduleCount}
						unreachedUrl={this.getDetailsUrl(ScheduleImportDraftDisplayStatus.NotReached)}
						wrapperClassName={styles.migrationSummaryPanel}
						fundName={fundName}
						listingName={listingName}/>
					<Timeline
						daysLeft={DaysLeft}
						startDate={StartDate}
						endDate={EndDate}
						editDetailsUrl={editMigrationUrl}
						isMigrationEditable={isMigrationEditable}
						extraClassNames={styles.timelinePanel}/>
				</div>
				<Givers migratedCount={MigratedGiverCount} unreachedCount={UnreachedGiverCount} uploadedCount={TotalGiverCount} />
			</Fragment>
		);
	}

	private getDetailsUrl(status?: ScheduleImportDraftDisplayStatus) {
		const { schedulesDetailsUrl, selectedListingId, selectedFundId } = this.props.vm;

		const params: string[] = [];

		if(status) {
			params.push(`SelectedStatus=${status}`);
		}

		if(selectedListingId) {
			params.push(`SelectedListings=${selectedListingId}`);
		}

		if(selectedFundId) {
			params.push(`SelectedFunds=${selectedFundId}`);
		}

		return `${schedulesDetailsUrl}?${params.join('&')}`;
	}
}
