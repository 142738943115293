import * as React from 'react';
import Pikaday from 'pikaday';
import moment from 'moment';
import { observer } from 'mobx-react';
import { responsiveHelper } from '../../helpers/responsive-helper';
import { SvgWrapper } from '../../components/svg-wrapper';
import { supportsDateInput } from '../../helpers/browser-support-helper';
import { classNames } from '../../utils/classnames';
import { Fragment } from '../../components/fragment';

export const dateFormat = 'YYYY-MM-DD';

export type OptionalDate = Date | undefined | null;

export interface IDatePickerCommonProps {
	acceptanceTestTargetId: string;
	formControlId?: string;
	placeholder?: string;
	hideIcon?: boolean;
	options?: Pikaday.PikadayOptions;
	additionalClassNames?: string;
	disabled?: boolean;
}

export interface IDatePickerPrimitiveProps {
	value: OptionalDate;
	onChange: (value: OptionalDate) => void;
	onBlur?: () => void;
	ariaInvalid?: boolean;
	ariaRequired?: boolean;
}

export type IDatePickerProps = IDatePickerCommonProps & IDatePickerPrimitiveProps;


export const DatePicker = observer((props: IDatePickerProps) => (
	<div className="date-picker">
		{(responsiveHelper.isXs || responsiveHelper.isSm) && supportsDateInput()
			? <DateInputHTMLField {...props} />
			: <DateInputPikaday {...props} />
		}
		{
			!props.hideIcon && <SvgWrapper svg="calendar" className="svg svg-calendar-red" />
		}
	</div>
));


@observer
class DateInputPikaday extends React.Component<IDatePickerProps> {
	private pikaday: Pikaday | null;

	render() {
		const {
			formControlId,
			placeholder,
			acceptanceTestTargetId,
			additionalClassNames,
			disabled,
			ariaInvalid,
			ariaRequired,
		} = this.props;

		return <input
			id={formControlId}
			onBlur={this.onBlur}
			className={classNames('form-control', additionalClassNames)}
			placeholder={placeholder}
			ref={this.initPikaday}
			disabled={disabled}
			data-pp-at-target={acceptanceTestTargetId}
			aria-invalid={ariaInvalid}
			aria-required={ariaRequired}
			autoComplete="off" />;

	}

	UNSAFE_componentWillReceiveProps(nextProps: IDatePickerProps) {
		const currentValue = this.props.value;
		const newValue = nextProps.value;
		this.setValue(newValue, currentValue);

		const currentMinDate = this.props.options && this.props.options.minDate;
		const newMinDate = nextProps.options && nextProps.options.minDate;
		this.setMinDate(newMinDate, currentMinDate, newValue);

		const currentMaxDate = this.props.options && this.props.options.maxDate;
		const newMaxDate = nextProps.options && nextProps.options.maxDate;
		this.setMaxDate(newMaxDate, currentMaxDate, newValue);
	}

	componentWillUnmount() {
		this.destroyPikadayIfExists();
	}

	private destroyPikadayIfExists = () => {
		if (this.pikaday) {
			this.pikaday.destroy();
			this.pikaday = null;
		}
	}

	private initPikaday = (field: HTMLInputElement) => {
		this.destroyPikadayIfExists();

		if (field) {
			this.pikaday = new Pikaday({
				...this.props.options,
				onSelect: this.onSelect,
				format: dateFormat,
				field,
				blurFieldOnSelect: false
			} as Pikaday.PikadayOptions & { blurFieldOnSelect?: boolean });

			if (!!this.props.value) {
				this.pikaday.setDate(this.props.value, true);
			} else {
				field.value = '';
			}
		}
	}

	private setValue = (newValue: OptionalDate, currentValue: OptionalDate) => {
		if (this.pikaday && newValue instanceof Date && !isSameDate(newValue, currentValue)) {
			this.pikaday.setDate(newValue, true);
			this.pikaday.gotoDate(newValue);
		}
	}

	private setMinDate = (newMinDate: OptionalDate, currentMinDate: OptionalDate, newValue: OptionalDate) => {
		if (this.pikaday && !isSameDate(newMinDate, currentMinDate)) {
			if (newMinDate) {
				this.pikaday.setMinDate(newMinDate);

				if (newValue && newMinDate > newValue) {
					this.pikaday.gotoDate(newMinDate);
				}
			} else {
				this.pikaday.setMinDate(null as any);
			}
		}
	}

	private setMaxDate = (newMaxDate: OptionalDate, currentMaxDate: OptionalDate, newValue: OptionalDate) => {
		if (this.pikaday && !isSameDate(newMaxDate, currentMaxDate)) {
			if (newMaxDate) {
				this.pikaday.setMaxDate(newMaxDate);

				if (newValue && newMaxDate < newValue) {
					this.pikaday.gotoDate(newMaxDate);
				}
			} else {
				this.pikaday.setMaxDate(null as any);
			}
		}
	}

	// Make pikaday more consistent with other input types by only firing onChange
	private onSelect = (date: OptionalDate) => {
		if (date && isSameDate(date, this.props.value)) {
			return;
		}
		this.props.onChange(date);
	}

	// When the input is left with an empty or invalid value it wont fire onSelect
	// This manually fires onSelect(null) to update any watching observables to null
	private onBlur = (ev: React.FocusEvent<HTMLInputElement>) => {
		const input = ev.currentTarget;

		if (!input.value) {
			this.onSelect(null);
		} else {
			const inputMoment = moment(input.value, dateFormat, false);
			const isInvalid = !inputMoment.isValid();
			if (isInvalid) {
				this.onSelect(inputMoment.toDate());
			}
		}
		if (this.props.onBlur) {
			this.props.onBlur();
		}
	}
}

@observer
class DateInputHTMLField extends React.Component<IDatePickerProps> {
	render() {
		const {
			formControlId,
			placeholder,
			value,
			options,
			acceptanceTestTargetId,
			additionalClassNames,
			disabled,
			ariaInvalid,
			ariaRequired,
			onBlur,
		} = this.props;

		const date = value ? moment(value).format(dateFormat) : '';
		const min = options && options.minDate ? moment(options.minDate).format(dateFormat) : '';
		const max = options && options.maxDate ? moment(options.maxDate).format(dateFormat) : '';

		const pseudoPlaceholderElement = date
			? null
			: <input
				type="text"
				className={classNames('form-control', additionalClassNames)}
				placeholder={placeholder}
				disabled={disabled}
				aria-hidden={true} />;

		return (
			<Fragment>
				{pseudoPlaceholderElement}
				<input
					id={formControlId}
					type="date"
					onChange={this.onChange}
					className={classNames('form-control', additionalClassNames, pseudoPlaceholderElement && 'with-placeholder')}
					onBlur={onBlur}
					value={date}
					min={min}
					max={max}
					disabled={disabled}
					data-pp-at-target={acceptanceTestTargetId}
					aria-invalid={ariaInvalid}
					aria-required={ariaRequired}
					autoComplete="off" />
			</Fragment>
		);
	}

	private onChange = (event: React.FormEvent<HTMLInputElement>) => {
		const { value } = event.currentTarget;
		const m = moment(value);
		const date = m.isValid() ? m.toDate() : null;
		if (date) {
			this.props.onChange(date);
		}
	}
}


function isSameDate(a: OptionalDate, b: OptionalDate) {
	if (!a || !b) {
		return a === b;
	}
	if (a.getTime && b.getTime) {
		return (a.getTime() === b.getTime());
	}
	return a === b;
}
