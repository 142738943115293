import { MachineContext } from '../../../Shared/state-machine/saga-state-machine';
import { DeleteStatementsEvents } from '../state/delete-statements-states';
import { givingStatementsDataService } from '../giving-statements-data-service';
import { GivingStatementsViewModel } from '../giving-statements-view-model';
import { MainEvents } from '../state/main';

export const DeleteStatementsActionMap = {
    DeleteStarted: function* (context: MachineContext) {
        const vm: GivingStatementsViewModel = context.viewModel;
        const { currentConfigurationId: runId } = vm;
        try {
            yield givingStatementsDataService.deleteRun({ runId });

            context.transition(MainEvents.LOAD_LANDING_VIEW);
        } catch (e) {
            context.transition(DeleteStatementsEvents.DELETE_FAILURE);
        }
    },
}