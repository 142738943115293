import {NodeListHelper} from '../helpers/nodelisthelper';
import {velocity} from '../helpers/velocity';

export class ContentSwitcher {

	private hiddenClass: string = 'hidden';
	private containerElement: HTMLElement;
	private contentSections: AnimatedItem[] = [];


	constructor(containerElement: HTMLElement, initialSectionIdentifier: string = null) {
		this.containerElement = containerElement;
		this.containerElement.style.overflow = 'hidden';


		let sections = NodeListHelper.toArray(this.containerElement.querySelectorAll('[data-content-switcher-section]'));

		let initializeSection: boolean = initialSectionIdentifier !== null;

		sections.map(section => {
			let animatedItem: AnimatedItem = new AnimatedItem(section as HTMLElement);
			if(initializeSection) {
				if(animatedItem.sectionIdentifier === initialSectionIdentifier) {
					animatedItem.element.classList.remove(this.hiddenClass);
				} else {
					animatedItem.element.classList.add(this.hiddenClass);
				}
			}
			this.contentSections.push(animatedItem);
		});
	}

	public showSection(sectionIdentifier: string) {
		let itemToHide: AnimatedItem = null;
		let itemToShow: AnimatedItem = null;

		const duration = 200;

		this.contentSections.map(item => {
			if (item.isElementVisible()) {
				itemToHide = item;
			}

			if (item.sectionIdentifier === sectionIdentifier) {
				itemToShow = item;
			}
		});
		if (itemToHide === itemToShow) {
			// nothing to do
			return;
		}

		let startHeight = this.calculateHeight(itemToHide);
		let endHeight = this.calculateHeight(itemToShow);

		velocity(this.containerElement, {height: [endHeight, startHeight]}, { duration: duration * 2});

		velocity(itemToHide.element, {opacity: [0, 1]}, {
			duration: duration,
			complete: () => {
				itemToHide.element.classList.add('hidden');
				itemToShow.element.classList.remove('hidden');

				velocity(itemToShow.element, {opacity: [1, 0]}, {
					duration: duration
				});
			}
		});
	}

	private calculateHeight(item: AnimatedItem) {

		let height;
		let elem = item.element;
		if(item.isElementHidden()) {
			// give hidden element 'layout' so height can be measured (but not seen)
			this.containerElement.style.position = 'relative';
			elem.style.opacity = '0';
			elem.style.position = 'absolute';
			elem.style.width = this.containerElement.offsetWidth + 'px';
			elem.style.visibility = 'hidden';
			elem.classList.remove('hidden');

			height = elem.offsetHeight;

			// reset styles to before measurement
			elem.style.position = '';
			elem.style.width = '';
			elem.style.visibility = '';
			elem.classList.add('hidden');
			this.containerElement.style.position = '';
		} else {
			height = elem.offsetHeight;
		}

		return height;
	}

}

class AnimatedItem {

	public sectionIdentifier: string;

	public element:HTMLElement;

	constructor(element:HTMLElement) {
		this.element = element;
		this.sectionIdentifier = element.dataset['contentSwitcherSection'];
	}

	public isElementHidden(): boolean {
		return this.element.classList.contains('hidden');
	}

	public isElementVisible(): boolean {
		return !this.isElementHidden();
	}
}

