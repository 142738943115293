import { sendRaygun } from './login-utils';
import { MessageBox } from './message-box';
import { Models } from './login.generated';
import { FormSubmitCallbackResult, LoginForm } from './login-form';
import { LoginTabs } from './login-tabs';
import { TxtSentBanner } from './txt-sent-banner';
import { LoginTooltip } from './login-tooltip';
import { EmailPasswordForm } from './form-email-password';

export class MobileCodeForm {
	private form: LoginForm<Models.PasswordlessCodeVerificationResultModel>;

	private displayMobileNumber: HTMLElement;
	private requestToken: HTMLInputElement;
	private resendCodeButton: HTMLButtonElement;
	private resendButtonTooltip: LoginTooltip;
	private mobileNumberHidden: HTMLInputElement;
	private banner: TxtSentBanner;

	constructor(private messageBox: MessageBox, private loginTabs: LoginTabs, showTxtSentBannerOnLoad: boolean, private adminEmailPasswordForm: EmailPasswordForm) {
		this.form = new LoginForm<Models.PasswordlessCodeVerificationResultModel>(Models.LoginView.PayerMobileCode, loginTabs, messageBox, (data) => this.loginResult(data));
		this.displayMobileNumber = this.form.querySelector<HTMLElement>('[data-pp-login-mobile-display]');
		this.requestToken = this.form.querySelector<HTMLInputElement>('input[name=RequestToken]');
		this.resendCodeButton = this.form.querySelector('[data-pp-login-resend-code]') as HTMLButtonElement;
		this.resendButtonTooltip = new LoginTooltip(this.resendCodeButton);
		this.mobileNumberHidden = this.form.querySelector('input[name=NormalisedInternationalNumber]') as HTMLInputElement;
		this.banner = new TxtSentBanner(showTxtSentBannerOnLoad);

		this.resendCodeButton.addEventListener('click', (e) => this.resendCodeButtonListenser(e));
	}

	setUpFormData(token: string, mobileNumber: Models.PasswordlessMobileNumber): void {
		this.displayMobileNumber.innerText = mobileNumber.NationalNumber;
		this.requestToken.value = token;
		this.mobileNumberHidden.value = mobileNumber.NormalisedInternationalNumber;

		this.banner.hideBanner();

		this.resendCodeButton.classList.remove('disabled');
		this.resendButtonTooltip.disable();
	}

	loginResult(data: Models.PasswordlessCodeVerificationResultModel): FormSubmitCallbackResult {
		switch(data.Result) {
			case Models.PasswordlessCodeVerificationResult.LoggedIn:
			case Models.PasswordlessCodeVerificationResult.MobileNumberIsSuspicious:
				window.location.href = data.RedirectTo;
				return FormSubmitCallbackResult.AboutToNavigate;
			case Models.PasswordlessCodeVerificationResult.EmailAndPasswordAuthenticationRequired:
				this.loginTabs.showAdminForm();
				this.adminEmailPasswordForm.setUserName(data.UserName);
				this.messageBox.displayError(data.ErrorMessage);
				return FormSubmitCallbackResult.ReenableForm;
			default:
				this.messageBox.displayError(data.ErrorMessage);
				return FormSubmitCallbackResult.ReenableForm;
		}
	}

	resendCodeButtonListenser(e: Event): void {
		if (this.resendCodeButton.classList.contains('disabled')) {
			return;
		}

		const url = '/loginrc';
		const data = this.form.serializeData();

		$.ajax({
			url: url,
			type: 'POST',
			data: data,
			success: (data) => this.resendCodeSuccess(data),
			error: (xhr) => this.ajaxError(url, xhr)
		});
	}

	resendCodeSuccess(data: Models.PasswordlessResendCodeResultModel): void {
		if (data.Result === Models.PasswordlessMobileLoginResult.ConfirmationCodeSent) {
			this.banner.showBanner();
			this.requestToken.value = data.Token;

			this.resendCodeButton.classList.add('disabled');
			this.resendButtonTooltip.enable();

			var secondsBetweenRequests = Number(this.resendCodeButton.getAttribute('data-seconds-between-requests'));
			setTimeout(() => {
				this.resendCodeButton.classList.remove('disabled');
				this.resendButtonTooltip.disable();
			}, secondsBetweenRequests * 1000);
		}
	}

	private ajaxError(url: string, client: XMLHttpRequest) {
		if (client.status === 401) {
			// 401 indicates the users CSRF token has expired. Need to refresh the page and try again
			this.messageBox.error('Your session has timed out. Please refresh your page and try again.');
		} else if (client.status === 429) {
			// 429 indicates the the user has been throttled
			this.messageBox.error('Too many requests sent. Please wait a few minutes before trying again.');
		} else {
			sendRaygun('PostError', url, client);
			this.messageBox.error('Something went wrong. Please refresh your page and try again.');
		}
	}
}
