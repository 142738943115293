import * as AjaxClient from '../../utils/ajax-client';
import { Models } from '../check-deposit-generated';

export async function registerScanner(url: string, scanner: Models.RegisterScannerViewModel): Promise<Models.RegisteredScannersViewModel> {
	try {
		return await AjaxClient.post<Models.RegisteredScannersViewModel>(url, scanner, { timeout: 30000 });
	} catch (error) {
		if (error instanceof AjaxClient.PostError
			&& (error.shouldReloadPage || error.timedout)) {
			document.location.reload(true);
		}

		throw error;
	}
}

export async function deleteScanner(url: string, scannerId: number): Promise<Models.RegisteredScannersViewModel> {
	try {
		return await AjaxClient.post<Models.RegisteredScannersViewModel>(url, { scannerId }, { timeout: 30000 });
	} catch (error) {
		if (error instanceof AjaxClient.PostError
			&& (error.shouldReloadPage || error.timedout)) {
			document.location.reload(true);
		}

		throw error;
	}
}
