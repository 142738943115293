import * as React from 'react';
import { Button } from '../../../Shared/components/button/button';

export interface IDuplicatePeriodModalProps {
	onCancel(): void;
	onSubmit(): void;
}

export const DuplicatePeriodModal: React.StatelessComponent<IDuplicatePeriodModalProps> = (props: IDuplicatePeriodModalProps) => {
	const { onCancel, onSubmit } = props;
	return (
		<div className="modal-content panel panel-plain">
			<Button className="close" aria-label="Close" onClick={onCancel}/>
			<div className="panel-heading"><h2>Duplicate time period</h2></div>
			<div className="panel-body">
				<>
					<p>A Giving Statement run for the selected time period already exists.</p>
					If you would like to create a new run for this timeframe, press continue
				</>
			</div>
			<div className="panel-footer panel-footer-btn-group">
				<Button className="btn btn-cancel" aria-label="Close" onClick={onCancel}>Close</Button>
				<Button className="btn btn-primary" aria-label="Ok" onClick={onSubmit}>Continue</Button>
			</div>
		</div>
	);
};
