export type ICheckNumberParsingResult = {
	routingNumber: string;
	accountNumber: string;
	checkNumber: string;
	cleanedCheckReaderOutput: string;
	success: true;
} | {
		cleanedCheckReaderOutput: string;
		success: false
	};

const businessCheckRegex = /.*?U([\d ?]+)U.*?T([\d ?]+)T.*?([\d ?]+).*?/;
const checkReaderOutputRegex = /[TU?][\d?]+/;

export namespace NumberParser {
	export function parseReadCheckNumber(unparsedText: string): ICheckNumberParsingResult {
		const cleanedText = unparsedText.trim();
		return parseBusinessCheckNumber(cleanedText)
			|| parsePersonalCheckNumber(cleanedText)
			|| buildFailedCheckNumberParsingResult(cleanedText);
	}

	export function looksLikeCheckReaderOutput(text: string): boolean {
		return checkReaderOutputRegex.test(text);
	}

	function buildFailedCheckNumberParsingResult(text: string): ICheckNumberParsingResult {
		return {
			cleanedCheckReaderOutput: sanitizeUnparsedCheckReaderText(text),
			success: false
		};
	}

	function parseBusinessCheckNumber(unparsedText: string): ICheckNumberParsingResult {
		/* Example: U111008U T064109445T 684-048542U */
		const cleanedText = unparsedText.replace(/[-]/gi, '').replace('.', '');
		const [, checkNumber, routingNumber, accountNumber] = businessCheckRegex.exec(cleanedText) || ['', '', '', ''];
		return createReadNumber(routingNumber, accountNumber, checkNumber, unparsedText);
	}

	function parsePersonalCheckNumber(unparsedText: string): ICheckNumberParsingResult {
		let sections: string[] = unparsedText
			.replace(/[- ]/g, '')
			.replace('.', '')
			.split(/[a-zA-Z]/)
			.map(s => s.trim())
			.filter(s => !!s);

			if (sections.length < 3) {
				sections = unparsedText
					.replace('-', '')
					.replace('.', '')
					.split(/[a-zA-Z ]/)
					.map(s => s.trim())
					.filter(s => !!s);
			}

		let routingNumber = sections[0] || '';
		let accountNumber = sections[1] || '';
		let checkNumber = sections[2] || '';
		if (checkNumber.length > accountNumber.length) {
			let temp = checkNumber;
			checkNumber = accountNumber;
			accountNumber = temp;
		}

		return createReadNumber(routingNumber, accountNumber, checkNumber, unparsedText);
	}

	function createReadNumber(routingNumber, accountNumber, checkNumber, unparsedText): ICheckNumberParsingResult {
		const rn = validateRoutingNumber(cleanupField(routingNumber));
		const an = cleanupField(accountNumber);
		const cn = cleanupField(checkNumber);
		const co = sanitizeUnparsedCheckReaderText(unparsedText);
		return (rn && an && cn)
			? {
				routingNumber: rn,
				accountNumber: an,
				checkNumber: cn,
				cleanedCheckReaderOutput: co,
				success: true
			}
			: null;
	}

	function sanitizeUnparsedCheckReaderText(text: string): string {
		return text.replace(new RegExp('[0-9]', 'g'), '0');
	}

	function validateRoutingNumber(routing: string): string {
		return (routing && routing.length === 9) ? routing : '';
	}

	function cleanupField(value: string): string {
		if (value === null || value === undefined || value.indexOf('?') !== -1) {
			return '';
		}
		return value.replace(/ /g, '');
	}
}
