import * as React from 'react';
import { observer } from 'mobx-react';

import { ScheduleImportReviewViewModel } from './schedule-import-review-view-model';
import { ScheduleImportStep } from '../schedule-import-step';
import { Formatter } from '../../../helpers/formatter';
import { SummaryForm, SummaryRow } from '../../components/summary-form/summary-form';
import { SkippedRowGrid } from './skipped-row-grid';
import { FundGrid } from './fund-grid';
import { Button } from '../../../components/button';
import { ModalDialogCommander } from '../../../components/modal-dialog-commander';
import { ModalDialog } from '../../../components/modal-dialog';

@observer
export class ScheduleImportReview extends React.Component<{ vm: ScheduleImportReviewViewModel }> {
	render() {
		const {
			bundles,
			handleNewUpload,
			handleConfirmImport,
			actionInProgress,
			hasConfirmedOrActiveMigration,
			paymentLabel,
			bundlesSummary: {
				ImportedDonorsCount,
				ImportedMonthlyTotal,
				ImportedSchedulesCount
			}
		} = this.props.vm;

		const nextStepAction = hasConfirmedOrActiveMigration ? 'Add to migration' : 'Set up alerts';
		const headerButton = <a className={`btn btn-link arrow-hollow-right-after${ bundles.length === 0 ? ' disabled' : '' }`} onClick={handleConfirmImport} >{nextStepAction}</a>;

		return (
			<ScheduleImportStep vm={this.props.vm} headerButton={headerButton}>
				{
					bundles.map( (bundle, index) => (
						<SummaryForm listingName={bundle.ListingName} dataFormat={bundle.ImportType} showDeleteButton={true} handleDelete={()=>this.handleDeleteImport(bundle.BundleId)}>

							<SummaryRow label="Number of schedules" content={bundle.NumberOfSchedules.toString()}/>
							<SummaryRow label="Estimated average monthly total" content={this.amountForDisplay(bundle.MonthlyTotal)}/>
							<SummaryRow label="Estimated average monthly total skipped" content={this.amountForDisplay(bundle.MonthlyTotalSkipped)}/>
							<SummaryRow label={`Number of ${paymentLabel.PayerPluralLowerCase}`} content={bundle.NumberOfGivers.toString()}/>
							<SummaryRow label="Number of skipped schedules" content={bundle.SkippedRows.length.toString()}>
								{bundle.SkippedRows.length > 0 && <SkippedRowGrid rows={bundle.SkippedRows} totalAmount={bundle.MonthlyTotalSkipped}/>}
							</SummaryRow>
							<SummaryRow label="Number of funds" content={bundle.Funds.length.toString()}>
								{bundle.Funds.length > 0 && <FundGrid funds={bundle.Funds} totalAmount={bundle.FundsTotalAmount}/>}
							</SummaryRow>
						</SummaryForm>
					))
				}

				<form className="panel panel-color panel-default gray transaction-import-summary-panel">
					<div className="panel-heading top-border">
						<div className="summary-heading">
							<div className="summary-title">{hasConfirmedOrActiveMigration ? 'Total to add to migration' : 'Total to migrate'}</div>
						</div>
					</div>
					<div className="summary-panel-body">
						<SummaryRow label="Number of schedules" content={ImportedSchedulesCount.toString()}/>
						<SummaryRow label={`Number of ${paymentLabel.PayerPluralLowerCase}`} content={ImportedDonorsCount.toString()}/>
						<SummaryRow label="Estimated average monthly total" content={this.amountForDisplay(ImportedMonthlyTotal)}/>
					</div>
					<div className="panel-footer panel-footer-btn-group ">
						<Button className="btn btn-ghost" onClick={handleNewUpload} disabled={actionInProgress}>Upload schedules</Button>
						<Button className="btn btn-default" onClick={handleConfirmImport} disabled={actionInProgress || bundles.length === 0}>{nextStepAction}</Button>
					</div>
				</form>
			</ScheduleImportStep>
		);
	}

	handleDeleteImport(bundleId: number) {
		const confirmButton = <Button className="btn btn-primary" onClick={() => this.confirmDelete(bundleId)}>Confirm</Button>;
		const buttons = [{label: 'Cancel', dismiss: true, isPrimary: false}, confirmButton];

		ModalDialogCommander.showReactDialog(
			<ModalDialog
				title="Are you sure you want to delete this upload?"
				showAlert={false}
				hideCloseButton={true}
				buttons={buttons}/>);
	}

	confirmDelete(bundleId: number) {
		ModalDialogCommander.forceCloseCurrent();
		this.props.vm.handleDeleteImport(bundleId);
	}

	amountForDisplay(amount: number) {
		return <span><span className="dollar">$</span>{Formatter.formatNumberForDisplay(amount)}</span>;
	}
}
