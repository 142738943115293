import * as React from 'react';
import { observer } from 'mobx-react';
import { CreateCampaignMainViewModel } from './create-campaign-main-view-model';
import { CreateCampaignForm } from '../../components/create-campaign-form/create-campaign-form';
import { StateMachine } from '../../../../Shared/state-machine/saga-state-machine';
import { Match, MatchAny } from '../../../../Shared/state-machine/match';
import { responsiveHelper } from '../../../helpers/responsive-helper';
import { Events, States } from './create-campaign-states-events-and-actions';
import { CampaignStatus, CampaignEditRequest, EditCampaignConfirmationRequiredResponse } from '../../campaigns-generated';
import { CampaignsErrorModal } from '../../components/campaigns-modals/campaigns-error-modal';
import { CampaignsSaveConfirmationModal } from '../../components/campaigns-modals/campaign-save-confirmation-modal';
import { classNames } from '../../../../Shared/utils/classnames';
import { StatusLabel } from '../../components/status-label';

import * as styles from './create-campaign-main.less';

@observer
export class CreateCampaignMain extends React.Component<{ vm: CreateCampaignMainViewModel }> {
	render() {
		const { vm } = this.props;
		const { machineContext, campaignStatus, editConfirmationRequired } = vm;
		return (
			<StateMachine machineContext={machineContext}>
				<MatchAny states={[States.Creating, States.Editing]}>
					<Match state={States.Creating}>
						<h1>{responsiveHelper.isXs ? 'Create Campaign' : 'Create a new Campaign'}</h1>
						<p className={styles.intro}>Engage your community by creating a Campaign to raise donations for a specific purpose or cause.</p>
					</Match>
					<Match state={States.Editing}>
						<h1>
							Edit Campaign <StatusLabel status={campaignStatus} />
						</h1>
						<p className={styles.intro}>Edit and update your Campaign.</p>
					</Match>
					<CreateCampaignForm viewModel={vm} />
				</MatchAny>
				<CampaignsErrorModal state={States.Error} onClose={this.onCloseErrorModal} />
				<CampaignsSaveConfirmationModal
					state={States.WaitingForConfirmation}
					message={this.getConfirmationMessage(editConfirmationRequired)}
					onConfirm={this.onConfirmSaving}
					onCancel={this.onCancelSaving} />
			</StateMachine>
		);
	}

	onCloseErrorModal = () => {
		Events.raise.CloseErrorModal(this.props.vm.machineContext);
	}

	onConfirmSaving = () => {
		const { machineContext, fieldsForRequest } = this.props.vm;
		Events.raise.Save(machineContext, { ...fieldsForRequest, Publish: false, Confirmed: true } as CampaignEditRequest);
	}

	onCancelSaving = () => {
		Events.raise.CancelSaving(this.props.vm.machineContext);
	}

	getConfirmationMessage(editConfirmationRequired: EditCampaignConfirmationRequiredResponse) {
		if (!editConfirmationRequired) {
			return '';
		}

		const { NewStatus } = editConfirmationRequired;

		if(NewStatus === CampaignStatus.Published) {
			return 'The campaign will be live upon saving this change.';
		}

		if(NewStatus === CampaignStatus.Closed) {
			return 'The campaign will be closed upon saving this change.';
		}

		return '';
	}
}
