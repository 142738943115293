import * as React from 'react';

export interface ISvgWrapperProps extends React.SVGProps<SVGSVGElement> {
	svg: string;
	title?: string;
}

export const SvgWrapper: React.StatelessComponent<ISvgWrapperProps> = (props) => {
	const { svg, title, ...svgProps } = props;

	return <svg {...svgProps} >
		{title ? <title>{title}</title> : null}
		<use xlinkHref={`#${svg}`}></use>
	</svg>;
};
