import * as React from 'react';
import {ModalDialogCommander} from '../../components/modal-dialog-commander';
import {Button} from '../../components/button';
import {makeAjaxCall} from '../utils/ajax-helper';
import {DeleteFundErrorDialog} from './common-delete-fund-dialog';
import {SvgWrapper} from '../../components/svg-wrapper';

export interface IDeleteFundButtonProps {
	urlCanDelete: string;
	className: string;
	canDeleteFund: () => JQueryPromise<any>;
}

export class DeleteFundButton extends React.Component<IDeleteFundButtonProps, { isProcessingRequest?: boolean }> {

	constructor(props) {
		super(props);
		this.state = { isProcessingRequest: false };
	}

	handleButtonClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
		ev.preventDefault();

		this.setState({
			isProcessingRequest: true
		});

		makeAjaxCall(this.props.urlCanDelete)
			.fail(error => {
				ModalDialogCommander.showReactDialog(DeleteFundErrorDialog(error));
			})
			.then(() => this.props.canDeleteFund())
			.always(() => {
				this.setState({
					isProcessingRequest: false
				});
			});
	}

	render() {
		return (
			<Button className={this.props.className} onClick={this.handleButtonClick} isProcessingRequest={this.state.isProcessingRequest}>
				<SvgWrapper svg="icon-delete" className="icon"/>Delete fund
			</Button>);
	}
}
