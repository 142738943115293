import * as React from 'react';
import { CampaignsOutageMainViewModel } from './campaigns-outage-main-view-model';
import { SvgWrapper } from '../../../components/svg-wrapper';

import * as styles from './campaigns-outage-main.less';

export class CampaignsOutageMain extends React.Component<{ vm: CampaignsOutageMainViewModel }> {
	render() {
		const { vm: { backUrl } } = this.props;
		return (
			<div className={styles.container}>
				<SvgWrapper svg="alert-exclamation-icon" className={styles.icon} />
				<h1>Sorry, Campaigns is unavailable</h1>
				<div className={styles.intro}>
					Please try again later
				</div>
				<a href={backUrl} className="btn btn-ghost">Back</a>
			</div>
		);
	}
}
