import { VirtualTerminalPayStats } from '../virtual-terminal-generated';

export module Metrics {
	const paymentMetrics = {
		paymentEntryStarted: 'payment-entry-started',
		paymentEntryFinished: 'payment-entry-finished',
		addPayerStarted: 'add-payer-started',
		addPayerFinished: 'add-payer-finished',
		editPayerStarted: 'edit-payer-started',
		editPayerFinished: 'edit-payer-finished',
		paymentEntryTiming: 'payment-entry-timing',
		addPayerTiming: 'add-payer-timing',
		editPayerTIming: 'edit-payer-timing'
	};

	let paymentInProgress = false;

	export function paymentEntryStarted() {
		if (!supportsPerformanceApi() || paymentInProgress) {
			return;
		}

		window.performance.clearMarks();
		window.performance.clearMeasures();

		paymentInProgress = true;
		window.performance.mark(paymentMetrics.paymentEntryStarted);
	}

	export function paymentEntryFinished() {
		if (!supportsPerformanceApi()) {
			return;
		}
		window.performance.mark(paymentMetrics.paymentEntryFinished);

		try {
			window.performance.measure(paymentMetrics.paymentEntryTiming, paymentMetrics.paymentEntryStarted, paymentMetrics.paymentEntryFinished);
		} catch(error) {
			window.reportUnhandledRejection(error);
		} finally {
			paymentInProgress = false;
		}
	}

	export function addPayerStarted() {
		if (!supportsPerformanceApi()) {
			return;
		}
		window.performance.mark(paymentMetrics.addPayerStarted);
	}

	export function addPayerFinished() {
		if (!supportsPerformanceApi()) {
			return;
		}
		window.performance.mark(paymentMetrics.addPayerFinished);

		try {
			window.performance.measure(uniqueKey(paymentMetrics.addPayerTiming), paymentMetrics.addPayerStarted, paymentMetrics.addPayerFinished);
		} catch (error) {
			window.reportUnhandledRejection(error);
		}
	}

	export function editPayerStarted() {
		if (!supportsPerformanceApi()) {
			return;
		}
		window.performance.mark(paymentMetrics.editPayerStarted);
	}

	export function editPayerFinished() {
		if (!supportsPerformanceApi()) {
			return;
		}
		window.performance.mark(paymentMetrics.editPayerFinished);

		try {
			window.performance.measure(uniqueKey(paymentMetrics.editPayerTIming), paymentMetrics.editPayerStarted, paymentMetrics.editPayerFinished);
		} catch(error) {
			window.reportUnhandledRejection(error);
		}
	}

	export function getPaymentEntryStats(): VirtualTerminalPayStats {
		if (!supportsPerformanceApi()) {
			return null;
		}
		const measures = window.performance.getEntriesByType('measure') as PerformanceMeasure[];

		const timings = measures.reduce((timings, measure) => {
			if (measure.name === paymentMetrics.paymentEntryTiming) {
				timings.PaymentEntryTiming = measure.duration;
			} else if (measure.name.indexOf(paymentMetrics.addPayerTiming) !== -1) {
				timings.AddPayerTimings
					? timings.AddPayerTimings.push(measure.duration)
					: timings.AddPayerTimings = [measure.duration];
			} else if (measure.name.indexOf(paymentMetrics.editPayerTIming) !== -1) {
				timings.EditPayerTimings
					? timings.EditPayerTimings.push(measure.duration)
					: timings.EditPayerTimings = [measure.duration];
			}
			return timings;
		}, {} as VirtualTerminalPayStats);

		const PayersAdded = timings.AddPayerTimings && timings.AddPayerTimings.length || 0;
		const PayersEdited = timings.EditPayerTimings && timings.EditPayerTimings.length || 0;

		return { ...timings, PayersAdded, PayersEdited };
	}

	function uniqueKey(key: string) {
		return key + '-' + new Date().getTime();
	}

	function supportsPerformanceApi() {
		return window.performance.mark && window.performance.measure;
	}
}
