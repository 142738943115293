import { observable, action, ObservableMap } from 'mobx';
import { SendStatementsViewModel, EmailBranding, SendSummaryViewModel, GivingStatementRunStatus } from '../../giving-statements-generated';
import { GivingStatementConfigurationViewModel, GivingStatementsViewModel } from '../../giving-statements-view-model';
import { PaymentLabel } from '../../../loggedinweb-generated';

export class SendStatementsPageViewModel {
	@observable excludeLessThanAmount: number;
	@observable selectedDeliveryTab: number;
	@observable numberOfStatements: number;
	@observable numberWithoutEmailAddresses: number;
	@observable showEmailTab: boolean;
	@observable showDownloadTab: boolean;
	@observable showTabsPanel: boolean;
	@observable downloadAllData: IZipInformation[] = [];
	@observable downloadNoEmailData: IZipInformation[] = [];
	@observable emailData?: EmailData;
	@observable supportsNoEmailDownload: boolean;
	@observable summaryMode: boolean;
	@observable summaryData: SentSummaryViewModel;
	@observable hasPublishedBefore: boolean;
	@observable status: GivingStatementRunStatus;
	@observable paymentLabel: PaymentLabel;

	statementName: string;

	constructor(info: SendStatementsViewModel, public rootvm: GivingStatementsViewModel) {
		this.numberOfStatements = info.StatementsGenerated;
		this.numberWithoutEmailAddresses = info.MembersWithNoEmails;
		this.excludeLessThanAmount = info.ExcludeStatementsLessThan;
		this.emailData = new EmailData(info, rootvm);

		this.selectedDeliveryTab = 0;
		this.showEmailTab = false;
		this.showDownloadTab = false;
		this.showTabsPanel = false;
		this.summaryMode = false;
		this.statementName = rootvm.currentConfiguration.statementName;
		this.supportsNoEmailDownload = info.SupportsNoEmailDownload;
		this.status = info.Status;
		this.summaryData = new SentSummaryViewModel(rootvm.currentConfiguration.status);
		this.hasPublishedBefore = info.Status === GivingStatementRunStatus.Issued;
		this.paymentLabel = rootvm.paymentLabel;
	}

	@action.bound
	updateExcludeLessThanAmount(amount: number) {
		this.excludeLessThanAmount = amount;
	}

	@action.bound
	updateSelectedDeliveryTab(index: number) {
		this.selectedDeliveryTab = index;
	}

	@action.bound
	updateNumberOfStatements(count: number) {
		this.numberOfStatements = count;
	}

	@action.bound
	updateNumberWithoutEmailAddresses(count: number) {
		this.numberWithoutEmailAddresses = count;
	}

	@action.bound
	updateShowDownloadTab(show: boolean) {
		this.showDownloadTab = show;
	}

	@action.bound
	updateShowEmailTab(show: boolean) {
		this.showEmailTab = show;
	}

	@action.bound
	updateShowTabsPanel(show: boolean) {
		this.showTabsPanel = show;
	}

	@action.bound
	updateDownloadAllData(data: IZipInformation[]) {
		this.downloadAllData = data;
	}

	@action.bound
	updateDownloadNoEmailData(data: IZipInformation[]) {
		this.downloadNoEmailData = data;
	}

	@action.bound
	updateStatus(status: GivingStatementRunStatus) {
		this.status = status;
		this.hasPublishedBefore = this.status === GivingStatementRunStatus.Issued;
	}

	@action.bound
	setSummaryMode() {
		this.summaryMode = true;
	}
}

export interface IOriginalEmailData {
	replyTo: string;
	heading: string;
	subject: string;
	message: string;
	videoUrl: string;
	hideTotalTaxDeductible: boolean;
}

export class EmailData {
	@observable originalEmailData: IOriginalEmailData;
	@observable replyTo: string;
	@observable heading: string;
	@observable subject: string;
	@observable emailBranding: EmailBranding;
	@observable message: string;
	@observable videoUrl: string;
	@observable contactEmail: string;
	@observable contactPhone: string;
	@observable lastUpdateTime: Date;
	@observable startDate: Date;
	@observable endDate: Date;
	@observable lastUpdatedBy: string;
	@observable emailCountToSend: number;
	@observable statementName: string;
	@observable defaultEmailTextKey: string;
	@observable hideTotalTaxDeductible: boolean;

	@observable errorMap: ObservableMap<string, string> = observable.map<string, string>();
	@observable errorMessages: string[] | null = null;

	constructor(vm: SendStatementsViewModel, mainViewModel: GivingStatementsViewModel) {
		const currentConfiguration: GivingStatementConfigurationViewModel = mainViewModel.currentConfiguration;

		this.replyTo = vm.ReplyTo;
		this.heading = vm.EmailHeading || currentConfiguration.statementName;
		this.subject = vm.EmailSubject || vm.EmailBranding.OrganizationName + ' - ' + currentConfiguration.statementName;
		this.emailBranding = vm.EmailBranding;
		this.message = vm.EmailText;
		this.videoUrl = vm.EmailVideoUrl;
		this.lastUpdateTime = vm.LastUpdated;
		this.contactEmail = currentConfiguration.emailAddress;
		this.contactPhone = currentConfiguration.phoneNumber;
		this.startDate = currentConfiguration.statementFromDate;
		this.endDate = currentConfiguration.statementToDate;
		this.emailCountToSend = vm.StatementsGenerated - vm.MembersWithNoEmails;
		this.lastUpdatedBy = ''; //Cannot currently set this.
		this.statementName = currentConfiguration.statementName;
		this.defaultEmailTextKey = vm.DefaultEmailTextKey;
		this.hideTotalTaxDeductible = vm.HideTotalTaxDeductible;

		this.resetOriginalEmailData();
	}

	@action.bound
	setValidationErrors(errors: { [key: string]: string; } | null) {
		this.errorMap.merge(errors);
		this.errorMessages = errors ? Object.getOwnPropertyNames(errors).map(name => errors[name]) : null;
	}

	@action.bound
	resetOriginalEmailData() {
		this.originalEmailData = {
			replyTo: this.replyTo,
			heading: this.heading,
			subject: this.subject,
			message: this.message,
			videoUrl: this.videoUrl,
			hideTotalTaxDeductible: this.hideTotalTaxDeductible,
		};
	}

	@action.bound
	updateReplyTo(replyTo: string) {
		this.replyTo = replyTo;
	}

	@action.bound
	updateHeading(heading: string) {
		this.heading = heading;
	}

	@action.bound
	updateSubject(subject: string) {
		this.subject = subject;
	}

	@action.bound
	updateEmailBranding(emailBranding: EmailBranding) {
		this.emailBranding = emailBranding;
	}

	@action.bound
	updateMessage(message: string) {
		this.message = message;
	}

	@action.bound
	updateVideoUrl(videoUrl: string) {
		this.videoUrl = videoUrl;
	}

	@action.bound
	updateContactEmail(email: string) {
		this.contactEmail = email;
	}

	@action.bound
	updateContactPhone(contactPhone: string) {
		this.contactPhone = contactPhone;
	}

	@action.bound
	updateLastUpdateTime(lastUpdateTime: Date) {
		this.lastUpdateTime = lastUpdateTime;
	}

	@action.bound
	updateStartDate(startDate: Date) {
		this.startDate = startDate;
	}

	@action.bound
	updateEndDate(endDate: Date) {
		this.endDate = endDate;
	}

	@action.bound
	updateEmailCountToSend(emailCountToSend: number) {
		this.emailCountToSend = emailCountToSend;
	}

	@action.bound
	updateLastUpdatedBy(lastUpdatedBy: string) {
		this.lastUpdatedBy = lastUpdatedBy;
	}

	@action.bound
	updateHideTotalTaxDeductible(hideTotalTaxDeductible: boolean) {
		this.hideTotalTaxDeductible = hideTotalTaxDeductible;
	}
}

export class SentSummaryViewModel {
	@observable statementsGenerated: number;
	@observable generatedAt: Date;
	@observable statementsEmailed: number;
	@observable statementsDownloaded: number;
	@observable givingUnitType: string;
	@observable membersWithoutEmail: number;
	@observable sentAt: Date;
	@observable status: GivingStatementRunStatus;

	constructor(status: GivingStatementRunStatus) {
		this.status = status;
	}

	@action.bound
	updateSentSummaryData(vm: SendSummaryViewModel, status: GivingStatementRunStatus) {
		this.statementsGenerated = vm.StatementsGenerated;
		this.generatedAt = vm.GeneratedDate;
		this.statementsEmailed = vm.StatementsEmailed;
		this.statementsDownloaded = vm.StatementsDownloaded;
		this.sentAt = vm.StatementsSentOn;
		this.givingUnitType = vm.GivingUnitType;
		this.membersWithoutEmail = vm.MembersWithoutEmail;
		this.status = status;
	}

	@action.bound
	updateSentSummaryStatus(status: GivingStatementRunStatus) {
		this.status = status;
	}
}

export interface IZipInformation {
	fromName: string;
	toName: string;
	url: string;
	doesNotSupportFromAndToName: boolean;
}
