import { runInAction } from 'mobx';
import { Task } from 'redux-saga';
import { take, fork, cancelled } from 'redux-saga/effects';
import { Channel } from '../../../Shared/utils/user-action-channel';
import { callInAction, ActionHandlers, AsyncActionHandler, ActionConcurrencyInstruction, Action } from '../../../Shared/utils/saga-utils';
import {
	getSettingsDataService,
	SettingsActionRequest,
	SettingsActionResponse,
	SettingsApiConfigActions,
} from '../settings-data-service';
import { reportError } from '../../utils/error-utils';
import { EventTimeApiConfig } from '../settings-generated';
import { runSaga } from '../../utils/saga-utils';

export interface ISettingsSagaContext {
	readonly userActionChannel: Channel<any>;
	readonly mainViewModel: any;
	readonly dataService: SettingsSagaDataService;
	executingBlockingAction?: boolean;
}

function getUserActionName<TActionType extends Action>(userAction: TActionType): string | undefined {
	return (userAction as any).constructor.name;
}

export function runSettingsSaga<TActionType extends Action>(
	sagaContext: ISettingsSagaContext,
	actionHandlers: ActionHandlers<ISettingsSagaContext, TActionType>) {
	runSaga({}, settingsSaga, sagaContext, actionHandlers);
}

function* settingsSaga<TActionType extends Action>(context: ISettingsSagaContext,
	actionHandlers: ActionHandlers<ISettingsSagaContext, TActionType>): IterableIterator<any> {

	const { userActionChannel } = context;

	let currentAsyncHandler: AsyncActionHandler<ISettingsSagaContext> | undefined;
	let currentAsyncTask: Task | undefined;

	while (true) {
		const userAction: TActionType = yield take(userActionChannel);
		try {
			const incomingActionHandler = actionHandlers.get(userAction);
			console.log('userAction', getUserActionName(userAction));

			if (currentAsyncHandler && currentAsyncTask && currentAsyncTask.isRunning()) {
				const instruction = actionHandlers.getInstructionForIncomingAction(currentAsyncHandler, incomingActionHandler);

				switch (instruction) {
					case ActionConcurrencyInstruction.AllowIncoming:
						console.log('Allowing incoming action', { running: currentAsyncHandler.actionType.name, incoming: incomingActionHandler.actionType.name });
						break;
					case ActionConcurrencyInstruction.CancelRunning:
						console.log('Cancelling running action', { running: currentAsyncHandler.actionType.name, incoming: incomingActionHandler.actionType.name });
						if (currentAsyncHandler.blocking) {
							context.executingBlockingAction = false;
						}
						currentAsyncTask.cancel();
						currentAsyncHandler = undefined;
						break;
					case ActionConcurrencyInstruction.BlockIncoming:
						console.log('Blocking incoming action', { running: currentAsyncHandler.actionType.name, incoming: incomingActionHandler.actionType.name });
						continue;
					default:
						throw new Error(`ActionConcurrencyInstruction[${instruction}] is not supported`);
				}
			}

			console.log('Starting action', { incoming: incomingActionHandler.actionType.name });
			if (incomingActionHandler.async) {
				currentAsyncHandler = incomingActionHandler;

				if (currentAsyncHandler.blocking) {
					context.executingBlockingAction = true;
				}

				currentAsyncTask = yield fork(function* () {
					try {
						yield callInAction(incomingActionHandler.handler, context, userAction);
					} catch (ex) {
						reportError(ex, {
							sagaRunningFork: true,
							sagaUserAction: getUserActionName(userAction),
						});
					} finally {
						if (!(yield cancelled())) {
							context.executingBlockingAction = false;
						}
					}
				});
			} else {
				runInAction(() => incomingActionHandler.handler(context, userAction));
			}
		} catch (ex) {
			reportError(ex, {
				sagaUserAction: getUserActionName(userAction),
			});
		}
	}
}

export type SettingsSagaDataService = {
	[x in keyof SettingsApiConfigActions]: (request: SettingsActionRequest<x>) => Iterator<SettingsActionResponse<x>>
};

export function sagaDataService(): SettingsSagaDataService {
	return (Object.keys(EventTimeApiConfig.actions) as Array<keyof SettingsApiConfigActions>).reduce((acc: any, actionKey: keyof SettingsApiConfigActions) => {
		acc[actionKey] = function* (request: any) {
			const action: (...args: any[]) => PromiseLike<any> = getSettingsDataService()[actionKey];
			return yield action(request);
		};

		return acc;
	}, {});
}
