
export class TxtSentBanner {
	private banner: HTMLElement;
	private bannerDismissButton: HTMLElement;
	private readonly bannerTimeout = 10000;

	constructor(showOnLoad: boolean) {
		this.banner = document.querySelector('[data-pp-txt-sent-banner]') as HTMLElement;
		this.bannerDismissButton = document.querySelector('[data-pp-txt-sent-banner-dismiss]') as HTMLElement;

		this.bannerDismissButton.addEventListener('click', (e) => this.bannerDismissButtonListenser(e));

		if (showOnLoad) {
			this.showBanner();
		}
	}

	showBanner = () => {
		this.banner.classList.add('show-txt-sent-banner');
		setTimeout(this.hideBanner, this.bannerTimeout);
	}

	hideBanner = () => {
		this.banner.classList.remove('show-txt-sent-banner');
	}

	bannerDismissButtonListenser = (e: Event): void => {
		this.hideBanner();
	}
}
