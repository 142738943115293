import * as React from 'react';
import { observer } from 'mobx-react';
import { classNames } from '../../../../Shared/utils/classnames';
import { Button } from '../../../components/button';
import { SvgWrapper } from '../../../components/svg-wrapper';
import * as styles from './editor-sliding-panel.less';

interface IEditorSlidingPanelProps {
	opened: boolean;
	togglePanel: () => void;
	acceptanceTestTargetId: string;
}

@observer
export class EditorSlidingPanel extends React.Component<IEditorSlidingPanelProps> {
	render() {
		const { children, opened, togglePanel, acceptanceTestTargetId } = this.props;

		return (
			<div className={classNames(styles.wrapper, opened && styles.opened)}>
				<Button tabIndex={0} className={styles.button} onClick={togglePanel} acceptanceTestTargetId={acceptanceTestTargetId}>
					<SvgWrapper svg="icon-chevron-right" className={styles.icon} />
					<span className={styles.text}>Edit</span>
				</Button>
				{children}
			</div>
		);
	}
}
