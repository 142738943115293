import * as React from 'react';
import { observer } from 'mobx-react';
import { Formatter } from '../../../helpers/formatter';
import * as styles from './styles.less';
import { IDownloadTabContentProps } from '../download-tab-content';
import { GivingStatementRunStatus } from '../../giving-statements-generated';
import * as sharedStyles from '../shared-styles/styles.less';
import { Tooltip, TooltipContent } from '../../../components/tooltip/tooltip';
import { SvgWrapper } from '../../../components/svg-wrapper';


@observer
export default class DownloadTabContentSelfAccess extends React.Component<IDownloadTabContentProps, { visible: boolean }> {

	constructor(props) {
		super(props);
		this.state = { visible: false };
	}

	render() {
		const { supportsNoEmailDownload, onClickPublish, status, paymentLabel } = this.props;
		return (
			<div className={sharedStyles.panelRowGroup}>
				<div className={`row ${sharedStyles.panelRow} ${styles.panelRow}`}>
					<div className={`${sharedStyles.panelRowLabel}`}>Download and publish statements</div>
					<div>
						Download to check before publishing, or to send your statements by postal mail.
					</div>
					<div>
						Publish to allow your {paymentLabel.PayerPluralLowerCase} to access their statements in their Pushpay account.
					</div>
					<div className={styles.splitPanel}>
						<div className={styles.subPanel}>
							<strong className={styles.header}>Step 1</strong>
							<div className={styles.downloadButtons}>
									{supportsNoEmailDownload && this.renderNoEmailSection()}
									{this.renderDownloadAllSection()}
							</div>
						</div>
						<div className={styles.subPanel}>
							<strong className={styles.header}>Step 2</strong>
							<span className={styles.copy}>
								Publish all statements
								<Tooltip label="more information"
									visible={this.state.visible}
									onVisibleChange={this.handleVisibleChange} >
									<SvgWrapper svg="icon-info" className={`icon tooltip-icon ${styles.tooltipIcon}`} />
									<TooltipContent>
										<div>{paymentLabel.PayerPluralSentenceCase} can access statements in their account once you publish.</div>
									</TooltipContent>
								</Tooltip>
							</span>
							<div className={styles.buttonGroup}>
								<button className="btn btn-default" onClick={onClickPublish}
									title={status === GivingStatementRunStatus.Issued?'All statements already published':null}
									disabled={status === GivingStatementRunStatus.Issued || status === GivingStatementRunStatus.Issuing}>
									Publish
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	private handleVisibleChange = (visible: boolean) => {
		this.setState({ visible: visible });
	}

	private renderNoEmailSection = () => {
		const { numberWithNoEmail, onClickDownloadNoEmail, givingUnitType } = this.props;
		const formattedNumber = Formatter.formatNumberForDisplay(numberWithNoEmail, 0);
		const givingUnit = givingUnitType.slice(0,-1);
		const givingUnits = givingUnitType;
		const copy = (numberWithNoEmail === 1)
			? <div><strong>{formattedNumber}</strong> {givingUnit} without an email</div>
			: <div><strong>{formattedNumber}</strong> {givingUnits} without emails</div>;

		return (
				<div className={styles.downloadSectionLeft}>
					<div className={styles.copy}>
						{copy}
					</div>
					<div className={styles.buttonGroup}>
						<button className="btn btn-ghost" disabled={!numberWithNoEmail} onClick={onClickDownloadNoEmail}>
							Download
						</button>
					</div>
			</div>
		);
	}

	private renderDownloadAllSection = () => {
		const { numberOfStatements, onClickDownloadAll } = this.props;
		const formattedNumber = Formatter.formatNumberForDisplay(numberOfStatements, 0);
		const copy = (numberOfStatements === 1)
			? <div><strong>1</strong> Statement</div>
			: <div><strong>{formattedNumber}</strong> Statements</div>;
		return (
			<div className={styles.downloadSectionRight}>
				<div className={styles.copy}>
					{copy}
				</div>
				<div className={styles.buttonGroup}>
					<button className={`btn btn-ghost`} onClick={onClickDownloadAll}>
						Download
					</button>
				</div>
			</div>
		);
	}
}
