import {observable, computed, action} from 'mobx';

export enum SortDirection {
	No, Ascending, Descending
}

export class SortField<TObject> {

	constructor(public parent: Sorter<TObject>, public serverFieldName: string, public valueGetter: (valueHolder: TObject) => string) {
	}

	@computed get direction() {
		return this === this.parent.currentSorter ? this.parent.currentDirection : SortDirection.No;
	}

	@action toggle() {
		let p = this.parent;
		if (this === p.currentSorter) {
			p.currentDirection = p.currentDirection === SortDirection.Ascending ? SortDirection.Descending : SortDirection.Ascending;
		} else {
			p.currentDirection = SortDirection.Ascending;
			p.currentSorter = this;
		}
		p.sortChanged();
	}
}

export class Sorter<TObject> {
	@observable
	currentSorter: SortField<TObject>;

	@observable
	currentDirection: SortDirection;

	constructor(public sortChanged: () => void) {

	}

	@computed get isDescending() {
		return this.currentDirection === SortDirection.Descending;
	}

	create(serverFieldName: string, valueGetter: (valueHolder: TObject) => any) {
		return new SortField<TObject>(this, serverFieldName, valueGetter);
	}

	createForString(serverFieldName: string, valueGetter: (valueHolder: TObject) => string) {
		return this.create(serverFieldName, x => (valueGetter(x) || '').toLowerCase());
	}

	createForNumber(serverFieldName: string, valueGetter: (valueHolder: TObject) => number) {
		return this.create(serverFieldName, x => (valueGetter(x) || 0));
	}

	sortLocally(localData: TObject[], thenBy?: (v: TObject) => any): TObject[] {
		let s = this.currentSorter;
		let ordered: IOrderedEnumerable<TObject>;

		if (this.currentDirection === SortDirection.Ascending) {
			ordered = localData.orderBy(s.valueGetter);
			if (thenBy) {
				ordered = ordered.thenBy(thenBy);
			}
		} else {
			ordered = localData.orderByDescending(s.valueGetter);
			if (thenBy) {
				ordered = ordered.thenByDescending(thenBy);
			}
		}

		return ordered.toArray();
	}
}
