import * as React from 'react';
import { observer } from 'mobx-react';
import { CampaignsCallToAction } from './campaigns-call-to-action';
import { Link } from '../../../components/link/link';

export interface WelcomeToCampaignsProps {
	createCampaignUrl: string;
}

export const WelcomeToCampaigns = observer(({ createCampaignUrl }: WelcomeToCampaignsProps) => (
	<CampaignsCallToAction
		getHeader={() => 'Welcome to Campaigns'}
		getIntro={() => 'Engage your community by creating a Campaign to raise donations for a specific purpose or cause.'}
		getLinks={() =>
			<Link href={createCampaignUrl}
				title="Create a new Campaign"
				className="btn btn-ghost"
				acceptanceTargetId="Create a new campaign">Create a new Campaign</Link>}
	/>
));
