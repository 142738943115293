import * as React from 'react';
import { delay } from 'redux-saga';
import { call, takeLatest, all } from 'redux-saga/effects';
import { runInAction } from 'mobx';
import { BrandingPackageState } from '../branding-settings-main-view-model';
import { BrandingSettingsDraftState, BrandingSettingsFormViewModel } from '../editor-form/branding-settings-form-view-model';

import { BrandingSettingsUserAction } from './actions';
import { IBrandingSettingsSagaContext } from '../branding-settings-saga';
import { UploadImageViewModel, ImageKind, ImageStatus } from '../branding-settings-generated';
import { savePackageHandler } from './save-package-handler';
import { getImageUploadChannel, getImageState, setImageState } from './utils';
import { stateNoSelection } from '../../components/form-controls/form-control-image-input/form-control-image-input';
import { isHttpTimeoutError } from './errors/http-timeout-error';
import { UnexpectedImageStateError } from './errors/unexpected-image-state-error';
import { InvalidImageError, isInvalidImageError } from './errors/invalid-image-error';
import { ModalDialogCommander } from '../../components/modal-dialog-commander';
import { ImageUploadErrorModal } from '../components/image-upload-error-modal/image-upload-error-modal';

export interface IImageUploaderRemoveAction {
	type: 'remove';
}

export interface IImageUploaderUploadAction {
	type: 'upload';
	file: File;
}

export type ImageUploaderAction =
	IImageUploaderRemoveAction |
	IImageUploaderUploadAction;

export const pollingTimeouts = [1000, 2000, 3000, 5000];

function getPollingTimeoutGenerator(): () => number {
	let attempt = 0;
	return () => pollingTimeouts[Math.min(pollingTimeouts.length - 1, attempt++)];
}

export function* imageSelectedHandler(context: IBrandingSettingsSagaContext, action: BrandingSettingsUserAction.FormImageFieldChange): IterableIterator<any> {
	const { imageUploadChannels } = context;
	const { file, imageKind } = action;

	const channel = getImageUploadChannel(imageUploadChannels, imageKind);

	channel.put(file ? { type: 'upload', file } : { type: 'remove' });
}

export function* startImageUploaderLoops(context: IBrandingSettingsSagaContext) {
	const imageKinds = context.mainViewModel.model.SupportsBackdropImages ? [ImageKind.HeroImage, ImageKind.BackdropImage, ImageKind.EnhancedLogo, ImageKind.Favicon] : [ImageKind.HeroImage, ImageKind.EnhancedLogo, ImageKind.Favicon];
	yield all(imageKinds.map(imageKind => {
		const channel = getImageUploadChannel(context.imageUploadChannels, imageKind);
		return takeLatest(channel, startImageUploader, context, imageKind);
	}));
}

/**
 * This saga loop is responsible for uploading images and triggering a save request on successful upload
 * It waits for actions on one of the image upload channels like @type {IImageUploaderUploadAction} or @type {IImageUploaderCancelAction}
 * When an upload action is put on the channel, the saga updates the status of the image on the view model
 * gets ImageUrlToUpload to s3 from the branding service, uploads the image to s3, polls GetImageDetails
 * until the image is Processed and sets the image state to success or error depending on the success of those operations.
 * When a cancel action is put on the channel, the saga makes sure to cancel a current upload (if there's one)
 * and update the image state on the viewModel to no-selection.
 * While image is uploading, it create a temporary thumbnail using URL.createObjectURL to show the user the image
 * being uploaded.
 * @param {IBrandingSettingsSagaContext} context
 * @param {ImageKind} imageKind
 */
export function* startImageUploader(context: IBrandingSettingsSagaContext, imageKind: ImageKind, action: ImageUploaderAction) {
	const { dataService, mainViewModel } = context;
	const { formViewModel, selectedListingId } = mainViewModel;
	const setTargetImageState = setImageState(formViewModel, imageKind);

	if (action.type === 'remove') {
		yield call(removeImage, context, setTargetImageState, imageKind);
		return;
	}

	const { file } = action;

	const listingId = parseInt(selectedListingId, 10);
	if (!file) {
		return;
	}
	const url = URL.createObjectURL(file);

	try {
		if (!(yield call(isImageValid, formViewModel, imageKind))) {
			throw new InvalidImageError(formViewModel.getImageError(imageKind), imageKind);
		}

		setTargetImageState({ uploadState: 'uploading' });
		const createUrlResponse: UploadImageViewModel = yield call(dataService.createAssetUploadUrl, {
			merchantId: listingId,
			imageKind: imageKind
		});

		yield call(dataService.uploadImage, { file, url: createUrlResponse.UploadUrl });

		setTargetImageState({ uploadState: 'processing' });
		const imageDetails = yield call(fetchImageDetails, context, listingId, createUrlResponse.ImageKey);

		runInAction(() => {
			formViewModel.draftState = BrandingSettingsDraftState.Changed;
			mainViewModel.packageState = BrandingPackageState.ChangesAwaitingPublish;

			setTargetImageState({
				uploadState: 'success',
				previewUrl: imageDetails.PublicUrl,
				value: imageDetails.ImageKey,
			});
		});

		yield call(savePackageHandler, context);
	} catch (error) {
		yield call(handleUploadImageError, context, setTargetImageState, error, imageKind);
	} finally {
		// always revoke the url after using it
		URL.revokeObjectURL(url);
	}
}

export function* isImageValid(formViewModel: BrandingSettingsFormViewModel, imageKind: ImageKind) {

	// delay if there's a file wanting to be uploaded, but not on a clear image action.
	yield call(delay, 600);
	// check if there is a client side error with the image.
	return formViewModel.isImageValid(imageKind);
}

export function* fetchImageDetails(context: IBrandingSettingsSagaContext, merchantId: number, imageKey: string) {
	const pollingTimeoutGenerator = getPollingTimeoutGenerator();

	while (true) {
		const imageDetails = yield call(context.dataService.getImageDetails, { merchantId, imageKey });
		const { ImageStatus: imageStatus } = imageDetails;

		if (imageStatus === ImageStatus.Processed) {
			return imageDetails;
		}

		if (imageStatus === ImageStatus.Processing || imageStatus === ImageStatus.NotFound) {
			yield call(delay, pollingTimeoutGenerator());
		} else if (imageStatus === ImageStatus.Invalid) {
			throw new InvalidImageError(undefined, imageDetails);
		} else {
			throw new UnexpectedImageStateError(
				`Unexpected image status: ${ImageStatus[imageStatus]}. Expected either Processed, Processing or Invalid`, {
					imageStatus: imageStatus,
					imageStatusText: ImageStatus[imageStatus],
					...imageDetails
				});
		}
	}
}

export function* removeImage(context: IBrandingSettingsSagaContext, setTargetImageState: (state) => void, imageKind: ImageKind) {
	const { mainViewModel } = context;
	const { formViewModel } = mainViewModel;
	const currentState = getImageState(formViewModel, imageKind);
	const isRemoveUploadedImage = currentState.uploadState === 'success';

	runInAction(() => {
		setTargetImageState(stateNoSelection);

		if (isRemoveUploadedImage) {
			mainViewModel.packageState = BrandingPackageState.ChangesAwaitingPublish;
			formViewModel.draftState = BrandingSettingsDraftState.Changed;
		}
	});

	if (isRemoveUploadedImage) {
		yield call(savePackageHandler, context);
	}
}

export function handleUploadImageError(context: IBrandingSettingsSagaContext, setTargetImageState: (state) => void, error: any, imageKind: ImageKind) {
	if (isHttpTimeoutError(error)) {
		setTargetImageState({
			uploadState: 'error',
			uploadError: error.message,
		});
		return;
	}
	if (!isInvalidImageError(error)) {
		context.reportError(error);
		setTargetImageState({
			uploadState: 'error',
			uploadError: 'Something went wrong. Please try again.',
		});
		return;
	}
	const dialogBox = React.createElement(ImageUploadErrorModal, { errorMessage: error.message, imageKind: imageKind });
	ModalDialogCommander.showReactDialog(dialogBox);
}

