import * as React from 'react';
import { delay, Task } from 'redux-saga';
import { call, spawn, cancel, fork } from 'redux-saga/effects';
import { callInAction } from '../../../../Shared/utils/saga-utils';
import { BatchEntrySagaContext } from '../../pages/batch-entry/batch-entry-saga';
import { GetBatchEntryPaymentsType, BatchEntryPaymentsRequestModel, BatchEntryPaymentsViewModel, BatchStatus } from '../../virtual-terminal-generated';
import { ModalDialogCommander } from '../../../components/modal-dialog-commander';
import { DeletePaymentConfirmationDialog, DeletePaymentConfirmationDialogOld } from '../../components/delete-payment-confirmation-dialog';
import { BatchEntryUserAction } from '../../pages/batch-entry/batch-entry-user-actions';
import { BatchEntryPaymentsStore } from './batch-entry-payments-store';

const batchEntryPaymentsPollingDelay = 60000;
const batchEntryPaymentDetailsAcceptableLoadingTime = 300;

let pollBatchPaymentsTask: Task;

export function* fetchAllBatchPaymentsAndStartPolling(context: BatchEntrySagaContext) {
	yield spawn(function* () {
		yield fetchAllBatchPayments(context);

		const batchStatus = context.mainViewModel.batchDetailsViewModel.batch.Status;
		if (batchStatus === BatchStatus.Open) {
			yield startPollingForBatchPayments(context);
		}
	});
}

export function* fetchAllBatchPayments(context: BatchEntrySagaContext) {
	yield callInAction(fetchBatchPayments, context, GetBatchEntryPaymentsType.Load);
}

function* startPollingForBatchPayments(context: BatchEntrySagaContext) {
	yield cancelPollingForBatchPayments();
	pollBatchPaymentsTask = yield spawn(pollForBatchPayments, context);
}

function* fetchBatchPayments(context: BatchEntrySagaContext, getBatchEntryPaymentsType: GetBatchEntryPaymentsType) {
	const { mainViewModel: { batchDetailsViewModel: { batch }, batchPaymentsStore }, dataService } = context;
	const { ListingId, BatchId } = batch;
	const { paymentsModel } = batchPaymentsStore;

	const paymentsRequestModel: BatchEntryPaymentsRequestModel = {
		BatchId,
		GetBatchEntryPaymentsType: getBatchEntryPaymentsType,
		LastFetchTime: paymentsModel.FetchTime,
		ExpandedItemEncodedToken: batchPaymentsStore.expandedItemEncodedToken,
	};

	const paymentsResponseModel: BatchEntryPaymentsViewModel = yield dataService.batchEntryPaymentsAsync({ merchantId: ListingId, model: paymentsRequestModel });

	if (polledForPaymentsAndFoundNoUpdates(paymentsResponseModel)) {
		return;
	}

	batchPaymentsStore.setModel(paymentsResponseModel);
	return;
}

export function* handleLoadBatchPayment(context: BatchEntrySagaContext, encodedToken: string) {
	const { dataService, mainViewModel: { listingStore: { selectedListing }, batchPaymentsStore } } = context;

	const displayLoadingSpinnerTask: Task = yield fork(setAcceptableLoadingTimeExceededAfterDelay, batchPaymentsStore);

	try {
		const payment = yield dataService.viewPayment({ merchantId: selectedListing.ListingId, encodedToken });
		batchPaymentsStore.setExpandedPayment(payment);
	} finally {
		displayLoadingSpinnerTask.cancel();
		batchPaymentsStore.setAcceptableLoadingTimeExceeded(false);
	}
}

function* setAcceptableLoadingTimeExceededAfterDelay(batchPaymentsStore: BatchEntryPaymentsStore) {
	yield call(delay, batchEntryPaymentDetailsAcceptableLoadingTime);
	batchPaymentsStore.setAcceptableLoadingTimeExceeded(true);
}

export function handleLaunchDeleteBatchPaymentDialog(context: BatchEntrySagaContext, encodedToken: string) {
	const confirmDeleteBatchPaymentDialog = CreateDeletionDialog(context, encodedToken);
	ModalDialogCommander.showReactDialog(confirmDeleteBatchPaymentDialog);
}

function CreateDeletionDialog(context: BatchEntrySagaContext, encodedToken: string) : React.ReactElement<any> {
	const {
		userActionChannel,
		mainViewModel: {
			modalDialogProcessingViewModel,
			listingStore: { paymentLabel }
		}
	} = context;

	return React.createElement(DeletePaymentConfirmationDialog, {
		paymentLabel,
		initGiftDeleteRequest: (details) => userActionChannel.put(new BatchEntryUserAction.DeleteBatchPayment(details.EncodedToken)),
		giftDetails: {EncodedToken: encodedToken, NonCashGiftId: null, IsNonCash: false, IsRecurring: false},
		modalDialogProcessingViewModel,
	});
}

export function* handleDeleteBatchPayment(context: BatchEntrySagaContext, encodedToken: string) {
	const {
		dataService,
		mainViewModel: {
			listingStore: {
				selectedListing
			},
			batchPaymentsStore: {
				paymentsModel,
				setModel,
			},
			modalDialogProcessingViewModel: {
				setIsProcessing,
			}
		}
	} = context;

	setIsProcessing(true);

	try {
		const { EncodedToken } = yield dataService.deletePayment({ merchantId: selectedListing.ListingId, encodedToken });

		const payments = [ ...paymentsModel.BatchEntryPayments ].filter(payment => payment.EncodedToken !== EncodedToken);
		setModel({ ...paymentsModel, BatchEntryPayments: payments });

		ModalDialogCommander.forceCloseCurrent();
	} finally {
		setIsProcessing(false);
	}
}

function* pollForBatchPayments(context: BatchEntrySagaContext) {
	const { mainViewModel: { batchDetailsViewModel } } = context;
	const { batchExists } = batchDetailsViewModel;

	if (!batchExists) {
		throw new Error('Tried to poll for batch before batch was created');
	}

	while(true) {
		yield call(delay, batchEntryPaymentsPollingDelay);
		yield callInAction(fetchBatchPayments, context, GetBatchEntryPaymentsType.Poll);
	}
}

export function* cancelPollingForBatchPayments() {
	if (pollBatchPaymentsTask && pollBatchPaymentsTask.isRunning()) {
		yield cancel(pollBatchPaymentsTask);
	}
}

function polledForPaymentsAndFoundNoUpdates(paymentsResponseModel: BatchEntryPaymentsViewModel) {
	const { GetBatchEntryPaymentsType: getBatchPaymentsType, HasBeenUpdated } = paymentsResponseModel;
	return getBatchPaymentsType === GetBatchEntryPaymentsType.Poll && !HasBeenUpdated;
}
