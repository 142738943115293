import * as React from 'react';
import {observable, action, computed} from 'mobx';
import {observer} from 'mobx-react';
import {ResponseTypes} from '../funds/funds-generated';
import InsightsEnrollmentRecipients = ResponseTypes.InsightsEnrollmentRecipients;
import InsightsEnrollmentViewModel = ResponseTypes.InsightsEnrollmentViewModel;
import InsightsEnrollmentRecipientState = ResponseTypes.InsightsEnrollmentRecipientState;
import {SvgWrapper} from '../components/svg-wrapper';
import {KeyCodes} from '../../Shared/helpers/keycodes';
import {ValidationHelper} from '../helpers/validationhelper';

export class EmailRecipientListStore {


	@observable emailList: InsightsEnrollmentViewModel[];
	@observable newEmailAddress: string;
	@observable isValidAddress: boolean;

	constructor(data: InsightsEnrollmentRecipients) {
		this.newEmailAddress = '';
		this.emailList = data.Enrollments;
		this.isValidAddress = true;
	}

	@action
	addItem = () => {
		if(this.newEmailAddress !== null) {
			this.newEmailAddress = this.newEmailAddress.trim();
		} else {
			this.newEmailAddress = '';
		}
		if(this.newEmailAddress !== '') {
			this.isValidAddress = ValidationHelper.validateEmailAddress(this.newEmailAddress) && !ValidationHelper.looksLikeCreditCard(this.newEmailAddress);
			if(this.isValidAddress) {
				this.emailList.push({
					EmailAddress: this.newEmailAddress,
					EnrollmentState: InsightsEnrollmentRecipientState.New
				});
				this.newEmailAddress = '';
			}
		}
	}

	@action
	updateNewEmailAddress = (emailAddress: string) => {
		this.newEmailAddress = emailAddress;
	}

	@action
	removeItem = (item: InsightsEnrollmentViewModel) => {
		if(item.EnrollmentState === InsightsEnrollmentRecipientState.Verified) {
			item.EnrollmentState = InsightsEnrollmentRecipientState.Deleted;
		} else {
			this.emailList.splice(this.emailList.indexOf(item), 1);
		}
	}

	@action
	editLastItem = () => {
		if(this.newEmailAddress === '') {
			var lastItem = this.emailList.pop();
			this.newEmailAddress = lastItem.EmailAddress;
		}
	}

	@computed
	get newEmailAddressForDisplay() {
		return this.newEmailAddress === '' ? '&nbsp;' : this.newEmailAddress;
	}

	@computed
	get currentEmailAddresses() {
		return this.emailList.filter(item => {
			return item.EnrollmentState !== InsightsEnrollmentRecipientState.Deleted;
		});
	}

	@computed
	get deletedEmailAddresses() {
		return this.emailList.filter(item => {
			return item.EnrollmentState === InsightsEnrollmentRecipientState.Deleted;
		});
	}
}


@observer
export class EmailRecipientList extends React.Component<{ emailRecipientListStore: EmailRecipientListStore}, {}> {
	private store: EmailRecipientListStore = this.props.emailRecipientListStore;
	private input: HTMLInputElement;

	render() {
		return (
			<div className="email-recipient-list">
				<div className="pill-box" onClick={(ev) => this.handleBoxClick()}>
					<ul>
						{this.store.currentEmailAddresses.map(email => this.renderItem(email))}
						{this.renderInput()}
					</ul>
				</div>
				{this.renderDeletedEmails(this.store.deletedEmailAddresses)}
				<div className="legend">
					<span className="item">
						<span className="status-icon verified-icon"><SvgWrapper className="svg" svg="ind-tick" /></span>
						Subscribed
					</span>
					<span className="item">
						<span className="status-icon unverified-icon"><SvgWrapper className="svg" svg="ind-question"/></span>
						Unverified
					</span>
					<span className="item">
						<span className="status-icon invalid-icon"><SvgWrapper className="svg" svg="invalid" /></span>
						Invalid
					</span>
				</div>
			</div>
		);
	}

	renderItem(item: InsightsEnrollmentViewModel) {
		return (
			<li className={`pill ${InsightsEnrollmentRecipientState[item.EnrollmentState].toLowerCase()}`}>
				{this.renderStatusIcon(item)}
				{item.EmailAddress }
				<a href="#" className="close-btn" onClick={(ev) => this.removeItem(ev, item) }>
					<SvgWrapper className="svg" svg="close-cross" />
				</a>
				<input type="hidden" name="EmailAddresses" value={item.EmailAddress}/>
			</li>
		);
	}

	renderInput = () => {
		return (
			<li className={`email-input${this.store.isValidAddress ? '' : ' invalid'}`}>
				{this.store.newEmailAddressForDisplay}
				<input type="text" value={this.store.newEmailAddress}
					ref={(ref) => this.input = ref}
					onKeyDown={ (ev) => this.handleKeyEvent(ev)}
					onChange={(ev) => this.handleInputChange(ev)}
					onBlur={this.handleInputBlur}
					className={this.store.isValidAddress ? '' : 'invalid'} />
			</li>

		);
	}

	renderStatusIcon(item) {
		let iconHtml;
		switch(item.EnrollmentState) {
			case InsightsEnrollmentRecipientState.Verified:
				iconHtml = <span className="status-icon verified-icon"><SvgWrapper className="svg" svg="ind-tick" /></span>;
				break;
			case InsightsEnrollmentRecipientState.Unverified:
				iconHtml = <span className="status-icon unverified-icon"><SvgWrapper className="svg" svg="ind-question"></SvgWrapper></span>;
				break;
			case InsightsEnrollmentRecipientState.Invalid:
				iconHtml = <span className="status-icon invalid-icon"><SvgWrapper className="svg" svg="invalid" /></span>;
				break;
			case InsightsEnrollmentRecipientState.New:
				iconHtml = null;
				break;
			default:
				iconHtml = null;
				break;
		}
		return iconHtml;
	}

	renderDeletedEmails = (emails: InsightsEnrollmentViewModel[]) => {
		if(emails.length > 0) {
			return (
				<div className="deleted-emails">
					{ emails.map(email =>  this.renderDeletedEmail(email)) }
				</div>
			);
		} else {
			return null;
		}
	}

	renderDeletedEmail = (email: InsightsEnrollmentViewModel) => {
		return (
			<div className="deleted">
				<strong>{email.EmailAddress}</strong> has been deleted.&nbsp;
				<a href="#" onClick={(ev) => this.undeleteEmail(ev, email)}>Undo</a>
			</div>
		);
	}

	removeItem = (ev: React.MouseEvent<HTMLAnchorElement>, itemToRemove: InsightsEnrollmentViewModel) => {
		ev.preventDefault();
		ev.stopPropagation();
		this.store.removeItem(itemToRemove);
	}

	handleKeyEvent(ev: React.KeyboardEvent<HTMLInputElement>) {
		switch(ev.keyCode) {
			case KeyCodes.Space:
			case KeyCodes.Enter:
				ev.preventDefault();
				ev.stopPropagation();
				this.store.addItem();
				break;
			case KeyCodes.Backspace:
			case KeyCodes.Delete:
				ev.stopPropagation();
				this.store.editLastItem();
				break;
			default:
				// reset isValidAddress
				this.store.isValidAddress = true;
				break;
		}
	}

	handleInputChange(ev: React.FormEvent<HTMLInputElement>) {
		this.store.updateNewEmailAddress((ev.target as HTMLInputElement).value);
	}

	handleInputBlur = () => {
		this.store.addItem();
	}

	handleBoxClick = () => {
		this.input.focus();
	}

	undeleteEmail = (event: React.MouseEvent<HTMLAnchorElement>, email: InsightsEnrollmentViewModel) => {
		event.preventDefault();
		event.stopPropagation();
		email.EnrollmentState = InsightsEnrollmentRecipientState.Verified;
	}
}
