import * as React from 'react';
import { computed } from 'mobx';
import { observer, Provider, inject } from 'mobx-react';
import { CampaignStatus, ScheduledCampaignCardViewModel } from '../../campaigns-generated';
import { Formatter } from '../../../helpers/formatter';
import { Fragment } from '../../../../Shared/components/fragment';
import { createCampaignCardMachineContext } from './state-machine/campaign-card-machine-context';
import { CampaignCardActions } from '../campaign-card-actions/campaign-card-actions';
import { classNames } from '../../../../Shared/utils/classnames';
import State from './state-machine/campaign-card-states';
import { Match } from '../../../../Shared/state-machine/match';
import { Spinner } from '../../../../Shared/components/spinner/spinner';
import { CampaignProgress } from '../campaign-progress/campaign-progress';
import { machineContextKey } from '../../../../Shared/state-machine/saga-state-machine';
import { createCampaignsLandingMachineContext } from '../../pages/campaigns-landing/state-machine/campaigns-landing-machine-context';
import { default as CampaignsLandingState } from '../../pages/campaigns-landing/state-machine/campaigns-landing-states';
import { CampaignCardErrorNotification } from '../campaign-card-error-notification/campaign-card-error-notification';
import { subscriber, subscribe, unsubscribeAll } from '../../../../Shared/decorators/subscriber';
import { handleCardFailure$ } from '../../pages/campaigns-landing/campaigns-landing-intent';
import { CampaignCardAction } from './state-machine/campaign-card-event-actions';
import { CampaignCardViewModel } from '../../campaign-types';
import { StatusLabel } from '../status-label';

import * as styles from './campaign-card.less';

export interface CampaignCardProps {
	cardViewModel: CampaignCardViewModel;
	machineContext?: ReturnType<typeof createCampaignsLandingMachineContext>;
	animationDelay?: number;
	acceptanceTestTargetId?: string;
	disableAnimation?: boolean;
}

function getTimeRecordFragment(label: string, value: string) {
	return <Fragment><span className={styles.timeRecordLabel}>{label}</span><span className={styles.timeRecordValue}> {value}</span></Fragment>;
}

function getOpenedFor(openedFor: string) {
	return openedFor ? getTimeRecordFragment('Opened for', openedFor) : null;
}

@inject(machineContextKey)
@subscriber
@observer
export class CampaignCard extends React.Component<CampaignCardProps> {

	private machineContext: ReturnType<typeof createCampaignCardMachineContext>;
	private onRetry: () => void;

	constructor(props) {
		super(props);
		this.machineContext = createCampaignCardMachineContext();
	}

	@subscribe(handleCardFailure$)
	onHandleCardFailure({ id, onRetry }: ReturnType<typeof CampaignCardAction.handleFailure>) {
		if (id === this.props.cardViewModel.Id) {
			this.onRetry = onRetry;
		}
	}

	@unsubscribeAll
	componentWillUnmount() { }

	render() {
		const { cardViewModel, animationDelay, acceptanceTestTargetId, disableAnimation} = this.props;
		return (
			<Provider machineContext={this.machineContext}>
				<div className={classNames(styles.container, styles.tabs)} data-pp-at-target={acceptanceTestTargetId}>
					<a href={cardViewModel.OverviewUrl} title={cardViewModel.Name} className={styles.title}>
						{cardViewModel.Name}
						<StatusLabel className={styles.status} status={cardViewModel.Status} />
					</a>
					<CampaignCardAmountRecord cardViewModel={cardViewModel} isPolling={this.isPolling} />
					<CampaignCardTimeRecord cardViewModel={cardViewModel} />
					<CampaignCardProgress cardViewModel={cardViewModel} isPolling={this.isPolling} animationDelay={animationDelay} disableAnimation={disableAnimation} />
					<CampaignCardActions cardViewModel={cardViewModel} className={styles.actions} />

					<Match state={State.Processing}>
						<div className={styles.cover}>
							<Spinner className={styles.loading} />
						</div>
					</Match>

					<Match state={State.ShowingError}>
						<CampaignCardErrorNotification retry={this.retry} />
					</Match>
				</div>
			</Provider>
		);
	}

	private retry = () => this.onRetry();

	@computed
	private get isPolling() {
		const { machineContext: { matchesState } } = this.props;
		return matchesState([CampaignsLandingState.PollingCollectedAmounts, CampaignsLandingState.On]);
	}
}

interface CampaignCardContentProps {
	cardViewModel: CampaignCardViewModel;
	machineContext?: ReturnType<typeof createCampaignCardMachineContext>;
	isPolling?: boolean;
	animationDelay?: number;
	disableAnimation?: boolean;
}

const CampaignCardAmountRecord = observer(({ cardViewModel, isPolling }: CampaignCardContentProps) => {
	return <div className={styles.amountRecord}>
		{cardViewModel.Status !== CampaignStatus.Draft &&
			<div className={classNames(styles.collectedInfo, styles.amountRecordItem)}>
				<span className={styles.amountLabel}>Total raised</span>
				{getDisplayedCollectionAmount(cardViewModel.CollectedAmount, isPolling)}
			</div>
		}
		{
			(cardViewModel.Status === CampaignStatus.Published || cardViewModel.Status === CampaignStatus.Closed) &&
			<div className={classNames(styles.pledgedInfo, styles.amountRecordItem)}>
				<span>Total {NewFeatures.DonorPledgeEntry_ConfigurablePledgeGrammar ? cardViewModel.PledgeLabel.VerbPastTenseLowerCase : 'pledged'}</span>
				<span className={styles.amount} data-pp-at-target="pledged amount">{Formatter.formatNumberForDisplay(cardViewModel.TotalPledged)}</span>
			</div>
		}
		<CampaignCardGoal cardViewModel={cardViewModel} />
	</div>;
});

function getDisplayedCollectionAmount(collectedAmount: number, isPolling: boolean) {
	if (isPolling) {
		return <span className={classNames(styles.amount, styles.amountPlaceholder)}>Calculating</span>;
	}

	return (
		<span className={styles.amount} data-pp-at-target="collected amount">
			{Formatter.formatNumberForDisplay(collectedAmount || 0)}
		</span>
	);
}

const CampaignCardGoal = observer(({ cardViewModel }: CampaignCardContentProps) => {

	if (cardViewModel.GoalAmount) {
		const isGoalReached = cardViewModel.Status !== CampaignStatus.Draft && (cardViewModel.CollectedAmount || 0) >= cardViewModel.GoalAmount;
		const goalContent = isGoalReached ? 'Goal Reached' : 'Goal';
		return (
			<div className={classNames(styles.amountRecordItem, isGoalReached && styles.reached)}>
				<span className={styles.amountLabel}>{goalContent}</span>
				<span className={classNames(styles.amount, isGoalReached && styles.reached)} data-pp-at-target="goal amount">
					{Formatter.formatNumberForDisplay(cardViewModel.GoalAmount)}
				</span>
			</div>
		);
	}

	if (cardViewModel.Status !== CampaignStatus.Draft) {
		return <div className={styles.amountRecordItem}>No Goal</div>;
	}

	return null;
});


const CampaignCardTimeRecord = observer(({ cardViewModel }: CampaignCardContentProps) => {
	let recordItems;

	if (cardViewModel.Status === CampaignStatus.Draft) {

		const { Created, Starts, Ends } = cardViewModel;
		recordItems = [
			getTimeRecordFragment('Created', Created),
			getTimeRecordFragment('Starts', Starts),
			Ends && getTimeRecordFragment('Ends', Ends),
		];

	} else if (cardViewModel.Status === CampaignStatus.Published) {

		const { Started, Ends } = cardViewModel;

		recordItems = [
			getTimeRecordFragment('Started', Started),
			Ends && getTimeRecordFragment('Ends', Ends),
			getOpenedFor(cardViewModel.OpenedFor),
		];

	} else if (cardViewModel.Status === CampaignStatus.Scheduled) {

		const { Starts, Ends } = cardViewModel;
		recordItems = [
			getTimeRecordFragment('Starts', Starts),
			Ends && getTimeRecordFragment('Ends', Ends),
		];

	} else if (cardViewModel.Status === CampaignStatus.Closed) {

		const { Started: started, Ended: ended } = cardViewModel;
		recordItems = [
			started && getTimeRecordFragment('Started', started),
			getTimeRecordFragment('Closed', ended),
			getOpenedFor(cardViewModel.OpenedFor),
		];

	} else {
		const unsupported: never | ScheduledCampaignCardViewModel = cardViewModel;
		throw new Error(`Campaign card ${unsupported} is not supported`);
	}

	return (
		<div className={styles.timeRecord}>
			{recordItems.filter(item => item).map((item, i) => (<span key={i} className={styles.timeRecordItem}>{item}</span>))}
		</div>
	);
});


const CampaignCardProgress = observer(({ cardViewModel, isPolling, animationDelay, disableAnimation }: CampaignCardContentProps) => {
	if ((cardViewModel.Status !== CampaignStatus.Draft) && cardViewModel.GoalAmount) {
		return (
			<CampaignProgress key={cardViewModel.Id}
				className={styles.progressBar}
				goalAmount={cardViewModel.GoalAmount}
				collectedAmount={isPolling ? 0 : cardViewModel.CollectedAmount}
				animationInSeconds={disableAnimation ? 0 : 3} closed={cardViewModel.Status === CampaignStatus.Closed}
				animationDelay={disableAnimation ? 0 : animationDelay} />
		);
	}
	return <div className={styles.progressBar}></div>;
});
