import { MachineContext } from './saga-state-machine';

export type ActionMap = { [actionKey: string]: (machineContext?: MachineContext, data?: {}) => Iterator<any> | void };
export type ActionMapKeys<TActionMap extends ActionMap> = { [x in keyof TActionMap]: x };

export function getActionMapKeys<TActionMap extends ActionMap>(actionMap: TActionMap) {
	return Object.keys(actionMap).reduce((acc, actionMapKey) => {
		acc[actionMapKey as keyof TActionMap] = actionMapKey;
		return acc;
	}, {} as ActionMapKeys<TActionMap>);
}
