import * as React from 'react';
import { observer } from 'mobx-react';
import { FormField, IFormFieldProps } from './form-field';
import { OptionalDate, IDatePickerCommonProps, DatePicker } from '../primitives/date-picker';
import { BaseFieldState } from './base-field-state';
import { ModelMetadata } from '../../../LoggedInWeb/loggedinweb-generated';
import { classNames } from '../../utils/classnames';

export class DatePickerFieldState extends BaseFieldState<OptionalDate> {
	constructor(initialValue: OptionalDate, metadata?: ModelMetadata.IPropertyMetadata) {
		super(initialValue, metadata);
		this.disableAutoValidation();
	}
}

export type IDatePickerFieldProps = IDatePickerCommonProps & IFormFieldProps & { fieldState: DatePickerFieldState };


export const DatePickerField = observer((props: IDatePickerFieldProps) => {
	const { label, className, tooltip, additionalClassNames, fieldState, ...rest } = props;
	const { value, onChange, enableAutoValidationAndValidate, hasError, error } = fieldState;
	const classes = classNames(`form-control`, additionalClassNames, hasError ? `input-validation-error` : null);
	return (
		<FormField label={label} className={className} tooltip={tooltip} error={error}>
			<DatePicker
				{...rest}
				value={value}
				onChange={onChange as any}
				ariaInvalid={hasError}
				onBlur={enableAutoValidationAndValidate}
				additionalClassNames={classes}
			/>
		</FormField>
	);
});
