import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { CloseFundButton, ICloseFundButtonProps } from './components/common-close-fund-button';
import { ReopenFundButton, IReopenFundButtonProps } from './components/common-reopen-fund-button';
import { DeleteFundButton, IDeleteFundButtonProps } from './components/common-delete-fund-button';
import { DeleteFundModalDialog, IDeleteFundModalDialogProps } from './components/common-delete-fund-dialog';
import { ModalDialogCommander } from '../components/modal-dialog-commander';
import { ModalDialog, IModalDialogButton } from '../components/modal-dialog';
import { ICloseCampaignFundButtonProps, CloseCampaignFundButton } from './components/close-campaign-fund-button';
import { IReopenCampaignFundButtonProps, ReopenCampaignFundButton } from './components/reopen-campaign-fund-button';

export interface IConfirmTaxChangeModalDialogProps {
	onConfirm: () => void;
}

export class ConfirmTaxChangeModalDialog extends React.Component<IConfirmTaxChangeModalDialogProps, any> {
	render() {
		const okButton = <button key="ok_button"
			className="btn btn-primary"
			onClick={this.props.onConfirm}
			data-dismiss="modal">OK</button>;
		const buttons: (IModalDialogButton | JSX.Element)[] = [
			{
				label: 'Cancel',
				dismiss: true,
				isPrimary: false
			},
			okButton
			];

		return (
			<ModalDialog title="" buttons={buttons}>
				<strong>Important!</strong><br />
				This will change the tax status for all transactions assigned to this fund.
				If you still intend to make this change, you must ensure you are fulfilling your legal obligations as a charitable organization.
			</ModalDialog>);
	}
}

export function init() {
	$.each($('[data-fund-action="close-fund"]'), (idx, button) => {
		var $button = $(button);
		var props: ICloseFundButtonProps = {
			url: $button.attr('data-action-url'),
			className: $button.attr('class'),
			fundClose: () => {
				window.location.reload();
				return jQuery.Deferred().promise();
			}
		};

		ReactDOM.render(<CloseFundButton {...props}/>, $(button).parent().get(0));
	});

	$.each($('[data-fund-action="close-campaign-fund"]'), (idx, button) => {
		var $button = $(button);
		var props: ICloseCampaignFundButtonProps = {
			url: $button.attr('data-action-url'),
			className: $button.attr('class')
		};

		ReactDOM.render(<CloseCampaignFundButton {...props}/>, $(button).parent().get(0));
	});

	$.each($('[data-fund-action="reopen-fund"]'), (idx, button) => {
		var $button = $(button);
		var props: IReopenFundButtonProps = {
			url: $button.attr('data-action-url'),
			className: $button.attr('class'),
			fundReopen: () => {
				window.location.reload();
				return jQuery.Deferred().promise();
			}
		};


		ReactDOM.render(<ReopenFundButton {...props}/>, $(button).parent().get(0));
	});

	$.each($('[data-fund-action="reopen-campaign-fund"]'), (idx, button) => {
		var $button = $(button);
		var props: IReopenCampaignFundButtonProps = {
			url: $button.attr('data-action-url'),
			className: $button.attr('class')
		};

		ReactDOM.render(<ReopenCampaignFundButton {...props}/>, $(button).parent().get(0));
	});

	$.each($('[data-fund-action="delete-fund"]'), (idx, button) => {
		var $button = $(button);
		var navigateOnDelete = $button.attr('data-navigate-on-delete');

		var dialogProps: IDeleteFundModalDialogProps = {
			urlDelete: $button.attr('data-action-delete-url'),
			fundDelete: () => {
				window.location.href = navigateOnDelete;
				return jQuery.Deferred().promise();
			}
		};
		var container = document.createElement('div');
		document.body.appendChild(container);

		var dialog: DeleteFundModalDialog | null;
		ReactDOM.render(<DeleteFundModalDialog {...dialogProps} ref={ref => dialog = ref} />, container);

		var props: IDeleteFundButtonProps = {
			urlCanDelete: $button.attr('data-action-candelete-url'),
			className: $button.attr('class'),
			canDeleteFund: () => {
				return dialog && dialog.open();
			}
		};

		ReactDOM.render(<DeleteFundButton {...props}/>, $(button).parent().get(0));
	});

	$.each($('[data-show-more-link]'), (idx, item) => {
		var link = $(item);
		var showMoreText = link.text();
		var showLessText = link.data('show-less-text');
		var targets = $(link.data('target'));

		link.on('click', (e) => {
			const currentTarget = e.currentTarget;

			if (currentTarget instanceof HTMLElement) {
				currentTarget.blur();
			}

			if (link.text() === showLessText) {
				link.text(showMoreText);
				targets.addClass('hidden');
			} else {
				link.text(showLessText);
				targets.removeClass('hidden');
			}
		});
	});

	function toggleTaxRadioButton() {
		const radioButton = document.querySelector('input[data-confirm-change]:not(:checked)');

		if (radioButton instanceof HTMLInputElement) {
			radioButton.checked = true;
		}
	}

	$.each($('[data-confirm-change]'), (idx, item: HTMLInputElement) => {
		item.addEventListener('change', () => {
			toggleTaxRadioButton();
			ModalDialogCommander.showReactDialog(<ConfirmTaxChangeModalDialog onConfirm={toggleTaxRadioButton} />);
		});
	});

	function triggerGivingAnimation(type: number) {
		try {
			window.dispatchEvent(new CustomEvent('pushpayGivingAnimation', { detail: type }));
		} catch (error) {
			// 
		}
	}

	$('input[data-giving-animation]').change(function () {
		var value = parseInt($(this).attr("data-giving-animation") ||'0');
		if ($(this).is(':checked') && value) {
			$('button[data-giving-animation-replay]').css({ opacity: '1' }).prop('disabled', false);;
			triggerGivingAnimation(value);
		} else {
			$('button[data-giving-animation-replay]').css({ opacity: '0' }).prop('disabled', true);;
        }
	});

	$('button[data-giving-animation-replay]').on('click', function () {
		var value = $('input[data-giving-animation]:checked').attr("data-giving-animation") || '0';
		triggerGivingAnimation(parseInt(value));
	});
}
