import * as React from 'react';
import { Formatter } from 'helpers/formatter';
import {
	Fund, ScheduleImportDraftDisplayStatus, PaymentLabel, ScheduleImportDraftEditingStatus, Country
} from '../../schedule-import-generated';
import { observer } from 'mobx-react';
import { ScheduleHelper } from '../../helpers/schedule-helper';
import { LoadingBox } from '../../../components/loading-box';
import { Button } from '../../../components/button';
import { inject } from 'mobx-react';
import { ScheduleDetailsUserActionCreator } from '../../pages/schedules-details/schedule-details-user-actions';
import { Fragment } from '../../../../Shared/components/fragment';
import { SvgWrapper } from '../../../components/svg-wrapper';
import { ModalDialogCommander } from '../../../components/modal-dialog-commander';
import { ModalDialog } from '../../../components/modal-dialog';
import { SelectItem } from '../../../transaction-import/transaction-import-generated';

import * as styles from './styles.less';

export type ScheduleDetailsExpandedRowProps = {
	scheduleId: number;
	status: ScheduleImportDraftDisplayStatus;
	name: string;
	hasSeenEmail: boolean;
	giftFrequency: string;
	fund?: Fund;
	amount: number;
	monthlyTotal: number;
	hasLoadedDetails: boolean;
	updateExpandedRow: (expandedRowKey: string) => void,
	expandedRowData: ScheduleDetailsProps | null;
	userActionCreator?: ScheduleDetailsUserActionCreator;
};

@inject('userActionCreator')
@observer
export class ScheduleDetailsExpandedRow extends React.Component<ScheduleDetailsExpandedRowProps> {
	render() {
		const { status, name, hasSeenEmail, giftFrequency, fund, amount, monthlyTotal, expandedRowData } = this.props;
		const isEditable = expandedRowData && expandedRowData.isEditable;
		const linkToPayer = expandedRowData && expandedRowData.linkToPayer;
		return (
			<div>
				<div className={`data-grid-row-data ${styles.rowDetails}`}>
					<div className="data-grid-cell data-grid-cell-status">{ScheduleHelper.statusFormatter(status)}</div>
					<div className="data-grid-cell data-grid-cell-giver">{linkToPayer ? <a href={linkToPayer}><strong>{name}</strong></a> : <span>{name}</span>}</div>
					<div className="data-grid-cell data-grid-cell-hasseenemail hidden-xs">{ScheduleHelper.seenEmailFormatter(hasSeenEmail)}</div>
					<div className="data-grid-cell data-grid-cell-fund hidden-xs">{ScheduleHelper.fundFormatter(fund)}</div>
					<div className="data-grid-cell data-grid-cell-giftfrequency hidden-xs">{giftFrequency}</div>
					<div className="data-grid-cell data-grid-cell-amount">{ScheduleHelper.amountFormatter(amount)}</div>
					<div className="data-grid-cell data-grid-cell-monthlytotal hidden-xs">{ScheduleHelper.amountFormatter(monthlyTotal)}</div>
					<div className="data-grid-cell data-grid-cell-viewlink hidden-xs" />
				</div>
				<div className={styles.wrapper}>
					{expandedRowData ? <ScheduleDetails {...expandedRowData} /> : <LoadingBox text="Loading"/> }
				</div>
				<div className={styles.footerBtnGroup}>
					<Button className="btn btn-ghost" onClick={this.closeButtonClicked}>Close</Button>
					<Button className="btn btn-default" onClick={this.editButtonClicked} disabled={!isEditable}>Edit</Button>
				</div>
			</div>
		);
	}

	componentDidMount() {
		const { userActionCreator, scheduleId } = this.props;
		userActionCreator.GetScheduleDetails(scheduleId);
	}

	private closeButtonClicked = () => {
		this.props.updateExpandedRow(null);
	}

	private editButtonClicked = () => {
		const { userActionCreator, expandedRowData } = this.props;

		if (expandedRowData && expandedRowData.isEditable) {
			userActionCreator.ShowEditScheduleDetailsDialog();
		}
	}
}

export type ScheduleDetailsProps = {
	scheduleId: number;
	paymentLabel: PaymentLabel;
	importedScheduleId: string;
	amount: number;
	paymentMethod: string;
	paymentReference: string;
	frequency: string;
	nextScheduledDate: Date;
	name: string;
	firstName: string;
	lastName: string;
	linkToPayer: string;
	email: string;
	mobileNumber: string;
	address: string;
	defaultCountry: Country;
	fund: string;
	listing: string;
	displayStatus: string;
	status: ScheduleImportDraftEditingStatus;
	availableStatusItems: SelectItem[];
	isEditable: boolean;
	isEmailBounced: boolean;
	userActionCreator?: ScheduleDetailsUserActionCreator;
};

@inject('userActionCreator')
@observer
export class ScheduleDetails extends React.Component<ScheduleDetailsProps> {
	render() {
		const {
			importedScheduleId,
			amount,
			paymentMethod,
			paymentReference,
			frequency,
			nextScheduledDate,
			name,
			linkToPayer,
			email,
			mobileNumber,
			address,
			fund,
			listing,
			displayStatus,
			isEditable
		} = this.props;
		return (
			<Fragment>
				<div className={styles.column}>
					<div className={styles.referenceItem}>
						<div className={styles.referenceItemTitle}>Amount</div>
						<div className={styles.referenceItemValue}>${Formatter.formatNumberForDisplay(amount)}</div>
					</div>
					{paymentMethod && (
						<div className={styles.referenceItem}>
							<div className={styles.referenceItemTitle}>Payment method</div>
							<div className={styles.referenceItemValue}>{paymentMethod}</div>
						</div>
					)}
					{paymentReference && (
						<div className={styles.referenceItem}>
							<div className={styles.referenceItemTitle}>Payment reference</div>
							<div className={styles.referenceItemValue}>{paymentReference}</div>
						</div>
					)}
					<div className={styles.referenceItem}>
						<div className={styles.referenceItemTitle}>Frequency</div>
						<div className={styles.referenceItemValue}>{frequency}</div>
					</div>
					{nextScheduledDate && (
						<div className={styles.referenceItem}>
							<div className={styles.referenceItemTitle}>Next gift scheduled</div>
							<div className={styles.referenceItemValue}>{Formatter.formatDate(nextScheduledDate, 'D MMMM, YYYY')}</div>
						</div>
					)}
				</div>
				<div className={styles.column}>
					<div className={styles.referenceItem}>
						<div className={styles.referenceItemTitle}>Name</div>
						<div className={styles.referenceItemValue}>
							{linkToPayer ? <a href={linkToPayer}>{name}</a> :<span>{name}</span>}
						</div>
					</div>
					<div className={styles.referenceItem}>
						<div className={styles.referenceItemTitle}>Email</div>
						<div className={styles.referenceItemValue}>{email}</div>
					</div>
					<div className={styles.referenceItem}>
						<div className={styles.referenceItemTitle}>Mobile</div>
						<div className={styles.referenceItemValue}>{mobileNumber}</div>
					</div>
					<div className={styles.referenceItem}>
						<div className={styles.referenceItemTitle}>Address</div>
						<div className={styles.referenceItemValue}>{address}</div>
					</div>
				</div>
				<div className={styles.column}>
					{importedScheduleId && (
						<div className={styles.referenceItem}>
							<div className={styles.referenceItemTitle}>Schedule ID</div>
							<div className={styles.referenceItemValue}>{importedScheduleId}</div>
						</div>
					)}
					<div className={styles.referenceItem}>
						<div className={styles.referenceItemTitle}>Fund</div>
						<div className={styles.referenceItemValue}>{fund ? fund : 'Donor to select'}</div>
					</div>
					<div className={styles.referenceItem}>
						<div className={styles.referenceItemTitle}>Listing</div>
						<div className={styles.referenceItemValue}>{listing}</div>
					</div>
					<div className={styles.referenceItem}>
						<div className={styles.referenceItemTitle}>Status</div>
						<div className={styles.referenceItemValue}>{displayStatus}</div>
					</div>
				</div>
				{isEditable && <button type="button" className={`btn btn-link ${styles.btnDelete}`} aria-label="delete" onClick={this.deleteButtonClicked}>
					<SvgWrapper className="icon" svg="icon-delete" />
					Delete
				</button>}

			</Fragment>
		);
	}

	private deleteButtonClicked = () => {
		const confirmButton = <Button className="btn btn-primary" onClick={this.confirmDelete}>Confirm</Button>;
		const buttons = [{label: 'Cancel', dismiss: true, isPrimary: false}, confirmButton];

		ModalDialogCommander.showReactDialog(
			<ModalDialog
				title="Are you sure you want to delete this schedule?"
				showAlert={false}
				hideCloseButton={true}
				buttons={buttons}/>);
	}

	private confirmDelete = () => {
		const { userActionCreator, scheduleId } = this.props;
		userActionCreator.DeleteSchedule(scheduleId);
		ModalDialogCommander.forceCloseCurrent();
	}
}
