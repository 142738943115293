

// ReSharper disable InconsistentNaming
// ReSharper disable StringLiteralWrongQuotes
/* tslint:disable:no-use-before-declare quotemark max-line-length */
import { IApiAction } from '../utils/data-service';
export enum ExportAction {
	View = 0, Csv = 1, Excel = 2, ExcelMultiMerchant = 3, Custom = 4,
}
export interface VirtualTerminalRemovePaymentResponseModel {
	ErrorMessage: string;
	ContentOutOfDate: VirtualTerminalContentOutOfDateModel;
	EncodedToken: string;
}
export interface VirtualTerminalContentOutOfDateModel {
	RecentGiftsOutOfDate: boolean;
	PaymentMethodNotFound: boolean;
	CommunityMemberNotFound: boolean;
	MerchantListingOutOfDate: boolean;
}
export interface BatchEntryPaymentResponseModel {
	BatchEntryPayment: BatchEntryPaymentViewModel;
	BatchEntryPayments: BatchEntryPaymentViewModel[];
	ErrorMessage: string;
	ReferenceFields: ICustomFieldViewModel[];
	ContentOutOfDate: VirtualTerminalContentOutOfDateModel;
}
export interface BatchEntryPaymentViewModel {
	EncodedToken: string;
	PaymentStatus: GiftEntryPaymentStatus;
	PayerDisplayName: string;
	PayerDisplayEmail: string;
	PayerDisplayPhone: string;
	PaymentMethod: string;
	FundKey: string;
	FundDisplayValue: string;
	AmountAsMoney: Money;
	CreatedOn: Date;
	IsDeletable: boolean;
	PayerAddress: string;
	AdditionalInfo: GiftEntryPaymentAdditionalInfo;
	EditUrl: string;
}
export interface GiftEntryPaymentAdditionalInfo {
	GivenOn: Date;
	Notes: string;
	ReferenceFields: GiftEntryPaymentCustomFieldViewModel[];
}
export interface GiftEntryPaymentCustomFieldViewModel {
	Label: string;
	Value: string;
	Key: string;
}
export interface Money {
	IsValid: boolean;
	Currency: Currency;
	Amount: number;
}
export enum Currency {
	NZD = 0, AUD = 1, USD = 2, CAD = 3, Unspecified = -1,
}
export enum GiftEntryPaymentStatus {
	Success = 0, Failed = 1, Processing = 2, Active = 3, Paused = 4, Refund = 5, Refunded = 6, Returned = 7, Return = 8, RefundReturn = 9, RefundReturned = 10,
}
export interface BatchHistoryViewModel {
	Items: BatchHistoryItemViewModel[];
}
export interface VirtualTerminalRemoveNonCashGiftResponseModel {
	ErrorMessage: string;
	ContentOutOfDate: VirtualTerminalContentOutOfDateModel;
	NonCashGiftId: number;
}
export interface RecentGiftEntryViewModel {
	EncodedToken: string;
	PaymentStatus: GiftEntryPaymentStatus;
	PayerDisplayName: string;
	PayerDisplayEmail: string;
	PayerDisplayPhone: string;
	PaymentMethod: string;
	FundDisplayValue: string;
	AmountAsMoney: Money;
	CreatedOn: Date;
	IsDeletable: boolean;
	PayerAddress: string;
	AdditionalInfo: GiftEntryPaymentAdditionalInfo;
	EditUrl: string;
	WhenNextPaymentDue: Date;
	FrequencyCode: FrequencyCode;
	IsRecurring: boolean;
	IsNonCash: boolean;
	NonCashGiftId: number;
	DescriptionForDonor: string;
	DescriptionForMerchant: string;
}
export enum FrequencyCode {
	Other = 0, Weekly = 1, Fortnightly = 2, Monthly = 3, FirstAndFifteenth = 4, OneMinute = 5, FiveMinutes = 6, FourHours = 7, Yearly = 8, SemiYearly = 9, Quarterly = 10,
}
export interface VirtualTerminalRecentGiftEntriesRequestModel {
	CurrentNumberOfGifts: number;
	GetRecentGiftEntryType: GetRecentGiftEntryType;
	LastFetchTime: Date;
	ExpandedItemEncodedToken: string;
	ExpandedItemNonCashGiftId: number;
	ExpandedItemIsNonCashGift: boolean;
}
export enum GetRecentGiftEntryType {
	Load = 0, LoadMore = 1, Poll = 2,
}
export interface RecentGiftEntriesViewModel {
	HasBeenUpdated: boolean;
	FetchTime: Date;
	RecentGiftEntries: RecentGiftEntryViewModel[];
	HasMoreRecentGiftEntries: HasMoreRecentGiftEntries;
	GetRecentGiftEntryType: GetRecentGiftEntryType;
	ExpandedGiftEntry: RecentGiftEntryViewModel;
}
export enum HasMoreRecentGiftEntries {
	Unknown = 0, Yes = 1, No = 2,
}
export interface VirtualTerminalExistingPaymentMethodsRequestModel {
	CommunityMemberId: number;
}
export interface VirtualTerminalExistingPaymentMethodsViewModel {
	PaymentMethods: ExistingPaymentMethodViewModel[];
	HasMorePages: boolean;
}
export interface ExistingPaymentMethodViewModel {
	PaymentMethodIndex: number;
	PaymentMethodType: PaymentMethodType;
	LastTwoDigits: string;
	CardBrandName: string;
	BankName: string;
	AccountNumberLength: number;
}
export enum PaymentMethodType {
	Unknown = 0, CreditCard = 1, NzBankAccount = 2, ACH = 3, Cash = 4, RecordedCheck = 5, AchCheck = 6, ProfitStarsScannedCheck = 7, RecordedCreditCard = 8, RecordedACH = 9, ImportedUnspecified = 10, ImportedUnsupported = 11,
}
export interface VirtualTerminalPaymentDetailsRequestModel {
	CommunityMemberId: number;
	MerchantVersion: number;
}
export interface VirtualTerminalPaymentDetailsResponseModel {
	CommunityMember: CommunityMemberQueryResult;
	PaymentDetails: VirtualTerminalPaymentDetailsModel;
	ExistingPaymentMethods: VirtualTerminalExistingPaymentMethodsViewModel;
	ListingConfiguration: VirtualTerminalListingConfiguration;
	ErrorMessage: string;
	ContentOutOfDate: VirtualTerminalContentOutOfDateModel;
}
export interface VirtualTerminalPaymentDetailsModel {
	PaymentMethodIndex: number;
	PaymentMethodType: PaymentMethodType;
	ReferenceFieldValues: CustomFieldEditModel[];
	PaymentReference: string;
	Amount: number;
	Notes: string;
	YourId: string;
	WantsTransactionNotifications: boolean;
	DescriptionForDonor: string;
	DescriptionForMerchant: string;
	AssetType: AssetType;
}
export interface CustomFieldEditModel {
	Key: string;
	Type: CustomFieldType;
	Value: string;
}
export enum CustomFieldType {
	Unknown = 0, Text = 1, Number = 2, DropDown = 3, Fund = 4,
}
export interface VirtualTerminalListingConfiguration {
	CustomFields: ICustomFieldViewModel[];
	CustomFieldDefaultValues: CustomFieldEditModel[];
	PaymentMin: number;
	PaymentMax: number;
	PaymentNounPluralLowerCase: string;
	SupportedPaymentMethodTypes: PaymentMethodType[];
	PreferredPaymentMethodType: PaymentMethodType;
	MerchantVersion: number;
	DefaultRecurringStartDate: Date;
	CardTokenizationAvailable: boolean;
	CardTokenizationHelpLink: string;
	SupportsNonCash: boolean;
	EnableFeatureOrganizationalGiving: boolean;
	SplitGivingEnabled: boolean;
}
export interface BatchEntryPaymentsRequestModel {
	BatchId: number;
	GetBatchEntryPaymentsType: GetBatchEntryPaymentsType;
	LastFetchTime: Date;
	ExpandedItemEncodedToken: string;
}
export enum GetBatchEntryPaymentsType {
	Load = 0, Poll = 1,
}
export interface BatchEntryPaymentsViewModel {
	HasBeenUpdated: boolean;
	FetchTime: Date;
	BatchEntryPayments: BatchEntryPaymentViewModel[];
	FundTotals: BatchEntryFundTotal[];
	GetBatchEntryPaymentsType: GetBatchEntryPaymentsType;
	ExpandedBatchEntryPayment: BatchEntryPaymentViewModel;
}
export interface BatchEntryFundTotal {
	FundKey: string;
	FundDisplayValue: string;
	FundCode: string;
	FundNotes: string;
	Total: Money;
}
export interface ReopenBatchRequestModel {
	BatchId: number;
}
export interface BatchDeleteResponseViewModel {
	Success: boolean;
	Reason: string;
}
export interface CloseBatchRequestModel {
	BatchId: number;
}
export interface CloseBatchResponseModel {
	Batch: BatchViewModel;
	BatchTotalsDoNotMatchExpectedTotals: boolean;
}
export interface EditBatchRequestModel {
	BatchId: number;
	Name: string;
	EventTimeId: number;
	ExpectedTotalCount: number;
	ExpectedTotalAmount: number;
}
export interface BatchViewModel {
	Name: string;
	BatchId: number;
	ListingId: number;
	ExpectedTotalCount: number;
	ExpectedTotalAmount: number;
	EventTimeId: number;
	Status: BatchStatus;
	CreatedBy: string;
	CreatedOn: Date;
	LastUpdatedBy: string;
	LastUpdatedOn: Date;
	ShowExportLabel: boolean;
	ExportLabel: string;
	ExportButtons: TransactionExportButton[];
}
export interface TransactionExportButton {
	Action: ExportAction;
	Description: string;
	Label: string;
}
export enum BatchStatus {
	Open = 0, Completed = 1, Deposited = 2,
}
export interface CreateBatchRequestModel {
	Name: string;
	EventTimeId: number;
	ExpectedTotalCount: number;
	ExpectedTotalAmount: number;
	GivenOnDate: Date;
}
export interface CreateBatchResponseModel {
	Batch: BatchViewModel;
	ListingConfiguration: VirtualTerminalListingConfiguration;
}
export interface VirtualTerminalPaymentRequestModel {
	CommunityMemberId: number;
	Amount: number;
	CommonFields: CommonPaymentFieldsModel;
	MerchantVersion: number;
	IsAnonymous: boolean;
	WantsTransactionNotifications: boolean;
	PaymentMethodIndex: number;
	PaymentMethodType: PaymentMethodType;
	CreditCard: FullCardModel;
	AchAccount: VirtualTerminalAchBankModel;
	RecordedCheck: RecordedCheckModel;
	RecordedACH: RecordedACHModel;
	RecordedCreditCard: RecordedCreditCardModel;
	ExistingCreditCard: ExistingCreditCardModel;
	ExistingACH: ExistingACHModel;
	YourId: string;
	IsRecurring: boolean;
	ScheduleFrequency: ScheduleFrequency;
	DescriptionForDonor: string;
	DescriptionForMerchant: string;
	AssetType: AssetType;
	LineItems: SplitLineItem[];
}
export interface SplitLineItem {
	FundKey: string;
	Amount: number;
}
export interface ScheduleFrequency {
	RepeatsEvery: number;
	RepeatUnit: TimeUom;
	FrequencyCode: FrequencyCode;
	EndType: EndType;
	EndDate: Date;
	EndOccurrences: number;
	WhenNextPaymentDue: Date;
	FrequencyCodeSelectList: FrequencyCode[];
	EndTypeSelectList: EndType[];
	HasAnnualRecurringFrequencyCodeOption: boolean;
	HasSemiAnnualRecurringFrequencyCodeOption: boolean;
	HasQuarterlyRecurringFrequencyCodeOption: boolean;
	DisplayOrphanedFrequencyOptionWarningMessage: boolean;
	IsOneTimeFutureGift: boolean;
}
export enum EndType {
	Never = 0, Date = 1, Occurrences = 2,
}
export enum TimeUom {
	Eternity = 0, Minute = 1, Hour = 2, Day = 3, Week = 4, Month = 5, Year = 6, FirstAndFifteenth = 7,
}
export interface ExistingACHModel {
	ConfirmedAccountNumber: string;
}
export interface ExistingCreditCardModel {
	ConfirmedCardNumber: string;
}
export interface RecordedCreditCardModel {
	Reference: string;
}
export interface RecordedACHModel {
	Reference: string;
}
export interface RecordedCheckModel {
	CheckNumber: string;
	CleanedCheckReaderOutput: string;
	RoutingNumber: string;
	AccountNumber: string;
	BankName: string;
	CheckAccountName: string;
	CheckType: AchCheckType;
}
export enum AchCheckType {
	Personal = 0, BusinessOrBank = 1,
}
export interface VirtualTerminalAchBankModel {
	RoutingNumber: string;
	AccountNumber: string;
	AccountType: AchAccountType;
}
export enum AchAccountType {
	Checking = 1, Savings = 2,
}
export interface FullCardModel {
	Number: string;
	ExpiryMonth: number;
	ExpiryYear: number;
	Cvc: string;
	ExpiryMonths: SelectListItem[];
	ExpiryYears: SelectListItem[];
	ShowCardTypes: boolean;
	CvcOptional: boolean;
	HasCvc: boolean;
}
export interface SelectListItem {
	Disabled: boolean;
	Group: SelectListGroup;
	Selected: boolean;
	Text: string;
	Value: string;
}
export interface SelectListGroup {
	Disabled: boolean;
	Name: string;
}
export interface CommonPaymentFieldsModel {
	ReferenceFieldValues: CustomFieldEditModel[];
	GivenOn: Date;
	Notes: string;
}
export interface VirtualTerminalPaymentResponseModel {
	RecentGiftEntry: RecentGiftEntryViewModel;
	RecentGiftEntries: RecentGiftEntryViewModel[];
	ErrorMessage: string;
	ReferenceFields: ICustomFieldViewModel[];
	ContentOutOfDate: VirtualTerminalContentOutOfDateModel;
}
export interface VirtualTerminalSearchPayersForCheckRequestModel {
	Check: RecordedCheckModel;
	Skip: number;
	MerchantVersion: number;
}
export interface VirtualTerminalSearchPayersForCheckResponseModel {
	ErrorMessage: string;
	SearchResult: CommunityMemberLookupResult;
	PaymentDetails: VirtualTerminalPaymentDetailsModel;
	MatchedCommunityMemberId: number;
	ListingConfiguration: VirtualTerminalListingConfiguration;
}
export interface CommunityMemberQueryResult {
	CommunityMemberId: number;
	PrimaryExternalId: string;
	CampusName: string;
	FirstName: string;
	LastName: string;
	EmailAddress: string;
	MobileNumber: string;
	Address: CommunityAddressModel;
	TimeZoneId: string;
	LastPaymentOnUtc: Date;
	LastPaymentOnLocal: Date;
	LockVersion: number;
	CommunityMemberType: CommunityMemberType;
}
export enum CommunityMemberType {
	Unknown = 0, Individual = 1, Organization = 2,
}
export interface CommunityAddressModel {
	Line1: string;
	Line2: string;
	City: string;
	State: string;
	Postcode: string;
	Country: Country;
	DisplayAddress: string;
	PostalCodeLabel: string;
}
export enum Country {
	NZ = 0, AU = 1, US = 2, AF = 4, AL = 8, AQ = 10, DZ = 12, AS = 16, AD = 20, AO = 24, AG = 28, AZ = 31, AR = 32, AT = 40, BS = 44, BH = 48, BD = 50, AM = 51, BB = 52, BE = 56, BM = 60, BT = 64, BO = 68,
	BA = 70, BW = 72, BV = 74, BR = 76, BZ = 84, IO = 86, SB = 90, VG = 92, BN = 96, BG = 100, MM = 104, BI = 108, BY = 112, KH = 116, CM = 120, CA = 124, CV = 132, KY = 136, CF = 140, LK = 144, TD = 148, CL = 152,
	CN = 156, TW = 158, CX = 162, CC = 166, CO = 170, KM = 174, YT = 175, CG = 178, CD = 180, CK = 184, CR = 188, HR = 191, CU = 192, CY = 196, CZ = 203, BJ = 204, DK = 208, DM = 212, DO = 214, EC = 218, SV = 222,
	GQ = 226, ET = 231, ER = 232, EE = 233, FO = 234, FK = 238, GS = 239, FJ = 242, FI = 246, AX = 248, FR = 250, GF = 254, PF = 258, TF = 260, DJ = 262, GA = 266, GE = 268, GM = 270, PS = 275, DE = 276, GH = 288,
	GI = 292, KI = 296, GR = 300, GL = 304, GD = 308, GP = 312, GU = 316, GT = 320, GN = 324, GY = 328, HT = 332, HM = 334, VA = 336, HN = 340, HK = 344, HU = 348, IS = 352, IN = 356, ID = 360, IR = 364, IQ = 368,
	IE = 372, IL = 376, IT = 380, CI = 384, JM = 388, JP = 392, KZ = 398, JO = 400, KE = 404, KP = 408, KR = 410, KW = 414, KG = 417, LA = 418, LB = 422, LS = 426, LV = 428, LR = 430, LY = 434, LI = 438, LT = 440,
	LU = 442, MO = 446, MG = 450, MW = 454, MY = 458, MV = 462, ML = 466, MT = 470, MQ = 474, MR = 478, MU = 480, MX = 484, MC = 492, MN = 496, MD = 498, ME = 499, MS = 500, MA = 504, MZ = 508, OM = 512, NA = 516,
	NR = 520, NP = 524, NL = 528, CW = 531, AW = 533, SX = 534, BQ = 535, NC = 540, VU = 548, NI = 558, NE = 562, NG = 566, NU = 570, NF = 574, NO = 578, MP = 580, UM = 581, FM = 583, MH = 584, PW = 585, PK = 586,
	PA = 591, PG = 598, PY = 600, PE = 604, PH = 608, PN = 612, PL = 616, PT = 620, GW = 624, TL = 626, PR = 630, QA = 634, RE = 638, RO = 642, RU = 643, RW = 646, BL = 652, SH = 654, KN = 659, AI = 660, LC = 662,
	MF = 663, PM = 666, VC = 670, SM = 674, ST = 678, SA = 682, SN = 686, RS = 688, SC = 690, SL = 694, SG = 702, SK = 703, VN = 704, SI = 705, SO = 706, ZA = 710, ZW = 716, ES = 724, SS = 728, SD = 729, EH = 732,
	SR = 740, SJ = 744, SZ = 748, SE = 752, CH = 756, SY = 760, TJ = 762, TH = 764, TG = 768, TK = 772, TO = 776, TT = 780, AE = 784, TN = 788, TR = 792, TM = 795, TC = 796, TV = 798, UG = 800, UA = 804, MK = 807,
	EG = 818, GB = 826, GG = 831, JE = 832, IM = 833, TZ = 834, VI = 850, BF = 854, UY = 858, UZ = 860, VE = 862, WF = 876, WS = 882, YE = 887, ZM = 894, XK = 999, __ = -2, Unsupported = -1,
}
export interface BatchQueryRequestModel {
	Page: number;
	TakeAllUpToCurrentPage: boolean;
	Status: BatchStatus;
	SortBy: SortOrder;
	MerchantListingIds: number[];
	Text: string;
	EventTimeIds: number[];
}
export enum SortOrder {
	CreatedOnAsc = 0, CreatedOnDesc = 1, BatchNameAsc = 2, BatchNameDesc = 3, TotalAmountAsc = 4, TotalAmountDesc = 5,
}
export interface BatchesQueryResponseModel {
	Batches: BatchListItemViewModel[];
	TotalBatchCount: number;
	CanLoadMore: boolean;
}
export interface EventTimeCreateRequest {
	Name: string;
	DayOfWeek: DayOfWeek;
	StartTime: string;
	EndTime: string;
}
export interface VirtualTerminalEventTimeViewModel {
	Name: string;
	DayOfWeek: DayOfWeek;
	StartTime: string;
	EndTime: string;
	Id: number;
	Token: string;
	Status: EventTimeStatus;
}
export enum EventTimeStatus {
	Unknown = 0, Open = 1, Archived = 2,
}
export interface DepositRequestModel {
	BatchIds: number[];
}
export interface DepositBatchResponseModel {
	Batches: BatchViewModel[];
}
export interface PayerSearchRequestModel {
	Query: string;
	Skip: number;
}
export interface CommunityMemberLookupResult {
	HasMorePages: boolean;
	Results: CommunityMemberQueryResult[];
}
export interface EditCommunityMemberRequestModel {
	CommunityMemberId: number;
	FirstName: string;
	LastName: string;
	EmailAddress: string;
	MobileNumber: string;
	Address: CommunityAddressModel;
	LockVersion: number;
	CommunityMemberType: CommunityMemberType;
}
export interface CommunityMemberModel {
	CommunityMemberId: number;
	PrimaryExternalId: string;
	CampusName: string;
	FirstName: string;
	LastName: string;
	EmailAddress: string;
	MobileNumber: string;
	Address: CommunityAddressModel;
	TimeZoneId: string;
	LockVersion: number;
	LastPaymentOnUtc: Date;
	LastPaymentOnLocal: Date;
	LinkedPersons: PersonModel[];
	CommunityMemberType: CommunityMemberType;
	PaymentLabel: PaymentLabel;
}
export interface PaymentLabel {
	NounSentenceCase: string;
	NounPluralSentenceCase: string;
	VerbSentenceCase: string;
	VerbAppliedSentenceCase: string;
	NounLowerCase: string;
	NounPluralLowerCase: string;
	VerbLowerCase: string;
	VerbAppliedLowerCase: string;
	VerbPresentTenseSentenceCase: string;
	VerbPresentTenseLowerCase: string;
	VerbPastTenseSentenceCase: string;
	VerbPastTenseLowerCase: string;
	PayerSentenceCase: string;
	PayerLowerCase: string;
	PayerPluralSentenceCase: string;
	PayerPluralLowerCase: string;
	ActionSentenceCase: string;
	ActionLowerCase: string;
	ActionPluralSentenceCase: string;
	ActionPluralLowerCase: string;
	NonCashNounSentenceCase: string;
	NonCashNounLowerCase: string;
	NonCashPluralSentenceCase: string;
	NonCashPluralLowerCase: string;
	TextGivingFeatureName: string;
	GiftEntryFeatureName: string;
}
export interface PersonModel {
	PersonId: number;
	FirstName: string;
	LastName: string;
	EmailAddress: string;
	EmailAddressVerified: boolean;
	MobileNumber: string;
	MobileNumberVerified: boolean;
	Address: CommunityAddressModel;
	LastPaymentOnUtc: Date;
	LastPaymentOnLocal: Date;
	CommunityMemberType: CommunityMemberType;
}
export interface VirtualTerminalRemovePaymentMethodRequestModel {
	CommunityMemberId: number;
	PaymentMethodIndex: number;
}
export interface VirtualTerminalRemovePaymentMethodResponseModel {
	ConfirmationRequired: boolean;
	ErrorMessage: string;
	ContentOutOfDate: VirtualTerminalContentOutOfDateModel;
}
export interface VirtualTerminalPayStats {
	PayersAdded: number;
	PayersEdited: number;
	AddPayerTimings: number[];
	EditPayerTimings: number[];
	PaymentEntryTiming: number;
	DefaultSettings: DefaultSettingsStatsModel;
}
export interface DefaultSettingsStatsModel {
	DefaultGivenOn: Date;
	DefaultNotes: string;
	DefaultPaymentMethodType: PaymentMethodType;
	DefaultReferenceFields: CustomFieldEditModel[];
}
export enum AssetType {
	Unknown = 0, StocksAndBonds = 1, Property = 2, Vehicles = 3, Other = 4,
}
export interface BatchHistoryItemViewModel {
	BatchHistoryId: number;
	DateAndTime: Date;
	OwnerName: string;
	Description: string;
}
export interface BatchEntryViewModel {
	Listings: ListingViewModel[];
	EventTimes: VirtualTerminalEventTimeViewModel[];
	Batch: BatchViewModel;
	OrgHasNoBatches: boolean;
	HasExportErrored: boolean;
	PayersReconciledMessage: string;
	ListingConfiguration: VirtualTerminalListingConfiguration;
	HelpCenterUrl: string;
	PaymentLabel: PaymentLabel;
	ReferrerUrl: string;
	BatchListUrl: string;
	ServiceTimeSettingsUrl: string;
}
export interface ListingViewModel {
	ListingId: number;
	ListingName: string;
	PaymentLabel: PaymentLabel;
	HomeCountry: Country;
	TransactionsUrl: string;
	SupportsNonCash: boolean;
	NonCashTransactionsUrl: string;
	ActiveEventTimes: number[];
	Role: MerchantAdminRole;
	Status: MerchantStatus;
	CanManageMembers: boolean;
}
export enum MerchantStatus {
	Active = 0, Closed = 3, Pending = 4, Deleted = 5,
}
export enum MerchantAdminRole {
	CanDoEverything = 0, PaymentEntryOnly = 1,
}
export interface BatchListItemViewModel {
	BatchId: number;
	BatchName: string;
	ListingName: string;
	Url: string;
	CreatedOn: Date;
	StatusLastChangedOn: Date;
	PaymentsTotal: number;
	PaymentsNumber: number;
	BatchStatus: BatchStatus;
	CreatedBy: string;
	LastUpdatedBy: string;
	PaymentLabel: PaymentLabel;
	RecordedPaymentsTotal: number;
	RecordedPaymentsNumber: number;
	HasBatchBeenSentToQuickBooks: boolean;
	IsOnClosedListing: boolean;
}
export interface BatchListViewModel {
	CreateBatchUrl: string;
	HelpCenterUrl: string;
	Listings: MultiSelectItem[];
	PaymentLabel: PaymentLabel;
	ServiceTimes: MultiSelectItem[];
	HasQuickBooks: boolean;
}
export interface MultiSelectItem {
	Text: string;
	SelectedText: string;
	Value: string;
	Disabled: boolean;
}
export enum DayOfWeek {
	Sunday = 0, Monday = 1, Tuesday = 2, Wednesday = 3, Thursday = 4, Friday = 5, Saturday = 6,
}
export interface SingleEntryViewModel {
	CommunityMemberId: number;
	Listings: ListingViewModel[];
	HasQuickBooks: boolean;
	BatchEntryUrl: string;
	HasAnnualRecurringGivingEnabled: boolean;
	HasSemiAnnualRecurringGivingEnabled: boolean;
	HasQuarterlyRecurringGivingEnabled: boolean;
	ShouldShowNewFrequencySelector: boolean;
	CanViewMembers: boolean;
	CanEditGiftEntries: boolean;
}
export interface EntryDashboardViewModel {
	HomeCountry: Country;
	PaymentLabel: PaymentLabel;
	SingleEntryUrl: string;
	BatchEntryUrl: string;
	BatchListUrl: string;
	CheckDepositUrl: string;
	HelpCenterUrl: string;
	ShowCheckDepositTile: boolean;
	RecentSingleEntriesCount: number;
	BatchesCount: number;
	HasQuickBooks: boolean;
	SupportsNonCashGifts: boolean;
}
export interface FundCustomFieldViewModel {
	Key: string;
	Label: string;
	Placeholder: string;
	IsRequired: boolean;
	Type: CustomFieldType.Fund;
	DefaultOptionValue: string;
	Options: FundCustomFieldOptionViewModel[];
	Order: number;
}
export interface FundCustomFieldOptionViewModel {
	Label: string;
	Value: string;
	ForListing: boolean;
}
export interface DropDownCustomFieldViewModel {
	Key: string;
	Label: string;
	Placeholder: string;
	IsRequired: boolean;
	Type: CustomFieldType.DropDown;
	DefaultOptionValue: string;
	Options: DropDownCustomFieldOptionViewModel[];
	Order: number;
}
export interface DropDownCustomFieldOptionViewModel {
	Label: string;
	Value: string;
}
export interface NumberCustomFieldViewModel {
	Key: string;
	Label: string;
	Placeholder: string;
	IsRequired: boolean;
	Type: CustomFieldType.Number;
	MinLength: number;
	MaxLength: number;
	Order: number;
}
export interface TextCustomFieldViewModel {
	Key: string;
	Label: string;
	Placeholder: string;
	IsRequired: boolean;
	Type: CustomFieldType.Text;
	MinLength: number;
	MaxLength: number;
	Validation: TextFieldValidation;
	Order: number;
}
export enum TextFieldValidation {
	NoValidation = 0, LettersOnly = 1,
}
export type ICustomFieldViewModel = TextCustomFieldViewModel | NumberCustomFieldViewModel | DropDownCustomFieldViewModel | FundCustomFieldViewModel;
export const VirtualTerminalApiConfig = {
	defaultBaseUrl: () => location.pathname.match(new RegExp('/pushpay/portal/\\d+', 'i'))[0],
	actions: {
		removePaymentMethod: <IApiAction<{model: VirtualTerminalRemovePaymentMethodRequestModel}, VirtualTerminalRemovePaymentMethodResponseModel>>{
			url: (data) => `/virtualterminal/remove-payment-method`,
		},
		removePaymentMethodConfirmed: <IApiAction<{model: VirtualTerminalRemovePaymentMethodRequestModel}, VirtualTerminalRemovePaymentMethodResponseModel>>{
			url: (data) => `/virtualterminal/remove-payment-method-confirmed`,
		},
		editCommunityMember: <IApiAction<{model: EditCommunityMemberRequestModel}, CommunityMemberModel>>{
			url: (data) => `/virtualterminal/editCommunityMember`,
		},
		searchPayers: <IApiAction<{model: PayerSearchRequestModel}, CommunityMemberLookupResult>>{
			url: (data) => `/virtualterminal/search-payers`,
		},
		depositBatches: <IApiAction<{model: DepositRequestModel}, DepositBatchResponseModel>>{
			url: (data) => `/virtualterminal/deposit-batches`,
		},
		addEventTime: <IApiAction<{createInfo: EventTimeCreateRequest}, VirtualTerminalEventTimeViewModel>>{
			url: (data) => `/virtualterminal/add-event-time`,
		},
		queryBatches: <IApiAction<{model: BatchQueryRequestModel}, BatchesQueryResponseModel>>{
			url: (data) => `/virtualterminal/batches/query`,
		},
		addCommunityMember: <IApiAction<{model: EditCommunityMemberRequestModel, merchantId: number}, CommunityMemberQueryResult>>{
			url: (data) => `/virtualterminal/${data.merchantId}/addcommunitymember`,
		},
		searchPayersForCheck: <IApiAction<{merchantId: number, model: VirtualTerminalSearchPayersForCheckRequestModel}, VirtualTerminalSearchPayersForCheckResponseModel>>{
			url: (data) => `/virtualterminal/${data.merchantId}/search-payers-for-check`,
		},
		logFailedCheckRead: <IApiAction<{merchantId: number, cleanedCheckReaderOutput: string}, boolean>>{
			url: (data) => `/virtualterminal/${data.merchantId}/failed-check-read`,
		},
		pay: <IApiAction<{merchantId: number, model: VirtualTerminalPaymentRequestModel, stats: VirtualTerminalPayStats}, VirtualTerminalPaymentResponseModel>>{
			url: (data) => `/virtualterminal/${data.merchantId}/pay`,
		},
		addNonCashGift: <IApiAction<{merchantId: number, model: VirtualTerminalPaymentRequestModel, stats: VirtualTerminalPayStats}, VirtualTerminalPaymentResponseModel>>{
			url: (data) => `/virtualterminal/${data.merchantId}/addnoncashgift`,
		},
		createBatch: <IApiAction<{merchantId: number, model: CreateBatchRequestModel}, CreateBatchResponseModel>>{
			url: (data) => `/virtualterminal/${data.merchantId}/create-batch`,
		},
		editBatch: <IApiAction<{merchantId: number, model: EditBatchRequestModel}, BatchViewModel>>{
			url: (data) => `/virtualterminal/${data.merchantId}/edit-batch`,
		},
		closeBatch: <IApiAction<{merchantId: number, model: CloseBatchRequestModel}, CloseBatchResponseModel>>{
			url: (data) => `/virtualterminal/${data.merchantId}/close-batch`,
		},
		deleteBatch: <IApiAction<{merchantId: number, batchId: number}, BatchDeleteResponseViewModel>>{
			url: (data) => `/virtualterminal/${data.merchantId}/delete-batch`,
		},
		reopenBatch: <IApiAction<{merchantId: number, model: ReopenBatchRequestModel}, BatchViewModel>>{
			url: (data) => `/virtualterminal/${data.merchantId}/reopen-batch`,
		},
		batchEntryPaymentsAsync: <IApiAction<{merchantId: number, model: BatchEntryPaymentsRequestModel}, BatchEntryPaymentsViewModel>>{
			url: (data) => `/virtualterminal/${data.merchantId}/batch-entry-payments`,
		},
		getEventTimes: <IApiAction<{merchantId: number}, VirtualTerminalEventTimeViewModel[]>>{
			url: (data) => `/virtualterminal/${data.merchantId}/get-eventtimes`,
		},
		getListingConfiguration: <IApiAction<{merchantId: number}, VirtualTerminalListingConfiguration>>{
			url: (data) => `/virtualterminal/${data.merchantId}/listing-configuration`,
		},
		getPayerPaymentDetails: <IApiAction<{merchantId: number, model: VirtualTerminalPaymentDetailsRequestModel}, VirtualTerminalPaymentDetailsResponseModel>>{
			url: (data) => `/virtualterminal/${data.merchantId}/payment-details`,
		},
		getPayerAllExistingPaymentMethods: <IApiAction<{merchantId: number, model: VirtualTerminalExistingPaymentMethodsRequestModel}, VirtualTerminalExistingPaymentMethodsViewModel>>{
			url: (data) => `/virtualterminal/${data.merchantId}/existing-payment-methods`,
		},
		addEventTimeActivateForMerchant: <IApiAction<{merchantId: number, createInfo: EventTimeCreateRequest}, VirtualTerminalEventTimeViewModel>>{
			url: (data) => `/virtualterminal/${data.merchantId}/add-event-time`,
		},
		recentGiftEntriesAsync: <IApiAction<{merchantId: number, model: VirtualTerminalRecentGiftEntriesRequestModel}, RecentGiftEntriesViewModel>>{
			url: (data) => `/virtualterminal/${data.merchantId}/recentgifts/list`,
		},
		getEventTimesWithBatchId: <IApiAction<{merchantId: number, batchId: number}, VirtualTerminalEventTimeViewModel[]>>{
			url: (data) => `/virtualterminal/${data.merchantId}/get-eventtimes/batch/${data.batchId}`,
		},
		viewNonCashGift: <IApiAction<{merchantId: number, nonCashGiftId: number}, RecentGiftEntryViewModel>>{
			url: (data) => `/virtualterminal/${data.merchantId}/recentgifts/noncash/${data.nonCashGiftId}/view`,
		},
		deleteNonCashGift: <IApiAction<{merchantId: number, nonCashGiftId: number}, VirtualTerminalRemoveNonCashGiftResponseModel>>{
			url: (data) => `/virtualterminal/${data.merchantId}/recentgifts/noncash/${data.nonCashGiftId}/delete`,
		},
		getBatchHistory: <IApiAction<{merchantId: number, batchId: number}, BatchHistoryViewModel>>{
			url: (data) => `/virtualterminal/${data.merchantId}/batch-history/${data.batchId}`,
		},
		payViaBatchEntry: <IApiAction<{merchantId: number, batchId: number, model: VirtualTerminalPaymentRequestModel, stats: VirtualTerminalPayStats}, BatchEntryPaymentResponseModel>>{
			url: (data) => `/virtualterminal/${data.merchantId}/batch/${data.batchId}/pay`,
		},
		viewPayment: <IApiAction<{merchantId: number, encodedToken: string}, RecentGiftEntryViewModel>>{
			url: (data) => `/virtualterminal/${data.merchantId}/recentgifts/${data.encodedToken}/view`,
		},
		deletePayment: <IApiAction<{merchantId: number, encodedToken: string}, VirtualTerminalRemovePaymentResponseModel>>{
			url: (data) => `/virtualterminal/${data.merchantId}/recentgifts/${data.encodedToken}/delete`,
		},
	},
	urls: {
	},
};
export const VirtualTerminalExportConfig = {
	defaultBaseUrl: () => (<RegExpMatchArray>location.pathname.match(new RegExp('/pushpay/portal/\\d+', 'i')))[0],
	actions: {
		batchExport: <IApiAction<{batchId: number, button: ExportAction}>>{
			url: (data) => `/entry/batch/export`,
		},
	},
	urls: {
	},
};
/* tslint:enable:no-use-before-declare quotemark max-line-length */
// ReSharper restore InconsistentNaming
// ReSharper restore StringLiteralWrongQuotes
// ReSharper disable InconsistentNaming
// ReSharper disable StringLiteralWrongQuotes
/* tslint:disable:no-use-before-declare quotemark max-line-length */
export namespace ModelMetadata {
	export class MetadataArrayOf<T> {
		metadata: T;

		constructor(metadata: T) {
			this.metadata = metadata;
		}

		at(index: number): T {
			return this.metadata;
		}
	}

	export interface IPropertyMetadata {
		propertyName: string;
		displayName?: string;
		placeholder?: string;
		validationRules?: { [rule: string]: any };
		modelMetadata?: { [name: string] :IPropertyMetadata};
	}

	export let VirtualTerminalPaymentRequestModel = {
		CommunityMemberId: {
			propertyName: "CommunityMemberId",
			validationRules: {
				number: {
					errorMessage: "The field CommunityMemberId must be a number.",
				},
			},
		},
		Amount: {
			propertyName: "Amount",
			validationRules: {
				number: {
					errorMessage: "The field Amount must be a number.",
				},
				regex: {
					errorMessage: "Please make sure the amount entered is valid, and has at most two decimal places",
					parameters: {
						pattern: "\\d+(.\\d{1,2})?",
					},
				},
				required: {
					errorMessage: "Please enter an amount",
				},
			},
		},
		CommonFields: {
			propertyName: "CommonFields",
		},
		MerchantVersion: {
			propertyName: "MerchantVersion",
			validationRules: {
				number: {
					errorMessage: "The field MerchantVersion must be a number.",
				},
				required: {
					errorMessage: "The MerchantVersion field is required.",
				},
			},
		},
		IsAnonymous: {
			propertyName: "IsAnonymous",
			validationRules: {
				required: {
					errorMessage: "The IsAnonymous field is required.",
				},
			},
		},
		WantsTransactionNotifications: {
			propertyName: "WantsTransactionNotifications",
			validationRules: {
				required: {
					errorMessage: "The WantsTransactionNotifications field is required.",
				},
			},
		},
		PaymentMethodIndex: {
			propertyName: "PaymentMethodIndex",
			validationRules: {
				number: {
					errorMessage: "The field PaymentMethodIndex must be a number.",
				},
			},
		},
		PaymentMethodType: {
			propertyName: "PaymentMethodType",
			validationRules: {
				required: {
					errorMessage: "The PaymentMethodType field is required.",
				},
			},
		},
		CreditCard: {
			propertyName: "CreditCard",
			validationRules: {
				skipsanitization: {},
			},
		},
		AchAccount: {
			propertyName: "AchAccount",
			get modelMetadata() {
				return ModelMetadata.VirtualTerminalAchBankModel;
			},
		},
		RecordedCheck: {
			propertyName: "RecordedCheck",
			validationRules: {
				conditionallyrequired: {
					parameters: {
						conditionalpropertyname: "PaymentMethodType",
						conditionalpropertyvalue: 5,
					},
				},
			},
			get modelMetadata() {
				return ModelMetadata.RecordedCheckModel;
			},
		},
		RecordedACH: {
			propertyName: "RecordedACH",
			validationRules: {
				conditionallyrequired: {
					parameters: {
						conditionalpropertyname: "PaymentMethodType",
						conditionalpropertyvalue: 9,
					},
				},
			},
			get modelMetadata() {
				return ModelMetadata.RecordedACHModel;
			},
		},
		RecordedCreditCard: {
			propertyName: "RecordedCreditCard",
			validationRules: {
				conditionallyrequired: {
					parameters: {
						conditionalpropertyname: "PaymentMethodType",
						conditionalpropertyvalue: 8,
					},
				},
			},
			get modelMetadata() {
				return ModelMetadata.RecordedCreditCardModel;
			},
		},
		ExistingCreditCard: {
			propertyName: "ExistingCreditCard",
		},
		ExistingACH: {
			propertyName: "ExistingACH",
		},
		YourId: {
			propertyName: "YourId",
			validationRules: {
				length: {
					errorMessage: "Your ID must be less than or equal to 100 characters",
					parameters: {
						max: 100,
					},
				},
				skipsanitization: {},
			},
		},
		IsRecurring: {
			propertyName: "IsRecurring",
			validationRules: {
				required: {
					errorMessage: "The IsRecurring field is required.",
				},
			},
		},
		ScheduleFrequency: {
			propertyName: "ScheduleFrequency",
		},
		DescriptionForDonor: {
			propertyName: "DescriptionForDonor",
			validationRules: {
				length: {
					errorMessage: "Description for donor must be less than 512 characters.",
					parameters: {
						max: 512,
					},
				},
			},
		},
		DescriptionForMerchant: {
			propertyName: "DescriptionForMerchant",
			validationRules: {
				length: {
					errorMessage: "Description for merchant must be less than 3000 characters.",
					parameters: {
						max: 3000,
					},
				},
			},
		},
		AssetType: {
			propertyName: "AssetType",
			validationRules: {
				required: {
					errorMessage: "The AssetType field is required.",
				},
			},
		},
		LineItems: {
			propertyName: "LineItems",
		},
	};
	export let RecordedCheckModel = {
		CheckNumber: {
			propertyName: "CheckNumber",
			displayName: "Check number",
			validationRules: {
				length: {
					errorMessage: "Enter a valid check number",
					parameters: {
						max: 15,
						min: 1,
					},
				},
				regex: {
					errorMessage: "Only numbers are accepted",
					parameters: {
						pattern: "^\\$?\\d+?$",
					},
				},
				skipsanitization: {},
			},
		},
		CleanedCheckReaderOutput: {
			propertyName: "CleanedCheckReaderOutput",
		},
		RoutingNumber: {
			propertyName: "RoutingNumber",
			displayName: "Routing number",
			validationRules: {
				length: {
					errorMessage: "A 9 digit routing number is required",
					parameters: {
						max: 9,
						min: 9,
					},
				},
				regex: {
					errorMessage: "Only numbers are accepted",
					parameters: {
						pattern: "^\\$?\\d+?$",
					},
				},
			},
		},
		AccountNumber: {
			propertyName: "AccountNumber",
			displayName: "Account number",
			validationRules: {
				length: {
					errorMessage: "Enter a valid account number",
					parameters: {
						max: 17,
						min: 4,
					},
				},
				regex: {
					errorMessage: "Only numbers are accepted",
					parameters: {
						pattern: "^\\$?\\d+?$",
					},
				},
				skipsanitization: {},
			},
		},
		BankName: {
			propertyName: "BankName",
			displayName: "Bank Name",
		},
		CheckAccountName: {
			propertyName: "CheckAccountName",
			displayName: "Check Account Name",
			validationRules: {
				length: {
					errorMessage: "Please enter valid name",
					parameters: {
						max: 200,
					},
				},
			},
		},
		CheckType: {
			propertyName: "CheckType",
			displayName: "Check type",
			validationRules: {
				required: {
					errorMessage: "The Check type field is required.",
				},
			},
		},
	};
	export let RecordedCreditCardModel = {
		Reference: {
			propertyName: "Reference",
			validationRules: {
				length: {
					errorMessage: "The field Reference must be a string with a maximum length of 28.",
					parameters: {
						max: 28,
					},
				},
			},
		},
	};
	export let RecordedACHModel = {
		Reference: {
			propertyName: "Reference",
			validationRules: {
				length: {
					errorMessage: "The field Reference must be a string with a maximum length of 28.",
					parameters: {
						max: 28,
					},
				},
			},
		},
	};
	export let VirtualTerminalAchBankModel = {
		RoutingNumber: {
			propertyName: "RoutingNumber",
			displayName: "Routing number",
			validationRules: {
				length: {
					errorMessage: "A 9 digit routing number is required",
					parameters: {
						max: 9,
						min: 9,
					},
				},
				required: {
					errorMessage: "Enter your bank routing number",
				},
			},
		},
		AccountNumber: {
			propertyName: "AccountNumber",
			displayName: "Account number",
			validationRules: {
				length: {
					errorMessage: "Enter a valid account number",
					parameters: {
						max: 17,
						min: 4,
					},
				},
				regex: {
					errorMessage: "Only numbers are accepted",
					parameters: {
						pattern: "^\\$?\\d+?$",
					},
				},
				required: {
					errorMessage: "Enter your account number",
				},
				skipsanitization: {},
			},
		},
		AccountType: {
			propertyName: "AccountType",
			displayName: "Account type",
			validationRules: {
				required: {
					errorMessage: "Please choose the account type",
				},
			},
		},
	};
	export let CreateBatchRequestModel = {
		Name: {
			propertyName: "Name",
			validationRules: {
				length: {
					errorMessage: "The field Name must be a string with a maximum length of 200.",
					parameters: {
						max: 200,
					},
				},
			},
		},
		EventTimeId: {
			propertyName: "EventTimeId",
			validationRules: {
				number: {
					errorMessage: "The field EventTimeId must be a number.",
				},
			},
		},
		ExpectedTotalCount: {
			propertyName: "ExpectedTotalCount",
			validationRules: {
				number: {
					errorMessage: "The field ExpectedTotalCount must be a number.",
				},
			},
		},
		ExpectedTotalAmount: {
			propertyName: "ExpectedTotalAmount",
			validationRules: {
				number: {
					errorMessage: "The field ExpectedTotalAmount must be a number.",
				},
			},
		},
		GivenOnDate: {
			propertyName: "GivenOnDate",
			validationRules: {
				date: {
					errorMessage: "The field GivenOnDate must be a date.",
				},
			},
		},
	};
	export let EditBatchRequestModel = {
		BatchId: {
			propertyName: "BatchId",
			validationRules: {
				number: {
					errorMessage: "The field BatchId must be a number.",
				},
				required: {
					errorMessage: "The BatchId field is required.",
				},
			},
		},
		Name: {
			propertyName: "Name",
			validationRules: {
				length: {
					errorMessage: "The field Name must be a string with a maximum length of 200.",
					parameters: {
						max: 200,
					},
				},
				required: {
					errorMessage: "A batch name is required",
				},
			},
		},
		EventTimeId: {
			propertyName: "EventTimeId",
			validationRules: {
				number: {
					errorMessage: "The field EventTimeId must be a number.",
				},
			},
		},
		ExpectedTotalCount: {
			propertyName: "ExpectedTotalCount",
			validationRules: {
				number: {
					errorMessage: "The field ExpectedTotalCount must be a number.",
				},
			},
		},
		ExpectedTotalAmount: {
			propertyName: "ExpectedTotalAmount",
			validationRules: {
				number: {
					errorMessage: "The field ExpectedTotalAmount must be a number.",
				},
			},
		},
	};
	export let BatchQueryRequestModel = {
		Page: {
			propertyName: "Page",
			validationRules: {
				number: {
					errorMessage: "The field Page must be a number.",
				},
				required: {
					errorMessage: "The Page field is required.",
				},
			},
		},
		TakeAllUpToCurrentPage: {
			propertyName: "TakeAllUpToCurrentPage",
		},
		Status: {
			propertyName: "Status",
			validationRules: {
				required: {
					errorMessage: "The Status field is required.",
				},
			},
		},
		SortBy: {
			propertyName: "SortBy",
			validationRules: {
				required: {
					errorMessage: "The SortBy field is required.",
				},
			},
		},
		MerchantListingIds: {
			propertyName: "MerchantListingIds",
		},
		Text: {
			propertyName: "Text",
			validationRules: {
				length: {
					errorMessage: "The field Text must be a string with a maximum length of 200.",
					parameters: {
						max: 200,
					},
				},
			},
		},
		EventTimeIds: {
			propertyName: "EventTimeIds",
		},
	};
	export let ExportAction = {
	};
}
/* tslint:enable:no-use-before-declare quotemark max-line-length */
// ReSharper restore InconsistentNaming
// ReSharper restore StringLiteralWrongQuotes
