import { PaymentLabel, ScheduleImportMigrationEmailContentType } from '../../../schedule-import-generated';
import { Formatter } from '../../../../helpers/formatter';

export class InitialEmailTemplate {
	static getDefaultVideoTextTemplate(orgName: string, paymentLabel: PaymentLabel): string {
		return(
/* tslint:disable:max-line-length */
`Thank you for your consistent generosity to ${orgName}  through your recurring ${paymentLabel.NounLowerCase}.

This short video message explains more.`
/* tslint:enable:max-line-length */
		);
	}

	static getDefaultTextTemplate(
		orgName: string,
		endDate: Date,
		paymentLabel: PaymentLabel,
		contentType: ScheduleImportMigrationEmailContentType,
		contactName: string,
		contactNumber: string
	): string {
		const endDateForDisplay = Formatter.formatDate(endDate, 'MMM D, YYYY');

		return (
`${this.getContentSpecificText(endDateForDisplay, contentType, paymentLabel)}

If you have any questions, please reply to this email or contact ${contactName} on ${contactNumber}.

Blessings,
The Team at ${orgName}`
		);
	}

	private static getContentSpecificText(endDateForDisplay: string, contentType: ScheduleImportMigrationEmailContentType, paymentLabel: PaymentLabel): string {
		/* tslint:disable:max-line-length */
		switch (contentType) {
			case ScheduleImportMigrationEmailContentType.OldSystemSwitchedOff:
				return(
`As you heard in the video, we are transitioning to a new online giving platform called Pushpay. It is our goal to complete this process by ${endDateForDisplay}, so we need your help to move your current online giving to the new platform. It's a simple process that will take no more than a few minutes.

To transition from our current platform just set up a new recurring gift using Pushpay by hitting the button below.

We'll be switching our old system off on ${endDateForDisplay}, after this date any remaining ${paymentLabel.NounPluralLowerCase} will be cancelled.`
				);
			case ScheduleImportMigrationEmailContentType.OldScheduleStoppedByChurch:
				return(
`As you heard in the video, we are transitioning to a new online giving platform called Pushpay. It is our goal to complete this process by ${endDateForDisplay}, so we need your help to move your current online giving to the new platform. It's a simple process that will take no more than a few minutes.

To transition from our current platform to Pushpay set up a new recurring ${paymentLabel.NounLowerCase} by hitting the button below.

As you set up your recurring ${paymentLabel.NounLowerCase} you will complete the creation of your new account.

We will cancel your old recurring ${paymentLabel.NounLowerCase} on your behalf by ${endDateForDisplay}, but you're also welcome to cancel it yourself if you wish.`
				);
			case ScheduleImportMigrationEmailContentType.OldScheduleCancelledByUser:
				return(
`As you heard in the video, we are transitioning to a new online giving platform called Pushpay. It is our goal to complete this process by ${endDateForDisplay}, so we need your help to move your current online giving to the new platform. It's a simple, two-step process that will take no more than a few minutes.

Here's how to transition from our current platform to Pushpay

	1. Cancel your current scheduled ${paymentLabel.NounPluralLowerCase} → [link to cancel ${paymentLabel.NounLowerCase}]
	2. Set up a new recurring ${paymentLabel.NounLowerCase} using Pushpay by hitting the button below.

As you set up your recurring ${paymentLabel.NounLowerCase} you will complete the creation of your new account.`
				);
		}
		/* tslint:enable:max-line-length */
	}
}
