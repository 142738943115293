import { useCallback, useState } from 'react';

import { useMediaBreakpoint } from '@pushpay/theming';

export function useMobile() {
	const [isOpenMobile, setIsOpenMobile] = useState(false);
	const toggleMobile = useCallback(() => setIsOpenMobile((current) => !current), []);
	const closeMobile = useCallback(() => setIsOpenMobile(false), []);
	const isMobile = useMediaBreakpoint('TABLET_AND_BELOW');

	return {
		isMobile,
		isOpenMobile,
		toggleMobile,
		closeMobile,
	}
}
