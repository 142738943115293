import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { MachineContext, machineContextKey } from '../../../../Shared/state-machine/saga-state-machine';
import { PledgeEntryMainViewModel } from '../../pages/pledge-entry/pledge-entry-main-view-model';
import { States, Events } from '../../pages/pledge-entry/pledge-entry-states-events-and-actions';
import { RenderResponsive } from '../../../components/hoc-behavior/render-responsive';
import { ResponsiveVisibility, ResponsiveBreakpoint } from '../../../helpers/responsive-helper';
import { Fragment } from '../../../../Shared/components/fragment';
import {
	PayerSearchContainer,
	PayerSearchLabel,
	PayerSearchEditButton,
	PayerSearchInputField,
	PayerSearchClearButton,
	SelectedPayerInfo,
	PayerSearchDropDownMenu,
	IPayerViewModel
} from '../../../components/payer-search-omnibox/payer-search-omnibox';

import * as styles from './pledge-entry-member-search.less';

@inject(machineContextKey)
@observer
export class PledgeEntryMemberSearch extends React.Component<{ vm: PledgeEntryMainViewModel, machineContext?: MachineContext }> {
	render() {
		const {
			machineContext,
			vm: {
				memberSearchValue,
				form,
				payerSearchResult,
				memberSearchDisplayValue,
				selectedPayer,
				enableFeatureOrganizationalGiving,
			}
		} = this.props;
		const memberSelected = machineContext.matchesState(States.MemberSelected);
		const showingResults = machineContext.matchesState(States.ShowingResults);
		const loadingMore = machineContext.matchesState(States.LoadingMore);
		return (
			<PayerSearchContainer className={styles.container}>
				<PayerSearchLabel payerNoun="Member">
					{
						memberSelected &&
						<RenderResponsive visibility={ResponsiveVisibility.Visible} breakpoints={ResponsiveBreakpoint.Xs}>
							<PayerSearchEditButton onEditHandler={this.handleEditMember} />
						</RenderResponsive>
					}
					<PayerSearchInputField
						value={memberSearchDisplayValue}
						placeholder="Type member's name"
						onChange={this.handleInputChange}
						focusOnMount={true}
					/>
					{
						memberSelected &&
						<Fragment>
							<PayerSearchClearButton selectedPayer={selectedPayer} onClick={this.handleClearMember} />
							<SelectedPayerInfo selectedPayer={selectedPayer}>
								<RenderResponsive visibility={ResponsiveVisibility.Hidden} breakpoints={ResponsiveBreakpoint.Xs}>
									<PayerSearchEditButton onEditHandler={this.handleEditMember} />
								</RenderResponsive>
							</SelectedPayerInfo>
						</Fragment>
					}
				</PayerSearchLabel>
				<PayerSearchDropDownMenu
					componentId="pledge-entry-omnibox"
					searchResult={payerSearchResult}
					searchValue={memberSearchValue}
					onChange={this.handleSelectedPayerChange}
					selectedPayer={selectedPayer}
					onLoadMore={this.handleLoadMore}
					loadMoreInProgress={loadingMore}
					onMenuStateChange={this.handleMenuStateChange}
					onAddNewCommunityMember={this.handleAddMember}
					openRequested={showingResults}
					enableFeatureOrganizationalGiving={enableFeatureOrganizationalGiving} />
			</PayerSearchContainer>
		);
	}

	handleInputChange = (event: React.FormEvent<HTMLInputElement>) => {
		const { machineContext } = this.props;
		const input = event.currentTarget.value;
		Events.raise.Search(machineContext, { query: input });
	}

	handleSelectedPayerChange = (payer: IPayerViewModel) => {
		Events.raise.SelectMember(this.props.machineContext, { payer });
	}

	handleLoadMore = () => {
		Events.raise.LoadMore(this.props.machineContext);
	}

	handleAddMember = () => {
		Events.raise.AddMember(this.props.machineContext);
	}

	handleEditMember = () => {
		Events.raise.EditMember(this.props.machineContext);
	}

	handleClearMember = () => {
		Events.raise.ClearMember(this.props.machineContext);
	}

	handleMenuStateChange = (open: boolean) => {
		if (open && this.props.vm.memberSearchResult) {
			Events.raise.ShowResults(this.props.machineContext);
		} else {
			Events.raise.HideResults(this.props.machineContext);
		}
	}
}
