import * as React from 'react';
import { computed } from 'mobx';
import { ValidationMessage } from '../../../components/form-controls/validation-message';
import { SvgWrapper } from '../../../components/svg-wrapper';
import { Autocomplete, AutocompleteStore } from '../../../components/autocomplete';
import { observer } from 'mobx-react';
import {
	ScheduleImportFundViewModel,
	ModelMetadata,
	ScheduleImportFundMatchStatus,
} from '../../schedule-import-generated';
import { FormControlCheckbox } from '../../../components/form-controls/form-control-checkbox/form-control-checkbox';
import { TooltipComponent } from '../../../components/tooltip-component';
import { ValidatableControl } from '../../../components/form-controls/validatable-control';

export interface IFundMappingRowProps {
	importedFund: ScheduleImportFundViewModel;
	pushpayFundStore: AutocompleteStore;
	payerLabel: string;
	updateAskDonorForFundChecked: (isChecked: boolean) => void;
}

@observer
export class FundMappingRow extends React.Component<IFundMappingRowProps> {
	render() {

		const {
			importedFund,
			pushpayFundStore,
			payerLabel,
			updateAskDonorForFundChecked,
		} = this.props;

		const elementName = `FundMappingRow_${importedFund.ImportFundId}`;
		return (
			<div className={`transaction-import-fund-row ${pushpayFundStore.selectedItem ? 'matched' : ''}`}>
				<div className="col-sm-4">
					<label className="import-fund-label">{importedFund.ImportFundName}</label>
					<div className="ask-donor-for-fund-container">
						<FormControlCheckbox
							containerClassNames="checkbox-green"
							value={this.isAskDonorForFundChecked}
							onChangeHandler={updateAskDonorForFundChecked}
							text={`Ask ${payerLabel} to select a new fund.`} />
						<TooltipComponent
							message={`Asking ${payerLabel} to choose a new fund will force them to select a new fund when setting up a new recurring schedule`}
							className="ask-donor-for-fund-tooltip"
							placement="top"/>
					</div>
				</div>
				<div className="col-sm-5">
					<Autocomplete
						emptyText="- Select Pushpay fund -" floatingLabelText="Selected Pushpay fund"
						autocompleteStore={pushpayFundStore}
						disabled={this.isAskDonorForFundChecked} />

					<ValidatableControl
						elementName={elementName}
						validationRules={this.isAskDonorForFundChecked ? null : ModelMetadata.ScheduleImportFundViewModel.PushpayFundId.validationRules}>

						<input data-validate="true" type="hidden" name={elementName} value={pushpayFundStore.selectedValue} />
					</ValidatableControl>
				</div>
				<div className="col-sm-3 import-fund-validation-container">
					{ this.renderValidationMessage(elementName) }
				</div>
			</div>
		);
	}

	private renderValidationMessage(elementName: string) {
		const {pushpayFundStore} = this.props;

		if (this.isAskDonorForFundChecked === false) {
			if (pushpayFundStore.selectedItem) {
				return (
					<div className="match-success">
						<SvgWrapper svg="alert-icon-success" className="svg icon match-success-icon" />
						<span className="match-success-message">You have matched your funds</span>
					</div>
				);
			} else {
				return (<ValidationMessage elementName={elementName} />);
			}
		}
	}

	@computed
	get isAskDonorForFundChecked() {
		return this.props.importedFund.FundMatchStatus === ScheduleImportFundMatchStatus.AskDonorToChoose;
	}
}

