import { ICancellablePromise } from '../../../utils/cancellable-promise';
import { BusyTracker } from '../_common';
import { runInAction } from 'mobx';

abstract class CommandBase<TServerResult> {
	trackers: BusyTracker[] = [];
	promise: ICancellablePromise<TServerResult>;
	commandName: string = this['constructor']['name'];
	protected cancelled = false;


	/**
	 * Apply the changes to the viewmodel pre-emptively
	 */
	protected abstract preApply(): void;

	/**
	 * Send the command to the server
	 */
	protected abstract send(): ICancellablePromise<TServerResult>;

	/**
	 * If the server send succeeded, apply any further data to the viewmodels.
	 * Using this is better that .thenning your own promise because this will be wrapped in a mobx action
	 */
	protected abstract postApply(result: TServerResult);

	/**
	 * If the server send failed, undo what the preview tried to do
	 */
	protected abstract undoPreApply(error): void;

	execute() {
		if (this.promise) {
			throw new Error('can\'t reuse commands');
		}
		runInAction(`${this.commandName}.preview`, () => this.preApply());

		if (this.cancelled) {
			//preApply cancelled us, don't continue
			return;
		}


		this.promise = this.send().then(
			x => {
				runInAction(`${this.commandName}.applyResult`, () => this.cancelled || this.postApply(x));
				return x;
			},
			e => {
				runInAction(`${this.commandName}.undoPreview`, () => this.undoPreApply(e));
				return Promise.reject(e);
			});

		this.trackers.forEach(x => x.trackPromise(this.promise));

		return this.promise;
	}

	cancel() {
		this.trackers.forEach(x => x.stopTracking(this.promise));
		if (this.promise) {
			this.promise.cancel();
		}

		this.cancelled = true;
		this.undoPreApply(null);
	}
}

export default CommandBase;
