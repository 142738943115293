import * as React from 'react';
import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import { CommunityMemberApiConfig, CommunityMemberDataServiceAction } from '../community-member-data-service';
import { IDataServiceActionSubscriber, IApiConfig, DataServiceActionSubscriberFactory } from '../../utils/data-service';
import { IPersonalDetails } from '../view-models/personal-details';
import { MemberFormViewModel } from '../view-models/member-form';
import { MemberModalWrapper } from '../components/member-modal-wrapper';
import { ModalDialogCommander, StandardErrorMessage, StandardErrorTitle } from '../../components/modal-dialog-commander';
import { Models as GeneratedModels } from '../community-member-generated';
import { PostError } from '../../utils/ajax-client';
import PersonalDetails from '../view-models/personal-details';

export type CommunityMemberActionSubscriberType = IDataServiceActionSubscriber<CommunityMemberApiConfig, 'updateCommunityMember'>;

export interface IEditMemberFormProps {
	editMemberActionSubscriberFactory?: DataServiceActionSubscriberFactory<IApiConfig, keyof IApiConfig['actions']>;
	memberId: number;
	details: IPersonalDetails;
	onCommunityMemberUpdated: (model: GeneratedModels.CommunityMemberModel, applyAccounts: boolean) => void;
	enableFeatureOrganizationalGiving: boolean;
}

export class EditMemberFormViewModel extends MemberFormViewModel {
	@observable editMemberActionSubscriber: CommunityMemberActionSubscriberType;

	private onCommunityMemberUpdated: (member: GeneratedModels.CommunityMemberModel, applyAccounts: boolean) => void;

	constructor(editMemberDataServiceActionSubscriberFactory: (action) => CommunityMemberActionSubscriberType) {
		super();
		this.editMemberActionSubscriber = editMemberDataServiceActionSubscriberFactory((action) => {
			this.subscribeToEditCommunityMember(action);
		});
	}

	@action
	editCommunityMember = () => {
		this.editMemberActionSubscriber.initRequest({ model: this.request });
	}

	@action
	setOnCommunityMemberUpdated = (onCommunityMemberUpdated: (member: GeneratedModels.CommunityMemberModel, applyAccounts: boolean) => void) => {
		this.onCommunityMemberUpdated = onCommunityMemberUpdated;
	}

	@action
	updateCommunityMember = (communityMember: GeneratedModels.CommunityMemberModel) => {
		this.onCommunityMemberUpdated(communityMember, false);
	}

	@action
	subscribeToEditCommunityMember = (action: CommunityMemberDataServiceAction) => {
		switch(action.type) {
			case 'request_success':
				this.updateCommunityMember(action.response);
				ModalDialogCommander.forceCloseCurrent();
				return;
			case 'request_error':
				const error = action.error as PostError;
				if (error.conflict) {
					if (error.updatedRecordForConflict) {
						this.updateCommunityMember(error.updatedRecordForConflict);
					}
					this.errorSummaryTitle = ' ';
					this.errorMessages = ['Your changes could not be saved as another user was editing this community member at the same time. Please reload the page and try again.'];
					if (error.updatedRecordForConflict) {
						let details = new PersonalDetails();
						details.importValues(error.updatedRecordForConflict);
						this.setRequest(this.request.CommunityMemberId, details);
					}
				} else if (error.validationErrors) {
					this.errorSummaryTitle = '';
					this.validationErrors = error.validationErrors;
					this.errorMessages = Object.getOwnPropertyNames(error.validationErrors).map(x => error.validationErrors[x]);
				} else {
					this.errorSummaryTitle = StandardErrorTitle;
					this.errorMessages = [ StandardErrorMessage ];
				}
				return;
		}
	}

	@action
	setRequest(memberId: number, details: IPersonalDetails) {
		this.request = {
			Address: Object.assign({}, details.Address),
			EmailAddress: details.EmailAddress,
			FirstName: details.FirstName,
			LastName: details.LastName,
			CommunityMemberId: memberId,
			LockVersion: details.LockVersion,
			MobileNumber: details.MobileNumber,
			CommunityMemberType: details.CommunityMemberType,
		} as GeneratedModels.EditCommunityMemberRequestModel;
	}

	@computed
	get isProcessingRequest() {
		return this.editMemberActionSubscriber.isProcessingRequest;
	}
}

@observer
export class EditMemberFormContainer extends React.Component<IEditMemberFormProps, {}> {
	private vm: EditMemberFormViewModel;

	UNSAFE_componentWillMount() {
		const { editMemberActionSubscriberFactory, memberId, details, onCommunityMemberUpdated } = this.props;
		this.vm = new EditMemberFormViewModel(editMemberActionSubscriberFactory);
		this.vm.setRequest(memberId, details);
		this.vm.setOnCommunityMemberUpdated(onCommunityMemberUpdated);
	}

	UNSAFE_componentWillReceiveProps(nextProps: IEditMemberFormProps) {
		this.vm.setRequest(nextProps.memberId, nextProps.details);
		this.vm.setOnCommunityMemberUpdated(nextProps.onCommunityMemberUpdated);
	}

	render() {
		const { editCommunityMember } = this.vm;
		return(
			<MemberModalWrapper memberFormViewModel={this.vm} submitHandler={editCommunityMember}>
				{this.props.children}
			</MemberModalWrapper>
		);
	}
}
