import * as React from 'react';

class ErrorRow {
	RowNumber: number;
	ErrorMessage: string;
	MessagePrefix?: string;
}

export interface IImportFileErrorGridProps {
	title?: string;
	description: string;
	messageHeader: string;
	rows: ErrorRow[];
}

export class ImportFileErrorGrid extends React.Component<IImportFileErrorGridProps> {
	render() {
		const {title, description, rows, messageHeader} = this.props;
		if (rows && rows.length > 0) {
			return (
				<div className="transaction-import-summary-grid no-vertical-margin">
					<div className="summary-grid-heading">
						{title && <div className="summary-grid-title">{title}</div>}
						<p>{description}</p>
					</div>

					<div className="row summary-grid-header hidden-xs">
						<div className="col-sm-3">Rows</div>
						<div className="col-sm-9">{messageHeader}</div>
					</div>
					{rows.map((row, index) => (
						<div className="row summary-grid-row" key={index}>
							<div className="col-sm-3"><span className="summary-label-responsive">Row {row.RowNumber}</span></div>
							<div className="col-sm-9">{row.MessagePrefix ? <b>{`${row.MessagePrefix}: `}</b> : ''}{row.ErrorMessage}</div>
						</div>
					))}
				</div>
			);
		} else {
			return null;
		}
	}
}
