import * as React from 'react';
import { observer } from 'mobx-react';
import { ModalDialog } from '../../../../components/modal-dialog';

export interface IBatchEntryCloseBatchErrorProps {
	totalAmount: number;
	expectedTotalAmount: number;
	totalCount: number;
	expectedTotalCount: number;
	paymentNoun: string;
	paymentNounPlural: string;
}

@observer
export class BatchEntryCloseBatchError extends React.Component<IBatchEntryCloseBatchErrorProps> {
	render() {
		const buttons = [{ label: 'Go back', dismiss: true, isPrimary: false }];

		return(
			<ModalDialog buttons={buttons} title={this.errorTitle()}>
				<div className="modal-text">
					{this.errorMessage()}
				</div>
			</ModalDialog>
		);
	}

	private errorTitle = () => {
		const { totalAmount, expectedTotalAmount, paymentNoun, paymentNounPlural } = this.props;
		return totalAmount !== expectedTotalAmount
			? `Total ${paymentNoun} amount doesn't match batch total.`
			: `Total number of ${paymentNounPlural} doesn't match batch ${paymentNounPlural}`;
	}

	private errorMessage = () => {
		const { totalAmount, expectedTotalAmount, totalCount, expectedTotalCount, paymentNounPlural } = this.props;

		if (totalAmount !== expectedTotalAmount && totalCount !== expectedTotalCount) {
			// tslint:disable-next-line:max-line-length
			return `The batch total and the number of ${paymentNounPlural} don’t match with the estimated total and the number. Please check all ${paymentNounPlural} before you complete this batch.`;
		}

		if (totalAmount > expectedTotalAmount) {
			return `Your batch total exceeds the estimated total. Please check all ${paymentNounPlural} before you complete this batch.`;
		}
		if (expectedTotalAmount > totalAmount) {
			return `Your batch total is less than the estimated total. Please check all ${paymentNounPlural} before you complete this batch.`;
		}
		if (totalCount > expectedTotalCount) {
			return `You have entered more ${paymentNounPlural} than the estimated number of ${paymentNounPlural}. Please check all ${paymentNounPlural} before you complete this batch`;
		}
		if (expectedTotalCount > totalCount) {
			return `You have entered less ${paymentNounPlural} than the estimated number of ${paymentNounPlural}. Please check all ${paymentNounPlural} before you complete this batch.`;
		}

		return null;
	}
}
