import CommandBase from './command-base';
import { ICancellablePromise } from '../../../utils/cancellable-promise';
import * as Models from '../../models/index';
import * as Util from '../../util';
import Member from '../member';
import Account from '../account';

export default class MoveAccountCommand extends CommandBase<Models.GroupPersonWithCommunityMemberResponseModel> {

	/**
	 * Move the account between viewmodels
	 */
	static moveAccount(account: Account, oldMember: Member, newMember: Member) {
		Util.arrayRemove(oldMember.accounts, account);
		newMember.insertAccounts(account);
	}

	constructor(public account: Account, public oldMember: Member, public newMember: Member) {
		super();
		this.trackers.push(newMember.busyTracker);
	}

	preApply(): void {
		MoveAccountCommand.moveAccount(this.account, this.oldMember, this.newMember);
		this.account.droppedAnimation.trigger();
		this.newMember.expanded = true;

		if (this.oldMember.accounts.length === 0) {
			Util.arrayRemove(this.oldMember.parent.currentMembers, this.oldMember);
		}
	}

	send(): ICancellablePromise<Models.GroupPersonWithCommunityMemberResponseModel> {
		if (!this.oldMember.id) {
			//unexpected error so throw instead of promise.reject
			throw new Error('oldMember has no id yet');
		}
		if (!this.newMember.id) {
			//unexpected error so throw instead of promise.reject
			throw new Error('newMember has no id yet');
		}

		return this.account.parent.parent.dataService.moveAccount({
			NewCommunityMemberId: this.newMember.id,
			OldCommunityMemberId: this.oldMember.id,
			PersonId: this.account.id
		});
	}

	postApply(result: Models.GroupPersonWithCommunityMemberResponseModel) {
		this.newMember.applyModel(result.TargetCommunityMember, false);
	}

	/**
	 * move the account back to the old members
	 */
	undoPreApply(error): void {
		if (this.oldMember.accounts.length === 0) {
			this.newMember.parent.insertMembers(this.oldMember);
		}
		MoveAccountCommand.moveAccount(this.account, this.newMember, this.oldMember);
	}

}
