import * as React from 'react';
import { observer } from 'mobx-react';
import { Fragment } from '../../../Shared/components/fragment';
import { AlertControl } from '../../transaction-import/components/alert-control';
import { ImportStepDescription } from '../../transaction-import/components/import-step-description';
import { ScheduleImportStepViewModel } from './schedule-import-step-view-model';
import { StepStatusIndicator } from '../../components/step-status';

@observer
export class ScheduleImportStep extends React.Component<{ vm: ScheduleImportStepViewModel, headerButton?: React.ReactElement<any>, helpLink?: React.ReactElement<any> }> {
	render() {
		const {
			vm:{
				alertProps,
				currentStep,
				stepTitle,
				stepDescription,
				scheduleImportSteps
			},
			headerButton,
			helpLink
		} = this.props;

		return (
			<Fragment>
				<AlertControl {...alertProps} />
				<StepStatusIndicator
					steps={scheduleImportSteps}
					currentStep={currentStep} />
				{stepTitle && <ImportStepDescription title={stepTitle} description={stepDescription} headerButton={headerButton} helpLink={helpLink}/>}
				{this.props.children}
			</Fragment>
		);
	}
}
