import * as React from 'react';
import { observer } from 'mobx-react';
import { getElementId } from './form-control-utils';
import { Tooltip, TooltipContent } from '../tooltip/tooltip';
import { SvgWrapper } from '../svg-wrapper';
import { IFormControlLabelledTooltipProps } from './form-control-labelled';
import { classNames } from '../../../Shared/utils/classnames';
export interface IFormControlLabelProps {
	label: string;
	elementName: string;
	uniqueSuffix?: string;
	tooltipOptions?: IFormControlLabelledTooltipProps;
	overridingClass?: string;
	additionalClass?: string;
	screenReaderOnly?: boolean;
}

@observer
export class FormControlLabel extends React.Component<IFormControlLabelProps> {
	render() {
		const { label, tooltipOptions, elementName, uniqueSuffix, overridingClass, additionalClass, screenReaderOnly } =
			this.props;
		const className = (overridingClass ? overridingClass : 'form-control-label') + ' ' + additionalClass;
		return (
			<label
				className={classNames(className, screenReaderOnly && 'sr-only')}
				htmlFor={getElementId(elementName, uniqueSuffix)}
			>
				{label}
				{tooltipOptions && <FormControlLabelledTooltip {...tooltipOptions} />}
			</label>
		);
	}
}

@observer
class FormControlLabelledTooltip extends React.Component<IFormControlLabelledTooltipProps, { visible: boolean }> {
	constructor(props) {
		super(props);
		this.state = { visible: false };
	}

	render() {
		const { className, placement, message, tooltipContentClassName } = this.props;
		return (
			<Tooltip
				visible={this.state.visible}
				onVisibleChange={this.handleVisibleChange}
				tooltipClassName={className}
				placement={placement}
				label="more information"
				tooltipContentClassName={tooltipContentClassName}
			>
				<SvgWrapper svg="icon-info" className={`icon tooltip-icon`} />
				<TooltipContent>
					<div>{message}</div>
				</TooltipContent>
			</Tooltip>
		);
	}

	private handleVisibleChange = (visible: boolean) => {
		this.setState({ visible: visible });
	};
}
