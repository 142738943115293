import { NodeListHelper } from '../helpers/nodelisthelper';

export class DescriptionUpdateManager {

	private updaters: DescriptionUpdater[];

	constructor() {
		let inputs = NodeListHelper.toArray(document.querySelectorAll('[data-item-description-source]'));

		this.updaters = inputs.map(input => {
			let target = (input as HTMLElement).attributes.getNamedItem('data-item-description-source').value;
			return new DescriptionUpdater(input as HTMLElement, target);
		});
	}
}

export class DescriptionUpdater {

	private sourceElement: HTMLElement;
	private targetElement: HTMLElement;

	constructor(source: HTMLElement, target: string) {
		this.sourceElement = source;
		this.targetElement = document.querySelector(`[data-item-description-target="${target}"]`) as HTMLElement;
		let radioButtons = NodeListHelper.toArray(this.sourceElement.querySelectorAll('input[type="radio"]'));

		radioButtons.map(item => {
			let radioButton: HTMLInputElement = item as HTMLInputElement;
			radioButton.addEventListener('change', (e: Event) => this.UpdateDescription(e.srcElement as HTMLInputElement));
			if(radioButton.checked) {
				this.UpdateDescription(radioButton);
			}
		});
	}

	private UpdateDescription(radioButton: HTMLInputElement) {
		if(radioButton.checked) {
			this.targetElement.innerHTML = radioButton.attributes.getNamedItem('data-description').value;
			let tooltipAttribute = radioButton.attributes.getNamedItem('data-tooltip');
			if(tooltipAttribute && tooltipAttribute.value) {
				this.targetElement.title = tooltipAttribute.value;
			}
		}
	}
}

export function init() {
	// tslint:disable-next-line:no-unused-expression
	new DescriptionUpdateManager();
}
