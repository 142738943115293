import * as React from 'react';
import { Panel, PanelBody, PanelType } from '../../../components/panel';
import { FormControlLabelled, FormControlType } from '../../../components/form-controls/form-control-labelled';
import { MultiSelectItem, TransactionExportButton, ExportAction } from '../../../loggedinweb-generated';
import { MultiSelectedItems } from '../../../components/multi-selected-items';
import { observer, inject} from 'mobx-react';
import { Button } from '../../../components/button';
import { ScheduleDetailsUserActionCreator } from '../../pages/schedules-details/schedule-details-user-actions';
import { SvgWrapper } from '../../../../Shared/components/svg-wrapper';
import { ExportDropDown } from '../../../components/export-dropdown/export-dropdown';

import * as styles from './styles.less';

export interface IScheduleDetailsFilterProps {
	statusOptions: MultiSelectItem[];
	statusSelectedValues: string[];
	listingOptions: MultiSelectItem[];
	listingSelectedValues: string[];
	fundsOptions: MultiSelectItem[];
	fundsSelectedValues: string[];
	userActionCreator?: ScheduleDetailsUserActionCreator;
	exportButtons: TransactionExportButton[];
}

export interface IScheduleDetailsFilterState {
	selectedStatus: string[];
	selectedListings: string[];
	selectedFunds: string[];
}

@inject('userActionCreator')
@observer
export class ScheduleDetailsFilter extends React.Component<IScheduleDetailsFilterProps, IScheduleDetailsFilterState> {
	constructor(props: IScheduleDetailsFilterProps) {
		super(props);
		this.state = {
			selectedStatus: props.statusSelectedValues,
			selectedListings: props.listingSelectedValues,
			selectedFunds: props.fundsSelectedValues,
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps: IScheduleDetailsFilterProps) {
		if(this.props.statusSelectedValues !== nextProps.statusSelectedValues ||
				this.props.listingSelectedValues !== nextProps.listingSelectedValues ||
				this.props.fundsSelectedValues !== nextProps.fundsSelectedValues) {

			this.setState({
				selectedStatus: nextProps.statusSelectedValues,
				selectedListings: nextProps.listingSelectedValues,
				selectedFunds: nextProps.fundsSelectedValues,
			});
			this.props.userActionCreator.UpdateFilters(nextProps.statusSelectedValues, nextProps.listingSelectedValues, nextProps.fundsSelectedValues, false);
		}
	}

	render() {
		const { statusOptions, listingOptions, fundsOptions, exportButtons } = this.props;
		const { selectedFunds, selectedListings, selectedStatus } = this.state;

		return (
			<Panel panelType={PanelType.HIGHLIGHT}>
				<PanelBody>
					<div className={styles.inputs}>
						<div className={styles.filter}>
							<FormControlLabelled
								label="Schedule status:"
								formControlProps={ {
									name: 'Status',
									formControlType: FormControlType.MultiSelect,
									items: statusOptions,
									onChangeHandler: this.statusChangeHandler,
									selectedValues: selectedStatus,
								} } />
						</div>
						<div className={styles.filter}>
							<FormControlLabelled
								label="Listings:"
								formControlProps={
									{
										name: 'Listings',
										formControlType: FormControlType.MultiSelect,
										items: listingOptions,
										onChangeHandler: this.listingsChangeHandler,
										selectedValues: selectedListings,
									}
								}
							/>
						</div>
						<div className={styles.filter}>
							<FormControlLabelled
								label="Funds:"
								formControlProps={ {
									name: 'Funds',
									formControlType: FormControlType.MultiSelect,
									items: fundsOptions,
									onChangeHandler: this.fundsChangeHandler,
									selectedValues: selectedFunds,
								} } />
						</div>
					</div>
					<div className={styles.filterTitle}>
						<h2 className={styles.filterHeading}>Selected filters</h2>
						<button className={styles.resetButton} onClick={this.clearFiltersHandler}>
							<SvgWrapper svg="close-cross" className={styles.resetSvg} />
							<span className={styles.resetButtonText}>Reset</span>
						</button>
					</div>
					<div className={styles.selectedFilters}>
						<MultiSelectedItems options={statusOptions} selectedValues={selectedStatus}
							title="Schedule status" className={styles.selectedFilterItem} valuesChanged={this.statusChangeHandler} />
						<MultiSelectedItems options={listingOptions} selectedValues={selectedListings}
							title="Listings" className={styles.selectedFilterItem} valuesChanged={this.listingsChangeHandler} />
						<MultiSelectedItems options={fundsOptions} selectedValues={selectedFunds}
							title="Funds" className={styles.selectedFilterItem} valuesChanged={this.fundsChangeHandler} />
					</div>
					<div className={styles.buttonGroup}>
						<Button className="btn btn-primary" onClick={this.applyFilters}>Apply filter</Button>
						<ExportDropDown exportButtons={exportButtons} handleExport={this.handleExport} />
					</div>
				</PanelBody>
			</Panel>
		);
	}

	private handleExport = (action: ExportAction) => {
		const { selectedStatus, selectedListings, selectedFunds } = this.state;
		this.props.userActionCreator.ExportSchedules(action, selectedStatus, selectedListings, selectedFunds);
	}

	private statusChangeHandler = (selectedStatus: string[]) => {
		this.setState({
			selectedStatus
		});
	}

	private listingsChangeHandler = (selectedListings: string[]) => {
		this.setState({
			selectedListings
		});
	}

	private fundsChangeHandler = (selectedFunds: string[]) => {
		this.setState({
			selectedFunds
		});
	}

	private clearFiltersHandler = () => {
		const { statusSelectedValues, listingSelectedValues, fundsSelectedValues } = this.props;
		if(statusSelectedValues.length > 0 || listingSelectedValues.length > 0 || fundsSelectedValues.length > 0) {
			this.statusChangeHandler([]);
			this.listingsChangeHandler([]);
			this.fundsChangeHandler([]);
			this.props.userActionCreator.UpdateFilters([], [], [], true);
		}
	}

	private applyFilters = () => {
		const { selectedStatus, selectedListings, selectedFunds } = this.state;
		this.props.userActionCreator.UpdateFilters(selectedStatus, selectedListings, selectedFunds, true);
	}
}
