import * as React from 'react';
import { getUniqueElementId } from '../../utils/form-control-utils';
import { ValidationMessage } from '../validation/validation-message';
import { classNames } from '../../utils/classnames';

export interface IFormFieldGroupProps {
	className?: string;
	error?: string;
	containerClassName?: string;
}

export class FormFieldGroup extends React.Component<IFormFieldGroupProps> {
	private readonly formControlId = getUniqueElementId();

	render() {
		const { className, error, containerClassName, children } = this.props;

		return (
			<fieldset className={classNames('form-group', containerClassName)} data-field-invalid={!!error || null}>
				<div className={className}>
					{children}
				</div>
				{error && <ValidationMessage id={this.formControlId} errorMessage={error} />}
			</fieldset>
		);
	}
}
