import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { CustomSelect, CustomSelectItem, getPrefixedCustomSelectId } from './custom-select';
import { getUniqueElementId } from './form-controls/form-control-utils';
import { isFunction } from '../utils/is-function';
import { classNames } from '../../Shared/utils/classnames';
import { Fragment } from '../../Shared/components/fragment';

export interface ICampusSelectorWithLabelProps {
	selectedCampusKey: string;
	availableCampus: { key: string; campusName: string }[];
	onChange: (campusKey: string) => void;
	acceptanceTestTargetId?: string;
	isUnavailable?: boolean;
	className?: string;
	componentId?: string;
	singleLine?: boolean;
	childrenClassName?: string;
	labelForCampusSelection?: string;
	isDisabled?: boolean;
}

export class CampusSelectorWithLabel extends React.Component<ICampusSelectorWithLabelProps, { open: boolean }> {
	private componentId = this.props.componentId || getUniqueElementId();
	private campusSelector: CampusSelector;
	constructor(props) {
		super(props);
		this.state = { open: false };
	}
	render() {
		const {
			className,
			children,
			availableCampus,
			singleLine = true,
			childrenClassName = 'col-sm-4',
			labelForCampusSelection = 'Select a campus',
			...rest
		} = this.props;
		return (
			<div
				className={classNames(
					'row',
					singleLine ? 'campus-selector-container' : 'campus-selector-multiline-container',
					className
				)}
			>
				<Fragment>
					<div
						className={classNames(
							childrenClassName,
							'campus-selector',
							!singleLine && 'campus-selector-multiline-child'
						)}
					>
						<label
							onClick={this.handleLabelClick}
							htmlFor={getPrefixedCustomSelectId(this.componentId)}
							className={classNames(
								'campus-selector-label',
							)}
						>
							{labelForCampusSelection}
						</label>
						<CampusSelector
							componentId={this.componentId}
							availableCampus={availableCampus}
							isDisabled={availableCampus.length <= 1}
							{...rest}
							ref={(campusSelector) => {
								this.campusSelector = campusSelector;
							}}
						/>
					</div>
				</Fragment>
			</div>
		);
	}

	private handleLabelClick = (event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault();
		(ReactDOM.findDOMNode(this.campusSelector).firstChild as HTMLElement).focus();
	};
}

interface ICampusSelectorProps {
	selectedCampusKey: string;
	availableCampus: { key: string; campusName: string }[];
	onChange: (campusId: string) => void;
	acceptanceTestTargetId?: string;
	componentId: string;
	isUnavailable?: boolean;
	isDisabled?: boolean;
}

class CampusSelector extends React.Component<ICampusSelectorProps, { open: boolean }> {
	state = { open: false };

	render() {
		const { selectedCampusKey, componentId, isUnavailable, isDisabled } = this.props;
		return (
			<CustomSelect
				componentId={componentId}
				open={this.state.open}
				selectedItem={selectedCampusKey}
				onItemSelected={this.handleItemSelected}
				onRequestOpen={this.handleRequestOpen}
				onRequestClose={this.handleRequestClose}
				acceptanceTestTargetId={this.props.acceptanceTestTargetId}
				isUnavailable={isUnavailable}
				isDisabled={isDisabled}
			>
				{this.props.availableCampus.map((x) => (
					<CustomSelectItem key={x.key} value={x.key} displayLabel={x.campusName} />
				))}
			</CustomSelect>
		);
	}

	handleItemSelected = (key: string) => {
		const { onChange } = this.props;
		this.setState({ open: false });

		if (isFunction(onChange)) {
			onChange(key);
		}
	};

	handleRequestOpen = () => {
		this.setState({ open: true });
	};

	handleRequestClose = () => {
		this.setState({ open: false });
	};
}
