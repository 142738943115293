import * as React from 'react';
import { AllocationOverviewPanel } from './allocation-overview-panel';
import { AllocationViewModel } from './allocation-view-model';
import { AllocationGrid } from './allocation-grid';
import { AllocationGridComplete } from './allocation-grid-complete';
import { observer, Provider } from 'mobx-react';
import { Models } from '../check-deposit-generated';
import UiBatchStatus = Models.UiBatchStatus;
import { ModalDialogCommander } from '../../components/modal-dialog-commander';
import { AllocationCompleteDialog } from './allocation-complete-dialog';
import { ExportDropDown } from '../../components/export-dropdown/export-dropdown';
import { ExportAction } from '../../loggedinweb-generated';
import { getCheckDepositExportDataService } from '../check-deposit-non-ajax-data-service';
import { BatchEntryFundTotals } from '../../components/fund-totals/batch-entry-fund-totals';


@observer
export class AllocationView extends React.Component<{ store: AllocationViewModel }, {}> {
	render() {
		const { BatchDetailsViewModel,
			dataService,
			CheckDetailsViewModel,
			applySpeedSettings,
			TransactionDetailsViewModel,
			isLoadingLastPaymentDetails,
		} = this.props.store;

		const { BatchStatus, BatchName, BatchListUrl, PayButtonLabel } = BatchDetailsViewModel.BatchDetails;
		const allocationComplete = CheckDetailsViewModel
			&& CheckDetailsViewModel.CheckDetails
			&& CheckDetailsViewModel.IncompleteCount === 0
			&& !BatchDetailsViewModel.isProcessingSpeedSettingsRequest;
		const isCheckDetailsOpen = CheckDetailsViewModel && CheckDetailsViewModel.ExpandedCheckStore !== null;

		const callToAction = 'Complete allocation';
		const nounPluralSentenceCase = 'Transactions';
		const completeButton = allocationComplete
			? <button type="button" className="cd-allocation-complete-button btn btn-default" onClick={this.onClickCompleteAllocation}>{callToAction}</button>
			: (<button type="button" className="cd-allocation-complete-button btn btn-default" title="All checks must be allocated before a batch can be completed" disabled>
				{callToAction}
			</button>);

		const exportButtons = BatchDetailsViewModel.BatchDetails.ExportButtons;

		return (
			<Provider dataService={dataService}>
				<div>
					<div className="page-head">
						<header className="cd-allocation-header">
							<h1>
								<span className="cd-allocation-batch-name">{BatchName}</span>
								{BatchStatus === UiBatchStatus.ReadyToAllocate ? <span className="label label-inprogress">READY TO ALLOCATE</span> : null}
								{TransactionDetailsViewModel && <span className="label label-success">COMPLETED</span>}
							</h1>
							{TransactionDetailsViewModel
								? <ExportButton exportButtons={exportButtons} handleExportBatch={this.handleExportBatch} />
								: completeButton}
						</header>
					</div>
					<AllocationOverviewPanel
						store={BatchDetailsViewModel}
						applySpeedSettings={applySpeedSettings}
						isCheckDetailsOpen={isCheckDetailsOpen} />

	
					{TransactionDetailsViewModel ? (
						<React.Fragment>
							<BatchEntryFundTotals
								showFundTotals={TransactionDetailsViewModel.showFundTotals}
								fundTotals={ TransactionDetailsViewModel.fundTotals }
								nounPluralSentenceCase={nounPluralSentenceCase}
							/>
							<AllocationGridComplete store={TransactionDetailsViewModel} title={nounPluralSentenceCase}/>
						</React.Fragment>
					) : (
						<AllocationGrid
							store={CheckDetailsViewModel}
							getPreviousPaymentDetailsInProgress={
								isLoadingLastPaymentDetails
							}
							payButtonLabel={PayButtonLabel}
						/>
					)}
					<footer className="cd-allocation-footer">
						{TransactionDetailsViewModel ? null : completeButton}
						<div className="cd-allocation-footer-left">
							<a href={BatchListUrl} className="btn btn-link arrow-hollow-left-before">Back to list</a>
						</div>
					</footer>
				</div>
			</Provider>
		);
	}

	onClickCompleteAllocation = () => {
		ModalDialogCommander.showReactDialog(<AllocationCompleteDialog store={this.props.store} />);
	}

	private handleExportBatch = (action: ExportAction) => {
		const { BatchId } = this.props.store.BatchDetailsViewModel.BatchDetails;
		getCheckDepositExportDataService().batchExport({batchId: BatchId, button: action});
	}
}

@observer
class ExportButton extends React.Component<{ exportButtons: Models.TransactionExportButton[], handleExportBatch: (action: ExportAction) => void }> {
	render() {
		const { exportButtons, handleExportBatch } = this.props;
		return (
			<div className="relative">
				<ExportDropDown exportButtons={exportButtons} handleExport={handleExportBatch}/>
			</div>
		);
	}
}
