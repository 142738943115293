import {
	EventTimeCreateRequest,
	SettingsEventTimeViewModel,
	EventTimeEditRequest,
	EventTimeViewModel,
} from '../../../settings/settings-generated';

export namespace ServiceTimeUserAction {
	export class LaunchAddServiceTimeDialog { }

	export class AddServiceTime {
		constructor(public eventTimeCreateRequest: EventTimeCreateRequest) { }
	}

	export class LaunchEditServiceTimeDialog {
		constructor(public eventTime: SettingsEventTimeViewModel | EventTimeEditRequest) { }
	}

	export class EditServiceTime {
		constructor(public eventTimeEditRequest: EventTimeEditRequest) { }
	}

	export class EditServiceTimeConfirmed {
		constructor(public eventTimeEditRequest: EventTimeEditRequest) { }
	}

	export class LaunchDeleteServiceTime {
		constructor(public eventTime: EventTimeViewModel) { }
	}

	export class DeleteServiceTime {
		constructor(public id: number) { }
	}

	export class ActivateServiceTime {
		constructor(public id: number, public listingId: number) { }
	}

	export class DeactivateServiceTime {
		constructor(public id: number, public listingId: number) { }
	}
}

export type ServiceTimeUserAction = typeof ServiceTimeUserAction[keyof typeof ServiceTimeUserAction]['prototype'];
