import {observable, computed, action} from 'mobx';
import {BusyTracker} from './_common';
import CommandBase from './commands/command-base';
import UngroupCommand from './commands/ungroup-command';
import SearchCommand from './commands/search-command';
import NextPageCommand from './commands/next-page-command';
import Account from './account';
import Member from './member';
import * as Sorting from './sorting';
import * as Models from '../models/index';
import { Models as GeneratedModels } from '../community-member-generated';

import CommunityMemberSearchOrder = GeneratedModels.CommunityMemberSearchOrder;

export class MemberSorter extends Sorting.Sorter<Member> {
	lastName: Sorting.SortField<Member>;
	firstName: Sorting.SortField<Member>;
	email: Sorting.SortField<Member>;
	mobile: Sorting.SortField<Member>;
	lastActivity: Sorting.SortField<Member>;

	constructor(sortChanged: () => void) {
		super(sortChanged);
		this.email = this.createForString(CommunityMemberSearchOrder[CommunityMemberSearchOrder.EmailAddress], x => x.details.EmailAddress);
		this.firstName = this.createForString(CommunityMemberSearchOrder[CommunityMemberSearchOrder.FirstName], x => x.details.FirstName);
		this.lastName = this.createForString(CommunityMemberSearchOrder[CommunityMemberSearchOrder.LastName], x => x.details.LastName);
		this.mobile = this.createForString(CommunityMemberSearchOrder[CommunityMemberSearchOrder.MobileNumber], x => x.details.MobileNumber);
		this.lastActivity = this.createForNumber(CommunityMemberSearchOrder[CommunityMemberSearchOrder.LastPaymentOn], x => +x.details.LastPaymentOnLocal);
	}
}

export default class Main {
	@observable currentMembers: Member[] = [];
	@observable searchTerms = '';
	@observable excludeResultsWithEmail = false;
	@observable excludeResultsWithAddress = false;
	@observable morePagesAvailable = false;
	@observable statementToPreviewMemberId: number = 0;
	@observable statementToPreviewRunId: number = 0;
	@observable filterMessage = null;
	@observable hasLongList = false;
	@observable enableFeatureOrganizationalGiving = false;
	@observable disableCommunityMemberDragAndDrop = false;
	@observable hideGivingStatementButton = false;

	sorter: MemberSorter;

	busyTracker = new BusyTracker();

	currentDataLoadCommand: CommandBase<any>;

	constructor(public dataService: Models.IDataService, public settings: GeneratedModels.InitViewModel) {
		this.sorter = new MemberSorter(() => this.newSearch());
		this.sorter.lastName.toggle();
		this.enableFeatureOrganizationalGiving = settings.EnableFeatureOrganizationalGiving;
		this.disableCommunityMemberDragAndDrop = settings.DisableCommunityMemberDragAndDrop;
		this.hideGivingStatementButton = settings.HideGivingStatementButton;
	}

	@action
	updateMessage() {
		this.hasLongList = false;
		if (this.excludeResultsWithEmail && this.excludeResultsWithAddress) {
			this.filterMessage = 'Showing members with no email address and no physical address';
		} else if (this.excludeResultsWithEmail) {
			this.filterMessage = 'Showing members with no email address';
		} else if (this.excludeResultsWithAddress) {
			this.filterMessage = 'Showing members with no physical address';
		} else if (this.searchTerms !== '') {
			this.filterMessage = 'Showing members containing "' + this.searchTerms + '"';
		} else {
			this.filterMessage = null;
		}
	}

	@action.bound
	updateExcludeResultsWithEmail(value: boolean) {
		this.excludeResultsWithEmail = value;
	}

	@action.bound
	updateExcludeResultsWithAddress(value: boolean) {
		this.excludeResultsWithAddress = value;
	}

	@action
	newSearch() {
		this.loadDataFromCommand(SearchCommand.create(this));
	}

	@action
	loadMore() {
		this.loadDataFromCommand(NextPageCommand.create(this));
		this.hasLongList = true;
	}

	insertMembers(...newMembers: Member[]) {
		let currentMembersThatArentAboutToBeAddedAgain = this.currentMembers.filter(x => !newMembers.some(n => n.id && n.id === x.id));
		this.currentMembers = currentMembersThatArentAboutToBeAddedAgain.concat(newMembers);
	}

	sortMembers() {
		this.currentMembers = this.sorter.sortLocally(this.currentMembers, x => x.id);
	}

	@action
	expandCollapseAll() {
		var s = this.expandCollapseAllState;
		this.currentMembers.forEach(x => x.expanded = !s);
	}

	@computed
	get expandCollapseAllState() {
		return this.currentMembers.some(x => x.expanded);
	}

	@action
	ungroup(account: Account): Member {
		var c = new UngroupCommand(account);
		c.execute();
		return c.newMember;
	}

	private loadDataFromCommand(c: CommandBase<any>) {
		if (this.currentDataLoadCommand) {
			this.currentDataLoadCommand.cancel();
		}
		this.currentDataLoadCommand = c;
		c.execute();
	}
}
