import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { NodeListHelper } from '../../helpers/nodelisthelper';
import { observable } from 'mobx';
import { Provider } from 'mobx-react';
import { IProviderContext } from '../stores/stores';

const componentsToLoad: Array<{ name: string, component: any }> = [];

class ComponentLoader {
	stores: Array<any>;

	constructor(stores: Array<any>) {
		this.stores = stores;
	}

	initComponentOfType(data: { name: string, component: any }) {
		NodeListHelper.toArray<HTMLElement>(document.querySelectorAll(`[data-pushpay-component=${data.name}]`)).forEach((element) => {
			this.initSingleComponent(data, element);
		});
	}

	initSingleComponent(data: { name: string, component: any}, element: HTMLElement) {
		const props = element.querySelector('script').textContent;
		const component = React.createElement(data.component, observable(props ? JSON.parse(props) : {}));
		const context: IProviderContext = { stores: this.stores };
		ReactDOM.render(<Provider {...context}>{component}</Provider>, element);
	}
}

let loader: ComponentLoader = null;

export function component<TProps>(componentName: string): <TFunction extends React.ComponentClass<TProps>>(target: TFunction) => TFunction {
	return (target) => {
		if (loader) {
			loader.initComponentOfType({ name: componentName, component: target });
		} else {
			componentsToLoad.push({ name: componentName, component: target });
		}
		return target;
	};
}

export function initAllComponents(...stores: Array<any>) {
	if (loader) {
		throw new Error('Components are already initialized');
	}

	loader = new ComponentLoader(stores);

	NodeListHelper.toArray<HTMLElement>(document.querySelectorAll(`[data-pushpay-component]`)).forEach((element) => {
		const componentName = element.getAttribute('data-pushpay-component');
		const data = componentsToLoad.find(x => x.name === componentName);
		if (data) {
			loader.initSingleComponent(data, element);
		}
	});
}
