import * as React from 'react';
import { fork, call, spawn } from 'redux-saga/effects';
import { delay, Task } from 'redux-saga';
import { ActionHandlers } from '../../../../Shared/utils/saga-utils';
import { BatchEntryUserAction } from './batch-entry-user-actions';
import {
	IVirtualTerminalSagaContext,
	sagaDataService,
	VirtualTerminalSagaDataService,
} from '../../saga/virtual-terminal-saga';
import {
	EventTimeCreateRequest,
	CreateBatchRequestModel,
	EditBatchRequestModel,
	ReopenBatchRequestModel,
	BatchViewModel,
	CreateBatchResponseModel,
	VirtualTerminalEventTimeViewModel,
	BatchEntryPaymentResponseModel,
	BatchHistoryViewModel,
	GiftEntryPaymentStatus,
} from '../../virtual-terminal-generated';
import { userActionChannel } from '../../../utils/user-action-channel';
import { userActionCreator } from '../../../utils/user-action-creator';
import { BatchEntryMainViewModel } from '../../pages/batch-entry/batch-entry-main-view-model';
import { BatchDetailsState, getEmptyBatchViewModel } from '../../components/batch-entry/batch-details/batch-entry-batch-details-view-model';
import { rewriteUrlAppendBatchIdAndUpdateBrowserHistory, rewriteUrlRemoveBatchIdAndUpdateBrowserHistory } from '../../utils/history-utils';
import { EventTimeAddEditDialogViewModel } from '../../../components/event-time/event-time-add-edit-dialog-view-model';
import { BatchEntryHistoryDialog } from '../../components/batch-entry/batch-entry-history-dialog';
import { ModalDialogCommander, StandardErrorTitle } from '../../../components/modal-dialog-commander';
import { VirtualTerminalFormSaga, IVirtualTerminalFormSagaContext } from '../../components/form/virtual-terminal-form-saga';
import { buildPaymentRequestModel } from '../../utils/build-payment-request';
import { alertController } from '../../../components/alert-controller';
import { Metrics } from '../../utils/metrics';
import { handleVirtualTerminalError, reportVirtualTerminalError } from '../../utils/virtual-terminal-error-utils';
import {
	handleLoadBatchPayment,
	handleLaunchDeleteBatchPaymentDialog,
	handleDeleteBatchPayment,
	cancelPollingForBatchPayments,
	fetchAllBatchPaymentsAndStartPolling,
} from '../../components/batch-entry-payments/batch-entry-payments-saga';
import { handleCloseBatch } from '../../components/batch-entry/close-batch/batch-entry-close-batch-saga';
import { EventTimeAddDialog } from '../../../components/event-time/event-time-add-edit-dialogs';
import { hasValidationErrors } from '../../../utils/error-utils';
import { PostError } from '../../../utils/ajax-client';

export class BatchEntrySagaContext implements IVirtualTerminalSagaContext {
	userActionChannel = userActionChannel<BatchEntryUserAction>();
	userActionCreator = userActionCreator<typeof BatchEntryUserAction>(BatchEntryUserAction, this.userActionChannel);
	dataService: VirtualTerminalSagaDataService = sagaDataService();
	executingBlockingAction?: boolean = false;
	constructor(public mainViewModel: BatchEntryMainViewModel) { }
}

export function* batchEntryOnSagaInit(context: BatchEntrySagaContext) {
	const { mainViewModel: { batchDetailsViewModel } } = context;

	if (!batchDetailsViewModel.batchExists) {
		return;
	}

	yield fetchAllBatchPaymentsAndStartPolling(context);
}

export function getActionHandlers() {
	const actionHandlers = new ActionHandlers<BatchEntrySagaContext, typeof BatchEntryUserAction[keyof typeof BatchEntryUserAction]>();
	const saveActionPriority = 100;

	// Save action handlers have the highest priority and block each other
	actionHandlers.add(
		BatchEntryUserAction.SubmitForm, {
			async: true,
			priority: saveActionPriority,
			handler: (context) => NewFeatures.BatchEntryPaymentTimeoutOptimization
				? handleSubmitForm(context)
				: handleSubmitFormOld(context)
		}
	);
	actionHandlers.add(
		BatchEntryUserAction.SelectedPayerChange, {
			async: true,
			priority: saveActionPriority,
			handler: (context, action) => VirtualTerminalFormSaga.handleSelectedPayerChange(getVirtualTerminalFormSagaContext(context), action.payer)
		}
	);
	actionHandlers.add(
		BatchEntryUserAction.RemovePaymentMethod, {
			async: true,
			priority: saveActionPriority,
			handler: (context, action) => VirtualTerminalFormSaga.handleRemovePaymentMethod(getVirtualTerminalFormSagaContext(context), action.paymentMethod)
		}
	);
	actionHandlers.add(
		BatchEntryUserAction.AddServiceTime, {
			async: true,
			priority: saveActionPriority,
			handler: (context, action) => handleAddServiceTime(context, action.eventTimeCreateRequest)
		}
	);
	actionHandlers.add(
		BatchEntryUserAction.CreateBatch, {
			async: true,
			priority: saveActionPriority,
			handler: (context, action) => handleCreateBatch(context, action.merchantId, action.createBatchRequestModel)
		}
	);
	actionHandlers.add(
		BatchEntryUserAction.UpdateBatch, {
			async: true,
			priority: saveActionPriority,
			handler: (context, action) => handleUpdateBatch(context, action.merchantId, action.editBatchRequestModel)
		}
	);
	actionHandlers.add(
		BatchEntryUserAction.CloseBatch, {
			async: true,
			priority: saveActionPriority,
			handler: (context, action) => handleCloseBatch(context, action.merchantId, action.closeBatchRequestModel)
		}
	);
	actionHandlers.add(
		BatchEntryUserAction.ReopenBatch, {
			async: true,
			priority: saveActionPriority,
			handler: (context, action) => handleReopenBatch(context, action.merchantId, action.reopenBatchRequestModel)
		}
	);
	actionHandlers.add(
		BatchEntryUserAction.DeleteBatch, {
			async: true,
			priority: saveActionPriority,
			handler: (context, action) => handleDeleteBatch(context, action.merchantId, action.batchId)
		}
	);
	actionHandlers.add(
		BatchEntryUserAction.LoadBatchPayment, {
			async: true,
			priority: saveActionPriority,
			handler: (context, action) => handleLoadBatchPayment(context, action.encodedToken),
		}
	);
	actionHandlers.add(
		BatchEntryUserAction.LaunchDeleteBatchPaymentDialog, {
			async: false,
			handler: (context, action) => handleLaunchDeleteBatchPaymentDialog(context, action.encodedToken),
		}
	);
	actionHandlers.add(
		BatchEntryUserAction.DeleteBatchPayment, {
			async: true,
			priority: saveActionPriority,
			handler: (context, action) => handleDeleteBatchPayment(context, action.encodedToken),
		}
	);
	// Resetting the form cancels any get data actions
	actionHandlers.add(
		BatchEntryUserAction.ResetForm, {
			async: false,
			priority: 50,
			handler: (context) => VirtualTerminalFormSaga.handleResetForm(getVirtualTerminalFormSagaContext(context))
		}
	);
	actionHandlers.add(
		BatchEntryUserAction.SelectAnonymousPayer, {
			async: false,
			priority: 20,
			handler: (context) => VirtualTerminalFormSaga.handleSelectAnonymousPayer(getVirtualTerminalFormSagaContext(context))
		}
	);
	// Omnibox value changes from user input or check reading is debouncable and cancels other get data actions
	actionHandlers.add(
		BatchEntryUserAction.OmniboxValueChange, {
			async: true,
			priority: 10,
			debounceable: true,
			handler: (context, action) => VirtualTerminalFormSaga.handleOmniboxValueChange(getVirtualTerminalFormSagaContext(context), action.value)
		}
	);

	actionHandlers.add(
		BatchEntryUserAction.LaunchAddServiceTimeDialog, {
			async: false,
			handler: (context) => handleLaunchAddServiceTimeDialog(context)
		}
	);
	actionHandlers.add(
		BatchEntryUserAction.ViewHistory, {
			async: true,
			handler: (context, action) => handleViewBatchHistory(context, action.merchantId, action.batchId)
		}
	);
	actionHandlers.add(
		BatchEntryUserAction.StartEditBatch, {
			async: false,
			handler: (context) => handleStartEditBatch(context)
		}
	);
	actionHandlers.add(
		BatchEntryUserAction.CancelEditBatch, {
			async: false,
			handler: (context) => handleCancelEditBatch(context)
		}
	);
	actionHandlers.add(
		BatchEntryUserAction.PrintDepositSlip, {
			async: false,
			handler: (context) => handlePrintDepositSlip(context)
		}
	);
	actionHandlers.add(
		BatchEntryUserAction.LoadMorePayers, {
			async: true,
			handler: (context) => VirtualTerminalFormSaga.handleLoadMorePayers(getVirtualTerminalFormSagaContext(context))
		}
	);
	actionHandlers.add(
		BatchEntryUserAction.ViewAllExistingPaymentMethods, {
			async: true,
			handler: (context) => VirtualTerminalFormSaga.handleViewAllExistingPaymentMethods(getVirtualTerminalFormSagaContext(context))
		}
	);

	return actionHandlers;
}

function handleLaunchAddServiceTimeDialog(context: BatchEntrySagaContext) {
	const { userActionChannel, mainViewModel: { setAddEventTimeDialogViewModel, modalDialogProcessingViewModel} } = context;
	const vm = new EventTimeAddEditDialogViewModel();
	setAddEventTimeDialogViewModel(vm);

	const addEventTimeDialog = React.createElement(EventTimeAddDialog, {
			vm,
			onSubmitHandler: (createRequest: EventTimeCreateRequest) => userActionChannel.put(new BatchEntryUserAction.AddServiceTime(createRequest)),
			modalDialogProcessingViewModel,
		});
	ModalDialogCommander.showReactForm(addEventTimeDialog);
}

function* handleAddServiceTime(context: BatchEntrySagaContext, createInfo: EventTimeCreateRequest) {
	const {
		dataService,
		mainViewModel: {
			listingStore,
			batchDetailsViewModel,
			addEventTimeDialogViewModel,
			modalDialogProcessingViewModel: {
				setIsProcessing
			}
		}
	} = context;
	setIsProcessing(true);
	let eventTime: VirtualTerminalEventTimeViewModel;
	try {
		const listingSelected = !!listingStore.selectedListing;
		eventTime = listingSelected
			? yield dataService.addEventTimeActivateForMerchant({
				merchantId: listingStore.selectedListing.ListingId,
				createInfo
			})
			: yield dataService.addEventTime({ createInfo });

		batchDetailsViewModel.updateEventTimeId(eventTime.Id);

		batchDetailsViewModel.addEventTime(eventTime);

		if (listingSelected) {
			batchDetailsViewModel.addEventTimeToSelectedListing(eventTime.Id);
		}

		ModalDialogCommander.forceCloseCurrent();
	} catch(err) {
		if (hasValidationErrors(err)) {
			addEventTimeDialogViewModel.validationErrors = err.validationErrors;
			return;
		}
		throw err;
	} finally {
		setIsProcessing(false);
	}
}

function* handleCreateBatch(context: BatchEntrySagaContext, merchantId: number, model: CreateBatchRequestModel) {
	const { mainViewModel: { batchDetailsViewModel, listingStore, defaultSettingsViewModel }, dataService } = context;

	let response: CreateBatchResponseModel;
	try {
		response = yield dataService.createBatch({ merchantId, model });
	} catch(error) {
		//todo we really should be returning UpdateConflict here and handling it explicitly
		//this is going to take a bunch of work and refactoring though so lets handle it only in the 500 case for now :(
		if (error.client.status === 500) {
			yield refreshEventTimesAndDisplayError(context, merchantId);
			return;
		}
		throw error;
	}

	const { Batch, ListingConfiguration } = response;
	batchDetailsViewModel.setBatch(Batch);
	listingStore.loadingConfigurationFinished(listingStore.selectedListingId, ListingConfiguration);
	batchDetailsViewModel.updateState(BatchDetailsState.Viewing);
	defaultSettingsViewModel.updateGivenOn(batchDetailsViewModel.giftsReceivedOn);
	rewriteUrlAppendBatchIdAndUpdateBrowserHistory(Batch.BatchId);

	yield fetchAllBatchPaymentsAndStartPolling(context);

	alertController.showSuccess(`Your batch ${Batch.Name} has been created.`);
}

function* handleUpdateBatch(context: BatchEntrySagaContext, merchantId: number, model: EditBatchRequestModel) {
	const { mainViewModel: { batchDetailsViewModel, defaultSettingsViewModel }, dataService } = context;

	let batch: BatchViewModel;
	try {
		batch = yield dataService.editBatch({ merchantId, model });
	} catch(error) {
		//todo we really should be returning UpdateConflict here and handling it explicitly
		//this is going to take a bunch of work and refactoring though so lets handle it only in the 500 case for now :(
		if (error.client.status === 500) {
			yield refreshEventTimesAndDisplayError(context, batchDetailsViewModel.batch.ListingId, model.BatchId);
			return;
		}
		throw error;
	}

	batchDetailsViewModel.setBatch(batch);
	batchDetailsViewModel.updateState(BatchDetailsState.Viewing);
	batchDetailsViewModel.updateEventTimes(model.EventTimeId);
	defaultSettingsViewModel.updateGivenOn(batchDetailsViewModel.giftsReceivedOn);

	alertController.showSuccess(`Your batch ${batch.Name} has been updated.`);
}

function* refreshEventTimesAndDisplayError(context: BatchEntrySagaContext, merchantId: number, batchId?: number) {
	const { dataService, mainViewModel: { batchDetailsViewModel } } = context;
	const eventTimes = batchId
		? yield dataService.getEventTimesWithBatchId({ merchantId, batchId })
		: yield dataService.getEventTimes({ merchantId });
	batchDetailsViewModel.setEventTimes(eventTimes);
	ModalDialogCommander.error('Sorry, the details for the service times have recently been updated. Please try again.', '');
}

function* handleReopenBatch(context: BatchEntrySagaContext, merchantId: number, model: ReopenBatchRequestModel) {
	const { mainViewModel: { batchDetailsViewModel }, dataService } = context;
	const batch: BatchViewModel = yield dataService.reopenBatch({ merchantId, model });
	batchDetailsViewModel.setBatch(batch);
	batchDetailsViewModel.showDefaultSettings();

	yield fetchAllBatchPaymentsAndStartPolling(context);

	alertController.showSuccess(`Your batch ${batch.Name} is now open.`);
}

function* handleDeleteBatch(context: BatchEntrySagaContext, merchantId: number, batchId: number) {
	const { mainViewModel: { batchDetailsViewModel }, dataService } = context;
	const deletedBatchName = batchDetailsViewModel.batch.Name;
	yield dataService.deleteBatch({ merchantId, batchId });
	batchDetailsViewModel.setBatch(getEmptyBatchViewModel());
	batchDetailsViewModel.updateState(BatchDetailsState.Creating);
	rewriteUrlRemoveBatchIdAndUpdateBrowserHistory();

	alertController.showSuccess(`Your batch ${deletedBatchName} has been deleted.`);
}

function* handleViewBatchHistory(context: BatchEntrySagaContext, merchantId: number, batchId: number) {
	const { dataService } = context;
	const batchHistoryViewModel: BatchHistoryViewModel = yield dataService.getBatchHistory({ merchantId, batchId });

	const viewHistoryDialog = React.createElement(BatchEntryHistoryDialog, {
		batchId,
		batchHistoryItems: batchHistoryViewModel.Items,
	});
	ModalDialogCommander.showReactDialog(viewHistoryDialog);
}

function handlePrintDepositSlip(_context: BatchEntrySagaContext) {
	window.print();
}

function handleStartEditBatch(context: BatchEntrySagaContext) {
	const { mainViewModel: { batchDetailsViewModel } } = context;
	batchDetailsViewModel.storeCurrentBatchDetailsState();
	batchDetailsViewModel.updateState(BatchDetailsState.Editing);
}

function handleCancelEditBatch(context: BatchEntrySagaContext) {
	const { mainViewModel: { batchDetailsViewModel } } = context;
	batchDetailsViewModel.revertBatchDetailsState();
	batchDetailsViewModel.updateState(BatchDetailsState.Viewing);
}

function* handleSubmitFormOld(context: BatchEntrySagaContext) {
	const { mainViewModel: { formViewModel, batchDetailsViewModel, listingStore, batchPaymentsStore, defaultSettingsViewModel }, dataService } = context;
	const { savePaymentStarted, savePaymentFinished, resetForm } = formViewModel;
	const { batch: { ListingId, BatchId } } = batchDetailsViewModel;

	Metrics.paymentEntryFinished();

	savePaymentStarted();

	try {
		const response: BatchEntryPaymentResponseModel = yield dataService.payViaBatchEntry({
			merchantId: ListingId,
			batchId: BatchId,
			stats: { ...Metrics.getPaymentEntryStats(), DefaultSettings: defaultSettingsViewModel.defaultSettingsStatsModel },
			model: buildPaymentRequestModel(formViewModel)
		});

		VirtualTerminalFormSaga.handleContentOutOfDate(getVirtualTerminalFormSagaContext(context), response.ContentOutOfDate);
		handleVirtualTerminalError(response.ErrorMessage);

		resetForm();

		if (response.BatchEntryPayment) {
			yield cancelPollingForBatchPayments();
			batchPaymentsStore.addPayment(response.BatchEntryPayment);
			yield fetchAllBatchPaymentsAndStartPolling(context);
		}

		alertController.showSuccess(`Success! Your ${listingStore.paymentLabel.NounLowerCase} was made successfully`);

	} catch(error) {
		const validationErrors = error.validationErrors;
		if (validationErrors) {
			alertController.showValidationErrors(validationErrors);
			formViewModel.updateValidationErrors(validationErrors);
		} else {
			throw error;
		}
	} finally {
		savePaymentFinished();
	}
}

function* handleSubmitForm(context: BatchEntrySagaContext) {
	const { mainViewModel: { formViewModel, batchDetailsViewModel, listingStore, batchPaymentsStore, defaultSettingsViewModel }, dataService } = context;
	const { savePaymentStarted, savePaymentFinished, resetForm } = formViewModel;
	const { batch: { ListingId, BatchId } } = batchDetailsViewModel;

	Metrics.paymentEntryFinished();

	savePaymentStarted();

	const paymentTimeoutTask: Task = yield fork(function* () {
		yield call(delay, 3000);
		resetForm();
		savePaymentFinished();

		alertController.showProcessing(`Your ${listingStore.paymentLabel.NounLowerCase} is being processed`, 30000);
	});

	yield spawn(function* () {
		try {
			const response: BatchEntryPaymentResponseModel = yield dataService.payViaBatchEntry({
				merchantId: ListingId,
				batchId: BatchId,
				stats: { ...Metrics.getPaymentEntryStats(), DefaultSettings: defaultSettingsViewModel.defaultSettingsStatsModel },
				model: buildPaymentRequestModel(formViewModel)
			});

			if (paymentTimeoutTask.isRunning()) {
				paymentTimeoutTask.cancel();

				VirtualTerminalFormSaga.handleContentOutOfDate(getVirtualTerminalFormSagaContext(context), response.ContentOutOfDate);
			}

			handleVirtualTerminalError(response.ErrorMessage);

			if (paymentTimeoutTask.isCancelled()) {
				resetForm();
			}

			if (response &&
				response.BatchEntryPayments &&
				response.BatchEntryPayments.length > 0
			) {
				yield cancelPollingForBatchPayments();
				for (let payment of response.BatchEntryPayments) {
					batchPaymentsStore.addPayment(payment);
				}
				yield fetchAllBatchPaymentsAndStartPolling(context);
				const failedGiftsCount = response.BatchEntryPayments.filter(x => x.PaymentStatus == GiftEntryPaymentStatus.Failed).length;
				if(failedGiftsCount > 0) {
					alertController.showWarning(`Your ${listingStore.paymentLabel.NounLowerCase} has been submitted, please check the table below for the submission status.`);
					return;
				}
			} else if (response.BatchEntryPayment) {
				yield cancelPollingForBatchPayments();
				batchPaymentsStore.addPayment(response.BatchEntryPayment);
				yield fetchAllBatchPaymentsAndStartPolling(context);
			}

			alertController.showSuccess(`Success! Your ${listingStore.paymentLabel.NounLowerCase} was made successfully`);
		} catch(error) {
			if (paymentTimeoutTask.isRunning()) {
				paymentTimeoutTask.cancel();

				if (hasValidationErrors(error)) {
					formViewModel.updateValidationErrors(error.validationErrors);
				}
			}

			if (hasValidationErrors(error)) {
				alertController.showValidationErrors(error.validationErrors, `Sorry your ${listingStore.paymentLabel.NounLowerCase} was not made successfully.`);
				return;
			}

			alertController.hide();

			if (error instanceof PostError && error.timedout) {
				yield ModalDialogCommander.error(
					`There was a problem communicating with Pushpay and we're currently looking into it. Please reload the page to confirm whether your last ${listingStore.paymentLabel.NounLowerCase} was created successfully.`,
					StandardErrorTitle
				);
				window.location.reload();
				return;
			}

			reportVirtualTerminalError(error);
		} finally {
			if (paymentTimeoutTask.isCancelled()) {
				savePaymentFinished();
			}
		}
	});
}

function getVirtualTerminalFormSagaContext(batchEntrySagaContext: BatchEntrySagaContext): IVirtualTerminalFormSagaContext {
	return {
		formViewModel: batchEntrySagaContext.mainViewModel.formViewModel,
		dataService: batchEntrySagaContext.dataService,
	};
}
