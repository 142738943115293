import * as React from 'react';
import { observable, action } from 'mobx';
import { BusyTracker, AnimationTrigger } from './_common';
import Main from './main';
import PersonalDetails from './personal-details';
import Account from './account';

import MoveAccountCommand from './commands/move-account-command';
import MoveMemberCommand from './commands/move-member-command';
import UpdateMemberCommand from './commands/update-member-command';

import * as Models from '../models/index';
import { ModalDialogCommander } from '../../components/modal-dialog-commander';
import { Models as GeneratedModels } from '../community-member-generated';
import ViewCustomStatementsCommand from './commands/view-custom-statements-command';
import { ICancellablePromise } from '../../utils/cancellable-promise';
import { EditMemberDialog } from '../views/edit-member-dialog';

export default class Member {
	@observable accounts: Account[] = [];
	@observable expanded = false;

	@observable id: number = null; //null while we make a new member, till the server tells us the new ID

	details = new PersonalDetails();

	busyTracker = new BusyTracker();

	ungroupAnimationTrigger = new AnimationTrigger('', 900);

	/**
	 * will be true if we ungroup an account that we don't expect to be from the current set of server-delivered data.
	 * when we see this row actually arrive from the server we must set this false again
	 */
	@observable isBeyondCurrentPage = false;
	@observable canPreview = false;
	@observable customStatements: GeneratedModels.GivingStatementsByNameAndPreviewabilityResponseModel[] = null;

	reactKey() {
		var fallbackKey = this.accounts.map(x => x.id).join(',');
		return this.id || fallbackKey;
	}

	constructor(public parent: Main) {
	}

	@action
	adoptAccount(account: Account) {
		new MoveAccountCommand(account, account.parent, this).execute();
	}

	@action
	adoptAllAccounts(member: Member) {
		new MoveMemberCommand(member, this).execute();
	}

	@action
	toggleExpanded() {
		this.expanded = !this.expanded;
	}

	edit(enableFeatureOrganizationalGiving: boolean) {
		const editor = <EditMemberDialog memberId={this.id} details={this.details} onCommunityMemberUpdated={this.applyModel}
			enableFeatureOrganizationalGiving={enableFeatureOrganizationalGiving} />;
		ModalDialogCommander.showReactForm(editor, { elementToFocusSelector: '[name=FirstName]' });
	}

	trySave = (request: GeneratedModels.EditCommunityMemberRequestModel) => {
		return new UpdateMemberCommand(this, request).execute();
	}

	cancelEdit() {
		throw new Error('not implemented');
	}

	insertAccounts(...accounts: Account[]) {
		accounts.forEach(x => x.parent = this);
		this.accounts = this.accounts.concat(accounts).sort((x, y) => x.id - y.id);
	}

	@action
	applyModel = (model: Models.CommunityMemberModel, applyAccounts = true) => {
		this.id = model.CommunityMemberId;
		this.details.importValues(model);

		if (applyAccounts) {
			this.accounts = [];
			const accountViewModels = model.LinkedPersons.map(ma => new Account(this).applyModel(ma));
			this.insertAccounts(...accountViewModels);
		}

		return this;
	}

	@action
	viewMemberCustomStatements(): ICancellablePromise<Models.GivingStatementsByNameAndPreviewabilityResponseModel[]> {
		return new ViewCustomStatementsCommand(this.parent, this).execute();
	}
}
