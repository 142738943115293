import React, { useState, useRef, useEffect, useCallback, FC, memo } from 'react';
import { DownloadsPanelContextProvider, Header as CapHeader } from '@pushpay/cap-layout';
import { ExportJob, JobAction } from '@pushpay/cap-layout/components/downloads-panel/types';
import { OrganizationDetails as CapOrganizationDetails } from '@pushpay/shell/lib/types/components/org-switcher/types';
import { isMockView } from '../helpers/is-mockview';
import { NavViewModel } from '../loggedinweb-generated';
import { getProductSwitcherConfig } from './get-product-switcher-config';

type ExportData = {
	jobs: ExportJob<unknown>[];
	jobsNotificationsCount?: number;
	downloadUrl?: { jobKey: string; url: string };
};

type CapProductSwitcherConfig = {
	hasMasAccess: boolean;
};

export const Header: FC<{
	vm: NavViewModel;
	currentOrganization: CapOrganizationDetails;
	organizations: CapOrganizationDetails[];
	setOrganizations: (organizations: CapOrganizationDetails[]) => void;
}> = memo(({ vm, currentOrganization, organizations, setOrganizations }) => {
	const [exportData, setExportData] = useState<ExportData | null>(null);

	// Remove with Feature ImplementSwitchingToInsights
	const [capProductSwitcherConfig, setCapProductSwitcherConfig] = useState<CapProductSwitcherConfig>({
		hasMasAccess: false,
	});
	const iframeRef = useRef<HTMLIFrameElement | null>(null);

	const showDownloadsPanel = !vm.CurrentActiveOrganization.IsMerchantAdmin && !isMockView();
	const capUrl = vm.NavInfo.CapUrl ? vm.NavInfo.CapUrl.slice(0, -1) : '';
	const iframeUrl = capUrl + '/chromeless/o/' + vm.NavInfo.OrganizationKey + (showDownloadsPanel ? '/downloads' : '');

	useEffect(() => {
		const handleMessage = (event: MessageEvent) => {
			if (event.origin === capUrl && event.data) {
				const { messageType, data } = event.data;
				if (showDownloadsPanel && messageType === 'UPDATE_EXPORT_DATA') {
					setExportData((previous) => ({ ...previous, ...data }));
					return;
				}
				if (!NewFeatures.ImplementSwitchingToInsights && messageType === 'PRODUCT_SWITCHER_CONFIG') {
					setCapProductSwitcherConfig(data);
					return;
				}
				if (messageType === 'ORGANIZATION_LIST') {
					setOrganizations(data.organizationList);
					return;
				}
			}
		};
		window.addEventListener('message', handleMessage);
		return () => window.removeEventListener('message', handleMessage);
	}, [setExportData]);

	if (!vm) {
		console.log('CapLayout component loaded but will exit because vm is not ready.');
		return null;
	}
	const {
		NavInfo: navInfo,
		CurrentActiveOrganization,
		LogoutUrl: logoutUrl,
		PersonalAccountUrl: personalAccountUrl,
		ProductSwitcherViewModel: productSwitcherViewModel,
	} = vm;
	const {
		IdentityKey: identityKey,
		Profile: { FirstName: profileFirstName, LastName: profileLastName, Url: profileUrl, Email: profileEmail },
		Header: { HelpCenterUrl: headerHelpCenterUrl },
	} = navInfo;

	const redirectUserTo = (url: string) => {
		window.location.href = url;
	};
	const postToFrame = useCallback(
		(messageType: string, data?: any) => {
			if (iframeRef.current && iframeRef.current.contentWindow) {
				iframeRef.current.contentWindow.postMessage(
					{
						messageType,
						data,
					},
					iframeUrl
				);
			}
		},
		[iframeRef.current]
	);

	const downloadsPanelConfig = showDownloadsPanel
		? {
				jobs: new Map(((exportData && exportData.jobs) || []).map((job) => [job.jobKey, job])),
				jobsNotificationsCount: (exportData && exportData.jobsNotificationsCount) || 0,
				downloadUrl: exportData && exportData.downloadUrl,
				dispatch: (jobAction: JobAction) => postToFrame('DISPATCH_JOB_ACTION', jobAction),
				onClose: () => postToFrame('CLOSE_PANEL'),
		  }
		: undefined;

	const productSwitcherConfig = getProductSwitcherConfig({
		...productSwitcherViewModel,
		...capProductSwitcherConfig,
		isMasActive: CurrentActiveOrganization.IsMasActive,
		organizationKey: currentOrganization.key,
	});

	return (
		<>
			{!isMockView() && (
				<iframe
					tabIndex={-1}
					scrolling="no"
					frameBorder="no"
					src={iframeUrl}
					ref={iframeRef}
					style={{ width: 0, height: 0 }}
				/>
			)}
			<DownloadsPanelContextProvider>
				<CapHeader
					data-pp-at-target="header"
					avatarID={identityKey}
					firstName={profileFirstName}
					lastName={profileLastName}
					emailAddress={profileEmail}
					profileLabel="Profile"
					logoutLabel="Log out"
					loggingOutLabel="Logging out"
					profileButtonAriaLabel="Profile menu"
					personalAccountLabel="Go to Personal Account"
					selectOrganizationLabel="Select Organization"
					buttonLabel="Click to switch Organization"
					clearButtonAriaLabel="Clear search text"
					placeholder="Search..."
					helpLabel="Help Center"
					helpUrl={headerHelpCenterUrl}
					profileLink={profileUrl}
					logout={() => redirectUserTo(logoutUrl)}
					personalAccount={personalAccountUrl}
					organizations={organizations}
					currentOrganization={currentOrganization}
					isSpa={false}
					downloadsPanelConfig={downloadsPanelConfig}
					productSwitcherConfig={productSwitcherConfig}
				/>
			</DownloadsPanelContextProvider>
		</>
	);
});
