import * as React from 'react';
import { button, buttonLoading, buttonContent } from './button.less';
import { classNames } from '../../utils/classnames';
import { Spinner } from '../spinner/spinner';

export interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	isLoading?: boolean;
	disabled?: boolean;
	loadingClassName?: string;
	spinnerClassName?: string;
	acceptanceTestTargetId?: string;
}

export const Button: React.StatelessComponent<IButtonProps> = (props) => {
	const { isLoading, disabled, children, className, loadingClassName, spinnerClassName, acceptanceTestTargetId, ...buttonProps } = props;

	// `...buttonProps` has to be the first attribute, so the user set attributes don't override the attributes we are
	// setting within the component.
	return (
		<button {...buttonProps}
			className={classNames(button, className, isLoading ? `${buttonLoading} ${loadingClassName}` : '')}
			disabled={isLoading || disabled}
			data-pp-at-target={acceptanceTestTargetId}>
			<div className={buttonContent}>
				{isLoading && <Spinner className={spinnerClassName} />}
				{children}
			</div>
		</button>
	);
};
