import * as React from 'react';
import { observer } from 'mobx-react';
import { MigrationTipsViewModel, ScheduleImportMigrationStatus } from '../schedule-import-generated';
import { AlertControl, AlertType } from '../../transaction-import/components/alert-control';

import { ProgressIndicator } from '../../components/progress-indicator';
import * as styles from './styles.less';

@observer
export class MigrationTips extends React.Component<{ vm: MigrationTipsViewModel }> {
	render() {
		const {
			MigrationStatus,
			UploadsCount,
			MigrationUrl,
			TotalSchedulesCount,
			MigratedSchedulesCount,
		} = this.props.vm;
		switch (MigrationStatus) {
			case ScheduleImportMigrationStatus.Draft:
				{/* tslint:disable max-line-length */}
				const content = <span> You currently have {UploadsCount} { UploadsCount === 1 ? 'set' : 'sets'} of uploaded schedules <a className="btn-link arrow-hollow-right-after" href={MigrationUrl}>ready to migrate into Pushpay</a></span>;
				{/* tslint:enable max-line-length */}
				return (
					<AlertControl alertContent={content} alertType={AlertType.Info} />
				);
			case ScheduleImportMigrationStatus.Confirmed:
			case ScheduleImportMigrationStatus.Active:
				return (
					<ProgressTips totalSchedulesCount={TotalSchedulesCount} migratedSchedulesCount={MigratedSchedulesCount} progressClassName={styles.colorBlue}>
						{/* tslint:disable max-line-length */}
						<h2>Your migration current progress</h2>
						<p>From your active migration, you have successfully migrated <strong>{MigratedSchedulesCount} {MigratedSchedulesCount === 1 ? 'schedule' : 'schedules'}</strong> into Pushpay.</p>
						<p>You can view the full details of your migration on your <a className="btn-link arrow-hollow-right-after" href={MigrationUrl}>migration summary page</a>.</p>
						{/* tslint:enable max-line-length */}
					</ProgressTips>
				);
			case ScheduleImportMigrationStatus.Paused:
				const remainingSchedules = TotalSchedulesCount - MigratedSchedulesCount;
				return (
					<ProgressTips totalSchedulesCount={TotalSchedulesCount}migratedSchedulesCount={MigratedSchedulesCount} progressClassName={styles.colorGray}>
						<h2>Migration paused</h2>
						<p>You have <strong>{remainingSchedules}</strong> remaining {remainingSchedules === 1 ? 'schedule' : 'schedules'} that need to be migrated into Pushpay.</p>
					</ProgressTips>
				);
			default:
				return null;
		}

	}
}

interface IProgressTipsProps {
	totalSchedulesCount: number;
	migratedSchedulesCount: number;
	progressClassName: string;
}

class ProgressTips extends React.Component<IProgressTipsProps> {

	render() {
		const {
			totalSchedulesCount,
			migratedSchedulesCount,
			progressClassName,
		} = this.props;
		return (
			<div className={styles.tipsContainer}>
				<div className={`${styles.progressWrapper} ${progressClassName}`}>
					<ProgressIndicator totalCount={totalSchedulesCount} currentCount={migratedSchedulesCount} units="Schedules"/>
				</div>
				<div>
					{this.props.children}
				</div>
			</div>
		);
	}
}

