import { VirtualTerminalExportConfig } from './virtual-terminal-generated';
import { GenericExportService, createNonAjaxPostService } from '../utils/non-ajax-post-request';

export type VirtualTerminalExportDataService = GenericExportService<typeof VirtualTerminalExportConfig>;

let instance: VirtualTerminalExportDataService | null = null;

export function getVirtualTerminalExportDataService() {
	if (instance === null) {
		instance = createNonAjaxPostService(VirtualTerminalExportConfig);
	}

	return instance;
}
