import { Observable, Subscription } from 'rxjs';

type Subscriber = { key: string, observable$: Observable<any> };

//Weird we cant key by variable in these decorators which means we cant use Symbols...
export function subscribe(observable$: Observable<any>) {
	return function (target: Object, key: string, descriptor: PropertyDescriptor) {
		const prototype = target as any;
		if (prototype.__registerSubscribers === undefined) {
			Object.defineProperty(prototype, '__registerSubscribers', {
				enumerable: false,
				value: [],
			});
		}
		prototype.__registerSubscribers.push({ key, observable$ });
		return descriptor;
	};
}

export function unsubscribeAll(_target: Object, _key: string, descriptor: PropertyDescriptor) {
	const fn = descriptor.value;

	descriptor.value = function () {
		fn.apply(this, arguments);
		const subscribers = (this as any).__subscribers;
		if (subscribers) {
			Object.keys(subscribers).forEach(key => subscribers[key].unsubscribe());
		}
	};
	return descriptor;
}

export function subscriber<T extends { new(...args: any[]): {} }>(constructor: T) {
	return class extends constructor {
		__subscribers = (this as any).__registerSubscribers.reduce((result: Record<string, Subscription>, x: Subscriber) => {
			result[`${x.key}`] = x.observable$.subscribe(((this as any)[x.key]).bind(this));
			return result;
		}, {});
	};
}
