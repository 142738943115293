import { observable, action, computed } from 'mobx';
import { PaymentLabel, SelectItem, ScheduleImportDraftEditingStatus, Country, ScheduleEditRequest } from '../../schedule-import-generated';
import { ScheduleDetailsProps } from '../schedule-details-expanded-row';

export class EditScheduleDetailsViewModel {
	readonly paymentLabel: PaymentLabel;
	readonly availableStatusItems: SelectItem[];
	readonly draftId: number;
	readonly isPayerDetailsEditable: boolean;
	readonly defaultCountry: Country;

	@observable firstName: string;
	@observable lastName: string;
	@observable email: string;
	@observable mobile: string;
	@observable status: ScheduleImportDraftEditingStatus;
	@observable validationErrors: { [s: string]: string };

	@observable actionInProgress: boolean;


	constructor(viewData: ScheduleDetailsProps) {
		this.paymentLabel = viewData.paymentLabel;
		this.draftId = viewData.scheduleId;
		this.availableStatusItems = viewData.availableStatusItems;
		this.defaultCountry = viewData.defaultCountry;
		this.firstName = viewData.firstName;
		this.lastName = viewData.lastName;
		this.email = viewData.email;
		this.mobile = viewData.mobileNumber;
		this.status =  viewData.isEmailBounced ? null : viewData.status;
		this.isPayerDetailsEditable = viewData.status === ScheduleImportDraftEditingStatus.Awaiting || viewData.isEmailBounced;

	}

	@action.bound
	updateFirstName(firstName: string) {
		this.firstName = firstName;
	}

	@action.bound
	updateLastName(lastName: string) {
		this.lastName = lastName;
	}

	@action.bound
	updateEmail(email: string) {
		this.email = email;
	}

	@action.bound
	updateMobile(mobile: string) {
		this.mobile = mobile;
	}

	@action.bound
	updateStatus(status: string) {
		if (ScheduleImportDraftEditingStatus[status]) {
			this.status = Number(status);
		}
	}

	@action.bound
	setValidationErrors(errors: { [s: string]: string }) {
		this.validationErrors = errors;
	}

	@computed
	get requestObject(): ScheduleEditRequest {
		return {
			DraftId: this.draftId,
			FirstName: this.firstName,
			LastName: this.lastName,
			Email: this.email,
			Mobile: this.mobile,
			Status: this.status,
			DefaultCountry: this.defaultCountry,
		};
	}
}
