import * as React from 'react';
import { observer } from 'mobx-react';
import { Button } from '../button';
import { EventTimeViewModel } from '../../loggedinweb-generated';
import { eventTimeForDisplay } from './event-time-display-formats';
import { ModalDialog } from '../modal-dialog';
import { ModalDialogProcessingViewModel } from '../modal-dialog/modal-dialog-processing-view-model';
import { PaymentBatchViewModel } from '../../settings/settings-generated';
import { EventTimeOpenBatchesList } from './event-time-open-batches-list';

@observer
export class EventTimeDeleteDialog extends React.Component<{
	eventTime: EventTimeViewModel,
	onSubmitHandler: (token: string) => void,
	onCancelHandler: () => void,
	modalDialogProcessingViewModel: ModalDialogProcessingViewModel,
	paymentNounPluralLowerCase: string,
	openPaymentBatches?: PaymentBatchViewModel[],
}> {
	render() {
		const {
			eventTime,
			onCancelHandler,
			modalDialogProcessingViewModel: { isProcessing },
			paymentNounPluralLowerCase,
			openPaymentBatches,
		} = this.props;
		const buttons = [
			<Button key="cancel" className="btn btn-cancel" type="button" data-dismiss="modal" disabled={isProcessing} onClick={onCancelHandler}>Not right now</Button>,
			<Button key="submit" className="btn btn-default" type="submit" isProcessingRequest={isProcessing} onClick={this.onSubmitHandler}>Archive service time</Button>
		];
		const hasOpenBatches = openPaymentBatches && openPaymentBatches.length > 0;

		const confirmationText = hasOpenBatches
			? 'All open batch entries will lose their connection to this service time.'
			: `Any ${paymentNounPluralLowerCase} or completed batch entries that are associated with this service time won't be affected.`;

		return (
			<ModalDialog title="Are you sure you want to archive this service time?" buttons={buttons} showAlert={false} hideCloseButton>
				{/* tslint:disable:max-line-length */}
				<p className="modal-text">You're about to archive this service time <strong>{eventTimeForDisplay(eventTime)}</strong>. {confirmationText}</p>
				{/* tslint:enable:max-line-length */}
				{hasOpenBatches && <EventTimeOpenBatchesList openPaymentBatches={openPaymentBatches} />}
			</ModalDialog>
		);
	}

	private onSubmitHandler = () => {
		const { eventTime: { Token }, onSubmitHandler } = this.props;
		onSubmitHandler(Token);
	}
}
