import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { observer } from 'mobx-react';
import { ModalDialogCommander } from '../components/modal-dialog-commander';
import { Button } from '../components/button';
import { ModalDialog } from '../components/modal-dialog';
import { BrandingSettingsMainViewModel, BrandingPackageState } from './branding-settings-main-view-model';

export interface IBrandingSettingConfirmDiscardDialogProps {
	discardClicked: () => void;
	vm: BrandingSettingsMainViewModel;
}

@observer
export class BrandingSettingConfirmDiscardDialog extends React.Component<IBrandingSettingConfirmDiscardDialogProps> {
	open() {
		return ModalDialogCommander.open(ReactDOM.findDOMNode(this));
	}

	close() {
		ModalDialogCommander.close(ReactDOM.findDOMNode(this));
	}

	render() {
		const { discardClicked, vm } = this.props;
		const isProcessing = vm.packageState === BrandingPackageState.DiscardInProgress;
		const discardButton =
			<Button key="discard_button"
				className="btn btn-primary"
				acceptanceTestTargetId="discard-confirm"
				isProcessingRequest={isProcessing}
				onClick={discardClicked}
				disabled={vm.formViewModel.isSavingOrUploadingOrProcessing}
			>Discard
			</Button>;
		const cancelButton = {
			label: 'Cancel',
			dismiss: true,
			isPrimary: false,
			disabled: isProcessing,
		};
		const buttons = [cancelButton, discardButton];
		return <ModalDialog title="" buttons={buttons} showAlert={false} inProgress={isProcessing} >
			Are you sure you want to discard all changes?
			</ModalDialog>;
	}
}
