import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { inject, observer } from 'mobx-react';
import { TransitionGroup } from 'react-transition-group-v1';
import { MachineContext, machineContextKey } from '../../../Shared/state-machine/saga-state-machine';
import { velocity } from '../../helpers/velocity';
import { SvgWrapper } from '../svg-wrapper';
import { isFunction } from '../../../Shared/utils/is-function';
import { AnimatedSuccessTick } from '../animated-svg/success-tick';
import { classNames } from '../../../Shared/utils/classnames';

import * as styles from './styles.less';

type ModalStates = {
	state: string | string[],
	anyState?: never,
	allStates?: never,
} | {
	state?: never,
	anyState: (string | string[])[],
	allStates?: never,
} | {
	state?: never,
	anyState?: never,
	allStates: (string | string[])[],
};

export type ModalType = 'dialog' | 'form';
export type IMatchModalProps = ModalStates & { type?: ModalType, machineContext?: MachineContext };

@inject(machineContextKey)
@observer
export class MatchModal extends React.Component<IMatchModalProps> {
	render() {
		const { type, children } = this.props;
		const isMatch = this.isMatch();

		const contents = (
			<TransitionGroup>
				{isMatch && <Modal type={type}>{children}</Modal>}
			</TransitionGroup>
		);

		return ReactDOM.createPortal(contents, document.body);
	}

	componentWillUnmount() {
		document.body.classList.remove('modal-open'); // ensure this class is removed
	}

	isMatch = (): boolean => {
		const { state, allStates, anyState, machineContext } = this.props;

		if (state) {
			return machineContext.matchesState(state);
		} else if (anyState) {
			return machineContext.matchesAnyState(anyState);
		} else if (allStates) {
			return machineContext.matchesAllStates(allStates);
		}
		return false;
	}
}

const animationDuration = 250;

@observer
class Modal extends React.Component<{ type?: ModalType }> {
	private backdrop: HTMLElement | null;
	private modalContainer: HTMLElement | null;
	private dialog: HTMLElement | null;

	render() {
		const { type, children } = this.props;
		const classes = type === 'form' ? classNames(styles.form, 'panel panel-default modal-content') : styles.dialog;

		return (
			<div className={styles.container}>
				<div className={styles.backdrop} ref={(el) => this.backdrop = el} />
				<div className={styles.modal} role="dialog" ref={(el) => this.modalContainer = el}>
					<div className={classes} ref={(el) => this.dialog = el}>
						{children}
					</div>
				</div>
			</div>
		);
	}

	componentWillAppear(callback) {
		this.animateIn(callback);
	}

	componentWillEnter(callback) {
		this.animateIn(callback);
	}

	componentWillLeave(callback) {
		velocity(this.backdrop, {
			opacity: [0, 0.5],
		}, {
			duration: animationDuration,
			easing: 'easeIn',
		});
		velocity(this.modalContainer, {
			opacity: [0, 1],
		}, {
			duration: animationDuration,
			complete: () => this.closeAnimationComplete(callback),
			easing: 'easeIn',
		});
	}

	private animateIn(callback) {
		document.body.classList.add('modal-open');
		velocity(this.backdrop, {
			opacity: [0.5, 0],
		}, {
			duration: animationDuration,
			easing: 'easeIn',
		});
		velocity(this.dialog, {
			translateY: [0, '-25%'],
		}, {
			duration: animationDuration,
			easing: 'easeOut'
		});
		velocity(this.modalContainer, {
			opacity: [1, 0],
		}, {
			duration: animationDuration,
			complete: callback,
			easing: 'easeIn',
		});
	}

	private closeAnimationComplete(callback) {
		document.body.classList.remove('modal-open');
		callback();
	}
}

export const ModalCancelButton = ({ onCancel, disabled }: { onCancel: () => void, disabled?: boolean }) => (
	<button type="button" className="close" aria-label="Close" disabled={disabled} onClick={onCancel} />
);

export type SimpleModalProps = ModalStates & {
	type: SimpleModalType;
	getCancelButton?: () => JSX.Element | null;
	getBody: () => JSX.Element;
	getFooter: () => JSX.Element;
};

type SimpleModalType = 'warning' | 'success';

@observer
export class SimpleMatchModal extends React.Component<SimpleModalProps> {
	render() {
		const { type, getCancelButton, getBody, getFooter, ...rest } = this.props;
		return (
			<MatchModal {...rest}>
				<div className="modal-content">
					{isFunction(getCancelButton) && getCancelButton()}
					<div className="modal-body">
						{getIcon(type)}
						{getBody()}
					</div>
					<div className="modal-footer">
						{getFooter()}
					</div>
				</div>
			</MatchModal>
		);
	}
}

function getIcon(type: SimpleModalType) {
	switch (type) {
		case 'warning':
			return <SvgWrapper svg="alert-exclamation-icon" className={styles.icon} />;
		case 'success':
			return <AnimatedSuccessTick wrapperClass={styles.icon} />;
		default:
			const unsupported: never = type;
			throw new Error(`The modal type ${unsupported} is not supported`);
	}
}
