import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { ModalDialog, IModalDialogProps } from '../components/modal-dialog';
import { BrowserSupportHelper } from '../helpers/browsersupporthelper';
import { Utils } from '../helpers/utils';
const transitionEnd = BrowserSupportHelper.transitionEndEvent();

const eventNamespace = '.modal-dialog-commander';

interface IDialog {
	element: JQuery;
	previouslyFocusedElement: HTMLElement;
	deferred: JQueryDeferred<any>;
}

export interface IDialogOptions {
	elementToFocusSelector?: string;
}

const defaultOpenOptions: IDialogOptions = {
	elementToFocusSelector: null,
};
const defaultShowDialogOptions: IDialogOptions = {
	elementToFocusSelector: '.btn-primary',
};

//support only 1 dialog
//opening new dialog should close the currently opened

export const StandardErrorMessage = `Don't worry, we have been notified and will look into what happened.`;
export const StandardErrorTitle = `Sorry, something went wrong!`;

export class ModalDialogCommander {
	private static backdrop: JQuery;
	private static currentDialog: IDialog;

	static open(el: Element | Text | JQuery, options?: IDialogOptions, hasStaticBackdrop: boolean = false) {
		const optionsWithDefaults: IDialogOptions = { ...defaultOpenOptions, ...options };

		this.hideCurrentDialog();
		const element = $(el);

		this.currentDialog = {
			element: element,
			previouslyFocusedElement: document.activeElement as HTMLElement,
			deferred: $.Deferred()
		};

		this.showElement(element, optionsWithDefaults.elementToFocusSelector, hasStaticBackdrop);
		this.showBackdrop();

		return this.currentDialog.deferred.promise();
	}

	static close(element: Element | Text | JQuery) {
		if (this.currentDialog && $(element).is(this.currentDialog.element)) {
			this.hideCurrentDialog();
			this.hideBackdrop();
		}
	}

	static forceCloseCurrent() {
		if (this.currentDialog) {
			this.hideCurrentDialog();
			this.hideBackdrop();
		}
	}

	static showReactDialog(modalElement: React.ReactElement<any>, options?: IDialogOptions) {
		return this.showModalInner(modalElement, options);
	}

	static showReactForm(modalElement: React.ReactElement<any>, options?: IDialogOptions) {
		return this.showModalInner(modalElement, options, true);
	}

	static alert(text: string, title: string) {
		const props: IModalDialogProps = { title: title };
		const h4Props = { 'data-pp-at-target': 'ModalText' };
		const element = React.createElement(ModalDialog, props, React.createElement('h4', h4Props, text));
		return this.showReactDialog(element);
	}

	static error(text: string, title: string) {
		if (!text) {
			//let's be nice to the user and show an error message with the standard text
			//but report an attempt
			window.reportUnhandledRejection(new Error('Attempt to show an empty error message.'), { text, title });
			return this.showStandardErrorMessage();
		}

		return this.alert(text, title);
	}

	static showStandardErrorMessage() {
		return this.error(StandardErrorMessage, StandardErrorTitle);
	}

	private static showModalInner(modalElement: React.ReactElement<any>, options?: IDialogOptions, hasStaticBackdrop: boolean = false) {
		const optionsWithDefaults: IDialogOptions = { ...defaultShowDialogOptions, ...options };

		const container = $('<div class=\"modal-container\"></div>').appendTo(document.body);
		const instance = ReactDOM.render(modalElement, container[0]) as React.ReactInstance;
		const dialog = ReactDOM.findDOMNode(instance);

		return this.open(dialog, optionsWithDefaults, hasStaticBackdrop).done(() => {
			ReactDOM.unmountComponentAtNode(container[0]);
			container.remove();
		});
	}

	private static bindDialog(element: JQuery, hasStaticBackDrop: boolean) {
		if (!hasStaticBackDrop) {
			element.on(`click${eventNamespace}`, (e) => {
				//the currentTarget is the overlay
				//the dialog should close when clicking on the overlay only
				if (e.target === e.currentTarget) {
					this.handleClose(element);
				}
			});
		}

		element.on(`click${eventNamespace}`, '[data-dismiss="modal"]', (e) => {
			var modalElement = $(e.currentTarget).closest('.modal');
			if (modalElement.length) {
				this.handleClose(modalElement);
			}
		});

		document.addEventListener('keyup', this.handleEscape(element));
	}

	private static handleClose(element: Element | JQuery) {
		if (!$(element).is('[data-modal-prevent-close=true]')) {
			this.close(element);
			document.removeEventListener('keyup', this.handleEscape(element));
		}
	}

	private static handleEscape(element: Element | JQuery) {
		return (e) => {
			if (e.keyCode === 27) {
				this.handleClose(element);
			}
		};
	}

	private static unbindDialog(element: JQuery) {
		element.off(eventNamespace);
	}

	private static showBackdrop() {
		if (!this.backdrop) {
			this.backdrop = $('<div class="modal-backdrop fade"></div>');
			this.backdrop.hide().appendTo(document.body);

			this.backdrop.on(transitionEnd, () => {
				if (!this.currentDialog) {
					this.backdrop.hide();
					$(document.body).removeClass('modal-open');
				}
			});
		}

		this.backdrop.show();
		Utils.forceReflow(this.backdrop[0]);

		$(document.body).addClass('modal-open');
		this.backdrop.addClass('in');
	}

	private static hideBackdrop() {
		if (this.backdrop) {
			this.backdrop.removeClass('in');
		}
	}

	private static showElement(element: JQuery, elementToFocusSelector: string, hasStaticBackDrop: boolean) {
		let elementToFocus: JQuery | HTMLElement = element;

		if (elementToFocusSelector) {
			const childElementsToFocus = element.find(elementToFocusSelector);

			if (childElementsToFocus.length) {
				elementToFocus = childElementsToFocus.get(0);
			}
		}

		element.addClass('visible');
		Utils.forceReflow(element[0]);
		element.toggleClass('in', true);
		this.ensureElementFocusable(element);
		elementToFocus.focus();

		this.bindDialog(element, hasStaticBackDrop);
	}

	private static ensureElementFocusable(element: JQuery) {
		if (element.attr('tabindex') === undefined) {
			element.attr('tabindex', -1);
		}
	}

	private static hideCurrentDialog() {
		if (this.currentDialog) {
			var dialog = this.currentDialog;
			var element = this.currentDialog.element;
			element.one(transitionEnd, () => {
				element.removeClass('visible');
				dialog.previouslyFocusedElement.focus();
				dialog.deferred.resolve();
			});

			element.toggleClass('in', false);
			this.unbindDialog(element);
			this.currentDialog = null;
		}
	}
}

export function initModalDialogCommander() {
	$(document.body).on('click', '[data-toggle="modal"]', (e) => {
		var element = $(e.currentTarget);
		var target = element.attr('data-target') || element.attr('href');
		var targetElement = $(target);

		if (target && targetElement.length) {
			ModalDialogCommander.open(targetElement);
		}
	});
}
