import * as React from 'react';
import { ModalDialog } from '../../../components/modal-dialog';
import { Channel } from '../../../../Shared/utils/user-action-channel';
import { inject, observer } from 'mobx-react';

export namespace EnableIntegrationUserAction {
	export class Cancel { }

	export class EnableIntegration { }
}

export type EnableIntegrationUserAction = typeof EnableIntegrationUserAction[keyof typeof EnableIntegrationUserAction]['prototype'];

@inject('userActionChannel')
@observer
export class EnableIntegrationDialog extends React.Component<{userActionChannel: Channel<EnableIntegrationUserAction>}, {isProcessing: boolean}> {
	constructor(props) {
		super(props);
		this.state = { isProcessing: false };
	}

	render() {
		const { isProcessing } = this.state;

		const buttons:JSX.Element[] = [
			<button type="button" key="cancel" className="btn btn-ghost" onClick={this.handleCancelClick} disabled={isProcessing}>Cancel</button>,
			<button type="button" key="enable" className="btn btn-default" onClick={this.handleEnableClick} disabled={isProcessing}>Confirm</button>
		];

		return(
			<ModalDialog title="Are you sure you want to enable this integration?" buttons={buttons} hideCloseButton={true} showAlert={false} >
				<p>By enabling the integration, you are integrating your
					Pushpay data with an unaffiliated third party service. You should ensure that you are
					comfortable with the third party's terms and privacy policies, which may vary from those of Pushpay.</p>
			</ModalDialog>
		);
	}

	handleCancelClick = () => {
		const { userActionChannel } = this.props;
		userActionChannel.put(new EnableIntegrationUserAction.Cancel());
	}

	handleEnableClick = () => {
		const { userActionChannel } = this.props;
		this.setState({isProcessing: true});
		userActionChannel.put(new EnableIntegrationUserAction.EnableIntegration());
	}
}
