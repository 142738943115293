import * as React from 'react';
import { TransitionGroup } from 'react-transition-group-v1';
import { observer } from 'mobx-react';
import { Models } from '../check-deposit-generated';
import { BatchListItem } from './batch-list-item';
import { BatchListStore } from './batch-list-store';
import { LoadingBox } from '../../components/loading-box';
import BatchStatusFilter = Models.BatchStatusFilter;
import { BatchListHeader } from './batch-list-header/batch-list-header';

@observer
export class BatchList extends React.Component<{ store: BatchListStore }> {
	render() {
		const store = this.props.store;
		return (
			<div className="list-group-wrapper">
				<ProgressTabs store={store} />
				<BatchListHeader store={store} />
				<div className={`cd-batch-list-loading-wrapper${store.isCurrentlyProcessingUserInitiatedQuery ? ` loading` : ``}`}>
					<TransitionGroup component="div" className="list-group">
						{this.props.store.batches.map(batch => <BatchListItem key={batch.BatchId} batch={batch} store={store} />)}
					</TransitionGroup>
					{store.isCurrentlyProcessingUserInitiatedQuery && <LoadingBox text="Loading data" />}
				</div>
				<div className="list-group-load-more">
					<button onClick={store.loadMore} disabled={!store.canLoadMore} className="btn btn-ghost">Load More</button>
				</div>
			</div>
		);
	}
}

@observer
class ProgressTabs extends React.Component<{ store: BatchListStore }> {
	render() {
		const isStatusCompleted = this.props.store.queryModel.Status === BatchStatusFilter.Closed;
		return (
			<ul className="nav nav-tabs cd-batch-list-tabs">
				<li className={!isStatusCompleted ? `active` : ``}>
					<a href="#" tabIndex={!isStatusCompleted ? -1 : null} onClick={this.setProgressToInProgress}>In progress</a>
				</li>
				<li className={isStatusCompleted ? `active` : ``}>
					<a href="#" tabIndex={isStatusCompleted ? -1 : null} onClick={this.setProgressToCompleted}>Completed</a>
				</li>
			</ul>
		);
	}
	private setProgressToInProgress = (ev: React.MouseEvent<HTMLAnchorElement>) => {
		ev.preventDefault();
		this.props.store.setProgressToInProgress();
	}
	private setProgressToCompleted = (ev: React.MouseEvent<HTMLAnchorElement>) => {
		ev.preventDefault();
		this.props.store.setProgressToCompleted();
	}
}
