import * as React from 'react';
import { observer } from 'mobx-react';
import { isFunction } from '../utils/is-function';
import { CustomFieldViewModel, CommonControlProps, getCustomFieldProperties } from '../utils/customFieldsUtils';
import { getCustomFieldPropertyMetadata } from '../../Shared/utils/customFieldsValidation';
import { CustomFieldEditModel } from '../loggedinweb-generated';
import { FormControlLabelled } from './form-controls/form-control-labelled';

@observer
export class CustomField extends React.Component<{ customField: CustomFieldViewModel, className?: string, value: string, onChange: (field: CustomFieldEditModel) => void }, {}> {
	render() {
		const { customField, value, className } = this.props;

		const metadata = getCustomFieldPropertyMetadata(customField);
		const { propertyName, displayName, placeholder } = metadata;

		const commonControlProps = {
			name: propertyName,
			placeholder,
			validationRules: metadata.validationRules,
			value: typeof value === 'string' ? value : '',
			onChangeHandler: this.handleChange,
		} as CommonControlProps;

		const label = `${displayName}${customField.IsRequired ? `` : ` (optional)`}`;
		const controlProps = getCustomFieldProperties(customField, commonControlProps);

		return <FormControlLabelled cssClassNames={className} label={label} formControlProps={controlProps} />;
	}

	private handleChange = (event: React.FormEvent<HTMLInputElement>) => {
		const { customField: { Key, Type }, onChange } = this.props;
		if (isFunction(onChange)) {
			onChange({ Key, Type, Value: event.currentTarget.value });
		}
	}
}
