import * as React from 'react';

export interface IAccessibilityListboxProps {
	id?: string;
}

/**
 * https://www.w3.org/TR/wai-aria/roles#listbox
 *
 * @param {(IAccessibilityListboxProps & { children?: React.ReactNode })} props
 * @returns
 */
export const AccessibilityListbox: React.StatelessComponent<IAccessibilityListboxProps> = (props: IAccessibilityListboxProps & { children?: React.ReactNode }) => {
	const { id, children, ...other} = props;

	return React.cloneElement((React.Children.only(children) as React.ReactElement), {
		role: 'listbox',
		id,
		...other
	});
};
