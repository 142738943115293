import { isObservable, toJS } from 'mobx';
import { post } from '../../LoggedInWeb/utils/ajax-client';
import { AjaxUtils } from '../../LoggedInWeb/utils/ajax-utils';
import { ICancellablePromise } from '../../LoggedInWeb/utils/cancellable-promise';
import { getCancellablePromiseEffect } from '../../LoggedInWeb/utils/saga-utils';

export interface IApiAction {
	readonly url: (model: any) => string;
	request?: any;
	response?: any;
}

type ApiConfig = { defaultBaseUrl: () => string, actions: {[key: string]: IApiAction } };

type ApiActions<TConfig extends ApiConfig> = TConfig['actions'];

type ActionRequest<TConfig extends ApiConfig, TActionKey extends keyof ApiActions<TConfig>>
	= ApiActions<TConfig>[TActionKey]['request'];

type ActionResponse<TConfig extends ApiConfig, TActionKey extends keyof ApiActions<TConfig>>
	= ApiActions<TConfig>[TActionKey]['response'];

function createActionHandler<TConfig extends { defaultBaseUrl: () => string, actions: any },
	TKey extends keyof ApiActions<TConfig>>(apiConfig: TConfig, actionKey: TKey) {
	const actionConfig = apiConfig.actions[actionKey] as any;

	return (request: any) => {
		request = isObservable(request) ? toJS(request) : { ...request };
		return post(actionConfig.url(request), request, {
			baseUrl: AjaxUtils.resolveBaseUrl(apiConfig.defaultBaseUrl),
		});
	};
}

export type DataService<TConfig extends ApiConfig> = {
	[x in keyof ApiActions<TConfig>]: (request: ActionRequest<TConfig, x>) => ICancellablePromise<ActionResponse<TConfig, x>>;
};


function createDataService<TConfig extends ApiConfig>(apiConfig: TConfig): DataService<TConfig> {
	return (Object.keys(apiConfig.actions) as Array<keyof ApiActions<TConfig>>)
		.reduce<DataService<TConfig>>((acc: any, actionKey: keyof ApiActions<TConfig>) => {
			acc[actionKey] = createActionHandler(apiConfig, actionKey);
			return acc;
		}, {} as DataService<TConfig>);
}

export type SagaDataService<TConfig extends ApiConfig> = {
	[x in keyof ApiActions<TConfig>]: (request: ActionRequest<TConfig, x>) => Iterator<ActionResponse<TConfig, x>>
};

function createSagaDataService<TConfig extends ApiConfig>(apiConfig: TConfig): SagaDataService<TConfig> {
	return (Object.keys(apiConfig.actions) as Array<keyof ApiActions<TConfig>>)
		.reduce<SagaDataService<TConfig>>((acc: any, actionKey: keyof ApiActions<TConfig>) => {
			acc[actionKey] = function* (request: any) {
				return yield getCancellablePromiseEffect(createActionHandler(apiConfig, actionKey)(request));
			};
			return acc;
		}, {} as SagaDataService<TConfig>);
}

export function dataService<TConfig extends ApiConfig>(apiConfig: TConfig) {
	let cachedDataService: DataService<TConfig> | null = null;
	let cachedSagaDataService: SagaDataService<TConfig> | null = null;

	return {
		getPromiseService: (): DataService<TConfig> => {
			if (!cachedDataService) {
				cachedDataService = createDataService(apiConfig);
			}
			return cachedDataService;
		},
		getSagaService: (): SagaDataService<TConfig> => {
			if (!cachedSagaDataService) {
				cachedSagaDataService = createSagaDataService(apiConfig);
			}
			return cachedSagaDataService;
		},
		mock: (mockDataService) => {
			cachedDataService = mockDataService;
			cachedSagaDataService = mockDataService;
		},
	};
}
