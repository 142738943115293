import { action, observable, runInAction } from 'mobx';
import { Models } from '../check-deposit-generated';

import * as CheckDepositClient from './registered-scanners-ajax-client';
import { PostError } from '../../utils/ajax-client';

export class RegisterScannerDialogViewModel {
	@observable isOpen: boolean;
	@observable availableModels: string[];
	@observable request: Models.RegisterScannerViewModel;
	@observable invalidFields: { [fieldName: string]: string };
	@observable isProcessingRequest: boolean;

	onScannerRegistered: (response: Models.RegisteredScannerViewModel[]) => void;
	onClose: () => void;

	private registerEndpoint: string;

	constructor(store: Models.RegisterScannerDialogViewModel, onScannerRegistered: (scanners: Models.RegisteredScannerViewModel[]) => void, onClose?: () => void) {
		this.isOpen = false;
		this.availableModels = store.AvailableModels;
		this.request = {
			Model: '',
			SerialNumber: ''
		};

		this.invalidFields = {};
		this.isProcessingRequest = false;
		this.onScannerRegistered = onScannerRegistered;
		this.onClose = onClose;
		this.registerEndpoint = store.RegisterNewScannerEndpoint;
	}

	@action
	openDialog = () => {
		this.clearModel();
		this.isOpen = true;
	}

	@action
	closeDialog = () => {
		if (!this.isProcessingRequest) {
			this.isOpen = false;

			if (this.onClose) {
				this.onClose();
			}
		}
	}

	@action
	updateModel = (modelName: string) => {
		this.request.Model = modelName;
	}

	@action
	updateSerialNumber = (serialNumber: string) => {
		this.request.SerialNumber = serialNumber;
	}

	@action
	onSubmitted = () => {
		this.isProcessingRequest = true;

		CheckDepositClient.registerScanner(this.registerEndpoint, this.request)
			.then(response => {
				runInAction('RegisterScannerDialogViewModel.handleSubmit[onFulfilled]', () => {
					this.isProcessingRequest = false;
					this.closeDialog();

					this.onScannerRegistered(response.RegisteredScanners);
				});
			})
			.catch(error => {
				runInAction('RegisterScannerDialogViewModel.handleSubmit[onRejected]', () => {
					this.isProcessingRequest = false;

					if (error instanceof PostError) {
						this.invalidFields = error.validationErrors;
					}
				});
			});
	}

	@action
	clearModel = () => {
		this.request = {
			Model: '',
			SerialNumber: ''
		};
		this.invalidFields = {};
	}
}
