import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { DataGrid, IDataGridColumn } from '../../../components/data-grid/data-grid';
import { BatchEntryPaymentViewModel, PaymentLabel } from '../../virtual-terminal-generated';
import { formatAmountColumn, formatPaymentIconColumn, formatPayerColumn, formatEditColumn } from '../../utils/grid-helpers';
import { ResponsiveVisibility, ResponsiveBreakpoint } from '../../../helpers/responsive-helper';
import { BatchEntryUserActionCreator } from '../../pages/batch-entry/batch-entry-user-actions';
import { SmoothHeightTransition } from '../../../components/hoc-behavior/transitions';
import { BatchEntryPaymentDetails } from './batch-entry-payment-details';
import { BatchEntryPaymentsStore } from './batch-entry-payments-store';

type BatchEntryPaymentViewModelColumns = Partial<{ [K in keyof BatchEntryPaymentViewModel]: IDataGridColumn }>;

@inject('userActionCreator')
@observer
export class BatchEntryPaymentsGrid extends React.Component<{
	paymentLabel: PaymentLabel,
	store: BatchEntryPaymentsStore,
	userActionCreator?: BatchEntryUserActionCreator,
}> {
	private columns: BatchEntryPaymentViewModelColumns;

	constructor(props) {
		super(props);

		const { paymentLabel: { PayerSentenceCase, NounLowerCase } } = this.props;

		this.columns = {
			...getBatchGiftsGridColumnConfig(PayerSentenceCase),
			EditUrl: { displayName: '', formatter: (item, classNames) => formatEditColumn(item, classNames, NounLowerCase) },
		};
	}

	render() {
		const {
			store: {
				paymentsModel: { BatchEntryPayments: payments }
			},
			paymentLabel: { NounPluralSentenceCase, NounLowerCase, NounPluralLowerCase }
		} = this.props;
		const paymentCount = payments && payments.length || 0;

		if (paymentCount === 0) {
			return <SmoothHeightTransition />;
		}
		return(
			<SmoothHeightTransition>
				<div className="vt-payments">
					<h2>{NounPluralSentenceCase}</h2>
					<BatchEntryPaymentsText paymentCount={paymentCount} nounLowerCase={NounLowerCase} nounPluralLowerCase={NounPluralLowerCase} />
					<div className="vt-payments-grid" data-pp-at-target="Gifts table">
						<DataGrid columns={this.columns}
							data={payments}
							getRowKey={getBatchEntryPaymentRowKey}
							renderExpandedRow={this.renderPaymentDetails}
						/>
					</div>
				</div>
			</SmoothHeightTransition>
		);
	}

	private renderPaymentDetails = (data: BatchEntryPaymentViewModel, updateExpandedRow: (key: string) => void) => {
		const { paymentLabel, store: { acceptableLoadingTimeExceeded, expandedPayment, setExpandedPayment } } = this.props;

		return <BatchEntryPaymentDetails
			payment={data}
			paymentLabel={paymentLabel}
			expandedPayment={expandedPayment}
			acceptableLoadingTimeExceeded={acceptableLoadingTimeExceeded}
			updateExpandedRow={updateExpandedRow}
			setExpandedPayment={setExpandedPayment}
		/>;
	}
}

const BatchEntryPaymentsText = ({ paymentCount, nounLowerCase, nounPluralLowerCase }: { paymentCount: number, nounLowerCase: string, nounPluralLowerCase: string }) => {
	if (paymentCount === 1) {
		return <p>There is <strong>{paymentCount} {nounLowerCase}</strong> in this batch.</p>;
	}
	return <p>There are <strong>{paymentCount} {nounPluralLowerCase} in this batch</strong></p>;
};

const getBatchEntryPaymentRowKey = (payment: BatchEntryPaymentViewModel) => payment.EncodedToken;

export function getBatchGiftsGridColumnConfig(PayerSentenceCase: string): BatchEntryPaymentViewModelColumns {
	return {
		PaymentStatus: { displayName: 'Status', formatter: formatPaymentIconColumn },
		PayerDisplayName: { displayName: PayerSentenceCase, formatter: formatPayerColumn },
		PayerDisplayEmail: {
			displayName: 'Email address',
			responsiveVisibility: ResponsiveVisibility.Hidden,
			responsiveBreakpoints: ResponsiveBreakpoint.Xs|ResponsiveBreakpoint.Sm,
		},
		PaymentMethod: {
			displayName: 'Method',
			responsiveVisibility: ResponsiveVisibility.Hidden,
			responsiveBreakpoints: ResponsiveBreakpoint.Xs,
		},
		FundDisplayValue: { displayName: 'Funds' },
		AmountAsMoney: { displayName: 'Amount', formatter: formatAmountColumn },
	};
}
