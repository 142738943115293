import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { Button } from '../../../../Shared/components/button/button';
import { MatchModal } from '../../../components/match-modal';
import { ErrorModalStates, SendPageEvents, SendPageStates } from '../../state/send-page';
import { MachineContext, machineContextKey } from '../../../../Shared/state-machine/saga-state-machine';
import { SvgWrapper } from '../../../../Shared/components/svg-wrapper';
import { MainEvents } from '../../state/main';


export interface IErrorModalModalProps {
	showWhenInState: string | string[];
	machineContext?: MachineContext;
}

@inject(machineContextKey)
@observer
export class ErrorModal extends React.Component<IErrorModalModalProps> {
	render() {
		const {  machineContext, showWhenInState } = this.props;

		const wrapperClassNames = ['modal-content'];

		if (machineContext.matchesState(ErrorModalStates.VISIBLE)) {
			wrapperClassNames.push('panel', 'panel-default');
		}

		const atTarget = showWhenInState === SendPageStates.DOWNLOAD_NO_EMAIL_MODAL_ACTIVE ? 'Download statements no email' : 'Download all statements';

		return (
			<MatchModal state={showWhenInState}>
				<div className={wrapperClassNames.join(' ')} data-pp-at-target={atTarget}>
					<Button className="close" aria-label="Close" onClick={this.onClose} />
						<div className="modal-body">
							<SvgWrapper svg="alert-exclamation-icon" width={68} height={68}/>
							<h2>Error</h2>
							<p>Oops, something went wrong</p>
						</div>
						<div className="modal-footer">
							<Button className="btn btn-cancel" aria-label="Close" onClick={this.onClose}>
								Close
							</Button>
						</div>
				</div>
			</MatchModal>
		);
	}

	onClose = () => {
		this.props.machineContext.transition(MainEvents.LOAD_LANDING_VIEW);
	}
}
