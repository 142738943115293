import * as React from 'react';
import { observer } from 'mobx-react';
import { PaymentLabel } from '../../../schedule-import-generated';
import { Fragment } from '../../../../../Shared/components/fragment';
import { MigrationDateRange } from '../../../components/migration-date-range/migration-date-range';
import { MigrationDateRangeViewModel } from '../../../components/migration-date-range/migration-date-range-view-model';

@observer
export class PanelDateRange extends React.Component<{vm: MigrationDateRangeViewModel, paymentLabel: PaymentLabel}> {
	render() {
		const {
			vm,
			paymentLabel,
		} = this.props;
		return (
			<Fragment>
				<div className="panel-heading">Select date range</div>
				<div className="panel-body">
					{/* tslint:disable:max-line-length */}
					<p>Setting a date range provides clarity to help move {paymentLabel.PayerPluralLowerCase} in a timely manner. Your first email will be sent on the start date. The minimum migration period is 60 days.</p>
					<MigrationDateRange vm={vm}
						startDateTip={`The first email to your ${paymentLabel.PayerPluralLowerCase} will be sent on this date.`}
						endDateTip={`Schedules will be pre-configured to start after this date. Once the end date has been reached your ${paymentLabel.PayerPluralLowerCase} can still migrate to Pushpay but no further reminder emails will be sent.`}
					/>
					{/* tslint:enable:max-line-length */}
				</div>
			</Fragment>
		);
	}
}
