import { ScheduleImportExportConfig } from './schedule-import-generated';
import { GenericExportService, createNonAjaxPostService } from '../utils/non-ajax-post-request';

export type ScheduleImportExportDataService = GenericExportService<typeof ScheduleImportExportConfig>;

let instance: ScheduleImportExportDataService | null = null;

export function getScheduleImportExportDataService() {
	if (instance === null) {
		instance = createNonAjaxPostService(ScheduleImportExportConfig);
	}

	return instance;
}
