import * as React from 'react';
import { observer } from 'mobx-react';
import { Button } from '../../components/button';
import { ModalDialog, IModalDialogButton } from '../../components/modal-dialog';
import { IntegrationServiceClient } from './integration-service-client';

export interface IDeleteIntegrationModalDialogProps {
	store: IntegrationServiceClient;
}

@observer
export class RemoveIntegrationModalDialog extends React.Component<IDeleteIntegrationModalDialogProps, {}> {
	render() {
		const deleteButton = <Button key="delete_button"
			className="btn btn-primary"
			onClick={this.props.store.proceedDeletingIntegration}
			isProcessingRequest={this.props.store.isDeleting}
			disabled={this.props.store.isProcessingRequest}>Delete <span className="hidden-xs">integration</span></Button>;

		const buttons: (IModalDialogButton | JSX.Element)[] = [
			{
				label: 'Cancel',
				dismiss: true,
				isPrimary: false,
				hideOnProgress: true
			},
			deleteButton];

		return (
			<ModalDialog title="" buttons={buttons} inProgress={this.props.store.isDeleting}>
				<p>Are you sure?</p>
				<p>Deleting your integration will also delete all of your settings.</p>
			</ModalDialog>
		);
	}
}
