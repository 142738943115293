import * as React from 'react';
import { observer, Provider } from 'mobx-react';
import { BatchListMainViewModel } from './batch-list-main-view-model';
import { BatchList } from '../../components/batch-list/batch-list';
import { SvgWrapper } from '../../../components/svg-wrapper';
import { BatchListUserAction } from './batch-list-user-actions';
import { AnalyticsContext, injectAnalytics, recordEvent, WithAnalytics } from '../../../analytics';
import { RenderResponsive } from '../../../components/hoc-behavior/render-responsive';
import { ResponsiveVisibility, ResponsiveBreakpoint } from '../../../helpers/responsive-helper';
import { classNames } from '../../../../Shared/utils/classnames';
import { BatchListSagaContext, getActionHandlers } from './batch-list-saga';
import { runVirtualTerminalSaga } from '../../saga/virtual-terminal-saga';
import { BatchEntryIntro } from '../../components/batch-entry-intro';
import { StorageHelper } from '../../../helpers/storagehelper';

import * as styles from './batch-list-main.less';

interface IBatchListMainProps {
	vm: BatchListMainViewModel;
}

const CreateButton = injectAnalytics(function CreateButton(props: WithAnalytics & {cssClassNames: string, url: string}) {
	const { url, cssClassNames, analytics } = props;
	return (
		<a
			className={classNames('btn', 'btn-sm', 'btn-link', cssClassNames)}
			href={url}
			onClick={() => {
				if (NewFeatures.SetupPinpointAnalytics && analytics) {
					const { feature, subFeature } = analytics;
					recordEvent({ feature, subFeature, eventTypeLabel: 'clickCreate', immediate: true });
				}
			}}
			data-pp-at-target="create batch"
		>
			<SvgWrapper svg="icon-add" className="icon" /> Create batch
		</a>
	);
});

const analyticsContext: AnalyticsContext = {
	feature: 'giftEntry',
	subFeature: 'batchGiftEntry',
}

@observer
export class BatchListMain extends React.Component<IBatchListMainProps, {}> {
	constructor(props: IBatchListMainProps) {
		super(props);
		const { vm } = props;
		const batchListSagaContext = new BatchListSagaContext(vm);

		runVirtualTerminalSaga(batchListSagaContext, getActionHandlers());

		vm.setUserActionChannel(batchListSagaContext.userActionChannel);
		vm.userActionChannel.put(new BatchListUserAction.QueryBatches());
	}

	componentDidMount() {
		//Clean up any expired default settings from local storage
		window.setTimeout(StorageHelper.cleanupExpiredDefaultSettings, 0);
	}

	render() {
		const { CreateBatchUrl, HelpCenterUrl, PaymentLabel: { NounPluralLowerCase } } = this.props.vm.viewData;

		return (
			<Provider analytics={analyticsContext}>
				<>
					<header className={styles.header}>
						<h1 className={styles.title}>Batch Entry</h1>
						<RenderResponsive visibility={ResponsiveVisibility.Visible} breakpoints={ResponsiveBreakpoint.Md|ResponsiveBreakpoint.Lg}>
							<CreateButton url={CreateBatchUrl} cssClassNames={`pull-right ${styles.buttonCreateDesktop}`} />
						</RenderResponsive>
					</header>
					<BatchEntryIntro paymentNoun={NounPluralLowerCase} />
					<RenderResponsive visibility={ResponsiveVisibility.Visible} breakpoints={ResponsiveBreakpoint.Xs|ResponsiveBreakpoint.Sm}>
						<CreateButton url={CreateBatchUrl} cssClassNames={styles.buttonCreateMobile} />
					</RenderResponsive>
					<BatchList {...this.props} />
					<footer className={styles.footer}>
						Learn how to enter {NounPluralLowerCase} and create your batch <a
							className="arrow-hollow-right-after"
							href={HelpCenterUrl}
							data-pp-at-target="help center"
							target="_blank" rel="noopener noreferrer"
						>Help Center</a>
					</footer>
				</>
			</Provider>
		);
	}
}
