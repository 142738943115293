import * as React from 'react';
import { action, computed, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { SmoothHeightTransition } from '../../components/hoc-behavior/transitions';
import { ResponseTypes, ModelMetadata } from '../../funds/funds-generated';

import { FormActions } from '../components/form-actions';
import { Form } from '../../funds/components/form-controls';
import { ValidationSummary } from '../../components/form-controls/validation-summary';
import { FormControlFileInput } from '../../components/form-controls/form-control-file-input/form-control-file-input';
import { FileUploadViewState } from '../../components/file-upload/file-upload-state';

import { IntegrationServiceClient } from '../components/integration-service-client';
import IntegrationFundsFileUploadVisibility = ResponseTypes.IntegrationFundsFileUploadVisibility;

export interface IIntegrationFundsFileUploadStore {
	viewModel: ResponseTypes.IntegrationViewModel;
	serviceClient: IntegrationServiceClient;
	fundsFileUploadVisibility: IntegrationFundsFileUploadVisibility;
}

@observer
export class IntegrationFundsFileUpload extends React.Component<{ controller: IIntegrationFundsFileUploadStore }, {}> {

	@observable
	private fileSelectionState = {
		viewState: FileUploadViewState.Empty,
		uploadError: '',
		previewUrl: '',
		value: '',
		file: null,
	};

	private readonly maxFundsFileSize = 3 * 1014 * 1024;

	handleSubmit = (form: HTMLFormElement) => {
		if (this.fileSelectionState.file === null || this.fileSelectionState.viewState === FileUploadViewState.Error) {
			this.fileSelectionError('Funds file for upload not selected.');
			return;
		}

		var data = new FormData();
		data.append('FundsFile', this.fileSelectionState.file, this.fileSelectionState.previewUrl);
		this.props.controller.serviceClient.saveFundsFile(data);
	}

	render() {
		return (
			<div className="form-group">
			<Form onSubmit={this.handleSubmit}>
				<div className="panel panel-default">
					<div className="panel-heading">
						<span>Funds file upload</span>
					</div>
						<div className="panel-body">
							<ValidationSummary />
							<div className="organization-settings">
								<div className="form-group">
									<div>Before you can link all your Pushpay funds to your funds in Shelby Arena you will have to upload a full list of your Shelby funds.</div>
								</div>
								<div className="form-group">
									<div>You can export your funds from the Contributions - Fund Management screen in Shelby Arena by clicking on the XLS icon below the list of funds.</div>
								</div>
								<div className="form-group">
									<div>Note: After exporting your funds from Arena, do not open, edit, or rename the file, as this will corrupt the formatting and prevent a successful upload.</div>
								</div>
								<FormControlFileInput
									fileCurrent={this.fileSelectionState as any}
									fileAccept=".xls"
									name="FundsFileUpload"
									onChangeHandler={this.handleFileChange}
									acceptanceTestTargetId="FundsFileUpload"
								/>
							</div>
						</div>
					<FormActions
						isSettingUp={this.props.controller.serviceClient.isSettingUp}
						isProcessingRequest={this.props.controller.serviceClient.isProcessingRequest}
						cancelButtonAction={this.props.controller.viewModel.ActionUrls.ConfigureIntegrationMappingsUrl}
						submitButtonText={'Upload'}
						cancelButtonText={'Skip'}/>
				</div>
			</Form>
			</div>
		);
	}

	handleFileChange = (newValue) => {
		runInAction(() => {
			if (newValue === null) {
				this.updateFileSelectionState(FileUploadViewState.Empty, '', null, '');
				return;
			}

			if (newValue.size === 0) {
				this.fileSelectionError('The file is empty. Please use the file downloaded from Shelby Arena web application.');
				return;
			}

			if (newValue.size > this.maxFundsFileSize) {
				this.fileSelectionError('The file is too large. Please use the file downloaded from Shelby Arena web application.');
				return;
			}

			this.updateFileSelectionState(FileUploadViewState.FileSelected, newValue.name, newValue, '');
		});
	}

	private fileSelectionError(errorMessage: string) {
		this.updateFileSelectionState(FileUploadViewState.Error, '', null, errorMessage);
	}

	private updateFileSelectionState(state: FileUploadViewState, previewUrl: string, file: File, errorMessage: string) {
		this.fileSelectionState.viewState = state;
		this.fileSelectionState.previewUrl = previewUrl;
		this.fileSelectionState.file = file;
		this.fileSelectionState.uploadError = errorMessage;
	}
}
