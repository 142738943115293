import * as React from 'react';
import { observer } from 'mobx-react';
import * as styles from './deliver-statements-tabs.less';
import { Fragment } from '../../../../Shared/components/fragment';
import { PanelHeader, PanelBody } from '../../../components/panel';

export interface ITabProps {
	label: string;
	tabIndex?: number;
	isSelected?: boolean;
	moreThanOne?: boolean;
	onSelect?: (tabIndex: number) => void;
}

export class Tab extends React.Component<ITabProps> {
	render() {
		const { label, tabIndex, isSelected, moreThanOne } = this.props;
		const classNames = `${styles.tab} ${isSelected ? styles.active : ''}`;

		return (
			moreThanOne &&
				<li role="presentation" className={classNames}>
				<button className={styles.button} type="button" value={tabIndex} onClick={this.onClick}>
					<span className={styles.label}>{label}</span>
				</button>
				</li> || null
		);
	}

	private onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		const { onSelect } = this.props;
		var tabIndex = Number(event.currentTarget.value);

		onSelect(tabIndex);
	}
}

export interface ITabsProps {
	selectedIndex: number;
	onChange: (tabIndex: number) => void;
	children: React.ReactElement<ITabProps> | React.ReactElement<ITabProps>[];
}

@observer
export class Tabs extends React.Component<ITabsProps> {

	render() {
		const { selectedIndex } = this.props;
		const selectedTab = React.Children.toArray(this.props.children)[selectedIndex] as React.ReactElement<any>;
		let moreThanOne = React.Children.toArray(this.props.children).length > 1;
		return (
			<PanelBody extraClassNames={styles.removeTopBorder}>
				<ul role="presentation" className={moreThanOne ? styles.tabs : styles.tabsNoUnderline}>{this.renderTabs(moreThanOne)}</ul>
				<div className={moreThanOne ? styles.contentPt : styles.content}>{selectedTab.props.children}</div>
			</PanelBody>
		);
	}

	private renderTabs( moreThanOne: boolean): React.ReactElement<ITabProps>[] {
		const { selectedIndex } = this.props;
		return React.Children.map(React.Children.toArray(this.props.children), (child: React.ReactElement<ITabProps>, index) => {
			return React.cloneElement(child, {
				key: index.toString(),
				tabIndex: index,
				isSelected: index === selectedIndex,
				onSelect: this.onSelect,
				moreThanOne: moreThanOne,
			} as any);
		});
	}

	private onSelect = (tabIndex: number) => {
		this.props.onChange(tabIndex);
	}
}
