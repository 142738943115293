import * as React from 'react';
import { ResponseTypes, ModelMetadata } from '../../funds/funds-generated';
import { observer } from 'mobx-react';
import { computed } from 'mobx';

import TooltipComponent from '../components/configuration-section-tooltip';

import { InputField, ValidationMessage } from '../../funds/components/form-controls';

import { ListingModel } from '../components/listing-model';
import { IntegrationConfigurationStore } from '../components/integration-configuration-store';
import { AuthenticationSection } from '../components/authentication-section';
import { ConfigurationSectionHeader } from '../components/configuration-section-header';
import { IntegrationContainer } from '../components/integration-container';
import { ConfigurationSection } from '../components/configuration-section';
import { ListingSettingsEditor } from '../components/listing-configuration';
import { FallbackFundSelector } from '../components/fallback-fund-selector';
import getFundsForDisplay from '../helpers/get-integration-funds-for-display';

import ThinkMinistryConfigurationViewModel = ResponseTypes.ThinkMinistryConfigurationViewModel;
import ThinkMinistryListingConfigurationModel = ResponseTypes.ThinkMinistryListingConfigurationModel;
import IntegrationListingConfigurationViewModel = ResponseTypes.IntegrationListingConfigurationViewModel;

export class ThinkMinistryIntegrationConfigurationStore
	extends IntegrationConfigurationStore<ThinkMinistryListingConfigurationModel, ThinkMinistryConfigurationViewModel> {

	@computed
	get funds() {
		return getFundsForDisplay(this.configuration.IntegrationFunds);
	}

	createListing(listing: IntegrationListingConfigurationViewModel<ThinkMinistryListingConfigurationModel>) {
		const messageWhenMissingFallbackFunds = this.isListingMissingFallbackFund(listing, this.configuration.IntegrationFunds)
			? ModelMetadata.ThinkMinistryListingConfigurationModel.FallbackFundKey.validationRules.required.errorMessage
			: null;

		return new ListingModel(listing, this.funds, messageWhenMissingFallbackFunds);
	}
}

@observer
export class ThinkMinistryIntegration extends React.Component<{ controller: ThinkMinistryIntegrationConfigurationStore }, {}> {

	renderAuthenticationSection = () => {
		return <ThinkMinistryAuthenticationSection controller={this.props.controller} />;
	}

	renderConfigurationSection = () => {
		return <ThinkMinistryConfigurationSection controller={this.props.controller} />;
	}

	render() {
		return <IntegrationContainer
			isAuthenticationSectionEditable={this.props.controller.isAuthenticationSectionEditable}
			authenticationSectionFactory={this.renderAuthenticationSection}
			configurationSectionFactory={this.renderConfigurationSection} />;
	}
}

@observer
class ThinkMinistryAuthenticationSection extends React.Component<{ controller: ThinkMinistryIntegrationConfigurationStore }, {}> {
	render() {
		return (
			<AuthenticationSection store={this.props.controller}>
				<div className="form-group">
					<label className="col-md-3 control-label">{this.metadata.InstanceUrl.displayName}</label>
					<div className="col-md-5">
						<InputField type="text" propertyMetadata={this.metadata.InstanceUrl} ignorePanLikeValidation={true}/>
					</div>
					<div className="col-md-3">
						<ValidationMessage for={this.metadata.InstanceUrl.propertyName} />
					</div>
				</div>
				<div className="form-group">
					<label className="col-md-3 control-label">{this.metadata.ClientId.displayName}</label>
					<div className="col-md-5">
						<InputField type="text" propertyMetadata={this.metadata.ClientId} ignorePanLikeValidation={true}/>
					</div>
					<div className="col-md-3">
						<ValidationMessage for={this.metadata.ClientId.propertyName} />
					</div>
				</div>
				<div className="form-group">
					<label className="col-md-3 control-label">{this.metadata.ClientSecret.displayName}</label>
					<div className="col-md-5">
						<InputField type="text" propertyMetadata={this.metadata.ClientSecret} ignorePanLikeValidation={true}/>
					</div>
					<div className="col-md-3">
						<ValidationMessage for={this.metadata.ClientSecret.propertyName} />
					</div>
				</div>
			</AuthenticationSection>
		);
	}

	private get metadata() {
		return ModelMetadata.ThinkMinistryAuthenticationEditModel;
	}
}

const message = `If we run into any trouble matching transactions to Ministry Platform
we'll use the fallback information you've selected here.`;

// ReSharper disable once InconsistentNaming
const ThinkMinistryListingRowHeader = React.createFactory(() => (
	<span><span>Select fallback funds in Ministry Platform</span><TooltipComponent message={message} /></span>
));

@observer
class ThinkMinistryConfigurationSection extends React.Component<{ controller: ThinkMinistryIntegrationConfigurationStore }, {}> {
	render() {
		return (
			<div>
				<ConfigurationSectionHeader store={this.props.controller} />
				<ConfigurationSection
					store={this.props.controller}
					headerComponentFactory={ThinkMinistryListingRowHeader}
					organizationSettings={null}
					listingSettingsEditor={ThinkMinistryListingSettingsEditor} />
			</div>
		);
	}
}

@observer
class ThinkMinistryListingSettingsEditor extends React.Component<{ listing: ListingModel<ThinkMinistryListingConfigurationModel> }, {}> {
	render() {
		return (
			<ListingSettingsEditor>
				<FallbackFundSelector
					propertyMetadata={this.metadata.FallbackFundKey}
					listing={this.props.listing}
					defaultValue={this.props.listing.model.FallbackFundKey} />
			</ListingSettingsEditor>
		);
	}

	private get metadata() {
		return ModelMetadata.ThinkMinistryListingConfigurationModel;
	}
}
