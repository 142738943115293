import {
	AchBankAccountPaymentMethod,
	CardPaymentMethod,
	CashPaymentMethod,
	RecordedAchBankAccountPaymentMethod,
	RecordedCardPaymentMethod,
	RecordedCheckPaymentMethod,
	PaymentMethod,
	NonCashPaymentMethod
} from '../components/payment-entry/payment-entry-model';
import { PaymentMethodType, VirtualTerminalListingConfiguration, ExistingPaymentMethodViewModel } from '../virtual-terminal-generated';

export function paymentMethodsForListing(listingConfiguration: VirtualTerminalListingConfiguration, allowNonCash: boolean): PaymentMethod[] {
	var paymentMethods = paymentMethodTypesForListing(listingConfiguration).map<PaymentMethod>(paymentMethodType => {
		switch (paymentMethodType) {
			case PaymentMethodType.CreditCard:
				return new CardPaymentMethod();
			case PaymentMethodType.Cash:
				return new CashPaymentMethod();
			case PaymentMethodType.ACH:
				return new AchBankAccountPaymentMethod();
			case PaymentMethodType.RecordedACH:
				return new RecordedAchBankAccountPaymentMethod();
			case PaymentMethodType.RecordedCreditCard:
				return new RecordedCardPaymentMethod();
			case PaymentMethodType.RecordedCheck:
				return new RecordedCheckPaymentMethod();
			default:
				return null;
		}
	}).filter(x => x);

	if (listingConfiguration.SupportsNonCash
		&& allowNonCash) {
		paymentMethods.push(new NonCashPaymentMethod());
	}

	return paymentMethods;
}

export function paymentMethodTypesForListing(listingConfiguration: VirtualTerminalListingConfiguration): PaymentMethodType[] {
	const { SupportedPaymentMethodTypes, CardTokenizationAvailable } = listingConfiguration;
	return filterOutCreditCardIfTokenizationIsNotAvailable(SupportedPaymentMethodTypes, CardTokenizationAvailable);
}

export function existingPaymentMethodsForListing(existingPaymentMethods: ExistingPaymentMethodViewModel[], listingConfiguration: VirtualTerminalListingConfiguration) {
	const { CardTokenizationAvailable } = listingConfiguration;
	return existingPaymentMethods.filter(x => CardTokenizationAvailable || x.PaymentMethodType !== PaymentMethodType.CreditCard);
}

function filterOutCreditCardIfTokenizationIsNotAvailable(paymentMethods: PaymentMethodType[], cardTokenizationAvailable: boolean): PaymentMethodType[] {
	return paymentMethods.filter(x => x !== PaymentMethodType.CreditCard || cardTokenizationAvailable);
}
