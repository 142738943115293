export module MaskedInputHelper {
	const maskedInputPlaceholderCharacter = '_';

	export function setCursorToFirstPlaceholder(event: React.FormEvent<HTMLInputElement>) {
		const input = event.currentTarget;
		const firstPlaceholderPosition = input.value.indexOf(maskedInputPlaceholderCharacter);
		if (firstPlaceholderPosition !== -1) {
			input.setSelectionRange(firstPlaceholderPosition, firstPlaceholderPosition);
		}
	}
}

