import { observable, action } from 'mobx';
import {
	GivingStatementHistoricSummaryListViewModel, GivingStatementRunStatus, PaymentLabel
} from '../../giving-statements-generated';
import { IStatementHistory } from '../../components/statement-history/statement-history';

export class LandingPageViewModel {
	@observable statementHistory: IStatementHistory[] | null = null;
	@observable newMemberCount: number;
	@observable scrollToRunsList: boolean;

	constructor(
		public communityMemberUrl: string,
		public transactionImportUrl: string,
		public fundsUrl: string,
		public giftEntryUrl: string,
		public paymentLabel: PaymentLabel,
		public allCommunityMembersAreReviewed: boolean,
	) {}

	@action
	setNewMemberCount(newMemberCount: number) {
		this.newMemberCount = newMemberCount;
	}

	@action
	updateStatementHistory(history: GivingStatementHistoricSummaryListViewModel[]) {
		this.statementHistory = history.map(item => new StatementInformation(item));
	}

	@action
	setScrollToRunsList(value: boolean) {
		this.scrollToRunsList = value;
	}
}

export class StatementInformation {
	@observable runId: string;
	@observable name: string;
	@observable campusName: string;
	@observable status: GivingStatementRunStatus;
	@observable count: number;

	constructor(info: GivingStatementHistoricSummaryListViewModel) {
		this.runId = info.RunId.toString(10);
		this.name = info.Name;
		this.status = GivingStatementRunStatus[info.Status];
		this.count = info.Count;
	}
}
