import * as React from 'react';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import { Member } from '../view-models/index';
import { PopupLink, popupHandler } from '../../components/popup-helper';
import { GivingStatementsByNameAndPreviewabilityResponseModel, StatementModel } from '../models/index';
import { ModalDialogCommander } from '../../components/modal-dialog-commander';
import { TooltipOld } from '../../components/tooltip-old';
import { layout } from '../../layout/layout';
import { Formatter } from '../../helpers/formatter';
import moment from 'moment';

export interface IMemberStatementsProps {
	vm: Member;
}

@observer
export class MemberCustomStatements extends React.Component<IMemberStatementsProps, {}> {

	constructor(props) {
		super(props);
		reaction(this.popupOpened, this.previewStatement);
	}

	render() {

		let { vm: { customStatements }} = this.props;
		if(!customStatements || customStatements.length <= 0) {
			return (<span>There are no transactions eligible for a giving statement this period</span>);
		}

		return  customStatements && (
			<div> { customStatements.map((statementType: GivingStatementsByNameAndPreviewabilityResponseModel) => {
						return (
							<div className="statement-list" key={statementType.Name}>
								<h3>{statementType.Name}</h3>
								<h6>
									<b>Statement type: </b>{statementType.Type}
									<span className="separator"/>
									<b> Statement period: </b>{this.dateIncludingYearIfYearsAreDifferent(statementType.PeriodFrom, statementType.PeriodTo)
									+ ` - ` + Formatter.formatDate(statementType.PeriodTo, 'MMM D, YYYY')}
								</h6>
								{
									(statementType.Statements && statementType.Statements.length > 0)
									?  (
										<table className="table table-line statements-table">
											<thead>
											<tr>
												<th className="created-on-column">Created on</th>
												<th className="sent-on-column">Sent on</th>
												<th className="created-by-column">Created by</th>
												<th className="links-column"/>
											</tr>
											</thead>
											<tbody>
											{
												statementType.CanPreview && (
													<tr>
														<td><strong>Draft</strong></td>
														<td>-</td>
														<td>-</td>
														<td><PopupLink popup="community-preview-statement" onClick={()=>this.setGlobalState(statementType.Id, statementType.CommunityMemberId)}>Preview</PopupLink>
														</td>
													</tr>
												)
											}
											{
												statementType.Statements && statementType.Statements.map(statement => (
													<tr key={statement.StatementId}>
														<td>{statement.CreatedOn}</td>
														<td>{statement.EmailedOn}</td>
														<td>{statement.CreatedBy}</td>
														<td><DownloadLink statement={statement}/></td>
													</tr>
												))
											}
											</tbody>
										</table>
									)
									: <span>There are no transactions eligible for a giving statement this period</span>
								}
							</div>
						);
					})
				}
			</div>
		);
	}

	private setGlobalState(runId : number, memberId : number) {
		// this is picked up by PreviewStatement theres no way to pass information directly to it.
		this.props.vm.parent.statementToPreviewMemberId = memberId;
		this.props.vm.parent.statementToPreviewRunId = runId;
	}

	private dateIncludingYearIfYearsAreDifferent(from : Date, to : Date) : string  {
		var momentFrom = moment(from);
		var momentTo = moment(to);
		if(momentFrom.year() === momentTo.year()) {
			return Formatter.formatDate(from, 'MMM D');
		}
		return Formatter.formatDate(from, 'MMM D, YYYY');
	}

	private popupOpened = () => {
		return popupHandler.currentPopupName === 'community-preview-statement';
	}

	private previewStatement = (shouldPreview: boolean) => {
		if (!shouldPreview) {
			return;
		}
		ModalDialogCommander.forceCloseCurrent();
	}
}

interface IDownloadLinkProps extends React.Props<DownloadLink> {
	statement: StatementModel;
}

class DownloadLink extends React.Component<IDownloadLinkProps, {}> {
	element: HTMLElement;

	componentDidMount() {
		if (this.element) {
			const layoutInstance = layout();
			layoutInstance.tooltips.push(new TooltipOld(this.element));
		}
	}

	render() {
		const { statement } = this.props;
		if (!statement.CompletedOn) {
			return (<span>In progress</span>);
		}

		if (statement.NoPaymentsFound) {
			return (<span ref={el => this.element = el}
						title="Generation was skipped for this statement as there were no tax deductible transactions in the giving statement's period">
						Skipped
					</span>);
		}

		return (<a href={statement.DownloadUrl} target="_blank" rel="noopener noreferrer">Download</a>);
	}
}
