import * as React from 'react';
import { EditMemberFormContainer, IEditMemberFormProps } from '../../community-member/views/edit-member-form-container';
import { MemberFormBody } from '../../community-member/components/member-form-body';
import { MemberFormFields } from '../../community-member/components/member-form-fields';
import { MemberFormFooter } from '../../community-member/components/member-form-footer';
import { MemberFormHeading } from '../../community-member/components/member-form-heading';
import { observer } from 'mobx-react';
import { getCheckDepositDataService } from '../check-deposit-data-service';

export const EditMemberDialog = observer((props: IEditMemberFormProps) => {
	const editMemberSubscriberFactory = getCheckDepositDataService().getActionSubscriberFactory('editCommunityMember');
	const organizationalGivingFeatureEnabled = props.enableFeatureOrganizationalGiving;
	return(
		<EditMemberFormContainer {...props} editMemberActionSubscriberFactory={editMemberSubscriberFactory}>
			<MemberFormHeading>Edit member</MemberFormHeading>
			<MemberFormBody>
				<MemberFormFields editing={true} organizationalGivingFeatureEnabled={organizationalGivingFeatureEnabled}/>
			</MemberFormBody>
			<MemberFormFooter />
		</EditMemberFormContainer>
	);
});
