import * as React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { SvgWrapper } from '../../components/svg-wrapper';
import { ResponseTypes } from '../funds-generated';
import { Tooltip, TooltipContent } from '../../components/tooltip/tooltip';
import { classNames } from '../../../Shared/utils/classnames';

export interface IManageFundsListItemProps {
	name: string;
	code: string;
	listingId: number;
	itemKey: string;
	itemUrl: string;
	isVisible: boolean;
	isDefault: boolean;
	isNew: boolean;
	toggleVisible: (key: string, listingId: number) => void;
	makeDefault: (key: string, listingId: number) => void;
	type: ResponseTypes.FundType;
	linkedCampaignUrl: string;
}

const campaignVisibilityTooltipMessage = 'This Fund is linked to a Campaign, change visibility by editing the Campaign';

@observer
export class ManageFundsListItem extends React.Component<
{
	model: IManageFundsListItemProps;
	showToggleDefaultButton: boolean;
}, any> {

	@observable
	private isTooltipVisible = false;

	getClassName() {
		const classes: Array<String> = ['fund-listing-item'];
		if (!this.props.model.isVisible) {
			classes.push('is-invisible');
		}
		return classes.join(' ');
	}

	callToggleVisible(e: React.MouseEvent<any>) {
		const currentTarget = e.currentTarget;

		if (currentTarget instanceof HTMLElement) {
			currentTarget.blur();
		}

		e.preventDefault();
		this.props.model.toggleVisible(this.props.model.itemKey, this.props.model.listingId);
	}

	callMakeDefaultOld(e: React.MouseEvent<any>) {
		e.preventDefault();
		this.props.model.makeDefault(this.props.model.itemKey, this.props.model.listingId);
	}

	callMakeDefault = () => {
		const { makeDefault, itemKey, listingId } = this.props.model;
		makeDefault(itemKey, listingId);
	}

	renderNewLabel() {
		if (this.props.model.isNew) {
			return <span className="fund-listing-new-label"><span>NEW</span></span>;
		}

		return null;
	}

	renderFundCode() {
		if (this.props.model.code !== this.props.model.name) {
			return <span className="fund-listing-item-code">{`(${this.props.model.code})`}</span>;
		}
		return null;
	}

	renderToggleDefaultButton() {
		if (this.props.showToggleDefaultButton) {
			const { isDefault, isVisible, type } = this.props.model;
			const icon = isDefault ? 'star' : 'star-open';
			const disabled = isDefault || (type === ResponseTypes.FundType.Campaign && !isVisible);
			const className = classNames('fund-listing-item-action', disabled ? 'noop' : null);

			return (
				<button type="button" className={className} onClick={this.callMakeDefault} disabled={disabled} key={`default_${isDefault}`}>
					<SvgWrapper svg={icon} className="svg" width="24" height="40" />
				</button>);

		}
		return null;
	}

	render() {
		return (
			<div className={this.getClassName()}>
				<div className="fund-listing-item-info" data-drag-handle>
					<span className="fund-listing-drag-handle">
						<SvgWrapper svg="drag-handle" width="11" height="44" />
					</span>
					<span className="fund-listing-item-name">{this.props.model.name}</span>
					{this.renderFundCode()}
					{this.renderNewLabel()}
				</div>
				{this.renderCampaignAction(this.props.model.type, this.props.model.linkedCampaignUrl)}
				{this.renderToggleDefaultButton()}
				<a className="fund-listing-item-action" href={this.props.model.itemUrl} title="Edit Fund">
					<SvgWrapper svg="pen-white" className="svg" width="17" height="44" />
				</a>
				{this.renderVisibleAction()}
			</div>
		);
	}

	private renderCampaignAction(type: ResponseTypes.FundType, url: string) {
		const className = 'fund-listing-item-action fund-listing-item-action-campaign';
		if (type !== ResponseTypes.FundType.Campaign || !url) {
			return <span className={classNames(className, 'noop')} />;
		}

		return (
			<a className={className} href={url} title="Edit Campaign">
				<SvgWrapper svg="icon-follow-link" className="svg" width="28" height="44" />
			</a>
		);
	}

	private renderVisibleAction() {
		if (this.props.model.type === ResponseTypes.FundType.Campaign ) {
			return (
				<Tooltip visible={this.isTooltipVisible}
					onVisibleChange={this.handleVisibleChange}
					tooltipClassName="fund-listing-item-action fund-listing-item-visible-tooltip"
					tooltipContentClassName="fund-listing-item-visible-tooltip-content"
					placement="top"
					label="fund visibility">
					<SvgWrapper svg="icon-info" className="icon tooltip-icon" />
					<TooltipContent>
						<div>{campaignVisibilityTooltipMessage}</div>
					</TooltipContent>
				</Tooltip>
			);
		}

		const iconVisible = this.props.model.isVisible ? 'eye' : 'eye-slash';
		return (
			<a className="fund-listing-item-action" href="#"
				onClick={(e) => this.callToggleVisible(e)}
				key={`visible_${this.props.model.isVisible}`}>
				<SvgWrapper svg={iconVisible} className="svg" width="26" height="44" />
			</a>
		);
	}

	private handleVisibleChange = (visible: boolean) => this.isTooltipVisible = visible;
}
