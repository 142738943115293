import * as React from 'react';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { PledgeEntryMainViewModel } from './pledge-entry-main-view-model';
import { StateMachine, machineContextKey, MachineContext } from '../../../../Shared/state-machine/saga-state-machine';
import { Match, MatchNone } from '../../../../Shared/state-machine/match';
import { PledgeEntryMemberSearch } from '../../components/pledge-entry-member-search/pledge-entry-member-search';
import { Form, FormValidationResult } from '../../../../Shared/forms/primitives/form';
import { AmountField } from '../../../../Shared/forms/fields/amount-field';
import { classNames } from '../../../../Shared/utils/classnames';
import { Button } from '../../../../Shared/components/button/button';
import { States, Events } from './pledge-entry-states-events-and-actions';
import { SelectField } from '../../../../Shared/forms/fields/select-field';
import { SmoothHeightTransition } from '../../../components/hoc-behavior/transitions';
import { Modals } from './pledge-entry-modals';
import { SvgWrapper } from '../../../components/svg-wrapper';
import { PledgesGrid } from '../../components/pledges-grid/pledges-grid';
import { WithDeletePledge } from '../../components/pledges-grid/with-delete-pledge';
import { Spinner } from '../../../../Shared/components/spinner/spinner';
import { LiveCampaignRequired } from '../../components/campaigns-call-to-actions/live-campaign-required';
import { Fragment } from '../../../../Shared/components/fragment';

import * as sharedStyles from '../../shared-styles.less';
import * as styles from './pledge-entry-main.less';

@observer
export class PledgeEntryMain extends React.Component<{ vm: PledgeEntryMainViewModel }> {
	render() {
		const { vm } = this.props;
		const { machineContext, campaignRequiredModel: { CampaignLandingUrl, CreateCampaignUrl } } = vm;
		return (
			<StateMachine machineContext={machineContext}>
				<Match state={States.LiveCampaignRequired}>
					<LiveCampaignRequired campaignLandingUrl={CampaignLandingUrl} createCampaignUrl={CreateCampaignUrl} />
				</Match>
				<Match state={States.AddingPledges}>
					<AddingPledges vm={vm} />
				</Match>
			</StateMachine>
		);
	}
}

@inject(machineContextKey)
@observer
class AddingPledges extends React.Component<{ vm: PledgeEntryMainViewModel, machineContext?: MachineContext }> {
	@observable amountFieldIsFocused = false;

	render() {
		const { vm } = this.props;
		const { campaignOptions, form, machineContext, pledges, pledgeLabel } = vm;
		const amountFieldClassName = classNames(styles.amountContainer, 'panel-full-width-control', this.amountFieldIsFocused ? 'panel-full-width-control-focused' : '');
		const addingPledge = machineContext.matchesState(States.SavingPledge);
		const memberSelected = machineContext.matchesState(States.MemberSelected);

		return (
			<Fragment>
				<h1 className={styles.header}>Add a {NewFeatures.DonorPledgeEntry_ConfigurablePledgeGrammar ? pledgeLabel.NounLowerCase : 'pledge'}</h1>
				<div className={styles.container}>
					<Form formState={form} onSubmit={this.onAddPledgeSubmit} disabled={addingPledge} className={styles.addAPledgeForm}>
						<div className="row">
							<SelectField label="Choose your Campaign"
								className="col-sm-9 col-md-6"
								fieldState={form.$.campaignId}
								options={campaignOptions}
								acceptanceTestTargetId="Choose Campaign" />
							<div className={classNames(styles.addMemberButtonWrapper, 'col-sm-3', 'col-md-6')}>
								<Button type="button" className={classNames(styles.addMemberButton, 'btn-link')} onClick={this.onAddMemberClick}>
									<SvgWrapper svg="icon-add" className={styles.addMemberIcon} />Add a new member
								</Button>
							</div>
						</div>
						<div className="panel panel-default">
							<PledgeEntryMemberSearch vm={vm} />
							<SmoothHeightTransition disableLeaveTransition>
								{
									memberSelected &&
									<AmountField
										label="Amount"
										placeholder="0.00"
										className={amountFieldClassName}
										additionalClassNames={styles.amountInput}
										fieldState={form.$.amount}
										onFocus={this.amountFocused}
										onBlur={this.amountBlurred}
										hideDollarInputAddon={true}
										acceptanceTestTargetId="Pledge amount" />
								}
							</SmoothHeightTransition>
						</div>
						<div className={styles.formButtons}>
							<button
								type="button"
								className={classNames('btn', 'btn-ghost', styles.reset)}
								onClick={this.onReset}
								disabled={!memberSelected}
							>
								Reset
							</button>
							<Button
								type="submit"
								className={classNames('btn', 'btn-primary', styles.add)}
								disabled={!memberSelected}
								isLoading={addingPledge}
								acceptanceTestTargetId={'Add pledge'}
							>
								Add {NewFeatures.DonorPledgeEntry_ConfigurablePledgeGrammar ? pledgeLabel.NounLowerCase : 'pledge'}
							</Button>
						</div>
					</Form>
					<h2 className={styles.gridHeading}>Recent {NewFeatures.DonorPledgeEntry_ConfigurablePledgeGrammar ? pledgeLabel.NounPluralLowerCase : 'pledges'}</h2>
					<Match state={States.Init}>
						<div className={sharedStyles.spinnerContainer}>
							<Spinner className={sharedStyles.loadSpinner} />
						</div>
					</Match>
					<MatchNone states={[States.Init]}>
						<WithDeletePledge
							machineContext={machineContext}
							deletingState={States.Deleting}
							processingDeleteState={States.ProcessingDelete}
							onDelete={this.onPledgesGridDelete}
							onConfirmDelete={this.onPledgesGridConfirmDelete}
							onCancelDelete={this.onPledgesGridCancelDelete}>
							<PledgesGrid hasCampaignNameColumn={true} pledges={pledges} pledgeLabel={pledgeLabel} />
						</WithDeletePledge>
					</MatchNone>
				</div>
				<Modals vm={vm} />
			</Fragment>
		);
	}

	@action.bound
	amountFocused() {
		this.amountFieldIsFocused = true;
	}

	@action.bound
	amountBlurred() {
		this.amountFieldIsFocused = false;
	}

	onReset = () => {
		Events.raise.ClearMember(this.props.vm.machineContext);
	}

	onAddMemberClick = () => {
		Events.raise.AddMember(this.props.vm.machineContext);
	}

	onAddPledgeSubmit = (result: FormValidationResult) => {
		const { vm: { machineContext, fieldsForRequest } } = this.props;
		if (!result.hasError) {
			Events.raise.AddPledge(machineContext, { request: fieldsForRequest });
		}
	}

	onPledgesGridDelete = () => {
		Events.raise.DeletePledge(this.props.vm.machineContext);
	}

	onPledgesGridConfirmDelete = (pledgeId: number) => {
		Events.raise.ConfirmDeletePledge(this.props.vm.machineContext, { request: { Id: pledgeId } });
	}

	onPledgesGridCancelDelete = () => {
		Events.raise.CancelDeletePledge(this.props.vm.machineContext);
	}
}
