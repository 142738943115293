import * as React from 'react';
import { observer } from 'mobx-react';
import { FormControlAmount } from '../../../components/form-controls/form-control-amount';
import * as styles from './styles.less';
import * as sharedStyles from '../shared-styles/styles.less';

export interface IExcludeByAmountProps {
	amount: number;
	amountChangeHandler: (value: number) => void;
	amountBlurHandler: () => void;
	excludedByAmount: number;
}

@observer
export class ExcludeByAmount extends React.Component<IExcludeByAmountProps> {

	render() {
		const { amount, amountChangeHandler, amountBlurHandler, excludedByAmount } = this.props;

		return (
			<div className={`row ${sharedStyles.panelRow}`}>
				<div className={styles.flexContainer}>
					<span className={sharedStyles.panelRowLabel}>Exclude statements with total contributions less than</span>
					<div className={styles.amountContainer}>
						<div className={styles.amountWrapper}>
							<FormControlAmount
								name="amountInput"
								value={amount}
								inputClassName={styles.amountInput}
								onChangeHandler={amountChangeHandler}
								onBlur={amountBlurHandler}
							/>
						</div>
						<span className={styles.excludedInfo}><span className={styles.bold}>{excludedByAmount} givers</span> will not recieve a giving statement</span>
					</div>
				</div>
			</div>
		);
	}
}
