import * as React from 'react';

export const PayerSearchLabel = (props: { payerNoun?: string, children? }) => {
	return (
		<label className="payer-search-label">
			{props.payerNoun || 'Giver'}'s details
			{props.children}
		</label>
	);
};
