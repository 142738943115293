import { getBoundingRect } from '../helpers/getboundingrect';
const template = '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>';

export enum TooltipPlacement {
	Left = 1,
	Right = 2,
	Bottom = 3,
	Top = 4
}

type ElementBounds = { top: number, height: number, left: number, width: number, right: number, bottom: number };

export class TooltipOld {
	private element: JQuery;
	private tip: JQuery;
	private title: string;
	private tooltipClassName: string;

	private state = {
		isVisible: false,
		isEnabled: true
	};

	constructor(element: Element | Text, tooltipClassName: string = null) {
		this.element = $(element);
		this.tooltipClassName = tooltipClassName;

		this.element.on('mouseenter touchend', (e) => {
			if (this.state.isEnabled) {
				e.preventDefault();
				this.open();
			}
		});
		this.element.on('mouseleave', () => this.close());

		this.title = this.element.attr('title');
		this.element.data('original-title', this.title).attr('title', null);
		this.element.data('tooltip', this);
	}

	disable() {
		this.state.isEnabled = false;
	}

	enable() {
		this.state.isEnabled = true;
	}

	getTip() {
		if (!this.tip) {
			this.tip = $(template);
			this.tip.find('.tooltip-inner').text(this.title);
			if (this.tooltipClassName) {
				this.tip.addClass(this.tooltipClassName);
			}
		}

		return this.tip;
	}

	open() {
		if (this.state.isVisible) { return; }

		this.state.isVisible = true;

		this.getTip().removeClass('in left right bottom top').attr('style', null);

		const elementBounds = getBoundingRect(this.element[0]);
		const placement = this.getTooltipPlacement();

		//bootstrap tooltip css has min and max sizes set, by setting initial tooltip position
		//to {0,bounds.right} will help getting its rendered height.

		this.getTip()
			.css('display', 'block')
			.appendTo('body');

		const tipBounds = this.getTip()[0].getBoundingClientRect();

		switch (placement) {
			case TooltipPlacement.Left:
				this.placeLeft(elementBounds, tipBounds);
				break;
			case TooltipPlacement.Bottom:
				this.placeBottom(elementBounds, tipBounds);
				break;
			case TooltipPlacement.Top:
				this.placeTop(elementBounds, tipBounds);
				break;
			default:
				this.placeRight(elementBounds, tipBounds);
				break;
		}

		this.getTip().addClass('in');
		if (this.isTouchSupported()) {
			//let's close the tooltip once touch starts
			$(document.body).one('touchstart', () => { this.close(); });
		}
	}

	isTouchSupported() {
		return 'ontouchstart' in document;
	}

	close() {
		if (!this.state.isVisible) { return; }

		this.state.isVisible = false;
		this.getTip().detach();
	}

	private placeRight(elementBounds: ElementBounds, tipBounds: ElementBounds) {
		this.getTip()
			.css({ top: this.verticalCenter(elementBounds, tipBounds), left: elementBounds.left + elementBounds.width })
			.addClass('right');
	}

	private placeBottom(elementBounds: ElementBounds, tipBounds: ElementBounds) {
		this.getTip()
			.css({top: elementBounds.top + elementBounds.height, left: this.horizontalCenter(elementBounds, tipBounds) })
			.addClass('bottom');
	}

	private placeLeft(elementBounds: ElementBounds, tipBounds: ElementBounds) {
		this.getTip()
			.css({ top: this.verticalCenter(elementBounds, tipBounds), right: elementBounds.right * -1 + elementBounds.width })
			.addClass('left');
	}

	private placeTop(elementBounds: ElementBounds, tipBounds: ElementBounds) {
		this.getTip()
			.css({top: elementBounds.top - elementBounds.height - tipBounds.height, left: this.horizontalCenter(elementBounds, tipBounds) })
			.addClass('top');
	}

	private verticalCenter(elementBounds: ElementBounds, tipBounds: ElementBounds) {
		return elementBounds.top + (elementBounds.height / 2) - (tipBounds.height / 2);
	}

	private horizontalCenter(elementBounds: ElementBounds, tipBounds: ElementBounds) {
		return elementBounds.left + elementBounds.width / 2 - tipBounds.width / 2;
	}

	private getTooltipPlacement(): TooltipPlacement {
		const placement = (this.element.attr('data-placement') || '').toLowerCase();
		switch (placement) {
			case 'left':
				return TooltipPlacement.Left;
			case 'bottom':
				return TooltipPlacement.Bottom;
			case 'top':
				return TooltipPlacement.Top;
			default:
				return TooltipPlacement.Right;
		}
	}
}
