import { Observable, of } from 'rxjs';
import { filter } from 'rxjs/operators';
import { RxMachineContext } from './rx-state-machine';
export const transitionSymbol = Symbol('state_machine_transition');
export const eventHandlerSymbol = Symbol('state machine event handler');

// import { Transition } from 'xstate/lib/types';

// eventHandlerSymbol implementation
// export const createEvent = <TAction extends () => {}>(eventName: string, nextState: string, actions?: TAction[]) => ({
// 	[eventName]: {
// 		[nextState]: {
// 			actions: actions || []
// 		}
// 	},
// 	[eventHandlerSymbol]: (payload?: ReturnType<TAction>) => (this.machineContext as RxMachineContext).transition(eventName, payload),
// } as Record<string, Transition> & {[eventHandlerSymbol]: Function});

type EventAction = () => {};

type EventPayload1<T1 extends EventAction> =
	(ReturnType<T1> extends ReturnType<T1> ? ReturnType<T1> : never);

type EventPayload2<T1 extends EventAction, T2 extends EventAction> =
	(ReturnType<T1> extends ReturnType<T1> ? ReturnType<T1> : never)
	& (ReturnType<T2> extends ReturnType<T2> ? ReturnType<T2> : never);

type EventPayload3<T1 extends EventAction, T2 extends EventAction, T3 extends EventAction> =
	(ReturnType<T1> extends ReturnType<T1> ? ReturnType<T1> : never)
	& (ReturnType<T2> extends ReturnType<T2> ? ReturnType<T2> : never)
	& (ReturnType<T3> extends ReturnType<T3> ? ReturnType<T3> : never);

export function createEvent<T1 extends EventAction, T2 extends EventAction, T3 extends EventAction>(
	eventName: string, nextState: string, action1: T1, action2: T2, action3: T3): (machineContext: RxMachineContext) => (payload: EventPayload3<T1, T2, T3>) => void;
export function createEvent<T1 extends EventAction, T2 extends EventAction>(
	eventName: string, nextState: string, action1: T1, action2: T2): (machineContext: RxMachineContext) => (payload: EventPayload2<T1, T2>) => void;
export function createEvent<T1 extends EventAction>(
	eventName: string, nextState: string, action1: T1): (machineContext: RxMachineContext) => (payload: EventPayload1<T1>) => void;
export function createEvent(
	eventName: string, nextState: string): (machineContext: RxMachineContext) => () => void;
export function createEvent<T1 extends EventAction, T2 extends EventAction, T3 extends EventAction>(
	eventName: string, nextState: string, action1?: T1, action2?: T2, action3?: T3) {
	const eventHandler = (machineContext: RxMachineContext) =>
		(payload: EventPayload3<T1, T2, T3>) =>
			machineContext.transition(eventName, payload);

	const actions = [action1, action2, action3].filter(action => !!action);
	(<any>eventHandler)[transitionSymbol] = {
		[eventName]: actions.length === 0 ? nextState : { [nextState]: { actions } }
	};

	return eventHandler;
}

export const getEventz = <T extends { [P in keyof T]: Function }>(events: T) =>
	Object.keys(events).reduce((result, key) => {
		result[key] = events[key];
		result.transitions[key] = (<any>events)[transitionSymbol];
		return result;
	}, { transitions: {} } as T & { transitions: { [P in keyof T]: object } });



export const createAction = <TPayload>() => ((): TPayload => ({} as TPayload));

export type PayloadAction<T extends EventAction, TEventCreator = any> = {
	type: T,
	payload: ReturnType<T>,
	eventCreator: TEventCreator
};


export function createStringEnum<TEnum extends Record<string, any>>(e: TEnum) {
	const stringKeys = Object.keys(e).filter(key => isNaN(+key));
	return stringKeys.reduce((result, key) => {
		const enumValue = e[e[key]] as Exclude<keyof TEnum, number | Symbol> ;
		result[enumValue] = enumValue;
		return result;
	}, {} as { [P in Exclude<keyof TEnum, number | Symbol>]: string });
}


export type ValueType<T1, T2 = null, T3 = null, T4 = null> =
	T1[keyof T1]
	& (T2 extends null ? {} : T2[keyof T2])
	& (T3 extends null ? {} : T3[keyof T3])
	& (T4 extends null ? {} : T4[keyof T4]);


export const ofType = <T extends EventAction>(action$: Observable<PayloadAction<any>>, actionType: T) => ({
	withCreator: <TEventCreator>(): Observable<PayloadAction<T, TEventCreator>> => action$.pipe(filter(x => x.type === actionType))
});


export const ERROR_IS_HANDLED = of('error is handled');
