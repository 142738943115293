import * as React from 'react';
import { observer } from 'mobx-react';
import { AllocationGridCompleteViewModel, SortableColumns } from './allocation-grid-complete-view-model';
import { DataGrid, DataGridCell, IDataGridColumn } from '../../components/data-grid/data-grid';
import { SvgWrapper } from '../../components/svg-wrapper';
import { Models } from '../check-deposit-generated';
import PaymentStatus = Models.PaymentStatus;
import { Formatter } from '../../helpers/formatter';
import TransactionSummaryViewModel = Models.TransactionSummaryViewModel;
import { ResponsiveVisibility, ResponsiveBreakpoint } from '../../helpers/responsive-helper';


@observer
export class AllocationGridComplete extends React.Component<{ store: AllocationGridCompleteViewModel, title?: string }> {
	private columns: { [key: string]: IDataGridColumn };

	constructor(props) {
		super(props);

		this.columns = {
			PaymentStatus: { displayName: 'Status', formatter: this.formatPaymentStatus, sortable: true },
			PayerDisplayName: { displayName: 'Full name', formatter: this.formatName, sortable: true },
			PayerDisplayEmail: {
				displayName: 'Email address',
				responsiveVisibility: ResponsiveVisibility.Hidden,
				responsiveBreakpoints: ResponsiveBreakpoint.Xs|ResponsiveBreakpoint.Sm
			},
			CreatedOn: { displayName: 'Date', formatter: this.formatDate },
			FundDisplayValue: {
				displayName: 'Fund',
				formatter: this.formatFund,
				sortable: true,
				responsiveVisibility: ResponsiveVisibility.Hidden,
				responsiveBreakpoints: ResponsiveBreakpoint.Xs|ResponsiveBreakpoint.Sm
			},
			AmountAsMoney: { displayName: 'Amount', formatter: this.formatAmount, sortable: true }
		};
	}

	render() {
		const { title, store } = this.props;
		const { sortedTransactions, sortedBy, sortColumns, sortDirection } = store;
		const transactionCount = sortedTransactions && sortedTransactions.length;
		const transactionCountDesc = transactionCount > 1
			? <span>are <strong>{transactionCount} transactions</strong></span>
			: <span>is <strong>{transactionCount} transaction</strong></span>;

		return transactionCount ? (
			<div className="cd-allocation-grid-complete">
				{!!title && <h2 className="cd-allocation-grid-title">{title}</h2>}
				<p className="cd-allocation-grid-info">There {transactionCountDesc} in this batch.</p>
				<DataGrid
					columns={this.columns}
					sortedBy={SortableColumns[sortedBy]}
					data={sortedTransactions}
					getRowKey={this.getRowKey}
					onSelectRow={this.onSelectRow}
					sortDirection={sortDirection}
					onSortByColumn={sortColumns}
					highlightNewData={false} />
			</div>
		) : null;
	}

	onSelectRow = (data: TransactionSummaryViewModel) => {
		if(!data.IsPaymentOnlyRole) {
			window.location.href = data.LinkToPayment;
		}
	}

	getRowKey = (data: TransactionSummaryViewModel) => data.PaymentId.toString();

	private formatName = (data: TransactionSummaryViewModel, classNames: string) => (
		<DataGridCell key="PayerDisplayName" classNames={classNames}>
			{data.IsPaymentOnlyRole ? (
				<strong>{data.PayerDisplayName}</strong>
			) : (
				<a href={data.LinkToPayer}>
					<strong>{data.PayerDisplayName}</strong>
				</a>
			)}
		</DataGridCell>
	)

	private formatAmount = (data: TransactionSummaryViewModel, classNames: string) => (
		<DataGridCell key="AmountAsMoney" classNames={classNames}>
			{data.SplitInfo === null
				? (<strong>{`$${Formatter.formatNumberForDisplay(data.AmountAsMoney.Amount)}`}</strong>)
				: (<strong>{`$${Formatter.formatNumberForDisplay(data.AmountAsMoney.Amount)} / $${Formatter.formatNumberForDisplay(data.SplitInfo.TotalAmount.Amount)}`}</strong>)}
		</DataGridCell>
	)

	private formatFund = (data: TransactionSummaryViewModel, classNames: string) => (
		<DataGridCell key="FundDisplayValue" classNames={classNames}>
			{data.FundDisplayValue}

			{data.SplitInfo === null
				? null
				: ` (split ${data.SplitInfo.NumberOfSplits} ways)`}
		</DataGridCell>
	)


	private formatPaymentStatus = (data: TransactionSummaryViewModel, classNames: string) => {
		const { PaymentStatus: transactionPaymentStatus } = data;
		const paymentStatus = PaymentStatus[transactionPaymentStatus].toLowerCase();
		const showIcon = transactionPaymentStatus !== PaymentStatus.New;
		let icon: string;
		let textClassName: string = '';

		switch (transactionPaymentStatus) {
			case PaymentStatus.Success:
				icon = 'ind-tick';
				textClassName = 'text-success';
				break;
			case PaymentStatus.Processing:
				icon = 'icon-hourglass';
				break;
			case PaymentStatus.Error:
			case PaymentStatus.Failed:
			case PaymentStatus.Expired:
			case PaymentStatus.Deleted:
				icon = 'ind-cross';
				textClassName = 'text-danger';
				break;
			default:
				break;
		}
		return (
			<DataGridCell key="PaymentStatus" classNames={`${classNames} ${textClassName}`}>
				{showIcon ? <SvgWrapper className="icon" svg={icon} title={paymentStatus} /> : null}<span className="hidden-sm hidden-xs">{paymentStatus}</span>
			</DataGridCell>
		);
	}

	private formatDate(data: TransactionSummaryViewModel, classNames: string) {
		let date = null;

		date = Formatter.formatDate(data.LegalGivenOnDateTimeOffset);

		return (
			<DataGridCell key="CreatedOn" classNames={classNames}>{date}</DataGridCell>
		);
	}
}
