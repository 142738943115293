import * as React from 'react';
import * as styles from './preview-toggle.less';
import { observer } from 'mobx-react';
import { getElementId } from '../../../components/form-controls/form-control-utils';
import { SvgWrapper } from '../../../components/svg-wrapper';
import { classNames } from '../../../../Shared/utils/classnames';

export interface IPreviewToggleProps {
	name: string;
	uniqueSuffix?: string;
	showMobilePreview: boolean;
	onChangeHandler: (event: React.FormEvent<HTMLInputElement>) => void;
}

@observer
export class PreviewToggle extends React.Component<IPreviewToggleProps, {}> {
	render() {
		const {
			name,
			uniqueSuffix,
			showMobilePreview,
		} = this.props;
		const nameAttr = getElementId(name, uniqueSuffix);
		return (
			<div role="radiogroup" aria-label="Preview simulated device" className={styles.previewToggle}>
				<label className={classNames(styles.label, { [styles.selected]: !showMobilePreview })}>
					<input
						type="radio"
						name={nameAttr}
						id={`${nameAttr}_desktop`}
						value="desktop"
						className={styles.radioBox}
						checked={!showMobilePreview}
						onChange={this.onChange}
						data-pp-at-target="desktop preview toogle"
					/>
					<SvgWrapper svg="laptop" className={classNames('svg', 'svg-laptop', styles.svgLaptop, styles.svg)} /> Desktop
				</label>
				<label className={classNames(styles.label, { [styles.selected]: showMobilePreview })}>
					<input
						type="radio"
						name={nameAttr}
						id={`${nameAttr}_mobile`}
						value="mobile"
						className={styles.radioBox}
						checked={showMobilePreview}
						onChange={this.onChange}
						data-pp-at-target="mobile preview toogle"
					/>
					<SvgWrapper svg="mobile-rect" className={classNames('svg','svg-mobile-rect', styles.svgMobileRect, styles.svg)} /> Mobile
				</label>
			</div>
		);
	}

	private onChange = (evt: React.FormEvent<HTMLInputElement>) => {
		this.props.onChangeHandler(evt);
	}
}
