import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { injectAnalytics, recordEvent, WithAnalytics } from '../../../../analytics';
import { BatchEntryUserActionCreator } from '../../../pages/batch-entry/batch-entry-user-actions';
import { BatchViewModel, BatchStatus, ExportAction } from '../../../virtual-terminal-generated';
import { ExportDropDown } from '../../../../components/export-dropdown/export-dropdown';
import { getVirtualTerminalExportDataService } from '../../../virtual-terminal-non-ajax-data-service';

import * as styles from './batch-entry-close-and-export.less';

@injectAnalytics
@inject('userActionCreator')
@observer
export class BatchEntryCloseAndExportButton extends React.Component<WithAnalytics & {
	batch: BatchViewModel,
	userActionCreator?: BatchEntryUserActionCreator,
}> {
	render() {
		const { batch } = this.props;

		if (batch.Status === BatchStatus.Open) {
			return (
				<button type="button"
					onClick={this.handleCompleteBatch}
					className={`btn btn-primary ${styles.button}`}
					data-pp-at-target="Complete batch">
					Complete batch
				</button>
			);
		}
		if (batch.Status === BatchStatus.Completed || batch.Status === BatchStatus.Deposited) {
			return (
				<div className={styles.button}>
					<ExportDropDown exportButtons={batch.ExportButtons} handleExport={this.handleExportBatch} />
				</div>
			);
		}
		return null;
	}

	private handleCompleteBatch = () => {
		const { userActionCreator, batch, analytics } = this.props;

		if (NewFeatures.SetupPinpointAnalytics && analytics) {
			const { feature, subFeature } = analytics;
			recordEvent({ feature, subFeature, eventTypeLabel: 'completeBatch' });
		}

		userActionCreator.CloseBatch(batch.ListingId, { ...batch });
	}

	private handleExportBatch = (action: ExportAction) => {
		const { BatchId } = this.props.batch;
		getVirtualTerminalExportDataService().batchExport({batchId: BatchId, button: action});
	}
}
