import * as React from 'react';
import * as styles from './preview-button.less';
import { observer } from 'mobx-react';
import { Button } from '../../../components/button';

export interface IBrandingSettingPreviewButtonProps {
	onClick : () => void;
}

@observer
export class BrandingSettingPreviewButton extends React.Component<IBrandingSettingPreviewButtonProps, {}> {
	render() {
		const { onClick } = this.props;
		return (
			<Button className={`btn btn-ghost ${styles.previewBtn}`}
				acceptanceTestTargetId="preview"
				onClick={onClick}>
				Preview
			</Button>
		);
	}
}
