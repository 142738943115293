import * as React from 'react';
import { observer } from 'mobx-react';
import { BaseFieldState } from './base-field-state';
import { ITextboxCommonProps, Textbox} from '../primitives/textbox';
import { FormField, IFormFieldProps } from './form-field';
import { classNames } from '../../utils/classnames';
import { ModelMetadata } from '../../../LoggedInWeb/loggedinweb-generated';

export class TextFieldState extends BaseFieldState<string> {
	constructor(initialValue: string, metadata?: ModelMetadata.IPropertyMetadata, skipSanitization = false) {
		super(initialValue, metadata, skipSanitization);
		this.disableAutoValidation();
	}
}

export type ITextboxFieldProps = ITextboxCommonProps & IFormFieldProps & { fieldState: TextFieldState };

@observer
export class TextBoxField extends React.Component<ITextboxFieldProps> {

	render() {
		const { label, className, tooltip, getDescription, additionalClassNames, fieldState, ...rest } = this.props;
		const { value, onChange, enableAutoValidationAndValidate, hasError, error } = fieldState;
		const classes = classNames(`form-control`, additionalClassNames, hasError ? `input-validation-error` : null);

		return (
			<FormField label={label} className={className} tooltip={tooltip} error={error} getDescription={getDescription}>
				<Textbox
					{...rest}
					value={value}
					onBlur={enableAutoValidationAndValidate}
					onChange={onChange}
					ariaInvalid={hasError}
					additionalClassNames={classes}
				/>
			</FormField>
		);
	}
}
