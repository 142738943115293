import * as React from 'react';
import { Button } from '../../../components/button';
import { SvgWrapper } from '../../../components/svg-wrapper';
import { Fragment } from '../../../../Shared/components/fragment';
import { ExternalSystemStatus } from '../../qbo-integration-generated';

import * as styles from './styles.less';

export interface StatusControlProps {
	systemStatus: ExternalSystemStatus;
	isProcessingRequest: boolean;
	isEditing: boolean;
	isSetupComplete: boolean;
	isAuthenticated: boolean;
	isNewConnection: boolean;
	connectButtonLabel: string;
	connectIntegrationAction: () => void;
	enableIntegrationAction: () => void;
	disableIntegrationAction: () => void;
	removeIntegrationAction: () => void;
	className?: string;
}

export class StatusControl extends React.Component<StatusControlProps, null> {
	render() {
		const { isEditing, isProcessingRequest, connectIntegrationAction, connectButtonLabel, className } = this.props;

		if (isEditing) {
			return (
				<span className={className}>
					{
						this.renderButtons()
					}
				</span>
			);
		} else {
			return (
				<span className={className}>
					{
						this.renderButtons()
					}
					<Button className={`${styles.statusControl} btn btn-link btn-sm`}
							isProcessingRequest={isProcessingRequest}
							disabled={isProcessingRequest}
							onClick={connectIntegrationAction}>
						<SvgWrapper svg="icon-add" className="icon"/>
						{connectButtonLabel}
					</Button>
				</span>
			);
		}
	}

	private renderButtons() {
		return (
			<Fragment>
				{this.renderEnableButton()}
				{this.renderDisableButton()}
				{this.renderRemoveButton()}
			</Fragment>
		);
	}

	private renderEnableButton() {
		const { systemStatus, isSetupComplete, isAuthenticated, isProcessingRequest, enableIntegrationAction } = this.props;

		const shouldRenderButton = (systemStatus === ExternalSystemStatus.Disabled || systemStatus === ExternalSystemStatus.InSetup) &&
			!isSetupComplete && isAuthenticated;

		return  shouldRenderButton &&
			<Button className={`${styles.statusControl} btn btn-link btn-sm`}
					isProcessingRequest={isProcessingRequest}
					disabled={isProcessingRequest}
					onClick={enableIntegrationAction}>
				<SvgWrapper svg="icon-link" className="icon"/>
				Enable integration
			</Button>;
	}

	private renderDisableButton() {
		const { systemStatus, isSetupComplete, isProcessingRequest, disableIntegrationAction } = this.props;

		const shouldRenderButton = (systemStatus === ExternalSystemStatus.Enabled || systemStatus === ExternalSystemStatus.Paused) && !isSetupComplete;

		return shouldRenderButton &&
			<Button className={`${styles.statusControl} btn btn-link btn-sm`}
					isProcessingRequest={isProcessingRequest}
					onClick={disableIntegrationAction}>
				<SvgWrapper svg="icon-unlink" className="icon"/>
				Disable integration
			</Button>;
	}

	private renderRemoveButton() {
		const { systemStatus, isNewConnection, isProcessingRequest, removeIntegrationAction } = this.props;

		const shouldRenderButton = (systemStatus === ExternalSystemStatus.Disabled || systemStatus === ExternalSystemStatus.InSetup) &&
			!isNewConnection;
		return shouldRenderButton &&
			<Button className={`${styles.statusControl} btn btn-link btn-sm`}
					disabled={isProcessingRequest}
					onClick={removeIntegrationAction}>
				<SvgWrapper svg="icon-delete" className="icon"/>
				Delete integration
			</Button>;
	}
}
