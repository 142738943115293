import { isFunction } from './is-function';

export function wrapReactChildEventHandlers(childProps: () => any, config: {[T in keyof React.DOMAttributes<any>]: React.DOMAttributes<any>[T]}) {
	return Object.keys(config).reduce((acc, eventKey) => {
		acc[eventKey] = (event: React.SyntheticEvent<any>) => {
			//run an original event handler from the child first
			const originalEventHandler = childProps()[eventKey];
			if (isFunction(originalEventHandler)) {
				originalEventHandler(event);
			}

			if (event.isDefaultPrevented()) {
				return;
			}

			config[eventKey](event);
		};

		return acc;
	}, {});
}
