import * as React from 'react';
import { KeyCodes } from '../../../Shared/helpers/keycodes';
import { eventToKeyCode } from '../../helpers/keycodehelper';
import { wrapReactChildEventHandlers } from '../../utils/wrap-reactchild-eventhandlers';

export type SelectableListKeyboardNavigation = 'next' | 'previous' | 'first' | 'last';

/**
 *  Defines consistent behavior for handling keyboard navigation for lists
 *  Handles keyDown event to request navigation (props.onNavigated) to next, previous, first or last items
 *  Notifies when a user want to select a currently highlighted item (props.onCurrentItemSelected)
 *  This behavior can be disabled by setting props.disabled to true
 * @export
 * @class SelectableListKeyboardController
 * @extends {React.Component<{
 * 	disabled?: boolean;
 * 	onNavigated: (item: SelectableListKeyboardNavigation) => void;
 * 	onCurrentItemSelected: () => void;
 * }, {}>}
 */
export class SelectableListKeyboardController extends React.Component<{
	disabled?: boolean;
	onNavigated: (item: SelectableListKeyboardNavigation) => void;
	onCurrentItemSelected: () => void;
}, {}> {

	private eventHandlers = wrapReactChildEventHandlers(() => (React.Children.only(this.props.children) as React.ReactElement).props, {
		onKeyDown: (event: React.KeyboardEvent<any>) => {
			const {onCurrentItemSelected, onNavigated} = this.props;
			const keyCode = eventToKeyCode(event);

			switch (keyCode) {
				case KeyCodes.Enter:
					event.preventDefault();
					onCurrentItemSelected();
					break;
				case KeyCodes.UpArrow:
					event.preventDefault();
					onNavigated('previous');
					break;
				case KeyCodes.DownArrow:
					event.preventDefault();
					onNavigated('next');
					break;
				case KeyCodes.End:
					event.preventDefault();
					onNavigated('last');
					break;
				case KeyCodes.Home:
					event.preventDefault();
					onNavigated('first');
					break;
				default:
					break;
			}
		}
	});

	render() {
		const child = React.Children.only(this.props.children) as React.ReactElement;

		if (this.props.disabled) {
			return child;
		}

		return React.cloneElement(child, this.eventHandlers);
	}
}

/**
 * A helper to parse {SelectableListKeyboardNavigation} result from {SelectableListKeyboardController}
 * @export
 * @param {number} currentIndex
 * @param {number} itemsLength
 * @param {SelectableListKeyboardNavigation} navigationType
 * @returns
 */
export function getNewHighlightedIndex(currentIndex: number, itemsLength: number, navigationType: SelectableListKeyboardNavigation) {
	let newIndex: number;
	switch (navigationType) {
		case 'previous':
			newIndex = currentIndex - 1;
			break;
		case 'next':
			newIndex = currentIndex + 1;
			break;
		case 'first':
			newIndex = 0;
			break;
		case 'last':
			newIndex = itemsLength - 1;
			break;
	}

	return clampNumber(newIndex, 0, itemsLength - 1);
}

function clampNumber(n: number, min: number, max: number) {
	return Math.min(Math.max(n, min), max);
}
