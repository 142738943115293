import * as React from 'react';
import { delay } from 'redux-saga';
import { cps, race, call, fork } from 'redux-saga/effects';
import { BrandingPackageViewModel } from '../branding-settings-generated';
import { IBrandingSettingsSagaContext } from '../branding-settings-saga';
import { BrandingSettingConfirmRefreshDialog } from '../branding-settings-confirm-refresh-dialog';
import { ModalDialogCommander } from '../../components/modal-dialog-commander';
import { BrandingPackageState } from '../branding-settings-main-view-model';

export function* handleConflict(context: IBrandingSettingsSagaContext, latestPackage: BrandingPackageViewModel): IterableIterator<any> {
	const { mainViewModel } = context;
	const { formViewModel } = mainViewModel;
	const simulateLoadingPackageDetailsInMilliSeconds: number = 500;

	mainViewModel.packageState = BrandingPackageState.Conflict;
	formViewModel.failedToSave();
	const refreshConfirmed = yield call(requestRefreshConfirmation, context);

	if (refreshConfirmed) {
		mainViewModel.loadLatestBrandingPackage(latestPackage);
		yield fork(function* () {
			yield call(delay, simulateLoadingPackageDetailsInMilliSeconds);
			mainViewModel.isLoadingListingDetails = false;
		});
		ModalDialogCommander.forceCloseCurrent();
	}
}
export function* requestRefreshConfirmation(context: IBrandingSettingsSagaContext) {
	let refreshAction: (error: any, result: any) => void;
	const refreshEffect = cps((callback) => refreshAction = callback); // convert callback `refreshClicked` to generator `refreshEffect`

	const dialog = React.createElement(BrandingSettingConfirmRefreshDialog, {
		refreshClicked: () => refreshAction(null, true),
		vm: context.mainViewModel
	});

	var r = yield race({
		cancel: call(() => ModalDialogCommander.showReactDialog(dialog)),
		refresh: refreshEffect
	});

	return !!r.refresh;
}

