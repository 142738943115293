import * as React from 'react';
import { ScheduleImportFundMatchingViewModel } from './schedule-import-fund-matching-view-model';
import { ScheduleImportStep } from '../schedule-import-step';
import { AddNewFundDialog } from '../../../transaction-import/components/add-new-fund-dialog';
import { ValidatableForm } from '../../../components/form-controls/validatable-form';
import { SvgWrapper } from '../../../components/svg-wrapper';
import { Button } from '../../../components/button';
import { observer } from 'mobx-react';
import { FundMappingRow } from './fund-mapping-row';

@observer
export class ScheduleImportFundMatching extends React.Component<{
	vm: ScheduleImportFundMatchingViewModel}, {}> {

	render() {
		const {
			actionInProgress,
			importedFunds,
			pushpayFundStores,
			paymentLabel,
			updateAskDonorForFundChecked,
			canAddNewFund,
		} = this.props.vm;

		return (
			<ScheduleImportStep vm={this.props.vm}>
				<div className="visible-xs header-buttons">
					<Button className="btn btn-link btn-sm" onClick={this.handleNewFund} disabled={!canAddNewFund}>
						<SvgWrapper svg="icon-add" className="svg icon" />
						Add new fund
					</Button>
				</div>
				<ValidatableForm onSubmit={this.handleSubmit} className="panel panel-default">
						<div className="panel-heading">
							<div className="header">
								<div className="col-sm-4">
									Funds from your file
								</div>
								<div className="col-sm-5 hidden-xs">
									Pushpay funds
								</div>
								<div className="col-sm-3 hidden-xs text-right">
									<Button className="btn btn-link btn-sm" onClick={this.handleNewFund} disabled={!canAddNewFund}>
										<SvgWrapper svg="icon-add" className="svg icon" />
										Add new fund
									</Button>
								</div>
							</div>
						</div>
						<div>
							{
								importedFunds.map( (fund, index) => (
									<FundMappingRow
										key={fund.ImportFundId}
										importedFund={fund}
										pushpayFundStore={pushpayFundStores[index]}
										payerLabel={paymentLabel.PayerPluralLowerCase}
										updateAskDonorForFundChecked={(isChecked: boolean) => updateAskDonorForFundChecked(index, isChecked)}/>
								))
							}
						</div>
						<div className="panel-footer panel-footer-btn-group">
							<Button className="btn btn-ghost" disabled={actionInProgress} onClick={this.handleCancel}>Cancel</Button>
							<Button submit={true} className="btn btn-default" disabled={actionInProgress}>Next</Button>
						</div>
					</ValidatableForm>
			</ScheduleImportStep>
		);
	}

	private handleSubmit = () => {
		this.props.vm.initMatchFundsRequest();
	}

	private handleNewFund = (ev: React.MouseEvent<HTMLButtonElement>) => {
		ev.preventDefault();
		if (this.props.vm.canAddNewFund) {
			this.props.vm.addNewFundViewModel.openDialog(<AddNewFundDialog vm={this.props.vm.addNewFundViewModel} handleAddNewFund={this.handleNewFundSubmit}/>);
		}
	}

	private handleNewFundSubmit = () => {
		this.props.vm.initAddNewFundRequest();
	}

	private handleCancel = () => {
		this.props.vm.initCancelImportRequest();
	}
}
