import * as React from 'react';
import { IBrandingSettingsSagaContext } from '../branding-settings-saga';
import { BrandingSettingsUserAction } from './actions';
import { ModalDialogCommander } from '../../components/modal-dialog-commander';
import { PreviewModalContent } from '../components/preview-modal/preview-modal';

export function* displayPreviewHandler(context: IBrandingSettingsSagaContext, action: BrandingSettingsUserAction.DisplayPreview): IterableIterator<any> {
	try {
		const dialog = React.createElement(PreviewModalContent,{mainViewModel: context.mainViewModel});
		yield ModalDialogCommander.showReactForm(dialog);
	} catch (error) {
		context.reportError(error);
	}
}
