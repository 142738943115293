import * as React from 'react';
import {SchedulesDetailsViewModel} from './schedules-details-view-model';
import { Provider, observer } from 'mobx-react';
import { ScheduleDetailsFilter } from '../../components/schedule-details-filter';
import { Fragment } from '../../../../Shared/components/fragment';
import { ScheduleDetailsGrid } from '../../components/schedule-details-grid';
import { LoadingBox } from '../../../components/loading-box';
import { Pagination } from '../../../components/pagination';
import { ScheduleDetailsSummary } from '../../components/schedule-details-summary';
import { getActionHandlers, ScheduleDetailsContext, scheduleDetailsOnSagaInit } from './schedule-details-saga';
import { runScheduleImportSaga } from '../../saga/schedule-import-saga';

import * as styles from './styles.less';
import { SvgWrapper } from '../../../../Shared/components/svg-wrapper';

@observer
export class SchedulesDetails extends React.Component<{vm: SchedulesDetailsViewModel}> {
	private readonly scheduleDetailsSagaContext: ScheduleDetailsContext;

	constructor(props) {
		super(props);

		this.scheduleDetailsSagaContext = new ScheduleDetailsContext(props.vm);
		runScheduleImportSaga(this.scheduleDetailsSagaContext, getActionHandlers(), scheduleDetailsOnSagaInit);
	}

	render() {
		const { currentMigrationUrl } = this.props.vm;
		return (
			<Provider userActionCreator={this.scheduleDetailsSagaContext.userActionCreator}>
				<Fragment>
					<div className={styles.header}>
						<h1 className={styles.title}>Schedules Details</h1>
						{currentMigrationUrl && <a href={currentMigrationUrl} className={styles.link}>Return to migration <SvgWrapper svg="icon-chevron-right" className={styles.linkSvg} /></a>}
					</div>
					<ScheduleDetailsFilter {...this.props.vm}  />
					<div style={{position: 'relative'}}>
						{this.renderGrid()}
					</div>
				</Fragment>
			</Provider>
		);
	}

	private renderGrid() {
		const { isLoadingData, paymentLabel, gridData, pageCount, currentPage, monthlyTotal, scheduleCount, sortedBy,
			sortDirection, expandedRowData } = this.props.vm;
		if(isLoadingData) {
			return <LoadingBox text="Loading"/>;
		}
		return(
			<Fragment>
				<ScheduleDetailsGrid paymentLabel={paymentLabel} data={gridData} sortedBy={sortedBy} sortDirection={sortDirection} expandedRowDetails={expandedRowData} />
				<Pagination numberOfPages={pageCount} currentPage={currentPage} onPageChange={this.changePageNumber}/>
				<ScheduleDetailsSummary monthlyTotal={monthlyTotal} scheduleCount={scheduleCount} />
			</Fragment>
		);
	}

	private changePageNumber = (newPage: number) => {
		this.scheduleDetailsSagaContext.userActionCreator.ChangePageNumber(newPage);
	}
}
