import * as React from 'react';
import { IntegrationLabels } from '../integration-labels';
import { IntegrationActions } from "../integration-actions";
import { PlanningCenterIntegrationConfigurationStore } from "../../pages/configure-planningcenter";

export class PlanningCenterPeopleConfigurationSection extends React.Component<{
	store: PlanningCenterIntegrationConfigurationStore,
	statusMessage: string,
	communityMembersUrl: string
}, {}> {
	render() {
		return (
			<div className="connection-section">
				<h2>
					<div className="integration-section-headers">
						<span>People</span>
						<IntegrationLabels labels={this.props.store.viewModel.Content.PrimaryLabels} />

						<span className="header-buttons">
							<IntegrationActions store={this.props.store} />
						</span>
					</div>
				</h2>
				<div className="panel panel-default">
					<div className="panel-body">
						<p>
							{this.props.statusMessage}
						</p>
						<p>
							Your Pushpay community members will be synchronized with Planning Center People whenever a
							change is made in either system.
						</p>
						<p>
							For each Pushpay community member the system will look for an existing person in Planning
							Center People by searching for their name, email address or mobile number. If an existing
							person cannot be found, a new person will be created in Planning Center People using the
							details from Pushpay.
						</p>
					</div>
				</div>
			</div>
		);
	}
}
