import { MachineConfig } from 'xstate/lib/types';
import { States, Events, Actions } from './campaign-details-states-events-and-actions';

export const getCampaignDetailsConfig = (): MachineConfig => {
	return {
		key: 'Campaign Overview',
		initial: States.Init,
		states: {
			[States.Init]: {
				onEntry: Actions.Start,
				...OnData(),
			},
			[States.Idle]: {
				on: {
					[Events.FetchPledgeData]: {
						[States.FetchingPledges]: { actions: [Actions.FetchPledges] },
					},
					[Events.Delete]: States.ConfirmPledgeDelete,
				},
			},
			[States.ConfirmPledgeDelete]: {
				on: {
					[Events.DeleteConfirmed]: {
						[States.DeletingPledge]: { actions: [Actions.DeletePledge] },
					},
					[Events.Cancel]: States.Idle,
				},
			},
			[States.FetchingPledges]: {
				...OnData()
			},
			[States.DeletingPledge]: {
				...OnData()
			},
			[States.Error]: {
				on: {
					[Events.CloseErrorModal]: States.Idle
				},
			},
		}
	};
};

const OnData = () => ({
	on: {
		[Events.Success]: States.Idle,
		[Events.Error]: States.Error,
	}
});
