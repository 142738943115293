import * as React from 'react';
import { ResponseTypes, ModelMetadata } from '../../funds/funds-generated';
import { observer } from 'mobx-react';
import { computed } from 'mobx';

import TooltipComponent from '../components/configuration-section-tooltip';

import { InputField, ValidationMessage } from '../../funds/components/form-controls';

import { ListingModel } from '../components/listing-model';
import { IntegrationConfigurationStore } from '../components/integration-configuration-store';
import { AuthenticationSection } from '../components/authentication-section';
import { ConfigurationSectionHeader } from '../components/configuration-section-header';
import { IntegrationContainer } from '../components/integration-container';
import { ConfigurationSection } from '../components/configuration-section';
import { ListingSettingsEditor } from '../components/listing-configuration';
import { FallbackFundSelector } from '../components/fallback-fund-selector';
import getFundsForDisplay from '../helpers/get-integration-funds-for-display';

import ElvantoConfigurationViewModel = ResponseTypes.ElvantoConfigurationViewModel;
import ElvantoListingConfigurationModel = ResponseTypes.ElvantoListingConfigurationModel;
import IntegrationListingConfigurationViewModel = ResponseTypes.IntegrationListingConfigurationViewModel;

export class ElvantoIntegrationConfigurationStore
	extends IntegrationConfigurationStore<ElvantoListingConfigurationModel, ElvantoConfigurationViewModel> {

	@computed
	get funds() {
		return getFundsForDisplay(this.configuration.IntegrationFunds);
	}

	createListing(listing: IntegrationListingConfigurationViewModel<ElvantoListingConfigurationModel>) {
		const messageWhenMissingFallbackFunds = this.isListingMissingFallbackFund(listing, this.configuration.IntegrationFunds)
			? ModelMetadata.ElvantoListingConfigurationModel.FallbackFundKey.validationRules.required.errorMessage
			: null;

		return new ListingModel(listing, this.funds, messageWhenMissingFallbackFunds);
	}
}

@observer
export class ElvantoIntegration extends React.Component<{ controller: ElvantoIntegrationConfigurationStore }, {}> {

	renderAuthenticationSection = () => {
		return <ElvantoAuthenticationSection controller={this.props.controller} />;
	}

	renderConfigurationSection = () => {
		return <ElvantoConfigurationSection controller={this.props.controller} />;
	}

	render() {
		return <IntegrationContainer
			isAuthenticationSectionEditable={this.props.controller.isAuthenticationSectionEditable}
			authenticationSectionFactory={this.renderAuthenticationSection}
			configurationSectionFactory={this.renderConfigurationSection} />;
	}
}

@observer
class ElvantoAuthenticationSection extends React.Component<{ controller: ElvantoIntegrationConfigurationStore }, {}> {
	render() {
		return (
			<AuthenticationSection store={this.props.controller}>
				<div className="form-group">
					<label className="col-md-3 control-label">{this.metadata.ClientId.displayName}</label>
					<div className="col-md-5">
						<InputField type="text" propertyMetadata={this.metadata.ClientId} ignorePanLikeValidation={true}/>
					</div>
					<div className="col-md-3">
						<ValidationMessage for={this.metadata.ClientId.propertyName} />
					</div>
				</div>
				<div className="form-group">
					<label className="col-md-3 control-label">{this.metadata.SharedSecret.displayName}</label>
					<div className="col-md-5">
						<InputField type="text" propertyMetadata={this.metadata.SharedSecret} ignorePanLikeValidation={true}/>
					</div>
					<div className="col-md-3">
						<ValidationMessage for={this.metadata.SharedSecret.propertyName} />
					</div>
				</div>
			</AuthenticationSection>
		);
	}

	private get metadata() {
		return ModelMetadata.ElvantoAuthenticationEditModel;
	}
}

const message = `If we run into any trouble matching transactions to Elvanto
we'll use the fallback information you've selected here.`;

// ReSharper disable once InconsistentNaming
const ElvantoListingRowHeader = React.createFactory(() => (
	<span><span>Select fallback funds in Elvanto </span><TooltipComponent message={message} /></span>
));

@observer
class ElvantoConfigurationSection extends React.Component<{ controller: ElvantoIntegrationConfigurationStore }, {}> {
	render() {
		return (
			<div>
				<ConfigurationSectionHeader store={this.props.controller} />
				<ConfigurationSection
					store={this.props.controller}
					headerComponentFactory={ElvantoListingRowHeader}
					organizationSettings={null}
					listingSettingsEditor={ElvantoListingSettingsEditor} />
			</div>
		);
	}
}

@observer
class ElvantoListingSettingsEditor extends React.Component<{ listing: ListingModel<ElvantoListingConfigurationModel> }, {}> {
	render() {
		return (
			<ListingSettingsEditor>
				<FallbackFundSelector
					propertyMetadata={this.metadata.FallbackFundKey}
					listing={this.props.listing}
					defaultValue={this.props.listing.model.FallbackFundKey} />
			</ListingSettingsEditor>
		);
	}

	private get metadata() {
		return ModelMetadata.ElvantoListingConfigurationModel;
	}
}
