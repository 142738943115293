import * as React from 'react';
import { observer } from 'mobx-react';

import { RenderResponsive } from '../../../components/hoc-behavior/render-responsive';
import { ResponsiveVisibility, ResponsiveBreakpoint } from '../../../helpers/responsive-helper';
import { Fragment } from '../../../../Shared/components/fragment';
import * as styles from './styles.less';
import { SvgWrapper } from '../../../components/svg-wrapper';

export interface ISummaryFormProps {
	listingName: string;
	dataFormat: string;
	showDeleteButton?: boolean;
	handleDelete?: () => void;
}

@observer
export class SummaryForm extends React.Component<ISummaryFormProps> {
	render() {
		const {
			listingName,
			dataFormat,
			showDeleteButton,
			handleDelete,
		} = this.props;
		return (
			<form className="panel panel-color panel-default gray transaction-import-summary-panel">
				<div className="panel-heading top-border">
					<div className="summary-heading">
						<strong>Listing</strong>
							{showDeleteButton && <button type="button" className={`btn btn-link ${styles.btnDelete}`} aria-lable="delete" onClick={handleDelete}>
								<SvgWrapper className="icon" svg="icon-delete" />
								Delete
							</button>}

						<div className="summary-title">{listingName}</div>
						<RenderResponsive visibility={ResponsiveVisibility.Hidden} breakpoints={ResponsiveBreakpoint.Xs}>
							<div className="summary-description">
								<strong>Data format:</strong> {dataFormat}
							</div>
						</RenderResponsive>
					</div>
				</div>
				<div className="summary-panel-body">
					<RenderResponsive visibility={ResponsiveVisibility.Visible} breakpoints={ResponsiveBreakpoint.Xs}>
						<SummaryRow label="Data format" content={dataFormat}/>
					</RenderResponsive>

					{this.props.children}
				</div>
			</form>
		);
	}
}

export interface ISummaryRowProps {
	label: string;
	content: string | React.ReactElement<any>;
}

@observer
export class SummaryRow extends React.Component<ISummaryRowProps, { expanded: boolean }> {
	constructor(props) {
		super(props);
		this.state = {expanded: false};
	}

	render() {
		const { label, content, children } = this.props;

		return (
			<Fragment>
				<div className="row summary-panel-row">
					<div className="col-xs-6 summary-panel-row-label">{label}</div>
					<div className="col-xs-6 summary-panel-row-value">{content}</div>
					{children && <Fragment>
						<div className="col-xs-12 text-right">
							<a className={`arrow-hollow-${this.isExpanded ? 'up' : 'down'}-after ${styles.arrow}`} onClick={this.toggleExpanded}>
								View {this.isExpanded ? 'less' : 'more'} details
							</a>
						</div>
						{this.isExpanded && <div className="col-xs-12">
							{children}
						</div>}
					</Fragment>}
				</div>
			</Fragment>
		);
	}

	toggleExpanded = () => {
		this.setState({expanded: !this.isExpanded});
	}

	get isExpanded() {
		return this.state.expanded;
	}
}
