import { action } from 'mobx';
import { PostError } from '../../utils/ajax-client';
import { alertController } from '../../components/alert-controller';
import { ModalDialogCommander, StandardErrorTitle } from '../../components/modal-dialog-commander';

export const defaultErrorMessage: string = `Our system encountered an unexpected error. We're currently looking into it. Please try again soon.`;
export const getErrorInfo = action(getErrorInfoInsideAction); // Can't use @action decorator in a module.

export type ErrorInfo = {
	error: any;
	message?: string;
};

function getErrorInfoInsideAction(e: any): ErrorInfo {
	return {
		error: e,
		message: defaultErrorMessage,
	};
}

export function reportError(error: any, customData?: any) {
	if (error instanceof PostError) {
		if (error.validationErrors) {
			alertController.showValidationErrors(error.validationErrors);
		} else if (error.userError) {
			ModalDialogCommander.error(error.userError, StandardErrorTitle);
		} else {
			ModalDialogCommander.showStandardErrorMessage();
		}

		if (error.client.status !== 500) {
			return;
		}
	}

	window.reportUnhandledRejection(error, {
		...customData,
	});
}
