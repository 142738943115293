import {ResponseTypes} from '../funds-generated';
import FundListItemViewModel = ResponseTypes.FundListItemViewModel;

export enum SortOrder {
	AToZ,
	ZToA,
	LowToHigh,
	HighToLow
}

export namespace SortOrder {

	export function toPrettyString(order: SortOrder) {
		switch(order) {
			case SortOrder.AToZ:
				return 'Fund name A to Z';
			case SortOrder.ZToA:
				return 'Fund name Z to A';
			case SortOrder.LowToHigh:
				return 'Fund value low to high';
			case SortOrder.HighToLow:
				return 'Fund value high to low';
		}
	}

	export function sortFund(a: FundListItemViewModel, b: FundListItemViewModel, sortOrder: SortOrder): number {
		return sortByIsUnknown(a, b)
			|| sortByTotal(a, b, sortOrder)
			|| sortByNameAndCode(a, b, sortOrder);
	}

	function sortByIsUnknown(a: FundListItemViewModel, b: FundListItemViewModel): number {
		if (a.IsUnknown === b.IsUnknown) {
			return null;
		}
		if (a.IsUnknown) {
			return 1;
		}
		return -1;
	}

	function sortByTotal(a: FundListItemViewModel, b: FundListItemViewModel, sortOrder: SortOrder): number {
		if (sortOrder !== SortOrder.HighToLow && sortOrder !== SortOrder.LowToHigh) {
			return null;
		}
		const sortOrderMultiplier = sortOrder === SortOrder.HighToLow ? -1 : 1;
		return genericSort(a.Total, b.Total, sortOrderMultiplier);
	}

	function sortByNameAndCode(a: FundListItemViewModel, b: FundListItemViewModel, sortOrder: SortOrder): number {

		const sortOrderMultiplier = sortOrder === SortOrder.ZToA ? -1 : 1; // ensures it defaults to AToZ for numerical sorts
		return genericSort(getNameAndCodeString(a), getNameAndCodeString(b), sortOrderMultiplier);
	}

	function genericSort<T>(a: T, b: T, sortOrderMultiplier: number) : number {
		if (a < b) {
			return -1 * sortOrderMultiplier;
		} else if (a > b) {
			return 1 * sortOrderMultiplier;
		}
		return 0;
	}

	function getNameAndCodeString(item: FundListItemViewModel) {
		return item.Name.toLowerCase() + item.Code.toLowerCase();
	}
}
