import { MessageBox } from './message-box';
import { querySelector, sendRaygun } from './login-utils';
import { Models } from './login.generated';
import { LoginTabs } from './login-tabs';

export enum FormSubmitCallbackResult {
	Error = 0,
	AboutToNavigate,
	ReenableForm
}

export class LoginForm<TResult> {
	private fieldsets: HTMLFieldSetElement[];
	private form: HTMLFormElement;
	private submitButton: HTMLButtonElement;
	private submitButtonOriginalText: string;

	constructor(
		public tab: Models.LoginView,
		public tabs: LoginTabs,
		public messageBox: MessageBox,
		private submitCallback: (result: TResult) => FormSubmitCallbackResult) {

		this.form = tabs.getFormForTab(tab);
		this.form.addEventListener('submit', (e) => this.submitListener(e));
		this.fieldsets = querySelector<HTMLFieldSetElement>('fieldset', this.form);
		this.submitButton = this.form.querySelector('button[type="submit"]') as HTMLButtonElement;
		this.submitButtonOriginalText = this.submitButton.innerText;
	}

	querySelector<T extends Element>(selectors: string) {
		return this.form.querySelector(selectors) as T;
	}

	querySelectorAll<T extends Element>(selectors: string) {
		return querySelector<T>(selectors, this.form);
	}

	serializeData(): string {
		return $(this.form).serialize();
	}

	private submitListener(e: Event): void {
		e.preventDefault();
		this.messageBox.clear();

		const $form = $(this.form);

		if ($form.valid()) {
			const data = $form.serialize();
			const url = this.form.action;
			this.setFormStateSending();

			$.ajax({
				url: url,
				type: this.form.method,
				data: data,
				success: (data, status, xhr) => { this.ajaxSuccess(data, url, xhr); },
				error: (xhr) => { this.ajaxError(url, xhr); }
			});
		}
	}

	private ajaxSuccess(data: any, url: string, client: XMLHttpRequest): void {
		const typedData = data as TResult;

		if (typedData) {
			const callbackResult = this.submitCallback(typedData);

			if (callbackResult !== FormSubmitCallbackResult.AboutToNavigate) {
				this.clearFormState();
			}
		} else {
			sendRaygun('DataNotOfExpectedType', url, client);
			this.messageBox.error('Something went wrong. Please refresh your page and try again.');
			this.clearFormState();
		}
	}

	private ajaxError(url: string, client: XMLHttpRequest): void {
		if (client.status === 401) {
			// 401 indicates the users CSRF token has expired. Need to refresh the page and try again
			this.messageBox.error('Your session has timed out. Please refresh your page and try again.');
		} else {
			sendRaygun('PostError', url, client);
			this.messageBox.error('Something went wrong. Please refresh your page and try again.');
		}
		this.clearFormState();
	}

	private setFormStateSending(): void {
		this.submitButton.innerText = 'Sending...';
		this.fieldsets.forEach(fieldset => fieldset.disabled = true);
	}

	private clearFormState(): void {
		this.submitButton.innerText = this.submitButtonOriginalText;
		this.fieldsets.forEach(fieldset => fieldset.disabled = false);
	}
}
