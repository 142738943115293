//returns Promise to allow easier transition to code splitting in the future
export function importModuleFromRequireContext(webpackRequireContext, filename: string): Promise<any> {
	filename = filename.toLowerCase();
	return new Promise((resolve, reject) => {
		try {

			const module = webpackRequireContext.keys().filter(x => x.toLowerCase().indexOf('./' + filename) === 0)[0];
			resolve(webpackRequireContext(module || filename));
		} catch (e) {
			reject(e);
		}
	});
}
