import * as React from 'react';
import { DataGrid, DataGridCell, IDataGridColumn, SortDirection } from '../../../components/data-grid/data-grid';
import {
	PaymentLabel, ScheduleDetailsSortableColumn, ScheduleDetailViewModel,
	ScheduleImportDraftDisplayStatus
} from '../../schedule-import-generated';
import { SvgWrapper } from '../../../../Shared/components/svg-wrapper';
import { Formatter } from 'helpers/formatter';
import { observer, inject } from 'mobx-react';
import { ScheduleHelper } from '../../helpers/schedule-helper';
import {
	ScheduleDetailsExpandedRow, ScheduleDetailsExpandedRowProps, ScheduleDetailsProps
} from '../schedule-details-expanded-row';
import { ScheduleDetailsUserActionCreator } from '../../pages/schedules-details/schedule-details-user-actions';
import { ResponsiveVisibility, ResponsiveBreakpoint } from '../../../helpers/responsive-helper';
import * as styles from './styles.less';

export type SummaryDetailsGridProps = {
	data: ScheduleDetailViewModel[];
	paymentLabel: PaymentLabel;
	sortedBy: ScheduleDetailsSortableColumn;
	sortDirection: SortDirection;
	expandedRowDetails: ScheduleDetailsProps;
	userActionCreator?: ScheduleDetailsUserActionCreator;
};

@inject('userActionCreator')
@observer
export class ScheduleDetailsGrid extends React.Component<SummaryDetailsGridProps> {
	render() {
		const { data, paymentLabel, sortedBy, sortDirection } = this.props;
		return <DataGrid
			columns={columns( paymentLabel )}
			data={data}
			getRowKey={getRowKey}
			noDataMessage={noData}
			classNames={[styles.detailsGrid]}
			sortedBy={sortedBy.toString()}
			sortDirection={sortDirection}
			onSortByColumn={this.changeGridSortedBy}
			renderExpandedRow={this.renderExpandedRow}/>;
	}


	private changeGridSortedBy = (newSortedBy: string) => {
		const {sortedBy, sortDirection, userActionCreator } = this.props;

		let newSortDirection: SortDirection = sortDirection;
		const newSortedByKey = ScheduleDetailsSortableColumn[newSortedBy];
		if(newSortedByKey === sortedBy) {
			newSortDirection = sortDirection === SortDirection.ASCENDING ? SortDirection.DESCENDING : SortDirection.ASCENDING;
		}

		userActionCreator.ChangeGridSortedBy(newSortedByKey, newSortDirection);
	}

	private renderExpandedRow = (data: ScheduleDetailViewModel, updateExpandedRow: (key: string) => void) => {
		const props: ScheduleDetailsExpandedRowProps = {
			scheduleId: data.ScheduleId,
			status: data.Status,
			name: data.Giver,
			hasSeenEmail: data.HasSeenEmail,
			giftFrequency: data.GiftFrequency,
			fund: data.Fund,
			amount: data.Amount,
			monthlyTotal: data.MonthlyTotal,
			hasLoadedDetails: false,
			expandedRowData: this.props.expandedRowDetails,
			updateExpandedRow: updateExpandedRow,
		};
		return <ScheduleDetailsExpandedRow {...props} />;
	}
}

const columns = (paymentLabel: PaymentLabel): { [key: string]: IDataGridColumn } => {
	return {
		Status: {
			displayName: 'Status',
			formatter: statusFormatter,
		},
		Giver: {
			displayName: paymentLabel.PayerSentenceCase,
			sortable: true,
			formatter: giverFormatter,
		},
		HasSeenEmail: {
			displayName: 'Email Opened',
			formatter: seenEmailFormatter,
			responsiveVisibility: ResponsiveVisibility.Hidden,
			responsiveBreakpoints: ResponsiveBreakpoint.Xs,
		},
		Fund: {
			displayName: 'Fund',
			formatter: fundFormatter,
		},
		GiftFrequency: {
			displayName: 'Frequency',
			formatter: giftFrequencyFormatter,
			responsiveVisibility: ResponsiveVisibility.Hidden,
			responsiveBreakpoints: ResponsiveBreakpoint.Xs,
		},
		Amount: {
			displayName: 'Amount',
			formatter: amountFormatter,
			sortable: true,
		},
		MonthlyTotal: {
			displayName: 'Est. Avg Monthly',
			formatter: monthlyTotalFormatter,
			sortable: true,
			responsiveVisibility: ResponsiveVisibility.Hidden,
			responsiveBreakpoints: ResponsiveBreakpoint.Xs,
		},
		ViewLink: {
			displayName: '',
			formatter: viewFormatter,
			responsiveVisibility: ResponsiveVisibility.Hidden,
			responsiveBreakpoints: ResponsiveBreakpoint.Xs,
		}
	};
};

const getRowKey = (rowData: ScheduleDetailViewModel): string => {
	return rowData.ScheduleId.toString();
};

const noData = 'No schedules to show';

function giverFormatter(item: ScheduleDetailViewModel, classNames: string): React.ReactNode {
	function handleGiverClick(event) {
		if (event.nativeEvent) {
			event.nativeEvent.stopPropagation();
		}
		event.stopPropagation();
	}

	const { Giver, LinkToPayer } = item;
	return (
		<DataGridCell classNames={classNames}>
			{LinkToPayer ? <a href={LinkToPayer} onClick={handleGiverClick}><strong>{Giver}</strong></a> : <span>{Giver}</span>}
		</DataGridCell>
	);
}

function statusFormatter(data: ScheduleDetailViewModel, className: string): React.ReactNode {
	return (
		<DataGridCell classNames={className}>
			{ScheduleHelper.statusFormatter(data.Status)}
		</DataGridCell>
	);
}

function seenEmailFormatter(data: ScheduleDetailViewModel, classNames: string): React.ReactNode {
	return <DataGridCell classNames={classNames}>{ScheduleHelper.seenEmailFormatter(data.HasSeenEmail)}</DataGridCell>;
}

function giftFrequencyFormatter(data: ScheduleDetailViewModel, classNames: string): React.ReactNode {
	return <DataGridCell classNames={classNames}>{data.GiftFrequency}</DataGridCell>;
}

function fundFormatter(data: ScheduleDetailViewModel, classNames: string): React.ReactNode {
	return <DataGridCell classNames={classNames}>{ScheduleHelper.fundFormatter(data.Fund)}</DataGridCell>;
}

function amountFormatter(data: ScheduleDetailViewModel, classNames: string) {
	return <DataGridCell classNames={classNames}>{ScheduleHelper.amountFormatter(data.Amount)}</DataGridCell>;
}

function monthlyTotalFormatter(data: ScheduleDetailViewModel, classNames: string) {
	return <DataGridCell classNames={classNames}>{ScheduleHelper.amountFormatter(data.MonthlyTotal)}</DataGridCell>;
}

function viewFormatter(data: ScheduleDetailViewModel, classNames: string) {
	return <DataGridCell classNames={classNames}>View</DataGridCell>;
}
