import { createAction } from '../../../../../Shared/state-machine/rx-state-machine-factory';

export const CampaignsLandingAction = {

	initialize: createAction<{
		hasCampaigns: boolean,
		pollForCollectedAmounts: boolean,
		collectedAmountsUpdatedSince: Date,
		savedNotification: string,
	}>(),

	loadDraftCampaigns: createAction<{
		position: number,
	}>(),

	loadActiveOrScheduledCampaigns: createAction<{
		position: number,
	}>(),

	loadClosedCampaigns: createAction<{
		position: number,
	}>(),

	getCollectedAmounts: createAction<{
		collectedAmountsUpdatedSince: Date,
	}>(),

	showError: createAction<{
		errorMessage: string
	}>(),

};
