export class PreventDoubleClick {
	static init() {
		$('form').on('submit', event => {
			if (!event.result) {
				//submit was cancelled, i.e. validate failed.
				return;
			}

			const $form = $(event.target);

			$form.find('[data-action="preventdoubleclick"]')
				.prop('disabled', true)
				.attr('value', 'Sending...')
				.text('Sending...')
				.click(e => {
					//for safety
					e.preventDefault();
					return false;
				});

			$form.find('[data-action="hideonsubmit"]').hide();
		});
	}
}
