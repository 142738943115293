import { observable, action, computed } from 'mobx';

import { BrandingSettingsViewModel, ListingDetailsViewModel, BrandingPackageViewModel, CampusDetailsViewModel } from './branding-settings-generated';
import { getBrandingSettingsDataService } from './branding-settings-data-service';
import { BrandingSettingsDraftState, BrandingSettingsFormViewModel } from './editor-form/branding-settings-form-view-model';

export enum BrandingPackageState {
	NoDraft = 1,
	ChangesAwaitingPublish = 2,
	PublishAwaitingConfirmation = 3,
	PublishingInProgress = 4,
	DiscardAwaitingConfirmation = 5,
	DiscardInProgress = 6,
	Conflict = 7
}

export enum PublishState {
	Unavailable = 1,
	Ready = 2,
	Pending = 3,
	Publishing = 4,
}

export class BrandingSettingsMainViewModel {

	formViewModel: BrandingSettingsFormViewModel;

	@observable
	packageState: BrandingPackageState;

	@observable
	selectedListingId: string;

	@observable
	selectedCampusKey: string;

	@observable
	selectedCampusName: string;

	@observable
	showMobilePreview: boolean;

	@observable
	isLoadingListingDetails: boolean;

	@observable
	isLoadingCampus: boolean;

	@observable
	sessionExpired: boolean = false;

	@observable
	organizationName: string;

	@observable
	defaultOrgBrandingListingId?: number;

	@observable
	defaultCampusBrandingListingId?: number;

	@observable
	canConfigureOrgDefaultBranding: boolean;

	hasReliableOrgAndCampusBranding: boolean;

	@computed
	get publishState(): PublishState {
		if (this.packageState === BrandingPackageState.PublishingInProgress) {
			return PublishState.Publishing;
		} else if (this.packageState === BrandingPackageState.PublishAwaitingConfirmation && this.formViewModel.isSavingOrUploadingOrProcessing) {
			return PublishState.Pending;
		} else if ((this.packageState === BrandingPackageState.ChangesAwaitingPublish || this.packageState === BrandingPackageState.PublishAwaitingConfirmation)
			&& this.formViewModel.isFormValid) {
			return PublishState.Ready;
		} else if (this.packageState === BrandingPackageState.Conflict) {
			return PublishState.Unavailable;
		} else {
			return PublishState.Unavailable;
		}
	}

	@computed
	get isDirty() {
		return this.isProcessing || this.formViewModel.draftState === BrandingSettingsDraftState.Unsaved;
	}

	@computed
	get isProcessing() {
		return this.formViewModel.isSavingOrUploadingOrProcessing || this.packageState === BrandingPackageState.PublishingInProgress;
	}

	constructor(public model: BrandingSettingsViewModel) {

		// temporary always include vm and dataService on the page for easier testing
		// if (DEBUG) {
		(window as any)['dataService'] = getBrandingSettingsDataService();
		(window as any)['vm'] = this;
		// }

		this.hasReliableOrgAndCampusBranding = model.HasReliableOrgAndCampusBranding;

		if (model.HasReliableOrgAndCampusBranding) {
			if (model.SelectedCampus) {
				this.onSelectCampus(model.SelectedCampus);
				this.setOrganizationName(this.model.OrganizationName);
			} else {
				this.onSelectListing(model.SelectedListing)
			}
		} else {
			this.onSelectListing(model.SelectedListing);
		}
	}

	@action.bound
	setSessionExpired(sessionExpired: boolean) {
		this.sessionExpired = sessionExpired;
	}

	@action.bound
	setOrganizationName(name: string) {
		this.organizationName = name;
	}

	@action.bound
	setDefaultOrgBranding(listingId: number) {
		if (!!listingId) {
			this.defaultOrgBrandingListingId = listingId;
		}
	}

	@action.bound
	setCanConfigureOrgDefaultBranding(canConfigure: boolean) {
		this.canConfigureOrgDefaultBranding = canConfigure;
	}

	@action.bound
	clearDefaultOrgBranding(){
		this.defaultOrgBrandingListingId = null;
	}

	@action.bound
	setDefaultCampusBranding(listingId: number) {
		if (!!listingId) {
			this.defaultCampusBrandingListingId = listingId;
		}
	}

	@action.bound
	clearDefaultCampusBranding(){
		this.defaultCampusBrandingListingId = null;
	}

	@action.bound
	onSelectListing(selectedListing: ListingDetailsViewModel) {
		this.packageState = selectedListing.BrandingPackage.DraftKey === null ? BrandingPackageState.NoDraft : BrandingPackageState.ChangesAwaitingPublish;

		if (selectedListing.ListingId !== this.model.SelectedListing.ListingId) {
			this.model.SelectedListing = selectedListing;
		}

		this.updateFormViewModel(selectedListing);
		this.selectedListingId = `${selectedListing.ListingId}`;
	}

	@action.bound
	onSelectCampus(selectedCampus: CampusDetailsViewModel) {
		this.selectedCampusKey = selectedCampus.CampusKey;
		this.selectedCampusName = selectedCampus.CampusName;
		this.model.Listings = selectedCampus.Listings;
		this.model.SelectedListing = selectedCampus.SelectedListing;

		this.setDefaultCampusBranding(selectedCampus.DefaultCampusBrandingListingId);
		this.setDefaultOrgBranding(selectedCampus.DefaultOrgBrandingListingId);
		this.setCanConfigureOrgDefaultBranding(selectedCampus.CanConfigureOrgDefaultBranding);

		this.onSelectListing(selectedCampus.SelectedListing);
	}

	@action.bound
	updateFormViewModel(viewModel: ListingDetailsViewModel) {
		if (this.formViewModel) {
			this.formViewModel.clearValidationErrors();
			this.formViewModel.draftState = BrandingSettingsDraftState.Saved;

			this.formViewModel.hasCampusBrandingMerchantChanged = false;
			this.formViewModel.hasOrganizationBrandingMerchantChanged = false;

			this.formViewModel.applyBrandingPackage(viewModel.BrandingPackage);
		} else {
			this.formViewModel = new BrandingSettingsFormViewModel(this.model.SupportsBackdropImages, viewModel.BrandingPackage);
		}

		this.formViewModel.hasReliableOrgAndCampusBranding = this.model.HasReliableOrgAndCampusBranding;
		this.formViewModel.isOrganizationBrandingMerchant = viewModel.IsOrgBrandingMerchant;
		this.formViewModel.isCampusBrandingMerchant = viewModel.IsCampusBrandingMerchant;
		this.formViewModel.setCanConfigureOrgDefaultBranding(this.canConfigureOrgDefaultBranding);
	}

	@action.bound
	handleMobilePreviewChange(showMobilePreview: boolean) {
		this.showMobilePreview = showMobilePreview;
	}

	@action.bound
	loadLatestBrandingPackage(latestBrandingPackage: BrandingPackageViewModel) {
		this.isLoadingListingDetails = true;
		this.formViewModel.applyBrandingPackage(latestBrandingPackage);
		this.formViewModel.clearValidationErrors();
		this.formViewModel.draftState = BrandingSettingsDraftState.Saved;
		this.packageState = latestBrandingPackage.DraftKey ? BrandingPackageState.ChangesAwaitingPublish : BrandingPackageState.NoDraft;
	}
}
