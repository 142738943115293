import * as React from 'react';
import {observer} from 'mobx-react';
import {Member, Main} from '../view-models/index';
import {popupHandler} from '../../components/popup-helper';
import {observable, action, computed} from 'mobx';

@observer
export default class PreviewStatement extends React.Component<{ vm: Main }, any> {

	member: Member;

	@observable
	generating: boolean = false;
	@observable
	generationMessage: string = '';

	regenerateStatement = () => {
		let { vm } = this.props;

		this.generating = true;

		/*
		There is a problem here that we don't know when the statment has completed generating
		this can take anywhere from 20s to a few minutes

		# We need to create endpoints to monitor the batch that this statement was linked too
		 or
		# we give the user a message to checkback later
		*/

		this.generationMessage = 'Generating';

		vm.dataService.regenerateCustomGivingStatement({
			CommunityMemberId: vm.statementToPreviewMemberId,
			GivingStatementRunId: vm.statementToPreviewRunId,
		})
		.then(() => {
			this.generationMessage = 'Generating - Check back shortly for the updated statement';
		})
		.catch(() => {
			this.generationMessage = 'Generating - There was a problem generating this statement';
		})
		.then(() => {
			setTimeout(() => {
					popupHandler.close();
					this.reset();
				},
				5 * 1000);
		});
	}

	@action
	reset() {
		this.generating = false;
	}

	@computed
	get canGenerate() {
		return (this.member.customStatements && this.member.customStatements.length > 0);
	}

	generateButton() {
		if (!this.canGenerate) {
			return null;
		}

		if (this.generating) {
			return (
				<label className="loading-label text-center">
					<img className="loading-icon" src="/Content/LoggedInWeb/assets/images/loading.gif"/>
					{this.generationMessage}
				</label>);
		}

		return (<button type="button" className="btn btn-ghost" onClick={this.regenerateStatement}>+ Create Replacement</button>);
	}

	iFrameRender(vm:any) {
		return(<iframe className="preview-content" src={`communitymembers/preview/${vm.statementToPreviewMemberId}/${vm.statementToPreviewRunId}`}></iframe>);
	}

	statementLabel() {
		return 'Giving Statement';
	}

	render() {

		let { vm } = this.props;
		this.member = vm.currentMembers.filter(x => vm.statementToPreviewMemberId === x.id)[0];

		if (!this.member) {
			return null;
		}
		if (this.member.canPreview) {
			return (
				<div>
					<h1>
					{this.member.details.FirstName} {this.member.details.LastName} &middot; {this.statementLabel()}
					</h1>
					<hr className="short"/>

					<p className="content-text">
						This draft giving statement contains real data from one of your donors,
						to give you a feel of how your statement will look.They have been designed to fit the US Window Envelope #10.
					</p>

					{this.generateButton() }
					<div>
						{this.iFrameRender(vm)}
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<h1>
					{this.member.details.FirstName} {this.member.details.LastName} &middot; {this.statementLabel()}
					</h1>
					<hr className="short"/>

					<p className="content-text">
						This community member had no eligible transactions for this year and will not be receiving a statement.
						If you think this is incorrect, please check their <a href="transactions">transaction history </a>or <a href="https://care.pushpay.com">get help from Pushpay.</a>
					</p>
				</div>
			);
		}
	}
}
