import * as React from 'react';
import { Panel, PanelBody, PanelType } from '../../../components/panel';
import { SvgWrapper } from '../../../../Shared/components/svg-wrapper';
import { dateService } from '../../services/date';
import { GivingStatementRunStatus } from '../../giving-statements-generated';
import { DeleteStatementsButton } from '../delete-statements-button';
import * as styles from './styles.less'

interface INoStatementsPanelProps {
	from?: Date;
	to?: Date;
	status: GivingStatementRunStatus;
	onRegenerateStatements(): void;
	hasBeenPublished: boolean;
	onDeleteStatements(): void;
}

export const NoStatementsPanel: React.FunctionComponent<INoStatementsPanelProps> = ({ from, to, status, onRegenerateStatements, hasBeenPublished, onDeleteStatements }) => (
	<Panel extraClassNames={styles.panel} panelType={PanelType.DEFAULT}>
		<PanelBody>
			<SvgWrapper svg="alert-exclamation-icon" />
			<h2>No statements were generated</h2>
			{from && to &&
				<p>There were no statements to generate between the period of {dateService.getStatementDateRange(from, to)}</p>
			}
			{
				status === GivingStatementRunStatus.Generated
				&& <div className={styles.regenerateStatements}>
					<a href="javascript:void(0)" onClick={onRegenerateStatements}>Regenerate statements </a>
					<SvgWrapper svg="icon-chevron-right" />
				</div>
			}
			{
				!hasBeenPublished &&
				<DeleteStatementsButton className={styles.deleteButton} onDelete={onDeleteStatements} />
			}
		</PanelBody>
	</Panel>
);
