import * as React from 'react';
import { Panel, PanelBody, PanelHeader, PanelType } from '../../../components/panel';
import * as styles from './styles.less';
import moment from 'moment';
import { SvgWrapper } from '../../../../Shared/components/svg-wrapper';

const dateFormat = 'MMMM DD, YYYY';

export interface ITimelineProps {
	daysLeft: number;
	startDate: Date;
	endDate: Date;
	editDetailsUrl: string;
	isMigrationEditable: boolean;
	extraClassNames?: string;
}

export class Timeline extends React.Component<ITimelineProps> {
	render() {
		const { daysLeft, startDate, endDate, editDetailsUrl, isMigrationEditable, extraClassNames } = this.props;
		const startDateFormatted = moment(startDate).format(dateFormat);
		const endDateFormatted = moment(endDate).format(dateFormat);
		return (
			<Panel panelType={PanelType.HIGHLIGHT} extraClassNames={extraClassNames}>
				<PanelHeader>
					<h1>Timeline</h1>
				</PanelHeader>
				<PanelBody>
					<h2 className={styles.timelineCountdown}>{daysLeft} Days left</h2>
					<p><strong>Start date:</strong> {startDateFormatted}</p>
					<p><strong>End date:</strong> {endDateFormatted}</p>
					{isMigrationEditable &&
						<a href={editDetailsUrl} className={styles.detailsLink}><SvgWrapper svg="icon-edit" width="22" height="22" className={styles.pencilIcon}/> Edit migration details</a>
					}
				</PanelBody>
			</Panel>
		);
	}
}
