import * as React from 'react';
import { observer } from 'mobx-react';
import { Button } from '../../../../Shared/components/button/button';
import { Fragment } from '../../../../Shared/components/fragment';

export interface IGenerateStatementsModalProps {
	totalStatements: number;
	onGenerateClick: () => void;
	onCancelClick: () => void;
	disabledForCampusOrg: boolean;
}

export const GenerateStatementsModal: React.StatelessComponent<IGenerateStatementsModalProps> = observer((props) => {
	const { totalStatements, onGenerateClick, onCancelClick, disabledForCampusOrg } = props;

	return (
		<Fragment>
			<div className="modal-content" data-pp-at-target="Modal">
				<button type="button" className="close" onClick={onCancelClick} aria-label="Close" />
				<div className="modal-body">
					{disabledForCampusOrg ?
						<h2>Giving Statements are about to be generated</h2> :
						<h2>You're about to generate <strong>{totalStatements}</strong> {totalStatements === 1 ? 'statement' : 'statements'}</h2>
					}
					<div className="modal-text">
						Once your statements are ready you can choose how you would like to publish them to your community members.
					</div>
				</div>
				<div className="modal-footer">
					<Button type="button" className="btn btn-cancel" onClick={onCancelClick}>Cancel</Button>
					<Button type="submit" className="btn btn-primary" onClick={onGenerateClick}>Generate</Button>
				</div>
			</div>
		</Fragment>
	);
});
