import * as React from 'react';
import { Button } from '../../../../Shared/components/button/button';
import { SvgWrapper } from '../../../../Shared/components/svg-wrapper';
import { classNames } from '../../../../Shared/utils/classnames';
import * as styles from './styles.less';

export const DeleteStatementsButton: React.FunctionComponent<{ onDelete: () => void, disableButton?: boolean, className: string }> = ({ onDelete, disableButton, className: classNameProp }) => (
	<Button className={classNames('btn', 'btn-link', styles.deleteButton, classNameProp)} type="button" onClick={onDelete} disabled={disableButton}>
        <SvgWrapper className={styles.deleteIcon} svg="icon-delete" />
        Delete Statements
    </Button>
);
