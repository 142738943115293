import * as React from 'react';
import { observer } from 'mobx-react';

import { ScheduleImportSetupAlertViewModel } from './schedule-import-setup-alert-view-model';
import { ScheduleImportStep } from '../schedule-import-step';
import { Button } from '../../../components/button';
import { Formatter } from '../../../helpers/formatter';
import { ValidatableForm } from '../../../components/form-controls/validatable-form';
import { ModalDialogCommander } from '../../../components/modal-dialog-commander';
import { ModalDialog } from '../../../components/modal-dialog';
import { PanelConfirmDetails } from './panel-confirm-details/panel-confirm-details';
import { PanelDateRange } from './panel-date-range/panel-date-range';
import { PanelContactDetails } from './panel-contact-details/panel-contact-details';
import { PanelEmailTemplate } from './panel-email-template/panel-email-template';

enum SubmitType {
	Draft = 0,
	Confirm = 1,
}

@observer
export class ScheduleImportSetupAlert extends React.Component<{ vm: ScheduleImportSetupAlertViewModel }> {
	private submitType: SubmitType;

	render() {
		const {
			viewData : {
				BundlesSummary: {
					ImportedDonorsCount,
					ImportedMonthlyTotal,
					ImportedSchedulesCount,
				},
				PaymentLabel,
				EmailBranding,
				ReviewUrl,
				CanConfirmMigration,
				ReviewCampaignHelpUrl,
			},
			dateRangeViewModel,
			contactDetailsViewModel,
			emailsViewModel,
			emailContentType,
			updateEmailContent,
			defaultStartPoint,
			updateDefaultStartPoint,
			actionInProgress,
			isDefaultEmailTemplatesModified,
		} = this.props.vm;

		const headerButton = <a className="btn btn-link arrow-hollow-right-after" href={ReviewUrl}>Add or remove schedules</a>;

		return (
			<ScheduleImportStep vm={this.props.vm} headerButton={headerButton}>
				<ValidatableForm className="panel panel-default" onSubmit={this.handleSubmit}>
					<PanelConfirmDetails
						numberOfSchedules={ImportedSchedulesCount}
						numberOfGivers={ImportedDonorsCount}
						monthlyTotal={ImportedMonthlyTotal}
						paymentLabel={PaymentLabel} />
					<PanelDateRange
						vm={dateRangeViewModel}
						paymentLabel={PaymentLabel} />
					<PanelContactDetails
						vm={contactDetailsViewModel}
						paymentLabel={PaymentLabel} />
					<PanelEmailTemplate
						vm={emailsViewModel}
						paymentLabel={PaymentLabel}
						emailContentType={emailContentType}
						defaultStartPoint={defaultStartPoint}
						contactName={contactDetailsViewModel.contactName}
						contactEmail={contactDetailsViewModel.contactEmail}
						contactPhone={contactDetailsViewModel.contactPhone}
						emailBranding={EmailBranding}
						isDefaultEmailTemplatesModified={isDefaultEmailTemplatesModified}
						endDate={dateRangeViewModel.endDate}
						updateEmailContent={updateEmailContent}
						updateDefaultStartPoint={updateDefaultStartPoint}
						reviewCampaignHelpUrl={ReviewCampaignHelpUrl} />
					<div className="panel-footer panel-footer-btn-group">
						<Button className="btn btn-ghost" submit={true} onClick={() => this.submitType = SubmitType.Draft} disabled={actionInProgress}>Save draft</Button>
						<Button className="btn btn-default" submit={true} onClick={() => this.submitType = SubmitType.Confirm} disabled={actionInProgress || !CanConfirmMigration }>Next</Button>
					</div>
				</ValidatableForm>
			</ScheduleImportStep>
		);
	}

	private handleSubmit = () => {
		const {
			viewData: {
				BundlesSummary: { ImportedDonorsCount },
				PaymentLabel,
				CanConfirmMigration,
			},
			dateRangeViewModel: {
				startDate,
			},
			saveMigration,
		} = this.props.vm;

		switch (this.submitType) {
			case SubmitType.Draft:
				saveMigration();
				break;
			case SubmitType.Confirm:
				if (CanConfirmMigration === false) {
					return;
				}

				const confirmButton = <Button className="btn btn-primary" onClick={this.confirmMigration}>Confirm</Button>;
				const buttons = [{label: 'Cancel', dismiss: true, isPrimary: false}, confirmButton];

				// tslint:disable:max-line-length
				ModalDialogCommander.showReactDialog(
					<ModalDialog
						title={`We\'ll be emailing ${ ImportedDonorsCount } ${ ImportedDonorsCount === 1 ? PaymentLabel.PayerLowerCase : PaymentLabel.PayerPluralLowerCase} from ${Formatter.formatDate(startDate, 'MMM D, YYYY')} to let them know about your transition to Pushpay and invite them to set up a recurring schedule. Are you sure you want to do this?`}
						showAlert={false}
						hideCloseButton={true}
						buttons={buttons}/>);
				// tslint:enable:max-line-length
				break;
		}
	}

	private confirmMigration = () => {
		ModalDialogCommander.forceCloseCurrent();
		this.props.vm.confirmMigration();
	}
}
