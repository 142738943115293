import { getSafariWebKitVersion, detectIOS } from '../utils/browser-detection';

export class BrowserSupportHelper {
	private static dateInputSupported: boolean = null;
	private static touchSupported: boolean = null;
	private static browserIsIOS: boolean = null;

	static transitionEndEvent() {
		const transitions = {
			'transition': 'transitionend',
			'OTransition': 'oTransitionEnd',
			'MozTransition': 'transitionend',
			'WebkitTransition': 'webkitTransitionEnd'
		};

		// If we're running in a browserless environment (eg. SSR), it doesn't apply.
		// Return a string so that it maintains the type that is expected.
		if (typeof document === 'undefined') {
			return '';
		}

		const el = document.createElement('fakeelement');
		return Object.getOwnPropertyNames(transitions).filter(t => el.style[t] !== undefined).map(t => transitions[t])[0];
	}

	// tslint:disable curly
	static matchesSelector(el: any, selector: string): boolean {
		if (el.matches) return el.matches(selector);
		if (el.matchesSelector) return el.matchesSelector(selector);
		if (el.msMatchesSelector) return el.msMatchesSelector(selector);
		if (el.mozMatchesSelector) return el.mozMatchesSelector(selector);
		if (el.webkitMatchesSelector) return el.webkitMatchesSelector(selector);
		if (el.oMatchesSelector) return el.oMatchesSelector(selector);

		const matches = document.querySelectorAll(selector);
		let i = matches.length;
		while (--i >= 0 && matches.item(i) !== el) { }
		return i > -1;
	}
	// tslint:enable curly

	static doesNotSupportCSSGrid() : boolean {
		const safariWebKitVersion = getSafariWebKitVersion();

		// Safari WebKit 603.*.* has issues with grid cell overflow
		return safariWebKitVersion && safariWebKitVersion.indexOf('603') > -1;
	}

	static addNoCSSGridClassIfNeeded() : void {
		if (this.doesNotSupportCSSGrid()) {
			document.body.classList.add('no-cssgrid');
		}
	}

	static supportsDateInput() : boolean {
		if (this.dateInputSupported === null) {
			const test = document.createElement('input');

			try {
				test.type = 'date';
				this.dateInputSupported = test.type === 'date';
			} catch (error) {
				this.dateInputSupported = false;
			}
		}

		return this.dateInputSupported;
	}

	static isTouchSupported() {
		if (this.touchSupported === null) {
			this.touchSupported = 'ontouchstart' in document;
		}

		return this.touchSupported;
	}

	static isIOS() {
		if (this.browserIsIOS === null) {
			this.browserIsIOS = detectIOS();
		}
		return this.browserIsIOS;
	}
}
