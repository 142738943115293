import * as React from 'react';
import { TipBox } from '../../../components/tipbox';
import { SvgWrapper } from '../../../../Shared/components/svg-wrapper';
import * as styles from './preliminary-panel.less';
import { PaymentLabel } from '../../giving-statements-generated';

export interface IPreliminaryPanelProps {
	communityMemberUrl: string;
	transactionImportUrl: string;
	fundsUrl: string;
	giftEntryUrl: string;
	paymentLabel: PaymentLabel;
}

export class PreliminaryPanel extends React.Component<IPreliminaryPanelProps> {
	render() {
		const { communityMemberUrl, transactionImportUrl, fundsUrl, giftEntryUrl, paymentLabel } = this.props;

		return (
			<TipBox storageKey="giving-statements-preliminary">
				<div className={styles.container}>
					<h2 className={styles.heading}>Things to check before you prepare statements:</h2>
					<ul className={styles.itemContainer}>
						<li className={styles.item}>
							<span>Review community members accounts.</span>
							<a href={communityMemberUrl}>Go to Community</a>
							<SvgWrapper svg="icon-chevron-right" />
						</li>
						<li className={styles.item}>
							<span>Import {paymentLabel.VerbPresentTenseLowerCase} transaction data from any other sources.</span>
							<a href={transactionImportUrl}>Go to Transaction Import</a>
							<SvgWrapper svg="icon-chevron-right" />
						</li>
						<li className={styles.item}>
							<span>Reallocate any transactions that are in an incorrect fund.</span>
							<a href={fundsUrl}>Go to Funds</a>
							<SvgWrapper svg="icon-chevron-right" />
						</li>
						<li className={styles.item}>
							<span>Enter any outstanding offline {paymentLabel.NounPluralLowerCase} using {paymentLabel.GiftEntryFeatureName}.</span>
							<a href={giftEntryUrl}>Go to {paymentLabel.GiftEntryFeatureName}</a>
							<SvgWrapper svg="icon-chevron-right" />
						</li>
					</ul>
				</div>
			</TipBox>
		);
	}
}
