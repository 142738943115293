import * as React from 'react';
import { createStats, millisecondsSinceNavigationStart } from '../../utils/page-loading-stats';
import { ModalDialogCommander } from '../../components/modal-dialog-commander';
import { action, observable, extendObservable, reaction, computed } from 'mobx';
import { Models } from '../check-deposit-generated';
import { AllocationBatchDetailsModel } from './allocation-batch-details-model';
import { AllocationCheckDetailsListModel } from './allocation-check-details-list-model';
import { CheckDepositDataService, CheckDepositDataServiceAction, isCheckDepositAction } from '../check-deposit-data-service';
import { AllocationGridCompleteViewModel } from './allocation-grid-complete-view-model';
import UiBatchStatus = Models.UiBatchStatus;
import { ModalDialog } from '../../components/modal-dialog';
import { BatchAlreadyAllocatedDialog } from './batch-already-allocated-dialog';
import { BatchHasIncompleteChecksDialog } from './batch-has-incomplete-checks-dialog';
import { alertController } from '../../components/alert-controller';
import { reportExportError } from '../../utils/error-utils';

const TRANSACTIONS_FOR_BATCH_ACTION = 'transactionSummaryForBatch';

//subset of Models.CheckAllocationEditModel
export interface CheckEditModel {
	EncodedToken: string;
	PaymentVersion: number;
	CommonPaymentFields: Models.CommonPaymentFieldsModel;
	CommunityMemberId: number;
	WantsTransactionNotifications: boolean;
	Splits: Models.CheckSplitEditModel[];
	YourId: string;
}

export class AllocationViewModel {
	@observable BatchDetailsViewModel: AllocationBatchDetailsModel;
	@observable CheckDetailsViewModel: AllocationCheckDetailsListModel;
	@observable TransactionDetailsViewModel: AllocationGridCompleteViewModel;
	@observable isProcessingCompleteAllocationRequest: boolean;
	private fundKey: string = '';

	@computed
	get isLoadingLastPaymentDetails() {
		return !!this.loadingLastPaymentDetailsRequest;
	}

	@observable
	private loadingLastPaymentDetailsRequest: string;

	private isCheckSplittingEnabled: boolean;

	constructor(
		public dataService: CheckDepositDataService,
		batchDetails: Models.CheckAllocationBatchDetailsModel
	) {
		this.BatchDetailsViewModel = new AllocationBatchDetailsModel(batchDetails);
		this.isCheckSplittingEnabled = this.BatchDetailsViewModel.BatchDetails.CheckSplittingEnabled;

		if (this.BatchDetailsViewModel.BatchDetails.BatchStatus === UiBatchStatus.Closed) {
			this.TransactionDetailsViewModel = new AllocationGridCompleteViewModel();
			this.dataService.subscribe((action) => {
				this.subscribeToQueryTransactionBatches(action);
			});
			this.getTransactionData();

			if (batchDetails.HasExportErrored) {
				reportExportError();
			}

			console.log(batchDetails.PayersReconciledMessage);
			if (batchDetails.PayersReconciledMessage) {
				alertController.showSuccess(batchDetails.PayersReconciledMessage);
			}
		} else {
			this.CheckDetailsViewModel = new AllocationCheckDetailsListModel(this.markCheckAsRead,
				this.postCheckData,
				this.loadPreviousPaymentDetails,
				this.getCheckData,
				this.getCheckDatum,
				batchDetails);
			this.fundKey = this.BatchDetailsViewModel.BatchDetails.CustomFields.filter(x => x.Type === Models.CustomFieldType.Fund)[0].Key;


			this.dataService.subscribe((action) => {
				this.subscribeToQueryCheckBatches(action);
				this.subscribeToSaveCheckAllocation(action);
				this.subscribeToCompleteCheckAllocation(action);
				this.subscribeToApplySpeedSettings(action);
				this.subscribeToGetLastPaymentDetails(action);
			});
			this.getCheckData();

			reaction(() => this.CheckDetailsViewModel.ExpandedCheckDetails, value => {
				this.cancelCurrentGetLastPaymentDetailsRequest();
			});
		}
	}

	@action
	getCheckData = () => {
		this.dataService.initRequest('checksForBatchWithSplits', {
			merchantId: this.BatchDetailsViewModel.BatchDetails.MerchantId,
			batchId: this.BatchDetailsViewModel.BatchDetails.BatchId,
			uiTimingStats: createStats<Models.UiTimingStats>({
				TimeSinceNavigationStart: millisecondsSinceNavigationStart()
			})
		});
	}

	@action
	getCheckDatum = (encodedToken: string) => {
		this.dataService.initRequest('checkForBatchWithSplits', {
			merchantId: this.BatchDetailsViewModel.BatchDetails.MerchantId,
			batchId: this.BatchDetailsViewModel.BatchDetails.BatchId,
			encodedToken,
			uiTimingStats: createStats<Models.UiTimingStats>({
				TimeSinceNavigationStart: millisecondsSinceNavigationStart()
			})
		});
	}

	@action
	postCheckData = (check: CheckEditModel, stats: Models.CheckAllocationStats) => {
		const { BatchId, MerchantVersion } = this.BatchDetailsViewModel.BatchDetails;

		this.CheckDetailsViewModel.isProcessingSaveRequest = true;
		this.BatchDetailsViewModel.isProcessingSaveRequest = true;
		this.dataService.initRequest('saveSplitCheckAllocation', {
			merchantId: this.BatchDetailsViewModel.BatchDetails.MerchantId,
			model: { ...check, MerchantVersion, BatchId },
			stats: stats
		});
	}

	getTransactionData() {
		this.dataService.initRequest(TRANSACTIONS_FOR_BATCH_ACTION, { batchId: this.BatchDetailsViewModel.BatchDetails.BatchId });
	}

	@action
	completeAllocation = () => {
		const { BatchDetails: { BatchId } } = this.BatchDetailsViewModel;

		this.isProcessingCompleteAllocationRequest = true;
		this.dataService.initRequest('completeBatch', {
			batchId: BatchId,
			uiTimingStats: createStats<Models.UiTimingStats>({
				TimeSinceNavigationStart: millisecondsSinceNavigationStart()
			})
		});
	}

	@action.bound
	markCheckAsRead(token: string) {
		const { BatchDetails: { BatchId } } = this.BatchDetailsViewModel;

		this.dataService.initRequest('markCheckAsRead', {
			batchId: BatchId,
			encodedToken: token
		});
	}

	@action.bound
	cancelCurrentGetLastPaymentDetailsRequest() {
		if (this.loadingLastPaymentDetailsRequest) {
			this.dataService.cancelRequest(this.loadingLastPaymentDetailsRequest);
			this.loadingLastPaymentDetailsRequest = null;
		}
	}

	@action.bound
	loadPreviousPaymentDetails(model: Models.GetLastPaymentDetailsRequestModel) {
		this.dataService.initRequest('getLastPaymentDetails', {
			merchantId: this.BatchDetailsViewModel.BatchDetails.MerchantId,
			model: model
		});

		return this.cancelCurrentGetLastPaymentDetailsRequest;
	}

	applySpeedSettings = () => {
		const { BatchDetails: { BatchId }, GivenOn, FundKey } = this.BatchDetailsViewModel;

		this.BatchDetailsViewModel.isProcessingSpeedSettingsRequest = true;
		this.CheckDetailsViewModel.bulkUpdateInProgress = true;
		this.dataService.initRequest('applySpeedSettingsWithSplits', {
			merchantId: this.BatchDetailsViewModel.BatchDetails.MerchantId,
			batchId: BatchId,
			model: { GivenOn, FundKey },
			uiTimingStats: createStats<Models.UiTimingStats>({
				TimeSinceNavigationStart: millisecondsSinceNavigationStart()
			})
		});
	}

	@action
	private subscribeToQueryCheckBatches = (action: CheckDepositDataServiceAction) => {
		if (!isCheckDepositAction(action, 'checksForBatchWithSplits')) {
			return;
		}

		switch (action.type) {
			case 'request_success':
				this.CheckDetailsViewModel.updateChecks(action.response);
				break;
			case 'request_error':
				this.reportError(action);
				break;
		}
	}

	@action
	private subscribeToQueryTransactionBatches = (action: CheckDepositDataServiceAction) => {
		if (!isCheckDepositAction(action, TRANSACTIONS_FOR_BATCH_ACTION)) {
			return;
		}

		switch (action.type) {
			case 'request_success':
				this.TransactionDetailsViewModel.update(action.response);
				break;
			case 'request_error':
				this.reportError(action);
				break;
		}
	}

	@action
	private subscribeToSaveCheckAllocation = (action: CheckDepositDataServiceAction) => {
		if (!isCheckDepositAction(action, 'saveSplitCheckAllocation')) {
			return;
		}

		switch (action.type) {
			case 'request_success':
				this.CheckDetailsViewModel.isProcessingSaveRequest = false;
				this.BatchDetailsViewModel.isProcessingSaveRequest = false;

				const { handleStartEditing, endCheckEditing, ExpandedCheckDetails } = this.CheckDetailsViewModel;
				Object.assign(ExpandedCheckDetails, action.response);

				const nextUnallocatedCheck = this.CheckDetailsViewModel.nextUnallocatedCheck();
				if (nextUnallocatedCheck) {
					handleStartEditing(nextUnallocatedCheck.EncodedToken, true);
				} else {
					endCheckEditing(null, true);
				}
				break;
			case 'request_error':
				this.CheckDetailsViewModel.isProcessingSaveRequest = false;
				this.BatchDetailsViewModel.isProcessingSaveRequest = false;
				if (action.error.conflict && this.CheckDetailsViewModel.ExpandedCheckStore) {
					Object.assign(this.CheckDetailsViewModel.ExpandedCheckDetails, action.error.updatedRecordForConflict);
					this.CheckDetailsViewModel.ExpandedCheckStore.updateCheck(action.error.updatedRecordForConflict);
				}
				alertController.showValidationErrors(action.error.validationErrors);
				break;
		}
	}

	@action
	private subscribeToCompleteCheckAllocation = (action: CheckDepositDataServiceAction) => {
		if (!isCheckDepositAction(action, 'completeBatch')) {
			return;
		}

		switch (action.type) {
			case 'request_success':
				window.location.href = this.BatchDetailsViewModel.BatchDetails.BatchListUrl;
				break;
			case 'request_error':
				this.isProcessingCompleteAllocationRequest = false;

				if (action.error.conflict) {
					const { BatchAlreadyAllocated: info, NumberOfChecksWithMissingData } = action.error.updatedRecordForConflict as Models.BatchAllocationResultModel;
					if (info) {
						ModalDialogCommander.showReactDialog(React.createElement(BatchAlreadyAllocatedDialog, { model: info }));
						return;
					}

					if (NumberOfChecksWithMissingData > 0) {
						ModalDialogCommander.showReactDialog(React.createElement(BatchHasIncompleteChecksDialog, { numberOfChecksWithMissingData: NumberOfChecksWithMissingData }));
						return;
					}
				}

				this.reportError(action);
				break;
		}
	}

	@action
	private subscribeToApplySpeedSettings = (action: CheckDepositDataServiceAction) => {
		if (!isCheckDepositAction(action, 'applySpeedSettingsWithSplits')) {
			return;
		}

		switch (action.type) {
			case 'request_success':
				this.BatchDetailsViewModel.FundKey = '';
				this.BatchDetailsViewModel.GivenOn = null;
				this.CheckDetailsViewModel.updateChecks(action.response);

				this.BatchDetailsViewModel.isProcessingSpeedSettingsRequest = false;
				this.CheckDetailsViewModel.bulkUpdateInProgress = false;
				break;
			case 'request_error':
				this.BatchDetailsViewModel.isProcessingSpeedSettingsRequest = false;
				this.CheckDetailsViewModel.bulkUpdateInProgress = false;
				alertController.showValidationErrors(action.error.validationErrors);
				break;
		}
	}

	@action
	private subscribeToGetLastPaymentDetails = (action: CheckDepositDataServiceAction) => {
		if (!isCheckDepositAction(action, 'getLastPaymentDetails')) {
			return;
		}

		switch (action.type) {
			case 'request_init':
				this.loadingLastPaymentDetailsRequest = action.requestId;
				break;
			case 'request_success':
				const { check } = this.CheckDetailsViewModel.ExpandedCheckStore;
				const { WantsTransactionNotifications, ReferenceFieldValues, YourId } = action.response;

				check.WantsTransactionNotifications = WantsTransactionNotifications;
				check.YourId = YourId;

				if (ReferenceFieldValues) {
					check.CommonPaymentFields.ReferenceFieldValues = ReferenceFieldValues;
					if (this.isCheckSplittingEnabled) {
						check.Splits[0].Fund.Value = ReferenceFieldValues.filter(x => x.Key === this.fundKey)[0].Value;
					}
				}

				this.loadingLastPaymentDetailsRequest = null;
				break;
			case 'request_error':
				this.loadingLastPaymentDetailsRequest = null;
				this.reportError(action);
				break;
		}
	}

	private reportError(action: CheckDepositDataServiceAction) {
		if (action.type !== 'request_error') {
			return;
		}

		//the validation errors are not handled by the subscribers
		//we never expect any validation errors here because of the client-side validation
		//however, if it happens, let's show a message to the user and send a raygun since it's not expected
		if (action.error.validationErrors) {
			const modal = React.createElement(ModalDialog, { title: 'Sorry, please fix the following' },
				React.createElement('ul', { className: 'list-unstyled' },
					Object.keys(action.error.validationErrors).map(x =>
						React.createElement('li', null, action.error.validationErrors[x])
					)
				)
			);
			ModalDialogCommander.showReactDialog(modal);
		} else {
			ModalDialogCommander.error(action.error.userError, '');
		}
	}
}
