import * as React from 'react';
import { TransitionGroup } from 'react-transition-group-v1';
import { getCheckDepositDataService } from '../check-deposit-data-service';
import { AllocationItemFormViewModel } from './allocation-item-form-store';
import { observer } from 'mobx-react';
import { ResponsiveBreakpoint, ResponsiveVisibility } from '../../helpers/responsive-helper';
import { RenderResponsive } from '../../components/hoc-behavior/render-responsive';
import { MemberFormBody } from '../../community-member/components/member-form-body';
import { MemberFormFields } from '../../community-member/components/member-form-fields';
import { MemberFormFooter } from '../../community-member/components/member-form-footer';
import { MemberFormHeading } from '../../community-member/components/member-form-heading';
import { AddMemberFormContainer, IAddMemberFormProps } from '../../community-member/views/add-member-form-container';
import { AllocationItemImages, AllocationItemImagesControlsSide, AllocationItemImagesControls } from './allocation-item-images';
import { AllocationItemImagesStore } from './allocation-item-images-store';
import { Models } from '../check-deposit-generated';
import CheckImageSource = Models.CheckImageSource;
import { FindElement } from '../../../Utilities/find-element';

@observer
export class AllocationAddMemberDialog extends React.Component<IAddMemberFormProps & { check: AllocationItemFormViewModel }> {
	private allocationItemImagesStore = new AllocationItemImagesStore(this.props.check, CheckImageSource.AddNewCommunityMemberDialog);

	private headerRef: HTMLElement;
	private bodyRef: HTMLElement;
	private scrollListener: EventListener;

	componentDidMount() {
		const parent = FindElement.findParentByClassName(this.bodyRef, 'modal');
		if (parent) {
			this.scrollListener = () => this.handleScroll(this.headerRef, this.bodyRef);
			parent.addEventListener('scroll', this.scrollListener);
		}
	}

	componentWillUnmount() {
		const parent = FindElement.findParentByClassName(this.bodyRef, 'modal');
		if (parent) {
			parent.removeEventListener('scroll', this.scrollListener);
		}
	}

	handleScroll(header, body) {
		let top = header.getBoundingClientRect().bottom;
		body.style.top = Math.max(-top, 0) + 'px';
	}

	render() {
		const { check: { CheckImageUrl, CheckNumber } } = this.props;
		const addMemberActionSubscriberFactory = getCheckDepositDataService().getActionSubscriberFactory('addCommunityMember');
		return (
			<AddMemberFormContainer {...this.props} addMemberActionSubscriberFactory={addMemberActionSubscriberFactory}>
				<MemberFormHeading>Add new member</MemberFormHeading>
				<MemberFormBody>
					<div ref={ref => this.headerRef = ref} className="cd-allocation-item-form-images-controls-dialog">
						<RenderResponsive visibility={ResponsiveVisibility.Visible} breakpoints={ResponsiveBreakpoint.Xs}>
							<TransitionGroup component="div">
								{this.allocationItemImagesStore.showImages && <AllocationItemImagesControlsSide store={this.allocationItemImagesStore} />}
							</TransitionGroup>
						</RenderResponsive>
						<AllocationItemImagesControls store={this.allocationItemImagesStore} classNames="text-right" />
					</div>
					<div ref={ref => this.bodyRef = ref} className="cd-allocation-item-form-sticky-image">
						<TransitionGroup component="div" className="cd-allocation-item-form-images-container cd-allocation-item-form-images-dialog-container">
							{this.allocationItemImagesStore.showImages && <AllocationItemImages checkNumber={CheckNumber} checkImageUrl={CheckImageUrl} store={this.allocationItemImagesStore} />}
						</TransitionGroup>
					</div>
					<MemberFormFields organizationalGivingFeatureEnabled={this.props.enableFeatureOrganizationalGiving} />
				</MemberFormBody>
				<MemberFormFooter />
			</AddMemberFormContainer>
		);
	}
}
