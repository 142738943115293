import * as React from 'react';
import { observer } from 'mobx-react';
import { Button } from '../../components/button';
import { ModalDialog, IModalDialogButton } from '../../components/modal-dialog';
import { IntegrationServiceClient } from './integration-service-client';

export interface IDisconnectIntegrationModalDialogProps {
	store: IntegrationServiceClient;
	proceedDisablingIntegration: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

@observer
export class DisconnectIntegrationModalDialog extends React.Component<IDisconnectIntegrationModalDialogProps, {}> {
	render() {
		const deleteButton = <Button key="disconnect_button"
			className="btn btn-primary"
			onClick={this.props.proceedDisablingIntegration}
			isProcessingRequest={this.props.store.isDisabling}
			disabled={this.props.store.isProcessingRequest}>Disable <span className="hidden-xs">integration</span></Button>;

		const buttons: (IModalDialogButton | JSX.Element)[] = [
			{
				label: 'Cancel',
				dismiss: true,
				isPrimary: false,
				hideOnProgress: true
			},
			deleteButton];

		return <ModalDialog title="" buttons={buttons} inProgress={this.props.store.isDisabling}>
			<p>Are you sure?</p>
			{this.props.store.integrationHasFunds ? (
				<p>Disabling your integration means your contributions will no longer be synchronized
				with {this.props.store.integrationName}.</p>
			): (
				<p>Disabling your integration means synchronization with {this.props.store.integrationName} will no longer occur.</p>
				)}
			<p>All your integration settings will be retained.</p>
		</ModalDialog>;
	}
}
