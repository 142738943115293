import * as React from 'react';
import { FormControlLabelled, FormControlType, IFormControlLabelledProps } from '../../components/form-controls/form-control-labelled';
import { observer } from 'mobx-react';
import { RegisterScannerDialogViewModel } from './register-scanner-dialog-viewmodel';
import { ValidationSummary } from '../../components/form-controls/validation-summary';
import { ValidatableForm } from '../../components/form-controls/validatable-form';
import { ModelMetadata } from '../check-deposit-generated';

export class IRegisterScannerProps {
	store: RegisterScannerDialogViewModel;
}

@observer
export class RegisterScannerComponent extends React.Component<IRegisterScannerProps> {
	modelNameUpdated = (event: React.FormEvent<HTMLSelectElement>) => {
		var modelValue = event.currentTarget.value;

		this.props.store.updateModel(modelValue);
	}

	serialNumberUpdated = (event: React.FormEvent<HTMLInputElement>) => {
		var serialNumberValue = event.currentTarget.value;

		this.props.store.updateSerialNumber(serialNumberValue);
	}

	onSubmit = () => {
		this.props.store.onSubmitted();
	}

	onClose = () => {
		if (!this.props.store.isProcessingRequest) {
			this.props.store.closeDialog();
		}
	}

	get metadata() {
		return ModelMetadata.RegisterScannerViewModel;
	}

	render() {
		const { availableModels, isProcessingRequest, invalidFields, request } = this.props.store;

		const errorMessages = Object.keys(invalidFields).map(k => invalidFields[k]);

		const modelProps: IFormControlLabelledProps = {
			label: 'Make and model',

			formControlProps: {
				formControlType: FormControlType.DropDown,
				name: 'Model',
				value: request.Model,
				onChangeHandler: this.modelNameUpdated,
				Options: availableModels.map(o => ({ Label: o, Value: o })),
				validationRules: this.metadata.Model.validationRules
			}
		};

		const serialNumberProps: IFormControlLabelledProps = {
			label: 'Serial number',
			formControlProps: {
				formControlType: FormControlType.Text,
				name: 'SerialNumber',
				value: request.SerialNumber,
				onChangeHandler: this.serialNumberUpdated,
				type: '',
				ignorePanLikeValidation: true,
				validationRules: this.metadata.SerialNumber.validationRules
			}
		};

		if (invalidFields) {
			if (invalidFields[modelProps.formControlProps.name]) {
				modelProps.isInvalid = true;
			}

			if (invalidFields[serialNumberProps.formControlProps.name]) {
				serialNumberProps.isInvalid = true;
			}
		}

		return (
			<ValidatableForm onSubmit={this.onSubmit} className="panel panel-default">
				<button type="button" className="close" aria-label="Close" onClick={this.onClose} />

				<div className="panel-heading">
					New check scanner details
				</div>
				<div className="panel-body">
					<ValidationSummary validationErrors={errorMessages} />
					<div className="row">
						<div className="col-md-12">
							<p>Once added, it usually takes 3 business days to register your scanner.
								<br />You will be able to scan checks once registration is complete.</p>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<FormControlLabelled {...modelProps} />
						</div>
						<div className="col-md-6">
							<FormControlLabelled {...serialNumberProps} />
						</div>
					</div>
				</div>
				<div className="panel-footer panel-footer-btn-group">
					<button type="button" key="0" className="btn btn-cancel" disabled={isProcessingRequest} onClick={this.onClose}>Cancel</button>
					<button type="submit" key="1" className="btn btn-default" disabled={isProcessingRequest}>
						Register{isProcessingRequest ? '...' : ''}
					</button>
				</div>
			</ValidatableForm>
		);
	}
}
