import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { ArrayHelper } from '../../helpers/arrayhelper';
import { getBoundingRect } from '../../helpers/getboundingrect';
import { ScrollingTransition, IScrollingBounds } from '../utils/scrolling-transition';

export interface ISortableListProps extends React.HTMLAttributes<any> {
	uniqueKey: string | number;
	sortingOrder: Array<string>;
	draggableItems: Array<string>;
	sortingOrderChange?: (sortingOrder: Array<string>) => void;
	dragStateChange?: (isDragging: boolean) => void;
}

export interface ISortableListState {
	draggable?: IDraggableData;
}

export interface IDraggableData {
	key: string;
	index: number;
}

export interface IDragEventData {
	data: IDraggableData;
	event: DragEvent;
	item: DraggableItem;
}

export interface IDraggableProps {
	dragStart: (draggable: DraggableItem, dragOffsetY: number) => void;
	dragEnd: () => void;
	drag: (positionY: number, clientY: number) => void;
	data: IDraggableData;
	isDragged: boolean;
	isImmovable: boolean;
}

export type ReactChild = (React.ReactElement<any> | number | string);

//temporary for tweaking animation time
function getAnimationDuration(): number {
	return window['manageFundsAnimationDuration'] = window['manageFundsAnimationDuration'] || 300;
}

//temporary for hold to reorder time
function getTouchDragAndDropDelay(): number {
	return window['manageFundsTouchDragAndDropDelay'] = window['manageFundsTouchDragAndDropDelay'] || 300;
}

export class SortableList extends React.Component<ISortableListProps, ISortableListState> {

	private itemData: { [key: string]: ItemData } = {};
	private temporarySortingOrder = [];

	private draggableAreaHeight = 0;
	private dragStartOffset = 0;
	private ul: HTMLElement = null;
	private ulPageOffset = 0;
	private scrollingBounds: IScrollingBounds;
	private scrollingTransition: ScrollingTransition;

	constructor(props: ISortableListProps) {
		super(props);

		this.state = { draggable: null };
		this.dragStart = this.dragStart.bind(this);
		this.dragEnd = this.dragEnd.bind(this);
		this.drag = this.drag.bind(this);
	}

	componentDidUpdate(prevProps: ISortableListProps, prevState: ISortableListState) {
		this.cacheItemPositionsAndSortingOrder();

		if (!ArrayHelper.equals(prevProps.sortingOrder, this.props.sortingOrder)) {
			this.props.sortingOrder.forEach(child => {
				var itemData = this.itemData[child];
				const domNode = itemData.element;

				var currentTop = itemData.originalBounds.top;

				if (itemData.previousTop !== currentTop) {
					const currentOffset = this.relativeTopForElement(domNode) - currentTop;

					itemData.transform(itemData.previousTop - currentTop + currentOffset);
					itemData.animate(0);
				}
			});
		}
	}

	componentDidMount() {
		this.cacheItemPositionsAndSortingOrder();
	}

	renderChild(child: ReactChild, idx: number) {
		const key = this.getChildKey(child);
		const data = this.itemData[key] = this.itemData[key] || new ItemData();
		return (
			<DraggableItem {...this.createDraggableProps(key, idx) }
						key={key}
						ref={ref => data.element = ReactDOM.findDOMNode(ref) as HTMLElement } >
				{ child }
			</DraggableItem>
		);
	}

	dragStart(draggable: DraggableItem, dragOffsetY: number) {
		const itemData = this.itemData[draggable.props.data.key];
		this.draggableCalculateBounds();
		this.dragStartOffset = dragOffsetY;
		this.setState({ draggable: draggable.props.data });
		itemData.stopAnimation();

		if (this.props.dragStateChange) {
			this.props.dragStateChange(true);
		}
	}

	dragEnd() {
		//successful move, let's post that the order changed
		this.dragStartOffset = 0;
		const newSortingOrder = this.getCurrentSortingOrder();
		if (this.scrollingTransition) {
			this.scrollingTransition.stop();
		}

		if (this.state.draggable !== null) {
			const itemData = this.itemData[this.state.draggable.key];
			this.setState({ draggable: null });

			if (this.props.dragStateChange) {
				this.props.dragStateChange(false);
			}

			if (this.props.sortingOrderChange) {
				//let's check if the order changed
				if (!ArrayHelper.equals(newSortingOrder, this.props.sortingOrder)) {
					this.props.sortingOrderChange(newSortingOrder);
				} else {
					//ensure the draggable element goes to the initial position;
					itemData.animate(0);
				}
			}
		}
	}

	drag(positionY: number, clientY: number) {
		if (this.state.draggable === null) {
			return;
		}

		this.startScrollingIfNeeded(positionY, clientY);
		this.moveElementIntoPosition(positionY);
		this.updateDraggablePosition(positionY);
	}

	render() {
		return (
			<ul ref={(ref) => this.ul = ref} {...this.props}>
				{this.getSortedChildren().map((x, idx) => this.renderChild(x, idx)) }
			</ul>
		);
	}

	private cacheItemPositionsAndSortingOrder() {
		this.ulPageOffset = getBoundingRect(this.ul).top;
		var listOffsetParent = this.ul.offsetTop;

		this.props.sortingOrder.forEach(child => {
			const itemData = this.itemData[child];
			const domNode = itemData.element;
			var bounds = {
				top: domNode.offsetTop,
				bottom: domNode.offsetTop + domNode.offsetHeight
			};

			if (domNode.offsetParent !== this.ul) {
				bounds.top -= listOffsetParent;
				bounds.bottom -= listOffsetParent;
			}

			itemData.setOriginalBounds(bounds);
		});

		this.temporarySortingOrder = this.props.sortingOrder.slice();
	}

	private draggableCalculateBounds() {
		const bottomPositions = this.props.draggableItems.map(x => this.itemData[x].originalBounds.bottom);
		this.draggableAreaHeight = Math.max(...bottomPositions);
		this.scrollingBounds = this.getScrollingBounds();
	}

	private createDraggableProps(key: string, idx: number): IDraggableProps {
		return {
			data: {
				key: key,
				index: idx
			},
			dragStart: this.dragStart,
			dragEnd: this.dragEnd,
			drag: this.drag,
			isDragged: this.state.draggable !== null && this.state.draggable.key === key,
			isImmovable: this.props.draggableItems.indexOf(key) === -1
		};
	}

	private getChildKey(child: ReactChild) {
		let key: string = null;
		// ReSharper disable once TypeGuardDoesntAffectAnything
		if (typeof child !== 'number' && typeof child !== 'string') {
			key = child.key.toString();
		}
		return key;
	}

	private getCurrentSortingOrder() {
		const currentSortingOrder = this.props.sortingOrder.slice();

		for (let i = 0; i < this.props.sortingOrder.length; i++) {
			const key = this.props.sortingOrder[i];
			const currentItemPosition = this.temporarySortingOrder.indexOf(key);
			if (currentItemPosition !== -1 && i !== currentItemPosition) {
				currentSortingOrder[currentItemPosition] = key;
			}
		}
		return currentSortingOrder;
	}

	private recalculateChildrensCurrentPositions() {
		let offset = 0;

		for (const key of this.temporarySortingOrder) {
			if (this.props.draggableItems.indexOf(key) !== -1) {
				const itemData = this.itemData[key];
				const originalPosition = itemData.originalBounds;
				const updatedPosition = itemData.currentBounds = {
					top: offset,
					bottom: offset + itemData.height
				};

				if (key !== this.state.draggable.key) {
					itemData.animate(updatedPosition.top - originalPosition.top);
				}

				offset += itemData.height;
			}
		}
	}

	private relativeTopForElement(element: Element) {
		return getBoundingRect(element).top - this.ulPageOffset;
	}

	private moveElementIntoPosition(pageY: number) {
		const positionYRelativeToParent = this.getPositionRelativeToParent(pageY);

		this.changeItemsOrder(positionYRelativeToParent);
	}

	private updateDraggablePosition(pageY: number) {
		const itemData = this.itemData[this.state.draggable.key];
		const positionYRelativeToParent = this.getPositionRelativeToParent(pageY);

		const dY = positionYRelativeToParent - itemData.originalBounds.top - this.dragStartOffset;

		itemData.transform(this.getAllowedDeltaY(dY, itemData), 1);
	}

	private getPositionRelativeToParent(pageY: number) {
		return this.getAllowedPageY(pageY) - this.ulPageOffset;
	}

	private changeItemsOrder(positionYRelativeToParent: number) {
		const draggableKey = this.state.draggable.key;

		for (let draggedOverKey of this.props.draggableItems) {
			if (draggedOverKey !== draggableKey) {

				const draggedOverPosition = this.itemData[draggedOverKey].getCurrentBounds();

				//let's find if positionYRelativeToParent intersects any items
				if (positionYRelativeToParent >= draggedOverPosition.top && positionYRelativeToParent <= draggedOverPosition.bottom) {
					//swap items
					const currentIndex = this.temporarySortingOrder.indexOf(draggableKey);
					this.temporarySortingOrder.splice(currentIndex, 1);

					let insertAt = this.temporarySortingOrder.indexOf(draggedOverKey);

					if (currentIndex === insertAt) {
						insertAt += 1;
					}

					this.temporarySortingOrder.splice(insertAt, 0, draggableKey);

					this.recalculateChildrensCurrentPositions();
					break;
				}
			}
		}
	}

	private startScrollingIfNeeded(pageY: number, clientY: number) {
		this.scrollingTransition = this.scrollingTransition || new ScrollingTransition(x => this.scrollingPositionUpdated(x));
		this.scrollingTransition.startScrollingIfNeeded(clientY, this.scrollingBounds);
	}

	private scrollingPositionUpdated(pageY: number) {
		//if the page is being scrolled - shouldn't trigger item
		//reordering animation - just updates the draggable position
		//if pageY move outside of the allowed bounds - the item is stopped moving and we need to trigger item reordering animation
		const allowedPageY = this.getAllowedPageY(pageY);
		if (allowedPageY - pageY === 0) {
			this.updateDraggablePosition(pageY);
		} else {
			this.moveElementIntoPosition(pageY);
		}
	}

	private getAllowedPageY(pageY: number): number {
		if (pageY < this.ulPageOffset) {
			return this.ulPageOffset;
		} else if (pageY > this.ulPageOffset + this.draggableAreaHeight) {
			return this.ulPageOffset + this.draggableAreaHeight;
		}
		return pageY;
	}

	private getAllowedDeltaY(desiredDeltaY: number, itemData: ItemData): number {
		const itemPosition = itemData.originalBounds;

		const currentItemTop = desiredDeltaY + itemPosition.top;
		const currentItemBottom = desiredDeltaY + itemPosition.bottom;

		if (currentItemTop < 0) {
			desiredDeltaY = 0 - itemPosition.top;
		} else if (currentItemBottom > this.draggableAreaHeight) {
			desiredDeltaY = this.draggableAreaHeight - itemPosition.bottom;
		}
		return desiredDeltaY;
	}

	private getScrollingBounds(): IScrollingBounds {
		const viewportHeight = window.innerHeight;
		return {
			minY: Math.floor(this.ulPageOffset - viewportHeight / 3),
			maxY: Math.ceil(this.ulPageOffset + this.draggableAreaHeight + viewportHeight / 3 - viewportHeight),
			viewportHeight: viewportHeight
		};
	}

	private getSortedChildren() {
		var sorted: Array<ReactChild> = [];

		React.Children.forEach(this.props.children, (x: ReactChild) => {
			sorted[this.props.sortingOrder.indexOf(this.getChildKey(x))] = x;
		});

		return sorted;
	}
}

function easeInOutQuad(t: number, b: number, c: number, d: number) {
	if ((t /= d / 2) < 1) {
		return c / 2 * t * t + b;
	}
	return -c / 2 * ((--t) * (t - 2) - 1) + b;
}

interface IVerticalBounds {
	top: number;
	bottom: number;
}

class ItemData {
	previousTop = 0;
	originalBounds: IVerticalBounds = null;
	currentBounds: IVerticalBounds = null;

	height: number;
	currentAnimationFrameRequest: number = null;
	element: HTMLElement = null;
	currentOffsetY = 0;
	currentOffsetZ = 0;

	setOriginalBounds(bounds: IVerticalBounds) {
		this.previousTop = (this.originalBounds && this.originalBounds.top) || 0;
		this.originalBounds = bounds;
		this.height = bounds.bottom - bounds.top;
		this.currentBounds = null;
	}

	getCurrentBounds(): IVerticalBounds {
		return this.currentBounds || this.originalBounds;
	}

	transform(y: number, z: number = this.currentOffsetZ) {
		if (isNaN(y)) {
			y = 0;
		}
		this.currentOffsetY = y;
		this.currentOffsetZ = z;

		this.setTransform(y, z);
	}

	stopAnimation() {
		if (this.currentAnimationFrameRequest) {
			cancelAnimationFrame(this.currentAnimationFrameRequest);
			this.currentAnimationFrameRequest = null;
		}
	}

	animate(to: number) {
		this.stopAnimation();
		const from = this.currentOffsetY;

		this.currentAnimationFrameRequest = requestAnimationFrame(() => {
			var startTime = Date.now();
			var loop = () => {
				var dT = Date.now() - startTime;

				if (dT <= getAnimationDuration()) {
					const currentResult = easeInOutQuad(dT, from, to - from, getAnimationDuration());
					this.currentOffsetY = currentResult;

					this.setTransform(currentResult, this.currentOffsetZ);
					this.currentAnimationFrameRequest = requestAnimationFrame(loop);
				} else {
					this.currentOffsetZ = 0;
					this.setTransform(to, 0);
					this.currentAnimationFrameRequest = null;
				}
			};
			this.currentAnimationFrameRequest = requestAnimationFrame(loop);
		});
	}

	private setTransform(y: number, z: number = 0) {
		if (y === 0 && z === 0) {
			this.element.style.transform = '';
		} else {
			this.element.style.transform = `translate3d(0,${y}px,${z}px)`;
		}
	}
}

export class DraggableItem extends React.Component<IDraggableProps, any> {

	private dragHandle: HTMLElement = null;
	private dragStartTimeout: number;
	private isDragging: boolean;

	constructor(props) {
		super(props);

		this.touchStart = this.touchStart.bind(this);
		this.touchMove = this.touchMove.bind(this);
		this.touchEnd = this.touchEnd.bind(this);
		this.mouseDown = this.mouseDown.bind(this);
		this.mouseMove = this.mouseMove.bind(this);
		this.mouseUp = this.mouseUp.bind(this);
	}

	mouseDown(event: React.MouseEvent<any>) {
		const mouseEvent = event.nativeEvent as MouseEvent;

		if (mouseEvent.which !== 1 || !this.isEventTargetDraggable(mouseEvent)) {
			return;
		}

		this.bindGlobalDragOverEvent();
		this.dragStart(event.currentTarget as HTMLElement, event.pageY);
	}

	mouseUp(event: MouseEvent) {
		this.unbindGlobalDragOverEvent();
		this.props.dragEnd();
	}

	touchStart(event: React.TouchEvent<any>) {
		if (!this.isEventTargetDraggable(event.nativeEvent)) {
			return;
		}

		if (event.touches.length === 1) {
			this.delayDragStartOnTouchDevices(event.currentTarget as HTMLElement, event.touches[0].pageX, event.touches[0].pageY);
		}
	}

	mouseMove(event: MouseEvent) {
		event.preventDefault();
		this.props.drag(event.pageY, event.clientY);
	}

	touchMove(event: React.TouchEvent<any>) {
		if (this.isDragging) {
			event.preventDefault();
		}

		if (event.touches.length === 1) {
			this.props.drag(event.touches[0].pageY, event.touches[0].clientY);
		}
	}

	touchEnd(event: React.TouchEvent<any>) {
		this.cancelDragStartTimeout();
		this.props.dragEnd();
	}

	dragStart(currentTarget: HTMLElement, pageY: number) {
		this.props.dragStart(this, this.getDragOffset(currentTarget as Element, pageY));
	}

	componentDidMount() {
		const dragHandle = (ReactDOM.findDOMNode(this) as Element).querySelector('[data-drag-handle]');
		if (dragHandle && dragHandle instanceof HTMLElement) {
			this.dragHandle = dragHandle;
		}
	}

	render() {
		if (this.props.isImmovable) {
			return (
				<li>
					{this.props.children}
				</li>);
		} else {
			return (
				<li
					onMouseDown= { this.mouseDown }
					onTouchStart = { this.touchStart }
					onTouchMove = { this.touchMove }
					onTouchEnd = { this.touchEnd }
					//prevents contact menu from being opened on tap and hold - does not prevent opening on mouse right click
					onContextMenu= { e => { e.preventDefault(); e.stopPropagation(); } }
					className={ this.props.isDragged ? 'is-dragged' : ''}
					>
					{this.props.children}
				</li>);
		}
	}

	private delayDragStartOnTouchDevices(currentTarget: HTMLElement, pageX: number, pageY: number) {
		var scrollingPositionStart = { x: document.body.scrollLeft, y: document.body.scrollTop };

		this.dragStartTimeout = setTimeout(() => {
			//if page has scrolled - cancel drag start
			if (scrollingPositionStart.x !== document.body.scrollLeft || scrollingPositionStart.y !== document.body.scrollTop) {
				return;
			}
			this.dragStartTimeout = null;
			this.isDragging = true;
			this.dragStart(currentTarget, pageY);
		}, getTouchDragAndDropDelay());
	}

	private cancelDragStartTimeout() {
		if (this.dragStartTimeout) {
			clearTimeout(this.dragStartTimeout);
		}
		this.dragStartTimeout = null;
		this.isDragging = false;
	}

	private bindGlobalDragOverEvent() {
		document.addEventListener('mouseup', this.mouseUp);
		document.addEventListener('mousemove', this.mouseMove);
	}

	private unbindGlobalDragOverEvent() {
		document.removeEventListener('mouseup', this.mouseUp);
		document.removeEventListener('mousemove', this.mouseMove);
	}

	private getDragOffset(currentTarget: Element, dragStartY: number) {
		return dragStartY - getBoundingRect(currentTarget).top;
	}

	private isEventTargetDraggable(event: Event) {
		return this.dragHandle === null || this.dragHandle.contains(event.target as Node);
	}
}
