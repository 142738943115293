import * as React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { BrandingSettingsMainViewModel } from '../../branding-settings-main-view-model';
import { LoadingBox } from '../../../components/loading-box';
import * as styles from './real-time-preview.less';
import { classNames } from '../../../../Shared/utils/classnames';
import { BrandingPackageSnapshot } from '../../editor-form/branding-settings-form-view-model';
import { SvgWrapper } from '../../../components/svg-wrapper';

interface IRealTimePreviewProps {
	vm: BrandingSettingsMainViewModel;
	responsiveHelperCallback?: (contentWindow: Window) => void;
	responsiveReactionDisposer?: () => void;
}

@observer
export class RealTimePreview extends React.Component<IRealTimePreviewProps> {

	private brandingPackageSnapshot: BrandingPackageSnapshot = null;
	private prevSelectedListingId: number;

	render() {
		const { vm, responsiveHelperCallback, responsiveReactionDisposer } = this.props;
		const {
			model,
			formViewModel: {
				givingTitle,
				primaryColor,
				boxedSecondaryColor,
				enhancedLogo,
				favicon,
				heroImage,
				backdrop,
				getInitialPackage,
				getPackageChanges,
				isCampusBrandingMerchant,
				isOrganizationBrandingMerchant
			},
			showMobilePreview,
			isLoadingListingDetails,
		} = this.props.vm;

		let data;
		const isSwitchingListing = this.prevSelectedListingId !== model.SelectedListing.ListingId;

		if (isSwitchingListing) {
			data = {
				brandingPackage: getInitialPackage(),
				merchantInfo: model.SelectedListing.MerchantInfo
			};
			this.prevSelectedListingId = model.SelectedListing.ListingId;
		} else {
			data = { brandingPackage: getPackageChanges(this.brandingPackageSnapshot) };
		}

		this.brandingPackageSnapshot = {
			givingTitle,
			primaryColor,
			boxedSecondaryColor,
			enhancedLogoImageKey: enhancedLogo.uploadState === 'success' ? enhancedLogo.value : '',
			faviconImageKey: favicon.uploadState === 'success' ? favicon.value : '',
			heroImageKey: heroImage.uploadState === 'success' ? heroImage.value : '',
			backdropImageKey: backdrop.uploadState === 'success' ? backdrop.value : '',
			isOrganizationBrandingMerchant,
			isCampusBrandingMerchant
		};

		return (
			<div className={styles.iframeContainer}>
				<Frame
					title="real time preview"
					url={model.PreviewUrl}
					dataToSync={vm.model.SupportsBackdropImages ? JSON.stringify({...data, supportsBackdropImages: vm.model.SupportsBackdropImages, showMobilePreview}) : JSON.stringify(data)}
					vm={vm}
					responsiveHelperCallback={responsiveHelperCallback}
					responsiveReactionDisposer={responsiveReactionDisposer}
					isLoadingData={isLoadingListingDetails}
				/>
			</div>
		);
	}
}

interface IFrameProps extends IRealTimePreviewProps {
	dataToSync: string;
	url: string;
	title: string;
	isLoadingData: boolean;
}

@observer
class Frame extends React.Component<IFrameProps> {
	iframe: HTMLIFrameElement;

	@observable
	isIFrameLoaded = false;

	private lastIframeScrollHeight: number | undefined;
	private scrollHeightChangeWatcher: number | undefined;

	componentDidMount() {
		this.sync(this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps: any) {
		this.sync(nextProps);
	}

	componentWillUnmount() {
		const { responsiveReactionDisposer } = this.props;
		if (responsiveReactionDisposer) {
			responsiveReactionDisposer();
		}
		cancelAnimationFrame(this.scrollHeightChangeWatcher);
	}

	render() {
		const { url, isLoadingData, vm: { showMobilePreview: mobileView }, title } = this.props;
		const isLoading = !this.isIFrameLoaded || isLoadingData;

		return (
			<div className={mobileView ? styles.mobileFrame : styles.iframeDesktop}>
				{isLoading && <LoadingBox text="" />}
				{mobileView && <SvgWrapper svg="iphone8" className={styles.mobileFramePhoneBackground} />}
				<div className={mobileView ? styles.iframeMobile : ''}>
					<iframe title={title}
						tabIndex={-1}
						className={classNames(styles.iframe, isLoading && styles.loading)}
						scrolling="no"
						frameBorder="no"
						src={url}
						ref={this.ref}
						onLoad={this.handleLoad} />
				</div>
			</div>
		);
	}

	ref = (iframe: HTMLIFrameElement) => {
		this.iframe = iframe;
	}

	@action
	private handleLoad = () => {
		const { responsiveHelperCallback } = this.props;
		if (responsiveHelperCallback) {
			responsiveHelperCallback(this.iframe.contentWindow);
		}

		this.isIFrameLoaded = true;
		this.sync(this.props);
		this.watchScrollHeightChange();
	}

	private sync = (props: any) => {
		if (this.isIFrameLoaded) {
			this.iframe.contentWindow.postMessage(props.dataToSync, window.location.origin);
		}
	}

	private watchScrollHeightChange = () => {
		cancelAnimationFrame(this.scrollHeightChangeWatcher);
		if (!this.iframe) {
			return;
		}

		const container = this.iframe.contentWindow.document.body;
		if (!container) {
			return;
		}

		if (this.lastIframeScrollHeight !== container.scrollHeight) {
			// do something
			this.iframe.style.minHeight = `${container.scrollHeight}px`;
		}

		this.lastIframeScrollHeight = container.scrollHeight;
		this.scrollHeightChangeWatcher = requestAnimationFrame(this.watchScrollHeightChange);
	}
}
