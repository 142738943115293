import { NodeListHelper } from '../helpers/nodelisthelper';
import { LocalStorageHelper } from '../helpers/localstoragehelper';

export class TipBox {
	private element: HTMLElement;
	private tipBoxKey: string;

	static bindAll() {
		NodeListHelper.toArray<HTMLElement>(document.querySelectorAll('[data-tipbox]')).map(x => new TipBox(x));
	}

	constructor(element: HTMLElement) {
		this.element = element;
		this.tipBoxKey = element.getAttribute('data-tipbox-key');

		this.dismiss = this.dismiss.bind(this);
		this.show = this.show.bind(this);

		this.bind();
	}

	dismiss() {
		this.element.classList.add('dismissed');
		this.persistTipboxState(true);
	}

	show() {
		this.element.classList.remove('dismissed');
		this.persistTipboxState(false);
	}

	private persistTipboxState(isDismissed: boolean) {
		const storage = LocalStorageHelper.getLocalStorage();
		if (this.tipBoxKey) {
			if (isDismissed) {
				storage[this.tipBoxKey] = isDismissed;
			} else {
				storage.removeItem(this.tipBoxKey);
			}
		}
	}

	private bind() {
		NodeListHelper.toArray<HTMLElement>(this.element.querySelectorAll('[data-dismiss="tipbox"]')).forEach(x => {
			x.addEventListener('click', this.dismiss);
		});

		NodeListHelper.toArray<HTMLElement>(this.element.querySelectorAll('[data-show="tipbox"]')).forEach(x => {
			x.addEventListener('click', this.show);
		});
	}
}
