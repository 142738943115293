import * as React from 'react';

export class ImportStepDescription extends React.Component<{ title: string, description: string, headerButton?: React.ReactElement<any>, helpLink?: React.ReactElement<any>}> {
	render() {
		const { title, description, headerButton, helpLink } = this.props;
		return (
			<div className="transaction-import-title">
				<h1>
					{title}
					{headerButton && <span className="header-buttons">{headerButton}</span>}
				</h1>
				<p className="transaction-import-step-description">{description}
					{helpLink && <span>{helpLink}</span>}
				</p>
				{this.props.children}
			</div>
		);
	}
}
