import * as React from 'react';
import { FormControlLabelled, FormControlType } from '../../components/form-controls/form-control-labelled';
import { getUniqueElementId } from '../../components/form-controls/form-control-utils';
import { PaymentLabel } from '../../virtual-terminal/virtual-terminal-generated';
import { ModelMetadata, Models } from '../community-member-generated';
import * as styles from './type-field.less';

interface ITypeFieldProps {
	selectedType: number;
	handleTypeChange: (type: string) => void;
	acceptanceTestTargetId?: string;
	disabled?: boolean;
	paymentLabel?: PaymentLabel;
}

export const TypeField: React.StatelessComponent<ITypeFieldProps> = (props) => {
	const { selectedType, handleTypeChange, acceptanceTestTargetId, disabled, paymentLabel  } = props;
	const { propertyName, validationRules } = ModelMetadata.EditCommunityMemberRequestModel.CommunityMemberType;
	const options = [{
		Label: 'Person',
		Value: `${Models.CommunityMemberType.Individual}`,
	}, {
		Label: 'Organization',
		Value: `${Models.CommunityMemberType.Organization}`,
	}];

	return (
		<FormControlLabelled label="Type"
			cssClassNames="col-xs-12"
			formControlProps={{
				formControlType: FormControlType.Radio,
				name: `${propertyName}_${selectedType}`,
				uniqueSuffix: getUniqueElementId(),
				value: selectedType.toString(),
				onChangeHandler: handleTypeChange,
				Options: options,
				acceptanceTestTargetId,
				validationRules,
				disabled,
				radioItemClassName: styles.communityMemberTypeOption,
			}}
		/>
	);
};
