

import { DataServiceAction, DataServiceActionSubscriberFactory, IApiConfig, ActionKey, ActionRequest } from '../data-service';
import { cps, CpsEffect } from 'redux-saga/effects';
import { ReduxSagaCpsCallback } from '../saga-utils';

function dataServiceCallImplementation<TApiConfig extends IApiConfig, TActionKey extends ActionKey<TApiConfig>>
	(subscriberFactory: DataServiceActionSubscriberFactory<TApiConfig, TActionKey>, request: ActionRequest<TApiConfig, TActionKey>, cb: ReduxSagaCpsCallback) {
	let subscriber = subscriberFactory((action: DataServiceAction<any, any>) => {
		if (action.type === 'request_success') {
			subscriber.destroy();
			cb(null, action.response);
		} else if (action.type === 'request_error') {
			subscriber.destroy();
			cb(action.error, null);
		}
	});

	cb.cancel = () => { subscriber.destroy(); };

	subscriber.initRequest(request);
}

export function dataServiceCall<TApiConfig extends IApiConfig, TActionKey extends ActionKey<TApiConfig>>
	(subscriberFactory: DataServiceActionSubscriberFactory<TApiConfig, TActionKey>, request: ActionRequest<TApiConfig, TActionKey>): CpsEffect {
	return cps(dataServiceCallImplementation, subscriberFactory, request);
}
