import * as React from 'react';
import { classNames, DragDropTypes } from './_common';
import {defineView} from './_common';
import * as ViewModels from '../view-models/index';
import * as ReactDnD from 'react-dnd';
import { SvgWrapper } from '../../components/svg-wrapper';
import PersonalDetails from '../view-models/personal-details';

const DetailsRow = (props: { id: string, details: PersonalDetails, verified: boolean }) => {
	let iconName;
	if (!props.details.IsOrganization) {
		iconName = `member-${props.verified ? 'fill' : 'outline'}`;
	} else {
		iconName = 'member-organization';
	}

	return (
		<tr key={props.id}>
			<td>
				<span className="member-icon">
					<SvgWrapper svg={iconName} className="svg" />
				</span>
			</td>
			<td className="name">{props.details.FirstName}</td>
			<td className="name">{props.details.LastName}</td>
			<td>{props.details.EmailAddress}</td>
			<td>{props.details.MobileNumber}</td>
		</tr>
	);
};

const Account = defineView<ViewModels.Account>(vm => {
	return <table><tbody><DetailsRow id={`${vm.id}`} details={vm.details} verified={vm.verified}/></tbody></table>;
});
const Member = defineView<ViewModels.Member>(vm => vm.details.IsOrganization
	? <table><tbody><DetailsRow id={`${vm.id}`} details={vm.details} verified={false} /></tbody></table>
	: vm.accounts.length === 1
		? <Account vm={vm.accounts[0]}/>
		: <table>
		<tbody>
			{vm.accounts.map(x =>
				<DetailsRow key={x.id} id={`${x.id}`} details={x.details} verified={x.verified} />
			)}
		</tbody>
	</table>
);

export interface DragLayerProps {
	item?: any;
	itemType?: string;
	currentOffset?: { x: number, y: number };
	isDragging?: boolean;
}

@ReactDnD.DragLayer(monitor => ({
	item: monitor.getItem(),
	itemType: monitor.getItemType(),
	currentOffset: monitor.getClientOffset(),
	isDragging: monitor.isDragging()
}))

export default class CustomDragLayer extends React.Component<DragLayerProps, any> {
	render(): any {
		const { item } = this.props;
		const draggable = item && item.vm && !item.vm.details.IsOrganization;

		function renderItem(props) {
			const {itemType, item} = props;
			switch (itemType) {
				case DragDropTypes.account:
					return <Account vm={item.vm}/>;
				case DragDropTypes.member:
					return <Member vm={item.vm}/>;
			}
			return <span>?</span>;
		}

		const {currentOffset} = this.props;
		const {x, y} = currentOffset || {x: 0, y: 0};
		const transform = `translate(${x + 15}px, ${y}px)`;

		const styles = {
			transform: transform,
			display: currentOffset ? 'inline-block' : 'none',
		};
		return <div className={classNames('draglayer', { 'non-draggable': !draggable })} style={styles}>{renderItem(this.props)}</div>;
	}
}
