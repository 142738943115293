import { action, observable } from 'mobx';
import { PaymentLabel } from '../../giving-statements-generated';
import { GivingStatementsViewModel } from '../../giving-statements-view-model';

export class PreviewPageViewModel {
	@observable percentComplete: number = 0;
	@observable iframeRef: HTMLIFrameElement;

	constructor(
		public mainViewModel: GivingStatementsViewModel,
		public paymentLabel: PaymentLabel
	) {}

	@action.bound
	setPercentComplete (percentComplete: number) {
		this.percentComplete = percentComplete;
	}

	@action.bound
	setIframeRef (iframeRef: HTMLIFrameElement) {
		this.iframeRef = iframeRef;
	}
}
