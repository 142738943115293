import * as React from 'react';
import { FormControlSelect, IFormControlSelectOptionProps } from './form-control-select';
import { IFormControlCommonProps } from './form-control-common-props';

export const timePickerDefaultTime: IFormControlSelectOptionProps = { Label: '12:00pm', Value: '12:00' };

export interface IFormControlTimePickerProps extends IFormControlCommonProps {
	onChangeHandler: (value: string) => void;
	value: string;
	hideEmptyOption?: boolean;
	disabled?: boolean;
}

export class FormControlTimePicker extends React.Component<IFormControlTimePickerProps> {
	render() {
		const { name, value, placeholder, uniqueSuffix, hideEmptyOption, disabled, acceptanceTestTargetId } = this.props;

		return <FormControlSelect
			value={value}
			Options={eventTimeOptions}
			onChangeHandler={this.onChangeHandler}
			name={name}
			placeholder={placeholder}
			uniqueSuffix={uniqueSuffix}
			hideEmptyOption={hideEmptyOption}
			disabled={disabled}
			acceptanceTestTargetId={acceptanceTestTargetId}
		/>;
	}

	onChangeHandler = (event: React.FormEvent<HTMLSelectElement>) => {
		this.props.onChangeHandler(event.currentTarget.value);
	}
}

const eventTimeOptions: IFormControlSelectOptionProps[] = [
	{ Label: '12:00am', Value: '00:00' },
	{ Label: '12:30am', Value: '00:30' },
	{ Label: '1:00am', Value: '01:00' },
	{ Label: '1:30am', Value: '01:30' },
	{ Label: '2:00am', Value: '02:00' },
	{ Label: '2:30am', Value: '02:30' },
	{ Label: '3:00am', Value: '03:00' },
	{ Label: '3:30am', Value: '03:30' },
	{ Label: '4:00am', Value: '04:00' },
	{ Label: '4:30am', Value: '04:30' },
	{ Label: '5:00am', Value: '05:00' },
	{ Label: '5:30am', Value: '05:30' },
	{ Label: '6:00am', Value: '06:00' },
	{ Label: '6:30am', Value: '06:30' },
	{ Label: '7:00am', Value: '07:00' },
	{ Label: '7:30am', Value: '07:30' },
	{ Label: '8:00am', Value: '08:00' },
	{ Label: '8:30am', Value: '08:30' },
	{ Label: '9:00am', Value: '09:00' },
	{ Label: '9:30am', Value: '09:30' },
	{ Label: '10:00am', Value: '10:00' },
	{ Label: '10:30am', Value: '10:30' },
	{ Label: '11:00am', Value: '11:00' },
	{ Label: '11:30am', Value: '11:30' },
	timePickerDefaultTime,
	{ Label: '12:30pm', Value: '12:30' },
	{ Label: '1:00pm', Value: '13:00' },
	{ Label: '1:30pm', Value: '13:30' },
	{ Label: '2:00pm', Value: '14:00' },
	{ Label: '2:30pm', Value: '14:30' },
	{ Label: '3:00pm', Value: '15:00' },
	{ Label: '3:30pm', Value: '15:30' },
	{ Label: '4:00pm', Value: '16:00' },
	{ Label: '4:30pm', Value: '16:30' },
	{ Label: '5:00pm', Value: '17:00' },
	{ Label: '5:30pm', Value: '17:30' },
	{ Label: '6:00pm', Value: '18:00' },
	{ Label: '6:30pm', Value: '18:30' },
	{ Label: '7:00pm', Value: '19:00' },
	{ Label: '7:30pm', Value: '19:30' },
	{ Label: '8:00pm', Value: '20:00' },
	{ Label: '8:30pm', Value: '20:30' },
	{ Label: '9:00pm', Value: '21:00' },
	{ Label: '9:30pm', Value: '21:30' },
	{ Label: '10:00pm', Value: '22:00' },
	{ Label: '10:30pm', Value: '22:30' },
	{ Label: '11:00pm', Value: '23:00' },
	{ Label: '11:30pm', Value: '23:30' },
];
