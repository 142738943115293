import * as React from 'react';
import { observer } from 'mobx-react';
import { getUniqueElementId } from './form-control-utils';
import { ValidationMessage } from './validation-message';
import './validatable-form';
import { FormControlLabel } from './form-control-label';
import { FormControlInput, IFormControlInputProps } from './form-control-input';
import { FormControlSelect, IFormControlSelectProps } from './form-control-select';
import { FormControlTextarea, IFormControlTextareaProps } from './form-control-textarea';
import { FormControlDatePicker, IFormControlDatePickerProps } from './form-control-date-picker';
import { FormControlAmount, IFormControlAmountProps } from './form-control-amount';
import { FormControlRadio, IFormControlRadioProps } from './form-control-radio';
import { FormControlMultiSelect, IFormControlMultiSelectProps } from './form-control-multi-select';
import { FormControlTimePicker, IFormControlTimePickerProps } from './form-control-time-picker';
import {
	FormControlImageInput,
	IFormControlImageInputProps,
} from './form-control-image-input/form-control-image-input';
import { FormControlColor, IFormControlColorProps } from './form-control-color/form-control-color';
import { TooltipPlacement } from '../tooltip/tooltip';
import { FormControlClipboard, IFormControlClipboardProps } from './form-control-clipboard/form-control-clipboard';

export enum FormControlType {
	Text,
	Tel,
	Number,
	DropDown,
	Textarea,
	DatePicker,
	Amount,
	Radio,
	MultiSelect,
	TimePicker,
	Image,
	Color,
	Clipboard,
}

export type FormControlProps =
	| IFormControlInputLabelledProps
	| IFormControlDatePickerLabelledProps
	| IFormControlDropDownLabelledProps
	| IFormControlTextareaLabelledProps
	| IFormControlAmountLabelledProps
	| IFormControlRadioLabelledProps
	| IFormControlMultiSelectLabelledProps
	| IFormControlTimePickerLabelledProps
	| IFormControlImageLabelledProps
	| IFormControlColorLabelledProps
	| IFormControlClipboardLabelledProps;

export interface IFormControlLabelledProps {
	label: string;
	cssClassNames?: string;
	isInvalid?: boolean;
	tooltipOptions?: IFormControlLabelledTooltipProps;
	formControlProps: FormControlProps;
	acceptanceTestTargetId?: string;
	description?: string;
	hideErrors?: boolean;
	screenReaderOnlyLabel?: boolean;
}

export interface IFormControlLabelledTooltipProps {
	className?: string;
	tooltipContentClassName?: string;
	message: React.ReactNode;
	placement?: TooltipPlacement;
}

export type IFormControlInputLabelledProps = IFormControlInputProps & {
	formControlType: FormControlType.Number | FormControlType.Text | FormControlType.Tel;
};

export interface IFormControlDatePickerLabelledProps extends IFormControlDatePickerProps {
	formControlType: FormControlType.DatePicker;
}

export interface IFormControlDropDownLabelledProps extends IFormControlSelectProps {
	formControlType: FormControlType.DropDown;
}

export interface IFormControlTextareaLabelledProps extends IFormControlTextareaProps {
	formControlType: FormControlType.Textarea;
}

export interface IFormControlAmountLabelledProps extends IFormControlAmountProps {
	formControlType: FormControlType.Amount;
}

export interface IFormControlRadioLabelledProps extends IFormControlRadioProps {
	formControlType: FormControlType.Radio;
}

export interface IFormControlMultiSelectLabelledProps extends IFormControlMultiSelectProps {
	formControlType: FormControlType.MultiSelect;
}

export interface IFormControlTimePickerLabelledProps extends IFormControlTimePickerProps {
	formControlType: FormControlType.TimePicker;
}

export interface IFormControlImageLabelledProps extends IFormControlImageInputProps {
	formControlType: FormControlType.Image;
}

export interface IFormControlColorLabelledProps extends IFormControlColorProps {
	formControlType: FormControlType.Color;
}

export interface IFormControlClipboardLabelledProps extends IFormControlClipboardProps {
	formControlType: FormControlType.Clipboard;
}

@observer
export class FormControlLabelled extends React.Component<IFormControlLabelledProps, {}> {
	private uniqueElementId: string;

	UNSAFE_componentWillMount() {
		this.uniqueElementId = getUniqueElementId();
	}

	render() {
		const {
			formControlProps,
			isInvalid,
			cssClassNames,
			tooltipOptions,
			label,
			description,
			hideErrors,
			screenReaderOnlyLabel,
		} = this.props;
		const uniqueId = formControlProps.uniqueSuffix || this.uniqueElementId;
		const { name: elementName } = formControlProps;

		const modifiedFormControlProps = { ...formControlProps, uniqueSuffix: uniqueId };
		let formControl;

		switch (modifiedFormControlProps.formControlType) {
			case FormControlType.Text:
			case FormControlType.Tel:
			case FormControlType.Number:
				formControl = (
					<FormControlInput
						{...modifiedFormControlProps}
						type={FormControlType[formControlProps.formControlType].toLowerCase()}
					/>
				);
				break;
			case FormControlType.DropDown:
				formControl = (
					<FormControlSelect
						{...modifiedFormControlProps}
						placeholder={formControlProps.placeholder || `Select ${label}`}
					/>
				);
				break;
			case FormControlType.Textarea:
				const { formControlType, ...textareaProps } = modifiedFormControlProps;
				formControl = <FormControlTextarea {...textareaProps} />;
				break;
			case FormControlType.DatePicker:
				formControl = <FormControlDatePicker {...modifiedFormControlProps} />;
				break;
			case FormControlType.Amount:
				formControl = <FormControlAmount {...modifiedFormControlProps} />;
				break;
			case FormControlType.Radio:
				formControl = <FormControlRadio {...modifiedFormControlProps} ariaLabel={label} />;
				break;
			case FormControlType.MultiSelect:
				formControl = <FormControlMultiSelect {...modifiedFormControlProps} />;
				break;
			case FormControlType.TimePicker:
				formControl = <FormControlTimePicker {...modifiedFormControlProps} />;
				break;
			case FormControlType.Image:
				formControl = <FormControlImageInput {...modifiedFormControlProps} />;
				break;
			case FormControlType.Color:
				formControl = <FormControlColor {...modifiedFormControlProps} />;
				break;
			case FormControlType.Clipboard:
				formControl = <FormControlClipboard {...modifiedFormControlProps} />;
				break;
		}

		return (
			<div
				className={`form-control-labelled form-group${isInvalid ? ' has-error' : ''} ${
					cssClassNames ? cssClassNames : ''
				}`}
			>
				<FormControlLabel
					screenReaderOnly={screenReaderOnlyLabel}
					label={label}
					elementName={elementName}
					uniqueSuffix={uniqueId}
					tooltipOptions={tooltipOptions}
				/>
				{description ? <p className="form-control-description">{description}</p> : ''}
				{formControl}
				<ValidationMessage elementName={elementName} hideErrors={hideErrors} />
			</div>
		);
	}
}
