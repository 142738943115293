import {ResponseTypes} from '../funds-generated';
import ValidationError = ResponseTypes.ValidationError;
import ValidationErrorModel = ResponseTypes.ValidationErrorModel;
import RedirectRequest = ResponseTypes.RedirectRequest;
import {Poller} from '../../helpers/poller';

export function isRedirectRequest(response: any): response is RedirectRequest {
	var redirectRequest = response as RedirectRequest;
	return redirectRequest && redirectRequest.RedirectTo !== undefined;
}

export function isValidationError(error: any): error is ValidationError {
	return error && error.ErrorMessage !== undefined;
}

export function isValidationErrorModel(errorModel: any): errorModel is ValidationErrorModel {
	return errorModel && errorModel.ValidationErrors !== undefined;
}

export function makeAjaxCall(url: string, data?: any) {
	const dfd = jQuery.Deferred();
	const antiForgeryTokenElement = document.querySelector('[name="__RequestVerificationToken"]') as HTMLInputElement;

	const settings: JQueryAjaxSettings = {
		url: url,
		method: 'POST',
		success: (response) => {
			if (isValidationErrorModel(response)) {
				//bad request, check if we have model validation errors
				dfd.reject(response);
			}
			if (isValidationError(response)) {
				dfd.reject(response);
			}
			if (isRedirectRequest(response)) {
				window.location.href = response.RedirectTo;
				//not resolving intentionally to have UI in transitioning/processing state
				return;
			}
			dfd.resolve(response);
		},
		error: (error) => {
			if (error.status === 401) {
				//unauthenticated, possibly session timeout.
				//Let's reload the page to open login screen
				window.location.reload();
				return;
			}

			dfd.reject();
		}
	};

	data = data || {};

	if (data instanceof FormData) {
		data.append('__RequestVerificationToken', (antiForgeryTokenElement && antiForgeryTokenElement.value) || '');
		settings.contentType = false;
		settings.processData = false;
	} else {
		data.__RequestVerificationToken = (antiForgeryTokenElement && antiForgeryTokenElement.value) || '';
	}

	settings.data = data;

	$.ajax(settings);

	return dfd.promise();
}

export class AjaxPoller extends Poller {
	protected finishedCallback: Function;
	protected timeoutCallback: Function;
	private url: string;

	constructor(url: string, successCallback, timeoutCallback, maxAttempts: number, pollIntervalms: number) {
		super(maxAttempts, pollIntervalms);
		this.url = url;
		this.finishedCallback = successCallback;
		this.timeoutCallback = timeoutCallback;
	}

	protected pollingFunc() {
		this.validateUrl();

		makeAjaxCall(this.url)
			.fail(() => {
				this.poll();
			})
			.done((data) => {
				this.success(data);
			});
	}

	protected success(data) {
		if (data === undefined) {
			this.poll();
		} else {
			this.finishedCallback(data);
		}
	}

	protected timeoutFunc() {
		if (this.timeoutCallback !== null) {
			this.timeoutCallback();
		}
	}

	private validateUrl() {
		if (!this.url) {
			throw new TypeError('Url to poll must not be empty or null');
		}
	}
}
