import * as React from 'react';
import {action, observable, reaction} from 'mobx';
import {NodeListHelper} from '../helpers/nodelisthelper';
import {Affix, AffixPosition} from '../funds/components/affix';
import {SvgWrapper} from '../components/svg-wrapper';

export class PopupHandler {
	@observable currentPopupName: string;

	popUps: Popup[];
	popUpLinks: HTMLAnchorElement[];

	init(): PopupHandler {

		if (!document) {
			return null;
		}

		this.popUps = [];

		NodeListHelper.toArray<HTMLElement>(document.querySelectorAll('.popup'))
			.forEach(p => {
				let popup = new Popup(p);
				this.popUps[popup.Id] = popup;
			});

		this.popUpLinks = NodeListHelper.toArray<HTMLAnchorElement>(document.querySelectorAll('a[data-action="display-popup"]'));
		this.popUpLinks.forEach(p => this.bindLinkAction(p));
		return this;
	}

	bindLinkAction(popupLink: PopupLink | HTMLAnchorElement) {

		let linkElement = popupLink instanceof PopupLink ? popupLink.linkElement : popupLink;

		linkElement.onclick = (ev) => {
			ev.preventDefault();
			this.currentPopupName = linkElement.href.substring(linkElement.href.indexOf('#') + 1);
		};
	}

	@action
	close = () => {
		this.currentPopupName = null;
	}
}
export let popupHandler = new PopupHandler();

export class Popup {

	element: HTMLElement;
	closeButtons: HTMLElement[];

	constructor(popup: HTMLElement) {
		this.element = popup;
		this.closeButtons = NodeListHelper.toArray<HTMLElement>(popup.querySelectorAll('.close'));
		this.bindCloseButtons();

		reaction(() => popupHandler.currentPopupName, this.openCloseHandler);
	}

	bindCloseButtons = () => {
		this.closeButtons.forEach(p => this.bindPopupClose(p));
	}

	bindPopupClose = (closeButton: HTMLElement) => {
		closeButton.onclick = (ev) => {
			ev.preventDefault();
			popupHandler.close();
		};
	}

	popUpClose = () => {
		document.body.classList.remove('popped');
		this.element.classList.remove('visible');
	}

	popUpOpen = () => {
		document.body.classList.add('popped');
		this.element.classList.add('visible');
	}

	private openCloseHandler = (openPopup: string) => {
		if (openPopup === this.Id) {
			this.popUpOpen();
		} else if (!openPopup) {
			this.popUpClose();
		}
	}

	get Id(): string {
		return this.element.id;
	}
}

export interface IPopupLinkProps {
	popup: string;
}

export class PopupLink extends React.Component<IPopupLinkProps & React.HTMLProps<HTMLAnchorElement>, {}> {
	linkElement: HTMLAnchorElement;

	render() {
		return (
			<a {...this.props} href={`#${this.props.popup}`} ref={this.bindPopup}>
				{this.props.children}
			</a>
		);
	}

	private bindPopup = (linkElement: HTMLAnchorElement) => {
		if (linkElement) {
			this.linkElement = linkElement;
			popupHandler.bindLinkAction(this);
			popupHandler.popUpLinks.push(this.linkElement);
		} else {
			popupHandler.popUpLinks.splice(popupHandler.popUpLinks.indexOf(this.linkElement), 1);
			this.linkElement.onclick = null;
		}
	}
}

export class PopupCloseLink extends React.Component<IPopupLinkProps, {}> {
	linkElement: HTMLElement;
	popup: Popup;

	render() {
		return (
			<Affix affixPosition={AffixPosition.Top} fixedTo={this.popup.element}>
				<div {...this.props} className="close" ref={this.bindCloseLink}>
					<SvgWrapper svg="close-cross" className="svg svg-close-cross" />
				</div>
			</Affix>
		);
	}

	UNSAFE_componentWillMount() {
		this.popup = popupHandler.popUps[this.props.popup];
	}

	private bindCloseLink = (linkElement: HTMLElement) => {
		if (linkElement) {
			this.linkElement = linkElement;
			this.popup.closeButtons.push(this.linkElement);
			this.popup.bindPopupClose(this.linkElement);
		} else {
			this.popup.closeButtons.splice(this.popup.closeButtons.indexOf(this.linkElement), 1);
			this.linkElement.onclick = null;
		}
	}
}
