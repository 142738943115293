import * as React from 'react';
// TODO PP-16776: replace usages of this modules with Shared/components/svg-wrapper and remove it
export interface ISvgWrapperProps {
	svg: string;
	title?: string;
}

export class SvgWrapper extends React.Component<ISvgWrapperProps & React.SVGAttributes<any>, {}> {
	render() {
		const { svg, title } = this.props;

		return <svg {...this.props} >
			{title ? <title>{title}</title> : null}
			<use xlinkHref={`#${svg}`}></use>
		</svg>;
	}
}
