import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { TransitionGroup } from 'react-transition-group-v1';
import { ScrollableContainer, ScrollableContainerItem } from './hoc-behavior/scrollable-container';
import { DropDownMenuController } from './hoc-behavior/dropdown-menu-controller';
import { StopMouseMovePropogationWhenSamePosition } from './hoc-behavior/stop-mousemove-when-same-position';
import {
	getNewHighlightedIndex,
	SelectableListKeyboardController,
	SelectableListKeyboardNavigation,
} from './hoc-behavior/selectable-list-keyboard-controller';
import { observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';
import { isFunction } from '../utils/is-function';
import { AccessibilityCombobox } from './hoc-accessibility/accessibility-combobox';
import { AccessibilityListbox } from './hoc-accessibility/accessibility-listbox';
import { AccessibilityOption } from './hoc-accessibility/accessibility-option';
import { velocity } from '../helpers/velocity';
import { classNames } from '../../Shared/utils/classnames';
import { Labels, LabelItem } from './label/labels';

const animationStep = 25;

export interface ICustomSelectItemProps {
	value: string;
	displayLabel: string;
	labels?: LabelItem[];
}

export const CustomSelectItem: React.StatelessComponent<ICustomSelectItemProps> = () => {
	//it's just a placeholder used for configuring the CustomSelect component
	//this component is replace by CustomSelectItemRendered during CustomSelectList.render()
	return null;
};

export interface ICustomSelectCustomizableItemProps {
	value: string;
	selectedComponentFactory: (key: string) => React.ReactNode;
	listItemComponentFactory: (state: ICustomSelectCustomizableItemState) => React.ReactNode;
}

export const CustomSelectCustomizableItem: React.StatelessComponent<ICustomSelectCustomizableItemProps> = () => {
	//it's just a placeholder used for configuring the CustomSelect component
	//this component is replace by CustomSelectItemRendered during CustomSelectList.render()
	return null;
};

export const CustomSelectHeader: React.StatelessComponent<{ children?: React.ReactChild }> = ({ children }) => {
	return <li className="dropdown-header">{children}</li>;
};

export const CustomSelectSeparator = () => {
	return <li className="divider" role="separator"></li>;
};

export interface ICustomSelectItemData {
	value: string;
	display: (key: string) => React.ReactNode;
	labels?: LabelItem[];
}

function getCustomSelectItems(children: React.ReactNode) {
	const result: ICustomSelectItemData[] = [];

	React.Children.forEach(children, (x) => {
		if (React.isValidElement<ICustomSelectItemProps>(x) && x.type === CustomSelectItem) {
			result.push({
				value: x.props.value,
				display: () => x.props.displayLabel,
				labels: x.props.labels,
			});
		} else if (
			React.isValidElement<ICustomSelectCustomizableItemProps>(x) &&
			x.type === CustomSelectCustomizableItem
		) {
			result.push({
				value: x.props.value,
				display: x.props.selectedComponentFactory,
			});
		}
	});

	return result;
}

export class CustomSelectViewModel {
	@observable
	componentId: string;

	@observable
	placeholder: string;

	@observable
	open: boolean;

	@observable
	items: ICustomSelectItemData[];

	@observable
	selectedItemValue: string;

	@observable
	highlightedItemIndex: number;

	@observable
	lastScrollTop = 0;

	@observable
	ensureItemIndexVisible: number;

	@observable
	isUnavailable: boolean = false;

	@observable
	isDisabled: boolean = false;

	@observable
	private onRequestOpen: () => void;

	@observable
	private onRequestClose: () => void;

	@observable
	private onItemSelected: (value: string) => void;

	@computed
	get itemsLength(): number {
		return this.items.length;
	}

	@computed
	get selectedItemIndex(): number {
		return this.items.indexOf(this.selectedItem);
	}

	@computed
	get highlightedItemValue(): string {
		return this.highlightedItem && this.highlightedItem.value;
	}

	@computed
	get selectedItem(): ICustomSelectItemData {
		return this.items.filter((x) => x.value === this.selectedItemValue)[0];
	}

	@computed
	get highlightedItem(): ICustomSelectItemData {
		return this.items[this.highlightedItemIndex];
	}

	@computed
	get listId(): string {
		return `${this.componentId}--list`;
	}

	@computed
	get selectedItemValueId(): string {
		return `${this.componentId}--value`;
	}

	getListItemId(index: number) {
		return `${this.listId}--item-${index}`;
	}

	@action
	updateFromProps(props: {
		componentId: string;
		open: boolean;
		selectedItem: string;
		placeholder?: string;
		onItemSelected: (value: string) => void;
		onRequestOpen?: () => void;
		onRequestClose?: () => void;
		items?: ICustomSelectItemData[];
		isUnavailable?: boolean;
		isDisabled?: boolean;
	}) {
		this.onItemSelected = props.onItemSelected;
		this.onRequestOpen = props.onRequestOpen;
		this.onRequestClose = props.onRequestClose;

		this.items = props.items;
		this.selectedItemValue = props.selectedItem;
		this.componentId = props.componentId;
		this.placeholder = props.placeholder;
		this.isUnavailable = props.isUnavailable;
		this.isDisabled = props.isDisabled;

		if (props.open && !this.open) {
			this.highlightedItemIndex = this.selectedItemIndex;
			if (this.highlightedItemIndex === -1) {
				this.highlightedItemIndex = 0;
			}
		}

		this.open = props.open;
	}

	@action
	persistLastScrollTop = (lastScrollTop: number) => {
		this.lastScrollTop = lastScrollTop;
	};

	@action
	handleItemHighlighted = (index: number) => {
		this.ensureItemIndexVisible = -1;
		this.highlightedItemIndex = index;
	};

	@action
	handleItemHighlightedByKeyboard = (navigationType: SelectableListKeyboardNavigation) => {
		const newIndex = getNewHighlightedIndex(this.highlightedItemIndex, this.itemsLength, navigationType);

		this.ensureItemIndexVisible = newIndex;
		this.highlightedItemIndex = newIndex;
	};

	handleCurrentItemSelected = () => {
		if (this.highlightedItem) {
			this.onItemSelected(this.highlightedItemValue);
		}
	};

	handleItemSelected = (index: number) => {
		const selected = this.items[index];
		if (selected) {
			this.onItemSelected(selected.value);
		}
	};

	handleRequestOpen = () => {
		const { onRequestOpen, open } = this;
		if (!open && isFunction(onRequestOpen)) {
			onRequestOpen();
		}
	};

	handleRequestClose = () => {
		const { onRequestClose, open } = this;
		if (open && isFunction(onRequestClose)) {
			onRequestClose();
		}
	};
}

export interface ICustomSelectButton {
	vm: CustomSelectViewModel;
	acceptanceTestTargetId?: string;
}

@observer
class CustomSelectButton extends React.Component<ICustomSelectButton> {
	render() {
		const { open, componentId, listId, handleCurrentItemSelected, handleItemHighlightedByKeyboard, isDisabled } =
			this.props.vm;
		return (
			<SelectableListKeyboardController
				disabled={!open}
				onCurrentItemSelected={handleCurrentItemSelected}
				onNavigated={handleItemHighlightedByKeyboard}
			>
				<AccessibilityCombobox
					id={componentId}
					expanded={open}
					owns={open ? listId : ''}
					activedescendant={this.getActiveDescendantId()}
				>
					<button
						disabled={isDisabled}
						id={componentId}
						className="form-control text-left"
						type="button"
						data-pp-at-target={this.props.acceptanceTestTargetId}
						onClick={this.handleClick}
					>
						{this.renderSelectedValue()}
					</button>
				</AccessibilityCombobox>
			</SelectableListKeyboardController>
		);
	}

	private handleClick = () => {
		const { open, handleRequestClose } = this.props.vm;
		if (open) {
			handleRequestClose();
		}
	};

	private renderSelectedValue() {
		const { placeholder, selectedItemIndex, selectedItem, itemsLength, selectedItemValueId, selectedItemValue } =
			this.props.vm;

		if (!selectedItem) {
			return <span id={selectedItemValueId}>{typeof placeholder === 'string' ? placeholder : ''}</span>;
		}

		return (
			<AccessibilityOption
				id={selectedItemValueId}
				optionIndex={selectedItemIndex}
				optionsCount={itemsLength}
				selected={true}
			>
				<span>{selectedItem.display(selectedItemValue)}</span>
			</AccessibilityOption>
		);
	}

	private getActiveDescendantId() {
		const { open, highlightedItemIndex } = this.props.vm;

		if (!open || highlightedItemIndex === -1) {
			return this.props.vm.selectedItemValueId;
		}

		return this.props.vm.getListItemId(highlightedItemIndex);
	}
}

export interface ICustomSelectProps {
	componentId: string;
	open: boolean;
	selectedItem: string;
	placeholder?: string;
	onItemSelected: (value: string) => void;
	onRequestOpen?: () => void;
	onRequestClose?: () => void;
	acceptanceTestTargetId?: string;
	isUnavailable?: boolean;
	isDisabled?: boolean;
}

const defaultDropdownClassNames = 'dropdown-menu dropdown-menu-hover-controlled open';

@observer
export class CustomSelectList extends React.Component<
	{
		vm: CustomSelectViewModel;
		className?: string;
	},
	{}
> {
	render() {
		const { listId, lastScrollTop, persistLastScrollTop } = this.props.vm;

		let className = this.props.className;
		if (typeof className !== 'string') {
			className = defaultDropdownClassNames;
		}

		return (
			<ScrollableContainer initialScrollingPosition={lastScrollTop} onUnmount={persistLastScrollTop}>
				<StopMouseMovePropogationWhenSamePosition>
					<AccessibilityListbox id={listId}>
						<ul className={className} onMouseDown={this.preventLosingFocus}>
							{this.getConvertedChildren()}
						</ul>
					</AccessibilityListbox>
				</StopMouseMovePropogationWhenSamePosition>
			</ScrollableContainer>
		);
	}

	componentWillEnter(callback) {
		const element = ReactDOM.findDOMNode(this) as HTMLElement;
		element.style.opacity = '0';

		// the animation is inspired by https://material.io/guidelines/motion/choreography.html
		velocity(element, 'fadeIn', {
			queue: false,
			duration: animationStep * 2,
			delay: animationStep,
			easing: 'ease-in-out',
		});

		velocity(element, 'slideDown', {
			queue: false,
			duration: animationStep * 5,
			complete: callback,
			easing: 'ease-in-out',
		});
	}

	componentWillLeave(callback) {
		const element = ReactDOM.findDOMNode(this) as HTMLElement;

		// the animation is inspired by https://material.io/guidelines/motion/choreography.html
		velocity(element, 'fadeOut', {
			queue: false,
			duration: animationStep * 2,
			easing: 'ease-in-out',
		});

		velocity(element, 'slideUp', {
			queue: false,
			duration: animationStep * 5,
			complete: callback,
			easing: 'ease-in-out',
		});
	}

	private getConvertedChildren() {
		const { children, vm } = this.props;
		let index = 0;

		return React.Children.map(children, (x) => {
			if (React.isValidElement<ICustomSelectItemProps>(x) && x.type === CustomSelectItem) {
				const currentIndex = index++;

				return (
					<ScrollableContainerItem key={x.key} tryScrollIntoView={currentIndex === vm.ensureItemIndexVisible}>
						<CustomSelectItemRendered
							{...x.props}
							index={currentIndex}
							id={vm.getListItemId(currentIndex)}
							isSelected={vm.selectedItemIndex === currentIndex}
							isHighlighted={vm.highlightedItemIndex === currentIndex}
							onItemHighlighted={vm.handleItemHighlighted}
							onItemSelected={vm.handleItemSelected}
						/>
					</ScrollableContainerItem>
				);
			} else if (
				React.isValidElement<ICustomSelectCustomizableItemProps>(x) &&
				x.type === CustomSelectCustomizableItem
			) {
				const currentIndex = index++;

				return (
					<ScrollableContainerItem key={x.key} tryScrollIntoView={currentIndex === vm.ensureItemIndexVisible}>
						{x.props.listItemComponentFactory({
							index: currentIndex,
							key: x.props.value,
							id: vm.getListItemId(currentIndex),
							isSelected: vm.selectedItemIndex === currentIndex,
							isHighlighted: vm.highlightedItemIndex === currentIndex,
							onItemHighlighted: vm.handleItemHighlighted,
							onItemSelected: vm.handleItemSelected,
						})}
					</ScrollableContainerItem>
				);
			}
			return x;
		});
	}

	private preventLosingFocus = (e: React.MouseEvent<HTMLUListElement>): void => {
		e.preventDefault();
	};
}

@observer
class CustomSelectItemRendered extends React.Component<
	ICustomSelectItemProps & {
		id: string;
		index: number;
		isSelected: boolean;
		isHighlighted: boolean;
		onItemSelected: (index: number) => void;
		onItemHighlighted: (index: number) => void;
		className?: string;
	},
	{}
> {
	render() {
		const { id, isSelected, isHighlighted, displayLabel, className, labels } = this.props;
		return (
			<AccessibilityOption id={id} selected={isSelected || null}>
				<li
					tabIndex={-1}
					onMouseMove={this.handleMouseMove}
					onClick={this.handleClick}
					className={isHighlighted ? 'hover' : ''}
				>
					<button
						tabIndex={-1}
						type="button"
						className={classNames('btn', 'btn-link', isSelected && 'active', className)}
						data-pp-at-target={displayLabel}
					>
						{displayLabel}
						{labels && <Labels items={labels}></Labels>}
					</button>
				</li>
			</AccessibilityOption>
		);
	}

	private handleMouseMove = () => {
		const { onItemHighlighted, index } = this.props;
		if (isFunction(onItemHighlighted)) {
			onItemHighlighted(index);
		}
	};

	private handleClick = () => {
		const { onItemSelected, index } = this.props;
		if (isFunction(onItemSelected)) {
			onItemSelected(index);
		}
	};
}

@observer
export class CustomSelect extends React.Component<
	ICustomSelectProps,
	{
		vm: CustomSelectViewModel;
	}
> {
	state = {
		vm: new CustomSelectViewModel(),
	};

	UNSAFE_componentWillMount() {
		const { children, acceptanceTestTargetId, ...rest } = this.props;
		this.state.vm.updateFromProps({
			...rest,
			componentId: getPrefixedCustomSelectId(this.props.componentId),
			items: getCustomSelectItems(this.props.children),
		});
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.state.vm.updateFromProps({
			...nextProps,
			componentId: getPrefixedCustomSelectId(nextProps.componentId),
			items: getCustomSelectItems(nextProps.children),
		});
	}

	render() {
		const { vm } = this.state;

		return (
			<DropDownMenuController
				onRequestClose={vm.handleRequestClose}
				onRequestOpen={vm.handleRequestOpen}
				open={vm.open}
				openOnFocus={false}
			>
				<div className={'select-wrapper custom-select' + (vm.open ? ' open' : '')}>
					<CustomSelectButton vm={vm} acceptanceTestTargetId={this.props.acceptanceTestTargetId} />
					<TransitionGroup>{this.getCustomSelectContent()}</TransitionGroup>
				</div>
			</DropDownMenuController>
		);
	}

	private getCustomSelectContent() {
		const { vm } = this.state;

		if (!vm.open) {
			return null;
		}

		if (vm.isUnavailable) {
			return <UnavailableDropDownMenu componentId={vm.componentId} />;
		}

		return <CustomSelectList vm={vm}>{this.props.children}</CustomSelectList>;
	}
}

const UnavailableDropDownMenu = observer(({ componentId }) => (
	<ul className={defaultDropdownClassNames}>
		<CustomSelectItemRendered
			value={''}
			displayLabel={'Please wait'}
			index={0}
			id={`${componentId}-unavailable`}
			className={'unavailable-menu-item'}
			isSelected={false}
			isHighlighted={false}
			onItemHighlighted={null}
			onItemSelected={null}
		/>
	</ul>
));

export function getPrefixedCustomSelectId(componentId: string) {
	return `pp_custom_select_${componentId}`;
}

export interface ICustomSelectCustomizableItemState {
	id: string;
	index: number;
	key: string;
	isSelected: boolean;
	isHighlighted: boolean;
	onItemSelected: (index: number) => void;
	onItemHighlighted: (index: number) => void;
}
