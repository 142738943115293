import { isObservable, toJS } from 'mobx';
import { post } from '../utils/ajax-client';
import { AjaxUtils } from '../utils/ajax-utils';
import { QboIntegrationApiConfig } from './qbo-integration-generated';
import { ICancellablePromise } from '../utils/cancellable-promise';

export interface IApiAction<TRequest, TResponse = any> {
	readonly url: (model: TRequest) => string;
	request: TRequest;
	response: TResponse;
}

export type QboIntegrationActionRequest<TActionKey extends keyof QboIntegrationApiConfigActions> = QboIntegrationApiConfigActions[TActionKey]['request'];
export type QboIntegrationActionResponse<TActionKey extends keyof QboIntegrationApiConfigActions> = QboIntegrationApiConfigActions[TActionKey]['response'];

export type QboIntegrationApiConfigActions = typeof QboIntegrationApiConfig['actions'];

export type QboIntegrationDataService = {
	[x in keyof QboIntegrationApiConfigActions]: (request: QboIntegrationActionRequest<x>) => ICancellablePromise<QboIntegrationActionResponse<x>>;
};

const options = {
	timeout: 20000,
	newCSRFToken: '',
};

let instance: QboIntegrationDataService | null = null;

function createActionHandler<TKey extends keyof QboIntegrationApiConfigActions>(actionKey: TKey) {
	const actionConfig = QboIntegrationApiConfig.actions[actionKey] as any;

	return (request: any) => {
		request = isObservable(request) ? toJS(request) : { ...request };

		return post(actionConfig.url(request), request, {
			timeout: options.timeout,
			baseUrl: AjaxUtils.resolveBaseUrl(QboIntegrationApiConfig.defaultBaseUrl),
			antiForgeryToken: options.newCSRFToken
		});
	};
}

function createQboIntegrationDataService(): QboIntegrationDataService {
	return (Object.keys(QboIntegrationApiConfig.actions) as Array<keyof QboIntegrationApiConfigActions>)
		.reduce<QboIntegrationDataService>((acc: any, actionKey: keyof QboIntegrationApiConfigActions) => {
			acc[actionKey] = createActionHandler(actionKey);
			return acc;
		}, {} as QboIntegrationDataService);
}

export function getQboIntegrationDataService() {
	if (instance === null) {
		instance = createQboIntegrationDataService();
	}

	return instance;
}

export function setNewCRSFoken(token: string) {
	options.newCSRFToken = token;
}

export function mockQboIntegrationDataService(mockDataService: QboIntegrationDataService) {
	instance = mockDataService;
}
