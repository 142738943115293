import { action, computed, observable } from 'mobx';
import { RecentGiftEntryViewModel, PaymentLabel, RecentGiftEntriesViewModel, GetRecentGiftEntryType, HasMoreRecentGiftEntries } from '../../virtual-terminal-generated';
import { IDataServiceActionSubscriber } from '../../../utils/data-service';
import { VirtualTerminalApiConfigType, getVirtualTerminalDataService, VirtualTerminalDataServiceAction } from '../../single-entry-data-service';
import { VirtualTerminalListingStore } from '../../virtual-terminal-listing-store';
import { ModalDialogCommander } from '../../../components/modal-dialog-commander';
import { ModalDialogProcessingViewModel } from '../../../components/modal-dialog/modal-dialog-processing-view-model';

export const recentGiftsPaymentDetailsAcceptableLoadingTime = 300;

export class RecentGiftsGridViewModel {
	@observable gifts: RecentGiftEntryViewModel[] = null;
	@observable expandedGiftDetails: RecentGiftEntryViewModel = null;
	@observable acceptableLoadingTimeExceeded: boolean = false;
	@observable lastFetchedTime: Date = null;
	@observable canLoadMore: boolean = false;
	recentGiftEntriesSubscriber: IDataServiceActionSubscriber<VirtualTerminalApiConfigType, 'recentGiftEntriesAsync'>;
	recentGiftDetailsSubscriber: IDataServiceActionSubscriber<VirtualTerminalApiConfigType, 'viewPayment'>;
	recentNonCashGiftDetailsSubscriber: IDataServiceActionSubscriber<VirtualTerminalApiConfigType, 'viewNonCashGift'>;
	recentGiftDeleteSubscriber: IDataServiceActionSubscriber<VirtualTerminalApiConfigType, 'deletePayment'>;
	recentNonCashGiftDeleteSubscriber: IDataServiceActionSubscriber<VirtualTerminalApiConfigType, 'deleteNonCashGift'>;
	acceptableLoadingTimeout: number;
	modalDialogProcessingViewModel: ModalDialogProcessingViewModel;

	constructor(private listingStore: VirtualTerminalListingStore) {
		const recentGiftEntriesSubscriberFactory = getVirtualTerminalDataService().getActionSubscriberFactory('recentGiftEntriesAsync');
		const recentGiftDetailsSubscriberFactory = getVirtualTerminalDataService().getActionSubscriberFactory('viewPayment');
		const recentNonCashGiftDetailsSubscriberFactory = getVirtualTerminalDataService().getActionSubscriberFactory('viewNonCashGift');
		const recentGiftDeleteSubscriberFactory = getVirtualTerminalDataService().getActionSubscriberFactory('deletePayment');
		const recentNonCashGiftDeleteSubscriberFactory = getVirtualTerminalDataService().getActionSubscriberFactory('deleteNonCashGift');

		this.recentGiftEntriesSubscriber = recentGiftEntriesSubscriberFactory((action) => this.subscribeToRecentGiftEntries(action));
		this.recentGiftDetailsSubscriber = recentGiftDetailsSubscriberFactory((action) => this.subscribeToRecentGiftDetails(action));
		this.recentNonCashGiftDetailsSubscriber = recentNonCashGiftDetailsSubscriberFactory((action) => this.subscribeToRecentNonCashGiftDetails(action));
		this.recentGiftDeleteSubscriber = recentGiftDeleteSubscriberFactory((action) => this.subscribeToRecentGiftDelete(action));
		this.recentNonCashGiftDeleteSubscriber = recentNonCashGiftDeleteSubscriberFactory((action) => this.subscribeToRecentNonCashGiftDelete(action));

		this.modalDialogProcessingViewModel = new ModalDialogProcessingViewModel();
	}

	initGiftDetailsRequest = (encodedToken: string) => {
		this.recentGiftDetailsSubscriber.initRequest({ merchantId: parseInt(this.selectedListingId), encodedToken: encodedToken });
		this.acceptableLoadingTimeout = window.setTimeout(() => this.updateAcceptableLoadingTimeExceeded(true), recentGiftsPaymentDetailsAcceptableLoadingTime);
	}

	initTypedGiftDetailsRequest = (gift: RecentGiftEntryViewModel) => {
		if(gift.IsNonCash) {
			this.recentNonCashGiftDetailsSubscriber.initRequest({ merchantId: parseInt(this.selectedListingId), nonCashGiftId: gift.NonCashGiftId });
		} else {
			this.recentGiftDetailsSubscriber.initRequest({ merchantId: parseInt(this.selectedListingId), encodedToken: gift.EncodedToken });
		}
		this.acceptableLoadingTimeout = window.setTimeout(() => this.updateAcceptableLoadingTimeExceeded(true), recentGiftsPaymentDetailsAcceptableLoadingTime);
	}

	initGiftDeleteRequest = (encodedToken: string) => {
		this.modalDialogProcessingViewModel.setIsProcessing(true);
		this.recentGiftDeleteSubscriber.initRequest({ merchantId: parseInt(this.selectedListingId), encodedToken });
	}

	initPaymentOrNonCashGiftDeleteRequest = (giftDetails: {IsNonCash: boolean, EncodedToken: string, NonCashGiftId: number}) => {
		const { IsNonCash, EncodedToken, NonCashGiftId } = giftDetails;

		this.modalDialogProcessingViewModel.setIsProcessing(true);

		if(IsNonCash) {
			this.recentNonCashGiftDeleteSubscriber.initRequest({ merchantId: parseInt(this.selectedListingId), nonCashGiftId: NonCashGiftId });
		} else {
			this.recentGiftDeleteSubscriber.initRequest({ merchantId: parseInt(this.selectedListingId), encodedToken: EncodedToken });
		}
	}

	@computed
	get currentNumberOfGifts() {
		return this.gifts && this.gifts.length || 0;
	}

	@computed
	get hasGifts() {
		return this.currentNumberOfGifts > 0;
	}

	@computed
	get selectedListingId() {
		return this.listingStore.selectedListingId;
	}

	@computed
	get paymentLabel(): PaymentLabel {
		return this.listingStore.selectedListing.PaymentLabel;
	}

	@computed
	get sortedGifts(): RecentGiftEntryViewModel[] {
		return this.gifts && this.gifts.orderByDescending(gift => gift.CreatedOn).toArray();
	}

	@action.bound
	updateGifts(newGifts: RecentGiftEntriesViewModel) {
		this.lastFetchedTime = newGifts.FetchTime;

		if (!newGifts.HasBeenUpdated) {
			return;
		}

		this.gifts = newGifts.GetRecentGiftEntryType === GetRecentGiftEntryType.LoadMore
			? this.gifts.concat(newGifts.RecentGiftEntries)
			: newGifts.RecentGiftEntries;

		if (newGifts.HasMoreRecentGiftEntries === HasMoreRecentGiftEntries.Yes) {
			this.canLoadMore = true;
		} else if (newGifts.HasMoreRecentGiftEntries === HasMoreRecentGiftEntries.No) {
			this.canLoadMore = false;
		}

		if (newGifts.ExpandedGiftEntry) {
			this.updateExpandedGiftDetails(newGifts.ExpandedGiftEntry);
		}
	}

	@action.bound
	removeGift(encodedToken: string) {
		this.gifts = this.gifts.filter((gift) => gift.EncodedToken !== encodedToken);
	}

	@action.bound
	removeNonCashGift(nonCashGiftId: number) {
		this.gifts = this.gifts.filter((gift) => gift.NonCashGiftId !== nonCashGiftId);
	}

	@action.bound
	addGift(gift: RecentGiftEntryViewModel) {
		this.gifts = this.gifts || [];
		this.gifts.push(gift);
	}

	@action.bound
	updateExpandedGiftDetails(gift: RecentGiftEntryViewModel) {
		this.expandedGiftDetails = gift;
	}

	@action.bound
	updateAcceptableLoadingTimeExceeded(exceeded: boolean) {
		this.acceptableLoadingTimeExceeded = exceeded;
	}

	private subscribeToRecentGiftEntries = (action: VirtualTerminalDataServiceAction) => {
		switch (action.type) {
			case 'request_success':
				this.updateGifts(action.response);
				return;
			case 'request_error':
				console.log('Request for recent gifts failed');
				return;
		}
	}

	private subscribeToRecentGiftDetails = (action: VirtualTerminalDataServiceAction) => {
		this.updateAcceptableLoadingTimeExceeded(false);
		clearTimeout(this.acceptableLoadingTimeout);
		switch (action.type) {
			case 'request_success':
				this.updateExpandedGiftDetails(action.response);
				return;
			case 'request_error':
				console.log('Request for recent gift details failed');
				return;
		}
	}

	private subscribeToRecentNonCashGiftDetails = (action: VirtualTerminalDataServiceAction) => {
		this.updateAcceptableLoadingTimeExceeded(false);
		clearTimeout(this.acceptableLoadingTimeout);
		switch (action.type) {
			case 'request_success':
				this.updateExpandedGiftDetails(action.response);
				return;
			case 'request_error':
				console.log('Request for recent gift details failed');
				return;
		}
	}

	private subscribeToRecentGiftDelete = (action: VirtualTerminalDataServiceAction) => {
		switch (action.type) {
			case 'request_success':
				this.modalDialogProcessingViewModel.setIsProcessing(false);
				ModalDialogCommander.forceCloseCurrent();
				this.removeGift(action.response.EncodedToken);
				return;
			case 'request_error':
				ModalDialogCommander.showStandardErrorMessage();
				return;
		}
	}

	private subscribeToRecentNonCashGiftDelete = (action: VirtualTerminalDataServiceAction) => {
		switch (action.type) {
			case 'request_success':
				this.modalDialogProcessingViewModel.setIsProcessing(false);
				ModalDialogCommander.forceCloseCurrent();
				this.removeNonCashGift(action.response.NonCashGiftId);
				return;
			case 'request_error':
				ModalDialogCommander.showStandardErrorMessage();
				return;
		}
	}
}
