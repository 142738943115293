import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, action, runInAction, computed } from 'mobx';
import { Tooltip, TooltipContent } from '../../components/tooltip/tooltip';
import { IDataServiceActionSubscriber } from '../../utils/data-service';
import { getTransactionsDataService, TransactionsApiConfig } from '../transactions-data-service';
import { Debouncer } from '../../utils/debouncer';
import { Models } from '../transactions-generated';

@observer
export class SplitPaymentInfoTooltip extends React.Component<{ merchantId: number, splitPaymentId: number, isCheck: boolean }, { store: SplitPaymentInfoStore }> {

	constructor(props) {
		super(props);
		const { merchantId, splitPaymentId } = this.props;
		this.state = { store: new SplitPaymentInfoStore(merchantId, splitPaymentId) };
	}

	render() {
		const { handleVisibleChange, tooltipVisible } = this.state.store;
		return (
			<Tooltip label="more information" visible={tooltipVisible} onVisibleChange={handleVisibleChange} placement="top">
				<span className="split-payment-info-tooltip">(split)</span>
				<TooltipContent>
					{this.tooltipContent()}
				</TooltipContent>
			</Tooltip>
		);
	}

	private tooltipContent = () => {
		const { model, isLoading, error } = this.state.store;

		if (isLoading) {
			return <div>Loading</div>;
		}

		if (error) {
			return <div>{error}</div>;
		}

		if (!model) {
			return null;
		}

		const label = this.props.isCheck ? 'check' : 'transaction';
		const { FundAndDollarAmounts, TotalDollars } = model;
		return (
			<div className="split-payment-info-tooltip-content">
				<p>This {label} has been split into the following payments:</p>
				<table><tbody>{FundAndDollarAmounts.map(x => <SplitPaymentInfoFundAndAmount key={x.PaymentId} fund={x.FundName} amount={x.Amount} />)}</tbody></table>
				<hr/>
				<p className="total-amount"><strong>Total:</strong> {TotalDollars}</p>
			</div>
		);
	}
}

@observer
class SplitPaymentInfoFundAndAmount extends React.Component<{fund: String, amount: String}, {}> {
	render() {
		const { fund, amount } = this.props;
		return <tr><th>{fund}</th><td>{amount}</td></tr>;
	}
}

export class SplitPaymentInfoStore {
	@observable visible: boolean;

	@observable model: Models.SplitPaymentToolTipModel;

	@observable error: string;

	@observable isLoading: boolean;

	@observable actionSubscriber: IDataServiceActionSubscriber<TransactionsApiConfig, 'splitPaymentInfo'>;

	private loadingDebouncer: Debouncer<any>;

	@computed
	get tooltipVisible() {
		return (this.visible && (!!this.model || this.isLoading || !!this.error));
	}

	constructor(private merchantId: number, private splitPaymentId: number) {
		if (!this.merchantId) {
			throw new Error('merchantId cannot be undefined');
		}

		if (!this.splitPaymentId) {
			throw new Error('splitPaymentId cannot be undefined');
		}

		this.actionSubscriber = getTransactionsDataService().getActionSubscriberFactory('splitPaymentInfo')(action => {
			runInAction('handleSplitPaymentInfoAjaxRequest', () => {
				if (action.type !== 'request_init') {
					this.isLoading = false;
					this.loadingDebouncer.cancel();
				}

				switch (action.type) {
					case 'request_error':
						this.error = 'A problem was encountered when retrieving this data. Please try again.';
						break;
					case 'request_success':
						this.model = action.response;
						break;
					default:
						break;
				}
			});
		});

		this.loadingDebouncer = new Debouncer<any>(400, () => runInAction(() => {
			this.isLoading = true;
		}));
	}

	@action.bound
	handleVisibleChange(visible: boolean) {
		this.visible = visible;

		if (this.visible && !this.model) {
			this.loadingDebouncer.exec();
			this.actionSubscriber.initRequest({
				merchantId: this.merchantId,
				splitPaymentId: this.splitPaymentId
			});
		} else if (!this.visible && this.actionSubscriber.isProcessingRequest) {
			this.isLoading = false;
			this.loadingDebouncer.cancel();
			this.actionSubscriber.cancelRequest();
		}
	}
}
