import * as React from 'react';
import { action, observable} from 'mobx';
import { observer } from 'mobx-react';
import { Tooltip, TooltipContent, TooltipPlacement } from '../../../LoggedInWeb/components/tooltip/tooltip';
import { SvgWrapper } from '../../../LoggedInWeb/components/svg-wrapper';

export interface IFormFieldTooltipProps {
	className?: string;
	tooltipContentClassName?: string;
	message: string;
	placement?: TooltipPlacement;
}

@observer
export class FormFieldTooltip extends React.Component<IFormFieldTooltipProps> {
	@observable
	visible = false;

	private handleVisibleChange = action((visible: boolean) => {
		this.visible = visible;
	});

	render() {
		const { className, placement, message, tooltipContentClassName } = this.props;
		return (
			<Tooltip visible={this.visible}
				onVisibleChange={this.handleVisibleChange}
				tooltipClassName={className}
				placement={placement}
				label="more information"
				tooltipContentClassName={tooltipContentClassName}>
				<SvgWrapper svg="icon-info" className={`icon tooltip-icon`} />
				<TooltipContent>
					<div>{message}</div>
				</TooltipContent>
			</Tooltip>
		);
	}
}
