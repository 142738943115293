import * as React from 'react';
import { classNames } from '../../Shared/utils/classnames';
import { SvgWrapper } from '../components/svg-wrapper';

import { buttonGroup, container, message, closeIcon, closeContainer, visible } from './export-button.less';

export class ExportButton extends React.Component<{
	vm: {Action: number; Description: string; Label: string}[];
}, {
	menuVisible: boolean
	messageVisible: boolean
	timeoutHandle?: number | null
}> {

	container: HTMLDivElement;

	constructor(props) {
		super(props);
		this.state = { menuVisible: false, messageVisible: false };
	}

	render() {
		const buttons = this.props.vm.map(b => (
			<li key={b.Action}>
				<button name="button"
					type="submit"
					className="btn btn-link"
					value={b.Action}
					title={b.Description}
					data-pp-at-target={`export - ${b.Action}`}
					onClick={this.downloadStarted.bind(this)}
				>
					<span>{b.Label}</span>
				</button>
			</li>));

		return (
			<div className={classNames('form-group', container)}>
				<div className={classNames('filter-control-submit', buttonGroup)}>
					<div className="responsive-btn-group">
						<button name="button" type="submit" className="btn btn-default" value="view" data-pp-at-target="apply filter">Apply filter</button>
						<div className="dropdown mobile-enabled" ref={this.setContainerRef.bind(this)}>
							<button type="button" className="btn btn-ghost down-arrow"  data-pp-at-target="export" onClick={this.toggleMenu.bind(this)}>
								Export
							</button>
							<ul className={classNames('dropdown-menu', this.state.menuVisible && 'show')}>
								{buttons}
							</ul>
						</div>
					</div>
				</div>
				<div className={classNames(message, this.state.messageVisible && visible)}>
					<div><strong>Please wait: </strong> Exports can take up to three minutes to complete</div>
					<div className={closeContainer}>
						<SvgWrapper className={classNames('icon', closeIcon)} onClick={this.hideMessage.bind(this)} svg="ind-cross" title="Close" />
					</div>
				</div>
			</div>
		);
	}

	setContainerRef(container: HTMLDivElement) {
		this.container = container;
	}

	componentDidMount() {
		document.addEventListener('click', this.toggleMenuIfVisible.bind(this));
	}

	toggleMenuIfVisible(event: React.MouseEvent<HTMLElement>) {
		if (!this.state.menuVisible) {
			return;
		}

		if (!this.container.contains(event.target as Node)) {
			this.toggleMenu();
		}
	}

	toggleMenu() {
		this.setState({menuVisible: !this.state.menuVisible});
	}

	downloadStarted() {
		const timeoutHandle = setTimeout(this.hideMessage.bind(this), 30000, true);
		this.setState({messageVisible: true, menuVisible: false, timeoutHandle: timeoutHandle});

	}

	hideMessage(fromTimeout?: boolean) {
		if (!fromTimeout && this.state.timeoutHandle) {
			clearTimeout(this.state.timeoutHandle);
		}
		this.setState({messageVisible: false, timeoutHandle: null});
	}
}
