import { FrequencyCode } from './virtual-terminal-generated';

export namespace Constants {
	export const AnonymousPayerFirstName = 'Anonymous';
	export const AnonymousPayerLastName = 'Payer';
	export const AddNewPayerItemKey = '__add_new_payer__';
}

export interface IAnonymousPayer {
	readonly firstName: string;
	readonly lastName: string;
	readonly isAnonymous: boolean;
}

export const AnonymousPayer = {
	firstName: Constants.AnonymousPayerFirstName,
	lastName: Constants.AnonymousPayerLastName,
	isAnonymous: true
} as IAnonymousPayer;

export const frequencyCodeReadable = {};
frequencyCodeReadable[FrequencyCode.Weekly] = 'Every week';
frequencyCodeReadable[FrequencyCode.Fortnightly] = 'Every 2 weeks';
frequencyCodeReadable[FrequencyCode.Monthly] = 'Every month';
frequencyCodeReadable[FrequencyCode.FirstAndFifteenth] = '1st and 15th monthly';

export const frequencyWithAnnualCodeReadable = {};
frequencyWithAnnualCodeReadable[FrequencyCode.Yearly] = 'Every year';

export const frequencyWithSemiAnnualCodeReadable = {};
frequencyWithSemiAnnualCodeReadable[FrequencyCode.SemiYearly] = 'Every 6 months';

export const frequencyWithQuarterlyCodeReadable = {};
frequencyWithQuarterlyCodeReadable[FrequencyCode.Quarterly] = 'Every 3 months';

export const testFrequencyCodeReadable = {};
testFrequencyCodeReadable[FrequencyCode.OneMinute] = 'One minute';
testFrequencyCodeReadable[FrequencyCode.FiveMinutes] = 'Five minutes';
testFrequencyCodeReadable[FrequencyCode.FourHours] = 'Four hours';

