import { isObservable, toJS } from 'mobx';
import { post } from '../utils/ajax-client';
import { AjaxUtils } from '../utils/ajax-utils';
import { ScheduleImportApiConfig } from './schedule-import-generated';
import { ICancellablePromise } from '../utils/cancellable-promise';

export interface IApiAction<TRequest, TResponse = any> {
	readonly url: (model: TRequest) => string;
	request: TRequest;
	response: TResponse;
}

export type ScheduleDetailsActionRequest<TActionKey extends keyof ScheduleDetailsApiConfigActions> = ScheduleDetailsApiConfigActions[TActionKey]['request'];
export type ScheduleDetailsActionResponse<TActionKey extends keyof ScheduleDetailsApiConfigActions> = ScheduleDetailsApiConfigActions[TActionKey]['response'];

export type ScheduleDetailsApiConfigActions = typeof ScheduleImportApiConfig['actions'];

export type ScheduleDetailsDataService = {
	[x in keyof ScheduleDetailsApiConfigActions]: (request: ScheduleDetailsActionRequest<x>) => ICancellablePromise<ScheduleDetailsActionResponse<x>>;
};

const options = {
	timeout: 20000,
	newCSRFToken: '',
};

let instance: ScheduleDetailsDataService | null = null;

function createActionHandler<TKey extends keyof ScheduleDetailsApiConfigActions>(actionKey: TKey) {
	const actionConfig = ScheduleImportApiConfig.actions[actionKey] as any;

	return (request: any) => {
		request = isObservable(request) ? toJS(request) : { ...request };

		return post(actionConfig.url(request), request, {
			timeout: options.timeout,
			baseUrl: AjaxUtils.resolveBaseUrl(ScheduleImportApiConfig.defaultBaseUrl),
			antiForgeryToken: options.newCSRFToken
		});
	};
}

function createScheduleDetailsDataService(): ScheduleDetailsDataService {
	return (Object.keys(ScheduleImportApiConfig.actions) as Array<keyof ScheduleDetailsApiConfigActions>)
		.reduce<ScheduleDetailsDataService>((acc: any, actionKey: keyof ScheduleDetailsApiConfigActions) => {
			acc[actionKey] = createActionHandler(actionKey);
			return acc;
		}, {} as ScheduleDetailsDataService);
}

export function getScheduleDetailsDataService() {
	if (instance === null) {
		instance = createScheduleDetailsDataService();
	}

	return instance;
}

export function setNewCRSFToken(token: string) {
	options.newCSRFToken = token;
}

export function mockScheduleDetailsDataService(mockDataService: ScheduleDetailsDataService) {
	instance = mockDataService;
}
