import * as React from 'react';
import { ObservableMap } from 'mobx';
import { Provider } from 'mobx-react';
import { validation } from '../../validation/validation';
import { ValidatableFormContext } from './validatable-form-context';

export interface IValidatableFormProps {
	className?: string;
	onSubmit?: (form: HTMLFormElement) => void;
	validationErrors?: { [key: string]: string; };
	errorMap?: ObservableMap<string, string>;
	acceptanceTestTargetId?: string;
}

/**
 * onSubmit is not call when form has invalid fields
 *
 * @export
 * @class ValidatableForm
 * @extends {React.Component<IFormProps, {}>}
 */
export class ValidatableForm extends React.Component<IValidatableFormProps, { context: ValidatableFormContext }> {
	private form: HTMLFormElement;
	private validator: JQueryValidation.Validator;

	constructor(props) {
		super(props);
		this.state = {
			context: new ValidatableFormContext(this.handleRevalidateElement, props.errorMap)
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps: IValidatableFormProps) {
		if (nextProps.validationErrors !== this.props.validationErrors) {
			this.state.context.updateErrorMap(nextProps.validationErrors);
		}
	}

	handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
	}

	handleJQuerySubmit = (event: JQueryEventObject) => {
		if (event.result && this.props.onSubmit) {//form is valid
			this.props.onSubmit(this.form);
		}
	}

	componentDidMount() {
		validation.parseForm(this.form);
		this.validator = validation.bindForm(this.form);

		$(this.form).on('submit', this.handleJQuerySubmit);

		this.validator.settings.showErrors = (errorMap, errorList) => {
			//bloody jQuery makes things "very" simple and obvious
			//this somewhat replicates logic of jQuery.validator.defaultShowErrors
			const validElements = $.map(this.validator.validElements(), (x: { name: string }) => x.name);

			//merge current error map with the passed error map
			const newErrorMap = { ...this.state.context.errorMap.toJSON(), ...errorMap };

			//ensure valid elements excluded from the error map
			validElements.forEach(x => {
				newErrorMap[x] = '';
			});

			this.state.context.updateErrorMap(newErrorMap);
		};
	}

	handleRevalidateElement = (elementName: string) => {
		this.validator.element($(`[name="${elementName}"]`, this.form));
	}

	componentWillUnmount() {
		$(this.form).off('submit', this.handleJQuerySubmit);
	}

	render() {
		return (
			<Provider validatableFormContext={this.state.context}>
				<form action="" autoComplete="off" noValidate={true} method="post" onSubmit={this.handleSubmit} ref={(ref) => this.form = ref}
					className={this.props.className} data-pp-at-target={this.props.acceptanceTestTargetId}>
					{this.props.children}
				</form>
			</Provider>
		);
	}
}
