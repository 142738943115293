import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { PaymentLabel } from '../../virtual-terminal-generated';
import { IVirtualTerminalFormUserActionCreator } from './virtual-terminal-form-user-action-creator';
import { checkNounLowerCase, checkNounTitleCase } from '../../utils/check-translator';
import { AddMemberButton } from '../../components/member/add-member-button';
import { RenderResponsive } from '../../../components/hoc-behavior/render-responsive';
import { ResponsiveBreakpoint, ResponsiveVisibility } from '../../../helpers/responsive-helper';
import { IMemberViewModel } from '../../components/member/member-view-model';
import { Metrics } from '../../utils/metrics';
import { communityMemberModelToViewModel } from '../../utils/member-view-model-helper';
import { nullableNumber } from '../../../utils/nullable-number';
import { VirtualTerminalFormViewModel } from './virtual-terminal-form-view-model';

import * as styles from './virtual-terminal-form-header.less';

const injectUserActionCreator = 'userActionCreator';

@inject(injectUserActionCreator)
@observer
export class VirtualTerminalFormHeader extends React.Component<{
	title?: string,
	formViewModel: VirtualTerminalFormViewModel,
	userActionCreator?: IVirtualTerminalFormUserActionCreator,
	paymentLabel: PaymentLabel,
}> {
	render() {
		const { title, formViewModel, paymentLabel } = this.props;
		const { listingStore, selectedPayer, omniboxValue } = formViewModel;
		const {
			selectedListingId,
			selectedListingSupportsRecordedCheck,
			selectedListingHomeCountry,
			selectedListingHasOrganizationalGivingEnabled,
			selectedListingCanAddNewMember,
		} = listingStore;
		const fullName = selectedPayer ? selectedPayer.name : omniboxValue;
		return (
			<div className={styles.container}>
				{title && <h2 className={styles.title}>{title}</h2>}
				<div className={styles.message}>
					<div className={styles.messageText}>
						<VirtualTerminalLeadMessage formViewModel={this.props.formViewModel} />
						{selectedListingSupportsRecordedCheck &&
							<RenderResponsive visibility={ResponsiveVisibility.Visible} breakpoints={ResponsiveBreakpoint.Xs | ResponsiveBreakpoint.Sm}>
								<p><em>(<strong>Note:</strong> {checkNounTitleCase(selectedListingHomeCountry)} readers are only supported on desktop.)</em></p>
							</RenderResponsive>
						}
					</div>
					{selectedListingCanAddNewMember && 
					<AddMemberButton
						MerchantId={nullableNumber(selectedListingId)}
						DefaultCountry={selectedListingHomeCountry}
						onCommunityMemberAdded={this.handleMemberAdded}
						fullName={fullName}
						className={styles.btnNewMember}
						paymentLabel={paymentLabel}
						enableFeatureOrganizationalGiving={selectedListingHasOrganizationalGivingEnabled}
					/>}
				</div>
			</div>
		);
	}

	private handleMemberAdded = (member: IMemberViewModel) => {
		Metrics.addPayerFinished();
		const payer = communityMemberModelToViewModel(member);
		this.props.userActionCreator.SelectedPayerChange(payer);
	}
}

@observer
class VirtualTerminalLeadMessage extends React.Component<{ formViewModel: VirtualTerminalFormViewModel }> {
	render() {
		const {
			selectedListingSupportsCash,
			selectedListingSupportsRecordedCheck,
			selectedListingHomeCountry,
			paymentLabel,
			selectedListingCanAddNewMember,
		} = this.props.formViewModel.listingStore;

		return (
			<p className={styles.lead}>
				{`Enter a ${paymentLabel.NounLowerCase} by searching for an existing ${paymentLabel.PayerLowerCase}`}
				{selectedListingSupportsCash && <VirtualTerminalAnonLink />}
				{selectedListingSupportsRecordedCheck && `, reading a ${checkNounLowerCase(selectedListingHomeCountry)}`}
				{selectedListingCanAddNewMember && ` or adding a new member`}
			</p>
		);
	}
}

@inject(injectUserActionCreator)
@observer
class VirtualTerminalAnonLink extends React.Component<{ userActionCreator?: IVirtualTerminalFormUserActionCreator }> {
	render() {
		return (
			<span>
				{`, or typing `}
				<button type="button"
					className={`btn btn-tertiary ${styles.btnAnon}`}
					onClick={this.handleSelectAnonymousPayer}
					data-pp-at-target="anonymous button">'anon'</button>
			</span>
		);
	}

	private handleSelectAnonymousPayer = () => {
		this.props.userActionCreator.SelectAnonymousPayer();
	}
}
