import * as React from 'react';
import { AnalyticsContext, recordEvent } from '../../../analytics';
import { ListingSelectorWithLabel } from '../../../components/listing-selector';
import { inject, observer, Provider } from 'mobx-react';
import { VirtualTerminalMainViewModel } from './single-entry-main-view-model';
import { VirtualTerminalUserAction } from './single-entry-user-actions';
import { RecentGiftsGrid } from '../../components/recent-gifts/recent-gifts-grid';
import { VirtualTerminalForm } from '../../components/form/virtual-terminal-form';
import { PaymentLabel } from '../../virtual-terminal-generated';
import { Channel } from '../../../utils/saga-utils';
import { VirtualTerminalListingStore } from '../../virtual-terminal-listing-store';
import { checkNounLowerCase } from '../../utils/check-translator';
import { VirtualTerminalFormHeader } from '../../components/form/virtual-terminal-form-header';
import { TipBox } from '../../../components/tipbox';
import { SvgWrapper } from '../../../components/svg-wrapper';

import * as styles from './single-entry-main.less';

const storageKey = 'QBOEnabledWarning';
const analyticsContext: AnalyticsContext = {
	feature: 'giftEntry',
	subFeature: 'singleGiftEntry',
};

@observer
export class VirtualTerminalMain extends React.Component<{ vm: VirtualTerminalMainViewModel }, {}> {

	render() {
		const {
			userActionChannel,
			userActionCreator,
			listingStore,
			formViewModel,
			recentGiftsGridViewModel,
			shouldDisplayQboEnabledWarning,
			batchEntryUrl
		} = this.props.vm;
		const { PaymentLabel, TransactionsUrl, SupportsNonCash, NonCashTransactionsUrl } = listingStore.selectedListing;

		return (
			<Provider userActionChannel={userActionChannel} userActionCreator={userActionCreator} analytics={analyticsContext}>
				<div>
					{shouldDisplayQboEnabledWarning && <QboEnableWarning batchEntryUrl={batchEntryUrl} paymentLabel={PaymentLabel} />}
					<header>
						<h1 className={styles.title}>New {PaymentLabel.GiftEntryFeatureName}</h1>
						<ListingSelectorWithLabel
							availableListings={listingStore.listingsAsDropDownOptions}
							selectedListingId={listingStore.selectedListingId}
							onChange={this.handleSelectedListingChange}
							className={styles.listingSelector} />
						<VirtualTerminalFormHeader formViewModel={formViewModel} paymentLabel={PaymentLabel} />
					</header>
					<VirtualTerminalForm formViewModel={formViewModel} />
					<div className="vt-recent-gifts">
						<h2>Recent {PaymentLabel.NounPluralLowerCase}</h2>
						{recentGiftsGridViewModel.hasGifts ?
							<div>
								{ SupportsNonCash ?
									<p>This list displays {PaymentLabel.NounLowerCase} entries made in the last 24 hours.
											&#32;{TransactionsUrl && <a href={TransactionsUrl} className="vt-recent-gifts-transactions-link arrow-hollow-right-after">View transactions</a>}
											&#32;{NonCashTransactionsUrl && <a href={NonCashTransactionsUrl} className="vt-recent-gifts-transactions-link arrow-hollow-right-after">View non-cash transactions</a>}
									</p>
								:
									<p>This list displays {PaymentLabel.NounLowerCase} entries made in the last 24 hours.
											&nbsp;{TransactionsUrl && <a href={TransactionsUrl} className="vt-recent-gifts-transactions-link arrow-hollow-right-after">View all transactions</a>}
									</p>
								}
								<RecentGiftsGrid vm={recentGiftsGridViewModel} />
							</div>
							: <p>Sorry, there are no recent {`${PaymentLabel.NounLowerCase}`} entries to see here!</p>
						}
					</div>
				</div>
			</Provider>
		);
	}

	private handleSelectedListingChange = (listingId: string) => {
		const { feature, subFeature } = analyticsContext;
		recordEvent({ feature, subFeature, eventTypeLabel: 'selectListing' });
		this.props.vm.userActionChannel.put(new VirtualTerminalUserAction.ListingChange(listingId));
	}
}

const QboEnableWarning = observer(({ batchEntryUrl, paymentLabel }) => (
	<TipBox storageKey={storageKey} displayTimes={3} dismissable>
		<div className={styles.warning}>
			For {paymentLabel.NounPluralLowerCase} to be sent to QuickBooks&reg; Online, you will need to use <a className="arrow-hollow-right-after" href={batchEntryUrl}>Batch Entry</a>
		</div>
	</TipBox>
));


export const VirtualTerminalLeadMessage = observer(({
	paymentLabel,
	listingStore
}: { paymentLabel: PaymentLabel,
	listingStore: VirtualTerminalListingStore
}) => {
	const supportsCash = listingStore.selectedListingSupportsCash;

	return (
		<p className={`lead ${styles.lead}`}>
			{`Enter a ${paymentLabel.NounLowerCase} by searching for an existing ${paymentLabel.PayerLowerCase}`}
			{supportsCash && <VirtualTerminalAnonLink supportsCash={supportsCash} />}
			{listingStore.selectedListingSupportsRecordedCheck && `, reading a ${checkNounLowerCase(listingStore.selectedListing.HomeCountry)}`}
			{listingStore.selectedListingCanAddNewMember && ` or adding a new member`}
		</p>
	);
});

@inject('userActionChannel')
@observer
export class VirtualTerminalAnonLink extends React.Component<{
	userActionChannel?: Channel<VirtualTerminalUserAction>,
	supportsCash: boolean
}, {}> {
	render() {
		const { supportsCash } = this.props;
		if (!supportsCash) {
			return null;
		}
		return (
			<span>
				{`, typing `}
				<button type="button"
						className={`btn btn-tertiary ${styles.btnAnon}`}
						onClick={this.handleSelectAnonymousPayer}
						data-pp-at-target="anonymous button">'anon'</button>
			</span>
		);
	}

	private handleSelectAnonymousPayer = () => {
		this.props.userActionChannel.put(new VirtualTerminalUserAction.SelectAnonymousPayer());
	}
}
