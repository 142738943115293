import * as React from 'react';
import { observer } from 'mobx-react';
import { ValidatableForm } from '../../components/form-controls/validatable-form';
import { FormControlLabelled, FormControlType, IFormControlLabelledTooltipProps } from '../../components/form-controls/form-control-labelled';
import { Link } from '../../components/link/link';
import { BrandingSettingsFormViewModel, BrandingSettingsFormField } from './branding-settings-form-view-model';
import { BrandingSettingsUserAction } from '../saga-actions/actions';
import { ImageKind } from '../branding-settings-generated';
import { ModelMetadata } from '../branding-settings-generated';
import { Checkbox } from '../../../Shared/forms/primitives/checkbox'
import { IFileSizeValidationRule, IFileTypeValidationRule, IValidationRules, IImageDimensionValidationRule } from '../../validation/validation-rules';

import * as styles from './branding-settings-form.less';

@observer
export class BrandingSettingsForm extends React.Component<{
	vm: BrandingSettingsFormViewModel;
	selectedListingName: string;
	selectedCampusName: string;
	currentOrgName: string;
	raiseAction: (action: BrandingSettingsUserAction) => void;
}> {

	render() {
		const {
			vm: {
				givingTitle,
				primaryColor,
				secondaryColor,
				heroImage,
				enhancedLogo,
				backdrop,
				favicon,
				errorMap,
				onFocusSecondaryColor,
				isCampusBrandingMerchant,
				isOrganizationBrandingMerchant,
				hasReliableOrgAndCampusBranding,
				canConfigureOrgDefaultBranding,
				supportsBackdropImages,
				setupGuideUrl
			},
			selectedListingName,
			selectedCampusName,
			currentOrgName
		} = this.props;

		return (
			<ValidatableForm acceptanceTestTargetId="branding settings form" errorMap={errorMap} className={styles.form}>
				{ hasReliableOrgAndCampusBranding && this.displayWarningMessage(isCampusBrandingMerchant, isOrganizationBrandingMerchant, selectedCampusName, currentOrgName, setupGuideUrl) }
				{
					(hasReliableOrgAndCampusBranding && selectedCampusName) &&
					<div>
						<Checkbox label="Set as default Organization branding" checked={isOrganizationBrandingMerchant} acceptanceTestTargetId="Editor organization branding checkbox" onChange={this.handleOrganizationCheckboxChange} disabled={!canConfigureOrgDefaultBranding} />
						<Checkbox label="Set as default Campus branding" checked={isCampusBrandingMerchant} acceptanceTestTargetId="Editor campus branding checkbox" onChange={this.handleCampusCheckboxChange} />
					</div>
				}
				<FormControlLabelled
					label="Page title"
					cssClassNames={styles.item}
					formControlProps={{
						formControlType: FormControlType.Text,
						type: 'text',
						value: givingTitle,
						name: ModelMetadata.BrandingPackageEditModel.GivingTitle.propertyName,
						placeholder: selectedListingName && `e.g. Welcome to ${selectedListingName}`,
						onChangeHandler: this.handleGivingTitleChange,
						acceptanceTestTargetId: 'GivingTitle',
						maxLength: 40,
						showInputCounts: true,
						validationRules: {
							length: {
								errorMessage: 'Page title must be shorter than 40 characters',
								parameters: {
									max: 40,
								},
							},
						},
					}}
				/>
				<div className={`${styles.colorGroup} ${styles.item}`}>
					<div className={`${styles.colorControlContainer}`}>
						<FormControlLabelled
							label="Primary Color"
							tooltipOptions={this.generateTooltipOptions('This is your main color, which will be used for buttons and tabs. For best results,' +
								' please select a dark color.')}
							formControlProps={{
								formControlType: FormControlType.Color,
								value: primaryColor || '',
								onChangeHandler: this.handlePrimaryColorChange,
								name: ModelMetadata.BrandingPackageEditModel.PrimaryColor.propertyName,
								acceptanceTestTargetId: 'Primary Color',
								validationRules: colorValidationRules,
								validateOnBlur: false,
								validateOnChange: true,
								revalidateOnBlur: false,
								revalidateOnChange: false,
								cssClassNames: `${styles.primaryColorContainer}`,
							}}
						/>
					</div>
					<div className={`${styles.colorControlContainer}`}>
						<FormControlLabelled
							label="Secondary Color"
							tooltipOptions={this.generateTooltipOptions('Your secondary color is used for header bars and will substitute your background image ' +
								'if no image is selected. For best results, please select a dark color.')}
							formControlProps={{
								formControlType: FormControlType.Color,
								value: secondaryColor || '',
								onChangeHandler: this.handleSecondaryColorChange,
								name: ModelMetadata.BrandingPackageEditModel.SecondaryColor.propertyName,
								acceptanceTestTargetId: 'Secondary Color',
								validationRules: colorValidationRules,
								validateOnBlur: false,
								validateOnChange: true,
								revalidateOnBlur: false,
								revalidateOnChange: false,
								onFocusHandler: onFocusSecondaryColor,
							}}
						/>
					</div>
				</div>
				{supportsBackdropImages && (
					<FormControlLabelled
						label="Full background image (Default)"
						description="Selecting a full background image takes priority over the banner image."
						tooltipOptions={this.generateTooltipOptions(
							'Please select an image at least 1920 pixels in width and at least 1080 pixels in height.'
						)}
						cssClassNames={styles.item}
						hideErrors={true}
						formControlProps={{
							formControlType: FormControlType.Image,
							fileCurrent: backdrop,
							onChangeHandler: this.handleBackdropImageChange,
							name: ModelMetadata.BrandingPackageEditModel.BackdropImageKey.propertyName,
							acceptanceTestTargetId: 'Backdrop Image Upload',
							containerClassNames: supportsBackdropImages ? styles.backgroundContained : styles.background,
							validationRules: {
								filetype: getImageFileTypeValidationRule(ImageKind.BackdropImage),
								filesize: fileSizeValidationRule,
								imagedimension: getImageValidationRules(ImageKind.BackdropImage),
							},
						}}
					/>
				)}
				<FormControlLabelled
					label={supportsBackdropImages ? "Banner Image" : "Background Image" }
					description="Select a landscape image."
					tooltipOptions={this.generateTooltipOptions('Please select an image at least 1500 pixels in width and at least 420 pixels in height.')}
					cssClassNames={styles.item}
					hideErrors={true}
					formControlProps={{
						formControlType: FormControlType.Image,
						fileCurrent: heroImage,
						onChangeHandler: this.handleHeroImageChange,
						name: ModelMetadata.BrandingPackageEditModel.HeroImageKey.propertyName,
						acceptanceTestTargetId: 'Hero Image Upload',
						containerClassNames: supportsBackdropImages ? styles.backgroundContained : styles.background,
						validationRules: {
							filetype: getImageFileTypeValidationRule(ImageKind.HeroImage),
							filesize: fileSizeValidationRule,
							imagedimension: getImageValidationRules(ImageKind.HeroImage)
						}
					}}
				/>
				<FormControlLabelled
					label="Branded logo image"
					description="Select a landscape logo image."
					tooltipOptions={this.generateTooltipOptions('Please select a PNG format image with a transparent background at least 40 pixels in width and height. Wide images work best.')}
					cssClassNames={styles.item}
					hideErrors={true}
					formControlProps={{
						formControlType: FormControlType.Image,
						fileCurrent: enhancedLogo,
						onChangeHandler: this.handleEnhancedLogoChange,
						name: ModelMetadata.BrandingPackageEditModel.EnhancedLogoImageKey.propertyName,
						acceptanceTestTargetId: 'Enhanced Logo Image Upload',
						containerClassNames: styles.brandLogo,
						validationRules: {
							filetype: getImageFileTypeValidationRule(ImageKind.EnhancedLogo),
							filesize: fileSizeValidationRule,
							imagedimension: getImageValidationRules(ImageKind.EnhancedLogo)
						}
					}}
				/>
				<FormControlLabelled
					label="Square logo"
					description="The square logo will be shown as the icon in the browser tab."
					tooltipOptions={this.generateTooltipOptions('Please select a square image at least 200 pixels in width and height.')}
					cssClassNames={styles.item}
					hideErrors={true}
					formControlProps={{
						formControlType: FormControlType.Image,
						fileCurrent: favicon,
						onChangeHandler: this.handleFaviconLogoChange,
						name: ModelMetadata.BrandingPackageEditModel.FaviconImageKey.propertyName,
						acceptanceTestTargetId: 'Favicon Image Upload',
						containerClassNames: styles.squareLogo,
						validationRules: {
							filetype: getImageFileTypeValidationRule(ImageKind.Favicon),
							filesize: fileSizeValidationRule,
							imagedimension: getImageValidationRules(ImageKind.Favicon)
						}
					}}
				/>
			</ValidatableForm>
		);
	}

	displayWarningMessage = (
		isCampusBrandingMerchant: boolean,
		isOrganizationBrandingMerchant: boolean,
		campusName: string,
		orgName: string,
		learnMoreUrl: string
		) => {
		const isCampusBrandingOnly = isCampusBrandingMerchant && !isOrganizationBrandingMerchant;
		const isOrgBrandingOnly = !isCampusBrandingMerchant && isOrganizationBrandingMerchant;
		const isOrgAndCampusBranding = isCampusBrandingMerchant && isOrganizationBrandingMerchant;

		return ((isCampusBrandingMerchant || isOrganizationBrandingMerchant) &&
			<div className="alert alert-highlight alert-warning" role="alert">
				<svg className="alert-icon" height="24" width="24"><use xlinkHref="#alert-icon-warning"></use></svg>
				<span>
					<strong>Note:</strong><br />
					{ isCampusBrandingOnly && <>This is the default listing for {campusName}. Branding changes you make here will be reflected across all listings in {campusName} unless they have their own branding. </> }
					{ isOrgBrandingOnly && <>This is the default listing for {orgName}. Branding changes you make here will be reflected across all listings in {orgName} unless they have their own branding. </> }
					{ isOrgAndCampusBranding && <>This is the default listing for {campusName} and {orgName}. Branding changes you make here will be reflected across all listings in {campusName} and {orgName} unless they have their own branding. </> }
					<Link acceptanceTargetId="Default branding warning learn more" href={learnMoreUrl} className={"alert-link focus"} target="_blank" rel="noopener noreferrer">Learn More</Link>.
				</span>
			</div>
		);
	}

	generateTooltipOptions = (message: string): IFormControlLabelledTooltipProps => {
		return {
			message: message,
			placement: 'top',
			tooltipContentClassName: styles.tooltipInner
		};
	}

	handleGivingTitleChange = (event: React.FormEvent<HTMLInputElement>) => {
		this.props.raiseAction(new BrandingSettingsUserAction.FormDataChange(BrandingSettingsFormField.GivingTitle, event.currentTarget.value));
	}

	handlePrimaryColorChange = (event: React.FormEvent<HTMLInputElement>) => {
		this.props.raiseAction(new BrandingSettingsUserAction.FormDataChange(BrandingSettingsFormField.PrimaryColor, event.currentTarget.value));
	}

	handleSecondaryColorChange = (event: React.FormEvent<HTMLInputElement>) => {
		this.props.raiseAction(new BrandingSettingsUserAction.FormDataChange(BrandingSettingsFormField.SecondaryColor, event.currentTarget.value));
	}

	handleHeroImageChange = (file: File | null) => {
		this.props.raiseAction(new BrandingSettingsUserAction.FormImageFieldChange(ImageKind.HeroImage, file));
	}

	handleEnhancedLogoChange = (file: File | null) => {
		this.props.raiseAction(new BrandingSettingsUserAction.FormImageFieldChange(ImageKind.EnhancedLogo, file));
	}

	handleFaviconLogoChange = (file: File | null) => {
		this.props.raiseAction(new BrandingSettingsUserAction.FormImageFieldChange(ImageKind.Favicon, file));
	}

	handleOrganizationCheckboxChange = (isChecked: boolean) => {
		this.props.raiseAction(new BrandingSettingsUserAction.FormDataChange(BrandingSettingsFormField.OrganizationBranding, String(isChecked)));
	}

	handleCampusCheckboxChange = (isChecked: boolean) => {
		this.props.raiseAction(new BrandingSettingsUserAction.FormDataChange(BrandingSettingsFormField.CampusBranding, String(isChecked)));
	}

	handleBackdropImageChange = (file: File | null) => {
		this.props.raiseAction(new BrandingSettingsUserAction.FormImageFieldChange(ImageKind.BackdropImage, file));
	}
}

export function getImageValidationRules(imageKind: ImageKind): IImageDimensionValidationRule {
	switch (imageKind) {
		case ImageKind.HeroImage:
			return {
				errorMessage: getValidationMessage(imageKind),
				parameters: {
					minheight: 420,
					minwidth: 1500,
				}
			};
		case ImageKind.EnhancedLogo:
			return {
				errorMessage: getValidationMessage(imageKind),
				parameters: {
					minwidth: 40,
					minheight: 40,
				}
			};
		case ImageKind.Favicon:
			return {
				errorMessage: getValidationMessage(imageKind),
				parameters: {
					minwidth: 200,
					minheight: 200,
				}
			};
		case ImageKind.BackdropImage:
			return {
				errorMessage: getValidationMessage(imageKind),
				parameters: {
					minheight: 1080,
					minwidth: 1920,
				}
			};
	}
}

const colorValidationRules = {
	length: {
		errorMessage: 'Invalid hex color code, e.g. #FFFFFF.',
		parameters: {
			min: 7,
			max: 7,
		},
	},
	required: {
		errorMessage: 'Select a color.',
	},
	regex: {
		errorMessage: 'Invalid hex color code, e.g. #FFFFFF.',
		parameters: {
			pattern: '^#[0-9a-fA-F]{6}$',
		},
	},
	colorcontrast: {
		errorMessage: 'Select a darker color.',
		parameters: {
			ratio: '3', // 3:1, WCAG AA Large Text
			against: '#ffffff',
		},
	},
} as IValidationRules;

const maxImageFileSize: number = 25; // Maximum image file size (mb).

function getImageFileTypeValidationRule(imageKind: ImageKind): IFileTypeValidationRule {
	return {
		errorMessage: getValidationMessage(imageKind),
		parameters: {
			types: ['image/jpeg', 'image/png'],
		}
	};
}

export function getValidationMessage(imageKind: ImageKind) {
	switch (imageKind) {
		case ImageKind.EnhancedLogo:
			return 'Please upload an image in .png format, at least 40px in width by 40px in height. Square or wide horizontal logos work best.';
		case ImageKind.Favicon:
			return 'Please upload a square image in .png format, at least 200px in width by 200px in height.';
		case ImageKind.HeroImage:
			return 'Please upload a rectangle image in .jpg or .png format, at least 1500px in width by 420px in height.';
		case ImageKind.BackdropImage: 
			return 'Please upload a rectangle image in .jpg or .png format, at least 1920px in width by 1080px in height.';
	}
}

const fileSizeValidationRule: IFileSizeValidationRule = {
	errorMessage: `Select an image no larger than ${maxImageFileSize}MB.`,
	parameters: {
		maxsize: maxImageFileSize,
	}
};
