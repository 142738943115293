import * as React from 'react';

export interface IFormControlLabelProps {
	formControlId: string;
	className?: string;
}

export class FormControlLabel extends React.Component<IFormControlLabelProps, {}> {
	render() {
		const { children, formControlId, className } = this.props;
		let classNames = [ 'form-control-label' ];

		if (className) {
			classNames.push(className);
		}

		return <label className={classNames.join(' ')} htmlFor={formControlId}>{children}</label>;
	}
}
