import { toJS } from 'mobx';

/**
 * Helper class to manage location history and state.
 */
export class LocationHistoryHelper<T> {
	private currentState: T;
	private readonly stateListener: (state:T) => void;
	private readonly  queryParamConverter: QueryParamConverter<T>;

	/**
	 * @param {QueryParamConverter<T>} queryParamConverter Class to convert queryParams to state object and state object to query params
	 * @param {(state: T) => void} stateChangeListener Method to call when the location state changes (typically on page load)
	 */
	constructor(queryParamConverter: QueryParamConverter<T>, stateChangeListener: (state:T) => void) {
		this.queryParamConverter = queryParamConverter;
		this.currentState = this.locationHasQueryString() ? this.queryParamConverter.queryToState(this.getQueryString()) : history.state;
		this.replaceState(this.currentState);
		this.stateListener = stateChangeListener;
		window.onpopstate = (ev) => this.stateChanged(ev);
	}

	/**
	 * Adds new state to history
	 * @param {T} newState to current state to persist
	 * @param {string} pageUrl (optional) the page URL to persist as the location
	 */
	pushState(newState: T, pageUrl: string = ''): void {
		this.currentState = toJS(newState);
		const queryParams = this.queryParamConverter.stateToQuery(this.currentState);
		const oldQueryString = window.location.search;
		const newQueryString = `?${queryParams}`;
		const oldPathName = window.location.pathname;
		const pathNamesMatch = pageUrl === '' || oldPathName === pageUrl;

		if(oldQueryString !== newQueryString || !pathNamesMatch) {
			history.pushState(this.currentState, '', this.convertStateToGetParams(pageUrl, queryParams));
		}
	}

	/**
	 * Replaces current state in history to new state
	 * @param {T} newState to current state to persist
	 * @param {string} pageUrl (optional) the page URL to persist as the location
	 */
	replaceState(newState: T, pageUrl: string = ''): void {
		this.currentState = toJS(newState);
		const queryString = this.queryParamConverter.stateToQuery(this.currentState);
		history.replaceState(this.currentState, '', this.convertStateToGetParams(pageUrl, queryString));
	}

	/**
	 * Returns the current location history state
	 * @returns {T} the current state
	 */
	getState(): T {
		return this.currentState;
	}

	private stateChanged = (ev: PopStateEvent) => {
		this.currentState = ev.state;
		this.stateListener(this.currentState);
	}

	private convertStateToGetParams(url: string, queryParams: string): string {
		return `${url}?${queryParams}`;
	}

	private getQueryString(): string {
		const rawSearch = window.location.search;
		if(rawSearch.length > 0 && rawSearch.indexOf('?') === 0) {
			return rawSearch.substr(1); // remove question mark
		}
		return rawSearch;
	}

	private locationHasQueryString = () => {
		return this.getQueryString() !== '';
	}
}

/**
 * Interface to provide translation between query params and state
 */
export interface QueryParamConverter<T> {
	/**
	 * @param {string} queryString The query string to convert
	 * @returns {T} The state object that the query string represents
	 */
	queryToState: (queryString: string) => T;

	/**
	 * @param {T} state The current state
	 * @returns {string} The query string that represents the passed in state
	 */
	stateToQuery: (state: T) => string;
}
