import * as React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { classNames } from '../../../../Shared/utils/classnames';
import { Tooltip, TooltipContent } from '../../../components/tooltip/tooltip';
import { SvgWrapper } from '../../../components/svg-wrapper';

import * as styles from './header.less';

export interface IBrandingSettingHeaderProps {
	givingPageUrl: string;
	setupGuideUrl: string;
}

@observer
export class BrandingSettingHeader extends React.Component<IBrandingSettingHeaderProps, {}> {
	@observable
	private visible: boolean;

	render() {
		const { givingPageUrl, setupGuideUrl } = this.props;
		const setupGuideTooltipMessage = 'Help on creating effective branding for your giving page.';
		return (
			<div>
				<h1 className={styles.header}>Customize your brand</h1 >
				<div className={styles.summaryWrapper} >
					<p className={classNames('lead', styles.selfServiceSummary)}>
						Upload your brand assets and create the right look for your <a href={givingPageUrl} data-pp-at-target="giving-page" target="_blank"
							rel="noopener noreferrer">giving page</a>.
					</p>
					<div className={classNames('lead', styles.setupWrapper)}>
						<Tooltip visible={this.visible}
							onVisibleChange={this.handleVisibleChange}
							tooltipClassName={styles.centerAlignTooltip}
							placement="top"
							tooltipContentClassName={styles.tooltipContent}
							label="setup guide" >
							<SvgWrapper svg="icon-info" className={`icon tooltip-icon`} />
							<TooltipContent>
								<div>{setupGuideTooltipMessage}</div>
							</TooltipContent>
						</Tooltip>
						<a href={setupGuideUrl} data-pp-at-target="setup-guide" target="_blank" rel="noopener noreferrer">Setup guide</a>
					</div>
				</div >
			</div >
		);
	}

	private handleVisibleChange = (visible: boolean) => {
		this.visible = visible;
	}
}
