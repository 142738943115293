import * as React from 'react';
import { MemberModalWrapper } from '../components/member-modal-wrapper';
import { MemberFormViewModel } from '../view-models/member-form';
import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import {
	ModalDialogCommander,
	StandardErrorMessage,
	StandardErrorTitle
} from '../../components/modal-dialog-commander';
import { CheckDepositApiConfig, CheckDepositDataServiceAction } from '../../check-deposit/check-deposit-data-service';
import { Models as GeneratedModels } from '../../check-deposit/check-deposit-generated';
import CommunityMemberQueryResult = GeneratedModels.CommunityMemberQueryResult;
import { IDataServiceActionSubscriber, IApiConfig, DataServiceActionSubscriberFactory } from '../../utils/data-service';

export type AddCommunityMemberActionSubscriberType = IDataServiceActionSubscriber<CheckDepositApiConfig, 'addCommunityMember'>;

export interface IAddMemberFormProps {
	fullName?: string;
	addMemberActionSubscriberFactory?: DataServiceActionSubscriberFactory<IApiConfig, keyof IApiConfig['actions']>;
	onCommunityMemberAdded?: (member: CommunityMemberQueryResult) => void;
	MerchantId: number;
	DefaultCountry: GeneratedModels.Country;
	enableFeatureOrganizationalGiving: boolean;
}

export class AddMemberFormViewModel extends MemberFormViewModel {
	@observable merchantId: number;
	@observable addMemberActionSubscriber: AddCommunityMemberActionSubscriberType;

	private onCommunityMemberAdded: (member: CommunityMemberQueryResult) => void;

	constructor(addMemberDataServiceActionSubscriberFactory: (action) => AddCommunityMemberActionSubscriberType) {
		super();
		this.addMemberActionSubscriber = addMemberDataServiceActionSubscriberFactory((action) => {
			this.subscribeToAddCommunityMember(action);
		});
	}

	@action
	setOnCommunityMemberAdded = (onCommunityMemberAdded: (member: CommunityMemberQueryResult) => void) => {
		this.onCommunityMemberAdded = onCommunityMemberAdded;
	}

	@action
	initRequestModel = (defaultCountry: GeneratedModels.Country, merchantId: number) => {
		this.merchantId = merchantId;
		this.request = {
			Address: {
				Line1: null,
				Line2: null,
				City: null,
				State: null,
				Postcode: null,
				DisplayAddress: null,
				PostalCodeLabel: null,
				Country: defaultCountry
			},
			EmailAddress: null,
			FirstName: null,
			LastName: null,
			CommunityMemberId: null,
			LockVersion: null,
			MobileNumber: null,
			CommunityMemberType: GeneratedModels.CommunityMemberType.Individual
		} as GeneratedModels.EditCommunityMemberRequestModel;
	}

	@action
	setMerchantId = (merchantId: number) => {
		this.merchantId = merchantId;
	}

	@action
	setDefaultCountry = (defaultCountry: GeneratedModels.Country) => {
		this.request.Address.Country = defaultCountry;
	}

	@action
	setNames = (fullName: string) => {
		if (!fullName) {
			return;
		}
		fullName = fullName.trim();
		const lastWhiteSpaceIndex = fullName.lastIndexOf(' ');
		if (lastWhiteSpaceIndex === -1) {
			this.request.FirstName = fullName;
		} else {
			this.request.FirstName = fullName.substr(0, lastWhiteSpaceIndex);
			this.request.LastName = fullName.substr(lastWhiteSpaceIndex + 1);
		}
	}

	@action
	addNewCommunityMember = () => {
		this.addMemberActionSubscriber.initRequest({ merchantId: this.merchantId, model: this.request });
	}

	@action
	destroy = () => {
		this.addMemberActionSubscriber.destroy();
	}

	@action
	private subscribeToAddCommunityMember = (action: CheckDepositDataServiceAction) => {
		switch(action.type) {
			case 'request_success':
				if (typeof this.onCommunityMemberAdded === 'function') {
					this.onCommunityMemberAdded(action.response);
				}
				ModalDialogCommander.forceCloseCurrent();
				return;
			case 'request_error':
				if (action.error.validationErrors) {
					this.errorMessages = Object.getOwnPropertyNames(action.error.validationErrors).map(x => action.error.validationErrors[x]);
					this.validationErrors = action.error.validationErrors;
					//show the default validation error summary title
					this.errorSummaryTitle = '';
				} else {
					this.errorMessages = [ StandardErrorMessage ];
					this.errorSummaryTitle = StandardErrorTitle;
				}
				return;
		}
	}

	@computed
	get isProcessingRequest() {
		return this.addMemberActionSubscriber.isProcessingRequest;
	}
}

@observer
export class AddMemberFormContainer extends React.Component<IAddMemberFormProps, {}> {
	private vm: AddMemberFormViewModel;

	UNSAFE_componentWillMount() {
		const { MerchantId: merchantId, DefaultCountry: defaultCountry, onCommunityMemberAdded, addMemberActionSubscriberFactory, fullName } = this.props;
		this.vm = new AddMemberFormViewModel(addMemberActionSubscriberFactory);
		this.vm.initRequestModel(defaultCountry, merchantId);
		this.vm.setOnCommunityMemberAdded(onCommunityMemberAdded);
		this.vm.setNames(fullName);
	}

	UNSAFE_componentWillReceiveProps(nextProps: IAddMemberFormProps) {
		const { MerchantId: merchantId, DefaultCountry: defaultCountry, onCommunityMemberAdded, fullName } = nextProps;
		if (nextProps.MerchantId !== merchantId) {
			this.vm.setMerchantId(merchantId);
		}
		if (nextProps.DefaultCountry !== defaultCountry) {
			this.vm.setDefaultCountry(defaultCountry);
		}
		if (nextProps.onCommunityMemberAdded !== onCommunityMemberAdded) {
			this.vm.setOnCommunityMemberAdded(onCommunityMemberAdded);
		}
		if (nextProps.fullName !== fullName) {
			this.vm.setNames(fullName);
		}
	}

	componentWillUnmount() {
		this.vm.destroy();
		this.vm = null;
	}

	render() {
		return(
			<MemberModalWrapper memberFormViewModel={this.vm} submitHandler={this.vm.addNewCommunityMember}>
				{this.props.children}
			</MemberModalWrapper>
		);
	}
}
