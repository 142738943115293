import { observable, action } from 'mobx';
import { BatchListItemViewModel, BatchStatus, PaymentLabel } from '../../../virtual-terminal-generated';

export class BatchListItemProps {
	@observable isSelected : boolean = false;

	constructor(public vm: BatchListItemViewModel, public hasQuickBooks: boolean, isSelected: boolean) {
		this.isSelected = isSelected;
	}

	@action
	setIsSelected(state: boolean) {
		this.isSelected = state;
	}
}
