import { states, events, actions, actionType, eventType } from '../../../../Shared/state-machine/states-events-and-actions';
import { MachineContext } from '../../../../Shared/state-machine/saga-state-machine';
import { CampaignDetailsMainViewModel } from './campaign-details-main-view-model';
import { generateCursor, getCampaignsSagaDataService } from '../../campaigns-data-service';
import { DeletePledgeRequest, GetPledgesRequest } from '../../campaigns-generated';

export const TABLE_PAGE_SIZE = 20;

enum CampaignDetailsStates {
	Init,
	Idle,
	FetchingPledges,
	ConfirmPledgeDelete,
	DeletingPledge,
	Error,
}

export const States = states(CampaignDetailsStates);

export const Events = events({
	FetchPledgeData: eventType<typeof Actions.dataTypes.FetchPledges>(),
	Success: eventType<void>(),
	Delete: eventType<void>(),
	DeleteConfirmed: eventType<typeof Actions.dataTypes.DeletePledge>(),
	Cancel: eventType<void>(),
	Error: eventType<void>(),
	CloseErrorModal: eventType<void>(),
});

export const Actions = actions({
	Start: actionType<void>(function *(machineContext: MachineContext<CampaignDetailsMainViewModel>) {
		const { mainDetailsViewModel: { CampaignId }, overviewTabViewModel: { cursorOffset, searchQuery } } = machineContext.viewModel;
		const pledgeSearchRequest: GetPledgesRequest = {
			CampaignId: CampaignId,
			Cursor: generateCursor(cursorOffset),
			NumberOfRecords: TABLE_PAGE_SIZE,
			Query: searchQuery,
		};

		yield doInitialFetch(machineContext, pledgeSearchRequest);
	}),
	FetchPledges: actionType<GetPledgesRequest>(function *(machineContext: MachineContext<CampaignDetailsMainViewModel>, searchRequest) {
		yield doFetch(machineContext, searchRequest);
	}),
	DeletePledge: actionType<DeletePledgeRequest>(function *(machineContext: MachineContext<CampaignDetailsMainViewModel>, request) {
		yield doDelete(machineContext, request);
	}),
});

const doInitialFetch = function *(machineContext: MachineContext<CampaignDetailsMainViewModel>, pledgeSearchRequest: GetPledgesRequest) {
	try {
		const resp = yield getCampaignsSagaDataService().getCampaignOverview({ pledgeSearchRequest });
		machineContext.viewModel.overviewTabViewModel.setInitialData(resp);
		Events.raise.Success(machineContext);
	} catch {
		Events.raise.Error(machineContext);
	}
};

const doFetch = function *(machineContext: MachineContext<CampaignDetailsMainViewModel>, searchRequest: GetPledgesRequest) {
	try {
		const resp = yield getCampaignsSagaDataService().getPledgeData({ searchRequest });
		machineContext.viewModel.overviewTabViewModel.setData(resp);
		Events.raise.Success(machineContext);
	} catch {
		Events.raise.Error(machineContext);
	}
};

const doDelete = function *(machineContext: MachineContext<CampaignDetailsMainViewModel>, request: DeletePledgeRequest) {
	try {
		const resp = yield getCampaignsSagaDataService().deletePledge({ request });
		machineContext.viewModel.overviewTabViewModel.setDataAfterDelete(resp);
		Events.raise.Success(machineContext);
	} catch {
		Events.raise.Error(machineContext);
	}
};
