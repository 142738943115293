import * as React from 'react';
import { observer } from 'mobx-react';
import { EventTimeAddEditDialogViewModel } from './event-time-add-edit-dialog-view-model';
import { FormControlLabelled, FormControlType } from '../form-controls/form-control-labelled';
import { RadioLayoutType } from '../form-controls/form-control-radio';
import { ModalDialogForm } from '../modal-dialog-form';
import { EnumHelper } from '../../helpers/enumhelper';
import { Button } from '../button';
import { DayOfWeekAbbreviated, EventTimeCreateRequest, ModelMetadata, EventTimeEditRequest } from '../../loggedinweb-generated';
import { Fragment } from '../../../Shared/components/fragment';
import { SvgWrapper } from '../../../Shared/components/svg-wrapper';
import { ModalDialogProcessingViewModel } from '../modal-dialog/modal-dialog-processing-view-model';

import * as styles from './event-time-dialog.less';

export interface IEventTimeAddDialogProps {
	vm: EventTimeAddEditDialogViewModel;
	onSubmitHandler: (request: EventTimeCreateRequest) => void;
	modalDialogProcessingViewModel: ModalDialogProcessingViewModel;
}

@observer
export class EventTimeAddDialog extends React.Component<IEventTimeAddDialogProps> {
	render() {
		return (
			<EventTimeAddEditDialog {...this.props}
				submissionVerb="Add"
				headerVerb="Add"
				modelMetadata={ModelMetadata.EventTimeCreateRequest}
				onSubmitHandler={this.onSubmitHandler} />
		);
	}

	private onSubmitHandler = () => {
		const { name, dayOfWeek, startTime, endTime, eventTime } = this.props.vm;
		const request = { Name: name, DayOfWeek: dayOfWeek, StartTime: startTime, EndTime: endTime };
		this.props.onSubmitHandler(request);
	}
}

export interface IEventTimeEditDialogProps {
	vm: EventTimeAddEditDialogViewModel;
	onSubmitHandler: (request: EventTimeEditRequest) => void;
	onCloseEventHandler: () => void;
	modalDialogProcessingViewModel: ModalDialogProcessingViewModel;
}

@observer
export class EventTimeEditDialog extends React.Component<IEventTimeEditDialogProps> {
	render() {
		const { onCloseEventHandler, modalDialogProcessingViewModel: { isProcessing } } = this.props;

		return (
			<EventTimeAddEditDialog {...this.props}
				submissionVerb="Update"
				headerVerb="Edit"
				modelMetadata={ModelMetadata.EventTimeEditRequest}
				onSubmitHandler={this.onSubmitHandler}
			>
				<ArchiveServiceTimeButton isProcessing={isProcessing} onCloseEvent={onCloseEventHandler} />
			</EventTimeAddEditDialog>
		);
	}

	private onSubmitHandler = () => {
		const { name, dayOfWeek, startTime, endTime, id, token, eventTime } = this.props.vm;
		const request = { Name: name, DayOfWeek: dayOfWeek, StartTime: startTime, EndTime: endTime, Id: id, Token: token };
		this.props.onSubmitHandler(request);
	}
}

type CreateRequestMetaData = {
	[P in keyof typeof ModelMetadata.EventTimeCreateRequest]: typeof ModelMetadata.EventTimeCreateRequest[P];
};

type EditRequestMetaData = {
	[P in keyof typeof ModelMetadata.EventTimeEditRequest]: typeof ModelMetadata.EventTimeEditRequest[P];
};

export type IEventTimeAddEditDialogProps = {
	vm: EventTimeAddEditDialogViewModel;
	modalDialogProcessingViewModel: ModalDialogProcessingViewModel;
	submissionVerb: string;
	headerVerb: string;
	modelMetadata: CreateRequestMetaData | EditRequestMetaData;
	onSubmitHandler: () => void;
};

@observer
class EventTimeAddEditDialog extends React.Component<IEventTimeAddEditDialogProps> {
	render() {
		const {
			vm: {
				eventTime,
				dayOfWeek,
				startTime,
				endTime,
				name,
				updateDayOfWeek,
				validationSummaryTitle,
				validationErrors,
				updateStartTime,
				updateEndTime,
				merchantNames,
				hasMerchantNames,
			},
			modalDialogProcessingViewModel: {
				isProcessing
			},
			submissionVerb,
			headerVerb,
			modelMetadata: metadata,
			onSubmitHandler,
		} = this.props;

		const buttons = [
			<Button key="cancel"
				className="btn btn-cancel"
				type="button"
				data-dismiss="modal"
				disabled={isProcessing}
				acceptanceTestTargetId="Add Service Time Cancel Button">Cancel</Button>,
			<Button key="submit"
				className="btn btn-default"
				type="submit"
				isProcessingRequest={isProcessing}
				acceptanceTestTargetId="Add Service Time Add Button">{submissionVerb}</Button>
		];

		return (
			<ModalDialogForm heading={`${headerVerb} service time`}
				buttons={buttons}
				onSubmitHandler={onSubmitHandler}
				validationSummaryTitle={validationSummaryTitle}
				validationErrors={validationErrors}
				dataModalPreventClose={isProcessing}
				hideClose
			>
				<div className="row">
					<FormControlLabelled cssClassNames="col-xs-12"
						label="Day of week"
						formControlProps={{
							formControlType: FormControlType.Radio,
							layoutType: RadioLayoutType.Grid,
							radioItemClassName: 'flex-auto',
							Options: EnumHelper.MapRadioGridOptions(DayOfWeekAbbreviated),
							name: metadata.DayOfWeek.propertyName,
							value: `${dayOfWeek}`,
							onChangeHandler: updateDayOfWeek,
							acceptanceTestTargetId: 'Day of week'
						}} />
				</div>
				<div className="row">
					<FormControlLabelled cssClassNames="col-sm-4"
						label="Start from"
						formControlProps={{
							formControlType: FormControlType.TimePicker,
							name: metadata.StartTime.propertyName,
							placeholder: 'Select start',
							value: startTime,
							onChangeHandler: updateStartTime,
							validationRules: metadata.StartTime.validationRules,
							acceptanceTestTargetId: 'Start from',
							hideEmptyOption: true,
						}}
					/>
					<FormControlLabelled cssClassNames="col-sm-4"
						label="Ends"
						formControlProps={{
							formControlType: FormControlType.TimePicker,
							name: metadata.EndTime.propertyName,
							placeholder: 'Select end',
							value: endTime,
							onChangeHandler: updateEndTime,
							validationRules: metadata.EndTime.validationRules,
							acceptanceTestTargetId: 'Ends at',
							hideEmptyOption: true,
						}}
					/>
				</div>
				<div className="row">
					<FormControlLabelled cssClassNames="col-xs-12"
						label="Service name (optional)"
						formControlProps={{
							formControlType: FormControlType.Text,
							type: 'text',
							name: metadata.Name.propertyName,
							placeholder: 'e.g. Sunday Morning',
							value: name,
							onChangeHandler: this.updateName,
							maxLength: metadata.Name.validationRules.length.parameters.max,
							validationRules: metadata.Name.validationRules,
							acceptanceTestTargetId: 'Service name'
						}} />
				</div>
				{hasMerchantNames && (
					<Fragment>
						<h5 className={styles.merchantsTitle}>Visible in these listings</h5>
						<ul>
							{merchantNames.map((merchantName, i) => <li key={`merchant-${i}`}>{merchantName}</li>)}
						</ul>
					</Fragment>
				)}
				{this.props.children}
			</ModalDialogForm>
		);
	}

	private updateName = (ev: React.FormEvent<HTMLInputElement>) => {
		this.props.vm.updateName(ev.currentTarget.value);
	}
}

class ArchiveServiceTimeButton extends React.Component<{
	isProcessing: boolean,
	onCloseEvent: () => void,
}> {
	render() {
		const { isProcessing, onCloseEvent } = this.props;

		return (
			<Button className="btn btn-link btn-link-inline" type="button" disabled={isProcessing} onClick={onCloseEvent}>
				<SvgWrapper svg="icon-close-fund" className="icon" />Archive service time
			</Button>
		);
	}
}
