export class Poller {
	pollingFunction: Function;
	private maxAttempts: number;
	private pollIntervalms: number;


	private attempts = 0;

	constructor(maxAttempts: number, pollIntervalms: number) {
		this.maxAttempts = maxAttempts;
		this.pollIntervalms = pollIntervalms;

		this.poll = this.poll.bind(this);
		this.pollingFunction = this.pollingFunc.bind(this);
	}

	poll() {
		if (this.reachedMaxAttempts()) {
			this.timeoutFunc();
			return;
		}

		this.incrementAttempts();

		setTimeout(this.pollingFunction, this.pollIntervalms);
	}

	protected pollingFunc() {
		this.poll();
	}

	protected timeoutFunc() {}

	private reachedMaxAttempts(): boolean {
		if (this.maxAttempts === null) {
			return false;}
		return this.attempts >= this.maxAttempts;
	}

	private incrementAttempts() {
		this.attempts++;
	}
}
