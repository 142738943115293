import * as React from 'react';
import { observer } from 'mobx-react';
import { ServiceTimeMainViewModel } from './service-time-main-view-model';
import { Fragment } from '../../../../Shared/components/fragment';
import { classNames } from '../../../../Shared/utils/classnames';
import { Button } from '../../../components/button';
import { SvgWrapper } from '../../../components/svg-wrapper';
import { ListingViewModel, SettingsEventTimeViewModel } from '../../settings-generated';
import { ServiceTimePicker } from '../../components/service-time/service-time-picker';
import { FormControlLabelled, FormControlType } from '../../../components/form-controls/form-control-labelled';
import { runSettingsSaga } from '../../saga/settings-saga';
import { ServiceTimeSagaContext, getActionHandlers } from './service-time-saga';
import { ServiceTimeUserAction } from './service-time-user-actions';

import * as styles from './service-time-main.less';

@observer
export class ServiceTimeMain extends React.Component<{ vm: ServiceTimeMainViewModel }, {}> {
	constructor(props: { vm: ServiceTimeMainViewModel }) {
		super(props);
		const { vm } = props;
		const serviceTimeSagaContext = new ServiceTimeSagaContext(vm);

		runSettingsSaga(serviceTimeSagaContext, getActionHandlers());

		vm.setUserActionChannel(serviceTimeSagaContext.userActionChannel);
	}

	render() {
		const { listingItems, filteredListings, eventTimes, hasServiceTimes, selectedListingIds } = this.props.vm;

		return (
			<Fragment>
				<header className={styles.header}>
					<h1 className={styles.title}>Service times</h1>
					<Button
						className={classNames('btn', 'btn-sm', 'btn-link', styles.buttonAdd)}
						data-pp-at-target="add service time"
						onClick={this.handleAddServiceTime}
					>
						<SvgWrapper svg="icon-add" className="icon" /> Add service time
					</Button>
				</header>
				<div className={`row ${styles.filter}`}>
					<FormControlLabelled
						formControlProps={{
							formControlType: FormControlType.MultiSelect,
							items: listingItems,
							name: 'listing',
							selectedValues: selectedListingIds,
							onChangeHandler: this.handleFilterByListing,
							acceptanceTestTargetId: 'Listing filter',
						}}
						label="Your listing"
						cssClassNames="col-md-6"
					/>
				</div>
				{hasServiceTimes && (
					<div className="panel panel-default">
						<header className="panel-heading">
							<h2 className={styles.panelTitle}>Service time listings</h2>
						</header>
						<div className="panel-body">
							<div className="row">
								{filteredListings.map((listing: ListingViewModel) => (
									<div key={listing.ListingId} className={`col-md-6 ${styles.listing}`}>
										<ServiceTimePicker
											listing={listing}
											eventTimes={eventTimes}
											onChangeServiceTimeActivated={this.handleChangeServiceTimeActivated}
											onEditServiceTime={this.handleEditServiceTime}
										/>
									</div>
								))}
							</div>
						</div>
					</div>
				)}
			</Fragment>
		);
	}

	handleFilterByListing = (selectedListings: string[]) => {
		this.props.vm.setSelectedListingIds(selectedListings);
	}

	handleAddServiceTime = () => {
		this.props.vm.userActionChannel.put(new ServiceTimeUserAction.LaunchAddServiceTimeDialog());
	}

	handleChangeServiceTimeActivated = (eventTime: SettingsEventTimeViewModel, listingId: number, isActivated: boolean) => {
		const action = isActivated
		? new ServiceTimeUserAction.ActivateServiceTime(eventTime.Id, listingId)
		: new ServiceTimeUserAction.DeactivateServiceTime(eventTime.Id, listingId);

		this.props.vm.userActionChannel.put(action);
	}

	handleEditServiceTime = (eventTime: SettingsEventTimeViewModel) => {
		this.props.vm.userActionChannel.put(new ServiceTimeUserAction.LaunchEditServiceTimeDialog(eventTime));
	}
}
