import * as React from 'react';
import { observer } from 'mobx-react';
import { Button } from '../../../components/button';
import { PublishState } from '../../branding-settings-main-view-model';
import { classNames } from '../../../../Shared/utils/classnames';
import * as styles from './publish-button.less';

export interface IBrandingSettingPublishButtonProps {
	publishState: PublishState;
	onClick : () => void;
}

export const BrandingSettingPublishButton = observer(({ publishState, onClick }: IBrandingSettingPublishButtonProps) => (
	<Button
		className={classNames('btn btn-default', styles.publishBtn, publishState === PublishState.Pending && styles.waiting)}
		acceptanceTestTargetId="publish"
		disabled={publishState === PublishState.Unavailable}
		isProcessingRequest={publishState === PublishState.Publishing}
		onClick={onClick}>
		Publish
	</Button>
));
