import * as React from 'react';
import { inject, observer, Provider } from 'mobx-react';
import { Fragment } from '../../Shared/components/fragment';
import { MachineContext, machineContextKey, StateMachine } from '../../Shared/state-machine/saga-state-machine';
import { Match } from '../../Shared/state-machine/match';
import { ScrollToTop } from './components/scroll-to-top';
import { attachGivingStatementsMachineContext, GivingStatementsStates } from './state/main';
import { LandingPage } from './pages/landing-page';
import { GivingStatementsViewModel } from './giving-statements-view-model';
import { ConfigurePage } from './pages/configure';
import { PreviewPage } from './pages/preview';
import { SendStatementsPage } from './pages/send-statements';
import * as sharedStyles from './components/shared-styles/styles.less';
import { LoadingBox } from '../components/loading-box';
import { MatchModal } from '../components/match-modal';
import { AnalyticsContext } from '../analytics';
import { DeleteStatementsEvents, DeleteStatementsStates } from './state/delete-statements-states';
import { DeleteStatementsConfirmModal } from './components/delete-statements-modals/delete-statements-confirm-modal';
import { DeleteStatementsFailureModal } from './components/delete-statements-modals/delete-statement-failure-modal';
import { LandingPageStates } from './state/landing-page';

const analyticsContext: AnalyticsContext = {
	feature: 'givingStatements',
	subFeature: 'configureGivingStatements',
};

export class GivingStatements extends React.Component<{ vm: GivingStatementsViewModel }> {
	render() {
		const machineContext = attachGivingStatementsMachineContext(this.props.vm);

		return (
			<Provider analytics={analyticsContext}>
				<StateMachine machineContext={machineContext}>
					<GivingStatementsApp vm={this.props.vm} />
				</StateMachine>
			</Provider>
		);
	}
}

@inject(machineContextKey)
@observer
class GivingStatementsApp extends React.Component<{ machineContext?: MachineContext, vm: GivingStatementsViewModel }> {
	render() {
		const {
			vm: {
				landingPageViewModel,
				configurePageViewModel,
				previewPageViewModel,
				sendPageViewModel,
				capUrl,
			},
			machineContext,
		} = this.props;

		const showNewLandingPage = machineContext.matchesState(LandingPageStates.MAIN);

		if (showNewLandingPage) {
			window.location.href = capUrl;
		}

		return (
			<Fragment>
				<Match state={GivingStatementsStates.PAGE_LOAD}>
					<div className={sharedStyles.loadingSpinnerWrapper}>
						<LoadingBox text="Loading..." />
					</div>
				</Match>
				<Match state={GivingStatementsStates.LANDING_VIEW}>
					<ScrollToTop>
						<LandingPage vm={landingPageViewModel} showPage={!showNewLandingPage} />
					</ScrollToTop>
				</Match>
				<Match state={GivingStatementsStates.CONFIGURE_VIEW}>
					<ScrollToTop>
						<ConfigurePage vm={configurePageViewModel} />
					</ScrollToTop>
				</Match>
				<Match state={GivingStatementsStates.PREVIEW_VIEW}>
					<ScrollToTop>
						<PreviewPage vm={previewPageViewModel} />
					</ScrollToTop>
				</Match>
				<Match state={GivingStatementsStates.SEND_VIEW}>
					<ScrollToTop>
						<SendStatementsPage vm={sendPageViewModel} />
					</ScrollToTop>
				</Match>
				{
					<>
						<MatchModal state={DeleteStatementsStates.CONFIRMING}>
							<DeleteStatementsConfirmModal onConfirm={this.confirmDeleteStatements} onDismiss={this.dismissDeleteStatements} />
						</MatchModal>
						<MatchModal state={DeleteStatementsStates.FAILED}>
							<DeleteStatementsFailureModal onClose={this.closeDeleteStatementsFailureModal} />
						</MatchModal>
					</>
				}
			</Fragment>
		);
	}

	componentDidMount() {
		window.addEventListener('popstate', this.onHistoryPopState);
	}

	componentWillUnmount() {
		window.removeEventListener('popstate', this.onHistoryPopState);
	}

	private onHistoryPopState = (event: PopStateEvent) => {
		const stateMachineEvent = event.state;

		if (stateMachineEvent) {
			event.preventDefault();
			this.props.machineContext.transition(stateMachineEvent);
		} else {
			// Due to the extra state being pushed onto the stack, we may get a popstate that has no state machine event. This
			// will cause the back button to do nothing. So to avoid this, we explicitly call `back`.
			history.back();
		}
	}

	private dismissDeleteStatements = () => {
		this.props.machineContext.transition(DeleteStatementsEvents.DELETE_CANCELLATION);
	}

	private confirmDeleteStatements = () => {
		this.props.machineContext.transition(DeleteStatementsEvents.DELETE_CONFIRMATION);
	}

	private closeDeleteStatementsFailureModal = () => {
		this.props.machineContext.transition(DeleteStatementsEvents.DISMISS_DELETE_FAILURE_MODAL);
	}

}
