import { ModalDialogCommander } from '../components/modal-dialog-commander';
import {
	ActionKey,
	combineMiddleware,
	DataService,
	DataServiceAction,
	DataServiceOptions,
	IDataService,
	isActionForService
} from '../utils/data-service';
import { VirtualTerminalApiConfig } from './virtual-terminal-generated';

export type VirtualTerminalApiConfigType = typeof VirtualTerminalApiConfig;
export type VirtualTerminalDataService = IDataService<VirtualTerminalApiConfigType>;
export type VirtualTerminalDataServiceAction = DataServiceAction<VirtualTerminalApiConfigType, any>;

export type VirtualTerminalActionRequest<TActionKey extends keyof VirtualTerminalApiConfigActions> = VirtualTerminalApiConfigActions[TActionKey]['request'];
export type VirtualTerminalActionResponse<TActionKey extends keyof VirtualTerminalApiConfigActions> = VirtualTerminalApiConfigActions[TActionKey]['response'];

export type VirtualTerminalApiConfigActions = typeof VirtualTerminalApiConfig['actions'];

export function isVirtualTerminalAction<TActionKey extends ActionKey<VirtualTerminalApiConfigType>>(action: VirtualTerminalDataServiceAction, key: TActionKey):
	action is DataServiceAction<VirtualTerminalApiConfigType, TActionKey> {
	return isActionForService(action, key);
}

function reportUnexpectedError(error, action: VirtualTerminalDataServiceAction) {
	window.reportUnhandledRejection(error, { action });
}

let virtualTerminalDataService: VirtualTerminalDataService = null;

function createVirtualTerminalDataService(options?: DataServiceOptions<VirtualTerminalApiConfigType>): VirtualTerminalDataService {
	options = options || {};

	options.middleware = combineMiddleware(next => action => {
		switch (action.type) {
			case 'request_init':
				console.log(`Virtual terminal request initiated`, action);
				break;
			case 'request_success':
				console.log(`Virtual terminal request completed`, action);
				break;
			case 'request_error':
				const { error } = action;

				if (error.shouldReloadPage || error.timedout) {
					ModalDialogCommander.error(error.userError, '').done(() => {
						document.location.reload(true);
					});
					return;
				}

				console.log(`Virtual terminal request failed`, action);
				break;
			case 'request_cancel':
				console.log(`Virtual terminal request cancelled`, action);
				break;
			case 'unexpected_error':
				reportUnexpectedError(action.error, action);
				ModalDialogCommander.showStandardErrorMessage();
				break;
		}

		return next(action);
	}, options.middleware);

	return new DataService(VirtualTerminalApiConfig, options);
}

export function getVirtualTerminalDataService(): VirtualTerminalDataService {
	if (!virtualTerminalDataService) {
		virtualTerminalDataService = createVirtualTerminalDataService();
	}

	return virtualTerminalDataService;
}

export function mockVirtualTerminalDataService(mockDataService: VirtualTerminalDataService) {
	virtualTerminalDataService = mockDataService;
}
