import * as React from 'react';
import { XOR } from '@pushpay/types';
import { createNamespace } from '../../Shared/helpers/namespace';
import { SvgWrapper } from './svg-wrapper';

export type IIconInputGroupProps = {
	position: IconInputGroupPosition;
	transparent?: boolean;
} & XOR<{
	svg: string;
	title?: string;
	width: number;
	height: number;
}, {
	render: React.ReactNode;
}>;

export enum IconInputGroupPosition {
	Left,
	Right
}

const ns = createNamespace('input-group');

export const IconInputGroup: React.StatelessComponent<{options: IIconInputGroupProps}> = (props: {children?: any, options: IIconInputGroupProps}) => {
	const { children, options } = props;
	const { svg, title, width, height, render } = options;

	return (
		<div className={`${ns()} ${options.transparent ? ns('transparent') : ''} ${options.position === IconInputGroupPosition.Left ? ns('left') : ns('right')}`}>
			{children}
			{render ? render
				: <div className={ns('addon')}>
					<SvgWrapper svg={svg} title={title} width={width} height={height} />
				  </div>
			}
		</div>
	);
};
