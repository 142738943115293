import * as React from 'react';
import moment from 'moment';
import * as ReactDnD from 'react-dnd';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { noPropagate } from '../util';
import * as ViewModels from '../view-models/index';

import {
	DragDropTypes,
	IDragSourcePropsBase,
	IDropTargetPropsBase,
	dropTargetCollector,
	dragSourceCollector,
	hideDragPreview,
	classNames
} from './_common';
import { SvgWrapper } from '../../components/svg-wrapper';

import AccountRow from './account-row';
import {MemberCustomStatements} from './member-custom-statements';
import {ModalDialogCommander} from '../../components/modal-dialog-commander';

export interface IMemberContainerProps extends IDropTargetPropsBase, IDragSourcePropsBase {
	vm: ViewModels.Member;
	index: number;
	enableFeatureOrganizationalGiving: boolean;
	disableCommunityMemberDragAndDrop: boolean;
	hideGivingStatementButton: boolean;
}

@ReactDnD.DropTarget<IMemberContainerProps>([DragDropTypes.account, DragDropTypes.member], {
	drop(props, monitor) {
		DragDropTypes.dispatchDropTarget(monitor, {
			account(a) {
				props.vm.adoptAccount(a);
			},
			member(p) {
				props.vm.adoptAllAccounts(p);
			}
		});
	},
	canDrop(props: IMemberContainerProps, monitor) {
		return DragDropTypes.dispatchDropTarget<boolean>(monitor, {
			account(a) {
				return a.parent !== props.vm && !props.vm.details.IsOrganization && !a.details.IsOrganization;
			},
			member(p) {
				return p !== props.vm && !props.vm.details.IsOrganization && !p.details.IsOrganization;
			}
		});
	},
}, dropTargetCollector)

@ReactDnD.DragSource<IMemberContainerProps>(DragDropTypes.member, {
	beginDrag(props) {
		return { vm: props.vm };
	},
	canDrag(props, monitor) {
		return true;
	},
}, dragSourceCollector)

@observer
@hideDragPreview
export class MemberContainer extends React.Component<IMemberContainerProps, {}> {
	@observable
	isChildDragging: boolean = false;

	memberStatements() {
		let {vm} = this.props;
		return (
			<div className="panel-body">
				<MemberCustomStatements vm={vm}/>
			</div>);
	}

	showGivingStatements = e => {

		e.stopPropagation();

		let {vm} = this.props;

		this.props.vm.viewMemberCustomStatements().then(() => {
			ModalDialogCommander.showReactDialog(<div className="modal fade" tabIndex={-1} role="dialog">
				<div className="panel panel-default modal-dialog modal-lg">
					<button type="button" className="modal-form-close" data-dismiss="modal" aria-label="Close"></button>
					<div className="panel-heading">
						Giving statements &middot; {vm.details.FirstName} {vm.details.LastName}
					</div>
					{this.memberStatements()}
				</div>
			</div>);
		});
	}

	render() {
		let { vm, index, connectDropTarget, isOver, canDrop, connectDragSource,
				isDragging, shouldHideElementOnDragBecauseIEIsStillTheWorst,
				enableFeatureOrganizationalGiving, disableCommunityMemberDragAndDrop, hideGivingStatementButton
		} = this.props;
		let ungrouped = vm.ungroupAnimationTrigger.isAnimating;
		let highlight = (!disableCommunityMemberDragAndDrop && isOver && !isDragging && !this.isChildDragging) || ungrouped;
		let canDrag = !(disableCommunityMemberDragAndDrop || vm.details.IsOrganization);
		let expandable = !vm.details.IsOrganization;
		let expanded = expandable && vm.expanded;

		const  detailsTitle = disableCommunityMemberDragAndDrop ? '' : vm.details.IsOrganization
			? 'Organizations cannot be grouped.'
			: 'Drag a row onto another community member to group them.';
		const element = (
			<div className={classNames('member-container', {
						expanded,
						highlight,
						disableCommunityMemberDragAndDrop,
						'non-draggable': disableCommunityMemberDragAndDrop || !canDrag,
						'non-droppable': disableCommunityMemberDragAndDrop || !canDrop,
						busy: vm.busyTracker.isBusy,
						'is-dragging': isDragging
					})}
					data-pp-at-target={`MemberTableRow${index + 1}`}
					style={{ opacity: shouldHideElementOnDragBecauseIEIsStillTheWorst ? 0 : null }}>
				<div className="member-details" onClick={() => vm.toggleExpanded()} title={detailsTitle}>
					{ disableCommunityMemberDragAndDrop ||
					<div className="draghandle">
						<SvgWrapper svg="drag-handle" />
					</div>
					}
					<div className="grid-row">
						<div className="row-icon"><GetMemberIcon vm={vm} enableFeatureOrganizationalGiving={enableFeatureOrganizationalGiving} /></div>
						<div className="name">{vm.details.FirstName}</div>
						<div className="name">{vm.details.LastName}</div>
						<div className="email-address">{vm.details.EmailAddress}</div>
						<div className="mobile-number">{vm.details.MobileNumber}</div>
						<div className="address"><span title={vm.details.Address.DisplayAddress}>{vm.details.Address.DisplayAddress}</span></div>
						<div className="last-activity">{moment(vm.details.LastPaymentOnLocal).format('D MMM\' YY')}</div>
						<div className="commands">
							{hideGivingStatementButton ? null :
								<button className="icon" onClick={this.showGivingStatements} title="View the draft giving statement for this community member and access giving statement history.">
									<SvgWrapper svg="giving-statement" className="svg giving-statement-icon" />
								</button>
							}
							<button key="edit" className="icon" accessKey="e" title="Edit the details for this community member."
								onClick={noPropagate(() => vm.edit(this.props.enableFeatureOrganizationalGiving))} >
								<SvgWrapper svg="icon-edit" className="svg edit-icon" />
							</button>
							{expandable && <button key="expand" className="icon" accessKey="x">
								<span className={`arrow ${!vm.expanded ? 'expand-arrow' : 'closed-arrow'}`}
									data-pp-at-target={`MemberDetailsArrow${index + 1}`}></span>
							</button>}
						</div>
					</div>
				</div>
				{expandable && <div className="accounts-container">
					{vm.accounts.map((x, index) => <AccountRow vm={x} index={index} key={x.id}
							isDraggingCallback={this.isChildDraggingCallback}
							disableCommunityMemberDragAndDrop={disableCommunityMemberDragAndDrop}
						/>)}
				</div>}
			</div>
		);

		return disableCommunityMemberDragAndDrop
			? element
			: connectDragSource(connectDropTarget(element));
	}

	@action.bound
	private isChildDraggingCallback(isDragging: boolean) {
		this.isChildDragging = isDragging;
	}
}

function GetMemberIcon(props: { vm: ViewModels.Member, enableFeatureOrganizationalGiving: boolean }) {
	const verifiedTooltipContent = 'This community member is based on a registered Pushpay account.';
	const unverifiedTooltipContent = 'This community member is based on an unregistered Pushpay account.';
	const unverifiedOrganizationTooltipContent = 'This organization is based on an unregistered Pushpay account.';

	let {vm, enableFeatureOrganizationalGiving} = props;
	let tooltipContent, iconName, countIndicator;
	let hasVerifiedAccounts = vm.accounts.some(x => x.verified);
	let accountsCount = vm.accounts.length;

	iconName = hasVerifiedAccounts ? 'member-fill' : 'member-outline';
	tooltipContent = hasVerifiedAccounts ? verifiedTooltipContent : unverifiedTooltipContent;

	if (accountsCount > 1) {
		countIndicator = <span className="count">{accountsCount}</span>;
	}

	if (vm.details.IsOrganization) {
		if(enableFeatureOrganizationalGiving) {
			iconName = 'member-organization';
			tooltipContent = unverifiedOrganizationTooltipContent;
		}
	}

	return (
		<span className="member-icon" title={tooltipContent}>
			<SvgWrapper className="svg" svg={iconName} />
			{countIndicator}
		</span>
	);
}
