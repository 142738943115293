import * as React from 'react';
import {observer} from 'mobx-react';
import {IModalDialogButton, ModalDialog} from '../../components/modal-dialog';
import {FormControlCheckbox} from '../../components/form-controls/form-control-checkbox/form-control-checkbox';
import {SvgWrapper} from '../../components/svg-wrapper';
import {BatchDialogStore} from './batch-dialog-store';
import {ClientFlowHelper} from '../helpers/client-flow-helper';

@observer
export class BatchOpenPortalDialog extends React.Component<{ store: BatchDialogStore }> {
	render() {
		const { hideOpenPortalMessage, toggleHideOpenPortalMessage } = this.props.store;

		const buttons: (IModalDialogButton | JSX.Element)[] = [
			{ label: 'Cancel', dismiss: true, isPrimary: false, hideOnProgress: true },
			<button type="button" className="btn btn-default" onClick={this.openCheckScanningPortal}>
				<SvgWrapper svg="icon-follow-link" className="icon" />
				Continue
			</button>
		];

		return (
			<ModalDialog title="Open check scanning portal" buttons={buttons} showAlert={false}>
				<div className="modal-text">
					<div className="cd-batch-open-portal-dialog-heading">
						We are redirecting you to a new screen where you can start scanning your checks.
						Here are some tips before you continue
					</div>
					<ul className="cd-batch-open-portal-dialog-list">
						<li className="cd-batch-open-portal-dialog-list-item">
							<div className="cd-batch-open-portal-dialog-list-circle">
								<SvgWrapper svg="icon-deposit" className="cd-batch-open-portal-dialog-list-icon" />
							</div>
							Use the transactions tab to create a new deposit.
						</li>
						<li className="cd-batch-open-portal-dialog-list-item">
							<div className="cd-batch-open-portal-dialog-list-circle">
								<SvgWrapper svg="icon-report" className="cd-batch-open-portal-dialog-list-icon icon-report" />
							</div>
							Use the reports tab to view details for previous deposits.
						</li>
					</ul>
					<FormControlCheckbox containerClassNames="checkbox-large" value={hideOpenPortalMessage} onChangeHandler={toggleHideOpenPortalMessage} text="Don't show this message again" />
				</div>
			</ModalDialog>
		);
	}

	private openCheckScanningPortal = () => {
		ClientFlowHelper.openCheckScanningPortalWindow();
		this.props.store.openBeforeAllocationDialog();
	}
}
