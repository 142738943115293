import * as React from 'react';
import { ResponsiveVisibility, ResponsiveBreakpoint } from '../../helpers/responsive-helper';
import { RenderResponsive } from '../../components/hoc-behavior/render-responsive';
import { observer } from 'mobx-react';
import { ModalDialog, IModalDialogButton } from '../../components/modal-dialog';
import { Formatter } from '../../helpers/formatter';
import { AllocationViewModel } from './allocation-view-model';

@observer
export class AllocationCompleteDialog extends React.Component<{ store: AllocationViewModel }> {
	render() {
		const { isProcessingCompleteAllocationRequest, completeAllocation, BatchDetailsViewModel } = this.props.store;
		const { BatchDetails: { BatchName, TotalAmount, NumberOfProcessibleChecks, PayButtonLabel } } = BatchDetailsViewModel;
		const buttons: (IModalDialogButton | JSX.Element)[] = [
			{ label: 'Go back', dismiss: true, isPrimary: false, hideOnProgress: true },
			( <button type="submit" key="1" className="btn btn-primary" onClick={completeAllocation} disabled={isProcessingCompleteAllocationRequest}>
				Complete allocation{isProcessingCompleteAllocationRequest ? '...' : ''}
			</button> )
		];

		return (
			<ModalDialog
				title="Have you finished your allocation?"
				buttons={buttons}
				showAlert={false}
				inProgress={isProcessingCompleteAllocationRequest}
				className="cd-allocation-complete-dialog"
			>
				<p className="modal-text">You&rsquo;re about to complete allocation for <strong>{BatchName}</strong>.
					After completion, {PayButtonLabel.NounPluralLowerCase} will be available in the transactions page and
					your {PayButtonLabel.PayerPluralLowerCase} will be sent receipts if you selected this option during allocation.</p>
				<div className="cd-allocation-complete-dialog-summary">
					<div className="cd-allocation-complete-dialog-summary-item">
						<RenderResponsive visibility={ResponsiveVisibility.Hidden} breakpoints={ResponsiveBreakpoint.Xs}>
							<label id="cd-allocation-complete-dialog-summary-label-name">Deposit</label>
						</RenderResponsive>
						<h3 aria-labelledby="cd-allocation-complete-dialog-summary-label-name">{BatchName}</h3>
					</div>
					<div className="cd-allocation-complete-dialog-summary-item">
						<label id="cd-allocation-complete-dialog-summary-label-number">Number of checks</label>
						<h3 aria-labelledby="cd-allocation-complete-dialog-summary-label-number">{NumberOfProcessibleChecks}</h3>
					</div>
					<div className="cd-allocation-complete-dialog-summary-item">
						<label id="cd-allocation-complete-dialog-summary-label-amount">Total amount</label>
						<h3 aria-labelledby="cd-allocation-complete-dialog-summary-label-amount"><sup>$</sup>{Formatter.formatNumberForDisplay(TotalAmount)}</h3>
					</div>
				</div>
			</ModalDialog>
		);
	}
}
