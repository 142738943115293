import { actions, actionType, events, eventType, states } from '../../../../Shared/state-machine/states-events-and-actions';
import { getCampaignsSagaDataService } from '../../campaigns-data-service';
import { MachineContext } from '../../../../Shared/state-machine/saga-state-machine';
import { CreateCampaignMainViewModel } from './create-campaign-main-view-model';
import {
	CampaignCreateRequest,
	CampaignEditRequest,
	CreateCampaignSuccessResponse,
	EditCampaignSuccessResponse,
	CloseCampaignSuccessResponse,
	EditCampaignConfirmationRequiredResponse,
	CampaignResponseType,
} from '../../campaigns-generated';
import { alertController } from 'components/alert-controller';
import { ModalDialogCommander } from 'components/modal-dialog-commander';

enum CreateCampaignStates {
	Init,
	Creating,
	Editing,
	Completed,
	Idle,
	Error,
	ProcessingSave,
	ProcessingSaveAndPublish,
	ProcessingCloseCampaign,
	WaitingForConfirmation,
}

export const States = states(CreateCampaignStates);

export const Events = events({
	StartCreating: eventType<void>(),
	StartEditing: eventType<void>(),
	CloseCampaign: eventType<typeof Actions.dataTypes.CloseCampaign>(),
	Save: eventType<typeof Actions.dataTypes.CreateCampaign | typeof Actions.dataTypes.UpdateCampaign>(),
	SaveAndPublish: eventType<typeof Actions.dataTypes.CreateCampaign | typeof Actions.dataTypes.UpdateCampaign>(),
	ProcessingSuccessful: eventType<typeof Actions.dataTypes.RedirectToLandingPage>(),
	RequireConfirmation: eventType<void>(),
	CancelSaving: eventType<void>(),
	ValidationError: eventType<void>(),
	ProcessingError: eventType<void>(),
	CloseErrorModal: eventType<void>(),
});

export const Actions = actions({
	Start: actionType<void>((machineContext: MachineContext<CreateCampaignMainViewModel>) => {
		if (machineContext.viewModel.campaignId) {
			Events.raise.StartEditing(machineContext);
		} else {
			Events.raise.StartCreating(machineContext);
		}
	}),
	CloseCampaign: actionType<{ Id: number }>(function* (machineContext: MachineContext<CreateCampaignMainViewModel>, data) {
		const { viewModel } = machineContext;
		try {
			const response: CloseCampaignSuccessResponse = yield getCampaignsSagaDataService().closeCampaign({ request: data });
			Events.raise.ProcessingSuccessful(machineContext, { url: response.RedirectUri });

			if(viewModel.modalDialogProcessingViewModel)
			{
				viewModel.modalDialogProcessingViewModel.setIsProcessing(false);
			}
		} catch (error) {
			if(viewModel.modalDialogProcessingViewModel)
			{
				viewModel.modalDialogProcessingViewModel.setIsProcessing(false);

				ModalDialogCommander.forceCloseCurrent();
			}

			handleError(machineContext, error);
		}
	}),
	CreateCampaign: actionType<CampaignCreateRequest>(function* (machineContext, data) {
		try {
			const response: CreateCampaignSuccessResponse = yield getCampaignsSagaDataService().createCampaign({ createInfo: data });
			Events.raise.ProcessingSuccessful(machineContext, { url: response.RedirectUri });
		} catch (error) {
			handleError(machineContext, error);
		}
	}),
	UpdateCampaign: actionType<CampaignEditRequest>(function* (machineContext: MachineContext<CreateCampaignMainViewModel>, data) {
		try {
			const response: EditCampaignSuccessResponse | EditCampaignConfirmationRequiredResponse = yield getCampaignsSagaDataService().editCampaign({ editInfo: data });
			if (response.Type === CampaignResponseType.Success) {
				Events.raise.ProcessingSuccessful(machineContext, { url: response.RedirectUri });
			} else if (response.Type === CampaignResponseType.ConfirmationRequired) {
				const { viewModel } = machineContext;
				viewModel.setEditConfirmationRequired(response);
				Events.raise.RequireConfirmation(machineContext);
			}
		} catch (error) {
			handleError(machineContext, error);
		}
	}),
	RedirectToLandingPage: actionType<{ url: string }>((machineContext, data) => {
		window.location.href = data.url;
	}),
});

function handleError(machineContext: MachineContext<CreateCampaignMainViewModel>, error: any) {

	if (error.validationErrors) {
		machineContext.viewModel.form.setErrors(error.validationErrors);
		alertController.showValidationErrors(error.validationErrors);
		Events.raise.ValidationError(machineContext);
		return;
	}

	Events.raise.ProcessingError(machineContext);
}
