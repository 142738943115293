import * as React from 'react';
import { responsiveHelper } from '../helpers/responsive-helper';
import { observer } from 'mobx-react';

@observer
export class TutorialSlide extends React.Component<{ imageSource: string, imgWidth?: number, imgHeight?: number, extraClassNamesForImage?: string }, {}> {
	render() {
		const imgWidth = responsiveHelper.isSm || responsiveHelper.isXs ? null : this.props.imgWidth;
		const imgHeight = responsiveHelper.isSm || responsiveHelper.isXs ? null : this.props.imgHeight;
		let imageCssClassNames = `tutorial-image ${this.props.extraClassNamesForImage || ``}`;

		return (
			<div className="tutorial-slide">
				<div className={`${imageCssClassNames}`}><img src={this.props.imageSource} width={imgWidth} height={imgHeight} /></div>
				<div className="tutorial-description">
					{this.props.children}
				</div>
			</div>
		);
	}
}
