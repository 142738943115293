import * as React from 'react';
import { observer } from 'mobx-react';
import { SimpleMatchModal, ModalCancelButton } from '../../../components/match-modal';
import { Fragment } from '../../../../Shared/components/fragment';
import { Button } from '../../../../Shared/components/button/button';
import { classNames } from '../../../../Shared/utils/classnames';
import { PledgeAlreadyAddedResponse, PledgeLabel } from '../../campaigns-generated';

import * as styles from './campaigns-modals.less';

interface PledgeDeleteConfirmationModalProps {
	visibleStates: (string | string[])[];
	deletingPledge: boolean;
	alreadyAddedPledge: PledgeAlreadyAddedResponse;
	pledgeLabel: PledgeLabel;
	onConfirm: () => void;
	onCancel: () => void;
}

@observer
export class PledgeDeleteConfirmationModal extends React.Component<PledgeDeleteConfirmationModalProps> {
	render() {
		const {
			visibleStates,
			deletingPledge,
			alreadyAddedPledge,
			onCancel,
			onConfirm,
			pledgeLabel,
		} = this.props;
		return (
			<SimpleMatchModal
				type="warning"
				anyState={visibleStates}
				getCancelButton={() => <ModalCancelButton disabled={deletingPledge} onCancel={onCancel} />}
				getBody={() =>
					<Fragment>
						<h1>Are you sure you want to delete this {NewFeatures.DonorPledgeEntry_ConfigurablePledgeGrammar ? pledgeLabel.NounLowerCase : 'pledge'}?</h1>
						<div>By clicking delete, {alreadyAddedPledge.PledgerNamePossessive} {NewFeatures.DonorPledgeEntry_ConfigurablePledgeGrammar ? pledgeLabel.NounLowerCase : 'pledge'} will be removed.</div>
					</Fragment>}
				getFooter={() =>
					<div className={styles.footer}>
						<Button type="submit" isLoading={deletingPledge} onClick={onConfirm} className="btn btn-primary">Delete {NewFeatures.DonorPledgeEntry_ConfigurablePledgeGrammar ? pledgeLabel.NounLowerCase : 'pledge'}</Button>
						<button type="button" onClick={onCancel} disabled={deletingPledge} className={classNames('btn', 'btn-link', styles.pledgeDeleteConfirmDeleteBtn)}>Close</button>
					</div>
				}
			/>
		);
	}
}
