import * as React from 'react';
import { IFormControlCommonProps } from './form-control-common-props';
import { getElementId } from './form-control-utils';
import { IIconInputGroupProps, IconInputGroup } from '../icon-input-group';
import { ValidatableControl } from './validatable-control';
import { isFunction } from '../../utils/is-function';
import { Formatter } from '../../helpers/formatter';
import { FloatMath } from '../../helpers/floatmath';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { classNames } from '../../../Shared/utils/classnames';
import { BrowserSupportHelper } from '../../helpers/browsersupporthelper';

const filterNumberRegex = /[^\d|\.]/g;
const precision = 2;

function parseStringValue(value: string): number | null {
	if (value === '' || value === null || value === 'undefined') {
		return null;
	}

	return parseFloat(value.replace(filterNumberRegex, ''));
}

export interface IFormControlAmountProps extends IFormControlCommonProps {
	inputGroupOptions?: IIconInputGroupProps;
	readonly?: boolean;
	onChangeHandler?: (value: number) => void;
	onFocus?: () => void;
	onBlur?: () => void;
	value: number;
	ignorePanLikeValidation?: boolean;
	inputClassName?: string;
}

export interface IFormControlAmountState {
	amount: string;
	amountAsNumber: number;
}

@observer
export class FormControlAmount extends React.Component<IFormControlAmountProps, IFormControlAmountState> {
	@observable
	state = { amount: '', amountAsNumber: null };

	private nextCaretPositionUpdate: number = null;
	private element: HTMLInputElement;

	UNSAFE_componentWillMount() {
		this.updateComponentState(this.props.value);
	}

	UNSAFE_componentWillReceiveProps(nextProps: IFormControlAmountProps) {
		const { value } = nextProps;
		if (value !== this.state.amountAsNumber) {
			this.updateComponentState(value);
		}
	}

	render() {
		const { name, uniqueSuffix, placeholder, inputGroupOptions,
			validationRules, readonly, disabled, tabIndex, ignorePanLikeValidation,
			onFocus, isInvalid, acceptanceTestTargetId, inputClassName } = this.props;
		const defaultValidationRules = {
			length: {
				errorMessage: `Please enter an amount less than one trillion`,
				parameters: {
					max: 19 // because we format the amount, this includes 2 decimal places and commas
				}
			}
		};

		const currentValidationRules = validationRules ? { ...defaultValidationRules, ...validationRules } : defaultValidationRules;

		const id = getElementId(name, uniqueSuffix);

		const commonProps = {
			id, name, placeholder,
			onFocus, disabled, tabIndex,
			readOnly: readonly,
			value: this.state.amount,
			type: BrowserSupportHelper.isIOS() ? 'number' : 'tel',
			className: classNames('form-control', inputClassName),
			onChange: this.handleAmountChange,
			ref: this.ref,
			onBlur: this.handleBlur,
			'data-ignore-pan-like': ignorePanLikeValidation,
			'data-pp-at-target': acceptanceTestTargetId,
		};

		const input = <input {...commonProps} />;

		const validatableInput = <ValidatableControl
			validationRules={currentValidationRules}
			elementName={name}
			revalidateOnBlur={true}
			isInvalid={isInvalid}>{input}</ValidatableControl>;

		return inputGroupOptions ? <IconInputGroup options={inputGroupOptions}>{validatableInput}</IconInputGroup> : validatableInput;
	}

	private ref = (element: HTMLInputElement) => {
		this.element = element;
		if (isFunction(this.props.refCallback)) {
			this.props.refCallback(element);
		}
	}

	private handleAmountChange = () => {
		if (this.props.readonly) {
			return;
		}

		this.updateElementValue(this.element.value);
	}

	private handleBlur = () => {
		this.updateComponentState(this.state.amountAsNumber);

		if (isFunction(this.props.onBlur)) {
			this.props.onBlur();
		}
	}

	@action
	private updateComponentState(value: number) {
		this.setState({
			amountAsNumber: value,
			amount: Formatter.formatNumberForDisplay(value, precision),
		});
	}

	private updateElementValue = (value: string) => {
		const element = this.element;
		let { selectionEnd: caretPosition } = element;

		const { value: updatedValue, caretPosition: newCaretPosition } = getFilteredValue(value, caretPosition);
		const valueAsNumber = round(parseStringValue(updatedValue), precision);


		this.setState({
			amountAsNumber: valueAsNumber,
			amount: updatedValue,
		}, () => {
			if (isFunction(this.props.onChangeHandler) && !isNaN(valueAsNumber)) {
				this.props.onChangeHandler(valueAsNumber);
			}

			if (updatedValue !== value) {
				if (this.nextCaretPositionUpdate) {
					cancelAnimationFrame(this.nextCaretPositionUpdate);
				}

				this.nextCaretPositionUpdate = requestAnimationFrame(() => {
					if (element.value === updatedValue) {
						element.setSelectionRange(newCaretPosition, newCaretPosition);
					}
				});
			}
		});
	}
}

function isCharDigit(a) {
	const charCode = a.charCodeAt(0);
	return charCode >= 48 && charCode <= 57; // 0-9
}

function getFilteredValue(value: string, caretPosition: number) {
	const chars = value.split('');
	const validChars = [];
	let newCaretPosition = caretPosition;

	for (let i = 0; i < chars.length; i++) {
		const char = chars[i];

		if (isCharDigit(char) || char === '.' || char === ',') {
			validChars.push(char);
		} else if (i < caretPosition) {
			newCaretPosition--;
		}
	}

	return {
		value: validChars.join(''),
		caretPosition: newCaretPosition
	};
}

function round(value: number, precision: number) {
	if (value === null) {
		return value;
	}
	return FloatMath.round(value, precision);
}
