import { observable, computed, action } from 'mobx';
import { SettingsServiceTimeConfigViewModel, ListingViewModel, SettingsEventTimeViewModel, EventTimeViewModel, EventTimeForMerchant, PaymentLabel } from '../../settings-generated';
import { MultiSelectItem } from '../../../loggedinweb-generated';
import { Channel } from '../../../../Shared/utils/user-action-channel';
import { ServiceTimeUserAction } from './service-time-user-actions';
import { EventTimeAddEditDialogViewModel } from '../../../components/event-time/event-time-add-edit-dialog-view-model';
import { ModalDialogProcessingViewModel } from '../../../components/modal-dialog/modal-dialog-processing-view-model';

export class ServiceTimeMainViewModel {
	userActionChannel: Channel<ServiceTimeUserAction>;
	paymentLabel: PaymentLabel;

	@observable
	listings: ListingViewModel[];

	@observable
	addEventTimeDialogViewModel: EventTimeAddEditDialogViewModel;

	@observable
	modalDialogProcessingViewModel: ModalDialogProcessingViewModel;

	@observable
	eventTimes: SettingsEventTimeViewModel[];

	@computed
	get hasServiceTimes(): boolean {
		return this.eventTimes && this.eventTimes.length > 0;
	}

	@computed
	get listingItems(): MultiSelectItem[] {
		return this.listings.map(({ ListingName, ListingId }) => ({
			Text: ListingName,
			SelectedText: ListingName,
			Value: String(ListingId),
			Disabled: false,
		}));
	}

	@observable
	selectedListingIds: string[];

	@computed
	get filteredListings(): ListingViewModel[] {
		if (!this.selectedListingIds || !this.selectedListingIds.length) {
			return this.listings;
		}

		return this.listings.filter((listing) => this.selectedListingIds.indexOf(String(listing.ListingId)) > -1);
	}

	constructor(model: SettingsServiceTimeConfigViewModel) {
		this.eventTimes = model.EventTimes;
		this.listings = model.Listings;
		this.modalDialogProcessingViewModel = new ModalDialogProcessingViewModel();
		this.paymentLabel = model.PaymentLabel;
	}

	@action.bound
	setSelectedListingIds(selectedListingIds: string[]) {
		this.selectedListingIds = selectedListingIds;
	}

	@action.bound
	setUserActionChannel(channel: Channel<ServiceTimeUserAction>) {
		this.userActionChannel = channel;
	}

	@action.bound
	setAddEventTimeDialogViewModel(addEventTimeDialogViewModel: EventTimeAddEditDialogViewModel) {
		this.addEventTimeDialogViewModel = addEventTimeDialogViewModel;
	}

	@action.bound
	setEventTimes(eventTimes: EventTimeViewModel[]) {
		this.eventTimes = eventTimes;
	}

	@action.bound
	addEventTimeToListing(eventTime: EventTimeForMerchant) {
		const { MerchantId, Token, Id } = eventTime;
		const listings = [ ...this.listings ];
		const [ listingToUpdate ] = listings.filter(listing => listing.ListingId === MerchantId);

		listingToUpdate.ActiveEventTimes.push(Id);
		this.listings = listings;
	}

	@action.bound
	removeEventTimeFromListing(eventTime: EventTimeForMerchant) {
		const { MerchantId, Token, Id } = eventTime;
		const listings = [ ...this.listings ];
		const [ listingToUpdate ] = listings.filter(listing => listing.ListingId === MerchantId);

		listingToUpdate.ActiveEventTimes = listingToUpdate.ActiveEventTimes.filter(id => id !== Id);
		this.listings = listings;
	}
}
