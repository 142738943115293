import { computed } from 'mobx';
import { FormState } from '../../../Shared/forms/form-state';
import { TextFieldState } from '../../../Shared/forms/fields/textbox-field';
import { RadioButtonFieldState } from '../../../Shared/forms/fields/radio-button-field';
import { SelectFieldState } from '../../../Shared/forms/fields/select-field';
import { BaseFieldState } from '../../../Shared/forms/fields/base-field-state';
import { MemberFormCreateProps, MemberFormEditProps } from './member-form-modal';
import { ModelMetadata, EditCommunityMemberRequestModel, Country, CommunityMemberType } from '../../loggedinweb-generated';
import { required } from '../../../Shared/forms/validation/validators';

const { EditCommunityMemberRequestModel: MemberMetaData } = ModelMetadata;
const { Address: { modelMetadata: AddressMetaData} } = MemberMetaData;

export class MemberFormViewModel {
	readonly communityMemberId?: number;

	readonly form: MemberForm;

	readonly lockVersion: number = null;

	readonly mode: 'create' | 'edit';

	constructor(model: MemberFormCreateProps | MemberFormEditProps) {
		this.mode = model.mode;
		if (model.mode === 'create') {
			const { firstName, lastName, defaultCountry, listingId, listings } = model;
			const initialListingId = listings.length === 1 ? listings[0].value : listingId;
			this.form = createMemberForm({ FirstName: firstName, LastName: lastName }, defaultCountry, initialListingId as number);
			this.form.$.listing.validators(required('Listing is required'));
		} else {
			const { memberDetails } = model;
			this.communityMemberId = memberDetails.CommunityMemberId;
			this.lockVersion = memberDetails.LockVersion;
			this.form = createMemberForm(memberDetails);
		}
	}

	@computed
	get requestModel() {
		const merchantId = this.mode === 'create' ? this.form.$.listing.$ : null;
		return { merchantId, model: this.fieldsForRequest };
	}

	@computed
	private get fieldsForRequest(): EditCommunityMemberRequestModel {
		const {
			communityMemberType, firstName, lastName, emailAddress, mobileNumber, addressLine1, addressLine2, city, state, postcode, country
		} = this.form.$;

		return {
			CommunityMemberId: this.communityMemberId,
			CommunityMemberType: Number(communityMemberType.value),
			FirstName: firstName.value,
			LastName: lastName.value,
			EmailAddress: emailAddress.value,
			MobileNumber: mobileNumber.value,
			Address: {
				Line1: addressLine1.value,
				Line2: addressLine2.value,
				City: city.value,
				State: state.value,
				Postcode: postcode.value,
				Country: Number(country.value),
				DisplayAddress: null,
				PostalCodeLabel: null,
			},
			LockVersion: this.lockVersion,
		};
	}
}

function createMemberForm(memberDetails: Partial<EditCommunityMemberRequestModel>, defaultCountry?: Country, listingId?: number) {
	const { FirstName, LastName, EmailAddress, MobileNumber, Address } = memberDetails;

	return new FormState({
		communityMemberType: new RadioButtonFieldState(String(memberDetails.CommunityMemberType || CommunityMemberType.Individual), MemberMetaData.CommunityMemberType),
		listing: new SelectFieldState(listingId),
		firstName: new TextFieldState(FirstName, MemberMetaData.FirstName),
		lastName: new TextFieldState(LastName, MemberMetaData.LastName),
		emailAddress: new TextFieldState(EmailAddress, MemberMetaData.EmailAddress),
		mobileNumber: new TextFieldState(MobileNumber, MemberMetaData.MobileNumber),
		addressLine1: new TextFieldState(Address ? Address.Line1 : '', AddressMetaData.Line1),
		addressLine2: new TextFieldState(Address ? Address.Line2 : '', AddressMetaData.Line2),
		city: new TextFieldState(Address? Address.City : '', AddressMetaData.City),
		state: new BaseFieldState(Address ? Address.State : '', AddressMetaData.State),
		postcode: new TextFieldState(Address ? Address.Postcode : '', AddressMetaData.Postcode),
		country: new SelectFieldState(Address ? Address.Country : defaultCountry, AddressMetaData.Country),
	});
}

type MemberForm = ReturnType<typeof createMemberForm>;
