import { QuickBooksSaveMappingsViewModel } from '../../qbo-integration-generated';

export namespace QboConfigurationUserAction {
	export class AuthorizeQuickBooks { }

	export class EnableIntegration { }

	export class DeleteIntegration { }

	export class DisableIntegration { }

	export class GetMappings { }

	export class SaveMappings {
		constructor(public qboMappings: QuickBooksSaveMappingsViewModel) {}
	}

	export class CancelMappings { }
}

export type QboConfigurationUserAction = typeof QboConfigurationUserAction[keyof typeof QboConfigurationUserAction]['prototype'];
