import * as React from 'react';
import { observable, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Subscription } from 'rxjs';
import { CampaignStatus, DraftCampaignCardViewModel, ActiveCampaignCardViewModel, ClosedCampaignCardViewModel, ScheduledCampaignCardViewModel } from '../../campaigns-generated';
import { Fragment } from '../../../../Shared/components/fragment';
import { Match } from '../../../../Shared/state-machine/match';
import {
	EditAction, DuplicateAction, DeleteAction, ActivateAction, CloseAction,
	ShareAction, MatchFundAction, ReactivateAction, AddPledgeAction, AddPledgeActionOld
} from './campaign-card-action';
import { Bubble } from '../../../components/bubble/bubble';
import { createCampaignCardMachineContext } from '../campaign-card/state-machine/campaign-card-machine-context';
import { DeleteActionPopupContent } from '../campaign-card-popup/delete-action-popup-content';
import { CloseActionPopupContent } from '../campaign-card-popup/close-action-popup-content';
import { ShareActionPopupContent } from '../campaign-card-popup/share-action-popup-content';
import State from '../campaign-card/state-machine/campaign-card-states';
import { getMousemoveEvent$ } from '../../pages/campaigns-landing/campaigns-landing-intent';
import { CampaignCardViewModel } from '../../campaign-types';

import * as styles from './campaign-card-actions.less';

interface CampaignCardActionsProps {
	cardViewModel: CampaignCardViewModel;
	machineContext?: ReturnType<typeof createCampaignCardMachineContext>;
	className: string;
}

@inject('machineContext')
@observer
export class CampaignCardActions extends React.Component<CampaignCardActionsProps> {
	@observable
	private indicatorTop: number;

	@observable
	private indicatorLeft: number;

	@observable
	private showIndicator: boolean = false;

	private indicatorMoveSubscription: Subscription;


	render() {
		const { cardViewModel, machineContext: { eventCreator }, className } = this.props;
		let actionItems;

		if (cardViewModel.Status === CampaignStatus.Draft) {

			const { EditUrl, DuplicateUrl, FundRequiresExternalSystemMapping, EditFundUrl } = cardViewModel;
			actionItems = <Fragment>
				<EditAction editUrl={EditUrl} />
				<DuplicateAction duplicateUrl={DuplicateUrl} />
				<DeleteAction onClick={eventCreator.deleteCampaign} >
					{
						<Match state={State.Confirming}>
							<Bubble onCancel={eventCreator.cancelConfirmation} placement="right">
								<DeleteActionPopupContent onCancel={eventCreator.cancelConfirmation}
									onConfirm={this.onConfirmDelete} />
							</Bubble>
						</Match>
					}
				</DeleteAction>
				{
					FundRequiresExternalSystemMapping
						? <MatchFundAction editFundUrl={EditFundUrl} />
						: <ActivateAction onClick={this.onActivate} />
				}
			</Fragment>;

		} else if (cardViewModel.Status === CampaignStatus.Published || cardViewModel.Status === CampaignStatus.Scheduled) {

			const { EditUrl, DuplicateUrl, ShareUrls, DonorPledgeEntryUrl } = cardViewModel;
			actionItems = <Fragment>
				<EditAction editUrl={EditUrl} />
				<DuplicateAction duplicateUrl={DuplicateUrl} />
				<CloseAction onClick={eventCreator.closeCampaign}>
					{
						<Match state={State.Confirming}>
							<Bubble onCancel={eventCreator.cancelConfirmation} placement="right">
								<CloseActionPopupContent onCancel={eventCreator.cancelConfirmation}
									onConfirm={this.onConfirmClose} />
							</Bubble>
						</Match>
					}
				</CloseAction>
				
				{ cardViewModel.Status === CampaignStatus.Published && !NewFeatures.DonorPledgeEntry_ConfigurablePledgeGrammar && <AddPledgeActionOld addPledgeUrl={cardViewModel.AddPledgeUrl} /> }
				{ cardViewModel.Status === CampaignStatus.Published && NewFeatures.DonorPledgeEntry_ConfigurablePledgeGrammar && <AddPledgeAction addPledgeUrl={cardViewModel.AddPledgeUrl} pledgeLabelValue={cardViewModel.PledgeLabel.NounLowerCase} /> }

				<ShareAction onClick={eventCreator.shareCampaign}>
					{
						<Match state={State.Sharing}>
							<Bubble onCancel={eventCreator.shareCampaignComplete} placement="left">
								<ShareActionPopupContent
									onSelect={this.onSelectShareUrl}
									listings={ShareUrls}
									donorPledgeEntryUrl={DonorPledgeEntryUrl}
									campaignStatus={cardViewModel.Status}
									pledgeLabel={cardViewModel.PledgeLabel} />
							</Bubble>
						</Match>
					}
				</ShareAction>
				{this.showIndicator && <SharedIndicator top={this.indicatorTop} left={this.indicatorLeft} />}
			</Fragment>;

		} else if (cardViewModel.Status === CampaignStatus.Closed) {

			const { DuplicateUrl, EditUrl } = cardViewModel;
			actionItems = <Fragment>
				<DuplicateAction duplicateUrl={DuplicateUrl} />
				<ReactivateAction reactivateUrl={EditUrl} />
			</Fragment >;

		} else {
			const unsupported: never | ScheduledCampaignCardViewModel = cardViewModel;
			throw new Error(`Campaign card ${unsupported} is not supported`);
		}

		return (
			<div className={className}>
				{actionItems}
			</div>
		);
	}

	componentWillUnmount() {
		if (this.indicatorMoveSubscription) {
			this.indicatorMoveSubscription.unsubscribe();
		}
	}

	private onSelectShareUrl = (e, url: string) => {
		e.stopPropagation();
		const textArea = document.createElement('textarea');
		textArea.style.opacity = '0';
		textArea.style.position = 'fixed';
		textArea.style.bottom = '0';
		textArea.style.left = '0';
		textArea.value = url;
		document.body.appendChild(textArea);
		textArea.select();
		document.execCommand('Copy');
		document.body.removeChild(textArea);
		this.props.machineContext.eventCreator.shareCampaignComplete();
		this.showSharedIndicator(e);
	}

	private showSharedIndicator(e: MouseEvent) {
		this.indicatorMoveSubscription = getMousemoveEvent$(e, 1500).subscribe(this.updateSharedIndicatorPosition, null, this.hideSharedIndicator);
	}

	@action.bound
	private hideSharedIndicator() {
		this.showIndicator = false;
	}

	@action.bound
	private updateSharedIndicatorPosition(e: MouseEvent) {
		this.showIndicator = true;
		this.indicatorLeft = e.clientX;
		this.indicatorTop = e.clientY;
	}

	private onConfirmDelete = () => {
		const { cardViewModel, machineContext: { eventCreator } } = this.props;
		eventCreator.confirmDeleteCampaign({ draftCard: cardViewModel as DraftCampaignCardViewModel });
	}

	private onConfirmClose = () => {
		const { cardViewModel, machineContext: { eventCreator } } = this.props;
		eventCreator.confirmCloseCampaign({ ActiveOrScheduledCard: cardViewModel as ActiveCampaignCardViewModel | ScheduledCampaignCardViewModel });
	}

	private onActivate = () => {
		const { cardViewModel, machineContext: { eventCreator } } = this.props;
		eventCreator.activateCampaign({ draftCard: cardViewModel as DraftCampaignCardViewModel });
	}
}

const SharedIndicator = (({ top, left }) => (
	<small className={styles.sharedIndicator}
		style={{ top: `${top}px`, left: `${left}px` }}>
		Copied
	</small>
));
