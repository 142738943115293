import * as React from 'react';
import { createPortal } from 'react-dom';
import ConfettiComponent, { Props as IConfettiOptions } from 'react-confetti';
import { isFunction } from '../../utils/is-function';
import { ThankYouAnimationType } from '../../../LoggedInWeb/loggedinweb-generated';
import { useEffect, useCallback, useState, useRef } from 'react';
import { isIE } from '../../helpers/browser-support-helper';

const heartTransparentColor = '#806bcfd6';
const heartOpaqueColor = '#806bcf';

export const givingAnimationEvent = 'pushpayGivingAnimation';
export const givingAnimationContainer = 'pushpayGivingAnimationContainer';

export const useWindowSize = () => {
	const [windowSize, setWindowSize] = React.useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	React.useEffect(() => {
		const handler = () => {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		};
		window.addEventListener('resize', handler);
		return () => window.removeEventListener('resize', handler);
	}, []);

	return windowSize;
};

const getConfettiOptions = (
	type: ThankYouAnimationType,
	{ width = 0, height = 0, className }: IConfettiOptions
): IConfettiOptions => {
	let options: IConfettiOptions = {
		width,
		height,
		className,
		recycle: false,
		confettiSource: {
			x: 0,
			y: 0,
			w: width,
			h: 0,
		},
	};

	if (type === ThankYouAnimationType.Hearts) {
		options = {
			...options,
			numberOfPieces: 140,
			drawShape: (ctx: CanvasRenderingContext2D) => {
				ctx.beginPath();
				ctx.moveTo(28.6, 0.8);
				ctx.bezierCurveTo(28.8, 0.9, 13.1, 7.8, 7.5, 17.2);
				ctx.bezierCurveTo(6.7, 18.5, 2.1, 27.6, 8.0, 35.2);
				ctx.bezierCurveTo(12.3, 40.7, 26.3, 41.8, 29.0, 30.9);
				ctx.bezierCurveTo(28.7, 34.2, 38.9, 43.1, 47.5, 36.9);
				ctx.bezierCurveTo(55.3, 31.3, 53.0, 20.7, 50.6, 17.0);
				ctx.bezierCurveTo(42.8, 5.0, 28.6, 0.9, 28.6, 0.8);
				ctx.fillStyle = isIE() ? heartOpaqueColor : heartTransparentColor;
				ctx.fill();
			},
		};
	} else {
		options = {
			...options,
			numberOfPieces: 600,
		};
	}
	return options;
};

export interface IConfettiProps {
	type?: ThankYouAnimationType;
	className?: string;
	onComplete?: () => void;
}

export const ConfettiAnimation = ({ type = ThankYouAnimationType.None, className, onComplete }: IConfettiProps) => {
	const { width, height } = useWindowSize();

	if (!type) {
		return null;
	}

	return (
		<ConfettiComponent
			{...getConfettiOptions(type, {
				width,
				height,
				className,
			})}
			onConfettiComplete={onComplete}
		/>
	);
};

export const ConfettiAnimationWithPortal = ({
	className,
	type = ThankYouAnimationType.None,
	onComplete,
	parentId,
}: IConfettiProps & { parentId: string }) => {
	const { width, height } = useWindowSize();
	const containerRef = useRef<HTMLDivElement | null>(null);
	const [animationType, setAnimationType] = useState(type || 0);

	const handleOnComplete = useCallback(() => {
		if (isFunction(onComplete)) {
			onComplete();
		}
		setAnimationType(0);
	}, [onComplete, setAnimationType]);

	const handleConfettiEvent = useCallback(
		(event: CustomEvent) => {
			if (!animationType && event) {
				setAnimationType(event.detail || 0);
			}
		},
		[animationType]
	);

	useEffect(() => {
		containerRef.current = document.querySelector(`#${parentId}`);
		window.addEventListener(givingAnimationEvent, handleConfettiEvent as any);
		return () => {
			window.removeEventListener(givingAnimationEvent, handleConfettiEvent as any);
		};
	}, [handleConfettiEvent]);

	useEffect(() => setAnimationType(type), [type]);
	if (!animationType || !containerRef.current) {
		return null;
	}

	return (
		<>
			{createPortal(
				<ConfettiComponent
					{...getConfettiOptions(animationType, {
						width,
						height,
						className,
					})}
					onConfettiComplete={handleOnComplete}
				/>,
				containerRef.current
			)}
		</>
	);
};
