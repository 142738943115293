import { IPayerViewModel } from '../components/payer-search-omnibox/payer-search-omnibox';
import { getPayerName } from '../utils/getPayerName';
import { Models } from './check-deposit-generated';

export function communityMemberModelToViewModel(model: Models.CommunityMemberQueryResult): IPayerViewModel {
	if (!model) {
		return null;
	}

	//dispay address does not include country and zip code
	const addressParts = [model.Address.Line1, model.Address.Line2, model.Address.City, model.Address.State];

	if (NewFeatures.NewGiftEntryCommunityMemberSearchResultLabels) {
		return {
			displayAddress: addressParts.filter((x) => x).join(', '),
			emailAddress: model.EmailAddress,
			id: `${
				model.CommunityMemberId === null || model.CommunityMemberId === undefined ? '' : model.CommunityMemberId
			}`,
			mobileNumber: model.MobileNumber,
			name: getPayerName(model),
			postCode: model.Address.Postcode,
			personalDetails: model,
			country: model.Address.Country,
			isOrganization: model.CommunityMemberType === Models.CommunityMemberType.Organization,
			campusName: model.CampusName,
			primaryExternalId: model.PrimaryExternalId,
		};
	}
	
	return {
		displayAddress: addressParts.filter(x => x).join(', '),
		emailAddress: model.EmailAddress,
		id: `${(model.CommunityMemberId === null || model.CommunityMemberId === undefined) ? '' : model.CommunityMemberId}`,
		mobileNumber: model.MobileNumber,
		name: getPayerName(model),
		postCode: model.Address.Postcode,
		personalDetails: model,
		country: model.Address.Country,
		isOrganization: model.CommunityMemberType === Models.CommunityMemberType.Organization,
	};
}
