import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { inject, Provider } from 'mobx-react';
import { velocity } from '../../helpers/velocity';
import { getBoundingRectRelativeToContainer } from '../../helpers/getboundingrect';
import { isFunction } from '../../utils/is-function';

export interface IScrollableContainerContext {
	scrollItemIntoView(element: HTMLElement);
}

/**
 * ScrollableContainer provides scrollableContainerContext for the descendants
 * IScrollableContainerContext is used to request this ScrollableContainer to scroll a item into view
 * When no item request scrolling into view - this ScrollableContainer initial scrolling position defined by
 * the prop initialScrollingPosition
 * When this component unmounts - it notifies it's last scrolling position by calling props.onUnmount(lastScrollTop) so
 * it can be passed that value as props.initialScrollingPosition when mounted again
 * @export
 * @class ScrollableContainer
 * @extends {React.Component<{
 * 	initialScrollingPosition: number;
 * 	onUnmount: (scrollTop: number) => void;
 * }, { context: IScrollableContainerContext }>}
 */
export class ScrollableContainer extends React.Component<{
	initialScrollingPosition?: number;
	onUnmount?: (scrollTop: number) => void;
}, { context: IScrollableContainerContext }> {
	private mounted: boolean;
	private itemToScrollIntoViewOnMount: HTMLElement;

	constructor(props) {
		super(props);

		this.state = {
			context: {
				scrollItemIntoView: (element: HTMLElement) => {
					if (this.mounted) {
						this.scrollItemIntoView(element, true);
					} else {
						this.itemToScrollIntoViewOnMount = element;
					}
				}
			}
		};
	}

	componentWillUnmount() {
		this.mounted = false;
		if (isFunction(this.props.onUnmount)) {
			const lastScrollTop = (ReactDOM.findDOMNode(this) as Element).scrollTop;
			this.props.onUnmount(lastScrollTop);
		}
	}

	componentDidMount() {
		this.mounted = true;
		const container = ReactDOM.findDOMNode(this) as HTMLElement;
		container.scrollTop = this.props.initialScrollingPosition || 0;

		this.scrollItemIntoView(this.itemToScrollIntoViewOnMount, false);
	}

	render() {
		return React.createElement(Provider, { scrollableContainerContext: this.state.context }, React.Children.only(this.props.children));
	}

	private scrollItemIntoView(element: HTMLElement, smoothScrolling: boolean) {
		if (!element) {
			return;
		}

		const container = ReactDOM.findDOMNode(this) as HTMLElement;
		if (!container.contains(element)) {
			return;
		}

		const elementRelativeBounds = getBoundingRectRelativeToContainer(element, container);

		if (elementRelativeBounds.top < 0) {
			if (smoothScrolling) {
				this.smoothScroll(element, container, 0);
			} else {
				container.scrollTop += elementRelativeBounds.top;
			}
		} else if (elementRelativeBounds.bottom > 0) {
			if (smoothScrolling) {
				this.smoothScroll(element, container, elementRelativeBounds.height - container.offsetHeight);
			} else {
				container.scrollTop += elementRelativeBounds.bottom;
			}
		}
	}

	private smoothScroll(element: HTMLElement, container: HTMLElement, offset: number) {
		//need to compensate the offset unhandled by velocity if container and offsetParent aren't this same
		const elementOffsetParentRelativeBounds =  getBoundingRectRelativeToContainer(element.offsetParent, container);

		velocity(element, 'scroll', {
			duration: 150,
			offset: offset + elementOffsetParentRelativeBounds.top,
			container: container,
			easing: 'ease-in-out'
		});
	}
}

/**
 * Notifies the ancestor ScrollableContainer to scroll this item into view when is true
 * @export
 * @class ScrollableContainerItem
 * @extends {React.Component<{
 * 	tryScrollIntoView: boolean;
 * 	scrollableContainerContext?: IScrollableContainerContext;
 * }, {}>}
 */
@inject('scrollableContainerContext')
export class ScrollableContainerItem extends React.Component<{
	tryScrollIntoView: boolean;
	scrollableContainerContext?: IScrollableContainerContext;
}, {}> {

	componentDidMount() {
		this.scrollIntoView();
	}

	componentDidUpdate() {
		this.scrollIntoView();
	}

	render() {
		return React.Children.only(this.props.children);
	}

	private scrollIntoView() {
		const {tryScrollIntoView, scrollableContainerContext} = this.props;
		if (tryScrollIntoView) {
			scrollableContainerContext.scrollItemIntoView(ReactDOM.findDOMNode(this) as HTMLElement);
		}
	}
}
