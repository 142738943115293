import * as React from 'react';
import { PaymentLabel } from '../../virtual-terminal-generated';
import { EditMemberDialog } from '../member/edit-member-dialog';
import { IMemberViewModel } from '../member/member-view-model';
import {
	PayerSearchContainer,
	PayerSearchEditButton,
	PayerSearchInputField,
	SelectedPayerInfo,
	PayerSearchClearButton,
	PayerSearchDropDownMenu,
	PayerSearchLabel
} from '../../../components/payer-search-omnibox/payer-search-omnibox';
import { observer, inject } from 'mobx-react';
import { VirtualTerminalFormViewModel } from '../form/virtual-terminal-form-view-model';
import { IVirtualTerminalPayerViewModel } from './payer-view-model';
import { ModalDialogCommander } from '../../../components/modal-dialog-commander';
import { RenderResponsive } from '../../../components/hoc-behavior/render-responsive';
import { ResponsiveVisibility, ResponsiveBreakpoint, responsiveHelper } from '../../../helpers/responsive-helper';
import { AddMemberDialog } from '../member/add-member-dialog';
import { communityMemberModelToViewModel } from '../../utils/member-view-model-helper';
import { checkNounLowerCase } from '../../utils/check-translator';
import { Metrics } from '../../utils/metrics';
import { IVirtualTerminalFormUserActionCreator } from '../form/virtual-terminal-form-user-action-creator';

@inject('userActionCreator')
@observer
export class PayerSearchOmnibox extends React.Component<{ vm: VirtualTerminalFormViewModel, userActionCreator?: IVirtualTerminalFormUserActionCreator,
	paymentLabel: PaymentLabel }, {}> {
	render() {
		const { paymentLabel } = this.props;
		const {
			currentSearchResult,
			checkReaderState,
			omniboxValue,
			searchValue,
			openRequested,
			listingStore,
			loadMoreInProgress,
			selectedPayer: payer } = this.props.vm;

		const payerNoun = listingStore.selectedListing.PaymentLabel.PayerSentenceCase;
		const readingCheck = checkReaderState === 'reading';
		const value = payer ? payer.name : omniboxValue;

		return (
			<PayerSearchContainer className={readingCheck ? ' check-reading' : ''}>
				<PayerSearchLabel payerNoun={payerNoun}>
					{readingCheck ?
						<span className="input-substitution-text">Reading check...</span>
						: null}
					{this.isPayerSelectedAndNotAnonymous && <RenderResponsive visibility={ResponsiveVisibility.Visible} breakpoints={ResponsiveBreakpoint.Xs}>
						<PayerSearchEditButton onEditHandler={this.handleEditMember} />
					</RenderResponsive>}
					<PayerSearchInputField
						focusOnMount={!(responsiveHelper.isXs || responsiveHelper.isSm)}
						value={value}
						placeholder={this.getInputFieldPlaceholder()}
						onChange={this.handleInputValueChange} />
					<PayerSearchClearButton selectedPayer={payer} onClick={this.handleClear} />

					<SelectedPayerInfo selectedPayer={payer}>
						{this.isPayerSelectedAndNotAnonymous && <RenderResponsive visibility={ResponsiveVisibility.Hidden} breakpoints={ResponsiveBreakpoint.Xs}>
							<PayerSearchEditButton onEditHandler={this.handleEditMember} />
						</RenderResponsive>}
					</SelectedPayerInfo>
					{checkReaderState === 'failure' ?
						<span className="payer-search-info"><span className="payer-search-info-error">Unsuccessful read, please try again</span></span>
						: null}
				</PayerSearchLabel>

				<PayerSearchDropDownMenu
					componentId="vt-omnibox"
					searchResult={currentSearchResult}
					searchValue={searchValue}
					onChange={this.handleSelectedPayerChange}
					selectedPayer={payer}
					openRequested={openRequested}
					onLoadMore={this.handleLoadMore}
					loadMoreInProgress={loadMoreInProgress}
					onMenuStateChange={this.handleMenuStateChange}
					onAddNewCommunityMember={this.handleAddMember}
					paymentLabel={paymentLabel}
					enableFeatureOrganizationalGiving={this.props.vm.listingStore.selectedListingHasOrganizationalGivingEnabled}  />
			</PayerSearchContainer>
		);
	}

	private get disabled() {
		return this.props.vm.paymentSearchDisabled;
	}

	private handleInputValueChange = (event: React.FormEvent<HTMLInputElement>) => {
		if (this.disabled) {
			return;
		}

		this.props.userActionCreator.OmniboxValueChange(event.currentTarget.value);
	}

	private handleClear = () => {
		if (this.disabled) {
			return;
		}

		this.props.userActionCreator.OmniboxValueChange('');
	}

	private handleSelectedPayerChange = (payer: IVirtualTerminalPayerViewModel) => {
		if (this.disabled) {
			return;
		}

		this.props.userActionCreator.SelectedPayerChange(payer);
	}

	private handleLoadMore = () => {
		if (this.disabled) {
			return;
		}

		this.props.userActionCreator.LoadMorePayers();
	}

	private handleMenuStateChange = (open: boolean) => {
		if (this.disabled) {
			return;
		}

		this.props.vm.handleMenuStateChange(open);
	}

	private handleAddMember = () => {
		Metrics.addPayerStarted();
		const { ListingId: listingId, HomeCountry: homeCountry, PaymentLabel } = this.props.vm.listingStore.selectedListing;
		const { omniboxValue, selectedPayer } = this.props.vm;
		const fullName = selectedPayer ? selectedPayer.name : omniboxValue;
		const enableFeatureOrganizationalGivingEnabled = this.props.vm.listingStore.selectedListingHasOrganizationalGivingEnabled;
		ModalDialogCommander.showReactForm(<AddMemberDialog MerchantId={listingId}
			DefaultCountry={homeCountry}
			onCommunityMemberAdded={this.handleMemberAdded}
			fullName={fullName}
			paymentLabel={PaymentLabel}
			enableFeatureOrganizationalGiving={enableFeatureOrganizationalGivingEnabled}
		/>);
	}

	private handleEditMember = () => {
		Metrics.editPayerStarted();
		const member = this.props.vm.selectedMember;
		const enableFeatureOrganizationalGivingEnabled = this.props.vm.listingStore.selectedListingHasOrganizationalGivingEnabled;

		ModalDialogCommander.showReactForm(<EditMemberDialog memberId={member.CommunityMemberId}
			details={member}
			onCommunityMemberUpdated={this.onMemberEdited}
			enableFeatureOrganizationalGiving={enableFeatureOrganizationalGivingEnabled}/>,
			{ elementToFocusSelector: '[name=FirstName]' });
	}

	private onMemberEdited = (member: IMemberViewModel) => {
		Metrics.editPayerFinished();
		this.props.vm.editSelectedMember(member);
	}

	private get isPayerSelectedAndNotAnonymous() {
		return this.props.vm.selectedPayer && !this.props.vm.selectedPayer.isAnonymous;
	}

	private handleMemberAdded = (member: IMemberViewModel) => {
		Metrics.addPayerFinished();
		const payer = communityMemberModelToViewModel(member);
		this.props.userActionCreator.SelectedPayerChange(payer);
	}

	private getInputFieldPlaceholder() {
		const { listingStore } = this.props.vm;
		const supportsCash = listingStore.selectedListingSupportsCash;
		const supportsRecordedCheck = listingStore.selectedListingSupportsRecordedCheck;

		let defaultPlaceholder = `Type name`;

		if (supportsCash && supportsRecordedCheck) {
			return `${defaultPlaceholder}, anon or read a ${checkNounLowerCase(listingStore.selectedListing.HomeCountry)}`;
		}

		if (supportsCash) {
			return `${defaultPlaceholder} or anon`;
		}

		if (supportsRecordedCheck) {
			return `${defaultPlaceholder} or read a ${checkNounLowerCase(listingStore.selectedListing.HomeCountry)}`;
		}

		return defaultPlaceholder;
	}
}


