import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { action, observable, reaction, computed } from 'mobx';
import { observer } from 'mobx-react';
import { makeAjaxCall } from '../utils/ajax-helper';
import { FormControlLabel } from '../../components/form-controls/form-control-label';
import { ModalDialogCommander } from '../../components/modal-dialog-commander';
import { Button } from '../../components/button';
import { SvgWrapper } from '../../components/svg-wrapper';
import { ValidationMessage } from '../../components/form-controls/validation-message';
import { Form, InputField, TextareaField } from './form-controls';
import { ValidationSummary } from '../../components/form-controls/validation-summary';
import getIntegrationFundsForDisplay from '../../integrations/helpers/get-integration-funds-for-display';
import { Autocomplete, AutocompleteStore } from '../../components/autocomplete';
import { IntegrationFundAutocomplete, IntegrationFundAutocompleteStore } from '../../integrations/components/integration-fund-autocomplete';

import { ResponseTypes, ModelMetadata } from '../funds-generated';
import ValidationErrorModel = ResponseTypes.ValidationErrorModel;
import ImportPushpayFund = ResponseTypes.ImportPushpayFund;
import ImportIntegrationFund = ResponseTypes.ImportIntegrationFund;
import QuickBooksManageFundInfo = ResponseTypes.QuickBooksManageFundInfo;
import AddNewFundModel = ResponseTypes.AddNewFundModel;

export interface IntegrationFundViewModel {
	Key: string;
	Code: string;
	Name: string;
	ParentFundKey: string;
}

export interface IntegrationFundsInfo {
	DisplayName: string;
	ExternalSystemId: number;
	IntegrationFunds: IntegrationFundViewModel[];
}

export class AddNewFundStore {
	@observable isProcessingRequest: boolean;
	@observable pushpayFund: ImportPushpayFund;
	@observable validationErrors: string[];
	@observable addFundDisabled: boolean;
	@observable integrationFunds: ImportIntegrationFund[];
	@observable fundName: string;
	@observable fundCode: string;
	@observable taxDeductible: boolean;
	@observable notes: string;
	externalSystemId: number;
	integrationName: string;
	fundCodePlaceholderText: string;
	payerLabel: string;
	integrationFundsAutocomplete: IntegrationFundAutocompleteStore;
	integrationFundProps: IntegrationFundRowProp[];
	quickBooksConfigurationProps: QuickBooksConfigurationRowProp[];
	private addNewFundUrl: string;

	constructor(addNewFundUrl: string,
		integrationFunds: ImportIntegrationFund[],
		externalSystemId: number,
		integrationName: string,
		fundCodePlaceholderText: string,
		payerLabel: string,
		integrationFundsInfo: IntegrationFundsInfo[],
		quickBooksManageFundInfo: QuickBooksManageFundInfo) {
		this.addNewFundUrl = addNewFundUrl;
		this.externalSystemId = externalSystemId;
		this.integrationName = integrationName;
		this.fundCodePlaceholderText = fundCodePlaceholderText;
		this.payerLabel = payerLabel;
		this.integrationFunds = integrationFunds;
		this.integrationFundsAutocomplete = new IntegrationFundAutocompleteStore(
			integrationFunds,
			null
		);
		this.integrationFundProps = integrationFundsInfo ? integrationFundsInfo.map(x => {
			const autoCompleteItems = getIntegrationFundsForDisplay(x.IntegrationFunds);
			return {
				externalSystemId: x.ExternalSystemId,
				displayName: x.DisplayName,
				integrationFundStore: new AutocompleteStore({Items: autoCompleteItems, SelectedValue: null}),
			};
		}) : [];

		this.initializeQuickBooksConfiguration(quickBooksManageFundInfo);
	}

	initializeQuickBooksConfiguration(quickBooksManageFundInfo: QuickBooksManageFundInfo) {
		this.quickBooksConfigurationProps = [];
		if (quickBooksManageFundInfo) {
			const accountItems = getIntegrationFundsForDisplay(quickBooksManageFundInfo.Configuration.Accounts);
			const integrationAccountInfo = {
				externalConfigurationId: quickBooksManageFundInfo.IntegrationMapping.AccountId,
				displayName: 'Account',
				integrationConfigurationStore: new AutocompleteStore({ Items: accountItems, SelectedValue: null }),
				isRequired: true,
				usesRegisteredSymbol: true,
			};
			this.quickBooksConfigurationProps.push(integrationAccountInfo);

			if (quickBooksManageFundInfo.CanClassesBeConfigured) {
				const classItems = getIntegrationFundsForDisplay(quickBooksManageFundInfo.Configuration.Classes);
				const integrationClassInfo = {
					externalConfigurationId: quickBooksManageFundInfo.IntegrationMapping.ClassId,
					displayName: 'Class',
					integrationConfigurationStore: new AutocompleteStore({ Items: classItems, SelectedValue: null }),
					isRequired: false,
					usesRegisteredSymbol: false,
				};
				this.quickBooksConfigurationProps.push(integrationClassInfo);
			}

			if (quickBooksManageFundInfo.CanLocationsBeConfigured) {
				const locationItems = getIntegrationFundsForDisplay(quickBooksManageFundInfo.Configuration.Locations);
				const integrationLocationItem = {
					externalConfigurationId: quickBooksManageFundInfo.IntegrationMapping.LocationId,
					displayName: 'Location',
					integrationConfigurationStore: new AutocompleteStore({ Items: locationItems, SelectedValue: null }),
					isRequired: false,
					usesRegisteredSymbol: false,
				};
				this.quickBooksConfigurationProps.push(integrationLocationItem);
			}
		}
	}

	@computed
	private get addNewFundModelData(): AddNewFundModel {
		return {
			ExternalSystemId: this.externalSystemId,
			IntegrationFund: this.integrationFundsAutocomplete.selectedValue,
			FundName: this.fundName,
			Code: this.fundCode,
			TaxDeductible: this.taxDeductible,
			Notes: this.notes,
			IntegrationFundMatches: this.matchedIntegrationFunds,
			QuickBooksIntegrationMapping: this.matchedQuickBooksConfiguration
		};
	}

	@action
	addNewFund = (dialog: Element | Text) => {
		this.isProcessingRequest = true;
		makeAjaxCall(this.addNewFundUrl, this.addNewFundModelData)
			.fail((validationErrorModel: ValidationErrorModel) => {
				this.validationErrors = validationErrorModel.ValidationErrors.map(x => x.ErrorMessage);
			})
			.done((newFund: ImportPushpayFund) => {
				this.pushpayFund = newFund;
				ModalDialogCommander.close(dialog);
			})
			.always(() => {
				this.isProcessingRequest = false;
			});
	}

	@action
	launch = () => {
		this.reset();
		ModalDialogCommander.showReactDialog(this.addNewFundDialog());
	}

	@action
	updateFundName = (name: string) => {
		this.fundName = name;
	}

	@action
	updateFundCode = (code: string) => {
		this.fundCode = code;
	}

	@action
	updateNotes = (notes: string) => {
		this.notes = notes;
	}

	@action
	checkTaxDeductible = () => {
		this.taxDeductible = true;
	}

	@action
	uncheckTaxDecuctible = () => {
		this.taxDeductible = false;
	}

	@action
	private reset = () => {
		this.isProcessingRequest = false;
		this.validationErrors = null;
		this.addFundDisabled = true;
		this.fundName = null;
		this.fundCode = null;
		this.taxDeductible = null;
		this.notes = null;
		this.integrationFundsAutocomplete.selectedItem = null;
		this.integrationFundsAutocomplete.searchTerm = null;
		reaction(() => this.integrationFundsAutocomplete.selectedItem, this.enableForm);
		this.integrationFundProps.map(f => {
			f.integrationFundStore.selectedItem = null;
			f.integrationFundStore.searchTerm = null;
		});
		this.quickBooksConfigurationProps.map(c => {
			c.integrationConfigurationStore.selectedItem = null;
			c.integrationConfigurationStore.searchTerm = null;
		});
	}

	@action
	private enableForm = () => {
		if (!this.integrationFundsAutocomplete.selectedItem) {
			return;
		}

		this.addFundDisabled = false;
		const selectedIntegrationFund = this.integrationFunds.find(x => x.Key === this.integrationFundsAutocomplete.selectedValue);
		this.fundName = selectedIntegrationFund.Name;
		this.fundCode = selectedIntegrationFund.Code;
		this.taxDeductible = selectedIntegrationFund.TaxDeductible;
	}

	private addNewFundDialog = () => <ModalAddNewFundDialog addNewFundStore={this} />;

	get matchedIntegrationFunds() {
		return this.integrationFundProps.map(fund => ({
			ExternalSystemId: fund.externalSystemId,
			IntegrationFundKey: fund.integrationFundStore.selectedValue,
		}));
	}

	get matchedQuickBooksConfiguration() {
		if (this.quickBooksConfigurationProps.length === 0) {
			return null;
		}

		const accountConfiguration = this.quickBooksConfigurationProps.filter(x => x.displayName === 'Account')[0];
		const classConfiguration = this.quickBooksConfigurationProps.filter(x => x.displayName === 'Class')[0];
		const locationConfiguration = this.quickBooksConfigurationProps.filter(x => x.displayName === 'Location')[0];

		return {
			FundId: null,
			AccountId: accountConfiguration.integrationConfigurationStore.selectedValue,
			ClassId: classConfiguration ? classConfiguration.integrationConfigurationStore.selectedValue : null,
			LocationId: locationConfiguration ? locationConfiguration.integrationConfigurationStore.selectedValue : null
		};
	}
}

@observer
export class ModalAddNewFundDialog extends React.Component<{ addNewFundStore: AddNewFundStore }, {}> {
	render() {
		const {
			integrationFundProps,
			quickBooksConfigurationProps,
		} = this.props.addNewFundStore;
		return (
			<div className="modal modal-form fade" tabIndex={-1} role="dialog" data-modal-prevent-close={this.store.isProcessingRequest}>
				<Form onSubmit={this.handleSubmit} className="panel panel-default form-horizontal modal-dialog">
					<button type="button" className="modal-form-close" data-dismiss="modal" aria-label="Close"></button>
					<div className="panel-heading">
						Add new Pushpay fund to match with {this.store.integrationName}
					</div>
					<div className="panel-body">
						<ValidationSummary validationErrors={this.store.validationErrors} />
						<div className="form-group">
							<label className="control-label col-md-4">Select {this.store.integrationName} fund</label>
							<div className="col-md-5 link-line-container">
								<IntegrationFundAutocomplete fieldName={this.metadata.IntegrationFund.propertyName}
									autocompleteStore={this.store.integrationFundsAutocomplete}
									emptyText="- Select matching fund -"
									validationRules={this.metadata.IntegrationFund.validationRules} />
								<div className={`link-line${this.store.integrationFundsAutocomplete.selectedItem ? ' linked' : ''}`}>
									<div className="link-line-icon">
										<SvgWrapper svg="icon-link" className="svg svg-icon-link" />
									</div>
								</div>
							</div>
							<div className="col-md-3">
								<ValidationMessage elementName={this.metadata.IntegrationFund.propertyName} />
							</div>
						</div>
						<div className="field-container">
							<div className="form-group">
								<label className="control-label col-md-4">{this.metadata.FundName.displayName}</label>
								<div className="col-md-5">
									<InputField type="text" propertyMetadata={this.metadata.FundName} placeholder="E.g. Tithes & Offerings"
										disabled={this.store.addFundDisabled} value={this.store.fundName} onChange={this.handleFundNameUpdate} />
								</div>
								<div className="col-md-3">
									<ValidationMessage elementName={this.metadata.FundName.propertyName} />
								</div>
							</div>
							<div className="form-group">
								<div className="col-md-4">
									<FormControlLabel
										label={this.metadata.Code.displayName}
										elementName={this.metadata.Code.propertyName}
										tooltipOptions={{message: `The Fund code is what will be populated in your transaction exports.
											If you need a specific value exported (i.e the matching ${this.store.fundCodePlaceholderText} for your Church Management
											System), enter this here. The Fund code is not displayed to ${this.store.payerLabel}.`,
										placement: 'top'}}/>
								</div>
								<div className="col-md-5">
									<InputField type="text" propertyMetadata={this.metadata.Code} placeholder={this.store.fundCodePlaceholderText}
										disabled={this.store.addFundDisabled} value={this.store.fundCode} onChange={this.handleFundCodeUpdate} />
								</div>
							</div>
							<div className="form-group">
								<div className="col-md-4">
									<FormControlLabel
										label={this.metadata.TaxDeductible.displayName}
										elementName={this.metadata.TaxDeductible.propertyName}
										tooltipOptions={{message: `Indicate whether the fund will be used for a tax deductible purpose.
											This setting will define whether the fund is eligible for giving statements in the future.`,
										placement: 'top'}}/>
								</div>
								<div className="col-md-5">
									<label className="radio-inline">
										<InputField type="radio" propertyMetadata={this.metadata.TaxDeductible} value={'true'} checked={this.store.taxDeductible}
											disabled={this.store.addFundDisabled} onChange={this.store.checkTaxDeductible} />
										<span className="radio-inner">Yes</span>
									</label>
									<label className="radio-inline">
										<InputField type="radio" propertyMetadata={this.metadata.TaxDeductible} value={'false'}
											checked={this.store.taxDeductible === false} disabled={this.store.addFundDisabled}
											onChange={this.store.uncheckTaxDecuctible} />
										<span className="radio-inner">No</span>
									</label>
								</div>
								<div className="col-md-3">
									<ValidationMessage elementName={this.metadata.TaxDeductible.propertyName} />
								</div>
							</div>
							<div className="form-group">
								<div className="col-md-4">
									<label className="control-label">{this.metadata.Notes.displayName}</label>
								</div>
								<div className="col-md-5">
									<TextareaField type="textarea" propertyMetadata={this.metadata.Notes} disabled={this.store.addFundDisabled}
										value={this.store.notes} onChange={this.handleNotesUpdate} placeholder={`Notes are not shown to your ${this.store.payerLabel}`} />
								</div>
							</div>
							{
								integrationFundProps.map( integrationFundProp => (
									<IntegrationFundRow key={integrationFundProp.externalSystemId} {...integrationFundProp}/>
								))
							}
							{
								quickBooksConfigurationProps.map( quickBooksConfigurationProp => (
									<QuickBooksConfiguration key={quickBooksConfigurationProp.externalConfigurationId} {...quickBooksConfigurationProp}/>
								))
							}
							<div className={`disabled-field-overlay${this.store.addFundDisabled ? ' active' : ''}`}></div>
						</div>
					</div>
					<div className="panel-footer panel-footer-btn-group">
						<button type="button"
							className="btn btn-cancel"
							onClick={this.handleCancel}
							style={{ display: this.store.isProcessingRequest ? 'none' : '' }}
							data-dismiss="modal">
							Cancel
						</button>
						<Button className="btn btn-default" type="submit" disabled={this.store.addFundDisabled}
							isProcessingRequest={this.store.isProcessingRequest}>
							Save
						</Button>
					</div>
				</Form>
			</div>
		);
	}
	private handleCancel = (ev: React.MouseEvent<HTMLButtonElement>) => {
		ev.preventDefault();
	}
	private handleFundNameUpdate = (ev: React.FormEvent<HTMLInputElement>) => {
		this.store.updateFundName(ev.currentTarget.value);
	}
	private handleFundCodeUpdate = (ev: React.FormEvent<HTMLInputElement>) => {
		this.store.updateFundCode(ev.currentTarget.value);
	}
	private handleNotesUpdate = (ev: React.FormEvent<HTMLTextAreaElement>) => {
		this.store.updateNotes(ev.currentTarget.value);
	}
	private handleSubmit = () => {
		this.store.addNewFund(ReactDOM.findDOMNode(this));
	}

	private get store() {
		return this.props.addNewFundStore;
	}
	private get metadata() {
		return ModelMetadata.AddNewFundModel;
	}
}

interface IntegrationFundRowProp {
	externalSystemId: number;
	displayName: string;
	integrationFundStore: AutocompleteStore;
}

const IntegrationFundRow: React.StatelessComponent<IntegrationFundRowProp> = (props) => {
	const { externalSystemId, displayName, integrationFundStore } = props;
	const elementName = `externalsystem_${externalSystemId}`;
	return (
		<div className="form-group">
			<div className="control-label col-md-4">
				<FormControlLabel
					label={displayName}
					elementName={elementName}
					tooltipOptions={{
						message: `Select the matching fund within ${displayName}. Pushpay transactions will be assigned to this fund when we generate the contribution in ${displayName}`,
						placement: 'top'}}/>
			</div>
			<div className="col-md-5">
				<Autocomplete
					emptyText="- Select matching fund -"
					autocompleteStore={integrationFundStore}
					fieldName={elementName}
					validationRules={{required: {errorMessage: 'Please select a matching fund'}}}/>
			</div>
			<div className="control-label col-md-3">
				<ValidationMessage elementName={elementName} />
			</div>
		</div>
	);
};

interface QuickBooksConfigurationRowProp {
	externalConfigurationId: string;
	displayName: string;
	integrationConfigurationStore: AutocompleteStore;
	isRequired: boolean;
	usesRegisteredSymbol: boolean;
}

const QuickBooksConfiguration: React.StatelessComponent<QuickBooksConfigurationRowProp> = (props) => {
	const { displayName, integrationConfigurationStore, isRequired, usesRegisteredSymbol } = props;
	const lowerCaseDisplayName = displayName.toLowerCase();
	const elementName = `qbo_${lowerCaseDisplayName}`;
	return (
		<div className="form-group">
			<div className="control-label col-md-4">
				<FormControlLabel
					label={`QuickBooks` + (usesRegisteredSymbol ? `\xAE` : ``) + ` ${displayName}`}
					elementName={elementName}
					tooltipOptions={{
						message: `Select the matching ${lowerCaseDisplayName} within QuickBooks. `
							+ `Pushpay settlements will be assigned to this ${lowerCaseDisplayName} when we generate the journal entry in QuickBooks.`,
						placement: 'top'}}/>
			</div>
			<div className="col-md-5">
				<Autocomplete
					emptyText={`- Select matching ${lowerCaseDisplayName} -`}
					autocompleteStore={integrationConfigurationStore}
					fieldName={elementName}
					validationRules={ isRequired ? {required: {errorMessage: `Please select a matching ${lowerCaseDisplayName}`}} : null}/>
			</div>
			<div className="control-label col-md-3">
				<ValidationMessage elementName={elementName} />
			</div>
		</div>
	);
};
