export class LoginPromoMaterial {
	private resizeEventListener: EventListenerOrEventListenerObject;
	private frame: HTMLIFrameElement;
	private hasBeenSet: boolean = false;

	constructor() {
		this.frame = document.querySelector('[data-pp-login-promo-iframe]') as HTMLIFrameElement;

		if (this.frame) {
			if ($(this.frame).is(':visible')) {
				this.loadPromoMaterial();
			} else {
				this.resizeEventListener = () => this.loadPromoMaterialIfVisible();
				window.addEventListener('resize', this.resizeEventListener);
			}
		}
	}

	loadPromoMaterialIfVisible() : void {
		if (this.frame && !this.hasBeenSet) {
			if ($(this.frame).is(':visible')) {
				this.loadPromoMaterial();
				window.removeEventListener('resize', this.resizeEventListener);
			}
		}
	}

	private loadPromoMaterial() : void {
		this.hasBeenSet = true;
		this.frame.src = $(this.frame).data('src');
	}
}
