import * as React from 'react';
import { observable, runInAction, action } from 'mobx';
import { observer } from 'mobx-react';

import { isFunction } from '../../utils/is-function';

import { FileUploadState, FileUploadStateFileSelected } from './file-upload-state';
import * as styles from './file-upload-styles.less';
import { SvgWrapper } from '../svg-wrapper';

export interface IFilePreviewPanelProps {
	fileCurrent: FileUploadStateFileSelected;
	acceptanceTestTargetId: string;
	onChangeHandler?: (file: File | null) => void;
}

export class PreviewImagePanel extends React.Component<IFilePreviewPanelProps> {
	render() {
		const {
			fileCurrent,
			acceptanceTestTargetId,
			onChangeHandler,
		} = this.props;

		return (
			<PreviewPanel
				fileCurrent={fileCurrent}
				acceptanceTestTargetId={acceptanceTestTargetId}
				onChangeHandler={onChangeHandler}>
			<div>not implemented yet, but look for me in PreviewImagePanel</div>
			</PreviewPanel>
		);
	}
}

export class PreviewFilePanel extends React.Component<IFilePreviewPanelProps> {
	render() {
		const {
			fileCurrent,
			acceptanceTestTargetId,
			onChangeHandler,
		} = this.props;

		return (
			<PreviewPanel
				fileCurrent={fileCurrent}
				acceptanceTestTargetId={acceptanceTestTargetId}
				onChangeHandler={onChangeHandler}>
				<div>{fileCurrent.previewUrl}</div>
			</PreviewPanel>
		);
	}
}

class PreviewPanel extends React.Component<IFilePreviewPanelProps> {
	render() {
		const {
			fileCurrent,
			children
		} = this.props;

		return (
			<div className={styles.previewContainer}>
				{children}
				{this.renderRemoveFileButton()}
			</div>
		);
	}

	private renderRemoveFileButton = () => {
		const {
			acceptanceTestTargetId = '',
		} = this.props;

		return (
			<button className={styles.close} onClick={this.removeExistingFile} data-pp-at-target={acceptanceTestTargetId + ' Remove Button'}>
				<SvgWrapper className={styles.closeIcon} svg="close-cross-small" title="Remove" />
			</button>
		);
	}

	private removeExistingFile = () => {
		const { onChangeHandler } = this.props;
		if (isFunction(onChangeHandler)) {
			this.props.onChangeHandler(null);
		}
	}
}
