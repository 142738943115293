import { observable, action } from 'mobx';

import { ScheduleImportStepViewModel, ImportStep} from '../schedule-import-step-view-model';
import {
	SelectItem,
	ScheduleImportFormat,
	CreateImportViewModel,
	OperationResponse,
	OperationResult,
} from '../../schedule-import-generated';
import { getScheduleImportDataService, ScheduleImportDataServiceAction, ScheduleImportApiConfigType, } from '../../schedule-import-data-service';
import { IDataServiceActionSubscriber } from '../../../utils/data-service';

export class ScheduleImportCreateViewModel extends ScheduleImportStepViewModel {
	readonly allImportTypes: SelectItem[];
	readonly allVisibleListings: SelectItem[];
	readonly cancelUrl: string;
	readonly prepareDataUrl: string;
	readonly unavailableRecurringFrequencies: string[];
	readonly supportUrl: string;

	@observable importType: ScheduleImportFormat;
	@observable merchantId: number;

	createImportSubscriber: IDataServiceActionSubscriber<ScheduleImportApiConfigType, 'createImport'>;

	constructor(model: CreateImportViewModel) {
		super(model.PaymentLabel, ImportStep.Select, 'Select source',
			'Select your file to upload. For details on the data format requirements and tips on preparing your data see ');

		this.allImportTypes = model.AllImportTypes;
		this.allVisibleListings = model.AllVisibleListings;
		this.cancelUrl = model.CancelUrl;
		this.unavailableRecurringFrequencies = model.UnavailableRecurringFrequencies;
		this.supportUrl = model.SupportUrl;

		this.importType = ScheduleImportFormat.Unknown;
		this.merchantId = model.AllVisibleListings.length === 1 ? Number(model.AllVisibleListings[0].Value) : 0;

		this.prepareDataUrl = model.PrepareDataHelpUrl;

		this.createImportSubscriber = getScheduleImportDataService().getActionSubscriberFactory('createImport')((action) => this.subscribeToCreateImport(action));
	}

	@action.bound
	updateImportType(value: string) {
		if (ScheduleImportFormat[value]) {
			this.importType = Number(value);
		}
	}

	@action.bound
	updateSelectedListing(value: string) {
		const listingId = Number(value);
		if (listingId) {
			this.merchantId = listingId;
		}
	}

	@action.bound
	initCreateImportRequest() {
		this.actionInProgress = true;
		this.createImportSubscriber.initRequest({
			model: {
				ImportType: this.importType,
				MerchantId: this.merchantId
			}
		});
	}

	@action.bound
	cancelImport() {
		this.actionInProgress = true;
		window.location.href = this.cancelUrl;
	}

	private subscribeToCreateImport(action: ScheduleImportDataServiceAction) {
		switch (action.type) {
			case 'request_success':
				const response: OperationResponse  =  action.response;
				if (response.Result === OperationResult.Success) {
					window.location.href = response.SuccessUrl;
					return;
				} else {
					this.showErrorAlert(response.ErrorMessage);
				}
				break;
			case 'request_error':
				const validationErrors = action.error.validationErrors;
				if (validationErrors) {
					this.showValidationErrorsAlert(validationErrors);
				}
				break;
		}
		this.actionInProgress = false;
	}
}
