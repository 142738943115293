import { ActionCreator } from '../../../utils/user-action-creator';
import { ScheduleDetailsSortableColumn, ExportAction, ScheduleEditRequest } from '../../schedule-import-generated';
import { SortDirection } from '../../../components/data-grid/data-grid';


export namespace ScheduleDetailsUserAction {
	export class GetScheduleList {}

	export class GetScheduleDetails {
		constructor(public scheduleImportDraftId: number) {}
	}

	export class DeleteSchedule {
		constructor(public scheduleImportDraftId: number) {}
	}

	export class UpdateFilters {
		constructor(public selectedStatus: string[],
					public selectedListings: string[],
					public selectedFunds: string[],
					public shouldUpdateLocationState: boolean) {}
	}

	export class ExportSchedules {
		constructor(public exportAction: ExportAction,
					public selectedStatus: string[],
					public selectedListings: string[],
					public selectedFunds: string[]) {}
	}

	export class ChangePageNumber {
		constructor(public pageNumber: number) {}
	}

	export class ChangeGridSortedBy {
		constructor(public newSortedBy: ScheduleDetailsSortableColumn, public sortDirection: SortDirection ) {}
	}

	export class ShowEditScheduleDetailsDialog {
		constructor() {}
	}

	export class EditScheduleDetails {
		constructor(public scheduleDetails: ScheduleEditRequest) {}
	}
}

export type ScheduleDetailsUserAction = typeof ScheduleDetailsUserAction[keyof typeof ScheduleDetailsUserAction]['prototype'];
export type ScheduleDetailsUserActionCreator = ActionCreator<typeof ScheduleDetailsUserAction>;

