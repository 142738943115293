import { MachineConfig } from 'xstate/lib/types';
import State from './campaigns-landing-states';
import { CampaignsLandingAction as Action } from './campaigns-landing-event-actions';
import { RxMachineContext } from '../../../../../Shared/state-machine/rx-state-machine';
import { createEvent, transitionSymbol } from '../../../../../Shared/state-machine/rx-state-machine-factory';
import { campaignsLandingSubject } from '../campaigns-landing-intent';

const Eventz = {
	showWelcome: createEvent('SHOW_WELCOME', State.Welcome),
	startCampaignsManagement: createEvent('START_CAMPAIGNS_MANAGEMENT', State.CampaignsManagement),
	loadDraftCampaigns: createEvent('LOAD_DRAFT_CAMPAIGNS', State.LoadingMore, Action.loadDraftCampaigns),
	processDraftCampaignsComplete: createEvent('PROCESS_DRAFT_CAMPAIGNS_COMPLETE', State.Idle),
	loadActiveOrScheduledCampaigns: createEvent('LOAD_ACTIVE_SCHEDULED_CAMPAIGNS', State.LoadingMore, Action.loadActiveOrScheduledCampaigns),
	processActiveOrScheduledCampaignsComplete: createEvent('PROCESS_ACTIVE_SCHEDULED_CAMPAIGNS_COMPLETE', State.Idle),
	loadClosedCampaigns: createEvent('LOAD_CLOSED_CAMPAIGNS', State.LoadingMore, Action.loadClosedCampaigns),
	processClosedCampaignsComplete: createEvent('PROCESS_CLOSED_CAMPAIGNS_COMPLETE', State.Idle),
	collapseDraftCampaigns: createEvent('COLLAPSE_DRAFT', State.Collapsed),
	expandDraftCampaigns: createEvent('EXPAND_DRAFT', State.Expanded),
	collapseActiveOrScheduledCampaigns: createEvent('COLLAPSE_ACTIVE_SCHEDULED', State.Collapsed),
	expandActiveOrScheduledCampaigns: createEvent('EXPAND_ACTIVE_SCHEDULED', State.Expanded),
	collapseClosedCampaigns: createEvent('COLLAPSE_CLOSED', State.Collapsed),
	expandClosedCampaigns: createEvent('EXPAND_CLOSED', State.Expanded),
	startPollingCollectedAmounts: createEvent('START_POLLING_COLLECT_AMOUNTS', State.On, Action.getCollectedAmounts),
	completePollingCollectedAmounts: createEvent('COMPLETE_POLLING_COLLECT_AMOUNTS', State.Off),
	processFailure: createEvent('PROCESS_FAILURE', State.On),
	processFailureComplete: createEvent('PROCESS_FAILURE_COMPLETE', State.Off),
};

export type ICampaignLandingEventCreator = { [P in keyof typeof Eventz]: ReturnType<(typeof Eventz)[P]> };

const draftStatusConfig = {
	[State.Draft]: {
		parallel: true,
		states: {
			[State.Collapsible]: {
				initial: State.Expanded,
				states: {
					[State.Expanded]: {
						on: Eventz.collapseDraftCampaigns[transitionSymbol]
					},
					[State.Collapsed]: {
						on: Eventz.expandDraftCampaigns[transitionSymbol]
					}
				}
			},
			[State.Managing]: {
				initial: State.Init,
				states: {
					[State.Init]: {
						onEntry: Action.loadDraftCampaigns,
						on: Eventz.processDraftCampaignsComplete[transitionSymbol]
					},
					[State.Idle]: {
						on: Eventz.loadDraftCampaigns[transitionSymbol]
					},
					[State.LoadingMore]: {
						on: Eventz.processDraftCampaignsComplete[transitionSymbol]
					},
				}
			}
		}
	}
};

const activeStatusConfig = {
	[State.ActiveOrScheduled]: {
		parallel: true,
		states: {
			[State.Collapsible]: {
				initial: State.Expanded,
				states: {
					[State.Expanded]: {
						on: Eventz.collapseActiveOrScheduledCampaigns[transitionSymbol]
					},
					[State.Collapsed]: {
						on: Eventz.expandActiveOrScheduledCampaigns[transitionSymbol]
					}
				}
			},
			[State.Managing]: {
				initial: State.Init,
				states: {
					[State.Init]: {
						onEntry: Action.loadActiveOrScheduledCampaigns,
						on: Eventz.processActiveOrScheduledCampaignsComplete[transitionSymbol]
					},
					[State.Idle]: {
						on: Eventz.loadActiveOrScheduledCampaigns[transitionSymbol]
					},
					[State.LoadingMore]: {
						on: Eventz.processActiveOrScheduledCampaignsComplete[transitionSymbol]
					},
				}
			}
		}
	}
};

const closedStatusConfig = {
	[State.Closed]: {
		parallel: true,
		states: {
			[State.Collapsible]: {
				initial: State.Expanded,
				states: {
					[State.Expanded]: {
						on: Eventz.collapseClosedCampaigns[transitionSymbol]
					},
					[State.Collapsed]: {
						on: Eventz.expandClosedCampaigns[transitionSymbol]
					}
				}
			},
			[State.Managing]: {
				initial: State.Init,
				states: {
					[State.Init]: {
						onEntry: Action.loadClosedCampaigns,
						on: Eventz.processClosedCampaignsComplete[transitionSymbol]
					},
					[State.Idle]: {
						on: Eventz.loadClosedCampaigns[transitionSymbol]
					},
					[State.LoadingMore]: {
						on: Eventz.processClosedCampaignsComplete[transitionSymbol]
					}
				}
			}
		}
	}
};

const machineConfig: MachineConfig = {
	key: 'Campaigns Landing State Machine',
	initial: State.Init,
	states: {
		[State.Init]: {
			onEntry: Action.initialize,
			on: {
				...Eventz.showWelcome[transitionSymbol],
				...Eventz.startCampaignsManagement[transitionSymbol]
			}
		},
		[State.Welcome]: {},
		[State.CampaignsManagement]: {
			parallel: true,
			states: {
				...draftStatusConfig,
				...activeStatusConfig,
				...closedStatusConfig,
				[State.PollingCollectedAmounts]: {
					initial: State.Off,
					states: {
						[State.On]: {
							on: Eventz.completePollingCollectedAmounts[transitionSymbol]
						},
						[State.Off]: {
							on: Eventz.startPollingCollectedAmounts[transitionSymbol]
						}
					}
				},
				[State.ShowingError]: {
					initial: State.Off,
					states: {
						[State.On]: {
							on: Eventz.processFailureComplete[transitionSymbol]
						},
						[State.Off]: {
							on: Eventz.processFailure[transitionSymbol]
						}
					}
				},
			}
		}
	}
};

export const createCampaignsLandingMachineContext = (payload: ReturnType<typeof Action.initialize>) =>
	RxMachineContext.connect<typeof Eventz>(machineConfig, campaignsLandingSubject)(Eventz, payload);
