import * as React from 'react';
import { ProgressCircle } from './progress-circle';
import { ProgressCount } from './progress-count';
import * as styles from './styles.less';
import { observer } from 'mobx-react';
import { isAnimationEnabled } from '../../helpers/isanimationenabled';

export interface IProgressIndicatorProps {
	totalCount: number;
	currentCount: number;
	units: string;
	animationDuration?: number;
}

interface IProgressIndicatorState {
	displayCount: number;
	progressPercentage: number;
}

interface IAnimationItem {
	totalCount: number;
	currentCount: number;
	animationDuration: number;
}

interface IAnimationProperties {
	startCount: number;
	startTime: number;
	animationDuration: number;
	countDelta: number;
	startPercentage: number;
	endPercentage: number;
}

@observer
export class ProgressIndicator extends React.Component<IProgressIndicatorProps, IProgressIndicatorState> {

	// Array of animations to perform.
	private animationQueue: IAnimationItem[] = [];

	// properties used by the currently playing animation
	private animationProps: IAnimationProperties | null = null;

	// Flag to indicate when animation is running
	private isAnimating: boolean = false;

	// id of current animation callback
	private animationFrameListener: number | null;

	constructor(props) {
		super(props);
		this.state = { displayCount: 0, progressPercentage: 0 };
	}

	componentDidMount() {
		const { totalCount, currentCount, animationDuration } = this.props;
		this.animationQueue.push({totalCount, currentCount, animationDuration: animationDuration || 1500});
		this.startAnimation();
	}

	UNSAFE_componentWillUpdate(nextProps: IProgressIndicatorProps, nextState: IProgressIndicatorState) {
		const { currentCount, totalCount } = this.props;
		if(nextProps.currentCount !== currentCount || nextProps.totalCount !== totalCount) {
			this.animationQueue.push({
				currentCount: nextProps.currentCount,
				totalCount: nextProps.totalCount,
				animationDuration: nextProps.animationDuration || 1500
			});
		}
	}

	componentDidUpdate() {
		if(!this.isAnimating && this.animationQueue.length) {
			this.startAnimation();
		}
	}

	componentWillUnmount() {
		if(this.animationFrameListener) {
			window.cancelAnimationFrame(this.animationFrameListener);
		}
		this.isAnimating = false;
		this.animationProps = null;
	}

	render() {
		const { units } = this.props;
		const { displayCount, progressPercentage } = this.state;
		return (
			<div className={styles.wrapper}>
				<ProgressCircle progressPercentage={progressPercentage} borderWidth={6} size={160}/>
				<ProgressCount displayCount={displayCount} units={units}/>
			</div>
		);
	}

	private startAnimation() {
		const { displayCount, progressPercentage } = this.state;

		const currentAnimation = this.animationQueue.pop();

		const countDelta = currentAnimation.currentCount - displayCount;
		const startPercentage = progressPercentage;
		const endPercentage = currentAnimation.currentCount / currentAnimation.totalCount;

		const hasPercentageChanged = startPercentage !== endPercentage;
		const hasCountChanged = countDelta !== 0;
		// only animate if percent completed or current count has changed
		if(hasPercentageChanged || hasCountChanged) {
			this.animationProps = {
				startTime: new Date().getTime(),
				animationDuration: currentAnimation.animationDuration,
				startCount: displayCount,
				countDelta,
				startPercentage,
				endPercentage,
			};
			if(isAnimationEnabled()) {
				this.isAnimating = true;
				this.animationFrameListener = window.requestAnimationFrame(() => this.animateFrame());
			} else {
				this.setState({
					progressPercentage: endPercentage,
					displayCount: currentAnimation.currentCount
				});
				if(this.animationQueue.length) {
					this.startAnimation();
				}
			}
		} else {
			this.isAnimating = false;
			this.animationProps = null;
			this.animationFrameListener = null;
			// start next animation in queue if there is one
			if(this.animationQueue.length) {
				this.startAnimation();
			}
		}
	}

	private animateFrame() {
		const { startTime, animationDuration,startPercentage, endPercentage, startCount, countDelta } = this.animationProps;
		const timeSinceStarted = new Date().getTime() - startTime;
		const percentCompleted = Math.min(1, timeSinceStarted / animationDuration); // Max out at 100% in case we've gone past the end time

		// calculate current percentage and count and set the state
		const currentPercentage = (endPercentage - startPercentage) * percentCompleted + startPercentage;
		const currentCount = Math.floor(startCount + (percentCompleted * countDelta));
		this.setState({displayCount: currentCount, progressPercentage: currentPercentage});

		// See if we should continue
		if(percentCompleted < 1) {
			this.animationFrameListener = window.requestAnimationFrame(() => this.animateFrame());
		} else {
			if(this.animationQueue.length) {
				this.startAnimation();
			} else {
				this.animationProps = null;
				this.isAnimating = false;
				this.animationFrameListener = null;
			}
		}
	}
}
