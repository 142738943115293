import * as React from 'react';
import {observer} from 'mobx-react';
import {Models} from '../check-deposit-generated';
import {Formatter} from '../../helpers/formatter';
import {BatchStillOpenDialog} from './batch-still-open-dialog';
import {BatchDismissRejectedDialog} from './batch-dismiss-rejected-dialog';
import {ModalDialogCommander} from '../../components/modal-dialog-commander';
import {SvgWrapper} from '../../components/svg-wrapper';
import {velocity} from '../../helpers/velocity';
import {BatchListStore} from './batch-list-store';
import {ResponsiveVisibility, ResponsiveBreakpoint} from '../../helpers/responsive-helper';
import {RenderResponsive} from '../../components/hoc-behavior/render-responsive';
import BatchItemViewModel = Models.BatchItemViewModel;
import UiBatchStatus = Models.UiBatchStatus;
import { BatchInfoLabel } from '../../components/batch-info-label';

const BatchListItemHeader = observer((props: { batch: BatchItemViewModel }) => {
	const { batch: { BatchName: batchName, Status: status } } = props;

	switch (status) {
		case UiBatchStatus.Populating:
		case UiBatchStatus.Reopened:
			return(
				<div className="list-group-item-large">
					<span className="list-group-item-title">{batchName}</span>
					<span className="label label-default list-group-item-label">Open</span>
				</div>
			);
		case UiBatchStatus.Validating:
			return(
				<div className="list-group-item-large">
					<span className="list-group-item-title">{batchName}</span>
					<span className="label label-warning list-group-item-label">Validating</span>
				</div>
			);
		case UiBatchStatus.ReadyToAllocate:
			return (
				<div className="list-group-item-large">
					<span className="list-group-item-title btn-link">{batchName}</span>
					<span className="label label-inprogress list-group-item-label">Ready<span className="hidden-xs"> to allocate</span></span>
				</div>
			);
		case UiBatchStatus.Closed:
			return(
				<div className="list-group-item-large">
					<span className="list-group-item-title btn-link">{batchName}</span>
					<SvgWrapper svg="ind-tick" className="cd-batch-list-item-closed-icon" />
				</div>
			);
		case UiBatchStatus.Rejected:
			return(
				<div className="list-group-item-large">
					<span className="list-group-item-title cd-batch-list-item-rejected">{batchName}</span>
					<span className="label label-default-secondary list-group-item-label">Rejected</span>
				</div>
			);
		default:
			return null;
	}
}) ;

const BatchDismissRejected = observer((props: { batch: BatchItemViewModel, openDismissRejectedDialog: () => void }) => {
	const { batch, openDismissRejectedDialog } = props;
	const isRejected = batch.Status === UiBatchStatus.Rejected;
	return(
		isRejected ?
		<span>
			<button type="button" className="btn btn-tertiary btn-tertiary-with-icon" onClick={openDismissRejectedDialog}>
				<SvgWrapper svg="close-cross" className="icon" />
				Remove from the list
			</button>
		</span>
		: null
	);
});

const BatchInfo = observer((props: { batch: BatchItemViewModel }) => {
	const { batch: { BatchId, ListingName, Status, LastUpdatedBy, StatusLastChangedOn, CreatedBy, CreatedOn } } = props;
	const isRejected = Status === UiBatchStatus.Rejected;
	return(
		<span className={`cd-batch-list-item-info ${isRejected ? ` cd-batch-list-item-rejected` : ``}`}>
			<span>Batch ID #{BatchId}</span>
			<span className="list-group-item-bullet"></span>
			<strong>{ListingName}</strong>
			<span className="list-group-item-light">
				<span className="list-group-item-bullet"></span>
				{
					Status === UiBatchStatus.Closed
						? <BatchInfoLabel prefix="Closed by" name={LastUpdatedBy} date={StatusLastChangedOn} isOnClosedListing={false} />
						: <BatchInfoLabel prefix={CreatedBy ? 'Created by' : 'Created'} name={CreatedBy} date={CreatedOn} isOnClosedListing={false} />
				}
			</span>
		</span>
	);
});

const BatchTotal = observer((props: { batch: BatchItemViewModel }) => {
	const { batch } = props;
	const isRejected = batch.Status === UiBatchStatus.Rejected;
	return(
		<div className={`list-group-right cd-batch-list-item-total-wrapper${isRejected ? ` cd-batch-list-item-rejected` : ``}`}>
			<div className="list-group-item-large cd-batch-list-item-total">
				<sup>$</sup>
				{Formatter.formatNumberForDisplay(batch.Total)}
			</div>
			<div className="no-wrap cd-batch-list-item-checks">
				<span className="hidden-xs">Number of checks: </span>
				<span className="visible-xs-inline">Checks: </span>
				<strong>{batch.NumberOfChecks}</strong>
			</div>
		</div>
	);
});

@observer
export class BatchListItem extends React.Component<{
	batch: BatchItemViewModel,
	store: BatchListStore
}> {
	private listItemWrapper: HTMLSpanElement;

	render() {
		const { batch, batch: { BatchName: batchName, Url: url, Status: status }, store: { isCheckScanningReadOnly }} = this.props;
		const openBatchStillOpenDialogHandler = !isCheckScanningReadOnly && this.openBatchStillOpenDialog;

		switch (batch.Status) {
			case UiBatchStatus.Populating:
			case UiBatchStatus.Reopened:
				return (
					<span className="list-group-item" ref={el => this.listItemWrapper = el} onClick={this.openBatchStillOpenDialog}>
						{this.renderRowContent()}
					</span>
				);

			case UiBatchStatus.Validating:
			case UiBatchStatus.Rejected:
				return (
					<span className="list-group-item" ref={el => this.listItemWrapper = el}>
						{this.renderRowContent()}
					</span>
				);

			case UiBatchStatus.ReadyToAllocate:
			case UiBatchStatus.Closed:
				return (
					<a href={url} title={batchName} className="list-group-item" ref={el => this.listItemWrapper = el}>
						{this.renderRowContent()}
					</a>
				);

			default:
				return null;
		}
	}

	componentWillLeave(callback) {
		if (this.props.store.batchCurrentlyBeingDismissed !== this.props.batch) {
			callback();
			return;
		}

		velocity(this.listItemWrapper, 'slideUp', {
			duration: 150,
			complete: callback,
			easing: 'ease-in-out'
		});
	}

	private openDismissRejectedBatchDialog = () => {
		const { batch, store: { dismissBatch }} = this.props;
		ModalDialogCommander.showReactDialog(<BatchDismissRejectedDialog batch={batch} onBatchDismissed={dismissBatch} />);
	}

	private openBatchStillOpenDialog = () => {
		ModalDialogCommander.showReactDialog(<BatchStillOpenDialog />);
	}

	private renderRowContent = () => {
		const { batch } = this.props;

		return (
			[
				<div className="list-group-left">
					<BatchListItemHeader batch={batch} />
					<RenderResponsive visibility={ResponsiveVisibility.Hidden} breakpoints={ResponsiveBreakpoint.Xs}>
						<span>
							<BatchInfo batch={batch} />
							<BatchDismissRejected batch={batch} openDismissRejectedDialog={this.openDismissRejectedBatchDialog} />
						</span>
					</RenderResponsive>
				</div>,
				<BatchTotal batch={batch} />,
				<RenderResponsive visibility={ResponsiveVisibility.Visible} breakpoints={ResponsiveBreakpoint.Xs}>
					<BatchDismissRejected batch={batch} openDismissRejectedDialog={this.openDismissRejectedBatchDialog} />
				</RenderResponsive>
			]
		);
	}
}
