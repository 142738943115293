import { observable, action } from 'mobx';

export class DataGridContext {
	@observable expandedRowKey: string;
	@observable initialDataHasLoaded: boolean = false;

	constructor(public getRowKey: (data: any) => string) {
		this.expandedRowKey = null;
	}

	@action
	updateExpandedRow = (data: any) => {
		this.expandedRowKey = data ? this.getRowKey(data) : null;
	}

	@action
	setInitialDataHasLoaded = () => {
		this.initialDataHasLoaded = true;
	}
}
