import * as React from 'react';
import { inject, observer } from 'mobx-react';
import {
	BatchTitleInput,
	EventTimeInput,
	GiftsReceivedOnInput,
	GiftTotalInputs,
} from './batch-entry-batch-details';
import { BatchEntryBatchDetailsViewModel } from './batch-entry-batch-details-view-model';
import { BatchEntryUserActionCreator } from '../../../pages/batch-entry/batch-entry-user-actions';
import { VirtualTerminalListingStore } from '../../..//virtual-terminal-listing-store';
import { ValidatableForm } from '../../../../components/form-controls/validatable-form';
import { SmoothHeightTransition } from '../../../../components/hoc-behavior/transitions';
import { FormControlLabelled, FormControlType } from '../../../../components/form-controls/form-control-labelled';
import { WithAnalytics, recordEvent, injectAnalytics } from '../../../../analytics';
import { ModelMetadata } from '../../../virtual-terminal-generated';

import * as styles from './batch-entry-batch-details.less';

@inject('userActionCreator')
@observer
export class BatchEntryBatchDetailsCreate extends React.Component<{ vm: BatchEntryBatchDetailsViewModel, userActionCreator?: BatchEntryUserActionCreator }> {
	render() {
		const { vm } = this.props;
		const {
			batch,
			updateBatchName,
			listingStore,
			giftsReceivedOn,
			updateGiftsReceivedOn,
			referrerUrl,
			validationErrors,
		} = vm;
		const { paymentLabel: { NounPluralLowerCase, NounPluralSentenceCase, VerbPresentTenseLowerCase }, selectedListingId } = listingStore;
		return (
			<ValidatableForm onSubmit={this.handleSubmit} validationErrors={validationErrors} acceptanceTestTargetId="Create batch details">
				<div className="panel panel-highlight">
					<header className="panel-heading">
						<BatchTitleInput
							label="Batch title (optional)"
							requestModelMetadata={ModelMetadata.CreateBatchRequestModel}
							batchName={batch.Name}
							updateBatchName={updateBatchName}
						/>
					</header>
					<div className={`panel-body ${styles.panelBody}`}>
						<BatchListingDropDown listingStore={listingStore} />
						<SmoothHeightTransition>
							{selectedListingId && <EventTimeInput vm={vm} verbPresentTenseLowerCase={VerbPresentTenseLowerCase} />}
						</SmoothHeightTransition>
						<GiftsReceivedOnInput nounPluralSentenceCase={NounPluralSentenceCase} giftsReceivedOn={giftsReceivedOn} updateGiftsReceivedOn={updateGiftsReceivedOn} />
						<GiftTotalInputs vm={vm} nounPluralLowerCase={NounPluralLowerCase} />
					</div>
					<footer className="panel-footer panel-footer-btn-group">
						<a href={referrerUrl} title="Cancel" className="btn btn-ghost">Cancel</a>
						<button type="submit" className="btn btn-primary">Create</button>
					</footer>
				</div>
			</ValidatableForm>
		);
	}

	private handleSubmit = () => {
		const { userActionCreator, vm: { listingStore, batch, giftsReceivedOn } } = this.props;
		userActionCreator.CreateBatch(listingStore.selectedListingId, { ...batch, GivenOnDate: giftsReceivedOn });
	}
}

@injectAnalytics
@observer
class BatchListingDropDown extends React.Component<WithAnalytics & { listingStore: VirtualTerminalListingStore }> {
	render() {
		const { selectedListingId, listingsAsFormControlLabelledOptions } = this.props.listingStore;
		return (
			<div className="row">
				<FormControlLabelled cssClassNames="col-md-6 col-lg-4"
					label="Your listing"
					formControlProps={{
						formControlType: FormControlType.DropDown,
						name: 'merchantId',
						placeholder: 'Select a listing',
						value: selectedListingId,
						onChangeHandler: this.updateSelectedListing,
						Options: listingsAsFormControlLabelledOptions,
						validationRules: { required: { errorMessage: 'A listing is required' } },
						acceptanceTestTargetId: 'Your listing',
					}} />
			</div>
		);
	}

	private updateSelectedListing = (ev: React.FormEvent<HTMLSelectElement>) => {
		if (NewFeatures.SetupPinpointAnalytics && this.props.analytics) {
			const { feature, subFeature } = this.props.analytics;
			recordEvent({ feature, subFeature, eventTypeLabel: 'selectListing' });
		}

		this.props.listingStore.handleSelectedListingChange(ev.currentTarget.value);
	}
}
