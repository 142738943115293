import * as React from 'react';
import { fork, race, call, cancelled, cancel, take, spawn } from 'redux-saga/effects';
import { NumberParser, ICheckNumberParsingResult } from '../../utils/check-number-parser';
import { getVirtualTerminalDataService, VirtualTerminalApiConfigType } from '../../single-entry-data-service';
import { VirtualTerminalUserAction } from './single-entry-user-actions';
import { communityMemberModelToViewModel, anonymousMember } from '../../utils/member-view-model-helper';
import { dataServiceCall, Channel } from '../../../utils/saga-utils';
import { ActionRequest, ActionResponse } from '../../../utils/data-service';
import { ICheckNumber, PaymentMethodUiType, PaymentMethod } from '../../components/payment-entry/payment-entry-model';
import { IVirtualTerminalPayerViewModel } from '../../components/payer-search/payer-view-model';
import { delay, Task } from 'redux-saga';
import { VirtualTerminalListingStore } from '../../virtual-terminal-listing-store';
import { VirtualTerminalFormViewModel } from '../../components/form/virtual-terminal-form-view-model';
import {
	ExistingACHModel,
	ExistingCreditCardModel,
	FullCardModel,
	GetRecentGiftEntryType,
	GiftEntryPaymentStatus,
	RecordedACHModel,
	RecordedCheckModel,
	RecordedCreditCardModel,
	SplitLineItem,
	VirtualTerminalAchBankModel,
	VirtualTerminalContentOutOfDateModel,
	VirtualTerminalPaymentRequestModel
} from '../../virtual-terminal-generated';
import { RecentGiftsGridViewModel } from '../../components/recent-gifts/recent-gifts-grid-view-model';
import { toPaymentMethodType } from '../../utils/payment-method-type-helper';
import { VirtualTerminalError } from '../../utils/virtual-terminal-error';
import { toJS } from 'mobx';
import { ModalDialogCommander } from '../../../components/modal-dialog-commander';
import { PaymentMethodRemoveDialog } from '../../components/payment-entry/payment-method-remove-dialog';
import { IMemberViewModel } from '../../components/member/member-view-model';
import { alertController } from '../../../components/alert-controller';
import { nullableNumber } from '../../../utils/nullable-number';
import { Country } from '../../../loggedinweb-generated';
import { Metrics } from '../../utils/metrics';

const anonymousSearchTerm = 'anon';

export interface ISingleEntrySagaContext {
	readonly userActionChannel: Channel<VirtualTerminalUserAction>;
	readonly formViewModel: VirtualTerminalFormViewModel;
	readonly recentGiftsViewModel: RecentGiftsGridViewModel;
	readonly listingStore: VirtualTerminalListingStore;
	readonly sagaState: IVirtualTerminalSagaState;
	readonly reportError: (error: any) => void;
}

export interface IVirtualTerminalSagaState {
	currentTask?: Task;
	currentLoadMoreTask?: Task;
	currentChangeListingTask?: Task;
	currentViewAllExistingPaymentMethodsTask?: Task;
	currentRemovePaymentMethodTask?: Task;
	currentFetchRecentGiftsTask?: Task;
	currentSetAnonymousPayerTask?: Task;
}

function isTaskRunning(task: Task | null): boolean {
	return task && task.isRunning();
}

export function* virtualTerminalSaga(context: ISingleEntrySagaContext): IterableIterator<any> {
	// A user can initiate the following long running sagas:
	// * Payer search - can be cancelled by all the sagas except for load more (we want to keep the value in the input in sync with the search results)
	// * Check reading - can be cancelled by all the sagas except for load more
	// * Payer selection - can be cancelled by all the sagas except for load more
	// * View all existing payment methods - can be cancelled by changing the selected payer, typing in the Omnibox or making a payment
	// * Load more - the least important saga, it should be cancelled when starting any other saga
	// * Remove payment method - can not be cancelled, if requires confirmation is returns the inner saga will block
	// * Fetch recent gifts - once called then enters a polling state, a listing change or load more action will fetch immediately then reset the polling window
	// * Selected anonymous payer - can not be cancelled, cancels any in flight load more or view all existing payment method sagas
	const { userActionChannel, sagaState } = context;

	while (true) {
		const userAction: VirtualTerminalUserAction = yield take(userActionChannel);
		const {
			currentTask,
			currentLoadMoreTask,
			currentViewAllExistingPaymentMethodsTask,
			currentRemovePaymentMethodTask,
			currentSetAnonymousPayerTask
		} = sagaState;


		// every action except for loadMore should cancel all the currently running sagas
		if (userAction instanceof VirtualTerminalUserAction.LoadMorePayers) {
			// load more cannot cancel any other tasks and should work in parallel with any other task
			// load more should not cancel the currently running load more task to prevent accidental double-clicks

			if (!isTaskRunning(currentTask) && !isTaskRunning(currentLoadMoreTask)) {
				sagaState.currentLoadMoreTask = yield fork(loadMorePayersSaga, context);
			}
		} else if (userAction instanceof VirtualTerminalUserAction.ViewAllExistingPaymentMethods) {

			if (!isTaskRunning(currentTask) && !isTaskRunning(currentViewAllExistingPaymentMethodsTask)) {
				sagaState.currentViewAllExistingPaymentMethodsTask = yield fork(viewAllExistingPaymentMethodsSaga, context);
			}
		} else if (userAction instanceof VirtualTerminalUserAction.OmniboxValueChange) {
			yield fork(omniboxValueChangeSaga, context, userAction.value);

		} else if (userAction instanceof VirtualTerminalUserAction.RemovePaymentMethod) {
			if (!isTaskRunning(currentTask) && !isTaskRunning(currentRemovePaymentMethodTask)) {
				sagaState.currentRemovePaymentMethodTask = yield fork(removePaymentMethodSaga, context, userAction.paymentMethod);
			}

		} else if (userAction instanceof VirtualTerminalUserAction.SelectedPayerChange) {
			if (isTaskRunning(currentTask)) {
				yield cancel(currentTask);
			}

			if (isTaskRunning(currentLoadMoreTask)) {
				yield cancel(currentLoadMoreTask);
			}

			if (isTaskRunning(currentViewAllExistingPaymentMethodsTask)) {
				yield cancel(currentViewAllExistingPaymentMethodsTask);
			}

			sagaState.currentTask = yield fork(selectedPayerChangeSaga, context, userAction.payer);
		} else if (userAction instanceof VirtualTerminalUserAction.SubmitForm) {
			if (isTaskRunning(currentTask)) {
				yield cancel(currentTask);
			}

			if (isTaskRunning(currentLoadMoreTask)) {
				yield cancel(currentLoadMoreTask);
			}

			if (isTaskRunning(currentViewAllExistingPaymentMethodsTask)) {
				yield cancel(currentViewAllExistingPaymentMethodsTask);
			}

			// blocking call to savePaymentSaga - not other VT sagas should be running when we're saving the payment
			yield call(savePaymentSaga, context);
		} else if (userAction instanceof VirtualTerminalUserAction.ListingChange) {
			yield fork(changeSelectedListingSaga, context, userAction.selectedListingId);

		} else if (userAction instanceof VirtualTerminalUserAction.ResetForm) {
			if (isTaskRunning(currentTask)) {
				yield cancel(currentTask);
			}

			if (isTaskRunning(currentLoadMoreTask)) {
				yield cancel(currentLoadMoreTask);
			}

			context.formViewModel.resetForm();
		} else if (userAction instanceof VirtualTerminalUserAction.SelectAnonymousPayer) {
			if (isTaskRunning(currentLoadMoreTask)) {
				yield cancel(currentLoadMoreTask);
			}

			if (isTaskRunning(currentViewAllExistingPaymentMethodsTask)) {
				yield cancel(currentViewAllExistingPaymentMethodsTask);
			}

			if (!isTaskRunning(currentSetAnonymousPayerTask)) {
				sagaState.currentSetAnonymousPayerTask = yield fork(selectAnonymousPayerSaga, context);
			}
		} else if (userAction instanceof VirtualTerminalUserAction.FetchRecentGifts) {
			yield fork(fetchRecentGiftsSaga, context, userAction.getRecentGiftEntryType);
		}
	}
}

export function* fetchRecentGiftsSaga(context: ISingleEntrySagaContext, getRecentGiftEntryType: GetRecentGiftEntryType): IterableIterator<any> {
	const { sagaState } = context;

	if (isTaskRunning(sagaState.currentFetchRecentGiftsTask)) {
		yield cancel(sagaState.currentFetchRecentGiftsTask);
	}

	sagaState.currentFetchRecentGiftsTask = yield fork(fetchRecentGiftsPollingSaga, context, getRecentGiftEntryType);
}

export function* fetchRecentGiftsPollingSaga(context: ISingleEntrySagaContext, getRecentGiftEntryType: GetRecentGiftEntryType): IterableIterator<any> {
	while (true) {
		yield call(fetchRecentGifts, context, getRecentGiftEntryType);
		yield call(delay, 60000);
		// All subsequent polling calls should be of type Poll
		getRecentGiftEntryType = GetRecentGiftEntryType.Poll;
	}
}

export function* fetchRecentGifts(context: ISingleEntrySagaContext, getRecentGiftEntryType: GetRecentGiftEntryType): IterableIterator<any> {
	const { listingStore, recentGiftsViewModel } = context;

	let response: ActionResponse<VirtualTerminalApiConfigType, 'recentGiftEntriesAsync'> = null;

	const { currentNumberOfGifts, lastFetchedTime, expandedGiftDetails } = recentGiftsViewModel;
	const expandedGiftEncodedToken = expandedGiftDetails && expandedGiftDetails.EncodedToken;
	const expandedItemNonCashGiftId = expandedGiftDetails && expandedGiftDetails.NonCashGiftId;
	const expandedItemIsNonCashGift = expandedGiftDetails && expandedGiftDetails.IsNonCash;

	try {
		response = yield dataServiceCall(getVirtualTerminalDataService().getActionSubscriberFactory('recentGiftEntriesAsync'), {
			merchantId: Number(listingStore.selectedListing.ListingId),
			model: {
				CurrentNumberOfGifts: currentNumberOfGifts,
				LastFetchTime: lastFetchedTime,
				ExpandedItemEncodedToken: expandedGiftEncodedToken,
				GetRecentGiftEntryType: getRecentGiftEntryType,
				ExpandedItemNonCashGiftId: expandedItemNonCashGiftId,
				ExpandedItemIsNonCashGift: expandedItemIsNonCashGift,
			}
		});

		recentGiftsViewModel.updateGifts(response);
	} catch (error) {
		context.reportError(error);
	}
}

export function* loadMorePayersSaga(context: ISingleEntrySagaContext): IterableIterator<any> {
	const { searchResult, searchValue, checkNumber, payersAccociatedWithCheckResult } = context.formViewModel;

	if (searchResult) {
		yield call(loadPayers, context, searchValue, searchResult.payers.length);
	} else if (payersAccociatedWithCheckResult) {
		yield call(loadPayersForCheck, context, checkNumber, payersAccociatedWithCheckResult.payers.length);
	}
}

export function* viewAllExistingPaymentMethodsSaga(context: ISingleEntrySagaContext): IterableIterator<any> {
	yield call(loadAllExistingPaymentMethods, context);
}

export function* selectedPayerChangeSaga(context: ISingleEntrySagaContext, payer: IVirtualTerminalPayerViewModel): IterableIterator<any> {
	if (!payer) {
		return;
	}

	if (payer.isAnonymous) {
		yield call(selectAnonymousPayerSaga, context);
	} else {
		yield call(loadPaymentDetails, context, payer);
	}
}

export function* selectAnonymousPayerSaga(context: ISingleEntrySagaContext): IterableIterator<any> {
	Metrics.paymentEntryStarted();

	const { formViewModel } = context;
	if (!formViewModel.listingStore.selectedListingConfiguration) {
		yield call(loadListingConfiguration, context, formViewModel.listingStore.selectedListingId);
	}
	formViewModel.setAnonymousPayer();
}

export function* omniboxValueChangeSaga(context: ISingleEntrySagaContext, value: string): IterableIterator<any> {
	if (value) {
		Metrics.paymentEntryStarted();
	}

	const { sagaState } = context;
	const { currentTask, currentLoadMoreTask, currentViewAllExistingPaymentMethodsTask } = sagaState;
	if (isTaskRunning(currentTask)) {
		yield cancel(currentTask);
	}

	if (isTaskRunning(currentLoadMoreTask)) {
		yield cancel(currentLoadMoreTask);
	}

	if (isTaskRunning(currentViewAllExistingPaymentMethodsTask)) {
		yield cancel(currentViewAllExistingPaymentMethodsTask);
	}

	sagaState.currentTask = yield fork(omniboxUpdateValueSaga, context, value);
}

export function* omniboxUpdateValueSaga(context: ISingleEntrySagaContext, value: string): IterableIterator<any> {
	const { listingStore: { selectedListing: { HomeCountry } } } = context;

	const looksLikeCheckReaderOutput = NumberParser.looksLikeCheckReaderOutput(value);

	if (looksLikeCheckReaderOutput && HomeCountry !== Country.US) {
		return;
	}

	yield call(updateOmniboxValue, context, value);

	if (looksLikeCheckReaderOutput) {
		yield call(checkReadingSaga, context, value);
	} else if (value && value.trim().length >= 2 && context.formViewModel.searchValue !== value) {
		// we don't need to start searching for payers if the current search result for that value or value is empty
		yield call(payerSearchSaga, context, value);
	}
}

export function* payerSearchSaga(context: ISingleEntrySagaContext, input: string): IterableIterator<any> {
	// debouncing
	const searchDelay = 500;
	yield call(delay, searchDelay);
	yield call(loadPayers, context, input, 0);
}

export function* updateOmniboxValue(context: ISingleEntrySagaContext, value: string): IterableIterator<any> {
	const { formViewModel } = context;
	const payer = formViewModel.selectedPayer;

	formViewModel.handleOmniboxValueChange(value);

	if (payer && payer.name !== value) {
		yield call(selectedPayerChangeSaga, context, null);
	}
}

function* readCheckNumber(context: ISingleEntrySagaContext, input: string) {
	const { userActionChannel } = context;

	while (true) {
		const result: { userAction: VirtualTerminalUserAction | null, timeout: boolean } = yield race({
			userAction: take(userActionChannel),
			timeout: call(delay, 500, true)
		});

		if (result.userAction && result.userAction instanceof VirtualTerminalUserAction.OmniboxValueChange) {
			input = result.userAction.value;
		} else if (result.timeout) {
			// the value in the omnibox hasn't change for 500ms - check reading is done
			return NumberParser.parseReadCheckNumber(input);
		}
	}
}

export function* checkReadingSaga(context: ISingleEntrySagaContext, input: string): IterableIterator<any> {
	const { formViewModel, listingStore } = context;
	const { selectedListingId } = listingStore;

	formViewModel.checkReadingStarted();

	const parsingResult: ICheckNumberParsingResult = yield call(readCheckNumber, context, input);

	if (!parsingResult.success) {
		formViewModel.checkReadingFailed();
		return yield spawn(logCheckReadFailure, context, parseInt(selectedListingId), parsingResult.cleanedCheckReaderOutput);
	}

	yield call(loadPayersForCheck, context, parsingResult, 0);
}

function* logCheckReadFailure(context: ISingleEntrySagaContext, merchantId: number, cleanedCheckReaderOutput: string): IterableIterator<any> {
	return yield dataServiceCall(getVirtualTerminalDataService().getActionSubscriberFactory('logFailedCheckRead'), {
		merchantId: merchantId,
		cleanedCheckReaderOutput: cleanedCheckReaderOutput
	});
}

export function* changeSelectedListingSaga(context: ISingleEntrySagaContext, listingId: string, forceReload: boolean = false): IterableIterator<any> {
	const { listingStore, sagaState } = context;

	if (listingStore.selectedListingId === listingId && !forceReload) {
		return;
	}

	// Listing change action can run in parallel with other actions
	// However, it's not cancellable by other actions except for another ListingChangeAction
	if (isTaskRunning(sagaState.currentChangeListingTask)) {
		yield cancel(sagaState.currentChangeListingTask);
	}

	sagaState.currentChangeListingTask = yield fork(loadListingConfigurationSaga, context, listingId, forceReload);
}

export function* loadListingConfigurationSaga(context: ISingleEntrySagaContext, listingId: string, forceReload: boolean): IterableIterator<any> {
	const { listingStore, formViewModel } = context;

	listingStore.handleSelectedListingChange(listingId);

	// Clear any form validation errors, selected payer and form data
	formViewModel.updateValidationErrors({});

	if (formViewModel.selectedMember) {
		yield fork(omniboxValueChangeSaga, context, '');
	}

	if (!forceReload) {
		yield fork(fetchRecentGiftsSaga, context, GetRecentGiftEntryType.Load);
	}

	const { listingConfigurations } = listingStore;

	// check if listing configuration is already loaded
	if (!forceReload && listingConfigurations.has(listingId)) {
		listingStore.loadingConfigurationFinished(listingId, listingConfigurations.get(listingId));
	} else {
		yield call(loadListingConfiguration, context, listingId);
	}
}

export function* loadAllExistingPaymentMethods(context: ISingleEntrySagaContext): IterableIterator<any> {
	const { formViewModel, listingStore } = context;
	const selectedListingId = Number(listingStore.selectedListingId);
	const selectedPayerId = Number(formViewModel.selectedPayer.id);

	let response: ActionResponse<VirtualTerminalApiConfigType, 'getPayerAllExistingPaymentMethods'> = null;

	formViewModel.loadExistingPaymentMethodsStarted();

	try {
		response = yield dataServiceCall(getVirtualTerminalDataService().getActionSubscriberFactory('getPayerAllExistingPaymentMethods'), {
			merchantId: selectedListingId,
			model: {
				CommunityMemberId: selectedPayerId
			}
		});
		formViewModel.loadExistingPaymentMethodsFinished(response);
	} catch (error) {
		formViewModel.loadExistingPaymentMethodsCancelled();
		context.reportError(error);
	} finally {
		if (yield cancelled()) {
			formViewModel.loadExistingPaymentMethodsCancelled();
		}
	}
}

export function* loadListingConfiguration(context: ISingleEntrySagaContext, listingId: string): IterableIterator<any> {
	const { listingStore } = context;

	let response: ActionResponse<VirtualTerminalApiConfigType, 'getListingConfiguration'> = null;

	//  a user should not be able to submit a form while requesting a listing configuration
	listingStore.loadingConfigurationStarted();

	try {
		response = yield dataServiceCall(getVirtualTerminalDataService().getActionSubscriberFactory('getListingConfiguration'), {
			merchantId: parseInt(listingId)
		});
		listingStore.loadingConfigurationFinished(listingId, response);
	} catch (error) {
		listingStore.loadingConfigurationCancelled();
		context.reportError(error);
	} finally {
		if (yield cancelled()) {
			listingStore.loadingConfigurationCancelled();
		}
	}
}

export function* loadPayers(context: ISingleEntrySagaContext, input: string, skip: number): IterableIterator<any> {
	const { formViewModel, listingStore } = context;
	const loadMore = skip > 0;

	try {
		let response: ActionResponse<VirtualTerminalApiConfigType, 'searchPayers'> = null;

		if (loadMore) {
			formViewModel.loadMoreInProgress = true;
		}

		try {
			response = yield dataServiceCall(getVirtualTerminalDataService().getActionSubscriberFactory('searchPayers'), {
				model: {
					Query: input,
					Skip: skip
				}
			});

			const members = response.Results as IMemberViewModel[];

			if (listingStore.selectedListingSupportsCash && input.toLowerCase() === anonymousSearchTerm) {
				members.unshift(anonymousMember);
			}

			const payers = members.map(communityMemberModelToViewModel);

			formViewModel.searchPayersComplete(input, {
				error: null,
				payers: loadMore ? formViewModel.searchResult.payers.concat(payers) : payers,
				hasMorePages: response.HasMorePages,
				appendedResults: loadMore,
			});
		} catch (error) {
			if (loadMore) {
				formViewModel.loadMoreInProgress = false;
				context.reportError(error);
			} else {
				formViewModel.searchPayersComplete(input, {
					error: error,
					payers: [],
					hasMorePages: false,
					appendedResults: loadMore,
				});
			}
		}
	} finally {
		if (loadMore && (yield cancelled())) {
			formViewModel.loadMoreInProgress = false;
		}
	}
}

export function* loadPayersForCheck(context: ISingleEntrySagaContext, check: ICheckNumber, skip: number): IterableIterator<any> {
	const { formViewModel, listingStore } = context;
	const { selectedListingConfiguration, selectedListingId } = listingStore;
	const { accountNumber, routingNumber, checkNumber, cleanedCheckReaderOutput } = check;
	const loadMore = skip > 0;

	try {
		let response: ActionResponse<VirtualTerminalApiConfigType, 'searchPayersForCheck'>;

		if (loadMore) {
			formViewModel.loadMoreInProgress = true;
		}

		try {
			response = yield dataServiceCall(getVirtualTerminalDataService().getActionSubscriberFactory('searchPayersForCheck'), {
				merchantId: parseInt(selectedListingId),
				model: {
					Check: <RecordedCheckModel>{
						AccountNumber: accountNumber,
						RoutingNumber: routingNumber,
						CheckNumber: checkNumber,
						CleanedCheckReaderOutput: cleanedCheckReaderOutput
					},
					Skip: skip,
					MerchantVersion: selectedListingConfiguration && selectedListingConfiguration.MerchantVersion
				}
			});

			handleError(response.ErrorMessage);

			if (response.ListingConfiguration) {
				// the current listing configuration has not been fetched or is out of date
				listingStore.loadingConfigurationFinished(selectedListingId, response.ListingConfiguration);
			}

			const searchResult = response.SearchResult;
			const matchedCommunityMemberId = response.MatchedCommunityMemberId;

			formViewModel.selectMemberMatch(searchResult.Results, matchedCommunityMemberId);

			const viewModels = searchResult.Results.map(communityMemberModelToViewModel);

			formViewModel.searchPayersForCheckComplete(check, {
				error: null,
				payers: loadMore ? formViewModel.payersAccociatedWithCheckResult.payers.concat(viewModels) : viewModels,
				hasMorePages: searchResult.HasMorePages,
				appendedResults: loadMore,
			}, response.PaymentDetails);
		} catch (error) {

			// TODO Notify a user when check reading isn't supported PP-15364
			if (loadMore) {
				formViewModel.loadMoreInProgress = false;
				context.reportError(error);
			} else {
				formViewModel.checkReadingFailed();
			}
		}
	} finally {
		if (loadMore && (yield cancelled())) {
			formViewModel.loadMoreInProgress = false;
		}
	}
}

export function* loadPaymentDetails(context: ISingleEntrySagaContext, payer: IVirtualTerminalPayerViewModel): IterableIterator<any> {
	const { formViewModel, listingStore } = context;
	const { selectedListingId, selectedListingConfiguration } = listingStore;

	let response: ActionResponse<VirtualTerminalApiConfigType, 'getPayerPaymentDetails'> = null;

	formViewModel.loadPaymentDetailsStarted(!!formViewModel.checkNumber);

	try {
		response = yield dataServiceCall(getVirtualTerminalDataService().getActionSubscriberFactory('getPayerPaymentDetails'), {
			merchantId: Number(selectedListingId),
			model: {
				CommunityMemberId: nullableNumber(payer.id),
				MerchantVersion: (selectedListingConfiguration && selectedListingConfiguration.MerchantVersion),
			}
		});

		handleContentOutOfDate(context, response.ContentOutOfDate);
		handleError(response.ErrorMessage);

		if (response.ListingConfiguration) {
			// the current listing configuration has not been fetched or is out of date
			listingStore.loadingConfigurationFinished(selectedListingId, response.ListingConfiguration);
		}

		formViewModel.updateSelectedMember(response.CommunityMember);
		formViewModel.loadExistingPaymentMethodsFinished(response.ExistingPaymentMethods);

		formViewModel.loadPaymentDetailsFinished(response.PaymentDetails);
	} catch (error) {
		formViewModel.loadPaymentDetailsCancelled();
		context.reportError(error);
	} finally {
		if (yield cancelled()) {
			formViewModel.loadPaymentDetailsCancelled();
		}
	}
}

export function* savePaymentSaga(context: ISingleEntrySagaContext): IterableIterator<any> {
	const { formViewModel, listingStore, recentGiftsViewModel } = context;

	let response: ActionResponse<VirtualTerminalApiConfigType, 'pay'> = null;

	Metrics.paymentEntryFinished();

	formViewModel.savePaymentStarted();
	try {
		response = yield call(postPaymentEntry, context);

		handleContentOutOfDate(context, response.ContentOutOfDate);
		handleError(response.ErrorMessage);

		formViewModel.resetForm();

		if (response &&
			response.RecentGiftEntries &&
			response.RecentGiftEntries.length > 0
		) {
			for (let gift of response.RecentGiftEntries) {
				recentGiftsViewModel.addGift(gift);
			}

			const failedGiftsCount = response.RecentGiftEntries.filter(x => x.PaymentStatus == GiftEntryPaymentStatus.Failed).length;
			if(failedGiftsCount > 0) {
				alertController.showWarning(`Your ${listingStore.selectedListing.PaymentLabel.GiftEntryFeatureName} has been submitted, please check the table below for the submission status.`);
				return;
			}
		} else if (response && response.RecentGiftEntry) {
			recentGiftsViewModel.addGift(response.RecentGiftEntry);
		}

		alertController.showSuccess(`Success! Your ${listingStore.selectedListing.PaymentLabel.GiftEntryFeatureName} was made successfully`);
	} catch (error) {
		const validationErrors = error.validationErrors;
		if (validationErrors) {
			alertController.showValidationErrors(validationErrors);
			formViewModel.updateValidationErrors(validationErrors);
		} else {
			context.reportError(error);
		}
	} finally {
		formViewModel.savePaymentFinished();
	}
}

export function* postPaymentEntry(context: ISingleEntrySagaContext): IterableIterator<any> {
	const request = buildPayRequest(context.formViewModel);
	if (context.formViewModel.paymentEntry.paymentMethod.type === PaymentMethodUiType.NonCash) {
		return yield dataServiceCall(getVirtualTerminalDataService().getActionSubscriberFactory('addNonCashGift'), request);
	} else {
		return yield dataServiceCall(getVirtualTerminalDataService().getActionSubscriberFactory('pay'), request);
	}
}

export function* removePaymentMethodSaga(context: ISingleEntrySagaContext, paymentMethod: PaymentMethod): IterableIterator<any> {
	yield call(removePaymentMethod, context, paymentMethod);
}

export function* removePaymentMethod(context: ISingleEntrySagaContext, paymentMethod: PaymentMethod): IterableIterator<any> {
	const { formViewModel, userActionChannel } = context;

	let response: ActionResponse<VirtualTerminalApiConfigType, 'removePaymentMethod'> = null;

	formViewModel.removePaymentMethodStarted();

	try {
		response = yield dataServiceCall(getVirtualTerminalDataService().getActionSubscriberFactory('removePaymentMethod'), {
			model: {
				CommunityMemberId: Number(formViewModel.selectedPayer.id),
				PaymentMethodIndex: Number(paymentMethod.key)
			}
		});

		if (response.ConfirmationRequired) {
			formViewModel.removePaymentMethodFinished();

			const removeDialog = React.createElement(PaymentMethodRemoveDialog, {
				userActionChannel: context.userActionChannel,
				paymentMethod: paymentMethod,
				formViewModel: formViewModel
			});

			ModalDialogCommander.showReactDialog(removeDialog)
				.then(() => userActionChannel.put(new VirtualTerminalUserAction.RemovePaymentMethodConfirmationClosed()));

			while (true) {
				const userAction: any = yield take(userActionChannel);

				if (userAction instanceof VirtualTerminalUserAction.RemovePaymentMethodConfirmed) {
					yield call(removePaymentMethodConfirmed, context, paymentMethod);
					ModalDialogCommander.forceCloseCurrent();
					return;
				} else if (userAction instanceof VirtualTerminalUserAction.RemovePaymentMethodConfirmationClosed) {
					return;
				}
			}
		} else {
			handleContentOutOfDate(context, response.ContentOutOfDate, paymentMethod);
			handleError(response.ErrorMessage);
			formViewModel.removePaymentMethod(paymentMethod);
		}
	} catch (error) {
		context.reportError(error);
	} finally {
		formViewModel.removePaymentMethodFinished();
	}
}

export function* removePaymentMethodConfirmed(context: ISingleEntrySagaContext, paymentMethod: PaymentMethod): IterableIterator<any> {
	const { formViewModel } = context;

	let response: ActionResponse<VirtualTerminalApiConfigType, 'removePaymentMethodConfirmed'> = null;

	formViewModel.removePaymentMethodStarted();

	try {
		response = yield dataServiceCall(getVirtualTerminalDataService().getActionSubscriberFactory('removePaymentMethodConfirmed'), {
			model: {
				CommunityMemberId: Number(formViewModel.selectedPayer.id),
				PaymentMethodIndex: Number(paymentMethod.key)
			}
		});

		handleContentOutOfDate(context, response.ContentOutOfDate, paymentMethod);
		handleError(response.ErrorMessage);

		formViewModel.removePaymentMethod(paymentMethod);
	} catch (error) {
		context.reportError(error);
	} finally {
		formViewModel.removePaymentMethodFinished();
	}
}

export function handleError(errorMessage: string) {
	if (!errorMessage) {
		return;
	}
	throw new VirtualTerminalError(errorMessage);
}

export function* handleContentOutOfDate(context: ISingleEntrySagaContext,
	contentOutOfDateModel: VirtualTerminalContentOutOfDateModel,
	paymentMethod: PaymentMethod = null): IterableIterator<any> {

	if (!contentOutOfDateModel) {
		return;
	}

	const { formViewModel } = context;
	const { CommunityMemberNotFound, MerchantListingOutOfDate, PaymentMethodNotFound, RecentGiftsOutOfDate } = contentOutOfDateModel;

	if (CommunityMemberNotFound) {
		yield fork(omniboxValueChangeSaga, context, '');
	}

	if (MerchantListingOutOfDate) {
		yield fork(changeSelectedListingSaga, context, formViewModel.listingStore.selectedListingId, true);
	}

	if (RecentGiftsOutOfDate) {
		yield fork(fetchRecentGiftsSaga, context, GetRecentGiftEntryType.Poll);
	}

	if (PaymentMethodNotFound) {
		formViewModel.removePaymentMethod(paymentMethod);
	}
}

export function buildPayRequest(formViewModel: VirtualTerminalFormViewModel): ActionRequest<VirtualTerminalApiConfigType, 'pay'> {

	const { paymentEntry, selectedPayer, listingStore, selectedMemberIsAnonymous, paymentEntryReferencesValidForTheCurrentMerchant, splits } = formViewModel;
	const { CommonPaymentFields, amount, paymentMethod, yourId, isRecurring, recurringSchedule, sendEmailNotifications,
		assetType, descriptionForDonor, descriptionForMerchant } = paymentEntry;
	const commonFields = { ...toJS(CommonPaymentFields), ReferenceFieldValues: paymentEntryReferencesValidForTheCurrentMerchant };

	const lineItems : SplitLineItem[] =
		splits
		&& splits.length > 0
			? splits.map(s => ({
				FundKey: s.Fund.Value,
				Amount: s.Amount
			})) : [];

	const model: VirtualTerminalPaymentRequestModel = {
		CommonFields: commonFields,
		Amount: amount,
		MerchantVersion: listingStore.selectedListingConfiguration.MerchantVersion,
		CommunityMemberId: nullableNumber(selectedPayer.id),
		PaymentMethodType: toPaymentMethodType(paymentMethod.type),
		IsAnonymous: selectedMemberIsAnonymous,
		AchAccount: null,
		CreditCard: null,
		PaymentMethodIndex: null,
		RecordedCheck: null,
		RecordedACH: null,
		RecordedCreditCard: null,
		ExistingACH: null,
		ExistingCreditCard: null,
		WantsTransactionNotifications: sendEmailNotifications,
		YourId: yourId,
		IsRecurring: isRecurring,
		ScheduleFrequency: recurringSchedule,
		AssetType: assetType,
		DescriptionForDonor: descriptionForDonor,
		DescriptionForMerchant: descriptionForMerchant,
		LineItems: lineItems
	};

	switch (paymentMethod.type) {
		case PaymentMethodUiType.RecordedCheck:
			model.RecordedCheck = <RecordedCheckModel>{
				CheckAccountName: '',
				AccountNumber: paymentMethod.accountNumber,
				CheckNumber: paymentMethod.checkNumber,
				RoutingNumber: paymentMethod.routingNumber
			};
			break;
		case PaymentMethodUiType.ACH:
			model.AchAccount = <VirtualTerminalAchBankModel>{
				AccountNumber: paymentMethod.accountNumber,
				RoutingNumber: paymentMethod.routingNumber,
				AccountType: paymentMethod.accountType
			};
			break;
		case PaymentMethodUiType.ExistingACH:
			model.PaymentMethodIndex = parseInt(paymentMethod.key);
			model.ExistingACH = <ExistingACHModel>{
				ConfirmedAccountNumber: paymentMethod.confirmedAccountNumber
			};
			break;
		case PaymentMethodUiType.ExistingCreditCard:
			model.PaymentMethodIndex = parseInt(paymentMethod.key);
			model.ExistingCreditCard = <ExistingCreditCardModel>{
				ConfirmedCardNumber: paymentMethod.confirmedCardNumber
			};
			break;
		case PaymentMethodUiType.RecordedCreditCard:
			model.RecordedCreditCard = <RecordedCreditCardModel>{
				Reference: paymentMethod.paymentReference || ''
			};
			break;
		case PaymentMethodUiType.RecordedACH:
			model.RecordedACH = <RecordedACHModel>{
				Reference: paymentMethod.paymentReference || ''
			};
			break;
		case PaymentMethodUiType.CreditCard:
			model.CreditCard = <FullCardModel>{
				Number: paymentMethod.cardNumber,
				ExpiryMonth: paymentMethod.expiryMonth,
				ExpiryYear: paymentMethod.expiryYear,
				Cvc: paymentMethod.cvv,
				CvcOptional: true
			};
			break;
		case PaymentMethodUiType.NonCash:
			model.DescriptionForDonor = paymentMethod.descriptionForDonor;
			model.DescriptionForMerchant = paymentMethod.descriptionForMerchant;
			model.AssetType = paymentMethod.assetType;
			break;
	}

	return {
		model,
		stats: Metrics.getPaymentEntryStats(),
		merchantId: formViewModel.listingStore.selectedListing.ListingId
	};
}
