import * as React from 'react';
import { observable, computed, ObservableMap, action } from 'mobx';
import { ListingViewModel, VirtualTerminalListingConfiguration, PaymentMethodType, MerchantStatus } from './virtual-terminal-generated';
import { StorageHelper } from '../helpers/storagehelper';
import { Country } from '../loggedinweb-generated';
import { alertController } from '../components/alert-controller';
import { getCustomFieldEditModelMap } from '../utils/customFieldsUtils';
import { IFormControlSelectOptionProps } from '../components/form-controls/form-control-select';
import { WarningMessage } from './components/warning-message';

export class VirtualTerminalListingStore {
	@observable
	selectedListingId = '';

	@observable
	listings: ListingViewModel[];

	@computed
	get listingsAsDropDownOptions() {
		return this.listings.map(x => ({ id: `${x.ListingId}`, listingName: x.ListingName }));
	}

	@computed
	get listingsAsFormControlLabelledOptions(): IFormControlSelectOptionProps[] {
		return this.listings.filter(m => m.Status == MerchantStatus.Active)
			.map(l => ({ Label: l.ListingName, Value: `${l.ListingId}` }))
	}

	@computed
	get selectedListing(): ListingViewModel {
		const [listing] = this.listings.filter(x => `${x.ListingId}` === this.selectedListingId);
		return listing;
	}

	@computed
	get selectedListingSupportsCash() {
		return this.supportsPaymentMethodType(PaymentMethodType.Cash);
	}

	@computed
	get selectedListingSupportsRecordedCheck() {
		return this.selectedListingIsInTheUS && this.supportsPaymentMethodType(PaymentMethodType.RecordedCheck);
	}

	@computed
	get paymentLabel() {
		return (this.selectedListing || this.listings[0]).PaymentLabel;
	}

	@computed
	get selectedListingCanAddNewMember() {
		return (this.selectedListing || this.listings[0]).CanManageMembers;
	}

	@computed
	get selectedListingHomeCountry() {
		return this.selectedListing && this.selectedListing.HomeCountry;
	}

	@computed
	get selectedListingHasOrganizationalGivingEnabled() {
		return this.selectedListingConfiguration && this.selectedListingConfiguration.EnableFeatureOrganizationalGiving;
	}

	@observable
	listingConfigurations?: ObservableMap<string, VirtualTerminalListingConfiguration> = observable.map<string, VirtualTerminalListingConfiguration>();

	@observable
	selectedListingConfiguration: VirtualTerminalListingConfiguration;

	@observable
	loadingListingConfiguration = false;

	@computed
	get selectedListingCustomFieldDefaultValues() {
		return this.selectedListingConfiguration
			&& this.selectedListingConfiguration.CustomFieldDefaultValues || [];
	}

	@computed
	get selectedListingCustomFieldDefaultValuesMap() {
		return getCustomFieldEditModelMap(this.selectedListingCustomFieldDefaultValues);
	}

	@computed
	get selectedListingCustomFieldsMap() {
		const fields = this.selectedListingConfiguration && this.selectedListingConfiguration.CustomFields;
		if(!fields) {
			return {};
		}
		return fields.reduce((map, field) => {
			map[field.Key] = field;
			return map;
		}, {});
	}

	constructor(listings: ListingViewModel[]) {
		this.listings = listings;

		if (listings.length < 1) {
			throw new Error('At least one listing must be provided');
		}
	}

	@action.bound
	handleSelectedListingChange(listingId: string) {
		this.selectedListingId = listingId;
		StorageHelper.setSelectedListingId(listingId);
	}

	@action.bound
	loadingConfigurationStarted() {
		this.loadingListingConfiguration = true;
	}

	@action.bound
	loadingConfigurationFinished(listingId: string, configuration: VirtualTerminalListingConfiguration) {
		this.loadingListingConfiguration = false;

		this.listingConfigurations.set(listingId, configuration);
		this.selectedListingConfiguration = configuration;

		this.alertIfCardsSupportedButTokenizationIsUnavailable(this.selectedListingConfiguration);
	}

	@action.bound
	loadingConfigurationCancelled() {
		this.loadingListingConfiguration = false;
	}

	@computed
	private get selectedListingIsInTheUS() {
		return this.selectedListingHomeCountry === Country.US;
	}

	private supportsPaymentMethodType(paymentMethodType: PaymentMethodType) {
		return this.selectedListingConfiguration
			&& this.selectedListingConfiguration.SupportedPaymentMethodTypes
			&& this.selectedListingConfiguration.SupportedPaymentMethodTypes.some(x => x === paymentMethodType);
	}

	private alertIfCardsSupportedButTokenizationIsUnavailable(listingConfiguration: VirtualTerminalListingConfiguration) {
		if(!listingConfiguration || !listingConfiguration.SupportedPaymentMethodTypes) {
			return;
		}

		if(listingConfiguration.SupportedPaymentMethodTypes.some(x => x === PaymentMethodType.CreditCard)
			&& !listingConfiguration.CardTokenizationAvailable) {
			const paymentNoun = listingConfiguration.PaymentNounPluralLowerCase;
			const hyperlinkWarning = React.createElement(WarningMessage, {
				warningText: `Entering card ${paymentNoun} is temporarily unavailable as a result of our Assured Payments feature currently being enabled. All other ${paymentNoun} are able to be entered. Card ${paymentNoun} will be re-enabled shortly. `,
				anchorText : 'Click here for more information',
				anchorTarget : listingConfiguration.CardTokenizationHelpLink
			},);
			alertController.showWarning(hyperlinkWarning);
		}
	}
}
