import * as React from 'react';
import { FormControlLabelled, FormControlType, IFormControlLabelledTooltipProps } from '../../../components/form-controls/form-control-labelled';
import { IIconInputGroupProps, IconInputGroupPosition } from '../../../components/icon-input-group';
import { CardPaymentMethod } from './payment-entry-model';
import { observer } from 'mobx-react';
import { injectAnalytics, recordEvent, WithAnalytics } from '../../../analytics';
import { CreditCardHelper } from '../../../helpers/creditcardhelper';
import { propertyNameOf } from '../../../utils/propertyNameOf';
import { FullCardModel } from '../../virtual-terminal-generated';

@injectAnalytics
@observer
export class PaymentMethodCard extends React.Component<WithAnalytics & {
	cssClassNames?: string;
	card: CardPaymentMethod;
}, {}> {
	render() {
		const { cssClassNames, card } = this.props;
		const { cardNumberInput, expiryDateInput, cvv, cardBrand } = card;

		const cardInputGroupOptions: IIconInputGroupProps = {
			svg: cardBrand.shortName.toLowerCase(),
			title: cardBrand.friendlyName,
			position: IconInputGroupPosition.Right,
			width: 32,
			height: 24,
			transparent: true
		};

		const cvvTooltipOptions: IFormControlLabelledTooltipProps = {
			message: `The card's CVV is usually on the back, next to your signature`,
			placement: 'top',
		};

		return (
			<div className={`vt-payment-method vt-payment-method-credit row ${cssClassNames ? cssClassNames : ''}`}>
				<FormControlLabelled cssClassNames="col-lg-4 col-sm-6"
					label="Card number"
					formControlProps={{
						formControlType: FormControlType.Text,
						name: propertyNameOf<FullCardModel>('Number'),
						placeholder: 'Enter card number',
						maskOptions: CreditCardHelper.getMaskOptions(cardBrand.cardNumberMask),
						inputGroupOptions: cardInputGroupOptions,
						type: 'text',
						value: cardNumberInput,
						ignorePanLikeValidation: true,
						onChangeHandler: this.handleCardNumberChange,
						...(NewFeatures.SetupPinpointAnalytics && { onBlurHandler: this.handleCardNumberBlur }),
						validationRules: {
							regex: {
								errorMessage: 'Enter the main line of digits on the card',
								parameters: {
									pattern: cardBrand.cardNumberRegex.source
								}
							},
							required: {
								errorMessage: 'Enter the card number'
							}
						},
						acceptanceTestTargetId: 'cc number'
					}} />
				<FormControlLabelled cssClassNames="col-lg-2 col-sm-3"
					label="Expiry date"
					formControlProps={{
						formControlType: FormControlType.Text,
						name: propertyNameOf<FullCardModel>('ExpiryMonth'),
						placeholder: 'MM / YY',
						maskOptions: CreditCardHelper.expiryMaskOptions(),
						type: 'text',
						value: expiryDateInput,
						onChangeHandler: this.handleExpiryDateChange,
						validationRules: {
							regex: {
								errorMessage: 'Please enter a valid expiry date',
								parameters: {
									pattern: CreditCardHelper.expiryRegex.source
								}
							},
							required: {
								errorMessage: 'Expiry date is required'
							}
						},
						acceptanceTestTargetId: 'cc expiry date',
					}} />
				<FormControlLabelled cssClassNames="col-lg-2 col-sm-3"
					label="CVV (optional)"
					formControlProps={{
						formControlType: FormControlType.Text,
						name: propertyNameOf<FullCardModel>('Cvc'),
						placeholder: `${cardBrand.cvvLength} digits`,
						maskOptions: CreditCardHelper.getMaskOptions(cardBrand.cvvMask),
						type: 'text',
						value: cvv,
						onChangeHandler: this.handleCvvChange,
						validationRules: {
							regex: {
								errorMessage: `Enter a valid verification code (${cardBrand.cvvLength} digits)`,
								parameters: {
									pattern: cardBrand.cvvRegex.source
								}
							},
						},
						acceptanceTestTargetId: 'cc cvv number',
					}}
					tooltipOptions={cvvTooltipOptions} />
			</div>
		);
	}

	private handleCardNumberBlur = () => {
		const { analytics, card: { cardNumber } } = this.props;
		if (analytics && cardNumber) {
			const { feature, subFeature } = analytics;
			recordEvent({
				feature,
				subFeature,
				eventTypeLabel: 'completePaymentMethod',
				attributes: { paymentMethodType: 'creditCard' }
			});
		}
	}

	private handleCardNumberChange = (event: React.FormEvent<HTMLInputElement>) => {
		this.props.card.updateCardNumberInput(event.currentTarget.value);
	}

	private handleExpiryDateChange = (event: React.FormEvent<HTMLInputElement>) => {
		this.props.card.updateExpiryDateInput(event.currentTarget.value);
	}

	private handleCvvChange = (event: React.FormEvent<HTMLInputElement>) => {
		this.props.card.updateCvv(event.currentTarget.value);
	}
}
