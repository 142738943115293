import { observable, action, computed } from 'mobx';

export class MigrationContactDetailsViewModel {
	@observable contactName: string;
	@observable contactEmail: string;
	@observable contactPhone: string;

	constructor(private initialContactName: string, private initialContactEmail: string, private initialContactPhone: string) {
		this.contactName = initialContactName;
		this.contactEmail = initialContactEmail;
		this.contactPhone = initialContactPhone;
	}

	@action.bound
	updateName(name: string) {
		this.contactName = name;
	}

	@action.bound
	updateEmail(email: string) {
		this.contactEmail = email;
	}

	@action.bound
	updatePhone(phone: string) {
		this.contactPhone = phone;
	}

	@computed
	get isContactNameModified(): boolean {
		return this.contactName && this.contactName !== this.initialContactName;
	}

	@computed
	get isContactEmailModified(): boolean {
		return this.contactEmail && this.contactEmail !== this.initialContactEmail;
	}

	@computed
	get isContactPhoneModified(): boolean {
		return this.contactPhone && this.contactPhone !== this.initialContactPhone;
	}

	@computed
	get isModified(): boolean {
		return this.isContactEmailModified || this.isContactNameModified || this.isContactPhoneModified;
	}
}
