import { IMaskedInputProps } from 'react-text-mask';
import { CardBrandData } from '../loggedinweb-generated';

export module CreditCardHelper {
	export const cardBrandMaskRegex = /[0-9]/;
	export const expiryRegex = /^([0-1][0-9])\s\/\s([0-9]{2})$/;
	export const expiryMask = [/[0-1]/, /[0-9]/, ' ', '/', ' ', /[0-9]/, /[0-9]/];

	export function getCardBrandFromInput(input: string) {
		for (const cardBrand of CardBrandData) {
			if (cardBrand.pattern.test(input)) {
				return cardBrand;
			}
		}
		return CardBrandData.filter(x => x.shortName === 'Unknown')[0];
	}

	export function getMaskOptions(mask: any[]): IMaskedInputProps {
		return { mask: mask, guide: false };
	}

	export function expiryMaskOptions(): IMaskedInputProps {
		return getMaskOptions(expiryMask);
	}

	export function getExpiryMonthParsed(input: string) {
		const result = expiryRegex.exec(input);
		return result ? parseInt(result[1]) : null;
	}

	export function getExpiryYearParsed(input: string) {
		const result = expiryRegex.exec(input);
		return result ? parseInt(result[2]) : null;
	}

	export function getCardNumberParsed(input: string | undefined) {
		return input ? input.replace(/\s+/g, '') : '';
	}
}
