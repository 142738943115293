import CommandBase from './command-base';
import { ICancellablePromise } from '../../../utils/cancellable-promise';
import * as Models from '../../models/index';
import Member from '../member';

export default class UpdateMemberCommand extends CommandBase<Models.EditMemberResponse> {

	constructor(public member: Member, public request: Models.EditCommunityMemberRequestModel) {
		super();
		this.trackers.push(member.busyTracker);
	}

	preApply(): void {
	}

	send(): ICancellablePromise<Models.EditMemberResponse> {
		if (!this.request.CommunityMemberId) {
			//unexpected error so throw instead of promise.reject
			throw new Error('request.CommunityMemberId has no id yet');
		}

		return this.member.parent.dataService.editMember(this.request);
	}

	postApply(result: Models.EditMemberResponse) {
		if (result instanceof Models.ConflictedCommunityMemberModel) {
			this.member.applyModel(result.newData, false);
		} else if (result instanceof Models.ValidationErrors) {
			//need to else if to get a corrent type guard in the else
		} else {
			this.member.applyModel(result, false);
		}
	}

	undoPreApply(error): void {
	}

}
