import { Subject } from './subject';
import { ICancellablePromise } from './cancellable-promise';
import { post, PostError } from './ajax-client';
import * as mobx from 'mobx';
import { AjaxUtils } from './ajax-utils';

export interface IApiConfig {
	defaultBaseUrl?: () => string;
	actions: { [key: string]: IApiAction<any, any> };
}

export interface IApiAction<TRequest, TResponse = any> {
	readonly url: (model: TRequest) => string;
	request?: TRequest;
	response?: TResponse;
}

export type ActionKey<TApiConfig extends IApiConfig> = keyof TApiConfig['actions'];

export type ActionRequest<TApiConfig extends IApiConfig, TActionKey extends ActionKey<TApiConfig>> =
	TApiConfig['actions'][TActionKey]['request'];

export type ActionResponse<TApiConfig extends IApiConfig, TActionKey extends ActionKey<TApiConfig>> =
	TApiConfig['actions'][TActionKey]['response'];

export interface IDataServicePayload<TApiConfig extends IApiConfig, TActionKey extends ActionKey<TApiConfig>> {
	actionKey: TActionKey;
	request: ActionRequest<TApiConfig, TActionKey>;
	response?: ActionResponse<TApiConfig, TActionKey>;
	requestId: string;
	error?: PostError;
}

export type DataServiceAction<TApiConfig extends IApiConfig, TActionKey extends ActionKey<TApiConfig>> = {
	type: 'request_init';
	actionKey: TActionKey;
	requestId: string;
	request: ActionRequest<TApiConfig, TActionKey>;
} | {
		type: 'request_error';
		actionKey: TActionKey;
		requestId: string;
		request: ActionRequest<TApiConfig, TActionKey>;
		error: PostError;
	} | {
		type: 'unexpected_error';
		actionKey: TActionKey;
		requestId: string;
		request: ActionRequest<TApiConfig, TActionKey>;
		error: Error;
	} | {
		type: 'request_success';
		actionKey: TActionKey;
		requestId: string;
		request: ActionRequest<TApiConfig, TActionKey>;
		response: ActionResponse<TApiConfig, TActionKey>;
	} | {
		type: 'request_cancel';
		actionKey: TActionKey;
		requestId: string;
		request: ActionRequest<TApiConfig, TActionKey>;
	};

export type DataServiceInitRequest<TApiConfig extends IApiConfig> = {
	<TActionKey extends ActionKey<TApiConfig>>(
		actionKey: TActionKey,
		request: ActionRequest<TApiConfig, TActionKey>,
	): string /*requestId*/;
};

export type DataServiceSubscribeCallback<TApiConfig extends IApiConfig> = {
	<TActionKey extends ActionKey<TApiConfig>>(
		callback: (action: DataServiceAction<TApiConfig, TActionKey>) => void,
	): () => void /*cancel subscription*/;
};

/**
 *
 *
 * @export
 * @interface IDataService
 * @template TApiConfig
 */
export interface IDataService<TApiConfig extends IApiConfig> {
	initRequest: DataServiceInitRequest<TApiConfig>;
	subscribe: DataServiceSubscribeCallback<TApiConfig>;
	cancelRequest: (requestId: string) => void;
	getActionSubscriberFactory: <TActionKey extends ActionKey<TApiConfig>>(actionKey: TActionKey)
		=> DataServiceActionSubscriberFactory<TApiConfig, TActionKey>;
}

export interface IDataServiceActionSubscriber<TApiConfig extends IApiConfig, TActionKey extends ActionKey<TApiConfig>> {
	initRequest: (request: ActionRequest<TApiConfig, TActionKey>) => void;
	destroy: () => void;
	cancelRequest: () => void;
	isProcessingRequest: boolean;
}

export interface DataServiceActionSubscriberFactory<TApiConfig extends IApiConfig, TActionKey extends ActionKey<TApiConfig>> {
	(subscriber: (action) => void): IDataServiceActionSubscriber<TApiConfig, TActionKey>;
}

export type DataServiceMiddleware<TApiConfig extends IApiConfig, TActionKey extends ActionKey<TApiConfig>> =
	(next: (action: DataServiceAction<TApiConfig, TActionKey>) => DataServiceAction<TApiConfig, TActionKey>) =>
		(action: DataServiceAction<TApiConfig, TActionKey>) => DataServiceAction<TApiConfig, TActionKey>;

export type DataServiceOptions<TApiConfig extends IApiConfig> = {
	baseUrl?: string;
	timeout?: number;

	//for attaching middleware that maps response payload before it's sent with the subscribers
	//can be used for logging, action filtering or any other very useful thing
	middleware?: DataServiceMiddleware<TApiConfig, any> | DataServiceMiddleware<TApiConfig, any>[];

	post?(url: string, data: any, options?: { timeout?: number, baseUrl?: string }): ICancellablePromise<any>;
};

export function isActionForService<TApiConfig extends IApiConfig, TActionKey extends ActionKey<TApiConfig>>
	(payload: DataServiceAction<TApiConfig, TActionKey>, actionKey: TActionKey): payload is DataServiceAction<TApiConfig, TActionKey> {
	return payload.actionKey === actionKey;
}

export function combineMiddleware<TApiConfig extends IApiConfig>
	(...args: (DataServiceMiddleware<TApiConfig, any> | DataServiceMiddleware<TApiConfig, any>[])[])
	: DataServiceMiddleware<TApiConfig, any>[] {

	return args.reduce<DataServiceMiddleware<TApiConfig, any>[]>((accumulator, item) => {
		if (!item) {
			return accumulator;
		}

		if (item instanceof Array) {
			accumulator.push(...item);
		} else {
			accumulator.push(item);
		}

		return accumulator;
	}, []);
}

function prepareBaseUrl<TApiConfig extends IApiConfig>(apiConfig: TApiConfig, options: DataServiceOptions<TApiConfig>): DataServiceOptions<TApiConfig> {
	const { baseUrl } = options;

	if (apiConfig.defaultBaseUrl && (baseUrl === undefined || baseUrl === null)) {
		options.baseUrl = AjaxUtils.resolveBaseUrl(apiConfig.defaultBaseUrl);
	}

	return options;
}

type PendingRequests = { [key: string]: { promise: ICancellablePromise<any>, request: any, actionKey: string } };

function getActionFromApiConfig<TApiConfig extends IApiConfig, TActionKey extends ActionKey<TApiConfig>>(apiConfig: TApiConfig, actionKey: TActionKey)
	: IApiAction<ActionRequest<TApiConfig, TActionKey>, ActionResponse<TApiConfig, TActionKey>> {

	const actionConfig = apiConfig.actions[`${actionKey}`];
	if (!actionConfig) {
		throw new Error(`Api config does not have configuration for the action ${actionKey}`);
	}

	return actionConfig;
}

/**
 * A data service built on the provided `apiConfig`
 * @example
 * //example of apiConfig
 * var apiConfig = {
 * 	defaultBaseUrl: () => 'baseUrl',
 * 	actions: {
 * 		getItem: <IApiAction<{ itemId: string }, { itemId: string, itemName: string }>>{
 * 			url: 'get-item-url'
 * 		},
 * 		saveItem: <IApiAction<{ itemId: string, itemName: string }, { itemId: string, itemName: string }>>{
 * 			url: 'save-item-url'
 * 		}
 * 	}
 * };
 *
 * @example
 * //data service example
 * var dataService = createDataService(apiConfig);
 *
 * dataService.subscribe((action) => {
 * 	switch (action.type) {
 * 		case 'request_init':
 * 			console.log(`${action.actionKey} request initiated`);
 * 			break;
 * 		case 'request_success':
 * 			if (isPayloadForAction('getItem', action)) {
 * 				console.log('getItem response', action.response);
 * 			} else if (isPayloadForAction('saveItem', action)) {
 * 				console.log('saveItem response', action.response);
 * 			}
 * 			break;
 * 		case 'request_error':
 * 			console.error(`Error sending ${action.actionKey} request`);
 * 			break;
 * 		case 'request_cancel':
 * 			console.log(`${action.actionKey} request cancelled`);
 * 			break;
 * 	}
 * });
 *
 * var requestId = dataService.initRequest('getItem', { itemId: 'a' }); //makes http post to `/baseUrl/get-item-url`
 * dataService.cancelRequest(requestId); // logs: getItem request cancelled
 *
 * var dataServiceWithMiddleware = createDataService(apiConfig, {
 * 	middleware: [
 * 		next => action => {
 * 			console.log('1', action);
 *
 * 			if (action.type === 'request_error') {
 * 				//a middleware can pass a modified action down the chain
 * 				return next({ ...action, type: 'request_success', response: {} });
 * 			}
 *
 * 			return next(action);
 * 		},
 * 		next => action => {
 * 			console.log('2', action);
 *
 * 			//not calling next prevents default execution
 * 			return action;
 * 		},
 * 		next => action => {
 * 			console.log('3', action); //never call because the second middleware function does not call next
 * 			return next(action);
 * 		}
 * 	]
 * });
 * @export
 * @class DataService
 * @implements {IDataService<TApiConfig>}
 * @template TApiConfig
 */
export class DataService<TApiConfig extends IApiConfig> implements IDataService<TApiConfig> {
	private subject = new Subject<DataServiceAction<TApiConfig, any>>();
	private pendingRequests: PendingRequests = {};
	private nextRequestId = 1;
	private middlewareReversed: DataServiceMiddleware<TApiConfig, any>[];
	constructor(private apiConfig: TApiConfig, private options?: DataServiceOptions<TApiConfig>) {
		if (!apiConfig) {
			throw new Error('Please provide apiConfig');
		}

		options = options ? { ...options } : {};

		options = this.options = prepareBaseUrl(apiConfig, options);

		if (!options.post) {
			options.post = post;
		}

		//ensures that options.middleware is normalized
		options.middleware = combineMiddleware(options.middleware);

		this.middlewareReversed = options.middleware.slice().reverse();
	}

	subscribe: DataServiceSubscribeCallback<TApiConfig> = (callback) => {
		return this.subject.register(callback);
	}

	initRequest: DataServiceInitRequest<TApiConfig> = (actionKey, request) => {
		const actionConfig = getActionFromApiConfig(this.apiConfig, actionKey);

		request = mobx.isObservable(request) ? mobx.toJS(request) : { ...(request as any) };
		const requestId = `${this.nextRequestId++}`;

		this.applyMiddleware({
			type: 'request_init',
			actionKey: actionKey,
			requestId: requestId,
			request: request,
		}, (action) => {
			this.subject.raise(action);

			const { baseUrl, timeout } = this.options;

			const promise = this.options.post(actionConfig.url(request), request, { baseUrl, timeout })
				.then(response => {
					delete this.pendingRequests[requestId];

					this.applyMiddleware({
						type: 'request_success',
						actionKey: actionKey,
						requestId: requestId,
						request: request,
						response: response,
					}, (action) => {
						this.subject.raise(action);
					});
				}, error => {
					delete this.pendingRequests[requestId];

					this.applyMiddleware({
						type: 'request_error',
						actionKey: actionKey,
						requestId: requestId,
						request: request,
						error: error,
					}, (action) => {
						this.subject.raise(action);
					});
				})
				.catch(e => {
					this.reportUnexpectedError(e, actionKey, request, requestId);
				})
				//we want to reportUnhandledRejection as a last resort
				//when applyMiddleware for unexpected_error fails for some reasons
				.catch(window.reportUnhandledRejection);

			this.pendingRequests[requestId] = { promise, request, actionKey: `${actionKey}` };
		});

		return requestId;
	}

	cancelRequest = (requestId: string) => {
		const pending = this.pendingRequests[requestId];
		if (!pending) {
			return;
		}

		this.applyMiddleware({
			type: 'request_cancel',
			actionKey: pending.actionKey,
			requestId: requestId,
			request: pending.request,
		}, (action) => {
			pending.promise.cancel();
		});
	}

	getActionSubscriberFactory<TActionKey extends ActionKey<TApiConfig>>(actionKey: TActionKey)
		: (subscriber: (action) => void) => IDataServiceActionSubscriber<TApiConfig, TActionKey> {
		return (subscriber: (action) => void) => new DataServiceActionSubscriber<TApiConfig, TActionKey>(this, actionKey, subscriber);
	}

	private applyMiddleware<TApiConfig extends IApiConfig, TActionKey extends ActionKey<TApiConfig>>(
		action: DataServiceAction<TApiConfig, TActionKey>,
		last: (action: DataServiceAction<TApiConfig, TActionKey>) => void,
	) {

		let lastHandler = action => { last(action); return action; };

		for (let middlewareHandler of this.middlewareReversed) {
			const cachedLastHandler = lastHandler;
			lastHandler = action => middlewareHandler(cachedLastHandler)(action);
		}

		lastHandler(action);
	}

	private reportUnexpectedError(error, actionKey, request, requestId) {
		this.applyMiddleware({
			type: 'unexpected_error',
			actionKey: actionKey,
			requestId: requestId,
			request: request,
			error: error,
		}, (action) => {
			this.subject.raise(action);
		});
	}
}

export class DataServiceActionSubscriber<TApiConfig extends IApiConfig, TActionKey extends ActionKey<TApiConfig>> implements IDataServiceActionSubscriber<TApiConfig, TActionKey> {
	@mobx.observable private currentlyProcessingRequest: string = null;
	private unsubscriber: Function;

	constructor(private dataService: DataService<TApiConfig>, private actionKey: TActionKey, subscriber) {
		this.subscribe(subscriber);
	}

	initRequest = (request: ActionRequest<TApiConfig, TActionKey>) => {
		this.cancelRequest();
		this.currentlyProcessingRequest = this.dataService.initRequest(this.actionKey, request);
	}

	destroy = () => {
		this.cancelRequest();
		this.unsubscriber();
	}

	@mobx.computed
	get isProcessingRequest() {
		return this.currentlyProcessingRequest !== null;
	}

	cancelRequest = () => {
		if (this.currentlyProcessingRequest) {
			this.dataService.cancelRequest(this.currentlyProcessingRequest);
			this.currentlyProcessingRequest = null;
		}
	}

	private subscribe = (subscriber) => {
		this.unsubscriber = this.dataService.subscribe((action) => {
			if (!isActionForService(action, this.actionKey)) {
				return;
			}

			if (action.requestId !== this.currentlyProcessingRequest) {
				return;
			}

			switch (action.type) {
				case 'request_cancel':
				case 'request_error':
				case 'request_success':
					this.currentlyProcessingRequest = null;
			}
			subscriber(action);
		});
	}
}
