import * as React from 'react';
import * as Models from './transaction-import-generated';
import { TransactionImportSummaryViewModel } from './transaction-import-summary-view-model';
import { ImportStepHeader, ImportStep } from './components/import-step-header';
import { ImportStepDescription } from './components/import-step-description';
import { AlertControl } from './components/alert-control';
import { ProgressBar } from './components/progress-bar';
import { Button } from '../components/button';
import { ModalDialogCommander } from '../components/modal-dialog-commander';
import { ModalDialog } from '../components/modal-dialog';
import { FormControlCheckbox } from '../components/form-controls/form-control-checkbox/form-control-checkbox';
import { FormControlSelect } from '../components/form-controls/form-control-select';
import { observer } from 'mobx-react';
import { Formatter } from '../helpers/formatter';
import {LoadingBox} from '../components/loading-box';
import {SvgWrapper} from '../components/svg-wrapper';
import { Fragment } from '../../Shared/components/fragment';

@observer
export class TransactionImportSummary extends React.Component<{vm: TransactionImportSummaryViewModel}, {}> {

	render() {
		const {
			paymentLabel,
			importStatus,
			alertProps,
			listingName,
			importType,
			transactionPeriod,
			timezoneId,
			totalAmount,
			importedAmount,
			skippedAmount,
			transactionsCount,
			percentComplete,
			payersCount,
			newPayersCount,
			fundsCount,
			newFundsCount,
			sendInvitationEmail,
			funds,
			skippedRows,
			totalSkippedRowCount,
			canLoadMoreSkippedRows,
			isLoadingMoreSkippedRows,
			navigationInProgress,
			availableTimezones,
			selectedTimezoneLabel,
			supportUrl,
		} = this.props.vm;

		const totalAmountElement = <span><span className="dollar">$</span>{Formatter.formatNumberForDisplay(totalAmount)}</span>;
		const importedAmountElement = <span><span className="dollar">$</span>{Formatter.formatNumberForDisplay(importedAmount)}</span>;
		const skippedAmountElement = <span><span className="dollar">$</span>{Formatter.formatNumberForDisplay(skippedAmount)}</span>;
		const disableSendEmail = importStatus === Models.ImportStatusForUI.Importing || importStatus === Models.ImportStatusForUI.Completed && sendInvitationEmail;
		const showImportedTotal = importStatus === Models.ImportStatusForUI.Importing || importStatus === Models.ImportStatusForUI.Completed;
		return (
			<div>
				<AlertControl {...alertProps} />

				{this.renderHeading()}
				{importStatus === Models.ImportStatusForUI.Importing && <ProgressBar percentComplete={percentComplete} />}

				<form className="panel panel-color panel-default gray transaction-import-summary-panel">
					<div className="panel-heading top-border">
						<div className="summary-heading">
							<strong>Listing</strong>
							<div className="summary-title">{listingName}</div>
							<div className="summary-description hidden-xs">
								<strong>Data format:</strong> {importType} | <strong>Transaction period:</strong> {transactionPeriod}
							</div>
						</div>
					</div>
					<div className="summary-panel-body">
						{this.renderSummaryRowMobileOnly('Data format', importType)}
						{this.renderSummaryRowMobileOnly('Transaction period', transactionPeriod)}

						{this.renderSummaryRowResponsive('Total amount', totalAmountElement, 'Total number of transactions', transactionsCount.toString(), { renderSubValue: true })}
						{showImportedTotal && this.renderSummaryRowResponsive('Imported amount', importedAmountElement, 'Skipped amount', skippedAmountElement, { renderSubValue: true })}
						{this.renderSummaryRowResponsive('Number of funds', fundsCount.toString(), 'New funds created', newFundsCount.toString(), { renderSubValue: true })}
						{this.renderSummaryRowResponsive(
							`Number of ${paymentLabel.PayerPluralLowerCase}`,
							payersCount.toString(),
							`New ${paymentLabel.PayerPluralLowerCase} added`,
							newPayersCount.toString(),
							{ renderSubValue: importStatus === Models.ImportStatusForUI.Completed })
						}

						{importStatus === Models.ImportStatusForUI.Completed &&
							<div className="row summary-panel-row">
								<div className="col-xs-6 summary-panel-row-label">Number of skipped rows</div>
								<div className="col-xs-6 summary-panel-row-value">{totalSkippedRowCount}</div>
								{totalSkippedRowCount > 0 &&
									<div className="col-xs-12"><a href="#skipped_rows">View more details</a></div>
								}
							</div>
						}

						{this.renderTimezoneSelectRow(importStatus, timezoneId, availableTimezones, selectedTimezoneLabel)}

						<div className="summary-panel-row-send-email">
							<FormControlCheckbox containerClassNames="checkbox-green" disabled={disableSendEmail} value={sendInvitationEmail} onChangeHandler={this.handleSendEmailChange}
								text={`Send email notifications to ${paymentLabel.PayerPluralLowerCase} about their ${paymentLabel.NounPluralLowerCase}.`} />
							<a className="arrow-hollow-right-after info-link" target="_blank" rel="noopener noreferrer" href={supportUrl}>
								Learn more
							</a>
						</div>
					</div>

					{importStatus === Models.ImportStatusForUI.NotStarted &&
						<div className="panel-footer panel-footer-btn-group ">
							<Button className="btn btn-ghost" onClick={this.handleBack} disabled={navigationInProgress}>Back</Button>
							<Button className="btn btn-default" onClick={this.handleSubmit} disabled={navigationInProgress}>Import</Button>
						</div>
					}
				</form>

				{importStatus !== Models.ImportStatusForUI.NotStarted &&
					<SummaryFundsGrid funds={funds}/> }

				{importStatus === Models.ImportStatusForUI.Completed &&
					<SummarySkippedRowsGrid
						skippedRows={skippedRows}
						totalRowCount={totalSkippedRowCount}
						canLoadMore={canLoadMoreSkippedRows}
						isLoadingMore={isLoadingMoreSkippedRows}
						handleLoadMore={this.handleLoadMore} />
				}
			</div>
		);
	}

	private renderHeading = () => {
		const {
			importStatus,
			listingName,
			transactionPeriod,
			importedBy,
			date,
			isDeletingImport,
		} = this.props.vm;

		if (importStatus === Models.ImportStatusForUI.NotStarted) {
			return (
				<div>
					<ImportStepHeader currentStep={ImportStep.Review}/>

					<ImportStepDescription title="Review and confirm" description="Please review all details before importing your transaction data into Pushpay." />
				</div>
			);
		} else {
			return (
				<div>
					<div className="transaction-import-summary-title">
						<div className="visible-xs">{this.renderImportStatus(importStatus)}</div>
						<h1>{listingName} - {transactionPeriod}<span className="hidden-xs"> {this.renderImportStatus(importStatus)}</span></h1>

						{importStatus === Models.ImportStatusForUI.Completed &&
							<button type="button" className="btn btn-link delete-import-btn" aria-lable="delete" disabled={isDeletingImport} onClick={this.handleDelete}>
								<SvgWrapper className="icon" svg="icon-delete" />
								Delete
							</button>
						}
					</div>
					<ul className="list-unstyled transaction-import-summary-subtitle">
						<li><strong>Date {this.renderDateVerb(importStatus)}:</strong> {date}</li>
						<li><strong>Imported by:</strong> {importedBy}</li>
					</ul>
					{importStatus === Models.ImportStatusForUI.Completed &&
						<h2>Summary</h2>
					}
				</div>
			);
		}
	}

	private renderImportStatus = (importStatus: Models.ImportStatusForUI) => {
		switch (importStatus) {
			case Models.ImportStatusForUI.Importing:
				return <span className="label label-warning">importing</span>;
			case Models.ImportStatusForUI.Completed:
				return <span className="label label-success">imported</span>;
		}
	}

	private renderDateVerb = (importStatus: Models.ImportStatusForUI) => {
		switch (importStatus) {
			case Models.ImportStatusForUI.NotStarted:
			case Models.ImportStatusForUI.Importing:
				return <span>started</span>;
			case Models.ImportStatusForUI.Completed:
				return <span>imported</span>;
		}
	}

	private renderTimezoneSelectRow = (importStatus: Models.ImportStatusForUI, timezoneId: string, availableTimezones: Models.SelectItem[], selectedTimezoneLabel: string) => {
		if (importStatus === Models.ImportStatusForUI.NotStarted) {
			return (
				<div className="row summary-panel-row">
					<div className="col-sm-8 summary-panel-row-label">Timezone for file</div>
					<div className="col-sm-4">
						<FormControlSelect
							Options={availableTimezones}
							name="timezone"
							hideEmptyOption={true}
							onChangeHandler={this.handleTimezoneSelected}
							value={timezoneId} />
					</div>
				</div>
			);
		} else {
			return (
				<div className="row summary-panel-row">
					<div className="col-xs-6 summary-panel-row-label">Timezone for file</div>
					<div className="col-xs-6 summary-panel-row-value-small">{selectedTimezoneLabel}</div>
				</div>
			);
		}
	}

	private renderSummaryRowResponsive = (
		mainLabel: string, mainValue: string | React.ReactElement<any>,
		subLabel: string, subValue: string | React.ReactElement<any>, options: { renderSubValue: boolean }) => {
		const { renderSubValue } = options;
		return (
			<Fragment>
				<div className="row summary-panel-row">
					<div className="col-xs-6 summary-panel-row-label">{mainLabel}</div>
					<div className="col-xs-6 summary-panel-row-value">{mainValue}</div>
					{renderSubValue &&
						<div className="col-xs-12 hidden-xs">{subLabel}: {subValue}</div>
					}
				</div>
				{renderSubValue &&
					<div className="row summary-panel-row visible-xs">
						<div className="col-xs-6 summary-panel-row-label">{subLabel}</div>
						<div className="col-xs-6 summary-panel-row-value">{subValue}</div>
					</div>
				}
			</Fragment>
		);
	}

	private renderSummaryRowMobileOnly = (label: string, value: string) => {
		return (
			<div className="row summary-panel-row visible-xs">
				<div className="col-xs-6 summary-panel-row-label">{label}</div>
				<div className="col-xs-6 summary-panel-row-value">{value}</div>
			</div>
		);
	}

	private handleSendEmailChange = (sendInvitationEmail: boolean) => {
		const { importStatus } = this.props.vm;
		const { paymentLabel } = this.props.vm;

		switch (importStatus) {
			case Models.ImportStatusForUI.NotStarted:
				this.props.vm.updateSendEmail(sendInvitationEmail);
				break;
			case Models.ImportStatusForUI.Completed:
				if (sendInvitationEmail) {
					const confirmButton = <Button className="btn btn-primary" onClick={this.comfirmSendEmail}>Send</Button>;
					const buttons = [{label: 'Cancel', dismiss: true, isPrimary: false}, confirmButton];
					ModalDialogCommander.showReactDialog(
						<ModalDialog
							title={`Are you sure you want to send email notifications to your ${paymentLabel.PayerPluralLowerCase}?`}
							showAlert={false}
							hideCloseButton={true}
							buttons={buttons}/>);
				} else {
					this.props.vm.updateSendEmail(false);
				}
				break;

		}
	}

	private comfirmSendEmail = () => {
		this.props.vm.updateSendEmail(true);
		this.props.vm.initConfirmSendEmailRequest();
		ModalDialogCommander.forceCloseCurrent();
	}

	private handleTimezoneSelected = (event: React.FormEvent<HTMLSelectElement>) => {
		const value = event.currentTarget.value;
		this.props.vm.updateTimezoneId(value);
	}

	private handleSubmit = () => {
		const confirmButton = <Button className="btn btn-primary" onClick={this.confirmImport}>Confirm</Button>;
		const buttons = [{label: 'Cancel', dismiss: true, isPrimary: false}, confirmButton];
		ModalDialogCommander.showReactDialog(
			<ModalDialog
				title={'You\'re about to import your transaction data into Pushpay. Are you sure you want to do this?'}
				showAlert={false}
				hideCloseButton={true}
				buttons={buttons}/>);
	}

	private confirmImport = () => {
		ModalDialogCommander.forceCloseCurrent();
		this.props.vm.initConfirmImportRequest();
		window.scrollTo(0, 0);
	}

	private handleBack = () => {
		this.props.vm.navigateBack();
	}

	private handleLoadMore = () => {
		this.props.vm.initLoadMoreRequest();
	}

	private handleDelete = () => {
		const confirmButton = <Button className="btn btn-primary" onClick={this.confirmDelete}>Delete</Button>;
		const buttons = [{label: 'Cancel', dismiss: true, isPrimary: false}, confirmButton];
		ModalDialogCommander.showReactDialog(
			<ModalDialog
				title={'Are you sure you want to delete this import? Deleting this import will remove your data from Pushpay.'}
				showAlert={false}
				hideCloseButton={true}
				buttons={buttons}/>);
	}

	private confirmDelete = () => {
		ModalDialogCommander.forceCloseCurrent();
		this.props.vm.initDeleteImportRequest();
	}
}

export const SummaryFundsGrid: React.StatelessComponent<{funds: Models.FundSummaryInfo[]}> = (props) => {
	const { funds } = props;
	const fundsCount = funds.length;
	return (
		<div className="transaction-import-summary-grid">
			<div className="summary-grid-heading">
				<div className="summary-grid-title">Funds</div>
				<p>There are <strong>{fundsCount} {fundsCount === 1 ? 'fund' : 'funds'}</strong> that {fundsCount === 1 ? 'has' : 'have'} been imported.</p>
			</div>

			<div className="row summary-grid-header hidden-xs">
				<div className="col-sm-3 col-xs-8">Funds from your file</div>
				<div className="col-sm-3 hidden-xs">Pushpay funds</div>
				<div className="col-sm-4 hidden-xs">Notes</div>
				<div className="col-sm-2 col-xs-4 text-right">Amount</div>
			</div>
			{funds.map((fund, index) => (
				<div className="row summary-grid-row" key={index}>
					<div className="col-sm-3 col-xs-8 summary-grid-cell">{fund.ImportedFundName}</div>
						<div className="col-sm-3 hidden-xs summary-grid-cell">{fund.PushpayFundName} <span className="summary-text-secondary">({fund.PushpayFundCode})</span></div>
					<div className="col-sm-4 hidden-xs summary-grid-cell">{fund.Notes}</div>
					<div className="col-sm-2 col-xs-4 text-right summary-grid-cell">${Formatter.formatNumberForDisplay(fund.Amount)}</div>
				</div>
			))}
		</div>
	);
};


export interface SummarySkippedRowsGridProps {
	skippedRows: Models.SkippedRowInfo[];
	totalRowCount: number;
	canLoadMore: boolean;
	isLoadingMore: boolean;
	handleLoadMore: () => void;
}

export const SummarySkippedRowsGrid: React.StatelessComponent<SummarySkippedRowsGridProps> = (props) => {
	const {
		skippedRows,
		totalRowCount,
		canLoadMore,
		isLoadingMore,
		handleLoadMore,
	} = props;

	return (
		<div className={`transaction-import-summary-grid-loading-wrapper${isLoadingMore ? ' loading' : ''}`}>
			<div className="transaction-import-summary-grid" id="skipped_rows">
				<div className="summary-grid-heading">
					<div className="summary-grid-title">Skipped rows</div>
					<p>
						There {totalRowCount === 1 ? 'is' : 'are'} <strong>{totalRowCount} {totalRowCount === 1 ? 'row' : 'rows'}</strong> that {totalRowCount === 1 ? 'has' : 'have'} been skipped.
					</p>
				</div>

				{totalRowCount > 0 &&
					<div className="row summary-grid-header hidden-xs">
						<div className="col-sm-3">Row</div>
						<div className="col-sm-6">Reason</div>
						<div className="col-sm-3 hidden-xs text-right">Amount</div>
					</div>
				}

				{skippedRows.map( row => (
					<div className="row summary-grid-row" key={row.RowNumber}>
						<div className="col-sm-3 summary-grid-cell"><span className="summary-label-responsive">Row {row.RowNumber}</span></div>
						<div className="col-sm-6 summary-grid-cell">
							{row.Reason} { row.DuplicatePaymentUrl && <a href={row.DuplicatePaymentUrl}>(ID #{row.DuplicatePaymentId})</a>}
						</div>
						<div className="col-sm-3 hidden-xs text-right summary-grid-cell">${Formatter.formatNumberForDisplay(row.Amount)}</div>
					</div>
				))}

				{ isLoadingMore && <LoadingBox text="Loading data"/>}
			</div>
			{canLoadMore &&
				<div className="list-group-load-more">
					<button onClick={handleLoadMore} disabled={isLoadingMore} className="btn btn-ghost">Load More</button>
				</div>
			}
		</div>

	);
};
