import * as React from 'react';
import { observer } from 'mobx-react';
import { ValidatableForm } from './form-controls/validatable-form';
import { ValidationSummary } from './form-controls/validation-summary';
import { Button } from './button';

export interface IModalDialogFormProps {
	onSubmitHandler?: () => void;
	dataModalPreventClose?: boolean;
	validationSummaryTitle?: string;
	validationErrors?: { [s: string]: string };
	hideClose?: boolean;
	heading: string;
	buttons?: (JSX.Element | Button)[];
}

@observer
export class ModalDialogForm extends React.Component<IModalDialogFormProps> {
	render() {
		const {
			dataModalPreventClose,
			onSubmitHandler,
			validationSummaryTitle,
			validationErrors,
			hideClose,
			heading,
			buttons,
			children,
		} = this.props;
		const validationSummaryErrors = validationErrors ? Object.keys(validationErrors).map(key => validationErrors[key]) : [];
		return (
			<div className="modal modal-form fade" tabIndex={-1} role="dialog" data-modal-prevent-close={dataModalPreventClose}>
				<ValidatableForm className="panel panel-default modal-dialog" onSubmit={onSubmitHandler} validationErrors={validationErrors}>
					{!hideClose && <button type="button" className="modal-form-close" data-dismiss="modal" aria-label="Close"></button>}
					<header className="panel-heading">
						{heading}
					</header>
					<div className="panel-body">
						<ValidationSummary validationErrors={validationSummaryErrors} customTitle={validationSummaryTitle} />
						{children}
					</div>
					{
						buttons && <footer className="panel-footer panel-footer-btn-group">
							{buttons}
						</footer>
					}
				</ValidatableForm>
			</div>
		);
	}
}
