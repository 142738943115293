import { recordEvent as recordEventBase } from '@pushpay/analytics';
import { inject, IReactComponent, IWrappedComponent } from 'mobx-react';

export type WithAnalytics = { analytics?: { feature: string; subFeature?: string } };

export type AnalyticsContext = {
	feature: string;
	subFeature?: string;
};

type EventArgs = {
	eventTypeLabel: string;
	attributes?: Record<string, string>;
	metrics?: Record<string, number>;
	immediate?: boolean;
};

export function recordEvent({ feature, subFeature, eventTypeLabel, attributes, metrics, immediate }: AnalyticsContext & EventArgs) {
	return recordEventBase({
		name: [feature, subFeature, eventTypeLabel].filter(Boolean).join('.'),
		attributes,
		metrics,
		immediate,
	});
}

export function injectAnalytics<T extends IReactComponent>(target: T): T & IWrappedComponent<T> {
	return inject((storeMap: WithAnalytics) => ({ analytics: storeMap.analytics }))(target);
}
