import { action, computed, observable } from 'mobx';
import {
	CheckDepositDataService,
	CheckDepositDataServiceAction,
	isCheckDepositAction
} from '../check-deposit-data-service';
import { Debouncer } from '../../utils/debouncer';
import { Models } from '../check-deposit-generated';
import { IDateRange } from '../../components/form-controls/form-control-date-range-labelled';
import BatchStatusFilter = Models.BatchStatusFilter;
import BatchesSummaryViewModel = Models.BatchesSummaryViewModel;
import BatchesViewModel = Models.BatchesViewModel;
import BatchItemViewModel = Models.BatchItemViewModel;
import BatchesQueryModel = Models.BatchesQueryModel;
import DropDownCustomFieldOptionViewModel = Models.DropDownCustomFieldOptionViewModel;
import SortOrder = Models.SortOrder;
import StatusFilter = Models.BatchStatusFilter;
import MultiSelectItem = Models.MultiSelectItem;

export class BatchListStore {
	@observable batchesBasedOn: IBatchesBasedOn;
	@observable canLoadMore: boolean = false;
	@observable totalBatchCount: number = 0;
	@observable batches: BatchItemViewModel[];
	@observable queryModel: BatchesQueryModel;
	@observable listings: MultiSelectItem[];
	@computed get selectedlistingIdsAsStrings(): string[] {
		return this.queryModel.ListingIds.map(x => x.toString());
	}
	@observable dateRange: IDateRange;
	@observable numberOfRegisteredScanners: number;
	@observable mobileFiltersExpanded: boolean = false;
	@observable mobileSortingActive: boolean = false;
	@observable batchCurrentlyBeingDismissed: BatchItemViewModel = null;
	readonly isCheckScanningReadOnly: boolean;

	@observable private currentlyProcessingQuery: string = null;
	@observable private userInitiatedQuery: boolean = true;
	private queryBatchesAllDebouncer: Debouncer<void>;

	constructor(public dataService: CheckDepositDataService, batchesSummaryViewModel: BatchesSummaryViewModel) {
		this.dataService.subscribe((action) => {
			this.subscribeToQueryBatches(action);
		});

		this.listings = batchesSummaryViewModel.Listings;

		this.batchesBasedOn = {
			numberOfBatches: 0,
			selectedListingIds: [],
			status: BatchStatusFilter.PendingOrActive,
		};

		this.isCheckScanningReadOnly = batchesSummaryViewModel.IsCheckScanningReadOnly;
		this.batches = [];
		this.resetQueryModel(StatusFilter.PendingOrActive);
		this.numberOfRegisteredScanners = batchesSummaryViewModel.NumberOfRegisteredScanners;
		this.queryBatchesAllDebouncer = new Debouncer<void>(60000, this.pollBatches);
	}

	@computed
	get numberOfBatches() {
		return this.batches.length;
	}

	@action
	dismissBatch = (batch: BatchItemViewModel) => {
		this.batchCurrentlyBeingDismissed = batch;
		const batchIndex = this.batches.indexOf(batch);
		this.batches.splice(batchIndex, 1);
	}

	@action
	queryBatches = () => {
		this.userInitiatedQuery = true;
		this.queryBatchesAllDebouncer.cancel();
		this.dataService.initRequest('queryBatches', { query: this.queryModel });
	}

	@action
	refreshBatches = () => {
		this.userInitiatedQuery = true;
		this.queryBatchesAll();
	}

	@action
	pollBatches = () => {
		this.userInitiatedQuery = false;
		this.queryBatchesAll();
	}

	@action
	updateBatches = (viewModel: BatchesViewModel) => {
		this.canLoadMore = viewModel.CanLoadMore;
		this.totalBatchCount = viewModel.TotalBatchCount;
		this.batches = this.queryModel.Page === 0
			? viewModel.Batches
			: this.batches.concat(viewModel.Batches);
		this.updateBatchesBasedOn();
	}

	@action
	updateBatchesBasedOn = () => {
		this.batchesBasedOn = {
			numberOfBatches: this.totalBatchCount,
			status: this.queryModel.Status,
			selectedListingIds: this.queryModel.ListingIds,
			fromDate: this.queryModel.FromDate,
			toDate: this.queryModel.ToDate,
		};
	}

	@action
	updateSelectedListings = (listingIds: string[]) => {
		this.queryModel.ListingIds = listingIds.map(x => Number(x));
		this.resetPaging();
		this.queryBatches();
	}

	@action
	setProgressToInProgress = () => {
		if (this.queryModel.Status !== StatusFilter.Closed) {
			return;
		}
		this.resetQueryModel(StatusFilter.PendingOrActive);
		this.queryBatches();
	}

	@action
	setProgressToCompleted = () => {
		if (this.queryModel.Status === StatusFilter.Closed) {
			return;
		}
		this.resetQueryModel(StatusFilter.Closed);
		this.queryBatches();
	}

	@action
	updateStatus = (status: StatusFilter) => {
		this.queryModel.Status = status;
		this.resetPaging();
		this.queryBatches();
	}

	@action
	updateSortBy = (sortBy: SortOrder) => {
		this.queryModel.SortBy = sortBy;
		this.resetPaging();
		this.queryBatches();
	}

	@action
	updateDateRange = (dateRange: IDateRange) => {
		this.dateRange = dateRange;
		this.queryModel.FromDate = dateRange.from;
		this.queryModel.ToDate = dateRange.to;
		this.resetPaging();
		this.queryBatches();
	}

	@action
	loadMore = () => {
		this.queryModel.Page++;
		this.queryBatches();
	}

	@action
	resetPaging = () => {
		this.queryModel.Page = 0;
	}

	@action
	toggleMobileFilters = () => {
		this.mobileFiltersExpanded = !this.mobileFiltersExpanded;
	}

	@action
	activateMobileSorting = () => {
		this.mobileSortingActive = true;
		this.mobileFiltersExpanded = false;
	}

	@action
	deactivateMobileSorting = () => {
		this.mobileSortingActive = false;
	}

	@computed
	get isCurrentlyProcessingUserInitiatedQuery() {
		return this.currentlyProcessingQuery !== null && this.userInitiatedQuery;
	}

	@computed
	get isCurrentlyProcessingQuery() {
		return this.currentlyProcessingQuery !== null;
	}

	@action
	private queryBatchesAll = () => {
		this.queryBatchesAllDebouncer.cancel();
		this.dataService.initRequest('queryBatches', { query: { ...this.queryModel, TakeAllUpToCurrentPage: true } });
	}

	@action
	private resetQueryModel = (status: StatusFilter) => {
		this.queryModel = {
			ListingIds: [],
			FromDate: null,
			ToDate: null,
			Status: status,
			SortBy: status == StatusFilter.Closed ? SortOrder.CreatedOnDesc : SortOrder.CreatedOnAsc,
			Page: 0,
			TakeAllUpToCurrentPage: false
		};
		this.dateRange = {
			from: null,
			to: null
		};
	}

	@action
	private subscribeToQueryBatches = (action: CheckDepositDataServiceAction) => {
		if (!isCheckDepositAction(action, 'queryBatches')) {
			return;
		}

		switch (action.type) {
			case 'request_init':
				if (this.currentlyProcessingQuery !== null) {
					this.dataService.cancelRequest(this.currentlyProcessingQuery);
				}
				this.currentlyProcessingQuery = action.requestId;
				break;
			case 'request_success':
				this.currentlyProcessingQuery = null;
				this.debounceQueryBatchesAll();
				this.updateBatches(action.response);
				break;
			case 'request_cancel':
			case 'request_error':
				this.currentlyProcessingQuery = null;
				this.debounceQueryBatchesAll();
				break;
		}
	}

	@action
	private debounceQueryBatchesAll = () => {
		if (this.queryModel.Status !== StatusFilter.Closed) {
			this.queryBatchesAllDebouncer.exec();
		}
	}
}

export interface IBatchesBasedOn {
	numberOfBatches: number;
	status: StatusFilter;
	selectedListingIds: number[];
	fromDate?: Date;
	toDate?: Date;
}
