var arrayPrototype: any = Array.prototype;

export class OrderedEnumerable<T> implements IOrderedEnumerable<T> {
	array: Array<T>;
	allTheThings: Array<{ predicate: any, multiplier: number }> = [];

	constructor(array: Array<T>) {
		this.array = array;
	}

	thenBy<TProp>(predicate: (x: T) => TProp): IOrderedEnumerable<T> {
		this.allTheThings.push({ predicate, multiplier: 1 });
		return this;
	}

	thenByDescending<TProp>(predicate: (x: T) => TProp): IOrderedEnumerable<T> {
		this.allTheThings.push({ predicate, multiplier: -1 });
		return this;
	}

	toArray(): T[] {
		return this.array.sort((a, b) => {
			let result: number;
			for (let thing of this.allTheThings) {
				result = sortingResult(a, b, thing.predicate, thing.multiplier);
				if (result !== 0) {
					return result;
				}
			}

			return 0;
		});
	}
}

function sortingResult(a, b, predicate, multiplier: number) {
	'use strict';
	var valueA = predicate(a);
	var valueB = predicate(b);

	if (valueA === null && valueB === null) {
		return 0;
	} else if (valueA !== null && valueB === null) {
		return 1 * multiplier;
	} else if (valueA === null && valueB !== null) {
		return -1 * multiplier;
	}

	if (valueA > valueB) {
		return 1 * multiplier;
	} else if (valueA < valueB) {
		return -1 * multiplier;
	}

	return 0;
}

if (!arrayPrototype.orderBy) {
	arrayPrototype.orderBy = function(predicate) {
		'use strict';
		return new OrderedEnumerable(this).thenBy(predicate);
	};
}

if (!arrayPrototype.orderByDescending) {
	arrayPrototype.orderByDescending = function(predicate) {
		'use strict';
		return new OrderedEnumerable(this).thenByDescending(predicate);
	};
}
