import * as React from 'react';
import { observer } from 'mobx-react';

import { Fragment } from '../../../../Shared/components/fragment';
import { ScheduleMigrationEditViewModel } from './schedule-migration-edit-view-model';
import { ValidatableForm } from '../../../components/form-controls/validatable-form';
import { MigrationDateRange } from '../../components/migration-date-range/migration-date-range';
import { MigrationContactDetails } from '../../components/migration-contact-details/migration-contact-details';
import { MigrationEmails } from '../../components/migration-emails/migration-emails';
import { Button } from '../../../components/button';
import { ModalDialogCommander } from '../../../components/modal-dialog-commander';
import { ModalDialog } from '../../../components/modal-dialog';
import { AlertControl } from '../../../transaction-import/components/alert-control';

@observer
export class ScheduleMigrationEdit extends React.Component<{ vm: ScheduleMigrationEditViewModel }> {
	render() {
		const {
			dateRangeViewModel,
			contactDetailsViewModel,
			emailsViewModel,
			viewData: {
				MinDaysRemainingForUpdate,
				PaymentLabel,
				EmailBranding,
			},
			actionInProgress,
			alertProps,
			migrationStatusText,
			migrationStatusClassName,
		} = this.props.vm;

		return (
			<Fragment>
				<AlertControl {...alertProps} />
				<h1>Edit migration details <span className={`label ${migrationStatusClassName} list-group-item-label`}>{migrationStatusText}</span></h1>
				<ValidatableForm className="panel panel-default" onSubmit={this.handleSubmit}>
					<div className="panel-heading">Edit date range</div>
					<div className="panel-body">
						{/* tslint:disable:max-line-length */}
						<MigrationDateRange vm={dateRangeViewModel}
							startDateTip="Editing start date will begin your migration in the future."
							endDateTip={`Editing end date will extend the length of time of your migration will run for. You will not be able to edit your migration end date ${MinDaysRemainingForUpdate} days before it ends`}
						/>
						{/* tslint:enable:max-line-length */}
					</div>
					<div className="panel-heading">Edit contact details for emails</div>
					<div className="panel-body">
						<p>Editing your contact details will update the contact details on your next email that will be sent to your {PaymentLabel.PayerPluralLowerCase}.</p>
						<MigrationContactDetails vm={contactDetailsViewModel} paymentLabel={PaymentLabel}/>
					</div>
					<div className="panel-heading">Edit emails content</div>
					<div className="panel-body">
						<p>Editing your email content will update your next email that will be sent to your {PaymentLabel.PayerPluralLowerCase}.</p>
						<MigrationEmails vm={emailsViewModel}
							paymentLabel={PaymentLabel}
							contactName={contactDetailsViewModel.contactName}
							contactEmail={contactDetailsViewModel.contactEmail}
							contactPhone={contactDetailsViewModel.contactPhone}
							emailBranding={EmailBranding}
							endDate={dateRangeViewModel.endDate}/>
					</div>
					<div className="panel-footer panel-footer-btn-group">
						<Button className="btn btn-ghost" onClick={this.handleCancel} disabled={actionInProgress}>Cancel</Button>
						<Button className="btn btn-default" submit={true} disabled={actionInProgress}>Save</Button>
					</div>
				</ValidatableForm>
			</Fragment>
		);
	}

	private handleSubmit = () => {
		if (this.props.vm.dateRangeViewModel.isEndDateModified) {
			const confirmButton = <Button className="btn btn-primary" onClick={this.confirmEdit}>Confirm</Button>;
			const buttons = [{label: 'Cancel', dismiss: true, isPrimary: false}, confirmButton];

			ModalDialogCommander.showReactDialog(
				<ModalDialog
					title="You are changing the end date. This is going to adjust the start date of all active schedules. Are you sure you want to do this?"
					showAlert={false}
					hideCloseButton={true}
					buttons={buttons}/>);
		} else {
			this.props.vm.initUpdateMigrationRequest();
		}
	}

	private confirmEdit = () => {
		ModalDialogCommander.forceCloseCurrent();
		this.props.vm.initUpdateMigrationRequest();
	}

	private handleCancel = () => {
		this.props.vm.handleCancel();
	}
}
