import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { TransitionGroup } from 'react-transition-group-v1';
import { velocity } from '../../helpers/velocity';
import { isFunction } from '../../utils/is-function';

export const enterDuration = 250;
export const leaveDuration = 150;

interface ITransitionChildInfo {
	element: HTMLElement;
	callback: () => void;
}

interface ITransitionChildProps {
	onWillEnter: (t: ITransitionChildInfo) => void;
	onWillLeave: (t: ITransitionChildInfo) => void;
}

class TransitionChild extends React.Component<ITransitionChildProps, {}> {
	element: HTMLElement;

	componentWillEnter(callback) {
		const { onWillEnter } = this.props;
		if (isFunction(onWillEnter)) {
			onWillEnter({
				element: this.element,
				callback: callback
			});
		} else {
			callback();
		}
	}

	componentWillLeave(callback) {
		const { onWillLeave } = this.props;
		if (isFunction(onWillLeave)) {
			onWillLeave({
				element: this.element,
				callback: callback
			});
		} else {
			callback();
		}
	}

	componentWillUnmount() {
		// Clear references from velocity cache.
		velocity.Utilities.removeData(this.element, ['velocity', 'fxqueue']);
	}

	render() {
		const props = { ref: (ref) => this.element = ReactDOM.findDOMNode(ref) as HTMLElement };
		return React.cloneElement(React.Children.only(this.props.children) as React.ReactElement, props);
	}
}

export class SmoothHeightTransition extends React.Component<{
	component?: keyof JSX.IntrinsicElements,
	className?: string,
	disableEnterTransition?: boolean,
	disableLeaveTransition?: boolean,
}, {}> {
	private entering: ITransitionChildInfo[] = [];
	private leaving: ITransitionChildInfo[] = [];

	render() {
		return (
			<TransitionGroup component={this.props.component} className={this.props.className} childFactory={this.childFactory}>
				{this.props.children}
			</TransitionGroup>
		);
	}

	private childFactory = (child) => {
		const { disableEnterTransition, disableLeaveTransition } = this.props;
		return (
			<TransitionChild
				onWillEnter={disableEnterTransition ? null : this.childWillEnter}
				onWillLeave={disableLeaveTransition ? null : this.childWillLeave}>
				{child}
			</TransitionChild>
		);
	}

	private childWillEnter = (info: ITransitionChildInfo) => {
		this.entering.push(info);

		info.element.style.opacity = '0';

		this.velocityChain(info.element, ['slideDown', { opacity: 1 }],
			{
				totalDuration: enterDuration,
				complete: () => {
					this.entering.splice(this.entering.indexOf(info), 1);
					info.callback();
				}
			});
	}

	private childWillLeave = (info: ITransitionChildInfo) => {
		this.leaving.push(info);

		this.velocityChain(info.element, [{ opacity: 0 }, 'slideUp'],
			{
				totalDuration: leaveDuration,
				complete: () => {
					this.leaving.splice(this.leaving.indexOf(info), 1);
					info.callback();
				}
			});
	}


	private velocityChain(element, animations: any[], options: { totalDuration: number, complete: () => void}) {
		const numberOfSteps = animations.length;
		const promises = [];

		for (let i = 0; i < animations.length; i++) {
			const animation = animations[i];

			promises.push(velocity(element, animation, {
				duration: options.totalDuration / numberOfSteps
			}));
		}

		Promise.all(promises).then(options.complete);
	}
}
