import { IFormControlSelectOptionProps } from '../../../components/form-controls/form-control-select';
import { action, observable } from 'mobx';
import {
	MappingsSetupProgress,
	PaymentLabel,
	QuickBooksMappingViewModel,
	QuickBooksMerchant,
	QuickBooksMerchantMappingViewModel,
} from '../../qbo-integration-generated';
import { NumberToMappingMap } from './qbo-mapping-client-side-view-model';

export class QboLocationMappingInfoViewModel {
	qbLocations: IFormControlSelectOptionProps[];

	panelEnabled: boolean;

	readonly panelTitle: string;

	setIsLocationsEnabled: (boolean) => void;

	@observable
	listingMappings: ListingMapping[];

	@observable
	enableMappings: boolean;

	@observable
	paymentLabel: PaymentLabel;

	@observable
	activeStep: MappingsSetupProgress;

	@observable
	isProcessingRequest: boolean;

	constructor(model: QuickBooksMappingViewModel,
				panelTitle: string,
				isLocationsEnabled: boolean,
				setIsLocationsEnabled: (boolean) => void,
				public setStepAsActive: (step: MappingsSetupProgress) => void) {

		this.panelTitle = panelTitle;

		this.qbLocations = model.Locations.map(location => ({Label: location.Name, Value: location.Id}));

		this.activeStep = model.SetupProgress;

		this.isProcessingRequest = false;

		this.panelEnabled = this.qbLocations.length > 0;

		this.enableMappings = isLocationsEnabled;

		this.setIsLocationsEnabled = setIsLocationsEnabled;

		const merchantMappingDict: NumberToMappingMap = model.MerchantMappings.reduce((dict, mapping) => {
			dict[mapping.MerchantId] = mapping;
			return dict;
		}, {});

		this.listingMappings = model.Merchants.map(merchant => new ListingMapping(merchant, merchantMappingDict[merchant.MerchantId]));

		this.paymentLabel = model.PaymentLabel;
	}

	@action.bound
	toggleEnableMapping(value: boolean) {
		this.setIsLocationsEnabled(value);
		this.enableMappings = value;
	}

	@action.bound
	setLocalActiveStep(step: MappingsSetupProgress) {
		this.activeStep = step;
	}

	@action.bound
	setIsProcessingRequest(value: boolean) {
		this.isProcessingRequest = value;
	}
}

export class ListingMapping {
	merchantName: string;
	merchantId: number;

	@observable
	locationId: string;

	constructor(merchant: QuickBooksMerchant, merchantMapping: QuickBooksMerchantMappingViewModel) {
		this.merchantName = merchant.MerchantName;
		this.merchantId = merchant.MerchantId;
		this.locationId = merchantMapping ? merchantMapping.LocationId : '';
	}

	@action.bound
	setLocation(newLocationId: string) {
		this.locationId = newLocationId;
	}
}
