import * as React from 'react';
import * as Sorting from '../view-models/sorting';
import {Main} from '../view-models/index';
import * as Common from './_common';
import {MemberContainer} from './member-container';
import {observer} from 'mobx-react';

@observer
class Sorter extends React.Component<{ vm: Sorting.SortField<any>, className?: string }, any> {
	render() {
		return (
			<button onClick={() => this.props.vm.toggle()}
				className={Common.classNames(this.getClassName(this.props.vm.direction), this.props.className)}>
				{this.props.children}
			</button>
		);
	}

	getClassName(dir: Sorting.SortDirection): string {
		switch (dir) {
			case Sorting.SortDirection.Ascending:
				return 'active asc';
			case Sorting.SortDirection.Descending:
				return 'active desc';
			default:
				return '';
		}
	}
}


export const MembersGrid = Common.defineView<Main>(vm => <div
	className={Common.classNames('members grid', { 'sobusy': vm.busyTracker.isBusy })}>
	<div className="grid-row head">
		<span className="row-icon"></span>
		<Sorter vm={vm.sorter.firstName} className="name">First name</Sorter>
		<Sorter vm={vm.sorter.lastName} className="name">Last name</Sorter>
		<Sorter vm={vm.sorter.email} className="email-address">Email address</Sorter>
		<Sorter vm={vm.sorter.mobile} className="mobile-number">Mobile</Sorter>
		<span className="address">Address</span>
		<Sorter vm={vm.sorter.lastActivity} className="last-activity">Last activity</Sorter>
		<div className="commands">
			<button className="icon" accessKey="e" onClick={() => vm.expandCollapseAll()}>
				<span className={`arrow ${!vm.expandCollapseAllState ? 'expand-arrow' : 'closed-arrow'}`}></span>
			</button>
		</div>
	</div>

	{vm.currentMembers.map((x, index) =>
		<MemberContainer vm={x} index={index} key={x.reactKey()}
			enableFeatureOrganizationalGiving={vm.enableFeatureOrganizationalGiving}
			disableCommunityMemberDragAndDrop={vm.disableCommunityMemberDragAndDrop}
			hideGivingStatementButton={vm.hideGivingStatementButton}
		/>)}
</div>);
