import * as React from 'react';
import { observable, action } from 'mobx';
import { observer, inject, Provider } from 'mobx-react';
import { AnalyticsContext, injectAnalytics, recordEvent, WithAnalytics } from '../../../analytics';
import { classNames } from '../../../../Shared/utils/classnames';
import { ConfigurationStep, configurationSteps, GivingStatementsViewModel } from '../../giving-statements-view-model';
import { DownloadStatementsModal } from '../../components/download-statements-modal';
import { IEditEmailTemplateProps } from '../../components/email-editor/email-editor';
import { EmailEditorSelfAccess } from '../../components/email-editor-self-access/email-editor-self-access';
import { Fragment } from '../../../../Shared/components/fragment';
import { LoadingBox } from '../../../components/loading-box';
import { MachineContext, machineContextKey } from '../../../../Shared/state-machine/saga-state-machine';
import { MainEvents } from '../../state/main';
import { Match, MatchAny } from '../../../../Shared/state-machine/match';
import { NoStatementsPanel } from '../../components/no-statements-panel';
import { Panel, PanelType } from '../../../components/panel';
import { ScrollToTop } from '../../components/scroll-to-top';
import { SendEmailConfirmationModal } from '../../components/send-email-confirmation-modal';
import { SendPageStates, SendPageEvents, LOADED_STATES, ErrorModalStates } from '../../state/send-page';
import { SendStatementsPageViewModel } from './send-statements-page-view-model';
import { SentSummary } from '../../components/sent-summary/sent-summary';
import { SentSummaryRunDetails } from '../../components/sent-summary-run-details/sent-summary-run-details';
import { StepStatusIndicator } from '../../../components/step-status';
import { SvgWrapper } from '../../../../Shared/components/svg-wrapper';
import { Tabs, Tab } from '../../components/deliver-statements-tabs/deliver-statements-tabs';
import DownloadTabContentSelfAccess from '../../components/download-tab-content-self-access';
import SetUpContactMethods, { IContactMethodSelectorProps } from '../../components/set-up-contact-methods';
import { ErrorModal } from '../../../giving-statements/components/error-modal';
import { DeleteStatementsEvents } from '../../state/delete-statements-states';
import * as rootStyles from '../../app.less';
import * as styles from './send-statements-page.less';
import * as sharedStyles from '../../components/shared-styles/styles.less';

export interface ISendStatementsPageProps {
	machineContext?: MachineContext;
	vm: SendStatementsPageViewModel;
	hideStatementNameAndStatus?: boolean;
}

const emailSelection = { Label: 'Email members', Value: 'email' };
const downloadSelection = { Label: 'Download statements', Value: 'download' };
const noActionSelection = { Label: 'No action required', Value: 'none' };

const analyticsContext: AnalyticsContext = {
	feature: 'givingStatements',
	subFeature: 'publishStatements',
};

@inject(machineContextKey)
@observer
export class SendStatementsPage extends React.Component<ISendStatementsPageProps> {
	render() {
		const panelTitle = this.props.machineContext.matchesState(SendPageStates.SUMMARY_MODE)
			? 'Set up delivery methods to resend statements'
			: 'Set up delivery methods';

		return (
			<Provider analytics={analyticsContext}>
				<Fragment>
					<Match state={SendPageStates.LOADING}>
						<ScrollToTop>
							<div className={sharedStyles.loadingSpinnerWrapper}>
								<LoadingBox text="Loading..." />
							</div>
						</ScrollToTop>
					</Match>
					<Match state={SendPageStates.NO_STATEMENTS}>
						<NoStatementsSection {...this.props} />
					</Match>
					<Match state={SendPageStates.SEND_MODE}>
						<StepStatusIndicator
							currentStep={ConfigurationStep.Send}
							steps={configurationSteps}
						/>
						<h1 className={rootStyles.pageTitle}>Publish your statements</h1>
						<SentSummarySection hideStatementNameAndStatus={true} {...this.props} />
					</Match>
					<Match state={SendPageStates.SUMMARY_MODE}>
						<SentSummarySection {...this.props} />
					</Match>
					<Match state={SendPageStates.LOAD_FAIL}>
						<FailedSendStatementsPage />
					</Match>
					<MatchAny states={LOADED_STATES}>
						<LoadedSendStatementsPage {...this.props} panelTitle={panelTitle} />
					</MatchAny>
				</Fragment>
			</Provider>
		);
	}
}

@observer
class SentSummarySection extends React.Component<ISendStatementsPageProps> {
	render() {
		const mainViewModel: GivingStatementsViewModel = this.props.vm.rootvm;

		const {
			statementsGenerated,
			generatedAt,
			statementsEmailed,
			statementsDownloaded,
			membersWithoutEmail,
			sentAt,
			status,
			givingUnitType,
		} = this.props.vm.summaryData;
		return (
			<Fragment>
				<SentSummaryRunDetails
					statementName={mainViewModel.currentConfiguration.statementName}
					generateAt={generatedAt}
					statementsGenerated={statementsGenerated}
					className={styles.spacing}
					onRegenerateStatements={this.handleRegenerateStatements}
					status={status}
					hideStatementNameAndStatus={this.props.hideStatementNameAndStatus}
					hasBeenPublished={mainViewModel.currentConfiguration.hasBeenPublished}
					onDeleteStatements={this.deleteStatements}
				/>
				<Fragment>
					<SentSummary
						statementsEmailed={statementsEmailed}
						statementsDownloaded={statementsDownloaded}
						membersWithoutEmail={membersWithoutEmail}
						sentAt={sentAt}
						className={styles.spacing}
						status={status}
						givingUnitType={givingUnitType}
					/>
					<div className={classNames(styles.separator, styles.spacing)} />
				</Fragment>
			</Fragment>
		);
	}

	handleRegenerateStatements = () => {
		this.props.machineContext.transition(MainEvents.LOAD_CONFIGURE_VIEW, true);
	}

	private deleteStatements = () => {
		this.props.machineContext.transition(DeleteStatementsEvents.DELETE_START);
	}
}

const FailedSendStatementsPage: React.StatelessComponent = observer(() => {
	//TODO: How to fail?
	return (
		<Fragment>
			Oops, Something went wrong
		</Fragment>
	);
});

export interface ILoadedSendStatementsPageProps {
	machineContext?: MachineContext;
	vm: SendStatementsPageViewModel;
	panelTitle: string;
}

@observer
export class LoadedSendStatementsPageCore extends React.Component<WithAnalytics & ILoadedSendStatementsPageProps> {

	@observable primarySelection: IContactMethodSelectorProps = {
		title: this.props.vm.numberWithoutEmailAddresses > 0 ? 'Primary delivery method' : 'Delivery method',
		message: this.props.vm.numberWithoutEmailAddresses > 0 ? 'If you wish, you can select a secondary method in the next step.' : null,
		options: [
			emailSelection,
			downloadSelection,
		],
		changeHandler: this.primaryContactMethodSelectionChanged,
		selectedValue: '',
	};

	@observable noEmailSelection: IContactMethodSelectorProps = {
		title: 'Secondary delivery method',
		message:
			<span>
				<strong key="NoEmailSelectorStyle">
					{this.props.vm.numberWithoutEmailAddresses}
				</strong>{this.getNoEmailSelectorMessage(this.props.vm.numberWithoutEmailAddresses)}
			</span>,
		options: [
			downloadSelection,
			noActionSelection,
		],
		changeHandler: this.noEmailContactMethodSelectionChanged,
		selectedValue: '',
	};

	@observable activeSecondarySelections: IContactMethodSelectorProps[] = [];

	emailEditorProps: IEditEmailTemplateProps;

	getNoEmailSelectorMessage(emailAddressCount) {
		return emailAddressCount > 1 ?
			'of your members do not have email addresses. Please select your alternate method for contacting these members.' :
			'of your members does not have an email address. Please select your alternate method for contacting this member.';
	}

	render() {
		const {
			numberOfStatements,
			numberWithoutEmailAddresses,
			downloadNoEmailData,
			downloadAllData,
			showEmailTab,
			showDownloadTab,
			showTabsPanel,
			selectedDeliveryTab,
			updateSelectedDeliveryTab,
			emailData,
			statementName,
			supportsNoEmailDownload,
			hasPublishedBefore,
			status,
			paymentLabel
		} = this.props.vm;

		const propsForSetupContactMethods = {
			title: this.props.panelTitle,
			numberOfStatements: numberOfStatements,
			primaryContactMethodProps: this.primarySelection,
			additionalContactMethodProps: this.activeSecondarySelections,
		};

		const downloadTabProps = {
			numberOfStatements: numberOfStatements,
			numberWithNoEmail: numberWithoutEmailAddresses,
			supportsNoEmailDownload: supportsNoEmailDownload,
			onClickDownloadNoEmail: this.onClickDownloadNoEmail,
			onClickDownloadAll: this.onClickDownloadAll,
			onClickPublish: this.onClickPublish,
			status: this.props.vm.summaryData.status,
			paymentLabel: paymentLabel,
			givingUnitType: this.props.vm.summaryData.givingUnitType
		};

		this.emailEditorProps = {
			...emailData,
			onReplyToChange: this.onEmailReplyToChanged,
			onSubjectChange: this.onEmailSubjectChanged,
			onHeadingChange: this.onEmailHeadingChanged,
			onMessageChange: this.onEmailMessageChanged,
			onVideoUrlChange: this.onEmailVideoUrlChanged,
			onSendClick: this.onClickSendEmail,
			onSaveAsDraftClick: this.onSaveAsDraft,
			onSendPreviewEmail: this.onSendPreviewEmail,
			onHideTotalTaxDeductibleChange: this.onHideTotalTaxDeductibleChanged,
			paymentLabel: paymentLabel,
		};

		return (
			<Fragment>
				<Panel panelType={PanelType.DEFAULT}>
					<SetUpContactMethods {...propsForSetupContactMethods} />
					{showTabsPanel && (showDownloadTab || showEmailTab) && <Tabs selectedIndex={selectedDeliveryTab} onChange={updateSelectedDeliveryTab}>
						{showEmailTab && <Tab label="Prepare your email">
							<EmailEditorSelfAccess status={status} hasPublishedBefore={hasPublishedBefore} {...this.emailEditorProps} />
						</Tab>}
						{showDownloadTab && <Tab label="Download statements" >
							<DownloadTabContentSelfAccess {...downloadTabProps} />
						</Tab>}
					</Tabs>}
				</Panel>
				<BackToGivingStatementsLink {...this.props} />
				<DownloadStatementsModal zips={downloadAllData} showWhenInState={SendPageStates.DOWNLOAD_ALL_MODAL_ACTIVE} />
				<DownloadStatementsModal zips={downloadNoEmailData} showWhenInState={SendPageStates.DOWNLOAD_NO_EMAIL_MODAL_ACTIVE} />
				<SendEmailConfirmationModal communityMemberCount={this.emailEditorProps.emailCountToSend} statementTitle={statementName} canDownload={showDownloadTab} />
				<ErrorModal showWhenInState={ErrorModalStates.VISIBLE}></ErrorModal>
			</Fragment>
		);
	}

	onClickDownloadNoEmail = () => {
		if (NewFeatures.SetupPinpointAnalytics && this.props.analytics) {
			const {feature, subFeature } = this.props.analytics;
			recordEvent({ feature, subFeature, eventTypeLabel: 'downloadStatements' });
		}
		this.props.machineContext.transition(SendPageEvents.SHOW_DOWNLOAD_NO_EMAIL_MODAL);
	}

	onClickDownloadAll = () => {
		if (NewFeatures.SetupPinpointAnalytics && this.props.analytics) {
			const {feature, subFeature } = this.props.analytics;
			recordEvent({ feature, subFeature, eventTypeLabel: 'downloadStatements' });
		}

		this.props.machineContext.transition(SendPageEvents.SHOW_DOWNLOAD_ALL_MODAL);
		this.props.machineContext.transition(SendPageEvents.START_LOADING_SUMMARY_DATA);
		this.props.machineContext.transition(SendPageEvents.SWITCH_TO_SUMMARY_MODE);
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}

	onClickPublish = () => {
		this.props.machineContext.transition(SendPageEvents.PUBLISH_STATEMENTS);
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}

	onClickSendEmail = () => {
		this.props.machineContext.transition(SendPageEvents.SHOW_EMAIL_MODAL);
	}

	onSaveAsDraft = () => {
		this.props.machineContext.transition(SendPageEvents.SAVE_AS_DRAFT);
	}

	onSendPreviewEmail = () => {
		this.props.machineContext.transition(SendPageEvents.SEND_PREVIEW_EMAIL);
	}

	@action.bound
	primaryContactMethodSelectionChanged(selectedValue: string) {
		if (NewFeatures.SetupPinpointAnalytics && this.props.analytics) {
			const { feature, subFeature } = this.props.analytics;
			recordEvent({ feature, subFeature, eventTypeLabel: 'selectDeliveryMethodPrimary' });
		}

		this.primarySelection.selectedValue = selectedValue;
		this.evaluateSelections();
	}

	@action.bound
	noEmailContactMethodSelectionChanged(selectedValue: string) {
		if (NewFeatures.SetupPinpointAnalytics && this.props.analytics) {
			const { feature, subFeature } = this.props.analytics;
			recordEvent({ feature, subFeature, eventTypeLabel: 'selectDeliveryMethodSecondary' });
		}

		this.noEmailSelection.selectedValue = selectedValue;
		this.evaluateSelections();
	}

	@action.bound
	onEmailReplyToChanged(value: string) {
		this.props.vm.emailData.updateReplyTo(value);
	}

	@action.bound
	onEmailSubjectChanged(value: string) {
		this.props.vm.emailData.updateSubject(value);
	}

	@action.bound
	onEmailHeadingChanged(value: string) {
		this.props.vm.emailData.updateHeading(value);
	}

	@action.bound
	onEmailVideoUrlChanged(value: string) {
		this.props.vm.emailData.updateVideoUrl(value);
	}

	@action.bound
	onEmailMessageChanged(value: string) {
		this.props.vm.emailData.updateMessage(value);
	}

	@action.bound
	onHideTotalTaxDeductibleChanged(value: boolean) {
		this.props.vm.emailData.updateHideTotalTaxDeductible(value);
	}

	evaluateSelections() {
		let shouldShowTabsPanel = false;
		let shouldShowDownloadTab = false;
		let shouldShowEmailTab = false;
		let secondarySelectionsToShow: IContactMethodSelectorProps[] = [];

		switch (this.primarySelection.selectedValue) {
			case emailSelection.Value:
				shouldShowEmailTab = true;
				if (this.props.vm.numberWithoutEmailAddresses > 0) {
					secondarySelectionsToShow = [this.noEmailSelection];
				} else {
					shouldShowTabsPanel = true;
				}
				break;
			case downloadSelection.Value:
				shouldShowDownloadTab = true;
				shouldShowTabsPanel = true;
				break;
		}

		if (secondarySelectionsToShow.length === 1) {
			switch (this.noEmailSelection.selectedValue) {
				case downloadSelection.Value:
					shouldShowDownloadTab = true;
				case noActionSelection.Value:
					// Switch fallthrough intentional
					shouldShowTabsPanel = true;
			}
		}

		this.props.vm.updateSelectedDeliveryTab(0);
		this.props.vm.updateShowTabsPanel(shouldShowTabsPanel);
		this.props.vm.updateShowDownloadTab(shouldShowDownloadTab);
		this.props.vm.updateShowEmailTab(shouldShowEmailTab);
		this.activeSecondarySelections = secondarySelectionsToShow;
	}
}

export class BackToGivingStatementsLink extends React.Component<ISendStatementsPageProps> {
	render() {
		return (
			<div className={styles.backToGivingStatements}>
				<SvgWrapper svg="icon-chevron-left" />
				<a href="javascript:void(0)" onClick={this.onClickBackToGivingStatements}> Back to Giving Statements</a>
			</div>
		);
	}

	private onClickBackToGivingStatements = () => {
		this.props.machineContext.transition(MainEvents.LOAD_LANDING_VIEW);
	}
}

export class NoStatementsSection extends React.Component<ISendStatementsPageProps> {
	render() {
		const {
			emailData: {
				startDate,
				endDate,
			},
			summaryData: {
				status,
			},
			rootvm: {
				currentConfiguration: {
					hasBeenPublished
				}
			},
		} = this.props.vm;

		return (
			<Fragment>
				<NoStatementsPanel
					from={startDate}
					to={endDate}
					status={status}
					onRegenerateStatements={this.handleRegenerateStatements}
					hasBeenPublished={hasBeenPublished}
					onDeleteStatements={this.deleteStatements} />
				<BackToGivingStatementsLink {...this.props} />
			</Fragment>
		);
	}

	handleRegenerateStatements = () => {
		this.props.machineContext.transition(MainEvents.LOAD_CONFIGURE_VIEW, true);
	}

	private deleteStatements = () => {
		this.props.machineContext.transition(DeleteStatementsEvents.DELETE_START);
	}
}

export const LoadedSendStatementsPage = injectAnalytics(LoadedSendStatementsPageCore);
