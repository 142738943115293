import CommandBase from './command-base';
import { ICancellablePromise } from '../../../utils/cancellable-promise';
import * as Models from '../../models/index';
import Member from '../member';
import Main from '../main';

export default class NextPageCommand extends CommandBase<Models.CommunityMemberSearchResult> {

	static create(main: Main) {
		let skip = main.currentMembers.filter(x => !x.isBeyondCurrentPage).length;
		return new NextPageCommand(
			main,
			main.searchTerms,
			main.sorter.currentSorter.serverFieldName,
			main.sorter.isDescending,
			main.excludeResultsWithEmail,
			main.excludeResultsWithAddress,
			skip
		);
	}

	constructor(
		public main: Main,
		public searchTerms: string,
		public sort: string,
		public sortDescending: boolean,
		public excludeResultsWithEmail: boolean,
		public excludeResultsWithAddress: boolean,
		public skip: number) {

		super();
		this.trackers = [main.busyTracker];
	}


	preApply(): void {
	}

	send(): ICancellablePromise<Models.CommunityMemberSearchResult> {
		return this.main.dataService.searchMembers({
			OrderBy: this.sort,
			OrderDirection: this.sortDescending ? 'Desc' : 'Asc',
			Query: this.searchTerms,
			Skip: this.skip,
			ExcludeResultsWithEmail: this.excludeResultsWithEmail,
			ExcludeResultsWithAddress: this.excludeResultsWithAddress
		});
	}

	postApply(result: Models.CommunityMemberSearchResult) {
		this.main.insertMembers(...result.SearchResults.map(mp => new Member(this.main).applyModel(mp)));

		this.main.morePagesAvailable = result.HasMorePages;
	}


	undoPreApply(error): void {
		//can't be undone
	}

}
