import * as React from 'react';
import {observer} from 'mobx-react';
import {KeyCodes} from '../../../Shared/helpers/keycodes';
import {SvgWrapper} from '../svg-wrapper';
import {responsiveHelper} from '../../helpers/responsive-helper';
import {InputField} from '../../funds/components/form-controls';
import { MultiSelectItem } from '../../loggedinweb-generated';
import { MultiSelectStore } from './multi-select-store';
import { BrowserSupportHelper } from '../../helpers/browsersupporthelper';

import * as styles from './multi-select.less';

export interface IMultiSelectProps {
	multiSelectStore: MultiSelectStore;
	id?: string;
	fieldName?: string;
	displayName?: string;
	validationRules?: any;
	acceptanceTestTargetId?: string;
}

@observer
export class MultiSelect extends React.Component<IMultiSelectProps> {
	private store = this.props.multiSelectStore;
	private container: HTMLElement;
	private input: HTMLInputElement;
	private itemsContainer: HTMLElement;
	private currentItem: HTMLElement;

	render() {
		const { id, acceptanceTestTargetId } = this.props;
		const { isActive, displayItems, dropListActive, hasSelectedItems, searchTerm } = this.store;
		const containerClass = `${styles.container} ${isActive ? `${styles.open} open` : ''} select-wrapper`;
		const itemsContainerClass = `${styles.displayItems} dropdown-menu ${displayItems.length > 0 && dropListActive ? '' : 'hidden'}`;

		return (
			<div
				className={containerClass}
				ref={this.saveContainer}
				onKeyDown={this.handleKeyPress}
				onFocus={this.handleComponentFocused}
			>
				<div className={`form-control ${styles.selectedItemContainer}`} onMouseDown={this.handleComponentClicked}>
					<input
						id={id}
						type="text"
						className={`${styles.multiSelectInput} ${hasSelectedItems ? styles.selected : ''}`}
						ref={this.saveInput}
						onChange={this.handleSearch}
						placeholder={this.renderSelectedItemsText()}
						value={searchTerm}
						data-pp-at-target={acceptanceTestTargetId}
					/>
				</div>
				{this.renderHiddenInputs()}
				<ul className={itemsContainerClass} ref={this.saveItemsContainer}>
					<li className={`visible-xs ${styles.dropdownHeader}`}>
						Select your chosen options below:
						<div className={styles.closeButton} onClick={this.handleComponentClicked}>
							<SvgWrapper svg="close" className={styles.svg} />
						</div>
					</li>
					{displayItems.map(item => this.renderItem(item))}
				</ul>
			</div>
		);
	}

	saveContainer = (container: HTMLDivElement) => this.container = container;

	saveInput = (input: HTMLInputElement) => this.input = input;

	saveItemsContainer = (itemsContainer: HTMLUListElement) => this.itemsContainer = itemsContainer;

	renderHiddenInputs(): JSX.Element | JSX.Element[] {
		const { fieldName: propertyName, validationRules } = this.props;
		const { selectedValues } = this.store;

		if (!propertyName) {
			return null;
		}

		if (selectedValues && selectedValues.length > 0) {
			return selectedValues.map(selectedValue => (
				<InputField
					key={selectedValue}
					data-validate
					type="hidden"
					propertyMetadata={{ propertyName, validationRules }}
					value={selectedValue}
				/>
			));
		}

		return null;
	}

	renderItem(item: MultiSelectItem) {
		const isCurrentItem = item === this.store.currentItem;
		const isCurrentItemClass = isCurrentItem ? styles.current : '';
		const isItemSelected = this.props.multiSelectStore.isItemSelected(item);
		const isSelectedItemClass = isItemSelected ? styles.selected : '';
		return (
			<li className={`${isCurrentItemClass} ${isSelectedItemClass}`} key={item.Value}
				ref={ref => ref && isCurrentItem ? this.currentItem = ref : null}>
				<button
					className={`btn btn-link ${styles.btnLink}`}
					onClick={() => this.store.toggleItem(item)}
					onMouseOver={() => this.store.setCurrentItem(item)}
					type="button"
					tabIndex={-1}
					disabled={item.Disabled}
				>
					<div className={`${styles.tickbox} ${ item.Disabled ? styles.tickboxDisabled : ''}`}>
						{isItemSelected && <SvgWrapper svg="checkbox-ticked" className={styles.svg} />}
					</div>
					{this.store.searchTerm ? this.renderSelectedText(item) : item.Text}
				</button>
			</li>
		);
	}

	renderSelectedText(item: MultiSelectItem) {
		const matches = this.store.searchExpression.exec(item.Text);
		return (
			<span>
				<strong>{matches[1]}</strong>{matches[2]}
			</span >
		);
	}

	renderSelectedItemsText() {
		const { selectedValues } = this.store;

		if (!selectedValues || selectedValues.length === 0 ) {
			return `All (${this.store.items.length})`;
		} else if (selectedValues.length === 1) {
			const [selectedItem] = this.store.items.filter(x => x.Value === selectedValues[0]);
			return selectedItem.SelectedText || selectedItem.Text;
		} else {
			return `Selected (${selectedValues.length})`;
		}
	}

	handleKeyPress = (ev: React.KeyboardEvent<any>) => {
		if (responsiveHelper.isXs) {
			return;
		}
		switch (ev.keyCode) {
			case KeyCodes.Tab:
			case KeyCodes.Escape: {
				this.resetScroll();
				this.input.blur();
				this.store.deactivate();
				return;
			}
			case KeyCodes.Enter: {
				ev.preventDefault();
				this.store.toggleCurrentItem();
				this.input.blur();
				this.store.deactivate();
				return;
			}
			case KeyCodes.Space: {
				ev.preventDefault();
				this.store.toggleCurrentItem();
				return;
			}
			case KeyCodes.DownArrow: {
				ev.preventDefault();
				this.store.moveCurrentItemDown();
				this.scrollDown();
				break;
			}
			case KeyCodes.UpArrow: {
				ev.preventDefault();
				this.store.moveCurrentItemUp();
				this.scrollUp();
				break;
			}
			case KeyCodes.Delete:
			case KeyCodes.Backspace: {
				this.store.deselectLastItem();
				break;
			}
		}
		this.store.showDropList(true);
	}

	resetScroll = () => {
		this.itemsContainer.scrollTop = 0;
	}

	scrollDown = () => {
		if (this.currentItem && this.currentItem.offsetTop + this.currentItem.offsetHeight >= this.itemsContainer.clientHeight) {
			this.itemsContainer.scrollTop += this.currentItem.offsetHeight;
		}
	}

	scrollUp = () => {
		if (this.currentItem && this.currentItem.offsetTop <= this.itemsContainer.scrollTop) {
			this.itemsContainer.scrollTop -= this.currentItem.offsetHeight;
		}
	}

	handleSearch = (ev: React.FormEvent<HTMLInputElement>) => {
		this.store.search(ev.currentTarget.value);
	}

	handleComponentClicked = (ev: React.MouseEvent<any>) => {
		const { isXs } = responsiveHelper;

		if (!this.store.isActive) {
			ev.preventDefault();
			this.store.activate();

			if (!isXs) {
				this.input.focus();
			}
		} else {
			this.store.deactivate();
		}
	}

	handleComponentFocused = () => {
		if (responsiveHelper.isXs) {
			return;
		}
		this.store.activate();
		this.input.focus();
	}

	handleWindowClick = (ev: Event) => {
		if (!this.container) {
			return;
		}
		const targetElement = ev.target as Element;
		if (targetElement === this.container ||
			!document.body.contains(targetElement) ||
			this.container.contains(targetElement)) {
			return;
		}
		this.resetScroll();
		this.input.blur();
		this.store.deactivate();
	}

	componentDidMount() {
		window.addEventListener('click', this.handleWindowClick);
	}

	componentWillUnmount() {
		window.removeEventListener('click', this.handleWindowClick);
	}

	componentDidUpdate() {
		const { multiSelectStore: { isActive } } = this.props;
		const fixBody = responsiveHelper.isXs && BrowserSupportHelper.isIOS() && isActive;

		if (fixBody) {
			const activeElement = document.activeElement as any;
			if (typeof activeElement.blur === 'function') {
				activeElement.blur();
			}
			this.input.blur();
			document.body.style.position = 'fixed';
		} else if(document.body.style.position === 'fixed') {
			document.body.style.position = '';
		}
	}
}
