import * as React from 'react';
import { MemberFormField } from './member-form-field';
import { ModelMetadata } from '../community-member-generated';

interface IAddressFieldProps {
	metadata: ModelMetadata.IPropertyMetadata;
	cssClassNames?: string;
	label?: string;
	acceptanceTestTargetId?: string;
}

export const AddressField: React.StatelessComponent<IAddressFieldProps> = (props) => {
	const { metadata, cssClassNames, label, acceptanceTestTargetId } = props;

	return (
		<MemberFormField
			metadata={metadata}
			cssClassNames={`${cssClassNames || `col-xs-12`}`}
			childModel={ModelMetadata.EditCommunityMemberRequestModel.Address.propertyName}
			label={label}
			acceptanceTestTargetId={acceptanceTestTargetId}
		/>
	);
};
