import { delay } from 'redux-saga';
import { MainEvents } from '../state/main';
import { MachineContext } from '../../../Shared/state-machine/saga-state-machine';
import { GivingStatementsViewModel } from '../giving-statements-view-model';
import { givingStatementsDataService } from '../giving-statements-data-service';
import { GivingStatementRunStatus, BatchCompletionResponse, ConfigureRunViewModel } from '../giving-statements-generated';
import { PreviewPageEvents } from '../state/preview-page';
import { PreviewPageViewModel } from '../pages/preview/preview-page-view-model';
import { urlService } from '../services/url';
import { DeleteStatementsStates } from '../state/delete-statements-states';

export const SCROLL_TO_TOP_CONFIG: ScrollToOptions = {
	top: 0,
	left: 0,
	behavior: 'smooth',
};

export const PreviewPageActionMap = {
	LoadPreviewView: function* (context: MachineContext) {
		const viewModel = context.viewModel as GivingStatementsViewModel;
		const { currentConfiguration } = viewModel;

		// If the current config status is `Generating`, go straight to polling.
		if (currentConfiguration.status === GivingStatementRunStatus.Generating) {
			context.transition(PreviewPageEvents.POLL_GENERATION_PROGRESS);
		}
	},
	BeginGenerationProgressPoll: function* (context: MachineContext) {
		try {
			const viewModel = context.viewModel as GivingStatementsViewModel;
			const previewPageViewModel = viewModel.previewPageViewModel as PreviewPageViewModel;

			const { currentConfigurationId: id, currentConfiguration } = viewModel;
			yield givingStatementsDataService.beginGenerating({ id });

			// Once we have started generating, we need to bring down the configuration to update the current configuration's
			// status.
			const { Status }: ConfigureRunViewModel = yield givingStatementsDataService.configure({ id });
			currentConfiguration.setStatus(Status);

			context.transition(PreviewPageEvents.POLL_GENERATION_PROGRESS);

			// Scroll the statement iframe and page to the top, so the progress bar is visible.
			window.scrollTo(SCROLL_TO_TOP_CONFIG);

			const { iframeRef } = previewPageViewModel;
			if (iframeRef && iframeRef.contentWindow) {
				iframeRef.contentWindow.scrollTo(SCROLL_TO_TOP_CONFIG);
			}
		} catch (error) {
			// @TODO Handle this error.
			console.error(error);
		}
	},
	PollGenerationProgress: function* (context: MachineContext) {
		try {
			const viewModel = context.viewModel as GivingStatementsViewModel;
			const previewPageViewModel = viewModel.previewPageViewModel as PreviewPageViewModel;
			const { currentConfigurationId: id } = viewModel;

			while (true) {
				if (!context.matchesAnyState([DeleteStatementsStates.DELETING, DeleteStatementsStates.SUCCEEDED])) {
					const generationStatus: BatchCompletionResponse = yield givingStatementsDataService.generationStatus({ id });

					previewPageViewModel.setPercentComplete(generationStatus.PercentComplete);

					if (generationStatus.Finished) {
						// This is the point the user moves onto the next page, so pushing onto the history stack so when the user
						// hits the browser back button, we can transition to the previous state.
						urlService.pushPreviousViewState();
						context.transition(MainEvents.LOAD_SEND_VIEW);
						return;
					}
				}

				yield delay(1500);
			}
		} catch (error) {
			// @TODO Handle this error.
			console.error(error);
		}
	}
};
