import {
	IntegrationStatusLabel,
	ExternalSystemStatus,
	QuickBooksConfigurationViewModel,
	QuickBooksMappingViewModel,
	PausedContext,
} from '../../qbo-integration-generated';
import { observable, computed, action } from 'mobx';
import { QboMappingClientSideViewModel } from '../../components/mapping-form/qbo-mapping-client-side-view-model';
import { FlashMessageBoxProps } from '../../components/flash-message-box';

export class QboConfigurationMainViewModel {

	integrationsLandingPageUri: string;

	helpPageUri: string;

	shouldPromptToEnable: boolean;

	instanceName: string;

	@observable
	labels: IntegrationStatusLabel[];

	@observable
	isProcessingRequest: boolean;

	@observable
	systemStatus: ExternalSystemStatus;

	@observable
	isSetupComplete: boolean;

	@observable
	isNewConnection: boolean;

	@observable
	isAuthenticated: boolean;

	@observable
	mappingInfo?: QboMappingClientSideViewModel;

	@observable
	isMappingInfoReady: boolean;

	@observable
	serverMessage: FlashMessageBoxProps | null;

	@observable
	initialServerMessage: FlashMessageBoxProps | null;

	@observable
	pausedContext?: PausedContext | null;

	constructor(model: QuickBooksConfigurationViewModel) {
		const { ViewModel: { Content, SystemStatus, IsSetupIncomplete, IsNewConnection, IsAuthenticated} } = model;
		this.labels = Content.PrimaryLabels;
		this.systemStatus = SystemStatus;
		this.isSetupComplete = IsSetupIncomplete;
		this.isNewConnection = IsNewConnection;
		this.isAuthenticated = IsAuthenticated;
		this.integrationsLandingPageUri = model.IntegrationsLandingPageUri;
		this.helpPageUri = model.ViewModel.Metadata.HelpUrl;
		this.instanceName = model.InstanceName;
		this.mappingInfo = null;
		this.isMappingInfoReady = false;
		this.shouldPromptToEnable = model.ShouldPromptToEnable;
		this.initialServerMessage = model.FlashMessage;
		this.pausedContext = null;
	}

	@computed
	get isEditing(): boolean {
		return this.isAuthenticated && (!this.isSetupComplete || !this.isNewConnection);
	}

	@action.bound
	setIsProcessingRequest(isProcessing: boolean) {
		this.isProcessingRequest = isProcessing;
		if(isProcessing) {
			this.serverMessage = null;
		}
	}

	@action.bound
	setIsMappingInfoReady(isMappingInfoReady: boolean) {
		this.isMappingInfoReady = isMappingInfoReady;
	}

	@action.bound
	setMappingInfo(mappingInfo: QuickBooksMappingViewModel | null) {
		if(mappingInfo === null) {
			this.isMappingInfoReady = false;
			this.mappingInfo = null;
		} else {
			this.mappingInfo = new QboMappingClientSideViewModel(mappingInfo);
			this.isMappingInfoReady = true;
		}
	}

	@action.bound
	setServerMessage(message: FlashMessageBoxProps | null) {
		this.serverMessage = message;
		if (message !== null) {
			this.initialServerMessage = null;
		}
	}

	@action.bound
	setPausedContext(pausedContext: PausedContext | null) {
		this.pausedContext = pausedContext;
	}
}
