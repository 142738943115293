import moment from 'moment';
import { Formatter } from '../../helpers/formatter';

export const dateService = {
	getStatementDateRange (from: Date, to: Date): string {
		const startDate = moment(from).year() === moment(to).year()
			? Formatter.formatDate(from, 'MMM D')
			: Formatter.formatDate(from, 'MMM D, YYYY');

		const endDate = Formatter.formatDate(to, 'MMM D, YYYY');

		return `${startDate} - ${endDate}`;
	}
};
