import * as React from 'react';
import { observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

export interface IFloatingLabelComponentProps {
	store: FloatingLabelComponentStore;
	placeholder?: string;
	label: string;
	onElementShouldGainFocus?: () => void;
}

export class FloatingLabelComponentStore {
	@observable elementHasFocus = false;
	@observable elementHasValue = false;
	@observable showLabelOnFocus = false;

	@computed
	get showAsLabel() {
		return this.elementHasValue || (this.showLabelOnFocus && this.elementHasFocus);
	}
}

@observer
export class FloatingLabelComponent extends React.Component<IFloatingLabelComponentProps, {}> {
	private span: HTMLElement;

	@observable
	private preventShowingPlaceholder = false;

	@computed
	private get showAsPlaceholder() {
		return !this.props.store.showAsLabel && !this.preventShowingPlaceholder;
	}

	render() {
		return (
			<label
				className={`floating-label${this.showAsPlaceholder ? ' as-placeholder' : ''}`}
				onClick={this.click}>
				<span
					onMouseDown={this.mouseDown}
					onMouseOut={this.mouseUp}
					onMouseUp={this.mouseUp}
					ref={(ref) => this.span = ref}>
					{this.props.label}
				</span>
			</label>
		);
	}

	@action
	private mouseDown = () => {
		this.preventShowingPlaceholder = true;
	}

	@action
	private mouseUp = () => {
		this.preventShowingPlaceholder = false;
	}

	private click = () => {
		if (!this.preventShowingPlaceholder) {
			if (this.props.onElementShouldGainFocus) {
				this.props.onElementShouldGainFocus();
			}
		}
	}
}
