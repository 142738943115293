import * as React from 'react';
import { action, observable, computed } from 'mobx';
import { observer } from 'mobx-react';

export enum AffixPosition {
	Top,
	Bottom
}

export class AffixUiStore {
	affixPosition: AffixPosition;
	fixedTo: HTMLElement | Window;

	@observable
	scrollTop: number;

	@observable
	affixHeight = 0;

	@observable
	offsetTop = 0;

	@observable
	viewportHeight = 0;

	constructor(affixPosition: AffixPosition, fixedTo: HTMLElement | Window) {
		this.affixPosition = affixPosition;
		this.fixedTo = fixedTo;
	}

	@computed
	get isDetached() {
		const affixPoint = this.affixPosition === AffixPosition.Top
			? this.offsetTop
			: this.offsetTop + this.affixHeight;

		return this.affixPosition === AffixPosition.Top
			? affixPoint < this.scrollTop
			: this.scrollTop + this.viewportHeight < affixPoint;
	}

	@action
	updateScrollTop = () => {
		this.scrollTop = this.fixedTo instanceof HTMLElement
			? (this.fixedTo as HTMLElement).scrollTop
			: (this.fixedTo as Window).pageYOffset;
	}

	@action
	update(data: { height: number, offsetTop: number, viewportHeight: number}) {
		this.affixHeight = data.height;
		this.offsetTop = data.offsetTop;
		this.viewportHeight = data.viewportHeight;
	}

	initAffix = () => {
		this.updateScrollTop();
		this.fixedTo.addEventListener('scroll', this.updateScrollTop);
	}

	disposeAffix = () => {
		this.fixedTo.removeEventListener('scroll', this.updateScrollTop);
	}
}

@observer
export class Affix extends React.Component<{ affixPosition: AffixPosition, fixedTo?: HTMLElement }, {}> {
	private uiStore: AffixUiStore;
	private positionSensor: HTMLElement;
	private windowHeightSensor: HTMLElement;
	private resizeSensor: HTMLElement;
	private handler: number;

	constructor(props) {
		super(props);
		this.uiStore = new AffixUiStore(this.props.affixPosition, this.props.fixedTo || window);
	}

	render() {
		// tslint:disable-next-line:max-line-length
		const affixClass = `clearfix ${this.uiStore.isDetached ? 'affix' : ''} ${this.uiStore.affixPosition === AffixPosition.Top ? 'affix-top' : 'affix-bottom'}`;
		return (
			<div className="affix-wrapper" style={{ height: this.uiStore.affixHeight || null }}>
				<div className="position-sensor" ref={(ref) => this.positionSensor = ref}></div>
				<div className="window-height-sensor" ref={(ref) => this.windowHeightSensor = ref}></div>
				<div className={affixClass}>
					<div className="resize-sensor" ref={(ref) => this.resizeSensor = ref}></div>
					{this.props.children}
				</div>
			</div>
		);
	}

	startTestPositionLoop = () => {
		const loop = () => {
			this.uiStore.update({
				height: this.resizeSensor.offsetHeight,
				offsetTop: this.positionSensor.offsetTop,
				viewportHeight: this.windowHeightSensor.offsetTop
			});
		};

		loop();

		this.handler = window.setInterval(loop, 50);
	}

	stopTestPositionLoop = () => {
		if (this.handler) {
			clearInterval(this.handler);
		}
	}

	componentDidMount() {
		this.startTestPositionLoop();
		this.uiStore.initAffix();
	}

	componentWillUnmount() {
		this.stopTestPositionLoop();
		this.uiStore.disposeAffix();
	}
}
