import * as React from 'react';
import { SvgWrapper } from '../svg-wrapper';
import { classNames } from '../../../Shared/utils/classnames';

import { loading } from './button-refresh.less';

export const ButtonRefresh = (props: {
	cssClassNames: string,
	onRefreshBatches: () => void,
	title?: string,
	text?: string | JSX.Element,
}) => {
	const { title, onRefreshBatches, cssClassNames, text } = props;

	return (
		<button type="button" className={cssClassNames} onClick={onRefreshBatches}>
			<SvgWrapper svg="icon-refresh" className="icon" title={title || 'Refresh batches'} />
			{ text || <span>Refresh<span className="hidden-xs"> list</span></span> }
		</button>
	);
};

export const ButtonRefreshLoading = (props: {
	cssClassNames: string,
	title?: string,
	text?: string | JSX.Element,
}) => {
	const { title, cssClassNames, text } = props;

	return (
		<button type="button" className={classNames(cssClassNames, loading)} disabled>
			<SvgWrapper svg="icon-refresh" className="icon icon-rotate" title={title || 'Loading batches'} />
			{ text || <span>Loading<span className="hidden-xs">...</span></span> }
		</button>
	);
};
