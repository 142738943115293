import {
	EventTimeCreateRequest,
	CreateBatchRequestModel,
	EditBatchRequestModel,
	CloseBatchRequestModel,
	ReopenBatchRequestModel,
} from '../../virtual-terminal-generated';
import { IVirtualTerminalPayerViewModel } from '../../components/payer-search/payer-view-model';
import { PaymentMethod } from '../../components/payment-entry/payment-entry-model';
import { ActionCreator } from '../../../utils/user-action-creator';

export namespace BatchEntryUserAction {
	//Batch details user actions
	export class LaunchAddServiceTimeDialog { }

	export class AddServiceTime {
		constructor(public eventTimeCreateRequest: EventTimeCreateRequest) { }
	}

	export class CreateBatch {
		constructor(public merchantId: number, public createBatchRequestModel: CreateBatchRequestModel) { }
	}

	export class UpdateBatch {
		constructor(public merchantId: number, public editBatchRequestModel: EditBatchRequestModel) { }
	}

	export class CloseBatch {
		constructor(public merchantId: number, public closeBatchRequestModel: CloseBatchRequestModel) { }
	}

	export class ReopenBatch {
		constructor(public merchantId: number, public reopenBatchRequestModel: ReopenBatchRequestModel) { }
	}

	export class DeleteBatch {
		constructor(public merchantId: number, public batchId: number) { }
	}

	export class ViewHistory {
		constructor(public merchantId: number, public batchId: number) { }
	}

	export class StartEditBatch { }

	export class CancelCreateBatch { }

	export class CancelEditBatch { }

	export class PrintDepositSlip { }

	export class ExportBatch { }

	// Payment form user actions
	export class ResetForm { }

	export class SubmitForm { }

	export class OmniboxValueChange {
		constructor(public value: string) { }
	}

	export class SelectedPayerChange {
		constructor(public payer: IVirtualTerminalPayerViewModel) { }
	}

	export class LoadMorePayers { }

	export class SelectAnonymousPayer { }

	export class ViewAllExistingPaymentMethods { }

	export class RemovePaymentMethod {
		constructor(public paymentMethod: PaymentMethod) { }
	}

	export class LoadBatchPayments { }

	export class LoadBatchPayment {
		constructor(public encodedToken: string) { }
	}

	export class LaunchDeleteBatchPaymentDialog {
		constructor(public encodedToken: string) { }
	}

	export class DeleteBatchPayment {
		constructor(public encodedToken: string) { }
	}
}

export type BatchEntryUserAction = typeof BatchEntryUserAction[keyof typeof BatchEntryUserAction]['prototype'];
export type BatchEntryUserActionCreator = ActionCreator<typeof BatchEntryUserAction>;
