

// ReSharper disable InconsistentNaming
// ReSharper disable StringLiteralWrongQuotes
/* tslint:disable:no-use-before-declare quotemark max-line-length */
import { IApiAction } from '../utils/data-service';
export enum JournalEntryCreationHint {
	None = 0, CreateForPausedSettlements = 1, DoNotCreateForPausedSettlements = 2,
}
export interface QuickBooksIntegrationActionResult {
	RedirectTo: string;
	Message: FlashMessage;
}
export interface FlashMessage {
	Title: string;
	Message: string;
	MessageType: FlashMessageType;
}
export enum FlashMessageType {
	Success = 0, Error = 1, Info = 2,
}
export interface QuickBooksSaveMappingsViewModel {
	MerchantLocationsEnabled: boolean;
	AllMerchantsUseTheSameBankAccountForProfitStars: boolean;
	SetupProgress: MappingsSetupProgress;
	Funds: QuickBooksFundMappingViewModel[];
	Merchants: QuickBooksMerchantMappingViewModel[];
	PaymentGateways: QuickBooksPaymentGatewayMappingViewModel[];
}
export interface QuickBooksPaymentGatewayMappingViewModel {
	PaymentGatewayId: string;
	IsEnabled: boolean;
	AccountId: string;
}
export interface QuickBooksMerchantMappingViewModel {
	MerchantId: number;
	ClassId: string;
	LocationId: string;
}
export interface QuickBooksFundMappingViewModel {
	FundId: number;
	AccountId: string;
	ClassId: string;
	LocationId: string;
}
export enum MappingsSetupProgress {
	Unknown = 0, Deposits = 1, MerchantLocations = 2, Funds = 3, Complete = 4,
}
export interface QuickBooksSaveMappingsResult {
	Result: MappingsResult;
	SetupProgress: MappingsSetupProgress;
	Message: FlashMessage;
	DisplayPausedDialog: boolean;
}
export enum MappingsResult {
	Unknown = 0, Success = 1, Failure = 2,
}
export interface QuickBooksListingConfigurationModel {
}
export interface QuickBooksConfigurationViewModel {
	ViewModel: IntegrationViewModel;
	Listings: IntegrationListingConfigurationViewModel<QuickBooksListingConfigurationModel>[];
	ShouldPromptToEnable: boolean;
	EnableIntegrationUri: string;
	IntegrationsLandingPageUri: string;
	InstanceName: string;
	FlashMessage: FlashMessage;
}
export interface IntegrationListingConfigurationViewModel<T> {
	ListingId: number;
	ListingName: string;
	Model: T;
}
export interface IntegrationViewModel {
	Metadata: IIntegrationMetadata;
	IsNewConnection: boolean;
	SystemStatus: ExternalSystemStatus;
	IsAuthenticated: boolean;
	IsThirdParty: boolean;
	IsSetupIncomplete: boolean;
	Content: IntegrationContent;
	ActionUrls: ActionUrls;
	Warnings: IntegrationWarningCollection;
	FundsAreEnabled: boolean;
}
export interface IntegrationWarningCollection {
	WarningOnConfigureIntegration: IntegrationWarning;
	WarningOnImportFunds: IntegrationWarning;
}
export interface IntegrationWarning {
	Title: string;
	Message: string;
	CallsToAction: IntegrationCallToAction[];
}
export enum IntegrationCallToAction {
	ConnectIntegration = 0, ManageIntegration = 1, ImportFunds = 2, EnableIntegration = 3, ContactUs = 4,
}
export interface ActionUrls {
	EnableIntegrationUrl: string;
	DisableIntegrationUrl: string;
	DeleteIntegrationUrl: string;
	ConfigureIntegrationUrl: string;
	SetupAuthenticationUrl: string;
	CancelActionUrl: string;
	SaveFundsFileUrl: string;
	ConfigureIntegrationMappingsUrl: string;
	ImportFundsUrl: string;
	EnablePcoGivingUrl: string;
	DisablePcoGivingUrl: string;
}
export interface IntegrationContent {
	PrimaryLabels: IntegrationStatusLabel[];
	SecondaryLabels: IntegrationStatusLabel[];
	PrimaryStatusDescription: IHtmlString;
	SecondaryStatusDescription: IHtmlString;
	CallsToAction: IntegrationCallToAction[];
}
export interface IHtmlString {
}
export interface IIntegrationMetadata {
	IntegrationSystem: IIntegrationSystem;
	ProductName: string;
	ProductNameFirstUse: string;
	Logo: string;
	LegalDisclaimer: string;
	ManageUrl: string;
	HelpUrl: string;
	HelpUrlOld: string;
	InfoUrl: string;
	EnabledInCorePackage: boolean;
}
export interface IIntegrationSystem {
	SystemId: IntegrationSystemIdentifier;
	DisplayName: string;
	DisplayNameFirstUse: string;
	IsEnabled: boolean;
	HasConfiguration: boolean;
	IntegrationTypes: IntegrationTypes;
}
export interface IntegrationTypes {
	CommunityMemberIsEnabled: boolean;
	PersonIsEnabled: boolean;
	PaymentIsEnabled: boolean;
	SettlementIsEnabled: boolean;
	BatchIsEnabled: boolean;
	FundsAreEnabled: boolean;
	FundsRefreshIsEnabled: boolean;
}
export enum IntegrationSystemIdentifier {
	FellowshipOne = 0, ChurchCommunityBuilder = 1, Elvanto = 2, ThinkMinistry = 3, PlanningCenter = 4, QuickBooks = 5, ShelbyArena = 6,
}
export interface QuickBooksMappingViewModel {
	Result: MappingsResult;
	Message: FlashMessage;
	ManageFundsUrl: string;
	CollapsedCreditCardPaymentGatewayMappings: CollapsedPaymentGatewayMapping[];
	CollapsedAchPaymentGatewayMappings: CollapsedPaymentGatewayMapping[];
	FundMappings: QuickBooksFundMappingViewModel[];
	MerchantMappings: QuickBooksMerchantMappingViewModel[];
	ProfitStarsPaymentGatewayMappings: QuickBooksPaymentGatewayMappingViewModel[];
	BatchEntryPaymentGatewayMappings: QuickBooksPaymentGatewayMappingViewModel[];
	ClassesForFundsEnabled: boolean;
	LocationsForFundsEnabled: boolean;
	Accounts: QuickBooksAccount[];
	Classes: QuickBooksClass[];
	Locations: QuickBooksLocation[];
	Funds: QuickBooksFund[];
	Merchants: QuickBooksMerchant[];
	PaymentGateways: QuickBooksPaymentGateway[];
	PaymentLabel: PaymentLabel;
	MerchantLocationsEnabled: boolean;
	AllMerchantsUseTheSameBankAccountForProfitStars: boolean;
	SetupProgress: MappingsSetupProgress;
	PausedContext: PausedContext;
	DisplayPausedDialog: boolean;
}
export interface PausedContext {
	PausedOnDisplayDate: string;
	NumberOfQueuedSettlements: number;
}
export interface PaymentLabel {
	NounSentenceCase: string;
	NounPluralSentenceCase: string;
	VerbSentenceCase: string;
	VerbAppliedSentenceCase: string;
	NounLowerCase: string;
	NounPluralLowerCase: string;
	VerbLowerCase: string;
	VerbAppliedLowerCase: string;
	VerbPresentTenseSentenceCase: string;
	VerbPresentTenseLowerCase: string;
	VerbPastTenseSentenceCase: string;
	VerbPastTenseLowerCase: string;
	PayerSentenceCase: string;
	PayerLowerCase: string;
	PayerPluralSentenceCase: string;
	PayerPluralLowerCase: string;
	ActionSentenceCase: string;
	ActionLowerCase: string;
	ActionPluralSentenceCase: string;
	ActionPluralLowerCase: string;
	NonCashNounSentenceCase: string;
	NonCashNounLowerCase: string;
	NonCashPluralSentenceCase: string;
	NonCashPluralLowerCase: string;
	TextGivingFeatureName: string;
	GiftEntryFeatureName: string;
}
export interface QuickBooksPaymentGateway {
	PaymentGatewayId: string;
	MerchantId: number;
	GatewayKind: QuickBooksPaymentGatewayKind;
	ConfigurationToken: string;
}
export enum QuickBooksPaymentGatewayKind {
	Unknown = 0, Ach = 1, CreditCard = 2, ProfitStars = 3, BatchEntry = 4,
}
export interface QuickBooksMerchant {
	MerchantId: number;
	MerchantName: string;
}
export interface QuickBooksFund {
	FundId: number;
	FundName: string;
	FundCode: string;
}
export interface QuickBooksLocation {
	Id: string;
	Name: string;
	FullyQualifiedName: string;
}
export interface QuickBooksClass {
	Id: string;
	Name: string;
	FullyQualifiedName: string;
}
export interface QuickBooksAccount {
	Id: string;
	Name: string;
	FullyQualifiedName: string;
	IsBankAccount: boolean;
}
export interface CollapsedPaymentGatewayMapping {
	AccountId: string;
	GatewayKind: QuickBooksPaymentGatewayKind;
	ConfigurationToken: string;
	IsEnabled: boolean;
	PaymentGatewayIds: string[];
}
export enum ExternalSystemStatus {
	Unknown = 0, Enabled = 1, Paused = 2, Disabled = 3, InSetup = 4, Deleted = 5,
}
export interface IntegrationStatusLabel {
	readonly CssClass: string;
	readonly Label: string;
}
export namespace IntegrationStatusLabel {
	export const SetupNotComplete: IntegrationStatusLabel = {
		CssClass: "label integration-label-setup-incomplete",
		Label: "Setup not complete",
	};
	export const SetupComplete: IntegrationStatusLabel = {
		CssClass: "label label-success-secondary",
		Label: "Setup complete",
	};
	export const Enabled: IntegrationStatusLabel = {
		CssClass: "label label-success",
		Label: "Enabled",
	};
	export const Disabled: IntegrationStatusLabel = {
		CssClass: "label label-default-secondary",
		Label: "Disabled",
	};
	export const Paused: IntegrationStatusLabel = {
		CssClass: "label label-danger",
		Label: "Paused",
	};
	export const PausedByPushpay: IntegrationStatusLabel = {
		CssClass: "label label-danger",
		Label: "Paused by Pushpay",
	};
	export const AuthenticationFailure: IntegrationStatusLabel = {
		CssClass: "label integration-label-exclamation",
		Label: "Authentication failure",
	};
	export const MissingMapping: IntegrationStatusLabel = {
		CssClass: "label integration-label-question",
		Label: "Missing mapping",
	};
	export const MissingFallbackFund: IntegrationStatusLabel = {
		CssClass: "label integration-label-question",
		Label: "Missing fallback fund",
	};
}
export const QboIntegrationApiConfig = {
	defaultBaseUrl: () => location.pathname.match(new RegExp('/pushpay/portal/\\d+', 'i'))[0],
	actions: {
		getMappings: <IApiAction<{}, QuickBooksMappingViewModel>>{
			url: (data) => `/integrations/quickbooks/getmappings`,
		},
		saveMappings: <IApiAction<{input: QuickBooksSaveMappingsViewModel}, QuickBooksSaveMappingsResult>>{
			url: (data) => `/integrations/quickbooks/savemappings`,
		},
		authenticate: <IApiAction<{}, QuickBooksIntegrationActionResult>>{
			url: (data) => `/integrations/quickbooks/authenticate`,
		},
		enable: <IApiAction<{journalEntryCreationHint: JournalEntryCreationHint}, QuickBooksIntegrationActionResult>>{
			url: (data) => `/integrations/quickbooks/enable`,
		},
		delete: <IApiAction<{}, QuickBooksIntegrationActionResult>>{
			url: (data) => `/integrations/quickbooks/delete`,
		},
		disable: <IApiAction<{}, QuickBooksIntegrationActionResult>>{
			url: (data) => `/integrations/quickbooks/disable`,
		},
	},
	urls: {
	},
};
/* tslint:enable:no-use-before-declare quotemark max-line-length */
// ReSharper restore InconsistentNaming
// ReSharper restore StringLiteralWrongQuotes
