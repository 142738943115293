import * as React from 'react';
import { observer } from 'mobx-react';
import * as Pikaday from 'pikaday';
import { IFormFieldProps, FormField } from './form-field';
import { OptionalDate, IDatePickerCommonProps, DatePicker } from '../primitives/date-picker';
import { classNames } from '../../utils/classnames';
import { FormFieldGroup } from './form-field-group';
import { DatePickerFieldState } from './date-picker-field';
import { FormState } from '../../../Shared/forms/form-state';
import { ModelMetadata } from '../../../LoggedInWeb/loggedinweb-generated';

interface IDatePickerFieldState {
	value?: OptionalDate;
	metadata?: ModelMetadata.IPropertyMetadata;
	skipSanitization?: boolean;
}

export const getDateRangeFieldState = (from: IDatePickerFieldState, to: IDatePickerFieldState) => new FormState({
	from: new DatePickerFieldState(from.value, from.metadata),
	to: new DatePickerFieldState(to.value, to.metadata),
});

interface IDateRangeCommonProps {
	fieldState: FormState<{ from: DatePickerFieldState, to: DatePickerFieldState }>;
	fromLabel?: string;
	toLabel?: string;
	label?: never;
	containerClassName?: string;
	disableFrom?: boolean;
	disableTo?: boolean;
	fromPlaceholder?: string;
	toPlaceholder?: string;
}

export type IDateRangeFieldProps = IDatePickerCommonProps & IFormFieldProps & IDateRangeCommonProps;

@observer
export class DateRangeField extends React.Component<IDateRangeFieldProps> {
	render() {
		const {
			fieldState,
			fromLabel = 'From',
			toLabel = 'To',
			className,
			containerClassName,
			tooltip,
			additionalClassNames,
			options,
			acceptanceTestTargetId,
			disableFrom,
			disableTo,
			fromPlaceholder,
			toPlaceholder,
			...rest } = this.props;

		const classes = classNames(`form-control`, additionalClassNames, fieldState.hasError ? `input-validation-error` : null);
		return (
			<FormFieldGroup className="date-range arrow-point-right" containerClassName={containerClassName} error={fieldState.error || ''}>
				<FormField label={fromLabel} className={className} tooltip={tooltip} isFormFieldGroupItem={true}>
					<DatePicker
						{...rest}
						value={fieldState.$.from.value}
						acceptanceTestTargetId={`${acceptanceTestTargetId}-from`}
						onChange={this.onFromChange}
						ariaInvalid={fieldState.hasError}
						onBlur={fieldState.enableAutoValidationAndValidate}
						additionalClassNames={classes}
						options={options}
						disabled={disableFrom}
						placeholder={fromPlaceholder}
					/>
				</FormField>
				<FormField label={toLabel} className={className} tooltip={tooltip} isFormFieldGroupItem={true}>
					<DatePicker
						{...rest}
						value={fieldState.$.to.value}
						acceptanceTestTargetId={`${acceptanceTestTargetId}-to`}
						onChange={this.onToChange}
						ariaInvalid={fieldState.hasError}
						onBlur={fieldState.enableAutoValidationAndValidate}
						additionalClassNames={classes}
						disabled={disableTo}
						placeholder={toPlaceholder}
						options={{
							...options,
							minDate: fieldState.$.from.value,
						} as Pikaday.PikadayOptions}
					/>
				</FormField>
			</FormFieldGroup>
		);
	}

	private onFromChange = (dateFrom: OptionalDate) => {
		const { $: { from: { onChange } } } = this.props.fieldState;
		onChange(dateFrom);
	}

	private onToChange = (dateTo: OptionalDate) => {
		const { $: { to: { onChange } } } = this.props.fieldState;
		onChange(dateTo);
	}
}

