import * as React from 'react';
import { ValidatableForm } from '../components/form-controls/validatable-form';
import { FileUploadForm } from './forms/file-upload-form';
import { ImportFileUploadViewModel } from './import-file-upload-store';
import { RejectedRowInfo } from './transaction-import-generated';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import { isFunction } from '../utils/is-function';
import { ImportFileErrorGrid } from './components/import-file-error-grid';

export interface IImportFileUploadFormProps {
	viewModel: ImportFileUploadViewModel;
}

@observer
export class ImportFileUploadForm extends React.Component<IImportFileUploadFormProps, {}> {
	render() {

		const {viewModel:{navigationInProgress, hasUploaded, hasFailed, isInvalid, rejectedRows, paymentLabel, showDonorsFile}} = this.props;
		const uploadHasError = hasFailed || isInvalid;
		const hasMessages = rejectedRows && rejectedRows.length > 0;

		if (!uploadHasError && !hasMessages) {
			return (
				<FileUploadForm
					navigationInProgress={navigationInProgress}
					showDonorsFile={showDonorsFile}
					handleSubmit={this.handleSubmit}
					handleCancelImport={this.handleCancelImport}
					handleTransactionsFileChange={this.handleTransactionsFileChange}
					handleDonorsFileChange={this.handleDonorsFileChange}
					fileTypeName="transaction"
					paymentLabel={paymentLabel}
				/>
			);
		} else if (hasUploaded && hasMessages) {
			return (
				<ValidatableForm>
					<div className="panel panel-default">
						<div className="panel-body">
							<ImportFileErrorGrid
								title="File upload complete."
								description="Your file upload has completed with warnings. You can ignore warnings and still proceed."
								messageHeader="Messages"
								rows={rejectedRows} />
						</div>
						<div className="panel-footer panel-footer-btn-group">
							<button type="button" className="btn btn-cancel" id="back" onClick={this.handleBack} disabled={navigationInProgress}>Cancel</button>
							<button type="button" className="btn btn-default" id="next" onClick={this.handleNext} disabled={navigationInProgress}>Next</button>
						</div>
					</div>
				</ValidatableForm>
			);
		} else {
			return (
				<ValidatableForm>
					<div className="panel panel-default">
						<div className="panel-body">
							{hasFailed && <h2>Please try uploading your file again</h2>}
							{isInvalid &&
								<ImportFileErrorGrid
									title="Rejected rows"
									description="We found errors in this file and some rows are rejected. Please check these rows and try to upload your file again."
									messageHeader="Errors"
									rows={rejectedRows} />
							}
						</div>
						<div className="panel-footer panel-footer-btn-group">
							<button type="button" className="btn btn-cancel" id="back" onClick={this.handleBack} disabled={navigationInProgress}>Try again</button>
						</div>
					</div>
				</ValidatableForm>
			);
		}
	}

	@action
	handleTransactionsFileChange = (file: File) => {
		this.props.viewModel.transactionsFile = file;
	}

	@action
	handleDonorsFileChange = (file: File) => {
		this.props.viewModel.donorsFile = file;
	}

	handleSubmit = () => {
		const { viewModel: {handleSubmit} } = this.props;
		if (isFunction(handleSubmit)) {
			handleSubmit();
		}
	}

	handleCancelImport = () => {
		const { viewModel: {handleCancelImport} } = this.props;
		if (isFunction(handleCancelImport)) {
			handleCancelImport();
		}
	}

	handleBack = () => {
		const { viewModel: {handleBack} } = this.props;
		if (isFunction(handleBack)) {
			handleBack();
		}
	}

	handleNext = () => {
		const { viewModel: {handleNext} } = this.props;
		if (isFunction(handleNext)) {
			handleNext();
		}
	}
}
