import * as React from 'react';
import { BankAccountMapping } from '../mapping-panels/bank-account-mapping';
import { inject, observer } from 'mobx-react';
import { QboConfigurationUserAction } from '../../pages/configuration/qbo-configuration-user-actions';
import { Channel } from '../../../../Shared/utils/user-action-channel';
import { QboMappingClientSideViewModel } from './qbo-mapping-client-side-view-model';
import { MappingsResult, MappingsSetupProgress } from '../../qbo-integration-generated';
import { FundsMapping } from '../mapping-panels/funds-mapping';
import { ListingLocationMapping } from '../mapping-panels/listing-location';
import { LoadingBox } from '../../../components/loading-box';
import { ValidatableForm } from '../../../components/form-controls/validatable-form';
import { Fragment } from '../../../../Shared/components/fragment';
import { StepStatusIndicator } from '../../../components/step-status';

export interface QboMappingFormProps {
	isMappingInfoLoaded: boolean;
	isProcessingRequest: boolean;
	vm: QboMappingClientSideViewModel;
	userActionChannel?: Channel<QboConfigurationUserAction>;
}

@inject('userActionChannel')
@observer
export class QboMappingForm extends React.Component<QboMappingFormProps> {

	render() {
		const { vm, isMappingInfoLoaded, isProcessingRequest } = this.props;
		const bankAccountMappingInfoViewModel = vm && vm.bankAccountMappingInfoViewModel;
		const fundsMappingInfoViewModel = vm && vm.fundsMappingInfoViewModel;
		const listingLocationInfoViewModel = vm && vm.listingLocationInfoViewModel;
		return isMappingInfoLoaded ?
			vm.mappingsResult === MappingsResult.Success &&
			<Fragment>
				<StepStatusIndicator
					steps={vm.configurationSteps}
					currentStep={vm.activeStep}
					completedStep={MappingsSetupProgress.Complete} />
				<ValidatableForm onSubmit={this.submitForm}>
					<div className="panel panel-default">
						<BankAccountMapping vm={bankAccountMappingInfoViewModel} cancelAction={this.cancelAction} isProcessingRequest={isProcessingRequest} />
						<ListingLocationMapping vm={listingLocationInfoViewModel} cancelAction={this.cancelAction} isProcessingRequest={isProcessingRequest}/>
						<FundsMapping vm={fundsMappingInfoViewModel} cancelAction={this.cancelAction} isProcessingRequest={isProcessingRequest}/>
						{
							vm.activeStep === MappingsSetupProgress.Complete &&
								<footer className="panel-footer panel-footer-btn-group">
									<button className="btn btn-ghost" onClick={this.cancelButtonClicked} disabled={isProcessingRequest}>Cancel</button>
									<button className="btn btn-default" disabled={isProcessingRequest}>Update</button>
								</footer>
						}
					</div>
				</ValidatableForm>
			</Fragment>
			: <div style={{position: 'relative'}}><LoadingBox  text="Loading"/></div>;
	}

	cancelButtonClicked = (e: React.FormEvent<HTMLButtonElement>) => {
		e.preventDefault();
		this.cancelAction();
	}

	cancelAction = () => {
		this.props.userActionChannel.put(new QboConfigurationUserAction.CancelMappings());
	}

	submitForm = () => {
		const model = this.props.vm.getSaveMappingModel();
		this.props.userActionChannel.put(new QboConfigurationUserAction.SaveMappings(model));
	}
}
