import * as React from 'react';
import { getElementId } from './form-control-utils';
import { IFormControlCommonProps } from './form-control-common-props';
import { ValidatableControl } from './validatable-control';
import { classNames } from '../../../Shared/utils/classnames';

export interface IFormControlTextareaProps extends IFormControlCommonProps {
	rows?: number;
	readonly?: boolean;
	onChangeHandler: (event: React.FormEvent<HTMLTextAreaElement>) => void;
	onBlurHandler?: (event: React.FormEvent<HTMLTextAreaElement>) => void;
	value: string;
	maxLength?: number;
	ignorePanLikeValidation?: boolean;
	cssClassNames?: string;
}

export const FormControlTextarea: React.StatelessComponent<IFormControlTextareaProps> = (props: IFormControlTextareaProps) => {
	const { uniqueSuffix, name, onChangeHandler, onBlurHandler, refCallback, validationRules, readonly,
		ignorePanLikeValidation, acceptanceTestTargetId, cssClassNames, ...rest } = props;

		const className = classNames('form-control', cssClassNames);

	return (
		<ValidatableControl validationRules={validationRules} elementName={name} revalidateOnBlur={true}>
			<textarea id={getElementId(name, uniqueSuffix)} name={name} className={className} onChange={onChangeHandler} onBlur={onBlurHandler}
				ref={refCallback} readOnly={readonly} data-ignore-pan-like={ignorePanLikeValidation} data-pp-at-target={acceptanceTestTargetId} {...rest} />
		</ValidatableControl>
	);
};
