import * as React from 'react';
import { SvgWrapper } from '../../../components/svg-wrapper';
import { Button } from '../../../components/button';
import { authorizeIcon, matchIcon, setupIcon } from './svg-icons';
import * as styles from './styles.less';

export const QboIntroPanel: React.StatelessComponent<{isProcessingRequest: boolean, connectAction: () => void}> = (props) => {
	return (
		<div className={styles.container}>
			<div className={styles.steps}>
				<QboIntroCard icon={authorizeIcon}>
					Authorize Pushpay to access your QuickBooks Online account then match bank accounts.
				</QboIntroCard>
				<QboIntroDivider />
				<QboIntroCard icon={matchIcon}>
					If you're using Locations in QuickBooks, you'll have the option to match them to your Pushpay Listings.
				</QboIntroCard>
				<QboIntroDivider />
				<QboIntroCard icon={setupIcon}>
					Set rules for how funds should map to your QuickBooks Accounts, Classes and Locations.
				</QboIntroCard>
			</div>
			<div className={styles.actions}>
				<Button className="btn btn-primary" onClick={props.connectAction} disabled={props.isProcessingRequest}>Authorize Pushpay</Button>
			</div>
		</div>
	);
};

const QboIntroCard: React.StatelessComponent<{icon: JSX.Element}> = (props) => {
	const { icon, children } = props;
	return (
		<div className={styles.card}>
			<div className={styles.cardIcon}>
				{icon}
			</div>
			<div className={styles.cardDescription}>
				{children}
			</div>
		</div>
	);
};

const QboIntroDivider: React.StatelessComponent<{}> = () => {
	return (
		<div className={styles.divider}>
			<SvgWrapper svg="arrow-short" className={styles.dividerIcon}/>
		</div>
	);
};
