import * as React from 'react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { machineContextKey, MachineContext } from '../../../../Shared/state-machine/saga-state-machine';
import { Match } from '../../../../Shared/state-machine/match';
import { PledgeEntryMainViewModel } from './pledge-entry-main-view-model';
import { Fragment } from '../../../../Shared/components/fragment';
import { States, Events } from './pledge-entry-states-events-and-actions';
import { CampaignsErrorModal } from '../../components/campaigns-modals/campaigns-error-modal';
import { PledgeAlreadyAddedModal } from '../../components/campaigns-modals/pledge-already-added-modal';
import { PledgeUpdateSuccessModal } from '../../components/campaigns-modals/pledge-updated-success-modal';
import { PledgeDeleteConfirmationModal } from '../../components/campaigns-modals/pledge-delete-confirmation-modal';
import { PledgeDeleteSuccessModal } from '../../components/campaigns-modals/pledge-delete-success-modal';
import { MemberFormMatchModal } from '../../../components/member-form/member-form-modal';
import { UpdatePledgeRequest, EditCommunityMemberRequestModel } from '../../campaigns-generated';

@inject(machineContextKey)
@observer
export class Modals extends React.Component<{
	vm: PledgeEntryMainViewModel,
	machineContext?: MachineContext
}> {
	render() {
		const { vm, machineContext } = this.props;
		const {
			form,
			alreadyAddedPledge,
			defaultCountry,
			listingOptions,
			memberFormValidationErrors,
			pledgeLabel,
		} = vm;
		const amount = form.$.amount.$;
		const updatingPledge = machineContext.matchesState(pledgeAlreadyAddedState(States.SavingPledge));
		const deletingPledge = machineContext.matchesState(pledgeAlreadyAddedState(States.DeletingPledge));
		const savingMember = machineContext.matchesState(States.SavingMember);
		const enableFeatureOrganizationalGiving = vm.enableFeatureOrganizationalGiving;
		return (
			<Fragment>
				<CampaignsErrorModal state={States.Error} onClose={this.onClose} />
				<Match state={States.PledgeAlreadyAdded}>
					<PledgeAlreadyAddedModal
						visibleStates={[pledgeAlreadyAddedState(States.Idle), pledgeAlreadyAddedState(States.SavingPledge)]}
						updatingPledge={updatingPledge}
						updatingOrDeletingPledge={updatingPledge || deletingPledge}
						alreadyAddedPledge={alreadyAddedPledge}
						newPledgeAmount={amount}
						onCancel={this.onCancel}
						onDelete={this.onDeletePledge}
						onUpdate={this.onUpdatePledge} />
					<PledgeUpdateSuccessModal state={pledgeAlreadyAddedState(States.PledgeUpdated)}
						alreadyAddedPledge={alreadyAddedPledge}
						newAmount={amount}
						onClose={this.onClose} />
					<PledgeDeleteConfirmationModal
						visibleStates={[pledgeAlreadyAddedState(States.ConfirmDelete), pledgeAlreadyAddedState(States.DeletingPledge)]}
						deletingPledge={deletingPledge}
						onConfirm={this.onConfirmDeletePledge}
						onCancel={this.onCancel}
						alreadyAddedPledge={alreadyAddedPledge}
						pledgeLabel={pledgeLabel} />
					<PledgeDeleteSuccessModal state={pledgeAlreadyAddedState(States.PledgeDeleted)}
						alreadyAddedPledge={alreadyAddedPledge}
						onClose={this.onClose} />
				</Match>
				<MemberFormMatchModal
					state={States.AddingMember}
					mode="create"
					firstName={this.firstName}
					lastName={this.lastName}
					defaultCountry={defaultCountry}
					listings={listingOptions}
					onCancel={this.onCancel}
					processing={savingMember}
					onSave={this.onMemberSave}
					validationErrors={memberFormValidationErrors}
					enableFeatureOrganizationalGiving={enableFeatureOrganizationalGiving} />
				<MemberFormMatchModal
					state={States.EditingMember}
					mode="edit"
					memberDetails={form.$.member.$}
					onCancel={this.onCancel}
					processing={savingMember}
					onSave={this.onMemberSave}
					validationErrors={memberFormValidationErrors}
					enableFeatureOrganizationalGiving={enableFeatureOrganizationalGiving} />
			</Fragment>
		);
	}

	onClose = () => {
		Events.raise.Close(this.props.machineContext);
	}

	onCancel = () => {
		Events.raise.Cancel(this.props.machineContext);
	}

	onDeletePledge = () => {
		Events.raise.DeletePledgeFromModal(this.props.machineContext);
	}

	onConfirmDeletePledge = () => {
		Events.raise.ConfirmDeletePledge(this.props.machineContext, { request: { Id: this.props.vm.alreadyAddedPledge.PledgeId } });
	}

	onMemberSave = (request: { merchantId?: number, model: EditCommunityMemberRequestModel}) => {
		Events.raise.SaveMember(this.props.vm.machineContext, request);
	}

	onUpdatePledge = () => {
		const { alreadyAddedPledge: { PledgeId }, form } = this.props.vm;
		const amount = form.$.amount.$;
		const communityMemberId = form.$.member.$.CommunityMemberId;
		const request: UpdatePledgeRequest = { PledgeId, CommunityMemberId: communityMemberId, Amount: amount };
		Events.raise.UpdatePledge(this.props.machineContext, { request });
	}

	@computed
	get firstName() {
		const textParts = this.props.vm.memberSearchValue.trim().split(' ');
		return textParts.length === 1 ? textParts[0] : textParts.slice(0, textParts.length - 1).join(' ');
	}

	@computed
	get lastName() {
		const textParts = this.props.vm.memberSearchValue.trim().split(' ');
		return textParts.length === 1 ? '' : textParts[textParts.length - 1];
	}
}

function pledgeAlreadyAddedState(state: string) {
	return [States.PledgeAlreadyAdded, state];
}
