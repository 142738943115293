import * as React from 'react';
import { PayerSearchOmnibox } from '../payer-search/payer-search-omnibox';
import { inject, observer } from 'mobx-react';
import { VirtualTerminalFormViewModel } from './virtual-terminal-form-view-model';
import { ValidatableForm } from '../../../components/form-controls/validatable-form';
import { PaymentEntryDetails } from '../payment-entry/payment-entry-details';
import { Button } from '../../../components/button';
import { IVirtualTerminalFormUserActionCreator } from './virtual-terminal-form-user-action-creator';
import { injectAnalytics, recordEvent, WithAnalytics } from '../../../analytics';

@injectAnalytics
@inject('userActionCreator')
@observer
export class VirtualTerminalForm extends React.Component<WithAnalytics & {
	formViewModel: VirtualTerminalFormViewModel,
	userActionCreator?: IVirtualTerminalFormUserActionCreator }
> {
	render() {
		const { formViewModel } = this.props;
		const {
			addGiftDisabled,
			paymentEntryVisible,
			savePaymentInProgress,
			validationErrors,
			formKey,
			listingStore,
			paymentEntry,
			selectedPayer,
		} = formViewModel;

		const paymentLabel = listingStore.selectedListing.PaymentLabel;
		const isProcessed = paymentEntryVisible ? paymentEntry.paymentMethod.isProcessed : true;
		const buttonLabel = `${isProcessed ? 'Process' : 'Record'} ${paymentLabel.NounLowerCase}`;
		const paymentEntryNotAvailableForLackOfPaymentMethods = !paymentEntryVisible
			&& selectedPayer
			&& !(paymentEntry && paymentEntry.paymentMethod);

		return (
			<ValidatableForm onSubmit={this.handleSubmit} validationErrors={validationErrors} key={formKey} acceptanceTestTargetId="single entry form">
				<div className={`panel panel-default vt-form${paymentEntryVisible ? ' vt-form-expanded' : ''}`}>
					<PayerSearchOmnibox vm={formViewModel} paymentLabel={paymentLabel} />
					<PaymentEntryDetails vm={formViewModel} />
					{paymentEntryVisible ?
						<div className="panel-footer panel-footer-btn-group">
							<Button disabled={savePaymentInProgress} className="btn btn-ghost" onClick={this.handleReset}
								acceptanceTestTargetId="reset button">Reset</Button>
							<Button
								submit
								className="btn btn-default"
								isProcessingRequest={savePaymentInProgress}
								disabled={addGiftDisabled}
								acceptanceTestTargetId="submit button"
								onClick={() => {
									if (NewFeatures.SetupPinpointAnalytics && this.props.analytics) {
										const { feature, subFeature } = this.props.analytics;
										recordEvent({ feature, subFeature, eventTypeLabel: 'recordGift' });
									}
								}}
							>
								{buttonLabel}
							</Button>
						</div>
						: null}
					{paymentEntryNotAvailableForLackOfPaymentMethods
						? <div className="panel-footer panel-footer-btn-group">
							<span>There are currently no payment entry methods available for this merchant listing.</span>
							</div>
						: null}
				</div>
			</ValidatableForm>
		);
	}

	private handleReset = () => {
		this.props.userActionCreator.ResetForm();
	}

	private handleSubmit = () => {
		this.props.userActionCreator.SubmitForm();
	}
}
