import moment from 'moment';
import MomentInput = moment.MomentInput;
import Pikaday from 'pikaday';

export class Datepicker {
	static dateFormat = 'YYYY-MM-DD';
	static moment1Day: MomentInput = {
		days: 1
	};

	private datepicker: JQuery;
	private options: Pikaday.PikadayOptions & IBlurFieldOnSelect; //Sad pikaday typings are currently missing the blurFieldOnSelect option

	constructor(element: Element) {
		this.datepicker = $(element);

		this.options = {
			field: element as HTMLElement,
			format: Datepicker.dateFormat,
			formatStrict: true,
			blurFieldOnSelect: false,
			firstDay: 0,
			minDate: this.getDataAsDate('pp-mindate'),
			maxDate: this.getDataAsDate('pp-maxdate')
		};

		this.setupAfterDateBehaviour();
		this.datepicker.data('picker', new Pikaday(this.options));
	}

	private getDataAsDate(dataName: string): Date {
		var data = this.datepicker.data(dataName);
		if (data) {
			var dateParts = data.split('-');
			return new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
		}
		return undefined;
	}

	private setupAfterDateBehaviour() {
		var afterDate = this.datepicker.data('pp-afterdate');

		if (afterDate) {
			var otherPicker = document.getElementById(afterDate) as HTMLInputElement;

			this.initDateAfterRange(otherPicker);
			this.bindAfterDateChangeHandler(otherPicker);
		}
	}

	// If other picker starts with a date set the min date of this picker to
	// other picker date
	private initDateAfterRange(otherPicker: HTMLInputElement) {
		var otherDate = moment(otherPicker.value);
		if (otherDate) {
			this.options.minDate = otherDate.toDate();
		}
	}

	// When other picker date is selected if it is before this date clear the date
	// and set the min date to other picker date
	private bindAfterDateChangeHandler(otherPicker: HTMLInputElement) {
		otherPicker.onchange = () => {
			var otherDate = moment(otherPicker.value);
			var thisPicker = this.datepicker.data('picker');

			if (thisPicker) {
				if (!otherDate.isValid()) {
					thisPicker.setMinDate(new Date(0));
					return;
				}

				thisPicker.setMinDate(otherDate.toDate());
				var thisDate = moment(this.datepicker.val());
				if (!thisDate.isValid() || thisDate.isBefore(otherDate)) {
					thisPicker.setDate();
					thisPicker.gotoDate(otherDate.toDate());
					this.datepicker.val('').trigger('change');
				}

			}
		};
	}
}

interface IBlurFieldOnSelect {
	blurFieldOnSelect: boolean;
}
