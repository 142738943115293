import * as React from 'react';

export interface IUploadProgressFormProps {
	title: string;
	cancelButtonText: string;
	nextButtonDisabled: boolean;
	cancelButtonDisabled?: boolean;
	navigationInProgress: boolean;
	handleCancel: () => void;
	handleNext?: () => void;
}

export class UploadProgressForm extends React.Component<IUploadProgressFormProps> {
	render() {
		const {
			title,
			cancelButtonText,
			navigationInProgress,
			handleNext,
			handleCancel,
			nextButtonDisabled,
			cancelButtonDisabled,
			children
		} = this.props;

		return (
			<form>
				<div className="panel panel-default ">
					<div className="panel-heading">{title}</div>
					<div className="panel-body">
						{children}
					</div>
					<div className="panel-footer panel-footer-btn-group">
						{!cancelButtonDisabled && !navigationInProgress && <button type="button" className="btn btn-cancel" id="cancel" onClick={handleCancel}>{cancelButtonText}</button>}
						{!nextButtonDisabled && !navigationInProgress && <button type="submit" className="btn btn-default" id="upload" onClick={handleNext}>Next</button>}
					</div>
				</div>
			</form>
		);
	}
}
