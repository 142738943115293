import { IBrandingSettingsSagaContext } from '../branding-settings-saga';
import { BrandingSettingsUserAction } from './actions';
import { BrandingSettingsDraftState, BrandingSettingsFormField } from '../editor-form/branding-settings-form-view-model';
import { BrandingPackageState } from '../branding-settings-main-view-model';
import { savePackageHandler } from './save-package-handler';
import { runInAction } from 'mobx';

export function formValueChangeHandler(context: IBrandingSettingsSagaContext, action: BrandingSettingsUserAction.FormDataChange) {
	const { mainViewModel } = context;
	const { formViewModel } = mainViewModel;

	runInAction(() => {
		formViewModel.draftState = BrandingSettingsDraftState.Changed;
		mainViewModel.packageState = BrandingPackageState.ChangesAwaitingPublish;

		switch (action.formField) {
			case BrandingSettingsFormField.GivingTitle:
				formViewModel.givingTitle = action.value;
				break;
			case BrandingSettingsFormField.PrimaryColor:
				formViewModel.primaryColor = action.value;
				break;
			case BrandingSettingsFormField.SecondaryColor:
				formViewModel.secondaryColor = action.value;
				break;
			case BrandingSettingsFormField.OrganizationBranding:
				formViewModel.isOrganizationBrandingMerchant = action.value == "true";
				formViewModel.hasOrganizationBrandingMerchantChanged = true;

				if (action.value == "true"){
					mainViewModel.setDefaultOrgBranding(parseInt(mainViewModel.selectedListingId));
				} else {
					mainViewModel.clearDefaultOrgBranding();
				}
				break;
			case BrandingSettingsFormField.CampusBranding:
				formViewModel.isCampusBrandingMerchant = action.value == "true";
				formViewModel.hasCampusBrandingMerchantChanged = true;

				if (action.value == "true"){
					mainViewModel.setDefaultCampusBranding(parseInt(mainViewModel.selectedListingId));
				} else {
					mainViewModel.clearDefaultCampusBranding();
				}
				break;
			default:
				const unsupported: never = action.formField;
				throw new Error(`BrandingSettingsFormField ${unsupported} is not supported`);
		}
	});

	savePackageHandler(context);
}
