import { useEffect } from 'react';

import { initializeAnalytics, getAnalytics } from '@pushpay/analytics';

import { recordEvent } from '../../analytics';
import { PinpointViewModel } from '../../loggedinweb-generated';

export const Pinpoint = function Pinpoint({
	PinpointAppId,
	PinpointCognitoIdentityPoolId,
	PinpointCognitoRegion,
	PinpointRegion,
	Email,
	FirstName,
	IdentityKey,
	LastName,
	OrganizationKey,
	TimeZone,
}: PinpointViewModel) {
	useEffect(() => {
		async function initAnalytics() {
			const { setAdditionalAttributes } = await getAnalytics();
			setAdditionalAttributes(currentAttributes => ({
				...currentAttributes,
				email: Email,
				firstName: FirstName,
				lastName: LastName,
				identityKey: IdentityKey,
				organizationKey: OrganizationKey,
				timezone: TimeZone,
				screenWidth: window.screen.width.toString(),
				screenHeight: window.screen.height.toString(),
			}));
			initializeAnalytics({
				configuration: {
					cognito: {
						identityPoolId: PinpointCognitoIdentityPoolId,
						region: PinpointCognitoRegion,
					},
					pinpoint: {
						appId: PinpointAppId,
						applicationType: 'multiPageApp',
						region: PinpointRegion,
					},
				},
			});
		}

		initAnalytics();

		window.pushpay.analytics = {
			recordEvent,
		};
	}, []);

	return null;
}
