

// ReSharper disable InconsistentNaming
// ReSharper disable StringLiteralWrongQuotes
/* tslint:disable:no-use-before-declare quotemark max-line-length */
import { IApiAction } from '../utils/data-service';
export enum ExportAction {
	View = 0, Csv = 1, Excel = 2, ExcelMultiMerchant = 3, Custom = 4,
}
export interface ScheduleEditRequest {
	DraftId: number;
	FirstName: string;
	LastName: string;
	Email: string;
	Mobile: string;
	Status: ScheduleImportDraftEditingStatus;
	DefaultCountry: Country;
}
export enum Country {
	NZ = 0, AU = 1, US = 2, AF = 4, AL = 8, AQ = 10, DZ = 12, AS = 16, AD = 20, AO = 24, AG = 28, AZ = 31, AR = 32, AT = 40, BS = 44, BH = 48, BD = 50, AM = 51, BB = 52, BE = 56, BM = 60, BT = 64, BO = 68,
	BA = 70, BW = 72, BV = 74, BR = 76, BZ = 84, IO = 86, SB = 90, VG = 92, BN = 96, BG = 100, MM = 104, BI = 108, BY = 112, KH = 116, CM = 120, CA = 124, CV = 132, KY = 136, CF = 140, LK = 144, TD = 148, CL = 152,
	CN = 156, TW = 158, CX = 162, CC = 166, CO = 170, KM = 174, YT = 175, CG = 178, CD = 180, CK = 184, CR = 188, HR = 191, CU = 192, CY = 196, CZ = 203, BJ = 204, DK = 208, DM = 212, DO = 214, EC = 218, SV = 222,
	GQ = 226, ET = 231, ER = 232, EE = 233, FO = 234, FK = 238, GS = 239, FJ = 242, FI = 246, AX = 248, FR = 250, GF = 254, PF = 258, TF = 260, DJ = 262, GA = 266, GE = 268, GM = 270, PS = 275, DE = 276, GH = 288,
	GI = 292, KI = 296, GR = 300, GL = 304, GD = 308, GP = 312, GU = 316, GT = 320, GN = 324, GY = 328, HT = 332, HM = 334, VA = 336, HN = 340, HK = 344, HU = 348, IS = 352, IN = 356, ID = 360, IR = 364, IQ = 368,
	IE = 372, IL = 376, IT = 380, CI = 384, JM = 388, JP = 392, KZ = 398, JO = 400, KE = 404, KP = 408, KR = 410, KW = 414, KG = 417, LA = 418, LB = 422, LS = 426, LV = 428, LR = 430, LY = 434, LI = 438, LT = 440,
	LU = 442, MO = 446, MG = 450, MW = 454, MY = 458, MV = 462, ML = 466, MT = 470, MQ = 474, MR = 478, MU = 480, MX = 484, MC = 492, MN = 496, MD = 498, ME = 499, MS = 500, MA = 504, MZ = 508, OM = 512, NA = 516,
	NR = 520, NP = 524, NL = 528, CW = 531, AW = 533, SX = 534, BQ = 535, NC = 540, VU = 548, NI = 558, NE = 562, NG = 566, NU = 570, NF = 574, NO = 578, MP = 580, UM = 581, FM = 583, MH = 584, PW = 585, PK = 586,
	PA = 591, PG = 598, PY = 600, PE = 604, PH = 608, PN = 612, PL = 616, PT = 620, GW = 624, TL = 626, PR = 630, QA = 634, RE = 638, RO = 642, RU = 643, RW = 646, BL = 652, SH = 654, KN = 659, AI = 660, LC = 662,
	MF = 663, PM = 666, VC = 670, SM = 674, ST = 678, SA = 682, SN = 686, RS = 688, SC = 690, SL = 694, SG = 702, SK = 703, VN = 704, SI = 705, SO = 706, ZA = 710, ZW = 716, ES = 724, SS = 728, SD = 729, EH = 732,
	SR = 740, SJ = 744, SZ = 748, SE = 752, CH = 756, SY = 760, TJ = 762, TH = 764, TG = 768, TK = 772, TO = 776, TT = 780, AE = 784, TN = 788, TR = 792, TM = 795, TC = 796, TV = 798, UG = 800, UA = 804, MK = 807,
	EG = 818, GB = 826, GG = 831, JE = 832, IM = 833, TZ = 834, VI = 850, BF = 854, UY = 858, UZ = 860, VE = 862, WF = 876, WS = 882, YE = 887, ZM = 894, XK = 999, __ = -2, Unsupported = -1,
}
export enum ScheduleImportDraftEditingStatus {
	Unknown = 0, Awaiting = 1, MigratedElsewhere = 2, DeclinedByAdmin = 3,
}
export interface ScheduleDeleteResponse {
	Result: OperationResult;
	ErrorMessage: string;
}
export enum OperationResult {
	Unknown = 0, Success = 1, Failed = 2,
}
export interface ScheduleDetailsResponse {
	Result: OperationResult;
	ErrorMessage: string;
	ScheduleDetails: ScheduleDetails;
}
export interface ScheduleDetails {
	DraftId: number;
	Amount: number;
	PaymentLabel: PaymentLabel;
	ImportedScheduleId: string;
	Frequency: string;
	NextOccurrence: string;
	PaymentMethod: string;
	PaymentReference: string;
	Name: string;
	FirstName: string;
	LastName: string;
	LinkToPayer: string;
	EmailAddress: string;
	MobileNumber: string;
	Address: string;
	Fund: string;
	MerchantListing: string;
	DefaultCountry: Country;
	Status: ScheduleImportDraftEditingStatus;
	DisplayStatus: string;
	AvailableStatusItems: SelectItem[];
	IsEditable: boolean;
	IsEmailBounced: boolean;
}
export interface SelectItem {
	Value: string;
	Label: string;
}
export interface PaymentLabel {
	NounSentenceCase: string;
	NounPluralSentenceCase: string;
	VerbSentenceCase: string;
	VerbAppliedSentenceCase: string;
	NounLowerCase: string;
	NounPluralLowerCase: string;
	VerbLowerCase: string;
	VerbAppliedLowerCase: string;
	VerbPresentTenseSentenceCase: string;
	VerbPresentTenseLowerCase: string;
	VerbPastTenseSentenceCase: string;
	VerbPastTenseLowerCase: string;
	PayerSentenceCase: string;
	PayerLowerCase: string;
	PayerPluralSentenceCase: string;
	PayerPluralLowerCase: string;
	ActionSentenceCase: string;
	ActionLowerCase: string;
	ActionPluralSentenceCase: string;
	ActionPluralLowerCase: string;
	NonCashNounSentenceCase: string;
	NonCashNounLowerCase: string;
	NonCashPluralSentenceCase: string;
	NonCashPluralLowerCase: string;
	TextGivingFeatureName: string;
	GiftEntryFeatureName: string;
}
export interface ConfirmImportRequest {
	BundleIds: number[];
	ClientDate: Date;
}
export interface CreateImportRequest {
	ImportType: ScheduleImportFormat;
	MerchantId: number;
}
export enum ScheduleImportFormat {
	Unknown = 0, Pushpay = 2, PushpayAndDonors = 3,
}
export interface UpdateMigrationRequest {
	MigrationId: number;
	StartDate: Date;
	OriginalStartDate: Date;
	EndDate: Date;
	OriginalEndDate: Date;
	ContactName: string;
	ContactEmail: string;
	ContactPhone: string;
	IsConfirmed: boolean;
	NeedToAdjustActiveSchedules: boolean;
	EmailContentType: ScheduleImportMigrationEmailContentType;
	DefaultStartPoint: ScheduleImportDefaultStartPoint;
	InitialEmail: EmailTemplate;
	WeeklyEmail: EmailTemplate;
	FinalEmail: EmailTemplate;
}
export interface EmailTemplate {
	Message: string;
	VideoMessage: string;
	VideoLink: string;
}
export enum ScheduleImportDefaultStartPoint {
	MigrationEnd = 0, NextDay = 1, ThreeDaysHence = 2, TenDaysHence = 3,
}
export enum ScheduleImportMigrationEmailContentType {
	Unknown = 0, OldSystemSwitchedOff = 1, OldScheduleStoppedByChurch = 2, OldScheduleCancelledByUser = 3,
}
export interface DeleteBundleResponse {
	Result: OperationResult;
	ErrorMessage: string;
	BundlesSummary: BundlesSummary;
}
export interface BundlesSummary {
	ImportedSchedulesCount: number;
	ImportedDonorsCount: number;
	ImportedMonthlyTotal: number;
}
export interface ImportNewFundModel {
	FundName: string;
	Code: string;
	TaxDeductible: boolean;
	Notes: string;
	IntegrationFundMatches: FundMatch[];
	QuickBooksIntegrationMapping: QuickBooksIntegrationMapping;
}
export interface QuickBooksIntegrationMapping {
	FundId: number;
	AccountId: string;
	ClassId: string;
	LocationId: string;
}
export interface FundMatch {
	IntegrationFundKey: string;
	ExternalSystemId: number;
}
export interface AddNewFundResponse {
	Result: OperationResult;
	ErrorMessage: string;
	NewFund: PushpayFundModel;
}
export interface PushpayFundModel {
	PushpayFundId: number;
	PushpayFundDisplayName: string;
}
export interface MatchFundsRequest {
	ScheduleImportBundleId: number;
	ImportedFunds: ScheduleImportFundViewModel[];
}
export interface ScheduleImportFundViewModel {
	ImportFundId: number;
	ImportFundName: string;
	PushpayFundId: number;
	FundMatchStatus: ScheduleImportFundMatchStatus;
}
export enum ScheduleImportFundMatchStatus {
	Unmatched = 0, Matching = 1, AutoMatched = 2, NoMatch = 3, ManuallyMatchedToExistingFund = 4, ManuallyMatchedToNewFund = 5, AskDonorToChoose = 6,
}
export interface OperationResponse {
	Result: OperationResult;
	ErrorMessage: string;
	SuccessUrl: string;
}
export interface ImportProgressResponse {
	Result: OperationResult;
	PercentageComplete: number;
}
export interface FileUploadStatusResponse {
	Result: OperationResult;
	UploadStatus: ScheduleImportUploadStatus;
	ParserMessages: FileParserMessage[];
}
export interface FileParserMessage {
	RowNumber: number;
	ErrorMessage: string;
	MessagePrefix: string;
}
export enum ScheduleImportUploadStatus {
	Unknown = 0, WaitingForUpload = 1, Uploading = 2, Completed = 3, ValidationError = 4, ValidationWarning = 5, Failed = 6,
}
export interface ReportUploadStatsRequest {
	ImportId: number;
	FileSize: number;
	FileName: string;
	UploadTimeInMs: number;
	Successful: boolean;
	Message: string;
}
export interface FileUploadRequest {
	ImportId: number;
	FileSize: number;
	FileName: string;
	Tags: string[];
}
export interface FileUploadResponse {
	Result: OperationResult;
	UploadLink: string;
}
export interface SchedulesListFilteredResponse {
	Result: OperationResult;
	ErrorMessage: string;
	ScheduleDetails: ScheduleDetailViewModel[];
	TotalCount: number;
	PageCount: number;
	TotalEstimatedMonthlyTotal: number;
}
export interface ScheduleDetailViewModel {
	ScheduleId: number;
	Status: ScheduleImportDraftDisplayStatus;
	Giver: string;
	HasSeenEmail: boolean;
	GiftFrequency: string;
	Fund: Fund;
	Amount: number;
	MonthlyTotal: number;
	LinkToPayer: string;
}
export interface Fund {
	Id: number;
	Name: string;
}
export enum ScheduleImportDraftDisplayStatus {
	Unknown = 0, Migrated = 1, Awaiting = 2, Declined = 3, MigratedElsewhere = 4, NotReached = 5,
}
export interface SchedulesListFilteredRequest {
	SelectedStatuses: ScheduleImportDraftDisplayStatus[];
	SelectedListings: number[];
	SelectedFunds: number[];
	PageNumber: number;
	SortedBy: ScheduleDetailsSortableColumn;
	IsAscending: boolean;
	MigrationId: number;
}
export enum ScheduleDetailsSortableColumn {
	Unknown = 0, Giver = 1, Amount = 2, MonthlyTotal = 3,
}
export interface SchedulesListViewModel {
	MigrationId: number;
	AvailableStatusItems: MultiSelectItem[];
	AvailableListingItems: MultiSelectItem[];
	AvailableFundItems: MultiSelectItem[];
	ScheduleCount: number;
	ScheduleImportTotalMonthlyValue: number;
	PageCount: number;
	PaymentLabel: PaymentLabel;
	CurrentMigrationUrl: string;
	ExportButtons: TransactionExportButton[];
}
export interface TransactionExportButton {
	Action: ExportAction;
	Description: string;
	Label: string;
}
export interface MultiSelectItem {
	Text: string;
	SelectedText: string;
	Value: string;
	Disabled: boolean;
}
export interface MigrationTipsViewModel {
	MigrationUrl: string;
	UploadsCount: number;
	TotalSchedulesCount: number;
	MigratedSchedulesCount: number;
	MigrationStatus: ScheduleImportMigrationStatus;
}
export enum ScheduleImportMigrationStatus {
	Draft = 0, Active = 1, Paused = 2, Finished = 4, Confirmed = 5, Closed = 6, Deleted = 7,
}
export interface MigrationSummaryViewModel {
	TotalScheduleCount: number;
	TotalEstimatedMonthlyAmount: number;
	MigratedScheduleCount: number;
	MigratedEstimatedMonthlyAmount: number;
	RemainingScheduleCount: number;
	RemainingEstimatedMonthlyAmount: number;
	DeclinedScheduleCount: number;
	MigratedElsewhereScheduleCount: number;
	UnreachedScheduleCount: number;
	PercentageMigrated: number;
	StartDate: Date;
	EndDate: Date;
	DaysLeft: number;
	TotalGiverCount: number;
	MigratedGiverCount: number;
	UnreachedGiverCount: number;
}
export interface MigrationDashboardViewModel {
	MigrationId: number;
	MigrationStatusText: string;
	MigrationStatusClassName: string;
	Listings: MerchantListing[];
	Funds: Fund[];
	EditMigrationUrl: string;
	IsMigrationEditable: boolean;
	SchedulesDetailsUrl: string;
	UploadMigrationUrl: string;
}
export interface MerchantListing {
	Id: number;
	Name: string;
}
export interface EditDraftScheduleViewModel {
	DraftId: number;
	RedirectUrl: string;
	PaymentLabel: PaymentLabel;
	FirstName: string;
	LastName: string;
	Email: string;
	Mobile: string;
	AvailableStatusItems: SelectItem[];
	Status: ScheduleImportDraftEditingStatus;
	IsEmailBounced: boolean;
	DefaultCountry: Country;
}
export interface EditMigrationViewModel {
	Migration: MigrationViewModel;
	PaymentLabel: PaymentLabel;
	MigrationStatus: ScheduleImportMigrationStatus;
	RedirectUrl: string;
	MinMigrationPeriodInDays: number;
	MaxMigrationPeriodInDays: number;
	MinDaysRemainingForUpdate: number;
	EmailBranding: EmailBranding;
}
export interface EmailBranding {
	OrganizationName: string;
	OrganizationLegalName: string;
	ShowLegalCopy: boolean;
	PrimaryColor: string;
	MerchantLogoUrl: string;
	PushpayLogoUrl: string;
	DefaultFundName: string;
}
export interface MigrationViewModel {
	MigrationId: number;
	MigrationStatusText: string;
	MigrationStatusClassName: string;
	StartDate: Date;
	EndDate: Date;
	ContactName: string;
	ContactEmail: string;
	ContactPhone: string;
	EmailContentType: ScheduleImportMigrationEmailContentType;
	DefaultStartPoint: ScheduleImportDefaultStartPoint;
	InitialEmail: EmailTemplateViewModel;
	WeeklyEmail: EmailTemplateViewModel;
	FinalEmail: EmailTemplateViewModel;
}
export interface EmailTemplateViewModel {
	Message: string;
	VideoMessage: string;
	VideoLink: string;
	Type: ScheduleImportEmailType;
}
export enum ScheduleImportEmailType {
	Unknown = 0, InitialInvite = 1, WeeklyReminder = 2, FinalReminder = 3,
}
export interface SetupAlertViewModel {
	CancelUrl: string;
	ReviewUrl: string;
	PaymentLabel: PaymentLabel;
	MinMigrationPeriodInDays: number;
	MaxMigrationPeriodInDays: number;
	Migration: MigrationViewModel;
	EmailBranding: EmailBranding;
	BundlesSummary: BundlesSummary;
	CanConfirmMigration: boolean;
	ReviewCampaignHelpUrl: string;
}
export interface ReviewImportViewModel {
	Bundles: BundleViewModel[];
	CreateImportUrl: string;
	HasConfirmedOrActiveMigration: boolean;
	BundlesSummary: BundlesSummary;
	PaymentLabel: PaymentLabel;
}
export interface BundleViewModel {
	BundleId: number;
	ImportType: string;
	ListingName: string;
	NumberOfSchedules: number;
	MonthlyTotal: number;
	MonthlyTotalSkipped: number;
	NumberOfGivers: number;
	SkippedRows: SkippedRowInfo[];
	Funds: FundInfo[];
	FundsTotalAmount: number;
}
export interface FundInfo {
	ImportedFundName: string;
	PushpayFundName: string;
	PushpayFundCode: string;
	Notes: string;
	Amount: number;
}
export interface SkippedRowInfo {
	RowNumber: number;
	Reason: string;
	Amount: number;
}
export interface ImportProgressViewModel {
	ImportId: number;
	PaymentLabel: PaymentLabel;
	ImportType: string;
	ListingName: string;
	MonthlyTotal: number;
	NumberOfSchedules: number;
	NumberOfFunds: number;
	NumberOfGivers: number;
	DateStarted: string;
	ImportedBy: string;
}
export interface FundMatchingViewModel {
	ScheduleImportBundleId: number;
	MerchantId: number;
	PushpayFunds: PushpayFundModel[];
	ImportedFunds: ScheduleImportFundViewModel[];
	FundCodePlaceholderText: string;
	CancelUrl: string;
	IntegrationFundsInfo: IntegrationFundsInfo[];
	QuickBooksManageFundInfo: QuickBooksManageFundInfo;
	PaymentLabel: PaymentLabel;
	AvailableListings: MultiSelectItem[];
}
export interface QuickBooksManageFundInfo {
	DisplayName: string;
	DisplayNameFirstUse: string;
	CanLocationsBeConfigured: boolean;
	CanClassesBeConfigured: boolean;
	Configuration: QuickBooksExternalConfiguration;
	IntegrationMapping: QuickBooksIntegrationMapping;
}
export interface QuickBooksExternalConfiguration {
	Accounts: IntegrationFundViewModel[];
	Classes: IntegrationFundViewModel[];
	Locations: IntegrationFundViewModel[];
}
export interface IntegrationFundViewModel {
	Key: string;
	Code: string;
	Name: string;
	ParentFundKey: string;
}
export interface IntegrationFundsInfo {
	DisplayName: string;
	ExternalSystemId: number;
	IntegrationFunds: IntegrationFundViewModel[];
}
export interface FileUploadViewModel {
	ImportId: number;
	PaymentLabel: PaymentLabel;
	ImportType: ScheduleImportFormat;
	UploadStatus: ScheduleImportUploadStatus;
	CancelUrl: string;
	BackUrl: string;
	ParserMessages: FileParserMessage[];
}
export interface CreateImportViewModel {
	AllImportTypes: SelectItem[];
	AllVisibleListings: SelectItem[];
	CancelUrl: string;
	PaymentLabel: PaymentLabel;
	UnavailableRecurringFrequencies: string[];
	SupportUrl: string;
	PrepareDataHelpUrl: string;
}
export const ScheduleImportApiConfig = {
	defaultBaseUrl: () => location.pathname.match(new RegExp('/pushpay/portal/\\d+', 'i'))[0],
	actions: {
		getUploadUrl: <IApiAction<{model: FileUploadRequest}, FileUploadResponse>>{
			url: (data) => `/recurringmigration/uploadUrl`,
		},
		reportUploadStatistics: <IApiAction<{model: ReportUploadStatsRequest}, boolean>>{
			url: (data) => `/recurringmigration/uploadstats`,
		},
		getImportUploadStatus: <IApiAction<{scheduleImportBundleId: number}, FileUploadStatusResponse>>{
			url: (data) => `/recurringmigration/uploadStatus`,
		},
		getImportProgressStatus: <IApiAction<{scheduleImportBundleId: number}, ImportProgressResponse>>{
			url: (data) => `/recurringmigration/importStatus`,
		},
		matchFunds: <IApiAction<{model: MatchFundsRequest}, OperationResponse>>{
			url: (data) => `/recurringmigration/matchfunds`,
		},
		addNewFund: <IApiAction<{selectedListings: number[], model: ImportNewFundModel}, AddNewFundResponse>>{
			url: (data) => `/recurringmigration/addNewFund`,
		},
		cancelImport: <IApiAction<{scheduleImportBundleId: number}, OperationResponse>>{
			url: (data) => `/recurringmigration/cancelImport`,
		},
		deleteImport: <IApiAction<{scheduleImportBundleId: number}, DeleteBundleResponse>>{
			url: (data) => `/recurringmigration/deleteImport`,
		},
		updateMigration: <IApiAction<{model: UpdateMigrationRequest}, OperationResponse>>{
			url: (data) => `/recurringmigration/updateMigration`,
		},
		createImport: <IApiAction<{model: CreateImportRequest}, OperationResponse>>{
			url: (data) => `/recurringmigration/createImport`,
		},
		confirmImport: <IApiAction<{model: ConfirmImportRequest}, OperationResponse>>{
			url: (data) => `/recurringmigration/confirmImport`,
		},
		migrationSummary: <IApiAction<{id: number, merchantId: number, fundId: number}, MigrationSummaryViewModel>>{
			url: (data) => `/recurringmigration/migration-summary`,
		},
		schedulesListFiltered: <IApiAction<{details: SchedulesListFilteredRequest}, SchedulesListFilteredResponse>>{
			url: (data) => `/recurringmigration/schedules-list-filtered`,
		},
		scheduleDetails: <IApiAction<{scheduleImportDraftId: number}, ScheduleDetailsResponse>>{
			url: (data) => `/recurringmigration/schedule-details`,
		},
		scheduleDelete: <IApiAction<{scheduleImportDraftId: number}, ScheduleDeleteResponse>>{
			url: (data) => `/recurringmigration/schedule-delete`,
		},
		scheduleEdit: <IApiAction<{model: ScheduleEditRequest}, OperationResponse>>{
			url: (data) => `/recurringmigration/schedule-edit`,
		},
	},
	urls: {
	},
};
export const ScheduleImportExportConfig = {
	defaultBaseUrl: () => (<RegExpMatchArray>location.pathname.match(new RegExp('/pushpay/portal/\\d+', 'i')))[0],
	actions: {
		exportSchedules: <IApiAction<{migrationId: number, action: ExportAction, statuses: string, listings: string, funds: string}>>{
			url: (data) => `/recurringmigration/export-schedules`,
		},
	},
	urls: {
	},
};
/* tslint:enable:no-use-before-declare quotemark max-line-length */
// ReSharper restore InconsistentNaming
// ReSharper restore StringLiteralWrongQuotes
// ReSharper disable InconsistentNaming
// ReSharper disable StringLiteralWrongQuotes
/* tslint:disable:no-use-before-declare quotemark max-line-length */
export namespace ModelMetadata {
	export class MetadataArrayOf<T> {
		metadata: T;

		constructor(metadata: T) {
			this.metadata = metadata;
		}

		at(index: number): T {
			return this.metadata;
		}
	}

	export interface IPropertyMetadata {
		propertyName: string;
		displayName?: string;
		placeholder?: string;
		validationRules?: { [rule: string]: any };
		modelMetadata?: { [name: string] :IPropertyMetadata};
	}

	export let CreateImportRequest = {
		ImportType: {
			propertyName: "ImportType",
			displayName: "Data format",
			validationRules: {
				required: {
					errorMessage: "Please select a data format",
				},
			},
		},
		MerchantId: {
			propertyName: "MerchantId",
			displayName: "Listing",
			validationRules: {
				number: {
					errorMessage: "The field Listing must be a number.",
				},
				required: {
					errorMessage: "Please select a listing",
				},
			},
		},
	};
	export let UpdateMigrationRequest = {
		MigrationId: {
			propertyName: "MigrationId",
			validationRules: {
				number: {
					errorMessage: "The field MigrationId must be a number.",
				},
				required: {
					errorMessage: "The MigrationId field is required.",
				},
			},
		},
		StartDate: {
			propertyName: "StartDate",
			displayName: "Start date",
			validationRules: {
				date: {
					errorMessage: "The field Start date must be a date.",
				},
				required: {
					errorMessage: "Please enter a start date",
				},
			},
		},
		OriginalStartDate: {
			propertyName: "OriginalStartDate",
			validationRules: {
				date: {
					errorMessage: "The field OriginalStartDate must be a date.",
				},
				required: {
					errorMessage: "The OriginalStartDate field is required.",
				},
			},
		},
		EndDate: {
			propertyName: "EndDate",
			displayName: "End date",
			validationRules: {
				date: {
					errorMessage: "The field End date must be a date.",
				},
				required: {
					errorMessage: "Please enter an end date",
				},
			},
		},
		OriginalEndDate: {
			propertyName: "OriginalEndDate",
			validationRules: {
				date: {
					errorMessage: "The field OriginalEndDate must be a date.",
				},
				required: {
					errorMessage: "The OriginalEndDate field is required.",
				},
			},
		},
		ContactName: {
			propertyName: "ContactName",
			displayName: "Contact name",
			validationRules: {
				length: {
					errorMessage: "The field Contact name must be a string with a maximum length of 200.",
					parameters: {
						max: 200,
					},
				},
				required: {
					errorMessage: "Please enter a contact name",
				},
			},
		},
		ContactEmail: {
			propertyName: "ContactEmail",
			displayName: "Contact email",
			validationRules: {
				email: {
					errorMessage: "The contact email is not a valid email address",
				},
				length: {
					errorMessage: "The field Contact email must be a string with a maximum length of 100.",
					parameters: {
						max: 100,
					},
				},
				required: {
					errorMessage: "Please enter a contact email",
				},
			},
		},
		ContactPhone: {
			propertyName: "ContactPhone",
			displayName: "Contact phone number",
			validationRules: {
				length: {
					errorMessage: "The field Contact phone number must be a string with a maximum length of 50.",
					parameters: {
						max: 50,
					},
				},
				regex: {
					errorMessage: "The contact phone number is not a valid phone number",
					parameters: {
						pattern: "^\\+?[0-9-() ]{7,20}$",
					},
				},
				required: {
					errorMessage: "Please enter a contact phone number",
				},
				skipsanitization: {},
			},
		},
		IsConfirmed: {
			propertyName: "IsConfirmed",
			validationRules: {
				required: {
					errorMessage: "The IsConfirmed field is required.",
				},
			},
		},
		NeedToAdjustActiveSchedules: {
			propertyName: "NeedToAdjustActiveSchedules",
			validationRules: {
				required: {
					errorMessage: "The NeedToAdjustActiveSchedules field is required.",
				},
			},
		},
		EmailContentType: {
			propertyName: "EmailContentType",
			validationRules: {
				required: {
					errorMessage: "The EmailContentType field is required.",
				},
			},
		},
		DefaultStartPoint: {
			propertyName: "DefaultStartPoint",
		},
		InitialEmail: {
			propertyName: "InitialEmail",
			get modelMetadata() {
				return ModelMetadata.EmailTemplate;
			},
		},
		WeeklyEmail: {
			propertyName: "WeeklyEmail",
			get modelMetadata() {
				return ModelMetadata.EmailTemplate;
			},
		},
		FinalEmail: {
			propertyName: "FinalEmail",
			get modelMetadata() {
				return ModelMetadata.EmailTemplate;
			},
		},
	};
	export let ScheduleEditRequest = {
		DraftId: {
			propertyName: "DraftId",
			validationRules: {
				number: {
					errorMessage: "The field DraftId must be a number.",
				},
				required: {
					errorMessage: "The DraftId field is required.",
				},
			},
		},
		FirstName: {
			propertyName: "FirstName",
			displayName: "First name",
			validationRules: {
				length: {
					errorMessage: "The field First name must be a string with a maximum length of 100.",
					parameters: {
						max: 100,
					},
				},
				required: {
					errorMessage: "Please enter a first name",
				},
			},
		},
		LastName: {
			propertyName: "LastName",
			displayName: "Last name",
			validationRules: {
				length: {
					errorMessage: "The field Last name must be a string with a maximum length of 100.",
					parameters: {
						max: 100,
					},
				},
				required: {
					errorMessage: "Please enter a last name",
				},
			},
		},
		Email: {
			propertyName: "Email",
			displayName: "Email address",
			validationRules: {
				email: {
					errorMessage: "The Email address field is not a valid e-mail address.",
				},
				length: {
					errorMessage: "The field Email address must be a string with a maximum length of 100.",
					parameters: {
						max: 100,
					},
				},
				required: {
					errorMessage: "Please enter an email address",
				},
			},
		},
		Mobile: {
			propertyName: "Mobile",
			displayName: "Mobile number",
			validationRules: {
				length: {
					errorMessage: "The field Mobile number must be a string with a maximum length of 20.",
					parameters: {
						max: 20,
					},
				},
				regex: {
					errorMessage: "The Mobile field is not a valid mobile number",
					parameters: {
						pattern: "^\\+?[0-9-() ]{7,20}$",
					},
				},
				skipsanitization: {},
			},
		},
		Status: {
			propertyName: "Status",
			displayName: "Status",
			validationRules: {
				required: {
					errorMessage: "Please select a status",
				},
			},
		},
		DefaultCountry: {
			propertyName: "DefaultCountry",
			validationRules: {
				required: {
					errorMessage: "The DefaultCountry field is required.",
				},
			},
		},
	};
	export let EmailTemplate = {
		Message: {
			propertyName: "Message",
			displayName: "Email template message",
			validationRules: {
				length: {
					errorMessage: "The field Email template message must be a string with a maximum length of 2000.",
					parameters: {
						max: 2000,
					},
				},
				required: {
					errorMessage: "Please enter the email template message.",
				},
				skipsanitization: {},
			},
		},
		VideoMessage: {
			propertyName: "VideoMessage",
			displayName: "Video message",
			validationRules: {
				length: {
					errorMessage: "The field Video message must be a string with a maximum length of 1000.",
					parameters: {
						max: 1000,
					},
				},
				skipsanitization: {},
			},
		},
		VideoLink: {
			propertyName: "VideoLink",
			displayName: "Video URL (optional)",
			validationRules: {
				length: {
					errorMessage: "The field Video URL (optional) must be a string with a maximum length of 200.",
					parameters: {
						max: 200,
					},
				},
			},
		},
	};
	export let ImportNewFundModel = {
		FundName: {
			propertyName: "FundName",
			displayName: "Fund name",
			validationRules: {
				length: {
					errorMessage: "Please enter a name 50 characters or fewer",
					parameters: {
						max: 50,
					},
				},
				required: {
					errorMessage: "Please enter a fund name",
				},
			},
		},
		Code: {
			propertyName: "Code",
			displayName: "Code (optional)",
			validationRules: {
				length: {
					errorMessage: "Please enter a code 128 characters or fewer",
					parameters: {
						max: 128,
					},
				},
				skipsanitization: {},
			},
		},
		TaxDeductible: {
			propertyName: "TaxDeductible",
			displayName: "Tax deductible",
			validationRules: {
				required: {
					errorMessage: "Please choose whether the fund should be tax deductible or not",
				},
			},
		},
		Notes: {
			propertyName: "Notes",
			displayName: "Notes (optional)",
			validationRules: {
				length: {
					errorMessage: "Please enter notes 256 characters or fewer",
					parameters: {
						max: 256,
					},
				},
			},
		},
		IntegrationFundMatches: {
			propertyName: "IntegrationFundMatches",
		},
		QuickBooksIntegrationMapping: {
			propertyName: "QuickBooksIntegrationMapping",
			validationRules: {
				skipsanitization: {},
			},
		},
	};
	export let ScheduleImportFundViewModel = {
		ImportFundId: {
			propertyName: "ImportFundId",
			validationRules: {
				number: {
					errorMessage: "The field ImportFundId must be a number.",
				},
				required: {
					errorMessage: "The ImportFundId field is required.",
				},
			},
		},
		ImportFundName: {
			propertyName: "ImportFundName",
		},
		PushpayFundId: {
			propertyName: "PushpayFundId",
			validationRules: {
				number: {
					errorMessage: "The field PushpayFundId must be a number.",
				},
				required: {
					errorMessage: "Please select a fund",
				},
			},
		},
		FundMatchStatus: {
			propertyName: "FundMatchStatus",
			validationRules: {
				required: {
					errorMessage: "The FundMatchStatus field is required.",
				},
			},
		},
	};
	export let SchedulesListFilteredRequest = {
		SelectedStatuses: {
			propertyName: "SelectedStatuses",
		},
		SelectedListings: {
			propertyName: "SelectedListings",
		},
		SelectedFunds: {
			propertyName: "SelectedFunds",
		},
		PageNumber: {
			propertyName: "PageNumber",
			validationRules: {
				number: {
					errorMessage: "The field PageNumber must be a number.",
				},
				required: {
					errorMessage: "The PageNumber field is required.",
				},
			},
		},
		SortedBy: {
			propertyName: "SortedBy",
			validationRules: {
				required: {
					errorMessage: "The SortedBy field is required.",
				},
			},
		},
		IsAscending: {
			propertyName: "IsAscending",
			validationRules: {
				required: {
					errorMessage: "The IsAscending field is required.",
				},
			},
		},
		MigrationId: {
			propertyName: "MigrationId",
			validationRules: {
				number: {
					errorMessage: "The field MigrationId must be a number.",
				},
				required: {
					errorMessage: "The MigrationId field is required.",
				},
			},
		},
	};
	export let SchedulesListFilteredResponse = {
		Result: {
			propertyName: "Result",
			validationRules: {
				required: {
					errorMessage: "The Result field is required.",
				},
			},
		},
		ErrorMessage: {
			propertyName: "ErrorMessage",
		},
		ScheduleDetails: {
			propertyName: "ScheduleDetails",
		},
		TotalCount: {
			propertyName: "TotalCount",
			validationRules: {
				number: {
					errorMessage: "The field TotalCount must be a number.",
				},
				required: {
					errorMessage: "The TotalCount field is required.",
				},
			},
		},
		PageCount: {
			propertyName: "PageCount",
			validationRules: {
				number: {
					errorMessage: "The field PageCount must be a number.",
				},
				required: {
					errorMessage: "The PageCount field is required.",
				},
			},
		},
		TotalEstimatedMonthlyTotal: {
			propertyName: "TotalEstimatedMonthlyTotal",
			validationRules: {
				number: {
					errorMessage: "The field TotalEstimatedMonthlyTotal must be a number.",
				},
				required: {
					errorMessage: "The TotalEstimatedMonthlyTotal field is required.",
				},
			},
		},
	};
	export let ExportAction = {
	};
}
/* tslint:enable:no-use-before-declare quotemark max-line-length */
// ReSharper restore InconsistentNaming
// ReSharper restore StringLiteralWrongQuotes
