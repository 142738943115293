import * as React from 'react';
import { observer } from 'mobx-react';
import { FormActions } from './form-actions';
import { Form } from '../../funds/components/form-controls';
import { ValidationSummary } from '../../components/form-controls/validation-summary';
import { IntegrationLabels } from './integration-labels';
import { IIntegrationConfigurationStore } from './integration-configuration-store';

export interface IAuthenticationSectionProps {
	store: IIntegrationConfigurationStore<any>;
}

@observer
export class AuthenticationSection extends React.Component<IAuthenticationSectionProps, {}> {
	handleSubmit = (form: HTMLFormElement) => {
		this.props.store.serviceClient.setupAuthentication(new FormData(form));
	}

	render() {
		return (
			<div className="form-horizontal connection-section">
				<Form onSubmit={this.handleSubmit}>
					<div className="panel panel-default">
						<div className="panel-heading">
							<span>{`Connect ${this.props.store.viewModel.Metadata.IntegrationSystem.DisplayName} integration`}</span>
							<IntegrationLabels labels={this.props.store.viewModel.Content.PrimaryLabels} />
						</div>
						{React.Children.count(this.props.children) > 0 &&
							<div className="panel-body">
								<ValidationSummary />
								{this.props.children}
							</div>
						}
						<FormActions
							isSettingUp={this.props.store.serviceClient.isSettingUp}
							isProcessingRequest={this.props.store.serviceClient.isProcessingRequest}
							cancelButtonAction={this.props.store.isAuthenticated
								? this.props.store.cancelEditingAuthenticationSection
								: this.props.store.cancelActionUrl}
							submitButtonText={this.props.store.isAuthenticated ? 'Update' : 'Connect'} />
					</div>
				</Form>
			</div>
		);
	}
}
