import { observable, action, autorun, runInAction } from 'mobx';
import { MemberSearchOmniboxViewModel } from '../view-models/member-search-omnibox-viewmodel';
import { IPayerViewModel, IPayerSearchResult } from '../../components/payer-search-omnibox/payer-search-models';
import { communityMemberPromiseDataService } from '../community-member-data-service';
import { Models } from '../community-member-generated';
import { IPersonalDetails } from './personal-details';
import { ICancellablePromise } from '../../utils/cancellable-promise';

interface IPayerIsEligibleForConversionLookup {
	[memberId: string]: boolean;
}

export class ConvertCommunityMemberToOrgModalViewModel {

	@observable
	newName: string;

	@observable
	convertButtonEnabled: boolean = false;

	@observable
	selectedPayer: IPayerViewModel;

	@observable
	conversionDone: boolean = false;

	@observable
	conversionSuccess: boolean;

	@observable
	errorOccuredInConversion: boolean = false;

	@observable
	isConverting: boolean = false;

	@observable
	conversionGuarded: boolean = false;

	@observable
	validationErrorMessage: string;

	omniboxVm: MemberSearchOmniboxViewModel;

	searchPromise: ICancellablePromise<Models.CommunityMemberByNameForOrganizationConversionSearchResult>;

	convertPromise: ICancellablePromise<boolean>;

	eligiblilityLookup: IPayerIsEligibleForConversionLookup = {};

	eligibilityAutorunDisposer = autorun(() => {
		const payerEligible = this.selectedPayer && this.eligiblilityLookup[this.selectedPayer.id];
		if (this.selectedPayer && !payerEligible) {
			if (this.selectedPayer.isRegistered) {
				runInAction(() => this.validationErrorMessage = "Changing an Active Community Member's member type is not currently supported");
			} else {
				runInAction(() => this.validationErrorMessage = 'Unable to change the type of this Pushpay account at this time');
			}
		} else {
			runInAction(() => this.validationErrorMessage = null);
		}

		const newNameProvided = this.newName && this.newName.length > 0;
		runInAction(() => this.convertButtonEnabled = payerEligible && newNameProvided);
	});

	constructor() {
		this.omniboxVm = new MemberSearchOmniboxViewModel();
		this.omniboxVm.selectedPayerChangeCallback = this.handleSelectedPayerChanged;
		this.omniboxVm.selectedPayerClearedCallback = this.handleClearSelectedPayer;
		this.omniboxVm.searchMembersCallback = this.handleSearch;
		this.omniboxVm.cancelSearchCallback = this.cancelSearch;
	}

	@action.bound
	handleNewNameChange = (event: React.FormEvent<HTMLInputElement>) => {
		this.changeNewName(event.currentTarget.value);
	}

	@action.bound
	handleSelectedPayerChanged = (payer: IPayerViewModel) => {
		if (payer) {
			this.changeNewName(payer.name);
			this.selectedPayer = payer;
		} else {
			this.changeNewName('');
			this.selectedPayer = null;
		}
	}

	@action.bound
	handleClearSelectedPayer = () => {
		this.changeNewName('');
		this.selectedPayer = null;
	}

	@action.bound
	onCancelGuardCancelClicked = () => {
		this.resetConversionGuard();
		return;
	}

	@action.bound
	onConvertClicked = () => {
		if (this.convertPromise) {
			return;
		}

		if (!this.conversionGuarded) {
			this.activateConversionGuard();
			return;
		}

		this.isConverting = true;

		this.convertPromise = communityMemberPromiseDataService().convertToOrganization({
			conversionRequestModel: {
				CommunityMemberId: parseInt(this.selectedPayer.id),
				Name: this.newName,
			}
		});

		this.convertPromise.then(result => this.consumeConvertPromise(result), error => this.handleConvertPromiseFailure());
	}

	@action.bound
	private consumeConvertPromise(result: boolean) {
		this.conversionSuccess = result;
		this.conversionDone = true;
		this.resetConversionGuard();
	}

	@action.bound
	private handleConvertPromiseFailure() {
		this.conversionSuccess = false;
		this.conversionDone = true;
		this.errorOccuredInConversion = true;
		this.resetConversionGuard();
	}

	@action.bound
	private changeNewName(newName: string) {
		this.newName = newName;
	}

	private activateConversionGuard() {
		this.conversionGuarded = true;
	}

	private resetConversionGuard() {
		this.conversionGuarded = false;
	}

	private cancelSearch = () => {
		if (this.searchPromise) {
			this.searchPromise.cancel();
		}
	}

	private handleSearch = (query: string, skip: number) => {
		this.cancelSearch();

		this.searchPromise = communityMemberPromiseDataService().searchMembersForOrganizationalConversionByName({
			model: {
				Query: query,
				Skip: skip,
			}
		});

		this.searchPromise.then(result => this.consumeSearchResult(result, skip > 0), error => this.errorSearching());
	}

	private errorSearching = () => {
		this.omniboxVm.updateSearchResult({
			error: true,
			payers: [],
			hasMorePages: false,
			appendedResults: false,
		});
	}

	private consumeSearchResult(result: Models.CommunityMemberByNameForOrganizationConversionSearchResult, loadingMore: boolean) {
		if (!loadingMore) {
			this.eligiblilityLookup = {};
		}

		const members: IPayerViewModel[] = result.SearchResults.map((mem) => {
			const mapped = {
				id: `${(mem.CommunityMemberId === null || mem.CommunityMemberId === undefined) ? '' : mem.CommunityMemberId}`,
				name: [mem.CommunityMemberFirstName, mem.CommunityMemberLastName].filter(x => x).join(' '),
				mobileNumber: mem.CommunityMemberMobileNumber,
				emailAddress: mem.CommunityMemberEmailAddress,
				displayAddress: this.getDisplayAddress(mem),
				postCode: mem.CommunityMemberAddressPostCode,
				personalDetails: this.getPersonalDetails(mem),
				country: mem.CommunityMemberAddressCountry,
				isOrganization: mem.CommunityMemberType === Models.CommunityMemberType.Organization,
				isRegistered: mem.IsRegistered,
			};
			this.eligiblilityLookup[mapped.id] = mem.EligibleForConversion;
			return mapped;
		});

		const mappedResult: IPayerSearchResult = {
			error: false,
			payers: loadingMore ? this.omniboxVm.searchResult.payers.concat(members) : members,
			hasMorePages: result.HasMorePages,
			appendedResults: loadingMore,
		};

		this.omniboxVm.updateSearchResult(mappedResult);
	}

	private getPersonalDetails(model: Models.CommunityMemberSearchForOrganizationalConversionByNameRow): IPersonalDetails {
		return {
			FirstName: model.CommunityMemberFirstName,
			LastName: model.CommunityMemberLastName,
			EmailAddress: model.CommunityMemberEmailAddress,
			MobileNumber: model.CommunityMemberMobileNumber,
			LastPaymentOnLocal: model.CommunityMemberLastPaymentOn,
			Address: {
				Line1: model.CommunityMemberAddressLine1,
				Line2: model.CommunityMemberAddressLine2,
				City: model.CommunityMemberAddressCity,
				State: model.CommunityMemberAddressState,
				Postcode: model.CommunityMemberAddressPostCode,
				Country: model.CommunityMemberAddressCountry,
				DisplayAddress: this.getDisplayAddress(model),
				PostalCodeLabel: '',
			},
			LockVersion: model.CommunityMemberLockVersion,
			CommunityMemberType: model.CommunityMemberType,
		};
	}

	private getDisplayAddress(model: Models.CommunityMemberSearchForOrganizationalConversionByNameRow): string {
		const addressParts = [model.CommunityMemberAddressLine1, model.CommunityMemberAddressLine2, model.CommunityMemberAddressCity, model.CommunityMemberAddressState];
		return addressParts.filter(x => x).join(', ');
	}
}
