import * as React from 'react';
import { observable, action, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { LocalStorageHelper } from '../../helpers/localstoragehelper';
import { Carousel } from '../carousel';
import { CarouselSlide, ICarouselProps } from '../carousel-slide';

export interface ITipCarouselProps {
	icon?: string;
	storageKey: string;
	dismissable?: boolean;
	extraClassNames?: string;
	slideDelay: number;
	showTipsLabel?: string;
	extremeEdge?: boolean;
	disableAnimation?: boolean;
	disableSlidesRotation?: boolean;
}

export class TipCarouselState {
	private static keyNamespace = 'Pushpay.TipCarouselState';

	@observable dismissedKeys: (string | number)[];

	constructor(private storageKey: string, private childKeys: (string | number)[]) {
		this.getIsDismissedFromStorage();
	}

	@action
	setDismissedState(hidden: boolean): void {
		const storage = LocalStorageHelper.getLocalStorage();
		if (hidden) {
			const dismissedKeys = toJS(this.childKeys);
			storage.setItem(`${TipCarouselState.keyNamespace}.${this.storageKey}`, JSON.stringify(dismissedKeys));
			this.dismissedKeys = dismissedKeys;
		} else {
			storage.setItem(`${TipCarouselState.keyNamespace}.${this.storageKey}`, '');
			this.dismissedKeys = [];
		}
	}

	public isDismissed = (key: string | number) => this.dismissedKeys.some(dismissed => dismissed === key);

	private getIsDismissedFromStorage(): void {
		const storage = LocalStorageHelper.getLocalStorage();
		const storedState = storage.getItem(this.getNamespacedKey());
		if (!storedState) {
			this.dismissedKeys = [];
		} else {
			this.dismissedKeys = JSON.parse(storedState);
		}

		// for compatibility when switching from a wrapped tipbox.
		for (const key of this.childKeys) {
			const legacyKeyName = `Pushpay.TipBoxState.${key}`;
			const legacyValue = storage.getItem(legacyKeyName);
			if (legacyValue === 'true') {
				this.dismissedKeys.push(key);
				storage.removeItem(legacyKeyName);
			}
		}
	}

	private getNamespacedKey(): string {
		return `${TipCarouselState.keyNamespace}.${this.storageKey}`;
	}
}

@observer
export class TipCarousel extends React.Component<ITipCarouselProps, TipCarouselState> {

	constructor(props, state) {
		super(props, state);
		const keys = React.Children.toArray(props.children)
			.filter(child => (child as React.ReactElement<any>).type === CarouselSlide)
			.map(child => (child as React.ReactElement<ICarouselProps>).key);
		this.state = new TipCarouselState(props.storageKey, keys);
	}

	render() {
		const showBoxText = this.props.showTipsLabel || 'help';
		const classNames = `tipbox clearfix ${this.props.extraClassNames}`;
		const children = React.Children.toArray(this.props.children);
		const visibleChildren = children.filter(child => !this.state.isDismissed((child as React.ReactElement<ICarouselProps>).key));
		if (!visibleChildren || visibleChildren.length === 0) {
			return (
				<div className={`${classNames} dismissed`}>
					<button className="btn pull-right btn-show-help" onClick={this.showTipBox}>+ Show {showBoxText}
					</button>
				</div>
			);
		} else {
			return (
				<div className={classNames}>
					<div className={this.getClassNames(this.props.extremeEdge)}>
						{this.renderIcon()}
						<div>
							<Carousel
								slideDelay={this.props.slideDelay}
								disableAnimation={this.props.disableAnimation}
								disableSlidesRotation={this.props.disableSlidesRotation}
							>
								{visibleChildren}
							</Carousel>
						</div>
					</div>
					{this.props.dismissable === false
						? null
						: <button className="btn btn-dismiss" onClick={this.dismissTipBox}>
							Dismiss
							<svg className="svg svg-close-cross">
								<use xlinkHref="#close-cross" />
							</svg>
						</button>
					}
				</div>
			);
		}
	}

	private renderIcon() {
		if (this.hasIcon()) {
			return (
				<div className="tipbox-icon">
					<svg className="svg" width="85" height="85">
						<use xlinkHref={`#${this.props.icon}`}></use>
					</svg>
				</div>
			);
		} else {
			return null;
		}
	}

	private hasIcon(): boolean {
		return (this.props.icon && this.props.icon !== '');
	}

	private getClassNames(extremeEdge: boolean | undefined): string {
		let className = 'tipbox-content';
		if (this.props.icon && this.props.icon !== '') {
			className += ' with-icon';
		}

		if (extremeEdge) {
			className += ' extreme-edge';
		}

		return className;
	}

	private dismissTipBox = () => {
		this.state.setDismissedState(true);
	}

	private showTipBox = () => {
		this.state.setDismissedState(false);
	}

}
