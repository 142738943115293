export interface IQueryBatchesOptions {
	debounceDelay?: number;
	takeAllUpToCurrentPage?: boolean;
	appendResults?: boolean;
}

export namespace BatchListUserAction {
	export class QueryBatches {
		constructor(public options?: IQueryBatchesOptions) { }
	}
	export class DepositBatches {
		constructor(public batchIds: number[]) { }
	}
}

export type BatchListUserAction = typeof BatchListUserAction[keyof typeof BatchListUserAction]['prototype'];
