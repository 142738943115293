import * as React from 'react';
import {observer} from 'mobx-react';
import {responsiveHelper, ResponsiveVisibility, ResponsiveBreakpoint} from '../../helpers/responsive-helper';


// breakpoints can be pipe delimited flag style eg. ResponsiveBreakpoint.xs|ResponsiveBreakpoint.sm|ResponsiveBreakpoint.md|ResponsiveBreakpoint.lg
@observer
export class RenderResponsive extends React.Component<{visibility: ResponsiveVisibility, breakpoints: ResponsiveBreakpoint}, {}> {
	render(): any {
		const { visibility, breakpoints } = this.props;

		if (!responsiveHelper.isVisible(visibility, breakpoints)) {
			return null;
		}

		return this.props.children;
	}
}
