import { ModalDialogCommander } from '../../components/modal-dialog-commander';
import { StandardErrorTitle } from '../../components/modal-dialog-commander';
import { isVirtualTerminalError, VirtualTerminalError } from './virtual-terminal-error';
import { reportError } from '../../utils/error-utils';

export function reportVirtualTerminalError(error: any, customData?: any) {
	if (isVirtualTerminalError(error)) {
		ModalDialogCommander.error(error.userError, StandardErrorTitle);
		return;
	}

	reportError(error, customData);
}

export function handleVirtualTerminalError(errorMessage: string) {
	if (!errorMessage) {
		return;
	}
	throw new VirtualTerminalError(errorMessage);
}
