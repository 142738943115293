import { MigrationDashboardViewModel, Fund, MerchantListing, MigrationSummaryViewModel } from '../../schedule-import-generated';
import { observable, action, computed } from 'mobx';
import { IFormControlSelectOptionProps } from '../../../components/form-controls/form-control-select';
import { getScheduleImportDataService, ScheduleImportApiConfigType, ScheduleImportDataServiceAction } from '../../schedule-import-data-service';
import { IDataServiceActionSubscriber } from '../../../utils/data-service';
import { LocationHistoryHelper, QueryParamConverter } from '../../../helpers/location-history-helper';

export class MigrationSummaryDashboardViewModel {

	@observable selectedListingId?: number;
	@observable selectedFundId?: number;
	@observable summaryViewModel: MigrationSummaryViewModel = null;
	@observable isLoadingData: boolean = true;
	@observable selectedListingName?: string;

	readonly merchantListings: IFormControlSelectOptionProps[];

	readonly funds: IFormControlSelectOptionProps[];

	readonly migrationId: number;

	readonly statusLabelClass: string;

	readonly statusLabelText: string;

	readonly editMigrationUrl: string;

	readonly isMigrationEditable: boolean;

	readonly schedulesDetailsUrl: string;

	readonly uploadSchedulesUrl: string;

	private createMigrationSummarySubscriber: IDataServiceActionSubscriber<ScheduleImportApiConfigType, 'migrationSummary'>;

	private readonly locationHelper: LocationHistoryHelper<MigrationSummaryQueryModel>;

	private readonly stateConverter: MigrationSummaryParamConverter;

	constructor(model: MigrationDashboardViewModel) {

		this.stateConverter = new MigrationSummaryParamConverter();
		this.locationHelper = new LocationHistoryHelper<MigrationSummaryQueryModel>(this.stateConverter, this.handleLocationStateChange);
		const revivedState = this.locationHelper.getState();

		this.merchantListings = this.convertOptions(model.Listings);
		this.funds = this.convertOptions(model.Funds);
		this.selectedFundId = revivedState ? revivedState.SelectedFund || null : null;
		this.selectedListingId = revivedState ? revivedState.SelectedListing || null : null;
		this.migrationId = model.MigrationId;
		this.statusLabelText = model.MigrationStatusText;
		this.statusLabelClass = model.MigrationStatusClassName;
		this.editMigrationUrl = model.EditMigrationUrl;
		this.isMigrationEditable = model.IsMigrationEditable;
		this.schedulesDetailsUrl = model.SchedulesDetailsUrl;
		this.uploadSchedulesUrl = model.UploadMigrationUrl;

		this.createMigrationSummarySubscriber = getScheduleImportDataService().getActionSubscriberFactory('migrationSummary')(action => this.subscribeToMigrationSummary(action));
		this.initMigrationSummaryRequest();
	}

	@action.bound
	merchantListingChangedHandler(listingId: number): void {
		this.selectedListingId = listingId;
		this.updateLocationState();
		this.initMigrationSummaryRequest();
	}

	@action.bound
	fundsChangedHandler(fundId: number): void {
		this.selectedFundId = fundId;
		this.updateLocationState();
		this.initMigrationSummaryRequest();
	}

	@computed
	get listingName(): string {
		return this.selectedListingId ? this.merchantListings.filter(x=>x.Value === this.selectedListingId.toString( 10 ))[0].Label : null;
	}

	@computed
	get fundName(): string {
		return this.selectedFundId ? this.funds.filter(x=>x.Value === this.selectedFundId.toString( 10 ))[0].Label : null;
	}

	private convertOptions(rawOptions: MerchantListing[] | Fund[]): IFormControlSelectOptionProps[] {
		return rawOptions.map(listing => {
			return {
				Label: listing.Name,
				Value: listing.Id.toString( 10 ),
			};
		});
	}

	private initMigrationSummaryRequest() {
		this.isLoadingData = true;
		this.createMigrationSummarySubscriber.initRequest({
			id: this.migrationId,
			fundId: this.selectedFundId,
			merchantId: this.selectedListingId,
		});
	}

	private subscribeToMigrationSummary(action: ScheduleImportDataServiceAction) {
		switch (action.type) {
			case 'request_success':
				const response: MigrationSummaryViewModel = action.response;
				this.summaryViewModel = response;
				this.isLoadingData = false;
				break;
			case 'request_error':
				console.log('error = ', action.error);
				break;
		}
	}

	@action
	private handleLocationStateChange = (newState: MigrationSummaryQueryModel) => {
		if(newState) {
			this.selectedListingId = newState.SelectedListing || null;
			this.selectedFundId = newState.SelectedFund || null;
		} else {
			this.selectedListingId = null;
			this.selectedFundId = null;
		}

		this.initMigrationSummaryRequest();
	}

	private updateLocationState() {
		const newState: MigrationSummaryQueryModel = {};
		if(this.selectedListingId) {
			newState.SelectedListing = this.selectedListingId;
		}
		if(this.selectedFundId) {
			newState.SelectedFund = this.selectedFundId;
		}

		this.locationHelper.pushState(newState);
	}
}

interface MigrationSummaryQueryModel {
	SelectedListing?: number;
	SelectedFund?: number;
}

class MigrationSummaryParamConverter implements QueryParamConverter<MigrationSummaryQueryModel> {
	queryToState(queryString : string): MigrationSummaryQueryModel {
		return queryString.split('&').reduce((acc, cur) => {
			const paramParts = cur.split('=');
			acc[paramParts[0]] = parseInt(paramParts[1], 0);
			return acc;
		}, {} as MigrationSummaryQueryModel);
	}

	stateToQuery(state:MigrationSummaryQueryModel): string {
		const queries: string[]  = [];

		if(state && state.SelectedListing) {
			queries.push(`SelectedListing=${state.SelectedListing}`);
		}

		if(state && state.SelectedFund) {
			queries.push(`SelectedFund=${state.SelectedFund}`);
		}

		return queries.join('&');
	}
}
