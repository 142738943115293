import * as React from 'react';
import { SvgWrapper } from '../svg-wrapper';

export const PayerSearchEditButton = ({ onEditHandler }: { onEditHandler?: () => void }) => {
	return (
		<button type="button" className="btn btn-xs btn-link payer-search-edit-btn " onClick={onEditHandler} data-pp-at-target="payer search edit">
			<SvgWrapper svg="pen" className="icon payer-search-edit-icon" /> Edit
		</button>
	);
};
