export function detectEdge() : boolean {
	if (typeof window !== 'undefined') {
		return window.navigator.userAgent.indexOf('Edge') > -1;
	}
	return false;
}

export function getSafariWebKitVersion() : string {
	if (typeof window !== 'undefined') {
		const { userAgent } = window.navigator;

		if (userAgent.indexOf('Chrome') > -1) {
			return null;
		}

		const userAgentWebKitString = userAgent.split(' ').find(userAgentPart => userAgentPart.indexOf('AppleWebKit') > -1);

		if (!userAgentWebKitString) {
			return null;
		}

		return userAgentWebKitString.split('/')[1];
	}
	return null;
}

export function detectIOS(): boolean {
	if (typeof window !== 'undefined') {
		return !!window.navigator.userAgent && /iPad|iPhone|iPod/.test(window.navigator.userAgent);
	}
	return null;
}
