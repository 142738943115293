import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Fragment } from '../../../../../Shared/components/fragment';
import { ListingDisplay } from './batch-entry-batch-details';
import { BatchEntryBatchDetailsViewModel } from './batch-entry-batch-details-view-model';
import { PaymentTotal } from '../../batch-entry-payments/batch-entry-payments-store';
import { RenderResponsive } from '../../../../components/hoc-behavior/render-responsive';
import { ResponsiveVisibility, ResponsiveBreakpoint } from '../../../../helpers/responsive-helper';
import { BatchStatus, DayOfWeek, VirtualTerminalEventTimeViewModel, BatchViewModel, EventTimeStatus } from '../../../virtual-terminal-generated';
import { BatchEntryDiscrepency } from '../../batch-entry-payments/batch-entry-discrepency';
import { SmoothHeightTransition } from '../../../../components/hoc-behavior/transitions';
import { DefaultSettings } from '../../default-settings/default-settings';
import { BatchEntryUserActionCreator } from '../../../pages/batch-entry/batch-entry-user-actions';
import { SvgWrapper } from '../../../../components/svg-wrapper';
import { Formatter } from '../../../../helpers/formatter';
import { BatchEntryCloseAndExportButton } from '../close-and-export/batch-entry-close-and-export';
import { classNames } from '../../../../../Shared/utils/classnames';
import { recordEvent, WithAnalytics, injectAnalytics } from '../../../../analytics';

import * as styles from './batch-entry-batch-details.less';

const injectUserActionCreator = 'userActionCreator';

@observer
export class BatchEntryBatchDetailsView extends React.Component<{ vm: BatchEntryBatchDetailsViewModel, batchCount: PaymentTotal, batchTotal: PaymentTotal }> {
	render() {
		const { vm, batchCount, batchTotal } = this.props;
		const { batch, listingStore, selectedEventTime, showingDefaultSettings, defaultSettingsViewModel } = vm;
		const { ExpectedTotalAmount, ExpectedTotalCount, Status } = batch;
		const { selectedListing: { ListingName }, paymentLabel: { NounLowerCase, NounPluralLowerCase } } = listingStore;
		const allowDeleteBatch = batchCount.fetched && batchCount.total === 0;
		return (
			<div>
				<header className={styles.header}>
					<div>
						<div>Batch <strong>#{batch.BatchId}</strong></div>
						<div className={styles.titleDisplay}>
							{batch.Name}
							<BatchStatusLabel batchStatus={Status} />
						</div>
						<div className={classNames(styles.titleButtons, 'hidden-print')}>
							{batch.Status === BatchStatus.Open && <BatchOpenButtons batch={batch} allowDeleteBatch={allowDeleteBatch} />}
							{batch.Status === BatchStatus.Completed && <BatchCompletedButtons batch={batch} />}
							{batch.Status === BatchStatus.Deposited && <BatchDepositedButtons />}
						</div>
					</div>
					<div className="hidden-print">
						<BatchEntryCloseAndExportButton batch={batch} />
					</div>
				</header>
				<div className="panel panel-highlight">
					<header className="panel-heading">
						<div className="display-flex">
							<RenderResponsive visibility={ResponsiveVisibility.Hidden} breakpoints={ResponsiveBreakpoint.Xs}>
								<div className="flex-auto">
									<ListingDisplay listingName={ListingName} cssClassNames={styles.listingHeading} />
									<EventTimeDisplay eventTime={selectedEventTime} />
								</div>
							</RenderResponsive>
							<div className="flex-none">
								<BatchEntryDiscrepency batchTotal={batchTotal}
									expectedTotal={ExpectedTotalAmount}
									batchCount={batchCount}
									expectedCount={ExpectedTotalCount}
									paymentNoun={NounLowerCase}
									paymentNounPlural={NounPluralLowerCase} />
							</div>
						</div>
					</header>
					<RenderResponsive visibility={ResponsiveVisibility.Visible} breakpoints={ResponsiveBreakpoint.Xs}>
						<div className={`panel-body ${styles.panelBody}`}>
							<ListingDisplay listingName={ListingName} cssClassNames={styles.listingHeading} />
						</div>
						<div className={`panel-body ${styles.panelBody}`}>
							<span className={styles.fieldTitle}>Service</span>
							<EventTimeDisplay eventTime={selectedEventTime} />
						</div>
						<BatchHistoryMobile batch={batch} />
					</RenderResponsive>
					<div className={`panel-body ${styles.panelBody} ${styles.historyPanel}`}>
						<RenderResponsive visibility={ResponsiveVisibility.Hidden} breakpoints={ResponsiveBreakpoint.Xs}>
							<BatchHistory batch={batch} />
						</RenderResponsive>
						{batch.Status === BatchStatus.Open && <ShowDefaultButton vm={vm} />}
					</div>
					<SmoothHeightTransition component="div">
						{showingDefaultSettings &&
							<div className={`panel-body panel-expander ${styles.panelBody} ${styles.defaultSettings}`}>
								<DefaultSettings vm={defaultSettingsViewModel} />
							</div>
						}
					</SmoothHeightTransition>
				</div>
			</div>
		);
	}
}

@injectAnalytics
@inject(injectUserActionCreator)
@observer
class BatchOpenButtons extends React.Component<WithAnalytics & { batch: BatchViewModel, allowDeleteBatch: boolean, userActionCreator?: BatchEntryUserActionCreator }> {
	render() {
		const { allowDeleteBatch } = this.props;
		return (
			<Fragment>
				<button type="button" onClick={this.handleStartEdit} className="btn btn-link btn-sm btn-vertically-centered" data-pp-at-target="Edit batch">
					<SvgWrapper svg="icon-edit" className="icon" />
					Edit batch
				</button>
				{
					allowDeleteBatch &&
					<button type="button" onClick={this.handleDelete} className="btn btn-link btn-sm btn-vertically-centered" data-pp-at-target="Delete batch">
						<SvgWrapper svg="icon-delete" className="icon" />
						Delete batch
					</button>
				}
			</Fragment>
		);
	}

	private handleStartEdit = () => {
		const { userActionCreator, analytics } = this.props;

		if (NewFeatures.SetupPinpointAnalytics && analytics) {
			const { feature, subFeature } = analytics;
			recordEvent({ feature, subFeature, eventTypeLabel: 'editBatch' });
		}

		userActionCreator.StartEditBatch();
	}

	private handleDelete = () => {
		const { userActionCreator, batch } = this.props;
		userActionCreator.DeleteBatch(batch.ListingId, batch.BatchId);
	}
}

@inject(injectUserActionCreator)
@observer
class BatchCompletedButtons extends React.Component<{ batch: BatchViewModel, userActionCreator?: BatchEntryUserActionCreator }> {
	render() {
		return (
			<Fragment>
				<button type="button" onClick={this.handleReopen} className="btn btn-link btn-sm btn-vertically-centered" data-pp-at-target="Reopen batch">
					<SvgWrapper svg="icon-reopen-fund" className="icon" />
					Reopen batch
				</button>
				<button type="button" onClick={this.handlePrint} className="btn btn-link btn-sm btn-vertically-centered">
					<SvgWrapper svg="icon-print" className="icon" />
					Print deposit slip
				</button>
			</Fragment>
		);
	}

	private handleReopen = () => {
		const { userActionCreator, batch } = this.props;
		userActionCreator.ReopenBatch(batch.ListingId, { ...batch });
	}

	private handlePrint = () => {
		this.props.userActionCreator.PrintDepositSlip();
	}
}

@inject(injectUserActionCreator)
@observer
class BatchDepositedButtons extends React.Component<{ userActionCreator?: BatchEntryUserActionCreator }> {
	render() {
		return (
		<Fragment>
			<button type="button" onClick={this.handlePrint} className="btn btn-link btn-sm btn-vertically-centered">
				<SvgWrapper svg="icon-print" className="icon" />
				Print deposit slip
			</button>
		</Fragment>);
	}

	private handlePrint = () => {
		this.props.userActionCreator.PrintDepositSlip();
	}
}

const EventTimeDisplay = ({ eventTime }: { eventTime: VirtualTerminalEventTimeViewModel }) => {
	if (eventTime === null) {
		return null;
	}
	const archivedLabel = eventTime.Status === EventTimeStatus.Archived ? ' (Archived)' : '';
	return (
		<div>
			<span className={styles.responsiveBold}>{DayOfWeek[eventTime.DayOfWeek]}, {eventTime.StartTime} - {eventTime.EndTime}</span> ({eventTime.Name}) {archivedLabel}
		</div>
	);
};

@observer
class BatchHistoryMobile extends React.Component<{ batch: BatchViewModel }> {
	render() {
		const { batch } = this.props;
		const { CreatedBy, CreatedOn, LastUpdatedBy, LastUpdatedOn } = batch;
		const showUpdated = !!LastUpdatedOn;
		return (
			<Fragment>
				<div className={`panel-body ${styles.panelBody}`}>
					<span className={styles.fieldTitle}>Created</span>
					<div>
						<BatchCreated createdBy={CreatedBy} createdOn={CreatedOn} />
						{!showUpdated && <span> <ViewHistoryButton batch={batch} /></span>}
					</div>
				</div>
				{
					showUpdated &&
					<div className={`panel-body ${styles.panelBody}`}>
						<span className={styles.fieldTitle}>Updated</span>
						<div>
							<BatchUpdated updatedBy={LastUpdatedBy} updatedOn={LastUpdatedOn} />
							<span> <ViewHistoryButton batch={batch} /></span>
						</div>
					</div>
				}
			</Fragment>
		);
	}
}

const BatchCreated = ({ createdBy, createdOn}: { createdBy: string, createdOn: Date}) => {
	return (
		<span>Batch created by {createdBy} on <span className={styles.responsiveBold}>{Formatter.formatDate(createdOn, 'MMM D, YYYY hh:mm a')}</span></span>
	);
};

const BatchUpdated = ({ updatedBy, updatedOn }:{ updatedBy: string, updatedOn: Date }) => {
	return (
		<span>Last updated by { updatedBy} on <span className={styles.responsiveBold}>{Formatter.formatDate(updatedOn, 'MMM D, YYYY hh:mm a')}</span>.</span>
	);
};

@inject(injectUserActionCreator)
@observer
class ViewHistoryButton extends React.Component<{ batch: BatchViewModel, userActionCreator?: BatchEntryUserActionCreator }> {
	render() {
		return (
			<button type="button" onClick={this.handleViewHistory} className="btn btn-link btn-sm btn-link-inline hidden-print">View history</button>
		);
	}

	private handleViewHistory = () => {
		const { batch: { ListingId, BatchId }, userActionCreator } = this.props;
		userActionCreator.ViewHistory(ListingId, BatchId);
	}
}

@observer
class BatchHistory extends React.Component<{ batch: BatchViewModel }> {
	render() {
		const { batch } = this.props;
		const { CreatedBy, CreatedOn, LastUpdatedBy, LastUpdatedOn } = batch;
		return (
			<div className="flex-auto">
				<BatchCreated createdBy={CreatedBy} createdOn={CreatedOn} />
				{ LastUpdatedOn && <span>. <BatchUpdated updatedBy={LastUpdatedBy} updatedOn={LastUpdatedOn} /></span> }
				<span> </span>
				<ViewHistoryButton batch={batch} />
			</div>
		);
	}
}

const ShowDefaultButton = ({ vm }: { vm: BatchEntryBatchDetailsViewModel }) => {
	const { showingDefaultSettings, showDefaultSettings, hideDefaultSettings } = vm;
	const text = showingDefaultSettings ? 'Hide default' : 'Show default';
	const clickHandler = showingDefaultSettings ? hideDefaultSettings : showDefaultSettings;
	return (
		<button type="button" className={`btn btn-link ${styles.showDefault}`} onClick={clickHandler}>
			<SvgWrapper svg="icon-xs-gear" className="icon icon-xs" />
			{text}
		</button>
	);
};

interface IBatchStatusLabelProps {
	className: string;
	text: string;
}

const BatchStatusLabel = ({ batchStatus }: { batchStatus: BatchStatus }) => {
	const props = getBatchStatusLabelProps(batchStatus);
	return(
		<span className={classNames('label', styles.statusLabel, props.className)}>{props.text}</span>
	);
};

function getBatchStatusLabelProps(batchStatus: BatchStatus): IBatchStatusLabelProps {
	switch(batchStatus) {
		case BatchStatus.Open:
			return { className: 'label-inprogress', text: BatchStatus[BatchStatus.Open] };
		case BatchStatus.Completed:
			return { className: 'label-success', text: BatchStatus[BatchStatus.Completed] };
		case BatchStatus.Deposited:
			return { className: 'label-success', text: BatchStatus[BatchStatus.Deposited] };
		default:
			const unsupported: never = batchStatus;
			throw new Error(`BatchStatus ${unsupported} is unsupported`);
	}
}
