import * as React from 'react';
import { classNames } from '../../../Shared/utils/classnames';

import * as styles from './bubble.less';

type BubblePlacement = 'left' | 'right';

export class Bubble extends React.Component<{ onCancel: () => void, placement: BubblePlacement }> {

	componentDidMount() {
		document.addEventListener('click', this.onClickAway);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.onClickAway);
	}

	render() {
		return (
			<div onClick={this.onClick}
				className={classNames(styles.container, this.props.placement === 'left' ? styles.left : styles.right)}>
				{this.props.children}
			</div>
		);
	}

	private onClick(e) {
		e.stopPropagation();
	}

	private onClickAway = (e) => {
		if (!(e.target as HTMLElement).closest(`.${styles.container}`)) {
			this.props.onCancel();
		}
	}
}
