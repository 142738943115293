export class ArrayHelper {
	static equals<T>(a: Array<T>, b: Array<T>) {
		if ((a === null || a === undefined) && (b === null || b === undefined)) {
			return true;
		}

		if (a === null || a === undefined || b === null || b === undefined) {
			return false;
		}

		let i = a.length;

		if (i !== b.length) {
			return false;
		}

		while (i--) {
			if (a[i] !== b[i]) {
				return false;
			}
		}
		return true;
	}

	static contains<T>(array: Array<T>, element: T) : boolean {
		return array.indexOf(element) !== -1;
	}

	static selectMany<T>(arrays: Array<Array<T>>) : Array<T> {
		return [].concat(...arrays);
	}

	static emptyIfNull<T>(array: Array<T>) : Array<T> {
		return array || [];
	}

	static arrayToMap<T>(array: T[], key: keyof T) {
		return array.reduce((map, item) => {
			map[item[key as string]] = item;
			return map;
		}, {} as { [k: string]: T });
	}
}
