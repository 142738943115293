import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { DataGrid, IDataGridColumn } from '../../../components/data-grid/data-grid';
import { RecentGiftsGridViewModel } from './recent-gifts-grid-view-model';
import { RecentGiftEntryViewModel, GetRecentGiftEntryType } from '../../virtual-terminal-generated';
import { ResponsiveVisibility, ResponsiveBreakpoint } from '../../../helpers/responsive-helper';
import { RecentGiftsPaymentDetails } from './recent-gifts-payment-details';
import { Channel } from '../../../utils/saga-utils';
import { VirtualTerminalUserAction } from '../../pages/single/single-entry-user-actions';
import { formatNullableAmountColumn, formatPaymentIconColumn, formatEditColumn, formatPayerColumn } from '../../utils/grid-helpers';

export function getRecentGiftsGridColumnConfig() {
	return {
		PaymentStatus: { displayName: 'Status', formatter: formatPaymentIconColumn },
		PayerDisplayName: { displayName: 'Giver', formatter: formatPayerColumn },
		PayerDisplayEmail: {
			displayName: 'Email address',
			responsiveVisibility: ResponsiveVisibility.Hidden,
			responsiveBreakpoints: ResponsiveBreakpoint.Xs|ResponsiveBreakpoint.Sm
		},
		PaymentMethod: {
			displayName: 'Method',
			responsiveVisibility: ResponsiveVisibility.Hidden,
			responsiveBreakpoints: ResponsiveBreakpoint.Xs
		},
		FundDisplayValue: { displayName: 'Funds' },
		AmountAsMoney: { displayName: 'Amount', formatter: formatNullableAmountColumn },
	};
}

@inject('userActionChannel')
@observer
export class RecentGiftsGrid extends React.Component<{ vm: RecentGiftsGridViewModel, userActionChannel?: Channel<VirtualTerminalUserAction> }, {}> {
	private columns: { [key: string]: IDataGridColumn };

	constructor(props) {
		super(props);

		this.columns = getRecentGiftsGridColumnConfig();
		Object.assign(this.columns, { EditUrl: {
			displayName: '',
			formatter: (item, classNames) => formatEditColumn(item, classNames, this.props.vm.paymentLabel.NounLowerCase)
		}});
	}

	render() {
		const { vm } = this.props;
		const { sortedGifts, paymentLabel, selectedListingId, canLoadMore } = vm;

		Object.assign(this.columns, {
			PayerDisplayName: {
				displayName: paymentLabel && paymentLabel.PayerSentenceCase || '',
				formatter: formatPayerColumn
			}
		});

		return (
			<div className="vt-payments-grid" data-pp-at-target="Gifts table">
				<DataGrid
					key={selectedListingId}
					columns={this.columns}
					data={sortedGifts}
					getRowKey={this.getRowKey}
					renderExpandedRow={this.renderPaymentDetails} />
				<div className="list-group-load-more">
					<button onClick={this.loadMore} disabled={!canLoadMore} className="btn btn-ghost">Load More</button>
				</div>
			</div>
		);
	}

	private renderPaymentDetails = (data: RecentGiftEntryViewModel, updateExpandedRow: (key: string) => void) => (
		<RecentGiftsPaymentDetails vm={this.props.vm} gift={data} updateExpandedRow={updateExpandedRow} />
	)

	private getRowKey = (data: RecentGiftEntryViewModel) => data.EncodedToken;

	private loadMore = () => {
		this.props.userActionChannel.put(new VirtualTerminalUserAction.FetchRecentGifts(GetRecentGiftEntryType.LoadMore));
	}
}
