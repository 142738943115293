import * as React from 'react';
import {SvgWrapper} from '../components/svg-wrapper';

export interface IHintProps {
	text: string;
	additionalClasses?: string;
}

export class Hint extends React.Component<IHintProps, {}> {
	render() {
		return (
			<div className={`hint-component${this.props.additionalClasses ? ` ${this.props.additionalClasses}` : ``}`}>
				<SvgWrapper svg="handwriting-arrow" className="svg" />
				<span>{this.props.text}</span>
			</div>
		);
	}
}
