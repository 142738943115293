import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { CustomSelect, CustomSelectItem, getPrefixedCustomSelectId } from './custom-select';
import { getUniqueElementId } from './form-controls/form-control-utils';
import { isFunction } from '../utils/is-function';
import { classNames } from '../../Shared/utils/classnames';
import { Fragment } from '../../Shared/components/fragment';

export interface IAvailableFrequencyOptionProps {
	Label: string;
	Value: string;
}

export interface IGivingFrequencySelectorWithLabelProps {
	selectedFrequencyValue: string;
	availableFrequencies: IAvailableFrequencyOptionProps[];
	onChange: (frequencyValue: string) => void;
	acceptanceTestTargetId?: string;
	isUnavailable?: boolean;
	className?: string;
	componentId?: string;
	singleLine?: boolean;
	childrenClassName?: string;
	labelForGivingFrequencySelection?: string;
	isDisabled?: boolean;
}

export class GivingFrequencySelectorWithLabel extends React.Component<
	IGivingFrequencySelectorWithLabelProps,
	{ open: boolean }
> {
	private componentId = this.props.componentId || getUniqueElementId();
	private givingFrequencySelector: GivingFrequencySelector;
	constructor(props) {
		super(props);
		this.state = { open: false };
	}
	render() {
		const {
			className,
			children,
			availableFrequencies,
			singleLine,
			childrenClassName,
			labelForGivingFrequencySelection = 'Select recurring frequency',
			...rest
		} = this.props;
		return (
			<div
				className={classNames(
					'form-control-labelled form-group',
					singleLine ? 'listing-selector-container' : 'listing-selector-multiline-container',
					className
				)}
			>
				{availableFrequencies.length > 1 ? (
					<Fragment>
						<label
							onClick={this.handleLabelClick}
							htmlFor={getPrefixedCustomSelectId(this.componentId)}
							className={classNames(
								childrenClassName,
								'form-control-label',
								'listing-selector-label',
								!singleLine && 'listing-selector-multiline-child'
							)}
						>
							{labelForGivingFrequencySelection}
						</label>
						<div
							className={classNames(
								childrenClassName,
								'listing-selector',
								!singleLine && 'listing-selector-multiline-child'
							)}
						>
							<GivingFrequencySelector
								componentId={this.componentId}
								availableFrequencies={availableFrequencies}
								{...rest}
								ref={(givingFrequencySelector) => {
									this.givingFrequencySelector = givingFrequencySelector;
								}}
							/>
						</div>
					</Fragment>
				) : (
					<Fragment>
						<label
							className={classNames(
								childrenClassName,
								'form-control-label',
								'listing-selector-label',
								!singleLine && 'listing-selector-multiline-child'
							)}
						>
							Listing
						</label>
						<div
							className={classNames(
								childrenClassName,
								'listing-name',
								!singleLine && 'listing-selector-multiline-child'
							)}
						>
							{availableFrequencies[0].Label}
						</div>
					</Fragment>
				)}
			</div>
		);
	}

	private handleLabelClick = (event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault();
		(ReactDOM.findDOMNode(this.givingFrequencySelector).firstChild as HTMLElement).focus();
	};
}

interface IGivingFrequencySelectorProps {
	selectedFrequencyValue: string;
	availableFrequencies: IAvailableFrequencyOptionProps[];
	onChange: (frequencyValue: string) => void;
	acceptanceTestTargetId?: string;
	componentId: string;
	isUnavailable?: boolean;
	isDisabled?: boolean;
}

class GivingFrequencySelector extends React.Component<IGivingFrequencySelectorProps, { open: boolean }> {
	state = { open: false };

	render() {
		const { selectedFrequencyValue, componentId, isUnavailable, isDisabled } = this.props;
		return (
			<CustomSelect
				componentId={componentId}
				open={this.state.open}
				selectedItem={selectedFrequencyValue}
				onItemSelected={this.handleItemSelected}
				onRequestOpen={this.handleRequestOpen}
				onRequestClose={this.handleRequestClose}
				acceptanceTestTargetId={this.props.acceptanceTestTargetId}
				isUnavailable={isUnavailable}
				isDisabled={isDisabled}
			>
				{this.props.availableFrequencies.map((x) => (
					<CustomSelectItem key={x.Value} value={x.Value} displayLabel={x.Label} />
				))}
			</CustomSelect>
		);
	}

	handleItemSelected = (value: string) => {
		const { onChange } = this.props;
		this.setState({ open: false });

		if (isFunction(onChange)) {
			onChange(value);
		}
	};

	handleRequestOpen = () => {
		this.setState({ open: true });
	};

	handleRequestClose = () => {
		this.setState({ open: false });
	};
}
