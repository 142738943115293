import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { isFunction } from '../../utils/is-function';
import { shallowEqual } from '../../helpers/shallowequal';
import { validation } from '../../validation/validation';
import { observer } from 'mobx-react';
import { injectValidationFormContext, IHaveValidatableFormContext, getFieldError } from './validatable-form-context';
import { IValidationRules } from '../../validation/validation-rules';

export interface IValidationRuleProps {
	validationRules?: IValidationRules;
	validateOnChange?: boolean;
	revalidateOnChange?: boolean;
	validateOnBlur?: boolean;
	revalidateOnBlur?: boolean;
}

export interface IValidatableControlProps extends IValidationRuleProps, IHaveValidatableFormContext {
	elementName: string;
	isInvalid?: boolean;
}

function hasValidationRules(props: IValidatableControlProps): boolean {
	return props.validationRules && Object.keys(props.validationRules).length > 0;
}

/**
 * Adds validation attributes to the child and keeps jQuery validation in sync with the props
 *
 * @export
 * @class ValidatableControl
 * @extends {React.Component<IValidatableControlProps, {}>}
 */
@injectValidationFormContext
@observer
export class ValidatableControl extends React.Component<IValidatableControlProps, {}> {
	render() {
		const { children, validationRules, elementName, isInvalid } = this.props;
		const child = React.Children.only(children) as React.ReactElement;

		if (hasValidationRules(this.props)) {
			const error = getFieldError(this.props, elementName);
			return React.cloneElement(child, {
				...validation.validationAttributesForProperty(validationRules),
				onChange: this.handleOnChange,
				onBlur: this.handleOnBlur,
				className: `${child.props.className}${error || isInvalid ? ' input-validation-error' : ''}`
			});
		}

		return child;
	}

	componentDidMount() {
		this.attachValidationToFields();
	}

	componentDidUpdate(prevProps: IValidatableControlProps) {
		const { validationRules } = this.props;
		if (!hasValidationRules(this.props)) {
			this.detachValidationFromFields();
		}

		if (!shallowEqual(prevProps.validationRules, validationRules)) {
			this.attachValidationToFields();
		}

	}

	componentWillUnmount() {
		this.detachValidationFromFields();
	}

	private attachValidationToFields = () => {
		validation.attachElementValidation(ReactDOM.findDOMNode(this) as Element);
	}

	private detachValidationFromFields = () => {
		validation.detachElementValidation(ReactDOM.findDOMNode(this) as Element);
	}

	private handleOnChange = (event) => {
		const { children, validatableFormContext, validateOnChange, revalidateOnChange, elementName } = this.props;
		const child = React.Children.only(children) as React.ReactElement<{ onChange: (event: any) => void }>;

		if (isFunction(child.props.onChange)) {
			child.props.onChange(event);
		}

		if (validatableFormContext) {
			if (validateOnChange) {
				validatableFormContext.validateElement(elementName);
			} else if (revalidateOnChange) {
				validatableFormContext.revalidateElement(elementName);
			}
		}
	}

	private handleOnBlur = (event) => {
		const { validateOnBlur, revalidateOnBlur, children, validatableFormContext, elementName } = this.props;
		const child = React.Children.only(children) as React.ReactElement<{ onBlur: (event: any) => void }>;

		if (validatableFormContext) {
			if (validateOnBlur) {
				validatableFormContext.validateElement(elementName);
			} else if (revalidateOnBlur) {
				validatableFormContext.revalidateElement(elementName);
			}
		}

		if (isFunction(child.props.onBlur)) {
			child.props.onBlur(event);
		}
	}
}
