import { action, computed, observable } from 'mobx';
import {
	CommunityMemberFundStatsResponse,
	ConfigureRunViewModel,
	CustomGivingStatementsViewModel,
	GivingStatementsPeriodType,
	PaymentLabel,
	GivingStatementRunStatus,
	GivingStatementsApiConfig,
	SendStatementsViewModel,
	GivingUnitType
} from './giving-statements-generated';
import { StepStatusStep } from '../components/step-status';
import { MachineContext } from '../../Shared/state-machine/saga-state-machine';
import { ConfigurePageViewModel } from './pages/configure/configure-page-view-model';
import { LandingPageViewModel } from './pages/landing-page/landing-page-view-model';
import { PreviewPageViewModel } from './pages/preview/preview-page-view-model';
import { SendStatementsPageViewModel } from './pages/send-statements/send-statements-page-view-model';
import { urlService } from './services/url';

export enum ConfigurationStep {
	Configure = 1,
	Generate = 2,
	Send = 3,
}

export const configurationSteps: StepStatusStep<ConfigurationStep>[] = [
	{ key: ConfigurationStep.Configure, label: 'Configure your statements' },
	{ key: ConfigurationStep.Generate, label: 'Generate your statements' },
	{ key: ConfigurationStep.Send, label: 'Publish your statements' },
];

export class GivingStatementsViewModel {
	sentSummaryRetrievalErrorCount: number = 0;
	paymentLabel: PaymentLabel;
	capUrl: string;

	@observable landingPageViewModel: LandingPageViewModel;

	@observable configurePageViewModel: ConfigurePageViewModel;

	@observable previewPageViewModel: PreviewPageViewModel;

	@observable sendPageViewModel: SendStatementsPageViewModel;

	@observable currentConfigurationId: number | null = null;

	@observable currentConfiguration: GivingStatementConfigurationViewModel | null = null;

	@observable communityMemberFundStats: CommunityMemberFundStatsResponse = {
		CommunityMemberTotal: 0,
		CommunityMembersWithNoEmail: 0,
		CommunityMembersWithNoPhysicalAddress: 0,
		DisabledForCampusOrg: false,
	};

	private stateMachineContext: MachineContext;

	constructor(vm: CustomGivingStatementsViewModel) {
		this.paymentLabel = vm.PaymentLabel;
		this.capUrl = vm.CapUrl;
		// The query parameter is deleted when the landing page is initialised, so we need preserve the value on construction.
		const allCommunityMembersAreReviewed = urlService.hasParameter('allCommunityMembersAreReviewed');

		this.landingPageViewModel = new LandingPageViewModel(
			vm.CommunityMemberUri,
			vm.TransactionImportUri,
			vm.FundsUri,
			vm.GiftEntryUri,
			this.paymentLabel,
			allCommunityMembersAreReviewed,
		);
		this.configurePageViewModel = new ConfigurePageViewModel(this, vm.FundsUri, vm.PaymentLabel);
		this.previewPageViewModel = new PreviewPageViewModel(this, vm.PaymentLabel);
	}

	setMachineContext(context: MachineContext) {
		this.stateMachineContext = context;
	}

	@action.bound
	setCurrentConfigurationId(id: number) {
		this.currentConfigurationId = id;
	}

	@action.bound
	setCurrentConfiguration(vm: ConfigureRunViewModel) {
		this.currentConfiguration = new GivingStatementConfigurationViewModel(vm);
	}

	@action.bound
	updateSendStatementsViewModels(vm: SendStatementsViewModel) {
		this.sendPageViewModel = new SendStatementsPageViewModel(vm, this);
	}

	@action.bound
	setCommunityMemberFundStats(communityMemberFundStats: CommunityMemberFundStatsResponse) {
		this.communityMemberFundStats = communityMemberFundStats;
	}

	@computed get previewUri() {
		let baseUrl;

		// `GivingStatementsApiConfig.defaultBaseUrl()` will throw an error if this is run in storybook, as the location
		// will not match `pushpay/portal`. So we will need to catch this, and set it to something.
		try {
			baseUrl = GivingStatementsApiConfig.defaultBaseUrl();
		} catch (error) {
			baseUrl = '#';
		}

		return `${baseUrl}/custom-giving-statements/${this.currentConfigurationId}/preview`;
	}
}

export class GivingStatementConfigurationViewModel {
	@observable periodTypes: string[];
	@observable availableQuarters: number[];
	@observable availableYears: number[];
	@observable selectedType: GivingStatementsPeriodType;
	@observable statementFromDate: Date;
	@observable statementToDate: Date;
	@observable phoneNumber: string;
	@observable emailAddress: string;
	@observable statementName: string;
	@observable currentDate: Date;
	@observable status: GivingStatementRunStatus;
	@observable nonCashAvailable: boolean;
	@observable nonCashIncluded: boolean;
	@observable einIncluded: boolean;
	@observable customerAddressIncluded: boolean;
	@observable ein: string;
	@observable customerAddress: string;
	@observable hasBeenGenerated: boolean;
	@observable hasBeenPublished: boolean;
	@observable publishType: string;
	@observable contributionsThreshold: number;

	constructor(vm: ConfigureRunViewModel) {
		this.periodTypes = vm.PeriodTypes;
		this.availableQuarters = vm.AvailableQuarters;
		this.availableYears = vm.AvailableYears;
		this.selectedType = vm.SelectedType;
		this.statementFromDate = vm.SelectedPeriodFrom;
		this.statementToDate = vm.SelectedPeriodTo;
		this.phoneNumber = vm.PhoneNumer;
		this.emailAddress = vm.EmailAddress;
		this.statementName = vm.StatementName;
		this.currentDate = vm.CurrentDate;
		this.status = vm.Status;
		this.nonCashAvailable = vm.NonCashAvailable;
		this.nonCashIncluded = vm.NonCashIncluded;
		this.einIncluded = vm.EinIncluded;
		this.customerAddressIncluded = vm.CustomerAddressIncluded;
		this.ein = vm.Ein;
		this.customerAddress = vm.CustomerAddress;
		this.hasBeenGenerated = vm.HasBeenGenerated;
		this.hasBeenPublished = vm.HasBeenPublished;
		this.publishType = GivingUnitType[vm.GivingUnitType];
		this.contributionsThreshold = vm.ContributionsThreshold;
	}

	@action.bound
	setStatus(status: GivingStatementRunStatus) {
		this.status = status;
	}
}
