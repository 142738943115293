import { IVirtualTerminalPayerViewModel } from '../../components/payer-search/payer-view-model';
import { PaymentMethod } from '../../components/payment-entry/payment-entry-model';
import { GetRecentGiftEntryType } from '../../virtual-terminal-generated';

export namespace VirtualTerminalUserAction {
	export class ListingChange {
		constructor(public selectedListingId: string) { }
	}

	export class OmniboxValueChange {
		constructor(public value: string) { }
	}

	export class SelectedPayerChange {
		constructor(public payer: IVirtualTerminalPayerViewModel) { }
	}

	export class SelectAnonymousPayer {
	}

	export class LoadMorePayers {
	}

	export class ViewAllExistingPaymentMethods {
	}

	export class ResetForm {
	}

	export class SubmitForm {
	}

	export class RemovePaymentMethod {
		constructor(public paymentMethod: PaymentMethod) { }
	}

	export class RemovePaymentMethodConfirmed {
	}

	export class RemovePaymentMethodConfirmationClosed {
	}

	export class FetchRecentGifts {
		constructor(public getRecentGiftEntryType: GetRecentGiftEntryType) { }
	}
}

export type VirtualTerminalUserAction = typeof VirtualTerminalUserAction[keyof typeof VirtualTerminalUserAction]['prototype'];
