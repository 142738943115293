import * as React from 'react';
import { observer } from 'mobx-react';
import { TransitionGroup } from 'react-transition-group-v1';
import { BatchListMainViewModel, IBatchesBasedOn } from '../../../pages/batch-list/batch-list-main-view-model';
import { BatchListUserAction } from '../../../pages/batch-list/batch-list-user-actions';
import { MultiSelectItem } from '../../../../loggedinweb-generated';
import { Fragment } from '../../../../../Shared/components/fragment';
import { ButtonRefresh, ButtonRefreshLoading } from '../../../../components/button-refresh/button-refresh';
import { SvgWrapper } from '../../../../components/svg-wrapper';
import { classNames } from '../../../../../Shared/utils/classnames';
import { BatchListFilterPanel } from './batch-list-filter-panel';
import { responsiveHelper } from '../../../../helpers/responsive-helper';
import { SortOrder, BatchStatus } from '../../../../virtual-terminal/virtual-terminal-generated';
import { FormControlCheckbox } from '../../../../components/form-controls/form-control-checkbox/form-control-checkbox';
import { ModalDialogCommander, IDialogOptions } from '../../../../components/modal-dialog-commander';
import { DepositBatchModalDialog } from '../batch-list-deposit-batch-dialog';
import { SortBy } from '../../../../components/sort-by/sort-by';

import * as styles from './batch-list-header.less';

@observer
export class BatchListHeader extends React.Component<{ vm: BatchListMainViewModel }, { filtersOpen: boolean }> {
	private sortByOptions = [
		option('Oldest first', SortOrder.CreatedOnAsc),
		option('Newest first', SortOrder.CreatedOnDesc),
		option('Batch name (A - Z)', SortOrder.BatchNameAsc),
		option('Batch name (Z - A)', SortOrder.BatchNameDesc),
		option('Highest amount', SortOrder.TotalAmountDesc),
		option('Lowest amount', SortOrder.TotalAmountAsc)
	];

	constructor(props) {
		super(props);

		this.state = {
			filtersOpen: false,
		};
	}

	render() {
		const { filtersOpen } = this.state;
		const {
			batchesBasedOn,
			selectedListingIds,
			searchTerm,
			selectedServiceTimeIdsAsStrings,
			sortBy,
			viewData: { Listings, ServiceTimes },
			raiseAction,
			changeSearch,
			changeSelectedListings,
			changeSelectedServiceTimes,
			requestInProgress,
			resetPage,
			showingBatchStatus,
			allBatchesSelectedToBeDeposited,
			selectAllLoadedBatches,
			hasSelectedBatchesToBeDeposited,
		} = this.props.vm;
		const filtersVisible = (responsiveHelper.isXs || responsiveHelper.isSm) ? filtersOpen : true;
		const canDepositBatch = showingBatchStatus === BatchStatus.Completed;

		return (
			<header className={`list-group-heading ${styles.container}`}>
				<TransitionGroup component="div">
					{filtersVisible &&
						<div className={classNames('row', styles.filters, 'panel', 'panel-default', 'panel-expander')}>
							<BatchListFilterPanel
								raiseAction={raiseAction}
								listings={Listings}
								searchTerm={searchTerm}
								selectedListingIds={selectedListingIds}
								serviceTimes={ServiceTimes}
								selectedServiceTimeIds={selectedServiceTimeIdsAsStrings}
								onChangeSearch={changeSearch}
								onSelectListings={changeSelectedListings}
								onSelectServiceTimes={changeSelectedServiceTimes}
								resetPage={resetPage}
							/>
						</div>}
				</TransitionGroup>
				<div className={styles.filterSummaryControlsRow}>
					<p className={`lead ${styles.filterSummary}`}>
						<SelectedFiltersSummary
							batchesBasedOn={batchesBasedOn}
							listings={Listings}
							serviceTimes={ServiceTimes}
						/>
					</p>
					<div className={styles.controls}>
						<button
							type="button"
							className={`btn btn-sm btn-link visible-sm visible-xs ${styles.controlItem}`}
							onClick={this.handleToggleFilters}
						>
							<SvgWrapper svg="filter" className="icon icon-xs" title="Refresh batches" />Filter
						</button>
						<div className={styles.controlItem}>
							<SortBy
								selectedValue={sortBy}
								options={this.sortByOptions}
								onChangeHandler={this.handleSort}
							/>
						</div>
						{requestInProgress
							? <ButtonRefreshLoading cssClassNames={`btn btn-sm btn-link ${styles.buttonRefresh} ${styles.controlItem}`} />
							: <ButtonRefresh cssClassNames={`btn btn-sm btn-link ${styles.buttonRefresh} ${styles.controlItem}`} onRefreshBatches={this.handleRefresh} />
						}
					</div>
				</div>
				{
					canDepositBatch && batchesBasedOn.numBatches > 0 &&
					<div className={styles.depositRow}>
						<div className={`lead ${styles.depositRowCheckbox}`}>
							<FormControlCheckbox acceptanceTestTargetId="Select All Batches"
								containerClassNames="checkbox-green"
								onChangeHandler={selectAllLoadedBatches}
								value={allBatchesSelectedToBeDeposited}
								text="Select all batches to be deposited" />
						</div>
						<div className={styles.depositSelectedBatches}>
							<button onClick={this.raiseDepositBatchesDialog}
								disabled={!hasSelectedBatchesToBeDeposited}
								data-pp-at-target="deposit batches"
								className={`btn btn-primary ${styles.depositSelectedBatchesButton}`}>Deposit batch
							</button>
						</div>
					</div>
				}
			</header>
		);
	}

	raiseDepositBatchesDialog = () => {
		const {selectedBatchCount, depositSelectedBatches} = this.props.vm;
		ModalDialogCommander.showReactDialog(<DepositBatchModalDialog batchCount={selectedBatchCount} confirm={depositSelectedBatches}/>,
			{ elementToFocusSelector: '.modal' } as IDialogOptions);
	}

	handleSort = (event: React.FormEvent<HTMLSelectElement>) => {
		const { changeSort, raiseAction } = this.props.vm;

		changeSort(parseInt(event.currentTarget.value, 10));
		raiseAction(new BatchListUserAction.QueryBatches({ takeAllUpToCurrentPage: true }));
	}

	handleRefresh = () =>
		this.props.vm.raiseAction(new BatchListUserAction.QueryBatches({ takeAllUpToCurrentPage: true }))

	handleToggleFilters = () => {
		this.setState({ filtersOpen: !this.state.filtersOpen });
	}
}

const SelectedFiltersSummary: React.StatelessComponent<{
	batchesBasedOn: IBatchesBasedOn,
	listings: MultiSelectItem[],
	serviceTimes: MultiSelectItem[],
}> = (props) => {
	const {
		batchesBasedOn: {
			numBatches,
			searchTerm,
			selectedListings,
			selectedServiceTimes,
		},
		listings,
		serviceTimes,
	} = props;
	const { length: numListings } = selectedListings;

	const filterValues: string[] = [];

	if (searchTerm) {
		filterValues.push(searchTerm);
	}

	if (numListings === 0 || numListings === listings.length) {
		filterValues.push('all listings');
	} else {
		selectedListings.forEach((selectedListing) => {
			const [listing] = listings.filter((listing: MultiSelectItem) => listing.Value === selectedListing);

			filterValues.push(listing.Text);
		});
	}

	const { length: numServiceTimes } = selectedServiceTimes;
	if (numServiceTimes === 0 || numServiceTimes === serviceTimes.length) {
		filterValues.push('all service times');
	} else {
		selectedServiceTimes.forEach((selectedServiceTime) => {
			const [serviceTime] = serviceTimes.filter((serviceTime: MultiSelectItem) => Number(serviceTime.Value) === selectedServiceTime);

			filterValues.push(serviceTime.SelectedText);
		});
	}

	const batchCount = numBatches === 1
		? <span>is <strong>1 batch</strong></span>
		: <span>are <strong>{numBatches} batches</strong></span>;

	const summary = filterValues.map((filterText: string, index: number) => {
		let prefix: string;

		if (index === 0) {
			prefix = '';
		} else {
			prefix = index + 1 === filterValues.length ? ' and ' : ', ';
		}

		return <span key={index}>{prefix}'{filterText}'</span>;
	});

	return (
		<Fragment>
			There {batchCount} based on {summary}
		</Fragment>
	);
};

const option = (label: string, value: SortOrder) => ({ Label: label, Key: `${value}`, Value: `${value}` });
