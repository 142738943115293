import * as React from 'react';
import { observer } from 'mobx-react';
import { BatchDialogStore } from './batch-dialog-store';
import { FormControlCheckbox } from '../../components/form-controls/form-control-checkbox/form-control-checkbox';
import { IModalDialogButton, ModalDialog } from '../../components/modal-dialog';
import { SvgWrapper } from '../../components/svg-wrapper';
import { ClientFlowHelper } from '../helpers/client-flow-helper';

@observer
export class BatchBeforeAllocationDialog extends React.Component<{store: BatchDialogStore}> {
	render() {
		const { hideBeforeAllocationMessage, toggleHideBeforeAllocationMessage } = this.props.store;

		const buttons: (IModalDialogButton | JSX.Element)[] = [
			<button type="button" className="btn btn-ghost" onClick={this.takeMeBackClickHandler}>
				<SvgWrapper svg="icon-follow-link" className="icon" />
				No, take me back
			</button>,
			{ label: 'Yes, continue', dismiss: true, isPrimary: true }
		];

		return(
			<ModalDialog title="Did you close the deposit?" buttons={buttons} showAlert={false}>
				<div className="modal-text">
					Closing the deposit submits checks for processing. Once closed, deposits are usually ready to allocate within the next business hour.
					<FormControlCheckbox containerClassNames="checkbox-large"
						value={hideBeforeAllocationMessage}
						onChangeHandler={toggleHideBeforeAllocationMessage}
						text="Don't show this message again" />
				</div>
			</ModalDialog>
		);
	}

	private takeMeBackClickHandler = () => {
		ClientFlowHelper.openCheckScanningPortalWindow();
	}
}

