import * as React from 'react';
import {observable, action} from 'mobx';
import {observer} from 'mobx-react';
import {LocalStorageHelper} from '../helpers/localstoragehelper';

export type ITipBoxProps = {
	icon?: string;
	storageKey: string;
	extraClassNames?: string;
} & ({ dismissable?: boolean, displayTimes?: never } | { dismissable: true, displayTimes: number });

const keyNamespace = 'Pushpay.TipBoxState';

@observer
export class TipBox extends React.Component<ITipBoxProps, {isDismissed: boolean}> {

	private storageKey: string;
	private displayTimesStorageKey: string;
	private storage: Storage;

	constructor(props) {
		super(props);
		this.storage = LocalStorageHelper.getLocalStorage();
		this.storageKey = `${keyNamespace}.${this.props.storageKey}`;
		this.state = { isDismissed: this.isDismissedStorageValue };
	}

	componentDidMount() {
		const isDisplayTimesValid = this.props.displayTimes && !this.state.isDismissed;
		if (isDisplayTimesValid) {
			this.displayTimesStorageKey = this.storageKey + '.RemainingTimes';
			const remainingDisplayTimes = this.displayTimesStorageValue;
			const dismissNextTime = remainingDisplayTimes === 1;

			if (dismissNextTime) {
				this.isDismissedStorageValue = true;
			}

			this.displayTimesStorageValue = Math.max(0, remainingDisplayTimes - 1);
		}
	}

	render() {
		const classNames = `tipbox clearfix ${this.props.extraClassNames}`;

		if (this.state.isDismissed) {
			return (
				<div className={`${classNames} dismissed`}>
					<button className="btn pull-right btn-show-help" onClick={this.showTipBox}>+ Show help
					</button>
				</div>
			);
		} else {
			return (
				<div className={classNames}>
					<div className={this.getClassNames()}>
						{this.renderIcon()}
						<div>
							{this.props.children}
						</div>
					</div>
					{this.props.dismissable === false
						? null
						: <button className="btn btn-dismiss" onClick={this.dismissTipBox}>
							Dismiss
							<svg className="svg svg-close-cross">
								<use xlinkHref="#close-cross" />
							</svg>
						</button>
					}
				</div>
			);
		}
	}

	private renderIcon() {
		if (this.hasIcon()) {
			return (
				<div className="tipbox-icon">
					<svg className="svg" width="85" height="85">
						<use xlinkHref={`#${this.props.icon}`}></use>
					</svg>
				</div>
			);
		} else {
			return null;
		}
	}

	private hasIcon(): boolean {
		return (this.props.icon && this.props.icon !== '');
	}

	private getClassNames(): string {
		let className = 'tipbox-content';
		if (this.props.icon && this.props.icon !== '') {
			className += ' with-icon';
		}

		return className;
	}

	private dismissTipBox = () => this.toggleTipBox(true);

	private showTipBox = () => this.toggleTipBox(false);

	private toggleTipBox(isDismissed: boolean) {
		this.isDismissedStorageValue = isDismissed;
		this.setState({ isDismissed });
	}

	private get isDismissedStorageValue() {
		const isDismissed = this.storage.getItem(this.storageKey);
		return isDismissed && isDismissed === 'true';
	}

	private set isDismissedStorageValue(isDismissed: boolean) {
		this.storage.setItem(this.storageKey, isDismissed.toString());
		const shouldClearDisplayTimes = isDismissed && this.displayTimesStorageKey;
		if(shouldClearDisplayTimes) {
			this.displayTimesStorageValue = 0;
		}
	}

	private get displayTimesStorageValue() {
		const storedDisplayTimes = this.storage.getItem(this.displayTimesStorageKey);
		return storedDisplayTimes && !isNaN(+storedDisplayTimes) ? +storedDisplayTimes : Math.max(0, this.props.displayTimes);
	}

	private set displayTimesStorageValue(displayTimes: number) {
		this.storage.setItem(this.displayTimesStorageKey, displayTimes.toString());
	}
}
