import * as React from 'react';
import { observer } from 'mobx-react';
import { Formatter } from '../../../helpers/formatter';
import { classNames } from '../../../../Shared/utils/classnames';
import { SvgWrapper } from '../../../components/svg-wrapper';
import { SmoothHeightTransition } from '../../../components/hoc-behavior/transitions';
import { PaymentTotal } from '../batch-entry-payments/batch-entry-payments-store';
import { Fragment } from '../../../../Shared/components/fragment';

import * as styles from './batch-entry-discrepency.less';

@observer
export class BatchEntryDiscrepency extends React.Component<{
	batchTotal: PaymentTotal,
	expectedTotal: number,
	batchCount: PaymentTotal,
	expectedCount: number,
	paymentNoun: string,
	paymentNounPlural: string,
}> {
	render() {
		const { batchTotal, expectedTotal, batchCount, expectedCount, paymentNoun, paymentNounPlural } = this.props;
		return (
			<SmoothHeightTransition className={styles.container}>
				{
					batchCount.fetched && batchTotal.fetched &&
					<Fragment>
						<div className={styles.item}>
							<label>Number of {paymentNoun} entries</label>
							<div className={styles.total}>{batchCount.total}</div>
							<ExpectedWrapper total={batchCount.total} expectedTotal={expectedCount} atTarget={'Expected batch count total'}>
								Number of {paymentNounPlural}: {expectedCount}
							</ExpectedWrapper>
						</div>
						<div className={styles.item}>
							<label>Batch total calculated</label>
							<div className={styles.total}><sup className={styles.dollarSign}>$</sup>{Formatter.formatNumberForDisplay(batchTotal.total)}</div>
							<ExpectedWrapper total={batchTotal.total} expectedTotal={expectedTotal} atTarget={'Expected batch total'}>
								Batch total: ${Formatter.formatNumberForDisplay(expectedTotal)}
							</ExpectedWrapper>
						</div>
					</Fragment>
				}
			</SmoothHeightTransition>
		);
	}
}

@observer
class ExpectedWrapper extends React.Component<{ total: number, expectedTotal: number, atTarget: string }> {
	render() {
		const { total, expectedTotal, atTarget, children } = this.props;
		if (!expectedTotal) {
			return null;
		}
		const isExpected = expectedTotal === total;
		const expectedClassName = isExpected ? styles.match : styles.discrepency;
		return (
			<div className={classNames(styles.expectedTotal, expectedClassName)} data-pp-at-target={atTarget}>
				{isExpected && <SvgWrapper svg="ind-transparent-tick" className={styles.matchIcon} />}
				{children}
			</div>
		);
	}
}
