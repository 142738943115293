import { getActionMapKeys } from '../../../Shared/state-machine/action-map';
import { MachineContext } from '../../../Shared/state-machine/saga-state-machine';
import { ConfigureRunViewModel, GivingStatementRunStatus } from '../giving-statements-generated';
import { GivingStatementsViewModel } from '../giving-statements-view-model';
import { givingStatementsDataService } from '../giving-statements-data-service';
import { MainEvents } from '../state/main';
import { LandingPageActionMap } from './landing-page-actions';
import { ConfigurePageActionMap } from './configure-page-actions';
import { PreviewPageActionMap } from './preview-page-actions';
import { SendPageActionMap } from './send-page-actions';
import { urlService } from '../services/url';
import { DeleteStatementsActionMap } from './delete-statements-actions';

export const GivingStatementsActionMap = {
	Init: function* (context: MachineContext) {
		const id = urlService.getConfigurationId();

		if (id) {
			// Clear all prefixes from the url, so we can reset via the same flow if we need to.
			urlService.clearConfigurationId();

			// If there was an ID extracted from the URL, continue the configuration using that config id in context.
			context.transition(MainEvents.LOAD_CONFIGURATION_IN_PROGRESS, id);
		} else {
			context.transition(MainEvents.LOAD_LANDING_VIEW);
		}
	},
	ClearConfigurationId: function* () {
		urlService.clearConfigurationId();
	},
	ContinueConfiguration: function* (context: MachineContext, configId: string | number) {
		console.log('Continuing configuration');

		try {
			const vm = context.viewModel as GivingStatementsViewModel;
			const id: number = Number(configId);
			const currentConfiguration: ConfigureRunViewModel = yield givingStatementsDataService.configure({ id });

			vm.setCurrentConfigurationId(id);
			vm.setCurrentConfiguration(currentConfiguration);

			urlService.setConfigurationId(id);
			urlService.initHistoryState();
			urlService.pushPreviousViewState();

			switch (currentConfiguration.Status) {
				case GivingStatementRunStatus.Draft:
				case GivingStatementRunStatus.Failed:
					context.transition(MainEvents.LOAD_CONFIGURE_VIEW, false);
					break;
				case GivingStatementRunStatus.Configured:
				case GivingStatementRunStatus.Generating:
					context.transition(MainEvents.LOAD_PREVIEW_VIEW);
					break;
				case GivingStatementRunStatus.Generated:
					context.transition(MainEvents.LOAD_SEND_VIEW, { immediatelyMoveToSummary: false });
					break;
				case GivingStatementRunStatus.Issued:
				case GivingStatementRunStatus.Issuing:
					context.transition(MainEvents.LOAD_SEND_VIEW, { immediatelyMoveToSummary: true });
					break;
				default:
					context.transition(MainEvents.LOAD_CONFIGURE_VIEW, false);
			}
		} catch (error) {
			// @TODO: Handle the error.
			console.error(error);
		}
	},
	...LandingPageActionMap,
	...ConfigurePageActionMap,
	...PreviewPageActionMap,
	...SendPageActionMap,
	...DeleteStatementsActionMap,
};

export const GivingStatementActions = getActionMapKeys(GivingStatementsActionMap);
