import * as React from 'react';
import moment from 'moment';
import { observer, inject } from 'mobx-react';
import { Button } from '../../../../Shared/components/button/button';
import { classNames } from '../../../../Shared/utils/classnames';
import { dateService } from '../../services/date';
import { Formatter } from '../../../helpers/formatter';
import { injectAnalytics, WithAnalytics, recordEvent } from '../../../analytics';
import { FormControlLabelled, FormControlType } from '../../../components/form-controls/form-control-labelled';
import { FormControlTextarea } from '../../../components/form-controls/form-control-textarea';
import { Fragment } from '../../../../Shared/components/fragment';
import { injectValidationFormContext } from '../../../components/form-controls/validatable-form-context';
import { machineContextKey } from '../../../../Shared/state-machine/saga-state-machine';
import { ModelMetadata, GivingStatementRunStatus } from '../../giving-statements-generated';
import { IEditEmailTemplateProps } from '../email-editor/email-editor';
import { ScrollToElement } from '../scroll-to-element';
import { SendPageStates, SendEmailModalStates } from '../../state/send-page';
import { ValidatableForm } from '../../../components/form-controls/validatable-form';
import { ValidationMessage } from '../../../components/form-controls/validation-message';
import { ValidationSummary } from '../../../components/form-controls/validation-summary';
import { Tooltip, TooltipContent } from '../../../components/tooltip/tooltip';
import { FormControlCheckbox } from '../../../components/form-controls/form-control-checkbox/form-control-checkbox';
import { SvgWrapper } from '../../../components/svg-wrapper';
import * as styles from './styles.less';

export interface IEditEmailSelfAccessTemplateProps extends IEditEmailTemplateProps {
	hasPublishedBefore: boolean;
	status: GivingStatementRunStatus;
}

@injectAnalytics
@inject(machineContextKey)
@injectValidationFormContext
@observer
export class EmailEditorSelfAccess extends React.Component<WithAnalytics & IEditEmailSelfAccessTemplateProps> {
	render() {
		const {
			machineContext,
			replyTo,
			emailCountToSend,
			onSendClick,
			errorMap,
			errorMessages,
			hasPublishedBefore,
			status,
			hideTotalTaxDeductible,
			onSaveAsDraftClick,
			onSendPreviewEmail,
		} = this.props;

		const metadata = ModelMetadata.UpdateStatementRequest;
		let verbPresentParticipleSuffix = machineContext.matchesState(SendEmailModalStates.SENDING) ? 'ing' : '';
		let nounPluralSuffix = `${emailCountToSend === 1 ? '' : 's'}`;
		let sendButtonLabel = !hasPublishedBefore
			? `Send${verbPresentParticipleSuffix} and publish${verbPresentParticipleSuffix} statement${nounPluralSuffix}`
			: `Send${verbPresentParticipleSuffix} statement${nounPluralSuffix}`;
		return (
			<ValidatableForm errorMap={errorMap} onSubmit={onSendClick}>
				{errorMessages && errorMessages.length > 0 && (
					<ScrollToElement>
						<ValidationSummary validationErrors={errorMessages} />
					</ScrollToElement>
				)}
				<FormControlLabelled
					label={metadata.ReplyTo.displayName}
					formControlProps={{
						formControlType: FormControlType.Text,
						type: 'text',
						placeholder: 'Enter a reply to address',
						name: metadata.ReplyTo.propertyName,
						value: replyTo,
						onChangeHandler: this.onReplyToChangeEvent,
						validationRules: metadata.ReplyTo.validationRules,
						acceptanceTestTargetId: 'email-reply-to',
					}}
				/>
				<FormControlLabelled
					label={metadata.EmailSubject.displayName}
					formControlProps={{
						formControlType: FormControlType.Text,
						type: 'text',
						placeholder: 'Enter an email subject',
						name: metadata.EmailSubject.propertyName,
						value: this.props.subject,
						onChangeHandler: this.onSubjectChangeEvent,
						validationRules: metadata.EmailSubject.validationRules,
						acceptanceTestTargetId: 'email-subjectemail',
					}}
				/>
				<>
					<label>Statement Amount</label>
					<FormControlCheckbox
						text="Hide total tax-deductible contributions amount from email"
						value={hideTotalTaxDeductible}
						acceptanceTestTargetId="hide-total-tax-deductible"
						onChangeHandler={this.onUpdateHideTotalTaxDeductibleEvent}
					/>
				</>
				<div className={styles.container}>
					{this.renderEmailTemplate()}
					<div className={styles.btnGroup}>
						<Button
							type="button"
							className={classNames(styles.bustOverlay, 'btn btn-ghost')}
							isLoading={machineContext.matchesState(SendPageStates.SENDING_PREVIEW_EMAIL)}
							onClick={onSendPreviewEmail}
						>
							Send me a test email
						</Button>
						{this.emailIsModified() && (
							<Button
								type="button"
								className={classNames(styles.bustOverlay, 'btn btn-ghost')}
								isLoading={machineContext.matchesState(SendPageStates.SAVING_AS_DRAFT)}
								disabled={machineContext.matchesState(SendEmailModalStates.SENDING)}
								onClick={onSaveAsDraftClick}
							>
								Save for later
							</Button>
						)}
						<Button
							type="submit"
							className="btn btn-default"
							isLoading={machineContext.matchesState(SendEmailModalStates.SENDING)}
							disabled={status === GivingStatementRunStatus.Issuing}
							onClick={() => {
								if (NewFeatures.SetupPinpointAnalytics && this.props.analytics) {
									const { feature, subFeature } = this.props.analytics;
									recordEvent({ feature, subFeature, eventTypeLabel: 'sendAndPublishStatements' });
								}
							}}
						>
							<Fragment>{sendButtonLabel}</Fragment>
						</Button>
						<OrgTypeTooltip />
					</div>
				</div>
			</ValidatableForm>
		);
	}

	private renderEmailTemplate() {
		const {
			emailBranding,
			contactPhone,
			contactEmail,
			endDate,
			startDate,
			statementName,
			paymentLabel,
			hideTotalTaxDeductible,
		} = this.props;

		const metadata = ModelMetadata.UpdateStatementRequest;

		const buttonStyle: React.CSSProperties = {
			borderColor: emailBranding.PrimaryColor,
			backgroundColor: emailBranding.PrimaryColor,
		};
		const borderStyle: React.CSSProperties = {
			borderTopColor: emailBranding.PrimaryColor,
		};

		return (
			<div className={styles.emailTemplate} style={borderStyle}>
				<div className={styles.overlay} />
				<div className={styles.header}>
					<div className={styles.organization}>
						<img className={styles.logo} src={emailBranding.MerchantLogoUrl} />
						<div className={styles.name}>{emailBranding.OrganizationName}</div>
					</div>
					<FormControlLabelled
						label={metadata.EmailHeading.displayName}
						formControlProps={{
							formControlType: FormControlType.Text,
							type: 'text',
							placeholder: 'Enter an email heading',
							name: metadata.EmailHeading.propertyName,
							value: this.props.heading,
							onChangeHandler: this.onHeadingChangeEvent,
							validationRules: metadata.EmailHeading.validationRules,
							acceptanceTestTargetId: 'email-subjectemail',
						}}
						cssClassNames={classNames(styles.bustOverlay, styles.emailHeadingInput)}
					/>
					{startDate && endDate && (
						<Fragment>
							<hr className={styles.headerDivider} />
							<div className={styles.date}>{dateService.getStatementDateRange(startDate, endDate)}</div>
							<hr className={styles.headerDivider} />
						</Fragment>
					)}
					<div className={styles.informationPanel}>
						<div className={styles.greeting}>Hi Sam,</div>
						<div>
							Thanks for your {paymentLabel.NounPluralLowerCase} in {endDate.getFullYear()}! Your most
							recent giving statement "{statementName}" is now available.{' '}
						</div>
						{!hideTotalTaxDeductible && (
							<div className={styles.amountPanel}>
								<div className={styles.text}>Total tax-deductible contributions</div>
								<div className={styles.dollarValue}>$7,800.00</div>
								<div className={styles.dots}>
									..................................................................................................................
								</div>
							</div>
						)}
					</div>
					<div className={styles.moreDetail}>To view your statement in more detail</div>
					<div className={styles.buttonPanel}>
						<Button
							className={classNames(styles.downloadStatementBtn, 'btn btn-default')}
							style={buttonStyle}
						>
							Sign in to your account
						</Button>
					</div>
					<div className={styles.problems}>Problems accessing your account?</div>
					<div className={styles.singleAccessLink}>Click here to access your single giving statement.</div>
				</div>
				<div className={styles.body}>
					<div className={styles.content}>
						<div className={styles.actionContainer}>{this.renderLastUpdatedLabel()}</div>
						{this.renderTextTemplate()}
						<FormControlLabelled
							label={metadata.EmailVideoUrl.displayName + ` (optional)`}
							cssClassNames={classNames(styles.bustOverlay, styles.videoLink)}
							formControlProps={{
								name: metadata.EmailVideoUrl.propertyName,
								formControlType: FormControlType.Text,
								type: 'text',
								placeholder: 'Enter a video URL',
								value: this.props.videoUrl,
								onChangeHandler: this.onUpdateVideoUrlEvent,
								validationRules: metadata.EmailVideoUrl.validationRules,
								acceptanceTestTargetId: 'email-videoUrl',
							}}
						/>
						<div className={styles.enquiry}>
							<span>
								If you have any queries about this or trouble accessing your Giving Statement, please
								email{' '}
							</span>
							<strong>{contactEmail}</strong>
							<span> or phone </span>
							<strong>{contactPhone}</strong>.
						</div>
					</div>
				</div>
				<div className={styles.footer}>
					{this.renderLogo()}
					<hr className={styles.footerDivider} />
					<div className={styles.copyright}>
						Copyright &copy; {Formatter.formatDate(new Date(), 'YYYY')} Pushpay. All rights reserved.
					</div>
				</div>
			</div>
		);
	}

	private renderLastUpdatedLabel() {
		const { lastUpdateTime } = this.props;

		return (
			lastUpdateTime && <div className={styles.lastUpdate}>Last updated {this.calculateAgo(lastUpdateTime)}</div>
		);
	}

	private renderTextTemplate() {
		const { message } = this.props;
		const metadata = ModelMetadata.UpdateStatementRequest;
		const charUsed = message ? message.length : 0;
		const charAllowed = metadata.EmailText.validationRules.length.parameters.max;

		return (
			<div className={classNames(styles.bustOverlay, styles.emailContentWrapper)}>
				<FormControlTextarea
					cssClassNames={styles.message}
					name={metadata.EmailText.propertyName}
					value={this.props.message}
					rows={10}
					validationRules={{
						...metadata.EmailText.validationRules,
						containsdefaulttext: {
							errorMessage: 'Please modify the default email message',
							parameters: {
								defaulttext: this.props.defaultEmailTextKey,
							},
						},
					}}
					onChangeHandler={this.onUpdateMessageEvent}
					maxLength={metadata.EmailText.validationRules.length.parameters.max}
				/>
				<div className={classNames(styles.charLimit, charUsed > charAllowed ? styles.limitExceeded : null)}>
					<div>
						<ValidationMessage elementName={metadata.EmailText.propertyName} />
					</div>
					<div>
						{message ? message.length : 0} / {charAllowed} characters used
					</div>
				</div>
			</div>
		);
	}

	private renderLogo() {
		const { emailBranding } = this.props;
		if (emailBranding.HasCustomBranding) {
			return (
				<div>
					<span className={styles.footerLogoText}>POWERED BY </span>
					<img className={styles.pushpayLogo} src={emailBranding.PushpayLogoUrl} />
				</div>
			);
		}
		return (
			<div>
				<img className={styles.pushpayLogo} src={emailBranding.PushpayLogoUrl} />
			</div>
		);
	}

	private calculateAgo(whenHappened: Date): string {
		return moment.utc(whenHappened).fromNow();
	}

	private emailIsModified = () => {
		const { originalEmailData } = this.props;

		return Object.keys(originalEmailData).some((key) => originalEmailData[key] !== this.props[key]);
	};

	private onReplyToChangeEvent = (nameEvent: React.FormEvent<HTMLInputElement>) => {
		this.props.onReplyToChange(nameEvent.currentTarget.value);
	};

	private onSubjectChangeEvent = (nameEvent: React.FormEvent<HTMLInputElement>) => {
		this.props.onSubjectChange(nameEvent.currentTarget.value);
	};

	private onHeadingChangeEvent = (nameEvent: React.FormEvent<HTMLInputElement>) => {
		this.props.onHeadingChange(nameEvent.currentTarget.value);
	};

	private onUpdateVideoUrlEvent = (nameEvent: React.FormEvent<HTMLInputElement>) => {
		this.props.onVideoUrlChange(nameEvent.currentTarget.value);
	};

	private onUpdateMessageEvent = (nameEvent: React.FormEvent<HTMLTextAreaElement>) => {
		this.props.onMessageChange(nameEvent.currentTarget.value);
	};
	private onUpdateHideTotalTaxDeductibleEvent = (checked: boolean) => {
		this.props.onHideTotalTaxDeductibleChange(checked);
	};
}

class OrgTypeTooltip extends React.Component<{}, { visible: boolean }> {
	constructor() {
		super({});
		this.state = { visible: false };
	}

	render() {
		return (
			NewFeatures.DisableDirectPdfLinkAccess && (
				<Tooltip
					label="more information"
					placement="bottom"
					visible={this.state.visible}
					onVisibleChange={this.handleVisibleChange}
				>
					<SvgWrapper svg="icon-info" className={`icon tooltip-icon ${styles.tooltipIcon}`} />
					<TooltipContent>
						<div>Organizations will always be emailed a direct link to view their giving statement.</div>
					</TooltipContent>
				</Tooltip>
			)
		);
	}

	private handleVisibleChange = (visible: boolean) => {
		this.setState({ visible: visible });
	};
}
