import * as React from 'react';
export interface IFundListTabStripProps {
	isOpenTabVisible: boolean;
	tabVisibilityChange: (isOpenTabVisible: boolean) => void;
}

export class FundListTabStrip extends React.Component<IFundListTabStripProps, {} > {

	handleClick(e: React.MouseEvent<HTMLAnchorElement>, isOpenTabVisible: boolean) {
		e.preventDefault();
		this.props.tabVisibilityChange(isOpenTabVisible);
	}

	render() {
		return (
			<ul className="nav nav-tabs fund-list-tabs">
				<li className={this.props.isOpenTabVisible ? 'active' : null}>
					<a href="#" onClick={(e) => this.handleClick(e, true) } >Open funds</a>
				</li>
				<li className={!this.props.isOpenTabVisible ? 'active' : null}>
					<a href="#" onClick={(e) => this.handleClick(e, false) }>Closed funds</a>
				</li>
			</ul>
		);
	}
}
