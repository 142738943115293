import * as React from 'react';
import { Button } from '../../../../Shared/components/button/button';
import { Fragment } from '../../../../Shared/components/fragment';

export interface IReviewCommunityMembersModalProps {
	communityMemberUrl: string;
	newMemberCount: number;
	onDismissClick: () => void;
	onNextClick: () => void;
}

export const ReviewCommunityMembersModal: React.StatelessComponent<IReviewCommunityMembersModalProps> = (props) => {
	const {
		communityMemberUrl,
		newMemberCount,
		onDismissClick,
		onNextClick
	} = props;

	return (
		<Fragment>
			<div className="modal-content" data-pp-at-target="Modal">
				<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onDismissClick} />
				<div className="modal-body left-align">
					<h2>Review community members</h2>
					<div className="modal-text">
						Your community has <strong>{newMemberCount}</strong> new member{newMemberCount === 1 ? '' : 's'} added
						since your last statements were generated. Please review community member accounts before continuing to
						ensure your statements are accurate.
					</div>
				</div>
				<div className="modal-footer">
					<a className="btn btn-cancel" href={communityMemberUrl}>Review Members</a>
					<Button type="submit" className="btn btn-primary" onClick={onNextClick}>Next</Button>
				</div>
			</div>
		</Fragment>
	);
};
