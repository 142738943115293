import { action, observable, IObservableValue } from 'mobx';
import { userActionChannel, Channel } from '../../../utils/saga-utils';
import { BatchEntryUserAction } from './batch-entry-user-actions';
import { BatchEntryViewModel, ListingViewModel, VirtualTerminalListingConfiguration, BatchViewModel } from '../../virtual-terminal-generated';
import { BatchEntryBatchDetailsViewModel } from '../../components/batch-entry/batch-details/batch-entry-batch-details-view-model';
import { EventTimeAddEditDialogViewModel } from '../../../components/event-time/event-time-add-edit-dialog-view-model';
import { VirtualTerminalListingStore } from '../../virtual-terminal-listing-store';
import { VirtualTerminalFormViewModel } from '../../components/form/virtual-terminal-form-view-model';
import { DefaultSettingsViewModel } from '../../components/default-settings/default-settings-view-model';
import { BatchEntryPaymentsStore } from '../../components/batch-entry-payments/batch-entry-payments-store';
import { StorageHelper } from '../../../helpers/storagehelper';
import { ModalDialogProcessingViewModel } from '../../../components/modal-dialog/modal-dialog-processing-view-model';
import { reportExportError } from '../../../utils/error-utils';
import { alertController } from '../../../components/alert-controller';

export class BatchEntryMainViewModel {

	@observable
	model: IObservableValue<BatchEntryViewModel> = observable.box<BatchEntryViewModel>(null);

	@observable
	listingStore: VirtualTerminalListingStore;

	@observable
	batchDetailsViewModel: BatchEntryBatchDetailsViewModel;

	@observable
	addEventTimeDialogViewModel: EventTimeAddEditDialogViewModel;

	@observable
	defaultSettingsViewModel: DefaultSettingsViewModel;

	@observable
	formViewModel: VirtualTerminalFormViewModel;

	@observable
	batchPaymentsStore: BatchEntryPaymentsStore;

	@observable
	modalDialogProcessingViewModel: ModalDialogProcessingViewModel;

	userActionChannel: Channel<BatchEntryUserAction> = userActionChannel();

	constructor(model: BatchEntryViewModel) {
		this.model.set(model);

		const { Listings, ListingConfiguration, EventTimes, Batch, ReferrerUrl, HasExportErrored, PayersReconciledMessage } = model;
		const defaultSettingsModel = Batch && StorageHelper.getDefaultSettingsForBatch(`${Batch.BatchId}`);

		this.initListingStore(Listings, ListingConfiguration, Batch);
		this.defaultSettingsViewModel = new DefaultSettingsViewModel(this.listingStore, defaultSettingsModel);
		this.batchDetailsViewModel = new BatchEntryBatchDetailsViewModel(this.listingStore, this.defaultSettingsViewModel, EventTimes, Batch, ReferrerUrl);
		this.formViewModel = new VirtualTerminalFormViewModel(this.listingStore, this.defaultSettingsViewModel, false, false);
		this.batchPaymentsStore = new BatchEntryPaymentsStore();
		this.modalDialogProcessingViewModel = new ModalDialogProcessingViewModel();

		if (HasExportErrored) {
			reportExportError();
		}

		console.log(PayersReconciledMessage);
		if (PayersReconciledMessage) {
			alertController.showSuccess(PayersReconciledMessage);
		}
	}

	@action.bound
	setAddEventTimeDialogViewModel(addEventTimeDialogViewModel: EventTimeAddEditDialogViewModel) {
		this.addEventTimeDialogViewModel = addEventTimeDialogViewModel;
	}

	@action.bound
	initListingStore(listings: ListingViewModel[], listingConfiguration: VirtualTerminalListingConfiguration, batch: BatchViewModel) {
		this.listingStore = new VirtualTerminalListingStore(listings);
		if (listingConfiguration && batch) {
			const listingId = `${batch.ListingId}`;
			this.listingStore.handleSelectedListingChange(listingId);
			this.listingStore.loadingConfigurationFinished(listingId, listingConfiguration);
		}
	}
}
