import * as React from 'react';
import { ModalDialogForm } from '../../../components/modal-dialog-form';
import { DataGrid, IDataGridColumn, DataGridCell } from '../../../components/data-grid/data-grid';
import { BatchViewModel, BatchStatus } from '../../virtual-terminal-generated';

export interface IBatchEntryDepositFailureDialogProps {
	batches: BatchEntryDepositFailure[];
}

export interface BatchEntryDepositFailure {
	batchId: number;
	name: string;
	reason: string;
}

const getHistoryGridRowKey = (item: BatchEntryDepositFailure) => `${item.batchId}`;

export class BatchEntryDepositFailuresDialog extends React.Component<IBatchEntryDepositFailureDialogProps> {
	private columns: { [key: string]: IDataGridColumn } = getGridColumns();
	render() {
		const { batches } = this.props;
		return (
			<ModalDialogForm heading={`Batches that could not be deposited`}>
				<div>The following batches could not be deposited for the following reasons:</div>
				<div className="batch-entry-deposit-failures-dialog-grid">
					<DataGrid columns={this.columns}
						data={batches}
						getRowKey={getHistoryGridRowKey}
						onSelectRow={false}
					/>
				</div>
			</ModalDialogForm>
		);
	}
}

function getGridColumns() {
	return {
		name: { displayName: 'Batch name' },
		reason: { displayName: 'Reason' },
	};
}
