import { channel, buffers } from 'redux-saga';
import { fork, call } from 'redux-saga/effects';
import { Channel } from '../../Shared/utils/user-action-channel';
import { BrandingSettingsUserAction } from './saga-actions/actions';
import { BrandingSettingsMainViewModel } from './branding-settings-main-view-model';
import { BrandingSettingsDataService, getBrandingSettingsDataService } from './branding-settings-data-service';
import { IBrandingSettingsAppState } from './branding-settings-app-state';
import { createActionHandlers } from './saga-actions/actions';
import { sagaLoopActionDispatcher, processLatest } from '../../Shared/utils/saga-utils';
import { reportError as defaultReportError } from '../utils/error-utils';
import { ISavePackageAction, savePackageSaga, handleErrorResponse } from './saga-actions/save-package-handler';
import { userActionChannel } from '../utils/saga-utils';
import { BrandingSettingsViewModel } from './branding-settings-generated';
import { startImageUploaderLoops, ImageUploaderAction } from './saga-actions/image-selected-handler';
import { BrandingSettingsDraftState } from './editor-form/branding-settings-form-view-model';
import { isInvalidPackageError } from './saga-actions/errors/invalid-package-error';

export interface IBrandingSettingsImageUploadChannels {
	heroImage: Channel<ImageUploaderAction>;
	enhancedLogo: Channel<ImageUploaderAction>;
	favicon: Channel<ImageUploaderAction>;
	backdrop: Channel<ImageUploaderAction>;
	backdropMobile: Channel<ImageUploaderAction>;
}

export interface IBrandingSettingsSagaContext {
	readonly userActionChannel: Channel<BrandingSettingsUserAction>;
	readonly savePackageChannel: Channel<ISavePackageAction>;
	readonly mainViewModel: BrandingSettingsMainViewModel;
	readonly disableValidation: boolean;
	dataService: BrandingSettingsDataService;
	imageUploadChannels: IBrandingSettingsImageUploadChannels;
	appState: IBrandingSettingsAppState;
	reportError(error: any, customData?: any);
}

export function createContext(data: BrandingSettingsViewModel, reportError?: (error: any, customData?: any) => void): IBrandingSettingsSagaContext {
	const context: IBrandingSettingsSagaContext = {
		userActionChannel: userActionChannel<BrandingSettingsUserAction>(),
		savePackageChannel: channel<ISavePackageAction>(buffers.none()),
		imageUploadChannels: {
			heroImage: channel<ImageUploaderAction>(buffers.none()),
			enhancedLogo: channel<ImageUploaderAction>(buffers.none()),
			favicon: channel<ImageUploaderAction>(buffers.none()),
			backdrop: channel<ImageUploaderAction>(buffers.none()),
			backdropMobile: channel<ImageUploaderAction>(buffers.none()),
		},
		mainViewModel: new BrandingSettingsMainViewModel(data),
		dataService: getBrandingSettingsDataService(),
		appState: {},
		disableValidation: false,
		reportError: reportError || defaultReportError,
	};
	return context;
}

export function* brandingSettingsSaga(context: IBrandingSettingsSagaContext): IterableIterator<any> {
	const { mainViewModel: { formViewModel } } = context;
	const saveDebouncingDelay = 600;

	yield fork(function* processLatestSavePackageRequestLoop() {
		try {
			yield processLatest({
				requestChannel: context.savePackageChannel,
				worker: (r) => savePackageSaga(context, r),
				debouncingInterval: saveDebouncingDelay,
				onError: ex => handleSavePackageChannelErrors(context, ex),
				onStartProcessing: () => formViewModel.draftState = BrandingSettingsDraftState.Saving,
				onFinishProcessing: () => formViewModel.draftState = BrandingSettingsDraftState.Saved,
			});
		} catch (ex) {
			context.reportError(ex, {
				runningProcessLatest: true
			});
		}
	});

	yield fork(startImageUploaderLoops, context);

	yield sagaLoopActionDispatcher({
		createActionHandlers,
		getActionName,
		userActionChannel: context.userActionChannel,
		reportError: context.reportError,
		context
	});
}

function getActionName(userAction: BrandingSettingsUserAction): string | undefined {
	return (Object.getOwnPropertyNames(BrandingSettingsUserAction) as Array<keyof typeof BrandingSettingsUserAction>)
		.filter((x: keyof typeof BrandingSettingsUserAction) => userAction instanceof BrandingSettingsUserAction[x])[0];
}

function* handleSavePackageChannelErrors(context: IBrandingSettingsSagaContext, ex) {
	if (isInvalidPackageError(ex)) {
		context.mainViewModel.formViewModel.failedToSave();
		return;
	}

	yield call(handleErrorResponse, context, ex);
}
