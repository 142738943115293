import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {IModalDialogButton, ModalDialog} from '../../components/modal-dialog';
import {ModalDialogCommander} from '../../components/modal-dialog-commander';
import {SvgWrapper} from '../../components/svg-wrapper';
import {ClientFlowHelper} from '../helpers/client-flow-helper';

export class BatchStillOpenDialog extends React.Component<{}> {
	render() {
		const buttons: (IModalDialogButton | JSX.Element)[]  = [
			{ label: 'Back to list', dismiss: true, isPrimary: false, hideOnProgress: true },
			<button type="button" className="btn btn-default" onClick={this.openCheckScanningPortal}>
				<SvgWrapper svg="icon-follow-link" className="icon" />
				Close Deposit
			</button>
		];
		return(
			<ModalDialog title="This deposit is still open" buttons={buttons}>
				Please close this deposit in the check scanning portal before you start allocating transactions.
			</ModalDialog>
		);
	}

	private openCheckScanningPortal = () => {
		ClientFlowHelper.openCheckScanningPortalWindow();
		ModalDialogCommander.close(ReactDOM.findDOMNode(this));
	}
}
