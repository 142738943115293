import { action, computed, observable, IKeyValueMap, ObservableMap } from 'mobx';
import { CustomFieldEditModel, PaymentMethodType, CustomFieldType, ICustomFieldViewModel, DefaultSettingsStatsModel } from '../../virtual-terminal-generated';
import { VirtualTerminalListingStore } from '../../virtual-terminal-listing-store';
import { IFormControlSelectOptionProps } from '../../../components/form-controls/form-control-select';
import { DefaultSettingsModel } from './default-settings-model';
import { checkNounLowerCase } from '../../utils/check-translator';
import { getPaymentMethodDisplayText, toPaymentMethodType } from '../../utils/payment-method-type-helper';
import { paymentMethodsForListing, paymentMethodTypesForListing } from '../../utils/payment-method-creators';
import { ArrayHelper } from '../../../helpers/arrayhelper';

export class DefaultSettingsViewModel {
	@observable
	givenOn: Date;

	@observable
	notes: string;

	@observable
	paymentMethodType: PaymentMethodType;

	@observable
	listingStore: VirtualTerminalListingStore;

	@observable
	referenceFields: ObservableMap<string, CustomFieldEditModel> = observable.map<string, CustomFieldEditModel>();

	constructor(listingStore: VirtualTerminalListingStore, model: DefaultSettingsModel | null) {
		this.listingStore = listingStore;

		if (model) {
			this.givenOn = model.DefaultGivenOn;
			this.notes = model.DefaultNotes;
			this.setPaymentMethodTypeIfValid(model.DefaultPaymentMethodType);
			this.setValidReferenceFieldValues(model.DefaultReferenceFields);
		}
	}

	@action.bound
	setPaymentMethodTypeIfValid(paymentMethodType: PaymentMethodType) {
		const { selectedListingConfiguration } = this.listingStore;
		const validPaymentMethodTypes = paymentMethodTypesForListing(selectedListingConfiguration);
		if (validPaymentMethodTypes.some(x => x === paymentMethodType)) {
			this.paymentMethodType = paymentMethodType;
		}
	}

	@action.bound
	setValidReferenceFieldValues(referenceFields: IKeyValueMap<CustomFieldEditModel>) {
		const { selectedListingCustomFieldsMap } = this.listingStore;
		const validReferenceFields = Object.keys(referenceFields).filter(x => x in selectedListingCustomFieldsMap).map(x => referenceFields[x]);
		this.referenceFields.replace(ArrayHelper.arrayToMap(validReferenceFields, 'Key'));
	}

	@computed.struct
	get defaultSettingsModel(): DefaultSettingsModel {
		return {
			DefaultReferenceFields: this.referenceFields.toJSON(),
			DefaultGivenOn: this.givenOn,
			DefaultNotes: this.notes,
			DefaultPaymentMethodType: this.paymentMethodType,
		};
	}

	@computed.struct
	get defaultSettingsStatsModel(): DefaultSettingsStatsModel {
		return {
			DefaultReferenceFields: this.referenceFieldValues,
			DefaultGivenOn: this.givenOn,
			DefaultNotes: this.notes,
			DefaultPaymentMethodType: this.paymentMethodType,
		};
	}
	@computed
	get referenceFieldValues(): CustomFieldEditModel[] {
		return Array.from(this.referenceFields.values());
	}

	@computed
	get paymentMethodTypeOptions(): IFormControlSelectOptionProps[] {
		const { selectedListingConfiguration, selectedListingHomeCountry } = this.listingStore;
		const checkNoun = checkNounLowerCase(selectedListingHomeCountry);
		const nonCashNoun = this.listingStore.paymentLabel.NonCashNounSentenceCase;

		return paymentMethodsForListing(selectedListingConfiguration, false)
			.map(x => ({ Label: getPaymentMethodDisplayText(x.type, checkNoun, nonCashNoun), Value: `${toPaymentMethodType(x.type)}` }));
	}

	@computed
	get fundFieldViewModel() {
		const [fundField] = this.listingStore.selectedListingConfiguration.CustomFields.filter(x => x.Type === CustomFieldType.Fund);
		return fundField;
	}

	@computed
	get fundFieldValue() {
		const fundField = this.referenceFields.get(this.fundFieldViewModel.Key);
		return fundField && fundField.Value;
	}

	@computed
	get notFundReferenceFieldViewModels(): ICustomFieldViewModel[] {
		return this.listingStore.selectedListingConfiguration.CustomFields.filter(x => x.Type !== CustomFieldType.Fund);
	}

	@action.bound
	updateGivenOn(givenOn: Date) {
		this.givenOn = givenOn;
	}

	@action.bound
	updateNotes(notes: string) {
		this.notes = notes;
	}

	@action.bound
	updateReferenceFieldValue(field: CustomFieldEditModel) {
		this.referenceFields.set(field.Key, field);
	}

	@action.bound
	updatePaymentMethodType(paymentMethodType: PaymentMethodType) {
		this.paymentMethodType = paymentMethodType;
	}
}
