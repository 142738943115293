import * as React from 'react';
import { ModalDialogCommander } from '../../components/modal-dialog-commander';
import { Button } from '../../components/button';
import { CampaignFundErrorModalDialog } from './campaign-fund-error-dialog';

export interface IReopenCampaignFundButtonProps {
	url: string;
	className: string;
}

export class ReopenCampaignFundButton extends React.Component<IReopenCampaignFundButtonProps> {
	render() {
		const { url, className } = this.props;
		const buttonText = 'Manage this fund in Campaigns';

		return (url && url.length > 0) ?
			<a href={url} className={className}>
				{buttonText}
			</a> :
			<Button className={className} onClick={this.handleButtonClick}>
				{buttonText}
			</Button>;

	}

	handleButtonClick = () => {
		ModalDialogCommander.showReactDialog(<CampaignFundErrorModalDialog />);
	}
}
