
import {ContentSwitcher} from './content-switcher';
import {NodeListHelper} from '../helpers/nodelisthelper';
export class ContentToggleManager {

	private toggleStates: ContentToggler[] = [];

	constructor() {
		let toggles: Array<HTMLElement> = NodeListHelper.toArray<HTMLElement>(document.querySelectorAll('[data-content-toggle]'));
		toggles.map((element) => {
			this.toggleStates.push(new ContentToggler(element as HTMLInputElement));
		});
	}
}

export class ContentToggler {

	private toggleElement: HTMLInputElement;
	private containerElement: HTMLElement;
	private contentSwitcher: ContentSwitcher;
	private inactiveTarget: string = 'inactive';
	private activeTarget: string = 'active';

	constructor(toggleElement: HTMLInputElement) {
		this.toggleElement = toggleElement;
		let toggleStateTarget = this.toggleElement.dataset['contentToggleTarget'];

		this.containerElement = document.querySelector(`[data-content-toggle-identifier=${toggleStateTarget}]`) as HTMLElement;
		this.contentSwitcher = new ContentSwitcher(this.containerElement);

		this.toggleElement.addEventListener('change', () => this.toggleState(), false);
	}

	private toggleState() {
		if(this.toggleElement.checked) {
			this.contentSwitcher.showSection(this.activeTarget);
		} else {
			this.contentSwitcher.showSection(this.inactiveTarget);
		}
	}
}

export let contentToggleManager = () => new ContentToggleManager();
