import {
	DashboardIcon,
	TransactionsIcon,
	ReconciliationIcon,
	FinanceIcon,
	FundsIcon,
	CampaignsIcon,
	CommunityIcon,
	AppAnalyticsIcon,
	DonorDevelopmentIcon,
	RecurringIcon,
	GiftIcon,
	GivingStatementsIcon,
	ResourceIcon,
	SettingsIcon,
	LockIcon,
	PaymentRequestIcon,
	SandboxSettingsIcon,
	HelpCenterIcon,
} from '@pushpay/iconography';

export const navigationIcons = {
	Dashboard: DashboardIcon,
	Transactions: TransactionsIcon,
	Reconciliation: ReconciliationIcon,
	Finance: FinanceIcon,
	Funds: FundsIcon,
	Campaigns: CampaignsIcon,
	Community: CommunityIcon,
	AppAnalytics: AppAnalyticsIcon,
	DonorDevelopment: DonorDevelopmentIcon,
	Recurring: RecurringIcon,
	GiftEntry: GiftIcon,
	GivingStatements: GivingStatementsIcon,
	ResourceCenter: ResourceIcon,
	Settings: SettingsIcon,
	PushpayAdmin: LockIcon,
	PaymentRequests: PaymentRequestIcon,
	SandboxSettings: SandboxSettingsIcon,
	PushpayAccountsSubMenu: CommunityIcon,
	HelpCenter: HelpCenterIcon,
};
