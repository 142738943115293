import { CheckEditModel } from './allocation-view-model';
import AllocationItemFormStore from './allocation-item-form-store';
import { IAddMemberFormProps } from '../../community-member/views/add-member-form-container';
import { action, observable, computed, decorate } from 'mobx';
import { Models } from '../check-deposit-generated';
import CheckAllocationViewModel = Models.CheckAllocationViewModel;
import { SortDirection } from '../../components/data-grid/data-grid';

export enum SortableColumns {
	ROW_NO,
	GIVER,
	ROUTING_ACCOUNT_NO,
	AMOUNT
}

export class AllocationCheckDetailsListModel {

	@observable CheckDetails: CheckAllocationViewModel[] = null;
	@observable ExpandedCheckStore: AllocationItemFormStore = null;
	@observable SortedBy: SortableColumns = SortableColumns.ROW_NO;
	@observable SortDirection: SortDirection = SortDirection.ASCENDING;
	@observable AddMemberFormProps: IAddMemberFormProps;
	@observable bulkUpdateInProgress = false;
	@observable isProcessingSaveRequest = false;
	@observable isPartialBatch = false;

	@computed
	get ExpandedCheckDetails(): CheckAllocationViewModel {
		if (!this.ExpandedCheckStore) {
			return null;
		}

		return this.CheckDetails.filter(value => value.EncodedToken === this.ExpandedCheckStore.check.EncodedToken)[0];
	}

	@computed
	get disabled() {
		return this.bulkUpdateInProgress || this.isProcessingSaveRequest;
	}

	@computed
	get showOverlay() {
		return this.bulkUpdateInProgress;
	}

	constructor(private markCheckAsRead: (token: string) => void,
		private onSave: (check: CheckEditModel, stats: Models.CheckAllocationStats) => void,
		private onLoadPreviousPaymentDetails: (model: Models.GetLastPaymentDetailsRequestModel) => () => void,
		private getChecks: () => void,
		private getCheck: (encodedToken: string) => void,
		private batch: Models.CheckAllocationBatchDetailsModel) {

		this.isPartialBatch = batch.NumberOfProcessibleChecks < batch.TotalNumberOfChecks;
		this.AddMemberFormProps = {
			enableFeatureOrganizationalGiving: batch.EnableFeatureOrganizationalGiving,
			...batch.AddMemberFormModel
		};
	}

	@computed
	get IncompleteCount(): number {
		if (this.CheckDetails) {
			return this.CheckDetails.filter(x => x.HasMissingData).length;
		}
		return 0;
	}

	@computed
	get SortedCheckDetails(): CheckAllocationViewModel[] {
		if (this.CheckDetails) {
			return this.SortDirection === SortDirection.ASCENDING
				? this.CheckDetails.orderBy(el => this.getSortProperty(el)).toArray()
				: this.CheckDetails.orderByDescending(el => this.getSortProperty(el)).toArray();
		}
		return null;
	}

	@action
	updateChecks = (newChecks: CheckAllocationViewModel[]) => {
		this.CheckDetails = newChecks;

		if (this.ExpandedCheckStore) {
			//reload ExpandedCheckStore
			this.ExpandedCheckStore.updateCheck(this.ExpandedCheckDetails);
		}
	}

	sortColumns(columnToSortBy: SortableColumns) {
		if (columnToSortBy === this.SortedBy) {
			// change sort direction
			this.SortDirection = (this.SortDirection === SortDirection.ASCENDING) ? SortDirection.DESCENDING : SortDirection.ASCENDING;
		} else {
			this.SortDirection = SortDirection.ASCENDING;
			this.SortedBy = columnToSortBy;
		}
	}

	nextUnallocatedCheck() {
		const nextIndex = this.CheckDetails.indexOf(this.ExpandedCheckDetails) + 1;
		const allChecksStartingFromCurrent = this.CheckDetails.slice(nextIndex).concat(this.CheckDetails.slice(0, nextIndex));

		for (let check of allChecksStartingFromCurrent) {
			if (check.HasMissingData) {
				return check;
			}
		}

		return null;
	}

	@action
	endCheckEditing = (checkToken: string = null, currentCheckSaved: boolean = false) => {
		if (this.isProcessingSaveRequest) {
			throw new Error('Cannot end editing another check while processing save request');
		}

		this.ExpandedCheckStore.destroy(currentCheckSaved);
		this.ExpandedCheckStore = null;
		if (!currentCheckSaved && checkToken) {
			this.getCheck(checkToken);
		}
	}

	@action
	handleStartEditing = (checkToken: string, currentCheckSaved: boolean = false) => {
		if (this.isProcessingSaveRequest) {
			throw new Error('Cannot start editing another check while processing save request');
		}

		if (this.ExpandedCheckStore) {
			this.endCheckEditing(checkToken, currentCheckSaved);
		}
		this.startCheckEditing(checkToken);
	}

	@action
	private startCheckEditing = (checkToken: string) => {
		const check = this.CheckDetails.filter(value => value.EncodedToken === checkToken)[0];

		this.ExpandedCheckStore = new AllocationItemFormStore(
			check,
			this.batch.CustomFields,
			this.onSave,
			this.onLoadPreviousPaymentDetails,
			this.batch.BatchId,
			this.batch.PayButtonLabel,
			this.batch.CheckSplittingEnabled,
			this.batch.EnableFeatureOrganizationalGiving,
		);

		if (check && !check.IsRead) {
			check.IsRead = true;
			this.markCheckAsRead(checkToken);
		}
	}

	private getSortProperty(data: CheckAllocationViewModel): string | number {
		switch (this.SortedBy) {
			case SortableColumns.ROW_NO:
				return data.RowNumber;
			case SortableColumns.GIVER:
				return data.CommunityMember ? `${data.CommunityMember.FirstName} ${data.CommunityMember.LastName}`.toLocaleLowerCase() : '';
			case SortableColumns.ROUTING_ACCOUNT_NO:
				return `${data.RoutingNumber} ${data.AccountNumber} ${data.CheckNumber}`.toLocaleLowerCase();
			case SortableColumns.AMOUNT:
				return data.Amount;
			default:
				return data.RowNumber;
		}
	}
}
