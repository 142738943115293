import * as React from 'react';
import { observer } from 'mobx-react';
import { FormControlLabelled, FormControlType } from '../../../components/form-controls/form-control-labelled';
import { ModelMetadata, PaymentLabel } from '../../schedule-import-generated';
import { Fragment } from '../../../../Shared/components/fragment';
import { MigrationContactDetailsViewModel } from './migration-contact-details-view-model';

@observer
export class MigrationContactDetails extends React.Component<{vm: MigrationContactDetailsViewModel, paymentLabel: PaymentLabel} > {
	render() {
		const {
			vm:{
				contactName,
				contactEmail,
				contactPhone,
			},
			paymentLabel
		} = this.props;
		const modelMetadata = ModelMetadata.UpdateMigrationRequest;
		return (
			<Fragment>
				<div className="row">
					<FormControlLabelled label={modelMetadata.ContactName.displayName}
						cssClassNames="col-sm-8"
						tooltipOptions={{
							message: `An individual or a team who can help your ${paymentLabel.PayerPluralLowerCase} with giving queries, i.e. 'The Finance Team'`,
							placement: 'top',
						}}
						formControlProps={{
							name: modelMetadata.ContactName.propertyName,
							formControlType: FormControlType.Text,
							type: 'text',
							value: contactName,
							onChangeHandler: this.updateName,
							validationRules: modelMetadata.ContactName.validationRules,
						}} />
				</div>
				<div className="row">
					<FormControlLabelled label={modelMetadata.ContactEmail.displayName}
						cssClassNames="col-sm-4"
						formControlProps={{
							name: modelMetadata.ContactEmail.propertyName,
							formControlType: FormControlType.Text,
							type: 'text',
							value: contactEmail,
							onChangeHandler: this.updateEmail,
							validationRules: modelMetadata.ContactEmail.validationRules,
						}} />
					<FormControlLabelled label={modelMetadata.ContactPhone.displayName}
						cssClassNames="col-sm-4"
						formControlProps={{
							name: modelMetadata.ContactPhone.propertyName,
							formControlType: FormControlType.Text,
							type: 'text',
							value: contactPhone,
							onChangeHandler: this.updatePhone,
							validationRules: modelMetadata.ContactPhone.validationRules,
						}} />
				</div>
			</Fragment>
		);
	}

	private updateName = (ev: React.FormEvent<HTMLInputElement>) => {
		this.props.vm.updateName(ev.currentTarget.value);
	}

	private updateEmail = (ev: React.FormEvent<HTMLInputElement>) => {
		this.props.vm.updateEmail(ev.currentTarget.value);
	}

	private updatePhone = (ev: React.FormEvent<HTMLInputElement>) => {
		this.props.vm.updatePhone(ev.currentTarget.value);
	}
}
