import * as React from 'react';
import { observer } from 'mobx-react';
import { CampaignStatus } from '../../campaigns-generated';
import { classNames } from '../../../../Shared/utils/classnames';
import { CampaignLabel } from '../../campaign-types';

export interface IStatusLabelProps {
	status: CampaignStatus;
	className?: string;
	acceptanceTestTargetId?: string;
}

export const StatusLabel: React.StatelessComponent<IStatusLabelProps> = observer((props) => {
	const { status, className, acceptanceTestTargetId } = props;

	let content: CampaignLabel;
	let labelClassName: string;

	switch (status) {
		case CampaignStatus.Draft:
			content = 'Draft';
			labelClassName = 'label-draft';
			break;
		case CampaignStatus.Scheduled:
			content = 'Scheduled';
			labelClassName = 'label-warning';
			break;
		case CampaignStatus.Published:
			content = 'Live';
			labelClassName = 'label-success';
			break;
		case CampaignStatus.Closed:
			content = 'Closed';
			labelClassName = 'label-default';
			break;
		default:
			const unsupported: never | CampaignStatus.Unknown | CampaignStatus.Deleted = status;
			throw new Error(`Campaign status ${unsupported} is not supported`);
	}

	return <label className={classNames(className, 'label', labelClassName)} data-pp-at-target={acceptanceTestTargetId}>{content}</label>;
});
