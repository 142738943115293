import * as Models from './transaction-import-generated';
import { observable, action, computed } from 'mobx';
import { AlertProps, AlertType } from './components/alert-control';
import { getTransactionImportDataService, TransactionImportDataServiceAction, TransactionImportApiConfigType, } from './transaction-import-data-service';
import { IDataServiceActionSubscriber } from '../utils/data-service';

export class TransactionImportSummaryViewModel {
	transactionImportId: number;
	paymentLabel: Models.PaymentLabel;
	listingName: string;
	importType: string;
	transactionPeriod: string;
	totalAmount: number;
	transactionsCount: number;
	payersCount: number;
	fundsCount: number;
	newFundsCount: number;
	date: string;
	importedBy: string;
	funds: Models.FundSummaryInfo[];
	updateIntervalId: number;
	backUrl: string;
	indexUrl: string;
	availableTimezones: Models.SelectItem[];
	totalSkippedRowCount: number;
	skippedRowCurrentPage: number = 1;
	supportUrl: string;

	@observable importStatus: Models.ImportStatusForUI;
	@observable alertProps: AlertProps;
	@observable percentComplete: number = 0;
	@observable sendInvitationEmail: boolean;
	@observable updateProgressRequestInProgress: boolean = false;
	@observable navigationInProgress: boolean = false;
	@observable timezoneId: string;
	@observable newPayersCount: number;
	@observable skippedRows: Models.SkippedRowInfo[];
	@observable canLoadMoreSkippedRows: boolean;
	@observable isLoadingMoreSkippedRows: boolean = false;
	@observable isDeletingImport: boolean = false;
	@observable importedAmount: number;
	@observable skippedAmount: number;

	getProgressSubscriber: IDataServiceActionSubscriber<TransactionImportApiConfigType, 'getImportProgress'>;
	confirmImportSubscriber: IDataServiceActionSubscriber<TransactionImportApiConfigType, 'confirmImport'>;
	confirmSendEmailSubscriber: IDataServiceActionSubscriber<TransactionImportApiConfigType, 'confirmSendEmail'>;
	loadMoreSkippedRowsSubscriber: IDataServiceActionSubscriber<TransactionImportApiConfigType, 'loadMoreSkippedRows'>;
	deleteImportSubscriber: IDataServiceActionSubscriber<TransactionImportApiConfigType, 'deleteImport'>;

	constructor(model: Models.TransactionImportSummaryViewModel) {
		this.transactionImportId = model.TransactionImportId;
		this.paymentLabel = model.PaymentLabel;
		this.importStatus = model.ImportStatus;
		this.listingName = model.ListingName;
		this.importType = model.ImportType;
		this.transactionPeriod = model.TransactionPeriod;
		this.timezoneId = model.TimezoneId;
		this.totalAmount = model.TotalAmount;
		this.importedAmount = model.ImportedAmount;
		this.skippedAmount = model.SkippedAmount;
		this.transactionsCount = model.TransactionsCount;
		this.payersCount = model.PayersCount;
		this.newPayersCount = model.NewPayersCount;
		this.fundsCount = model.FundsCount;
		this.newFundsCount = model.NewFundsCount;
		this.date = model.Date;
		this.importedBy = model.ImportedBy;
		this.funds = model.Funds;
		this.skippedRows = model.SkippedRows;
		this.totalSkippedRowCount = model.TotalSkippedRowCount;
		this.canLoadMoreSkippedRows = model.CanLoadMoreSkippedRows;

		this.backUrl = model.BackUrl;
		this.indexUrl = model.IndexUrl;
		this.availableTimezones = model.AvailableTimezones;
		this.sendInvitationEmail = model.SendInvitationEmail;
		this.supportUrl = model.SupportUrl;

		this.getProgressSubscriber = getTransactionImportDataService().getActionSubscriberFactory('getImportProgress')((action) => this.subscribeToGetProgress(action));
		this.confirmImportSubscriber = getTransactionImportDataService().getActionSubscriberFactory('confirmImport')((action) => this.subscribeToConfirmImport(action));
		this.confirmSendEmailSubscriber = getTransactionImportDataService().getActionSubscriberFactory('confirmSendEmail')((action) => this.subscribeToConfirmSendEmail(action));
		this.loadMoreSkippedRowsSubscriber = getTransactionImportDataService().getActionSubscriberFactory('loadMoreSkippedRows')((action) => this.subscribeToLoadMoreSkippedRows(action));
		this.deleteImportSubscriber = getTransactionImportDataService().getActionSubscriberFactory('deleteImport')((action) => this.subscribeToDeleteImport(action));

		if (this.importStatus === Models.ImportStatusForUI.Importing) {
			this.startUpdateProgressPolling();
			this.showImportingInfoAlert();
		}
	}

	@action.bound
	showAlert(type: AlertType, content: string | string[], showCloseButton: boolean) {
		this.alertProps = {
			alertType: type,
			alertContent: content,
			showCloseButton: showCloseButton,
			onClose: () => {
				this.alertProps = null;
			}
		};
	}

	@action.bound
	updateSendEmail(value: boolean) {
		this.sendInvitationEmail = value;
	}

	@action.bound
	updateTimezoneId(value: string) {
		this.timezoneId = value;
	}

	@action.bound
	initGetProgressRequest() {
		this.updateProgressRequestInProgress = true;
		this.getProgressSubscriber.initRequest({
			transactionImportId: this.transactionImportId
		});
	}

	@action.bound
	initConfirmImportRequest() {
		this.navigationInProgress = true;
		this.confirmImportSubscriber.initRequest({
			model: {
				TransactionImportId: this.transactionImportId,
				TimezoneId: this.timezoneId,
				SendInvitationEmail: this.sendInvitationEmail,
			}
		});
	}

	@action.bound
	initConfirmSendEmailRequest() {
		this.confirmSendEmailSubscriber.initRequest({
			transactionImportId: this.transactionImportId
		});
	}

	@action.bound
	initLoadMoreRequest() {
		this.isLoadingMoreSkippedRows = true;
		this.loadMoreSkippedRowsSubscriber.initRequest({
			model: {
				TransactionImportId: this.transactionImportId,
				CurrentPage: this.skippedRowCurrentPage,
			}
		});
	}

	@action.bound
	initDeleteImportRequest() {
		this.isDeletingImport = true;
		this.deleteImportSubscriber.initRequest({
			transactionImportId: this.transactionImportId
		});
	}

	@action.bound
	navigateBack() {
		this.navigationInProgress = true;
		window.location.href = this.backUrl;
	}

	@computed
	get selectedTimezoneLabel() {
		const [selectedItem] = this.availableTimezones.filter(t => t.Value === this.timezoneId);
		return selectedItem.Label;
	}

	private subscribeToLoadMoreSkippedRows(action: TransactionImportDataServiceAction) {
		switch (action.type) {
			case 'request_success':
				const result =  action.response as Models.TransactionImportLoadSkippedRowsResultModel;
				if (result.Result === Models.OperationResult.Success) {
					this.canLoadMoreSkippedRows = result.CanLoadMoreSkippedRows;
					this.totalSkippedRowCount =  result.TotalSkippedRowCount;
					this.skippedRows = this.skippedRows.concat(result.SkippedRows);
					this.skippedRowCurrentPage++;
				}
				break;
		}
		this.isLoadingMoreSkippedRows = false;
	}

	private subscribeToDeleteImport(action: TransactionImportDataServiceAction) {
		switch (action.type) {
			case 'request_success':
				const result =  action.response as Models.TransactionImportOperationResultModel;
				if (result.Result === Models.OperationResult.Success) {
					window.location.href = this.indexUrl;
					this.showAlert(AlertType.Success, 'Your import has been deleted.', true);
					return;
				} else {
					this.showAlert(AlertType.Danger, result.ErrorMessage, true);
				}
				break;
		}
		this.isDeletingImport = false;
	}

	private subscribeToConfirmSendEmail(action: TransactionImportDataServiceAction) {
		switch (action.type) {
			case 'request_success':
				const result =  action.response as Models.TransactionImportOperationResultModel;
				if (result.Result === Models.OperationResult.Success) {
					this.showAlert(AlertType.Success, 'Email notifications will be sent to givers.', true);
				}
				break;
		}
	}

	private subscribeToConfirmImport(action: TransactionImportDataServiceAction) {
		switch (action.type) {
			case 'request_success':
				const result =  action.response as Models.TransactionImportOperationResultModel;
				if (result.Result === Models.OperationResult.Success) {
					this.importStatus = Models.ImportStatusForUI.Importing;
					this.startUpdateProgressPolling();
					this.showImportingInfoAlert();
				} else {
					this.showAlert(AlertType.Danger, result.ErrorMessage, true);
				}
				break;
		}
		this.navigationInProgress = false;
	}

	private showImportingInfoAlert() {
		this.showAlert(AlertType.Info, 'Your import is waiting in line to be processed. We\'ll send you a notification email when the import process is completed.', false);
	}

	private subscribeToGetProgress(action: TransactionImportDataServiceAction) {
		switch (action.type) {
			case 'request_success':
				const result =  action.response as Models.TransactionImportProgressResultModel;
				if (result.Result === Models.OperationResult.Success) {
					if (result.ImportStatus === Models.ImportStatusForUI.Completed) {
						this.alertProps = null;
						this.stopUpdateProgressPolling();
						window.location.reload();
					} else {
						this.percentComplete = result.PercentComplete;
						this.importStatus = result.ImportStatus;
						this.newPayersCount = result.NewPayersCount;
						this.importedAmount = result.ImportedAmount;
						this.skippedAmount = result.SkippedAmount;
					}
				}
				break;
		}
		this.updateProgressRequestInProgress = false;
	}

	private startUpdateProgressPolling() {
		this.initGetProgressRequest();
		this.updateIntervalId = window.setInterval(() => {
			if(this.importStatus === Models.ImportStatusForUI.Importing && !this.updateProgressRequestInProgress) {
				this.initGetProgressRequest();
			}
		}, 20000);
	}

	private stopUpdateProgressPolling() {
		if (this.updateIntervalId) {
			clearInterval(this.updateIntervalId);
		}
	}
}
