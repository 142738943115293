import { isObservable, toJS } from 'mobx';
import { post } from '../utils/ajax-client';
import { AjaxUtils } from '../utils/ajax-utils';
import { VirtualTerminalApiConfig } from './virtual-terminal-generated';
import { ICancellablePromise } from '../utils/cancellable-promise';

export interface IApiAction<TRequest, TResponse = any> {
	readonly url: (model: TRequest) => string;
	request: TRequest;
	response: TResponse;
}

export type VirtualTerminalActionRequest<TActionKey extends keyof VirtualTerminalApiConfigActions> = VirtualTerminalApiConfigActions[TActionKey]['request'];
export type VirtualTerminalActionResponse<TActionKey extends keyof VirtualTerminalApiConfigActions> = VirtualTerminalApiConfigActions[TActionKey]['response'];

export type VirtualTerminalApiConfigActions = typeof VirtualTerminalApiConfig['actions'];

export type VirtualTerminalDataService = {
	[x in keyof VirtualTerminalApiConfigActions]: (request: VirtualTerminalActionRequest<x>) => ICancellablePromise<VirtualTerminalActionResponse<x>>;
};

const options = {
	timeout: 20000,
	newCSRFToken: '',
};

let instance: VirtualTerminalDataService | null = null;

function createActionHandler<TKey extends keyof VirtualTerminalApiConfigActions>(actionKey: TKey) {
	const actionConfig = VirtualTerminalApiConfig.actions[actionKey] as any;

	return (request: any) => {
		request = isObservable(request) ? toJS(request) : { ...request };

		return post(actionConfig.url(request), request, {
			timeout: options.timeout,
			baseUrl: AjaxUtils.resolveBaseUrl(VirtualTerminalApiConfig.defaultBaseUrl),
			antiForgeryToken: options.newCSRFToken
		});
	};
}

function createVirtualTerminalDataService(): VirtualTerminalDataService {
	return (Object.keys(VirtualTerminalApiConfig.actions) as Array<keyof VirtualTerminalApiConfigActions>)
		.reduce<VirtualTerminalDataService>((acc: any, actionKey: keyof VirtualTerminalApiConfigActions) => {
			acc[actionKey] = createActionHandler(actionKey);
			return acc;
		}, {} as VirtualTerminalDataService);
}

export function getVirtualTerminalDataService() {
	if (instance === null) {
		instance = createVirtualTerminalDataService();
	}

	return instance;
}

export function setNewCRSFoken(token: string) {
	options.newCSRFToken = token;
}

export function mockVirtualTerminalDataService(mockDataService: VirtualTerminalDataService) {
	instance = mockDataService;
}
