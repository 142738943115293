import * as React from 'react';

export class NewScreenSwitch extends React.Component<{ vm: { capUrl: string }}, {}> {

	constructor(props) {
		super(props);
	}

	render() {
		const { capUrl } = this.props.vm;
		return (
			<div className='portal-transaction-legacyLinkContainer'>
				<div className='root'>
					<label htmlFor='main-tx-card-legacy-switch' className='wrapper legacySwitch'>
						<input className='field fieldWithIcon' id='main-tx-card-legacy-switch' type='checkbox' placeholder='' autoComplete='off' />
						<a className='switch' href={ capUrl } onClick={this.clickHandler.bind(this)}>
								<span className='thumb'></span>
							</a>
					</label>
				</div>
				<div className='legacyLink'>New experience off</div>
			</div>
		);
	}

	clickHandler() {
		this.writeCookie('transactionScreenPref', 'new', 365);
	}

	writeCookie = function (cname, cvalue, days) {
		let dt, expires;
		dt = new Date();
		dt.setTime(dt.getTime() + (days * 24 * 60 * 60 * 1000));
		expires = "; expires=" + dt.toUTCString();

		let hostName = window.location.hostname;
		hostName = hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1);

		document.cookie = cname + "=" + cvalue + expires + '; domain=' + hostName + '; path=/';
	};
}
