import * as React from 'react';
import { TransitionGroup }from 'react-transition-group-v1';
import { SvgWrapper } from '../../components/svg-wrapper';
import { observer } from 'mobx-react';
import { velocity } from '../../helpers/velocity';

export enum AlertType {
	Success = 0,
	Warning = 1,
	Danger = 2,
	Info = 3
}

export interface AlertProps {
	alertContent?: string | string[] | React.ReactElement<any>;
	alertType?: AlertType;
	onClose?: () => void;
	showCloseButton?: boolean;
}

@observer
export class AlertControl extends React.Component<AlertProps, {}> {

	render() {
		return (
			<TransitionGroup>
				{this.props.alertContent && <Alert {...this.props} />}
			</TransitionGroup>
		);
	}
}

class Alert extends React.Component<AlertProps, {}> {
	private alertElement: HTMLElement;

	componentWillEnter(callback) {
		velocity(this.alertElement, {
			scale: [1, 0.9],
			opacity: [1, 0]
		},
		{
			duration: 366,
			complete: callback,
			easing: 'ease-in'
		});
	}

	componentWillLeave(callback) {
		velocity(this.alertElement, 'fadeOut', {
			duration: 250,
			complete: callback,
			easing: 'ease-in'
		});
	}

	render() {
		const {
			alertType,
			alertContent,
			showCloseButton,
		} = this.props;

		const statusName = AlertType[alertType].toLowerCase();

		return (
			<div className={`alert alert-highlight alert-${statusName}`} ref={(element) => this.alertElement = element}>
				<SvgWrapper className="alert-icon" svg={(`alert-icon-${statusName}`)} />
				<div className="alert-content">
					{this.renderContent(alertContent)}
				</div>
				{showCloseButton &&
					<button type="button" className="btn btn-dismiss" onClick={this.dismissAlert}>
						<SvgWrapper className="svg" svg="close-cross" />
					</button>
				}
			</div>
		);
	}

	renderContent = (alertContent: string | string[] | React.ReactElement<any>) => {
		if (typeof alertContent === 'string' || React.isValidElement<any>(alertContent)) {
			return alertContent;
		} else {
			if (alertContent.length === 1) {
				return alertContent[0];
			} else {
				return (
					<ul className="alert-highlight-validation-list">
						{alertContent.map(message => <li>{message}</li>)}
					</ul>
				);
			}
		}
	}

	dismissAlert = () => {
		this.props.onClose();
	}
}
