import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {ResponseTypes} from '../funds-generated';
import {ModalDialogCommander} from '../../components/modal-dialog-commander';
import {Button} from '../../components/button';
import {ModalDialog, IModalDialogButton} from '../../components/modal-dialog';
import {makeAjaxCall} from '../utils/ajax-helper';
import FundValidationError = ResponseTypes.FundValidationError;

export interface IDeleteFundModalDialogProps {
	urlDelete: string;
	fundDelete: () => JQueryPromise<any>;
}

export let DeleteFundErrorDialog = (error = { ErrorMessage: 'There was a problem deleting the fund' } as FundValidationError) =>
	<ModalDialog title="">{error.ErrorMessage}</ModalDialog>;

export class DeleteFundModalDialog extends React.Component<IDeleteFundModalDialogProps, { isProcessingRequest: boolean }> {
	constructor(props) {
		super(props);
		this.state = { isProcessingRequest: false };
	}

	open() {
		return ModalDialogCommander.open(ReactDOM.findDOMNode(this));
	}

	close() {
		ModalDialogCommander.close(ReactDOM.findDOMNode(this));
	}

	handleDeleteButtonClick() {

		this.setState({
			isProcessingRequest: true
		});

		makeAjaxCall(this.props.urlDelete)
			.fail(error => {
				ModalDialogCommander.showReactDialog(DeleteFundErrorDialog(error));
			})
			.then(() => this.props.fundDelete())
			.always(() => {
				this.close();
				this.setState({
					isProcessingRequest: false
				});
			});
	}

	render() {
		const deleteButton = <Button key="delete_button"
								className="btn btn-primary"
								onClick={() => this.handleDeleteButtonClick()}
								isProcessingRequest={this.state.isProcessingRequest}>Delete fund</Button>;
		const buttons: (IModalDialogButton | JSX.Element)[] = [
			{
				label: 'Cancel',
				dismiss:true,
				isPrimary: false,
				hideOnProgress: true
			},
			deleteButton];
		return <ModalDialog title="" buttons={buttons} inProgress={this.state.isProcessingRequest}>Are you sure you want to delete this fund?</ModalDialog>;
	}
}
