import * as React from 'react';
import { observer } from 'mobx-react';
import { BaseFieldState } from './base-field-state';
import { ITextareaCommonProps, Textarea} from '../primitives/textarea/textarea';
import { FormField, IFormFieldProps } from './form-field';
import { classNames } from '../../utils/classnames';
import { ModelMetadata } from '../../../LoggedInWeb/loggedinweb-generated';

export class TextareaFieldState extends BaseFieldState<string> {
	maxLength: number;

	constructor(initialValue: string, metadata?: ModelMetadata.IPropertyMetadata, skipSanitization = false) {
		super(initialValue, metadata, skipSanitization);
		this.disableAutoValidation();

		if (metadata && metadata.validationRules) {
			const validationRules = metadata.validationRules;
			Object.keys(validationRules).some(key => {
				if (key === 'maxLength') {
					this.maxLength = validationRules[key].parameters.max;
					return true;
				}
				if (key === 'length') {
					this.maxLength = validationRules[key].parameters.max;
					return true;
				}
				return false;
			});
		}
	}
}

export type ITextareaFieldProps = ITextareaCommonProps & IFormFieldProps & { fieldState: TextareaFieldState };

@observer
export class TextareaField extends React.Component<ITextareaFieldProps> {

	render() {
		const { label, className, tooltip, getDescription, additionalClassNames, fieldState, ...rest } = this.props;
		const { value, onChange, enableAutoValidationAndValidate, hasError, error, maxLength } = fieldState;
		const classes = classNames(`form-control`, hasError ? `input-validation-error` : null);

		return (
			<FormField label={label} className={className} tooltip={tooltip} error={error} getDescription={getDescription}>
				<Textarea
					{...rest}
					value={value}
					onBlur={enableAutoValidationAndValidate}
					onChange={onChange}
					ariaInvalid={hasError}
					wrapperClassNames={classes}
					additionalClassNames={additionalClassNames}
					maxLength={maxLength}
				/>
			</FormField>
		);
	}
}
