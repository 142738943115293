import * as React from 'react';
import { observer } from 'mobx-react';
import { IntegrationLabels } from './integration-labels';
import { IIntegrationConfigurationStore } from './integration-configuration-store';
import { Button } from '../../components/button';
import { SvgWrapper } from '../../components/svg-wrapper';

export interface IConfigurationSectionHeaderProps {
	store: IIntegrationConfigurationStore<any>;
}

@observer
export class ConfigurationSectionHeader extends React.Component<IConfigurationSectionHeaderProps, {}> {

	renderEditButton() {
		return (
			<Button className="btn btn-link btn-sm btn-vertically-centered"
				disabled={this.props.store.serviceClient.isProcessingRequest}
				isProcessingRequest={false}
				onClick={this.props.store.startEditingAuthenticationSection}>
				<SvgWrapper svg="icon-edit" className="icon"/>Edit connection details
			</Button>
		);
	}

	render() {
		return (
			<div className="connection-section">
				<h2>
					<div>
						<span>{`Configure ${this.viewModel.Metadata.IntegrationSystem.DisplayName}`}</span>
						<IntegrationLabels labels={this.viewModel.Content.PrimaryLabels} />
					</div>
					<div className="header-buttons hidden-xs hidden-sm">{this.renderEditButton()}</div>
				</h2>
				<div className="visible-xs visible-sm">{this.renderEditButton()}</div>
			</div>
		);
	}

	private get viewModel() {
		return this.props.store.viewModel;
	}
}
