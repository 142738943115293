import * as React from 'react';
export class LoadingBox extends React.Component<{ text: string }, {}> {
	render() {
		const { text } = this.props;

		return (
			<div className="loading-box">
				<img className="loading-box-img" src="/Content/LoggedInWeb/assets/images/loader.png" alt={text || 'Loading'} />
				<div>{text}</div>
			</div>
		);
	}
}
