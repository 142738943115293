import {NodeListHelper} from '../helpers/nodelisthelper';
import {SelectorHelper} from '../helpers/selectorhelper';

export class MoreFiltersButton {
	static bindAll() {
		const showMoreFilterNodes = document.querySelectorAll('[data-action="show-more-filters"]');
		const showMoreFilters = NodeListHelper.toArray<Element>(showMoreFilterNodes);
		showMoreFilters.forEach((filter) => {
			const filterSection = SelectorHelper.closest('.advanced-filters-section', filter);

			if (MoreFiltersButton.filtersAreActive(filterSection)) {
				MoreFiltersButton.expandFilterSection(filterSection);
			} else {
				filter.addEventListener('click', () => {
					MoreFiltersButton.expandFilterSection(filterSection);
				});
			}
		});
	}

	private static expandFilterSection(filterSection: Element) {
		filterSection.classList.add('is-expanded');
	}

	private static filtersAreActive(filterSection: Element) {
		const advancedFilterNodes = filterSection.querySelectorAll('.is-advanced input, .is-advanced select');
		const advancedFilters = NodeListHelper.toArray<any>(advancedFilterNodes);
		return advancedFilters.some(element => element.value !== '');
	}
}
