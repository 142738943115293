import * as React from 'react';
import { observer } from 'mobx-react';
import { responsiveHelper } from '../../helpers/responsive-helper';
import { KeyCodes } from '../../../Shared/helpers/keycodes';
import { velocity } from '../../helpers/velocity';
import { enterTransitionDuration, leaveTransitionDuration } from './data-grid';
import { DataGridContext } from './data-grid-context';

export const dataGridRowPanelMargin = '20px';
export const dataGridRowPanelMobilePosTop = '50px';

@observer
export class DataGridRowPanel extends React.Component<{ dataGridContext: DataGridContext }, {}> {
	private container: HTMLElement;
	private panel: HTMLElement;

	render() {
		return (
			<div className="data-grid-row-panel-container" ref={this.updateContainerNode}>
				<div className="data-grid-row-panel panel panel-default" onKeyDown={this.onKeyDown} tabIndex={0} ref={this.updatePanelNode}>{this.props.children}</div>
			</div>
		);
	}

	componentDidMount() {
		if (responsiveHelper.isXs || responsiveHelper.isSm) {
			return;
		}
		this.scrollWindowToContainer();
	}

	componentWillEnter(callback) {
		let panelStyle = { opacity: 0 };
		let velocityProps = { opacity: 1 };

		if (responsiveHelper.isXs || responsiveHelper.isSm) {
			Object.assign(panelStyle, { top: dataGridRowPanelMobilePosTop });
			Object.assign(velocityProps, { top: 0 });
		} else {
			Object.assign(velocityProps, {
				marginTop: dataGridRowPanelMargin,
				marginBottom: dataGridRowPanelMargin,
			});
		}

		Object.assign(this.panel.style, panelStyle);
		velocity(this.panel, velocityProps, {
			duration: enterTransitionDuration,
			complete: callback,
			easing: 'ease-in-out',
		});
	}

	componentDidEnter() {
		this.panel.focus();
	}

	componentWillLeave(callback) {
		let velocityProps = { opacity: 0 };

		if (responsiveHelper.isMd||responsiveHelper.isLg) {
			Object.assign(velocityProps, {
				marginTop: 0,
				marginBottom: 0,
			});
		}

		if (this.props.dataGridContext.expandedRowKey) {
			callback();
		} else {
			velocity(this.panel, velocityProps, {
				duration: leaveTransitionDuration,
				complete: callback,
				easing: 'ease-in-out',
			});
		}
	}

	componentWillUnmount() {
		velocity(this.container, 'stop', true);
		velocity(this.panel, 'stop', true);
	}

	private scrollWindowToContainer = () => {
		velocity(this.container, 'stop', true);
		velocity(this.container, 'scroll', {
			duration: enterTransitionDuration,
			easing: 'ease-in-out',
			offset: -200,
			// delaying slightly makes the scroll position correct when switching items
			delay: 1,
		});
	}

	private onKeyDown = (event: React.KeyboardEvent<any>) => {
		if (event.keyCode === KeyCodes.Escape) {
			this.props.dataGridContext.updateExpandedRow(null);
		}
	}

	private updateContainerNode = (container) => this.container = container;

	private updatePanelNode = (panel) => this.panel = panel;
}
