import { action, computed, observable } from 'mobx';
import { FieldState } from 'formstate/lib/core/fieldState';
import { FormState } from '../../../../Shared/forms/form-state';
import {
	PledgeEntryViewModel,
	ModelMetadata,
	AddPledgeRequest,
	PledgeAlreadyAddedResponse,
	SearchMemberResult,
	Country,
	PledgeRowViewModel,
	CampaignRequiredViewModel,
	PledgeLabel,
} from '../../campaigns-generated';
import { IPayerSearchResult, IPayerViewModel } from '../../../components/payer-search-omnibox/payer-search-models';
import { pledgeEntryMachineConfig } from './pledge-entry-state-machine';
import { connectMachine } from '../../../../Shared/state-machine/connect-machine';
import { Actions, mapSearchMemberResultToIPayer } from './pledge-entry-states-events-and-actions';
import { MachineContext } from '../../../../Shared/state-machine/saga-state-machine';
import { AmountFieldState } from '../../../../Shared/forms/fields/amount-field';
import { SelectFieldState, SelectOption } from '../../../../Shared/forms/fields/select-field';

@connectMachine(pledgeEntryMachineConfig, Actions.handlers)
export class PledgeEntryMainViewModel {
	@observable	memberSearchValue = '';

	@observable	memberSearchResult: IMemberSearchResult = null;

	@observable alreadyAddedPledge: PledgeAlreadyAddedResponse = null;

	@observable pledges: PledgeRowViewModel[];

	@observable memberFormValidationErrors: { [key: string]: string } = null;

	readonly form: PledgeEntryForm;

	readonly machineContext: MachineContext;

	readonly campaignOptions: SelectOption[];
	readonly listingOptions: SelectOption[];
	readonly defaultCountry: Country;

	readonly campaignRequiredModel: CampaignRequiredViewModel;

	readonly enableFeatureOrganizationalGiving: boolean;

	readonly pledgeLabel: PledgeLabel;

	private searchSkip = 0;

	private readonly searchMembersPageSize: number;

	@computed
	get selectedPayer(): IPayerViewModel {
		return this.form.$.member.$ ? mapSearchMemberResultToIPayer(this.form.$.member.$) : null;
	}

	@computed
	get memberSearchDisplayValue() {
		return this.selectedPayer ? this.selectedPayer.name : this.memberSearchValue;
	}

	@computed
	get payerSearchResult(): IPayerSearchResult {
		const memberSearchResult = this.memberSearchResult;
		if (!memberSearchResult) {
			return { payers: [], hasMorePages: false, error: false };
		}
		const { members, hasMorePages, error } = memberSearchResult;
		return {
			payers: members.map(mapSearchMemberResultToIPayer),
			hasMorePages,
			error,
		};
	}

	constructor(model: PledgeEntryViewModel) {
		this.campaignRequiredModel = model.CampaignRequired;
		this.searchMembersPageSize = model.SearchMembersPageSize;
		this.defaultCountry = model.DefaultCountry;
		this.campaignOptions = model.Campaigns.map(x => ({ label: x.DisplayName, value: x.Id }));
		this.listingOptions = model.Listings.map(x => ({ label: x.DisplayName, value: x.Id }));

		const defaultCampaignId = model.SelectedCampaignId || this.campaignOptions[0] && this.campaignOptions[0].value as number;
		this.form = createPledgeEntryForm(defaultCampaignId);
		this.enableFeatureOrganizationalGiving = model.EnableFeatureOrganizationalGiving;
		this.pledgeLabel = model.PledgeLabel;
	}

	@action.bound
	getNextSearchSkip() {
		this.searchSkip += this.searchMembersPageSize;
		return this.searchSkip;
	}

	@action.bound
	resetSearchSkip() {
		this.searchSkip = 0;
		return this.searchSkip;
	}

	@action.bound
	updateMemberSearchValue(value: string) {
		this.memberSearchValue = value;
		return this.memberSearchValue;
	}

	@action.bound
	updateMemberSearchResult(result: IMemberSearchResult) {
		this.memberSearchResult = result;
	}

	@action.bound
	updatePledges(pledges: PledgeRowViewModel[]) {
		this.pledges = pledges;
	}

	@action.bound
	clearMember() {
		this.memberSearchValue= '';
		this.memberSearchResult = null;
		this.form.$.member.onChange(null);
		this.form.$.amount.onChange(null);
		this.form.$.amount.reset();
	}

	@action.bound
	setAlreadyAddedPledge(pledge: PledgeAlreadyAddedResponse) {
		this.alreadyAddedPledge = pledge;
	}

	@action.bound
	setMemberFormValidationErrors(errors: { [key: string]: string }) {
		this.memberFormValidationErrors = errors;
	}

	@computed
	get fieldsForRequest(): AddPledgeRequest {
		const { campaignId, member, amount} = this.form.$;
		return {
			CampaignId: campaignId.$ as number,
			CommunityMemberId: member.$ && Number(member.$.CommunityMemberId),
			Amount: amount.$,
		};
	}
}

const meta = ModelMetadata.AddPledgeRequest;

function createPledgeEntryForm(defaultCampaignId: number) {
	return new FormState({
		campaignId: new SelectFieldState(defaultCampaignId, meta.CampaignId),
		member: new FieldState<SearchMemberResult>(null),
		amount: new AmountFieldState(null, meta.Amount),
	});
}

type PledgeEntryForm = ReturnType<typeof createPledgeEntryForm>;

export interface IMemberSearchResult {
	members: Array<SearchMemberResult>;
	hasMorePages?: boolean;
	error?: boolean;
}
