import * as React from 'react';
import { observer } from 'mobx-react';
import { classNames } from '../../../../Shared/utils/classnames';

import * as styles from './campaigns-header.less';

@observer
export class CampaignsHeader extends React.Component<{ createCampaignUrl: string }> {
	render() {
		const { createCampaignUrl } = this.props;
		return (
			<header className={classNames(styles.container)}>
				<h1 className={styles.title}>Campaigns</h1>
				<a href={createCampaignUrl} title="Create a new Campaign" className="btn btn-primary" data-pp-at-target="create campaign">Create a new Campaign</a>
			</header>
		);
	}
}
