import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { observer } from 'mobx-react';
import { TooltipOld } from '../tooltip-old';
import { layout } from '../../layout/layout';

@observer
export class TooltipComponent extends React.Component<{text: string}, {}> {
	private tooltip: TooltipOld;

	render() {
		const message = this.props.text;
		return (
			<li data-toggle="tooltip" title={message} data-placement="top">
				{this.props.children}
			</li>
		);
	}

	componentDidMount() {
		this.tooltip = layout().bindTooltip(ReactDOM.findDOMNode(this), 'multi-selected-items');
	}

	componentWillUnmount() {
		layout().unbindTooltip(this.tooltip);
		this.tooltip.close();
	}
}
