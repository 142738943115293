import * as React from 'react';
import { Panel, PanelBody, PanelHeader, PanelType } from '../../../components/panel';
import * as styles from './styles.less';
import { SvgWrapper } from '../../../components/svg-wrapper';

export interface IGiversProps {
	migratedCount: number;
	unreachedCount: number;
	uploadedCount: number;
}

export class Givers extends React.Component<IGiversProps> {
	render() {
		const { migratedCount, unreachedCount, uploadedCount } = this.props;
		return (
			<Panel panelType={PanelType.HIGHLIGHT}>
				<PanelHeader>
					<h1>Givers</h1>
				</PanelHeader>
				<PanelBody extraClassNames={styles.giverPanel}>
					<GiverColumn title="Migrated" svg="icon-migrated" svgWidth={19} svgHeight={18} count={migratedCount} svgClassName={styles.migratedIcon} />
					<GiverColumn title="Could not be reached" svg="icon-error" svgWidth={18} svgHeight={16} count={unreachedCount} svgClassName={styles.errorIcon} />
					<GiverColumn title="Uploaded" svg="icon-clipboard" svgWidth={14} svgHeight={21} count={uploadedCount} />
				</PanelBody>
			</Panel>
		);
	}
}

interface IGiverColumnProps {
	title: string;
	svg: string;
	svgWidth: number;
	svgHeight: number;
	svgClassName?: string;
	count: number;
}

class GiverColumn extends React.Component<IGiverColumnProps> {
	render() {
		const { title, svg, svgHeight, svgWidth, svgClassName, count } = this.props;

		return (
			<div className={styles.giverColumn}>
				<div className={styles.giverIcon}><SvgWrapper svg={svg} width={svgWidth} height={svgHeight} className={svgClassName} /></div>
				<div className={styles.giverDetails}>
					<h2 className={styles.giverHeading}>{title}:</h2>
					<div className={styles.count}>{count}</div>
				</div>
			</div>
		);
	}
}
