import * as React from 'react';
import { observer } from 'mobx-react';
import { SimpleMatchModal } from '../../../components/match-modal';
import { Fragment } from '../../../../Shared/components/fragment';

interface CampaignsSaveConfirmationModalProps {
	state: string | string[];
	message: string;
	onConfirm: () => void;
	onCancel: () => void;
}

export const CampaignsSaveConfirmationModal = observer(({ state, message, onConfirm, onCancel }: CampaignsSaveConfirmationModalProps) => (
	<SimpleMatchModal
		type="warning"
		state={state}
		getBody={() => <div>{message}</div>}
		getFooter={() => (
			<Fragment>
				<button className="btn btn-primary" onClick={onConfirm}>Confirm</button>
				<button className="btn btn-cancel" onClick={onCancel}>Cancel</button>
			</Fragment>
		)} />
));
