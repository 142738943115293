import * as React from 'react';
import { observable, runInAction, action } from 'mobx';
import { observer } from 'mobx-react';

import { Fragment } from '../../../Shared/components/fragment';
import { classNames } from '../../../Shared/utils/classnames';
import { isFunction } from '../../utils/is-function';
import { getElementId } from '../form-controls/form-control-utils';

import { IFormControlCommonProps } from '../form-controls/form-control-common-props';
import { ValidatableControl } from '../form-controls/validatable-control';
import * as styles from './file-upload-styles.less';
import { FileUploadAreaBorder } from './file-upload-area-border';
import { FileUploadState, FileUploadStateError, FileUploadViewState } from './file-upload-state';

export interface IFileUploadPanelProps extends IFormControlCommonProps {
	fileAccept: string;
	ariaLabel: string;
	isDragOver: boolean;
	updateIsDragOver: (isDragOver: boolean) => void;
	onChangeHandler?: (file: File | null) => void;
	fileUploadState: FileUploadState;
}

@observer
export class FileUploadPanel extends React.Component<IFileUploadPanelProps> {

	private inputRef: HTMLInputElement;

	render() {
		const {
			name,
			disabled,
			fileUploadState,
			acceptanceTestTargetId = '',
			fileAccept = '*/*',
			tabIndex,
			uniqueSuffix,
			ariaLabel,
			validationRules,
			isDragOver,
		} = this.props;

		const id = getElementId(name, uniqueSuffix);
		const hasError = fileUploadState.viewState === FileUploadViewState.Error;
		const visible = fileUploadState.viewState !== FileUploadViewState.FileSelected;

		return (
			<Fragment>
				<label
					htmlFor={id}
					className={classNames('upload-area', styles.uploadArea, isDragOver && styles.active, hasError && styles.error, !visible && styles.hidden)}
					onDrop={this.onDrop}
				>
					<FileUploadAreaBorder active={!!isDragOver} />
					<span className={styles.content}>{'Drag and drop a file or '}</span>
					<span className={styles.link} tabIndex={0} onKeyPress={this.handleSpaceEnterKeyPress(() => this.inputRef.click())}>browse your files</span>
					<ValidatableControl
						validationRules={validationRules}
						elementName={name}
						validateOnChange={true}
					>
						<input
							type="file"
							accept={fileAccept}
							onClick={this.handleClick}
							onChange={this.onFileChangeHandler}
							id={id}
							name={name}
							className={styles.input}
							disabled={disabled}
							tabIndex={tabIndex}
							data-pp-at-target={acceptanceTestTargetId}
							aria-label={ariaLabel}
							ref={ref => this.inputRef = ref}
						/>
					</ValidatableControl>
				</label>
				{
					hasError && <span className={'field-validation-error ' + styles.errorText}>
						{(fileUploadState as FileUploadStateError).uploadError}
					</span>
				}
			</Fragment>
		);
	}

	private handleSpaceEnterKeyPress = (callback: () => void) => (evt: React.KeyboardEvent<any>) => {
		if (evt.key === 'Space' || evt.key === 'Enter') {
			callback();
		}
	}

	private onDrop = (evt: React.DragEvent<HTMLElement>) => {
		const { updateIsDragOver } = this.props;

		evt.preventDefault();
		updateIsDragOver(false);
		this.removeExistingFile();

		// Change this.inputRef.files programmatically and trigger input change event, so jQuery validation is called.
		// Note: assignment of this.inputRef.files works in Chrome, Safari and Firefox, but not in Edge, IE 11. Edge will
		// have a fix for it soon, see https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/18069907/.
		const prototype = Object.getPrototypeOf(this.inputRef);
		if (Object.getOwnPropertyDescriptor(prototype, 'files').set) {
			this.inputRef.files = evt.dataTransfer.files;
			const changeEvent = document.createEvent('Event');
			changeEvent.initEvent('change', true, true);
			this.inputRef.dispatchEvent(changeEvent);
		} else { // In the case when the assignment of this.inputRef.files doesn't work, it relies on the server side validation.
			this.onChangeFile(evt.dataTransfer.files);
		}
	}

	private onChangeFile(files: FileList) {
		const { onChangeHandler } = this.props;
		if (files.length === 0 || !files[0] || !isFunction(onChangeHandler)) {
			return;
		}
		onChangeHandler(files[0]);
	}

	private removeExistingFile = () => {
		const { onChangeHandler } = this.props;
		if (isFunction(onChangeHandler)) {
			this.props.onChangeHandler(null);
		}
	}

	private onFileChangeHandler = (evt: React.FormEvent<HTMLInputElement>) => {
		this.onChangeFile(evt.currentTarget.files);
	}

	private handleClick = (evt: React.FormEvent<HTMLInputElement>) => {
		// Choose the same file doesn't trigger onChange event, the user might modify a file to meet upload requirements,
		// we need to allow them to upload in this case, so we clear input value each time the user wants to select a file, which makes
		// the onChange event is always triggered even if the user selects the same file.
		evt.currentTarget.value = '';
	}
}
