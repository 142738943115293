import * as React from 'react';
import {observer} from 'mobx-react';
import { FormControlLabelled, FormControlType } from '../../../components/form-controls/form-control-labelled';
import { velocity } from '../../../helpers/velocity';
import { classNames } from '../../../../Shared/utils/classnames';
import { BatchListStore } from '../batch-list-store';
import { Models } from '../../check-deposit-generated';
import BatchStatusFilter = Models.BatchStatusFilter;
import MultiSelectItem = Models.MultiSelectItem;
import { FormControlDateRangeLabelled } from '../../../components/form-controls/form-control-date-range-labelled';

import * as styles from './batch-list-filter-panel.less';

@observer
export class BatchListFilterPanel extends React.Component<{ store: BatchListStore }> {
	private filterPanel: HTMLDivElement;

	render() {
		const store = this.props.store;
		const { queryModel } = this.props.store;

		const inProgressTabSelected = queryModel.Status !== BatchStatusFilter.Closed;

		return (
			<div ref={this.saveFilters}>
				<ListingFilter store={store} />
				{inProgressTabSelected
					? <StatusFilter store={store} />
					: <DateRangeFilter store={store} />}
			</div>
		);
	}

	componentWillEnter(callback) {
		velocity(this.filterPanel, 'fadeIn', {
			duration: 250,
			complete: callback,
			easing: 'ease-in-out',
		});
	}

	componentWillLeave(callback) {
		velocity(this.filterPanel, 'fadeOut', {
			duration: 150,
			complete: callback,
			easing: 'ease-in-out',
		});
	}

	saveFilters = (filterPanel: HTMLDivElement) => this.filterPanel = filterPanel;
}

@observer
export class ListingFilter extends React.Component<{store: BatchListStore}> {
	render() {
		const { listings, selectedlistingIdsAsStrings } = this.props.store;
		return(
			<FormControlLabelled
				formControlProps={{
					formControlType: FormControlType.MultiSelect,
					items: listings,
					name: 'listing',
					selectedValues: selectedlistingIdsAsStrings,
					onChangeHandler: this.onChangeHandler,
					acceptanceTestTargetId: 'Listing filter',
				}}
				label="Listing"
				cssClassNames="col-lg-4 col-md-4" />
		);
	}

	private onChangeHandler = (selectedListingIds: string[]) => {
		this.props.store.updateSelectedListings(selectedListingIds);
	}
}

@observer
export class StatusFilter extends React.Component<{store: BatchListStore}> {
	readonly statusOptions = [
		option('Open', BatchStatusFilter.Pending),
		option('Ready to allocate', BatchStatusFilter.Active)
	];

	render() {
		const { queryModel } = this.props.store;
		return(
			<FormControlLabelled
				formControlProps={{
					formControlType: FormControlType.MultiSelect,
					items: this.statusOptions,
					name: 'status',
					onChangeHandler: this.onChangeHandler,
					acceptanceTestTargetId: 'Status filter',
				}}
				label="Status"
				cssClassNames="col-lg-4 col-md-4" />
		);
	}

	private onChangeHandler = (selectedStatuses: string[]) => {
		const converted = selectedStatuses.map(x => Number(x) as BatchStatusFilter);
		const status = converted.length === 1
			? converted[0]
			: BatchStatusFilter.PendingOrActive;
		this.props.store.updateStatus(status);
	}
}

const option = (text: string, value: BatchStatusFilter): MultiSelectItem => ({
	Text: text,
	Value: `${value}`,
	SelectedText: null,
	Disabled: false,
});

@observer
export class DateRangeFilter extends React.Component<{store: BatchListStore}> {
	render() {
		return(
			<FormControlDateRangeLabelled cssClassNames={`list-group-filter col-lg-4 ${styles.filterDateRange}`}
				fromInputName="from-filter"
				toInputName="to-filter"
				value={this.props.store.dateRange}
				onChangeHandler={this.props.store.updateDateRange} />
		);
	}
}
