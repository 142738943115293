import * as React from 'react';
import Accordion from '@pushpay/accordion';
import { LoggedInWebThemeProvider } from '../../../components/theming';
import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming/theme/giving';
import { ComponentProps } from '@pushpay/types';
import { FormControlLabelled, FormControlType } from '../../../components/form-controls/form-control-labelled';
import * as styles from './styles.less';

export type IKeywordsListProps = {
	vm: any;
};

const useStyles = createUseStyles((theme: Theme) => {
	return {
		root: {
			borderTopColor: 'transparent',
			backgroundColor: 'transparent',
		},
		title: {
			backgroundColor: 'transparent',
			paddingTop: '10px',
			paddingBottom: '10px',
		},
		content: {
			backgroundColor: 'transparent',
			borderBottomColor: 'transparent',
		},
	};
});

export const KeywordsAccordion = ({
	vm: data,
	classes: classesProp,
	'data-pp-at-target': targetId,
}: ComponentProps<IKeywordsListProps, typeof useStyles>) => {
	const classes = useStyles(classesProp, undefined);
	const { MerchantSmsKeywords, DefaultListing } = data;

	var items = Object.keys(MerchantSmsKeywords).map((merchant, i) => {
		return { key: `${merchant}-${i}`, merchantName: merchant, keywords: MerchantSmsKeywords[merchant] };
	});

	const renderTitle = (item) => {
		return item.merchantName;
	};

	const renderContent = (item) => {
		return (
			<>
				<h6>
					<strong>{DefaultListing.PayButtonLabel.ActionPluralSentenceCase} Keywords</strong>
				</h6>
				{item.keywords.map((keyword, i) => (
					<div key={i}>
						<FormControlLabelled
							cssClassNames={styles.clipboard}
							label={keyword}
							formControlProps={{
								name: keyword,
								value: keyword,
								formControlType: FormControlType.Clipboard,
								placeholder: '',
								cssClassNames: styles.input,
							}}
							screenReaderOnlyLabel
						/>
					</div>
				))}
			</>
		);
	};

	return (
		<>
			<Accordion
				classes={{
					root: classes.root,
					title: classes.title,
					content: classes.content,
				}}
				items={items}
				renderTitle={renderTitle}
				renderContent={renderContent}
				getKey={(item) => item.key}
				showArrowOnlyOnHover={false}
				data-pp-at-target={`${targetId}-accordion`}
			/>
		</>
	);
};

export const KeywordsList: React.FC<IKeywordsListProps> = (props: IKeywordsListProps) => {
	return (
		<>
			<LoggedInWebThemeProvider>
				<div className={styles.keywordsList}>
					<KeywordsAccordion vm={props.vm} data-pp-at-target="keywords-list" />
				</div>
			</LoggedInWebThemeProvider>
		</>
	);
};
