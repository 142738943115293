import * as React from 'react';
import { observer } from 'mobx-react';
import { ModalDialog, IModalDialogButton } from '../../../components/modal-dialog';
import { PaymentMethod } from './payment-entry-model';
import { Channel } from '../../../utils/saga-utils';
import { VirtualTerminalUserAction } from '../../pages/single/single-entry-user-actions';
import { VirtualTerminalFormViewModel } from '../form/virtual-terminal-form-view-model';

@observer
export class PaymentMethodRemoveDialog extends React.Component<{
	paymentMethod: PaymentMethod,
	formViewModel: VirtualTerminalFormViewModel,
	userActionChannel: Channel<VirtualTerminalUserAction>
}, {}> {
	render() {
		const { processingRemovePaymentMethod } = this.props.formViewModel;

		const buttons: (IModalDialogButton | JSX.Element)[] = [
			{ label: 'No', dismiss: true, isPrimary: false, hideOnProgress: true },
			<button type="button" key="confirm" className="btn btn-default" onClick={this.handleRemoveClick} disabled={processingRemovePaymentMethod}>
				Yes
			</button>
		];
		return (
			<ModalDialog title="Remove payment method" buttons={buttons} showAlert={false} inProgress={processingRemovePaymentMethod}>
				This payment method is used for 1 or more recurring schedules. Removing this payment method will cancel those schedules. Are you sure you want to remove it?
			</ModalDialog>
		);
	}

	handleRemoveClick = () => {
		const { userActionChannel } = this.props;
		userActionChannel.put(new VirtualTerminalUserAction.RemovePaymentMethodConfirmed());
	}
}
