import * as React from 'react';
import { reaction, IReactionDisposer } from 'mobx';
import { observer, Provider } from 'mobx-react';
import { MemberFormFields } from './member-form-fields';
import { MemberFormFooter } from './member-form-footer';
import { MemberFormViewModel } from './member-form-view-model';
import { SelectOption } from '../../../Shared/forms/primitives/select';
import { EditCommunityMemberRequestModel, Country } from '../../loggedinweb-generated';
import { MatchModal, ModalCancelButton } from '../../components/match-modal';
import { Form } from '../../../Shared/forms/primitives/form';

export type MemberModalDialogProps = MemberFormModalCommonProps & (MemberFormCreateProps | MemberFormEditProps);

type MemberFormModalCommonProps = {
	state: string | string[];
	onSave: (model: { merchantId: number, model: EditCommunityMemberRequestModel } | { model: EditCommunityMemberRequestModel }) => void;
	onCancel: () => void;
	processing?: boolean;
	validationErrors?: { [key: string]: string };
	enableFeatureOrganizationalGiving: boolean;
};

export type MemberFormCreateProps = {
	mode: 'create';
	firstName: string;
	lastName?: string;
	defaultCountry: Country;
	listingId?: number;
	listings: SelectOption[];
};

export type MemberFormEditProps = {
	mode: 'edit';
	memberDetails: Partial<EditCommunityMemberRequestModel>;
};

@observer
export class MemberFormMatchModal extends React.Component<MemberModalDialogProps> {
	render() {
		const { state } = this.props;
		return (
			<MatchModal state={state} type="form">
				<MemberFormModalInner {...this.props} />
			</MatchModal>
		);
	}
}

@observer
class MemberFormModalInner extends React.Component<MemberModalDialogProps> {
	readonly memberFormViewModel: MemberFormViewModel;
	private disposeUpdateContext: IReactionDisposer;

	constructor(props) {
		super(props);
		const { state, children, processing, onCancel, onSave, validationErrors, enableFeatureOrganizationalGiving, ...rest } = this.props;
		this.memberFormViewModel = new MemberFormViewModel({ ...rest });
	}

	componentDidMount() {
		this.disposeUpdateContext = reaction(() => this.props.validationErrors, this.updateErrors);
	}

	componentWillUnmount() {
		this.disposeUpdateContext();
	}

	updateErrors = (validationErrors: { [key: string]: string }) => {
		if (validationErrors) {
			this.memberFormViewModel.form.setErrors(validationErrors);
		}
	}

	render() {
		const { onCancel, processing, mode, enableFeatureOrganizationalGiving } = this.props;
		const title = mode === 'create' ? 'Add new member' : 'Edit member';
		const listings = this.props.mode === 'create' ? this.props.listings : null;

		return (
			<Provider memberFormViewModel={this.memberFormViewModel}>
				<Form onSubmit={this.onSubmit} formState={this.memberFormViewModel.form} disabled={processing}>
					<ModalCancelButton disabled={processing} onCancel={this.onCancel} />
					<div className="panel-heading">{title}</div>
					<MemberFormFields listings={listings} enableFeatureOrganizationalGiving={enableFeatureOrganizationalGiving} />
					<MemberFormFooter onCancel={onCancel} />
				</Form>
			</Provider>
		);
	}

	onSubmit = (result) => {
		if (!result.hasError) {
			this.props.onSave(this.memberFormViewModel.requestModel);
		}
	}

	onCancel = () => {
		this.props.onCancel();
	}
}
