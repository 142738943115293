import * as React from 'react';
import moment from 'moment';
import { DayOfWeek, EventTimeViewModel } from '../../loggedinweb-generated';

export function eventTimeForDisplay(eventTime: EventTimeViewModel): string {
	const { StartTime, EndTime, Name, DayOfWeek: eventDayOfWeek } = eventTime;

	return `${DayOfWeek[eventDayOfWeek]}, ${formatTimeForDisplay(StartTime)} - ${formatTimeForDisplay(EndTime)} (${Name})`;
}

export const EventTimeForDisplayNarrow = (props: { eventTime: EventTimeViewModel }): JSX.Element => {
	const { eventTime: { StartTime, EndTime, Name, DayOfWeek: eventDayOfWeek }} = props;

	return (
		<span>
			{DayOfWeek[eventDayOfWeek]}, {formatTimeForDisplay(StartTime)} - {formatTimeForDisplay(EndTime)} <span className="small">({Name})</span>
		</span>
	);
};

export const formatTimeForDisplay = (time: string) => moment(time, 'HH:mm').format('h:mma');

