import * as React from 'react';
import { observer } from 'mobx-react';
import { Button } from '../../components/button';
import { ModalDialog, IModalDialogButton } from '../../components/modal-dialog';
import { IntegrationServiceClient } from './integration-service-client';

export interface IEnableIntegrationAjaxModalDialogProps {
	store: IntegrationServiceClient;
	proceedEnablingIntegration: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

@observer
export class EnableIntegrationAjaxModalDialog extends React.Component<IEnableIntegrationAjaxModalDialogProps, {}> {
	render() {
		const enableButton = <Button key="enable_button"
			className="btn btn-primary"
			onClick={this.props.proceedEnablingIntegration}
			isProcessingRequest={this.props.store.isEnabling}
			disabled={this.props.store.isProcessingRequest}>Confirm</Button>;

		const buttons: (IModalDialogButton | JSX.Element)[] = [
			{
				label: 'Cancel',
				dismiss: true,
				isPrimary: false,
				hideOnProgress: true
			},
			enableButton];

		const thirdPartyMessage = <p>
			By enabling the integration, you are integrating your
			Pushpay data with an unaffiliated third party service. You should ensure that you are
			comfortable with the third party's terms and privacy policies, which may vary from those of Pushpay.
		</p>;

		return (
			<ModalDialog title="Are you sure you want to enable this integration?"
				buttons={buttons}
				hideCloseButton={true}
				inProgress={this.props.store.isEnabling}
				showAlert={false}>
				{this.props.store.integrationIsThirdParty && thirdPartyMessage}
			</ModalDialog>
		);
	}
}
