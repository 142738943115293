import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { CountryData } from '../../loggedinweb-generated';
import { TextBoxField } from '../../../Shared/forms/fields/textbox-field';
import { SelectField, SelectOption } from '../../../Shared/forms/fields/select-field';
import { RadioButtonField } from '../../../Shared/forms/fields/radio-button-field';
import { MemberFormViewModel } from './member-form-view-model';
import { ModelMetadata } from '../../loggedinweb-generated';
import { Models } from '../../community-member/community-member-generated';

const { FirstName, LastName, EmailAddress, MobileNumber, Address } =  ModelMetadata.EditCommunityMemberRequestModel;
const { CommunityMemberType } = Models;
const CommunityMemberTypeOptions = [{
	label: 'Person',
	value: `${CommunityMemberType.Individual}`,
}, {
	label: 'Organization',
	value: `${CommunityMemberType.Organization}`,
}];

interface IMemberFormFieldsProps {
	listings?: SelectOption[];
	memberFormViewModel?: MemberFormViewModel;
	enableFeatureOrganizationalGiving: boolean;
}

@inject('memberFormViewModel')
@observer
export class MemberFormFields extends React.Component<IMemberFormFieldsProps> {

	render() {
		const { memberFormViewModel, listings, enableFeatureOrganizationalGiving } = this.props;
		const { form, mode } = memberFormViewModel;
		const showListings = listings && listings.length > 1;
		var organizationalGivingEnabled = enableFeatureOrganizationalGiving;

		return (
			<div className="panel-body">
				{renderOrganizationTypeRow(form, mode, organizationalGivingEnabled)}
				{
					showListings &&
					<div className="row">
						<SelectField
							fieldState={form.$.listing}
							label="Listing"
							placeholder="Select listing"
							options={listings}
							className="col-sm-12"
							acceptanceTestTargetId="listing"
						/>
					</div>
				}
				{
					organizationalGivingEnabled && form.$.communityMemberType.value === String(CommunityMemberType.Organization)
					?
					<div className="row">
						<TextBoxField
							fieldState={form.$.firstName}
							label="Organization name"
							className="col-sm-12"
							acceptanceTestTargetId="first name"
						/>
					</div>
					:
					<div className="row">
						<TextBoxField
							fieldState={form.$.firstName}
							label={FirstName.displayName}
							className="col-sm-6 member-form-input-first-name"
							acceptanceTestTargetId="first name"
						/>
						<TextBoxField
							fieldState={form.$.lastName}
							label={LastName.displayName}
							className="col-sm-6"
							acceptanceTestTargetId="last name"
						/>
					</div>
				}
				<div className="row">
					<TextBoxField
						fieldState={form.$.emailAddress}
						label={EmailAddress.displayName}
						className="col-sm-6"
						acceptanceTestTargetId="email address"
					/>
					<TextBoxField
						fieldState={form.$.mobileNumber}
						label={MobileNumber.displayName}
						className="col-sm-6"
						acceptanceTestTargetId="mobile number"
					/>
				</div>
				<div className="row">
					<TextBoxField
						fieldState={form.$.addressLine1}
						label={Address.displayName}
						placeholder={Address.modelMetadata.Line1.placeholder}
						className="col-xs-12"
						acceptanceTestTargetId="address line 1"
					/>
					<TextBoxField
						fieldState={form.$.addressLine2}
						placeholder={Address.modelMetadata.Line2.placeholder}
						className="col-xs-12"
						acceptanceTestTargetId="address line 2"
					/>
					<TextBoxField
						fieldState={form.$.city}
						placeholder={Address.modelMetadata.City.placeholder}
						className="col-xs-12"
						acceptanceTestTargetId="city"
					/>
					<StateField form={form} />
					<TextBoxField
						fieldState={form.$.postcode}
						className="col-xs-6 col-sm-3"
						placeholder={Address.modelMetadata.Postcode.placeholder}
						acceptanceTestTargetId="post code"
					/>
					<CountryField form={form} />
				</div>
			</div>
		);
	}
}

function renderOrganizationTypeRow(form, mode, organizationalGivingEnabled: boolean) {
	if(organizationalGivingEnabled) {
		return (<div className="row">
		<RadioButtonField
			fieldState={form.$.communityMemberType}
			label="Type"
			options={CommunityMemberTypeOptions}
			className="col-xs-12"
			disabled={mode === 'edit'}
			acceptanceTestTargetId="type"
		/>
	</div>);
	}
	return null;
}

const StateField = observer(props => {
	const { form } = props;
	const [ country ] = CountryData.filter(x => x.countryCode === Number(form.$.country.value));
	const countryStates = country && country.subRegions;
	const stateOptions = countryStates ? countryStates.map(x => ({ label: x.Name, value: `${x.Code}` })) : [];
	return countryStates
		? <SelectField
			fieldState={form.$.state}
			className="col-xs-12 col-sm-9"
			placeholder="Select state"
			options={stateOptions}
			acceptanceTestTargetId="state"
		/>
		: <TextBoxField
			fieldState={form.$.state}
			className="col-xs-12 col-sm-9"
			placeholder={Address.modelMetadata.State.placeholder}
			acceptanceTestTargetId="state"
		/>;
});

const CountryField = observer(props => {
	const { form } = props;
	const countryOptions = CountryData.map((country) => ({
		label: country.displayName,
		value: `${country.countryCode}`
	}));
	return <SelectField
		fieldState={form.$.country}
		options={countryOptions}
		className="col-xs-12"
		acceptanceTestTargetId="country"
	/>;
});
