import * as React from 'react';
import { observer } from 'mobx-react';
import { IFormControlCommonProps } from './form-control-common-props';
import { ValidatableControl } from './validatable-control';
import { getElementId } from './form-control-utils';
import { createNamespace } from '../../../Shared/helpers/namespace';
import { IFormControlLabelledTooltipProps } from './form-control-labelled';
import { Tooltip, TooltipContent } from '../tooltip/tooltip';
import { SvgWrapper } from '../svg-wrapper';
import { classNames } from '../../../Shared/utils/classnames';
import { FormControlFrequencyGrid } from './form-control-frequency-grid/form-control-frequency-grid';

const ns = createNamespace('form-control-radio');

export interface IFormControlRadioOptionProps {
	Label: string;
	Value: string;
	tooltipOptions?: IFormControlLabelledTooltipProps;
	disabled?: boolean;
	radioButtonClassName?: string;
}

export enum RadioLayoutType {
	InlineRadio,
	Grid,
	RadioSection,
	FrequencyGrid,
}

export interface IFormControlRadioProps extends IFormControlCommonProps {
	Options: IFormControlRadioOptionProps[];
	onChangeHandler: (value: string) => void;
	value: string;
	layoutType?: RadioLayoutType;
	radioItemClassName?: string;
	ariaLabel?: string;
}

@observer
export class FormControlRadio extends React.Component<IFormControlRadioProps, {}> {
	render() {
		const { isInvalid, name, validationRules } = this.props;

		if (validationRules) {
			console.warn(
				"Warning: FormControlRadio doesn't support validation rules yet. jQueryValidate will not validate the field."
			);
		}

		return (
			<ValidatableControl
				validationRules={validationRules}
				elementName={name}
				revalidateOnChange={false}
				isInvalid={isInvalid}
			>
				{this.renderOptions()}
			</ValidatableControl>
		);
	}

	private renderOptions() {
		const {
			Options,
			radioItemClassName,
			uniqueSuffix,
			value,
			ariaLabel,
			acceptanceTestTargetId,
			disabled,
			name,
			onChangeHandler,
		} = this.props;
		const layoutType = this.props.layoutType || RadioLayoutType.InlineRadio;
		switch (layoutType) {
			case RadioLayoutType.InlineRadio:
			case RadioLayoutType.Grid:
				return this.renderOpitonsAsInlineOrGrid();
			case RadioLayoutType.RadioSection:
				return this.renderOptionsAsSection();
			case RadioLayoutType.FrequencyGrid:
				return (
					<FormControlFrequencyGrid
						Options={Options}
						radioItemClassName={radioItemClassName}
						value={value}
						id={getElementId(name, uniqueSuffix)}
						ariaLabel={ariaLabel}
						acceptanceTestTargetId={acceptanceTestTargetId}
						disabled={disabled}
						name={name}
						onChangeHandler={onChangeHandler}
					/>
				);
			default:
				return null;
		}
	}

	private getContainerProperties() {
		const { ariaLabel, name, uniqueSuffix } = this.props;

		return {
			id: getElementId(name, uniqueSuffix),
			role: 'radiogroup',
			'aria-label': ariaLabel,
		};
	}

	private renderInput(optionValue: string, optionDisabled: boolean) {
		const { acceptanceTestTargetId, disabled, name, value } = this.props;

		return (
			<input
				className={ns('input')}
				type="radio"
				name={name}
				value={optionValue}
				checked={value === optionValue}
				onChange={this.onChangeHandler}
				data-pp-at-target={`${acceptanceTestTargetId} ${optionValue}`}
				disabled={optionDisabled || disabled}
			/>
		);
	}

	private renderOpitonsAsInlineOrGrid() {
		const { layoutType, Options, radioItemClassName } = this.props;
		const gridStyle = layoutType === RadioLayoutType.Grid;
		return (
			<div {...this.getContainerProperties()} className={classNames(ns(), gridStyle && 'radio-grid')}>
				{Options.map(({ Label, Value, tooltipOptions, disabled: optionDisabled, radioButtonClassName }) => (
					<label
						key={Value}
						className={classNames(
							'radio-inline',
							radioItemClassName,
							tooltipOptions ? 'radio-with-tooltip' : '',
							radioButtonClassName
						)}
					>
						{this.renderInput(Value, optionDisabled)}
						<span className={classNames(ns('button'), 'radio-inner')}>
							<span className={ns('button-content')}>{Label}</span>
						</span>
						{tooltipOptions && <RadioItemTooltip {...tooltipOptions} />}
					</label>
				))}
			</div>
		);
	}

	private renderOptionsAsSection() {
		const { Options, radioItemClassName, value } = this.props;

		return (
			<div {...this.getContainerProperties()} className="radio-section-container">
				{Options.map(({ Label, Value, tooltipOptions, disabled: optionDisabled, radioButtonClassName }) => (
					<label key={Value} className={classNames('radio-item', radioItemClassName, radioButtonClassName)}>
						{this.renderInput(Value, optionDisabled)}
						<div className="radio-circle">
							{value === Value && <div className="radio-circle-checked"></div>}
						</div>
						<span className={classNames(ns('button-content'), 'radio-text')}>{Label}</span>
						{tooltipOptions && (
							<div className="radio-tooltip-container">
								{' '}
								<RadioItemTooltip {...tooltipOptions} />
							</div>
						)}
					</label>
				))}
			</div>
		);
	}

	private onChangeHandler = (ev: React.FormEvent<HTMLInputElement>) => {
		this.props.onChangeHandler(ev.currentTarget.value);
	};
}

@observer
export class RadioItemTooltip extends React.Component<IFormControlLabelledTooltipProps, { visible: boolean }> {
	constructor(props) {
		super(props);
		this.state = { visible: false };
	}

	render() {
		const { tooltipContentClassName, className, placement, message } = this.props;
		return (
			<span className="info-tooltip radio-tooltip">
				<Tooltip
					label="more information"
					visible={this.state.visible}
					onVisibleChange={this.handleVisibleChange}
					tooltipClassName={className}
					placement={placement}
				>
					<SvgWrapper svg="icon-info" className="icon tooltip-icon" />
					<TooltipContent>
						<div className={tooltipContentClassName}>{message}</div>
					</TooltipContent>
				</Tooltip>
			</span>
		);
	}

	private handleVisibleChange = (visible: boolean) => {
		this.setState({ visible: visible });
	};
}
