import * as React from 'react';
import { SmoothHeightTransition } from '../../components/hoc-behavior/transitions';
import { ResponseTypes, ModelMetadata } from '../../funds/funds-generated';
import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import { ModelForArrayItem } from '../../funds/components/form-controls';
import { InputField, ValidationMessage, SelectField } from '../../funds/components/form-controls';

import { ListingModel } from '../components/listing-model';
import { IntegrationConfigurationStore } from '../components/integration-configuration-store';
import { AuthenticationSection } from '../components/authentication-section';
import { ConfigurationSectionHeader } from '../components/configuration-section-header';
import { IntegrationContainer } from '../components/integration-container';
import { ConfigurationSection } from '../components/configuration-section';
import { ListingSettingsEditor } from '../components/listing-configuration';
import { FallbackFundSelector } from '../components/fallback-fund-selector';
import { FormControlLabel } from '../../components/form-controls/form-control-label';
import getFundsForDisplay, { IntegrationFundDisplayItem } from '../helpers/get-integration-funds-for-display';
import { IntegrationFundsFileUpload, IIntegrationFundsFileUploadStore } from '../components/funds-file-upload';

import ShelbyArenaConfigurationViewModel = ResponseTypes.ShelbyArenaConfigurationViewModel;
import ShelbyArenaListingConfigurationModel = ResponseTypes.ShelbyArenaListingConfigurationModel;
import IntegrationListingConfigurationViewModel = ResponseTypes.IntegrationListingConfigurationViewModel;
import ShelbyArenaPaymentMethodEditModel = ResponseTypes.ShelbyArenaPaymentMethodEditModel;
import IntegrationFundsFileUploadVisibility = ResponseTypes.IntegrationFundsFileUploadVisibility;

export class ShelbyArenaListingModel extends ListingModel<ShelbyArenaListingConfigurationModel> {
	campuses: ResponseTypes.CcbCampusViewModel[];

	constructor(listing: IntegrationListingConfigurationViewModel<ShelbyArenaListingConfigurationModel>,
		funds: IntegrationFundDisplayItem[],
		validationError: string = null) {
		super(listing, funds, validationError);
	}

	@computed
	get campus(): string {
		return `${this.model.CampusId || ''}`;
	}
}

export class ShelbyArenaIntegrationConfigurationStore
	extends IntegrationConfigurationStore<ShelbyArenaListingConfigurationModel, ShelbyArenaConfigurationViewModel>
	implements IIntegrationFundsFileUploadStore {

	@observable
	PaymentMethods: ShelbyArenaPaymentMethodEditModel[] = null;

	@observable
	BatchPaymentsByPaymentMethods: boolean;

	@observable
	DefaultPaymentMethodId: string = null;

	@observable
	AnonymousIndividualId: string = null;

	@observable
	MembershipStatusId: string = null;

	@observable
	MobilePhoneNumberId: string = null;

	@observable
	NonCashPaymentMethod: ShelbyArenaPaymentMethodEditModel = null;

	readonly PayerLowerCaseLabel: string;

	readonly fundsFileUploadVisibility: IntegrationFundsFileUploadVisibility;

	constructor(configuration: ShelbyArenaConfigurationViewModel) {
		super(configuration);
		this.AnonymousIndividualId = `${configuration.AnonymousIndividualId || ''}`;
		this.MembershipStatusId = `${configuration.MembershipStatusId || ''}`;
		this.MobilePhoneNumberId = `${configuration.MobilePhoneTypeId || ''}`;
		this.PaymentMethods = configuration.PaymentMethods;
		this.BatchPaymentsByPaymentMethods = configuration.BatchPaymentsByPaymentMethods;
		this.DefaultPaymentMethodId = `${configuration.DefaultPaymentMethodId || ''}`;
		this.PayerLowerCaseLabel = this.configuration.PayerLowerCaseLabel;
		this.fundsFileUploadVisibility = this.configuration.FundsFileUploadVisibility;
		this.NonCashPaymentMethod = configuration.NonCashPaymentMethod;
	}

	@computed
	get funds() {
		return getFundsForDisplay(this.configuration.IntegrationFunds);
	}

	@action.bound
	updateAnonymousIndividualId(anonymousIndividualId: string) {
		this.AnonymousIndividualId = anonymousIndividualId;
	}

	@action.bound
	updateMembershipStatusId(membershipStatusId: string) {
		this.MembershipStatusId = membershipStatusId;
	}

	@action.bound
	updateMobilePhoneNumberId(mobilePhoneNumberId: string) {
		this.MobilePhoneNumberId = mobilePhoneNumberId;
	}

	@action.bound
	BatchPaymentsByPaymentMethodsChange(checked: boolean) {
		this.BatchPaymentsByPaymentMethods = checked;
	}

	@action.bound
	togglePaymentMethod(checked: boolean, index: number) {
		this.PaymentMethods[index].IsEnabled = checked;
	}

	@action.bound
	paymentMethodIdChange(paymentMethodId: string, index: number) {
		this.PaymentMethods[index].ShelbyArenaPaymentMethodId = Number(paymentMethodId);
	}

	@action.bound
	nonCashPaymentMethodIdChange(paymentMethodId: string) {
		this.NonCashPaymentMethod.ShelbyArenaPaymentMethodId = Number(paymentMethodId);
	}

	@action.bound
	toggleNonCashPaymentMethod(checked: boolean) {
		this.NonCashPaymentMethod.IsEnabled = checked;
	}

	@action.bound
	defaultPaymentMethodIdChange(paymentMethodId: string) {
		this.DefaultPaymentMethodId = paymentMethodId;
	}

	createListing(listing: IntegrationListingConfigurationViewModel<ShelbyArenaListingConfigurationModel>) {
		const messageWhenMissingFallbackFunds = this.isListingMissingFallbackFund(listing, this.configuration.IntegrationFunds)
			? ModelMetadata.ShelbyArenaListingConfigurationModel.FallbackFundKey.validationRules.required.errorMessage
			: null;

		return new ListingModel(listing, this.funds, messageWhenMissingFallbackFunds);
	}
}

@observer
export class ShelbyArenaIntegration extends React.Component<{ controller: ShelbyArenaIntegrationConfigurationStore }, {}> {

	renderAuthenticationSection = () => {
		return <ShelbyArenaAuthenticationSection controller={this.props.controller} />;
	}

	renderConfigurationSection = () => {
		return <ShelbyArenaConfigurationSection controller={this.props.controller} />;
	}

	renderIntegrationContainer = () => {
		return <IntegrationContainer
			isAuthenticationSectionEditable={this.props.controller.isAuthenticationSectionEditable}
			authenticationSectionFactory={this.renderAuthenticationSection}
			configurationSectionFactory={this.renderConfigurationSection} />;
	}

	renderFundsFileUpload = () => {
		return <IntegrationFundsFileUpload controller={this.props.controller} />;
	}

	render() {
		return this.props.controller.fundsFileUploadVisibility === IntegrationFundsFileUploadVisibility.Visible
		? this.renderFundsFileUpload()
		: this.renderIntegrationContainer();
	}
}

@observer
class ShelbyArenaAuthenticationSection extends React.Component<{ controller: ShelbyArenaIntegrationConfigurationStore }, {}> {
	render() {
		return (
			<AuthenticationSection store={this.props.controller}>
				<div className="form-group">
					<label className="col-md-3 control-label">{this.metadata.TenancyUrl.displayName}</label>
					<div className="col-md-5">
						<InputField type="text" propertyMetadata={this.metadata.TenancyUrl} ignorePanLikeValidation={true} />
					</div>
					<div className="col-md-3">
						<ValidationMessage for={this.metadata.TenancyUrl.propertyName} />
					</div>
				</div>
				<div className="form-group">
					<label className="col-md-3 control-label">{this.metadata.Username.displayName}</label>
					<div className="col-md-5">
						<InputField type="text" propertyMetadata={this.metadata.Username} ignorePanLikeValidation={true} />
					</div>
					<div className="col-md-3">
						<ValidationMessage for={this.metadata.Username.propertyName} />
					</div>
				</div>
				<div className="form-group">
					<label className="col-md-3 control-label">{this.metadata.Password.displayName}</label>
					<div className="col-md-5">
						<InputField type="password" propertyMetadata={this.metadata.Password} />
					</div>
					<div className="col-md-3">
						<ValidationMessage for={this.metadata.Password.propertyName} />
					</div>
				</div>
				<div className="form-group">
					<label className="col-md-3 control-label">{this.metadata.ApiKey.displayName}</label>
					<div className="col-md-5">
						<InputField type="text" propertyMetadata={this.metadata.ApiKey} ignorePanLikeValidation={true} />
					</div>
					<div className="col-md-3">
						<ValidationMessage for={this.metadata.ApiKey.propertyName} />
					</div>
				</div>
				<div className="form-group">
					<label className="col-md-3 control-label">{this.metadata.ApiSecret.displayName}</label>
					<div className="col-md-5">
						<InputField type="text" propertyMetadata={this.metadata.ApiSecret} ignorePanLikeValidation={true} />
					</div>
					<div className="col-md-3">
						<ValidationMessage for={this.metadata.ApiSecret.propertyName} />
					</div>
				</div>
			</AuthenticationSection>
		);
	}

	private get metadata() {
		return ModelMetadata.ShelbyArenaAuthenticationEditModel;
	}
}

const message = `If we run into any trouble matching transactions to Shelby Arena we'll use the fallback information you've selected here.`;

const anonymousTooltipMessage = `For use when recording anonymous cash gifts. Just enter the Shelby Arena person ID for the person you wish to assign all anonymous gifts to.`;
const membershipStatusIdTooltipMessage = `Enter the Shelby Arena membership status ID you want to assign for any new people created.`;
const mobilePhoneTypeIdTooltipMessage = `Enter the Shelby Arena phone type ID for 'Cell'.`;
const transactionsBatchedDaily = 'Transactions will be sent in a single daily batch';
const transactionsBatchedDailyByPaymentMethod = 'Transactions will be batched and sent by payment method';

const ShelbyArenaListingRowHeader = React.createFactory(() => (
	<span className="form-group">
		<FormControlLabel
			label="Select matching campuses and fallback funds"
			elementName="fallbackfund"
			overridingClass="control-label"
			tooltipOptions={{message: message, placement: 'top'}}/>
	</span>
));

@observer
class ShelbyArenaConfigurationSection extends React.Component<{ controller: ShelbyArenaIntegrationConfigurationStore }, {}> {
	[x: string]: any;
	renderOrganizationSettings = () => {
		const { AnonymousIndividualId, MembershipStatusId, MobilePhoneNumberId } = this.props.controller;
		return (
			<div className="organization-settings">
				<div className="form-group">
					<FormControlLabel
						label={this.metadata.AnonymousIndividualId.displayName}
						elementName={this.metadata.AnonymousIndividualId.propertyName}
						overridingClass="col-md-3 control-label"
						tooltipOptions={{message: anonymousTooltipMessage, placement: 'top'}}/>
					<div className="col-md-5">
						<InputField type="text"
							placeholder="- Enter anonymous payer ID -"
							propertyMetadata={this.metadata.AnonymousIndividualId}
							ignorePanLikeValidation={true}
							onChange={this.handleAnonymousIndividualIdChange}
							value={AnonymousIndividualId} />
					</div>
					<div className="col-md-3">
						<ValidationMessage for={this.metadata.AnonymousIndividualId.propertyName} />
					</div>
				</div>
				<div className="form-group">
					<FormControlLabel
						label={this.metadata.MembershipStatusId.displayName}
						elementName={this.metadata.MembershipStatusId.propertyName}
						overridingClass="col-md-3 control-label"
						tooltipOptions={{message: membershipStatusIdTooltipMessage, placement: 'top'}}/>
					<div className="col-md-5">
						<InputField type="text"
							placeholder="- Enter membership status ID -"
							propertyMetadata={this.metadata.MembershipStatusId}
							ignorePanLikeValidation={true}
							onChange={this.handleMembershipStatusIdChange}
							value={MembershipStatusId} />
					</div>
					<div className="col-md-3">
						<ValidationMessage for={this.metadata.MembershipStatusId.propertyName} />
					</div>
				</div>
				<div className="form-group">
					<FormControlLabel
						label={this.metadata.MobilePhoneTypeId.displayName}
						elementName={this.metadata.MobilePhoneTypeId.propertyName}
						overridingClass="col-md-3 control-label"
						tooltipOptions={{message: mobilePhoneTypeIdTooltipMessage, placement: 'top'}}/>
					<div className="col-md-5">
						<InputField type="text"
							placeholder="- Enter cell phone type ID -"
							propertyMetadata={this.metadata.MobilePhoneTypeId}
							ignorePanLikeValidation={true}
							onChange={this.handleMobilePhoneNumberIdChange}
							value={MobilePhoneNumberId} />
					</div>
					<div className="col-md-3">
						<ValidationMessage for={this.metadata.MobilePhoneTypeId.propertyName} />
					</div>
				</div>
			</div>
		);
	}

	renderWarning = (value: ShelbyArenaPaymentMethodEditModel) => {
		if (value.IsEnabled) {
			return null;
		}

		return (
			<div>
				<span className="list-group-item-lighter">Transactions with this payment method in Pushpay will be sent to Shelby Arena with the above default as the payment method.</span>
			</div>
		);
	}

	renderEditor = (value: ShelbyArenaPaymentMethodEditModel, index: number) => {
		if (!value.IsEnabled) {
			return null;
		}

		return (
			<div className="form-group">
			<div>
				<InputField type="text"
					floatingLabel="Enter payment method ID"
					placeholder="- Enter payment method ID -"
					propertyMetadata={this.metadataPaymentMethod.ShelbyArenaPaymentMethodId}
					ignorePanLikeValidation={true}
					onChange={e => this.paymentMethodIdChange(e, index)}
					defaultValue={value.ShelbyArenaPaymentMethodId ? value.ShelbyArenaPaymentMethodId.toString() : ''} />
				<ValidationMessage for={this.metadataPaymentMethod.ShelbyArenaPaymentMethodId.propertyName} />
				<InputField type="hidden" propertyMetadata={this.metadataPaymentMethod.Type} value={`${value.Type}`} />
			</div>
			</div>
		);
	}

	renderNonCashPaymentMethodEditor = (value: ShelbyArenaPaymentMethodEditModel) => {
		if (!value.IsEnabled) {
			return null;
		}

		return (
			<div className="form-group">
				<div>
					<InputField type="text"
						name={this.metadata.NonCashPaymentMethod.propertyName + '.' + this.metadataPaymentMethod.ShelbyArenaPaymentMethodId.propertyName}
						floatingLabel="Enter payment method ID"
						placeholder="- Enter payment method ID -"
						propertyMetadata={this.metadataPaymentMethod.ShelbyArenaPaymentMethodId}
						ignorePanLikeValidation={true}
						onChange={e => this.nonCashPaymentMethodIdChange(e)}
						defaultValue={value.ShelbyArenaPaymentMethodId ? value.ShelbyArenaPaymentMethodId.toString() : ''} />
					<ValidationMessage for={this.metadataPaymentMethod.ShelbyArenaPaymentMethodId.propertyName} />
					<InputField type="hidden" propertyMetadata={this.metadataPaymentMethod.Type} value={`${value.Type}`} />
				</div>
			</div>
		);
	}

	renderPaymentMethodList = (value: ShelbyArenaPaymentMethodEditModel, index: number,) => {
		return (
			<ModelForArrayItem propertyName={this.metadata.PaymentMethods.propertyName} index={index} key={value.Type}>
				<li className={'list-group-item' + (value.IsEnabled ? ' selected' : '')}>
					<div className="col-md-3 form-group">
						<FormControlLabel
							label={value.Name}
							elementName={value.Name}
							overridingClass="col-md-3 control-label"
							tooltipOptions={{message: value.Tooltip, placement: 'top'}}/>
						<label className="slider-toggle">
							<input
								type="checkbox"
								name={this.metadata.PaymentMethods.propertyName + '[' + index + '].IsEnabled'}
								checked={value.IsEnabled}
								value={value.IsEnabled ? 'true' : 'false'}
								onChange={e => this.togglePaymentMethod(e, index)} />
							<span></span>
						</label>
					</div>
					<div className="form-group">
						<SmoothHeightTransition component="div" className="listing-settings-editor">
							{this.renderEditor(value, index)}
							{this.renderWarning(value)}
						</SmoothHeightTransition>
					</div>
				</li>
			</ModelForArrayItem>
		);
	}

	renderNonCashPaymentMethod = (value: ShelbyArenaPaymentMethodEditModel) => {

		return (
			<li className={'list-group-item' + (value.IsEnabled ? ' selected' : '')}>
				<div className="col-md-3 form-group">
					<FormControlLabel
						label={value.Name}
						elementName={value.Name}
						overridingClass="col-md-3 control-label"
						tooltipOptions={{
							message: value.Tooltip,
							placement: 'top'
						}} />
					<label className="slider-toggle">
						<input
							type="checkbox"
							name={this.metadata.NonCashPaymentMethod.propertyName + '.IsEnabled'}
							checked={value.IsEnabled}
							value={value.IsEnabled ? 'true' : 'false'}
							onChange={e => this.toggleNonCashPaymentMethod(e)} />
						<span></span>
					</label>
				</div>
				<div className="form-group">
					<SmoothHeightTransition component="div" className="listing-settings-editor">
						{this.renderNonCashPaymentMethodEditor(value)}
						{this.renderWarning(value)}
					</SmoothHeightTransition>
				</div>
			</li>
		);
	}

	renderDefaultPaymentMethod = () => {
		return (
			<div>
				<li className="list-group-item is-header">
					<label>Enter payment method IDs</label>
				</li>
				<div className="panel-body">
					<div className="organization-settings">
						<div className="form-group">
							<div>Enter your Shelby Arena Payment Method IDs to match them to the relevant Pushpay payment methods.
								The default payment method will apply to all transactions sent from Pushpay, unless you map individual payment methods as exceptions.
								Multiple Pushpay methods can be mapped to a single Shelby Arena payment method ID.</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	renderDailyBathingToggleLabel = (batchPaymentsByPaymentMethods: boolean) => {
		return (
			<FormControlLabel
				label={batchPaymentsByPaymentMethods ? transactionsBatchedDailyByPaymentMethod : transactionsBatchedDaily}
				additionalClass={batchPaymentsByPaymentMethods ? '' : 'list-group-item-lighter'}
				elementName={this.metadata.BatchPaymentsByPaymentMethods.propertyName} />
		);
	}

	renderPaymentMethodConfig = () => {
		const { BatchPaymentsByPaymentMethods, DefaultPaymentMethodId} = this.props.controller;
		return (
			<div>
				{this.renderDefaultPaymentMethod()}
				<li className="list-group-item is-header">
					<label>Pushpay payment methods</label>
					<label>Shelby Arena payment method ID</label>
				</li>
				<li className={'list-group-item selected'}>
					<div className="col-md-3">
						<label className="col-md-3">Default</label>
						<span></span>
					</div>
					<div className="listing-settings-editor">
						<div className="form-group">
							<div>
								<InputField type="text"
									floatingLabel="Enter default payment method ID"
									placeholder="- Enter default payment method ID -"
									propertyMetadata={this.metadata.DefaultPaymentMethodId}
									ignorePanLikeValidation={true}
									onChange={this.handleDefaultPaymentMethodIdChange}
									value={ DefaultPaymentMethodId } />
							</div>
							<ValidationMessage for={this.metadata.DefaultPaymentMethodId.propertyName} />
						</div>
					</div>
				</li>
				{this.props.controller.PaymentMethods.map(this.renderPaymentMethodList, this)}
				{this.renderNonCashPaymentMethod(this.props.controller.NonCashPaymentMethod)}
				<li className="list-group-item is-header list-group-left">
					<label>Configure transaction batching</label>
				</li>

				<div className="panel-body">
					<div className="organization-settings">
						<div className="form-group">
							<div>Batches created in Pushpay using Batch Entry and Check Deposit will be sent to Shelby Arena as entered.
								All other transactions (including online and Single Entry) will be sent to Shelby Arena in a single daily batch.
								If you want these payments split into separate daily batches based on their payment method, select this option below.
							</div>
						</div>
						<div className="form-group">
							<label className="slider-toggle">
								<input
									type="checkbox"
									name={this.metadata.BatchPaymentsByPaymentMethods.propertyName}
									checked={BatchPaymentsByPaymentMethods}
									value={BatchPaymentsByPaymentMethods ? 'true' : 'false'}
									onChange={e => this.BatchPaymentsByPaymentMethodsChange(e)} />
								<span></span>
							</label>
							{this.renderDailyBathingToggleLabel(BatchPaymentsByPaymentMethods)}
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		return (
			<div>
				<div>
					<ConfigurationSectionHeader store={this.props.controller} />
					<ConfigurationSection
						store={this.props.controller}
						headerComponentFactory={ShelbyArenaListingRowHeader}
						organizationSettings={this.renderOrganizationSettings()}
						listingSettingsEditor={ShelbyArenaListingSettingsEditor}
						paymentMethodConfig={this.renderPaymentMethodConfig()}
					/>
				</div>
				<div>
				</div>
			</div>
		);
	}

	private handleAnonymousIndividualIdChange = (ev: React.FormEvent<HTMLInputElement>) => {
		this.props.controller.updateAnonymousIndividualId(ev.currentTarget.value);
	}

	private handleMembershipStatusIdChange = (ev: React.FormEvent<HTMLInputElement>) => {
		this.props.controller.updateMembershipStatusId(ev.currentTarget.value);
	}

	private handleMobilePhoneNumberIdChange = (ev: React.FormEvent<HTMLInputElement>) => {
		this.props.controller.updateMobilePhoneNumberId(ev.currentTarget.value);
	}

	private handleDefaultPaymentMethodIdChange = (ev: React.FormEvent<HTMLInputElement>) => {
		this.props.controller.defaultPaymentMethodIdChange(ev.currentTarget.value);
	}

	private BatchPaymentsByPaymentMethodsChange = (ev: React.FormEvent<HTMLInputElement>) => {
		ev.currentTarget.value = ev.currentTarget.checked ? 'true' : 'false';
		this.props.controller.BatchPaymentsByPaymentMethodsChange(ev.currentTarget.checked);
	}

	private paymentMethodIdChange = (ev: React.FormEvent<HTMLInputElement>, index) => {
		this.props.controller.paymentMethodIdChange(ev.currentTarget.value, index);
	}

	private nonCashPaymentMethodIdChange = (ev: React.FormEvent<HTMLInputElement>) => {
		this.props.controller.nonCashPaymentMethodIdChange(ev.currentTarget.value);
	}

	private togglePaymentMethod = (ev: React.FormEvent<HTMLInputElement>, index:number) => {
		ev.currentTarget.value = ev.currentTarget.checked ? 'true' : 'false';
		this.props.controller.togglePaymentMethod(ev.currentTarget.checked, index);
	}

	private toggleNonCashPaymentMethod = (ev: React.FormEvent<HTMLInputElement>) => {
		ev.currentTarget.value = ev.currentTarget.checked ? 'true' : 'false';
		this.props.controller.toggleNonCashPaymentMethod(ev.currentTarget.checked);
	}

	private get metadata() {
		return ModelMetadata.ShelbyArenaConfigurationEditModel;
	}

	get metadataPaymentMethod() {
		return ModelMetadata.ShelbyArenaPaymentMethodEditModel;
	}
}

@observer
class ShelbyArenaListingSettingsEditor extends React.Component<{ listing: ShelbyArenaListingModel }, {}> {
	render() {
		return (
			<ListingSettingsEditor>
				<div className="form-group">
					<InputField
						floatingLabel="Enter campus ID"
						placeholder="- Enter campus ID -"
						propertyMetadata={this.metadata.CampusId}
						defaultValue={this.props.listing.model.CampusId ? this.props.listing.model.CampusId.toString() : ''}>
					</InputField>
					<ValidationMessage for={this.metadata.CampusId.propertyName} />
				</div>
				<FallbackFundSelector
					propertyMetadata={this.metadata.FallbackFundKey}
					listing={this.props.listing}
					defaultValue={this.props.listing.model.FallbackFundKey} />
			</ListingSettingsEditor>
		);
	}

	private get metadata() {
		return ModelMetadata.ShelbyArenaListingConfigurationModel;
	}
}
