import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { FormControlInput } from '../../components/form-controls/form-control-input';
import { FormControlLabelled, FormControlType } from '../../components/form-controls/form-control-labelled';
import { MemberFormViewModel } from '../view-models/member-form';
import { ModelMetadata } from '../community-member-generated';

interface IMemberFormFieldProps {
	memberFormViewModel?: MemberFormViewModel;
	childModel?: string;
	label?: string;
	metadata: ModelMetadata.IPropertyMetadata;
	cssClassNames?: string;
	ignorePanLikeValidation?: boolean;
	acceptanceTestTargetId?: string;
	currentValue?: any;
	handleChange?: (event: React.FormEvent<HTMLInputElement>) => void;
}

@inject('memberFormViewModel')
@observer
export class MemberFormField extends React.Component<IMemberFormFieldProps> {
	render() {
		const { label, metadata, cssClassNames, ignorePanLikeValidation, acceptanceTestTargetId, currentValue, handleChange } = this.props;
		const formControlProps = {
			type: 'text',
			name: metadata.propertyName,
			placeholder: metadata.placeholder,
			value: currentValue || (this.currentValue || ''),
			onChangeHandler: handleChange || this.handleChange,
			validationRules: metadata.validationRules,
			ignorePanLikeValidation,
			acceptanceTestTargetId,
		};

		return (
			label ? <FormControlLabelled cssClassNames={cssClassNames} label={label}
				formControlProps={{
					formControlType: FormControlType.Text,
					...formControlProps
				}}
			/>
				: <div className={`form-group ${cssClassNames || ''}`}>
					<FormControlInput {...formControlProps} />
				</div>
		);
	}

	handleChange = (event: React.FormEvent<HTMLInputElement>) => {
		const value = event.currentTarget.value;
		this.props.memberFormViewModel.updateRequest(this.props.metadata.propertyName, value, this.props.childModel);
	}

	private get currentValue() {
		return this.props.childModel
			? this.props.memberFormViewModel.request[this.props.childModel][this.props.metadata.propertyName]
			: this.props.memberFormViewModel.request[this.props.metadata.propertyName];
	}
}
