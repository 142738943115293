import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {ResponseTypes} from '../funds-generated';
import {AjaxPoller} from '../utils/ajax-helper';
import {ModalDialogCommander} from '../../components/modal-dialog-commander';
import {ModalDialog} from '../../components/modal-dialog';
import MonitoredTaskStatus = ResponseTypes.MonitoredTaskStatus;

export interface IModalProgressDialogProps {
	title: string;
	timeoutMessage: string;
	progressUrl: string;
	progressEnd: ModalProgressDialog.ProgressEnd;
}

export interface IModalProgressDialogState {
	currentItems: number;
	totalItems: number;
}

export class ModalProgressDialog extends React.Component<IModalProgressDialogProps, IModalProgressDialogState> {
	constructor(props) {
		super(props);

		this.state = {
			currentItems: 0,
			totalItems: 0
		};

		this.updateProgress = this.updateProgress.bind(this);
		this.progressEnd = this.progressEnd.bind(this);
		this.progressTimeout = this.progressTimeout.bind(this);
	}

	componentDidMount() {
		const ajaxPoller = new ProgressPoller(this.props.progressUrl, this.updateProgress, this.progressEnd, this.progressTimeout, null, 1000);
		ajaxPoller.poll();
	}

	updateProgress(progressStatus: MonitoredTaskStatus) {
		this.setState({
			currentItems:progressStatus.CurrentItems,
			totalItems: progressStatus.TotalItems
		});
	}

	progressEnd() {
		if (this.props.progressEnd === ModalProgressDialog.ProgressEnd.Close) {
			ModalDialogCommander.close(ReactDOM.findDOMNode(this));
			return;
		}
		window.location.reload();
	}

	progressTimeout() {
		Raygun.send(this.props.timeoutMessage);
		ModalDialogCommander.showReactDialog(<ModalDialog title="">{this.props.timeoutMessage}</ModalDialog>);
	}

	render() {
		return (
			<ModalProgressDialogComponent
				title={this.props.title}
				currentItems={this.state.currentItems}
				totalItems={this.state.totalItems}/>
			);
	}
}

export module ModalProgressDialog {
	export enum ProgressEnd {
		Reload,
		Close
	}
}

export interface IModalProgressDialogComponentProps {
	title: string;
	currentItems: number;
	totalItems: number;
}

export class ModalProgressDialogComponent extends React.Component<IModalProgressDialogComponentProps, {}> {
	renderProgressBar() {
		const percentage = this.props.totalItems > 0
			? Math.round(100.0 * this.props.currentItems / this.props.totalItems)
			: 0;

		const progressBarStyle: React.CSSProperties = {
			width: `${percentage}%`
		};

		return (
			<div className="progress">
				<div className="progress-bar progress-bar-striped active" role="progressbar"
					aria-valuenow={percentage} aria-valuemin={0} aria-valuemax={100} style={progressBarStyle}>
				</div>
			</div>
		);
	}

	renderProgress() {
		if (this.props.totalItems === 0) {
			return null;
		}
		return (
			<span>
				{this.props.currentItems.toLocaleString()} / {this.props.totalItems.toLocaleString()}
			</span>
		);
	}

	render() {
		return (
			<div className="modal fade" tabIndex={-1} role="dialog" data-modal-prevent-close="true">
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-body">
							{this.renderProgressBar()}
							{this.props.title} {this.renderProgress()}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export class ProgressPoller extends AjaxPoller {
	protected continuationCallback: Function;

	constructor(url: string, continuationCallback: Function, successCallback: Function, timeoutCallback: Function,
		maxAttempts: number, pollIntervalms: number) {
		super(url, successCallback, timeoutCallback, maxAttempts, pollIntervalms);
		this.continuationCallback = continuationCallback;
	}

	protected success(data) {
		if (data === undefined) {
			this.poll();
		} else if (this.inProgress(data as MonitoredTaskStatus)) {
			this.continuationCallback(data);
			this.poll();
		} else {
			this.continuationCallback(data);
			this.finishedCallback(data);
		}
	}

	private inProgress(status: MonitoredTaskStatus) {
		return status && status.InProgress;
	}
}
