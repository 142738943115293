import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { observer } from 'mobx-react';
import { ModalDialogCommander } from '../components/modal-dialog-commander';
import { Button } from '../components/button';
import { ModalDialog } from '../components/modal-dialog';
import { BrandingSettingsMainViewModel, PublishState } from './branding-settings-main-view-model';

export interface IBrandingSettingConfirmPublishDialogProps {
	publishClicked: () => void;
	vm: BrandingSettingsMainViewModel;
}

@observer
export class BrandingSettingConfirmPublishDialog extends React.Component<IBrandingSettingConfirmPublishDialogProps> {
	open() {
		return ModalDialogCommander.open(ReactDOM.findDOMNode(this));
	}

	close() {
		ModalDialogCommander.close(ReactDOM.findDOMNode(this));
	}

	render() {
		const { publishClicked, vm } = this.props;
		const isProcessing = vm.publishState === PublishState.Publishing;
		const publishButton =
			<Button key="publish_button"
				className="btn btn-primary"
				acceptanceTestTargetId="publish-confirm"
				isProcessingRequest={isProcessing}
				onClick={publishClicked}
			>Publish
			</Button>;
		const cancelButton = {
			label: 'Cancel',
			dismiss: true,
			isPrimary: false,
			disabled: isProcessing,
		};
		const buttons = [cancelButton, publishButton];
		return	<ModalDialog title="" buttons={buttons} showAlert={false} inProgress={isProcessing} >
				Are you sure you want to publish your brand changes?
			</ModalDialog>;
	}
}
