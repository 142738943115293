import * as React from 'react';
import { observer } from 'mobx-react';
import { ModalDialog, IModalDialogButton } from '../../components/modal-dialog';
import { PaymentLabel } from '../virtual-terminal-generated';
import { ModalDialogProcessingViewModel } from '../../components/modal-dialog/modal-dialog-processing-view-model';

@observer
export class DeletePaymentConfirmationDialogOld extends React.Component<{
	paymentLabel: PaymentLabel,
	encodedToken: string,
	isRecurring: boolean,
	initGiftDeleteRequest: (encodedToken: string) => void,
	modalDialogProcessingViewModel: ModalDialogProcessingViewModel,
}, {}> {
	render() {
		const { paymentLabel, isRecurring, modalDialogProcessingViewModel: { isProcessing } } = this.props;
		const buttons: (IModalDialogButton | JSX.Element)[] = [
			{ label: 'No', dismiss: true, isPrimary: false, disabled: isProcessing },
			<button type="submit" key="1" className="btn btn-primary" onClick={this.onConfirmDelete} disabled={isProcessing}>
				Yes{isProcessing && '...'}
			</button>
		];

		return (
			<ModalDialog
				title={`Are you sure you want to ${isRecurring ? 'cancel this recurring schedule' : `delete this ${paymentLabel.NounLowerCase}`}?`}
				buttons={buttons}
				showAlert={true}
			>
				<p className="modal-text">
					{isRecurring ? `No further ${paymentLabel.NounPluralLowerCase} will be made for this schedule. ` : ''}This action cannot be undone.
				</p>
			</ModalDialog>
		);
	}

	onConfirmDelete = () => {
		this.props.initGiftDeleteRequest(this.props.encodedToken);
	}
}

@observer
export class DeletePaymentConfirmationDialog extends React.Component<{
	paymentLabel: PaymentLabel,
	giftDetails : { IsNonCash: boolean, EncodedToken: string, NonCashGiftId: number, IsRecurring: boolean },
	initGiftDeleteRequest: ({IsNonCash: boolean, EncodedToken: string, NonCashGiftId: number}) => void,
	modalDialogProcessingViewModel: ModalDialogProcessingViewModel,
}, {}> {
	render() {
		const { paymentLabel, modalDialogProcessingViewModel: { isProcessing } } = this.props;
		const { IsRecurring } = this.props.giftDetails;
		const buttons: (IModalDialogButton | JSX.Element)[] = [
			{ label: 'No', dismiss: true, isPrimary: false, disabled: isProcessing },
			<button type="submit" key="1" className="btn btn-primary" onClick={this.onConfirmDelete} disabled={isProcessing}>
				Yes{isProcessing && '...'}
			</button>
		];

		return (
			<ModalDialog
				title={`Are you sure you want to ${IsRecurring ? 'cancel this recurring schedule' : `delete this ${paymentLabel.NounLowerCase}`}?`}
				buttons={buttons}
				showAlert={true}
			>
				<p className="modal-text">
					{IsRecurring ? `No further ${paymentLabel.NounPluralLowerCase} will be made for this schedule. ` : ''}This action cannot be undone.
				</p>
			</ModalDialog>
		);
	}

	onConfirmDelete = () => {
		this.props.initGiftDeleteRequest(this.props.giftDetails);
	}
}
