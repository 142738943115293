import * as React from 'react';
import { observer } from 'mobx-react';
import { ModalDialogCommander } from '../../../components/modal-dialog-commander';
import { PaymentLabel } from '../../virtual-terminal-generated';
import { AddMemberDialog } from './add-member-dialog';
import { IAddMemberFormProps } from '../../../community-member/views/add-member-form-container';
import { SvgWrapper } from '../../../components/svg-wrapper';
import { Metrics } from '../../utils/metrics';
import { classNames } from '../../../../Shared/utils/classnames';

@observer
export class AddMemberButton extends React.Component<IAddMemberFormProps & { className?: string, paymentLabel?: PaymentLabel }, {}> {
	render() {
		const { paymentLabel } = this.props;
		return(
			<button type="button"
					className={classNames('btn', 'btn-sm', 'btn-link', 'pull-right', this.props.className)}
					onClick={this.addNewCommunityMember}
					data-pp-at-target="add member">
				<SvgWrapper svg="icon-add" className="icon" />Add a new member
			</button>
		);
	}

	addNewCommunityMember = () => {
		Metrics.paymentEntryStarted();
		Metrics.addPayerStarted();
		ModalDialogCommander.showReactForm(<AddMemberDialog {...this.props} />,
			{ elementToFocusSelector: '[name=FirstName]'});
	}
}
