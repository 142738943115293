import { action, computed, observable } from 'mobx';
import { FloatMath } from '../../../helpers/floatmath';
import { BatchEntryPaymentsViewModel, BatchEntryPaymentViewModel, GiftEntryPaymentStatus } from '../../virtual-terminal-generated';

export class BatchEntryPaymentsStore {
	@observable
	paymentsModel: BatchEntryPaymentsViewModel;

	@observable
	expandedPayment: BatchEntryPaymentViewModel;

	@observable
	acceptableLoadingTimeExceeded: boolean = false;

	constructor() {
		this.paymentsModel = getDefaultBatchEmptyPaymentsViewModel();
	}

	@computed
	get batchPaymentsTotal(): PaymentTotal {
		if (!this.paymentsModel.FetchTime) {
			return { fetched: false };
		}
		const total = this.paymentsModel.BatchEntryPayments.reduce((sum, payment) => {
			const { PaymentStatus, AmountAsMoney: { Amount } } = payment;
			if (PaymentStatus == GiftEntryPaymentStatus.RefundReturned) {
				return sum -= Amount;
			}

			if (PaymentStatus == GiftEntryPaymentStatus.RefundReturn) {
				return sum += Amount;
			}

			if (PaymentStatus == GiftEntryPaymentStatus.Returned) {
				return sum += Amount;
			}

			if (PaymentStatus == GiftEntryPaymentStatus.Return) {
				return sum -= Amount;
			}

			if (PaymentStatus == GiftEntryPaymentStatus.Refunded) {
				return sum += Amount;
			}

			if (PaymentStatus === GiftEntryPaymentStatus.Refund) {
				return sum -= Amount;
			}

			if (PaymentStatus === GiftEntryPaymentStatus.Failed) {
				return sum;
			}

			return sum += Amount;
		}, 0);
		return {
			fetched: true,
			total: FloatMath.round(total, 2)
		};
	}

	@computed
	get batchPaymentsCount(): PaymentTotal {
		if (!this.paymentsModel.FetchTime) {
			return { fetched: false };
		}
		return {
			fetched: true,
			total: this.paymentsModel.BatchEntryPayments.reduce((count, payment) => {
				const { PaymentStatus } = payment;
				return PaymentStatus === GiftEntryPaymentStatus.Failed ? count : count + 1;
			}, 0)
		};
	}

	@action.bound
	setModel(paymentsModel: BatchEntryPaymentsViewModel) {
		this.paymentsModel = paymentsModel;
	}

	@action.bound
	addPayment(payment: BatchEntryPaymentViewModel) {
		this.paymentsModel.BatchEntryPayments.unshift(payment);
	}

	@action.bound
	removePayment(payment: BatchEntryPaymentViewModel) {
		const index = this.paymentsModel.BatchEntryPayments.indexOf(payment);
		if (index > -1) {
			this.paymentsModel.BatchEntryPayments.splice(index, 1);
		}
	}

	@action.bound
	setExpandedPayment(payment: BatchEntryPaymentViewModel) {
		this.expandedPayment = payment;
	}

	@computed
	get expandedItemEncodedToken() {
		return this.expandedPayment && this.expandedPayment.EncodedToken || null;
	}

	@action.bound
	setAcceptableLoadingTimeExceeded(acceptableLoadingTimeExceeded: boolean) {
		this.acceptableLoadingTimeExceeded = acceptableLoadingTimeExceeded;
	}
}

function getDefaultBatchEmptyPaymentsViewModel(): BatchEntryPaymentsViewModel {
	return {
		BatchEntryPayments: [],
		FundTotals: [],
		ExpandedBatchEntryPayment: null,
		FetchTime: null,
		HasBeenUpdated: null,
		GetBatchEntryPaymentsType: null,
	};
}

export type PaymentTotal = PaymentTotalFetched | PaymentTotalNotFetched;

interface PaymentTotalFetched {
	fetched: true;
	total: number;
}

interface PaymentTotalNotFetched {
	fetched: false;
}
