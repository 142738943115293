import * as React from 'react';
import { observer } from 'mobx-react';
import { FormActions } from './form-actions';
import { Form } from '../../funds/components/form-controls';
import { IIntegrationConfigurationStore } from './integration-configuration-store';
import { ListingModel } from './listing-model';
import { ModelForArrayItem } from '../../funds/components/form-controls';
import { ListingConfiguration } from './listing-configuration';
import { ModelMetadata } from '../../funds/funds-generated';

export interface IConfigurationSectionProps<TListingModel> {
	store: IIntegrationConfigurationStore<TListingModel>;
	headerComponentFactory: React.Factory<{}>;
	organizationSettings?: React.ReactChild;
	listingSettingsEditor: React.ComponentClass<{ listing: ListingModel<TListingModel> }>;
	paymentMethodConfig?: React.ReactChild;
}

@observer
export class ConfigurationSection extends React.Component<IConfigurationSectionProps<any>, {}> {
	renderConfigurationPerListing(model: ListingModel<any>, index: number) {
		return (
			<ModelForArrayItem propertyName={this.metadata.Listings.propertyName} index={index} key={model.listingId}>
				<ListingConfiguration
					model={model}
					editor={this.props.listingSettingsEditor} />
			</ModelForArrayItem>
		);
	}

	handleSubmit = (form: HTMLFormElement) => {
		this.props.store.serviceClient.setupConfiguration(new FormData(form));
	}

	renderPanelHeader = () => {
		if (this.props.organizationSettings) {
			return <div className="panel-heading">Settings</div>;
		}

		return null;
	}

	renderOrganizationSettings = () => {
		if (this.props.organizationSettings) {
			return <div className="panel-body">{this.props.organizationSettings}</div>;
		}

		return null;
	}

	renderPaymentMethodConfig = () => {
		if (this.props.paymentMethodConfig) {
			return <div className="panel panel-default">
				<ul className="list-group">{this.props.paymentMethodConfig}</ul>
			</div>;
		}

		return null;
	}

	render() {
		return (
			<Form onSubmit={this.handleSubmit}>
				<div className="panel panel-default">
					{this.renderPanelHeader()}
					{this.renderOrganizationSettings()}
					<ul className="list-group">
						<li className="list-group-item is-header">
							<label>Pushpay listings</label>
							<label>{this.props.headerComponentFactory()}</label>
						</li>
						{this.props.store.listings.map(this.renderConfigurationPerListing, this)}
					</ul>
				</div>
				{this.renderPaymentMethodConfig()}
				<div className="panel panel-default">
					<FormActions
						isSettingUp={this.props.store.serviceClient.isSettingUp}
						isProcessingRequest={this.props.store.serviceClient.isProcessingRequest}
						cancelButtonAction={this.props.store.cancelActionUrl}
						submitButtonText="Setup" />
				</div>
			</Form>
		);
	}

	private get metadata() {
		return ModelMetadata.IIntegrationConfigurationEditModel;
	}
}
