import * as React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

export interface IEnableIntegrationModalDialogProps {
	actionUrl: string;
	integrationIsThirdParty: boolean;
}

@observer
export class EnableIntegrationModalDialog extends React.Component<IEnableIntegrationModalDialogProps, {}> {
	@observable isProcessing = false;

	handleSubmit = () => {
		//disabled elements are not included in the form data - let's send a request first and then disable the buttons
		setTimeout(() => {
			this.isProcessing = true;
		}, 0);
	}

	render() {
		const antiForgeryTokenElement = document.querySelector('[name="__RequestVerificationToken"]') as HTMLInputElement;
		const thirdPartyMessage = <p>
			By enabling the integration, you are integrating your
			Pushpay data with an unaffiliated third party service. You should ensure that you are
			comfortable with the third party's terms and privacy policies, which may vary from those of Pushpay.
		</p>;

		return (
			<div className="modal fade" tabIndex={-1} role="dialog" data-modal-prevent-close="true">
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<form onSubmit={this.handleSubmit} action={this.props.actionUrl} method="post">
							<input type="hidden" name="__RequestVerificationToken" value={(antiForgeryTokenElement && antiForgeryTokenElement.value) || ''} />
							<div className="modal-body">
								<h2>Are you sure you want to enable this integration?</h2>
								{this.props.integrationIsThirdParty && thirdPartyMessage}
							</div>
							<div className="modal-footer">
								<button type="submit"
									name="enableIntegration"
									value="false"
									className="btn btn-cancel"
									disabled={this.isProcessing}>Cancel</button>
								<button type="submit"
									name="enableIntegration"
									value="true"
									className="btn btn-primary"
									disabled={this.isProcessing}>Confirm</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		);
	}
}
