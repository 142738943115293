import * as React from 'react';
import { CampaignStatus, CampaignOverviewViewModel } from '../../campaigns-generated';
import { StatusLabel } from '../status-label';
import { SvgWrapper } from '../../../../Shared/components/svg-wrapper';
import { Link } from '../../../components/link/link';
import { observer } from 'mobx-react';
import { Fragment } from '../../../../Shared/components/fragment';

import * as styles from './campaign-details.less';

@observer
export class CampaignDetailsIntro extends React.Component<CampaignOverviewViewModel> {
	render() {
		const { CampaignName, Description, Status, FundName, Listings, EditUrl, BackUrl, AddPledgeUrl, PledgeLabel } = this.props;
		return (
			<div className={styles.container}>
				<h1 className={styles.title} data-pp-at-target="Campaign title">{CampaignName}</h1>
				<div className={styles.backLink}>
					<Link href={BackUrl} acceptanceTargetId="Go back link"><SvgWrapper svg="icon-chevron-left" className={styles.linkIcon}/> Go back</Link>
				</div>
				<div className={styles.status}>
				<StatusLabel status={Status} acceptanceTestTargetId="Campaign status" />
				</div>
				<div className={styles.editLink}>
					{
						Status === CampaignStatus.Closed
						? <Link href={EditUrl} acceptanceTargetId="Edit campaign link">Reactivate <SvgWrapper svg="reactivate" className={styles.linkIcon} /></Link>
						: <Link href={EditUrl} acceptanceTargetId="Edit campaign link">Edit <SvgWrapper svg="icon-edit" className={styles.linkIcon}/></Link>
					}
				</div>
				{
					AddPledgeUrl &&
					<div className={styles.addLink}>
						<Link href={AddPledgeUrl} acceptanceTargetId="Add pledge link"><SvgWrapper svg="icon-add" className={styles.addIcon}/>{NewFeatures.DonorPledgeEntry_ConfigurablePledgeGrammar ? `Add a ${PledgeLabel.NounLowerCase}` : 'Add a pledge'}</Link>
					</div>
				}
				<div className={styles.info}>
					<CampaignInfo label="Fund" value={FundName} acceptanceTestTargetId="Fund name" />
					<CampaignInfoLabels overviewViewModel={this.props} />
				</div>
				<div className={styles.listings}>
					<CampaignInfo label="Listings" value={Listings.join(', ')} acceptanceTestTargetId="Listings"/>
				</div>
			</div>
		);
	}
}

const CampaignInfoLabels = observer(({ overviewViewModel }: { overviewViewModel: CampaignOverviewViewModel }) => {
	const { Status, CreatedDate, StartDate, ClosedDate, OpenedFor, EndDate } = overviewViewModel;
	switch (Status) {
		case CampaignStatus.Draft:
			return (
				<Fragment>
					<CampaignInfo label="Created" value={CreatedDate} acceptanceTestTargetId="Created date" />
					<CampaignInfo label="Starts" value={StartDate} acceptanceTestTargetId="Starts" />
					{EndDate && <CampaignInfo label="Ends" value={EndDate} acceptanceTestTargetId="End date" /> }
				</Fragment>
			);
		case CampaignStatus.Scheduled:
			return (
				<Fragment>
					<CampaignInfo label="Starts" value={StartDate} acceptanceTestTargetId="Starts" />
					{EndDate && <CampaignInfo label="Ends" value={EndDate} acceptanceTestTargetId="End date" />}
				</Fragment>
			);
		case CampaignStatus.Published:
			return (
				<Fragment>
					<CampaignInfo label="Started" value={StartDate} acceptanceTestTargetId="Started" />
					{EndDate && <CampaignInfo label="Ends" value={EndDate} acceptanceTestTargetId="End date" />}
					<CampaignInfo label="Opened for" value={OpenedFor} acceptanceTestTargetId="Opened for" />
				</Fragment>
			);
		case CampaignStatus.Closed:
			return (
				<Fragment>
					<CampaignInfo label="Started" value={StartDate} acceptanceTestTargetId="Started" />
					<CampaignInfo label="Closed" value={ClosedDate} acceptanceTestTargetId="Closed date" />
					<CampaignInfo label="Opened for" value={OpenedFor} acceptanceTestTargetId="Opened for" />
				</Fragment>
			);
		default:
			const invalidStatus: never | CampaignStatus.Deleted | CampaignStatus.Unknown = Status;
			throw new Error(`Campaign ${invalidStatus} can not be displayed`);
	}
});

interface ICampaignInfoProps {
	label: string;
	value: string;
	acceptanceTestTargetId: string;
}

const CampaignInfo: React.StatelessComponent<ICampaignInfoProps> = observer(
	(props: ICampaignInfoProps) => {
		const { label, value, acceptanceTestTargetId } = props;

		return (
			<div className={styles.infoItem}>
				<span className={styles.infoTitle}>{label}: </span>
				<strong data-pp-at-target={acceptanceTestTargetId}>{value}</strong>
			</div>
		);
	}
);

interface ICampaignDescriptionProps {
	value: string;
	acceptanceTestTargetId: string;
}

const CampaignDescription: React.StatelessComponent<ICampaignDescriptionProps> = observer(
	(props: ICampaignDescriptionProps) => {
		const { value, acceptanceTestTargetId } = props;

		return (
			<div>
				<p data-pp-at-target={acceptanceTestTargetId} dangerouslySetInnerHTML={{ __html: value}}></p>
			</div>
		);
	}
);
