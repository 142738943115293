import * as React from 'react';
import { observer } from 'mobx-react';
import { NonCashPaymentMethod } from './payment-entry-model';
import { AssetType } from '../../virtual-terminal-generated';
import { FormControlLabelled, IFormControlLabelledTooltipProps, FormControlType } from '../../../components/form-controls/form-control-labelled';
import { IFormControlSelectOptionProps } from '../../../components/form-controls/form-control-select';
import { ModelMetadata } from '../../virtual-terminal-generated';
import { PaymentLabel } from 'loggedinweb-generated';
import { isIE } from '../../../../Shared/helpers/browser-support-helper';
import { injectAnalytics, recordEvent, WithAnalytics } from '../../../analytics';

@injectAnalytics
@observer
export class PaymentMethodNonCash extends React.Component<WithAnalytics & { noncash: NonCashPaymentMethod }> {
	render() {
		const { noncash } = this.props;
		const { assetType } = noncash;

		const options : IFormControlSelectOptionProps[] = [
			{
				Label: 'Stocks and Bonds',
				Value: AssetType.StocksAndBonds.toString()
			},
			{
				Label: 'Property',
				Value: AssetType.Property.toString()
			},
			{
				Label: 'Vehicles',
				Value: AssetType.Vehicles.toString()
			},
			{
				Label: 'Other',
				Value: AssetType.Other.toString()
			},
		];

		return (
			<div className={`vt-payment-method-non-cash form-group`}>
				<FormControlLabelled
					label="Asset type"
					formControlProps={{
						formControlType: FormControlType.DropDown,
						acceptanceTestTargetId: 'asset-type-dropdown',
						name: 'listing-filter',
						value: assetType.toString(),
						onChangeHandler: this.handleItemSelected,
						...(NewFeatures.SetupPinpointAnalytics && { onBlurHandler: this.handleAssetTypeBlur }),
						Options: options,
						hideEmptyOption: true,
					}}/>
			</div>
		);
	}

	private handleAssetTypeBlur = () => {
		const { analytics, noncash: { assetType } } = this.props;
		if (analytics && assetType) {
			const { feature, subFeature } = analytics;
			recordEvent({
				feature,
				subFeature,
				eventTypeLabel: 'completePaymentMethod',
				attributes: { paymentMethodType: 'nonCash' }
			});
		}
	}

	private handleItemSelected = (event: React.FormEvent<HTMLSelectElement>) => {
		const assetType = parseInt(event.currentTarget.value);
		this.props.noncash.setAssetType(assetType);
	}
}

const formControlWidthClassNames: string = 'col-md-12';

@observer
export class PaymentMethodNonCashAdditionalFields extends React.Component<{ noncash: NonCashPaymentMethod, paymentLabel: PaymentLabel }> {
	render() {
		const { noncash, paymentLabel } = this.props;
		const { descriptionForDonor, descriptionForMerchant } = noncash;

		const giftDescriptionValidationRules = ModelMetadata.VirtualTerminalPaymentRequestModel.DescriptionForDonor.validationRules;
		const giftDescriptionDefaultValidationRules = {
			required: {
				errorMessage: `Please enter a ${paymentLabel.NounLowerCase} description`
			}
		};

		const shortDescriptionTooltipOptions: IFormControlLabelledTooltipProps = {
			message: `Enter the short description of the ${paymentLabel.NounLowerCase}`,
			placement: 'top',
		};

		const longDescriptionTooltipOptions: IFormControlLabelledTooltipProps = {
			message: `Describe the ${paymentLabel.NounLowerCase} in more detail, or enter notes about the ${paymentLabel.NounLowerCase}`,
			placement: 'top',
		};

		const giftDescriptionFullValidationRules = giftDescriptionValidationRules
													? { ...giftDescriptionDefaultValidationRules, ...giftDescriptionValidationRules }
													: giftDescriptionDefaultValidationRules;

		const placeholders = this.GetPlaceholdersForAssetType(noncash.assetType);

		return (
			<div className={`vt-payment-method-non-cash`}>
				<div className={`row`}>
					<FormControlLabelled cssClassNames={formControlWidthClassNames}
						label={`${paymentLabel.NounSentenceCase} description`}
						tooltipOptions={shortDescriptionTooltipOptions}
						formControlProps={{
							formControlType: FormControlType.Text,
							type: `text`,
							name: `gift description`,
							placeholder: placeholders.DescriptionForDonor,
							maxLength: giftDescriptionValidationRules.length.parameters.max,
							validationRules: giftDescriptionFullValidationRules,
							value: descriptionForDonor,
							onChangeHandler: this.onShortDescriptionChanged,
							acceptanceTestTargetId: `description-for-donor-text`,
						}} />
				</div>
				<div className={`row`}>
					<FormControlLabelled cssClassNames={formControlWidthClassNames}
						label={`Detailed description (optional)`}
						tooltipOptions={longDescriptionTooltipOptions}
						formControlProps={{
							formControlType: FormControlType.Textarea,
							name: `detailed description`,
							placeholder: placeholders.DescriptionForMerchant,
							maxLength: ModelMetadata.VirtualTerminalPaymentRequestModel.DescriptionForMerchant.validationRules.length.parameters.max,
							validationRules: ModelMetadata.VirtualTerminalPaymentRequestModel.DescriptionForMerchant.validationRules,
							rows: 4,
							value: descriptionForMerchant,
							onChangeHandler: this.onLongDescriptionChanged,
							acceptanceTestTargetId: `description-for-merchant-text`,
						}} />
				</div>
			</div>
		);
	}

	private GetPlaceholdersForAssetType = (assetType: AssetType) => {

		let placeholders: { DescriptionForDonor: string; DescriptionForMerchant: string };
		switch (assetType) {
			case AssetType.StocksAndBonds:
				placeholders = {
					DescriptionForDonor: 'e.g. Microsoft shares or federal housing bonds',
					DescriptionForMerchant: 'e.g. Types of shares — name of stock, total number of units, any conditions tied to the stock/bond',
				};
				break;
			case AssetType.Property:
				placeholders = {
					DescriptionForDonor: 'e.g. Terraced House in Maryland',
					DescriptionForMerchant: 'e.g. Details of property such as color of house, number of rooms, type of house and address',
				};
				break;
			case AssetType.Vehicles:
				placeholders = {
					DescriptionForDonor: 'e.g. Chevrolet Silverado (2016 Model)',
					DescriptionForMerchant: 'e.g. Chassis number, color of vehicle, details of internal and external look of the vehicle',
				};
				break;
			case AssetType.Other:
				placeholders = {
					DescriptionForDonor: 'e.g. Two boxes of clothing and toys, one pallet of canned food',
					DescriptionForMerchant: 'e.g. 144 cans tomatoes, 144 cans beans — surplus from supermarket chain',
				};
		}

		// IE has a bug when rendering placeholders in a textarea: the placeholder doesn't show up until you focus, then blur the text area.
		// There has been a lot of back-and-forth in the react repo about this and it is broken on our current version.
		// https://github.com/facebook/react/issues/6731
		// https://github.com/facebook/react/issues/11172
		// https://github.com/facebook/react/issues/13632
		//
		// Just don't show a placeholder for `DescriptionForMerchant` if we're in IE. :(
		if(isIE()) {
			placeholders.DescriptionForMerchant = '';
		}

		return placeholders;
	}

	private onShortDescriptionChanged = (ev: React.FormEvent<HTMLInputElement>) => {
		this.props.noncash.setDescriptionForDonor(ev.currentTarget.value);
	}

	private onLongDescriptionChanged = (ev: React.FormEvent<HTMLTextAreaElement>) => {
		this.props.noncash.setDescriptionForMerchant(ev.currentTarget.value);
	}
}
