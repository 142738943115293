import * as React from 'react';
import { observer } from 'mobx-react';
import { SimpleMatchModal, ModalCancelButton } from '../../../components/match-modal';
import { Fragment } from '../../../../Shared/components/fragment';
import { PledgeAlreadyAddedResponse } from '../../campaigns-generated';
import { Button } from '../../../../Shared/components/button/button';
import { SvgWrapper } from '../../../components/svg-wrapper';

import * as styles from './campaigns-modals.less';

interface IPledgeAlreadyAddedModalProps {
	visibleStates: (string | string[])[];
	alreadyAddedPledge: PledgeAlreadyAddedResponse;
	newPledgeAmount: number;
	updatingPledge: boolean;
	updatingOrDeletingPledge: boolean;
	onCancel: () => void;
	onUpdate: () => void;
	onDelete: () => void;
}

@observer
export class PledgeAlreadyAddedModal extends React.Component<IPledgeAlreadyAddedModalProps> {
	render() {
		const { visibleStates, onCancel, updatingOrDeletingPledge } = this.props;
		return (
			<SimpleMatchModal type="warning"
				anyState={visibleStates}
				getCancelButton={() => <ModalCancelButton disabled={updatingOrDeletingPledge} onCancel={onCancel} />}
				getBody={() => <Body {...this.props} />}
				getFooter={() => <Footer {...this.props} />}
			/>
		);
	}
}

@observer
class Body extends React.Component<IPledgeAlreadyAddedModalProps> {
	render () {
		const {
			alreadyAddedPledge: {
				PushpayerAccountUrl,
				PledgerName,
				TotalAmount,
				PayerLowerCase,
			},
		} = this.props;
		return (
			<Fragment>
				<h1>A pledge has already been added for this {PayerLowerCase}</h1>
				<strong>
					<a href={PushpayerAccountUrl} target="_blank" rel="noopener noreferrer">{PledgerName}</a>
					&nbsp;<span className={styles.pledgeAlreadyAddedExistingAmount}>- Pledge: ${TotalAmount}</span>
				</strong>
			</Fragment>
		);
	}
}

@observer
class Footer extends React.Component<IPledgeAlreadyAddedModalProps> {
	render() {
		const { newPledgeAmount, onUpdate, onDelete, updatingPledge, updatingOrDeletingPledge } = this.props;
		return (
			<div className={styles.footer}>
				<p className={styles.pledgeAlreadyAddedMessage}>
					Would you like to update it to the new pledge of ${newPledgeAmount}?
				</p>
				<Button type="submit"
					onClick={onUpdate}
					isLoading={updatingPledge}
					disabled={updatingOrDeletingPledge}
					className="btn btn-primary">
					Update pledge
				</Button>
				<div className={styles.pledgeAlreadyAddedOr}>or</div>
				<Button type="button" onClick={onDelete} disabled={updatingOrDeletingPledge} className="btn btn-link">
					<SvgWrapper svg="bin" className={styles.pledgeAlreadyAddedDeleteIcon} />
					Delete pledge
				</Button>
			</div>
		);
	}
}
