import { observable, action, computed } from 'mobx';
import moment from 'moment';
import { ScheduleImportMigrationStatus } from '../../schedule-import-generated';

export class MigrationDateRangeViewModel {
	readonly minStartDate: Date;
	readonly minMigrationPeriodInDays: number;
	readonly maxMigrationPeriodInDays: number;
	readonly isStartDateDisabled: boolean = false;
	readonly isEndDateDisabled: boolean = false;

	@observable startDate: Date;
	@observable endDate: Date;
	@observable minEndDate: Date;
	@observable maxEndDate: Date;

	constructor(
		private initialStartDate: Date,
		private initialEndDate: Date,
		minMigrationPeriodInDays: number,
		maxMigrationPeriodInDays: number,
		migrationStatus: ScheduleImportMigrationStatus,
		minDaysRemainingForUpdate?: number
	) {
		this.minMigrationPeriodInDays = minMigrationPeriodInDays;
		this.maxMigrationPeriodInDays = maxMigrationPeriodInDays;

		this.startDate = initialStartDate;
		this.endDate = initialEndDate;

		switch (migrationStatus) {
			case ScheduleImportMigrationStatus.Draft:
			case ScheduleImportMigrationStatus.Confirmed:
				const tomorrow = moment().startOf('day').add(1, 'day');
				this.minStartDate = tomorrow.toDate();

				if (moment(this.startDate).isBefore(tomorrow)) {
					this.startDate = null;
					this.endDate = null;
					this.adjustEndDateRange(this.minStartDate);
				} else {
					this.adjustEndDateRange(this.startDate);
				}
				break;
			case ScheduleImportMigrationStatus.Active:
			case ScheduleImportMigrationStatus.Paused:
				this.minEndDate = this.endDate;
				this.maxEndDate = this.getEndDateInclusive(this.startDate, this.maxMigrationPeriodInDays);
				this.isStartDateDisabled = true;
				this.isEndDateDisabled = moment().startOf('day').add(minDaysRemainingForUpdate, 'day').isAfter(moment(this.endDate));
				break;
			default:
				this.adjustEndDateRange(this.startDate);
		}
	}

	@action.bound
	updateStartDate(date: Date) {
		this.startDate = date;

		this.adjustEndDateRange(date);
	}

	@action.bound
	updateEndDate(date: Date) {
		this.endDate = date;
	}

	@computed
	get numberOfDaysForMigration() : number {
		return moment(this.endDate).diff(moment(this.startDate), 'days') + 1;
	}

	@computed
	get isEndDateModified() : boolean {
		return this.endDate && this.endDate.getTime() !== this.initialEndDate.getTime();
	}

	@computed
	get isStartDateModified() : boolean {
		return this.startDate && this.startDate.getTime() !== this.initialStartDate.getTime();
	}

	@computed
	get isModidfied() : boolean {
		return this.isStartDateModified || this.isEndDateModified;
	}

	private adjustEndDateRange(startDate: Date) {
		this.minEndDate = this.getEndDateInclusive(startDate, this.minMigrationPeriodInDays);
		this.maxEndDate = this.getEndDateInclusive(startDate, this.maxMigrationPeriodInDays);

		if ( moment(this.endDate).isBefore(moment(this.minEndDate)) || moment(this.endDate).isAfter(moment(this.maxEndDate)) ) {
			this.endDate = this.minEndDate;
		}
	}

	private getEndDateInclusive(startDate: Date, numberOfDays: number): Date {
		return moment(startDate).add(numberOfDays - 1, 'days').toDate();
	}
}
