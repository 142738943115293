export module FindElement {
	export function findParentByClassName(el: HTMLElement, className: string): HTMLElement | null {
		const parent = el.parentElement;

		if (!parent) {
			return null;
		}

		if (parent.classList.contains(className)) {
			return parent;
		} else {
			return this.findParentByClassName(parent, className);
		}
	}
}
