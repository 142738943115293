import { action, observable, computed } from 'mobx';
import { Models } from '../check-deposit-generated';
import { RegisteredScannerViewModel } from './registered-scanner-viewmodel';
import { RegisterScannerDialogViewModel } from './register-scanner-dialog-viewmodel';
import { DeleteRegisteredScannerDialogViewModel } from './delete-registered-scanner-dialog-viewmodel';

export class RegisteredScannersViewModel {
	@observable scanners: RegisteredScannerViewModel[];

	@observable deleteRegisteredScannerDialogViewModel: DeleteRegisteredScannerDialogViewModel;
	@observable registerScannerDialogViewModel: RegisterScannerDialogViewModel;

	@observable private eagerlyOpenedDialogClosed: boolean;

	constructor(model: Models.RegisteredScannersViewModel) {
		this.scanners = model.RegisteredScanners.map(scanner => new RegisteredScannerViewModel(scanner));

		this.registerScannerDialogViewModel = new RegisterScannerDialogViewModel(model.RegisterScannerDialogViewModel, this.registerScannerSubmitted, this.registerScannerDialogClosed);
		this.registerScannerDialogViewModel.onScannerRegistered = this.registerScannerSubmitted;

		this.deleteRegisteredScannerDialogViewModel = new DeleteRegisteredScannerDialogViewModel(model.DeleteRegisteredScannerDialogViewModel, this.deleteScannerSubmitted);
		this.deleteRegisteredScannerDialogViewModel.onScannerDeleted = this.deleteScannerSubmitted;
		this.eagerlyOpenedDialogClosed = false;
	}

	@action
	registerScannerSubmitted = (response: Models.RegisteredScannerViewModel[]) => {
		this.scanners = response;
	}

	@action
	deleteScannerSubmitted = (response: Models.RegisteredScannerViewModel[]) => {
		this.scanners = response;
	}

	@action
	registerScannerDialogClosed = () => {
		this.eagerlyOpenedDialogClosed = true;
	}

	@computed
	get shouldEagerOpenRegisterScannerDialog() {
		return this.scanners.length === 0 && !this.eagerlyOpenedDialogClosed;
	}
}
