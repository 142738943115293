import * as React from 'react';
import { ServiceTimeUserAction } from './service-time-user-actions';
import { ServiceTimeMainViewModel } from '../service-time/service-time-main-view-model';
import { Channel, userActionChannel } from '../../../../Shared/utils/user-action-channel';
import { sagaDataService, SettingsSagaDataService, ISettingsSagaContext } from '../../saga/settings-saga';
import { ActionHandlers } from '../../../../Shared/utils/saga-utils';
import { ModalDialogCommander } from '../../../components/modal-dialog-commander';
import { EventTimeAddEditDialogViewModel } from '../../../components/event-time/event-time-add-edit-dialog-view-model';
import { EventTimeEditConfirmationDialog } from '../../../components/event-time/event-time-edit-confirmation-dialog';
import { EventTimeCreateRequest, EventTimeViewModel, SettingsEventTimeViewModel, EventTimeEditRequest, EventTimeResponse, PaymentBatchViewModel } from '../../settings-generated';
import { EventTimeDeleteDialog } from '../../../components/event-time/event-time-delete-dialog';
import { EventTimeErrorDialog } from '../../../components/event-time/event-time-error-dialog';
import { hasValidationErrors } from '../../../utils/error-utils';
import { EventTimeAddDialog, EventTimeEditDialog } from '../../../components/event-time/event-time-add-edit-dialogs';

export class ServiceTimeSagaContext implements ISettingsSagaContext {
	userActionChannel: Channel<ServiceTimeUserAction> = userActionChannel();
	dataService: SettingsSagaDataService = sagaDataService();
	executingBlockingAction?: boolean = false;
	constructor(public mainViewModel: ServiceTimeMainViewModel) { }
}

export function getActionHandlers() {
	const actionHandlers = new ActionHandlers<ISettingsSagaContext, typeof ServiceTimeUserAction[keyof typeof ServiceTimeUserAction]>();
	const saveActionPriority = 100;

	actionHandlers.add(
		ServiceTimeUserAction.LaunchAddServiceTimeDialog, {
			async: false,
			handler: (context) => handleLaunchAddServiceTimeDialog(context)
		}
	);

	actionHandlers.add(
		ServiceTimeUserAction.AddServiceTime, {
			async: true,
			priority: saveActionPriority,
			handler: (context, action) => handleAddServiceTime(context, action.eventTimeCreateRequest)
		}
	);

	actionHandlers.add(
		ServiceTimeUserAction.LaunchEditServiceTimeDialog, {
			async: true,
			priority: saveActionPriority,
			handler: (context, action) => handleLaunchEditServiceTimeDialog(context, action.eventTime)
		}
	);

	actionHandlers.add(
		ServiceTimeUserAction.LaunchDeleteServiceTime, {
			async: true,
			handler: (context, action) => handleLaunchDeleteServiceTime(context, action.eventTime)
		}
	);

	actionHandlers.add(
		ServiceTimeUserAction.DeleteServiceTime, {
			async: true,
			priority: saveActionPriority,
			handler: (context, action) => handleDeleteServiceTime(context, action.id)
		}
	);

	actionHandlers.add(
		ServiceTimeUserAction.ActivateServiceTime, {
			async: true,
			priority: saveActionPriority,
			handler: (context, action) => handleActivateServiceTime(context, action.id, action.listingId)
		}
	);

	actionHandlers.add(
		ServiceTimeUserAction.DeactivateServiceTime, {
			async: true,
			priority: saveActionPriority,
			handler: (context, action) => handleDeactivateServiceTime(context, action.id, action.listingId)
		}
	);

	actionHandlers.add(
		ServiceTimeUserAction.EditServiceTime, {
			async: true,
			priority: saveActionPriority,
			handler: (context, action) => handleEditServiceTime(context, action.eventTimeEditRequest)
		}
	);

	actionHandlers.add(
		ServiceTimeUserAction.EditServiceTimeConfirmed, {
			async: true,
			priority: saveActionPriority,
			handler: (context, action) => handleEditServiceTimeConfirmed(context, action.eventTimeEditRequest)
		}
	);

	return actionHandlers;
}

function handleLaunchAddServiceTimeDialog(context: ServiceTimeSagaContext) {
	const { userActionChannel, mainViewModel: { setAddEventTimeDialogViewModel, modalDialogProcessingViewModel} } = context;
	const vm = new EventTimeAddEditDialogViewModel();
	setAddEventTimeDialogViewModel(vm);

	const addEventTimeDialog = React.createElement(EventTimeAddDialog, {
			vm,
			onSubmitHandler: (createRequest: EventTimeCreateRequest) => userActionChannel.put(new ServiceTimeUserAction.AddServiceTime(createRequest)),
			modalDialogProcessingViewModel,
		});
	ModalDialogCommander.showReactForm(addEventTimeDialog);
}

function* handleAddServiceTime(context: ServiceTimeSagaContext, createInfo: EventTimeCreateRequest) {
	const {
		dataService,
		mainViewModel: { setEventTimes, addEventTimeDialogViewModel, modalDialogProcessingViewModel: { setIsProcessing } },
	} = context;
	let eventTime: EventTimeViewModel;
	let eventTimes: EventTimeViewModel[];

	setIsProcessing(true);

	try {
		eventTime = yield dataService.add({ createInfo });

		if (eventTime) {
			eventTimes = yield dataService.getEventTimes({});
			setEventTimes(eventTimes);
		}

		ModalDialogCommander.forceCloseCurrent();
	} catch(err) {
		if (hasValidationErrors(err)) {
			addEventTimeDialogViewModel.validationErrors = err.validationErrors;
			return;
		}
		throw err;
	} finally {
		setIsProcessing(false);
	}
}

function* handleLaunchEditServiceTimeDialog(context: ServiceTimeSagaContext, eventTime: SettingsEventTimeViewModel | EventTimeEditRequest) {
	const { userActionChannel, dataService, mainViewModel: {
		setAddEventTimeDialogViewModel, modalDialogProcessingViewModel
	} } = context;

	// get the full eventTime with merchant names from the server
	const { EventTime: eventTimeWithMerchants } = yield dataService.getEventTime({ id: eventTime.Id });

	// apply any edits that may have been set if the user cancelled a previous confirmation modal
	const eventTimeWithAnyEditsApplied: EventTimeViewModel = {
		...eventTimeWithMerchants,
		StartTime: eventTime.StartTime,
		EndTime: eventTime.EndTime,
		Name: eventTime.Name,
	};
	const vm = new EventTimeAddEditDialogViewModel(eventTimeWithAnyEditsApplied);
	setAddEventTimeDialogViewModel(vm);

	const addEventTimeDialog = React.createElement(EventTimeEditDialog, {
			vm,
			onSubmitHandler: (editRequest: EventTimeEditRequest) => {
				userActionChannel.put(new ServiceTimeUserAction.EditServiceTime(editRequest));
			},
			onCloseEventHandler: () => userActionChannel.put(
				new ServiceTimeUserAction.LaunchDeleteServiceTime(eventTimeWithMerchants)),
			modalDialogProcessingViewModel,
		});

	ModalDialogCommander.showReactForm(addEventTimeDialog);
}

function* handleEditServiceTime(context: ServiceTimeSagaContext, editRequest: EventTimeEditRequest) {
	const {
		dataService,
		mainViewModel: { addEventTimeDialogViewModel, modalDialogProcessingViewModel: { setIsProcessing } },
	} = context;

	setIsProcessing(true);

	try {
		const response = yield dataService.edit({ editInfo: editRequest });

		switch (response.Type) {
			case EventTimeResponse.Success:
				updateListingActiveEventTimesIfCloned(editRequest.Id, response.EventTime, context);
				yield refreshEventTimesAndCloseDialog(context);
				break;
			case EventTimeResponse.ConfirmationRequired:
				launchEditServiceTimeConfirmationDialog(context, editRequest, response.InProgressBatches);
				break;
			case EventTimeResponse.Invalid:
				yield refreshEventTimesAndDisplayError(context, response.Reason);
				break;
			default:
				throw new Error(`${response.Type} response is not supported.`);
		}
	} catch (err) {
		if (hasValidationErrors(err)) {
			addEventTimeDialogViewModel.validationErrors = err.validationErrors;
			return;
		}
		throw err;
	} finally {
		setIsProcessing(false);
	}

	function launchEditServiceTimeConfirmationDialog(context: ServiceTimeSagaContext,
		editRequest: EventTimeEditRequest, openPaymentBatches: PaymentBatchViewModel[]) {

		const { userActionChannel, mainViewModel: { modalDialogProcessingViewModel } } = context;
		const editEventTimeConfirmationDialog = React.createElement(EventTimeEditConfirmationDialog, {
			onConfirm: () => userActionChannel.put(new ServiceTimeUserAction.EditServiceTimeConfirmed(editRequest)),
			onCancel: () => userActionChannel.put(new ServiceTimeUserAction.LaunchEditServiceTimeDialog(editRequest)),
			modalDialogProcessingViewModel,
			openPaymentBatches,
		});

		ModalDialogCommander.showReactForm(editEventTimeConfirmationDialog);
	}
}

function* handleEditServiceTimeConfirmed(context: ServiceTimeSagaContext, editRequest: EventTimeEditRequest) {
	const {
		dataService,
		mainViewModel: { modalDialogProcessingViewModel: { setIsProcessing } },
	} = context;

	setIsProcessing(true);

	try {
		const response = yield dataService.editConfirmed({ editInfo: editRequest });

		switch (response.Type) {
			case EventTimeResponse.Success:
				updateListingActiveEventTimesIfCloned(editRequest.Id, response.EventTime, context);
				yield refreshEventTimesAndCloseDialog(context);
				break;
			case EventTimeResponse.Invalid:
				yield refreshEventTimesAndDisplayError(context, response.Reason);
				break;
			default:
				throw new Error(`${response.Type} response is not supported.`);
		}
	} finally {
		setIsProcessing(false);
	}
}

function updateListingActiveEventTimesIfCloned(preEditId: number, editedEventTime: EventTimeViewModel, context: ServiceTimeSagaContext) {
	if (preEditId === editedEventTime.Id) {
		return; // event time was not cloned
	}

	// If the edited event time was cloned (due to editing properties other than its name) then
	// the returned event time will have a different Id, which needs to be included in each listings
	// active event times.
	const activeListingNames = editedEventTime.MerchantNames;
	context.mainViewModel.listings
		.filter(listing => activeListingNames.some(activeName => activeName === listing.ListingName))
		.forEach(listing => {
			listing.ActiveEventTimes = listing.ActiveEventTimes.filter(x => x !== preEditId);
			listing.ActiveEventTimes.push(editedEventTime.Id);
		});
}

function* handleLaunchDeleteServiceTime(context: ServiceTimeSagaContext, eventTime: SettingsEventTimeViewModel) {
	const {
		userActionChannel,
		dataService,
		mainViewModel: {
			modalDialogProcessingViewModel,
			modalDialogProcessingViewModel: { setIsProcessing },
			paymentLabel: {
				NounPluralLowerCase
			},
		}
	} = context;

	setIsProcessing(true);

	try {
		const response = yield dataService.getInProgressPaymentBatchesForEventTime({ id: eventTime.Id });

		switch (response.Type) {
			case EventTimeResponse.Success:
				launchDeleteServiceTimeConfirmationDialog(eventTime, response.Batches);
				break;
			case EventTimeResponse.Invalid:
				yield refreshEventTimesAndDisplayError(context, response.Reason);
				break;
			default:
				throw new Error(`${response.Type} response is not supported.`);
		}
	} finally {
		setIsProcessing(false);
	}

	function launchDeleteServiceTimeConfirmationDialog(eventTime: SettingsEventTimeViewModel,
		openPaymentBatches: PaymentBatchViewModel[]) {

		const deleteAction = new ServiceTimeUserAction.DeleteServiceTime(eventTime.Id);

		const deleteEventTimeConfirmationDialog = React.createElement(EventTimeDeleteDialog, {
			eventTime: { ...eventTime, MerchantNames: null },
			onSubmitHandler: () => userActionChannel.put(deleteAction),
			onCancelHandler: () => {
				userActionChannel.put(new ServiceTimeUserAction.LaunchEditServiceTimeDialog(eventTime));
			},
			modalDialogProcessingViewModel,
			paymentNounPluralLowerCase: NounPluralLowerCase,
			openPaymentBatches,
		});

		ModalDialogCommander.showReactForm(deleteEventTimeConfirmationDialog);
	}
}

function* handleDeleteServiceTime(context: ServiceTimeSagaContext, id: number) {
	const {
		dataService,
		mainViewModel: { modalDialogProcessingViewModel: { setIsProcessing } },
	} = context;

	setIsProcessing(true);

	try {
		const response = yield dataService.delete({ id });

		switch (response.Type) {
			case EventTimeResponse.Success:
				yield refreshEventTimesAndCloseDialog(context);
				break;
			case EventTimeResponse.Invalid:
				yield refreshEventTimesAndDisplayError(context, response.Reason);
				break;
			default:
				throw new Error(`${response.Type} response is not supported.`);
		}
	} finally {
		setIsProcessing(false);
	}
}

function* handleActivateServiceTime(context: ServiceTimeSagaContext, id: number, listingId: number) {
	const {
		dataService,
		mainViewModel: { addEventTimeToListing },
	} = context;

	const response = yield dataService.activateEventTimeForMerchant({ id, merchantId: listingId });

	switch (response.Type) {
		case EventTimeResponse.Success:
			addEventTimeToListing(response.EventTimeForMerchant);
			break;
		case EventTimeResponse.Invalid:
			yield refreshEventTimesAndDisplayError(context, response.Reason);
			break;
		default:
			throw new Error(`${response.Type} response is not supported.`);
	}
}

function* handleDeactivateServiceTime(context: ServiceTimeSagaContext, id: number, listingId: number) {
	const {
		dataService,
		mainViewModel: { removeEventTimeFromListing },
	} = context;

	const response = yield dataService.deactivateEventTimeForMerchant({ id, merchantId: listingId });

	switch (response.Type) {
		case EventTimeResponse.Success:
			removeEventTimeFromListing(response.EventTimeForMerchant);
			break;
		case EventTimeResponse.Invalid:
			yield refreshEventTimesAndDisplayError(context, response.Reason);
			break;
		default:
			throw new Error(`${response.Type} response is not supported.`);
	}
}

function* refreshEventTimesAndCloseDialog(context: ServiceTimeSagaContext) {
	const { dataService, mainViewModel: { setEventTimes }} = context;

	const eventTimes = yield dataService.getEventTimes({});
	setEventTimes(eventTimes);
	ModalDialogCommander.forceCloseCurrent();
}

function* refreshEventTimesAndDisplayError(context: ServiceTimeSagaContext, message: string) {
	const { dataService, mainViewModel: { setEventTimes }} = context;

	const eventTimes = yield dataService.getEventTimes({});
	setEventTimes(eventTimes);
	const dialog = React.createElement(EventTimeErrorDialog, { message });
	ModalDialogCommander.showReactDialog(dialog);
}
