import { createStringEnum } from '../../../../../Shared/state-machine/rx-state-machine-factory';


enum CampaignsState {
	Idle,
	Processing,
	Confirming,
	Sharing,
	ShowingError,
}

const State = createStringEnum(CampaignsState);

export default State;
