import * as React from 'react';
import { observer } from 'mobx-react';

import {
	SkippedRowInfo,
} from '../../schedule-import-generated';
import { Formatter } from '../../../helpers/formatter';
import { RenderResponsive } from '../../../components/hoc-behavior/render-responsive';
import { ResponsiveVisibility, ResponsiveBreakpoint } from '../../../helpers/responsive-helper';

export interface ISkippedRowGridProps {
	rows: SkippedRowInfo[];
	totalAmount: number;
}

@observer
export class SkippedRowGrid extends React.Component<ISkippedRowGridProps> {
	render() {
		const {
			rows,
			totalAmount,
		} = this.props;

		return (
			<div className="transaction-import-summary-grid small-top-margin">
				<RenderResponsive visibility={ResponsiveVisibility.Hidden} breakpoints={ResponsiveBreakpoint.Xs}>
					<div className="row summary-grid-header">
						<div className="col-sm-3">Row</div>
						<div className="col-sm-6">Reason</div>
						<div className="col-sm-3 text-right">Amount</div>
					</div>
				</RenderResponsive>

				{rows.map( row => (
					<div className="row summary-grid-row" key={row.RowNumber}>
						<div className="col-sm-3 col-xs-8 summary-grid-cell"><span className="summary-label-responsive">Row {row.RowNumber}</span></div>
						<RenderResponsive visibility={ResponsiveVisibility.Hidden} breakpoints={ResponsiveBreakpoint.Xs}>
							<div className="col-sm-6 summary-grid-cell">{row.Reason}</div>
						</RenderResponsive>
						<div className="col-sm-3 col-xs-4 text-right summary-grid-cell">${Formatter.formatNumberForDisplay(row.Amount)}</div>
					</div>
				))}
				<div className="row summary-grid-row">
					<div className="col-xs-12 text-right summary-grid-cell"><strong>Total: </strong>${Formatter.formatNumberForDisplay(totalAmount)}</div>
				</div>
			</div>
		);
	}
}




