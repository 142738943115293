import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as styles from './tooltip.less';
import { TransitionGroup } from 'react-transition-group-v1';
import { observable, action } from 'mobx';
import { observer, Provider, inject } from 'mobx-react';
import { getBoundingRect } from '../../helpers/getboundingrect';
import { BrowserSupportHelper } from '../../helpers/browsersupporthelper';
import { isFunction } from '../../utils/is-function';
import { KeyCodes } from '../../../Shared/helpers/keycodes';

export type TooltipPlacement = 'left' | 'right' | 'bottom' | 'top';

export type ElementBounds = {
	height: number,
	width: number,
	top: number,
	left: number,
	right: number,
	bottom: number,
};

export interface ITooltipProps {
	visible: boolean;
	onVisibleChange?: (visible: boolean) => void;
	onClick?: () => void;
	placement?: TooltipPlacement;
	tooltipClassName?: string;
	tooltipContentClassName?: string;
	tooltipPoliteness?: string;
	label: string;
}

export class TooltipContext {
	@observable
	visible: boolean;

	@observable
	label: string;

	@observable
	tooltipContentClassName?: string;

	documentActiveElement: Element;

	@observable
	onVisibleChange: (visible: boolean) => void;

	@observable
	placement: TooltipPlacement;

	@observable
	anchorBounds: ElementBounds;

	@observable
	tooltipClassName: string;

	@observable
	tooltipPoliteness?: string;

	shouldFocusTooltipContent = () => !!this.documentActiveElement;

	@action
	handleRequestOpen = (activeElement?: Element) => {
		if (isFunction(this.onVisibleChange)) {
			if (activeElement) {
				this.documentActiveElement = activeElement;
			}
			this.onVisibleChange(true);
		}
	}

	@action
	handleRequestClose = () => {
		if (isFunction(this.onVisibleChange)) {
			if (this.documentActiveElement) {
				(this.documentActiveElement as HTMLElement).focus();
				this.documentActiveElement = null;
			}
			this.onVisibleChange(false);
		}
	}

	@action
	updateFromProps(props: ITooltipProps) {
		this.visible = props.visible;
		this.placement = props.placement || 'right';
		this.onVisibleChange = props.onVisibleChange;
		this.tooltipClassName = props.tooltipClassName || '';
		this.label = props.label;
		this.tooltipContentClassName = props.tooltipContentClassName || '';
		this.tooltipPoliteness = props.tooltipPoliteness || 'off';
	}

	@action
	updateAnchorBounds(anchorBounds: ElementBounds) {
		this.anchorBounds = anchorBounds;
	}
}

@observer
export class Tooltip extends React.Component<ITooltipProps, { context: TooltipContext }> {
	constructor(props) {
		super(props);
		this.state = { context: new TooltipContext() };
	}

	render() {
		const { handleRequestClose, label, tooltipClassName } = this.state.context;

		return (
			<Provider tooltipContext={this.state.context}>
				<button type="button" onClick={this.onClickEventHandler} className={`${styles.textBtn} info-tooltip ${tooltipClassName}`} aria-label={label}
					onMouseLeave={handleRequestClose} onMouseEnter={this.onHoverEventHandler} onTouchEnd={this.onHoverEventHandler}>
					{this.props.children}
				</button>
			</Provider>
		);
	}

	UNSAFE_componentWillMount() {
		this.state.context.updateFromProps(this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps: ITooltipProps) {
		this.state.context.updateFromProps(nextProps);
	}

	componentDidMount() {
		this.updateAnchorBounds();
	}

	componentDidUpdate() {
		this.updateAnchorBounds();
	}

	private onClickEventHandler = () => {
		if (this.props.onClick) {
			this.props.onClick();
		}
		this.state.context.handleRequestOpen(document.activeElement);
	}

	private onHoverEventHandler = () => {
		this.state.context.handleRequestOpen();
	}

	private updateAnchorBounds() {
		const elementBounds = getBoundingRect(ReactDOM.findDOMNode(this) as Element);
		this.state.context.updateAnchorBounds(elementBounds);
	}
}

@inject('tooltipContext')
@observer
export class TooltipContent extends React.Component<{ tooltipContext?: TooltipContext; }, {}> {
	private container: HTMLDivElement;

	render() {
		return null;
	}

	UNSAFE_componentWillMount() {
		this.container = document.createElement('div');
		this.container.className = `tooltip-container ${this.props.tooltipContext.tooltipClassName}`;
		this.container.setAttribute("aria-live", this.props.tooltipContext.tooltipPoliteness);
		document.body.appendChild(this.container);

		if (BrowserSupportHelper.isTouchSupported()) {
			document.addEventListener('touchstart', this.props.tooltipContext.handleRequestClose);
		}
	}

	componentWillUnmount() {
		if (BrowserSupportHelper.isTouchSupported()) {
			document.removeEventListener('touchstart', this.props.tooltipContext.handleRequestClose);
		}

		ReactDOM.unmountComponentAtNode(this.container);
		document.body.removeChild(this.container);
	}

	componentDidMount() {
		this.placeTooltip();
	}

	componentDidUpdate() {
		this.placeTooltip();
	}

	private placeTooltip() {
		const { visible } = this.props.tooltipContext;
		let component: React.ReactElement<any>;

		if (!visible) {
			component = <TransitionGroup></TransitionGroup>;
		} else {
			component = (
				<TransitionGroup>
					<TooltipContentRenderer tooltipContext={this.props.tooltipContext}>{this.props.children}</TooltipContentRenderer>
				</TransitionGroup>
			);
		}

		ReactDOM.unstable_renderSubtreeIntoContainer(this, component, this.container);
	}
}

@observer
class TooltipContentRenderer extends React.Component<{ tooltipContext: TooltipContext; }, {}> {
	render() {
		const { tooltipContext: { placement, tooltipContentClassName }, children } = this.props;

		return (
			<div onKeyDown={this.handleEscapeDown} tabIndex={0} className={`tooltip fade ${placement}`} role="tooltip">
				<div className="tooltip-arrow"></div>
				<div className={`tooltip-inner ${tooltipContentClassName}`}>
					{children}
				</div>
			</div>
		);
	}

	componentDidMount() {
		this.updatePosition();
	}

	componentDidUpdate() {
		this.updatePosition();
	}

	componentWillAppear(callback) {
		const element = ReactDOM.findDOMNode(this) as HTMLElement;
		element.classList.add('in');
		if (this.props.tooltipContext.shouldFocusTooltipContent()) {
				element.focus();
		}
		setTimeout(callback, 150);
	}

	componentWillEnter(callback) {
		const element = ReactDOM.findDOMNode(this) as HTMLElement;
		element.classList.add('in');
		if (this.props.tooltipContext.shouldFocusTooltipContent()) {
			element.focus();
		}
		setTimeout(callback, 150);
	}

	componentWillLeave(callback) {
		//Component can unmount  before componentWillLeave runs
		//This is fixed in React Transition Group v2 but lets work around it for now
		try {
			const element = ReactDOM.findDOMNode(this) as HTMLElement;
			element.classList.remove('in');
			setTimeout(callback, 150);
		} catch {
			callback();
		}
	}
	private handleEscapeDown = (evt: React.KeyboardEvent<HTMLDivElement>) => {
		const { keyCode } = evt;
		const { Escape } = KeyCodes;
		if (keyCode === Escape) {
			this.hideTooltip();
		}
	}

	private hideTooltip = () => {
		this.props.tooltipContext.handleRequestClose();
	}

	private updatePosition() {
		const element = ReactDOM.findDOMNode(this) as HTMLElement;
		this.setElementPlacement(element, this.props.tooltipContext.placement, this.props.tooltipContext.anchorBounds);
	}

	private setElementPlacement(element: HTMLElement, placement: TooltipPlacement, anchorBounds: ElementBounds) {
		let tipBounds = element.getBoundingClientRect();
		let position: { top: number, left?: number, right?: number };

		switch (placement) {
			case 'left':
				position = { top: this.verticalCenter(anchorBounds, tipBounds), right: anchorBounds.right * -1 + anchorBounds.width };
				break;
			case 'bottom':
				position = { top: anchorBounds.top + anchorBounds.height, left: this.horizontalCenter(anchorBounds, tipBounds) };
				break;
			case 'top':
				position = { top: anchorBounds.top - tipBounds.height, left: this.horizontalCenter(anchorBounds, tipBounds) };
				break;
			case 'right':
				position = { top: this.verticalCenter(anchorBounds, tipBounds), left: anchorBounds.left + anchorBounds.width };
				break;
		}

		element.style.top = `${position.top}px`;
		if (typeof position.right === 'number') {
			element.style.right = `${position.right}px`;
		}

		if (typeof position.left === 'number') {
			element.style.left = `${position.left}px`;
		}
	}

	private verticalCenter(elementBounds: ElementBounds, tipBounds: ElementBounds) {
		return elementBounds.top + (elementBounds.height / 2) - (tipBounds.height / 2);
	}

	private horizontalCenter(elementBounds: ElementBounds, tipBounds: ElementBounds) {
		return elementBounds.left + elementBounds.width / 2 - tipBounds.width / 2;
	}
}
