import NextPageCommand from './next-page-command';
import * as Models from '../../models/index';
import Main from '../main';

export default class SearchCommand extends NextPageCommand {

	static create(main: Main) {
		return new SearchCommand(
			main,
			main.searchTerms,
			main.sorter.currentSorter.serverFieldName,
			main.sorter.isDescending,
			main.excludeResultsWithEmail,
			main.excludeResultsWithAddress
		);
	}

	constructor(
		public main: Main,
		public searchTerms: string,
		public sort: string,
		public sortDescending: boolean,
		public excludeResultsWithEmail: boolean,
		public excludeResultsWithAddress: boolean) {

		super(main, searchTerms, sort, sortDescending, excludeResultsWithEmail, excludeResultsWithAddress, 0);
	}

	preApply(): void {
	}

	postApply(result: Models.CommunityMemberSearchResult) {
		this.main.currentMembers = [];
		super.postApply(result);
	}
}
