import { IFormControlSelectOptionProps } from '../../../components/form-controls/form-control-select';
import {
	MappingsSetupProgress,
	PaymentLabel, QuickBooksFund, QuickBooksFundMappingViewModel,
	QuickBooksMappingViewModel
} from '../../qbo-integration-generated';
import { action, computed, observable } from 'mobx';

export class QboFundsMappingInfoViewModel {
	incomeAccounts: IFormControlSelectOptionProps[];
	locations: IFormControlSelectOptionProps[];
	classes: IFormControlSelectOptionProps[];
	paymentLabel: PaymentLabel;

	readonly panelTitle: string;

	@observable
	isClassesEnabled: boolean;

	@observable
	isLocationsEnabled: boolean;

	@observable
	isListingsMappedToLocations: boolean;

	@observable
	fundMappings: FundMapping[];

	@observable
	activeStep: MappingsSetupProgress;

	@observable
	isProcessingRequest: boolean;

	constructor(model: QuickBooksMappingViewModel, panelTitle: string, isListingsMappedToLocations: boolean, public setStepAsActive: (step: MappingsSetupProgress) => void) {
		this.panelTitle = panelTitle;

		// make funds id dict to make lookups faster
		const fundIdDict: {[key:string]: QuickBooksFundMappingViewModel} = model.FundMappings.reduce((dict, fundMapping) => {
			dict[fundMapping.FundId] = fundMapping;
			return dict;
		}, {});

		this.incomeAccounts = model.Accounts.filter(account => !account.IsBankAccount).map(account => (
			{Label: account.Name, Value: account.Id}
		));

		this.activeStep = model.SetupProgress;

		this.isProcessingRequest = false;

		this.locations = model.Locations.map(location => ({Label: location.Name, Value: location.Id}));

		this.classes = model.Classes.map(qboClass => {
			return {Label: qboClass.Name, Value: qboClass.Id};
		});

		this.isClassesEnabled = model.ClassesForFundsEnabled;
		this.isLocationsEnabled = model.LocationsForFundsEnabled;

		this.paymentLabel = model.PaymentLabel;

		this.fundMappings = model.Funds.map(fund => new FundMapping(fund, fundIdDict));

		this.isListingsMappedToLocations = isListingsMappedToLocations;
	}

	@computed
	get isLocationsShown(): boolean {
		return this.isLocationsEnabled ? !this.isListingsMappedToLocations : false;
	}

	@action.bound
	setIsListingsMappedToLocations(value: boolean) {
		this.isListingsMappedToLocations = value;
	}

	@action.bound
	setLocalActiveStep(step: MappingsSetupProgress) {
		this.activeStep = step;
	}

	@action.bound
	setIsProcessingRequest(value: boolean) {
		this.isProcessingRequest = value;
	}
}

export class FundMapping {
	fundId: number;

	fundName: string;

	fundCode: string;

	@observable
	accountId: string;

	@observable
	locationId?: string;

	@observable
	classId?: string;

	constructor(fund: QuickBooksFund, fundIdDict: {[key:string]: QuickBooksFundMappingViewModel}) {
		this.fundId = fund.FundId;
		this.fundName = fund.FundName;
		this.fundCode = fund.FundCode;

		const mapping = fundIdDict[this.fundId] || null;
		this.accountId = mapping ? mapping.AccountId : '';
		this.locationId = mapping ? mapping.LocationId : '';
		this.classId = mapping ? mapping.ClassId : '';
	}

	@action.bound
	setAccountId(accountId: string) {
		this.accountId = accountId;
	}

	@action.bound
	setLocationId(locationId: string) {
		this.locationId = locationId;
	}

	@action.bound
	setClassId(classId: string) {
		this.classId = classId;
	}

}
