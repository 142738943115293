import * as React from 'react';
import { observer } from 'mobx-react';
import { isFunction } from '../../../utils/is-function';
import { classNames } from '../../../utils/classnames';
import * as styles from './textarea.less';

export interface ITextareaCommonProps {
	acceptanceTestTargetId: string;
	formControlId?: string;
	placeholder?: string;
	additionalClassNames?: string;
	labelledBy?: string;
	name?: string;
	disabled?: boolean;
	ariaDescribedBy?: string;
	ariaRequired?: boolean;
	rows?: number;
}

export interface ITextareaWrapperProps {
	maxLength?: number;
	wrapperClassNames?: string;
}

export interface ITextareaPrimitiveProps {
	value: string;
	ariaInvalid?: boolean;
	onChange?: (value: string) => void;
	onBlur?: () => void;
}

export type ITextareaProps = ITextareaCommonProps & ITextareaPrimitiveProps;

const TextareaLineHeight = 1.6;

class TextareaPrimitive extends React.Component<ITextareaProps, {}> {
	textarea: HTMLTextAreaElement;

	render() {
		const {
			formControlId,
			acceptanceTestTargetId = null,
			additionalClassNames,
			labelledBy,
			ariaDescribedBy,
			ariaRequired,
			ariaInvalid,
			value,
			rows = 3,
			...rest } = this.props;

		const valueOrEmptyStringToEnsureThisIsAControlledComponent = value || '';

		return (
			<textarea
				className={classNames(styles.textarea, additionalClassNames)}
				{...rest}
				spellCheck={false}
				id={formControlId}
				onChange={this.handleChange}
				onBlur={this.handleBlur}
				data-pp-at-target={acceptanceTestTargetId}
				aria-labelledby={labelledBy}
				aria-describedby={ariaDescribedBy}
				aria-required={ariaRequired}
				aria-invalid={ariaInvalid || undefined}
				name={name}
				rows={rows}
				// calculate height based on line height to make "rows" property work on ie11
				style={{ height: rows * TextareaLineHeight + 'em' }}
			>{valueOrEmptyStringToEnsureThisIsAControlledComponent}</textarea>
		);
	}

	private handleChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
		const { onChange } = this.props;

		if (isFunction(onChange)) {
			onChange(event.currentTarget.value);
		}
	}

	private handleBlur = () => {
		const { onBlur } = this.props;

		if (isFunction(onBlur)) {
			onBlur();
		}
	}
}

export const Textarea = observer((props: ITextareaProps & ITextareaWrapperProps) => {
	const { value } = props;
	const { maxLength, wrapperClassNames, ...rest } = props;
	const characterCount = value ? value.length : 0;
	return (
		<div className={classNames(styles.wrapper, wrapperClassNames)}>
			<TextareaPrimitive {...rest} />
			{
				maxLength
				?
					<div className={classNames(styles.inputCount, characterCount > maxLength ? styles.exceedMax : '')}>
						{characterCount} / {maxLength} Characters
					</div>
				:
					null
			}
		</div>
	);
});
