import { observable, action, computed } from 'mobx';
import { IImportFileUploader, UploadError, ImportFileUploader } from './utils/file-uploader';
import { ICancellablePromise, CancellablePromise } from '../utils/cancellable-promise';
import * as Models from './transaction-import-generated';
import { TransactionImportDataService, isTransactionImportAction } from './transaction-import-data-service';
import { AlertProps, AlertType } from './components/alert-control';

export class ImportFileUploadViewModel {
	@observable transactionsFile: File;
	@observable donorsFile?: File;
	@observable uploadStatus: Models.TransactionImportUploadStatus;
	@observable percentComplete: number;
	@observable navigationInProgress: boolean = false;
	@observable rejectedRows: Models.RejectedRowInfo[];
	@observable paymentLabel: Models.PaymentLabel;
	cancelUrl: string;
	backUrl: string;
	showDonorsFile: boolean;
	handleSubmit: () => void;
	handleCancelUpload: () => void;
	handleCancelImport: () => void;
	handleNext: () => void;
	handleBack: () => void;

	constructor(handleSubmit: () => void, handleCancelUpload: () => void, handleCancelImport: () => void, handleNext: () => void, handleBack: () => void) {
		this.handleSubmit = handleSubmit;
		this.handleCancelUpload = handleCancelUpload;
		this.handleCancelImport = handleCancelImport;
		this.handleNext = handleNext;
		this.handleBack = handleBack;
	}

	@computed
	get isWaitingForUpload() {
		return this.uploadStatus === Models.TransactionImportUploadStatus.WaitingForUpload;
	}

	@computed
	get isUploading() {
		return this.uploadStatus === Models.TransactionImportUploadStatus.Uploading;
	}

	@computed
	get isValidating() {
		return this.uploadStatus === Models.TransactionImportUploadStatus.Validating;
	}

	@computed
	get hasUploaded() {
		return this.uploadStatus === Models.TransactionImportUploadStatus.Completed;
	}

	@computed
	get hasFailed() {
		return this.uploadStatus === Models.TransactionImportUploadStatus.Failed;
	}

	@computed
	get isInvalid() {
		return this.uploadStatus === Models.TransactionImportUploadStatus.Invalid;
	}
}

const completedUploadStatus = [
	Models.TransactionImportUploadStatus.Completed,
	Models.TransactionImportUploadStatus.Invalid,
	Models.TransactionImportUploadStatus.Failed
];

export interface IImportFileUploadStore {
	alertProps: AlertProps;
	viewModel: ImportFileUploadViewModel;
}

export class ImportFileUploadStore implements IImportFileUploadStore {
	pollForUpdateTimeInMs: number = 1000;
	uploadStartTime: number;
	uploadChain: ICancellablePromise<any>;
	viewModel: ImportFileUploadViewModel;
	@observable alertProps: AlertProps;

	constructor(private data: Models.TransactionImportUploadViewModel,
		private dataService: TransactionImportDataService,
		private uploader: IImportFileUploader = new ImportFileUploader()) {
		this.viewModel = new ImportFileUploadViewModel(this.submit, this.cancelUpload, this.cancelImport, this.next, this.back);
		this.viewModel.uploadStatus = data.UploadStatus;
		this.viewModel.cancelUrl = data.CancelUrl;
		this.viewModel.backUrl = data.BackUrl;
		this.viewModel.rejectedRows = data.RejectedRows;
		this.viewModel.paymentLabel = data.PaymentLabel;
		this.viewModel.showDonorsFile = data.ImportType === Models.TransactionImportType.PushpayAndDonors;

		if (this.viewModel.uploadStatus === Models.TransactionImportUploadStatus.Validating) {
			this.uploadChain = this.startWaitForVerification()
				.then(this.handleVerificationResult)
				.catch(this.handleUploadError);
		}
	}

	@action
	showAlert = (type: AlertType, content: string | string[]) => {
		this.alertProps = {
			alertType: type,
			alertContent: content,
			showCloseButton: true,
			onClose: () => {
				this.alertProps = null;
			}
		};
	}

	@action
	submit = () => {

		this.alertProps = null;
		this.viewModel.uploadStatus = Models.TransactionImportUploadStatus.Uploading;
		this.viewModel.percentComplete = 0;
		this.startUploadTimer();

		let startFileUpload = null;
		if (this.viewModel.donorsFile) {
			startFileUpload = this.startFileUploadWithTag(this.viewModel.donorsFile, 'donors', n => this.onUploadEvent(n / 2))
				.chainCancel(_ => this.startFileUploadWithTag(this.viewModel.transactionsFile, null, n => this.onUploadEvent(n / 2 + 50)));
		} else {
			startFileUpload = this.startFileUploadWithTag(this.viewModel.transactionsFile, null, this.onUploadEvent);
		}

		this.uploadChain = startFileUpload
			.chainCancel(() => {
				this.viewModel.uploadStatus = Models.TransactionImportUploadStatus.Validating;
				this.reportSuccessfulUpload(this.viewModel.transactionsFile);
				return this.startWaitForVerification();
			})
			.then(this.handleVerificationResult)
			.catch(this.handleUploadError);
	}

	@action
	cancelUpload = () => {
		if (this.uploadChain) {
			this.uploadChain.cancel();
		}

		this.viewModel.uploadStatus = Models.TransactionImportUploadStatus.WaitingForUpload;
		this.showAlert(AlertType.Danger, 'Upload was cancelled');
	}

	@action
	cancelImport = () => {
		const { cancelUrl } = this.viewModel;
		this.viewModel.navigationInProgress = true;

		this.startCancelImport(this.data.ImportId)
			.then(result => {
				if (result.Result === Models.OperationResult.Success) {
					if (this.uploadChain) {
						this.uploadChain.cancel();
					}
					window.location.href = cancelUrl;
				} else {
					this.showAlert(AlertType.Danger, result.ErrorMessage);
					this.viewModel.navigationInProgress = false;
				}
			});
	}

	@action
	next = () => {
		this.viewModel.navigationInProgress = true;
		window.location.reload();
	}

	@action
	back = () => {
		const { backUrl } = this.viewModel;
		this.viewModel.navigationInProgress = true;
		window.location.href = backUrl;
	}

	@action
	onUploadEvent = (percentComplete: number) => {
		this.viewModel.percentComplete = percentComplete;
	}

	@action
	handleUploadError = (error) => {
		var message: string;
		if (error instanceof UploadError) {
			message = error.message;
		} else if (error instanceof Error) {
			message = error.message;
		} else {
			message = 'Unknown error';
		}
		this.showAlert(AlertType.Danger, 'Your file upload was unsuccessful. Please try again.');
		this.reportFailedUpload(this.viewModel.transactionsFile, message);
	}

	private startFileUploadWithTag(file: File, tag: string, onProgress: (percentComplete: number) => void) {
		return this.getFileUploadLink(file, tag, this.data.ImportId)
			.chainCancel(uploadLink => this.uploader.uploadFile(file, uploadLink, (ev) => onProgress(ev.percentComplete)));
	}

	private handleVerificationResult = (uploadStatusResult: Models.TransactionImportUploadStatusResultModel) => {
		this.viewModel.uploadStatus = uploadStatusResult.UploadStatus;
		this.viewModel.rejectedRows = uploadStatusResult.RejectedRows;

		switch (uploadStatusResult.UploadStatus) {
			case Models.TransactionImportUploadStatus.Failed:
				this.showAlert(AlertType.Danger, 'Your file upload was unsuccessful. Please try again.');
				break;
			case Models.TransactionImportUploadStatus.Invalid:
				this.showAlert(AlertType.Danger, 'Please review rejected row details and try to upload the file again. You cannot import a file until rejected rows are clear.');
				break;
		}
	}

	private getFileUploadLink(file: File, tag: string, importId: number) {
		var promise = new CancellablePromise<string>((resolve, reject, onCancel) => {
			const unsubscribe = this.dataService.subscribe(action => {
				if (!isTransactionImportAction(action, 'getUploadUrl')) {
					return;
				}

				if (action.type === 'request_init') {
					return;
				}

				unsubscribe();

				if (action.type === 'request_success') {
					resolve(action.response.UploadLink);
				} else {
					reject('Failed to get upload link');
				}
			});

			onCancel(() => {
				unsubscribe();
			});

			this.dataService.initRequest(
				'getUploadUrl',
				{
					model: <Models.TransactionImportNewUploadModel>{
						TransactionImportId: this.data.ImportId,
						FileName: file.name,
						FileSize: file.size,
						Tags: [tag],
					}
				});
		});

		return promise;
	}

	private startWaitForVerification() {
		return new CancellablePromise<Models.TransactionImportUploadStatusResultModel>((resolve, reject, onCancel) => {
			const unsubscribe = this.dataService.subscribe(action => {
				if (!isTransactionImportAction(action, 'getImportUploadStatus')) {
					return;
				}

				if (action.type === 'request_init') {
					return;
				}

				if (action.type === 'request_success') {
					if (completedUploadStatus.indexOf(action.response.UploadStatus) !== -1) {
						unsubscribe();
						resolve(action.response);
					} else {
						setTimeout(
							() => this.dataService.initRequest('getImportUploadStatus', { transactionImportId: this.data.ImportId }),
							this.pollForUpdateTimeInMs);
					}
				} else {
					unsubscribe();
					reject('Failed to get upload status');
				}
			});

			onCancel(() => {
				unsubscribe();
			});

			this.dataService.initRequest('getImportUploadStatus', { transactionImportId: this.data.ImportId });
		});
	}

	private startCancelImport(importId: number) {
		var promise = new CancellablePromise<Models.TransactionImportOperationResultModel>((resolve, reject, onCancel) => {
			const unsubscribe = this.dataService.subscribe(action => {
				if (!isTransactionImportAction(action, 'cancelImport')) {
					return;
				}

				if (action.type === 'request_init') {
					return;
				}

				unsubscribe();

				if (action.type === 'request_success') {
					resolve(action.response);
				} else {
					reject('Failed to cancel import');
				}
			});

			onCancel(() => {
				unsubscribe();
			});

			this.dataService.initRequest('cancelImport', { transactionImportId: importId });
		});

		return promise;
	}

	private startUploadTimer() {
		this.uploadStartTime = window.performance.now();
	}

	private reportSuccessfulUpload(file: File) {
		this.reportUploadTime(file, true);
	}

	private reportFailedUpload(file: File, message: string) {
		this.reportUploadTime(file, false, message);
	}

	private reportUploadTime(file: File, successful: boolean, message: string = null) {
		const uploadTimeInMs = window.performance.now() - this.uploadStartTime;
		this.dataService.initRequest('reportUploadStatistics', {
			transactionImportId: this.data.ImportId,
			fileName: file.name,
			fileSize: file.size,
			successful,
			message,
			uploadTimeInMs,
		});
	}
}
