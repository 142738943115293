import { delay } from 'redux-saga';
import { call } from 'redux-saga/effects';
import { BrandingSettingsApiConfig, BrandingPackageViewModel, BrandingPackageEditModel } from '../branding-settings-generated';
import { IBrandingSettingsSagaContext } from '../branding-settings-saga';
import { PostError } from '../../utils/ajax-client';
import { InvalidPackageError } from './errors/invalid-package-error';
import { convertFormViewModelToEditModel } from './utils';
import { handleConflict } from './conflict-handler';
import { IProcessLatestRequest } from '../../../Shared/utils/saga-utils';

export type SavePackageRequest = typeof BrandingSettingsApiConfig.actions.savePackage.request;

export interface ISavePackageAction extends IProcessLatestRequest {
	merchantId: number;
	model: BrandingPackageEditModel;
}

export function* savePackageSaga(context: IBrandingSettingsSagaContext, action: ISavePackageAction): IterableIterator<any> {
	const { dataService } = context;
	const { mainViewModel: { formViewModel } } = context;

	if (!formViewModel.isFormValid) {
		yield call(delay, 400); // Delay for 400ms, so the user can see state being changed from Saving to Unsaved when there is a client side error.
		throw new InvalidPackageError('Form was not valid prior to saving package');
	}

	const request: SavePackageRequest = {
		merchantId: action.merchantId,
		model: action.model,
		eTag: formViewModel.eTag,
	};
	const savedPackage: BrandingPackageViewModel = yield dataService.savePackage(request);
	formViewModel.draftKey = savedPackage.DraftKey;
	formViewModel.eTag = savedPackage.ETag;
}

export function* handleErrorResponse(context: IBrandingSettingsSagaContext, ex: Error) {
	const { mainViewModel } = context;
	const { formViewModel } = mainViewModel;
	let validationErrors: { [s: string]: string; };

	if (ex instanceof PostError) {
		if (ex.conflict) {
			const latestPackage = ex.updatedRecordForConflict as BrandingPackageViewModel;
			yield call(handleConflict, context, latestPackage);
			return;
		}

		if (ex.validationErrors && Object.keys(ex.validationErrors).length > 0) {
			validationErrors = ex.validationErrors;
		} else {
			if (ex.shouldReloadPage) {
				mainViewModel.setSessionExpired(true);
			}
			context.reportError(ex, {
				runningSavePackage: true
			});
		}
	}

	formViewModel.failedToSave(validationErrors);
}

export function savePackageHandler(context: IBrandingSettingsSagaContext, runImmediately: boolean = false) {
	context.savePackageChannel.put({
		merchantId: parseInt(context.mainViewModel.selectedListingId, 10),
		model: convertFormViewModelToEditModel(context.mainViewModel.formViewModel),
		runImmediately,
	});
}
