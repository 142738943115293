import CommandBase from './command-base';
import MoveAccountCommand from './move-account-command';
import { ICancellablePromise } from '../../../utils/cancellable-promise';

import * as Models from '../../models/index';
import * as Util from '../../util';
import Member from '../member';
import Account from '../account';

export default class UngroupCommand extends CommandBase<Models.UngroupPersonResponseModel> {

	newMember: Member;
	oldMember: Member;

	constructor(public account: Account) {
		super();
		this.oldMember = account.parent;
	}

	/**
	 * create a new Member with no id;
	 * put it in the right place in the members list
	 * move the account to it
	 */
	preApply(): void {

		let main = this.oldMember.parent;

		//create a new member placeholder
		this.newMember = new Member(main);
		this.newMember.details.importValues(this.account.details);

		//graft the account to the new member
		MoveAccountCommand.moveAccount(this.account, this.oldMember, this.newMember);

		this.newMember.expanded = false;
		this.newMember.ungroupAnimationTrigger.trigger();


		main.insertMembers(this.newMember);
		if (main.currentMembers.indexOf(this.newMember) === main.currentMembers.length - 1) {
			this.newMember.isBeyondCurrentPage = true;
		}


		this.trackers.push(this.newMember.busyTracker);

	}

	send(): ICancellablePromise<Models.UngroupPersonResponseModel> {
		return this.account.parent.parent.dataService.ungroup({
			RemoveFromCommunityMemberId: this.oldMember.id,
			PersonId: this.account.id
		});
	}

	postApply(result: Models.UngroupPersonResponseModel) {
		//most importantly, read a real ID out
		this.newMember.applyModel(result.TargetCommunityMember);
	}

	/**
	 * move the account back to the old members
	 */
	undoPreApply(error): void {
		let main = this.oldMember.parent;
		Util.arrayRemove(main.currentMembers, this.newMember);
		MoveAccountCommand.moveAccount(this.account, this.newMember, this.oldMember);
	}
}
