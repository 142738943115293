import * as React from 'react';
import { Channel } from '../../../../utils/saga-utils';
import { ModalDialog } from '../../../../components/modal-dialog';
import { Formatter } from '../../../../helpers/formatter';

import * as styles from './batch-entry-close-batch-confirm.less';

export namespace BatchEntryCloseBatchConfirmUserAction {
	export class Cancel { }

	export class Complete { }
}

export type BatchEntryCloseBatchConfirmUserAction = typeof BatchEntryCloseBatchConfirmUserAction[keyof typeof BatchEntryCloseBatchConfirmUserAction]['prototype'];

export interface IBatchEntryCloseBatchConfirmProps {
	batchId: number;
	batchName: string;
	numberOfGifts: number;
	totalAmount: number;
	paymentNounPlural: string;
	userActionChannel: Channel<BatchEntryCloseBatchConfirmUserAction>;
}

export class BatchEntryCloseBatchConfirm extends React.Component<IBatchEntryCloseBatchConfirmProps, { isProcessing: boolean }> {

	constructor(props) {
		super(props);
		this.state = { isProcessing: false };
	}

	render() {
		const { batchId, batchName, numberOfGifts, totalAmount, paymentNounPlural} = this.props;
		const { isProcessing } = this.state;
		const buttons: JSX.Element[] = [
			<button type="button" key="cancel" className="btn btn-ghost" data-pp-at-target="Cancel Complete Batch" onClick={this.handleCancelClick} disabled={isProcessing}>Cancel</button>,
			<button type="button" key="complete" className="btn btn-default" data-pp-at-target="Confirm Complete Batch" onClick={this.handleCompleteClick} disabled={isProcessing}>
				Complete
			</button>
		];
		return (
			<ModalDialog title={`Have you finished entering all ${paymentNounPlural}?`} buttons={buttons} showAlert={false} hideCloseButton={true}>
				<p className={`modal-text ${styles.message}`}>
					You're about to complete this batch <strong>{batchName}</strong> (batch #{batchId})
				</p>
				<div data-pp-at-target="Confirm Complete Modal" className={styles.container}>
					<div className={styles.left}>
						<div>Batch title</div>
						<div className={styles.large}>{batchName}</div>
					</div>
					<div className={styles.right}>
						<div>Number of {paymentNounPlural}</div>
						<div className={styles.large}>{numberOfGifts}</div>
					</div>
					<div className={styles.right}>
						<div>Total amount</div>
						<div className={styles.large}>
							<sup className={styles.dollar}>$</sup>
							{Formatter.formatNumberForDisplay(totalAmount)}
						</div>
					</div>
				</div>
			</ModalDialog>
		);
	}

	handleCancelClick = () => {
		const { userActionChannel } = this.props;
		userActionChannel.put(new BatchEntryCloseBatchConfirmUserAction.Cancel());
	}

	handleCompleteClick = () => {
		const { userActionChannel } = this.props;
		this.setState({ isProcessing: true });
		userActionChannel.put(new BatchEntryCloseBatchConfirmUserAction.Complete());
	}
}
