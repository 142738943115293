///<reference path="login.d.ts"/>

export function querySelector<T extends Element>(selector: string, source: ParentNode = document): T[] {
	const nodeList = source.querySelectorAll(selector);
	return [].slice.call(nodeList as NodeListOf<T>);
}

export function sendRaygun(errorType: string, url: string, client: XMLHttpRequest): void {
	const error = new Error(`${errorType}:${client.status} Url:${url || 'unknown'}

${client.response}`);
	Raygun.send(error);
	window.console.error('Raygunning', error);
}
