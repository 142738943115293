import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { TooltipOld } from './tooltip-old';
import { layout } from '../layout/layout';
import { SvgWrapper } from './svg-wrapper';

export interface ITooltipComponentProps {
	className?: string;
	message: string;
	placement?: 'left' | 'right' | 'top' | 'bottom';
}

export class TooltipComponent extends React.Component<ITooltipComponentProps, {}> {
	private tooltip: TooltipOld;

	componentDidMount() {
		this.tooltip = layout().bindTooltip(ReactDOM.findDOMNode(this));
	}

	componentWillUnmount() {
		layout().unbindTooltip(this.tooltip);
	}

	render() {
		const { className, message, placement } = this.props;

		return (
			<span className={className} data-toggle="tooltip" title={message} data-placement={placement}>
				<SvgWrapper svg="icon-info" className="icon tooltip-icon" aria-label={message} />
			</span>
		);
	}
}
