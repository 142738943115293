import * as React from 'react';
import { observer } from 'mobx-react';
import { SimpleMatchModal, ModalCancelButton } from '../../../components/match-modal';
import { Fragment } from '../../../../Shared/components/fragment';
import { PledgeAlreadyAddedResponse } from '../../campaigns-generated';

interface PledgeDeleteSuccessModalProps {
	state: string | string[];
	alreadyAddedPledge: PledgeAlreadyAddedResponse;
	onClose: () => void;
}

export const PledgeDeleteSuccessModal = observer(({ state, alreadyAddedPledge, onClose }: PledgeDeleteSuccessModalProps) => (
	<SimpleMatchModal
		type="success"
		state={state}
		getCancelButton={() => <ModalCancelButton onCancel={onClose} />}
		getBody={() => <Fragment><h1>The pledge has been deleted</h1><div>{alreadyAddedPledge.PledgerNamePossessive} pledge has been removed</div></Fragment>}
		getFooter={() => <button type="submit" onClick={onClose} className="btn btn-ghost">Close</button>} />
));
