import { getCheckDepositDataService, isCheckDepositAction } from '../check-deposit-data-service';
import { createStats, millisecondsSinceNavigationStart } from '../../utils/page-loading-stats';
import { CheckEditModel } from './allocation-view-model';
import { communityMemberModelToViewModel } from '../utils';
import { IPayerViewModel } from '../../components/payer-search-omnibox/payer-search-omnibox';
import { observable, action, toJS, extendObservable, computed } from 'mobx';
import { CustomFieldViewModel } from '../../utils/customFieldsUtils';
import { Models } from '../check-deposit-generated';
import { Formatter } from '../../helpers/formatter';
import CheckAllocationViewModel = Models.CheckAllocationViewModel;
import CustomFieldEditModel = Models.CustomFieldEditModel;
import GetLastPaymentDetailsRequestModel = Models.GetLastPaymentDetailsRequestModel;
import PaymentLabel = Models.PaymentLabel;
import CommunityMemberQueryResult = Models.CommunityMemberQueryResult;
import CustomFieldType = Models.CustomFieldType;
import { CommunityMemberModel } from '../../community-member/models/index';

const zeroFormatted = Formatter.formatNumberForDisplay(0);

export interface AllocationItemFormViewModel {
	RowNumber: number;
	EncodedToken: string;
	PaymentVersion: number;
	Amount: number;
	RoutingNumber: string;
	AccountNumber: string;
	CheckNumber: string;
	CheckImageUrl: string;
	WantsTransactionNotifications: boolean;
	CommonPaymentFields: Models.CommonPaymentFieldsModel;
	HasMissingData: boolean;
	Payer: IPayerViewModel;
	BatchId: number;
	PayButtonLabel: PaymentLabel;
	Splits: Models.CheckSplitEditModel[];
	YourId: string;
}

export default class AllocationItemFormStore {
	@observable check: AllocationItemFormViewModel;
	@observable allAmountsAreValid: boolean;
	@observable checkSplitAmountErrorMessage: string;
	@observable totalAllocated: number;

	referenceFields: CustomFieldViewModel[];
	fundField: CustomFieldViewModel;
	private stats: Models.CheckAllocationStats;
	private cancelLoadingPreviousPaymentDetails: () => void;
	private unsubscribe: (() => void)[] = [];

	constructor(
		check: CheckAllocationViewModel,
		private allReferenceFields: CustomFieldViewModel[],
		public onSave: (check: CheckEditModel, stats: Models.CheckAllocationStats) => void,
		public onLoadPreviousPaymentDetails: (model: GetLastPaymentDetailsRequestModel) => () => void,
		batchId: number,
		public PayButtonLabel: PaymentLabel,
		public isCheckSplittingEnabled: boolean,
		public enableFeatureOrganizationalGiving: boolean) {

		const { FundName, IsRead, CommunityMember, ...rest } = check;
		const checkViewModel: AllocationItemFormViewModel = {
			...rest,
			Payer: communityMemberModelToViewModel(check.CommunityMember),
			BatchId: batchId,
			PayButtonLabel: PayButtonLabel
		};

		this.check = checkViewModel;

		this.stats = createStats<Models.CheckAllocationStats>({
			TimeCheckAllocationOpen: millisecondsSinceNavigationStart(),
			TimeSinceNavigationStart: 0,
			HadMissingData: check.HasMissingData,
			HadMissingPayer: !check.CommunityMember,
			IsModified: false,
			PayerSearchCount: 0,
			PayersSelected: 0,
			NewPayersSelected: 0
		});

		//track payerSearchCount
		this.unsubscribe.push(getCheckDepositDataService().subscribe(action => {
			if (isCheckDepositAction(action, 'searchPayers')) {
				if (action.type === 'request_success') {
					this.stats.PayerSearchCount++;
				}
			}
		}));
		this.logCheckAllocationState(Models.CheckFormState.Open);

		this.referenceFields = isCheckSplittingEnabled ? this.allReferenceFields.filter(x => x.Type !== Models.CustomFieldType.Fund): this.allReferenceFields;
		this.fundField = isCheckSplittingEnabled ? this.allReferenceFields.filter(x => x.Type === Models.CustomFieldType.Fund)[0] : null;
		if(isCheckSplittingEnabled) {
			this.validateSplits();
		} else {
			this.allAmountsAreValid = true;
		}

	}

	destroy(formSaved: boolean) {
		if (!formSaved) {
			this.logCheckAllocationState(Models.CheckFormState.Closed);
		}

		this.unsubscribe.forEach(x => x());
	}

	@computed
	get formattedTotalAllocated(): string  {
		return `$${Formatter.formatNumberForDisplay((this.totalAllocated))}`;
	}

	@action
	updateCheck(check: CheckAllocationViewModel) {
		const { FundName, IsRead, CommunityMember, ...rest } = check;
		this.check = {
			...rest,
			BatchId: this.check.BatchId,
			PayButtonLabel: this.check.PayButtonLabel,
			Payer: communityMemberModelToViewModel(check.CommunityMember),
		};
	}

	@action
	changeWantsNotifications = (checked: boolean) => {
		this.stats.IsModified = true;
		this.check.WantsTransactionNotifications = checked;
	}

	@action
	updateReferenceFieldValue = (field: CustomFieldEditModel) => {
		this.stats.IsModified = true;
		const values = this.check.CommonPaymentFields.ReferenceFieldValues;
		const current = values.filter(x => x.Key === field.Key)[0];

		if (current) {
			values.splice(values.indexOf(current), 1, field);
		} else {
			values.push(field);
		}
	}

	@action
	updateNotes = (value: string) => {
		this.stats.IsModified = true;
		this.check.CommonPaymentFields.Notes = value;
	}

	@action
	updateYourId = (yourId: string) => {
		this.stats.IsModified = true;
		this.check.YourId = yourId;
	}

	@action
	updateGivenOn = (date: Date) => {
		this.stats.IsModified = true;
		this.check.CommonPaymentFields.GivenOn = date;
	}

	@action
	updatePayer = (payer: IPayerViewModel, searchTerm: string, payerIndex: number, isNewPayer: boolean = false) => {
		this.stats.IsModified = true;
		if (payer) {
			this.stats.PayersSelected++;
			if (isNewPayer) {
				this.stats.NewPayersSelected++;
			}
		}

		this.check.Payer = payer;
		const { BatchId, EncodedToken } = this.check;

		if (this.cancelLoadingPreviousPaymentDetails) {
			this.cancelLoadingPreviousPaymentDetails();
			this.cancelLoadingPreviousPaymentDetails = null;
		}

		if (payer && !isNewPayer) {
			this.cancelLoadingPreviousPaymentDetails = this.onLoadPreviousPaymentDetails({
				BatchId: BatchId,
				CommunityMemberId: parseInt(payer.id) || null,
				EncodedToken: EncodedToken
			});
		}

		//log payer search stats
		if (payer) {
			getCheckDepositDataService().initRequest('logPayerSearchStats', {
				model: createStats<Models.PayerSearchStats>({
					BatchId: this.check.BatchId,
					EncodedPaymentToken: this.check.EncodedToken,
					IsNewPayer: isNewPayer,
					TimeCheckAllocationOpen: this.stats.TimeCheckAllocationOpen,
					TimePayerSelected: millisecondsSinceNavigationStart(),
					SelectedPayerIndex: payerIndex,
					SearchTermCharCount: searchTerm.length,
					SearchTermWordCount: searchTerm.split(' ').filter(x => x).length,
					CommunityMemberId: payer.id
				})
			});
		}
	}

	@action
	handleCommunityMemberEdited = (model: CommunityMemberModel) => {
		const payer = communityMemberModelToViewModel(model);
		this.check.Payer = payer;
	}

	@action
	handleCommunityMemberAdded = (communityMemberQueryResult: CommunityMemberQueryResult, searchTerm?: string) => {
		this.updatePayer(communityMemberModelToViewModel(communityMemberQueryResult), searchTerm, -1, true);
	}

	@action
	handleAddFund = () => {
		this.check.Splits.push({
			Fund: {
				Key: this.fundField.Key,
				Value: '',
				Type: CustomFieldType.DropDown
			},
			Amount: 0,
			AmountValidationErrorMessage: null,
			FundValidationErrorMessage: null
		});
		this.validateSplits();
	}

	@action
	handleRemoveFund = (index: number) => {
		this.check.Splits.splice(index, 1);

		// set amount to check's amount if only 1 fund
		if(this.check.Splits.length === 1) {
			this.check.Splits[0].Amount = this.check.Amount;
		}
		this.validateSplits();
	}

	@action
	handleFundChanged = (fundValue: string, index: number): void => {
		this.check.Splits[index].Fund.Value = fundValue;
		this.validateFunds();
	}

	@action
	handleAmountChanged = (amountValue: number, index: number): void => {
		this.check.Splits[index].Amount = amountValue;
		this.validateAmounts();
	}

	@action
	handleAmountBlurred = () => {
		this.validateAmounts();
	}

	saveCheck = () => {
		const { EncodedToken, PaymentVersion, CommonPaymentFields, WantsTransactionNotifications, Payer, Splits, YourId } = toJS(this.check);

		this.onSave({
			EncodedToken,
			PaymentVersion,
			CommonPaymentFields,
			WantsTransactionNotifications,
			CommunityMemberId: parseInt(Payer && Payer.id) || null,
			Splits,
			YourId
		}, { ...this.stats, TimeSinceNavigationStart: millisecondsSinceNavigationStart() });
	}

	private logCheckAllocationState = (formState: Models.CheckFormState) => {
		getCheckDepositDataService().initRequest('logCheckAllocationState', {
			batchId: this.check.BatchId,
			encodedPaymentToken: this.check.EncodedToken,
			formState,
			model: { ...this.stats, TimeSinceNavigationStart: millisecondsSinceNavigationStart() }
		});
	}

	private validateSplits = (): void => {
		this.validateAmounts();
		this.validateFunds();
	}

	private validateAmounts = (): void => {
		const {Splits, Amount} = this.check;
		let validAmounts = true;
		let errorMessage: string = null;

		// ensure all amounts add up to check total
		this.totalAllocated = Splits.reduce((sum, split) => sum + split.Amount, 0);
		const absoluteDiff = Math.abs(this.totalAllocated - Amount);
		const formattedDiff = Formatter.formatNumberForDisplay(absoluteDiff);

		if(formattedDiff !== zeroFormatted) {
			validAmounts = false;
			const direction = this.totalAllocated < Amount ? 'under' : 'over';
			errorMessage =`You are $${formattedDiff} ${direction} the total amount you can allocate.`;
		}
		// ensure all amounts are greater than 0
		this.check.Splits = Splits.map((item) => {
			item.AmountValidationErrorMessage = item.Amount <= 0 ? 'Please enter an amount' : null;
			return item;
		});
		this.allAmountsAreValid = validAmounts;
		this.checkSplitAmountErrorMessage = errorMessage;
	}

	private validateFunds = (): void => {
		const {Splits} = this.check;

		// get map of duplicated funds
		const frequencyCount = Splits.map((split) => {
			// ignore empty
			if(split.Fund.Value !== '') {
				return split.Fund.Value;
			}
		}).reduce((ret, cur) => {
			if(cur) {
				ret[cur] = (ret[cur] || 0) + 1;
			}
			return ret;
		}, {});
		const duplicates = Object.keys(frequencyCount).filter((key) => frequencyCount[key] > 1).reduce((ret, cur) => {
			// converting to object keys to make lookup faster
			ret[cur] = true;
			return ret;
		}, {});

		// go through funds and add error message if value in duplicates
		this.check.Splits = Splits.map((split) => {
			split.FundValidationErrorMessage = duplicates[split.Fund.Value] ? 'Please ensure all funds are unique.' : null;
			return split;
		});
	}
}
