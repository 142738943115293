import * as React from 'react';
import { observer } from 'mobx-react';
import { BatchViewModel } from '../../../virtual-terminal-generated';
import { BatchEntryCloseAndExportButton } from '../close-and-export/batch-entry-close-and-export';
import { classNames } from '../../../../../Shared/utils/classnames';

import * as styles from './batch-entry-footer.less';

@observer
export class BatchEntryFooter extends React.Component<{ batchListingUrl: string, batch: BatchViewModel }> {
	render() {
		const { batchListingUrl, batch } = this.props;
		return (
			<footer className={classNames(styles.container, 'hidden-print')}>
				<a href={batchListingUrl} className={`arrow-hollow-left-before ${styles.link}`}>Back to list</a>
				<BatchEntryCloseAndExportButton batch={batch} />
			</footer>
		);
	}
}
