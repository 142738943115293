/// <reference path="../app.d.ts" />

function navigationStart() {
	return window.performance.timing.navigationStart;
}

export interface IStats {
	NavigationStart: Date;
}

export function createStats<TStats>(initial: Partial<TStats>): TStats & IStats {
	return { ...(initial as any), NavigationStart: new Date(navigationStart()) };
}

export function millisecondsSinceNavigationStart() {
	return Math.round(window.performance.now());
}
