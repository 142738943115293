import * as React from 'react';

export interface IResponsiveImageProps {
	image: string;
	image2x?: string;
}

const image2xSuffix = '@2x';

export const ResponsiveImage = (props: IResponsiveImageProps & React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) => {
	let { image, image2x } = props;

	if(!image2x) {
		const extensionIndex = image.lastIndexOf('.');
		if (extensionIndex === -1) {
			throw new Error('Image should have a valid file extension');
		}
		const imagePath = image.slice(0, extensionIndex);
		const imageExtension = image.slice(extensionIndex + 1);
		image2x = `${imagePath}${image2xSuffix}.${imageExtension}`;
	}

	return(
		<img {...props} src={image} srcSet={`${image} 1x, ${image2x} 2x`} />
	);
};
