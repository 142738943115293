import { observable, computed, action, when } from 'mobx';
import { CheckDepositDataService } from '../check-deposit-data-service';
import { BatchListStore } from './batch-list-store';
import { BatchDialogStore } from './batch-dialog-store';
import { Models } from '../check-deposit-generated';
import BatchesSummaryViewModel = Models.BatchesSummaryViewModel;
import PaymentLabel = Models.PaymentLabel;

export class BatchListViewModel {
	@observable batchListStore: BatchListStore;
	@observable batchDialogStore: BatchDialogStore;
	@observable registeredScannersRedirectUrl: string;
	@observable numberOfRegisteredScanners: number;
	payButtonLabel: PaymentLabel;
	isPaymentEntryOnlyRole: boolean;

	@observable hasBatches: boolean;

	@action
	onScannerRegistered = (scanners: Models.RegisteredScannerViewModel[]) => {
		this.numberOfRegisteredScanners = scanners.length;
		this.batchListStore.numberOfRegisteredScanners = scanners.length;
	}

	constructor(dataService: CheckDepositDataService, batchesSummaryViewModel: BatchesSummaryViewModel) {
		this.hasBatches = batchesSummaryViewModel.HasBatches;
		this.batchDialogStore = new BatchDialogStore(batchesSummaryViewModel.IsCheckScanningReadOnly, batchesSummaryViewModel.CheckDepositOverviewSupportUrl);
		this.numberOfRegisteredScanners = batchesSummaryViewModel.NumberOfRegisteredScanners;
		this.registeredScannersRedirectUrl = batchesSummaryViewModel.RegisteredScannersRedirectUrl;
		this.payButtonLabel = batchesSummaryViewModel.PayButtonLabel;
		this.isPaymentEntryOnlyRole = batchesSummaryViewModel.IsPaymentEntryOnlyRole;

		this.batchListStore = new BatchListStore(dataService, batchesSummaryViewModel);
		this.batchListStore.queryBatches();

		//If we start with no batches, when some batches are retrieved set that we now have some batches
		if(!this.hasBatches) {
			when(() => this.batchesWereRetrieved, this.setHasBatches);
		}
	}

	@computed
	get batchesWereRetrieved() {
		return this.batchListStore.totalBatchCount > 0;
	}

	@action
	private setHasBatches = () => {
		this.hasBatches = true;
	}
}
