import { MainEvents } from '../state/main';

const BASE_URL_REGEX = /^(.*custom-giving-statements)\/?(\d*)\/?(.*)?|$/;
let initHistoryStateSet = false;

export const urlService = {
	execRegex (): any[] {
		return BASE_URL_REGEX.exec(location.pathname);
	},

	getBaseUrl (): string {
		const regex = urlService.execRegex();

		return regex && regex[1];
	},

	hasParameter(name: string): boolean {
		const queryParams = location.search.substr(1).split('&').reduce((q, query) => {
			const [key, value] = query.split('=');
			return (q[key] = value, q);
		}, {});

		return Boolean(queryParams[name]);
	},

	getConfigurationId (): string {
		const regex = urlService.execRegex();

		return regex && regex[2];
	},

	setConfigurationId (configurationId: number | string): void {
		const baseUrl = urlService.getBaseUrl();

		// Only replacing state if the base url is parsed, otherwise we are outside of giving statements, and don't want to
		// mess around with URL's.
		if (baseUrl) {
			history.replaceState(null, null, `${baseUrl}/${configurationId}`);
		}
	},

	clearConfigurationId (): void {
		const baseUrl = urlService.getBaseUrl();

		// Query parameter is preserved, because it is used as a flag to check whether there are any new community members
		// added after last giving statement generation. Only replacing state if the base url is parsed, otherwise we are
		// outside of giving statements, and don't want to mess around with URL's.
		if (baseUrl) {
			history.replaceState(null, null, `${baseUrl}${location.search}`);
		}
	},

	initHistoryState (): void {
		// For us to access the `state` on the PopStateEvent, we need to first push a null state to the stack. We should
		// only ever do this once initially. Otherwise if the user goes through this flow again, we will be pushing too many
		// states onto the history stack.
		if (!initHistoryStateSet) {
			history.pushState(MainEvents.LOAD_PREVIOUS_VIEW, null, null);
			initHistoryStateSet = true;
		}
	},

	pushPreviousViewState (): void {
		history.pushState(MainEvents.LOAD_PREVIOUS_VIEW, null, null);
	}
};
