import React, { RefObject } from 'react';

import { Navigation, SideBar as SideBarBase } from '@pushpay/shell';

import { AdministeredOrganization, NavItem } from '../../loggedinweb-generated';
import { useSideBarStyles } from './side-bar-styles';
import { useNavigationItems } from './use-navigation-items';

type SideBarProps = {
	navItems: NavItem[];
	currentOrganization: AdministeredOrganization;
	feedbackUrl: string;
	mobileButtonRef: RefObject<HTMLButtonElement>;
	isOpenMobile: boolean;
	closeMobile: () => void;
};

export function SideBar({
	currentOrganization,
	navItems,
	feedbackUrl,
	isOpenMobile,
	closeMobile,
	mobileButtonRef,
}: SideBarProps) {
	const classes = useSideBarStyles(undefined, undefined);
	const items = useNavigationItems({ currentOrganization, feedbackUrl, navItems });

	return (
		<SideBarBase
			data-pp-at-target="sidebar"
			className={classes.root}
			mobile={{
				mobileButtonRef: mobileButtonRef,
				isOpenMobile: isOpenMobile,
				closeMobile,
			}}
			collapseText="Collapse Menu"
			expandText="Expand Menu"
			topSection={
				<Navigation
					className={classes.nav}
					classes={{
						item: classes.item,
						itemActive: classes.itemActive,
					}}
					items={items}
				/>
			}
		/>
	);
}
