import * as React from 'react';
import { QBOPageHeading } from '../page-heading/page-heading';
import { StatusControl } from '../status-control/status-control';
import { ExternalSystemStatus, IntegrationStatusLabel } from '../../qbo-integration-generated';
import { observer } from 'mobx-react';

import * as styles from './styles.less';

export interface PageIntroProps {
	title: string;
	instanceName?: string;
	labels: IntegrationStatusLabel[];
	isProcessingRequest: boolean;
	isEditing: boolean;
	isSetupComplete: boolean;
	isAuthenticated: boolean;
	isNewConnection: boolean;
	systemStatus: ExternalSystemStatus;
	connectButtonLabel: string;
	connectIntegrationAction: () => void;
	enableIntegrationAction: () => void;
	disableIntegrationAction: () => void;
	removeIntegrationAction: () => void;
}

@observer
export class PageIntro extends React.Component<PageIntroProps, null> {
	render() {
		return <div className={styles.pageIntro}>
			<QBOPageHeading className={styles.introHeading} {...this.props} />
			<StatusControl className={styles.statusButton} {...this.props} />
			<div className={styles.introText}>
				{this.props.children}
			</div>
		</div>;
	}
}
