import { call, fork, cancel } from 'redux-saga/effects';
import { Task, delay } from 'redux-saga';

function* cancelTaskIfRunning(task: Task) {
	if (task && task.isRunning()) {
		yield cancel(task);
	}
}

export function getDebounceEffect<T>() {
	let task: Task;
	return {
		debounce: function* (interval: number, fn: (data?: T) => Iterator<any>, data?: T) {
			yield cancelTaskIfRunning(task);
			yield delay(interval);
			task = yield fork(fn, data);
		},
		cancel: function* () {
			yield cancelTaskIfRunning(task);
		}
	};
}

export function getCancellableEffect<T>() {
	let task: Task;
	return {
		run: function* (fn: (data?: T) => Iterator<any>, data?: T) {
			task = yield fork(fn, data);
		},
		cancel: function* () {
			yield cancelTaskIfRunning(task);
		}
	};
}

export function getProcessLatestEffect<T>(interval: number) {
	let processingTask: Task;
	let buffer: () => Iterator<any>;
	const debounceEffect = getDebounceEffect();
	return {
		processLatest: function* (fn: (data?: T) => Iterator<any>, data?: T) {
			buffer = () => fn(data);
			if (processingTask && processingTask.isRunning()) {
				return;
			}

			yield debounceEffect.debounce(interval, function* () {
				processingTask = yield fork(function* () {
					while(buffer) {
						const currentFn = buffer;
						buffer = null;
						yield call(currentFn);
					}
				});
			});
		}
	};
}
