import * as React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { isFunction } from '../../utils/is-function';
import { Formatter } from '../../utils/formatter';
import { FloatMath } from '../../utils/floatmath';
import { classNames } from '../../utils/classnames';

const filterNumberRegex = /[^\d|\.]/g;
const precision = 2;
const maxFormattedSize = '###,###,###,###.##'.length;

export enum InputStyle {
	/**
	 * Normal Style suitable for desktop and androids
	 * Shows as telephone keyboard on mobile experience
	 */
	Normal,
	/**
	 * IOS style changes the keyboard in safari to use text
	 * Shows as number keypad (without separators) on IOS
	 */
	IOS,
}

export interface IAmountCommonProps {
	acceptanceTestTargetId: string;
	formControlId?: string;
	placeholder?: string;
	inputStyle?: InputStyle;
	additionalClassNames?: string;
	disabled?: boolean;
	ariaDescribedBy?: string;
	ariaRequired?: boolean;
	hideDollarInputAddon?: boolean;
	onFocus?: () => void;
	onBlur?: () => void;
}

export interface IAmountPrimitiveProps {
	ariaLabel?: string;
	ariaInvalid?: boolean;
	value: number;
	onChange?: (value: number | null) => void;
}

export type IAmountProps = IAmountCommonProps & IAmountPrimitiveProps;

export interface IAmountState {
	amount: string;
	amountAsNumber: number | null;
}

@observer
export class Amount extends React.Component<IAmountProps> {
	@observable amountAsNumber: number | null = this.props.value;
	@observable amount = Formatter.formatNumberForDisplay(this.props.value, precision);

	defaultInputStyle = this.getSupportedInputStyle();

	private nextCaretPositionUpdate: number | null = null;
	private element: HTMLInputElement | null;

	UNSAFE_componentWillMount() {
		this.updateComponentState(this.props.value);
	}

	UNSAFE_componentWillReceiveProps(nextProps: IAmountProps) {
		const { value } = nextProps;
		if (value !== this.amountAsNumber) {
			this.updateComponentState(value);
		}
	}

	render() {
		const {
			placeholder,
			formControlId,
			additionalClassNames,
			acceptanceTestTargetId,
			ariaDescribedBy,
			ariaInvalid,
			ariaLabel,
			ariaRequired,
			disabled,
			hideDollarInputAddon,
		} = this.props;
		let { inputStyle } = this.props;
		if (inputStyle === null || inputStyle === undefined) {
			inputStyle = this.defaultInputStyle;
		}
		const inputProps = {
			id: formControlId,
			placeholder: placeholder,
			value: this.amount,
			ref: this.ref,
			type: inputStyle === InputStyle.IOS ? 'text' : 'tel',
			pattern: inputStyle === InputStyle.IOS ? '\\d*' : undefined,
			onChange: this.handleAmountChange,
			onBlur: this.handleBlur,
			onFocus: this.handleFocus,
			className: additionalClassNames,
			'data-pp-at-target': acceptanceTestTargetId,
			'aria-label': ariaLabel,
			'aria-describedby': ariaDescribedBy,
			'aria-invalid': ariaInvalid || undefined,
			'aria-required': ariaRequired,
			disabled : disabled,
			maxLength: maxFormattedSize,
		};
		const inputGroupClassNames = classNames('input-group', hideDollarInputAddon ? null : 'input-group-left');
		return (
			<div className={inputGroupClassNames}>
				<input {...inputProps} />
				{!hideDollarInputAddon && <span className="input-group-addon">$</span>}
			</div>
		);
	}

	private handleAmountChange = () => {
		const value = this.element && this.element.value || '';
		this.updateElementValue(value);
	}

	private handleBlur = () => {
		this.updateComponentState(this.amountAsNumber);

		if (isFunction(this.props.onBlur)) {
			this.props.onBlur();
		}
	}

	private handleFocus = () => {
		if (isFunction(this.props.onFocus)) {
			this.props.onFocus();
		}
	}

	@action
	private updateComponentState(value: number | null) {
		this.amountAsNumber = value;
		this.amount = Formatter.formatNumberForDisplay(value, precision);
	}

	@action
	private updateElementValue = (value: string) => {
		const element = this.element;
		if (!element) {
			return;
		}
		let { selectionEnd: caretPosition } = element;

		const { value: updatedValue, caretPosition: newCaretPosition } = getFilteredValue(value, caretPosition || -1);
		const valueAsNumber = round(parseStringValue(updatedValue), precision);

		this.amountAsNumber = valueAsNumber;
		this.amount = updatedValue;

		if (isFunction(this.props.onChange)) {
			this.props.onChange(valueAsNumber);
		}

		if (updatedValue !== value) {
			if (this.nextCaretPositionUpdate) {
				cancelAnimationFrame(this.nextCaretPositionUpdate);
			}

			this.nextCaretPositionUpdate = requestAnimationFrame(() => {
				if (element && element.value === updatedValue) {
					element.setSelectionRange(newCaretPosition, newCaretPosition);
				}
			});
		}
	}

	private ref = (element: HTMLInputElement) => {
		this.element = element;
	}

	private getSupportedInputStyle(): InputStyle {
		const iOS = !!navigator.userAgent && /iPad|iPhone|iPod/.test(navigator.userAgent);
		return iOS ? InputStyle.IOS : InputStyle.Normal;
	}
}

function isCharDigit(a: string) {
	const charCode = a.charCodeAt(0);
	return charCode >= 48 && charCode <= 57; // 0-9
}

function getFilteredValue(value: string, caretPosition: number) {
	const chars = value.split('');
	const validChars = [];
	let newCaretPosition = caretPosition;

	for (let i = 0; i < chars.length; i++) {
		const char = chars[i];

		if (isCharDigit(char) || char === '.' || char === ',') {
			validChars.push(char);
		} else if (i < caretPosition) {
			newCaretPosition--;
		}
	}

	return {
		value: validChars.join(''),
		caretPosition: newCaretPosition
	};
}

function round(value: number | null, precision: number) {
	if (value === null) {
		return value;
	}
	return FloatMath.round(value, precision);
}

function parseStringValue(value: string | null): number | null {
	if (value === '' || value === null || value === 'undefined') {
		return null;
	}

	return parseFloat(value.replace(filterNumberRegex, ''));
}
