import { Models } from './check-deposit-generated';
import { GenericExportService, createNonAjaxPostService } from '../utils/non-ajax-post-request';

export type CheckDepositExportDataService = GenericExportService<typeof Models.CheckDepositExportConfig>;

let instance: CheckDepositExportDataService | null = null;

export function getCheckDepositExportDataService() {
	if (instance === null) {
		instance = createNonAjaxPostService(Models.CheckDepositExportConfig);
	}

	return instance;
}
