import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { AlertControl, AlertType } from '../../../transaction-import/components/alert-control';
import { Button } from '../../../../Shared/components/button/button';
import { CommunityMemberStatsDisplay } from '../../components/community-member-stats-display/community-member-stats-display';
import { ConfigurationStep, configurationSteps, GivingStatementsViewModel } from '../../giving-statements-view-model';
import { ConfigurePageEvents, ConfigurePageStates } from '../../state/configure-page';
import { ConfigurePageViewModel } from './configure-page-view-model';
import { EnterStatementDetails } from '../../components/enter-statement-details';
import { FormEvents } from '../../state/form-states';
import { DeleteStatementsEvents } from '../../state/delete-statements-states';
import { GivingStatementsPeriodType } from '../../giving-statements-generated';
import { MachineContext, machineContextKey } from '../../../../Shared/state-machine/saga-state-machine';
import { MainEvents } from '../../state/main';
import { Match } from '../../../../Shared/state-machine/match';
import { classNames } from '../../../../Shared/utils/classnames';
import { Panel, PanelType, PanelFooter } from '../../../components/panel';
import { PeriodSelector } from '../../components/period-selector/period-selector';
import { ScrollToElement } from '../../components/scroll-to-element';
import { StepStatusIndicator } from '../../../components/step-status';
import { TaxDeductibleFundsModal } from '../../components/tax-deductible-funds/tax-deductible-funds-modal';
import { ValidatableForm } from '../../../components/form-controls/validatable-form';
import { ValidationSummary } from '../../../components/form-controls/validation-summary';
import { DuplicatePeriodModal } from '../../components/configure-duplicate-period-modal';
import { MatchModal } from '../../../components/match-modal';
import { SvgWrapper } from '../../../components/svg-wrapper';
import { injectAnalytics, recordEvent, WithAnalytics } from '../../../analytics';
import { DeleteStatementsButton } from '../../components/delete-statements-button';
import * as rootStyles from '../../app.less';
import * as styles from './index.less';

export interface IConfigurePageProps {
	machineContext?: MachineContext;
	vm: ConfigurePageViewModel;
}

@injectAnalytics
@inject(machineContextKey)
@observer
export class ConfigurePage extends React.Component<WithAnalytics & IConfigurePageProps> {
	render() {
		const {
			vm: {
				mainViewModel: {
					communityMemberFundStats,
					currentConfiguration: {
						statementName,
						emailAddress,
						phoneNumber,
						currentDate,
						availableYears,
						selectedType,
						statementFromDate,
						statementToDate,
						nonCashAvailable,
						nonCashIncluded,
						einIncluded,
						customerAddressIncluded,
						ein,
						customerAddress,
						publishType,
						contributionsThreshold,
						hasBeenPublished,
					}
				},
				statementNameChanged,
				emailAddressChanged,
				phoneNumberChanged,
				einIncludedChanged,
				customerAddressIncludedChanged,
				publishTypeChanged,
				contributionsThresholdChanged,
				errorMap,
				errorMessages,
				taxDeductibleFunds,
				fundsSummaryUrl,
				isRegenerating,
				periodControlsDisabled,
				statementControlsDisabled,
				nonChangeableControlsDisabled,
				paymentLabel,
			}
		} = this.props;
		const minimumYear = availableYears && availableYears.length > 0 ? availableYears[0] : currentDate.getFullYear(); // TODO should be returned from back end

		return (
			<div className={rootStyles.pageContainer}>
				<Match state={ConfigurePageStates.LOAD_COMMUNITY_MEMBER_STATS_FAILED}>
					<ScrollToElement>
						<AlertControl
							alertType={AlertType.Danger}
							alertContent="Something has gone wrong. Please reload the page and try again."
						/>
					</ScrollToElement>
				</Match>
				<StepStatusIndicator
					currentStep={ConfigurationStep.Configure}
					steps={configurationSteps}
				/>
				<h1 className={rootStyles.pageTitle}>
					Configure your statements
					{
						!hasBeenPublished &&
						<DeleteStatementsButton className={styles.deleteButton} onDelete={this.deleteStatements} />
					}
				</h1>

				<ValidatableForm errorMap={errorMap} onSubmit={this.submitForm} >
					{
						errorMessages && errorMessages.length > 0 &&
						<ScrollToElement>
							<ValidationSummary validationErrors={errorMessages} />
						</ScrollToElement>
					}
					<Panel panelType={PanelType.DEFAULT}>
						<PeriodSelector
							defaultPeriodType={selectedType}
							defaultStartDate={statementFromDate}
							defaultEndDate={statementToDate}
							minimumYear={minimumYear}
							currentDate={currentDate}
							onPeriodChange={this.handlePeriodChange}
							disabled={isRegenerating || periodControlsDisabled}
						/>
						<EnterStatementDetails
							name={statementName}
							email={emailAddress}
							phone={phoneNumber}
							periodType={selectedType}
							nonCashAvailable={nonCashAvailable}
							nonCashIncluded={nonCashIncluded}
							einIncluded={einIncluded}
							customerAddressIncluded={customerAddressIncluded}
							onNameChange={statementNameChanged}
							onEmailChange={emailAddressChanged}
							onPhoneChange={phoneNumberChanged}
							onNonCashIncludedChange={this.handleNonCashIncludedChange}
							onEINIncludedChange={einIncludedChanged}
							onCustomerAddressIncludedChange={customerAddressIncludedChanged}
							onPublishTypeChange={publishTypeChanged}
							onContributionsThresholdChange={contributionsThresholdChanged}
							disabled={statementControlsDisabled}
							nonChangeableControlsDisabled={nonChangeableControlsDisabled}
							paymentLabel={paymentLabel}
							ein={ein}
							publishType={publishType}
							customerAddress={customerAddress}
							contributionsThreshold={contributionsThreshold}
						/>
						{!communityMemberFundStats.DisabledForCampusOrg && <CommunityMemberStatsDisplay
							memberCount={communityMemberFundStats.CommunityMemberTotal}
							memberWithoutEmailCount={communityMemberFundStats.CommunityMembersWithNoEmail}
							memberWithoutEmailOrPhysicalCount={communityMemberFundStats.CommunityMembersWithNoPhysicalAddress}
						/>}
						<PanelFooter extraClassNames={classNames('panel-footer-btn-group', styles.footer)}>
						<>
							<a href="#" className={styles.backLink} onClick={this.goBack}>
								<SvgWrapper className={styles.icon} svg="icon-chevron-left" />
								Back to home
							</a>
							<Button className="btn btn-ghost" type="submit" onClick={this.backToHomeAfterSubmit}>
								Save for later
							</Button>
								<Button className="btn btn-default" type="submit" onClick={this.continueAfterSubmitForPage} >
								Save and continue
							</Button>
						</>
						</PanelFooter>
					</Panel>
				</ValidatableForm>
				<MatchModal state={ConfigurePageStates.FUNDS_MODAL_ACTIVE}>
					<TaxDeductibleFundsModal fundsSummaryUrl={fundsSummaryUrl} items={taxDeductibleFunds} onClose={this.dismissModal} />
				</MatchModal>
				<MatchModal state={ConfigurePageStates.DUPLICATE_MODAL_ACTIVE}>
					<DuplicatePeriodModal
						onCancel={this.dismissDuplicateModal}
						onSubmit={this.continueAfterSubmitForModal}
					/>
				</MatchModal>
			</div>
		);
	}

	private goBack = () => {
		this.props.machineContext.transition(MainEvents.LOAD_PREVIOUS_VIEW);
	}

	private submitForm = () => {
		this.props.machineContext.transition(FormEvents.START_SUBMISSION);
	}

	private showFundsModal = () => {
		this.props.machineContext.transition(ConfigurePageEvents.SHOW_FUNDS_MODAL);
	}

	private dismissModal = () => {
		this.props.machineContext.transition(ConfigurePageEvents.DISMISS_FUNDS_MODAL);
	}

	private dismissDuplicateModal = () => {
		this.props.machineContext.transition(ConfigurePageEvents.DISMISS_DUPLICATE_PERIOD_MODAL);
	}

	private showHistoricalRuns = () => {
		this.props.machineContext.transition(MainEvents.LOAD_HISTORICAL_RUNS);
	}

	private handlePeriodChange = (periodType: GivingStatementsPeriodType, startDate: Date, endDate: Date) => {
		this.props.vm.periodChangedHandler(periodType, startDate, endDate);
		this.props.machineContext.transition(ConfigurePageEvents.LOAD_COMMUNITY_MEMBER_STATS);
	}

	private handleNonCashIncludedChange = (nonCashIncluded: boolean) => {
		this.props.vm.nonCashIncludedChanged(nonCashIncluded);
		this.props.machineContext.transition(ConfigurePageEvents.LOAD_COMMUNITY_MEMBER_STATS);
	}

	private continueAfterSubmit = () => {
		if (NewFeatures.SetupPinpointAnalytics && this.props.analytics) {
			const { feature, subFeature } = this.props.analytics;
			recordEvent({ feature, subFeature, eventTypeLabel: 'saveAndContinue' });
		}
		this.props.vm.setBackToHomeAfterSubmit(false);
	}

	private continueAfterSubmitForPage = () => {
		this.props.vm.setIsIntended("false");
		this.continueAfterSubmit();
	}

	private continueAfterSubmitForModal = () => {
		this.props.vm.setIsIntended("true");
		this.continueAfterSubmit();
		this.props.machineContext.transition(FormEvents.START_SUBMISSION);
	}

	private backToHomeAfterSubmit = () => {
		this.props.vm.setBackToHomeAfterSubmit(true);
	}

	private deleteStatements = () => {
		this.props.machineContext.transition(DeleteStatementsEvents.DELETE_START);
	}
}
