import * as React from 'react';
import { observer } from 'mobx-react';
import { ModalDialog } from '../modal-dialog';
import { Button } from '../button';
import { ModalDialogProcessingViewModel } from '../modal-dialog/modal-dialog-processing-view-model';
import { PaymentBatchViewModel } from '../../settings/settings-generated';
import { EventTimeOpenBatchesList } from './event-time-open-batches-list';

@observer
export class EventTimeEditConfirmationDialog extends React.Component<{
	onConfirm: () => void,
	onCancel: () => void,
	modalDialogProcessingViewModel: ModalDialogProcessingViewModel,
	openPaymentBatches: PaymentBatchViewModel[],
}> {
	render() {
		const { onConfirm, onCancel, modalDialogProcessingViewModel: { isProcessing }, openPaymentBatches } = this.props;
		const buttons = [
			<Button key="cancel" className="btn btn-cancel" type="button" disabled={isProcessing} onClick={onCancel}>Cancel</Button>,
			<Button key="submit" className="btn btn-default" type="submit" isProcessingRequest={isProcessing} onClick={onConfirm}>Continue</Button>,
		];

		return (
			<ModalDialog title="Are you sure you want to continue?" buttons={buttons} hideCloseButton showAlert={false}>
				<ul className="modal-text text-left">
					<li>We'll archive the existing service time and a new one will be created</li>
					<li>All completed batches will remain with the existing service time</li>
					<li>The new service time will be applied to all open batches and listings</li>
				</ul>
				<EventTimeOpenBatchesList openPaymentBatches={openPaymentBatches} />
			</ModalDialog>
		);
	}
}
