import * as React from 'react';
import { observer } from 'mobx-react';
import { Formatter } from '../../../helpers/formatter';
import { FormControlTextarea } from '../../../components/form-controls/form-control-textarea';
import { FormControlLabelled, FormControlType } from '../../../components/form-controls/form-control-labelled';
import { ValidationMessage } from '../../../components/form-controls/validation-message';
import { Button } from 'components/button';
import { ScheduleImportEmailType, ModelMetadata, EmailBranding, PaymentLabel } from '../../schedule-import-generated';
import { MigrationEmailsViewModel } from './migration-emails-view-model';
import { Fragment } from '../../../../Shared/components/fragment';
import { classNames } from '../../../../Shared/utils/classnames';
import * as styles from './styles.less';
import { IHaveValidatableFormContext, injectValidationFormContext, getFieldError } from '../../../components/form-controls/validatable-form-context';

export interface IEditEmailTemplateProps extends IHaveValidatableFormContext {
	vm: MigrationEmailsViewModel;
	paymentLabel: PaymentLabel;
	contactName: string;
	contactEmail: string;
	contactPhone: string;
	emailBranding: EmailBranding;
	endDate: Date;
}

@injectValidationFormContext
@observer
export class MigrationEmails extends React.Component<IEditEmailTemplateProps> {
	private emailBody: HTMLDivElement;

	render() {
		const {
			activeEmailTemplateType,
		} = this.props.vm;

		const isInitialEmailActive = activeEmailTemplateType === ScheduleImportEmailType.InitialInvite;
		const isWeeklyEmailActive = activeEmailTemplateType === ScheduleImportEmailType.WeeklyReminder;
		const isFinalEmailActive = activeEmailTemplateType === ScheduleImportEmailType.FinalReminder;
		return (
			<Fragment>
				<div className="list-group-wrapper">
					<ul className={`nav nav-tabs ${styles.emailTab}`}>
						<li className={classNames(isInitialEmailActive ? 'active' : null, styles.emailTabItem)}>
							<a href=""
								tabIndex={isInitialEmailActive ? -1 : null}
								onClick={event => this.onToggleActiveEmailTemplate(event, ScheduleImportEmailType.InitialInvite)}
								data-pp-at-target="InitialEmail">
								Initial email
							</a>
						</li>
						<li className={classNames(isWeeklyEmailActive ? 'active' : null, styles.emailTabItem)}>
							<a href=""
								tabIndex={isWeeklyEmailActive ? -1 : null}
								onClick={event => this.onToggleActiveEmailTemplate(event, ScheduleImportEmailType.WeeklyReminder)}
								data-pp-at-target="ReminderEmail">
								Reminder email
							</a>
						</li>
						<li className={classNames(isFinalEmailActive ? 'active' : null, styles.emailTabItem)}>
							<a href=""
								tabIndex={isFinalEmailActive ? -1 : null}
								onClick={event => this.onToggleActiveEmailTemplate(event, ScheduleImportEmailType.FinalReminder)}
								data-pp-at-target="FinalReminder">
								Final reminder
							</a>
						</li>
					</ul>
					{this.renderEmailTemplate()}
				</div>
			</Fragment>
		);
	}

	private renderEmailTemplate() {
		const {
			vm: {
				activeEmailTemplate,
				activeEmailTemplateType,
			},
			paymentLabel,
			contactName,
			contactEmail,
			contactPhone,
			emailBranding,
			endDate,
		} = this.props;

		const borderStyle: React.CSSProperties = {
			borderTopColor: emailBranding.PrimaryColor
		};

		const buttonStyle: React.CSSProperties = {
			borderColor: emailBranding.PrimaryColor,
			backgroundColor: emailBranding.PrimaryColor
		};

		const linkStyle: React.CSSProperties = {
			color: emailBranding.PrimaryColor,
		};

		const showVideoUrlAndText = activeEmailTemplateType === ScheduleImportEmailType.InitialInvite;
		return (
			<div className={styles.emailTemplate}>
				<div className={styles.overlay}></div>
				<div className={styles.header} style={borderStyle}>
					<img className={styles.logo} src={emailBranding.MerchantLogoUrl} />
					<h1 className={styles.heading}>{emailBranding.OrganizationName}</h1>
					<hr className={styles.headerDivider}/>
					<div className={styles.date}>{Formatter.formatDate(endDate, 'MMM D, YYYY')}</div>
				</div>
				<div className={styles.body} ref={ref => this.emailBody = ref}>
					<h1 className={styles.heading}>Hi {paymentLabel.PayerLowerCase},</h1>
					{showVideoUrlAndText && <Fragment>
						{this.renderTextTemplate(activeEmailTemplate.VideoMessage, ModelMetadata.EmailTemplate.VideoMessage, 4, this.updateVideoTextTemplate)}
						<FormControlLabelled
							label={ModelMetadata.EmailTemplate.VideoLink.displayName}
							cssClassNames={styles.videoLink}
							formControlProps={{
								name: ModelMetadata.EmailTemplate.VideoLink.propertyName,
								formControlType: FormControlType.Text,
								type: 'text',
								value: activeEmailTemplate.VideoLink,
								onChangeHandler: this.updateVideoLink,
								validationRules: ModelMetadata.EmailTemplate.VideoLink.validationRules,
						}} />
					</Fragment>}

					{this.renderTextTemplate(activeEmailTemplate.Message, ModelMetadata.EmailTemplate.Message, 10, this.updateTextTemplate)}

					<div className={styles.giftDetails} style={borderStyle}>
						<div className={styles.giftHeader}>Your {paymentLabel.NounLowerCase} details</div>
						<div className={styles.giftBody}>
							<div className={`row ${styles.infoRow}`}>
								<div className={`col-sm-6 ${styles.infoLabel}`}>Amount</div>
								<div className={`col-sm-6 ${styles.infoValue}`}>${Formatter.formatNumberForDisplay(100)}</div>
							</div>
							<div className={`row ${styles.infoRow}`}>
								<div className={`col-sm-6 ${styles.infoLabel}`}>{paymentLabel.NounSentenceCase} frequency</div>
								<div className={`col-sm-6 ${styles.infoValue}`}>Every month</div>
							</div>
							<div className={`row ${styles.infoRow}`}>
								<div className={`col-sm-6 ${styles.infoLabel}`}>Fund</div>
								<div className={`col-sm-6 ${styles.infoValue}`}>{emailBranding.DefaultFundName}</div>
							</div>
							<div className={styles.action}>
								<Button className="btn btn-default" style={buttonStyle}>Set up recurring {paymentLabel.NounLowerCase}</Button>
							</div>
						</div>

					</div>

					<p>If you would prefer not to set up a {paymentLabel.NounLowerCase} using Pushpay you may <a href="#" style={linkStyle}>unsubscribe from reminder emails</a>.</p>
					<p>If you need help with anything, or would like to send us your feedback, please contact {contactName} on the following details:</p>
					<p><strong>Phone:</strong> {contactPhone}</p>
					<p><strong>Email:</strong> {contactEmail}</p>
					<hr className={styles.footerDivider}/>
				</div>
				<div className={styles.footer}>
					{ emailBranding.OrganizationLegalName && <div className={styles.legalName}>
						Organization Legal Name: { emailBranding.OrganizationLegalName }
					</div>}
					{ emailBranding.ShowLegalCopy && <p>No goods or services were given in exchange for the listed contribution other than intangible religious benefits.</p>}
					<img className={styles.pushpayLogo} src={emailBranding.PushpayLogoUrl} />
				</div>
			</div>
		);
	}

	private renderTextTemplate(text: string, meta: ModelMetadata.IPropertyMetadata, row: number, onChange: (ev: React.FormEvent<HTMLTextAreaElement>) => void) {
		return (
			<Fragment>
				<div className={styles.emailContentWrapper}>
					{this.renderCharCount(text, meta.validationRules.length.parameters.max)}
					<FormControlTextarea
						cssClassNames={styles.message}
						name={meta.propertyName}
						value={text}
						rows={row}
						validationRules={meta.validationRules}
						onChangeHandler={onChange} />
					<ValidationMessage elementName={meta.propertyName}/>
				</div>
			</Fragment>
		);
	}

	private renderCharCount(text: string, charAllowed: number) {
		const charUsed = text ? text.length : 0;
		return (
			<div className={classNames(styles.charUsed, charUsed > charAllowed ? styles.limitExceeded : null)}>
				{charUsed} / {charAllowed} characters used
			</div>
		);
	}

	private onToggleActiveEmailTemplate = (event: React.MouseEvent<HTMLAnchorElement>, emailType: ScheduleImportEmailType) => {
		event.preventDefault();

		if (this.validateEmailTemplateFields() === false) {
			return;
		}

		const {
			activeEmailTemplateType,
			updateActiveEmailTemplateType,
		} = this.props.vm;

		if (activeEmailTemplateType !== emailType) {
			updateActiveEmailTemplateType(emailType);
		}
	}

	private validateEmailTemplateFields = (): boolean => {
		const meta = ModelMetadata.EmailTemplate;
		const elementList = this.props.vm.activeEmailTemplateType === ScheduleImportEmailType.InitialInvite
			? [meta.Message.propertyName, meta.VideoLink.propertyName, meta.VideoMessage.propertyName]
			: [meta.Message.propertyName];

		elementList.forEach(x => this.props.validatableFormContext.validateElement(x));

		if (elementList.some(x => !!getFieldError(this.props, x))) {
			const rect = this.emailBody.getBoundingClientRect();
			const scrollToY = rect.top + window.pageYOffset;
			window.scrollTo(0, scrollToY);
			return false;
		}

		return true;
	}

	private updateTextTemplate = (ev: React.FormEvent<HTMLTextAreaElement>) => {
		this.props.vm.updateEmailMessage(ev.currentTarget.value);
	}

	private updateVideoTextTemplate = (ev: React.FormEvent<HTMLTextAreaElement>) => {
		this.props.vm.updateEmailVideoMessage(ev.currentTarget.value);
	}

	private updateVideoLink = (ev: React.FormEvent<HTMLInputElement>) => {
		this.props.vm.updateEmailVideoLink(ev.currentTarget.value);
	}
}
