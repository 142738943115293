import * as React from 'react';
import { classNames } from '../../../Shared/utils/classnames';

import * as styles from './styles.less';
import { SvgWrapper } from '../svg-wrapper';

export type PaginationProps = {
	numberOfPages: number;
	currentPage: number;
	onPageChange: (newPage: number) => void;
};

const LEFT_PAGE = -100;
const RIGHT_PAGE = -200;

const PAGE_NEIGHBORS = 2;

export class Pagination extends React.Component<PaginationProps> {
	render() {
		const { numberOfPages, currentPage } = this.props;
		const isFirstPage = currentPage === 1;
		const isLastPage = currentPage === numberOfPages;

		if (numberOfPages < 2) {
			return null;
		}

		return (
			<ul className={styles.paginationContainer}>
				<li key="previous" className={classNames(styles.paginationControl, {[styles.paginationControlDisabled]: isFirstPage})} onClick={isFirstPage ? null : this.handleMoveLeft}>
					<SvgWrapper svg="icon-chevron-left" className={styles.directionIcon} />
					<span className={styles.directionText}>Previous</span>
				</li>
				{this.pageNumbers.map( (pageNumber: number, index: number) => this.renderPageNumber(pageNumber, index) )}
				<li key="next" className={classNames(styles.paginationControl, {[styles.paginationControlDisabled]: isLastPage})} onClick={isLastPage ? null : this.handleMoveRight}>
					<span className={styles.directionText}>Next</span>
					<SvgWrapper svg="icon-chevron-right" className={styles.directionIcon} />
				</li>
			</ul>
		);
	}

	handleClick = (page: number) => {
		this.goToPage(page);
	}

	handleMoveLeft = () => {
		const { currentPage } = this.props;
		this.goToPage(currentPage - 1);
	}

	handleMoveRight = () => {
		const { currentPage } = this.props;
		this.goToPage(currentPage + 1);
	}

	private renderPageNumber(pageNumber: number, index: number): React.ReactNode {
		if (pageNumber === LEFT_PAGE || pageNumber === RIGHT_PAGE) {
			return <li key={index} className={classNames(styles.paginationControl, styles.omitSymbol)} aria-label="omit pages">...</li>;
		}

		const { currentPage } = this.props;

		const isCurrentPage = pageNumber === currentPage;
		const appliedClasses = classNames({[styles.paginationControl]: true, [styles.currentPage]: isCurrentPage});
		const clickHandler = isCurrentPage ? null : () => this.handleClick(pageNumber);

		return <li key={index} className={appliedClasses} onClick={clickHandler}>{pageNumber}</li>;
	}

	private createRange = (from: number, to: number): number[] => {
		let i = from;
		const range: number[] = [];

		while (i <= to) {
			range.push(i);
			i++;
		}

		return range;
	}

	private goToPage = (page: number) => {
		const { onPageChange, numberOfPages } = this.props;

		const currentPage = Math.max(0, Math.min(page, numberOfPages));

		onPageChange(currentPage);
	}

	private get pageNumbers(): number[] {
		const { numberOfPages, currentPage } = this.props;
		const totalNumbers = PAGE_NEIGHBORS * 2 + 3;
		const totalBlocks = totalNumbers + 2;

		if (numberOfPages > totalBlocks) {

			const leftBound = currentPage - PAGE_NEIGHBORS;
			const rightBound = currentPage + PAGE_NEIGHBORS;
			const beforeLastPage = numberOfPages - 1;

			const startPage = leftBound > 2 ? leftBound : 2;
			const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

			const defaultPages = this.createRange(startPage, endPage);

			const pageCount = defaultPages.length;
			const singleSpillOffset = totalNumbers - pageCount - 1;

			const spillLeft = startPage > 2;
			const spillRight = endPage < beforeLastPage;

			if (spillLeft && !spillRight) {
				const extraPages = this.createRange(startPage - singleSpillOffset, startPage - 1);
				return [1, LEFT_PAGE, ...extraPages, ...defaultPages, numberOfPages];
			}

			if (!spillLeft && spillRight) {
				const extraPages = this.createRange(endPage + 1, endPage + singleSpillOffset);
				return [1, ...defaultPages, ...extraPages, RIGHT_PAGE, numberOfPages];
			}

			return [1, LEFT_PAGE, ...defaultPages, RIGHT_PAGE, numberOfPages];
		}

		return this.createRange(1, numberOfPages);
	}
}
