import { ResponseTypes } from '../../funds/funds-generated';
import { IListingModel } from './listing-model';
import { observable, action, computed } from 'mobx';
import { IntegrationServiceClient } from './integration-service-client';

import IIntegrationConfigurationViewModel = ResponseTypes.IIntegrationConfigurationViewModel;
import IntegrationListingConfigurationViewModel = ResponseTypes.IntegrationListingConfigurationViewModel;

export interface IIntegrationConfigurationStore<TModel> {
	viewModel: ResponseTypes.IntegrationViewModel;
	serviceClient: IntegrationServiceClient;
	isAuthenticated: boolean;
	isAuthenticationSectionEditable: boolean;
	cancelActionUrl: string;
	startEditingAuthenticationSection: () => void;
	cancelEditingAuthenticationSection: () => void;
	listings: IListingModel<TModel>[];
}

export abstract class IntegrationConfigurationStore<TModel, TConfiguration extends IIntegrationConfigurationViewModel<any>>
	implements IIntegrationConfigurationStore<TModel> {

	@observable
	viewModel: ResponseTypes.IntegrationViewModel;

	@observable
	serviceClient: IntegrationServiceClient;

	@observable
	protected authenticationSectionInEditMode = false;

	@observable
	protected configuration: TConfiguration;

	constructor(configuration: TConfiguration) {
		this.setConfiguration(configuration);
	}

	@computed
	get listings(): IListingModel<TModel>[] {
		return this.configuration.Listings
			? this.configuration.Listings.orderBy(x => x.ListingName.toLowerCase()).toArray().map(x => this.createListing(x))
			: [];
	}

	@computed
	get isAuthenticationSectionEditable() {
		return !this.isAuthenticated || this.authenticationSectionInEditMode;
	}

	@computed
	get isAuthenticated() {
		return this.viewModel.IsAuthenticated;
	}

	@computed
	get systemStatus(): ResponseTypes.ExternalSystemStatus {
		return this.viewModel.SystemStatus;
	}

	get cancelActionUrl() {
		return this.configuration.ViewModel.ActionUrls.CancelActionUrl;
	}

	@action
	startEditingAuthenticationSection = () => {
		this.authenticationSectionInEditMode = true;
	}

	@action
	cancelEditingAuthenticationSection = () => {
		this.authenticationSectionInEditMode = false;
	}

	@action
	setConfiguration(configuration: any) {
		this.viewModel = configuration.ViewModel;
		this.configuration = configuration;
		this.authenticationSectionInEditMode = false;
		this.serviceClient = new IntegrationServiceClient(
			this.viewModel.Metadata.IntegrationSystem.DisplayName,
			this.viewModel.IsThirdParty,
			this.viewModel.Metadata.IntegrationSystem.IntegrationTypes.PaymentIsEnabled);
		this.serviceClient.actionUrls = this.viewModel.ActionUrls;
	}

// ReSharper disable once InconsistentNaming
	isListingMissingFallbackFund(listing: IntegrationListingConfigurationViewModel<{ FallbackFundKey: string }>, integrationFunds: ResponseTypes.IntegrationFundViewModel[]): boolean {
		//false when setup incomplete - because all listings enabled with no fallback fund selected
		//also it should be false when the listing is disabled
		if (this.viewModel.IsSetupIncomplete || !listing.Model) {
			return false;
		}
		const fallbackFundKey = listing.Model.FallbackFundKey;

		return !fallbackFundKey || integrationFunds.filter(x => x.Key === fallbackFundKey).length === 0;
	}

	abstract createListing(listing: IntegrationListingConfigurationViewModel<TModel>): IListingModel<TModel>;
}
