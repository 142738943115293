import * as React from 'react';
import { observer } from 'mobx-react';
import { isFunction } from '../../../utils/is-function';
import { FormControlLabelled, FormControlType } from '../../../components/form-controls/form-control-labelled';

export interface IAnnualSelectorProps {
	name: string;
	chosenYear: number;
	availableAnnualYears: number[];
	onAnnualChange: (year: number) => void;

	acceptanceTestTargetId: string;
	ariaLabel?: string;
	disabled?: boolean;
}

@observer
export class AnnualSelector extends React.Component<IAnnualSelectorProps, {}> {

	render() {
		const { name, chosenYear, availableAnnualYears, disabled } = this.props;
		const { acceptanceTestTargetId, ariaLabel } = this.props;

		const validationRules = this.validate(chosenYear);

		return (
			<div aria-label={ariaLabel}>
				<FormControlLabelled label="Year"
					acceptanceTestTargetId={acceptanceTestTargetId}
					cssClassNames="col-sm-4"
					formControlProps={{
						placeholder: 'Please select a year',
						name: name,
						formControlType: FormControlType.DropDown,
						value: chosenYear ? chosenYear.toString() : undefined,
						Options: availableAnnualYears.map(x => ({ Value: x.toString(), Label: x.toString() })),
						validationRules: validationRules,
						onChangeHandler: this.handleChange,
						disabled,
					}} />
			</div>
		);
	}

	private handleChange = (event: React.FormEvent<HTMLSelectElement>) => {
		const year = Number(event.currentTarget.value);

		const { onAnnualChange } = this.props;

		if (isFunction(onAnnualChange)) {
			onAnnualChange(year);
		}
	}

	private validate(year: number) {
		if (!year) {
			return { nevervalid: { errorMessage: 'Please select a year' } };
		}
	}
}
