import * as React from 'react';
import * as styles from './convert-community-member-to-org-modal.less';
import { observer } from 'mobx-react';
import { ModalDialogForm } from '../../components/modal-dialog-form';
import { Button } from '../../components/button';
import { FormControlLabelled, FormControlType } from '../../components/form-controls/form-control-labelled';
import { MemberSearchOmnibox } from './member-search-omnibox';
import { ConvertCommunityMemberToOrgModalViewModel } from '../view-models/convert-community-member-to-org-modal-viewmodel';
import { SvgWrapper } from '../../../Shared/components/svg-wrapper';
import { AnimatedSuccessTick } from '../../components/animated-svg/success-tick';
import { Fragment } from '../../../Shared/components/fragment';

export interface IConvertCommunityMemberToOrgModalProps {
	vm: ConvertCommunityMemberToOrgModalViewModel;
}

const cancelButton = <Button key="cancel"
	className="btn btn-cancel"
	type="button"
	data-dismiss="modal"
	acceptanceTestTargetId="Convert Community Member Cancel">Cancel</Button>;

const closeButton = <Button key="close"
	className="btn btn-default"
	type="button"
	data-dismiss="modal"
	acceptanceTestTargetId="Convert Community Member Close">Close</Button>;



@observer
export class ConvertCommunityMemberToOrgModal extends React.Component<IConvertCommunityMemberToOrgModalProps> {

	render() {
		const { vm } = this.props;

		return (
			<ModalDialogForm heading="Change member type"
				buttons={this.getModalButtons()}
				onSubmitHandler={vm.onConvertClicked}>
				{this.getModalContent()}
			</ModalDialogForm>
		);
	}

	componentWillUnmount() {
		const { vm } = this.props;
		vm.eligibilityAutorunDisposer();
	}

	private getModalButtons() {
		const { vm } = this.props;

		const cancelGuardButton = <Button key="cancel"
			className="btn btn-cancel"
			type="button"
			onClick={vm.onCancelGuardCancelClicked}
			acceptanceTestTargetId="Convert Community Member Cancel Guard">Cancel</Button>;

		const convertButton = <Button key="submit"
			className="btn btn-default"
			type="submit"
			acceptanceTestTargetId="Convert Community Member Convert"
			disabled={!vm.convertButtonEnabled}
			isProcessingRequest={vm.isConverting}>Confirm</Button>;

		if (vm.conversionDone) {
			return [closeButton];
		} else if (!vm.conversionDone && vm.conversionGuarded) {
			return [cancelGuardButton, convertButton];
		} else {
			return [cancelButton, convertButton];
		}
	}

	private getModalContent() {
		const { vm } = this.props;

		if (vm.errorOccuredInConversion) {
			return (
				<div className={`modal-text ${styles.modalText}`}>
					<SvgWrapper className={`${styles.errorIcon}`} svg="alert-exclamation-icon" width={68} height={68} />
					<h2>Error</h2>
					<p>Something has gone wrong. Please reload the page and try again.</p>
				</div>
			);
		} else if (vm.conversionGuarded) {
			return (
				<div className={`modal-text ${styles.modalText}`}>
					<SvgWrapper className={`${styles.errorIcon}`} svg="alert-exclamation-icon" width={68} height={68} />
					<h2>This action cannot be reversed. Are you sure you wish to proceed?</h2>
					<p>Community member <strong>{vm.selectedPayer.name}</strong> will be changed to an organization named <strong>{vm.newName}</strong></p>
				</div>
			);
		} else if (vm.conversionDone) {
			if (vm.conversionSuccess) {
				return (
					<div className={`modal-text ${styles.modalText}`}>
						<AnimatedSuccessTick />
						<h2>Success!</h2>
						<p>Community member <strong>{vm.selectedPayer.name}</strong> has been changed to an organization named <strong>{vm.newName}</strong></p>
					</div>
				);
			} else {
				return (
					<div className={`modal-text ${styles.modalText}`}>
						<SvgWrapper className={`${styles.errorIcon}`} svg="alert-exclamation-icon" width={68} height={68} />
						<h2>Error</h2>
						<p>Sorry, we were unable to change that community member's type to organization.</p>
					</div>
				);
			}
		} else {
			return (
				<Fragment>
					<MemberSearchOmnibox label="1) Select a Person to change to an Organization" vm={vm.omniboxVm} errorText={vm.validationErrorMessage} enableFeatureOrganizationalGiving={true}/>
					{
						vm.omniboxVm.selectedPayer &&
						!vm.validationErrorMessage &&
						<FormControlLabelled
							label="2) This Person will be changed to an Organization with the following name:"
							formControlProps={{
								name: 'newOrganizationName',
								type: 'text',
								formControlType: FormControlType.Text,
								value: vm.newName,
								onChangeHandler: vm.handleNewNameChange,
							}}
						/>
					}
				</Fragment>
			);
		}
	}
}
