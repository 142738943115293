import * as React from 'react';
import { observer } from 'mobx-react';

import {
	FundInfo,
} from '../../schedule-import-generated';
import { Formatter } from '../../../helpers/formatter';
import { RenderResponsive } from '../../../components/hoc-behavior/render-responsive';
import { ResponsiveVisibility, ResponsiveBreakpoint } from '../../../helpers/responsive-helper';

export interface IFundGridProps {
	funds: FundInfo[];
	totalAmount: number;
}

@observer
export class FundGrid extends React.Component<IFundGridProps> {
	render() {
		const {
			funds,
			totalAmount,
		} = this.props;

		return (
			<div className="transaction-import-summary-grid small-top-margin">
				<RenderResponsive visibility={ResponsiveVisibility.Hidden} breakpoints={ResponsiveBreakpoint.Xs}>
					<div className="row summary-grid-header">
						<div className="col-sm-3">Funds from your file</div>
						<div className="col-sm-3">Pushpay funds</div>
						<div className="col-sm-4">Notes</div>
						<div className="col-sm-2 text-right">Amount</div>
					</div>
				</RenderResponsive>

				{funds.map((fund, index) => (
					<div className="row summary-grid-row" key={index}>
						<div className="col-sm-3 col-xs-8 summary-grid-cell"><span className="summary-label-responsive">{fund.ImportedFundName}</span></div>
						<RenderResponsive visibility={ResponsiveVisibility.Hidden} breakpoints={ResponsiveBreakpoint.Xs}>
							<div className="col-sm-3 summary-grid-cell">
								{fund.PushpayFundName} <span className="summary-text-secondary">{fund.PushpayFundCode && `(${fund.PushpayFundCode})`}</span>
							</div>
							<div className="col-sm-4 summary-grid-cell">{fund.Notes}</div>
						</RenderResponsive>
						<div className="col-sm-2 col-xs-4 text-right summary-grid-cell">${Formatter.formatNumberForDisplay(fund.Amount)}</div>
					</div>
				))}
				<div className="row summary-grid-row">
					<div className="col-xs-12 text-right summary-grid-cell"><strong>Total: </strong>${Formatter.formatNumberForDisplay(totalAmount)}</div>
				</div>
			</div>
		);
	}
}




