import * as React from 'react';
import { SettingsEventTimeViewModel } from '../../settings-generated';
import { classNames } from '../../../../Shared/utils/classnames';
import { SvgWrapper } from '../../../../Shared/components/svg-wrapper';
import { Button } from '../../../components/button';
import { FormControlCheckbox } from '../../../components/form-controls/form-control-checkbox/form-control-checkbox';
import { EventTimeForDisplayNarrow } from '../../../components/event-time/event-time-display-formats';

import * as styles from './service-time-picker.less';

export interface IServiceTimePickerItemProps {
	isActivated: boolean;
	onChangeActivated: (eventTime: SettingsEventTimeViewModel, isActivated: boolean) => void;
	onEdit: (eventTime: SettingsEventTimeViewModel) => void;
	eventTime: SettingsEventTimeViewModel;
}

export class ServiceTimePickerItem extends React.Component<IServiceTimePickerItemProps> {
	render() {
		const { isActivated, eventTime } = this.props;
		const text = <EventTimeForDisplayNarrow eventTime={{ ...eventTime, MerchantNames: null }} />;

		return (
			<li
				className={classNames(styles.item, isActivated ? styles.itemActivated : null)}
				data-simulate-hover={this.props['data-simulate-hover']}
			>
				<FormControlCheckbox
					value={isActivated}
					text={text}
					onChangeHandler={this.toggleActivated}
					containerClassNames={styles.checkbox}
					labelClassNames={classNames('no-wrap', 'truncate', styles.label)}
					tickClassNames={isActivated ? styles.tick : null}
				/>
				<Button
					className={classNames('btn', 'btn-link', styles.button)}
					onClick={this.onClickEdit}
					title="Edit service time"
				>
					<SvgWrapper svg="icon-edit" className={styles.icon} />
				</Button>
			</li>
		);
	}

	toggleActivated = (checked: boolean) => {
		const { onChangeActivated, eventTime } = this.props;

		onChangeActivated(eventTime, checked);
	}

	onClickEdit = () => {
		const { eventTime, onEdit } = this.props;

		onEdit(eventTime);
	}
}
