import { CancellablePromise, ICancellablePromise } from '../../utils/cancellable-promise';
import { isFunction } from '../../utils/is-function';

export interface IUploadProgressEvent {
	url: string;
	file: File;
	percentComplete: number;
	total: number;
	loaded: number;
}

export class UploadError {
	status: number;
	message: string;
}

export interface IImportFileUploader {
	uploadFile: (file: File, url: string, onProgress?: (ev: IUploadProgressEvent) => void) => ICancellablePromise<{}>;
}

export class ImportFileUploader implements IImportFileUploader {
	uploadFile(file: File, url: string, onProgress?: (ev: IUploadProgressEvent) => void): ICancellablePromise<{}> {
		return new CancellablePromise<{}>((resolve, reject, onCancel) => {
			var xhr = new XMLHttpRequest();

			if (isFunction(onProgress)) {
				xhr.upload.onprogress = (ev: ProgressEvent) => {
					const { total, loaded, lengthComputable } = ev;
					const percentComplete = lengthComputable ? (loaded / total) * 100 : 0;
					onProgress({ url, file, percentComplete, total, loaded });
				};
			}

			xhr.onload = () => {
				if (xhr.status === 200) {
					resolve({});
				} else {
					reject(buildError(xhr));
				}
			};
			xhr.onerror = () => reject(buildError(xhr));

			xhr.open('PUT', url);
			xhr.setRequestHeader('Content-type', 'text/plain');

			onCancel(() => xhr.abort());

			xhr.send(file);
		});
	}
}

function buildError(xhr: XMLHttpRequest): UploadError {
	const message = 'Unknown error';
	return {status:xhr.status, message};
}
