import * as React from 'react';
import { observer } from 'mobx-react';
import { MappingsSetupProgress, QuickBooksAccount } from '../../../qbo-integration-generated';
import { FormControlSelect, IFormControlSelectOptionProps } from '../../../../components/form-controls/form-control-select';
import {
	QboBankAccountMappingInfoViewModel,
	CollapsedAccountMapping,
	ProfitStarsMapping
} from '../../mapping-form/qbo-bank-account-mapping-info-view-model';
import { QboPanelContainer } from '../panel-container';

import * as styles from './styles.less';
import { FormControlToggle } from '../../../../components/form-controls/form-control-toggle';

export interface BankAccountMappingProps {
	vm: QboBankAccountMappingInfoViewModel;
	cancelAction: () => void;
	isProcessingRequest: boolean;
}

@observer
export class BankAccountMapping extends React.Component<BankAccountMappingProps> {
	render() {
		const {
			vm: {
				achMappings,
				creditCardMappings,
				profitStarsMappings,
				batchEntryMappings,
				bankAccounts,
				paymentLabel,
				activeStep,
				setStepAsActive,
				panelTitle,
			},
			cancelAction,
			isProcessingRequest,
		} = this.props;
		return (
			<QboPanelContainer
				title={panelTitle}
				editButtonAcceptanceTestTargetId="back-account-mapping-edit"
				activeOnStep={MappingsSetupProgress.Deposits}
				currentStep={activeStep}
				setStepAsActive={setStepAsActive}
				cancelAction={cancelAction}
				isProcessingRequest={isProcessingRequest}
			>
					<p className={styles.introParagraph}>This integration will send your Pushpay batches through to QuickBooks Online as journal entries. In
						order to ensure journals are assigned correctly in QuickBooks Online, please select the Bank
						Account/s where your Pushpay {paymentLabel.VerbPresentTenseLowerCase} is deposited into.</p>
					{ achMappings.map((mapping, index) => <MappingRow mapping={mapping} accounts={bankAccounts} index={index} key={index} />)}
					{ creditCardMappings.map((mapping, index) => <MappingRow mapping={mapping} accounts={bankAccounts} index={index} key={index} />)}
					{ profitStarsMappings.map((mapping, index) => <MappingRow mapping={mapping} accounts={bankAccounts} index={index} key={index} />)}
					{ batchEntryMappings.map((mapping, index) => <MappingRow mapping={mapping} accounts={bankAccounts} index={index} key={index} />)}
			</QboPanelContainer>
		);
	}
}

export interface MappingRowProps {
	mapping: CollapsedAccountMapping | ProfitStarsMapping;
	accounts: QuickBooksAccount[];
	index: number;
}

@observer
class MappingRow extends React.Component<MappingRowProps> {
	render() {
		const { accounts, index, mapping: { gatewayKind, associatedMerchants, isEnabled, selectedAccount, toggleIsEnabled } } = this.props;

		const optionsProps: IFormControlSelectOptionProps[] = accounts.map(account => {
			return { Label: account.Name, Value: account.Id };
		});

		return(
			<div className={styles.mappingRow}>
				<div className={styles.mappingType}>
					<div className={styles.typeName}>{gatewayKind}</div>
					<div className={styles.typeListings}>
						{associatedMerchants}
					</div>
				</div>
				<FormControlToggle value={isEnabled} onChangeHandler={toggleIsEnabled} containerClassNames={styles.mappingEnabled}/>
				<div className={styles.mappingAccounts}>
					<label className={`${styles.accountLabel} ${isEnabled ? '' : styles.disabled}`} htmlFor={`account-${index}`}>
						Match to QuickBooks bank account
					</label>
					<div className={`select-wrapper ${isEnabled ? '' : styles.disabled}`}>
						<FormControlSelect Options={optionsProps}
										onChangeHandler={this.accountChanged}
										value={selectedAccount}
										name={`account-${index}`}
										placeholder="Select a bank account"
										disabled={!isEnabled}/>
					</div>
				</div>
			</div>
		);
	}

	accountChanged = (e: React.FormEvent<HTMLSelectElement>) => {
		this.props.mapping.selectedAccountChanged(e.currentTarget.value);
	}
}
