import * as React from 'react';
import {observer} from 'mobx-react';
import {ArrayHelper} from '../../helpers/arrayhelper';

@observer
export class ValidationSummary extends React.Component<{ validationErrors?: string[], customTitle?: string }, {}> {
	render() {
		const { validationErrors } = this.props;
		const hasErrors = validationErrors && validationErrors.length > 0;
		return (
			<div className={hasErrors ? 'validation-summary-errors' : 'validation-summary-valid'} data-valmsg-summary="true">
				<span className="ico"></span>
				<h3>{this.props.customTitle || 'Sorry, please fix the following: '}</h3>
				<ul key={ArrayHelper.emptyIfNull(validationErrors).join()}>
					{hasErrors && validationErrors.map(error => <ValidationSummaryItem key={error} error={error} />)}
				</ul>
			</div>
		);
	}
}

const ValidationSummaryItem = observer((props: { error: string }) => {
	const error = props.error;
	return <li>{error}</li>;
});
