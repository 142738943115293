import * as React from 'react';
import {action, observable} from 'mobx';
import {LocalStorageHelper} from '../../helpers/localstoragehelper';
import {BatchOpenPortalDialog} from './batch-open-portal-dialog';
import { ModalDialogCommander } from '../../components/modal-dialog-commander';
import { ClientFlowHelper } from '../helpers/client-flow-helper';
import { BatchBeforeAllocationDialog } from './batch-before-allocation-dialog';

export class BatchDialogStore {
	@observable hideOpenPortalMessage: boolean;
	@observable hideBeforeAllocationMessage: boolean;
	private readonly HideOpenPortalMessageKey = 'CheckDepositBatches_HideOpenPortalMessage';
	private readonly HideBeforeAllocationMessageKey = 'CheckDepositBatches_HideBeforeAllocationMessage';

	constructor(public isCheckScanningReadOnly: boolean, public helpCenterUrl?: string) {
		this.hideOpenPortalMessage = this.getInitialMessageKeyValue(this.HideOpenPortalMessageKey);
		this.hideBeforeAllocationMessage = this.getInitialMessageKeyValue(this.HideBeforeAllocationMessageKey);
	}

	@action
	toggleHideOpenPortalMessage = (hideMessage: boolean) => {
		if (hideMessage) {
			this.hideOpenPortalMessage = true;
			LocalStorageHelper.getLocalStorage().setItem(this.HideOpenPortalMessageKey, 'true');
		} else {
			this.hideOpenPortalMessage = false;
			LocalStorageHelper.getLocalStorage().removeItem(this.HideOpenPortalMessageKey);
		}
	}

	@action toggleHideBeforeAllocationMessage = (hideMessage: boolean) => {
		if (hideMessage) {
			this.hideBeforeAllocationMessage = true;
			LocalStorageHelper.getLocalStorage().setItem(this.HideBeforeAllocationMessageKey, 'true');
		} else {
			this.hideBeforeAllocationMessage = false;
			LocalStorageHelper.getLocalStorage().removeItem(this.HideBeforeAllocationMessageKey);
		}
	}

	@action
	openDialogOrCheckScanningPortal = () => {
		if(this.hideOpenPortalMessage) {
			ClientFlowHelper.openCheckScanningPortalWindow();
			this.openBeforeAllocationDialog();
		} else {
			ModalDialogCommander.showReactDialog(<BatchOpenPortalDialog store={this} />);
		}
	}

	openBeforeAllocationDialog = () => {
		if(this.hideBeforeAllocationMessage) {
			ModalDialogCommander.forceCloseCurrent();
		} else {
			ModalDialogCommander.showReactDialog(<BatchBeforeAllocationDialog store={this}/>);
		}
	}

	private getInitialMessageKeyValue = (messageKey: string) => {
		const messageKeyStored = LocalStorageHelper.getLocalStorage().getItem(messageKey);
		return messageKeyStored && messageKeyStored === 'true';
	}
}
