import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { TransitionGroup } from 'react-transition-group-v1';
import { FormControlLabelled, FormControlType, IFormControlLabelledTooltipProps } from '../../components/form-controls/form-control-labelled';
import { RenderResponsive } from '../../components/hoc-behavior/render-responsive';
import { responsiveHelper, ResponsiveVisibility, ResponsiveBreakpoint } from '../../helpers/responsive-helper';
import { Debouncer } from '../../utils/debouncer';
import { observer } from 'mobx-react';
import AllocationItemFormStore from './allocation-item-form-store';
import { PaymentCommonFields } from '../../components/payment-common-fields';
import { SvgWrapper } from '../../components/svg-wrapper';
import { AllocationOmnibox } from './allocation-omnibox';
import { FundAllocation } from './fund-allocation';
import { Formatter } from '../../helpers/formatter';
import { KeyCodes } from '../../../Shared/helpers/keycodes';
import { velocity } from '../../helpers/velocity';
import { AllocationCheckDetailsListModel } from './allocation-check-details-list-model';
import { FormControlCheckbox } from '../../components/form-controls/form-control-checkbox/form-control-checkbox';
import { CustomFieldEditModel } from '../../loggedinweb-generated';
import { Button } from '../../components/button';
import { ValidatableForm } from '../../components/form-controls/validatable-form';
import { ModalDialogCommander } from '../../components/modal-dialog-commander';
import { AllocationAddMemberDialog } from './allocation-add-member-dialog';
import { IAddMemberFormProps } from '../../community-member/views/add-member-form-container';
import { AllocationItemImages, AllocationItemImagesControls, AllocationItemImagesControlsSide } from './allocation-item-images';
import { AllocationItemImagesStore } from './allocation-item-images-store';
import { Models } from '../check-deposit-generated';
import CheckImageSource = Models.CheckImageSource;
import { enterTransitionDuration, leaveTransitionDuration } from '../../components/data-grid/data-grid';
import { dataGridRowPanelMobilePosTop, dataGridRowPanelMargin } from '../../components/data-grid/data-grid-row-panel';
import { IPayerViewModel } from '../../components/payer-search-omnibox/payer-search-models';
import { EditMemberDialog } from './edit-member-dialog';
import { AllocationEditMemberDialog } from './allocation-edit-member-dialog';

export interface IAllocationItemFormProps {
	store: AllocationItemFormStore;
	addMemberFormProps: IAddMemberFormProps;
	focusRow: (token: string) => void;
	toggleDocumentScroll: (enableScroll: boolean) => void;
	showLoadingOverlay?: boolean;
	disabled?: boolean;
	listViewModel: AllocationCheckDetailsListModel;
	isProcessingSaveRequest?: boolean;
}

@observer
export class AllocationItemForm extends React.Component<IAllocationItemFormProps, { showLoadingOverlay: boolean }> {
	state = { showLoadingOverlay: false };
	form: HTMLElement;
	panel: HTMLElement;
	private showLoadingOverlayDebounce: Debouncer<boolean>;
	private allocationItemImagesStore = new AllocationItemImagesStore(this.props.store.check, CheckImageSource.AllocationForm);

	UNSAFE_componentWillMount() {
		this.showLoadingOverlayDebounce = new Debouncer(300, this.toggleLoadingOverlay);
		this.toggleLoadingOverlay(this.props.showLoadingOverlay);
	}

	render() {
		const { store, disabled, isProcessingSaveRequest } = this.props;
		const { check, saveCheck, referenceFields, updatePayer, PayButtonLabel, isCheckSplittingEnabled,
				handleAddFund, handleRemoveFund, handleFundChanged, handleAmountChanged, handleAmountBlurred, allAmountsAreValid,
				fundField, checkSplitAmountErrorMessage, formattedTotalAllocated } = store;
		const { RowNumber, WantsTransactionNotifications, RoutingNumber, AccountNumber, CheckNumber, Amount, HasMissingData, Payer, EncodedToken, BatchId, YourId } = check;
		const amountForDisplay = Formatter.formatNumberForDisplay(Amount);
		const splitInfo = isCheckSplittingEnabled ? check.Splits : null;
		const buttonDisabled = disabled || !allAmountsAreValid;
		const onCancelClick = () => this.endCheckEditing(check.EncodedToken);

		return (
			<div className="cd-allocation-item-form" ref={(form) => this.form = form}>
				<ValidatableForm onSubmit={saveCheck}>
					<div className="data-grid-row-panel panel panel-default" onKeyDown={this.onKeyDown} tabIndex={0} ref={(panel) => this.panel = panel}>
						<header className="panel-heading">
							<RenderResponsive visibility={ResponsiveVisibility.Visible} breakpoints={ResponsiveBreakpoint.Xs}>
								<h3 className="cd-allocation-item-form-header-title">Allocate this check</h3>
							</RenderResponsive>
							<div className="cd-allocation-item-form-header-left">
								<div className="cd-missing-data-indicator cd-allocation-item-form-missing-data-indicator">{HasMissingData ? '\u2022' : null}</div>
								<RenderResponsive visibility={ResponsiveVisibility.Hidden} breakpoints={ResponsiveBreakpoint.Xs}>
									<div className="cd-allocation-item-form-row-number">{RowNumber}</div>
								</RenderResponsive>
								<div className="cd-allocation-item-form-check-details">{`${RoutingNumber} \u2013 ${AccountNumber} \u2013 ${CheckNumber}`}</div>
								<RenderResponsive visibility={ResponsiveVisibility.Hidden} breakpoints={ResponsiveBreakpoint.Xs}>
									<AllocationItemImagesControls store={this.allocationItemImagesStore} />
								</RenderResponsive>
							</div>
							<div className="cd-allocation-item-form-header-right">
								<div className="cd-allocation-item-form-amount">${amountForDisplay}</div>
								<button
									type="button"
									className="cd-allocation-item-form-close-btn btn btn-link"
									onClick={onCancelClick}>
									<SvgWrapper className="cd-allocation-item-form-header-close-icon" svg="close-cross" title="Cancel" />
								</button>
							</div>
						</header>
						<div className="panel-body cd-allocation-item-form-header">
							<RenderResponsive visibility={ResponsiveVisibility.Visible} breakpoints={ResponsiveBreakpoint.Xs}>
								<div className="cd-allocation-item-form-images-controls-mobile">
									<TransitionGroup component="div">
										{this.allocationItemImagesStore.showImages && <AllocationItemImagesControlsSide store={this.allocationItemImagesStore} />}
									</TransitionGroup>
									<AllocationItemImagesControls store={this.allocationItemImagesStore} classNames="text-right" />
								</div>
							</RenderResponsive>
							<TransitionGroup component="div" className="cd-allocation-item-form-images-container">
								{this.allocationItemImagesStore.showImages && <AllocationItemImages checkImageUrl={check.CheckImageUrl}
									checkNumber={check.CheckNumber} store={this.allocationItemImagesStore} />}
							</TransitionGroup>
							<AllocationOmnibox
								payer={Payer}
								focusOnMount={!(responsiveHelper.isXs || responsiveHelper.isSm)}
								disabled={disabled}
								onPayerSelected={updatePayer}
								onAddNewCommunityMember={this.onAddNewCommunityMember}
								onEditCommunityMember={this.onEditCommunityMember}
								batchId={BatchId}
								encodedToken={EncodedToken}
								className="cd-allocation-item-form-search"
								payButtonLabel={PayButtonLabel}
								onFocusSearch={this.onFocusSearch}
								enableFeatureOrganizationalGiving={this.props.store.enableFeatureOrganizationalGiving} />
						</div>
						<div className={`panel-body cd-allocation-item-form-body${this.state.showLoadingOverlay ? ' disabled' : ''}`}>
							{isCheckSplittingEnabled ? <FundAllocation
								splitInfo={splitInfo}
								viewModel={fundField}
								allAmountsAreValid={allAmountsAreValid}
								amountErrorMessage={checkSplitAmountErrorMessage}
								formattedTotalAllocated={formattedTotalAllocated}
								onFundChange={handleFundChanged}
								onAmountChange={handleAmountChanged}
								onAmountBlur={handleAmountBlurred}
								onAdd={handleAddFund}
								onDelete={handleRemoveFund} /> : null}
							<PaymentCommonFields
								customFields={referenceFields}
								model={check.CommonPaymentFields}
								onCustomFieldChange={this.updateReferenceFieldValue}
								onNotesChange={this.updateNotes}
								onGivenOnChange={this.updateGivenOn}
								paymentNounLowerCase={PayButtonLabel.NounLowerCase}
								paymentNounSentenceCase={PayButtonLabel.NounSentenceCase}
								yourId={YourId}
								onYourIdChange={this.handleYourIdChange}
								sendEmailNotification={WantsTransactionNotifications}
								onEmailNotificationChange={this.onChangeNotifications}
								showNotes={true}
								showEmailNotification={true} />
						</div>
						<footer className="panel-footer text-center">
							<Button submit={true} className="btn btn-primary" disabled={buttonDisabled} isProcessingRequest={isProcessingSaveRequest}>Save &amp; Next</Button>
							<button
								type="button"
								className="btn btn-ghost"
								onClick={onCancelClick}>Cancel</button>
						</footer>
					</div>
				</ValidatableForm>
			</div>
		);
	}

	componentDidMount() {
		if (responsiveHelper.isXs || responsiveHelper.isSm) {
			return;
		}
		this.scrollWindowToForm();
	}

	componentWillEnter(callback) {
		let panelStyle = { opacity: 0 };
		let velocityProps = { opacity: 1 };

		if (responsiveHelper.isXs || responsiveHelper.isSm) {
			Object.assign(panelStyle, { top: dataGridRowPanelMobilePosTop });
			Object.assign(velocityProps, { top: 0 });
		} else {
			Object.assign(velocityProps, {
				marginTop: dataGridRowPanelMargin,
				marginBottom: dataGridRowPanelMargin,
			});
		}

		Object.assign(this.panel.style, panelStyle);
		velocity(this.panel, velocityProps, {
			duration: enterTransitionDuration,
			complete: callback,
			easing: 'ease-in-out',
		});
	}

	componentDidEnter() {
		if (responsiveHelper.isXs || responsiveHelper.isSm) {
			this.props.toggleDocumentScroll(false);
			this.panel.focus();
		}
	}

	componentWillLeave(callback) {
		if (this.props.listViewModel.ExpandedCheckStore) {
			callback();
		} else {
			if (responsiveHelper.isXs || responsiveHelper.isSm) {
				this.props.toggleDocumentScroll(true);
				this.props.focusRow(this.props.store.check.EncodedToken);
			}

			velocity(this.panel, {
				opacity: 0,
				marginTop: 0,
				marginBottom: 0,
			}, {
				duration: leaveTransitionDuration,
				complete: callback,
				easing: 'ease-in-out',
			});
		}
	}

	componentWillUnmount() {
		velocity(this.panel, 'stop', true);
		velocity(this.form, 'stop', true);
		this.showLoadingOverlayDebounce.cancel();
	}

	UNSAFE_componentWillReceiveProps(nextProps: IAllocationItemFormProps) {
		this.showLoadingOverlayDebounce.exec(nextProps.showLoadingOverlay);
	}

	onFocusSearch = (event: React.FocusEvent<HTMLInputElement>) => {
		if (!responsiveHelper.isXs) {
			return;
		}
		(ReactDOM.findDOMNode(event.currentTarget) as Element).closest('.payer-search-omnibox').scrollIntoView({ behavior: 'smooth' });
	}

	private handleYourIdChange = (event: React.FormEvent<HTMLInputElement>) => {
		if (this.props.disabled) {
			return;
		}
		this.props.store.updateYourId(event.currentTarget.value);
	}

	private onAddNewCommunityMember = (searchTerm: string) => {
		const { handleCommunityMemberAdded } = this.props.store;
		ModalDialogCommander.showReactForm(<AllocationAddMemberDialog
			check={this.props.store.check}
			fullName={searchTerm}
			onCommunityMemberAdded={(member) => handleCommunityMemberAdded(member, searchTerm)}
			{...this.props.addMemberFormProps} />, { elementToFocusSelector: `[name=FirstName]` });
	}

	private onEditCommunityMember = (payer: IPayerViewModel) => {
		const { handleCommunityMemberEdited } = this.props.store;
		ModalDialogCommander.showReactForm(<AllocationEditMemberDialog memberId={+payer.id}
			details={payer.personalDetails}
			onCommunityMemberUpdated={handleCommunityMemberEdited}
			enableFeatureOrganizationalGiving={this.props.store.enableFeatureOrganizationalGiving}
			check={this.props.store.check} />,
			{ elementToFocusSelector: '[name=FirstName]' });
	}

	private scrollWindowToForm = () => {
		velocity(this.form, 'stop', true);
		velocity(this.form, 'scroll', {
			duration: enterTransitionDuration,
			easing: 'ease-in-out',
			offset: -200,
			// delaying slightly makes the scroll position correct when switching checks
			delay: 1,
		});
	}

	private onKeyDown = (event: React.KeyboardEvent<any>) => {
		const { keyCode } = event;

		if (keyCode === KeyCodes.Escape) {
			this.props.listViewModel.endCheckEditing();
		}
	}

	private onChangeNotifications = (value: boolean) => {
		if (this.props.disabled) {
			return;
		}
		this.props.store.changeWantsNotifications(value);
	}

	private updateNotes = (value: string) => {
		if (this.props.disabled) {
			return;
		}
		this.props.store.updateNotes(value);
	}

	private updateGivenOn = (value: Date) => {
		if (this.props.disabled) {
			return;
		}
		this.props.store.updateGivenOn(value);
	}

	private updateReferenceFieldValue = (field: CustomFieldEditModel) => {
		if (this.props.disabled) {
			return;
		}
		this.props.store.updateReferenceFieldValue(field);
	}

	private toggleLoadingOverlay = (value: boolean) => {
		this.setState(s => ({ showLoadingOverlay: value }));
	}

	private endCheckEditing = (encodedToken: string) => {
		const { endCheckEditing, isProcessingSaveRequest } = this.props.listViewModel;

		if (isProcessingSaveRequest) {
			return;
		}
		endCheckEditing(encodedToken);
	}

	private endCheckEditingOld = () => {
		const { endCheckEditing, isProcessingSaveRequest } = this.props.listViewModel;

		if (isProcessingSaveRequest) {
			return;
		}
		endCheckEditing();
	}
}
