import { NodeListHelper } from '../helpers/nodelisthelper';
import { responsiveHelper } from '../helpers/responsive-helper';
import { BrowserSupportHelper } from '../helpers/browsersupporthelper';

/**
 * Tables can be either mobile enabled or not to enable a table for moble add the `mobile-enabled` to table
 * <table class="mobile-enabled">...</table>
 */
export class Tables {

	tables: Table[];

	constructor() {
		this.tables = NodeListHelper.toArray<HTMLTableElement>(document.querySelectorAll('table')).map(t => new Table(t));
	}
}

export class Table {
	private table: HTMLTableElement;
	private isSelectable = false;
	private headerCheckbox: HTMLInputElement = null;

	constructor(table: HTMLTableElement) {
		this.table = table;

		this.isSelectable = BrowserSupportHelper.matchesSelector(this.table, '[data-selectable="true"]');

		if (this.isSelectable) {
			const checkbox = this.table.querySelector('input[data-header-checkbox]');
			if (checkbox instanceof HTMLInputElement) {
				this.headerCheckbox = checkbox;
			}
		}

		this.bind();
	}

	bind() {

		this.getRows().map(tr => {
			tr.addEventListener('click', e => this.onRowClick(tr, e));

			if (this.isSelectable) {
				this.getRowCheckboxes(tr).map(checkbox => {
					checkbox.addEventListener('change', e => this.onRowCheckboxChange(tr, checkbox, e));
				});
			}
		});

		if (this.isSelectable) {
			if (this.headerCheckbox) {
				this.headerCheckbox.addEventListener('change', e => this.onHeaderCheckboxChange(e));
			}
		}
	}

	onRowClick(tr: HTMLTableRowElement, event: Event) {
		const target = event.target as Element;

		if (this.shouldHandleClickEvent(target)) {
			if (this.isMobileEnabled()) {
				this.mobileEnabledClick(tr, event);
			} else {
				this.navigateToRowUrl(tr, event);
				this.rowSelection(tr, event);
			}
		}
	}

	mobileEnabledClick(tr: HTMLTableRowElement, event: Event) {
		// follow link when button is clicked
		if (BrowserSupportHelper.matchesSelector(event.target as Element, 'button.see-detail')) {
			this.navigateToRowUrl(tr, event);
		}

		if (event.defaultPrevented) {
			return;
		}

		// hide all other 'open' rows
		this.getExpandedRows().map(tr2 => {
			if (tr !== tr2) {
				tr2.classList.remove('expanded');
			}
		});

		tr.classList.toggle('expanded');

		event.preventDefault();
	}

	onRowCheckboxChange(tr: HTMLTableRowElement, checkbox: HTMLInputElement, event: Event) {
		this.toggleRowSelection(tr);

		this.updateHeaderCheckboxState();
		this.triggerSelectionChange();
	}

	onHeaderCheckboxChange(event: Event) {

		var isChecked = this.headerCheckbox.checked;
		this.headerCheckbox.removeAttribute('data-indeterminate');
		this.getRows().map(tr => {
			this.toggleRowSelection(tr, isChecked);
		});
		this.triggerSelectionChange();
	}

	getReallocateAll() {
		return this.headerCheckbox.checked;
	}

	getRows() {
		return NodeListHelper.toArray<HTMLTableRowElement>(this.table.querySelectorAll('tbody tr:not(.grid-empty-text)'));
	}

	getSelectedRows() {
		return NodeListHelper.toArray<HTMLTableRowElement>(this.table.querySelectorAll('tbody tr.selected:not(.grid-empty-text)'));
	}

	getExpandedRows() {
		return NodeListHelper.toArray<HTMLTableRowElement>(this.table.querySelectorAll('tbody tr.expanded'));
	}

	getRowCheckboxes(tr: HTMLTableRowElement) {
		return NodeListHelper.toArray<HTMLInputElement>(tr.querySelectorAll('input[data-row-checkbox]'));
	}

	private navigateToRowUrl(tr: HTMLTableRowElement, event: Event) {
		if (event.defaultPrevented) {
			return;
		}

		if (!this.hasNavigableRows()) {
			return;
		}

		const rowUrl = tr.getAttribute('data-row-url');
		if (rowUrl) {
			window.location.href = rowUrl;
			event.preventDefault();
		}
	}

	private toggleRowSelection(tr: HTMLTableRowElement, isChecked?: boolean) {
		isChecked = (isChecked !== null && isChecked !== undefined) ? isChecked : !(tr.classList.contains('selected'));

		if (isChecked) {
			tr.classList.add('selected');
		} else {
			tr.classList.remove('selected');
		}

		this.getRowCheckboxes(tr).map(checkbox => {
			checkbox.checked = isChecked;
		});
	}

	private updateHeaderCheckboxState() {
		if (!this.headerCheckbox) {
			return;
		}
		let anySelected = false;
		let anyUnselected = false;

		for (let tr of this.getRows()) {
			const isSelected = tr.classList.contains('selected');

			anySelected = anySelected || isSelected;
			anyUnselected = anyUnselected || !isSelected;

			//table contains both selected and not selected items, we're done
			if (anySelected && anyUnselected) {
				break;
			}
		}

		//IE does not fire 'Change' event on indeterminate checkbox when you click on it
		//http://stackoverflow.com/questions/33523130/ie-does-not-fire-change-event-on-indeterminate-checkbox-when-you-click-on-it
		//the idea is to use data-indeterminate attr instead of indeterminate property

		if (anySelected && anyUnselected) {
			this.headerCheckbox.setAttribute('data-indeterminate', 'true');
			this.headerCheckbox.checked = false;
		} else if (anySelected) {
			this.headerCheckbox.removeAttribute('data-indeterminate');
			this.headerCheckbox.checked = true;
		} else {
			this.headerCheckbox.removeAttribute('data-indeterminate');
			this.headerCheckbox.checked = false;
		}
	}

	private triggerSelectionChange() {

		let event: CustomEvent;
		if (typeof CustomEvent === 'function') {
			event = new CustomEvent('gridselectionchange', { detail: { table: this } });
		} else {
			event = document.createEvent('CustomEvent');
			event.initCustomEvent('gridselectionchange', true, true, { table: this });
		}

		this.table.dispatchEvent(event);
	}

	private rowSelection(tr: HTMLTableRowElement, event: Event) {
		if (event.defaultPrevented) {
			return;
		}

		if (!this.isSelectable) {
			return;
		}

		this.toggleRowSelection(tr);
		this.updateHeaderCheckboxState();
		this.triggerSelectionChange();

		event.preventDefault();
	}

	private hasNavigableRows() {
		return this.table.classList.contains('row-navigate');
	}

	private isMobileEnabled() {
		return this.table.classList.contains('mobile-enabled') && responsiveHelper.isXs;
	}

	private shouldHandleClickEvent(target: Element): boolean {
		return !BrowserSupportHelper.matchesSelector(target, 'a[href], a[href] *, input, label, label *');
	}
}

