import { MachineConfig } from 'xstate/lib/types';
import { GivingStatementActions } from '../actions/main-actions';

export enum LandingPageStates {
	MAIN = 'Landing page main',
	MAIN_IDLE = 'Landing page main idle',
	GETTING_NEW_COMMUNITY_MEMBER_COUNT = 'Getting new community member count',
	SHOWING_COMMUNITY_MEMBER_DIALOG = 'Showing Community Member dialog',
	CREATING_NEW_CONFIGURATION = 'Creating new configuration',
	STATEMENT_HISTORY = 'Poll for existing configurations',
	STATEMENT_HISTORY_IDLE = 'Poll for configs idle',
	STATEMENT_HISTORY_FETCHING = 'Fetching config list',
}

export enum LandingPageEvents {
	GET_NEW_COMMUNITY_MEMBER_COUNT = 'Get new community member count',
	SHOW_COMMUNITY_MEMBER_DIALOG = 'Show Community Member dialog',
	DISMISS_COMMUNITY_MEMBER_DIALOG = 'Dismiss Community Member dialog',
	CREATE_NEW_CONFIGURATION = 'Create new configuration',
	SHOW_EXISTING_CONFIG_LIST = 'Show existing configuration list',
	FETCH_EXISTING_CONFIG_LIST = 'Fetch existing configuration list',
}

export const LandingPageStateMachine = (): MachineConfig => ({
	parallel: true,
	states: {
		[LandingPageStates.MAIN]: {
			initial: LandingPageStates.MAIN_IDLE,
			states: {
				[LandingPageStates.MAIN_IDLE]: {
					on: {
						[LandingPageEvents.GET_NEW_COMMUNITY_MEMBER_COUNT]: {
							[LandingPageStates.GETTING_NEW_COMMUNITY_MEMBER_COUNT]: {
								actions: [GivingStatementActions.GetNewCommunityMemberCount],
							},
						},
					},
				},
				[LandingPageStates.GETTING_NEW_COMMUNITY_MEMBER_COUNT]: {
					on: {
						[LandingPageEvents.SHOW_COMMUNITY_MEMBER_DIALOG]: LandingPageStates.SHOWING_COMMUNITY_MEMBER_DIALOG,
						[LandingPageEvents.CREATE_NEW_CONFIGURATION]: {
							[LandingPageStates.CREATING_NEW_CONFIGURATION]: {
								actions: [GivingStatementActions.CreateConfiguration],
							},
						},
					}
				},
				[LandingPageStates.SHOWING_COMMUNITY_MEMBER_DIALOG]: {
					on: {
						[LandingPageEvents.CREATE_NEW_CONFIGURATION]: {
							[LandingPageStates.CREATING_NEW_CONFIGURATION]: {
								actions: [GivingStatementActions.CreateConfiguration],
							},
						},
						[LandingPageEvents.DISMISS_COMMUNITY_MEMBER_DIALOG]: LandingPageStates.MAIN_IDLE,
					}
				},
				[LandingPageStates.CREATING_NEW_CONFIGURATION]: {},
			},
		},
		[LandingPageStates.STATEMENT_HISTORY]: {
			initial: LandingPageStates.STATEMENT_HISTORY_IDLE,
			states: {
				[LandingPageStates.STATEMENT_HISTORY_IDLE]: {
					on: {
						[LandingPageEvents.FETCH_EXISTING_CONFIG_LIST]: {
							[LandingPageStates.STATEMENT_HISTORY_FETCHING]: {
								actions: [GivingStatementActions.FetchExistingConfigurationList],
							},
						},
					},
				},
				[LandingPageStates.STATEMENT_HISTORY_FETCHING]: {
					on: {
						[LandingPageEvents.SHOW_EXISTING_CONFIG_LIST]: LandingPageStates.STATEMENT_HISTORY_IDLE
					},
				},
			},
		},
	},
});
