import * as React from 'react';
import { observer } from 'mobx-react';
import { ModalDialog, IModalDialogButton } from '../../components/modal-dialog';
import { Button } from '../../components/button';

@observer
export class BatchHasIncompleteChecksDialog extends React.Component<{ numberOfChecksWithMissingData: number }, { isReloading?: boolean }> {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const {numberOfChecksWithMissingData} = this.props;
		const { isReloading } = this.state;

		const buttons: (IModalDialogButton | JSX.Element)[] = [
			<Button type="submit" key="1" className="btn btn-primary" onClick={this.reload} isProcessingRequest={isReloading}>
				Reload page
			</Button>
		];

		const checksLabel = numberOfChecksWithMissingData > 1 ? `${numberOfChecksWithMissingData} checks have`: `one check has`;

		return (
			<ModalDialog title="Some checks have missing data" buttons={buttons} inProgress={true} showAlert={true} hideCloseButton={true}>
				<p className="modal-text">
					{`You can't complete this allocation because ${checksLabel} missing data. Please reload the page to view these checks.`}
				</p>
			</ModalDialog>
		);
	}

	private reload = () => {
		this.setState({ isReloading: true });
		location.reload(true);
	}
}
