import { AjaxUtils } from '../utils/ajax-utils';

export interface IStoreLoader {
	name: string;
	storeInit: (data: any) => any;
	instance?: any;
}

export class StoreLoaderBase {
	private readonly loaders: IStoreLoader[];

	constructor(loaders: IStoreLoader[]) {
		this.loaders = loaders;
	}

	getStore(storeName: string) {
		const loader = this.getLoader(storeName);

		if(loader.instance) {
			return loader.instance;
		}

		const data = this.getDataForStore(loader);
		const instance = loader.storeInit(data);
		loader.instance = instance;

		return instance;
	}

	private getDataForStore(loader: IStoreLoader) {
		const element = document.querySelector(`[data-pushpay-store='${loader.name}']`);
		if(!element) {
			throw new Error(`Store ${loader.name} has not been initialized`);
		}

		const data = element.textContent;
		if(data) {
			return JSON.parse(data, AjaxUtils.reviveDate);
		}

		return null;
	}

	private getLoader(storeName: string) {
		const loader = this.loaders.find(x => x.name === storeName);

		if(!loader) {
			throw new Error(`Store loader ${storeName} is not defined`);
		}
		return loader;
	}
}
