import * as React from 'react';
import { wrapReactChildEventHandlers } from '../../utils/wrap-reactchild-eventhandlers';

/**
 * Behavior to stop propogation on mouseMove event when the position of the mouse pointer not changed
 * For example, it's can be used by a scrollable drop down menu with keyboard navigation -
 * Keyboard navigation scolls a highlighted item into view, but a mouse move event is still triggered on scroll even though the mouse position hasn't changed
 * @export
 * @class StopMouseMovePropogationWhenSamePosition
 * @extends {React.Component<{}, {}>}
 */
export class StopMouseMovePropogationWhenSamePosition extends React.Component<{}, {}> {
	private previousMousePosition: { clientX, clientY } = null;

	private eventHandlers = wrapReactChildEventHandlers(() => (React.Children.only(this.props.children) as React.ReactElement).props, {
		onMouseMoveCapture: (event: React.MouseEvent<any>) => {
			const {clientX, clientY} = event;
			const sameMouseLocation = this.previousMousePosition && clientX === this.previousMousePosition.clientX && clientY === this.previousMousePosition.clientY;
			if (sameMouseLocation) {
				event.stopPropagation();
				return;
			}

			this.previousMousePosition = { clientX, clientY };
		}
	} as any);

	render() {
		return React.cloneElement(React.Children.only(this.props.children) as React.ReactElement, this.eventHandlers);
	}
}
