import * as React from 'react';
import { createNamespace } from '../../../../Shared/helpers/namespace';
import { FormControlLabelled, FormControlType } from '../../../components/form-controls/form-control-labelled';
import { RadioLayoutType } from '../../../components/form-controls/form-control-radio';
import { VirtualTerminalFormViewModel } from '../form/virtual-terminal-form-view-model';
import { observer } from 'mobx-react';
import { EndType } from '../../virtual-terminal-generated';
import { SmoothHeightTransition } from '../../../components/hoc-behavior/transitions';
import { GivingFrequencySelectorWithLabel } from '../../../components/giving-frequency-selector';
import {
	frequencyCodeReadable,
	testFrequencyCodeReadable,
	frequencyWithAnnualCodeReadable,
	frequencyWithSemiAnnualCodeReadable,
	frequencyWithQuarterlyCodeReadable,
} from '../../constants';

const ns = createNamespace('vt-form-payment-details-recurring');

function getScheduleFrequencyOptions(frequencies: { [f: number]: string }) {
	return Object.keys(frequencies).map((k) => ({
		Label: frequencies[k],
		Value: k,
	}));
}

@observer
export class PaymentEntryRecurring extends React.Component<{ vm: VirtualTerminalFormViewModel, inlinePanel?: boolean }, {}> {
	render() {
		const {
			vm: {
				paymentEntry,
				updateRecurringFrequency,
				updateRecurringStartDate,
				updateRecurringEndDate,
				listingStore,
				annualRecurringGivingEnabled,
				semiAnnualRecurringGivingEnabled,
				quarterlyRecurringGivingEnabled,
			},
			inlinePanel,
		} = this.props;
		const { recurringSchedule } = paymentEntry;
		const endTypeRequiresData =
			recurringSchedule &&
			(recurringSchedule.EndType === EndType.Date || recurringSchedule.EndType === EndType.Occurrences);
		const controlNames = {
			frequency: 'FrequencyCode',
			startDate: 'WhenNextPaymentDue',
			endType: 'EndType',
			endDate: 'EndDate',
			endOccurrences: 'EndOccurrences',
		};
		const defaultRecurringStartDate = listingStore.selectedListingConfiguration.DefaultRecurringStartDate;

		let scheduleFrequencies = [];

		scheduleFrequencies = getScheduleFrequencyOptions(frequencyCodeReadable);

		if (quarterlyRecurringGivingEnabled) {
			scheduleFrequencies = scheduleFrequencies.concat(
				getScheduleFrequencyOptions(frequencyWithQuarterlyCodeReadable)
			);
		}

		if (semiAnnualRecurringGivingEnabled) {
			scheduleFrequencies = scheduleFrequencies.concat(
				getScheduleFrequencyOptions(frequencyWithSemiAnnualCodeReadable)
			);
		}

		if (annualRecurringGivingEnabled) {
			scheduleFrequencies = scheduleFrequencies.concat(
				getScheduleFrequencyOptions(frequencyWithAnnualCodeReadable)
			);
		}

		if (NewFeatures.TesterCommandsEnabled) {
			scheduleFrequencies = getScheduleFrequencyOptions(testFrequencyCodeReadable).concat(scheduleFrequencies);
		}

		const renderRecurringFrequencyButtons = () => {
			return (
				<GivingFrequencySelectorWithLabel
					selectedFrequencyValue={recurringSchedule.FrequencyCode.toString()}
					availableFrequencies={scheduleFrequencies}
					onChange={updateRecurringFrequency}
					acceptanceTestTargetId="Gift Frequency"
					className="col-md-4"
					labelForGivingFrequencySelection="How often?"
				/>
			);
		};
		const renderRecurringEndUi = () => {
			return (
				<GivingFrequencySelectorWithLabel
					selectedFrequencyValue={recurringSchedule.EndType.toString()}
					availableFrequencies={[
						{
							Label: 'Never',
							Value: `${EndType.Never}`,
						},
						{
							Label: 'Specific date',
							Value: `${EndType.Date}`,
						},
						{
							Label: 'Number of occurrences',
							Value: `${EndType.Occurrences}`,
						},
					]}
					onChange={this.onChangeRecurringEndType}
					acceptanceTestTargetId="recurring end type"
					className="col-md-4"
					labelForGivingFrequencySelection="Ending after"
				/>
			);
		};
		return (
			<div className={`${ns()} ${inlinePanel ? "" :  "panel-body panel-expander"}`}>
				<div className="row">{renderRecurringFrequencyButtons()}</div>
				<div className="row">
					<FormControlLabelled
						cssClassNames="col-md-4"
						label="Starting date"
						formControlProps={{
							name: controlNames.startDate,
							formControlType: FormControlType.DatePicker,
							placeholder: 'Choose a date',
							value: recurringSchedule.WhenNextPaymentDue,
							onChangeHandler: updateRecurringStartDate,
							options: {
								minDate: defaultRecurringStartDate,
							},
							validationRules: {
								propertyName: controlNames.startDate,
								required: {
									errorMessage: 'Please select a starting date',
								},
							},
							acceptanceTestTargetId: 'recurring starting date',
						}}
					/>
					{renderRecurringEndUi()}
				</div>
				<SmoothHeightTransition>
					{endTypeRequiresData && (
						<div className="row">
							{recurringSchedule.EndType === EndType.Date && (
								<FormControlLabelled
									cssClassNames="col-md-4"
									label="End date"
									formControlProps={{
										name: controlNames.endDate,
										formControlType: FormControlType.DatePicker,
										placeholder: 'Choose a date',
										value: recurringSchedule.EndDate,
										onChangeHandler: updateRecurringEndDate,
										options: {
											minDate: recurringSchedule.WhenNextPaymentDue || defaultRecurringStartDate,
										},
										validationRules: {
											propertyName: controlNames.endDate,
											required: {
												errorMessage: 'Please select an end date',
											},
										},
										acceptanceTestTargetId: 'recurring end date',
									}}
								/>
							)}
							{recurringSchedule.EndType === EndType.Occurrences && (
								<FormControlLabelled
									cssClassNames={`${ns('end-occurrences')} col-md-4`}
									label="Number of occurrences"
									formControlProps={{
										name: controlNames.endOccurrences,
										placeholder: '1 - 1,000',
										type: 'number',
										formControlType: FormControlType.Number,
										value:
											recurringSchedule.EndOccurrences === null
												? ''
												: `${recurringSchedule.EndOccurrences}`,
										maxLength: 4,
										onChangeHandler: this.onChangeEndOccurrences,
										validationRules: {
											propertyName: controlNames.endOccurrences,
											required: {
												errorMessage: 'Please select the number of occurrences',
											},
											regex: {
												errorMessage: 'Only whole numbers are accepted',
												parameters: {
													pattern: '^\\$?\\d+?$',
												},
											},
											range: {
												errorMessage: 'Please enter a number no greater than 1000',
												parameters: {
													max: 1000,
													min: 1,
												},
											},
										},
										acceptanceTestTargetId: 'recurring end occurrences',
									}}
								/>
							)}
						</div>
					)}
				</SmoothHeightTransition>
			</div>
		);
	}

	onChangeRecurringEndType = (recurringEndType: string) => {
		this.props.vm.updateRecurringEndType(recurringEndType);
	};

	onChangeEndOccurrences = (event: React.FormEvent<HTMLInputElement>) => {
		this.props.vm.updateRecurringEndOccurrences(event.currentTarget.value);
	};
}
