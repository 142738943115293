import * as React from 'react';
import { observer } from 'mobx-react';
import { injectAnalytics, recordEvent, WithAnalytics } from '../../../analytics';
import { PanelHeader, PanelBody } from '../../../components/panel';
import { Fragment } from '../../../../Shared/components/fragment';
import { classNames } from '../../../../Shared/utils/classnames';
import { FormControlLabelled, FormControlType } from '../../../components/form-controls/form-control-labelled';
import { GivingStatementsPeriodType, ModelMetadata, PaymentLabel, GivingUnitType } from '../../giving-statements-generated';
import { FormControlCheckbox } from '../../../components/form-controls/form-control-checkbox/form-control-checkbox';
import * as styles from './styles.less';

export interface EnterStatementDetailsProps {
	name: string;
	email: string;
	phone: string;
	nonCashAvailable?: boolean;
	nonCashIncluded?: boolean;
	einIncluded?: boolean;
	customerAddressIncluded?: boolean;
	disabled?: boolean;
	nonChangeableControlsDisabled?: boolean;
	onNameChange?: (name: string) => void;
	onEmailChange?: (email: string) => void;
	onPhoneChange?: (phone: string) => void;
	onNonCashIncludedChange?: (nonCashIncluded: boolean) => void;
	onEINIncludedChange?: (included: boolean) => void;
	onCustomerAddressIncludedChange?: (included: boolean) => void;
	onPublishTypeChange?: (publishType: string) => void;
	onContributionsThresholdChange?: (amount: number) => void;
	periodType?: GivingStatementsPeriodType;
	paymentLabel: PaymentLabel;
	ein?: string;
	publishType: string;
	customerAddress?: string;
	contributionsThreshold: number;
}

@injectAnalytics
@observer
export class EnterStatementDetails extends React.Component<WithAnalytics & EnterStatementDetailsProps> {
	render() {
		const metadata = ModelMetadata.UpdateRunRequest;
		const {
			name,
			email,
			phone,
			periodType,
			disabled,
			nonChangeableControlsDisabled,
			nonCashAvailable,
			nonCashIncluded,
			einIncluded,
			customerAddressIncluded,
			paymentLabel,
			ein,
			customerAddress,
			publishType,
			contributionsThreshold,
		} = this.props;

		return (
			<Fragment>
				<PanelHeader>Statement details</PanelHeader>
				<PanelBody>
					<div className="row">
						<FormControlLabelled
							label={`${metadata.StatementName.displayName}`}
							cssClassNames="col-sm-8"
							formControlProps={{
								formControlType: FormControlType.Text,
								type: 'text',
								//we don't want StatementName to be counted as State for browser autofill... propertyName is StatementName so we want Input to match pascal case
								name: 'Input' + metadata.StatementName.propertyName,
								placeholder: 'Enter statement name',
								value: name,
								onChangeHandler: this.onNameChangeEvent,
								validationRules: metadata.StatementName.validationRules,
								validateOnBlur: true,
								validateOnChange: false,
								acceptanceTestTargetId: 'statement-name',
								readonly: periodType === GivingStatementsPeriodType.Annual,
								disabled
							}}
						/>
					</div>
					<div className="row">
						<FormControlLabelled
							label={`${metadata.ContactEmail.displayName}`}
							description="This will show on every statement as the contact email address for your organization."
							cssClassNames="col-sm-8"
							formControlProps={{
								formControlType: FormControlType.Text,
								type: 'text',
								name: metadata.ContactEmail.propertyName,
								placeholder: 'Enter email address',
								value: email,
								onChangeHandler: this.onEmailChangeEvent,
								...(NewFeatures.SetupPinpointAnalytics && { onBlurHandler: () => {
									if (this.props.analytics && email) {
										const { feature, subFeature } = this.props.analytics;
										recordEvent({ feature, subFeature, eventTypeLabel: 'addContactEmail' });
									}
								}}),
								validationRules: metadata.ContactEmail.validationRules,
								validateOnBlur: true,
								validateOnChange: false,
								acceptanceTestTargetId: 'statement-email',
								disabled
							}}
						/>
					</div>
					<div className="row">
						<FormControlLabelled
							label={`${metadata.ContactPhoneNumber.displayName}`}
							description="This will show on every statement as the contact phone number for your organization."
							cssClassNames="col-sm-8"
							formControlProps={{
								formControlType: FormControlType.Text,
								type: 'text',
								name: metadata.ContactPhoneNumber.propertyName,
								placeholder: 'Enter phone number',
								value: phone,
								onChangeHandler: this.onPhoneChangeEvent,
								...(NewFeatures.SetupPinpointAnalytics && { onBlurHandler: () => {
									if (this.props.analytics && phone) {
										const { feature, subFeature } = this.props.analytics;
										recordEvent({ feature, subFeature, eventTypeLabel: 'addContactPhone' });
									}
								}}),
								validationRules: metadata.ContactPhoneNumber.validationRules,
								validateOnBlur: true,
								validateOnChange: false,
								acceptanceTestTargetId: 'statement-phone',
								disabled
							}}
						/>
					</div>
					<div className="row">
						<FormControlLabelled
							label={`${metadata.ContributionsThreshold.displayName}`}
							description={`${paymentLabel.PayerPluralSentenceCase} with tax-deductible ${paymentLabel.NounPluralLowerCase} above this amount or non-cash ${paymentLabel.NounPluralLowerCase} only would have a statement generated.`}
							cssClassNames="col-sm-8"
							formControlProps={{
								formControlType: FormControlType.Amount,
								name: metadata.ContributionsThreshold.propertyName,
								placeholder: 'Type amount',
								value: contributionsThreshold,
								onChangeHandler: this.onContributionsThresholdChangeEvent,
								validateOnBlur: true,
								validateOnChange: false,
								acceptanceTestTargetId: 'contributions-threshold',
								disabled: nonChangeableControlsDisabled
							}}
						/>
					</div>
					<div className="row">
						<label className="col-sm-8">Options selected below will be included in your statements</label>
						{
							nonCashAvailable &&
							<FormControlCheckbox
								text={paymentLabel.NonCashPluralSentenceCase}
								value={nonCashIncluded}
								containerClassNames="col-sm-8"
								acceptanceTestTargetId="statement-non-cash"
								onChangeHandler={this.onNonCashIncludedChangeEvent}
								disabled={disabled}
							/>
						}
						{
							ein &&
							<FormControlCheckbox
								text={`Your EIN: ${ein}`}
								value={einIncluded}
								containerClassNames="col-sm-8"
								acceptanceTestTargetId="statement-toggle-ein"
								onChangeHandler={this.onEINIncludedChangeEvent}
								disabled={disabled}
							/>
						}
						{
							customerAddress &&
							<FormControlCheckbox
								text={`Your physical address: ${customerAddress}`}
								value={customerAddressIncluded}
								containerClassNames="col-sm-8"
								acceptanceTestTargetId="statement-toggle-address"
								onChangeHandler={this.onCustomerAddressIncludedChangeEvent}
								disabled={disabled}
							/>
						}
					</div>
					<div className="row">
						<FormControlLabelled
							cssClassNames={classNames(styles.publishType, "col-sm-8")}
							label={metadata.GivingUnitType.displayName}
							formControlProps={{
								radioItemClassName: styles.publishTypeItem,
								formControlType: FormControlType.Radio,
								value: publishType,
								name: metadata.GivingUnitType.propertyName,
								Options: [
									{
										Label: `Individuals - each community member gets a statement of only their ${paymentLabel.NounPluralLowerCase}`,
										Value: GivingUnitType[GivingUnitType.CommunityMember]
									},
									{
										Label: `Household - primary and secondary household members get a statement of all the ${paymentLabel.NounPluralLowerCase} for their family`,
										Value: GivingUnitType[GivingUnitType.Household]
									},
								],
								onChangeHandler: this.onPublishTypeChangeEvent,
								disabled: nonChangeableControlsDisabled,
							}} />
					</div>
				</PanelBody>
			</Fragment>
		);
	}

	onNameChangeEvent = (nameEvent: React.FormEvent<HTMLInputElement>) => {
		this.props.onNameChange(nameEvent.currentTarget.value);
	}

	onEmailChangeEvent = (emailEvent: React.FormEvent<HTMLInputElement>) => {
		this.props.onEmailChange(emailEvent.currentTarget.value);
	}

	onPhoneChangeEvent = (phoneEvent: React.FormEvent<HTMLInputElement>) => {
		this.props.onPhoneChange(phoneEvent.currentTarget.value);
	}

	onNonCashIncludedChangeEvent = (checked: boolean) => {
		this.props.onNonCashIncludedChange(checked);
	}

	onEINIncludedChangeEvent = (checked: boolean) => {
		this.props.onEINIncludedChange(checked);
	}

	onCustomerAddressIncludedChangeEvent = (checked: boolean) => {
		this.props.onCustomerAddressIncludedChange(checked);
	}

	onPublishTypeChangeEvent = (publishType: string) => {
		this.props.onPublishTypeChange(publishType);
	}

	onContributionsThresholdChangeEvent = (amount: number) => {
		this.props.onContributionsThresholdChange(amount);
	}
}
