import * as React from 'react';
import { GiftEntryPaymentStatus, Money } from '../virtual-terminal-generated';
import { DataGridCell } from '../../components/data-grid/data-grid';
import { SvgWrapper } from '../../components/svg-wrapper';
import { Formatter } from '../../helpers/formatter';

interface IHavePaymentStatus {
	PaymentStatus: GiftEntryPaymentStatus;
}

interface IHaveAmountAsMoney {
	AmountAsMoney: Money;
}

interface IHaveEditUrl {
	EditUrl: string;
}

interface IHavePayerDisplayName {
	PayerDisplayName: string;
}

export function formatPaymentIconColumn(data: IHavePaymentStatus, classNames: string): JSX.Element {
	const { PaymentStatus: transactionPaymentStatus } = data;
	const paymentStatus = GiftEntryPaymentStatus[transactionPaymentStatus].toLowerCase();
	let icon: string;
	let textClassName: string = '';

	switch (transactionPaymentStatus) {
		case GiftEntryPaymentStatus.Success:
			icon = 'ind-tick';
			textClassName = 'text-success';
			break;
		case GiftEntryPaymentStatus.Processing:
			icon = 'icon-hourglass';
			break;
		case GiftEntryPaymentStatus.Failed:
			icon = 'ind-cross';
			textClassName = 'text-danger';
			break;
		case GiftEntryPaymentStatus.Active:
			icon = 'icon-recurring';
			textClassName = 'text-success';
			break;
		case GiftEntryPaymentStatus.Paused:
			icon = 'icon-paused';
			break;
		case GiftEntryPaymentStatus.Refund:
		case GiftEntryPaymentStatus.Refunded:
		case GiftEntryPaymentStatus.Returned:
		case GiftEntryPaymentStatus.Return:
		case GiftEntryPaymentStatus.RefundReturn:
		case GiftEntryPaymentStatus.RefundReturned:
			icon = 'icon-refund';
			break;
		default:
			break;
	}
	return (
		<DataGridCell key="PaymentStatus" classNames={`${classNames} ${textClassName}`}>
			{ <SvgWrapper className="icon" svg={icon} title={paymentStatus} /> }<span className="hidden-sm hidden-xs">{paymentStatus}</span>
		</DataGridCell>
	);
}

export function formatNullableAmountColumn(item: {AmountAsMoney: Money | null } & IHavePaymentStatus, classNames: string): JSX.Element {
	if(item.AmountAsMoney === null) {
		return (
			<DataGridCell key="Amount" classNames={classNames}>
				<strong></strong>
			</DataGridCell>
		);
	}
	return formatAmountColumn(item, classNames);
}

export function formatAmountColumn(item: IHaveAmountAsMoney & IHavePaymentStatus, classNames: string): JSX.Element {
	let amount: string = `$${Formatter.formatNumberForDisplay(item.AmountAsMoney.Amount)}`;

	switch (item.PaymentStatus) {
		case GiftEntryPaymentStatus.Refund:
		case GiftEntryPaymentStatus.RefundReturned:
		case GiftEntryPaymentStatus.Return:
			amount = `-${amount}`;
			break;
		case GiftEntryPaymentStatus.Refunded:
		case GiftEntryPaymentStatus.RefundReturn:
		case GiftEntryPaymentStatus.Returned:
			amount = amount;
			break;
		default:
			amount = amount;
			break;
	}

	return (
		<DataGridCell key="Amount" classNames={classNames}>
			<strong>{amount}</strong>
		</DataGridCell>
	);
}

export function formatEditColumn(item: IHaveEditUrl, classNames: string, paymentNounLowerCase): JSX.Element {
	const { EditUrl } = item;
	return (
		<DataGridCell key="EditTransaction" classNames={`${classNames}${EditUrl ? '' : ' data-grid-cell-empty'}`}>
			{EditUrl && (
				<span><SvgWrapper svg="pen" className="vt-payments-grid-action-icon" title={`Edit ${paymentNounLowerCase}`} /> Edit</span>
			)}
		</DataGridCell>
	);
}

export function formatPayerColumn(item: IHavePayerDisplayName, classNames: string): JSX.Element {
	const { PayerDisplayName } = item;
	return (
		<DataGridCell key="PayerDisplayName" classNames={classNames}>
			<strong>{PayerDisplayName}</strong>
		</DataGridCell>
	);
}
