import * as React from 'react';
import { Fragment } from '../../../../../Shared/components/fragment';
import { MappingsSetupProgress } from '../../../qbo-integration-generated';
import { observer } from 'mobx-react';
import * as styles from './styles.less';

export interface QboPanelContainerProps {
	title: string;
	currentStep: MappingsSetupProgress;
	activeOnStep: MappingsSetupProgress;
	editButtonAcceptanceTestTargetId: string;
	isProcessingRequest: boolean;
	setStepAsActive: (step: MappingsSetupProgress) => void;
	cancelAction: () => void;
}

@observer
export class QboPanelContainer extends React.Component<QboPanelContainerProps> {
	render() {
		const { title, currentStep, activeOnStep, isProcessingRequest, cancelAction, children } = this.props;
		const panelIsActive = currentStep === activeOnStep || currentStep === MappingsSetupProgress.Complete;

		return (
			<Fragment>
				<header className={`panel-heading ${styles.qboHeading}`}>
					<h3 className={styles.qboTitle}>{title}</h3>
				</header>
				{
					panelIsActive &&
					<div className="panel-body">
						{children}
					</div>
				}
				{
					panelIsActive && currentStep !== MappingsSetupProgress.Complete &&
					<footer className={`panel-footer panel-footer-btn-group ${styles.panelFooter}`}>
						<button className="btn btn-ghost" onClick={cancelAction} disabled={isProcessingRequest}>Cancel</button>
						<button className="btn btn-default" disabled={isProcessingRequest}>Update</button>
					</footer>
				}
			</Fragment>
		);
	}
}
