import * as React from 'react';
import { DateHelper } from '../helpers/datehelper';
import { Formatter } from '../helpers/formatter';

export const DateLabel = (props: { date: Date }) => {
	const { date } = props;
	if (DateHelper.isToday(date)) {
		return <strong>Today {Formatter.formatDate(date, 'hh:mm a')}</strong>;
	}
	return <span>on <strong>{Formatter.formatDate(date, 'MMM D, YYYY hh:mm a')}</strong></span>;
};
