import React, { FC, useEffect, useRef } from 'react';

import { headerHeight as headerHeightPx } from '@pushpay/shell';
import { add } from '@pushpay/styles';

export const PreconfiguredGivingLinksResultScrollContainer: FC<{ generatedUrl: string }> = ({
	generatedUrl,
	children,
}) => {
	const containerRef = useRef<HTMLDivElement>(null);
	useEffect(
		function scrollIntoView() {
			if (generatedUrl && containerRef.current) {
				const headerHeight = parseInt(add(headerHeightPx, 5)); // additional 5px adds visual balance
				const refY = containerRef.current.getBoundingClientRect().top;
				const offsetY = refY + window.pageYOffset - headerHeight;
				window.scrollTo({
					top: offsetY,
					behavior: 'smooth',
				});
			}
		},
		[generatedUrl]
	);
	return <div ref={containerRef}>{children}</div>;
};

export const GeneratedResultScrollContainer: FC<{ shouldScroll: boolean }> = ({
	shouldScroll,
	children,
}) => {
	const containerRef = useRef<HTMLDivElement>(null);
	useEffect(
		function scrollIntoView() {
			if (shouldScroll && containerRef.current) {
				const headerHeight = parseInt(add(headerHeightPx, 5)); // additional 5px adds visual balance
				const refY = containerRef.current.getBoundingClientRect().top;
				const offsetY = refY + window.pageYOffset - headerHeight;
				window.scrollTo({
					top: offsetY,
					behavior: 'smooth',
				});
			}
		},
		[shouldScroll]
	);
	return <div ref={containerRef}>{children}</div>;
};
