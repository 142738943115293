import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { TransitionGroup } from 'react-transition-group-v1';
import { observer } from 'mobx-react';
import { SvgWrapper } from './svg-wrapper';
import { createNamespace } from '../../Shared/helpers/namespace';
import { velocity } from '../helpers/velocity';

const alertLifetime = 10000;

class AlertController {
	private container: HTMLDivElement;
	private timeoutId: number;

	showSuccess(alertContent: string | React.ReactElement<any>, timeout?: number) {
		this.show(AlertStatus.Success, alertContent, 'success alert', timeout);
	}

	showProcessing(alertContent: string | React.ReactElement<any>, timeout?: number) {
		this.show(AlertStatus.Processing, alertContent, 'processing alert', timeout);
	}

	showWarning(alertContent: string | React.ReactElement<any>, timeout?: number) {
		this.show(AlertStatus.Warning, alertContent, 'warning alert', timeout);
	}

	showDanger(alertContent: string | React.ReactElement<any>, timeout?: number) {
		this.show(AlertStatus.Danger, alertContent, 'danger alert', timeout);
	}

	showValidationErrors(validationErrors: { [validationErrors: string]: string }, title?: string, timeout?: number) {
		this.show(AlertStatus.Danger, <AlertValidationErrors validationErrors={validationErrors} title={title} acceptanceTestTargetId="validation error alert"  />, undefined, timeout);
	}

	hide() {
		if (this.container) {
			clearTimeout(this.timeoutId);
			this.clear();
		}
	}

	private show = (status: AlertStatus, alertContent: string | React.ReactElement<any>, acceptanceTestTargetId?: string, timeout = alertLifetime) => {
		if (this.container) {
			clearTimeout(this.timeoutId);
			this.clear();
		} else {
			this.createContainer();
		}
		ReactDOM.render(
			<AlertContainer status={status} visible={true} onClear={this.clear} acceptanceTestTargetId={acceptanceTestTargetId}>
				{alertContent}
			</AlertContainer>, this.container);
		this.timeoutId = window.setTimeout(this.clear, timeout);
	}

	private clear = () => {
		ReactDOM.render(<AlertContainer visible={false}></AlertContainer>, this.container);
	}

	private createContainer = () => {
		this.container = document.createElement('div');
		this.container.className = 'alert-container affix-wrapper';
		document.body.appendChild(this.container);
	}
}

export const alertController = new AlertController();

interface IAlertProps {
	className?: string
	status?: AlertStatus;
	onClear?: () => void;
	acceptanceTestTargetId?: string;
	children?: React.ReactNode;
}

export enum AlertStatus {
	Success = 0,
	Warning = 1,
	Danger = 2,
	Processing = 3,
}

const ns = createNamespace('alert');

class AlertContainer extends React.Component<IAlertProps & { visible: boolean }, {}> {
	private key = 0;

	render() {
		return(
			<TransitionGroup>
				{this.props.visible && <ControlledAlert key={this.key++} {...this.props} />}
			</TransitionGroup>
		);
	}
}

@observer
export class ControlledAlert extends React.Component<IAlertProps, {}> {
	private alertElement: HTMLSpanElement;
	render() {
		return (
			<Alert {...this.props} ref={(element) => (this.alertElement = element)} className="affix hidden-print" />
		);
	}

	componentWillAppear(callback) {
		this.animateIn(callback);
	}

	componentWillEnter(callback) {
		this.animateIn(callback);
	}

	componentWillLeave(callback) {
		velocity(this.alertElement, 'fadeOut', {
			duration: 250,
			complete: callback,
			easing: 'ease-in',
		});
	}

	private animateIn(callback) {
		velocity(
			this.alertElement,
			{
				scale: [1, 0.9],
				opacity: [1, 0],
			},
			{
				duration: 366,
				complete: callback,
				easing: 'ease-in',
			}
		);
	}
}

export const Alert = React.forwardRef<HTMLSpanElement, IAlertProps>(
	({ acceptanceTestTargetId, children, className = '', onClear, status }, ref) => {
		const statusName = AlertStatus[status].toLowerCase();
		const handleClear = (ev: React.MouseEvent<HTMLAnchorElement>) => {
			ev.preventDefault();
			onClear();
		};

		const iconName = status === AlertStatus.Processing ? 'spinner' : ns(`icon-${statusName}`);

		return (
			<span
				className={`alert alert-highlight ${ns(statusName)} ${className}`}
				ref={ref}
				onClick={onClear ? handleClear : undefined}
				data-pp-at-target={acceptanceTestTargetId}
			>
				<SvgWrapper className="alert-icon" svg={iconName} />
				<div className="alert-content">{children}</div>
				{onClear && (
					<a href="#" className="btn-dismiss">
						<SvgWrapper className="svg" svg="close-cross" />
					</a>
				)}
			</span>
		);
	}
);

@observer
class AlertValidationErrors extends React.Component<{
	title?: string,
	validationErrors: { [s: string]: string },
	acceptanceTestTargetId?: string,
}, {}> {
	render() {
		const { title, validationErrors, acceptanceTestTargetId } = this.props;
		const validationMessages = Object.keys(validationErrors).map(x => validationErrors[x]);

		if (validationMessages.length === 1) {
			return <div data-pp-at-target={acceptanceTestTargetId}>{title && `${title} `}{validationMessages[0]}</div>;
		}
		return (
			<>
				{title}
				<ul className="alert-highlight-validation-list" data-pp-at-target={acceptanceTestTargetId}>
					{validationMessages.map(message => <li>{message}</li>)}
				</ul>
			</>
		);
	}
}
