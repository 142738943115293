import * as React from 'react';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import { SvgWrapper } from '../../../LoggedInWeb/components/svg-wrapper';

export type CheckboxListOptionValue = string | number;

export interface ICheckboxListOptionProps {
	label: string;
	value: CheckboxListOptionValue;
}

export interface ICheckboxListCommonProps {
	formControlId?: string;
	options: ICheckboxListOptionProps[];
	additionalClassNames?: string;
	disabled?: boolean;
	acceptanceTestTargetId: string;
}

export interface ICheckboxListPrimitiveProps<TValue extends CheckboxListOptionValue> {
	selectedValues: TValue[];
	onChange: (value: TValue[]) => void;
	ariaInvalid?: boolean;
}

export type ICheckboxListProps = ICheckboxListCommonProps & ICheckboxListPrimitiveProps<CheckboxListOptionValue>;

@observer
export class CheckboxList extends React.Component<ICheckboxListProps> {
	render() {
		const {
			options,
			selectedValues,
			additionalClassNames,
			disabled,
			ariaInvalid,
			acceptanceTestTargetId,
		} = this.props;
		return (
			<div className={additionalClassNames}>
				{options.map((option) => {
					const { label, value } = option;
					const checked = selectedValues.some(x => x === value);
					return (
						<CheckboxListItem
							key={value}
							label={label}
							value={value}
							checked={checked}
							onChange={this.onChange}
							ariaInvalid={ariaInvalid}
							disabled={disabled}
							acceptanceTestTargetId={acceptanceTestTargetId}
						/>
					);
				})}
			</div>
		);
	}

	private onChange = (ev: React.FormEvent<HTMLInputElement>) => {
		const { selectedValues } = this.props;
		const { checked, value } = ev.currentTarget;

		const selectedValue = this.optionMap[value];
		const newValues = checked
			? [ ...selectedValues, selectedValue ]
			: selectedValues.filter(x => x !== selectedValue);

		this.props.onChange(newValues);
	}

	//Values can be numbers or strings but event values are always strings
	//We need to map the event value back to the original value
	@computed
	private get optionMap(): Record<string, CheckboxListOptionValue> {
		return this.props.options.reduce((acc: Record<string, CheckboxListOptionValue>, option) => {
			const { value } = option;
			acc[value.toString()] = value;
			return acc;
		}, {});
	}
}

@observer
class CheckboxListItem extends React.Component<{
	label: string,
	value: string | number,
	checked: boolean,
	onChange: (ev: React.FormEvent<HTMLInputElement>) => void,
	disabled?: boolean,
	ariaInvalid?: boolean,
	acceptanceTestTargetId: string,
}> {
	render() {
		const {
			label,
			value,
			checked,
			onChange,
			disabled,
			ariaInvalid,
			acceptanceTestTargetId,
		} = this.props;
		return (
			<div className="checkbox">
				<label>
					<input type="checkbox"
						checked={checked}
						name={label}
						value={value}
						onChange={onChange}
						disabled={disabled}
						aria-invalid={ariaInvalid || undefined}
						data-pp-at-target={`${acceptanceTestTargetId} ${value}`}
						/>
					{checked && <SvgWrapper svg="checkbox-ticked" className="svg svg-checkbox-ticked" />}
					<span>{label}</span>
				</label>
			</div>
		);
	}
}
