import * as React from 'react';
import { IPayerViewModel } from './payer-search-models';

export class SelectedPayerInfo extends React.Component<{ selectedPayer: IPayerViewModel }, {}> {
	render() {
		if (!this.props.selectedPayer) {
			return null;
		}

		const {emailAddress, mobileNumber, displayAddress, postCode} = this.props.selectedPayer;

		return(
			<div className="payer-search-info" data-pp-at-target="payer search info">
				{emailAddress ?
					<span>{emailAddress}</span>
					: null}
				{mobileNumber ?
					<span>{mobileNumber}</span>
					: null}
				{displayAddress ?
					<span className="payer-search-info-address">{displayAddress}</span>
					: null}
				{postCode ?
					<span className="payer-search-info-postcode">{`${displayAddress ? '\u00A0' : ''}${postCode}`}</span>
					: null}
				{this.props.children}
			</div>
		);
	}
}
