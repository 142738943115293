import * as React from 'react';
import { observer } from 'mobx-react';
import * as styles from './styles.less';
import { ModelMetadata } from '../../../funds/funds-generated';
import { InputField, SelectField } from '../../../funds/components/form-controls';
import { CcbMembershipTypeModel } from 'integrations/components/ccb-membership-type-configuration-model';
import { classNames } from '../../../../Shared/utils/classnames';

export interface IMembershipTypeMappingConfigurationProps {
	model: CcbMembershipTypeModel;
}

@observer
export class MembershipTypeMappingConfiguration extends React.Component<IMembershipTypeMappingConfigurationProps, {}> {
	render() {
		const { communityMemberTypes, membershipTypeName, mappedCommunityMemberTypeId, membershipTypeId } = this.props.model;
		const { MappedCommunityMemberTypeId, MembershipTypeId } = ModelMetadata.CcbMembershipTypeEditModel;

		const individualTypeValue = communityMemberTypes.filter(x => !x.IsOrganization)[0];
		const organizationTypeValue = communityMemberTypes.filter(x => x.IsOrganization)[0];

		return <li className={classNames(styles.memberTypeMappingListItem, 'list-group-item')}>
			<div>
				<label className={classNames(styles.memberTypeMappingLabel)}>{membershipTypeName}</label>
			</div>
			<div className="form-group">
				<SelectField
					propertyMetadata={MappedCommunityMemberTypeId}
					floatingLabel={'Select Pushpay member type'}
					value={mappedCommunityMemberTypeId  || ''}
					onChange={this.membershipTypeChanged}>
					<option value="">- Select Pushpay member type -</option>
					<option value={individualTypeValue.CommunityMemberTypeId}>{individualTypeValue.CommunityMemberTypeName}</option>
					<option value={organizationTypeValue.CommunityMemberTypeId}>{organizationTypeValue.CommunityMemberTypeName}</option>
				</SelectField>
				<InputField type="hidden" propertyMetadata={MembershipTypeId} value={`${membershipTypeId}`} />
			</div>
		</li>;
	}

	membershipTypeChanged = (event :React.FormEvent<HTMLSelectElement>) => {
		const value = event.currentTarget.value;
		this.props.model.updateMappedCommunityMemberTypeId(value);
	}
}
