import * as React from 'react';
import { getElementId, getUniqueElementId } from './form-control-utils';
import { IFormControlCommonProps } from './form-control-common-props';
import { ValidatableControl } from './validatable-control';
import { classNames } from '../../../Shared/utils/classnames';

export interface IFormControlSelectOptionProps {
	Label: string;
	Value: string;
}

export interface IFormControlSelectOptionGroupProps {
	Label: string;
	Options: IFormControlSelectOptionProps[];
}

export interface IFormControlSelectProps extends IFormControlCommonProps {
	Options: (IFormControlSelectOptionProps | IFormControlSelectOptionGroupProps)[];
	hideEmptyOption?: boolean;
	onChangeHandler: (event: React.FormEvent<HTMLSelectElement>) => void;
	onBlurHandler?: (event: React.FormEvent<HTMLSelectElement>) => void;
	value: string;
	revalidateOnBlur?: boolean;
	wrapperClassName?: string;
}

export const FormControlSelect = (props: IFormControlSelectProps) => {
	const { uniqueSuffix, name, Options, hideEmptyOption, onChangeHandler, onBlurHandler, placeholder, value,
		refCallback, validationRules, disabled, tabIndex, isInvalid, revalidateOnBlur, acceptanceTestTargetId, wrapperClassName } = props;

	return (
		<div className={`select-wrapper${wrapperClassName ? ` ${wrapperClassName}` : ''}`}>
			<ValidatableControl validationRules={validationRules} elementName={name} revalidateOnChange={!revalidateOnBlur} revalidateOnBlur={revalidateOnBlur} isInvalid={isInvalid}>
				<select
					className={classNames('form-control', !value && !disabled ? 'form-control-unselected' : null)}
					id={getElementId(name, uniqueSuffix || getUniqueElementId())}
					name={name}
					onChange={onChangeHandler}
					onBlur={onBlurHandler ? onBlurHandler : null}
					value={value}
					ref={refCallback}
					tabIndex={tabIndex}
					disabled={disabled}
					data-pp-at-target={acceptanceTestTargetId}>
					{!hideEmptyOption && <option value="">{placeholder}</option>}
					{Options.map(renderOption)}
				</select>
			</ValidatableControl>
		</div>
	);
};

export function renderOption(option: IFormControlSelectOptionProps | IFormControlSelectOptionGroupProps, index: number) {
	if (isOptionGroup(option)) {
		return (
			<optgroup key={index} label={option.Label}>
				{option.Options.map(renderOption)}
			</optgroup>
		);
	}
	return <option value={option.Value} key={option.Value}>{option.Label}</option>;
}

function isOptionGroup(option: IFormControlSelectOptionProps | IFormControlSelectOptionGroupProps): option is IFormControlSelectOptionGroupProps {
	return option['Options'] instanceof Array;
}
