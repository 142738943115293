import * as React from 'react';
import { cps, race, call, Effect } from 'redux-saga/effects';
import { BrandingPackageViewModel } from '../branding-settings-generated';
import { IBrandingSettingsSagaContext } from '../branding-settings-saga';
import { BrandingSettingConfirmDiscardDialog } from '../branding-settings-confirm-discard-draft-dialog';
import { ModalDialogCommander } from '../../components/modal-dialog-commander';
import { BrandingPackageState } from '../branding-settings-main-view-model';

export function* requestDiscardConfirmation(context: IBrandingSettingsSagaContext) {
	let discardAction: (error: any, result: any) => void;
	const discardDraftEffect = cps((callback) => discardAction = callback); // convert callback `discardClicked` to generator `discardDraftEffect`

	const dialog = React.createElement(BrandingSettingConfirmDiscardDialog, {
		discardClicked: () => discardAction(null, true),
		vm: context.mainViewModel
	});

	var r = yield race({
		cancel: call(() => ModalDialogCommander.showReactDialog(dialog)),
		discard: discardDraftEffect
	});

	return !!r.discard;
}

export function* discardDraftHandler(context: IBrandingSettingsSagaContext): IterableIterator<Effect> {
	const { mainViewModel } = context;
	const { formViewModel } = mainViewModel;
	const merchantId = context.mainViewModel.model.SelectedListing.ListingId;
	mainViewModel.packageState = BrandingPackageState.DiscardAwaitingConfirmation;
	var discardConfirmed = yield call(requestDiscardConfirmation, context);

	if (discardConfirmed) {
		try {
			mainViewModel.packageState = BrandingPackageState.DiscardInProgress;
			const brandingPackage: BrandingPackageViewModel = yield call(
				context.dataService.discardDraftPackage,
				{ merchantId: Number(merchantId) }
			);

			formViewModel.applyBrandingPackage(brandingPackage);
			mainViewModel.packageState = BrandingPackageState.NoDraft;
			formViewModel.clearValidationErrors();
			ModalDialogCommander.forceCloseCurrent();
		} catch (error) {
			mainViewModel.packageState = BrandingPackageState.ChangesAwaitingPublish;
			// We don't want to close the dialog in a finally block, as reportError() potentially opens a new dialog (finally would close this)
			ModalDialogCommander.forceCloseCurrent();
			context.reportError(error);
		}
	} else {
		mainViewModel.packageState = BrandingPackageState.ChangesAwaitingPublish;
	}

}
