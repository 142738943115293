import * as React from 'react';
import { observer } from 'mobx-react';
import { FormControlLabelled, FormControlType } from '../../../components/form-controls/form-control-labelled';
import { ModelMetadata } from '../../schedule-import-generated';
import { MigrationDateRangeViewModel } from './migration-date-range-view-model';
import * as styles from './styles.less';

export interface IDateRangeProps {
	vm: MigrationDateRangeViewModel;
	startDateTip: string;
	endDateTip: string;
}

@observer
export class MigrationDateRange extends React.Component<IDateRangeProps> {
	render() {
		const {
			vm: {
				startDate,
				endDate,
				minStartDate,
				minEndDate,
				maxEndDate,
				isStartDateDisabled,
				isEndDateDisabled,
				numberOfDaysForMigration,
				updateStartDate,
				updateEndDate,
			},
			startDateTip,
			endDateTip,
		} = this.props;

		const modelMetadata = ModelMetadata.UpdateMigrationRequest;
		return (
			<div className="row">
				<FormControlLabelled label={modelMetadata.StartDate.displayName}
					cssClassNames="col-sm-4"
					tooltipOptions={{
						message: startDateTip,
						placement: 'top',
					}}
					formControlProps={{
						options: {minDate: minStartDate},
						name: modelMetadata.StartDate.propertyName,
						formControlType: FormControlType.DatePicker,
						value: startDate,
						disabled: isStartDateDisabled,
						onChangeHandler: updateStartDate,
						validationRules: modelMetadata.StartDate.validationRules,
					}} />

				<FormControlLabelled label={modelMetadata.EndDate.displayName}
					cssClassNames="col-sm-4"
					tooltipOptions={{
						message: endDateTip,
						placement: 'top',
					}}
					formControlProps={{
						options: {minDate: minEndDate, maxDate: maxEndDate},
						name: modelMetadata.EndDate.propertyName,
						formControlType: FormControlType.DatePicker,
						disabled: isEndDateDisabled,
						value: endDate,
						onChangeHandler: updateEndDate,
						validationRules: modelMetadata.EndDate.validationRules,
					}} />

				{isNaN(numberOfDaysForMigration) === false && <p className={`col-sm-4 ${styles.dateHint}`}>
					<strong>{numberOfDaysForMigration} days</strong> to migrate your schedules
				</p>}
			</div>
		);
	}
}
