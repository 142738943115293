import * as React from 'react';
import { SvgWrapper } from '../../../../Shared/components/svg-wrapper';
import { Panel, PanelType, PanelBody, PanelHeader } from '../../../components/panel';
import { Formatter } from '../../../helpers/formatter';
import * as styles from './sent-summary.less';
import { GivingStatementRunStatus } from '../../../giving-statements/giving-statements-generated';

interface ISentSummaryProps {
	statementsEmailed: number;
	statementsDownloaded: number;
	membersWithoutEmail: number;
	// statementsExcluded: number;
	sentAt: Date;
	className?: string;
	status: GivingStatementRunStatus;
	givingUnitType: string;
}

export const SentSummary: React.StatelessComponent<ISentSummaryProps> = (props) => {
	const {
		statementsEmailed,
		statementsDownloaded,
		membersWithoutEmail,
		// statementsExcluded,
		sentAt,
		className,
		status,
		givingUnitType,
	} = props;

	return (
		<Panel panelType={PanelType.DEFAULT} extraClassNames={className}>
			<PanelHeader>Delivery summary</PanelHeader>
			<PanelBody>
				{status === GivingStatementRunStatus.Issued && <div className={styles.date}>Published on: <strong>{Formatter.formatDate(sentAt, 'MMM D, YYYY')}</strong></div>}
				<div className={styles.splitPanel}>
					<div className={styles.subPanel}>
						<SvgWrapper svg="statements-emailed" />
						<div className={styles.number}>{statementsEmailed}</div>
						<div>statements emailed</div>
					</div>
					<div className={styles.subPanel}>
						<SvgWrapper svg="statements-downloaded" />
						<div className={styles.number}>{statementsDownloaded}</div>
						<div>statements downloaded</div>
						<div><strong>({membersWithoutEmail} {givingUnitType} without email)</strong></div>
					</div>
					{/* This functionality has been removed, hide it for now in case we want to enable it again. */}
					{/*<div className={styles.subPanel}>*/}
					{/*<SvgWrapper svg="statements-excluded" />*/}
					{/*<div className={styles.number}>{statementsExcluded}</div>*/}
					{/*<div>statements excluded</div>*/}
					{/*<div><strong>(contributions under $50.00)</strong></div>*/}
					{/*</div>*/}
				</div>
			</PanelBody>
		</Panel>
	);
};
