import { ActionHandlers } from '../../../Shared/utils/saga-utils';
import { IBrandingSettingsSagaContext } from '../branding-settings-saga';
import { handleClickedSaga } from './clicked';
import { BrandingSettingsFormField } from '../editor-form/branding-settings-form-view-model';
import { formValueChangeHandler } from './form-value-change-handler';
import { listingChangeHandler } from './listing-change-handler';
import { campusChangeHandler } from './campus-change-handler';
import { publishDraftHandler } from './publish-handler';
import { imageSelectedHandler } from './image-selected-handler';
import { ImageKind } from '../branding-settings-generated';
import { discardDraftHandler } from './discard-draft-handler';
import { displayPreviewHandler } from './display-preview-handler';
import { savePackageHandler } from './save-package-handler';

export namespace BrandingSettingsUserAction {
	export class Clicked {
	}

	export class SavePackage {

	}

	export class DisplayPreview {

	}

	export class PublishDraft {

	}

	export class DiscardDraft {

	}

	export class ChangeListing {
		constructor(public listingId: string) {

		}
	}

	export class ChangeCampus {
		constructor(public campusKey: string) {

		}
	}

	export class FormDataChange {
		constructor(public formField: BrandingSettingsFormField, public value: string) {

		}
	}

	export class FormImageFieldChange {
		constructor(public imageKind: ImageKind, public file: File | null) {

		}
	}
}

export type BrandingSettingsUserAction = typeof BrandingSettingsUserAction[keyof typeof BrandingSettingsUserAction]['prototype'];

export function createActionHandlers() {
	const actionHandlers = new ActionHandlers<IBrandingSettingsSagaContext, typeof BrandingSettingsUserAction[keyof typeof BrandingSettingsUserAction]>();

	actionHandlers.add(
		BrandingSettingsUserAction.Clicked,
		{
			async: true,
			handler: (context) => handleClickedSaga(context),
		}
	);
	actionHandlers.add(
		BrandingSettingsUserAction.SavePackage,
		{
			async: false,
			handler: (context) => savePackageHandler(context, true),
		}
	);
	actionHandlers.add(
		BrandingSettingsUserAction.PublishDraft,
		{
			async: true,
			handler: publishDraftHandler,
		}
	);
	actionHandlers.add(
		BrandingSettingsUserAction.FormDataChange,
		{
			async: false,
			handler: formValueChangeHandler,
		}
	);
	actionHandlers.add(
		BrandingSettingsUserAction.ChangeListing,
		{
			async: true,
			handler: listingChangeHandler,
		}
	);
	actionHandlers.add(
		BrandingSettingsUserAction.ChangeCampus,
		{
			async: true,
			handler: campusChangeHandler,
		}
	);
	actionHandlers.add(
		BrandingSettingsUserAction.FormImageFieldChange,
		{
			async: true,
			handler: imageSelectedHandler
		}
	);
	actionHandlers.add(
		BrandingSettingsUserAction.DiscardDraft,
		{
			async: true,
			handler: discardDraftHandler
		}
	);

	actionHandlers.add(
		BrandingSettingsUserAction.DisplayPreview,
		{
			async: true,
			handler: displayPreviewHandler
		}
	);
	return actionHandlers;
}

