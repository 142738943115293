import * as React from 'react';
import { IntegrationStatusLabel } from '../../qbo-integration-generated';

export interface IntegrationStatusLabelsProperties {
	labels: IntegrationStatusLabel[];
	tooltip?: string;
	labelGroupClassName?: string;
	labelClassName?: string;
}

export class IntegrationStatusLabels extends React.Component<IntegrationStatusLabelsProperties, null> {
	render() {
		const { labels, tooltip, labelGroupClassName, labelClassName} = this.props;

		const groupClasses = `${labelGroupClassName} integration-labels`;

		return (
			<span className={groupClasses}>
				{
					labels.map(label => {
						const classNames = `${labelClassName} ${label.CssClass}`;
						return <span className={classNames} key={label.Label} title={tooltip}>{label.Label}</span>;
					})
				}
			</span>
		);
	}
}
