import * as React from 'react';
import { isFunction } from '../../utils/is-function';
import { CheckDepositDataService } from '../check-deposit-data-service';
import {
	PayerSearchContainer,
	PayerSearchInputField,
	SelectedPayerInfo,
	PayerSearchClearButton,
	PayerSearchDropDownMenu,
	PayerSearchValidationMessage,
	IPayerViewModel,
	PayerSearchLabel,
	PayerSearchEditButton
} from '../../components/payer-search-omnibox/payer-search-omnibox';
import { observer, inject } from 'mobx-react';
import { AllocationOmniboxViewModel } from './allocation-omnibox-viewmodel';
import { Models } from '../check-deposit-generated';
import PaymentLabel = Models.PaymentLabel;
import { RenderResponsive } from '../../components/hoc-behavior/render-responsive';
import { ResponsiveVisibility, ResponsiveBreakpoint } from '../../helpers/responsive-helper';

@inject('dataService')
@observer
export class AllocationOmnibox extends React.Component<{
	className?: string;
	dataService?: CheckDepositDataService;
	payer: IPayerViewModel;
	disabled?: boolean;
	focusOnMount?: boolean;
	onPayerSelected?: (payer: IPayerViewModel, searchTerm: string, payerIndex: number) => void;
	onAddNewCommunityMember: (searchTerm: string) => void;
	onEditCommunityMember: (payer: IPayerViewModel) => void;
	batchId: number;
	encodedToken: string;
	payButtonLabel: PaymentLabel;
	onFocusSearch: (event: React.FocusEvent<HTMLInputElement>) => void;
	enableFeatureOrganizationalGiving: boolean;
}, {}> {
	private vm: AllocationOmniboxViewModel;

	UNSAFE_componentWillMount() {
		this.vm = new AllocationOmniboxViewModel(this.props.dataService);
		this.vm.updateFromProps(this.props.payer, this.props.batchId, this.props.encodedToken);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.vm.updateFromProps(nextProps.payer, nextProps.batchId, nextProps.encodedToken);
	}

	componentWillUnmount() {
		this.vm.destroy();
		this.vm = null;
	}

	render() {
		const { omniboxValue, searchValue, currentSearchResult, loadMore, loadMoreInProgress, openRequested, handleMenuStateChange } = this.vm;
		const selectedPayer = this.props.payer;
		const value = selectedPayer ? selectedPayer.name : omniboxValue;
		const {payButtonLabel} = this.props;
		return (
			<PayerSearchContainer className={this.props.className}>
				<PayerSearchLabel payerNoun={payButtonLabel.PayerSentenceCase}>
					{selectedPayer && <RenderResponsive visibility={ResponsiveVisibility.Visible} breakpoints={ResponsiveBreakpoint.Xs}>
							<PayerSearchEditButton onEditHandler={this.handleEditCommunityMember} />
						</RenderResponsive>}
					<PayerSearchInputField
						value={value}
						placeholder={`Type ${payButtonLabel.PayerLowerCase}'s name`}
						focusOnMount={this.props.focusOnMount && !this.props.disabled}
						onChange={this.handleInputValueChange}
						onFocus={this.props.onFocusSearch} />
					<PayerSearchClearButton selectedPayer={selectedPayer} onClick={this.handleClear} />

					<SelectedPayerInfo selectedPayer={selectedPayer}>
						{selectedPayer && <RenderResponsive visibility={ResponsiveVisibility.Hidden} breakpoints={ResponsiveBreakpoint.Xs}>
							<PayerSearchEditButton onEditHandler={this.handleEditCommunityMember} />
						</RenderResponsive>}
					</SelectedPayerInfo>
					<PayerSearchValidationMessage selectedPayer={selectedPayer} payerNoun={payButtonLabel.PayerLowerCase} />
				</PayerSearchLabel>

				<PayerSearchDropDownMenu
					componentId="check-deposit-omnibox"
					searchResult={currentSearchResult}
					searchValue={searchValue}
					onLoadMore={loadMore}
					loadMoreInProgress={loadMoreInProgress}
					onChange={this.handleSelectPayerChange}
					selectedPayer={selectedPayer}
					openRequested={openRequested}
					onMenuStateChange={handleMenuStateChange}
					onAddNewCommunityMember={this.handleAddNewCommunityMember}
					enableFeatureOrganizationalGiving={this.props.enableFeatureOrganizationalGiving} />
			</PayerSearchContainer>
		);
	}

	private handleInputValueChange = (event: React.FormEvent<HTMLInputElement>) => {
		this.valueChanged(event.currentTarget.value);
	}

	private handleSelectPayerChange = (payer: IPayerViewModel) => {
		const { props: {onPayerSelected, disabled}, vm: {searchValue, currentSearchResult} } = this;
		if (disabled) {
			return;
		}

		if (isFunction(onPayerSelected)) {
			let index = -1;

			if (payer && currentSearchResult.payers) {
				index = currentSearchResult.payers.indexOf(payer);
			}

			onPayerSelected(payer, searchValue, index);
		}
	}

	private handleAddNewCommunityMember = () => {
		const { props: {onAddNewCommunityMember, disabled}, vm: {searchValue} } = this;
		if (disabled) {
			return;
		}

		if (isFunction(onAddNewCommunityMember)) {
			onAddNewCommunityMember(searchValue);
		}
	}

	private handleEditCommunityMember = () => {
		const { props: {onEditCommunityMember, disabled, payer} } = this;
		if (disabled) {
			return;
		}

		if (isFunction(onEditCommunityMember)) {
			onEditCommunityMember(payer);
		}
	}

	private handleClear = () => {
		this.valueChanged('');
	}

	private valueChanged(value: string) {
		const { payer, disabled } = this.props;

		if (disabled) {
			return;
		}

		if (payer && payer.name !== value) {
			this.handleSelectPayerChange(null);
		}

		this.vm.handleInputValueChange(value);
	}
}
