import { useState, useMemo } from 'react';

import { OrganizationDetails as CapOrganizationDetails } from '@pushpay/shell/lib/types/components/org-switcher/types';

import { AdministeredOrganization } from '../loggedinweb-generated';

const mapToOrganizationDetails = (item: AdministeredOrganization): CapOrganizationDetails => ({
	key: item.Key,
	name: item.Name,
	logoUrl: item.LogoUrl,
	url: item.Url,
});

export function useMyOrganizations(administeredOrgs: AdministeredOrganization[], currentOrg: AdministeredOrganization) {
	const [organizations, setOrganizations] = useState(() => administeredOrgs.map(mapToOrganizationDetails));
	const currentOrganization = useMemo(() => mapToOrganizationDetails(currentOrg), [currentOrg]);

	return {
		currentOrganization,
		organizations,
		setOrganizations,
	};
}
