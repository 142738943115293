import * as React from 'react';
import { action, observable, computed } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import { GivingStatementsPeriodType } from '../../giving-statements-generated';
import { PeriodTypeSelector } from './period-type-selector';
import { AnnualSelector } from './annual-selector';
import { QuarterSelector } from './quarter-selector';
import { CustomSelector } from './custom-date-selector';
import { isFunction } from '../../../utils/is-function';
import { PanelBody, PanelHeader } from '../../../components/panel';
import { Fragment } from '../../../../Shared/components/fragment';

function maxQuartersForYear(currentDate: Date, year: number): number {
	const currentYear = currentDate.getFullYear();
	const previousYear = currentYear - 1;

	if (year < previousYear) {
		return 4;
	}

	if (year === previousYear) {
		if (currentDate >= new Date(currentYear, 0, 10)) {
			return 4;
		} else {
			return 3;
		}
	}

	if (currentDate >= new Date(currentYear, 9, 11)) {
		return 3;
	}
	if (currentDate >= new Date(currentYear, 6, 11)) {
		return 2;
	}
	if (currentDate >= new Date(currentYear, 3, 11)) {
		return 1;
	}
	return 0;
}

function quartersPerYear(minYear: number, currentDate: Date): [number, number][] {
	const currentYear = currentDate.getFullYear();
	return Array.from({length: (currentYear - minYear) + 1}, (_, y): [number, number] => {
		const year = minYear + y;
		return [year, maxQuartersForYear(currentDate, year)];
	}).filter(([_, maxQuarters]) => maxQuarters > 0);
}

export class PeriodSelectorViewModel {
	currentDate: Date;
	minimumYear: number;
	onPeriodChange: (periodType: GivingStatementsPeriodType, startDate: Date, endDate: Date) => void;

	@observable
	startDate: Date;

	@observable
	endDate: Date;

	@observable
	periodType: GivingStatementsPeriodType;

	@observable
	chosenQuarterYear: number;

	private maxQuartersPerYear: [number, number][];

	constructor(periodType: GivingStatementsPeriodType, currentDate: Date, minimumYear: number, defaultStartDate: Date, defaultEndDate: Date) {
		this.maxQuartersPerYear = quartersPerYear(minimumYear, currentDate);
		this.currentDate = currentDate;
		this.minimumYear = minimumYear;
		this.startDate = defaultStartDate;
		this.endDate = defaultEndDate;
		this.onChangePeriodType(periodType);
	}

	get availableAnnualYears(): number[] {
		const years = this.maxQuartersPerYear.filter(([_,q]) => q === 4).map(([y,_]) => y);
		return years;
	}

	get availableQuarterYears(): number[] {
		const years = this.maxQuartersPerYear.map(([y,_]) => y);
		return years;
	}

	get isAnnualDisabled(): boolean {
		return this.availableAnnualYears.length === 0;
	}

	get isQuarterlyDisabled(): boolean {
		return this.availableQuarterYears.length === 0;
	}

	@computed
	get startQuarter(): number {
		if (!this.startDate || !this.endDate) {
			return undefined;
		}
		if (this.startDate.getFullYear() !== this.endDate.getFullYear()) {
			return undefined;
		}
		const year = this.startDate.getFullYear();
		for (var q = 1; q <= this.maxQuarters; q++) {
			var quarterStart = moment([year]).quarter(q).startOf('quarter').toDate();
			if (this.startDate.getTime() === quarterStart.getTime()) {
				return q;
			}
		}
	}

	@computed
	get endQuarter(): number {
		if (!this.startDate || !this.endDate) {
			return undefined;
		}
		if (this.startDate.getFullYear() !== this.endDate.getFullYear()) {
			return undefined;
		}
		const year = this.endDate.getFullYear();
		for (var q = 1; q <= this.maxQuarters; q++) {
			var quarterEnd = moment([year]).quarter(q).endOf('quarter').startOf('day').toDate();
			if (this.endDate.getTime() === quarterEnd.getTime()) {
				return q;
			}
		}
	}

	@computed
	get year(): number {
		if (!this.startDate || !this.endDate) {
			return undefined;
		}
		if (this.startDate.getFullYear() !== this.endDate.getFullYear()) {
			return undefined;
		}
		const year = this.endDate.getFullYear();
		if (this.startDate.getTime() === new Date(year, 0, 1).getTime() &&
			this.endDate.getTime() === new Date(year, 11, 31).getTime()) {
			return year;
		}
	}

	@computed
	get maxQuarters(): number {
		const chosenYear = this.chosenQuarterYear || this.year;
		if (!chosenYear) {
			return 0;
		}

		for (var i = 0; i < this.maxQuartersPerYear.length; i++) {
			const [year, max] = this.maxQuartersPerYear[i];
			if (chosenYear === year) {
				return max;
			}
		}

		return 0;
	}

	@action.bound
	onQuarterYearChange(year: number) {
		this.chosenQuarterYear = year;
		this.startDate = null;
		this.endDate = null;
	}

	@action.bound
	onQuarterChange(startQuarter: number, endQuarter: number) {
		if (!startQuarter || !endQuarter) {
			this.startDate = null;
			this.endDate = null;
		} else {
			this.startDate = moment([this.chosenQuarterYear]).quarter(startQuarter).startOf('quarter').toDate();
			this.endDate = moment([this.chosenQuarterYear]).quarter(endQuarter).endOf('quarter').startOf('day').toDate();
		}
		if (isFunction(this.onPeriodChange)) {
			this.onPeriodChange(GivingStatementsPeriodType.Quarterly, this.startDate, this.endDate);
		}
	}

	@action.bound
	onAnnualChange(year: number) {
		if (year) {
			this.startDate = new Date(year, 0, 1);
			this.endDate = new Date(year, 11, 31);
		} else {
			this.startDate = null;
			this.endDate = null;
		}
		if (isFunction(this.onPeriodChange)) {
			this.onPeriodChange(GivingStatementsPeriodType.Annual, this.startDate, this.endDate);
		}
	}

	@action.bound
	onCustomChange(start: Date, end: Date) {
		this.startDate = start;
		this.endDate = end;
		if (isFunction(this.onPeriodChange)) {
			this.onPeriodChange(GivingStatementsPeriodType.Custom, this.startDate, this.endDate);
		}
	}

	@action.bound
	onChangePeriodType(periodType: GivingStatementsPeriodType) {
		if (this.isAnnualDisabled && periodType === GivingStatementsPeriodType.Annual) {
			periodType = GivingStatementsPeriodType.Quarterly;
		}
		if (this.isQuarterlyDisabled && periodType === GivingStatementsPeriodType.Quarterly) {
			periodType = GivingStatementsPeriodType.Custom;
		}

		this.periodType = periodType;
		if (this.periodType === GivingStatementsPeriodType.Quarterly) {
			if (this.startDate && this.endDate && this.startDate.getFullYear() === this.endDate.getFullYear()) {
				this.chosenQuarterYear = this.endDate.getFullYear();
			} else {
				var availableQuarterYears = this.availableQuarterYears;
				this.chosenQuarterYear = availableQuarterYears.length > 0 && availableQuarterYears[availableQuarterYears.length - 1];
			}
		}
	}
}

export interface IPeriodSelectorProps {
	minimumYear: number;
	currentDate: Date;
	defaultPeriodType?: GivingStatementsPeriodType;
	defaultStartDate?: Date;
	defaultEndDate?: Date;
	disabled?: boolean;
	onPeriodChange?: (periodType: GivingStatementsPeriodType, startDate: Date, endDate: Date) => void;
}

@observer
export class PeriodSelector extends React.Component<IPeriodSelectorProps, {}> {
	private vm: PeriodSelectorViewModel;

	constructor(props) {
		super(props);
		const { defaultPeriodType, currentDate, minimumYear, defaultStartDate, defaultEndDate, onPeriodChange } = props;
		this.vm = new PeriodSelectorViewModel(defaultPeriodType, currentDate, minimumYear, defaultStartDate, defaultEndDate);
		this.vm.onPeriodChange = onPeriodChange;
	}

	render() {
		const { disabled } = this.props;
		const vm = this.vm;
		const fullYear = vm.currentDate.getFullYear();

		return (
			<Fragment>
				<PanelHeader>Select statement period</PanelHeader>
				<PanelBody>
					<div className="row">
						<PeriodTypeSelector
							periodType={vm.periodType}
							onChangePeriodType={vm.onChangePeriodType}
							perTypeProps={{
								[GivingStatementsPeriodType.Annual]: {
									title: vm.isAnnualDisabled ? `Annual statements for ${fullYear} can be configured from January 10 ${fullYear + 1}` : '',
									disabled: disabled || vm.isAnnualDisabled
								},
								[GivingStatementsPeriodType.Quarterly]: {
									title: vm.isQuarterlyDisabled ? `Quarterly statements for ${fullYear} can be configured after April 10 ${fullYear}` : '',
									disabled: disabled || vm.isQuarterlyDisabled
								},
								[GivingStatementsPeriodType.Custom]: { title: '', disabled },
							}}
							formControlId="periodtype"
							acceptanceTestTargetId="periodtype"
						/>
					</div>
					<div className="row">
						{vm.periodType === GivingStatementsPeriodType.Annual &&
							<AnnualSelector
								name="year"
								chosenYear={vm.year}
								availableAnnualYears={vm.availableAnnualYears}
								onAnnualChange={vm.onAnnualChange}
								ariaLabel="Year"
								acceptanceTestTargetId="year"
								disabled={disabled}
							/>
						}
						{vm.periodType === GivingStatementsPeriodType.Quarterly &&
							<QuarterSelector
								chosenYear={vm.chosenQuarterYear || vm.year}
								startQuarter={vm.startQuarter}
								endQuarter={vm.endQuarter}
								maxQuarters={vm.maxQuarters}
								availableQuarterYears={vm.availableQuarterYears}
								onQuarterChange={vm.onQuarterChange}
								onQuarterYearChange={vm.onQuarterYearChange}
								ariaLabel="Quarter"
								acceptanceTestTargetId="quarter"
								disabled={disabled}
							/>
						}
						{vm.periodType === GivingStatementsPeriodType.Custom &&
							<CustomSelector
								startDate={vm.startDate}
								endDate={vm.endDate}
								currentDate={vm.currentDate}
								minimumYear={vm.minimumYear}
								onCustomChange={vm.onCustomChange}
								ariaLabel="Custom"
								acceptanceTestTargetId="custom"
								disabled={disabled}
							/>
						}
					</div>
				</PanelBody>
			</Fragment>
		);
	}
}
