import { querySelector } from './login-utils';
import { MessageBox } from './message-box';
import { Models } from './login.generated';
import { LoginPromoMaterial } from './login-promo-material';

export class LoginTabs {
	tabs: HTMLElement[];
	loginPromoMaterial: LoginPromoMaterial;

	constructor(private messageBox: MessageBox) {
		this.tabs = querySelector<HTMLElement>('[data-pp-login-tab]');
		const tabChangeButtons = querySelector<HTMLElement>('[data-pp-login-show-tab]');
		tabChangeButtons.forEach(btn => btn.addEventListener('click', (ev) => this.clickListener(ev)));
		this.loginPromoMaterial = new LoginPromoMaterial();
	}

	getFormForTab(tab: Models.LoginView): HTMLFormElement {
		return document.querySelector(`form[data-pp-login-form="${Models.LoginView[tab]}"]`) as HTMLFormElement;
	}

	showTab(t: Models.LoginView) {
		var tabToShow = (t === Models.LoginView.NotSpecified ? Models.LoginView.Signpost : t);
		const selectedTab = Models.LoginView[tabToShow] || Models.LoginView[Models.LoginView.Signpost];

		this.tabs.forEach(tab => {
			const tabName = $(tab).data('ppLoginTab');
			if (tabName === selectedTab) {
				tab.classList.remove('hidden');
				this.resetTab(tab);
			} else {
				tab.classList.add('hidden');
			}
		});

		this.messageBox.clear();
		window.scrollTo(0, 0);
		this.loginPromoMaterial.loadPromoMaterialIfVisible();
	}

	showMobileForm() {
		this.showTab(Models.LoginView.PayerMobile);
	}

	showMobileCodeForm() {
		this.showTab(Models.LoginView.PayerMobileCode);
	}

	showAdminForm() {
		this.showTab(Models.LoginView.AdminEmailAndPassword);
	}

	showEmailAndPassword() {
		this.showTab(Models.LoginView.PayerEmailAndPassword);
	}

	showSignpost() {
		this.showTab(Models.LoginView.Signpost);
	}

	private resetTab(tab: HTMLElement) {
		const markedFields = querySelector<HTMLInputElement>('[data-pp-login-clear-on-tab-change]', tab);
		markedFields.forEach(field => field.value = '');

		const errorLabels = querySelector<HTMLSpanElement>('.field-validation-error span');
		errorLabels.forEach(label => label.innerText = '');
	}

	private clickListener(ev: MouseEvent) {
		const $target = $(ev.currentTarget);
		const showTabValue = <string>$target.data('ppLoginShowTab');
		const tab = Models.LoginView[showTabValue];
		this.showTab(tab);
	}
}
