import * as React from 'react';
import { SubRegion } from '../../loggedinweb-generated';
import { ModelMetadata } from '../community-member-generated';
import { FormControlSelect } from '../../components/form-controls/form-control-select';
import { getUniqueElementId } from '../../components/form-controls/form-control-utils';

interface ICountryStateFieldProps {
	selectedState: string;
	countryStates: SubRegion[];
	handleCountryStateChange: (ev: React.FormEvent<HTMLSelectElement>) => void;
	acceptanceTestTargetId?: string;
}

export const CountryStateField: React.StatelessComponent<ICountryStateFieldProps> = (props) => {
	const { selectedState, countryStates, handleCountryStateChange, acceptanceTestTargetId } = props;
	const stateMetadata = ModelMetadata.EditCommunityMemberRequestModel.Address.modelMetadata.State;
	const stateOptions = countryStates.map(x => ({ Label: x.Name, Key: `${x.Code}`, Value: `${x.Code}` }));

	const selectedValueIsInvalid = selectedState && (!stateOptions.some((state) => state.Value === selectedState));
	const invalidStateRule = {
		nevervalid: { errorMessage: 'Invalid State, please choose an option.' }
	};

	return (
		<div className="col-xs-12 col-sm-9 form-group">
			<FormControlSelect Options={stateOptions}
				name={stateMetadata.propertyName}
				uniqueSuffix={getUniqueElementId()}
				hideEmptyOption={false}
				onChangeHandler={handleCountryStateChange}
				placeholder = "State"
				value={selectedState || ''}
				validationRules={selectedValueIsInvalid && invalidStateRule}
				acceptanceTestTargetId={acceptanceTestTargetId}/>
		</div>
	);
};
