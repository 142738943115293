import { merge, Subject, of, fromEvent, timer, animationFrameScheduler, } from 'rxjs';
import { flatMap, tap, retryWhen, takeWhile, filter, map, take, takeUntil, startWith, throttleTime, share, delay } from 'rxjs/operators';
import { CampaignsLandingAction } from './state-machine/campaigns-landing-event-actions';
import { CampaignCardAction } from '../../components/campaign-card/state-machine/campaign-card-event-actions';
import { PayloadAction, ofType, ValueType, ERROR_IS_HANDLED } from '../../../../Shared/state-machine/rx-state-machine-factory';
import { ICampaignLandingEventCreator } from './state-machine/campaigns-landing-machine-context';
import { getCampaignsPromiseDataService, generateCursor } from '../../campaigns-data-service';
import { CampaignStatus, GetCollectedAmountsResponseType } from '../../campaigns-generated';
import { ICampaignCardEventCreator } from '../../components/campaign-card/state-machine/campaign-card-machine-context';
import { alertController } from '../../../components/alert-controller';
import { CampaignCardSection } from '../../campaign-types';


export const campaignsLandingSubject: Subject<PayloadAction<ValueType<typeof CampaignsLandingAction, typeof CampaignCardAction>>[]> = new Subject();

const campaignsLandingAction$ = campaignsLandingSubject.pipe(flatMap(x => x));

const initializeCampaigns = ofType(campaignsLandingAction$, CampaignsLandingAction.initialize)
	.withCreator<ICampaignLandingEventCreator>()
	.pipe(
		tap(({ payload: { hasCampaigns, pollForCollectedAmounts, collectedAmountsUpdatedSince, savedNotification }, eventCreator }) => {
			if (savedNotification) {
				alertController.showSuccess(savedNotification);
			}

			if (hasCampaigns) {
				eventCreator.startCampaignsManagement();

				if (pollForCollectedAmounts) {
					eventCreator.startPollingCollectedAmounts({ collectedAmountsUpdatedSince });
				}
			} else {
				eventCreator.showWelcome();
			}
		})
	);

initializeCampaigns.subscribe();

const numberOfRecords = 4;

const loadDraftCampaigns$ = ofType(campaignsLandingAction$, CampaignsLandingAction.loadDraftCampaigns)
	.withCreator<ICampaignLandingEventCreator>()
	.pipe(
		flatMap(async ({ payload, eventCreator }) => {
			try {
				return [
					await getCampaignsPromiseDataService().getCampaignsByStatus({
						requestInfo: {
							Statuses: [CampaignStatus.Draft],
							Cursor: payload ? generateCursor(payload.position) : '',
							NumberOfRecords: numberOfRecords
						}
					}),
					CampaignCardSection.Drafts,
				];
			} catch (error) {
				eventCreator.processFailure();
				return ERROR_IS_HANDLED;
			} finally {
				eventCreator.processDraftCampaignsComplete();
			}
		})
	);

const loadActiveCampaigns$ = ofType(campaignsLandingAction$, CampaignsLandingAction.loadActiveOrScheduledCampaigns)
	.withCreator<ICampaignLandingEventCreator>()
	.pipe(
		flatMap(async ({ payload, eventCreator }) => {
			try {
				return [
					await getCampaignsPromiseDataService().getCampaignsByStatus({
						requestInfo: {
							Statuses: [CampaignStatus.Published, CampaignStatus.Scheduled],
							Cursor: payload ? generateCursor(payload.position) : '',
							NumberOfRecords: numberOfRecords
						}
					}),
					CampaignCardSection.Published,

				];
			} catch (error) {
				eventCreator.processFailure();
				return ERROR_IS_HANDLED;
			} finally {
				eventCreator.processActiveOrScheduledCampaignsComplete();
			}
		})
	);

const loadClosedCampaigns$ = ofType(campaignsLandingAction$, CampaignsLandingAction.loadClosedCampaigns)
	.withCreator<ICampaignLandingEventCreator>()
	.pipe(
		flatMap(async ({ payload, eventCreator }) => {
			try {
				return [
					await getCampaignsPromiseDataService().getCampaignsByStatus({
						requestInfo: {
							Statuses: [CampaignStatus.Closed],
							Cursor: payload ? generateCursor(payload.position) : '',
							NumberOfRecords: numberOfRecords
						}
					}),
					CampaignCardSection.Closed,
				];
			} catch (error) {
				eventCreator.processFailure();
				return ERROR_IS_HANDLED;
			} finally {
				eventCreator.processClosedCampaignsComplete();
			}
		})
	);

export const loadCampaigns$ = merge(loadDraftCampaigns$, loadActiveCampaigns$, loadClosedCampaigns$).pipe(filter(x => x !== ERROR_IS_HANDLED), share());

export const activateCampaign$ = ofType(campaignsLandingAction$, CampaignCardAction.activateCampaign)
	.withCreator<ICampaignCardEventCreator>()
	.pipe(
		flatMap(async ({ payload: { draftCard }, eventCreator }) => {
			try {
				const { Campaign } = await getCampaignsPromiseDataService().publishCampaign({ request: { Id: draftCard.Id } });
				eventCreator.processCampaignComplete();
				return [draftCard, Campaign];
			} catch (error) {
				eventCreator.processCampaignFail({
					id: draftCard.Id,
					onRetry: () => eventCreator.activateCampaign({ draftCard })
				});
				return ERROR_IS_HANDLED;
			}
		}),
		filter(x => x !== ERROR_IS_HANDLED),
	);

export const closeCampaign$ = ofType(campaignsLandingAction$, CampaignCardAction.closeCampaign)
	.withCreator<ICampaignCardEventCreator>()
	.pipe(
		flatMap(async ({ payload: { ActiveOrScheduledCard }, eventCreator }) => {
			try {
				const { Campaign } = await getCampaignsPromiseDataService().closeCampaign({ request: { Id: ActiveOrScheduledCard.Id } });
				eventCreator.processCampaignComplete();
				return [ActiveOrScheduledCard, Campaign];
			} catch (error) {
				eventCreator.processCampaignFail({
					id: ActiveOrScheduledCard.Id,
					onRetry: () => eventCreator.confirmCloseCampaign({ ActiveOrScheduledCard })
				});
				return ERROR_IS_HANDLED;
			}
		}),
		filter(x => x !== ERROR_IS_HANDLED),
	);

export const deleteCampaign$ = ofType(campaignsLandingAction$, CampaignCardAction.deleteCampaign)
	.withCreator<ICampaignCardEventCreator>()
	.pipe(
		flatMap(async ({ payload: { draftCard }, eventCreator }) => {
			try {
				await getCampaignsPromiseDataService().deleteCampaign({ request: { Id: draftCard.Id } });
				eventCreator.processCampaignComplete();
				return draftCard;
			} catch (error) {
				eventCreator.processCampaignFail({
					id: draftCard.Id,
					onRetry: () => eventCreator.confirmDeleteCampaign({ draftCard })
				});
				return ERROR_IS_HANDLED;
			}
		}),
		filter(x => x !== ERROR_IS_HANDLED),
	);

const GetCollectedAmountsPolling = 'GetCollectedAmountsPolling';

export const getCollectedAmounts$ = ofType(campaignsLandingAction$, CampaignsLandingAction.getCollectedAmounts)
	.withCreator<ICampaignLandingEventCreator>()
	.pipe(
		flatMap(({ payload: { collectedAmountsUpdatedSince }, eventCreator }) => {
			return of(collectedAmountsUpdatedSince).pipe(
				flatMap(async (updatedSince) => {
					//Todo: handle server side error.
					const response = await getCampaignsPromiseDataService().getCollectedAmounts({ request: { UpdatedSince: updatedSince } });
					if (response.Type === GetCollectedAmountsResponseType.Poll) {
						throw new Error(GetCollectedAmountsPolling);
					}

					eventCreator.completePollingCollectedAmounts();
					return response;
				}),
				retryWhen((errors) => errors.pipe(
					delay(3000),
					takeWhile((error: Error) => error.message === GetCollectedAmountsPolling))
				)
			);
		})
	);


export const handleCardFailure$ = ofType(campaignsLandingAction$, CampaignCardAction.handleFailure)
	.withCreator<ICampaignCardEventCreator>()
	.pipe(
		map(x => x.payload),
		share()
	);

export const startProcessingCard$ = ofType(campaignsLandingAction$, CampaignCardAction.startProcessing)
	.withCreator<ICampaignCardEventCreator>()
	.pipe(map(x => x));

export const finishProcessingCard$ = ofType(campaignsLandingAction$, CampaignCardAction.finishProcessing)
	.withCreator<ICampaignCardEventCreator>()
	.pipe(map(x => x));

const retryBufferInSeconds = 60;

export const retryCountdown$ = timer(0, 1000).pipe(
	map(x => retryBufferInSeconds - x - 1),
	take(retryBufferInSeconds),
);

export const getMousemoveEvent$ = (initial: MouseEvent, timeout: number) => fromEvent<MouseEvent>(document, 'mousemove')
	.pipe(
		takeUntil(timer(timeout)),
		startWith(initial),
		throttleTime(0, animationFrameScheduler)
	);
