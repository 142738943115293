import * as React from 'react';
import { Fragment } from '../../../Shared/components/fragment';

export class ScrollToTop extends React.Component<{ children: React.ReactNode }> {
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		return <Fragment>{ this.props.children }</Fragment>;
	}
}
