import * as React from 'react';
import { SvgWrapper } from '../../../../Shared/components/svg-wrapper';
import { classNames } from '../../../../Shared/utils/classnames';
import { isFunction } from '../../../../Shared/utils/is-function';

import * as styles from './campaigns-call-to-action.less';

export type CampaignCallToActionProps = {
	getHeader: () => React.ReactChild;
	getIntro?: () => React.ReactChild;
	getLinks: () => React.ReactChild;
};

export class CampaignsCallToAction extends React.Component<CampaignCallToActionProps> {
	render() {
		const { getHeader, getIntro, getLinks } = this.props;
		return (
			<div className={styles.wrapper}>
				<div className={classNames('text-center', styles.container)}>
					<div className={styles.logo}>
						<SvgWrapper className={styles.icon} svg="loudspeaker" title="Welcome to Campaigns" />
					</div>
					<h1 className={styles.header}>{getHeader()}</h1>
					<div className={styles.content}>
						{isFunction(getIntro) && <div className={styles.intro}>{getIntro()}</div>}
						{getLinks()}
					</div>
				</div>
			</div>
		);
	}
}
