import * as React from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { BatchListStore, IBatchesBasedOn } from '../batch-list-store';
import { TransitionGroup } from 'react-transition-group-v1';
import { MultiSelectItem } from '../../../loggedinweb-generated';
import { ButtonRefresh, ButtonRefreshLoading } from '../../../components/button-refresh/button-refresh';
import { SvgWrapper } from '../../../components/svg-wrapper';
import { classNames } from '../../../../Shared/utils/classnames';
import { renderOption } from '../../../components/form-controls/form-control-select';
import { responsiveHelper } from '../../../helpers/responsive-helper';
import { Models } from '../../check-deposit-generated';
import SortOrder = Models.SortOrder;
import BatchStatusFilter = Models.BatchStatusFilter;
import { BatchListFilterPanel } from './batch-list-filter-panel';
import { Fragment } from '../../../../Shared/components/fragment';
import { SortBy } from '../../../components/sort-by/sort-by';

import * as styles from './batch-list-header.less';

@observer
export class BatchListHeader extends React.Component<{ store: BatchListStore }, { filtersOpen: boolean }> {
	private sortByOptions = [
		option('Oldest first', SortOrder.CreatedOnAsc),
		option('Newest first', SortOrder.CreatedOnDesc),
		option('Batch name (A - Z)', SortOrder.BatchNameAsc),
		option('Batch name (Z - A)', SortOrder.BatchNameDesc),
		option('Highest amount', SortOrder.TotalAmountDesc),
		option('Lowest amount', SortOrder.TotalAmountAsc)
	];

	constructor(props) {
		super(props);

		this.state = {
			filtersOpen: false,
		};
	}

	render() {
		const { filtersOpen } = this.state;
		const store = this.props.store;
		const {
			batchesBasedOn,
			listings,
			queryModel: { SortBy: sortBy },
			isCurrentlyProcessingQuery,
			refreshBatches,
		} = this.props.store;
		const filtersVisible = (responsiveHelper.isXs || responsiveHelper.isSm) ? filtersOpen : true;

		return (
			<header className={`list-group-heading ${styles.container}`}>
				<TransitionGroup component="div">
					{filtersVisible &&
						<div className={classNames('row', styles.filters, 'panel', 'panel-default', 'panel-expander')}>
							<BatchListFilterPanel store={store}/>
						</div>}
				</TransitionGroup>
				<div className={styles.filterSummaryControlsRow}>
					<p className={`lead ${styles.filterSummary}`}>
						<SelectedFiltersSummary batchesBasedOn={batchesBasedOn} listings={listings} />
					</p>
					<div className={styles.controls}>
						<button
							type="button"
							className={`btn btn-sm btn-link visible-sm visible-xs ${styles.controlItem}`}
							onClick={this.handleToggleFilters}
						>
							<SvgWrapper svg="filter" className="icon icon-xs" title="Refresh batches" />Filter
						</button>
						<div className={styles.controlItem}>
							<SortBy
								selectedValue={sortBy}
								options={this.sortByOptions}
								onChangeHandler={this.handleSort}
							/>
						</div>
						{isCurrentlyProcessingQuery
							? <ButtonRefreshLoading cssClassNames={`btn btn-sm btn-link ${styles.buttonRefresh} ${styles.controlItem}`} />
							: <ButtonRefresh cssClassNames={`btn btn-sm btn-link ${styles.buttonRefresh} ${styles.controlItem}`} onRefreshBatches={refreshBatches} />
						}
					</div>
				</div>
			</header>
		);
	}

	handleSort = (event: React.FormEvent<HTMLSelectElement>) => {
		const sortBy = parseInt(event.currentTarget.value);
		this.props.store.updateSortBy(sortBy);
	}

	handleToggleFilters = () => {
		this.setState({ filtersOpen: !this.state.filtersOpen });
	}
}

const SelectedFiltersSummary: React.StatelessComponent<{
	batchesBasedOn: IBatchesBasedOn,
	listings: MultiSelectItem[],
}> = (props) => {
	const {
		batchesBasedOn: {
			numberOfBatches,
			status,
			selectedListingIds,
			fromDate,
			toDate,
		},
		listings,
	} = props;

	const listingText = getListingText(listings, selectedListingIds);
	const statusText = getStatusText(status);
	const dateRangeText = getDateRangeText(fromDate, toDate);

	const completed = status === BatchStatusFilter.Closed ? ' completed' : '';
	const batchCount = numberOfBatches === 1
		? <span>is <strong>1 batch</strong></span>
		: <span>are <strong>{numberOfBatches}{completed} batches</strong></span>;

	return (
		<Fragment>
			There {batchCount} based on {listingText}{statusText}{dateRangeText}
		</Fragment>
	);
};

function getListingText(listings: MultiSelectItem[], selectedListingIds: number[]): string | JSX.Element[] {
	const { length: numListings } = selectedListingIds;

	if (numListings === 0 || numListings === listings.length) {
		return '\'all listings\'';
	}

	const listingNames: string[] = selectedListingIds.map((selectedListing) => {
		const [listing] = listings.filter((listing: MultiSelectItem) => Number(listing.Value) === selectedListing);
		return listing.Text;
	});
	const listingText = listingNames.map((name: string, index: number) => {
		let prefix: string;
		if (index === 0) {
			prefix = '';
		} else if (index + 1 === listingNames.length) {
			prefix = ' and ';
		} else {
			prefix = ', ';
		}
		return <span key={index}>{prefix}'{name}'</span>;
	});

	return listingText;
}

function getStatusText(status: BatchStatusFilter): string {
	if (status === BatchStatusFilter.Closed) {
		return '';
	}

	let statusText: string;
	switch (status) {
		case BatchStatusFilter.Pending:
			statusText = 'Open';
			break;
		case BatchStatusFilter.Active:
			statusText = 'Ready to allocate';
			break;
		case BatchStatusFilter.PendingOrActive:
			statusText = 'Open or Ready to allocate';
			break;
		default:
			const never: never = status;
	}
	return ` that are \'${statusText}\'`;
}

function getDateRangeText(fromDate: Date, toDate: Date) {
	let dateRangeText = '';
	if (fromDate) {
		dateRangeText += ` from '${moment(fromDate).format('MMM DD, YYYY')}'`;
	}
	if (toDate) {
		dateRangeText += ` until '${moment(toDate).format('MMM DD, YYYY')}'`;
	}
	return dateRangeText;
}

const option = (label: string, value: SortOrder) => ({ Label: label, Key: `${value}`, Value: `${value}` });
