import { PaymentLabel, ScheduleImportMigrationEmailContentType } from '../../../schedule-import-generated';
import { Formatter } from '../../../../helpers/formatter';


export class WeeklyEmailTemplate {
	static getDefaultTextTemplate(
		orgName: string,
		endDate: Date,
		paymentLabel: PaymentLabel,
		contentType: ScheduleImportMigrationEmailContentType,
		contactName: string,
		contactNumber: string
	): string {
		const endDateForDisplay = Formatter.formatDate(endDate, 'MMM D, YYYY');

		return (
/* tslint:disable:max-line-length */
`Thank you for your consistent generosity to ${orgName} through your recurring ${paymentLabel.NounLowerCase}. We have begun the process of moving to a new online giving experience called Pushpay, which will replace [old giving platform] and wanted to send a friendly reminder that we still need your help.

It is our goal to get all recurring giving switched over by ${endDateForDisplay}, so we need to get your ${paymentLabel.NounPluralLowerCase} transitioned to the new platform.

Here's how:

${this.getContentSpecificText(endDateForDisplay, contentType, paymentLabel)}

As always, if you have any questions, please reply to this email or contact ${contactName} on ${contactNumber}. We are so blessed that you have made the commitment to ${paymentLabel.VerbLowerCase} in this way, and are honored that you have decided to help us continue to expand God's family.

Blessings,
The Team at ${orgName}`
/* tslint:enable:max-line-length */
		);
	}

	private static getContentSpecificText(endDateForDisplay: string, contentType: ScheduleImportMigrationEmailContentType, paymentLabel: PaymentLabel): string {
		/* tslint:disable:max-line-length */
		switch (contentType) {
			case ScheduleImportMigrationEmailContentType.OldSystemSwitchedOff:
				return(
`Visit and set up your ${paymentLabel.NounLowerCase} using Pushpay. The recurring ${paymentLabel.NounLowerCase} option should already be selected, simply check your ${paymentLabel.NounLowerCase} details and choose a payment option. As you set up your recurring ${paymentLabel.NounLowerCase} you will complete the creation of your new account.

Remember we'll be switching off [old giving platform] on ${endDateForDisplay}, any ${paymentLabel.NounPluralLowerCase} that have not been moved by this time will be stopped.`
				);
			case ScheduleImportMigrationEmailContentType.OldScheduleStoppedByChurch:
				return(
`Visit and set up your ${paymentLabel.NounLowerCase} using Pushpay. The recurring ${paymentLabel.NounLowerCase} option should already be selected, simply check your ${paymentLabel.NounLowerCase} details and choose a payment option. As you set up your recurring ${paymentLabel.NounLowerCase} you will complete the creation of your new account.

Once we see your new ${paymentLabel.NounLowerCase} on Pushpay we'll stop your old ${paymentLabel.NounLowerCase} on [old giving platform].`
				);
			case ScheduleImportMigrationEmailContentType.OldScheduleCancelledByUser:
				return(
`Part One: Setting up a new recurring ${paymentLabel.NounLowerCase} using Pushpay

The recurring ${paymentLabel.NounLowerCase} option should already be selected, simply check your ${paymentLabel.NounLowerCase} details and choose a payment option. As you set up your recurring ${paymentLabel.NounLowerCase} you will complete the creation of your new account.

Part Two: Cancel scheduled ${paymentLabel.NounPluralLowerCase} in [old giving platform]

[Insert Cancellation Steps Here] or if you would like us to cancel your recurring ${paymentLabel.NounLowerCase} on our old platform please get in touch with us.`
				);
		}
		/* tslint:enable:max-line-length */
	}
}
