import * as React from 'react';
import { observer } from 'mobx-react';
import { IFormControlCheckboxProps, FormControlCheckboxToolip } from '../form-control-checkbox/form-control-checkbox';
import { classNames } from '../../../../Shared/utils/classnames';

import * as styles from './styles.less';

@observer
export class FormControlToggle extends React.Component<IFormControlCheckboxProps> {
	render() {
		const {
			value,
			text,
			containerClassNames,
			disabled,
			acceptanceTestTargetId,
			labelClassNames,
			tooltipOptions
		} = this.props;

		return (
			<div className={classNames(
				containerClassNames ? containerClassNames : null,
				{ disabled }
			)}>
				<label
					className={classNames(labelClassNames)}
					data-pp-at-target={acceptanceTestTargetId ? `${acceptanceTestTargetId}_label` : undefined}
				>
					<input type="checkbox" className={styles.checkbox} checked={value} disabled={disabled} onChange={this.onChangeHandler} data-pp-at-target={acceptanceTestTargetId} />
					<span className={styles.toggle} />
					{text}
					{tooltipOptions && <FormControlCheckboxToolip {...tooltipOptions} />}
				</label>
			</div>
		);
	}

	private onChangeHandler = (ev: React.FormEvent<HTMLInputElement>) => {
		this.props.onChangeHandler(ev.currentTarget.checked);
	}
}
