import * as React from 'react';
import { observer } from 'mobx-react';
import { Fragment } from '../../../../Shared/components/fragment';
import { classNames } from '../../../../Shared/utils/classnames';
import { GivingStatementRunStatus } from '../../giving-statements-generated';
import * as styles from './statement-history.less';

export interface IStatementHistory {
	runId: string;
	name: string;
	status: GivingStatementRunStatus;
	count: number;
}

interface IStatementHistoryProps {
	items: IStatementHistory[];
	onItemClick?(runId: string): void;
}

@observer
export class StatementHistory extends React.Component<IStatementHistoryProps> {
	render() {
		const { items, onItemClick } = this.props;

		return (
			<Fragment>
				<h2 className={classNames('page-header', styles.header)}>Statement history</h2>
				<ul className="list-group">
					{
						items.map(item => (
							<li className="list-group-item" key={item.runId}>
								<StatementHistoryItem item={item} onItemClick={onItemClick} />
							</li>
						))
					}
				</ul>
			</Fragment>
		);
	}
}

interface IStatementHistoryItemProps {
	item: IStatementHistory;
	onItemClick?(runId: string): void;
}

@observer
class StatementHistoryItem extends React.Component<IStatementHistoryItemProps> {
	render() {
		const { item: { name, status, count } } = this.props;
		const statusName = GivingStatementRunStatus[status];
		var statusLabel = GivingStatementRunStatus[status];

		if (status === GivingStatementRunStatus.Issued) {
			statusLabel = 'Published';
		} else if (status === GivingStatementRunStatus.Issuing) {
			statusLabel = 'Publishing';
		}

		let showStatementsGeneratedCount: boolean = false;
		if (status === GivingStatementRunStatus.Generating || status === GivingStatementRunStatus.Generated
			|| status === GivingStatementRunStatus.Issuing || status === GivingStatementRunStatus.Issued) {
			showStatementsGeneratedCount = true;
		}

		return (
			<div className={styles.itemContainer} onClick={this.itemClickedHandler}>
				<div className={styles.name}>{name}</div>
				<div className={styles.status}>
					<span className={classNames('label', styles[statusName.toLowerCase()])}>{statusLabel}</span>
				</div>
				{showStatementsGeneratedCount &&
				<div className={styles.countContainer}>
					<span className={styles.count}>{count}</span>
					<span className={styles.countText}>statements generated</span>
				</div>
				}
			</div>
		);
	}

	itemClickedHandler = () => {
		const { item: { runId }, onItemClick } = this.props;
		if (onItemClick ) {
			onItemClick(runId);
		}
	}
}
