import * as React from 'react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { createNamespace } from '../../../../Shared/helpers/namespace';
import { Formatter } from '../../../helpers/formatter';
import { velocity } from '../../../helpers/velocity';
import { SvgWrapper } from '../../../../Shared/components/svg-wrapper';
import { classNames } from '../../../../Shared/utils/classnames';
import { PledgeGridDeleteConfirmation } from './pledges-grid';
import { PledgeLabel, PaymentLabel, PledgeRowWithTotalsViewModel } from '../../campaigns-generated';
import { deletePledgeContextKey, DeletePledgeContext } from './with-delete-pledge';

import * as styles from './pledge-details.less';

const ns = createNamespace('recent-pledges-grid-details');

@inject(deletePledgeContextKey)
@observer
export class RecentPledgeDetailWithTotals extends React.Component<{
	pledgeDetail: PledgeRowWithTotalsViewModel,
	showCampaignName?: boolean,
	updateExpandedRow: (expandedRowKey: string) => void,
	deletePledgeContext?: DeletePledgeContext,
	pledgeLabel: PledgeLabel;
	paymentLabel: PaymentLabel;
}> {
	private content: HTMLElement;

	render() {
		const { pledgeDetail, showCampaignName, pledgeLabel, paymentLabel } = this.props;
		const {
			CampaignName,
			PledgerName,
			EmailAddress,
			PushpayerAccountUrl,
			TotalAmount,
			CreatedOn,
			TotalGiven,
			Progress,
			Remaining,
		} = pledgeDetail;

		return (
			<div>
				<div className={styles.backButton} onClick={this.closePanel}>
					<SvgWrapper svg="icon-chevron-left" className={styles.backButtonSvg} />Go Back
				</div>
				<div className={styles.title}>Pledge</div>
				<div>
					<div className={styles.item}>
						<label id={ns('payer-pledger-name')} className={styles.itemLabel}>Pledger</label>
						<a href={PushpayerAccountUrl} aria-labelledby={ns('payer-pledger-name')}>{PledgerName}</a>
					</div>
					<div className={styles.item}>
						<label id={ns('email')} className={styles.itemLabel}>Email</label>
						<span aria-labelledby={ns('email')}>{EmailAddress}</span>
					</div>
					{
						showCampaignName &&
						<div className={styles.item}>
							<label id={ns('campaign')} className={styles.itemLabel}>Campaign name</label>
							<span aria-labelledby={ns('date')}>{CampaignName}</span>
						</div>
					}
					<div className={styles.item}>
						<label id={ns('date')} className={styles.itemLabel}>{NewFeatures.DonorPledgeEntry_ConfigurablePledgeGrammar ? `Date ${pledgeLabel.VerbPastTenseLowerCase}` : 'Date pledged'}</label>
						<span aria-labelledby={ns('date')}>{CreatedOn}</span>
					</div>
					<div className={styles.item}>
						<label id={ns('amount')} className={styles.itemLabel}>{NewFeatures.DonorPledgeEntry_ConfigurablePledgeGrammar ? `Total ${pledgeLabel.VerbPastTenseLowerCase}` : 'Total pledged'}</label>
						<span aria-labelledby={ns('amount')}>
							${Formatter.formatNumberForDisplay(TotalAmount)}
						</span>
					</div>
					<div className={styles.item}>
						<label id={ns('totalGiven')} className={styles.itemLabel}>{`Total ${paymentLabel.VerbPastTenseLowerCase}`}</label>
						<span aria-labelledby={ns('totalGiven')}>
							${Formatter.formatNumberForDisplay(TotalGiven)}
						</span>
					</div>
					<div className={styles.item}>
						<label id={ns('progress')} className={styles.itemLabel}>Progress</label>
						<span aria-labelledby={ns('progress')}>
						{Progress}
						</span>
					</div>
					<div className={styles.item}>
						<label id={ns('remaining')} className={styles.itemLabel}>Remaining</label>
						<span aria-labelledby={ns('remaining')}>
						{`$${Formatter.formatNumberForDisplay(Remaining)}`}
						</span>
					</div>
				</div>
				<div className={styles.deleteButtonWrapper}>
					<span className={classNames('btn', 'btn-ghost', styles.deleteButton)} onClick={this.onDelete}>
						{NewFeatures.DonorPledgeEntry_ConfigurablePledgeGrammar ? `Delete ${pledgeLabel.NounSentenceCase}` : 'Delete Pledge'}
					</span>
				</div>
				{
					this.deleting && <PledgeGridDeleteConfirmation pledge={pledgeDetail} pledgeLabel={pledgeLabel} />
				}
			</div>
		);
	}

	@computed
	private get deleting() {
		const { pledgeDetail, deletePledgeContext } = this.props;
		return deletePledgeContext.isDeletingOrProcessingDelete(pledgeDetail.Id);
	}

	componentWillEnter(callback) {
		this.content.style.opacity = '0';
		velocity(this.content, 'fadeIn', {
			duration: 250,
			easing: 'ease-in',
			complete: callback
		});
	}

	private closePanel = () => {
		this.props.updateExpandedRow(null);
	}

	private onDelete = () => {
		this.props.deletePledgeContext.handleDelete(this.props.pledgeDetail.Id);
	}
}
