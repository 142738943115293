import * as React from 'react';
import {CarouselSlide} from './carousel-slide';
import {responsiveHelper} from '../helpers/responsive-helper';
import { classNames as cx } from '../../Shared/utils/classnames';
import {observer} from 'mobx-react';

export enum SlideState {
	fadeIn,
	visible,
	fadeOut
}

export interface CarouselProps {
	slideDelay: number;
	disableAnimation?: boolean;
	disableSlidesRotation?: boolean;
}

@observer
export class Carousel extends React.Component<CarouselProps, {currentSlide: number, slideState: SlideState}> {

	private animationDuration = 500;
	private slideCount: number;
	private changeTimer: number;
	private rotateSlides: boolean = true;
	private normalizedSlides: React.ReactChild[];

	constructor(props) {
		super(props);
		this.state = {
			currentSlide: 0,
			slideState: SlideState.visible
		};
		this.normalizedSlides = this.normalizeSlides(React.Children.toArray(this.props.children) as React.ReactChild[]);
		this.slideCount = this.normalizedSlides.length;
	}

	componentDidMount() {
		this.fadeIn();
	}

	render() {
		// disable auto-advance in mobile
		if (((responsiveHelper.isSm || responsiveHelper.isXs) && this.rotateSlides) || this.slideCount <= 1) {
			this.rotateSlides = false;

			clearTimeout(this.changeTimer);
		}

		const { slideState } = this.state;
		const slideControlClass = `slide-control ${this.slideCount > 1 ? `` : `slide-control-hidden`}`;

		return (
			<div className="carousel">
				<div className={cx('slides', {
					visible: slideState === SlideState.visible
				})}>
					<a
						href="#"
						className={slideControlClass}
						onClick={this.showPreviousSlide}
					>
						<span className="arrow previous-arrow" />
					</a>
					{this.normalizedSlides[this.state.currentSlide]}
					<a
						href="#"
						className={slideControlClass}
						onClick={this.showNextSlide}
					>
						<span className="arrow next-arrow" />
					</a>
				</div>
				{this.slideCount > 1 &&
					<div className="slide-indicators">
						{this.renderIndicators()}
					</div>
				}
			</div>
		);
	}

	private normalizeSlides(carouselChildren: React.ReactChild[]): React.ReactElement<CarouselSlide>[] {
		const normalizedChildren = carouselChildren.map(item => {
			if ((item as React.ReactElement<any>).type === CarouselSlide) {
				return item as React.ReactElement<CarouselSlide>;
			} else {
				return this.wrapChildInSlide(item);
			}
		});

		return normalizedChildren;
	}

	private wrapChildInSlide(child: React.ReactChild): React.ReactElement<CarouselSlide> {
		return (
			<CarouselSlide key="wrappedChild">
				{child}
			</CarouselSlide>
		);
	}


	private renderIndicators = () => {
		const { currentSlide } = this.state;
		return this.normalizedSlides.map((_, index) => (
			<a
				key={index}
				href="#"
				className={cx('slide-indicator', {
					'current-slide': index === currentSlide
				})}
				onClick={(e) => this.clickIndicatorHandler(e, index)} />
		));
	}

	private changeSlide = (slideToShow: number) => {
		if (this.props.disableAnimation) {
			this.setState({currentSlide: slideToShow, slideState: SlideState.visible});
		} else {
			this.changeTimer = window.setTimeout(this.fadeIn, 1);
			this.setState({currentSlide: slideToShow, slideState: SlideState.fadeIn});
		}
	}

	private fadeIn = () => {
		this.setState({slideState: SlideState.visible});
		if (this.rotateSlides && !this.props.disableSlidesRotation) {
			let timeToWait = this.props.slideDelay - this.animationDuration;
			let nextSlideNum = this.calculateNextSlide();
			this.changeTimer = window.setTimeout(() => this.fadeOut(nextSlideNum), timeToWait);
		}
	}

	private fadeOut = (slideToShow: number) => {
		this.setState({slideState: SlideState.fadeOut});
		this.changeTimer = window.setTimeout(() => this.changeSlide(slideToShow), this.animationDuration);
	}

	private clickIndicatorHandler(e: React.MouseEvent<HTMLAnchorElement>, slideToShow: number) {
		e.preventDefault();
		clearTimeout(this.changeTimer);
		this.rotateSlides = false;
		if (this.props.disableAnimation) {
			this.changeSlide(slideToShow);
		} else {
			this.fadeOut(slideToShow);
		}
	}

	private showNextSlide = (e: React.MouseEvent<HTMLAnchorElement>) => {
		clearTimeout(this.changeTimer);
		e.preventDefault();
		this.rotateSlides = false;
		this.fadeOut(this.calculateNextSlide());
	}

	private showPreviousSlide = (e: React.MouseEvent<HTMLAnchorElement>) => {
		clearTimeout(this.changeTimer);
		e.preventDefault();
		this.rotateSlides = false;
		let newSlideNum = this.state.currentSlide - 1;
		if (newSlideNum < 0) {
			newSlideNum = this.slideCount - 1;
		}
		this.fadeOut(newSlideNum);
	}

	private calculateNextSlide(): number {
		const nextSlideNum = this.state.currentSlide + 1;

		return nextSlideNum < this.slideCount ? nextSlideNum : 0;
	}
}
