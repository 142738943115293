import * as React from 'react';
import { CampaignDetailsMainViewModel } from './campaign-details-main-view-model';
import { CampaignDetailsIntro } from '../../components/campaign-details-intro/campaign-details-intro';
import { CampaignDetailsTabs } from '../../components/campaign-details-tabs/campaign-details-tabs';
import { StateMachine } from '../../../../Shared/state-machine/saga-state-machine';
import { observer } from 'mobx-react';
import { Match, MatchNot } from '../../../../Shared/state-machine/match';
import { States, Events } from './campaign-details-states-events-and-actions';
import { CampaignsErrorModal } from '../../components/campaigns-modals/campaigns-error-modal';

export interface ICampaignDetailsMainProps {
	store: CampaignDetailsMainViewModel;
}

@observer
export class CampaignDetailsMain extends React.Component<ICampaignDetailsMainProps> {
	render() {
		const { mainDetailsViewModel, overviewTabViewModel, currentTabId, machineContext } = this.props.store;
		return (
			<StateMachine machineContext={machineContext}>
				<MatchNot state={States.Error}>
					<CampaignDetailsIntro {...mainDetailsViewModel} />
					<CampaignDetailsTabs currentTab={currentTabId} overviewViewModel={overviewTabViewModel}/>
				</MatchNot>
				<Match state={States.Error}>
					<CampaignsErrorModal state={States.Error} onClose={this.onCloseErrorModal} />
				</Match>
			</StateMachine>
		);
	}

	onCloseErrorModal = () => {
		Events.raise.CloseErrorModal(this.props.store.machineContext);
	}

}
