import CommandBase from './command-base';
import MoveAccountCommand from './move-account-command';
import { ICancellablePromise } from '../../../utils/cancellable-promise';
import * as Models from '../../models/index';
import * as Util from '../../util';
import Member from '../member';
import Account from '../account';


export default class MoveMemberCommand extends CommandBase<Models.MergeCommunityMembersResponseModel> {

	movedAccounts: Account[];

	constructor(public member: Member, public newMember: Member) {
		super();
		this.trackers.push(newMember.busyTracker);
	}


	preApply(): void {
		this.movedAccounts = this.member.accounts.slice();
		this.movedAccounts.forEach(x => MoveAccountCommand.moveAccount(x, this.member, this.newMember));

		this.newMember.expanded = true;

		Util.arrayRemove(this.member.parent.currentMembers, this.member);
	}

	send(): ICancellablePromise<Models.MergeCommunityMembersResponseModel> {
		if (!this.member.id) {
			//unexpected error so throw instead of promise.reject
			throw new Error('member has no id yet');
		}
		if (!this.newMember.id) {
			//unexpected error so throw instead of promise.reject
			throw new Error('newMember has no id yet');
		}

		return this.member.parent.dataService.moveMember({
			PeopleToMove: this.movedAccounts.map((value) => { return { PersonId: value.id }; }),
			CommunityMemberToAssimilate: this.member.id,
			CommunityMemberToMergeInto: this.newMember.id
		});
	}

	postApply(result: Models.MergeCommunityMembersResponseModel) {
		this.newMember.applyModel(result.TargetCommunityMember, false);
	}

	/**
	 * move the account back to the old members
	 */
	undoPreApply(error): void {
		this.newMember.parent.insertMembers(this.member);
		this.movedAccounts.forEach(x => MoveAccountCommand.moveAccount(x, this.newMember, this.member));
	}
}
