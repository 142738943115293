import * as React from 'react';
import { FormFieldTooltip } from '../../../../Shared/forms/fields/form-field-tooltip';
import { Panel, PanelType, PanelHeader, PanelBody } from '../../../components/panel';
import { ProgressIndicator } from '../../../components/progress-indicator';
import * as styles from './styles.less';
import { SvgWrapper } from '../../../../Shared/components/svg-wrapper';
import { Formatter } from '../../../helpers/formatter';

export interface IMigrationSummaryProps {
	fundName?: string;
	listingName?: string;
	overallScheduleCount: number;
	overallScheduleValue: number;
	migratedScheduleCount: number;
	migratedScheduleValue: number;
	migratedSchedulePercent: number;
	migratedDetailsUrl: string;
	incompleteScheduleCount: number;
	incompleteScheduleValue: number;
	incompleteDetailsUrl: string;
	declinedScheduleCount: number;
	declinedDetailsUrl: string;
	migratedElsewhereCount: number;
	migratedElsewhereUrl: string;
	unreachedCount: number;
	unreachedUrl: string;
	wrapperClassName?: string;
}

export class MigrationSummary extends React.Component<IMigrationSummaryProps> {
	render() {
		const {
			fundName, listingName, overallScheduleCount, overallScheduleValue, migratedScheduleCount, migratedSchedulePercent, migratedScheduleValue,
			migratedDetailsUrl, incompleteScheduleCount, incompleteScheduleValue, incompleteDetailsUrl, declinedScheduleCount, declinedDetailsUrl,
			migratedElsewhereCount, migratedElsewhereUrl, unreachedCount, unreachedUrl, wrapperClassName,
		} = this.props;

		return (
			<Panel panelType={PanelType.HIGHLIGHT} extraClassNames={wrapperClassName}>
				<PanelHeader>
					<h1>{listingName ? listingName :'All Listings'} and {fundName ? fundName + ' Fund' :'All Funds'}</h1>
				</PanelHeader>
				<PanelBody extraClassNames={styles.summaryPanel}>
					<div className={styles.panelLeft}>
						<div className={styles.chart}>
							<div className={styles.chartWrapper}>
								<ProgressIndicator totalCount={overallScheduleCount}
									currentCount={migratedScheduleCount} units={pluralize('Schedule', migratedScheduleCount)}/>
							</div>
							<p><strong>{Math.floor(migratedSchedulePercent)}</strong>% of your schedules have been successfully migrated</p>
						</div>
						<div className={styles.uploadedSummary}>
							<h2>Uploaded:</h2>
							<ul>
								<li><strong>{overallScheduleCount}</strong> {pluralize('Schedule', overallScheduleCount)}</li>
								<li><strong>${Formatter.formatNumberForDisplay(overallScheduleValue)}</strong> Est. average monthly total</li>
							</ul>
						</div>
					</div>
					<div className={styles.panelRight}>
						<SegmentSummary segmentName="Migrated" detailsUrl={migratedDetailsUrl} scheduleCount={migratedScheduleCount} monetaryTotal={migratedScheduleValue} />
						<SegmentSummary segmentName="Awaiting" detailsUrl={incompleteDetailsUrl} scheduleCount={incompleteScheduleCount} monetaryTotal={incompleteScheduleValue} />
						<SegmentSummary segmentName="Reminders stopped" detailsUrl={migratedElsewhereUrl} scheduleCount={migratedElsewhereCount}
							tooltip="You've stopped reminders for these schedules manually; or we've stopped sending reminders automatically because the owner has already setup one
							or more different schedules with Pushpay. The schedules can still be setup using the link in any emails sent previously." />
						<SegmentSummary segmentName="Could not be reached" detailsUrl={unreachedUrl} scheduleCount={unreachedCount} />
						<SegmentSummary segmentName="Declined" detailsUrl={declinedDetailsUrl} scheduleCount={declinedScheduleCount} />
					</div>
				</PanelBody>
			</Panel>
		);
	}
}

export interface ISegmentSummaryProps {
	segmentName: string;
	detailsUrl: string;
	scheduleCount: number;
	monetaryTotal?: number;
	tooltip?: string;
}

export class SegmentSummary extends React.Component<ISegmentSummaryProps> {
	render() {
		const { segmentName, detailsUrl, scheduleCount, monetaryTotal, tooltip } = this.props;

		const hasMonetaryTotal = monetaryTotal !== null && monetaryTotal !== undefined;

		return(
			<section className={styles.segmentCard}>
				<header>
					<h1>{segmentName}:{tooltip && <FormFieldTooltip message={tooltip} className={styles.tooltip} placement="top" />}</h1>
					<a href={detailsUrl}>View details <SvgWrapper svg="icon-chevron-right" width="14" height="14" style={{fill: 'currentColor'}}/></a>
				</header>
				<div className={styles.segmentDetail}><strong>{scheduleCount}</strong> {pluralize('Schedule', scheduleCount)}</div>
				{ hasMonetaryTotal && <div className={styles.segmentDetail}><strong>${Formatter.formatNumberForDisplay(monetaryTotal)}</strong> Est. average monthly total</div> }
			</section>
		);
	}
}

const pluralize = (word: string, count: number) => count === 1 ? word : `${word}s`;
