import * as React from 'react';
import Pikaday from 'pikaday';
import moment from 'moment';
import { BrowserSupportHelper } from '../../helpers/browsersupporthelper';
import { observer } from 'mobx-react';
import { SvgWrapper } from '../svg-wrapper';
import { IFormControlCommonProps } from './form-control-common-props';
import { ValidatableControl } from './validatable-control';
import { responsiveHelper } from '../../helpers/responsive-helper';
import { getElementId } from './form-control-utils';
import { Fragment } from '../../../Shared/components/fragment';
import { classNames } from '../../../Shared/utils/classnames';

export interface IFormControlDatePickerProps extends IFormControlCommonProps {
	value: Date;
	onChangeHandler: (date: Date) => void;
	onBlurHandler?: () => void;
	options?: Pikaday.PikadayOptions;
}

export const dateFormat = 'YYYY-MM-DD';

const neverValidRule = {
	nevervalid: {
		errorMessage: 'Invalid date, please try again or select a date.'
	}
};

@observer
class FormControlDateInputPikaday extends React.Component<IFormControlDatePickerProps, { isInvalid: boolean }> {
	private pikaday: Pikaday;

	constructor(props) {
		super(props);
		this.state = { isInvalid: false };
	}

	render() {
		const { name, placeholder, isInvalid, uniqueSuffix, validationRules, acceptanceTestTargetId, disabled } = this.props;
		const id = getElementId(name, uniqueSuffix);
		const statefulValidationRules = this.state.isInvalid ? neverValidRule : validationRules;

		const input = <input
			name={name}
			id={id}
			onBlur={this.onInvalidHandler}
			className="form-control"
			placeholder={placeholder}
			ref={this.initPikaday}
			data-pp-at-target={acceptanceTestTargetId}
			disabled={disabled}
		/>;

		return <ValidatableControl validationRules={statefulValidationRules} elementName={name} revalidateOnBlur={true} isInvalid={isInvalid}>{input}</ValidatableControl>;
	}

	UNSAFE_componentWillReceiveProps(nextProps: IFormControlDatePickerProps) {
		const currentValue = this.props.value;
		const newValue = nextProps.value;

		const currentMinDate = this.props.options && this.props.options.minDate;
		const newMinDate = nextProps.options && nextProps.options.minDate;
		this.setMinDate(newMinDate, currentMinDate, newValue);

		const currentMaxDate = this.props.options && this.props.options.maxDate;
		const newMaxDate = nextProps.options && nextProps.options.maxDate;
		this.setMaxDate(newMaxDate, currentMaxDate, newValue);

		this.setValue(newValue, currentValue);
	}

	componentWillUnmount() {
		this.destroyPikadayIfExists();
	}

	private destroyPikadayIfExists = () => {
		if (this.pikaday) {
			this.pikaday.destroy();
			this.pikaday = null;
		}
	}

	private initPikaday = (field: HTMLInputElement) => {
		this.destroyPikadayIfExists();

		if (field) {
			this.pikaday = new Pikaday({
				...this.props.options,
				onSelect: this.onSelect,
				format: dateFormat,
				field,
				blurFieldOnSelect: false
			} as Pikaday.PikadayOptions);
			this.pikaday.setDate(this.props.value, true);
		}
	}

	private setValue = (newValue: Date, currentValue: Date) => {
		if (!isSameDate(newValue, currentValue)) {
			this.pikaday.setDate(newValue, true);
		}
	}

	private setMinDate = (newMinDate: Date, currentMinDate: Date, newValue: Date) => {
		if (!isSameDate(newMinDate, currentMinDate)) {
			if (newMinDate !== null) {
				this.pikaday.setMinDate(newMinDate);

				if (newMinDate > newValue) {
					this.pikaday.gotoDate(newMinDate);
				}
			} else {
				this.pikaday.setMinDate(new Date(0));
			}
		}
	}

	private setMaxDate = (newMaxDate: Date, currentMaxDate: Date, newValue: Date) => {
		if (!isSameDate(newMaxDate, currentMaxDate)) {
			if (newMaxDate !== null) {
				this.pikaday.setMaxDate(newMaxDate);

				if (newMaxDate < newValue) {
					this.pikaday.gotoDate(newMaxDate);
				}
			}
		}
	}

	// Make pikaday more consistent with other input types by only firing onChange
	private onSelect = (date: Date) => {
		if (isSameDate(date, this.props.value)) {
			return;
		}
		this.props.onChangeHandler(date);
	}

	// When the input is left with an empty or invalid value it wont fire onSelect
	// This manually fires onSelect(null) to update any watching observables to null
	private onInvalidHandler = (ev: React.FocusEvent<HTMLInputElement>) => {
		const input = ev.currentTarget;
		const inputMoment = moment(input.value, dateFormat, false);
		const isInvalid = !inputMoment.isValid();
		if (isInvalid) {
			this.onSelect(null);
		}
		this.setState({ isInvalid: isInvalid && input.value !== '' });

		if (this.props.onBlurHandler) {
			this.props.onBlurHandler();
		}
	}
}

@observer
class FormControlDateInput extends React.Component<IFormControlDatePickerProps, {}> {
	render() {
		const { name, placeholder, value: date, options, uniqueSuffix, validationRules, isInvalid, acceptanceTestTargetId, disabled, onBlurHandler } = this.props;
		const value = date ? moment(date).format(dateFormat) : '';
		const id = getElementId(name, uniqueSuffix);
		const min = options && options.minDate ? moment(options.minDate).format(dateFormat) : '';
		const max = options && options.maxDate ? moment(options.maxDate).format(dateFormat) : '';

		const pseudoPlaceholderElement = value
			? null
			: <input type="text" className="form-control" placeholder={placeholder} aria-hidden={true} disabled={disabled} />;

		return (
			<Fragment>
				{pseudoPlaceholderElement}
				<ValidatableControl validationRules={validationRules} elementName={name} revalidateOnBlur={true} isInvalid={isInvalid}>
					<input name={name}
						id={id}
						type="date"
						onChange={this.onChange}
						onBlur={onBlurHandler}
						className={classNames('form-control', pseudoPlaceholderElement && 'with-placeholder')}
						value={value}
						min={min}
						max={max}
						data-pp-at-target={acceptanceTestTargetId}
						disabled={disabled} />
				</ValidatableControl>
			</Fragment>
		);
	}

	private onChange = (event: React.FormEvent<HTMLInputElement>) => {
		const { value } = event.currentTarget;
		const date = moment(value).isValid() ? new Date(value) : null;

		this.props.onChangeHandler(date);
	}
}

@observer
export class FormControlDatePicker extends React.Component<IFormControlDatePickerProps, {}> {
	render() {
		return (
			<div className="date-picker">
				{(responsiveHelper.isXs || responsiveHelper.isSm) && BrowserSupportHelper.supportsDateInput()
					? <FormControlDateInput {...this.props} />
					: <FormControlDateInputPikaday {...this.props} />
				}
				<SvgWrapper svg="calendar" className="svg svg-calendar-red" />
			</div>
		);
	}
}

@observer
export class FormControlDatePickerWithoutIcon extends React.Component<IFormControlDatePickerProps, {}> {
	render() {
		return (
			<div className="date-picker">
				{(responsiveHelper.isXs || responsiveHelper.isSm) && BrowserSupportHelper.supportsDateInput()
					? <FormControlDateInput {...this.props} />
					: <FormControlDateInputPikaday {...this.props} />
				}
			</div>
		);
	}
}

function isSameDate(a: Date, b: Date) {
	if (a === null || b === null || a === undefined || b === undefined) {
		return a === b;
	}

	return a.getTime() === b.getTime();
}
