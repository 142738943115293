import * as React from 'react';
import {observable, action, computed, reaction} from 'mobx';
import {observer} from 'mobx-react';
import {ArrayHelper} from '../helpers/arrayhelper';
import {KeyCodes} from '../../Shared/helpers/keycodes';
import {SvgWrapper} from '../components/svg-wrapper';
import {Utils} from '../helpers/utils';
import {responsiveHelper} from '../helpers/responsive-helper';
import {InputField} from '../funds/components/form-controls';
import { FloatingLabelComponent, FloatingLabelComponentStore } from '../funds/components/floating-label';
import { MultiSelectViewModel, MultiSelectItem } from '../loggedinweb-generated';

export class MultiSelectStore {
	@observable items: MultiSelectItem[];
	@observable selectedItems: MultiSelectItem[];
	@observable currentIndex: number;
	@observable searchTerm: string;
	@observable isActive: boolean;
	@observable dropListActive: boolean;

	constructor(multiSelectViewModel: MultiSelectViewModel) {
		this.items = multiSelectViewModel.Items;
		this.initSelectedItems(multiSelectViewModel.SelectedValues || []);
	}

	@action
	activate = () => {
		if (this.isActive) { return; }
		this.isActive = true;
		this.showDropList(true);
		this.resetCurrentItem();
	}

	@action
	deactivate = () => {
		this.isActive = false;
		this.searchTerm = '';
		this.resetCurrentItem();
	}

	@action
	showDropList = (showDropList: boolean) => {
		this.dropListActive = showDropList;
	}

	@action
	selectItem = (item: MultiSelectItem) => {
		item.Disabled = true;
		this.selectedItems.push(item);
		this.showDropList(false);
		this.resetCurrentItem();
	}

	@action
	selectItemByValue = (selectedValue: string) => {
		const selectedItem = this.items.find(x => x.Value === selectedValue);
		this.selectItem(selectedItem);
	}

	@action
	deselectItem = (item: MultiSelectItem) => {
		item.Disabled = false;
		this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
	}

	@action
	deselectLastItem = () => {
		if (!this.searchTerm && this.selectedItems.length > 0) {
			const lastSelectedItem = this.selectedItems[this.selectedItems.length - 1];
			this.deselectItem(lastSelectedItem);
		}
	}

	@action
	search = (searchTerm: string) => {
		this.searchTerm = searchTerm;
		this.resetCurrentItem();
	}

	@computed
	get searchExpression() {
		return new RegExp(`^(${Utils.escapeRegex(this.searchTerm)})(.*)`, 'i');
	}

	@computed
	get displayItems() {
		return this.searchTerm
			? this.items.filter(x => this.searchExpression.test(x.Text))
			: this.items;
	}

	@action
	setCurrentItem = (item: MultiSelectItem) => { this.currentIndex = this.displayItems.indexOf(item); }

	@action
	resetCurrentItem = () => { this.currentIndex = -1; }

	@computed
	get currentItem() {
		return this.currentIndex < 0
			? null
			: this.displayItems[this.currentIndex];
	}

	@action
	moveCurrentItemUp = () => {
		this.currentIndex = Math.max(this.currentIndex - 1, 0);
	}

	@action
	moveCurrentItemDown = () => {
		this.currentIndex = Math.max(Math.min(this.currentIndex + 1, this.displayItems.length - 1), 0);
	}

	@action
	selectCurrentItem = () => {
		if (this.displayItems.length === 1 && !this.displayItems[0].Disabled) {
			this.selectItem(this.displayItems[0]);
			return;
		}
		if (this.currentIndex < 0) {
			return;
		}
		if (this.displayItems[this.currentIndex].Disabled) { return; }

		this.selectItem(this.displayItems[this.currentIndex]);
	}

	@computed
	get selectedValues() {
		return ArrayHelper.emptyIfNull(this.selectedItems).map(x => x.Value);
	}

	private initSelectedItems(selectedValues: string[]) {
		const selectedItems = this.items.filter(x => ArrayHelper.contains(selectedValues, x.Value));
		this.selectedItems = selectedItems;
		selectedItems.forEach(x => x.Disabled = true);
	}
}

export interface IMultiSelectProps {
	multiSelectStore: MultiSelectStore;
	placeholderText?: string;
	fieldName?: string;
	validationRules?: any;
}

@observer
export class MultiSelect extends React.Component<IMultiSelectProps, {}> {
	private store = this.props.multiSelectStore;
	private container: HTMLElement;
	private input: HTMLInputElement;
	private itemsContainer: HTMLElement;
	private currentItem: HTMLElement;

	private floatingLabelStore = new FloatingLabelComponentStore();

	constructor(props) {
		super(props);
		this.floatingLabelStore.showLabelOnFocus = true;
		this.updateStateOfFloatingLabelStore();
		reaction(() => this.store.isActive || this.store.selectedItems.length, this.updateStateOfFloatingLabelStore);
	}

	@action
	updateStateOfFloatingLabelStore = () => {
		this.floatingLabelStore.elementHasFocus = this.store.isActive;
		this.floatingLabelStore.elementHasValue = this.store.selectedItems.length !== 0;
	}

	render() {
		const containerClass = `multi-select ${this.store.isActive ? ' open' : ''}`;
		const itemsContainerClass = `display-items dropdown-menu
			${this.store.displayItems.length > 0 && this.store.dropListActive ? '' : ' hidden'}`;

		return (
			<div className={containerClass} ref={(ref) => this.container = ref}
				onKeyDown={this.handleKeyPress}
				onClick={this.handleComponentFocused}
				onFocus={this.handleComponentFocused}>
				{this.renderHiddenInput() }
				{this.renderFloatingLabel()}
				<ul className="selected-item-container" onClick={this.handleSelectedItemsClick}>
					{this.store.selectedItems.map(selectedItem => this.renderSelectedItem(selectedItem))}
					{this.renderInput()}
					{this.renderMobileInput()}
				</ul>
				<ul className={itemsContainerClass} ref={(ref) => this.itemsContainer = ref}>
					{this.store.displayItems.map(item => this.renderItem(item))}
				</ul>
				<div className="clearfix"></div>
			</div>
		);
	}

	renderFloatingLabel() {
		if (this.props.placeholderText) {
			return (
				<FloatingLabelComponent
					store={this.floatingLabelStore}
					label={this.props.placeholderText}
					placeholder={this.props.placeholderText} />
			);
		}

		return null;
	}

	renderHiddenInput(): JSX.Element | JSX.Element[] {
		if (!this.props.fieldName) {
			return null;
		}
		const metadata = {
			propertyName: this.props.fieldName,
			validationRules: this.props.validationRules
		};
		if (this.store.selectedValues && this.store.selectedValues.length > 0) {
			return this.store.selectedValues
				.map(selectedValue => <InputField data-validate type="hidden" propertyMetadata={metadata} value={selectedValue} />);
		}
		return (<InputField data-validate type="hidden" propertyMetadata={metadata} value={null} />);
	}
	renderInput() {
		return (
			<li className="multi-select-input-container">
				{this.store.searchTerm}
				<input type="text" className="multi-select-input"
					ref={(ref) => this.input = ref}
					onChange={this.handleSearch}
					value={this.store.searchTerm}/>
			</li>
		);
	}
	renderMobileInput() {
		return (
			<select className="multi-select-mobile" multiple={true}
				onChange={this.handleMobileItemSelected}>
				{this.store.displayItems.map(item => this.renderMobileOption(item))}
			</select>
		);
	}
	renderMobileOption(item: MultiSelectItem) {
		const selected = ArrayHelper.contains(this.store.selectedValues, item.Value);
		return (
			<option key={item.Value} value={item.Value}
				selected={selected} disabled={item.Disabled && !selected}>
				{item.Text}
			</option>
		);
	}
	renderSelectedItem(selectedItem: MultiSelectItem) {
		return (
			<li className="spillow" key={`${selectedItem.Value}_selected`}>
				<span className="spillow-content">{selectedItem.SelectedText || selectedItem.Text}</span>
				<a href="#" className="spillow-close" onClick={(ev) => this.handleDeselect(ev, selectedItem) }>
					<SvgWrapper className="svg" svg="close-cross" />
				</a>
			</li>
		);
	}
	renderItem(item: MultiSelectItem) {
		const isCurrentItem = item === this.store.currentItem;
		const itemClass = isCurrentItem
			? 'selected'
			: '';

		return (
			<li className={itemClass} key={item.Value}
				ref={ref => ref && isCurrentItem ? this.currentItem = ref : null}>
				<button className="btn btn-link"
					onClick={() => this.store.selectItem(item)}
					onMouseOver={() => this.store.setCurrentItem(item)}
					disabled={item.Disabled}
					type="button" tabIndex={-1}>
					{this.store.searchTerm ? this.renderSelectedText(item) : item.Text}
				</button>
			</li>
		);
	}
	renderSelectedText(item: MultiSelectItem) {
		const matches = this.store.searchExpression.exec(item.Text);
		return (
			<span>
				<strong>{matches[1]}</strong>{matches[2]}
			</span >
		);
	}

	handleKeyPress = (ev: React.KeyboardEvent<any>) => {
		if (responsiveHelper.isXs) {
			return;
		}
		switch (ev.keyCode) {
			case KeyCodes.Tab:
			case KeyCodes.Escape: {
				this.resetScroll();
				this.input.blur();
				this.store.deactivate();
				return;
			}
			case KeyCodes.Enter: {
				this.store.selectCurrentItem();
				return;
			}
			case KeyCodes.DownArrow: {
				ev.preventDefault();
				this.store.moveCurrentItemDown();
				this.scrollDown();
				break;
			}
			case KeyCodes.UpArrow: {
				ev.preventDefault();
				this.store.moveCurrentItemUp();
				this.scrollUp();
				break;
			}
			case KeyCodes.Delete:
			case KeyCodes.Backspace: {
				this.store.deselectLastItem();
				break;
			}
		}
		this.store.showDropList(true);
	}

	resetScroll = () => {
		this.itemsContainer.scrollTop = 0;
	}
	scrollDown = () => {
		if (this.currentItem && this.currentItem.offsetTop + this.currentItem.offsetHeight >= this.itemsContainer.clientHeight) {
			this.itemsContainer.scrollTop += this.currentItem.offsetHeight;
		}
	}
	scrollUp = () => {
		if (this.currentItem && this.currentItem.offsetTop <= this.itemsContainer.scrollTop) {
			this.itemsContainer.scrollTop -= this.currentItem.offsetHeight;
		}
	}

	handleSearch = (ev: React.FormEvent<HTMLInputElement>) => {
		this.store.search(ev.currentTarget.value);
	}

	handleDeselect = (ev: React.MouseEvent<HTMLAnchorElement>, item: MultiSelectItem) => {
		ev.preventDefault();
		ev.stopPropagation();
		this.store.deselectItem(item);
		this.store.deactivate();
	}

	handleComponentFocused = () => {
		if (responsiveHelper.isXs) {
			return;
		}
		this.store.activate();
		this.input.focus();
	}
	handleMobileItemSelected = (ev: React.FormEvent<HTMLSelectElement>) => {
		this.store.selectItemByValue(ev.currentTarget.value);
	}
	handleSelectedItemsClick = () => {
		this.store.showDropList(true);
	}

	handleWindowClick = (ev: Event) => {
		if (!this.container) {
			return;
		}
		const targetElement = ev.target as Element;
		if (targetElement === this.container || this.container.contains(targetElement)) {
			return;
		}
		this.resetScroll();
		this.input.blur();
		this.store.deactivate();
	}

	componentDidMount() {
		window.addEventListener('click', this.handleWindowClick);
	}

	componentWillUnmount() {
		window.removeEventListener('click', this.handleWindowClick);
	}
}
