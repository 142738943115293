import * as React from 'react';
import { observer } from 'mobx-react';
import { Formatter } from 'helpers/formatter';

import * as styles from './styles.less';

export interface IScheduleDetailsSummaryProps {
	scheduleCount: number;
	monthlyTotal: number;
}

export const ScheduleDetailsSummary: React.SFC<IScheduleDetailsSummaryProps>  = observer(({scheduleCount, monthlyTotal}) => {
		return (
			<div className={styles.container}>
				<div>Schedules: <strong>{Formatter.formatNumberForDisplay(scheduleCount, 0)}</strong></div>
				<div>Estimated average monthly total: <strong>${Formatter.formatNumberForDisplay(monthlyTotal)}</strong></div>
			</div>
		);
});
