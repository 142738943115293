import { action, observable, computed, ObservableMap } from 'mobx';
import { Formatter } from '../../../helpers/formatter';
import { GivingStatementsPeriodType, PaymentLabel } from '../../giving-statements-generated';
import { GivingStatementsViewModel } from '../../giving-statements-view-model';

export class ConfigurePageViewModel {
	@observable isRegenerating: boolean = false;

	@observable periodControlsDisabled: boolean = false;

	@observable statementControlsDisabled: boolean = false;

	@observable nonChangeableControlsDisabled: boolean = false;

	@observable errorMap: ObservableMap<string, string> = observable.map<string, string>();

	@observable errorMessages: string[] | null = null;

	@observable taxDeductibleFunds: string[] = [];

	@observable backToHomeAfterSubmit: boolean = false;

	@observable isIntended: string = "false";

	constructor(
		public mainViewModel: GivingStatementsViewModel,
		public fundsSummaryUrl: string,
		public paymentLabel: PaymentLabel
	) { }

	@computed
	get isFormValid() {
		return !Array.from(this.errorMap.values()).some(x => !!x);
	}

	@action.bound
	periodChangedHandler(periodType: GivingStatementsPeriodType, startDate: Date, endDate: Date) {
		this.mainViewModel.currentConfiguration.selectedType = periodType;
		this.mainViewModel.currentConfiguration.statementFromDate = startDate;
		this.mainViewModel.currentConfiguration.statementToDate = endDate;
		this.generateStatementName();
	}

	@action.bound
	generateStatementName() {
		const { selectedType, statementToDate } = this.mainViewModel.currentConfiguration;

		if (!statementToDate) {
			return;
		}

		switch (selectedType) {
			case GivingStatementsPeriodType.Annual:
				this.statementNameChanged(`${statementToDate.getFullYear()} Annual Giving Statement`);
				break;
			case GivingStatementsPeriodType.Quarterly:
				this.statementNameChanged(`Quarter ${Math.floor(statementToDate.getMonth() / 3) + 1} ${statementToDate.getFullYear()} Giving Statement`);
				break;
			case GivingStatementsPeriodType.Custom:
				this.statementNameChanged(`${Formatter.formatDate(statementToDate, 'MMM')} ${statementToDate.getFullYear()} Giving Statement`);
				break;
		}
	}

	@action.bound
	statementNameChanged(name: string) {
		this.mainViewModel.currentConfiguration.statementName = name;
	}

	@action.bound
	emailAddressChanged(value: string) {
		this.mainViewModel.currentConfiguration.emailAddress = value;
	}

	@action.bound
	phoneNumberChanged(value: string) {
		this.mainViewModel.currentConfiguration.phoneNumber = value;
	}

	@action.bound
	nonCashIncludedChanged(included: boolean) {
		this.mainViewModel.currentConfiguration.nonCashIncluded = included;
	}

	@action.bound
	einIncludedChanged(included: boolean) {
		this.mainViewModel.currentConfiguration.einIncluded = included;
	}

	@action.bound
	customerAddressIncludedChanged(included: boolean) {
		this.mainViewModel.currentConfiguration.customerAddressIncluded = included;
	}

	@action.bound
	publishTypeChanged(publishType: string) {
		this.mainViewModel.currentConfiguration.publishType = publishType;
	}

	@action.bound
	contributionsThresholdChanged(amount: number) {
		this.mainViewModel.currentConfiguration.contributionsThreshold = amount;
	}

	@action.bound
	setTaxDeductibleFunds(items: string[]) {
		this.taxDeductibleFunds = items;
	}

	@action.bound
	setValidationErrors(errors: { [key: string]: string; } | null) {
		this.errorMap.merge(errors);
		this.errorMessages = errors ? Object.getOwnPropertyNames(errors).map(name => errors[name]) : null;
	}

	@action.bound
	clearErrorMessages(): void {
		this.errorMessages = null;
	}

	@action.bound
	setIsRegenerating(value: boolean) {
		this.isRegenerating = value;
	}

	@action.bound
	setPeriodControlsDisabled(value: boolean) {
		this.periodControlsDisabled = value;
	}

	@action.bound
	setStatementControlsDisabled(value: boolean) {
		this.statementControlsDisabled = value;
	}

	@action.bound
	setNonChangeableControlsDisabled(value: boolean) {
		this.nonChangeableControlsDisabled = value;
	}

	@action.bound
	setBackToHomeAfterSubmit(value: boolean) {
		this.backToHomeAfterSubmit = value;
	}

	@action.bound
	setIsIntended(isIntended: string) {
		this.isIntended = isIntended;
	}
}
