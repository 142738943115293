import * as React from 'react';
import { observer } from 'mobx-react';
import { Fragment } from '../../../../Shared/components/fragment';
import { PanelHeader, PanelBody } from '../../../components/panel';
import { FormControlSelect, IFormControlSelectOptionProps } from '../../../components/form-controls/form-control-select';
import * as styles from './styles.less';
import * as sharedStyles from '../shared-styles/styles.less';

export interface SetUpContactMethodsProps {
	title: string;
	numberOfStatements: number;
	primaryContactMethodProps: IContactMethodSelectorProps;
	additionalContactMethodProps?: IContactMethodSelectorProps[];
}

export interface IContactMethodSelectorProps {
	title: string;
	message?: string | JSX.Element;
	selectedValue?: string;
	options: IFormControlSelectOptionProps[];
	changeHandler: (selectedValue: string) => void;
}

@observer
export default class SetUpContactMethods extends React.Component<SetUpContactMethodsProps> {
	render() {
		const { title, numberOfStatements, primaryContactMethodProps, additionalContactMethodProps } = this.props;

		return (
			<Fragment>
				<PanelHeader>
					{title}
				</PanelHeader>
				<PanelBody>
					<div className={sharedStyles.panelRowGroup}>
						<div className={`row ${sharedStyles.panelRow}`}>
							<div className={styles.flexContainer}>
								<span className={`${sharedStyles.panelRowLabel}`}>Total statements generated</span>
								<span className={`${sharedStyles.panelRowValue}`}>{numberOfStatements}</span>
							</div>
						</div>
						<div className={`row ${sharedStyles.panelRow}`}>
							<div className={styles.flexContainerVertical}>
								<span className={sharedStyles.panelRowLabel}>{primaryContactMethodProps.title}</span>
								{
									primaryContactMethodProps.message &&
									<span className={styles.contactMethodMessage}>{primaryContactMethodProps.message}</span>
								}
								<FormControlSelect
									name="primaryInput"
									placeholder="Select an option"
									Options={primaryContactMethodProps.options}
									value={primaryContactMethodProps.selectedValue}
									onChangeHandler={this.onPrimarySelectionChangeEvent}
									wrapperClassName={styles.contactMethodsOptionsSelect}
								/>
							</div>
						</div>
						{additionalContactMethodProps && additionalContactMethodProps.map((methodProps, i) => {
							const onMethodChangeEvent = (selectionEvent: React.FormEvent<HTMLSelectElement>) => {
								methodProps.changeHandler(selectionEvent.currentTarget.value);
							};

							return (
								<div className={`row ${sharedStyles.panelRow}`} key={`secondarySelectorRow-${i}`}>
									<div className={styles.flexContainerVertical}>
										<span className={sharedStyles.panelRowLabel}>{methodProps.title}</span>
										{
											methodProps.message &&
											<span className={styles.contactMethodMessage}>{methodProps.message}</span>
										}
										<FormControlSelect
											name={`secondarySelectorInput-${i}`}
											placeholder="Select an option"
											Options={methodProps.options}
											value={methodProps.selectedValue}
											onChangeHandler={onMethodChangeEvent}
											wrapperClassName={styles.contactMethodsOptionsSelect}
										/>
									</div>
								</div>
							);
						})}
					</div>
				</PanelBody>
			</Fragment>
		);
	}

	onPrimarySelectionChangeEvent = (selectionEvent: React.FormEvent<HTMLSelectElement>) => {
		this.props.primaryContactMethodProps.changeHandler(selectionEvent.currentTarget.value);
	}
}
