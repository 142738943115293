import * as React from 'react';
import { FlashMessageType } from '../../qbo-integration-generated';

export interface FlashMessageBoxProps {
	Title: string;
	Message: string | string[];
	MessageType: FlashMessageType;
}

export class FlashMessageBox extends React.Component<FlashMessageBoxProps> {
	render() {
		const { Message } = this.props;

		const alertClassName = this.getContainerClassName();
		const iconClassName = this.getIconClassName();

		return Message && (
			<div className={`alert ${alertClassName}`}>
				<span className={`ion ${iconClassName}`} />
				<h3>{this.getTitleEvenIfEmpty()}</h3>
				{ typeof Message === 'string' ?
					<p>{Message}</p> :
					<ul>
						{(Message as string[]).map(item => <li key={item}>{item}</li>)}
					</ul>
				}

			</div>
		);
	}

	private getContainerClassName() {
		switch (this.props.MessageType) {
			case FlashMessageType.Error:
				return 'alert-danger';
			case FlashMessageType.Success:
				return 'alert-success';
			case FlashMessageType.Info:
				return 'alert-info';
			default:
				return 'alert-warning';
		}
	}

	private getIconClassName() {
		switch (this.props.MessageType) {
			case FlashMessageType.Error:
				return 'ion-ios-close-outline';
			case FlashMessageType.Success:
				return 'ion-ios-checkmark-outline';
			case FlashMessageType.Info:
				return 'ion-ios-information-outline';
			default:
				return 'ion-ios-information-outline';
		}
	}

	private getTitleEvenIfEmpty() {
		if(this.props.Title) {
			return this.props.Title;
		}

		switch (this.props.MessageType) {
			case FlashMessageType.Error:
				return 'Error';
			case FlashMessageType.Success:
				return 'Success';
			default:
				return 'Attention';

		}
	}
}
