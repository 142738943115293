import { ResponseTypes } from '../../funds/funds-generated';
// replicates AutocompleteItem but with optional TextPrefix
// ReSharper disable InconsistentNaming
export type IntegrationFundDisplayItem = {
	Text: string;
	TextPrefix?: string;
	Value: string;
};
// ReSharper restore InconsistentNaming

export default function getIntegrationFundAutocompleteItems(funds: ResponseTypes.IntegrationFundViewModel[]):
	IntegrationFundDisplayItem[] {

	const sortableNames = funds.reduce((val, item) => {
			val[item.Key] = item.Name.toLowerCase();
			return val;
		}, {});

	return funds
		.orderBy(x => sortableNames[x.ParentFundKey || x.Key])
		.thenBy(x => !!x.ParentFundKey)
		.thenBy(x => sortableNames[x.Key])
		.toArray().map(toIntegrationFundDisplayItem);
}

function toIntegrationFundDisplayItem(fund: ResponseTypes.IntegrationFundViewModel): IntegrationFundDisplayItem {
	const text = `${fund.Name}${fund.Code !== null ? ` (${fund.Code})` : ``}`;
	return { Value: fund.Key, Text: text, TextPrefix: fund.ParentFundKey ? '-' : '' };
}
