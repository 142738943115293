import { observable } from 'mobx';
import { ScheduleImportStepViewModel, ImportStep } from '../schedule-import-step-view-model';
import {
	PaymentLabel,
	ImportProgressViewModel,
	ImportProgressResponse,
	OperationResult,
} from '../../schedule-import-generated';
import { getScheduleImportDataService, ScheduleImportDataServiceAction, ScheduleImportApiConfigType, } from '../../schedule-import-data-service';
import { IDataServiceActionSubscriber } from '../../../utils/data-service';

export class ScheduleImportProgressViewModel extends ScheduleImportStepViewModel {
	readonly ImportId: number;
	readonly paymentLabel: PaymentLabel;
	readonly importType: string;
	readonly listingName: string;
	readonly monthlyTotal: number;
	readonly numberOfSchedules: number;
	readonly numberOfFunds: number;
	readonly numberOfGivers: number;
	readonly dateStarted: string;
	readonly importedBy: string;
	readonly pollForUpdateTimeInMs: number = 2000;
	updateIntervalId: number;
	@observable percentageComplete: number = 0;

	getProgressSubscriber: IDataServiceActionSubscriber<ScheduleImportApiConfigType, 'getImportProgressStatus'>;

	constructor(model: ImportProgressViewModel) {
		super(model.PaymentLabel, ImportStep.Review);

		this.ImportId = model.ImportId;
		this.paymentLabel = model.PaymentLabel;
		this.importType = model.ImportType;
		this.listingName = model.ListingName;
		this.monthlyTotal = model.MonthlyTotal;
		this.numberOfSchedules = model.NumberOfSchedules;
		this.numberOfFunds = model.NumberOfFunds;
		this.numberOfGivers = model.NumberOfGivers;
		this.dateStarted = model.DateStarted;
		this.importedBy = model.ImportedBy;

		this.getProgressSubscriber = getScheduleImportDataService().getActionSubscriberFactory('getImportProgressStatus')((action) => this.subscribeToGetProgress(action));
		this.startUpdateProgressPolling();
		this.showInfoAlert(
			'We are waiting in line to upload your file. Leaving this page will not affect this process.'
		);
	}

	private startUpdateProgressPolling() {
		this.updateIntervalId = window.setInterval(() => {
			this.getProgressSubscriber.initRequest({
				scheduleImportBundleId: this.ImportId
			});
		}, this.pollForUpdateTimeInMs);
	}

	private stopUpdateProgressPolling() {
		if (this.updateIntervalId) {
			clearInterval(this.updateIntervalId);
		}
	}

	private subscribeToGetProgress(action: ScheduleImportDataServiceAction) {
		switch (action.type) {
			case 'request_success':
				const response: ImportProgressResponse  =  action.response;
				if (response.Result === OperationResult.Success) {
					this.percentageComplete = response.PercentageComplete;

					if (this.percentageComplete >= 100) {
						this.stopUpdateProgressPolling();
						window.location.reload();
					}
				}
				break;
		}
	}
}
