import { ModalDialogCommander } from '../components/modal-dialog-commander';
import {
	ActionKey,
	combineMiddleware,
	DataService,
	DataServiceAction,
	DataServiceOptions,
	IDataService,
	isActionForService
} from '../utils/data-service';
import { TransactionImportApiConfig } from './transaction-import-generated';

export type TransactionImportApiConfigType = typeof TransactionImportApiConfig;
export type TransactionImportDataService = IDataService<TransactionImportApiConfigType>;
export type TransactionImportDataServiceAction = DataServiceAction<TransactionImportApiConfigType, any>;

export function isTransactionImportAction<TActionKey extends ActionKey<TransactionImportApiConfigType>>(action: TransactionImportDataServiceAction, key: TActionKey):
	action is DataServiceAction<TransactionImportApiConfigType, TActionKey> {
	return isActionForService(action, key);
}

function reportUnexpectedError(error, action: TransactionImportDataServiceAction) {
	window.reportUnhandledRejection(error, { action });
}

let transactionImportDataService: TransactionImportDataService = null;

function createTransactionImportDataService(options?: DataServiceOptions<TransactionImportApiConfigType>): TransactionImportDataService {
	options = options || {};

	options.middleware = combineMiddleware(next => action => {
		switch (action.type) {
			case 'request_init':
				console.log(`Transaction import request initiated`, action);
				break;
			case 'request_success':
				console.log(`Transaction import request completed`, action);
				break;
			case 'request_error':
				const { error } = action;

				if (error.shouldReloadPage || error.timedout) {
					ModalDialogCommander.error(error.userError, '').done(() => {
						document.location.reload(true);
					});
					return;
				}

				console.log(`Transaction import request failed`, action);
				break;
			case 'request_cancel':
				console.log(`Transaction import request cancelled`, action);
				break;
			case 'unexpected_error':
				reportUnexpectedError(action.error, action);
				ModalDialogCommander.showStandardErrorMessage();
				break;
		}

		return next(action);
	}, options.middleware);

	return new DataService(TransactionImportApiConfig, options);
}

export function getTransactionImportDataService(): TransactionImportDataService {
	if (!transactionImportDataService) {
		transactionImportDataService = createTransactionImportDataService();
	}

	return transactionImportDataService;
}

export function mockTransactionImportDataService(mockDataService: TransactionImportDataService) {
	transactionImportDataService = mockDataService;
}
