import {
	CustomFieldType,
	ICustomFieldViewModel,
	TextFieldValidation,
} from '../../LoggedInWeb/loggedinweb-generated';

export type CustomFieldViewModel = ICustomFieldViewModel;

const regexValidationLettersOnly = '^\\D*$';
const regexValidationNumbersOnly = '^[0-9]*$';

type ValidationRules = { [rule: string]: any };

export type PropertyMetadata = {
	propertyName: string;
	displayName?: string;
	placeholder?: string;
	validationRules?: { [rule: string]: any };
};

export function getCustomFieldPropertyMetadata(field: CustomFieldViewModel): PropertyMetadata {
	return {
		propertyName: field.Key,
		displayName: field.Label,
		placeholder: field.Type === CustomFieldType.Number || field.Type === CustomFieldType.Text ? field.Placeholder : '',
		validationRules: getValidationRules(field),
	};
}

export function getValidationRules(field: CustomFieldViewModel): ValidationRules {
	const rules: ValidationRules = {};

	switch (field.Type) {
		case CustomFieldType.Text:
			setLengthRule(rules, field.MinLength, field.MaxLength, field.Label);

			if (field.IsRequired) {
				rules['required'] = {
					errorMessage: `Please enter ${field.Label.toLowerCase()}`,
				};
			}

			if (field.Validation === TextFieldValidation.LettersOnly) {
				rules['regex'] = {
					errorMessage: `The ${field.Label.toLowerCase()} is not valid`,
					parameters: {
						pattern: regexValidationLettersOnly,
					},
				};
			}
			break;
		case CustomFieldType.Number:
			setLengthRule(rules, field.MinLength, field.MaxLength, field.Label);

			if (field.IsRequired) {
				rules['required'] = {
					errorMessage: `Please enter ${field.Label.toLowerCase()}`,
				};
			}

			rules['regex'] = {
				errorMessage: `The ${field.Label.toLowerCase()} is not valid`,
				parameters: {
					pattern: regexValidationNumbersOnly,
				},
			};
			break;
		case CustomFieldType.DropDown:
		case CustomFieldType.Fund:
			if (field.IsRequired) {
				rules['required'] = {
					errorMessage: `Please choose an option for ${field.Label.toLowerCase()}`,
				};
			}

			break;
	}

	return rules;
}

function setLengthRule(rules: ValidationRules, minLength: number, maxLength: number, fieldLabel: string) {
	let message = `Please enter between ${minLength} and ${maxLength} characters for ${fieldLabel.toLowerCase()}`;
	if (minLength === maxLength) {
		message = `Please enter ${minLength} characters for ${fieldLabel.toLowerCase()}`;
	}

	rules['length'] = {
		errorMessage: message,
		parameters: {
			min: minLength,
			max: maxLength,
		},
	};
}
