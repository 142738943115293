import * as React from 'react';
import * as styles from '../resource-center-dashboard.less';

interface VideoContainerProps {
	title: string;
	url: string;
}

export const VideoContainer = ({ title, url }) => (
	<>
		<h4 className={styles.videoTitle}>{title}</h4>
		<div className={styles.video}>
			<iframe className={styles.videoFrame} title={title} frameBorder={0} src={url} allowFullScreen />
		</div>
	</>
);
