import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Form } from '../../../../Shared/forms/primitives/form';
import { Formatter } from '../../../helpers/formatter';
import { TextBoxField } from '../../../../Shared/forms/fields/textbox-field';
import { AmountField } from '../../../../Shared/forms/fields/amount-field';
import { RadioButtonField } from '../../../../Shared/forms/fields/radio-button-field';
import { CheckboxListField } from '../../../../Shared/forms/fields/checkboxlist-field';
import { CreateCampaignMainViewModel } from '../../pages/create-campaign/create-campaign-main-view-model';
import { machineContextKey, MachineContext } from '../../../../Shared/state-machine/saga-state-machine';
import { CampaignStatus, CampaignCreateRequest, CampaignEditRequest, CampaignDetailsViewModel } from '../../campaigns-generated';
import { Match } from '../../../../Shared/state-machine/match';
import { Events, States } from '../../pages/create-campaign/create-campaign-states-events-and-actions';
import { Button } from '../../../../Shared/components/button/button';
import { classNames } from '../../../../Shared/utils/classnames';
import { Fragment } from '../../../../Shared/components/fragment';
import { DateRangeField } from '../../../../Shared/forms/fields/date-range-field';
import { TextareaField } from '../../../../Shared/forms/fields/textarea-field';

import * as styles from './create-campaign-form.less';
import { ModalDialogCommander } from '../../../components/modal-dialog-commander';
import { CampaignCloseConfirmationModal } from '../campaigns-modals/campaign-close-confirmation-modal';

@inject(machineContextKey)
@observer
export class CreateCampaignForm extends React.Component<{ viewModel: CreateCampaignMainViewModel, machineContext?: MachineContext }> {
	render() {
		const { viewModel, machineContext } = this.props;
		const { form, listings, campaignStatus, campaignId, backUrl, campaignDetails, paymentLabel, pledgeLabel } = viewModel;
		const isProcessing = machineContext.matchesAnyState([
			States.ProcessingCloseCampaign,
			States.ProcessingSaveAndPublish,
			States.ProcessingSave
		]);
		const showOutcomeNumbers = machineContext.matchesState(States.Editing) &&
			(campaignStatus === CampaignStatus.Published || campaignStatus === CampaignStatus.Closed);
		const shouldDisableStartField = campaignStatus === CampaignStatus.Published || campaignStatus === CampaignStatus.Closed;
		const showListings = listings && (listings.length > 1);
		return (
			<Form formState={form} disabled={isProcessing} className={styles.form}>
				<div className="panel panel-default">
					<div className="panel-heading">
						Campaign details
					</div>
					{
						showOutcomeNumbers &&
						<div className={styles.outcomeBar}>
							<span className={styles.outcomeNumbers}>
								<span className={styles.outcomeNumber}>{NewFeatures.DonorPledgeEntry_ConfigurablePledgeGrammar ? `${pledgeLabel.VerbPastTenseSentenceCase}` : 'Pledged'} <strong>${Formatter.formatNumberForDisplay(campaignDetails.PledgedTotal)}</strong></span>
								<span className={styles.outcomeNumber}>Collected <strong>${Formatter.formatNumberForDisplay(campaignDetails.CollectedAmount)}</strong></span>
							</span>
						</div>
					}
					<div className={classNames('panel-body', showOutcomeNumbers && styles.formBody)}>
						<div className="row">
							<TextBoxField label="Campaign name"
								className={classNames(styles.formGroup, 'col-sm-8')}
								acceptanceTestTargetId="Campaign name"
								fieldState={form.$.campaignName}
								placeholder="e.g. Missions fundraiser"
								tooltip={{
									message: 'A new fund will be created for each Campaign',
									placement: 'top',
								}}
								getDescription={() => <FundLinkInfo campaignDetails={campaignDetails} />}
							/>
						</div>
						<div className="row">
							<TextareaField label="Description"
								className={classNames(styles.formGroup, 'col-sm-12')}
								acceptanceTestTargetId="Description"
								fieldState={form.$.description}
								tooltip={{
									message: `Visible to your givers when they make a ${NewFeatures.DonorPledgeEntry_ConfigurablePledgeGrammar ? `${pledgeLabel.NounLowerCase}` : 'pledge'}`,
									placement: 'top',
								}}
							/>
						</div>
						<div className="row">
							<AmountField label="Financial goal (optional)"
								className={classNames(styles.formGroup, 'col-sm-4')}
								acceptanceTestTargetId="Financial goal"
								fieldState={form.$.financialGoal}
								placeholder="e.g. 1,000.00"
							/>
						</div>
						<div className="row">
							<DateRangeField
								fromLabel="Start"
								toLabel="Finish"
								containerClassName={classNames(styles.formGroup, 'col-sm-8')}
								acceptanceTestTargetId="Start Finish Date"
								fieldState={form.$.StartFinishDate}
								disableFrom={shouldDisableStartField} />
						</div>
						{
							showListings &&
							<div className="row">
								<CheckboxListField label="Select listings for this Campaign to apply to"
									className={classNames(styles.formGroup, 'col-xs-12')}
									acceptanceTestTargetId="Select listings for this Campaign"
									fieldState={form.$.campaignListings}
									options={listings.map(x => ({
										label: x.DisplayName,
										value: x.Id,
									}))} />
							</div>
						}
						<div className="row">
							<TextBoxField label="Add an email address (optional)"
								className={classNames(styles.formGroup, 'col-sm-4')}
								acceptanceTestTargetId="Email Address"
								fieldState={form.$.email}
								placeholder="e.g. name@gmail.com"
								tooltip={{
									message: `Visible to your ${paymentLabel.PayerLowerCase}s on their account's Campaigns page.`,
									placement: 'top',
								}}
							/>
						</div>
						<div className="row">
							<TextBoxField label="Add a contact phone number (optional)"
								className={classNames(styles.formGroup, 'col-sm-4')}
								acceptanceTestTargetId="Phone Number"
								fieldState={form.$.phoneNumber}
								placeholder="e.g. (405) 546 4567"
								tooltip={{
									message: `Visible to your ${paymentLabel.PayerLowerCase}s on their account's Campaigns page.`,
									placement: 'top',
								}}
							/>
						</div>
					</div>
					<Fragment>
						<div className="panel-heading">{paymentLabel.NounSentenceCase} &amp; Fund Details</div>
						<div className={classNames('panel-body', styles.formBody)}>
							<div className="row">
								<TextareaField label="Customized thank you message (optional)"
									acceptanceTestTargetId="Thank you message"
									fieldState={form.$.fundThankYouMessage}
									tooltip={{
										message: `Provide a custom message for this campaign to be shown to ${paymentLabel.PayerLowerCase}s on the thank you screen and receipt email. `
											+ `Leave blank to use the thank you message in General Settings.`,
										placement: 'top',
									}}
								/>
							</div>
							<div className="row">
								<RadioButtonField label="Is this tax deductible?"
									className={classNames( styles.formGroup, 'col-xs-12' )}
									acceptanceTestTargetId="Is tax deductible"
									fieldState={form.$.isTaxDeductible}
									options={[
										{
											label: 'Yes',
											value: `${true}`,
										},
										{
											label: 'No',
											value: `${false}`,
										}
									]} />
							</div>
						</div>
					</Fragment>
					<div className={classNames('panel-footer', styles.buttons, styles.formFooter)}>
						<BackButton backUrl={backUrl} />
						<Match state={States.Creating}>
							<SaveButton
								text="Save"
								loadingText="Saving"
								viewModel={viewModel} className={classNames(styles.draft, styles.button, `btn`, `btn-cancel`)} />
							<PublishButton
								text="Publish"
								loadingText="Publishing"
								viewModel={viewModel}/>
						</Match>
						<Match state={States.Editing}>
							{
								campaignStatus === CampaignStatus.Draft &&
								<Fragment>
									<SaveButton
										text="Save"
										loadingText="Saving"
										viewModel={viewModel} className={classNames(styles.draft, styles.button, `btn`, `btn-cancel`)} />
									<PublishButton
										text="Publish"
										loadingText="Publishing"
										viewModel={viewModel} />
								</Fragment>
							}
							{
								(campaignStatus === CampaignStatus.Published || campaignStatus === CampaignStatus.Scheduled) &&
								<Fragment>
									<CloseCampaignButton
									id={campaignId}
									viewModel = {viewModel}
									/>
									<SaveButton
										text="Update Campaign"
										loadingText="Updating"
										viewModel={viewModel}
										className={classNames(styles.publish, styles.button, `btn`, `btn-primary`)} />
								</Fragment>
							}
							{
								campaignStatus === CampaignStatus.Closed &&
								<Fragment>
									<PublishButton
										text="Reactivate"
										loadingText="Reactivating"
										viewModel={viewModel} />
								</Fragment>
							}
						</Match>
					</div>
				</div>
			</Form>
		);
	}
}

@observer
class FundLinkInfo extends React.Component<{ campaignDetails: CampaignDetailsViewModel }> {
	render() {
		const { FundName, FundUrl } = this.props.campaignDetails;
		return FundName ? (
			<div className={styles.fundInfo}>
				This campaign is linked to fund: <a href={FundUrl} className={styles.fundInfoLink}>{FundName}</a>
			</div>
		) : null;
	}
}

@observer
class BackButton extends React.Component<{ backUrl: string }> {
	render() {
		const { backUrl } = this.props;
		return (
			<a href={backUrl} title="Back" className={classNames(styles.back, styles.button, `btn`, `btn-cancel`)} data-pp-at-target="back button">Back</a>
		);
	}
}

@inject(machineContextKey)
@observer
class CloseCampaignButton extends React.Component<{ id: number, machineContext?: MachineContext, viewModel: CreateCampaignMainViewModel}> {
	render() {
		const { machineContext } = this.props;
		const isLoading = machineContext.matchesState(States.ProcessingCloseCampaign);
		return (
			<Button type="button" onClick={this.showCloseCampaignConfirmationPopup} className={classNames(styles.draft, styles.button, `btn`, `btn-cancel`)}
				acceptanceTestTargetId="close campaign button">
				{isLoading ? `Closing` : `Close Campaign`}
			</Button>
		);
	}

	showCloseCampaignConfirmationPopup = () =>
	{
		const { viewModel:{modalDialogProcessingViewModel} } = this.props;

		const campaignCloseConfirmationDialog = React.createElement(CampaignCloseConfirmationModal, {
			onSubmitHandler: this.confirmCloseCampaign,
			onCancelHandler: null,
			viewModel : modalDialogProcessingViewModel
		});

		ModalDialogCommander.showReactForm(campaignCloseConfirmationDialog);
	}

	confirmCloseCampaign = () => {
		const { id, machineContext, viewModel:{ modalDialogProcessingViewModel:{setIsProcessing} }} = this.props;

		setIsProcessing(true);
		Events.raise.CloseCampaign(machineContext, { Id: id })
	}
}

@inject(machineContextKey)
@observer
class PublishButton extends React.Component<{
	viewModel: CreateCampaignMainViewModel,
	text: string,
	loadingText: string,
	machineContext?: MachineContext,
}> {
	render() {
		const { machineContext } = this.props;
		const isLoading = machineContext.matchesState(States.ProcessingSaveAndPublish);
		const buttonText = getPublishButtonText(this.props, isLoading);
		return (
			<Button type="button" isLoading={isLoading} onClick={this.onPublishClick} className={classNames(styles.publish, styles.button, `btn`, `btn-primary`)}
				acceptanceTestTargetId="publish campaign button">
				{buttonText}
			</Button>
		);
	}

	onPublishClick = async () => {
		const { viewModel: { form, fieldsForRequest }, machineContext } = this.props;
		const result = await form.validate();
		if (result.hasError) {
			form.enableAutoValidation();
		} else {
			Events.raise.SaveAndPublish(machineContext, { ...fieldsForRequest, Publish: true } as CampaignCreateRequest | CampaignEditRequest);
		}
	}
}

function getPublishButtonText(props, isLoading) {
	const { viewModel: { fundMappingRequired }, text, loadingText } = props;

	if (fundMappingRequired) {
		return `Next`;
	}
	return isLoading ? loadingText : text;
}

@inject(machineContextKey)
@observer
class SaveButton extends React.Component<{
	viewModel: CreateCampaignMainViewModel,
	className: string,
	text: string,
	loadingText: string,
	machineContext?: MachineContext
}> {
	render() {
		const { machineContext, className, text, loadingText } = this.props;
		const isLoading = machineContext.matchesState(States.ProcessingSave);
		return (
			<Button type="button" isLoading={isLoading} onClick={this.onSave} className={className} acceptanceTestTargetId="save campaign button">
				{isLoading ? loadingText : text}
			</Button>
		);
	}

	onSave = async () => {
		const { viewModel: { form, fieldsForRequest }, machineContext } = this.props;
		const result = await form.validate();
		if (result.hasError) {
			form.enableAutoValidation();
		} else {
			Events.raise.Save(machineContext, { ...fieldsForRequest, Publish: false } as CampaignCreateRequest | CampaignEditRequest);
		}
	}
}
