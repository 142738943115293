import * as React from 'react';
import {BatchDialogStore} from './batch-dialog-store';
import {SvgWrapper} from '../../components/svg-wrapper';

export class BatchAddNewDepositLink extends React.Component<{store: BatchDialogStore}> {
	render() {
		const { openDialogOrCheckScanningPortal, isCheckScanningReadOnly } = this.props.store;
		return(
			<button type="button" className="btn btn-link btn-sm"
				disabled={isCheckScanningReadOnly}
				onClick={openDialogOrCheckScanningPortal}>
				<SvgWrapper svg="icon-follow-link" className="icon" />
				Open check scanning portal
			</button>
		);
	}
}
