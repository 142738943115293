import * as React from 'react';

import { useVirtualizer, Virtualizer as VirtualizerBase, VirtualItem as VirtualItemBase } from '@tanstack/react-virtual';

export type VirtualItem = VirtualItemBase;

export type Virtualizer = VirtualizerBase<Element, Element>;

export const Virtualize = function Virtualize({
	selectedIndex,
	children,
	...rest
}: Parameters<typeof useVirtualizer>[0] & {
	selectedIndex?: number;
	children: (virtualizer: Virtualizer) => React.ReactNode;
}) {
	const virtualizer = useVirtualizer(rest);

	React.useEffect(function scrollToIndexOnMount() {
		if (selectedIndex !== undefined) {
			virtualizer.scrollToIndex(selectedIndex);
		}
	}, []);

	return <>{children(virtualizer)}</>;
};
