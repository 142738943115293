import * as React from 'react';
import { observer } from 'mobx-react';
import { Button } from '../../../components/button';

interface DiscardDraftButtonProps {
	canDiscard: boolean;
	className: string;
	discardDraft: () => void;
}

export const DiscardDraftButton = observer(({ canDiscard, className, discardDraft }: DiscardDraftButtonProps) => (
	<Button
		className={`btn-link ${className}`}
		onClick={discardDraft}
		disabled={!canDiscard}
		acceptanceTestTargetId="discard-draft">
		Discard all changes
	</Button>
));
