import * as React from 'react';
import * as styles from './preview-modal.less';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import { BrandingSettingsMainViewModel } from '../../branding-settings-main-view-model';
import { RealTimePreview } from '../real-time-preview/real-time-preview';
import { SvgWrapper } from '../../../components/svg-wrapper';
import { responsiveHelper } from '../../../helpers/responsive-helper';

@observer
export class PreviewModalContent extends React.Component<{ mainViewModel: BrandingSettingsMainViewModel }> {
	closeButtonRef: HTMLButtonElement;
	reactionDisposer: () => void;

	componentDidMount() {
		const { mainViewModel } = this.props;
		this.reactionDisposer = reaction(() => {
			const { isXs, isSm, isMd } = responsiveHelper;
			return { isXs, isSm, isMd };
		}, ({ isXs, isSm, isMd }) => {
			if (isXs) {
				mainViewModel.showMobilePreview = true;
			} else if (isSm) {
				mainViewModel.showMobilePreview = false;
			} else if (isMd) {
				this.closeButtonRef.click();
			}
		}, { fireImmediately: true });
	}

	componentWillUnmount() {
		if (this.reactionDisposer) {
			this.reactionDisposer();
		}
	}

	render() {
		return (
				<div className={`modal modal-form fade`} tabIndex={-1} role="dialog">
					<button type="button" className={styles.closeBtn} data-dismiss="modal" aria-label="Close" ref={ref => this.closeButtonRef = ref}>
						<SvgWrapper svg="ind-cross" className="svg"/>
					</button>
					<RealTimePreview vm={this.props.mainViewModel} />
				</div>
		);
	}
}
