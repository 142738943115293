import * as Models from './transaction-import-generated';
import { observable, action } from 'mobx';
import { AlertProps, AlertType } from './components/alert-control';
import { getTransactionImportDataService, TransactionImportDataServiceAction, TransactionImportApiConfigType, } from './transaction-import-data-service';
import { IDataServiceActionSubscriber } from '../utils/data-service';


export class TransactionImportCreateViewModel {
	allImportTypes: Models.SelectItem[];
	allVisibleListings: Models.SelectItem[];
	cancelUrl: string;

	@observable importType: Models.TransactionImportType;
	@observable merchantId: number;

	@observable alertProps: AlertProps;
	@observable navigationInProgress: boolean = false;

	createImportSubscriber: IDataServiceActionSubscriber<TransactionImportApiConfigType, 'createImport'>;

	constructor(model: Models.TransactionImportCreateViewModel) {
		this.allImportTypes = model.AllImportTypes;
		this.allVisibleListings = model.AllVisibleListings;
		this.cancelUrl = model.CancelUrl;
		this.importType = 0;
		this.merchantId = model.AllVisibleListings.length === 1 ? +model.AllVisibleListings[0].Value : 0;

		this.createImportSubscriber = getTransactionImportDataService().getActionSubscriberFactory('createImport')((action) => this.subscribeToCreateImport(action));
	}

	@action.bound
	showAlert(type: AlertType, content: string | string[]) {
		this.alertProps = {
			alertType: type,
			alertContent: content,
			showCloseButton: true,
			onClose: () => {
				this.alertProps = null;
			}
		};
	}

	@action.bound
	updateImportType(value: string) {
		if ( Models.TransactionImportType[value]) {
			this.importType = Number(value);
		}
	}

	@action.bound
	updateSelectedListing(value: string) {
		const listingId = Number(value);
		if (listingId) {
			this.merchantId = listingId;
		}
	}

	@action.bound
	initCreateImportRequest() {
		this.navigationInProgress = true;
		this.createImportSubscriber.initRequest({
			model: {
				ImportType: this.importType,
				MerchantId: this.merchantId
			}
		});
	}

	@action.bound
	cancelImport() {
		this.navigationInProgress = true;
		window.location.href = this.cancelUrl;
	}

	private subscribeToCreateImport(action: TransactionImportDataServiceAction) {
		switch (action.type) {
			case 'request_success':
				const result =  action.response as Models.TransactionImportOperationResultModel;
				if (result.Result === Models.OperationResult.Success) {
					window.location.href = result.SuccessUrl;
					return;
				} else {
					this.showAlert(AlertType.Danger, result.ErrorMessage);
				}
				break;
			case 'request_error':
				const validationErrors = action.error.validationErrors;
				if (validationErrors) {
					this.showAlert(AlertType.Danger, Object.keys(validationErrors).map(x => validationErrors[x]));
				}
				break;
		}
		this.navigationInProgress = false;
	}
}
