

// ReSharper disable InconsistentNaming
// ReSharper disable StringLiteralWrongQuotes
/* tslint:disable:no-use-before-declare quotemark max-line-length */
import { IApiAction } from '../utils/data-service';
export interface AddMemberResponse {
	MemberInfo: SearchMemberResult;
}
export interface SearchMemberResult {
	DisplayAddress: string;
	EmailAddress: string;
	CommunityMemberId: number;
	CommunityMemberType: CommunityMemberType;
	PrimaryExternalId: string;
	CampusName: string;
	MobileNumber: string;
	FirstName: string;
	LastName: string;
	Address: CommunityAddressModel;
	LockVersion: number;
}
export interface CommunityAddressModel {
	Line1: string;
	Line2: string;
	City: string;
	State: string;
	Postcode: string;
	Country: Country;
	DisplayAddress: string;
	PostalCodeLabel: string;
}
export enum Country {
	NZ = 0, AU = 1, US = 2, AF = 4, AL = 8, AQ = 10, DZ = 12, AS = 16, AD = 20, AO = 24, AG = 28, AZ = 31, AR = 32, AT = 40, BS = 44, BH = 48, BD = 50, AM = 51, BB = 52, BE = 56, BM = 60, BT = 64, BO = 68,
	BA = 70, BW = 72, BV = 74, BR = 76, BZ = 84, IO = 86, SB = 90, VG = 92, BN = 96, BG = 100, MM = 104, BI = 108, BY = 112, KH = 116, CM = 120, CA = 124, CV = 132, KY = 136, CF = 140, LK = 144, TD = 148, CL = 152,
	CN = 156, TW = 158, CX = 162, CC = 166, CO = 170, KM = 174, YT = 175, CG = 178, CD = 180, CK = 184, CR = 188, HR = 191, CU = 192, CY = 196, CZ = 203, BJ = 204, DK = 208, DM = 212, DO = 214, EC = 218, SV = 222,
	GQ = 226, ET = 231, ER = 232, EE = 233, FO = 234, FK = 238, GS = 239, FJ = 242, FI = 246, AX = 248, FR = 250, GF = 254, PF = 258, TF = 260, DJ = 262, GA = 266, GE = 268, GM = 270, PS = 275, DE = 276, GH = 288,
	GI = 292, KI = 296, GR = 300, GL = 304, GD = 308, GP = 312, GU = 316, GT = 320, GN = 324, GY = 328, HT = 332, HM = 334, VA = 336, HN = 340, HK = 344, HU = 348, IS = 352, IN = 356, ID = 360, IR = 364, IQ = 368,
	IE = 372, IL = 376, IT = 380, CI = 384, JM = 388, JP = 392, KZ = 398, JO = 400, KE = 404, KP = 408, KR = 410, KW = 414, KG = 417, LA = 418, LB = 422, LS = 426, LV = 428, LR = 430, LY = 434, LI = 438, LT = 440,
	LU = 442, MO = 446, MG = 450, MW = 454, MY = 458, MV = 462, ML = 466, MT = 470, MQ = 474, MR = 478, MU = 480, MX = 484, MC = 492, MN = 496, MD = 498, ME = 499, MS = 500, MA = 504, MZ = 508, OM = 512, NA = 516,
	NR = 520, NP = 524, NL = 528, CW = 531, AW = 533, SX = 534, BQ = 535, NC = 540, VU = 548, NI = 558, NE = 562, NG = 566, NU = 570, NF = 574, NO = 578, MP = 580, UM = 581, FM = 583, MH = 584, PW = 585, PK = 586,
	PA = 591, PG = 598, PY = 600, PE = 604, PH = 608, PN = 612, PL = 616, PT = 620, GW = 624, TL = 626, PR = 630, QA = 634, RE = 638, RO = 642, RU = 643, RW = 646, BL = 652, SH = 654, KN = 659, AI = 660, LC = 662,
	MF = 663, PM = 666, VC = 670, SM = 674, ST = 678, SA = 682, SN = 686, RS = 688, SC = 690, SL = 694, SG = 702, SK = 703, VN = 704, SI = 705, SO = 706, ZA = 710, ZW = 716, ES = 724, SS = 728, SD = 729, EH = 732,
	SR = 740, SJ = 744, SZ = 748, SE = 752, CH = 756, SY = 760, TJ = 762, TH = 764, TG = 768, TK = 772, TO = 776, TT = 780, AE = 784, TN = 788, TR = 792, TM = 795, TC = 796, TV = 798, UG = 800, UA = 804, MK = 807,
	EG = 818, GB = 826, GG = 831, JE = 832, IM = 833, TZ = 834, VI = 850, BF = 854, UY = 858, UZ = 860, VE = 862, WF = 876, WS = 882, YE = 887, ZM = 894, XK = 999, __ = -2, Unsupported = -1,
}
export enum CommunityMemberType {
	Unknown = 0, Individual = 1, Organization = 2,
}
export interface GetPledgesResponse {
	PledgeTableData: PledgeTableViewModel<PledgeRowWithTotalsViewModel>;
}
export interface PledgeTableViewModel<TPledgeRowViewModel> {
	Cursor: string;
	TotalNumberOfPledges: number;
	Results: TPledgeRowViewModel[];
}
export interface GetPledgeExportRequest {
	CampaignId: number;
}
export interface DeleteRecentPledgeRequest {
	Id: number;
}
export interface DeleteRecentPledgeResponse {
}
export interface DeletePledgeRequest {
	PledgeId: number;
	CampaignId: number;
	Query: string;
	Cursor: string;
	NumberOfRecords: number;
}
export interface DeletePledgeResponse {
	PledgeStatistics: PledgeStatisticsViewModel;
	PledgeTableData: PledgeTableViewModel<PledgeRowWithTotalsViewModel>;
}
export interface PledgeStatisticsViewModel {
	PledgedAmount: number;
	PledgeCount: number;
}
export interface RecentPledgesRequest {
}
export interface RecentPledgesResponse {
	Pledges: PledgeRowViewModel[];
}
export interface PledgeRowViewModel {
	Id: number;
	PledgerName: string;
	PushpayerAccountUrl: string;
	EmailAddress: string;
	CampaignName: string;
	CampaignUrl: string;
	TotalAmount: number;
	CreatedOn: string;
	UpdatedOn: string;
}
export interface GetPledgesRequest {
	CampaignId: number;
	Query: string;
	Cursor: string;
	NumberOfRecords: number;
}
export interface CampaignOverviewResponse {
	PaymentLabel: PaymentLabel;
	PledgeStatistics: PledgeStatisticsViewModel;
	CampaignStatistics: CampaignStatisticsViewModel;
	PledgeTableData: PledgeTableViewModel<PledgeRowWithTotalsViewModel>;
	PledgeExportUrl: string;
}
export interface CampaignStatisticsViewModel {
	CollectedAmount: number;
	RecurringScheduleCount: number;
}
export interface PaymentLabel {
	NounSentenceCase: string;
	NounPluralSentenceCase: string;
	VerbSentenceCase: string;
	VerbAppliedSentenceCase: string;
	NounLowerCase: string;
	NounPluralLowerCase: string;
	VerbLowerCase: string;
	VerbAppliedLowerCase: string;
	VerbPresentTenseSentenceCase: string;
	VerbPresentTenseLowerCase: string;
	VerbPastTenseSentenceCase: string;
	VerbPastTenseLowerCase: string;
	PayerSentenceCase: string;
	PayerLowerCase: string;
	PayerPluralSentenceCase: string;
	PayerPluralLowerCase: string;
	ActionSentenceCase: string;
	ActionLowerCase: string;
	ActionPluralSentenceCase: string;
	ActionPluralLowerCase: string;
	NonCashNounSentenceCase: string;
	NonCashNounLowerCase: string;
	NonCashPluralSentenceCase: string;
	NonCashPluralLowerCase: string;
	TextGivingFeatureName: string;
	GiftEntryFeatureName: string;
}
export interface UpdatePledgeRequest {
	PledgeId: number;
	CommunityMemberId: number;
	Amount: number;
}
export interface AddPledgeRequest {
	CampaignId: number;
	CommunityMemberId: number;
	Amount: number;
}
export interface PledgeAlreadyAddedResponse {
	Type: AddPledgeResponseType.PledgeAlreadyAdded;
	PledgeId: number;
	PledgerName: string;
	PledgerNamePossessive: string;
	PushpayerAccountUrl: string;
	TotalAmount: number;
	PayerLowerCase: string;
}
export enum AddPledgeResponseType {
	Unknown = 0, Success = 1, PledgeAlreadyAdded = 2,
}
export interface AddPledgeSuccessResponse {
	Type: AddPledgeResponseType.Success;
	Pledge: PledgeRowViewModel;
}
export interface EditCommunityMemberRequestModel {
	CommunityMemberId: number;
	FirstName: string;
	LastName: string;
	EmailAddress: string;
	MobileNumber: string;
	Address: CommunityAddressModel;
	LockVersion: number;
	CommunityMemberType: CommunityMemberType;
}
export interface EditMemberResponse {
	UpdatedCommunityMember: SearchMemberResult;
}
export interface MemberSearchRequestModel {
	Query: string;
	Skip: number;
}
export interface SearchMembersResponse {
	HasMorePages: boolean;
	Results: SearchMemberResult[];
}
export interface GetCollectedAmountsRequest {
	UpdatedSince: Date;
}
export interface GetCollectedAmountsPollResponse {
	Type: GetCollectedAmountsResponseType.Poll;
}
export enum GetCollectedAmountsResponseType {
	Unknown = 0, Poll = 1, Success = 2,
}
export interface GetCollectedAmountsSuccessResponse {
	Type: GetCollectedAmountsResponseType.Success;
	CollectedAmounts: CollectedAmount[];
}
export interface CollectedAmount {
	FundKey: string;
	Amount: number;
}
export interface CampaignCloseRequest {
	Id: number;
}
export interface CloseCampaignSuccessResponse {
	Type: CampaignResponseType.Success;
	Campaign: ClosedCampaignCardViewModel;
	RedirectUri: string;
}
export enum CampaignResponseType {
	Unknown = 0, Success = 1, Invalid = 2, ConfirmationRequired = 3,
}
export interface CampaignDeleteRequest {
	Id: number;
}
export interface DeleteCampaignSuccessResponse {
	Type: CampaignResponseType.Success;
}
export interface CampaignPublishRequest {
	Id: number;
}
export interface PublishCampaignSuccessResponse {
	Campaign: BaseCampaignCardViewModel;
}
export interface BaseCampaignCardViewModel {
	Id: number;
	Name: string;
	DuplicateUrl: string;
	OverviewUrl: string;
	GoalAmount: number;
	PledgeLabel: PledgeLabel;
}
export interface PledgeLabel {
	NounSentenceCase: string;
	NounPluralSentenceCase: string;
	NounLowerCase: string;
	NounPluralLowerCase: string;
	VerbSentenceCase: string;
	VerbLowerCase: string;
	VerbPresentTenseSentenceCase: string;
	VerbPresentTenseLowerCase: string;
	VerbPastTenseSentenceCase: string;
	VerbPastTenseLowerCase: string;
}
export interface CampaignEditRequest {
	Id: number;
	CampaignName: string;
	Description: string;
	TaxDeductible: boolean;
	FinancialGoal: number;
	Publish: boolean;
	SelectedListings: number[];
	StartDate: Date;
	EndDate: Date;
	Email: string;
	PhoneNumber: string;
	FundThankYouMessage: string;
	Confirmed: boolean;
}
export interface EditCampaignConfirmationRequiredResponse {
	Type: CampaignResponseType.ConfirmationRequired;
	NewStatus: CampaignStatus;
}
export enum CampaignStatus {
	Unknown = 0, Draft = 1, Published = 2, Closed = 3, Deleted = 4, Scheduled = 5,
}
export interface EditCampaignSuccessResponse {
	Type: CampaignResponseType.Success;
	RedirectUri: string;
}
export interface CampaignCreateRequest {
	CampaignName: string;
	Description: string;
	TaxDeductible: boolean;
	FinancialGoal: number;
	Publish: boolean;
	SelectedListings: number[];
	StartDate: Date;
	EndDate: Date;
	Email: string;
	PhoneNumber: string;
	FundThankYouMessage: string;
}
export interface CreateCampaignSuccessResponse {
	Type: CampaignResponseType.Success;
	RedirectUri: string;
}
export interface GetCampaignsByStatusRequest {
	Statuses: CampaignStatus[];
	Cursor: string;
	NumberOfRecords: number;
}
export interface GetCampaignsSuccessResponse {
	HasNextPage: boolean;
	Campaigns: BaseCampaignCardViewModel[];
}
export interface PledgeRowWithTotalsViewModel {
	Id: number;
	PledgerName: string;
	PushpayerAccountUrl: string;
	EmailAddress: string;
	CampaignName: string;
	CampaignUrl: string;
	TotalAmount: number;
	CreatedOn: string;
	UpdatedOn: string;
	TotalGiven: number;
	Progress: number;
	Remaining: number;
}
export interface PledgeEntryViewModel {
	Campaigns: CampaignViewModel[];
	Listings: ListingsViewModel[];
	DefaultCountry: Country;
	SearchMembersPageSize: number;
	SelectedCampaignId: number;
	CampaignRequired: CampaignRequiredViewModel;
	EnableFeatureOrganizationalGiving: boolean;
	PledgeLabel: PledgeLabel;
}
export interface CampaignRequiredViewModel {
	IsCampaignRequired: boolean;
	CreateCampaignUrl: string;
	CampaignLandingUrl: string;
}
export interface ListingsViewModel {
	Id: number;
	DisplayName: string;
}
export interface CampaignViewModel {
	Id: number;
	DisplayName: string;
}
export interface CampaignOverviewViewModel {
	CampaignId: number;
	CampaignName: string;
	Description: string;
	EditUrl: string;
	Status: CampaignStatus;
	GoalAmount: number;
	FundName: string;
	StartDate: string;
	EndDate: string;
	OpenedFor: string;
	Listings: string[];
	CollectedAmount: number;
	PledgedAmount: number;
	RecurringScheduleCount: number;
	PledgeCount: number;
	PledgeLabel: PledgeLabel;
	ClosedDate: string;
	CreatedDate: string;
	AddPledgeUrl: string;
	BackUrl: string;
}
export interface CampaignsOutageViewModel {
	BackUrl: string;
}
export interface CreateCampaignViewModel {
	FundMappingRequired: boolean;
	BackUrl: string;
	CampaignDetails: CampaignDetailsViewModel;
	Listings: ListingsViewModel[];
	PaymentLabel: PaymentLabel;
	PledgeLabel: PledgeLabel;
}
export interface CampaignDetailsViewModel {
	Id: number;
	Name: string;
	Description: string;
	FinancialGoal: number;
	TaxDeductible: boolean;
	AssignedListings: number[];
	Status: CampaignStatus;
	FundName: string;
	FundUrl: string;
	StartDate: Date;
	EndDate: Date;
	PledgedTotal: number;
	CollectedAmount: number;
	EmailAddress: string;
	PhoneNumber: string;
	FundThankYouMessage: string;
}
export interface CampaignsLandingViewModel {
	CreateCampaignUrl: string;
	HasCampaigns: boolean;
	CampaignSavedNotification: string;
	PollForCollectedAmounts: boolean;
	CollectedAmountsUpdatedSince: Date;
}
export interface ScheduledCampaignCardViewModel {
	Status: CampaignStatus.Scheduled;
	EditUrl: string;
	ShareUrls: CampaignShareUrlViewModel[];
	DonorPledgeEntryUrl: string;
	CollectedAmount: number;
	FundKey: string;
	Starts: string;
	Ends: string;
	Id: number;
	Name: string;
	DuplicateUrl: string;
	OverviewUrl: string;
	GoalAmount: number;
	PledgeLabel: PledgeLabel;
}
export interface CampaignShareUrlViewModel {
	ListingName: string;
	Url: string;
}
export interface ClosedCampaignCardViewModel {
	Status: CampaignStatus.Closed;
	Started: string;
	Ended: string;
	OpenedFor: string;
	CollectedAmount: number;
	FundRequiresExternalSystemMapping: boolean;
	EditFundUrl: string;
	FundKey: string;
	TotalPledged: number;
	EditUrl: string;
	Id: number;
	Name: string;
	DuplicateUrl: string;
	OverviewUrl: string;
	GoalAmount: number;
	PledgeLabel: PledgeLabel;
}
export interface ActiveCampaignCardViewModel {
	Status: CampaignStatus.Published;
	Started: string;
	OpenedFor: string;
	EditUrl: string;
	ShareUrls: CampaignShareUrlViewModel[];
	DonorPledgeEntryUrl: string;
	CollectedAmount: number;
	FundKey: string;
	AddPledgeUrl: string;
	TotalPledged: number;
	Ends: string;
	Id: number;
	Name: string;
	DuplicateUrl: string;
	OverviewUrl: string;
	GoalAmount: number;
	PledgeLabel: PledgeLabel;
}
export interface DraftCampaignCardViewModel {
	Status: CampaignStatus.Draft;
	Created: string;
	EditUrl: string;
	FundRequiresExternalSystemMapping: boolean;
	EditFundUrl: string;
	Starts: string;
	Ends: string;
	Id: number;
	Name: string;
	DuplicateUrl: string;
	OverviewUrl: string;
	GoalAmount: number;
	PledgeLabel: PledgeLabel;
}
export const CampaignsApiConfig = {
	defaultBaseUrl: () => (<RegExpMatchArray>location.pathname.match(new RegExp('/pushpay/portal/\\d+', 'i')))[0],
	actions: {
		getCampaignsByStatus: <IApiAction<{requestInfo: GetCampaignsByStatusRequest}, GetCampaignsSuccessResponse>>{
			url: (data) => `/campaigns/ajax/getByStatus`,
		},
		createCampaign: <IApiAction<{createInfo: CampaignCreateRequest}, CreateCampaignSuccessResponse>>{
			url: (data) => `/campaigns/ajax/create`,
		},
		editCampaign: <IApiAction<{editInfo: CampaignEditRequest}, EditCampaignSuccessResponse | EditCampaignConfirmationRequiredResponse>>{
			url: (data) => `/campaigns/ajax/edit`,
		},
		publishCampaign: <IApiAction<{request: CampaignPublishRequest}, PublishCampaignSuccessResponse>>{
			url: (data) => `/campaigns/ajax/publish`,
		},
		deleteCampaign: <IApiAction<{request: CampaignDeleteRequest}, DeleteCampaignSuccessResponse>>{
			url: (data) => `/campaigns/ajax/delete`,
		},
		closeCampaign: <IApiAction<{request: CampaignCloseRequest}, CloseCampaignSuccessResponse>>{
			url: (data) => `/campaigns/ajax/close`,
		},
		getCollectedAmounts: <IApiAction<{request: GetCollectedAmountsRequest}, GetCollectedAmountsSuccessResponse | GetCollectedAmountsPollResponse>>{
			url: (data) => `/campaigns/ajax/collected-amounts`,
		},
		searchMembers: <IApiAction<{model: MemberSearchRequestModel}, SearchMembersResponse>>{
			url: (data) => `/campaigns/ajax/search-members`,
		},
		editMember: <IApiAction<{model: EditCommunityMemberRequestModel}, EditMemberResponse>>{
			url: (data) => `/campaigns/ajax/edit-member`,
		},
		addPledge: <IApiAction<{request: AddPledgeRequest}, AddPledgeSuccessResponse | PledgeAlreadyAddedResponse>>{
			url: (data) => `/campaigns/ajax/add-pledge`,
		},
		updatePledge: <IApiAction<{request: UpdatePledgeRequest}, AddPledgeSuccessResponse>>{
			url: (data) => `/campaigns/ajax/update-pledge`,
		},
		getCampaignOverview: <IApiAction<{pledgeSearchRequest: GetPledgesRequest}, CampaignOverviewResponse>>{
			url: (data) => `/campaigns/ajax/campaign-overview`,
		},
		getRecentPledges: <IApiAction<{request: RecentPledgesRequest}, RecentPledgesResponse>>{
			url: (data) => `/campaigns/ajax/recent-pledges`,
		},
		deletePledge: <IApiAction<{request: DeletePledgeRequest}, DeletePledgeResponse>>{
			url: (data) => `/campaigns/ajax/delete-pledge`,
		},
		deleteRecentPledge: <IApiAction<{request: DeleteRecentPledgeRequest}, DeleteRecentPledgeResponse>>{
			url: (data) => `/campaigns/ajax/delete-recent-pledge`,
		},
		getPledgeExport: <IApiAction<{request: GetPledgeExportRequest}>>{
			url: (data) => `/campaigns/ajax/pledge-export`,
		},
		getPledgeData: <IApiAction<{searchRequest: GetPledgesRequest}, GetPledgesResponse>>{
			url: (data) => `/campaigns/ajax/pledges`,
		},
		addMember: <IApiAction<{merchantId: number, model: EditCommunityMemberRequestModel}, AddMemberResponse>>{
			url: (data) => `/campaigns/ajax/${data.merchantId}/add-member`,
		},
	},
	urls: {
	},
};
/* tslint:enable:no-use-before-declare quotemark max-line-length */
// ReSharper restore InconsistentNaming
// ReSharper restore StringLiteralWrongQuotes
// ReSharper disable InconsistentNaming
// ReSharper disable StringLiteralWrongQuotes
/* tslint:disable:no-use-before-declare quotemark max-line-length */
export namespace ModelMetadata {
	export class MetadataArrayOf<T> {
		metadata: T;

		constructor(metadata: T) {
			this.metadata = metadata;
		}

		at(index: number): T {
			return this.metadata;
		}
	}

	export interface IPropertyMetadata {
		propertyName: string;
		displayName?: string;
		placeholder?: string;
		validationRules?: { [rule: string]: any };
		modelMetadata?: { [name: string] :IPropertyMetadata};
	}

	export let GetCampaignsByStatusRequest = {
		Statuses: {
			propertyName: "Statuses",
		},
		Cursor: {
			propertyName: "Cursor",
			validationRules: {
				maxlength: {
					errorMessage: "Cursor has exceeded maximum length of '200'.",
					parameters: {
						max: 200,
					},
				},
			},
		},
		NumberOfRecords: {
			propertyName: "NumberOfRecords",
			validationRules: {
				number: {
					errorMessage: "The field NumberOfRecords must be a number.",
				},
				required: {
					errorMessage: "The NumberOfRecords field is required.",
				},
			},
		},
	};
	export let CampaignCreateRequest = {
		CampaignName: {
			propertyName: "CampaignName",
			validationRules: {
				length: {
					errorMessage: "Campaign name must be 50 characters or less",
					parameters: {
						max: 50,
					},
				},
				required: {
					errorMessage: "Please enter a Campaign name",
				},
			},
		},
		Description: {
			propertyName: "Description",
			validationRules: {
				length: {
					errorMessage: "Description must be 500 characters or less",
					parameters: {
						max: 500,
					},
				},
				required: {
					errorMessage: "Please enter a description",
				},
			},
		},
		TaxDeductible: {
			propertyName: "TaxDeductible",
			validationRules: {
				required: {
					errorMessage: "Please select a tax deductible status",
				},
			},
		},
		FinancialGoal: {
			propertyName: "FinancialGoal",
			validationRules: {
				number: {
					errorMessage: "The field FinancialGoal must be a number.",
				},
				range: {
					errorMessage: "Financial goal must be between 0 and $1,000,000,000,000.00",
					parameters: {
						max: 1000000000000.0,
						min: 0.0,
					},
				},
			},
		},
		Publish: {
			propertyName: "Publish",
			validationRules: {
				required: {
					errorMessage: "The Publish field is required.",
				},
			},
		},
		SelectedListings: {
			propertyName: "SelectedListings",
			validationRules: {
				minlength: {
					errorMessage: "The Campaign must be applied to at least one listing",
					parameters: {
						min: 1,
					},
				},
				required: {
					errorMessage: "The Campaign must be applied to at least one listing",
				},
			},
		},
		StartDate: {
			propertyName: "StartDate",
			displayName: "start date",
			validationRules: {
				date: {
					errorMessage: "The field start date must be a date.",
				},
				required: {
					errorMessage: "Please select a start date",
				},
			},
		},
		EndDate: {
			propertyName: "EndDate",
			displayName: "finish date",
			validationRules: {
				date: {
					errorMessage: "The field finish date must be a date.",
				},
			},
		},
		Email: {
			propertyName: "Email",
			displayName: "email address",
			validationRules: {
				length: {
					errorMessage: "Email length exceeds limit",
					parameters: {
						max: 320,
					},
				},
			},
		},
		PhoneNumber: {
			propertyName: "PhoneNumber",
			displayName: "phone number",
			validationRules: {
				length: {
					errorMessage: "Phone number length is invalid",
					parameters: {
						max: 20,
					},
				},
				regex: {
					errorMessage: "The contact phone number is not a valid phone number",
					parameters: {
						pattern: "^\\+?[0-9-() ]{7,20}$",
					},
				},
			},
		},
		FundThankYouMessage: {
			propertyName: "FundThankYouMessage",
			validationRules: {
				length: {
					errorMessage: "Fund thank you message must be 500 characters or less",
					parameters: {
						max: 500,
					},
				},
			},
		},
	};
	export let CampaignEditRequest = {
		Id: {
			propertyName: "Id",
			validationRules: {
				number: {
					errorMessage: "The field Id must be a number.",
				},
				required: {
					errorMessage: "The Id field is required.",
				},
			},
		},
		CampaignName: {
			propertyName: "CampaignName",
			validationRules: {
				length: {
					errorMessage: "Campaign name must be 50 characters or less",
					parameters: {
						max: 50,
					},
				},
				required: {
					errorMessage: "Please enter a Campaign name",
				},
			},
		},
		Description: {
			propertyName: "Description",
			validationRules: {
				length: {
					errorMessage: "Description must be 500 characters or less",
					parameters: {
						max: 500,
					},
				},
				required: {
					errorMessage: "Please enter a description",
				},
			},
		},
		TaxDeductible: {
			propertyName: "TaxDeductible",
			validationRules: {
				required: {
					errorMessage: "Please select a tax deductible status",
				},
			},
		},
		FinancialGoal: {
			propertyName: "FinancialGoal",
			validationRules: {
				number: {
					errorMessage: "The field FinancialGoal must be a number.",
				},
				range: {
					errorMessage: "Financial goal must be between 0 and $1,000,000,000,000.00",
					parameters: {
						max: 1000000000000.0,
						min: 0.0,
					},
				},
			},
		},
		Publish: {
			propertyName: "Publish",
			validationRules: {
				required: {
					errorMessage: "The Publish field is required.",
				},
			},
		},
		SelectedListings: {
			propertyName: "SelectedListings",
			validationRules: {
				minlength: {
					errorMessage: "The Campaign must be applied to at least one listing",
					parameters: {
						min: 1,
					},
				},
				required: {
					errorMessage: "The Campaign must be applied to at least one listing",
				},
			},
		},
		StartDate: {
			propertyName: "StartDate",
			displayName: "start date",
			validationRules: {
				date: {
					errorMessage: "The field start date must be a date.",
				},
				required: {
					errorMessage: "Please select a start date",
				},
			},
		},
		EndDate: {
			propertyName: "EndDate",
			displayName: "finish date",
			validationRules: {
				date: {
					errorMessage: "The field finish date must be a date.",
				},
			},
		},
		Email: {
			propertyName: "Email",
			displayName: "email address",
			validationRules: {
				length: {
					errorMessage: "Email length exceeds limit",
					parameters: {
						max: 320,
					},
				},
			},
		},
		PhoneNumber: {
			propertyName: "PhoneNumber",
			displayName: "phone number",
			validationRules: {
				length: {
					errorMessage: "Phone number length is invalid",
					parameters: {
						max: 20,
					},
				},
				regex: {
					errorMessage: "The contact phone number is not a valid phone number",
					parameters: {
						pattern: "^\\+?[0-9-() ]{7,20}$",
					},
				},
			},
		},
		FundThankYouMessage: {
			propertyName: "FundThankYouMessage",
			validationRules: {
				length: {
					errorMessage: "Fund thank you message must be 500 characters or less",
					parameters: {
						max: 500,
					},
				},
			},
		},
		Confirmed: {
			propertyName: "Confirmed",
			validationRules: {
				required: {
					errorMessage: "The Confirmed field is required.",
				},
			},
		},
	};
	export let CampaignPublishRequest = {
		Id: {
			propertyName: "Id",
			validationRules: {
				number: {
					errorMessage: "The field Id must be a number.",
				},
				required: {
					errorMessage: "The Id field is required.",
				},
			},
		},
	};
	export let CampaignDeleteRequest = {
		Id: {
			propertyName: "Id",
			validationRules: {
				number: {
					errorMessage: "The field Id must be a number.",
				},
				required: {
					errorMessage: "The Id field is required.",
				},
			},
		},
	};
	export let CampaignCloseRequest = {
		Id: {
			propertyName: "Id",
			validationRules: {
				number: {
					errorMessage: "The field Id must be a number.",
				},
				required: {
					errorMessage: "The Id field is required.",
				},
			},
		},
	};
	export let GetCollectedAmountsRequest = {
		UpdatedSince: {
			propertyName: "UpdatedSince",
			validationRules: {
				date: {
					errorMessage: "The field UpdatedSince must be a date.",
				},
				required: {
					errorMessage: "The UpdatedSince field is required.",
				},
			},
		},
	};
	export let MemberSearchRequestModel = {
		Query: {
			propertyName: "Query",
			validationRules: {
				length: {
					errorMessage: "The field Query must be a string with a maximum length of 100.",
					parameters: {
						max: 100,
					},
				},
			},
		},
		Skip: {
			propertyName: "Skip",
			validationRules: {
				number: {
					errorMessage: "The field Skip must be a number.",
				},
				range: {
					errorMessage: "The field Skip must be between 0 and 2147483647.",
					parameters: {
						max: 2147483647,
						min: 0,
					},
				},
				required: {
					errorMessage: "The Skip field is required.",
				},
			},
		},
	};
	export let EditCommunityMemberRequestModel = {
		CommunityMemberId: {
			propertyName: "CommunityMemberId",
			validationRules: {
				number: {
					errorMessage: "The field CommunityMemberId must be a number.",
				},
				required: {
					errorMessage: "The CommunityMemberId field is required.",
				},
			},
		},
		FirstName: {
			propertyName: "FirstName",
			displayName: "First name",
			validationRules: {
				length: {
					errorMessage: "The field First name must be a string with a maximum length of 100.",
					parameters: {
						max: 100,
					},
				},
				required: {
					errorMessage: "The First name field is required.",
				},
			},
		},
		LastName: {
			propertyName: "LastName",
			displayName: "Last name",
			validationRules: {
				length: {
					errorMessage: "The field Last name must be a string with a maximum length of 100.",
					parameters: {
						max: 100,
					},
				},
			},
		},
		EmailAddress: {
			propertyName: "EmailAddress",
			displayName: "Email",
			validationRules: {
				email: {
					errorMessage: "The Email field is not a valid e-mail address.",
				},
				length: {
					errorMessage: "The field Email must be a string with a maximum length of 100.",
					parameters: {
						max: 100,
					},
				},
			},
		},
		MobileNumber: {
			propertyName: "MobileNumber",
			displayName: "Mobile",
			validationRules: {
				regex: {
					errorMessage: "The Mobile field is not a valid mobile number",
					parameters: {
						pattern: "^\\+?[0-9-() ]{7,20}$",
					},
				},
				skipsanitization: {},
			},
		},
		Address: {
			propertyName: "Address",
			displayName: "Mailing address",
		},
		LockVersion: {
			propertyName: "LockVersion",
			validationRules: {
				number: {
					errorMessage: "The field LockVersion must be a number.",
				},
				range: {
					errorMessage: "The field LockVersion must be between 0 and 2147483647.",
					parameters: {
						max: 2147483647,
						min: 0,
					},
				},
				required: {
					errorMessage: "The LockVersion field is required.",
				},
			},
		},
		CommunityMemberType: {
			propertyName: "CommunityMemberType",
			validationRules: {
				required: {
					errorMessage: "The CommunityMemberType field is required.",
				},
			},
		},
	};
	export let AddPledgeRequest = {
		CampaignId: {
			propertyName: "CampaignId",
			validationRules: {
				number: {
					errorMessage: "The field CampaignId must be a number.",
				},
				required: {
					errorMessage: "Please select a Campaign",
				},
			},
		},
		CommunityMemberId: {
			propertyName: "CommunityMemberId",
			validationRules: {
				number: {
					errorMessage: "The field CommunityMemberId must be a number.",
				},
				required: {
					errorMessage: "Please select a community member",
				},
			},
		},
		Amount: {
			propertyName: "Amount",
			validationRules: {
				number: {
					errorMessage: "The field Amount must be a number.",
				},
				range: {
					errorMessage: "Pledge amount must be between one cent and $1,000,000,000,000.00",
					parameters: {
						max: 1000000000000.0,
						min: 0.01,
					},
				},
				required: {
					errorMessage: "Please enter an amount",
				},
			},
		},
	};
	export let UpdatePledgeRequest = {
		PledgeId: {
			propertyName: "PledgeId",
			validationRules: {
				number: {
					errorMessage: "The field PledgeId must be a number.",
				},
				required: {
					errorMessage: "The PledgeId field is required.",
				},
			},
		},
		CommunityMemberId: {
			propertyName: "CommunityMemberId",
			validationRules: {
				number: {
					errorMessage: "The field CommunityMemberId must be a number.",
				},
				required: {
					errorMessage: "Please select a community member",
				},
			},
		},
		Amount: {
			propertyName: "Amount",
			validationRules: {
				number: {
					errorMessage: "The field Amount must be a number.",
				},
				range: {
					errorMessage: "Pledge amount must be between one cent and $1,000,000,000,000.00",
					parameters: {
						max: 1000000000000.0,
						min: 0.01,
					},
				},
				required: {
					errorMessage: "Please enter an amount",
				},
			},
		},
	};
	export let GetPledgesRequest = {
		CampaignId: {
			propertyName: "CampaignId",
			validationRules: {
				number: {
					errorMessage: "The field CampaignId must be a number.",
				},
				required: {
					errorMessage: "The CampaignId field is required.",
				},
			},
		},
		Query: {
			propertyName: "Query",
			validationRules: {
				length: {
					errorMessage: "The field Query must be a string with a maximum length of 100.",
					parameters: {
						max: 100,
					},
				},
			},
		},
		Cursor: {
			propertyName: "Cursor",
			validationRules: {
				length: {
					errorMessage: "The field Cursor must be a string with a maximum length of 100.",
					parameters: {
						max: 100,
					},
				},
			},
		},
		NumberOfRecords: {
			propertyName: "NumberOfRecords",
			validationRules: {
				number: {
					errorMessage: "The field NumberOfRecords must be a number.",
				},
				range: {
					errorMessage: "The field NumberOfRecords must be between 0 and 2147483647.",
					parameters: {
						max: 2147483647,
						min: 0,
					},
				},
				required: {
					errorMessage: "The NumberOfRecords field is required.",
				},
			},
		},
	};
	export let RecentPledgesRequest = {
	};
	export let DeletePledgeRequest = {
		PledgeId: {
			propertyName: "PledgeId",
			validationRules: {
				number: {
					errorMessage: "The field PledgeId must be a number.",
				},
				required: {
					errorMessage: "The PledgeId field is required.",
				},
			},
		},
		CampaignId: {
			propertyName: "CampaignId",
			validationRules: {
				number: {
					errorMessage: "The field CampaignId must be a number.",
				},
				required: {
					errorMessage: "The CampaignId field is required.",
				},
			},
		},
		Query: {
			propertyName: "Query",
			validationRules: {
				length: {
					errorMessage: "The field Query must be a string with a maximum length of 100.",
					parameters: {
						max: 100,
					},
				},
			},
		},
		Cursor: {
			propertyName: "Cursor",
			validationRules: {
				length: {
					errorMessage: "The field Cursor must be a string with a maximum length of 100.",
					parameters: {
						max: 100,
					},
				},
			},
		},
		NumberOfRecords: {
			propertyName: "NumberOfRecords",
			validationRules: {
				number: {
					errorMessage: "The field NumberOfRecords must be a number.",
				},
				range: {
					errorMessage: "The field NumberOfRecords must be between 0 and 2147483647.",
					parameters: {
						max: 2147483647,
						min: 0,
					},
				},
				required: {
					errorMessage: "The NumberOfRecords field is required.",
				},
			},
		},
	};
	export let DeleteRecentPledgeRequest = {
		Id: {
			propertyName: "Id",
			validationRules: {
				number: {
					errorMessage: "The field Id must be a number.",
				},
				required: {
					errorMessage: "The Id field is required.",
				},
			},
		},
	};
	export let GetPledgeExportRequest = {
		CampaignId: {
			propertyName: "CampaignId",
			validationRules: {
				number: {
					errorMessage: "The field CampaignId must be a number.",
				},
				required: {
					errorMessage: "The CampaignId field is required.",
				},
			},
		},
	};
}
/* tslint:enable:no-use-before-declare quotemark max-line-length */
// ReSharper restore InconsistentNaming
// ReSharper restore StringLiteralWrongQuotes
