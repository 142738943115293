import * as React from 'react';
import {SortOrder} from './sort-order';

export interface IDropdownProps {
	sortOrder: SortOrder;
	sortOrderChanged: (selectedValue: SortOrder) => void;
}

export class FundListDropdown extends React.Component<IDropdownProps, {}> {

	choices = [
		SortOrder.AToZ,
		SortOrder.ZToA,
		SortOrder.LowToHigh,
		SortOrder.HighToLow
	];

	renderChoice(choice: SortOrder) {
		return (<option value={`${choice}`}>{SortOrder.toPrettyString(choice)}</option>);
	}

	sortOrderChange(event: React.FormEvent<HTMLSelectElement>) {
		this.props.sortOrderChanged(parseInt(event.currentTarget.value) as SortOrder);
	}

	render() {
		return (
			<div className="select-wrapper">
				<select className="form-control" onChange={(e) => this.sortOrderChange(e)} value={`${this.props.sortOrder}`}>
						{this.choices.map((x) => this.renderChoice(x)) }
					</select>
				</div>
			);
	}
}
