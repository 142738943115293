import { MachineConfig } from 'xstate/lib/types';
import { Actions, Events, States } from './create-campaign-states-events-and-actions';

export const getCreateCampaignConfig = (): MachineConfig => {
	return {
		key: 'Create Campaign',
		initial: States.Init,
		states: {
			[States.Init]: {
				onEntry: Actions.Start,
				on: {
					[Events.StartCreating]: States.Creating,
					[Events.StartEditing]: States.Editing,
				},
			},
			[States.Creating]: {
				initial: States.Idle,
				states: {
					[States.Idle]: {
						on: {
							[Events.Save]: {
								[States.ProcessingSave]: { actions: [Actions.CreateCampaign] },
							},
							[Events.SaveAndPublish]: {
								[States.ProcessingSaveAndPublish]: { actions: [Actions.CreateCampaign] },
							}
						},
					},
					[States.ProcessingSave]: { on: ErrorEvent() },
					[States.ProcessingSaveAndPublish]: { on: ErrorEvent() },
					...ErrorState(),
				},
				on: {
					[Events.ProcessingSuccessful]: States.Completed,
				}
			},
			[States.Editing]: {
				initial: States.Idle,
				states: {
					[States.Idle]: {
						on: {
							[Events.Save]: {
								[States.ProcessingSave]: { actions: [Actions.UpdateCampaign] },
							},
							[Events.SaveAndPublish]: {
								[States.ProcessingSaveAndPublish]: { actions: [Actions.UpdateCampaign] },
							},
							[Events.CloseCampaign]: {
								[States.ProcessingCloseCampaign]: { actions: [Actions.CloseCampaign] },
							},
						},
					},
					[States.ProcessingSave]: {
						on: {
							...ErrorEvent(),
							[Events.RequireConfirmation]: States.WaitingForConfirmation,
						}
					},
					[States.WaitingForConfirmation]: {
						on: {
							[Events.Save]: {
								[States.ProcessingSave]: { actions: [Actions.UpdateCampaign] },
							},
							[Events.CancelSaving]: States.Idle,
						}
					},
					[States.ProcessingSaveAndPublish]: { on: ErrorEvent() },
					[States.ProcessingCloseCampaign]: { on: ErrorEvent() },
					...ErrorState(),
				},
				on: {
					[Events.ProcessingSuccessful]: States.Completed,
				}
			},
			[States.Completed]: {
				onEntry: Actions.RedirectToLandingPage,
			},
		}
	};
};

const ErrorEvent = () => ({
	[Events.ValidationError]: States.Idle,
	[Events.ProcessingError]: States.Error,
});

const ErrorState = () => ({
	[States.Error]: { on: { [Events.CloseErrorModal]: States.Idle } },
});
