import * as React from 'react';

export const ModalDialogWrapper = ({ children }: { children: React.ReactNode }) => (
	<div className="modal-container">
		<div className="modal-backdrop fade in" />
		<div role="dialog" className="modal fade visible in">
			<div className="modal-dialog" role="document" data-pp-at-target="Modal">
				{children}
			</div>
		</div>
	</div>
);
