import { observable, action, computed } from 'mobx';
import { Channel } from '../../../../Shared/utils/user-action-channel';
import {
	BatchListViewModel,
	BatchStatus,
	SortOrder,
	BatchQueryRequestModel,
	MultiSelectItem,
	BatchListItemViewModel
} from '../../virtual-terminal-generated';
import { BatchListUserAction } from './batch-list-user-actions';
import { Debouncer } from '../../../utils/debouncer';
import { BatchListItemProps } from '../../components/batch-list/batch-list-item/batch-list-item-props';

const defaultQueryModel: BatchQueryRequestModel = {
	Page: 0,
	TakeAllUpToCurrentPage: false,
	Status: BatchStatus.Open,
	SortBy: SortOrder.CreatedOnDesc,
	MerchantListingIds: [],
	Text: '',
	EventTimeIds: [],
};

export interface IBatchesBasedOn {
	numBatches: number;
	searchTerm: string;
	selectedListings: string[];
	selectedServiceTimes: number[];
}

export class BatchListMainViewModel {
	userActionChannel: Channel<BatchListUserAction> = null;
	defaultQueryModel: BatchQueryRequestModel;
	queryBatchesAllDebouncer: Debouncer<void>;

	@observable
	batches: BatchListItemProps[] = [];

	@observable
	batchesBasedOn: IBatchesBasedOn = {
		numBatches: 0,
		searchTerm: '',
		selectedListings: [],
		selectedServiceTimes: [],
	};

	@observable
	selectedListingIds: string[] = [];

	@observable
	selectedServiceTimeIds: number[] = [];

	@computed
	get selectedServiceTimeIdsAsStrings(): string[] {
		return this.selectedServiceTimeIds.map(x => x.toString());
	}

	@observable
	showingBatchStatus: BatchStatus = BatchStatus.Open;

	@observable
	canLoadMore: boolean = false;

	@observable
	searchTerm: string = '';

	@observable
	sortBy: SortOrder = SortOrder.CreatedOnDesc;

	@observable
	page: number = 0;

	@observable
	requestInProgress: boolean = false;

	@computed
	get selectedBatchCount() : number {
		return this.batches.filter(x => x.isSelected).length;
	}

	@computed
	get hasSelectedBatchesToBeDeposited(): boolean {
		return this.showingBatchStatus === BatchStatus.Completed
			&& this.batches.some(x => x.isSelected);
	}

	@computed
	get queryModel(): BatchQueryRequestModel {
		return {
			Page: this.page,
			TakeAllUpToCurrentPage: false,
			Status: this.showingBatchStatus,
			SortBy: this.sortBy,
			MerchantListingIds: this.selectedListingIds.length > 0
				? this.selectedListingIds.map((listingId: string) => parseInt(listingId, 10))
				: this.viewData.Listings.map((listing: MultiSelectItem) => parseInt(listing.Value)),
			Text: this.searchTerm,
			EventTimeIds: this.selectedServiceTimeIds.length > 0
				? this.selectedServiceTimeIds
				: null,
		};
	}

	get hasQuickBooks(): boolean  {
		return this.viewData.HasQuickBooks;
	}

	constructor(public viewData: BatchListViewModel) {
		this.queryBatchesAllDebouncer = new Debouncer<void>(60000, this.pollBatches);
	}

	pollBatches = () => {
		this.raiseAction(new BatchListUserAction.QueryBatches({ takeAllUpToCurrentPage: true }));
	}

	@action.bound
	raiseAction(action: BatchListUserAction) {
		this.userActionChannel.put(action);
	}

	@action.bound
	changeTabAndResetFilters(batchStatus: BatchStatus) {
		const { Page, MerchantListingIds, EventTimeIds, SortBy, Text } = defaultQueryModel;

		this.showingBatchStatus = batchStatus;
		this.page = Page;
		this.selectedListingIds = MerchantListingIds.map((id: number) => `${id}`);
		this.selectedServiceTimeIds = EventTimeIds;
		this.sortBy = SortBy;
		this.searchTerm = Text;
	}

	@action.bound
	updateBatches(batches: BatchListItemViewModel[], totalBatchCount: number, append: boolean) {
		this.batches = append
			? [...this.batches,
				...batches.map(x => new BatchListItemProps(x, this.hasQuickBooks, this.allBatchesSelectedToBeDeposited))]
			: batches.map(x => new BatchListItemProps(x, this.hasQuickBooks, this.allBatchesSelectedToBeDeposited));
		this.batchesBasedOn = {
			selectedServiceTimes: this.selectedServiceTimeIds,
			selectedListings: this.selectedListingIds,
			numBatches: totalBatchCount,
			searchTerm: this.searchTerm,
		};
	}

	// FeatureFlagWillMakeThisCodeObsolete(BatchEntry_QBO)
	@action.bound
	updateBatchesOld(batches: BatchListItemViewModel[], append: boolean) {
		this.batches = append
			? [...this.batches,
				...batches.map(x => new BatchListItemProps(x, this.hasQuickBooks, this.allBatchesSelectedToBeDeposited))]
			: batches.map(x => new BatchListItemProps(x, this.hasQuickBooks, this.allBatchesSelectedToBeDeposited));
		this.batchesBasedOn = {
			selectedServiceTimes: this.selectedServiceTimeIds,
			selectedListings: this.selectedListingIds,
			numBatches: this.batches.length,
			searchTerm: this.searchTerm,
		};
	}

	@action.bound
	setUserActionChannel(channel: Channel<BatchListUserAction>) {
		this.userActionChannel = channel;
	}

	@action.bound
	changeSort(sortBy: SortOrder) {
		this.sortBy = sortBy;
	}

	@action.bound
	changeSearch(searchTerm: string) {
		this.searchTerm = searchTerm;
	}

	@action.bound
	changeSelectedListings(selectedListingIds: string[]) {
		this.selectedListingIds = selectedListingIds;
	}

	@action.bound
	changeSelectedServiceTimes(selectedServiceTimeIds: number[]) {
		this.selectedServiceTimeIds = selectedServiceTimeIds;
	}

	@action.bound
	updateCanLoadMore(canLoadMore: boolean) {
		this.canLoadMore = canLoadMore;
	}

	@action.bound
	nextPage() {
		this.page++;
	}

	@action.bound
	resetPage() {
		this.page = 0;
	}

	@action.bound
	setRequestInProgress(requestInProgress: boolean) {
		this.requestInProgress = requestInProgress;
	}

	@computed
	get allBatchesSelectedToBeDeposited() {
		const filteredBatches = this.batches.filter(x => x.vm.BatchStatus === BatchStatus.Completed);
		return filteredBatches.length > 0
			&& filteredBatches
				.every(x => x.isSelected);
	}

	@action
	selectAllLoadedBatches = (state: boolean) => {
		this.batches.forEach(x => x.setIsSelected(state));
	}

	@action
	depositSelectedBatches = () => {
		this.raiseAction(new BatchListUserAction.DepositBatches(
			this.batches
				.filter(x => x.isSelected)
				.map(x => x.vm.BatchId))
		);
	}
}
