import * as React from 'react';
import { action, computed, observable, reaction } from 'mobx';
import { observer } from 'mobx-react';
import { Button } from '../../components/button';
import { SvgWrapper } from '../../components/svg-wrapper';
import { TooltipComponent } from '../../components/tooltip-component';
import { Autocomplete, AutocompleteStore, IAutocompleteItem } from '../../components/autocomplete';
import { ModalDialogCommander } from '../../components/modal-dialog-commander';
import { AddNewFundStore } from './modal-add-new-fund-dialog';
import { ModalDialog } from '../../components/modal-dialog';
import { Affix, AffixPosition } from './affix';
import { CloseFundButton } from './common-close-fund-button';
import { SmoothHeightTransition } from '../../components/hoc-behavior/transitions';
import { isValidationError, isValidationErrorModel, makeAjaxCall } from '../utils/ajax-helper';
import { Form, InputField, ModelForArrayItem, ValidationMessage } from './form-controls';
import { ValidationSummary } from '../../components/form-controls/validation-summary';
import { EnableIntegrationModalDialog } from '../../integrations/components/enable-integration-dialog';
import getIntegrationFundsForDisplay from '../../integrations/helpers/get-integration-funds-for-display';
import { responsiveHelper } from '../../../Shared/helpers/responsive-helper';

import {
	DeprecatedPagination,
} from "@pushpay/pagination";

import { ModelMetadata, ResponseTypes } from '../funds-generated';
import ImportFundsViewModel = ResponseTypes.ImportFundsViewModel;
import ImportPushpayFund = ResponseTypes.ImportPushpayFund;
import ImportFundMapping = ResponseTypes.ImportFundMapping;
import ImportFundPaginationInfo = ResponseTypes.ImportFundPaginationInfo;
import { LoggedInWebThemeProvider } from '../../components/theming';

export class ImportFundsStore {
	importUrl: string;
	integrationName: string;
	integrationIsThirdParty: boolean;
	referrer: string;
	useVirtualizedDropdown: boolean;
	enablePagination: boolean;
	paginationInfo: ImportFundPaginationInfo;

	@observable isProcessingRequest: boolean;
	@observable fundMappingItems: ImportFundMappingItem[];
	@observable integrationFundItems: IAutocompleteItem[];
	@observable addNewFundStore: AddNewFundStore;
	@observable validationErrors: string[];
	@observable searchTerm: string;

	constructor(initialData: ImportFundsViewModel) {
		this.importUrl = initialData.ImportUrl;
		this.referrer = initialData.Referrer;
		this.integrationName = initialData.IntegrationName;
		this.integrationIsThirdParty = initialData.IntegrationViewModel != null && initialData.IntegrationViewModel.IsThirdParty;
		this.integrationFundItems = getIntegrationFundsForDisplay(initialData.IntegrationFunds);
		this.useVirtualizedDropdown = initialData.UseVirtualizedDropdowns;

		this.enablePagination = initialData.EnablePagination;
		this.paginationInfo = initialData.PaginationInfo;

		this.fundMappingItems = initialData.FundMappings.map(mapping =>
			new ImportFundMappingItem(mapping.PushpayFund, mapping.IntegrationFund && mapping.IntegrationFund.Key, this.integrationFundItems));

		this.addNewFundStore = new AddNewFundStore(initialData.AddNewFundUrl,
			initialData.IntegrationFunds,
			initialData.ExternalSystemId,
			this.integrationName,
			initialData.FundCodePlaceholderText,
			initialData.PayerLabel,
			initialData.IntegrationFundsInfo,
			initialData.QuickBooksManageFundInfo);

		reaction(this.whenFundAdded, this.addNewFundItem);
	}

	@computed
	get displayMappings() {
		return this.fundMappingItems.orderBy(x => x.pushpayFund.Name.toLowerCase()).toArray();
	}

	@action
	import = () => {
		this.isProcessingRequest = true;
		makeAjaxCall(this.importUrl, this.importFundMappings)
			.fail((error: any) => {
				this.isProcessingRequest = false;

				if (isValidationErrorModel(error)) {
					this.validationErrors = error.ValidationErrors.map(x => x.ErrorMessage);
					return;
				}
				const errorMessage = isValidationError(error)
					? error.ErrorMessage
					: 'There was a problem saving your changes. Please try again.';
				ModalDialogCommander.showReactDialog(this.importErrorDialog(errorMessage));
			}).done((response: any) => {
				ModalDialogCommander.showReactDialog(<EnableIntegrationModalDialog actionUrl={response.EnableIntegrationActionUrl} integrationIsThirdParty={this.integrationIsThirdParty} />);
			});
	}

	@action
	closeFund = (fundMappingItem: ImportFundMappingItem) => {
		this.fundMappingItems.splice(this.fundMappingItems.indexOf(fundMappingItem), 1);
		return jQuery.Deferred().resolve().promise();
	}

	@computed
	private get importFundMappings() {
		return { FundMappings: this.fundMappingItems.map(x => x.toFundMapping).filter(x => x.IntegrationFundKey !== null) };
	}

	private whenFundAdded = () => {
		return this.addNewFundStore.pushpayFund;
	}

	private addNewFundItem = (pushpayFund: ImportPushpayFund): void => {
		if (!pushpayFund) {
			return;
		}
		const importFundMappingItem = new ImportFundMappingItem(pushpayFund,
			this.addNewFundStore.integrationFundsAutocomplete.selectedValue,
			this.integrationFundItems);
		this.fundMappingItems.push(importFundMappingItem);
	}

	private importErrorDialog = (errorMessage: string) => <ModalDialog title="Whoops, something went wrong">{errorMessage}</ModalDialog>;
}

export let ImportFundsErrorDialog = (message?: string) => (
	<ModalDialog title="Whoops, something went wrong">
		{
			message || 'There was a problem loading the funds data.\u00a0Please try again.'
		}
	</ModalDialog>);


@observer
export class PaginatedImportFundsPanel extends React.Component<{ importFundsStore: ImportFundsStore }, {}> {
	render() {
		const { paginationInfo, useVirtualizedDropdown } = this.props.importFundsStore; // FF UseVirtualizedDropdownComponentInLoggedInWeb
		const hideVirtualisedDropDown = useVirtualizedDropdown && responsiveHelper.isXs;
		const { CurrentPage, TotalItemCount, PageSize } = paginationInfo;

		return (
			<LoggedInWebThemeProvider>
				<div className="import-funds">
					<Form onSubmit={this.store.import}>
						<ValidationSummary validationErrors={this.store.validationErrors} />
						{hideVirtualisedDropDown ? (
							<div className="alert alert-danger alert-integration-warning">
								<div className="alert-body">
									<span className="alert-title">
										Sorry, fund mapping is not available on mobile devices
									</span>
									{`Mapping of your Pushpay funds to your ${this.store.integrationName} funds is only available on desktop devices.`}
								</div>
							</div>
						) : (
							<>
								{this.renderSearch()}
								<div className="panel panel-default">
									{this.renderHeader()}
									<SmoothHeightTransition className="import-funds-list" component="ul">
										{this.store.displayMappings.map((mapping, index) => (
											<ImportFundsRow
												key={mapping.pushpayFund.Key}
												fundMapping={mapping}
												index={index}
												importFundsStore={this.store}
											/>
										))}
									</SmoothHeightTransition>
									<div className="panel-footer panel-footer-btn-group">
										<a href={this.store.referrer} className="btn btn-cancel">
											Cancel
										</a>
										<Button
											isProcessingRequest={this.store.isProcessingRequest}
											className="btn btn-default"
											type="submit"
										>
											Save
										</Button>
									</div>
								</div>
							</>
						)}
					</Form>
					{this.showPagination && (
						<DeprecatedPagination
							className="import-funds-pagination"
							data-pp-at-target="import-funds-paging"
							totalCount={TotalItemCount}
							currentPage={CurrentPage}
							pageSize={PageSize}
							onSelectPage={this.selectPage}
							onPageJump={this.selectPage}
							pageLinkTitles={{
								nextPage: ">",
								previousPage: "<",
							}}
						/>
					)}
				</div>
			</LoggedInWebThemeProvider>
		);
	}
	renderHeader() {
		return (
			<Affix affixPosition={AffixPosition.Top}>
				<div className="panel-heading import-funds-header">
					<div className="header">
						<div className="col-sm-4">
							Pushpay funds
							<TooltipComponent message={`These are the funds that are visible to your givers.
If a Pushpay fund doesn't have an equivalent in ${this.store.integrationName}, it can be closed.`} />
						</div>
						<div className="col-sm-8 hidden-xs">
							{this.store.integrationName} funds
							<TooltipComponent message={`These are the funds within ${this.store.integrationName}. Pushpay transactions
will be assigned to the matching fund when we generate the contribution in ${this.store.integrationName}.`} />
						</div>
					</div>
					<div className="sticky-header">
						<span className="sticky-label">Import funds</span>
						<div className="responsive-btn-group">
							<button type="button" className="btn btn-link btn-sm" onClick={this.launch}>
								<SvgWrapper svg="icon-add" className="svg icon" />
								Add new fund
							</button>
							<a href={this.store.referrer} className="btn btn-ghost hidden-xs">Cancel</a>
							<Button className="btn btn-default" type="submit" isProcessingRequest={this.store.isProcessingRequest}>
								Save
							</Button>
						</div>
					</div>
				</div>
			</Affix>
		);
	}
	renderSearch() {
		const { searchTerm } = this.store;
		return (
			<div className="import-funds-search-panel">
				<div className="import-funds-search-block">
					<div className="import-funds-search-container">
						<input type="text" placeholder="Search funds" onChange={this.onSearchTermChanged} value={searchTerm} className="import-funds-search-input" />
						<button className="import-funds-search-button" onClick={this.onSearchSubmit} type="button"><SvgWrapper svg="icon-search" className="import-funds-search-icon" /></button>
					</div>
				</div>
			</div>
		);
	}
	private launch = (ev: React.MouseEvent<HTMLButtonElement>) => {
		ev.preventDefault();
		this.store.addNewFundStore.launch();
	}
	private get store() {
		return this.props.importFundsStore;
	}
	private get showPagination() {
		return this.store.paginationInfo.TotalItemCount > this.store.paginationInfo.PageSize;
	}
	@action.bound
	private async selectPage(pageNumber: number) {
		this.store.paginationInfo.CurrentPage = pageNumber;

		makeAjaxCall(this.store.paginationInfo.PaginationUrl, { PaginationInfo: this.store.paginationInfo })
			.fail((response) => this.onDataRetrievalError(response.ErrorMessage))
			.done((response: ResponseTypes.ImportFundsViewModel) => this.onDataRetrievalSuccess(response));
	};
	@action.bound
	private onDataRetrievalSuccess(data: ResponseTypes.ImportFundsViewModel) {
		this.store.fundMappingItems = data.FundMappings.map(mapping =>
			new ImportFundMappingItem(mapping.PushpayFund, mapping.IntegrationFund && mapping.IntegrationFund.Key, this.store.integrationFundItems));
		this.store.paginationInfo = data.PaginationInfo;
	}
	private onDataRetrievalError(message?: string) {
		ModalDialogCommander.showReactDialog(ImportFundsErrorDialog(message));
	}
	@action.bound
	private onSearchSubmit(e: React.MouseEvent<HTMLButtonElement>) {
		const { paginationInfo } = this.store;
		const firstPage = 1;
		paginationInfo.CurrentPage = firstPage;
		this.selectPage(firstPage);
	}
	@action.bound
	private onSearchTermChanged(e: React.ChangeEvent<HTMLInputElement>) {
		this.store.paginationInfo.SearchTerm = e.currentTarget.value;
	}
}

export class ImportFundMappingItem {
	pushpayFund: ImportPushpayFund;
	initialSelectedValue: string;
	autocompleteStore: AutocompleteStore;

	constructor(pushpayFund: ImportPushpayFund, selectedValue: string, dropListItems: IAutocompleteItem[]) {
		this.pushpayFund = pushpayFund;
		this.initialSelectedValue = selectedValue;
		this.autocompleteStore = new AutocompleteStore({ Items: dropListItems, SelectedValue: this.initialSelectedValue });
	}

	@computed
	get linked() {
		return this.autocompleteStore.selectedItem !== null;
	}

	@computed
	get toFundMapping(): ImportFundMapping {
		return { PushpayFundKey: this.pushpayFund.Key, IntegrationFundKey: this.autocompleteStore.selectedValue };
	}
}

@observer
export class ImportFundsPanel extends React.Component<{ importFundsStore: ImportFundsStore }, {}> {
	render() {
		const { useVirtualizedDropdown } = this.props.importFundsStore; // FF UseVirtualizedDropdownComponentInLoggedInWeb
		const hideVirtualisedDropDown = useVirtualizedDropdown && responsiveHelper.isXs;

		return (
			<div className="import-funds">
				<Form onSubmit={this.store.import}>
					<ValidationSummary validationErrors={this.store.validationErrors} />
					{hideVirtualisedDropDown ? (
						<div className="alert alert-danger alert-integration-warning">
							<div className="alert-body">
								<span className="alert-title">
									Sorry, fund mapping is not available on mobile devices
								</span>
								{`Mapping of your Pushpay funds to your ${this.store.integrationName} funds is only available on desktop devices.`}
							</div>
						</div>
					) : (
						<div className="panel panel-default">
							{this.renderHeader()}
							<SmoothHeightTransition className="import-funds-list" component="ul">
								{this.store.displayMappings.map((mapping, index) => (
									<ImportFundsRow
										key={mapping.pushpayFund.Key}
										fundMapping={mapping}
										index={index}
										importFundsStore={this.store}
									/>
								))}
							</SmoothHeightTransition>
							<div className="panel-footer panel-footer-btn-group">
								<a href={this.store.referrer} className="btn btn-cancel">
									Cancel
								</a>
								<Button
									isProcessingRequest={this.store.isProcessingRequest}
									className="btn btn-default"
									type="submit"
								>
									Save
								</Button>
							</div>
						</div>
					)}
				</Form>
			</div>
		);
	}
	renderHeader() {
		return (
			<Affix affixPosition={AffixPosition.Top}>
				<div className="panel-heading import-funds-header">
					<div className="header">
						<div className="col-sm-4">
							Pushpay funds
							<TooltipComponent message={`These are the funds that are visible to your givers.
If a Pushpay fund doesn't have an equivalent in ${this.store.integrationName}, it can be closed.`} />
						</div>
						<div className="col-sm-8 hidden-xs">
							{this.store.integrationName} funds
							<TooltipComponent message={`These are the funds within ${this.store.integrationName}. Pushpay transactions
will be assigned to the matching fund when we generate the contribution in ${this.store.integrationName}.`} />
						</div>
					</div>
					<div className="sticky-header">
						<span className="sticky-label">Import funds</span>
						<div className="responsive-btn-group">
							<button type="button" className="btn btn-link btn-sm" onClick={this.launch}>
								<SvgWrapper svg="icon-add" className="svg icon" />
								Add new fund
							</button>
							<a href={this.store.referrer} className="btn btn-ghost hidden-xs">Cancel</a>
							<Button className="btn btn-default" type="submit" isProcessingRequest={this.store.isProcessingRequest}>
								Save
							</Button>
						</div>
					</div>
				</div>
			</Affix>
		);
	}
	private launch = (ev: React.MouseEvent<HTMLButtonElement>) => {
		ev.preventDefault();
		this.store.addNewFundStore.launch();
	}
	private get store() {
		return this.props.importFundsStore;
	}
}

@observer
class ImportFundsRow extends React.Component<{ fundMapping: ImportFundMappingItem, index: number, importFundsStore: ImportFundsStore }, {}> {
	render() {
		const fundMappingClass = `import-funds-row${this.mapping.linked ? ' linked' : ''}`;
		const { useVirtualizedDropdown } = this.props.importFundsStore;

		return (
			<ModelForArrayItem propertyName={this.metadata.FundMappings.propertyName} index={this.props.index}>
				<li className={fundMappingClass}>
					<div className="pushpay-fund-container">
						<div className="fund-name">
							<div>{this.mapping.pushpayFund.Name} {this.renderCode()}</div>
							{this.renderCloseFundButtonOrNotes()}
						</div>
						{this.renderLinkIcon()}
						<InputField type="hidden" propertyMetadata={this.mappingsMetadata.PushpayFundKey} value={this.mapping.pushpayFund.Key} />
					</div>
					<div className="integration-fund-container">
						<label className="match-fund-label">Match with {this.store.integrationName} fund</label>
						<Autocomplete emptyText="- Select matching fund -" floatingLabelText="Select matching fund"
							autocompleteStore={this.mapping.autocompleteStore}
							fieldName={this.mappingsMetadata.IntegrationFundKey.propertyName}
							validationRules={this.mappingsMetadata.IntegrationFundKey.validationRules}
							useVirtualized={useVirtualizedDropdown} />
					</div>
					<div className="integration-validation-container">
						<ValidationMessage for={this.mappingsMetadata.IntegrationFundKey.propertyName} />
					</div>
				</li>
			</ModelForArrayItem>
		);
	}
	renderCloseFundButtonOrNotes() {
		if (this.mapping.pushpayFund.IsLocked) {
			return <span className="fund-notes">{this.mapping.pushpayFund.Notes}</span>;
		}

		return (
			<CloseFundButton className="btn btn-link btn-sm btn-close-fund"
				url={this.mapping.pushpayFund.CloseFundUrl} fundClose={this.handleCloseFund} />
		);
	}
	renderLinkIcon() {
		if (this.mapping.autocompleteStore.selectedItem) {
			return (<SvgWrapper svg="icon-link" className="svg svg-icon-link" />);
		} else {
			return (<SvgWrapper svg="icon-unlink" className="svg svg-icon-unlink" />);
		}
	}
	renderCode() {
		if (!this.mapping.pushpayFund.Code) {
			return null;
		}
		return (
			<span className="fund-code">({this.mapping.pushpayFund.Code}) </span>
		);
	}
	private handleCloseFund = () => {
		return this.store.closeFund(this.mapping);
	}
	private get mapping() {
		return this.props.fundMapping;
	}
	private get store() {
		return this.props.importFundsStore;
	}
	private get metadata() {
		return ModelMetadata.ImportFundMappings;
	}
	private get mappingsMetadata() {
		return ModelMetadata.ImportFundMappings.FundMappings.modelMetadata.metadata;
	}
}

@observer
export class ImportFundsHeader extends React.Component<{ importFundsStore: ImportFundsStore }, {}> {
	render() {
		return (
			<div className="header-buttons">
				<button className="btn btn-link btn-sm" onClick={this.props.importFundsStore.addNewFundStore.launch}>
					<SvgWrapper svg="icon-add" className="svg icon" />
					Add new fund
				</button>
			</div>
		);
	}
}

@observer
export class AddNewFundLink extends React.Component<{ importFundsStore: ImportFundsStore }, {}> {
	render() {
		return (
			<a href="#" onClick={this.handleOnClick}>Add new fund</a>
		);
	}
	private handleOnClick = (ev: React.MouseEvent<HTMLAnchorElement>) => {
		ev.preventDefault();
		this.props.importFundsStore.addNewFundStore.launch();
	}
}
