import { ICancellablePromise } from '../../utils/cancellable-promise';
import { Models as GeneratedModels } from '../community-member-generated';

//requests
export type UngroupPersonRequestModel = GeneratedModels.UngroupPersonRequestModel;
export type MergeCommunityMembersRequestModel = GeneratedModels.MergeCommunityMembersRequestModel;
export type GroupPersonWithCommunityMemberRequestModel = GeneratedModels.GroupPersonWithCommunityMemberRequestModel;
export type EditCommunityMemberRequestModel = GeneratedModels.EditCommunityMemberRequestModel;
export type SearchRequestModel = GeneratedModels.SearchRequestModel;
export type GivingStatementsRequestModel = GeneratedModels.GivingStatementsRequestModel;
export type RegenerateCustomStatementRequestModel = GeneratedModels.RegenerateCustomStatementRequestModel;

//responses
export type UngroupPersonResponseModel = GeneratedModels.UngroupPersonResponseModel;
export type MergeCommunityMembersResponseModel = GeneratedModels.MergeCommunityMembersResponseModel;
export type GroupPersonWithCommunityMemberResponseModel = GeneratedModels.GroupPersonWithCommunityMemberResponseModel;
export type CommunityMemberSearchResult = GeneratedModels.CommunityMemberSearchResult;

export type GivingStatementsByNameAndPreviewabilityResponseModel = GeneratedModels.GivingStatementsByNameAndPreviewabilityResponseModel;

//complex types
export type CommunityMemberModel = GeneratedModels.CommunityMemberModel;
export type PersonModel = GeneratedModels.PersonModel;
export type CommunityAddressModel = GeneratedModels.CommunityAddressModel;
export type StatementModel = GeneratedModels.StatementModel;


export class ValidationErrors {
	constructor(public errors: { [s: string]: string }) {
	}
}

/**
 * If you try and update a CommunityMemberModel but someone else did in the meantime
 */
export class ConflictedCommunityMemberModel {
	constructor(public newData: CommunityMemberModel) {
	}
}

export type EditMemberResponse = CommunityMemberModel //success
	| ValidationErrors //server found a validation error
	| ConflictedCommunityMemberModel; //someone else edited it in the meantime


export interface IDataService {
	searchMembers(request: SearchRequestModel): ICancellablePromise<CommunityMemberSearchResult>;

	// Move an Account into a (new) Member. When the service returns, its data can replace the current Member
	moveAccount(request: GroupPersonWithCommunityMemberRequestModel): ICancellablePromise<GroupPersonWithCommunityMemberResponseModel>;

	moveMember(request: MergeCommunityMembersRequestModel): ICancellablePromise<MergeCommunityMembersResponseModel>;

	//save a member's new details
	editMember(request: EditCommunityMemberRequestModel): ICancellablePromise<EditMemberResponse>;

	ungroup(request: UngroupPersonRequestModel): ICancellablePromise<UngroupPersonResponseModel>;

	memberCustomStatements(request: GivingStatementsRequestModel): ICancellablePromise<GivingStatementsByNameAndPreviewabilityResponseModel[]>;

	regenerateCustomGivingStatement(request: RegenerateCustomStatementRequestModel): ICancellablePromise<any>;
}
