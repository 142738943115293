import * as React from 'react';
import {observer} from 'mobx-react';
import * as ViewModels from '../view-models/forms';
import {
	VmProps
} from './_common';


@observer
export class FieldEditorBox extends React.Component<VmProps<ViewModels.Field>, {}> {
	componentDidMount() {
		if (this.props.vm.parent.pleaseFocusMeField === this.props.vm) {
			var i = this.refs['input'] as any;
			i.focus();
			i.select();
		}
	}

	render() {
		var {vm} = this.props;
		return <input ref="input" type="text" value={vm.value} onChange={e => vm.setValue(e)}/>;
	}
}
