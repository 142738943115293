import * as React from 'react';
import * as styles from './labels.less';
import { classNames } from '../../../Shared/utils/classnames';
import { SvgWrapper } from '../../../Shared/components/svg-wrapper';

export enum LabelType {
	Default = 'default',
	DefaultDark = 'default-dark',
	DefaultSecondary = 'default-secondary',
	Primary = 'primary',
	Success = 'success',
	SuccessSecondary = 'success-secondary',
	Danger = 'danger',
	Info = 'info',
	Warning = 'warning',
}

export type LabelItem = {
	type: LabelType;
	text: string;
};

export interface ILabelsProps {
	items: LabelItem[];
	additionalClasses?: string;
	acceptanceTestTargetId?: string;
}

export class Labels extends React.Component<ILabelsProps, {}> {
	render() {
		const { items, additionalClasses, acceptanceTestTargetId, ...additionalProps } = this.props;
		return (
			<div
				className={classNames(styles.wrapper, additionalClasses)}
				data-pp-at-target={this.props.acceptanceTestTargetId}
				{...additionalProps}>
					{items.map((item, index) => (
						<span key={index} className={classNames('label', `label-${item.type}`, styles.label)}>
							<SvgWrapper svg="star" className={`svg ${styles.icon}`} />
							{item.text}
						</span>
					))}
			</div>
		);
	}
}
