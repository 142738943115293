import { observable, computed, action } from 'mobx';
import { ResponseTypes } from '../../funds/funds-generated';

import CcbMembershipTypeViewModel = ResponseTypes.CcbMembershipTypeViewModel;
import CommunityMemberTypeViewModel = ResponseTypes.CommunityMemberTypeViewModel;

export class CcbMembershipTypeConfigurationModel {
	@observable
	defaultCcbOrganizationalTypeId: number;

	@observable
	membershipTypes: CcbMembershipTypeModel[];

	constructor(
		defaultCcbOrganizationalTypeId: number,
		membershipTypes: CcbMembershipTypeViewModel[],
		public communityMemberTypes: CommunityMemberTypeViewModel[]) {

		this.defaultCcbOrganizationalTypeId = defaultCcbOrganizationalTypeId;

		if (membershipTypes) {
			this.membershipTypes = membershipTypes.map(x => new CcbMembershipTypeModel(
				x.MappedCommunityMemberType ? x.MappedCommunityMemberType.CommunityMemberTypeId : null,
				x.MembershipTypeId,
				x.MembershipTypeName,
				this.communityMemberTypes,
				this.validateDefaultCcbOrganizationalType)
			);
		}
	}

	@action.bound
	updateDefaultCcbOrganizationalTypeId(selectedDefaultCcbOrganizationalTypeId: string) {
		const parsedId = parseInt(selectedDefaultCcbOrganizationalTypeId, 10);
		this.defaultCcbOrganizationalTypeId = isNaN(parsedId) ? null : parsedId;
	}

	validateDefaultCcbOrganizationalType = (updatedCcbMembershipType: CcbMembershipTypeModel) => {
		if (this.defaultCcbOrganizationalTypeId === updatedCcbMembershipType.membershipTypeId
			&& !updatedCcbMembershipType.isOrganizationalType) {
				this.defaultCcbOrganizationalTypeId = null;
			}
	}
}

export class CcbMembershipTypeModel {
	@observable
	mappedCommunityMemberTypeId: number;

	constructor(
		mappedCommunityMemberTypeId: number,
		public membershipTypeId: number,
		public membershipTypeName: string,
		public communityMemberTypes: CommunityMemberTypeViewModel[],
		public validateDefaultCcbOrganizationalType: (ccbMembershipType: CcbMembershipTypeModel) => void
	) {
		this.mappedCommunityMemberTypeId = mappedCommunityMemberTypeId;
	}

	@computed
	get isOrganizationalType(): boolean {
		return this.mappedCommunityMemberTypeId !== null
			&& !isNaN(this.mappedCommunityMemberTypeId)
			&& this.communityMemberTypes.filter(x => x.CommunityMemberTypeId === this.mappedCommunityMemberTypeId)[0].IsOrganization;
	}

	@action.bound
	updateMappedCommunityMemberTypeId(selectedCommunityMemberTypeId: string) {
		const parsedId = parseInt(selectedCommunityMemberTypeId, 10);
		this.mappedCommunityMemberTypeId = isNaN(parsedId) ? null : parsedId;

		this.validateDefaultCcbOrganizationalType(this);
	}
}
