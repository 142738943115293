import * as React from 'react';
import { observer } from 'mobx-react';
import { SimpleMatchModal, ModalCancelButton } from '../../../components/match-modal';
import { Fragment } from '../../../../Shared/components/fragment';
import { PledgeAlreadyAddedResponse } from '../../campaigns-generated';

interface PledgeUpdateSuccessModalProps {
	state: string | string[];
	alreadyAddedPledge: PledgeAlreadyAddedResponse;
	newAmount: number;
	onClose: () => void;
}

export const PledgeUpdateSuccessModal = observer(({ state, alreadyAddedPledge, newAmount, onClose }: PledgeUpdateSuccessModalProps) => (
	<SimpleMatchModal
		type="success"
		state={state}
		getCancelButton={() => <ModalCancelButton onCancel={onClose} />}
		getBody={() =>
			<Fragment>
				<h1>Pledge updated</h1>
				<div>{alreadyAddedPledge.PledgerNamePossessive} pledge has been updated from ${alreadyAddedPledge.TotalAmount} to ${newAmount}</div>
			</Fragment>}
		getFooter={() => <button type="submit" onClick={onClose} className="btn btn-primary">Close</button>} />
));
