import * as React from 'react';
import { SvgWrapper } from '../../../../Shared/components/svg-wrapper';
import { Formatter } from '../../../helpers/formatter';
import { classNames } from '../../../../Shared/utils/classnames';
import { GivingStatementRunStatus } from '../../giving-statements-generated';
import { DeleteStatementsButton } from '../delete-statements-button';
import * as styles from './sent-summary-run-details.less';

interface ISentSummaryRunDetailsProps {
	statementName: string;
	generateAt: Date;
	statementsGenerated: number;
	className?: string;
	hideStatementNameAndStatus?: boolean;
	status: GivingStatementRunStatus;
	onRegenerateStatements?(): void;
	hasBeenPublished: boolean;
	onDeleteStatements(): void;
}

export const SentSummaryRunDetails: React.StatelessComponent<ISentSummaryRunDetailsProps> = (props) => {
	const {
		statementName,
		generateAt,
		statementsGenerated,
		className,
		status,
		onRegenerateStatements,
		hideStatementNameAndStatus,
		hasBeenPublished,
		onDeleteStatements,
	} = props;
	var statusLabel = GivingStatementRunStatus[status];

	if (status === GivingStatementRunStatus.Issued) {
		statusLabel = 'Published';
	} else if (status === GivingStatementRunStatus.Issuing) {
		statusLabel = 'Publishing';
	}

	return (
		<div className={className}>
			{!hideStatementNameAndStatus && <div className={styles.titleContainer}>
				<div className={styles.statementNameContainer}>
					<div className={styles.statementName}>{statementName}</div>
					<div className={styles.runStatus}>
						<span className={classNames('label', styles[GivingStatementRunStatus[status].toLowerCase()])}>{statusLabel}</span>
					</div>
				</div>
			</div>}
			<div className={styles.detailsContainer}>
				<div className={styles.generatedDate}><strong>Generated date: </strong>{Formatter.formatDate(generateAt, 'MMM D, YYYY')}</div>
				<div className={styles.statementsGenerated}><strong>Total statements generated: </strong>{statementsGenerated}</div>
				{
					(status === GivingStatementRunStatus.Issued || status === GivingStatementRunStatus.Generated)
					&& <div className={styles.regenerateStatements}>
						<a href="javascript:void(0)" onClick={onRegenerateStatements}>Regenerate statements </a>
						<SvgWrapper svg="icon-chevron-right" />
					</div>
				}
				{
					status !== GivingStatementRunStatus.Issuing &&
					!hasBeenPublished &&
					<DeleteStatementsButton className={styles.deleteButton} onDelete={onDeleteStatements} />
				}
			</div>
		</div>
	);
};
