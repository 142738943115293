import { action, observable } from 'mobx';
import { Models } from '../community-member-generated';

export abstract class MemberFormViewModel {
	@observable request: Models.EditCommunityMemberRequestModel;
	@observable validationErrors?: { [s: string]: string };
	@observable errorMessages?: string[];
	@observable errorSummaryTitle?: string;

	abstract isProcessingRequest: boolean;

	@action
	updateTypeState = (type: number) => {
		this.request.CommunityMemberType = type;
	}

	@action
	updateCountry = (country: number) => {
		this.request.Address.Country = country;
	}

	@action
	updateCountryState = (state: string) => {
		this.request.Address.State = state;
	}

	@action
	updateRequest = (propertyName: string, value: string, childModel?: string) => {
		if (childModel) {
			this.request[childModel][propertyName] = value;
		} else {
			this.request[propertyName] = value;
		}
	}
}
