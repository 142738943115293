import * as React from 'react';
import { observer } from 'mobx-react';
import { FormActions } from '../form-actions';
import { Form } from '../../../funds/components/form-controls';
import { ListingModel } from '../listing-model';
import { ModelForArrayItem, ModelFor } from '../../../funds/components/form-controls';
import { ListingConfiguration } from '../listing-configuration';
import { MembershipTypeMappingConfiguration } from '../membership-type-mapping-configuration/membership-type-mapping-configuration';
import { DefaultOrganizationTypeConfiguration } from '../default-organization-type-configuration/default-organization-type-configuration';
import { ModelMetadata } from '../../../funds/funds-generated';
import TooltipComponent from '../configuration-section-tooltip';

import { classNames } from '../../../../Shared/utils/classnames';
import * as styles from './styles.less';

import { CcbMembershipTypeConfigurationModel } from 'integrations/components/ccb-membership-type-configuration-model';
import { CcbIntegrationConfigurationStore } from 'integrations/pages/configure-ccb';
import { CcbMembershipTypeModel } from 'integrations/components/ccb-membership-type-configuration-model';
import { SvgWrapper } from 'components/svg-wrapper';

export interface ICcbConfigurationSectionProps<TListingModel> {
	store: CcbIntegrationConfigurationStore;
	headerComponentFactory: React.Factory<null>;
	organizationSettings?: React.ReactChild;
	listingSettingsEditor: React.ComponentClass<{ listing: ListingModel<TListingModel> }>;
	paymentMethodConfig?: React.ReactChild;
}

const integrationAbbreviation =  "ChMS";

@observer
export class CcbConfigurationSection extends React.Component<ICcbConfigurationSectionProps<any>, {}> {

	renderConfigurationPerListing = (model: ListingModel<any>, index: number) => {
		const { Listings } = ModelMetadata.CcbConfigurationEditModel;

		return (
			<ModelForArrayItem propertyName={Listings.propertyName} index={index} key={model.listingId}>
				<ListingConfiguration
					model={model}
					editor={this.props.listingSettingsEditor} />
			</ModelForArrayItem>
		);
	}

	renderTypeMappingPerMemebershipType = (model: CcbMembershipTypeModel, index: number) => {
		return (
			<ModelForArrayItem propertyName={this.membershipTypeMappingsPropertyName} index={index} key={model.membershipTypeId}>
				<MembershipTypeMappingConfiguration model={model}  />
			</ModelForArrayItem>
		);
	}

	renderDefaultOrganizationalTypeSettings(model: CcbMembershipTypeConfigurationModel) {
		const { MembershipTypeConfiguration } = ModelMetadata.CcbConfigurationEditModel;

		return (
			<ModelFor propertyName={MembershipTypeConfiguration.propertyName}>
				<DefaultOrganizationTypeConfiguration model={model} />
			</ModelFor>
		);
	}

	handleSubmit = (form: HTMLFormElement) => {
		this.props.store.serviceClient.setupConfiguration(new FormData(form));
	}

	renderPanelHeader = () => {
		return this.props.organizationSettings && <div className="panel-heading">Settings</div>;
	}

	renderOrganizationSettings = () => {
		return this.props.organizationSettings && <div className="panel-body">{this.props.organizationSettings}</div>;
	}

	renderPaymentMethodConfig = () => {
		return this.props.paymentMethodConfig && <div className="panel panel-default">
			<ul className="list-group">{this.props.paymentMethodConfig}</ul>
		</div>;
	}

	renderMembershipTypeSettings = () => {
		const mappingTipMessage = `For every applicable ${integrationAbbreviation} member type select whether it is a Pushpay Individual or Organization. \n` +
			`This will be used to match your community members to ${integrationAbbreviation}. You can leave the membership type blank if it is not exclusively an organization or individual.`;

		const defaultOrganizationalTypeTipMessage = `Select which of the member types should be used when creating a new organization in ${integrationAbbreviation}. \n` +
			'You have to have first identified the member type as an organization above. \n' +
			`All new organizations will be created with this label in ${integrationAbbreviation}.`;

		return <>
			<li className="list-group-item">
				<label>
					<span className={classNames(styles.memberTypeConfigHeader)}>
						<span>{integrationAbbreviation} member type mapping </span>
						<TooltipComponent message={mappingTipMessage} />
					</span>
				</label>
			</li>
			{this.props.store.MembershipTypeConfiguration.membershipTypes.map(this.renderTypeMappingPerMemebershipType)}
			<li className="list-group-item">
				<label>
					<span className={classNames(styles.memberTypeConfigHeader)}>
						<span>Default {integrationAbbreviation} organization </span>
						<TooltipComponent message={defaultOrganizationalTypeTipMessage} />
					</span>
				</label>
			</li>
			{this.renderDefaultOrganizationalTypeSettings(this.props.store.MembershipTypeConfiguration)}
		</>;
	}

	renderMembershipTypeMissingError = () => {
		const mappingTipMessage = `For every applicable ${integrationAbbreviation} member type select whether it is a Pushpay Individual or Organization.
			This will be used to match your community members to ${integrationAbbreviation}. You can leave the membership type blank if it is not exclusively an organization or individual.`;

		return <>
			<li className="list-group-item is-header">
				<label className={classNames(styles.memberTypeConfigHeader)}>
					<span>
						<span>{integrationAbbreviation} member type mapping </span>
						<TooltipComponent message={mappingTipMessage} />
					</span>
				</label>
			</li>
			{this.renderNoCcbMemberTypesAlert()}
		</>;
	}

	renderNoCcbMemberTypesAlert = () => {
		const noMembershipTypesWarningMessage = `There are currently no Membership Types configured in ${integrationAbbreviation}.
			In order for organizational gifts to be synchronized with ${integrationAbbreviation} there needs to be a corresponding label set up.`;

		return <div className={classNames('alert', styles.noCcbTypesFoundAlert)}>
			<SvgWrapper className="ion" svg="alert-exclamation-icon" width={62} height={62}/>
			<h3>No Membership Types in {integrationAbbreviation}</h3>
			<p>{noMembershipTypesWarningMessage}</p>
		</div>;
	}

	render() {
		let membershipTypeSettings;
		const membershipTypes = this.props.store.MembershipTypeConfiguration.membershipTypes;

		if (membershipTypes && membershipTypes.length >= 1) {
			membershipTypeSettings = this.renderMembershipTypeSettings();
		} else {
			membershipTypeSettings = this.renderMembershipTypeMissingError();
		}

		return (
			<Form onSubmit={this.handleSubmit}>
				<div className="panel panel-default">
					{this.renderPanelHeader()}
					{this.renderOrganizationSettings()}
					<ul className="list-group">
						<li className="list-group-item is-header">
							<label>Pushpay listings</label>
							<label>{this.props.headerComponentFactory()}</label>
						</li>
						{this.props.store.listings.map(this.renderConfigurationPerListing)}
						{membershipTypeSettings}
					</ul>
				</div>
				{this.renderPaymentMethodConfig()}
				<div className="panel panel-default">
					<FormActions
						isSettingUp={this.props.store.serviceClient.isSettingUp}
						isProcessingRequest={this.props.store.serviceClient.isProcessingRequest}
						cancelButtonAction={this.props.store.cancelActionUrl}
						submitButtonText="Setup" />
				</div>
			</Form>
		);
	}

	get membershipTypeMappingsPropertyName() {
		const { MembershipTypeConfiguration } = ModelMetadata.CcbConfigurationEditModel;
		return MembershipTypeConfiguration.propertyName + '.' + MembershipTypeConfiguration.modelMetadata.MembershipTypeMappings.propertyName;
	}
}
