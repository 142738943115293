import { IVirtualTerminalPayerViewModel } from '../../components/payer-search/payer-view-model';
import { action, observable, IObservableValue } from 'mobx';
import { SingleEntryViewModel } from '../../virtual-terminal-generated';
import { VirtualTerminalUserAction } from './single-entry-user-actions';
import { ISingleEntrySagaContext, virtualTerminalSaga } from './single-entry-main-saga';
import { runSaga, userActionChannel, Channel } from '../../../utils/saga-utils';
import { RecentGiftsGridViewModel } from '../../components/recent-gifts/recent-gifts-grid-view-model';
import { VirtualTerminalListingStore } from '../../virtual-terminal-listing-store';
import { VirtualTerminalFormViewModel } from '../../components/form/virtual-terminal-form-view-model';
import { ModalDialogCommander } from '../../../components/modal-dialog-commander';
import { StorageHelper } from '../../../helpers/storagehelper';
import { Metrics } from '../../utils/metrics';
import { userActionCreator, ActionCreator } from '../../../utils/user-action-creator';

export class VirtualTerminalMainViewModel {
	@observable
	model: IObservableValue<SingleEntryViewModel> = observable.box<SingleEntryViewModel>(null);

	@observable
	formViewModel: VirtualTerminalFormViewModel;

	@observable
	recentGiftsGridViewModel: RecentGiftsGridViewModel;

	@observable
	listingStore: VirtualTerminalListingStore;

	userActionChannel: Channel<VirtualTerminalUserAction>;

	userActionCreator: ActionCreator<typeof VirtualTerminalUserAction>;

	readonly batchEntryUrl: string;
	readonly shouldDisplayQboEnabledWarning: boolean;

	constructor(model: SingleEntryViewModel) {
		this.userActionChannel = userActionChannel();
		this.userActionCreator = userActionCreator<typeof VirtualTerminalUserAction>(VirtualTerminalUserAction, this.userActionChannel);

		this.model.set(model);
		this.listingStore = new VirtualTerminalListingStore(model.Listings);
		this.formViewModel = new VirtualTerminalFormViewModel(this.listingStore);
		this.formViewModel.annualRecurringGivingEnabled = model.HasAnnualRecurringGivingEnabled;
		this.formViewModel.semiAnnualRecurringGivingEnabled = model.HasSemiAnnualRecurringGivingEnabled;
		this.formViewModel.quarterlyRecurringGivingEnabled = model.HasQuarterlyRecurringGivingEnabled;
		this.formViewModel.shouldShowMemberList = model.CanViewMembers;
		this.formViewModel.shouldDisplayGiftEntryEditButtons = model.CanEditGiftEntries;
		this.recentGiftsGridViewModel = new RecentGiftsGridViewModel(this.listingStore);

		const sagaContext: ISingleEntrySagaContext = {
			formViewModel: this.formViewModel,
			recentGiftsViewModel: this.recentGiftsGridViewModel,
			userActionChannel: this.userActionChannel,
			listingStore: this.listingStore,
			sagaState: {},
			reportError
		};

		runSaga({}, virtualTerminalSaga, sagaContext);

		this.initSelectedListing();
		this.preselectPayer(model.CommunityMemberId);

		this.batchEntryUrl = model.BatchEntryUrl;
		this.shouldDisplayQboEnabledWarning = model.HasQuickBooks;
	}

	@action.bound
	private preselectPayer(communityMemberId: number) {
		if (!communityMemberId) {
			return;
		}
		Metrics.paymentEntryStarted();
		this.userActionChannel.put(new VirtualTerminalUserAction.SelectedPayerChange(
			<IVirtualTerminalPayerViewModel> {
				id: `${communityMemberId}`
			}
		));
	}

	@action.bound
	private initSelectedListing() {
		const visibleListings = this.listingStore.listings.map(x => `${x.ListingId}`);
		const storedListingId = StorageHelper.getSelectedListingId(visibleListings);
		const selectedListingId = storedListingId || visibleListings[0];

		this.userActionChannel.put(new VirtualTerminalUserAction.ListingChange(selectedListingId));
	}
}

function reportError(error: any) {
	if (error && error.userError) {
		ModalDialogCommander.error(error.userError, '');
	} else {
		ModalDialogCommander.showStandardErrorMessage();
	}
}
