import { ProductSwitcherProps } from '@pushpay/shell/lib/types/components/product-switcher/product-switcher';
import { ProductSwitcherRedirectValue } from '@pushpay/shell/lib/types/components/product-switcher/types';
import { ProductSwitcherViewModel } from '../loggedinweb-generated';

type UseProductSwitcherPropsArgs = ProductSwitcherViewModel & {
	hasMasAccess: boolean;
	isMasActive: boolean;
	organizationKey: string;
};

const PRODUCT_SWITCHER_QUERY_FORM = 'https://grow.pushpay.com/lp-dm-product-switcher.html?utm_medium=site&utm_source=pushpay&utm_content=dm-product-switcher&utm_campaign=product-switcher&utm_term=faith--all--all';
export const PRODUCT_SWITCHER_LEARN_MORE_APPS = 'https://pushpay.com/product/mobile-app';
export const PRODUCT_SWITCHER_LEARN_MORE_APPS_CATHOLIC = 'https://pushpay.com/product/catholic/mobile-app';
export const PRODUCT_SWITCHER_LEARN_MORE_CHMS = 'https://pushpay.com/product/chms-software';
export const PRODUCT_SWITCHER_LEARN_MORE_CHMS_CATHOLIC = 'https://pushpay.com/product/catholic/chms-software';
export const PRODUCT_SWITCHER_LEARN_MORE_GIVING = 'https://pushpay.com/product/church-giving';
export const PRODUCT_SWITCHER_LEARN_MORE_GIVING_CATHOLIC = 'https://pushpay.com/product/catholic/church-giving';
export const PRODUCT_SWITCHER_LEARN_MORE_INSIGHTS = 'https://pushpay.com/product/insights';
export const PRODUCT_SWITCHER_LEARN_MORE_INSIGHTS_CATHOLIC = 'https://pushpay.com/product/catholic/insights';


export function getProductSwitcherConfig({
	CcbTenantUrl,
	LaunchPadUrl,
	PushpayMarketingUrl,
	CanAccessAppStudio,
	CanAccessInsights,
	OrganizationCanUseAppStudio,
	OrganizationCanUseInsights,
	IsCatholic,
	hasMasAccess,
	isMasActive,
	organizationKey,
}: UseProductSwitcherPropsArgs): ProductSwitcherProps {
	function buildRedirectUrl(value: ProductSwitcherRedirectValue) {
		const url = new URL(LaunchPadUrl);
		url.searchParams.set('redirect', encodeURIComponent(btoa(JSON.stringify(value))));
		return url.toString();
	}

	const getSharedProps = (label: string, description = 'Product description') => ({
		description,
		label,
		learnMoreLabel: `Learn about ${label}`,
		learnMoreUrl: PRODUCT_SWITCHER_QUERY_FORM,
	});

	function getChmsProps(): ProductSwitcherProps['chmsProps'] {
		const label = 'ChMS';
		const description = 'Seamless church management software that scales and grows with your church';

		if (NewFeatures.ImplementSwitchingToInsights) {
			return {
				access: CcbTenantUrl ? 'hasAccess' : 'organizationNoProduct',
				url: buildRedirectUrl({ product: 'ccb', tenantUrl: CcbTenantUrl }),
				label,
				description,
				learnMoreLabel: 'Learn about ChMS',
				learnMoreUrl: IsCatholic ? PRODUCT_SWITCHER_LEARN_MORE_CHMS_CATHOLIC : PRODUCT_SWITCHER_LEARN_MORE_CHMS,
			} as ProductSwitcherProps['chmsProps'];
		} else {
			if (CcbTenantUrl) {
				return {
					access: 'hasAccess',
					url: buildRedirectUrl({ product: 'ccb', tenantUrl: CcbTenantUrl }),
					...getSharedProps(label),
				};
			}
			return { access: 'organizationNoProduct', ...getSharedProps(label, description) };
		}
	}

	function getAppStudioProps(): ProductSwitcherProps['appStudioProps'] {
		const label = 'App Studio';
		const description = 'Church apps designed to increase engagement and leadership in your community';

		if (NewFeatures.ImplementSwitchingToInsights) {
			const getAccess = () => {
				if(!OrganizationCanUseAppStudio) {
					return 'organizationNoProduct';
				}
				return CanAccessAppStudio ? 'hasAccess' : 'noPermission';
			};

			return {
				access: getAccess(),
				url: buildRedirectUrl({ product: 'mas', organizationKey }),
				label,
				description,
				learnMoreLabel: 'Learn about Apps',
				learnMoreUrl: IsCatholic ? PRODUCT_SWITCHER_LEARN_MORE_APPS_CATHOLIC : PRODUCT_SWITCHER_LEARN_MORE_APPS,
			} as ProductSwitcherProps['appStudioProps'];

		} else {
			if (!isMasActive) {
				return { access: 'organizationNoProduct', ...getSharedProps(label, description) };
			}

			if (!hasMasAccess) {
				return { access: 'noPermission', ...getSharedProps(label) };
			}
			return { access: 'hasAccess', url: buildRedirectUrl({ product: 'mas', organizationKey }), ...getSharedProps(label) };
		}
	}

	function getInsightsProps(): ProductSwitcherProps['insightsProps'] {
		const label = 'Insights';
		const description = 'All your church metrics in one spot to increase church health and engagement';

		const getAccess = () => {
			if (!OrganizationCanUseInsights) {
				return 'organizationNoProduct';
			}
			return CanAccessInsights ? 'hasAccess' : 'noPermission';
		};

		return {
			access: getAccess(),
			url: buildRedirectUrl({ product: 'insights', organizationKey }),
			label,
			description,
			learnMoreLabel: 'Learn about Insights',
			learnMoreUrl: IsCatholic ? PRODUCT_SWITCHER_LEARN_MORE_INSIGHTS_CATHOLIC : PRODUCT_SWITCHER_LEARN_MORE_INSIGHTS,
		} as ProductSwitcherProps['insightsProps'];
	}

	return {
		title: 'Switch to',
		pushpayMarketing: { label: 'Pushpay.com', title: 'Pushpay website', url: PushpayMarketingUrl },
		givingProps: NewFeatures.ImplementSwitchingToInsights
			? { access: 'current', label: 'Giving', description: '', learnMoreLabel: '', learnMoreUrl: '' }
			: { access: 'current', ...getSharedProps('Giving') },
		chmsProps: getChmsProps(),
		appStudioProps: getAppStudioProps(),
		insightsProps: NewFeatures.ImplementSwitchingToInsights ? getInsightsProps() : undefined,
		tooltips: {
			noPermission: `Your organization has this product, but you'll need to request access from your organization's admin.`,
			organizationNoProduct: `Sorry, your organization doesn't have access to this product. Contact our team to learn more.`,
		},
		buttonLabel: 'Switch product',
		noOrgAcessTitle: 'More tools for your organization:',
	};
}
