import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { observer } from 'mobx-react';
import { ModalDialogCommander } from '../components/modal-dialog-commander';
import { Button } from '../components/button';
import { ModalDialog } from '../components/modal-dialog';
import { BrandingSettingsMainViewModel, BrandingPackageState } from './branding-settings-main-view-model';

export interface IBrandingSettingConfirmRefreshDialogProps {
	refreshClicked: () => void;
	vm: BrandingSettingsMainViewModel;
}

@observer
export class BrandingSettingConfirmRefreshDialog extends React.Component<IBrandingSettingConfirmRefreshDialogProps> {
	open() {
		return ModalDialogCommander.open(ReactDOM.findDOMNode(this));
	}

	close() {
		ModalDialogCommander.close(ReactDOM.findDOMNode(this) as Element);
	}

	render() {
		const { refreshClicked, vm } = this.props;
		const isProcessing = vm.packageState === BrandingPackageState.DiscardInProgress;
		const refreshButton =
			<Button key="refresh_button"
				className="btn btn-primary"
				acceptanceTestTargetId="refresh-confirm"
				isProcessingRequest={isProcessing}
				onClick={refreshClicked}
				disabled={vm.formViewModel.isSavingOrUploadingOrProcessing}
			>Ok
			</Button>;
		const cancelButton = {
			label: 'Cancel',
			dismiss: true,
			isPrimary: false,
			disabled: isProcessing,
		};
		const buttons = [cancelButton, refreshButton];
		return <ModalDialog title="" buttons={buttons} showAlert={false} inProgress={isProcessing} >
			Changes were made by another user. Your changes will be discarded.
			</ModalDialog>;
	}
}
