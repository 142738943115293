import * as React from 'react';
import { SvgWrapper } from '../../../../Shared/components/svg-wrapper';
import * as styles from './process-steps.less';

export class ProcessSteps extends React.Component<{}> {
	render() {
		return (
			<div className={styles.container}>
				<div className={styles.item}>
					<SvgWrapper svg="configure-statements" />
					<div>
						<h4>Configure your statements</h4>
					</div>
				</div>
				<div className={styles.arrow}>
					<SvgWrapper svg="arrow-short" />
				</div>
				<div className={styles.item}>
					<SvgWrapper svg="generate-statements" />
					<div>
						<h4>Generate your statements</h4>
					</div>
				</div>
				<div className={styles.arrow}>
					<SvgWrapper svg="arrow-short" />
				</div>
				<div className={styles.item}>
					<SvgWrapper svg="send-statements" />
					<div>
						<h4>Publish your statements</h4>
					</div>
				</div>
			</div>
		);
	}
}
