import * as React from 'react';
import { observer, Provider } from 'mobx-react';
import { AnalyticsContext } from '../../../analytics';
import { Fragment } from '../../../../Shared/components/fragment';
import { BatchEntryMainViewModel } from './batch-entry-main-view-model';
import { BatchEntrySagaContext, getActionHandlers, batchEntryOnSagaInit } from './batch-entry-saga';
import { runVirtualTerminalSaga } from '../../saga/virtual-terminal-saga';
import { BatchEntryBatchDetails } from '../../components/batch-entry/batch-details/batch-entry-batch-details';
import { VirtualTerminalForm } from '../../components/form/virtual-terminal-form';
import { VirtualTerminalFormHeader } from '../../components/form/virtual-terminal-form-header';
import { BatchEntryIntro } from '../../components/batch-entry-intro';
import { BatchEntryTipBox } from '../../components/batch-entry/tipbox/batch-entry-tipbox';
import { BatchDetailsState } from '../../components/batch-entry/batch-details/batch-entry-batch-details-view-model';
import { BatchEntryPaymentsGrid } from '../../components/batch-entry-payments/batch-entry-payments-grid';
import { SmoothHeightTransition } from '../../../components/hoc-behavior/transitions';
import { BatchEntryFundTotals } from '../../components/batch-entry-payments/batch-entry-fund-totals';
import { BatchStatus } from '../../virtual-terminal-generated';
import { BatchEntryDiscrepency } from '../../components/batch-entry-payments/batch-entry-discrepency';
import { BatchEntryFooter } from '../../components/batch-entry/footer/batch-entry-footer';

const analyticsContext: AnalyticsContext = {
	feature: 'giftEntry',
	subFeature: 'batchGiftEntry',
}

@observer
export class BatchEntryMain extends React.Component<{ vm: BatchEntryMainViewModel }, {}> {
	private readonly batchEntrySagaContext: BatchEntrySagaContext;

	constructor(props) {
		super(props);

		this.batchEntrySagaContext = new BatchEntrySagaContext(props.vm);
		runVirtualTerminalSaga(this.batchEntrySagaContext, getActionHandlers(), batchEntryOnSagaInit);
	}

	render() {
		const { batchDetailsViewModel, formViewModel, listingStore: { paymentLabel }, batchPaymentsStore, model } = this.props.vm;
		const { OrgHasNoBatches, HelpCenterUrl, ServiceTimeSettingsUrl, BatchListUrl } = model.get();
		const { batchExists, batch } = batchDetailsViewModel;
		const { NounPluralLowerCase, NounPluralSentenceCase, NounLowerCase } = paymentLabel;
		const formIntroTitle = `Enter ${paymentLabel.NounPluralLowerCase}`;
		const showFundTotals = batch.Status === BatchStatus.Completed;
		const showCreateBatchHeader = batchDetailsViewModel.state === BatchDetailsState.Creating;
		const showPaymentForm = batch.Status === BatchStatus.Open;
		const { paymentsModel: { FundTotals }, batchPaymentsCount, batchPaymentsTotal } = batchPaymentsStore;

		return (
			<Provider userActionCreator={this.batchEntrySagaContext.userActionCreator} analytics={analyticsContext}>
				<>
					<SmoothHeightTransition disableLeaveTransition>
					{
						showCreateBatchHeader &&
						<header>
							<h1>Create a batch</h1>
							<BatchEntryIntro paymentNoun={NounPluralLowerCase} />
							{OrgHasNoBatches && <BatchEntryTipBox
								paymentNoun={NounPluralLowerCase}
								helpUrl={HelpCenterUrl}
								serviceTimeUrl={ServiceTimeSettingsUrl}/>}
						</header>
					}
					</SmoothHeightTransition>
					<BatchEntryBatchDetails vm={batchDetailsViewModel} batchTotal={batchPaymentsTotal} batchCount={batchPaymentsCount} />
					{
						batchExists &&
						<Fragment>
							<SmoothHeightTransition disableLeaveTransition>
								{
									showPaymentForm &&
									<Fragment>
										<VirtualTerminalFormHeader title={formIntroTitle} formViewModel={formViewModel} paymentLabel={paymentLabel} />
										<VirtualTerminalForm formViewModel={formViewModel} />
									</Fragment>
								}
							</SmoothHeightTransition>
							<BatchEntryFundTotals showFundTotals={showFundTotals} fundTotals={FundTotals} nounPluralSentenceCase={NounPluralSentenceCase} />
							<BatchEntryPaymentsGrid paymentLabel={paymentLabel} store={batchPaymentsStore} />
							<BatchEntryDiscrepency batchTotal={batchPaymentsTotal}
										expectedTotal={batch.ExpectedTotalAmount}
										batchCount={batchPaymentsCount}
										expectedCount={batch.ExpectedTotalCount}
										paymentNoun={NounLowerCase}
										paymentNounPlural={NounPluralLowerCase} />
							<BatchEntryFooter batchListingUrl={BatchListUrl} batch={batch} />
						</Fragment>
					}
				</>
			</Provider>
		);
	}
}
