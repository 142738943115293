import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {ResponseTypes} from '../funds-generated';
import {ModalDialogCommander} from '../../components/modal-dialog-commander';
import {Button} from '../../components/button';
import {ModalDialog, IModalDialogButton} from '../../components/modal-dialog';
import {makeAjaxCall} from '../utils/ajax-helper';
import FundValidationError = ResponseTypes.FundValidationError;
import ListingMustHaveVisibleFundError = ResponseTypes.ListingMustHaveVisibleFundError;

export interface ICloseFundModalDialogProps {
	urlClose: string;
	fundClose: () => JQueryPromise<any>;
}

const maxNumberOfListingsToShow = 10;

function formatListings(listings: string[]) {
	if (listings.length > maxNumberOfListingsToShow) {
		return `${listings.length} listings`;
	}

	return `the following listings: ${listings.join(', ')}`;
}

export let ListingMustHaveVisibleFundErrorDialog = (error: ListingMustHaveVisibleFundError) =>
	(<ModalDialog title="">{`This fund cannot be closed because it is the only fund available to ${error.Verbage} on ` +
		`${error.MultipleListingsVisible ? 'some of your listings' : 'your listing'}. ` +
		'Before retrying, please visit '} <a href={error.ManageFundsUrl}>manage funds</a>
		{`, and make another fund visible for ${error.MultipleListingsVisible ? formatListings(error.Listings) : 'your listing'}.`}
	</ModalDialog>);


export let CloseFundErrorDialog = (error = { ErrorMessage: 'There was a problem closing the fund' } as FundValidationError) =>
	<ModalDialog title="">{error.ErrorMessage}</ModalDialog>;

export class CloseFundModalDialog extends React.Component<ICloseFundModalDialogProps, { isProcessingRequest: boolean }> {
	constructor(props) {
		super(props);
		this.state = { isProcessingRequest: false };
	}

	open() {
		return ModalDialogCommander.open(ReactDOM.findDOMNode(this));
	}

	close() {
		ModalDialogCommander.close(ReactDOM.findDOMNode(this));
	}

	handleCloseButtonClick=()=> {

		this.setState({
			isProcessingRequest: true
		});

		makeAjaxCall(this.props.urlClose)
			.fail(error => {
				this.setState({
					isProcessingRequest: false
				});
				ModalDialogCommander.showReactDialog(CloseFundErrorDialog(error));
			})
			.then((response) => {
				if (this.isListingMustHaveVisibleFundError(response)) {
					ModalDialogCommander.showReactDialog(ListingMustHaveVisibleFundErrorDialog(response));
				} else {
					this.props.fundClose().then(() => {
						this.close();
						this.setState({
							isProcessingRequest: false
						});
					});
				}
			});
	}

	render() {
		const closeButton = <Button key="delete_button"
								className="btn btn-primary"
								onClick={this.handleCloseButtonClick}
								isProcessingRequest={this.state.isProcessingRequest}>Close fund</Button>;

		const buttons: (IModalDialogButton | JSX.Element)[] = [
			{
				label: 'Cancel',
				dismiss: true,
				isPrimary: false,
				hideOnProgress: true
			},
			closeButton];
		return <ModalDialog title="" buttons={buttons} inProgress={this.state.isProcessingRequest}>Are you sure you want to close this fund?</ModalDialog>;
	}

	private isListingMustHaveVisibleFundError(response: any): response is ListingMustHaveVisibleFundError {
		return response.Listings !== undefined;
	}
}
