import React, { FC } from 'react';
import { ThemeProvider, GivingTheme } from '@pushpay/theming';

import { elevationLayoutZIndex, elevationModalZIndex, elevationPopoverZIndex } from './theme-provider.less';

const extendedTheme = {
	ELEVATION: {
		LAYOUT: {
			Z_INDEX: Number(elevationLayoutZIndex),
		},
		MODAL: {
			Z_INDEX: Number(elevationModalZIndex),
		},
		POPOVER: {
			Z_INDEX: Number(elevationPopoverZIndex),
		},
	},
};

export const LoggedInWebThemeProvider: FC = ({ children }) => {
	return (
		<ThemeProvider theme={GivingTheme}>
			<ThemeProvider theme={extendedTheme}>{children}</ThemeProvider>
		</ThemeProvider>
	);
};
