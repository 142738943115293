import * as React from 'react';
import { delay } from 'redux-saga';
import { cancelled, spawn, call, take } from 'redux-saga/effects';
import { VirtualTerminalFormViewModel } from './virtual-terminal-form-view-model';
import { IVirtualTerminalPayerViewModel } from '../../components/payer-search/payer-view-model';
import { Metrics } from '../../utils/metrics';
import { NumberParser, ICheckNumberParsingResult } from '../../utils/check-number-parser';
import { callInAction } from '../../../../Shared/utils/saga-utils';
import {
	Country,
	VirtualTerminalPaymentDetailsResponseModel,
	VirtualTerminalSearchPayersForCheckResponseModel,
	RecordedCheckModel,
	CommunityMemberLookupResult,
	VirtualTerminalExistingPaymentMethodsViewModel,
	VirtualTerminalRemovePaymentMethodResponseModel,
	VirtualTerminalContentOutOfDateModel,
	VirtualTerminalListingConfiguration,
} from '../../virtual-terminal-generated';
import { VirtualTerminalSagaDataService } from '../../saga/virtual-terminal-saga';
import { ICheckNumber, PaymentMethod } from '../../components/payment-entry/payment-entry-model';
import { communityMemberModelToViewModel, anonymousMember } from '../../utils/member-view-model-helper';
import { IMemberViewModel } from '../../components/member/member-view-model';
import {
	PaymentMethodRemoveDialog,
	PaymentMethodRemoveDialogUserAction,
} from '../../components/payment-entry/payment-method-remove-dialog-new';
import { userActionChannel, Channel } from '../../../../Shared/utils/user-action-channel';
import { ModalDialogCommander } from '../../../components/modal-dialog-commander';
import { handleVirtualTerminalError } from '../../utils/virtual-terminal-error-utils';

export interface IVirtualTerminalFormSagaContext {
	formViewModel: VirtualTerminalFormViewModel;
	dataService: VirtualTerminalSagaDataService;
}

const anonymousSearchTerm = 'anon';

export namespace VirtualTerminalFormSaga {
	export function handleResetForm(context: IVirtualTerminalFormSagaContext) {
		const { formViewModel } = context;
		formViewModel.resetForm();
	}

	export function handleSelectAnonymousPayer(context: IVirtualTerminalFormSagaContext) {
		Metrics.paymentEntryStarted();
		const { formViewModel } = context;
		formViewModel.setAnonymousPayer();
	}

	export function* handleOmniboxValueChange(context: IVirtualTerminalFormSagaContext, value: string): IterableIterator<any> {
		const { formViewModel } = context;
		const { listingStore: { selectedListingHomeCountry }, handleOmniboxValueChange, selectedPayer, searchValue } = formViewModel;

		const looksLikeCheckReaderOutput = NumberParser.looksLikeCheckReaderOutput(value);
		if (looksLikeCheckReaderOutput && selectedListingHomeCountry !== Country.US) {
			return;
		}

		if (value) {
			Metrics.paymentEntryStarted();
		}

		handleOmniboxValueChange(value);

		const payer = formViewModel.selectedPayer;

		if (payer && payer.name !== value) {
			yield callInAction(handleSelectedPayerChange, context, selectedPayer);
		}

		if (looksLikeCheckReaderOutput) {
			yield callInAction(checkReadingSaga, context, value);
		} else if (value && value.trim().length >= 2 && searchValue !== value) {
			// we don't need to start searching for payers if the current search result for that value or value is empty
			yield callInAction(payerSearchSaga, context, value);
		}
	}

	export function* handleSelectedPayerChange(context: IVirtualTerminalFormSagaContext, payer: IVirtualTerminalPayerViewModel): IterableIterator<any> {
		if (!payer) {
			return;
		}

		if (payer.isAnonymous) {
			handleSelectAnonymousPayer(context);
		} else {
			yield callInAction(loadPaymentDetails, context, payer);
		}
	}

	export function* handleLoadMorePayers(context: IVirtualTerminalFormSagaContext): IterableIterator<any> {
		const { formViewModel: { searchResult, searchValue, checkNumber, payersAccociatedWithCheckResult } } = context;

		if (searchResult) {
			const skip = searchResult.payers.length;
			yield callInAction(loadPayers, context, searchValue, skip);
		} else if (payersAccociatedWithCheckResult) {
			const skip = payersAccociatedWithCheckResult.payers.length;
			yield callInAction(loadPayersForCheck, context, checkNumber, skip);
		}
	}

	export function* handleViewAllExistingPaymentMethods(context: IVirtualTerminalFormSagaContext) {
		const { formViewModel, dataService } = context;
		const { listingStore, loadExistingPaymentMethodsStarted, loadExistingPaymentMethodsFinished, loadExistingPaymentMethodsCancelled } = formViewModel;
		const selectedListingId = Number(listingStore.selectedListingId);
		const selectedPayerId = Number(formViewModel.selectedPayer.id);

		loadExistingPaymentMethodsStarted();

		try {
			const response: VirtualTerminalExistingPaymentMethodsViewModel = yield dataService.getPayerAllExistingPaymentMethods({
				merchantId: selectedListingId,
				model: {
					CommunityMemberId: selectedPayerId
				}
			});
			loadExistingPaymentMethodsFinished(response);
		} catch (error) {
			loadExistingPaymentMethodsCancelled();
		} finally {
			if (yield cancelled()) {
				loadExistingPaymentMethodsCancelled();
			}
		}
	}

	export function* handleRemovePaymentMethod(context: IVirtualTerminalFormSagaContext, paymentMethod: PaymentMethod) {
		const { formViewModel, dataService } = context;
		const { removePaymentMethodStarted, removePaymentMethodFinished, removePaymentMethod, selectedPayer } = formViewModel;

		removePaymentMethodStarted();

		try {
			const response: VirtualTerminalRemovePaymentMethodResponseModel = yield dataService.removePaymentMethod({
				model: {
					CommunityMemberId: Number(selectedPayer.id),
					PaymentMethodIndex: Number(paymentMethod.key),
				}
			});

			if (response.ConfirmationRequired) {
				removePaymentMethodFinished();

				const actionChannel: Channel<PaymentMethodRemoveDialogUserAction> = userActionChannel();
				const removeDialog = React.createElement(PaymentMethodRemoveDialog, {
					userActionChannel: actionChannel,
					paymentMethod: paymentMethod,
					formViewModel,
				});

				ModalDialogCommander.showReactDialog(removeDialog).then(() => actionChannel.put(new PaymentMethodRemoveDialogUserAction.Cancel()));

				const userAction = yield take(actionChannel);

				if (userAction instanceof PaymentMethodRemoveDialogUserAction.Cancel) {
					return;
				}

				yield callInAction(removePaymentMethodConfirmed, context, paymentMethod);
				ModalDialogCommander.forceCloseCurrent();
			} else {
				handleContentOutOfDate(context, response.ContentOutOfDate);
				handleVirtualTerminalError(response.ErrorMessage);
				removePaymentMethod(paymentMethod);
			}
		} finally {
			removePaymentMethodFinished();
		}
	}

	function* removePaymentMethodConfirmed(context: IVirtualTerminalFormSagaContext, paymentMethod: PaymentMethod): IterableIterator<any> {
		const { formViewModel, dataService } = context;
		const { removePaymentMethodStarted, removePaymentMethodFinished, selectedPayer, removePaymentMethod } = formViewModel;

		removePaymentMethodStarted();

		try {
			const response: VirtualTerminalRemovePaymentMethodResponseModel = yield dataService.removePaymentMethodConfirmed({
				model: {
					CommunityMemberId: Number(selectedPayer.id),
					PaymentMethodIndex: Number(paymentMethod.key)
				}
			});

			handleContentOutOfDate(context, response.ContentOutOfDate);
			handleVirtualTerminalError(response.ErrorMessage);

			removePaymentMethod(paymentMethod);
		} finally {
			removePaymentMethodFinished();
		}
	}

	export function* handleContentOutOfDate(context: IVirtualTerminalFormSagaContext,
		contentOutOfDateModel: VirtualTerminalContentOutOfDateModel,
		paymentMethod: PaymentMethod = null): IterableIterator<any> {

		if (!contentOutOfDateModel) {
			return;
		}

		const { formViewModel: { removePaymentMethod } } = context;
		const { CommunityMemberNotFound, MerchantListingOutOfDate, PaymentMethodNotFound } = contentOutOfDateModel;

		if (CommunityMemberNotFound) {
			yield callInAction(handleOmniboxValueChange, context, '');
		}

		if (MerchantListingOutOfDate) {
			yield callInAction(reloadListingConfiguration, context);
		}

		if (PaymentMethodNotFound) {
			removePaymentMethod(paymentMethod);
		}
	}

	function* payerSearchSaga(context: IVirtualTerminalFormSagaContext, input: string): IterableIterator<any> {
		// debouncing
		const searchDelay = 500;
		yield call(delay, searchDelay);
		yield callInAction(loadPayers, context, input, 0);
	}


	function* loadPaymentDetails(context: IVirtualTerminalFormSagaContext, payer: IVirtualTerminalPayerViewModel): IterableIterator<any> {
		const { formViewModel, dataService } = context;
		const {
			loadPaymentDetailsStarted,
			loadPaymentDetailsCancelled,
			loadPaymentDetailsFinished,
			updateSelectedMember,
			loadExistingPaymentMethodsFinished,
			listingStore,
		} = formViewModel;
		const { selectedListingId, selectedListingConfiguration } = listingStore;

		loadPaymentDetailsStarted(!!formViewModel.checkNumber);

		try {
			const response: VirtualTerminalPaymentDetailsResponseModel = yield dataService.getPayerPaymentDetails({
				merchantId: Number(selectedListingId),
				model: {
					CommunityMemberId: Number(payer.id),
					MerchantVersion: (selectedListingConfiguration && selectedListingConfiguration.MerchantVersion),
				}
			});

			handleContentOutOfDate(context, response.ContentOutOfDate);
			handleVirtualTerminalError(response.ErrorMessage);

			if (response.ListingConfiguration) {
				// the current listing configuration has not been fetched or is out of date
				listingStore.loadingConfigurationFinished(selectedListingId, response.ListingConfiguration);
			}

			updateSelectedMember(response.CommunityMember);
			loadExistingPaymentMethodsFinished(response.ExistingPaymentMethods);
			loadPaymentDetailsFinished(response.PaymentDetails);
		} catch (error) {
			loadPaymentDetailsCancelled();
		} finally {
			if (yield cancelled()) {
				loadPaymentDetailsCancelled();
			}
		}
	}

	function* checkReadingSaga(context: IVirtualTerminalFormSagaContext, input: string): IterableIterator<any> {
		const { formViewModel } = context;
		const { listingStore: { selectedListingId }, checkReadingStarted, checkReadingFailed } = formViewModel;

		checkReadingStarted();

		const parsingResult: ICheckNumberParsingResult = yield callInAction(readCheckNumber, context, input);

		if (!parsingResult.success) {
			checkReadingFailed();
			return yield spawn(logCheckReadFailure, context, Number(selectedListingId), parsingResult.cleanedCheckReaderOutput);
		}

		yield callInAction(loadPayersForCheck, context, parsingResult, 0);
	}

	function* readCheckNumber(context: IVirtualTerminalFormSagaContext, input: string): IterableIterator<any> {
		yield call(delay, 500, true);
		return NumberParser.parseReadCheckNumber(input);
	}

	function* loadPayers(context: IVirtualTerminalFormSagaContext, input: string, skip: number): IterableIterator<any> {
		const { formViewModel, dataService } = context;
		const { listingStore, setLoadMoreInProgress, searchResult, searchPayersComplete } = formViewModel;
		const loadMore = skip > 0;

		if (loadMore) {
			setLoadMoreInProgress(true);
		}

		try {
			const response: CommunityMemberLookupResult = yield dataService.searchPayers({
				model: {
					Query: input,
					Skip: skip
				}
			});

			const members = response.Results as IMemberViewModel[];

			if (listingStore.selectedListingSupportsCash && input.toLowerCase() === anonymousSearchTerm) {
				members.unshift(anonymousMember);
			}

			const payers = members.map(communityMemberModelToViewModel);

			searchPayersComplete(input, {
				error: null,
				payers: loadMore ? searchResult.payers.concat(payers) : payers,
				hasMorePages: response.HasMorePages,
				appendedResults: loadMore,
			});
		} catch (error) {
			if (loadMore) {
				throw error;
			}

			searchPayersComplete(input, {
				error: error,
				payers: [],
				hasMorePages: false,
				appendedResults: loadMore,
			});
		} finally {
			if (loadMore) {
				setLoadMoreInProgress(false);
			}
		}
	}

	function* loadPayersForCheck(context: IVirtualTerminalFormSagaContext, check: ICheckNumber, skip: number): IterableIterator<any> {
		const { formViewModel, dataService } = context;
		const { setLoadMoreInProgress, selectMemberMatch, searchPayersForCheckComplete, payersAccociatedWithCheckResult, listingStore } = formViewModel;
		const { selectedListingConfiguration, selectedListingId } = listingStore;
		const { accountNumber, routingNumber, checkNumber, cleanedCheckReaderOutput } = check;
		const loadMore = skip > 0;

		if (loadMore) {
			setLoadMoreInProgress(true);
		}

		try {
			const response: VirtualTerminalSearchPayersForCheckResponseModel = yield dataService.searchPayersForCheck({
				merchantId: Number(selectedListingId),
				model: {
					Check: <RecordedCheckModel>{
						AccountNumber: accountNumber,
						RoutingNumber: routingNumber,
						CheckNumber: checkNumber,
						CleanedCheckReaderOutput: cleanedCheckReaderOutput
					},
					Skip: skip,
					MerchantVersion: selectedListingConfiguration && selectedListingConfiguration.MerchantVersion
				}
			});

			handleVirtualTerminalError(response.ErrorMessage);

			if (response.ListingConfiguration) {
				// the current listing configuration has not been fetched or is out of date
				listingStore.loadingConfigurationFinished(selectedListingId, response.ListingConfiguration);
			}

			const searchResult = response.SearchResult;
			const matchedCommunityMemberId = response.MatchedCommunityMemberId;

			selectMemberMatch(searchResult.Results, matchedCommunityMemberId);

			const viewModels = searchResult.Results.map(communityMemberModelToViewModel);

			searchPayersForCheckComplete(check, {
				error: null,
				payers: loadMore ? payersAccociatedWithCheckResult.payers.concat(viewModels) : viewModels,
				hasMorePages: searchResult.HasMorePages,
				appendedResults: loadMore,
			}, response.PaymentDetails);
		} catch (error) {
			if (loadMore) {
				throw error;
			}
			formViewModel.checkReadingFailed();
		} finally {
			if (loadMore) {
				setLoadMoreInProgress(false);
			}
		}
	}

	function* reloadListingConfiguration(context: IVirtualTerminalFormSagaContext) {
		const { formViewModel: { listingStore }, dataService } = context;
		const { loadingConfigurationStarted, loadingConfigurationFinished, loadingConfigurationCancelled, selectedListingId } = listingStore;

		loadingConfigurationStarted();

		try {
			const response: VirtualTerminalListingConfiguration = yield dataService.getListingConfiguration({
				merchantId: Number(selectedListingId)
			});

			loadingConfigurationFinished(selectedListingId, response);
		} catch (error) {
			loadingConfigurationCancelled();
			throw error;
		} finally {
			if (yield cancelled()) {
				loadingConfigurationCancelled();
			}
		}
	}

	function* logCheckReadFailure(context: IVirtualTerminalFormSagaContext, merchantId: number, cleanedCheckReaderOutput: string): IterableIterator<any> {
		const { dataService } = context;
		return yield dataService.logFailedCheckRead({ merchantId, cleanedCheckReaderOutput });
	}
}
