import {Dropdown} from '../components/dropdown';
import {FlyoutHandler} from '../components/flyouthandler';
import {Datepicker} from'../components/datepicker';
import {TooltipOld} from '../components/tooltip-old';
import { initModalDialogCommander } from '../components/modal-dialog-commander';
import {Tables} from '../components/tables';
import {PreventDoubleClick} from '../components/prevent-double-click';
import {VisibilityToggler} from '../components/visibility-toggler';
import {MoreFiltersButton} from '../components/more-filters-button';
import {TipBox} from '../components/tipboxes';
import FancyLogo from './fancy-logo';
import {FloatingLabel} from '../components/floating-label';
import {popupHandler, PopupHandler} from '../components/popup-helper';

export class Layout {
	flyoutHandler: FlyoutHandler;
	tables: Tables;
	fancyLogo: FancyLogo;
	popupHandler: PopupHandler;

	dropdowns: Array<Dropdown> = [];
	datepickers: Array<Datepicker> = [];
	tooltips: Array<TooltipOld> = [];


	constructor() {
		this.bindDropdowns();
		this.bindDatepickers();
		this.bindFlyouts();
		this.bindTooltipComponents();

		initModalDialogCommander();
		this.fancyLogo = FancyLogo.Init();
		this.tables = new Tables();
		this.popupHandler = popupHandler.init();

		PreventDoubleClick.init();
		VisibilityToggler.init();
		MoreFiltersButton.bindAll();
		TipBox.bindAll();
		FloatingLabel.bindAll();

	}

	bindFlyouts() {
		let flyouts = [
			{
				openLink: '#menu-open',
				closeLink: '.sidebar-close',
				panel: '.sidebar'
			},
			{
				openLink: '[data-pp-at-logout]',
				closeLink: '#profile-close',
				panel: '#profile-flyout'
			}
		];

		this.flyoutHandler = new FlyoutHandler(flyouts);
	}

	bindDropdowns() {
		$('.dropdown').each((i, element) => {
			this.dropdowns.push(new Dropdown(element));
		});
	}

	bindDatepickers() {
		$('[data-provide=datepicker]').each((i, element) => {
			this.datepickers.push(new Datepicker(element));
		});
	}

	bindTooltipComponents() {
		$('[data-toggle=tooltip]').each((i, element) => {
			this.tooltips.push(new TooltipOld(element));
		});
	}

	bindTooltip = (element: Element | Text, tooltipClassName: string = null) => {
		const tooltip = new TooltipOld(element, tooltipClassName);
		this.tooltips.push(tooltip);
		return tooltip;
	}

	unbindTooltip = (tooltip: TooltipOld) => {
		const index = this.tooltips.indexOf(tooltip);
		if(index > -1) {
			this.tooltips.splice(index, 1);
		}
	}
}

export function layout(): Layout {
	if (typeof window !== 'undefined') {
		const layout: Layout = window.__PushpayLoggedInWeb = window.__PushpayLoggedInWeb || new Layout();
		return layout;
	}

	return null;
}
