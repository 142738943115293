import { createStringEnum } from '../../../../../Shared/state-machine/rx-state-machine-factory';


enum CampaignsState {
	Init,
	Welcome,
	CampaignsManagement,
	Draft,
	ActiveOrScheduled,
	Closed,
	Idle,
	LoadingMore,
	Collapsible,
	Collapsed,
	Expanded,
	Managing,
	PollingCollectedAmounts,
	ShowingError,
	On,
	Off,
}

const State = createStringEnum(CampaignsState);

export default State;
