import * as React from 'react';
import { observer } from 'mobx-react';
import { TransactionExportButton, ExportAction } from '../../loggedinweb-generated';
import { DropDownMenuController } from '../hoc-behavior/dropdown-menu-controller';

import * as styles from './export-dropdown.less';

@observer
export class ExportDropDown extends React.Component<{
	exportButtons: TransactionExportButton[],
	handleExport: (action: ExportAction) => void
}> {
	state = {
		open: false
	};

	render() {
		return (
			<DropDownMenuController open={this.state.open} onRequestClose={this.handleRequestClose} onRequestOpen={this.handleRequestOpen} openOnFocus={false}>
				<div className={styles.exportDropdown}>
					<button type="button" className={`btn btn-ghost dropdown-toggle down-arrow ${styles.exportButton}`} data-toggle="dropdown" onClick={this.onClickHandler}>Export</button>
					<div onMouseDown={this.preventLosingFocus}>
						<ul className={`dropdown-menu${this.state.open ? ' open' : ''} ${styles.exportMenu}`}>
							{this.props.exportButtons.map((exportButton) =>
								<ExportButton key={exportButton.Label} exportButton={exportButton} handleExport={this.props.handleExport}/>
							)}
						</ul>
					</div>
				</div>
			</DropDownMenuController>
		);
	}

	private onClickHandler = () => {
		if (this.state.open) {
			this.handleRequestClose();
		}
	}

	private handleRequestOpen = () => {
		this.toggleExportButton(true);
	}

	private handleRequestClose = () => {
		this.toggleExportButton(false);
	}

	private toggleExportButton = (open: boolean) => {
		this.setState(s => ({ open }));
	}

	private preventLosingFocus = (e: React.MouseEvent<HTMLDivElement>): void => {
		e.preventDefault();
	}
}

@observer
class ExportButton extends React.Component<{ exportButton: TransactionExportButton, handleExport: (action: ExportAction) => void }> {
	render() {
		const { Description, Label } = this.props.exportButton;
		return (
			<li><button type="submit" className={`btn btn-link ${styles.exportButton}`} title={Description} onClick={this.onClickHandler}>{Label}</button></li>
		);
	}

	private onClickHandler = () => {
		const { Action } = this.props.exportButton;
		this.props.handleExport(Action);
	}
}
