import * as React from 'react';
import { CampaignOverviewTabViewModel } from '../../pages/campaign-details/campaign-details-main-view-model';
import { Tab, TabContainer } from '../../../components/tab/tab';
import { CampaignDetailsOverview } from '../campaign-details-overview/campaign-details-overview';

export enum CampaignDetailsTabIdentifiers {
	'Overview',
}
export interface ICampaignDetailsTabsProps {
	currentTab: CampaignDetailsTabIdentifiers;
	overviewViewModel: CampaignOverviewTabViewModel;
}

export class CampaignDetailsTabs extends React.Component<ICampaignDetailsTabsProps> {
	render() {
		const { currentTab, overviewViewModel } = this.props;
		return (
			<TabContainer currentTabId={currentTab}>
				<Tab tabId={CampaignDetailsTabIdentifiers.Overview} title="Overview" acceptanceTestTargetId="Overview tab">
					<CampaignDetailsOverview vm={overviewViewModel} />
				</Tab>
			</TabContainer>
		);
	}
}
