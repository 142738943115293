import * as React from 'react';
import * as ReactDOM from 'react-dom';

export interface IModalDialogControllerProps {
	isVisible: boolean;
}

export interface IModalDialogControllerState {
	isCurrentlyVisible: boolean;
}

export class ModalDialogController extends React.Component<IModalDialogControllerProps, {}> {
	private container: HTMLDivElement;

	modal = () => {
		if (!this.props.isVisible) {
			return (<div></div>);
		}

		return (
			<div>
				<div className="modal-backdrop fade in"></div>
				<div className="modal fade visible in" tabIndex={-1} role="dialog">
					<div className="modal-dialog" role="document">
						{this.props.children}
					</div>
				</div>
			</div>
		);
	}

	componentDidMount() {
		this.container = document.createElement('div');
		this.container.className = 'modal-container';
		document.body.appendChild(this.container);
		this.update();
	}

	componentWillUnmount() {
		if (this.container) {
			document.body.removeChild(this.container);
		}
	}

	componentDidUpdate() {
		this.update();
	}

	update = () => {
		ReactDOM.unstable_renderSubtreeIntoContainer(this, this.modal(), this.container);

		if (this.props.isVisible) {
			document.body.classList.add('modal-open');
		} else {
			document.body.classList.remove('modal-open');
		}
	}

	render() {
		return null;
	}
}
