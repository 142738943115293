import * as React from 'react';
import { observer } from 'mobx-react';
import { ModalDialog, IModalDialogButton } from '../../components/modal-dialog';
import { Models } from '../check-deposit-generated';
import { Button } from '../../components/button';
import { DateLabel } from '../../components/date-label';

@observer
export class BatchAlreadyAllocatedDialog extends React.Component<{ model: Models.BatchAlreadyAllocatedModel }, { isReloading?: boolean }> {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { AllocatedBy, AllocatedOn } = this.props.model;
		const { isReloading } = this.state;

		const buttons: (IModalDialogButton | JSX.Element)[] = [
			<Button type="submit" key="1" className="btn btn-primary" onClick={this.reload} isProcessingRequest={isReloading}>
				Reload page
			</Button>
		];
		const messageFirstPart = AllocatedBy ? `${AllocatedBy} completed allocation ` : 'The batch allocation was completed ';

		return (
			<ModalDialog title="The batch is already allocated" buttons={buttons} inProgress={true} showAlert={true} hideCloseButton={true}>
				<p className="modal-text">
					{messageFirstPart}<DateLabel date={AllocatedOn} />{`. Please reload this page to see the most up-to-date information.`}
				</p>
			</ModalDialog>
		);
	}

	private reload = () => {
		this.setState({ isReloading: true });
		location.reload(true);
	}
}
