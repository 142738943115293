

// ReSharper disable InconsistentNaming
// ReSharper disable StringLiteralWrongQuotes
/* tslint:disable:no-use-before-declare quotemark max-line-length */
import { IApiAction } from '../utils/data-service';
export interface SendStatementsViewModel {
	ReplyTo: string;
	EmailSubject: string;
	EmailHeading: string;
	EmailText: string;
	EmailVideoUrl: string;
	ExcludeStatementsLessThan: number;
	StatementsGenerated: number;
	MembersWithNoEmails: number;
	MembersWithNoPhysicals: number;
	Status: GivingStatementRunStatus;
	EmailBranding: EmailBranding;
	LastUpdated: Date;
	SupportsNoEmailDownload: boolean;
	DefaultEmailTextKey: string;
	HideTotalTaxDeductible: boolean;
}
export interface DownloadViewModel {
	Zips: PdfZipDescription[];
}
export interface PdfZipDescription {
	FromName: string;
	ToName: string;
	NumberOfStatementsInZip: number;
	DownloadUrl: string;
	DoesNotSupportFromAndToName: boolean;
}
export interface ConfigureRunViewModel {
	PeriodTypes: string[];
	AvailableQuarters: number[];
	AvailableYears: number[];
	SelectedType: GivingStatementsPeriodType;
	SelectedPeriodFrom: Date;
	SelectedPeriodTo: Date;
	PhoneNumer: string;
	EmailAddress: string;
	StatementName: string;
	CurrentDate: Date;
	Status: GivingStatementRunStatus;
	NonCashAvailable: boolean;
	NonCashIncluded: boolean;
	EinIncluded: boolean;
	CustomerAddressIncluded: boolean;
	Ein: string;
	CustomerAddress: string;
	HasBeenGenerated: boolean;
	HasBeenPublished: boolean;
	GivingUnitType: GivingUnitType;
	ContributionsThreshold: number;
}
export enum GivingUnitType {
	Unknown = 0, CommunityMember = 1, Household = 2, Organization = 3,
}
export interface BatchCompletionResponse {
	PercentComplete: number;
	Finished: boolean;
}
export interface GivingStatementHistoricSummaryListViewModel {
	RunId: number;
	Name: string;
	Status: string;
	Count: number;
}
export interface CommunityMemberFundStatsResponse {
	CommunityMemberTotal: number;
	CommunityMembersWithNoEmail: number;
	CommunityMembersWithNoPhysicalAddress: number;
	DisabledForCampusOrg: boolean;
}
export interface UpdateRunResponse {
	Updated: boolean;
	IsDuplicatePeriod: boolean;
}
export interface UpdateRunRequest {
	PeriodType: GivingStatementsPeriodType;
	To: Date;
	From: Date;
	StatementName: string;
	ContactEmail: string;
	ContactPhoneNumber: string;
	NonCashIncluded: boolean;
	EinIncluded: boolean;
	CustomerAddressIncluded: boolean;
	GivingUnitType: GivingUnitType;
	ContributionsThreshold: number;
}
export interface UpdateStatementRequest {
	ReplyTo: string;
	EmailSubject: string;
	EmailHeading: string;
	EmailText: string;
	EmailVideoUrl: string;
	ExcludeStatementsLessThan: number;
	HideTotalTaxDeductible: boolean;
}
export interface EmailBranding {
	OrganizationName: string;
	OrganizationLegalName: string;
	ShowLegalCopy: boolean;
	PrimaryColor: string;
	MerchantLogoUrl: string;
	PushpayLogoUrl: string;
	HasCustomBranding: boolean;
}
export enum GivingStatementRunStatus {
	Unknown = 0, Configured = 1, Generating = 2, Generated = 3, Issued = 4, Failed = 5, Draft = 6, Issuing = 7,
}
export enum GivingStatementsPeriodType {
	Quarterly = 0, Annual = 1, Custom = 2,
}
export interface SendSummaryViewModel {
	StatementsGenerated: number;
	GeneratedDate: Date;
	StatementName: string;
	StatementsEmailed: number;
	StatementsDownloaded: number;
	GivingUnitType: string;
	MembersWithoutEmail: number;
	StatementsSentOn: Date;
}
export interface CustomGivingStatementsViewModel {
	CommunityMemberUri: string;
	TransactionImportUri: string;
	FundsUri: string;
	GiftEntryUri: string;
	PaymentLabel: PaymentLabel;
	CapUrl: string;
}
export interface PaymentLabel {
	NounSentenceCase: string;
	NounPluralSentenceCase: string;
	VerbSentenceCase: string;
	VerbAppliedSentenceCase: string;
	NounLowerCase: string;
	NounPluralLowerCase: string;
	VerbLowerCase: string;
	VerbAppliedLowerCase: string;
	VerbPresentTenseSentenceCase: string;
	VerbPresentTenseLowerCase: string;
	VerbPastTenseSentenceCase: string;
	VerbPastTenseLowerCase: string;
	PayerSentenceCase: string;
	PayerLowerCase: string;
	PayerPluralSentenceCase: string;
	PayerPluralLowerCase: string;
	ActionSentenceCase: string;
	ActionLowerCase: string;
	ActionPluralSentenceCase: string;
	ActionPluralLowerCase: string;
	NonCashNounSentenceCase: string;
	NonCashNounLowerCase: string;
	NonCashPluralSentenceCase: string;
	NonCashPluralLowerCase: string;
	TextGivingFeatureName: string;
	GiftEntryFeatureName: string;
}
export const GivingStatementsApiConfig = {
	defaultBaseUrl: () => location.pathname.match(new RegExp('/pushpay/portal/\\d+', 'i'))[0],
	actions: {
		communityMemberFundStats: <IApiAction<{start: Date, end: Date, includeNonCash: boolean}, CommunityMemberFundStatsResponse>>{
			url: (data) => `/custom-giving-statements/community-and-fund-stats`,
		},
		taxDeductibleFunds: <IApiAction<{id: number}, string[]>>{
			url: (data) => `/custom-giving-statements/tax-deductible-funds`,
		},
		createRun: <IApiAction<{}, number>>{
			url: (data) => `/custom-giving-statements/create-run`,
		},
		historicSummaryList: <IApiAction<{}, GivingStatementHistoricSummaryListViewModel[]>>{
			url: (data) => `/custom-giving-statements/historic-summary-list`,
		},
		newCommunityMemberCount: <IApiAction<{}, number>>{
			url: (data) => `/custom-giving-statements/new-community-member-count`,
		},
		generationStatus: <IApiAction<{id: number}, BatchCompletionResponse>>{
			url: (data) => `/custom-giving-statements/${data.id}/generation-status`,
		},
		configure: <IApiAction<{id: number}, ConfigureRunViewModel>>{
			url: (data) => `/custom-giving-statements/${data.id}/configure`,
		},
		deleteRun: <IApiAction<{runId: number}, boolean>>{
			url: (data) => `/custom-giving-statements/${data.runId}/delete`,
		},
		getSendAndDownloadData: <IApiAction<{id: number, onlyWithNoEmailAddress: boolean}, DownloadViewModel>>{
			url: (data) => `/custom-giving-statements/${data.id}/download-links`,
		},
		beginGenerating: <IApiAction<{id: number}, boolean>>{
			url: (data) => `/custom-giving-statements/${data.id}/begin-generating`,
		},
		updateSendStatements: <IApiAction<{id: number, updateModel: UpdateStatementRequest}, boolean>>{
			url: (data) => `/custom-giving-statements/${data.id}/update-statements`,
		},
		statementsLoad: <IApiAction<{id: number}, SendStatementsViewModel>>{
			url: (data) => `/custom-giving-statements/${data.id}/statements-load`,
		},
		triggerEmailSend: <IApiAction<{id: number}, boolean>>{
			url: (data) => `/custom-giving-statements/${data.id}/trigger-email-send`,
		},
		sendPreviewEmail: <IApiAction<{id: number}, boolean>>{
			url: (data) => `/custom-giving-statements/${data.id}/send-preview-email`,
		},
		setAsPublished: <IApiAction<{id: number}, boolean>>{
			url: (data) => `/custom-giving-statements/${data.id}/set-as-published`,
		},
		sendSummary: <IApiAction<{id: number}, SendSummaryViewModel>>{
			url: (data) => `/custom-giving-statements/${data.id}/send-summary`,
		},
		updateRunForNewModal: <IApiAction<{ id: number, isIntended: string, updateModel: UpdateRunRequest }, UpdateRunResponse>>{
			url: (data) => `/custom-giving-statements/${data.id}/update-run/${data.isIntended}`,
		},
	},
	urls: {
	},
};
/* tslint:enable:no-use-before-declare quotemark max-line-length */
// ReSharper restore InconsistentNaming
// ReSharper restore StringLiteralWrongQuotes
// ReSharper disable InconsistentNaming
// ReSharper disable StringLiteralWrongQuotes
/* tslint:disable:no-use-before-declare quotemark max-line-length */
export namespace ModelMetadata {
	export class MetadataArrayOf<T> {
		metadata: T;

		constructor(metadata: T) {
			this.metadata = metadata;
		}

		at(index: number): T {
			return this.metadata;
		}
	}

	export interface IPropertyMetadata {
		propertyName: string;
		displayName?: string;
		placeholder?: string;
		validationRules?: { [rule: string]: any };
		modelMetadata?: { [name: string] :IPropertyMetadata};
	}

	export let CommunityMemberFundStatsResponse = {
		CommunityMemberTotal: {
			propertyName: "CommunityMemberTotal",
			validationRules: {
				number: {
					errorMessage: "The field CommunityMemberTotal must be a number.",
				},
				required: {
					errorMessage: "The CommunityMemberTotal field is required.",
				},
			},
		},
		CommunityMembersWithNoEmail: {
			propertyName: "CommunityMembersWithNoEmail",
			validationRules: {
				number: {
					errorMessage: "The field CommunityMembersWithNoEmail must be a number.",
				},
				required: {
					errorMessage: "The CommunityMembersWithNoEmail field is required.",
				},
			},
		},
		CommunityMembersWithNoPhysicalAddress: {
			propertyName: "CommunityMembersWithNoPhysicalAddress",
			validationRules: {
				number: {
					errorMessage: "The field CommunityMembersWithNoPhysicalAddress must be a number.",
				},
				required: {
					errorMessage: "The CommunityMembersWithNoPhysicalAddress field is required.",
				},
			},
		},
		DisabledForCampusOrg: {
			propertyName: "DisabledForCampusOrg",
			validationRules: {
				required: {
					errorMessage: "The DisabledForCampusOrg field is required.",
				},
			},
		},
	};
	export let UpdateRunRequest = {
		PeriodType: {
			propertyName: "PeriodType",
			validationRules: {
				required: {
					errorMessage: "Please a period type",
				},
			},
		},
		To: {
			propertyName: "To",
			displayName: "To date",
			validationRules: {
				date: {
					errorMessage: "The field To date must be a date.",
				},
				required: {
					errorMessage: "Please enter a to date",
				},
			},
		},
		From: {
			propertyName: "From",
			displayName: "From date",
			validationRules: {
				date: {
					errorMessage: "The field From date must be a date.",
				},
				required: {
					errorMessage: "Please enter a from date",
				},
			},
		},
		StatementName: {
			propertyName: "StatementName",
			displayName: "Statement name",
			validationRules: {
				length: {
					errorMessage: "The field Statement name must be a string with a maximum length of 45.",
					parameters: {
						max: 45,
					},
				},
				required: {
					errorMessage: "Please enter a name for the statement",
				},
			},
		},
		ContactEmail: {
			propertyName: "ContactEmail",
			displayName: "Contact email address",
			validationRules: {
				email: {
					errorMessage: "The contact email address is not a valid email address",
				},
				length: {
					errorMessage: "The field Contact email address must be a string with a maximum length of 100.",
					parameters: {
						max: 100,
					},
				},
				required: {
					errorMessage: "Please enter an email address",
				},
			},
		},
		ContactPhoneNumber: {
			propertyName: "ContactPhoneNumber",
			displayName: "Contact phone number",
			validationRules: {
				length: {
					errorMessage: "The field Contact phone number must be a string with a maximum length of 30.",
					parameters: {
						max: 30,
					},
				},
				regex: {
					errorMessage: "The contact phone number is not a valid phone number",
					parameters: {
						pattern: "^\\+?[0-9-() ]{7,20}$",
					},
				},
				required: {
					errorMessage: "Please enter a phone number",
				},
			},
		},
		NonCashIncluded: {
			propertyName: "NonCashIncluded",
			displayName: "Include Non-Cash",
			validationRules: {
				required: {
					errorMessage: "The Include Non-Cash field is required.",
				},
			},
		},
		EinIncluded: {
			propertyName: "EinIncluded",
			displayName: "Include Employer Identification Number (EIN)",
			validationRules: {
				required: {
					errorMessage: "The Include Employer Identification Number (EIN) field is required.",
				},
			},
		},
		CustomerAddressIncluded: {
			propertyName: "CustomerAddressIncluded",
			displayName: "Include Your Address",
			validationRules: {
				required: {
					errorMessage: "The Include Your Address field is required.",
				},
			},
		},
		GivingUnitType: {
			propertyName: "GivingUnitType",
			displayName: "Publish format",
			validationRules: {
				required: {
					errorMessage: "The Publish format field is required.",
				},
			},
		},
		ContributionsThreshold: {
			propertyName: "ContributionsThreshold",
			displayName: "Statement Amount Starting Range",
			validationRules: {
				number: {
					errorMessage: "The field Statement Amount Starting Range must be a number.",
				},
			},
		},
	};
	export let UpdateStatementRequest = {
		ReplyTo: {
			propertyName: "ReplyTo",
			displayName: "Reply to",
			validationRules: {
				email: {
					errorMessage: "The reply to address is not a valid email address",
				},
				length: {
					errorMessage: "The field Reply to must be a string with a maximum length of 100.",
					parameters: {
						max: 100,
					},
				},
				required: {
					errorMessage: "The Reply to field is required.",
				},
			},
		},
		EmailSubject: {
			propertyName: "EmailSubject",
			displayName: "Subject",
			validationRules: {
				length: {
					errorMessage: "The field Subject must be a string with a maximum length of 200.",
					parameters: {
						max: 200,
					},
				},
				required: {
					errorMessage: "The Subject field is required.",
				},
			},
		},
		EmailHeading: {
			propertyName: "EmailHeading",
			displayName: "Heading",
			validationRules: {
				length: {
					errorMessage: "The field Heading must be a string with a maximum length of 200.",
					parameters: {
						max: 200,
					},
				},
				required: {
					errorMessage: "The Heading field is required.",
				},
			},
		},
		EmailText: {
			propertyName: "EmailText",
			displayName: "Message",
			validationRules: {
				length: {
					errorMessage: "The field Message must be a string with a maximum length of 10000.",
					parameters: {
						max: 10000,
					},
				},
				required: {
					errorMessage: "The Message field is required.",
				},
			},
		},
		EmailVideoUrl: {
			propertyName: "EmailVideoUrl",
			displayName: "Video URL",
			validationRules: {
				length: {
					errorMessage: "The field Video URL must be a string with a maximum length of 200.",
					parameters: {
						max: 200,
					},
				},
			},
		},
		ExcludeStatementsLessThan: {
			propertyName: "ExcludeStatementsLessThan",
			validationRules: {
				number: {
					errorMessage: "The field ExcludeStatementsLessThan must be a number.",
				},
				required: {
					errorMessage: "The ExcludeStatementsLessThan field is required.",
				},
			},
		},
		HideTotalTaxDeductible: {
			propertyName: "HideTotalTaxDeductible",
			validationRules: {
				required: {
					errorMessage: "The HideTotalTaxDeductible field is required.",
				},
			},
		},
	};
	export let EmailBranding = {
		OrganizationName: {
			propertyName: "OrganizationName",
		},
		OrganizationLegalName: {
			propertyName: "OrganizationLegalName",
		},
		ShowLegalCopy: {
			propertyName: "ShowLegalCopy",
			validationRules: {
				required: {
					errorMessage: "The ShowLegalCopy field is required.",
				},
			},
		},
		PrimaryColor: {
			propertyName: "PrimaryColor",
		},
		MerchantLogoUrl: {
			propertyName: "MerchantLogoUrl",
		},
		PushpayLogoUrl: {
			propertyName: "PushpayLogoUrl",
		},
		HasCustomBranding: {
			propertyName: "HasCustomBranding",
			validationRules: {
				required: {
					errorMessage: "The HasCustomBranding field is required.",
				},
			},
		},
	};
}
/* tslint:enable:no-use-before-declare quotemark max-line-length */
// ReSharper restore InconsistentNaming
// ReSharper restore StringLiteralWrongQuotes
