import moment from 'moment';
import { LocalStorageHelper } from './localstoragehelper';
import { DefaultSettingsModel } from '../virtual-terminal/components/default-settings/default-settings-model';

export module StorageHelper {
	const selectedListingIdKey = 'selectedListingId';
	const givenOnStorageKey = 'givenOn';
	const batchDefaultSettingsStorageKeyPrefix = 'batchDefaultSettings_';
	const batchDefaultSettingsStorageKeyPrefixRegex = (batchId: string = '') => new RegExp(`^${batchDefaultSettingsStorageKeyPrefix}${batchId}`);

	const serializedDateFormat = 'YYYY-MM-DD';
	const serializedTimestampFormat = 'YYYY-MM-DDTHH-mm';
	const hoursThatGivenOnIsValidFor = 12;
	const daysThatDefaultSettingsAreValidFor = 30;

	interface GivenOnStorageItem {
		givenOnDate: string;
		timestamp: string;
	}

	export function getBatchDefaultSettingsStorageKeyPrefixForTesting() {
		return batchDefaultSettingsStorageKeyPrefix;
	}

	export function setDefaultSettingsForBatch(batchId: string, defaultSettingsModel: DefaultSettingsModel) {
		const existingDefaultSettingsKey = getDefaultSettingsKeyForBatch(batchId);
		if (existingDefaultSettingsKey) {
			LocalStorageHelper.getLocalStorage().removeItem(existingDefaultSettingsKey);
		}
		const newDefaultSettingsKey = `${batchDefaultSettingsStorageKeyPrefix}${batchId}_${moment().format(serializedDateFormat)}`;
		LocalStorageHelper.getLocalStorage().setItem(newDefaultSettingsKey, JSON.stringify(defaultSettingsModel));
	}

	export function getDefaultSettingsForBatch(batchId: string): DefaultSettingsModel {
		const defaultSettingsKey = getDefaultSettingsKeyForBatch(batchId);
		return defaultSettingsKey && JSON.parse(LocalStorageHelper.getLocalStorage().getItem(defaultSettingsKey), reviveGivenOn);
	}

	function getDefaultSettingsKeyForBatch(batchId: string) {
		const [defaultSettingsKey] = Object.keys(LocalStorageHelper.getLocalStorage())
			.filter(x => batchDefaultSettingsStorageKeyPrefixRegex(batchId).test(x));

		return defaultSettingsKey;
	}

	function reviveGivenOn(key: keyof DefaultSettingsModel, value: any) {
		if (key === 'DefaultGivenOn') {
			const dateValue = moment(value);
			return dateValue.isValid ? dateValue.toDate() : null;
		}
		return value;
	}

	export function cleanupExpiredDefaultSettings() {
		Object.keys(LocalStorageHelper.getLocalStorage())
			.filter(x => batchDefaultSettingsStorageKeyPrefixRegex().test(x))
			.forEach(defaultSettingsKey => {
				const batchDefaultSettingsDate = moment(defaultSettingsKey.split('_').pop(), serializedDateFormat);

				if (batchDefaultSettingsDate.isValid() && !defaultSettingsAreExpired(batchDefaultSettingsDate)) {
					return;
				}
				LocalStorageHelper.getLocalStorage().removeItem(defaultSettingsKey);
			});
	}

	function defaultSettingsAreExpired(batchDefaultSettingsDate: moment.Moment) {
		return moment().add(-daysThatDefaultSettingsAreValidFor, 'days').isAfter(batchDefaultSettingsDate);
	}

	export function getSelectedListingId(visibleListings: string[]) {
		const storedSelectedListingId = LocalStorageHelper.getLocalStorage().getItem(selectedListingIdKey);

		if (!storedSelectedListingId) {
			return null;
		}

		if (visibleListings.some(x => x === storedSelectedListingId)) {
			return storedSelectedListingId;
		}

		LocalStorageHelper.getLocalStorage().removeItem(selectedListingIdKey);
		return null;
	}

	export function setSelectedListingId(selectedListingId: string) {
		LocalStorageHelper.getLocalStorage().setItem(selectedListingIdKey, selectedListingId);
	}

	export function getGivenOn(): Date {
		return getGivenOnAndCheckTimestampForExpiry();
	}

	function getGivenOnAndCheckTimestampForExpiry(): Date {
		const serializedGivenOn = LocalStorageHelper.getLocalStorage().getItem(givenOnStorageKey);

		if (!serializedGivenOn) {
			return null;
		}
		let item: GivenOnStorageItem = null;
		try {
			item = JSON.parse(serializedGivenOn);
		} catch (ex) {
			return deleteGivenOnAndReturnNull();
		}

		let givenOnTimestamp = parseDate(item.timestamp, serializedTimestampFormat);
		if (!givenOnTimestamp) {
			return deleteGivenOnAndReturnNull();
		}

		givenOnTimestamp.setHours(givenOnTimestamp.getHours() + hoursThatGivenOnIsValidFor);

		if (givenOnTimestamp < new Date()) {
			return deleteGivenOnAndReturnNull();
		} else {
			return parseDate(item.givenOnDate, serializedDateFormat);
		}
	}

	function deleteGivenOnAndReturnNull() {
		LocalStorageHelper.getLocalStorage().removeItem(givenOnStorageKey);
		return null;
	}

	function parseDate(value: string, format: string): Date {
		if (!value) {
			return null;
		}

		const momentGivenOn = moment(value, format);

		if (!momentGivenOn.isValid()) {
			return null;
		}

		return momentGivenOn.toDate();
	}

	export function setGivenOn(givenOn: Date) {
		return setGivenOnWithTimestamp(givenOn);
	}

	function setGivenOnWithTimestamp(givenOn: Date) {
		const momentGivenOn = moment(givenOn);
		if (momentGivenOn.isValid()) {
			let item: GivenOnStorageItem = {
				givenOnDate: momentGivenOn.format(serializedDateFormat),
				timestamp: moment().format(serializedTimestampFormat)
			};
			LocalStorageHelper.getLocalStorage().setItem(givenOnStorageKey, JSON.stringify(item));
		} else {
			LocalStorageHelper.getLocalStorage().removeItem(givenOnStorageKey);
		}
	}
}
