import { communityMemberModelToViewModel } from '../utils';
import { Debouncer } from '../../utils/debouncer';
import {
	CheckDepositDataService,
	CheckDepositDataServiceAction,
	isCheckDepositAction
} from '../check-deposit-data-service';
import { IPayerViewModel, IPayerSearchResult } from '../../components/payer-search-omnibox/payer-search-omnibox';
import { observable, action, reaction, computed } from 'mobx';

export class AllocationOmniboxViewModel {
	@observable
	omniboxValue = '';

	@observable
	searchValue = '';

	@observable
	searchResult: IPayerSearchResult;

	@observable
	searchResultForCheck: IPayerSearchResult;

	@observable
	loadMoreInProgress = false;

	@observable
	openRequested = false;

	@observable
	private selectedPayer: IPayerViewModel;

	@observable
	private batchId: number;

	@observable
	private encodedToken: string;

	@computed
	get currentSearchResult() {
		if (this.searchResult) {
			return this.searchResult;
		}

		if (this.searchResultForCheck && this.searchResultForCheck.payers.length > 0) {
			return this.searchResultForCheck;
		}

		return null;
	}

	private paymentSearchDebouncer: Debouncer<string>;
	private currentlyProcessingSearchRequest: string;
	private currentlyProcessingSearchRequestForCheck: string;
	private unsubscribe: Function;

	constructor(private dataService: CheckDepositDataService) {
		const searchDelay = 500 ;
		this.paymentSearchDebouncer = new Debouncer<string>(searchDelay, this.searchPayers);

		this.unsubscribe = this.dataService.subscribe((action) => {
			this.subscribeToSearchPayers(action);
			this.subscribeToSearchPayersForCheck(action);
		});

		reaction(() => this.selectedPayer, payer => {
			if (!payer) {
				return;
			}
			this.cancelCurrentRequest();
			this.paymentSearchDebouncer.cancel();
			this.searchResult = null;
			this.searchValue = '';
		});
	}

	@action
	handleInputValueChange(value: string) {
		this.omniboxValue = value;

		if (!value) {
			this.searchResult = null;
			this.searchValue = '';
		}

		this.cancelCurrentRequest();
		this.paymentSearchDebouncer.exec(value);
		this.loadPayersForCheck();
	}

	@action.bound
	handleMenuStateChange(open: boolean) {
		this.openRequested = open;
		this.loadPayersForCheck();
	}

	@action
	updateFromProps(payer: IPayerViewModel, batchId: number, encodedToken: string) {
		this.selectedPayer = payer;
		this.batchId = batchId;
		this.encodedToken = encodedToken;
	}

	@action
	loadMore = () => {
		if (this.loadMoreInProgress) {
			return;
		}
		this.loadMoreInProgress = true;
		if (this.searchResult) {
			this.searchPayers(this.omniboxValue, this.searchResult.payers.length);
			return;
		}

		if (this.searchResultForCheck) {
			this.searchPayersForCheck(this.searchResultForCheck.payers.length);
			return;
		}
	}

	destroy() {
		this.cancelCurrentRequest();
		this.cancelCurrentSearchRequestForCheck();
		this.paymentSearchDebouncer.cancel();
		this.unsubscribe();
	}

	private cancelCurrentRequest() {
		if (this.currentlyProcessingSearchRequest) {
			this.dataService.cancelRequest(this.currentlyProcessingSearchRequest);
			this.currentlyProcessingSearchRequest = null;
		}
	}

	private cancelCurrentSearchRequestForCheck() {
		if (this.currentlyProcessingSearchRequestForCheck) {
			this.dataService.cancelRequest(this.currentlyProcessingSearchRequestForCheck);
			this.currentlyProcessingSearchRequestForCheck = null;
		}
	}

	private searchPayers = (value: string, skip: number = 0) => {
		value = value && value.trim();
		this.cancelCurrentRequest();

		if (!value || value.length < 2) {
			return;
		}

		this.currentlyProcessingSearchRequest = this.dataService.initRequest('searchPayers', { model: { Query: value, Skip: skip } });
	}

	private loadPayersForCheck() {
		if (this.openRequested && !this.omniboxValue && !this.selectedPayer && !this.searchResultForCheck) {
			this.searchPayersForCheck();
		}
	}

	private searchPayersForCheck(skip: number = 0) {
		if (this.currentlyProcessingSearchRequestForCheck) {
			//already making the same request
			return;
		}

		this.currentlyProcessingSearchRequestForCheck =
			this.dataService.initRequest('searchPayersForCheck', { model: { Skip: skip, BatchId: this.batchId, EncodedToken: this.encodedToken } });
	}

	@action
	private subscribeToSearchPayers(action: CheckDepositDataServiceAction) {
		if (!isCheckDepositAction(action, 'searchPayers') || action.requestId !== this.currentlyProcessingSearchRequest) {
			return;
		}

		switch (action.type) {
			case 'request_success':
				this.currentlyProcessingSearchRequest = null;

				this.searchValue = action.request.model.Query;
				const convertedPayers = action.response.Results.map(communityMemberModelToViewModel);
				const loadingMore = this.loadMoreInProgress;

				this.searchResult = {
					error: false,
					payers: loadingMore ? this.searchResult.payers.concat(convertedPayers) : convertedPayers,
					hasMorePages: action.response.HasMorePages,
					appendedResults: loadingMore,
				};
				this.loadMoreInProgress = false;
				break;
			case 'request_error':
				this.currentlyProcessingSearchRequest = null;

				this.searchValue = action.request.model.Query;
				this.searchResult = {
					error: true,
					payers: [],
					hasMorePages: false,
					appendedResults: false,
				};
				this.loadMoreInProgress = false;
				break;
		}
	}

	@action
	private subscribeToSearchPayersForCheck(action: CheckDepositDataServiceAction) {
		if (!isCheckDepositAction(action, 'searchPayersForCheck') || action.requestId !== this.currentlyProcessingSearchRequestForCheck) {
			return;
		}

		switch (action.type) {
			case 'request_success':
				this.currentlyProcessingSearchRequestForCheck = null;

				const convertedPayers = action.response.Results.map(communityMemberModelToViewModel);
				const loadingMore = this.loadMoreInProgress;

				this.searchResultForCheck = {
					error: false,
					payers: loadingMore ? this.searchResultForCheck.payers.concat(convertedPayers) : convertedPayers,
					hasMorePages: action.response.HasMorePages,
					appendedResults: loadingMore,
				};
				this.loadMoreInProgress = false;
				break;
			case 'request_error':
				this.currentlyProcessingSearchRequestForCheck = null;

				this.searchResultForCheck = {
					error: true,
					payers: [],
					hasMorePages: false,
					appendedResults: false,
				};
				this.loadMoreInProgress = false;
				break;
		}
	}
}
