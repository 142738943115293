export default class FancyLogo {

	static attribute: string = 'data-fancy-logo';
	scrollLimit: number = 180;

	static Init() : FancyLogo {
		let el = document.querySelector(`[${FancyLogo.attribute}]`);

		if (!el) {
			return null;
		}

		return new FancyLogo(el);
	}

	constructor(public element: Element) {
		if (!element) {
			return;
		}

		let attrVal = parseInt(this.element.getAttribute(FancyLogo.attribute));

		if (attrVal !== null && attrVal !== 0) {
			this.scrollLimit = attrVal;
		}

		window.addEventListener('scroll', () => this.scrolled());
	}

	scrolled() {

		if (window.scrollY > this.scrollLimit) {
			this.element.classList.add('in');
		} else {
			this.element.classList.remove('in');
		}
	}
}
