import { Channel, userActionChannel } from '../../../../Shared/utils/user-action-channel';
import { ActionHandlers } from '../../../../Shared/utils/saga-utils';
import { IQboIntegrationSagaContext, QboSagaDataService, sagaDataService } from '../../saga/qbo-integration-saga';
import { QboConfigurationUserAction } from './qbo-configuration-user-actions';
import { QboConfigurationMainViewModel } from './qbo-configuration-main-view-model';
import {
	FlashMessageType,
	MappingsResult,
	MappingsSetupProgress,
	QuickBooksIntegrationActionResult,
	QuickBooksMappingViewModel,
	QuickBooksSaveMappingsResult,
	ExternalSystemStatus
} from '../../qbo-integration-generated';
import { QuickBooksSaveMappingsViewModel } from '../../qbo-integration-generated';
import { showEnableIntegrationDialog } from '../../components/enable-integration-dialog/enable-integraion-saga';
import { showProcessPurgeQueueDialog } from '../../components/process-purge-queue-dialog/process-purge-queue-saga';
import { PostError } from '../../../utils/ajax-client';
import { spawn } from 'redux-saga/effects';

export class QboConfigurationSagaContext implements IQboIntegrationSagaContext {
	userActionChannel: Channel<QboConfigurationUserAction> = userActionChannel();
	dataService: QboSagaDataService = sagaDataService();
	executingBlockingAction?: boolean = false;
	constructor(public mainViewModel: QboConfigurationMainViewModel) { }
}

export function* initializeMappingData(context: QboConfigurationSagaContext) {
	const { isAuthenticated } = context.mainViewModel;

	if(!isAuthenticated) {
		return;
	}

	yield spawn(getMappingInfo, context);
}

export function getActionHandlers() {
	const actionHandlers = new ActionHandlers<QboConfigurationSagaContext, typeof QboConfigurationUserAction[keyof typeof QboConfigurationUserAction]>();

	actionHandlers.add(
		QboConfigurationUserAction.AuthorizeQuickBooks,
		{
			async: true,
			debounceable: false,
			blocking: true,
			handler: context => changeIntegrationStatus(context, context.dataService.authenticate)
		}
	);

	actionHandlers.add(
		QboConfigurationUserAction.EnableIntegration,
		{
			async: true,
			debounceable: false,
			blocking: true,
			handler: context => changeIntegrationStatus(context, context.dataService.enable)
		}
	);

	actionHandlers.add(
		QboConfigurationUserAction.DisableIntegration,
		{
			async: true,
			debounceable: false,
			blocking: true,
			handler: context => changeIntegrationStatus(context, context.dataService.disable)
		}
	);

	actionHandlers.add(
		QboConfigurationUserAction.DeleteIntegration,
		{
			async: true,
			debounceable: false,
			blocking: true,
			handler: context => changeIntegrationStatus(context, context.dataService.delete)
		}
	);

	actionHandlers.add(
		QboConfigurationUserAction.GetMappings,
		{
			async: true,
			handler: context => getMappingInfo(context)
		}
	);

	actionHandlers.add(
		QboConfigurationUserAction.SaveMappings,
		{
			async: true,
			blocking: true,
			debounceable: false,
			handler: (context, action) => saveMappingInfo(context, action.qboMappings)
		}
	);

	actionHandlers.add(
		QboConfigurationUserAction.CancelMappings,
		{
			async: true,
			blocking: true,
			handler: (context) => cancelMappings(context)
		}
	);

	return actionHandlers;
}

function* changeIntegrationStatus(context: QboConfigurationSagaContext, statusChangeMethod) {
	const { setIsProcessingRequest, setServerMessage } = context.mainViewModel;
	setIsProcessingRequest(true);
	try {
		const response:QuickBooksIntegrationActionResult = yield statusChangeMethod(null);
		if(response.RedirectTo) {
			window.location.href = response.RedirectTo;
			return;
		}
		setServerMessage(response.Message);
	} finally {
		setIsProcessingRequest(false);
	}
}

function* getMappingInfo(context: QboConfigurationSagaContext) {
	const { setIsMappingInfoReady, setMappingInfo, setServerMessage, setIsProcessingRequest, setPausedContext } = context.mainViewModel;
	setIsMappingInfoReady(false);
	setIsProcessingRequest(true);

	try {
		const mappings: QuickBooksMappingViewModel = yield context.dataService.getMappings(null);
		if(mappings.Result === MappingsResult.Failure) {
			setServerMessage(mappings.Message);
		}
		setMappingInfo(mappings);
		setPausedContext(mappings.PausedContext);
		if (mappings.DisplayPausedDialog && mappings.PausedContext && mappings.PausedContext.NumberOfQueuedSettlements > 0) {
			yield showProcessPurgeQueueDialog(context, mappings.PausedContext);
		}

		if (mappings.DisplayPausedDialog) {
			yield showEnableIntegrationDialog(context);
		}
	} finally {
		setIsProcessingRequest(false);
	}
}

function* saveMappingInfo(context: QboConfigurationSagaContext, mappings: QuickBooksSaveMappingsViewModel) {
	const { mainViewModel: { setIsProcessingRequest, setServerMessage, shouldPromptToEnable, mappingInfo, pausedContext } } = context;
	setIsProcessingRequest(true);

	try {
		const result: QuickBooksSaveMappingsResult = yield context.dataService.saveMappings({input: mappings});

		if(result.Message !== null) {
			setServerMessage(result.Message);
		}

		if(result.Result === MappingsResult.Success) {
			mappingInfo.setActiveStep(result.SetupProgress);
		}

		if (result.DisplayPausedDialog && pausedContext && pausedContext.NumberOfQueuedSettlements > 0) {
			yield showProcessPurgeQueueDialog(context, pausedContext);
		}

		if (result.DisplayPausedDialog) {
			yield showEnableIntegrationDialog(context);
		}

		if(shouldPromptToEnable && mappingInfo.activeStep === MappingsSetupProgress.Complete ) {
			yield showEnableIntegrationDialog(context);
		}
	} catch (e) {
		if(e instanceof PostError && (e as PostError).validationErrors) {
			const { validationErrors } = e;
			const errorMessages: string[] = [];

			if(validationErrors.PaymentGatewaysNotMapped) {
				errorMessages.push(validationErrors.PaymentGatewaysNotMapped);
			}

			if(validationErrors.ListingsToLocationsNotMapped) {
				errorMessages.push(validationErrors.ListingsToLocationsNotMapped);
			}

			if(errorMessages.length > 0) {
				const message = {
					MessageType: FlashMessageType.Error,
					Message: errorMessages,
					Title: 'Sorry, please fix the following:'
				};
				setServerMessage(message);
			} else {
				throw e;
			}
		} else {
			throw e;
		}
	} finally {
		setIsProcessingRequest(false);
	}
}

function* cancelMappings(context: QboConfigurationSagaContext) {
	const { integrationsLandingPageUri, setIsProcessingRequest } = context.mainViewModel;
	setIsProcessingRequest(true);
	window.location.href = integrationsLandingPageUri;
}
