import { ModalDialogCommander } from '../components/modal-dialog-commander';
import {
	combineMiddleware,
	DataService,
	DataServiceAction,
	DataServiceOptions,
	IDataService
} from '../utils/data-service';
import { Models } from './transactions-generated';

export type TransactionsApiConfig = typeof Models.TransactionsApiConfig;
export type TransactionsDataService = IDataService<TransactionsApiConfig>;
export type TransactionsDataServiceAction = DataServiceAction<TransactionsApiConfig, any>;

function reportAjaxError(action: TransactionsDataServiceAction) {
	window.reportUnhandledRejection(new Error(`Transactions ajax error: ${action.actionKey}`), { action });
}

function reportUnexpectedError(error, action: TransactionsDataServiceAction) {
	window.reportUnhandledRejection(error, { action });
}

let transactionsDataService: TransactionsDataService = null;

function createTransactionsDataService(options?: DataServiceOptions<TransactionsApiConfig>): TransactionsDataService {
	options = options || {};

	options.middleware = combineMiddleware(next => action => {
		switch (action.type) {
			case 'request_init':
				console.log(`Transactions request initiated`, action);
				break;
			case 'request_success':
				console.log(`Transactions request completed`, action);
				break;
			case 'request_error':
				const { error } = action;
				//action contains all the details - request payload, error etc.
				reportAjaxError(action);

				if (error.shouldReloadPage || error.timedout) {
					ModalDialogCommander.error(error.userError, '').done(() => {
						document.location.reload(true);
					});
					return;
				}

				console.error(`Transactions request failed`, action);
				break;
			case 'request_cancel':
				console.log(`Transactions request cancelled`, action);
				break;
			case 'unexpected_error':
				reportUnexpectedError(action.error, action);
				ModalDialogCommander.showStandardErrorMessage();
				break;
		}

		return next(action);
	}, options.middleware);

	return new DataService(Models.TransactionsApiConfig, options);
}

export function getTransactionsDataService(): TransactionsDataService {
	if (!transactionsDataService) {
		transactionsDataService = createTransactionsDataService();
	}

	return transactionsDataService;
}

export function mockTransactionsDataService(mockDataService: TransactionsDataService) {
	transactionsDataService = mockDataService;
}
