import * as React from 'react';
import { observer } from 'mobx-react';
import { FormField, IFormFieldProps } from './form-field';
import { IRadioButtonCommonProps, RadioButton } from '../primitives/radio-button';
import { classNames } from '../../utils/classnames';
import { BaseFieldState } from './base-field-state';
import { ModelMetadata } from '../../../LoggedInWeb/loggedinweb-generated';

export class RadioButtonFieldState extends BaseFieldState<string> {
	constructor(initialValue: string, metadata?: ModelMetadata.IPropertyMetadata) {
		super(initialValue, metadata);
	}
}

export type IRadioButtonFieldProps = IRadioButtonCommonProps & IFormFieldProps & { fieldState: RadioButtonFieldState };

@observer
export class RadioButtonField extends React.Component<IRadioButtonFieldProps> {
	render() {
		const { label, className, tooltip, fieldState, additionalClassNames, ...rest } = this.props;
		const { value, onChange, hasError, error } = fieldState;
		const classes = classNames(additionalClassNames, hasError ? `input-validation-error` : null);

		return (
			<FormField label={label} className={className} tooltip={tooltip} error={error}>
				<RadioButton
					{...rest}
					selectedValue={value}
					onChange={onChange}
					ariaInvalid={hasError}
					additionalClassNames={classes}
				/>
			</FormField>
		);
	}
}

