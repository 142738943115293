import update from 'immutability-helper';

export interface ISpliceSpec<T> {
	start: number;
	deleteCount: number;
	items?: T[];
}

//the idea of the state-helper is to wrap React.addons.update
//in order to achieve compile-time checks for property names
//https://facebook.github.io/react/docs/update.html

export interface IStateHelper {
	set: <T>(value: T) => T;
	apply: <T>(func: (value: T) => T) => T;
	merge: <T>(value: T) => T;
	push: <T>(...values: T[]) => T[];
	unshift: <T>(...values: T[]) => T[];
	splice: <T>(...values: ISpliceSpec<T>[]) => T[];
	update: <T>(state: T, spec: T) => T;
}

class StateHelperLocal {
	set<T>(value: T): T {
		const spec = {
			$set: value
		};

		return spec as any;
	}

	apply<T>(func: (value: T) => T): T {
		const spec = {
			$apply: func
		};

		return spec as any;
	}

	merge<T>(value: T): T {
		const spec = {
			$merge: value
		};

		return spec as any;
	}

	push<T>(...values: T[]): T[] {
		const spec = {
			$push: values
		};

		return spec as any;
	}

	unshift<T>(...values: T[]): T[] {
		const spec = {
			$unshift: values
		};

		return spec as any;
	}

	splice<T>(...values: ISpliceSpec<T>[]): T[] {
		const spec = {
			$splice: values.map(x => x.items ? [x.start, x.deleteCount, x.items] : [x.start, x.deleteCount])
		};

		return spec as any;
	}

	update<T>(state: T, spec: T): T {
		return update(state, spec) as any;
	}
}

export var stateHelper: IStateHelper = new StateHelperLocal();
