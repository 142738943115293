import * as React from 'react';
import { observer } from 'mobx-react';
import { SvgWrapper } from '../../components/svg-wrapper';
import { DeleteRegisteredScannerDialogViewModel } from './delete-registered-scanner-dialog-viewmodel';
import { ValidationSummary } from '../../components/form-controls/validation-summary';

export class IDeleteRegisteredScannerDialogViewModelProps {
	store: DeleteRegisteredScannerDialogViewModel;
}

@observer
export class DeleteRegisteredScannerDialog extends React.Component<IDeleteRegisteredScannerDialogViewModelProps> {
	render() {
		const { isProcessingRequest, failedToDelete } = this.props.store;
		const errorMessages = failedToDelete
			? ['We were unable to delete that scanner, try again or refresh the page']
			: null;

		return (
			<div className="modal-content">
				<button type="button" className="close" aria-label="Close" onClick={this.props.store.closeDialog}></button>
				<ValidationSummary customTitle="Something went wrong" validationErrors={errorMessages} />
				<div className="modal-body">
					<div className="alert-icon"><SvgWrapper svg="alert-exclamation-icon" /></div>
					<div>
						<p>
							Are you sure you want to delete this check scanner? You will no longer be able to scan checks using this device.
						</p>
					</div>
				</div>
				<div className="modal-footer">
					{this.props.store.isProcessingRequest
						? null
						: <button type="button" key="0" className="btn btn-cancel" disabled={isProcessingRequest} onClick={this.props.store.closeDialog}>Cancel</button>}
					<button type="submit" key="1" className="btn btn-default" disabled={isProcessingRequest}
						onClick={this.props.store.onSubmitted} >
						Delete{isProcessingRequest ? '...' : ''}
					</button>
				</div>
			</div>
		);
	}
}
