import * as React from 'react';
import { observer } from 'mobx-react';
import { IMaskedInputProps } from 'react-text-mask';
import { FormControlLabelled, FormControlType } from '../../../components/form-controls/form-control-labelled';
import { AchBankAccountPaymentMethod } from './payment-entry-model';
import { ModelMetadata } from '../../virtual-terminal-generated';
import { IIconInputGroupProps, IconInputGroupPosition } from '../../../components/icon-input-group';
import { injectAnalytics, recordEvent, WithAnalytics } from '../../../analytics';

@injectAnalytics
@observer
export class PaymentMethodAch extends React.Component<WithAnalytics & {
	cssClassNames?: string;
	accounts: { Key: string, Value: string, Label: string }[];
	achBankAccount: AchBankAccountPaymentMethod;
}, {}> {
	static fieldLengths;

	constructor(props) {
		super(props);

		PaymentMethodAch.fieldLengths = {
			routingNumber: 9,
			accountNumber: 17
		};
	}

	createMaskOptions(length: number): { mask: RegExp[], guide: boolean } {
		const mask = new Array<RegExp>(length).fill(/[0-9]/);
		return { mask, guide: false };
	}

	render() {
		const { cssClassNames, accounts, achBankAccount: { routingNumber, accountNumber, accountType } } = this.props;
		const { accountNumber: accountNumberLength, routingNumber: routingNumberLength } = PaymentMethodAch.fieldLengths;
		const routingNumberMaskOptions: IMaskedInputProps = this.createMaskOptions(routingNumberLength);
		const accountNumberMaskOptions: IMaskedInputProps = this.createMaskOptions(accountNumberLength);
		const cardInputGroupOptions: IIconInputGroupProps = {
			svg: 'ach',
			title: 'Ach',
			position: IconInputGroupPosition.Right,
			width: 32,
			height: 24,
			transparent: true
		};

		return (
			<div className={`vt-payment-method vt-payment-method-bank row ${cssClassNames ? cssClassNames : ''}`}>
				<FormControlLabelled cssClassNames="col-md-4 col-sm-6"
					label="Routing number"
					formControlProps={{
						formControlType: FormControlType.Text,
						name: this.metadata.RoutingNumber.propertyName,
						inputGroupOptions: cardInputGroupOptions,
						placeholder: '●●●●●●●●●',
						maskOptions: routingNumberMaskOptions,
						type: 'text',
						value: routingNumber,
						onChangeHandler: this.handleRoutingNumberChange,
						...(NewFeatures.SetupPinpointAnalytics && { onBlurHandler: this.handleRoutingNumberBlur }),
						validationRules: this.metadata.RoutingNumber.validationRules,
						acceptanceTestTargetId: 'routing number - ach',
					}} />
				<FormControlLabelled cssClassNames="col-md-4 col-sm-6"
					label="Account number"
					formControlProps={{
						formControlType: FormControlType.Text,
						name: this.metadata.AccountNumber.propertyName,
						placeholder: '●●●●●●●●●',
						maskOptions: accountNumberMaskOptions,
						type: 'text',
						value: accountNumber,
						ignorePanLikeValidation: true,
						onChangeHandler: this.handleAccountNumberChange,
						validationRules: this.metadata.AccountNumber.validationRules,
						acceptanceTestTargetId: 'account number - ach',
					}} />
				<FormControlLabelled cssClassNames="col-lg-2 col-md-4 col-sm-6"
					label="Account type"
					formControlProps={{
						formControlType: FormControlType.DropDown,
						name: this.metadata.AccountType.propertyName,
						placeholder: 'Select account',
						Options: accounts,
						value: `${accountType}`,
						onChangeHandler: this.handleAccountTypeChange,
						validationRules: this.metadata.AccountType.validationRules,
						acceptanceTestTargetId: 'account type - ach',
					}} />
			</div>
		);
	}

	private handleRoutingNumberBlur = () => {
		const { analytics, achBankAccount: { routingNumber } } = this.props;
		if (analytics && routingNumber) {
			const { feature, subFeature } = analytics;
			recordEvent({
				feature,
				subFeature,
				eventTypeLabel: 'completePaymentMethod',
				attributes: { paymentMethodType: 'ACH' }
			});
		}
	}

	private handleRoutingNumberChange = (event: React.FormEvent<HTMLInputElement>) => {
		this.props.achBankAccount.updateRoutingNumber(event.currentTarget.value);
	}

	private handleAccountNumberChange = (event: React.FormEvent<HTMLInputElement>) => {
		this.props.achBankAccount.updateAccountNumber(event.currentTarget.value);
	}

	private handleAccountTypeChange = (event: React.FormEvent<HTMLSelectElement>) => {
		const value = event.currentTarget.value;
		this.props.achBankAccount.updateAccountType(value && parseInt(value));
	}

	private get metadata() {
		return ModelMetadata.VirtualTerminalAchBankModel;
	}
}
