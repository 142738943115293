import * as React from 'react';
import { observer } from 'mobx-react';
import { ListingModel } from './listing-model';
import { SmoothHeightTransition } from '../../components/hoc-behavior/transitions';

import { ModelMetadata } from '../../funds/funds-generated';
import { InputField, ModelFor } from '../../funds/components/form-controls';

export interface IListingConfigurationProps<TListingModel> {
	model: ListingModel<TListingModel>;
	editor: React.ComponentClass<{ listing: ListingModel<TListingModel> }>;
}

// ReSharper disable once InconsistentNaming
export class ListingSettingsEditor extends React.Component<{}, {}> {
	render() {
		return (
			<div className="listing-settings-editor">
				{this.props.children}
			</div>);
	}
}

@observer
export class ListingConfiguration extends React.Component<IListingConfigurationProps<any>, {}> {
	renderEditor() {
		if (this.props.model.enabled) {
			return React.createElement(this.props.editor, { listing: this.props.model });
		}

		return null;
	}

	render() {
		return (
			<li className={'list-group-item' + (this.props.model.enabled ? ' selected' : '') + (this.props.model.validationError ? ' has-custom-validation-error' : '')}>
				<div>
					<label>{this.props.model.listingName}</label>
					<label className="slider-toggle">
						<input
							type="checkbox"
							checked={this.props.model.enabled}
							value="true"
							onChange={this.props.model.toggleActive} />
						<span></span>
					</label>
					<InputField type="hidden" propertyMetadata={this.metadata.ListingId} value={`${this.props.model.listingId}`} />
				</div>
				<ModelFor propertyName={this.metadata.Model.propertyName}>
					<SmoothHeightTransition component="div">
						{this.renderEditor()}
					</SmoothHeightTransition>
				</ModelFor>
			</li>
		);
	}

	get metadata() {
		return ModelMetadata.IntegrationListingConfigurationViewModel;
	}
}

