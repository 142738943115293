import * as React from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import { classNames } from '../../../../Shared/utils/classnames';
import { DateQuarterSelector } from '../date-quarter-selector/date-quarter-selector';
import { AnnualSelector } from './annual-selector';
import * as styles from './styles.less';

export interface IQuarterSelectorProps {
	startQuarter: number;
	endQuarter: number;
	maxQuarters: number;
	chosenYear: number;
	availableQuarterYears: number[];
	onQuarterChange: (start: number, end: number) => void;
	onQuarterYearChange: (year: number) => void;
	acceptanceTestTargetId: string;
	ariaLabel?: string;
	disabled?: boolean;
}

@observer
export class QuarterSelector extends React.Component<IQuarterSelectorProps, {}> {

	render() {
		const { startQuarter, endQuarter, maxQuarters, chosenYear, availableQuarterYears, disabled } = this.props;
		const { acceptanceTestTargetId, ariaLabel } = this.props;

		let periodText: string = null;
		if (chosenYear && startQuarter && endQuarter) {
			const start = moment([chosenYear]).quarter(startQuarter).format('MMMM Do');
			const end = moment([chosenYear]).quarter(endQuarter).endOf('quarter').format('MMMM Do');
			periodText = `${start} to ${end}`;
		}

		const validationRules = this.validate();

		return <div>
			<div className="row">
				<AnnualSelector
					name="quarterlyYear"
					chosenYear={chosenYear}
					availableAnnualYears={availableQuarterYears}
					onAnnualChange={this.handleYearChange}
					ariaLabel={ariaLabel}
					acceptanceTestTargetId={`${acceptanceTestTargetId}_year`}
					disabled={disabled}
				/>
			</div>
			<div className="row">
				<div className="col-md-6">
					<DateQuarterSelector
						name="DateQuarterSelector"
						start={startQuarter}
						end={endQuarter}
						max={maxQuarters}
						onChange={this.handleQuarterChange}
						acceptanceTestTargetId={acceptanceTestTargetId}
						ariaLabel={ariaLabel}
						validationRules={validationRules}
						disabled={disabled}
					/>
				</div>
				{periodText && <div className={classNames(styles.periodDisplay, 'col-md-6')}>
						<strong>Time period:</strong> {periodText}
				</div>}
			</div>
		</div>;
	}

	private handleQuarterChange = (startQuarter: number, endQuarter: number) => {
		const { onQuarterChange } = this.props;
		onQuarterChange(startQuarter, endQuarter);
	}

	private handleYearChange = (year: number) => {
		const { onQuarterYearChange } = this.props;
		onQuarterYearChange(year);
	}

	private validate() {
		const { startQuarter, endQuarter } = this.props;

		if (!startQuarter || !endQuarter ) {
			return {nevervalid:{errorMessage:'Please select a quarter'}};
		}
	}
}
