import * as React from 'react';
import { EnumHelper } from '../../helpers/enumhelper';

export enum ImportStep {
	Select = 1,
	Upload = 2,
	Match = 3,
	Review = 4,
}

const StepNames = {
	'Select': 'Select source',
	'Upload': 'Upload CSV file',
	'Match': 'Match funds',
	'Review': 'Review & confirm'
};

export class ImportStepHeader extends React.Component<{currentStep: ImportStep}, {}> {
	render() {
		const {currentStep} = this.props;
		return (
			<div className="transaction-import-header">
				{EnumHelper.MapKeys(ImportStep).map((key) => (
					<div key={key} className={`import-step ${key === ImportStep[currentStep] ? 'selected' : ''}`}>
						<div className="step-circle-wrapper">
							<div className="step-circle">{ImportStep[key]}</div>
						</div>
						<span className="step-text">{StepNames[key]}</span>
					</div>
				))}
			</div>
		);
	}
}
