import * as React from 'react';
import { observer} from 'mobx-react';
import { FormControlLabelled, FormControlType } from '../../../../components/form-controls/form-control-labelled';
import { MultiSelectItem, ModelMetadata } from '../../../virtual-terminal-generated';
import { velocity } from '../../../../helpers/velocity';
import { BatchListUserAction } from '../../../pages/batch-list/batch-list-user-actions';
import { injectAnalytics, recordEvent, WithAnalytics } from '../../../../analytics';

@injectAnalytics
@observer
export class BatchListFilterPanel extends React.Component<WithAnalytics & {
	listings: MultiSelectItem[],
	serviceTimes: MultiSelectItem[],
	raiseAction: (action: BatchListUserAction) => void,
	searchTerm: string,
	selectedListingIds: string[],
	selectedServiceTimeIds: string[],
	onChangeSearch: (searchTerm: string) => void,
	onSelectListings: (selectedListings: string[]) => void,
	onSelectServiceTimes: (selectedServiceTimes: number[]) => void,
	resetPage: () => void,
}> {
	private filterPanel: HTMLDivElement;

	render() {
		const {
			listings,
			serviceTimes,
			searchTerm,
			selectedListingIds,
			selectedServiceTimeIds,
		} = this.props;
		const filterClassNames = 'col-lg-4 col-md-3';
		const hasServiceTimes = serviceTimes && serviceTimes.length > 0;

		return (
			<div ref={this.saveFilters}>
				<FormControlLabelled
					formControlProps={{
						formControlType: FormControlType.Text,
						type: 'text',
						value: searchTerm,
						name: 'search',
						placeholder: 'batch ID, keyword...',
						onChangeHandler: this.handleSearch,
						acceptanceTestTargetId: 'Search filter',
					}}
					label="Search"
					cssClassNames={filterClassNames}
				/>
				<FormControlLabelled
					formControlProps={{
						formControlType: FormControlType.MultiSelect,
						items: listings,
						name: 'listing',
						selectedValues: selectedListingIds,
						onChangeHandler: this.handleFilterByListing,
						acceptanceTestTargetId: 'Listing filter',
					}}
					label="Listing"
					cssClassNames={filterClassNames}
				/>
				{hasServiceTimes && <FormControlLabelled
					formControlProps={{
						formControlType: FormControlType.MultiSelect,
						items: serviceTimes,
						name: ModelMetadata.BatchQueryRequestModel.EventTimeIds.propertyName,
						selectedValues: selectedServiceTimeIds,
						onChangeHandler: this.handleFilterByServiceTime,
						acceptanceTestTargetId: 'Service time filter',
					}}
					label="Service time"
					cssClassNames="col-lg-4 col-md-6"
				/>
				}
			</div>
		);
	}

	componentWillEnter(callback) {
		velocity(this.filterPanel, 'fadeIn', {
			duration: 250,
			complete: callback,
			easing: 'ease-in-out',
		});
	}

	componentWillLeave(callback) {
		velocity(this.filterPanel, 'fadeOut', {
			duration: 150,
			complete: callback,
			easing: 'ease-in-out',
		});
	}

	saveFilters = (filterPanel: HTMLDivElement) => this.filterPanel = filterPanel;

	handleSearch = (event: React.FormEvent<HTMLInputElement>) => {
		this.props.resetPage();
		this.props.onChangeSearch(event.currentTarget.value);
		this.props.raiseAction(new BatchListUserAction.QueryBatches({ debounceDelay: 500 }));
	}

	handleFilterByListing = (selectedListings: string[]) => {
		if (NewFeatures.SetupPinpointAnalytics && this.props.analytics) {
			const { feature, subFeature } = this.props.analytics;
			recordEvent({ feature, subFeature, eventTypeLabel: 'selectListing' });
		}

		this.props.resetPage();
		this.props.onSelectListings(selectedListings);
		this.props.raiseAction(new BatchListUserAction.QueryBatches());
	}

	handleFilterByServiceTime = (selectedServiceTimes: string[]) => {
		this.props.resetPage();
		this.props.onSelectServiceTimes(selectedServiceTimes.map(x => Number(x)));
		this.props.raiseAction(new BatchListUserAction.QueryBatches());
	}
}
