import * as React from 'react';
import * as styles from './form-control-frequency-grid.less';
import { classNames } from '../../../../Shared/utils/classnames';
import { FrequencyCode } from '../../../loggedinweb-generated';
import { IFormControlRadioProps } from '../../form-controls/form-control-radio';

export interface IFormControlFrequencyGrid extends IFormControlRadioProps {
	radioItemClassName: string;
	acceptanceTestTargetId: string;
	disabled: boolean;
	name: string;
}
export const FormControlFrequencyGrid = ({
	Options,
	radioItemClassName,
	value,
	id,
	ariaLabel,
	acceptanceTestTargetId,
	disabled,
	name,
	onChangeHandler,
}) => {
	const onChange = (ev: React.FormEvent<HTMLInputElement>) => {
		onChangeHandler(ev.currentTarget.value);
	};

	const renderRow = (rows, rowStyle: string) => (
		<span className={rowStyle}>
			{rows.map(({ Label, Value, disabled: optionDisabled }) => (
				<label className={classNames(styles.frequencyGridLabel, value === Value && styles.frequencyGridActive)}>
					<input
						className={classNames(styles.frequencyGridRadio, radioItemClassName)}
						type="radio"
						name={name}
						value={Value}
						checked={value === Value}
						onChange={onChange}
						data-pp-at-target={`${acceptanceTestTargetId} ${Value}`}
						disabled={optionDisabled || disabled}
					/>
					<span className={styles.frequencyGridTitle} data-pp-at-target={`${acceptanceTestTargetId}_title`}>
						{Label}
					</span>
				</label>
			))}
		</span>
	);

	const isTopRow = (match: string) =>
		[FrequencyCode.Weekly, FrequencyCode.Fortnightly].indexOf(parseInt(match)) !== -1;
	const isTesterRow = (match: string) =>
		[FrequencyCode.OneMinute, FrequencyCode.FiveMinutes, FrequencyCode.FourHours].indexOf(parseInt(match)) !== -1;

	const topRow = Options.filter((option) => isTopRow(option.Value));
	const bottomRow = Options.filter((option) => !isTopRow(option.Value) && !isTesterRow(option.Value));
	const testerRow = Options.filter((option) => isTesterRow(option.Value));

	const attr = {
		id,
		role: 'radiogroup',
		'aria-label': ariaLabel,
	};

	return (
		<div className={styles.frequencyGrid} {...attr}>
			{renderRow(topRow, styles.frequencyGridRow)}
			{renderRow(bottomRow, styles.frequencyGridRow)}
			{NewFeatures.TesterCommandsEnabled &&
				testerRow.length !== 0 &&
				renderRow(testerRow, styles.frequencyGridTesterRow)}
		</div>
	);
};
