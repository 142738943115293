import * as React from 'react';
import * as styles from './styles.less';

export interface IPanelProps {
	panelType: PanelType | PanelType[];
	extraClassNames?: string | string[];
}

export enum PanelType {
	DEFAULT,
	HIGHLIGHT,
}
function normalizeClassNames(classNames: string | string[] | null | undefined): string {
	if(classNames === null || classNames === undefined) {
		return '';
	}
	return Array.isArray(classNames) ? (classNames as string[]).join(' ') : classNames as string;
}

export class Panel extends React.Component<IPanelProps> {
	render() {
		const { extraClassNames, children } = this.props;

		return (
			<section className={`panel ${styles.panel} ${this.getPanelTypeClasses()} ${normalizeClassNames(extraClassNames)}`}>
				{children}
			</section>
		);
	}

	private getPanelTypeClasses(): string {
		const { panelType } = this.props;
		if(Array.isArray(panelType)) {
			const classNameArr = (panelType as PanelType[]).map(item => this.convertToPanelTypeClassName(item));
			return normalizeClassNames(classNameArr);
		} else {
			return this.convertToPanelTypeClassName(panelType as PanelType);
		}
	}

	private convertToPanelTypeClassName(panelType: PanelType): string {
		switch (panelType) {
			case PanelType.DEFAULT:
				return 'panel-default';
			case PanelType.HIGHLIGHT:
				return `panel-highlight ${styles.panelHighlight}`;
			default:
				return '';

		}
	}
}

export interface IPanelHeaderProps {
	extraClassNames?: string | string[];
}

export class PanelHeader extends React.Component<IPanelHeaderProps> {
	render() {
		const { extraClassNames, children } = this.props;
		return (
			<header className={`panel-heading ${styles.panelHeader} ${normalizeClassNames(extraClassNames)}`}>
				{children}
			</header>
		);
	}
}

export interface IPanelBodyProps {
	extraClassNames?: string | string[];
}

export class PanelBody extends React.Component<IPanelBodyProps> {
	render() {
		const { extraClassNames, children } = this.props;
		return (
			<div className={`panel-body ${styles.panelBody} ${normalizeClassNames(extraClassNames)}`}>
				{children}
			</div>
		);
	}
}

export interface IPanelFooterProps {
	extraClassNames?: string | string[];
}

export class PanelFooter extends React.Component<IPanelFooterProps> {
	render() {
		const { extraClassNames, children } = this.props;
		return (
			<footer className={`panel-footer ${normalizeClassNames(extraClassNames)}`}>
				{children}
			</footer>
		);
	}
}
