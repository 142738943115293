import { toJS } from 'mobx';
import { VirtualTerminalFormViewModel } from '../components/form/virtual-terminal-form-view-model';
import {
	VirtualTerminalPaymentRequestModel,
	RecordedCheckModel,
	VirtualTerminalAchBankModel,
	ExistingACHModel,
	ExistingCreditCardModel,
	RecordedCreditCardModel,
	RecordedACHModel,
	FullCardModel,
	SplitLineItem,
} from '../virtual-terminal-generated';
import { nullableNumber } from '../../utils/nullable-number';
import { toPaymentMethodType } from './payment-method-type-helper';
import { PaymentMethodUiType } from '../components/payment-entry/payment-entry-model';

export function buildPaymentRequestModel(formViewModel: VirtualTerminalFormViewModel): VirtualTerminalPaymentRequestModel {
	const { paymentEntry, selectedPayer, listingStore, selectedMemberIsAnonymous, paymentEntryReferencesValidForTheCurrentMerchant, allowRecurring, splits } = formViewModel;
	const { CommonPaymentFields, amount, paymentMethod, yourId, isRecurring, recurringSchedule, sendEmailNotifications, assetType,
		descriptionForDonor, descriptionForMerchant } = paymentEntry;
	const commonFields = { ...toJS(CommonPaymentFields), ReferenceFieldValues: paymentEntryReferencesValidForTheCurrentMerchant };

	const lineItems : SplitLineItem[] =
		splits
		&& splits.length > 0
			? splits.map(s => ({
				FundKey: s.Fund.Value,
				Amount: s.Amount
			})) : [];

	const model: VirtualTerminalPaymentRequestModel = {
		CommonFields: commonFields,
		Amount: amount,
		MerchantVersion: listingStore.selectedListingConfiguration.MerchantVersion,
		CommunityMemberId: nullableNumber(selectedPayer.id),
		PaymentMethodType: toPaymentMethodType(paymentMethod.type),
		IsAnonymous: selectedMemberIsAnonymous,
		AchAccount: null,
		CreditCard: null,
		PaymentMethodIndex: null,
		RecordedCheck: null,
		RecordedACH: null,
		RecordedCreditCard: null,
		ExistingACH: null,
		ExistingCreditCard: null,
		WantsTransactionNotifications: sendEmailNotifications,
		YourId: yourId,
		IsRecurring: allowRecurring && isRecurring,
		ScheduleFrequency: recurringSchedule,
		AssetType: assetType,
		DescriptionForDonor: descriptionForDonor,
		DescriptionForMerchant: descriptionForMerchant,
		LineItems: lineItems
	};

	switch (paymentMethod.type) {
		case PaymentMethodUiType.RecordedCheck:
			model.RecordedCheck = <RecordedCheckModel>{
				CheckAccountName: '',
				AccountNumber: paymentMethod.accountNumber,
				CheckNumber: paymentMethod.checkNumber,
				RoutingNumber: paymentMethod.routingNumber
			};
			break;
		case PaymentMethodUiType.ACH:
			model.AchAccount = <VirtualTerminalAchBankModel>{
				AccountNumber: paymentMethod.accountNumber,
				RoutingNumber: paymentMethod.routingNumber,
				AccountType: paymentMethod.accountType
			};
			break;
		case PaymentMethodUiType.ExistingACH:
			model.PaymentMethodIndex = parseInt(paymentMethod.key);
			model.ExistingACH = <ExistingACHModel>{
				ConfirmedAccountNumber: paymentMethod.confirmedAccountNumber
			};
			break;
		case PaymentMethodUiType.ExistingCreditCard:
			model.PaymentMethodIndex = parseInt(paymentMethod.key);
			model.ExistingCreditCard = <ExistingCreditCardModel>{
				ConfirmedCardNumber: paymentMethod.confirmedCardNumber
			};
			break;
		case PaymentMethodUiType.RecordedCreditCard:
			model.RecordedCreditCard = <RecordedCreditCardModel>{
				Reference: paymentMethod.paymentReference || ''
			};
			break;
		case PaymentMethodUiType.RecordedACH:
			model.RecordedACH = <RecordedACHModel>{
				Reference: paymentMethod.paymentReference || ''
			};
			break;
		case PaymentMethodUiType.CreditCard:
			model.CreditCard = <FullCardModel>{
				Number: paymentMethod.cardNumber,
				ExpiryMonth: paymentMethod.expiryMonth,
				ExpiryYear: paymentMethod.expiryYear,
				Cvc: paymentMethod.cvv,
				CvcOptional: true
			};
			break;
		case PaymentMethodUiType.Cash:
			break;
		case PaymentMethodUiType.NonCash:
			break;
		default:
			const unsupported: never = paymentMethod;
			throw new Error(`Payment method ${unsupported} is unsupported`);
	}
	return model;
}
