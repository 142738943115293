import * as React from 'react';
import { ModalDialogCommander } from '../../components/modal-dialog-commander';
import { Button } from '../../components/button';
import { SvgWrapper } from '../../components/svg-wrapper';
import { CampaignFundErrorModalDialog } from './campaign-fund-error-dialog';

export interface ICloseCampaignFundButtonProps {
	url: string;
	className: string;
}

export class CloseCampaignFundButton extends React.Component<ICloseCampaignFundButtonProps, {}> {
	handleButtonClick = () => {
		ModalDialogCommander.showReactDialog(<CampaignFundErrorModalDialog />);
	}

	render() {
		if (this.props.url && this.props.url.length > 0) {
			return (
				<a href={this.props.url}>
					<Button className={this.props.className}>
						Edit Campaign to close fund
					</Button>
				</a>);
		} else {
			return (
				<Button className={this.props.className} onClick={this.handleButtonClick}>
					Edit Campaign to close fund
				</Button>);
		}
	}
}
