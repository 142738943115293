import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { injectAnalytics, recordEvent, WithAnalytics } from '../../../analytics';
import { Button } from '../../../../Shared/components/button/button';
import { LandingPageEvents, LandingPageStates } from '../../state/landing-page';
import { LandingPageViewModel } from './landing-page-view-model';
import { MachineContext, machineContextKey } from '../../../../Shared/state-machine/saga-state-machine';
import { MainEvents } from '../../state/main';
import { MatchModal } from '../../../components/match-modal';
import { PreliminaryPanel } from '../../components/preliminary-panel/preliminary-panel';
import { ProcessSteps } from '../../components/process-steps/process-steps';
import { ReviewCommunityMembersModal } from '../../components/review-community-members-modal';
import { StatementHistory } from '../../components/statement-history/statement-history';
import { urlService } from '../../services/url';
import * as rootStyles from '../../app.less';
import * as styles from './landing-page.less';

export interface ILandingPageProps {
	machineContext? : MachineContext;
	vm: LandingPageViewModel;
	showPage: boolean;
}

@injectAnalytics
@inject(machineContextKey)
@observer
export class LandingPage extends React.Component<WithAnalytics & ILandingPageProps> {
	render() {
		const {
			machineContext,
			vm: {
				communityMemberUrl,
				fundsUrl,
				giftEntryUrl,
				newMemberCount,
				paymentLabel,
				statementHistory,
				transactionImportUrl,
			},
			showPage,
		} = this.props;

		return (
		showPage ? 
			<div className={rootStyles.pageContainer}>
				<h1 className={rootStyles.pageTitle}>Giving Statements</h1>
				<PreliminaryPanel
					communityMemberUrl={communityMemberUrl}
					transactionImportUrl={transactionImportUrl}
					fundsUrl={fundsUrl}
					giftEntryUrl={giftEntryUrl}
					paymentLabel={paymentLabel}
				/>
				<ProcessSteps />
				<div className={styles.btnContainer}>
					<Button
						className="btn btn-primary"
						onClick={this.getNewCommunityMemberCount}
						isLoading={machineContext.matchesState(LandingPageStates.GETTING_NEW_COMMUNITY_MEMBER_COUNT)}>
						Get started
					</Button>
				</div>

				{statementHistory && statementHistory.length > 0 && <div ref={this.scrollToList}>
						<StatementHistory items={statementHistory} onItemClick={this.handleStatementHistoryClick} />
					</div>}

				<MatchModal state={LandingPageStates.SHOWING_COMMUNITY_MEMBER_DIALOG}>
					<ReviewCommunityMembersModal
						newMemberCount={newMemberCount}
						communityMemberUrl={communityMemberUrl}
						onDismissClick={this.dismissCommunityMemberDialog}
						onNextClick={this.createNewConfiguration}
					/>
				</MatchModal>
			</div> : null
		);
	}

	componentDidMount () {
		urlService.pushPreviousViewState();
		this.props.machineContext.transition(LandingPageEvents.FETCH_EXISTING_CONFIG_LIST);
	}

	private scrollToList = (element: HTMLElement) => {
		const { scrollToRunsList } = this.props.vm;
		if (scrollToRunsList) {
			requestAnimationFrame(() => element && element.scrollIntoView({behavior: 'smooth'}));
		}
	}

	private getNewCommunityMemberCount = () => {
		if (NewFeatures.SetupPinpointAnalytics && this.props.analytics) {
			const { feature, subFeature } = this.props.analytics;
			recordEvent({ feature, subFeature, eventTypeLabel: 'getStarted' });
		}
		this.props.machineContext.transition(LandingPageEvents.GET_NEW_COMMUNITY_MEMBER_COUNT);
	}

	private dismissCommunityMemberDialog = () => {
		this.props.machineContext.transition(LandingPageEvents.DISMISS_COMMUNITY_MEMBER_DIALOG);
	}

	private createNewConfiguration = () => {
		this.props.machineContext.transition(LandingPageEvents.CREATE_NEW_CONFIGURATION);
	}

	private handleStatementHistoryClick = (runId: string) => {
		if (NewFeatures.SetupPinpointAnalytics && this.props.analytics) {
			const { feature, subFeature } = this.props.analytics;
			recordEvent({ feature, subFeature, eventTypeLabel: 'selectStatementRun' });
		}
		this.props.machineContext.transition(MainEvents.LOAD_CONFIGURATION_IN_PROGRESS, runId);
	}
}
