import * as React from 'react';
import { observer } from 'mobx-react';
import { BatchDismissRejectedDialogViewModel } from './batch-dismiss-rejected-dialog-view-model';
import { IModalDialogButton, ModalDialog } from '../../components/modal-dialog';
import { Models } from '../check-deposit-generated';
import BatchItemViewModel = Models.BatchItemViewModel;

export interface IBatchDismissRejectedDialogProps {
	batch: BatchItemViewModel;
	onBatchDismissed?: (batch: BatchItemViewModel) => void;
}

@observer
export class BatchDismissRejectedDialog extends React.Component<IBatchDismissRejectedDialogProps> {
	private vm: BatchDismissRejectedDialogViewModel;

	UNSAFE_componentWillMount() {
		const { onBatchDismissed, batch } = this.props;
		this.vm = new BatchDismissRejectedDialogViewModel();
		this.vm.setViewModel(batch, onBatchDismissed);
	}

	UNSAFE_componentWillReceiveProps(nextProps: IBatchDismissRejectedDialogProps) {
		this.vm.setViewModel(nextProps.batch, nextProps.onBatchDismissed);
	}

	componentWillUnmount() {
		this.vm.destroy();
		this.vm = null;
	}

	render() {
		const buttons: (IModalDialogButton | JSX.Element)[] = [
			{ label: 'Cancel', dismiss: true, isPrimary: false },
			<button type="button" className="btn btn-default" onClick={this.vm.removeBatch} disabled={this.vm.isProcessingRequest}>
				Yes
			</button>
		];
		return(
			<ModalDialog title="Remove batch" buttons={buttons} showAlert={false}>
				This batch will no longer be visible. Are you sure?
			</ModalDialog>
		);
	}
}
