import * as React from 'react';
import { CampaignOverviewTabViewModel } from '../../pages/campaign-details/campaign-details-main-view-model';
import { States, Events } from '../../pages/campaign-details/campaign-details-states-events-and-actions';
import { inject, observer } from 'mobx-react';
import { Match, MatchNot } from '../../../../Shared/state-machine/match';
import { Formatter } from '../../../helpers/formatter';
import { Spinner } from '../../../../Shared/components/spinner/spinner';
import { PledgesGrid } from '../pledges-grid/pledges-grid';
import { PledgesGridWithTotals } from '../pledges-grid/pledges-grid-with-totals';
import { WithDeletePledge } from '../pledges-grid/with-delete-pledge';
import { Pagination } from '../../../components/pagination';
import { MachineContext, machineContextKey } from '../../../../Shared/state-machine/saga-state-machine';
import { SvgWrapper } from '../../../../Shared/components/svg-wrapper';
import { ResponsiveBreakpoint, ResponsiveVisibility } from '../../../helpers/responsive-helper';
import { RenderResponsive } from '../../../components/hoc-behavior/render-responsive';
import * as styles from './campaign-details-overview.less';
import * as sharedStyles from '../../shared-styles.less';
import { CampaignStatisticsViewModel, PledgeStatisticsViewModel, PledgeLabel } from '../../campaigns-generated';
import { Fragment } from '../../../../Shared/components/fragment';
import { Button } from '../../../../Shared/components/button/button';
import { classNames } from '../../../../Shared/utils/classnames';

const ExportDisableTimer:number = 3000;
export interface ICampaignOverviewTabProps {
	vm: CampaignOverviewTabViewModel;
}

@inject(machineContextKey)
@observer
export class CampaignDetailsOverview extends React.Component<ICampaignOverviewTabProps & { machineContext?: MachineContext }> {
	formExportRef: HTMLFormElement;

	render() {
		const { vm } = this.props;
		const { searchQuery, pledgeTableData, pageCount, pageNumber, pledgeLabel, paymentLabel, pledgeExportUrl, canExportPledges, isExporting } = vm;
		const antiForgeryTokenElement = document.querySelector('[name="__RequestVerificationToken"]') as HTMLInputElement;

		return (
			<div className={styles.container}>
				<Match state={States.Init}>
					<div className={sharedStyles.spinnerContainer}>
						<Spinner className={sharedStyles.loadSpinner} />
					</div>
				</Match>
				<MatchNot state={States.Init}>
					<OverviewAmounts {...vm} />
					<OverviewCounts {...vm} />
					<h2>{NewFeatures.DonorPledgeEntry_ConfigurablePledgeGrammar ? pledgeLabel.NounPluralSentenceCase : 'Pledges'}</h2>
					
					<div className={styles.searchExportContainer}>
						<div className={styles.searchBlock}>
							<SearchBox query={searchQuery} onChange={this.updateSearchQuery} onSubmit={this.submitSearch}/>
						</div>	
						{canExportPledges && <RenderResponsive visibility={ResponsiveVisibility.Hidden} breakpoints={ResponsiveBreakpoint.Xs | ResponsiveBreakpoint.Sm}>
							<div className={styles.exportBlock}>
								<form method="post" action={pledgeExportUrl} ref={this.setFormRef}>
									<input name="__RequestVerificationToken" type="hidden" value={(antiForgeryTokenElement && antiForgeryTokenElement.value || "")}></input>
									<button type="button" onClick={this.handleExport} disabled={isExporting} data-pp-at-target="Pledge Export" className={styles.exportButton}>Export</button>
								</form>
							</div>
						</RenderResponsive>}
					</div>
					
					
					<div className={styles.pledgeTableWrapper}>
						<Match state={States.FetchingPledges}>
							<div className={sharedStyles.spinnerContainer}>
								<Spinner className={sharedStyles.loadSpinner} />
							</div>
						</Match>
						<MatchNot state={States.FetchingPledges}>
							<WithDeletePledge
								deletingState={States.ConfirmPledgeDelete}
								processingDeleteState={States.DeletingPledge}
								onDelete={this.deletePledge}
								onConfirmDelete={this.confirmDeletePledge}
								onCancelDelete={this.cancelDeletePledge}>
									<PledgesGridWithTotals 
										pledges={pledgeTableData && pledgeTableData.Results} 
										hasCampaignNameColumn={false}
										pledgeLabel={pledgeLabel}
										paymentLabel={paymentLabel} 
									/>
							</WithDeletePledge>
						</MatchNot>
					</div>
					<Pagination numberOfPages={pageCount} currentPage={pageNumber} onPageChange={this.fetchTableData}/>
				</MatchNot>
			</div>
		);
	}

	fetchTableData = (pageNumber: number) => {
		const { vm, machineContext } = this.props;
		const campaignId = machineContext.viewModel.mainDetailsViewModel.CampaignId;
		const req = vm.getTableDataRequest(pageNumber, campaignId);
		Events.raise.FetchPledgeData(machineContext, req);
	}

	deletePledge = () => {
		Events.raise.Delete(this.props.machineContext);
	}

	confirmDeletePledge = (pledgeId: number) => {
		const { vm, machineContext } = this.props;
		const campaignId = machineContext.viewModel.mainDetailsViewModel.CampaignId;
		const req = vm.getDeletePledgeRequest(vm.pageNumber, campaignId, pledgeId);
		Events.raise.DeleteConfirmed(machineContext, req);
	}

	cancelDeletePledge = () => {
		Events.raise.Cancel(this.props.machineContext);
	}

	updateSearchQuery = (query: string) => {
		this.props.vm.searchQuery = query;
	}

	submitSearch = () => {
		this.fetchTableData(1);
	}

	setFormRef = (ref:HTMLFormElement) => {
		this.formExportRef = ref;
	}

	handleExport = (e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		const { isExporting } = this.props.vm;
		if(this.formExportRef && !isExporting){
			this.props.vm.isExporting = true;
			this.formExportRef.submit();
			e.preventDefault();
			window.setTimeout(() => {
				this.props.vm.isExporting = false;
			}, ExportDisableTimer);
		}
	}
}

interface IOverviewAmountsProps {
	campaignStatistics: CampaignStatisticsViewModel;
	pledgeStatistics: PledgeStatisticsViewModel;
	goalAmount: number;
	pledgeLabel: PledgeLabel;
}

const OverviewAmounts: React.StatelessComponent<IOverviewAmountsProps> = observer((props) => {
	const { 
		campaignStatistics: { CollectedAmount }, 
		pledgeStatistics: { PledgedAmount }, 
		goalAmount,
		pledgeLabel
	} = props;

	if (CollectedAmount === null && PledgedAmount === null && goalAmount === null) {
		return null;
	}

	return (
		<div className={styles.priorityTotals}>
			{ CollectedAmount !== null &&
				<OverviewTotalFragment label="Total raised" value={'$' + Formatter.formatNumberForDisplay(CollectedAmount)} acceptanceTestTargetId="Collected amount" />
			}
			{ PledgedAmount !== null &&
				<OverviewTotalFragment label={NewFeatures.DonorPledgeEntry_ConfigurablePledgeGrammar ? `Total ${pledgeLabel.VerbPastTenseLowerCase}` : 'Total pledged'} value={'$' + Formatter.formatNumberForDisplay(PledgedAmount)} acceptanceTestTargetId="Pledged amount" />
			}
			{ goalAmount !== null &&
				<OverviewTotalFragment label="Goal" value={'$' + Formatter.formatNumberForDisplay(goalAmount)} acceptanceTestTargetId="Goal amount" extraClassName={styles.goalTotal} />
			}
		</div>
	);
});

const OverviewCounts: React.StatelessComponent<{
	campaignStatistics: CampaignStatisticsViewModel, 
	pledgeStatistics: PledgeStatisticsViewModel,
	pledgeLabel: PledgeLabel }> = observer((props) => {
	const { 
		campaignStatistics: { RecurringScheduleCount }, 
		pledgeStatistics: { PledgeCount },
		pledgeLabel,
	} = props;

	if (RecurringScheduleCount === null && PledgeCount === null) {
		return null;
	}

	return (
		<div className={styles.secondaryTotals}>
			{ RecurringScheduleCount !== null &&
				<OverviewTotalFragment
					label="Total recurring schedules"
					value={Formatter.formatNumberForDisplay(RecurringScheduleCount, 0)}
					acceptanceTestTargetId="Recurring schedule count" />
			}
			{ PledgeCount !== null &&
				<OverviewTotalFragment 
					label={NewFeatures.DonorPledgeEntry_ConfigurablePledgeGrammar ? `Individual ${pledgeLabel.NounPluralLowerCase}` : 'Total pledgers'} 
					value={Formatter.formatNumberForDisplay(PledgeCount, 0)} 
					acceptanceTestTargetId="Pledge count" />
			}
		</div>
	);
});

const OverviewTotalFragment: React.StatelessComponent<{label: string, value: string, acceptanceTestTargetId: string, extraClassName?: string}> = observer((props) => {

	const { label, value, acceptanceTestTargetId, extraClassName } = props;

	return (
		<span className={classNames(styles.total, extraClassName)}>
			<span className={styles.totalLabel}>{label}</span>
			<span className={styles.totalValue} data-pp-at-target={acceptanceTestTargetId}>{value}</span>
		</span>
	);
});

interface ISearchBox {
	query: string;
	onChange: (query: string) => void;
	onSubmit: () => void;
}

class SearchBox extends React.Component<ISearchBox> {
	render() {
		const { query } = this.props;
		return (
			<Fragment>
				<form onSubmit={this.submitSearch} className={styles.searchBox}>
					<input type="text" placeholder={NewFeatures.DonorPledgeEntry_ConfigurablePledgeGrammar ? 'Search community' : 'Search pledgers'} onChange={this.queryChanged} value={query} className={styles.searchInput} />
					<button className={styles.searchButton}><SvgWrapper svg="icon-search" className={styles.searchIcon} /></button>
				</form>
				<Button className={classNames('btn', 'btn-link', styles.resetLink)} disabled={query === ''} onClick={this.resetSearch}>Reset</Button>
			</Fragment>
		);
	}

	queryChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.props.onChange(e.currentTarget.value);
	}

	submitSearch = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		this.props.onSubmit();
	}

	resetSearch = () => {
		const { onChange, onSubmit } = this.props;
		onChange('');
		onSubmit();
	}
}
