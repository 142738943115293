import * as React from 'react';
import { observer } from 'mobx-react';
import { ResponsiveVisibility, ResponsiveBreakpoint } from '../../helpers/responsive-helper';
import { RenderResponsive} from '../../components/hoc-behavior/render-responsive';
import { SvgWrapper } from '../../components/svg-wrapper';
import { detectEdge } from '../../utils/browser-detection';
import { velocity } from '../../helpers/velocity';
import { AllocationItemImagesStore } from './allocation-item-images-store';

//todo: consolidate these values - import from transitions but we'll need to move it out of Funds
export const enterTransitionDuration: number = 250;
export const leaveTransitionDuration: number = 150;

export interface IAllocationItemImagesProps {
	store: AllocationItemImagesStore;
	checkImageUrl: string;
	checkNumber: string;
}

@observer
export class AllocationItemImages extends React.Component<{
	store: AllocationItemImagesStore,
	checkImageUrl: string,
	checkNumber: string
}, {}> {
	images: HTMLElement;

	render() {
		const { checkImageUrl, checkNumber, store } = this.props;
		const flipContainerClassnames = `flip-container${store.showBackImage ? ' flip-container-flipped' : ''}${!detectEdge() ? ' flip-container-perspective' : ''}`;

		return (
			<div className="cd-allocation-item-form-images" ref={(images) => this.images = images}>
				<div className="cd-allocation-item-form-image-container">
					<div className={flipContainerClassnames}>
						<div className="flip-flipper">
							<div className="cd-allocation-item-form-image flip-front">
								<img src={checkImageUrl} alt={`Check ${checkNumber} front`} />
							</div>
							<div className="cd-allocation-item-form-image flip-back">
								<img src={checkImageUrl} alt={`Check ${checkNumber} back`} />
							</div>
						</div>
					</div>
					<RenderResponsive visibility={ResponsiveVisibility.Hidden} breakpoints={ResponsiveBreakpoint.Xs}>
						<button type="button" className="btn cd-allocation-item-form-image-flip-button" onClick={store.onFlipImage}>
							<SvgWrapper className="icon icon-flip" svg="icon-flip" title="Flip image" />
							<div>FLIP</div>
						</button>
					</RenderResponsive>
				</div>
			</div>
		);
	}

	componentWillEnter(callback) {
		velocity(this.images, 'slideDown', {
			duration: enterTransitionDuration,
			complete: callback,
			easing: 'ease-in-out',
		});
	}

	componentWillLeave(callback) {
		velocity(this.images, 'slideUp', {
			duration: leaveTransitionDuration,
			complete: callback,
			easing: 'ease-in-out',
		});
	}
}

export const AllocationItemImagesControls = observer((props: { store: AllocationItemImagesStore, classNames?: string }) => (
	<div className={`cd-allocation-item-form-images-control ${props.classNames || ''}`}>
		<button type="button" className="btn btn-link" onClick={props.store.onToggleImages}>
			{props.store.showImages
				? <span className="arrow-hollow-up-after">Hide check</span>
				: <span className="arrow-hollow-down-after">Show check</span>
			}
		</button>
	</div>
));

const AllocationItemImagesControlsSideButton = (props: { selected: boolean, onFlipImage: () => void, text: string }) => (
	props.selected
		? <strong className="cd-allocation-item-form-image-controls-side-selected">{props.text}</strong>
		: <button type="button" className="btn btn-tertiary" onClick={props.onFlipImage}>{props.text}</button>
);

@observer
export class AllocationItemImagesControlsSide extends React.Component<{ store: AllocationItemImagesStore }, {}> {
	sideControls: HTMLElement;

	render() {
		const { onFlipImage, showBackImage } = this.props.store;

		return (
			<div className="cd-allocation-item-form-images-controls-side" ref={(sideControls) => this.sideControls = sideControls}>
				<AllocationItemImagesControlsSideButton selected={!showBackImage} text="Front" onFlipImage={onFlipImage} />
				<AllocationItemImagesControlsSideButton selected={showBackImage} text="Back" onFlipImage={onFlipImage} />
			</div>
		);
	}

	componentWillEnter(callback) {
		this.sideControls.style.opacity = '0';
		velocity(this.sideControls, 'fadeIn', {
			duration: enterTransitionDuration,
			complete: callback,
			easing: 'ease-in-out',
		});
	}

	componentWillLeave(callback) {
		velocity(this.sideControls, 'fadeOut', {
			duration: leaveTransitionDuration,
			complete: callback,
			easing: 'ease-in-out',
		});
	}
}

