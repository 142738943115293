import * as React from 'react';
import { take, spawn } from 'redux-saga/effects';
import { BatchEntrySagaContext } from '../../../pages/batch-entry/batch-entry-saga';
import { IBatchEntryCloseBatchErrorProps, BatchEntryCloseBatchError } from './batch-entry-close-batch-error';
import { ModalDialogCommander } from '../../../../components/modal-dialog-commander';
import { Channel, userActionChannel } from '../../../../../Shared/utils/user-action-channel';
import {
	IBatchEntryCloseBatchConfirmProps,
	BatchEntryCloseBatchConfirmUserAction,
	BatchEntryCloseBatchConfirm
} from './batch-entry-close-batch-confirm';
import { CloseBatchRequestModel, CloseBatchResponseModel } from '../../../virtual-terminal-generated';
import { alertController } from '../../../../components/alert-controller';
import { cancelPollingForBatchPayments, fetchAllBatchPayments, fetchAllBatchPaymentsAndStartPolling } from '../../batch-entry-payments/batch-entry-payments-saga';
import { PaymentTotal } from '../../batch-entry-payments/batch-entry-payments-store';

export function* handleCloseBatch(context: BatchEntrySagaContext, merchantId: number, model: CloseBatchRequestModel) {
	const { mainViewModel: { batchDetailsViewModel }, dataService } = context;

	if (!batchTotalsMatchExpectedTotals(context)) {
		showCloseBatchErrorDialog(context);
		return;
	}

	const userAction = yield showCloseConfirmationDialogAndWaitForConfirmation(context);

	if (closeBatchIsCancelled(userAction)) {
		ModalDialogCommander.forceCloseCurrent();
		return;
	}

	const response: CloseBatchResponseModel = yield dataService.closeBatch({ merchantId, model });
	const { Batch, BatchTotalsDoNotMatchExpectedTotals } = response;

	if (Batch.EventTimeId !== null && !batchDetailsViewModel.eventTimes.some(x => x.Id === Batch.EventTimeId)) {
		// Handles a corner case with multiple tabs wherein:
		// Batch details page is opened in tab 1
		// The batch's service time is updated (and cloned) in tab 2
		// In tab 1 (without refreshing the page) the batch is completed
		// The view model will now not have the batch's newly cloned service time unless we manually refresh
		const eventTimes = yield dataService.getEventTimes({ merchantId });
		batchDetailsViewModel.setEventTimes(eventTimes);
	}	

	if (BatchTotalsDoNotMatchExpectedTotals) {
		showCloseBatchErrorDialog(context);
		yield fetchAllBatchPaymentsAndStartPolling(context);
		return;
	}

	batchDetailsViewModel.setBatch(Batch);
	ModalDialogCommander.forceCloseCurrent();
	batchDetailsViewModel.hideDefaultSettings();
	alertController.showSuccess(`Your batch ${Batch.Name} is now completed.`);

	yield cancelPollingForBatchPayments();
	yield spawn(fetchAllBatchPayments, context);
}

function closeBatchIsCancelled(userAction: BatchEntryCloseBatchConfirmUserAction) {
	return userAction instanceof BatchEntryCloseBatchConfirmUserAction.Cancel;
}

function* showCloseConfirmationDialogAndWaitForConfirmation(context: BatchEntrySagaContext) {
	const { mainViewModel: { batchDetailsViewModel, listingStore, batchPaymentsStore } } = context;
	const { batch: { BatchId, Name } } = batchDetailsViewModel;
	const { paymentLabel: { NounPluralLowerCase } } = listingStore;
	const { batchPaymentsTotal, batchPaymentsCount } = batchPaymentsStore;

	const confirmationChannel: Channel<BatchEntryCloseBatchConfirmUserAction> = userActionChannel();
	const confirmationDialogProps: IBatchEntryCloseBatchConfirmProps = {
		batchId: BatchId,
		batchName: Name,
		paymentNounPlural: NounPluralLowerCase,
		totalAmount: paymentTotalToNumber(batchPaymentsTotal),
		numberOfGifts: paymentTotalToNumber(batchPaymentsCount),
		userActionChannel: confirmationChannel
	};

	const confirmationDialog = React.createElement(BatchEntryCloseBatchConfirm, { ...confirmationDialogProps });

	ModalDialogCommander.showReactForm(confirmationDialog);

	return yield take(confirmationChannel);
}

function showCloseBatchErrorDialog(context: BatchEntrySagaContext) {
	const { mainViewModel: { batchPaymentsStore, batchDetailsViewModel: { batch }, listingStore: { paymentLabel } } } = context;
	const { batchPaymentsTotal, batchPaymentsCount } = batchPaymentsStore;
	const { ExpectedTotalAmount, ExpectedTotalCount } = batch;
	const { NounLowerCase, NounPluralLowerCase } = paymentLabel;

	const errorDialogProps: IBatchEntryCloseBatchErrorProps = {
		totalAmount: paymentTotalToNumber(batchPaymentsTotal),
		expectedTotalAmount: ExpectedTotalAmount,
		totalCount: paymentTotalToNumber(batchPaymentsCount),
		expectedTotalCount: ExpectedTotalCount,
		paymentNoun: NounLowerCase,
		paymentNounPlural: NounPluralLowerCase,
	};
	const errorDialog = React.createElement(BatchEntryCloseBatchError, { ...errorDialogProps });
	ModalDialogCommander.showReactDialog(errorDialog);
}

function batchTotalsMatchExpectedTotals(context: BatchEntrySagaContext) {
	const { mainViewModel: { batchDetailsViewModel, batchPaymentsStore } } = context;
	const { batchPaymentsTotal, batchPaymentsCount } = batchPaymentsStore;
	const { batch: { ExpectedTotalAmount, ExpectedTotalCount } } = batchDetailsViewModel;

	if (!ExpectedTotalAmount && !ExpectedTotalCount) {
		return true;
	}

	if (!batchPaymentsTotal.fetched || !batchPaymentsCount.fetched) {
		return false;
	}

	if (ExpectedTotalAmount && ExpectedTotalAmount !== batchPaymentsTotal.total) {
		return false;
	}
	if (ExpectedTotalCount && ExpectedTotalCount!== batchPaymentsCount.total) {
		return false;
	}
	return true;
}

function paymentTotalToNumber(paymentTotal: PaymentTotal): number {
	if (paymentTotal.fetched) {
		return paymentTotal.total;
	}
	return 0;
}
