import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { ResponseTypes } from '../funds-generated';
import { Button } from '../../components/button';
import { ModalDialog, IModalDialogButton } from '../../components/modal-dialog';
import { ModalProgressDialog } from './modal-progress-dialog';
import { ModalDialogCommander } from '../../components/modal-dialog-commander';
import { makeAjaxCall } from '../utils/ajax-helper';
import FundValidationError = ResponseTypes.FundValidationError;
import ReallocateTransactionsEditModel = ResponseTypes.ReallocateTransactionsEditModel;
import ReallocateRecurringScheduleFundEditModel = ResponseTypes.ReallocateRecurringScheduleFundEditModel;
import TransactionsQueryModel = ResponseTypes.TransactionsQueryModel;
import RecurringSchedulesQueryModel = ResponseTypes.RecurringSchedulesQueryModel;

export interface ISelectListItem {
	Text: string;
	Value: string;
	Group: string;
}

export interface ISelectListItemGroup {
	Label: string;
	Options: ISelectListItem[];
}

export interface IReallocationControlProps {
	filters: string;
	query: TransactionsQueryModel | RecurringSchedulesQueryModel;
	shownTransactionCount: number;
	totalTransactionCount: number;
	urlReallocate: string;
	availableFunds: ISelectListItem[];
	availableFundsGrouped: ISelectListItemGroup[];
	itemToReallocateName: string;
	isTransactionReallocation: boolean;
}

export interface IReallocationControlState {
	selectedTransactionKeys: number[];
	selectedFund: string;
	reallocateAll: boolean;
}

export interface IConfirmReallocationDialogProps {
	numberOfPaymentsToReallocate: number;
	selectedPaymentIds: number[];
	query: TransactionsQueryModel | RecurringSchedulesQueryModel;
	selectedFund: string;
	selectedFundKey: string;
	urlReallocate: string;
	reallocateAll: boolean;
	itemToReallocateName: string;
	isTransactionReallocation: boolean;
}

export let ReallocationProgressDialog = (url, itemToReallocateName) =>
	<ModalProgressDialog title={`Reallocating ${itemToReallocateName}: `} progressUrl={url}
		timeoutMessage={`Reallocating ${itemToReallocateName} has timed out`}
		progressEnd={ModalProgressDialog.ProgressEnd.Reload} />;

export let ReallocationErrorDialog = (itemToReallocateName, error =
	{ ErrorMessage: `There was a problem reallocating ${itemToReallocateName}, please try again later` } as FundValidationError) =>
	<ModalDialog title="">{error.ErrorMessage}</ModalDialog>;

export class ConfirmReallocationDialog extends React.Component<IConfirmReallocationDialogProps, { isProcessingRequest: boolean }> {
	constructor(props) {
		super(props);
		this.state = { isProcessingRequest: false };
	}

	confirmReallocation() {

		this.setState({
			isProcessingRequest: true
		});
		var model: ReallocateTransactionsEditModel | ReallocateRecurringScheduleFundEditModel;
		if (this.props.isTransactionReallocation) {
			model = {
				FundKey: this.props.selectedFundKey,
				PaymentIds: this.props.selectedPaymentIds,
				Query: this.props.query,
				ReallocateAll: this.props.reallocateAll
			} as ReallocateTransactionsEditModel;
		} else {
			model = {
				DestinationFundKey: this.props.selectedFundKey,
				ScheduleIds: this.props.selectedPaymentIds,
				Query: this.props.query,
				ReallocateAll: this.props.reallocateAll
			} as ReallocateRecurringScheduleFundEditModel;
		}
		makeAjaxCall(this.props.urlReallocate, model)
			.fail(error => {
				ModalDialogCommander.showReactDialog(ReallocationErrorDialog(`${this.props.itemToReallocateName}${this.props.numberOfPaymentsToReallocate > 1 ? 's' : ''}`, error));
			})
			.then((progressUrl) => {
				ModalDialogCommander.showReactDialog(ReallocationProgressDialog(progressUrl, `${this.props.itemToReallocateName}${this.props.numberOfPaymentsToReallocate > 1 ? 's' : ''}`));
				return jQuery.Deferred().promise();
			})
			.always(() => {
				this.close();
				this.setState({
					isProcessingRequest: false
				});
			});
	}

	close() {
		ModalDialogCommander.close(ReactDOM.findDOMNode(this) as Element);
	}

	render() {
		const reallocateButton = <Button key="reallocate_button"
			className="btn btn-primary"
			onClick={() => this.confirmReallocation()}
			isProcessingRequest={this.state.isProcessingRequest}>Reallocate</Button>;
		const buttons: (IModalDialogButton | JSX.Element)[] = [
			{
				label: 'Cancel',
				dismiss: true,
				isPrimary: false,
				hideOnProgress: true
			},
			reallocateButton];

		return (
			<ModalDialog title="" buttons={buttons} inProgress={this.state.isProcessingRequest}>
				<h4>Confirm reallocation of {this.props.numberOfPaymentsToReallocate} {this.props.itemToReallocateName}
					{this.props.numberOfPaymentsToReallocate > 1 ? 's' : ''} to '{this.props.selectedFund}'</h4>
			</ModalDialog>
		);
	}
}


export class ReallocationControl extends React.Component<IReallocationControlProps, IReallocationControlState> {

	private fundSelectElement: HTMLSelectElement;

	constructor(props: IReallocationControlProps) {
		super(props);
		this.state = {
			selectedTransactionKeys: [],
			selectedFund: '',
			reallocateAll: false
		};

		this.fundSelected = this.fundSelected.bind(this);
		this.showReallocationDialog = this.showReallocationDialog.bind(this);
	}

	getSelectedTransactionCount() {
		const selectedRowsCount = this.state.selectedTransactionKeys.length;
		return this.props.shownTransactionCount === selectedRowsCount ? this.props.totalTransactionCount : selectedRowsCount;
	}

	fundSelected() {
		var selectedValue = this.fundSelectElement.value;
		this.setState({ selectedFund: selectedValue });
	}

	renderFundDropDownListOption(fund: ISelectListItem) {
		return (<option value={fund.Value}>{fund.Text}</option>);
	}

	showReallocationDialog() {
		const selectedFund = this.props.availableFunds.filter(x => x.Value === this.state.selectedFund)[0].Text;
		const dialog = <ConfirmReallocationDialog selectedFund={selectedFund}
			numberOfPaymentsToReallocate={this.getNumberOfTransactionsToReallocate()}
			selectedPaymentIds={this.state.selectedTransactionKeys.slice(0, 1000)}
			selectedFundKey={this.state.selectedFund}
			query={this.props.query}
			reallocateAll={this.state.reallocateAll}
			urlReallocate={this.props.urlReallocate}
			isTransactionReallocation={this.props.isTransactionReallocation}
			itemToReallocateName={this.props.itemToReallocateName} />;
		ModalDialogCommander.showReactDialog(dialog);
	}

	getNumberOfTransactionsToReallocate(): number {
		return Math.min(this.state.reallocateAll
			? this.props.totalTransactionCount
			: this.state.selectedTransactionKeys.length, 1000);
	}

	renderReallocateButton() {
		const isDisabled = this.state.selectedTransactionKeys.length === 0 || !this.state.selectedFund;
		return (<Button className="btn btn-ghost col-sm-6"
			disabled={isDisabled} isProcessingRequest={false} onClick={this.showReallocationDialog}>
			Reallocate
				</Button>);
	}

	renderLabel() {
		const selectedRowsCount = this.getNumberOfTransactionsToReallocate();
		if (selectedRowsCount) {
			return (
				<span className="col-md-6 control-label">
					Move {selectedRowsCount} selected {this.props.itemToReallocateName}{selectedRowsCount > 1 ? 's' : ''} to a different fund
				</span>
			);
		} else {
			return (
				<span className="col-md-6 control-label">
					Select {this.props.itemToReallocateName}s to move to a different fund
				</span>
			);
		}
	}

	renderReallocationForm() {
		const selectedRowsCount = this.getNumberOfTransactionsToReallocate();
		return selectedRowsCount < 1 ? null : <div className="form-group">
			<div className="row reallocation-label">
				{this.renderLabel()}
			</div>
			<div className="col-sm-4 form-group-fund reallocation-label">
				<div className="select-wrapper new-design-select-wrapper">
					{this.renderFundDropdownField(this.props.availableFundsGrouped)}
				</div>
			</div>
			<div className="col-sm-2">
				{this.renderReallocateButton()}
			</div>
		</div>;
	}

	renderFundDropdownField(funds: ISelectListItemGroup[]) {
		return (
			<select className="form-control"
				onChange={this.fundSelected}
				ref={(ref) => { this.fundSelectElement = ref; }}
				value={this.state.selectedFund}>
				<option value="">Select a new fund</option>
				{funds.filter(x => x.Options.length > 0).map((x, index) => {
					return this.renderFundDropdownFieldOptions(x, index);
				})}
			</select>
		);
	}

	renderFundDropdownFieldOptions(item: ISelectListItemGroup, index: number) {
		return (
			<optgroup key={index} label={item.Label}>
				{item.Options.map(this.renderFundDropdownFieldOptionItems)}
			</optgroup>
		);
	}

	renderFundDropdownFieldOptionItems(item: ISelectListItem) {
		return <option value={item.Value} key={item.Value}>{item.Text}</option>;
	}

	render() {
		return (
			<div className="reallocation-control">
				<div className="form-horizontal">
					{this.renderReallocationForm()}
				</div>
				<h4 className="grid-counter">
					Showing {this.props.totalTransactionCount} {this.props.itemToReallocateName}{this.props.totalTransactionCount > 1 ? 's' : ''} {this.props.filters}
				</h4>
			</div>
		);
	}
}
