import { action, computed, reaction, observable } from 'mobx';
import { getCheckDepositDataService } from '../check-deposit-data-service';
import { createStats, millisecondsSinceNavigationStart } from '../../utils/page-loading-stats';
import { AllocationItemFormViewModel } from './allocation-item-form-store';
import { Models } from '../check-deposit-generated';
import CheckImageState = Models.CheckImageState;
import CheckImageSource = Models.CheckImageSource;

export class AllocationItemImagesStore {
	@observable showBackImage: boolean = false;
	@observable showImages: boolean = true;

	private unsubscribeFromLogCheckImageStats: Function;

	constructor(private check: AllocationItemFormViewModel, private checkImageSource: CheckImageSource) {
		//track check image state changes
		this.unsubscribeFromLogCheckImageStats = reaction(() => this.CheckImageState, this.logCheckImageStats);
	}

	@action
	onFlipImage = () => {
		this.showBackImage = !this.showBackImage;
	}

	@action
	onToggleImages = () => {
		this.showImages = !this.showImages;
	}

	@computed
	private get CheckImageState(): CheckImageState {
		if (!this.showImages) {
			return CheckImageState.Closed;
		}
		return this.showBackImage ? CheckImageState.Back : CheckImageState.Front;
	}

	destroy() {
		this.unsubscribeFromLogCheckImageStats();
	}

	private logCheckImageStats = (state: CheckImageState) => {
		getCheckDepositDataService().initRequest('logCheckImageStats', {
			model: createStats({
				BatchId: this.check.BatchId,
				EncodedPaymentToken: this.check.EncodedToken,
				CheckImageState: state,
				TimeSinceNavigationStart: millisecondsSinceNavigationStart(),
				CheckImageSource: this.checkImageSource
			})
		});
	}
}
