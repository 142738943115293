import * as React from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { FormControlLabelled, FormControlType } from '../../../components/form-controls/form-control-labelled';

export interface ICustomSelectorProps {
	startDate: Date;
	endDate: Date;
	minimumYear: number;
	currentDate: Date;

	onCustomChange?: (start: Date, end: Date) => void;

	acceptanceTestTargetId: string;
	ariaLabel?: string;
	disabled?: boolean;
}

@observer
export class CustomSelector extends React.Component<ICustomSelectorProps, {}> {

	render() {
		const { startDate, endDate, currentDate, minimumYear, disabled } = this.props;
		const { acceptanceTestTargetId, ariaLabel } = this.props;

		const defaultStartDate = new Date(currentDate.getFullYear(), 0, 1);
		const minimumDate = new Date(minimumYear, 0, 1);
		const maximumDate = moment(currentDate).subtract(10, 'day').toDate();
		const startRules = this.validateStart(startDate, minimumDate, maximumDate);
		const endRules = this.validateEnd(startDate, endDate, minimumDate, maximumDate);

		// To rpevent the user selecting the same date, offsetting the from max date to be 1 day before the end/maximum
		// date. Doing the same for min date.
		const fromMaxDate = moment(endDate || maximumDate).subtract(1, 'day').toDate();
		const toMinDate = moment(startDate || minimumDate).add(1, 'day').toDate();

		return <div aria-label={ariaLabel}>
			<div className="row">
				<FormControlLabelled label="Start date"
					cssClassNames="col-sm-4"
					acceptanceTestTargetId={`${acceptanceTestTargetId}_start`}
					hideErrors={!startRules}
					formControlProps={{
						name: 'Custom-StartDate',
						formControlType: FormControlType.DatePicker,
						value: startDate,
						onChangeHandler: this.handleStartDateChange,
						validationRules: startRules,
						placeholder: 'Select a start date',
						options: {
							minDate: minimumDate,
							maxDate: fromMaxDate,
							defaultDate: defaultStartDate,
						},
						disabled,
					}} />
				<FormControlLabelled label="End date"
					cssClassNames="col-sm-4"
					acceptanceTestTargetId={`${acceptanceTestTargetId}_end`}
					hideErrors={!endRules}
					formControlProps={{
						name: 'Custom-EndDate',
						formControlType: FormControlType.DatePicker,
						value: endDate,
						onChangeHandler: this.handleEndDateChange,
						validationRules: endRules,
						placeholder: 'Select an end date',
						options: {
							minDate: toMinDate,
							maxDate: maximumDate,
							defaultDate: maximumDate,
						},
						disabled,
					}} />
			</div>
		</div>;
	}

	private validateStart(startDate: Date, minimumDate: Date, maximumDate: Date) {
		if (startDate === undefined || startDate === null) {
			return {required:{errorMessage:'Please select a start date'}};
		}

		if (startDate < minimumDate) {
			return {nevervalid:{errorMessage:'Please select a later date'}};
		}

		if (startDate > maximumDate) {
			return {nevervalid:{errorMessage:'Please select an earlier date'}};
		}
	}

	private validateEnd(startDate: Date, endDate: Date, minimumDate: Date, maximumDate: Date) {
		if (endDate === undefined || endDate === null) {
			return {required:{errorMessage:'Please select an end date'}};
		}

		if (endDate < minimumDate || endDate < startDate) {
			return {nevervalid:{errorMessage:'Please select a later date'}};
		}

		if (endDate > maximumDate) {
			return {nevervalid:{errorMessage:'Please select an earlier date'}};
		}
	}

	private handleStartDateChange = (startDate: Date) => {
		const { onCustomChange, endDate } = this.props;
		onCustomChange(startDate, endDate);
	}

	private handleEndDateChange = (endDate: Date) => {
		const { onCustomChange, startDate } = this.props;
		onCustomChange(startDate, endDate);
	}
}
