import moment from 'moment';
import { action, observable, computed } from 'mobx';
import * as Models from '../models/index';
import { Models as GeneratedModels } from '../community-member-generated';

export interface IPersonalDetails {
	FirstName: string;
	LastName: string;
	EmailAddress: string;
	MobileNumber: string;
	LastPaymentOnLocal: Date;
	Address: Models.CommunityAddressModel;
	LockVersion: number;
	CommunityMemberType: GeneratedModels.CommunityMemberType;
}

export default class PersonalDetails implements IPersonalDetails {
	@observable FirstName: string;
	@observable LastName: string;
	@observable EmailAddress: string;
	@observable MobileNumber: string;
	@observable LastPaymentOnLocal: Date;
	@observable Address: Models.CommunityAddressModel;
	@observable LockVersion: number;
	@observable CommunityMemberType: GeneratedModels.CommunityMemberType;

	@computed
	get IsOrganization() {
		return this.CommunityMemberType === GeneratedModels.CommunityMemberType.Organization;
	}

	toString() {
		return [this.FirstName, this.LastName, this.EmailAddress, this.MobileNumber, this.Address.DisplayAddress].join(' ');
	}

	@action
	importValues(v: Models.CommunityMemberModel | Models.PersonModel | PersonalDetails) {
		this.FirstName = v.FirstName;
		this.LastName = v.LastName;
		this.EmailAddress = v.EmailAddress;
		this.MobileNumber = v.MobileNumber;
		this.Address = Object.assign({}, v.Address);
		this.CommunityMemberType = v.CommunityMemberType;
		const lastPaymentOnLocal = v.LastPaymentOnLocal;
		if (typeof lastPaymentOnLocal === 'string') {
			this.LastPaymentOnLocal = moment(lastPaymentOnLocal).toDate();
		} else {
			this.LastPaymentOnLocal = lastPaymentOnLocal;
		}
		this.LockVersion = hasLockVersion(v) ? v.LockVersion : 0;
		this.CommunityMemberType = v.CommunityMemberType;
	}
}

function hasLockVersion(model: any): model is { LockVersion: number } {
	return model.LockVersion !== null && model.LockVersion !== undefined;
}
