import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { observer, Provider } from 'mobx-react';
import { Fragment } from '../../../../Shared/components/fragment';
import { PageIntro } from '../../components/page-intro/page-intro';
import { QboHelpPanel } from '../../components/help-panel/help-panel';
import { QboIntroPanel } from '../../components/qbo-intro-panel';
import { QboConfigurationMainViewModel } from './qbo-configuration-main-view-model';
import { getActionHandlers, QboConfigurationSagaContext, initializeMappingData } from './qbo-configuration-main-saga';
import { runQboIntegrationSaga } from '../../saga/qbo-integration-saga';
import { QboConfigurationUserAction } from './qbo-configuration-user-actions';
import { QboMappingForm } from '../../components/mapping-form';
import { FlashMessageBox } from '../../components/flash-message-box';

export function withQboConfigurationSagaContext<TProps extends { vm: QboConfigurationMainViewModel }>(
	Component: React.ComponentType<TProps>,
	getSagaContext: (vm: QboConfigurationMainViewModel) => QboConfigurationSagaContext
) {
	return (props: TProps) => <Component {...props} qboConfigurationSagaContext={getSagaContext(props.vm)} />;
}

type WithQboConfigurationSagaContext<T> = { qboConfigurationSagaContext: QboConfigurationSagaContext } & T;

@observer
export class QboConfigurationMainCore extends React.Component<WithQboConfigurationSagaContext<{ vm: QboConfigurationMainViewModel }>> {
	private readonly qboConfigurationSagaContext: QboConfigurationSagaContext;
	private errorMessageRef: HTMLDivElement;

	constructor(props) {
		super(props);

		this.qboConfigurationSagaContext = props.qboConfigurationSagaContext;
		runQboIntegrationSaga(this.qboConfigurationSagaContext, getActionHandlers(), initializeMappingData);
	}

	render() {
		const { vm } = this.props;
		const { isProcessingRequest, isAuthenticated, mappingInfo, isMappingInfoReady, instanceName, serverMessage, initialServerMessage } = this.props.vm;
		let title = 'QuickBooks\xAE Online Integration';
		let messageToDisplay = serverMessage || initialServerMessage;
		return (
			<Provider userActionChannel={this.qboConfigurationSagaContext.userActionChannel}>
				<Fragment>
					<PageIntro
							{...vm}
							isEditing={vm.isEditing}
							title={title}
							instanceName={instanceName}
							connectButtonLabel="Connect QuickBooks"
							connectIntegrationAction={this.authenticateIntegrationAction}
							enableIntegrationAction={this.enableIntegration}
							disableIntegrationAction={this.disableIntegration}
							removeIntegrationAction={this.removeIntegration}>
						Connect with QuickBooks Online to have your batches automatically created as journal entries.
					</PageIntro>
					<QboHelpPanel helpPageUri={vm.helpPageUri}/>
					{ messageToDisplay && <div ref={(ref) => this.errorMessageRef = ref}><FlashMessageBox {...messageToDisplay}/></div> }
					{ isAuthenticated ?
						<QboMappingForm isMappingInfoLoaded={isMappingInfoReady} vm={mappingInfo} isProcessingRequest={isProcessingRequest} /> :
						<QboIntroPanel connectAction={this.authenticateIntegrationAction} isProcessingRequest={isProcessingRequest} /> }
				</Fragment>
			</Provider>
		);
	}

	componentDidUpdate() {
		if(this.props.vm.serverMessage) {
			(ReactDOM.findDOMNode(this.errorMessageRef) as Element).scrollIntoView(false);
		}
	}

	authenticateIntegrationAction = () => {
		this.qboConfigurationSagaContext.userActionChannel.put(new QboConfigurationUserAction.AuthorizeQuickBooks());
	}

	enableIntegration = () => {
		this.qboConfigurationSagaContext.userActionChannel.put(new QboConfigurationUserAction.EnableIntegration());
	}

	disableIntegration = () => {
		this.qboConfigurationSagaContext.userActionChannel.put(new QboConfigurationUserAction.DisableIntegration());
	}

	removeIntegration = () => {
		this.qboConfigurationSagaContext.userActionChannel.put(new QboConfigurationUserAction.DeleteIntegration());
	}
}

export const QboConfigurationMain = withQboConfigurationSagaContext(QboConfigurationMainCore, (vm) => new QboConfigurationSagaContext(vm));
