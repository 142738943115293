import * as React from 'react';
import { ValidatableForm } from '../../components/form-controls/validatable-form';
import { ImportFileUpload } from '../components/import-file-upload';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import { PaymentLabel } from '../../loggedinweb-generated';

export interface IImportFileUploadFormProps {
	navigationInProgress: boolean;
	showDonorsFile: boolean;
	fileTypeName: string;
	paymentLabel: PaymentLabel;
	handleSubmit: () => void;
	handleCancelImport: () => void;
	handleTransactionsFileChange: (file: File) => void;
	handleDonorsFileChange: (file: File) => void;
}

@observer
export class FileUploadForm extends React.Component<IImportFileUploadFormProps> {
	render() {
		const {
			navigationInProgress,
			showDonorsFile,
			handleSubmit,
			handleCancelImport,
			handleTransactionsFileChange,
			handleDonorsFileChange,
			fileTypeName,
			paymentLabel
		} = this.props;

		var capitalisedFileTypeName = fileTypeName.charAt(0).toUpperCase() + fileTypeName.slice(1);

		return (
			<ValidatableForm onSubmit={handleSubmit}>
				<div className="panel panel-default">
					<div className="panel-heading">Select files</div>
					<div className="panel-body">
						<ImportFileUpload
							name="transactions"
							label={`${capitalisedFileTypeName}s file`}
							tooltip={`Your ${fileTypeName} file should include information relating to your ${paymentLabel.NounPluralLowerCase}.`}
							onFileChange={handleTransactionsFileChange}
							required={true} />
						{ showDonorsFile &&
							<ImportFileUpload
								name="{donors"
								label={`${paymentLabel.PayerPluralSentenceCase} file`}
								tooltip={`Your ${paymentLabel.PayerPluralLowerCase} file should only include information relating to your ${paymentLabel.PayerPluralLowerCase}.`}
								onFileChange={handleDonorsFileChange}
								required={true} />}
					</div>
					<div className="panel-footer panel-footer-btn-group">
						<button type="button" className="btn btn-cancel" id="cancel" onClick={handleCancelImport} disabled={navigationInProgress}>Cancel</button>
						<button type="submit" className="btn btn-default" id="upload" disabled={navigationInProgress}>Upload</button>
					</div>
				</div>
			</ValidatableForm>
		);
	}
}
