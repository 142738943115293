import * as React from 'react';
import {CSSTransitionGroup} from 'react-transition-group-v1';
import {DeleteFundButton} from './common-delete-fund-button';
import {SvgWrapper} from '../../components/svg-wrapper';
import {Formatter} from '../../helpers/formatter';
import {ResponseTypes} from '../funds-generated';

export interface IFundListItemProps {
	key: string;
	name: string;
	isOpen: boolean;
	editUrl: string;
	canDeleteUrl: string;
	deleteUrl: string;
	notes: string;
	code: string;
	isUnknown: boolean;
	defaultOnNumberOfListings: number;
	shownOnNumberOfListings: number;
	totalNumberOfListings: number;
	total: number;
	FundType: ResponseTypes.FundType;
	CampaignName: string;
	CampaignUrl: string;
	deleteFund: (key: string, deleteUrl: string) => JQueryPromise<any>;
}

export class FundListItem extends React.Component<{ item: IFundListItemProps }, {}> {

	getFundUsage() {
		const shownOnNumberOfListings = this.props.item.shownOnNumberOfListings;
		const totalNumberOfListings = this.props.item.totalNumberOfListings;

		if (shownOnNumberOfListings === 0) {
			return null;
		}

		let fundUsage: string;
		if (totalNumberOfListings === 1) {
			fundUsage = `${totalNumberOfListings} listing`;
		} else if (shownOnNumberOfListings === totalNumberOfListings) {
			fundUsage = `${totalNumberOfListings} listings`;
		} else {
			fundUsage = `${shownOnNumberOfListings} of ${totalNumberOfListings} listings`;
		}

		return (
			<small>
				{fundUsage}
			</small>
		);
	}

	renderLabel() {
		const defaultOnNumberOfListings = this.props.item.defaultOnNumberOfListings;
		if (defaultOnNumberOfListings > 0) {
			const listingsText = defaultOnNumberOfListings > 1 ? 'listings' : 'listing';
			return (
				<span className="label label-success">
					<SvgWrapper svg="star" />
					<span>Default ({defaultOnNumberOfListings} {listingsText})</span>
				</span>
			);
		}

		if (this.props.item.isOpen && this.props.item.shownOnNumberOfListings === 0) {
			return (
				<span className="label label-default">
					<SvgWrapper svg="eye-slash" />
					<span>Not visible<span className="hidden-xs"> in any listings</span></span>
				</span>
			);
		}
		return null;
	}

	renderAmount() {
		const fundTotalKey = `${this.props.item.key}_${this.props.item.total}`;
		const displayAmount = Formatter.formatNumberForDisplay(this.props.item.total);
		return (
				<CSSTransitionGroup
					component="div"
					className="fund-total"
					transitionName="fund-total"
					transitionEnterTimeout={7800}
					transitionLeaveTimeout={2000}>
					<div key={fundTotalKey}>
						<sup>$</sup><span>{displayAmount}</span>
					</div>
				</CSSTransitionGroup>
			);
	}

	renderDelete() {
		if (this.props.item.isOpen) {
			return null;
		}

		if (this.props.item.FundType === ResponseTypes.FundType.Campaign) {
			return null;
		}

		return (
			<DeleteFundButton urlCanDelete={this.props.item.canDeleteUrl}
				className="btn btn-link"
				canDeleteFund={() => { return this.deleteFund(); } }/>
		);
	}

	renderIsUnknownNotes() {
		return (
			<span dangerouslySetInnerHTML={{ __html: this.props.item.notes }}></span>
			);
	}

	deleteFund() {
		var key = this.props.item.key;
		var deleteUrl = this.props.item.deleteUrl;
		return this.props.item.deleteFund(key, deleteUrl);
	}

	renderCampaignDetails(): any {
		if (this.props.item.FundType === ResponseTypes.FundType.Campaign) {
			if (this.props.item.CampaignName && this.props.item.CampaignName.length > 0) {
				return <div>Linked to: <a href={this.props.item.CampaignUrl}>{this.props.item.CampaignName} Campaign</a></div>;
			}
		}
	}

	render() {
		const itemClassName = `list-group-item${this.props.item.isUnknown ? ' list-group-item-unknown' : ''}`;
		return (
			<a href={this.props.item.editUrl} title={this.props.item.name} className={itemClassName}>
				<div className="list-group-item-heading">
					<div>
						<span className="btn-link fund-name">{this.props.item.name}</span>
						<small>({this.props.item.code})</small>
						{this.getFundUsage()}
						<span className="hidden-xs">
							{this.renderLabel()}
						</span>
					</div>
					<div className="fund-total-container">
						{this.renderAmount() }
						<div className="visible-xs">{this.renderLabel() }</div>
					</div>
				</div>
				<p className="list-group-item-text">
					{this.renderCampaignDetails()}
					{this.props.item.isUnknown ? this.renderIsUnknownNotes() : this.props.item.notes}
					{this.renderDelete()}
				</p>
			</a>);
	}
}

