import moment from 'moment';
import { action, observable, computed } from 'mobx';
import { LocalStorageHelper } from '../../helpers/localstoragehelper';
import { Models } from '../check-deposit-generated';
import CheckAllocationBatchDetailsModel = Models.CheckAllocationBatchDetailsModel;

const DATE_FORMAT = 'MMMM D, YYYY h:mma';

export class AllocationBatchDetailsModel {
	@observable showBatchSettings: boolean;
	@observable BatchDetails: CheckAllocationBatchDetailsModel;
	@observable GivenOn: Date;
	@observable FundKey: string;
	@observable isProcessingSpeedSettingsRequest: boolean;
	@observable isProcessingSaveRequest: boolean = false;

	@computed
	get speedSettingsButtonDisabled() {
		return (!this.GivenOn && !this.FundKey) || this.isProcessingSaveRequest;
	}

	private checkDepositAllocationShowBatchSettingsKey = 'checkDepositAllocationShowBatchSettings';

	constructor(batchDetails: CheckAllocationBatchDetailsModel) {
		this.BatchDetails = batchDetails;
		this.GivenOn = null;
		this.FundKey = '';

		const localStorageShowSettings = LocalStorageHelper.getLocalStorage().getItem(this.checkDepositAllocationShowBatchSettingsKey);

		if (!localStorageShowSettings) {
			this.showBatchSettings = true;
		} else {
			this.showBatchSettings = JSON.parse(localStorageShowSettings);
		}
	}

	@computed
	get StatusLastChangedOnFormatted(): string {
		const statusLastChangedOn = this.BatchDetails.StatusLastChangedOn;
		return statusLastChangedOn ? moment(statusLastChangedOn).format(DATE_FORMAT) : null;
	}

	@computed
	get CreatedOnFormatted(): string {
		const createdOn = this.BatchDetails.CreatedOn;
		return moment(createdOn).format(DATE_FORMAT);
	}

	@computed
	get fundCustomField(): Models.ICustomFieldViewModel {
		return this.BatchDetails.CustomFields.filter(x => x.Type === Models.CustomFieldType.Fund)[0];
	}

	@action
	updateLastUpdated(updatedBy: string, statusLastChangedOn: Date) {
		this.BatchDetails.UpdatedBy = updatedBy;
		this.BatchDetails.StatusLastChangedOn = statusLastChangedOn;
	}

	@action
	toggleShowBatchSettings = () => {
		this.showBatchSettings = !this.showBatchSettings;
		LocalStorageHelper.getLocalStorage().setItem(this.checkDepositAllocationShowBatchSettingsKey, JSON.stringify(this.showBatchSettings));
	}

	@action
	onChangeGivenOn = (date: Date) => {
		this.GivenOn = date;
	}

	@action
	onChangeFund = (value: string) => {
		this.FundKey = value;
	}
}
