import { observable, action, computed } from 'mobx';
import { ArrayHelper } from '../../helpers/arrayhelper';
import { Utils } from '../../helpers/utils';
import { MultiSelectItem, MultiSelectViewModel } from '../../loggedinweb-generated';

export class MultiSelectStore {
	@observable items: MultiSelectItem[];
	@observable selectedValues: string[];
	@observable currentIndex: number;
	@observable searchTerm: string = '';
	@observable isActive: boolean;
	@observable dropListActive: boolean;

	public displayName?: string;

	constructor(
		multiSelectViewModel: MultiSelectViewModel,
		displayName?: string,
		public externalChangeListener?: (selectedValues: string[]) => void,
	) {
		this.displayName = displayName;
		this.items = multiSelectViewModel.Items;
		this.initSelectedItems(multiSelectViewModel.SelectedValues || []);
	}

	@action
	activate = () => {
		if (this.isActive) { return; }
		this.isActive = true;
		this.showDropList(true);
		this.resetCurrentItem();
	}

	@action
	deactivate = () => {
		this.isActive = false;
		this.searchTerm = '';
		this.resetCurrentItem();
	}

	@action
	showDropList = (showDropList: boolean) => {
		this.dropListActive = showDropList;
	}

	@action
	selectItem = (item: MultiSelectItem) => {
		if (this.isItemSelected(item) === false) {
			this.selectedValues.push(item.Value);
		}

		if (this.externalChangeListener) {
			this.externalChangeListener(this.selectedValues);
		}
	}

	@action
	selectItemByValue = (selectedValue: string) => {
		const selectedItem = this.items.find(x => x.Value === selectedValue);
		this.selectItem(selectedItem);
	}

	@action
	setSelectedItems = (items: string[]) => {
		this.reset();
		items.forEach(item => this.selectItemByValue(item));
	}

	@action
	deselectItem = (item: MultiSelectItem) => {
		this.selectedValues.splice(this.selectedValues.indexOf(item.Value), 1);

		if (this.externalChangeListener) {
			this.externalChangeListener(this.selectedValues);
		}
	}

	@action
	toggleItem = (item: MultiSelectItem) => {
		if (item.Disabled) {
			return;
		}

		if (this.isItemSelected(item)) {
			this.deselectItem(item);
		} else {
			this.selectItem(item);
		}
	}

	@action
	deselectLastItem = () => {
		if (!this.searchTerm && this.selectedValues.length > 0) {
			const lastSelectedValue = this.selectedValues[this.selectedValues.length - 1];
			const [lastSelectedItem] = this.items.filter(x => x.Value === lastSelectedValue);
			this.deselectItem(lastSelectedItem);
		}
	}

	@action
	search = (searchTerm: string) => {
		this.searchTerm = searchTerm;
		this.resetCurrentItem();
	}

	@computed
	get searchExpression() {
		return new RegExp(`^(${Utils.escapeRegex(this.searchTerm)})(.*)`, 'i');
	}

	@computed
	get displayItems() {
		return this.searchTerm
			? this.items.filter(x => this.searchExpression.test(x.Text))
			: this.items;
	}

	@action
	setCurrentItem = (item: MultiSelectItem) => { this.currentIndex = this.displayItems.indexOf(item); }

	@action
	resetCurrentItem = () => { this.currentIndex = -1; }

	@computed
	get currentItem() {
		return this.currentIndex < 0
			? null
			: this.displayItems[this.currentIndex];
	}

	@action
	moveCurrentItemUp = () => {
		this.currentIndex = Math.max(this.currentIndex - 1, 0);
	}

	@action
	moveCurrentItemDown = () => {
		this.currentIndex = Math.max(Math.min(this.currentIndex + 1, this.displayItems.length - 1), 0);
	}

	@action
	toggleCurrentItem = () => {
		const currentItem = this.currentItem;
		if (!currentItem) {
			return;
		}

		this.toggleItem(currentItem);
	}

	@computed
	get selectedItems() {
		return this.items.filter(x => this.isItemSelected(x));
	}

	@computed
	get hasSelectedItems() {
		return this.selectedValues && this.selectedValues.length > 0;
	}

	@action
	reset = () => {
		this.selectedValues = [];
	}

	isItemSelected = (item: MultiSelectItem) => {
		return ArrayHelper.contains(this.selectedValues, item.Value);
	}

	@action.bound
	private initSelectedItems(selectedValues: string[]) {
		this.selectedValues = selectedValues;
	}
}
