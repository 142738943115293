import * as React from 'react';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import { LocalStorageHelper } from '../../helpers/localstoragehelper';
import { SvgWrapper } from '../../../Shared/components/svg-wrapper';
import { TransitionGroup } from 'react-transition-group-v1';
import { velocity } from '../../helpers/velocity';
import { classNames } from '../../../Shared/utils/classnames';

import * as styles from './notification-popup.less';

const leaveDuration = 250;

export type NotificationPopupPosition = 'left' | 'right';

export interface INotificationPopupProps {
	position: NotificationPopupPosition;
	hideWithLocalStorageKey?: string;
}

export class NotificationPopup extends React.Component<INotificationPopupProps, { isVisible: boolean }> {
	constructor(props: INotificationPopupProps) {
		super(props);

		const { hideWithLocalStorageKey } = this.props;
		const isVisible = !hideWithLocalStorageKey || !LocalStorageHelper.getLocalStorage().getItem(hideWithLocalStorageKey);
		this.state = { isVisible: isVisible };
	}

	render() {
		const { isVisible } = this.state;
		const { position, children } = this.props;

		return (
			<TransitionGroup>
				{isVisible && <NotificationPopupInner position={position} children={children} closeHandler={this.handleClose} />}
			</TransitionGroup>
		);
	}

	handleClose = () => {
		this.setState({ isVisible: false });
		LocalStorageHelper.getLocalStorage().setItem(this.props.hideWithLocalStorageKey, 'dismissed');
	}
}

class NotificationPopupInner extends React.Component<{ position: NotificationPopupPosition, closeHandler: () => void }> {
	private container: HTMLDivElement;
	render() {
		const { position, closeHandler } = this.props;
		const positionClass = position === 'left' ? styles.left : styles.right;
		return (
			<div className={classNames(styles.popup, 'notch', styles.popupNotch, positionClass, 'hidden-xs', 'hidden-sm')} ref={(element) => this.container = element }>
				<h3 className={styles.popupTitle}>What's new</h3>
				<button type="button" onClick={closeHandler} className={classNames('btn', 'btn-dismiss', styles.popupBtnDismiss)} aria-label="Close">
					<SvgWrapper svg="close-cross" className="svg svg-close-cross"/>
				</button>
				{this.props.children}
				<button type="button" className={classNames('btn', 'btn-tertiary', styles.popupBtnOk)} onClick={closeHandler}>Ok, got it</button>
			</div>
		);
	}

	componentWillLeave(callback) {
		if (!this.container) {
			callback();
			return;
		}

		velocity(this.container, 'fadeOut', {
			duration: leaveDuration,
			easing: 'ease-out',
			complete: callback,
		});
	}
}
