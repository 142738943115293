import * as React from 'react';
import { PaymentLabel } from '../../virtual-terminal/virtual-terminal-generated';
import { AccessibilityListbox } from '../hoc-accessibility/accessibility-listbox';
import { IPayerSearchResult } from './payer-search-models';
import { isFunction } from '../../utils/is-function';
import { PayerSearchDropDownMenuItem, PayerSearchLoadMoreItem, PayerSearchDownDownMenuEmptyItem } from '../payer-search-omnibox/payer-search-dropdown-items';
import { IHaveOmniboxContext, OmniboxContextKey, LoadMoreId } from './payer-search-omnibox-context';
import { StopMouseMovePropogationWhenSamePosition } from '../hoc-behavior/stop-mousemove-when-same-position';
import { ScrollableContainer } from '../hoc-behavior/scrollable-container';
import { inject, observer } from 'mobx-react';
import { IPayerViewModel } from './payer-search-omnibox';
import { SvgWrapper } from '../svg-wrapper';
import { WithAnalytics, recordEvent, injectAnalytics } from '../../analytics';

@injectAnalytics
@inject(OmniboxContextKey)
@observer
export class PayerSearchDropDownMenu extends React.Component<IHaveOmniboxContext & WithAnalytics & {
	componentId: string;
	selectedPayer: IPayerViewModel;
	onChange?: (payer: IPayerViewModel) => void;
	onAddNewCommunityMember?: () => void;
	searchResult: IPayerSearchResult;
	searchValue: string;
	onLoadMore?: () => void;
	loadMoreInProgress: boolean;
	openRequested: boolean;
	onMenuStateChange: (open: boolean) => void;
	paymentLabel?: PaymentLabel;
	hideAddNewMemberOption?: boolean;
	enableFeatureOrganizationalGiving: boolean;
}, { lastScrollTop: number }> {
	constructor(props) {
		super(props);
		this.state = {
			lastScrollTop: 0
		};
	}

	render() {
		const { searchResult, selectedPayer, omniboxContext, searchValue, loadMoreInProgress, hideAddNewMemberOption } = this.props;
		const hasPayers = searchResult && searchResult.payers && searchResult.payers.length > 0;
		if (!omniboxContext.open) {
			return null;
		}
		return (
			<div className="dropdown-menu open" onMouseDown={this.preventLosingFocus}>
				<ScrollableContainer initialScrollingPosition={this.state.lastScrollTop} onUnmount={this.persistScrollingPosition}>
					<StopMouseMovePropogationWhenSamePosition>
						<AccessibilityListbox id={omniboxContext.listId}>
							<ul className="dropdown-menu dropdown-menu-nested dropdown-menu-hover-controlled open" onMouseMove={this.regainFocus}>
								{!hasPayers && <PayerSearchDownDownMenuEmptyItem searchResult={searchResult} searchValue={searchValue} />}
								{searchResult.payers.map((x, index) => (
									<PayerSearchDropDownMenuItem payer={x} selectedPayer={selectedPayer} key={x.id} enableFeatureOrganizationalGiving={this.props.enableFeatureOrganizationalGiving} />
								))}
								{searchResult.hasMorePages ?
									<PayerSearchLoadMoreItem inProgress={loadMoreInProgress} />
									: null}
							</ul>
						</AccessibilityListbox>
					</StopMouseMovePropogationWhenSamePosition>
				</ScrollableContainer>
				{!hideAddNewMemberOption && <button className="btn btn-link btn-sm payer-search-add-new-payer-button" type="button" onClick={this.handleAddNewMember}>
					<SvgWrapper svg="icon-add" className="icon" />Add a new member
				</button>}
			</div>
		);
	}

	UNSAFE_componentWillMount() {
		//syncs this.props.searchResult items with the omnibox context
		//handleChange is passed to make it available in the context so we pass less props
		this.updateFromProps(this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		//syncs nextProps.searchResult items with the omnibox context
		this.updateFromProps(nextProps);
	}

	private updateFromProps({ omniboxContext, searchResult, selectedPayer, openRequested, componentId, onMenuStateChange }: PayerSearchDropDownMenu['props']) {
		omniboxContext.updateFromProps(searchResult, !!selectedPayer, componentId, openRequested, this.handleChange, onMenuStateChange);
	}

	private handleChange = (payerId: string) => {
		const { onLoadMore } = this.props;

		if (payerId === LoadMoreId) {
			if (isFunction(onLoadMore)) {
				onLoadMore();
			}

			return;
		}

		if (isFunction(this.props.onChange)) {
			const payer = this.getItemByPayerId(payerId);
			if (payer) {
				if (NewFeatures.SetupPinpointAnalytics && this.props.analytics) {
					const { feature, subFeature } = this.props.analytics;
					recordEvent({ feature, subFeature, eventTypeLabel: 'selectGiverName' });
				}

				this.props.onChange(payer);
				this.props.omniboxContext.handleRequestClose();
			}
		}
	}

	private handleAddNewMember = () => {
		const { onAddNewCommunityMember } = this.props;
		if (isFunction(onAddNewCommunityMember)) {
			onAddNewCommunityMember();
		}
	}

	private getItemByPayerId(id: string) {
		const { searchResult } = this.props;

		if (!searchResult || searchResult.payers.length === 0) {
			return null;
		}

		return searchResult.payers.filter(x => `${x.id}` === id)[0];
	}

	private persistScrollingPosition = (lastScrollTop: number) => {
		this.setState({ lastScrollTop });
	}

	private preventLosingFocus = (e: React.MouseEvent<HTMLDivElement>): void => {
		e.preventDefault();
	}

	private regainFocus = () => {
		this.props.omniboxContext.setFocused(true);
	}
}
