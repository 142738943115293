import { observable, computed, action, runInAction } from 'mobx';
import * as Util from '../util';


export class BusyTracker {
	@observable promises: PromiseLike<any>[] = [];

	trackPromise(p: PromiseLike<any>) {
		this.promises.push(p);
		p.then(x => {
			Util.arrayRemove(this.promises, p);
		}, e => {
			Util.arrayRemove(this.promises, p);
			//we might have gotten a null rejection. This means something else has handled the error but wants us to know.
			//otherwise, chain the rejection, so we can bubble up to unhandledrejection?
			if (e) {
				return Promise.reject(e);
			}
		});
	}

	stopTracking(p: PromiseLike<any>) {
		Util.arrayRemove(this.promises, p);
	}

	@computed get isBusy() {
		return this.promises.length > 0;
	}
}

export enum AnimationState {
	Inactive, Active, Triggered
}

export class AnimationTrigger {
	@observable state = AnimationState.Inactive;
	timeoutHandle: number;

	constructor(private className: string, public animationLength: number) {

	}

	@action trigger() {
		clearTimeout(this.timeoutHandle);
		this.state = AnimationState.Triggered;
		setTimeout(() => runInAction(() => this.state = AnimationState.Active), 0);
		this.timeoutHandle = window.setTimeout(() => runInAction(() => this.state = AnimationState.Inactive), this.animationLength);
	}

	@computed get isAnimating() {
		return this.state === AnimationState.Active;
	}

	@computed get currentClassName() {
		switch (this.state) {
			case AnimationState.Triggered:
				return `triggered ${this.className}`;
			case AnimationState.Active:
				return this.className;
			case AnimationState.Inactive:
				return null;
		}
	}
}

