import { getCheckDepositDataService } from '../check-deposit-data-service';
import { Models } from '../check-deposit-generated';
import { AjaxUtils } from '../../utils/ajax-utils';

import CheckDepositSsoApiConfig = Models.CheckDepositSsoApiConfig;

export module ClientFlowHelper {
	const checkScanningPortalWindows = new Array<Window>();

	export function openCheckScanningPortalWindow() {
		const checkScanningPortalUrl = getCheckDepositPortalUrl();

		const newCheckScanningPortalWindow = window.open(checkScanningPortalUrl,
			'_blank',
			'toolbar=0,scrollbars=1,location=0,statusbar=0,menubar=0,resizable=0,width=1160,height=860,left=50,top=50,titlebar=yes'
		);
		newCheckScanningPortalWindow.opener = null;

		trackCheckScanningPortalWindow(newCheckScanningPortalWindow);
		logOpenCheckScanningPortalWindows();
	}

	function trackCheckScanningPortalWindow(checkScanningPortalWindow: Window) {
		checkScanningPortalWindows.push(checkScanningPortalWindow);
	}

	function getCheckScanningPortalWindowsCount() {
		return checkScanningPortalWindows.filter(x => !x.closed).length;
	}

	function getCheckDepositPortalUrl() {
		return AjaxUtils.combineWithBasePath(CheckDepositSsoApiConfig.urls.index(undefined), AjaxUtils.resolveBaseUrl(CheckDepositSsoApiConfig.defaultBaseUrl));
	}

	function logOpenCheckScanningPortalWindows() {
		getCheckDepositDataService().initRequest('logCheckScanningPortalWindowsCount', { openCheckScanningPortalWindowsCount: getCheckScanningPortalWindowsCount() });
	}
}
