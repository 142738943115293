import { inject } from 'mobx-react';
import { observable, ObservableMap, action } from 'mobx';

export interface IHaveValidatableFormContext {
	validatableFormContext?: ValidatableFormContext;
}

export class ValidatableFormContext {
	@observable
	errorMap: ObservableMap<string, string> = observable.map<string, string>();

	constructor(private onRevalidateElement: (elementName: string) => void, errorMap?: ObservableMap<string, string>) {
		this.errorMap = errorMap || observable.map<string, string>();
	}

	@action
	updateErrorMap(errorMap) {
		this.errorMap.merge(errorMap);
	}

	@action
	revalidateElement(elementName: string) {
		//revalidate element only when it was submitted
		if (this.errorMap.has(elementName)) {
			this.onRevalidateElement(elementName);
		}
	}

	@action
	validateElement(elementName: string) {
		this.onRevalidateElement(elementName);
	}
}

export type IReactComponent<P> = React.StatelessComponent<P> | React.ComponentClass<P> | React.ClassicComponentClass<P>;

export function injectValidationFormContext<P extends IHaveValidatableFormContext>(clazz: IReactComponent<P>): React.ClassicComponentClass<P>;
export function injectValidationFormContext<P extends IHaveValidatableFormContext>(clazz: React.ClassicComponentClass<P>): React.ClassicComponentClass<P>;
export function injectValidationFormContext<P extends IHaveValidatableFormContext, TFunction extends React.ComponentClass<P | void>>(target: TFunction): TFunction;
export function injectValidationFormContext(target) {
	return inject((s: any) => ({ validatableFormContext: s.validatableFormContext }))(target);
}

export function getFieldError(props: IHaveValidatableFormContext, fieldName: string) {
	const error = props.validatableFormContext && props.validatableFormContext.errorMap.get(fieldName);
	if (typeof error === 'string') {
		return error;
	}

	return null;
}
