import * as React from 'react';

export class ScrollToElement extends React.Component<{ children: React.ReactNode }> {
	ref: HTMLDivElement;

	componentDidMount() {
		this.ref.scrollIntoView({
			behavior: 'smooth',
		});
	}

	render () {
		return <div ref={ref => this.ref = ref}>{this.props.children}</div>;
	}
}
